frappe.provide('silicon_ioi.payments');

export class ioiGlory {
	static API_URL = "https://glory.local:8080/api";


	static async need_login(){
		let output = await ioiGlory.get_status();
		return output.success != true;
	}
	static async get_status(){
		try {
			const req_headers = {
									"Accept-Encoding": "gzip, deflate, br",
									"Accept": "application/json",
									"Connection": "keep-alive",
									"Content-Type": "application/json",
								};
			const response = await fetch(`${ioiGlory.API_URL}/status`,
										{
											headers: req_headers,
											method: "GET",
											credentials: "include",
										}
									);
			if (response.status >= 200 && response.status < 300) {
				const data = await response.text();
				return {success: true, data: data};
			} else {
				return {success: false, code: response.status ,error: response.statusText};
			}
		} catch (error) {
			return {success: false, error: error}
		}
	}

	static async login(user,password){
		try {
			const authB64 = btoa(user + ":" + password);
			const req_headers = {
									"Authorization": "Basic " + authB64,
									// "Host": "127.0.0.1:8080",
									"Accept-Encoding": "gzip, deflate, br",
									"Accept": "application/json",
									"Connection": "keep-alive",
									"Content-Type": "application/json",
								};
			const response = await fetch(`${ioiGlory.API_URL}/login`,
										{
										headers: req_headers,
										method: "GET",
										credentials: "include",
										//mode: 'no-cors',
										}
									);
			if (response.status >= 200 && response.status < 300) {
				const data = await response.text();
				return {success: true, data: data};
			} else {
				return {success: false, code: response.status ,error: response.statusText};
			}
		} catch (error) {
			return {success: false, error: error};
		}
	}

	static async logout(){
		try {
			const req_headers = {
									"Accept-Encoding": "gzip, deflate, br",
									"Accept": "application/json",
									"Connection": "keep-alive",
									"Content-Type": "application/json",
								};
			const response = await fetch(`${ioiGlory.API_URL}/logout`,
										{
										headers: req_headers,
										method: "GET",
										credentials: "include",
										}
									);
			if (response.status >= 200 && response.status < 300) {
				const data = await response.text();
				return {success: true, data: data};
			} else {
				return {success: false, code: response.status ,error: response.statusText};
			}
		} catch (error) {
			return {success: false, error: error};
		}
	}

	static async ping(){
		try {
			const response = await fetch(`${ioiGlory.API_URL}/ping`, {
			  method: "GET",
			});

			if (response.status >= 200 && response.status < 300) {
				const data = await response.text();
				return {success: true, data: data};
			} else {
				return {success: false, code: response.status ,error: response.statusText};
			}
		} catch (error) {
			return {success: false, error: error};
		}
	}
	
	static async transaction(amount){
		try {
			const response = await fetch(`${ioiGlory.API_URL}/transaction`, {
			  method: "POST",
			  credentials: "include",
			  headers: {
				"Content-Type": "application/json",
			  },
			  body:JSON.stringify({"amount": amount}),
			});

			if (response.status >= 200 && response.status < 300) {
				const data = await response.text();
				return {success: true, data: data};
			} else {
				return {success: false, code: response.status ,error: response.statusText};
			}
		} catch (error) {
			return {success: false, error: error};
		}
	}

	static async cancel_pay(){
		try {
			const response = await fetch(`${ioiGlory.API_URL}/cancel`, {
			  method: "POST",
			  credentials: "include",
			});

			if (response.status >= 200 && response.status < 300) {
				const data = await response.text();
				return {success: true, data: data};
			} else {
				return {success: false, code: response.status ,error: response.statusText};
			}
		} catch (error) {
			return {success: false, error: error};
		}
	}

	/*static async start_replenish(){
		try {
			const req_body = {
								"type": "cassette"
							};
			const response = await fetch(`${ioiGlory.API_URL}/replenish`, {
			  method: "POST",
			  credentials: "include",
			  headers: {
				"Content-Type": "application/json",
			  },
			  body:JSON.stringify(req_body),
			});

			if (response.status >= 200 && response.status < 300) {
				const data = await response.text();
				return {success: true, data: data};
			} else {
				return {success: false, code: response.status ,error: response.statusText};
			}
		} catch (error) {
			return {success: false, error: error};
		}
	}

	static async end_replenish(){
		try {
			const response = await fetch(`${ioiGlory.API_URL}/replenish`, {
			  method: "DELETE",
			});

			if (response.status >= 200 && response.status < 300) {
				const data = await response.text();
				return {success: true, data: data};
			} else {
				return {success: false, code: response.status ,error: response.statusText};
			}
		} catch (error) {
			return {success: false, error: error};
		}
	}
		
	static async inventory(){
		try {
			const response = await fetch(`${ioiGlory.API_URL}/inventory`, {
			  method: "GET",
			  credentials: "include",
			});

			if (response.status >= 200 && response.status < 300) {
				const data = await response.text();
				return {success: true, data: data};
			} else {
				return {success: false, code: response.status ,error: response.statusText};
			}
		} catch (error) {
			return {success: false, error: error};
		}
	}

	static async history(){
		try {
			const response = await fetch(`${ioiGlory.API_URL}/history`, {
			  method: "GET",
			  credentials: "include",
			});

			if (response.status >= 200 && response.status < 300) {
				const data = await response.text();
				return {success: true, data: data};
			} else {
				return {success: false, code: response.status ,error: response.statusText};
			}
		} catch (error) {
			return {success: false, error: error};
		}
	}*/
}

silicon_ioi.payments.ioiGlory = ioiGlory;
