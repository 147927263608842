frappe.provide('silicon_ioi.doctype');


const path_cash_desk = 'silicon_ioi.ioi_configuration.doctype.ioi_cash_desk.ioi_cash_desk';
const path_cash_desk_movement = 'silicon_ioi.ioi_configuration.doctype.ioi_cash_desk_movement.ioi_cash_desk_movement';

export class ioiCashDesk
{

	static pay_cash_desk_content_pay_type = '';
	static pay_cash_desk_content_pay_subtype = '';
	static pay_cash_desk_content_pay_status = 0;

	static pay_cash_desk_content_cash_value = '';
	static pay_cash_desk_content_cash_back_value = '';

	static pay_cash_desk_content_bank_card_value = '';
	static pay_cash_desk_content_bank_card_back_value = '';
	static pay_cash_desk_content_bank_card_back_unallowed_value = '';
	static pay_cash_desk_content_bank_card_transaction_id = '';
	static pay_cash_desk_content_bank_card_transaction_detail = '';

	static pay_cash_desk_content_credit_card_value = '';
	static pay_cash_desk_content_credit_card_transaction_id = '';
	static pay_cash_desk_content_credit_card_transaction_detail = '';

	static pay_cash_desk_content_check_value = '';
	static pay_cash_desk_content_check_id = '';

	static pay_cash_desk_content_emoney_value = '';
	static pay_cash_desk_content_emoney_back_value = '';
	static pay_cash_desk_content_emoney_back_unallowed_value = '';
	static pay_cash_desk_content_emoney_transaction_id = '';
	static pay_cash_desk_content_emoney_transaction_detail = '';

	static pay_cash_desk_content_check_spec_1_value = '';
	static pay_cash_desk_content_check_spec_1_id = '';
	static pay_cash_desk_content_check_spec_1_transaction_id = '';
	static pay_cash_desk_content_check_spec_1_transaction_detail = '';

	static pay_cash_desk_content_check_spec_2_value = '';
	static pay_cash_desk_content_check_spec_2_id = '';
	static pay_cash_desk_content_check_spec_2_transaction_id = '';
	static pay_cash_desk_content_check_spec_2_transaction_detail = '';

	static pay_cash_desk_content_check_spec_3_value = '';
	static pay_cash_desk_content_check_spec_3_id = '';
	static pay_cash_desk_content_check_spec_3_transaction_id = '';
	static pay_cash_desk_content_check_spec_3_transaction_detail = '';

	static rounded_still_to_paid = 0.0;



	static clear_pending_payment()
	{
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_cash_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_cash_back_value = '';


		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_back_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_back_unallowed_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_detail = '';

		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_detail = '';

		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_id = '';

		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_back_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_back_unallowed_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_transaction_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_transaction_detail = '';

		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_detail = '';

		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_detail = '';

		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_detail = '';

		if (document.getElementById('ioi_sales_pos_pay_button_pay_deliver')) {
			document.getElementById('ioi_sales_pos_pay_button_pay_deliver').src = '/assets/silicon_ioi/images/buttons/pay_deliver.svg';
		}

	}

	static has_pending_payment()
	{
		let ret_value = false;

		if ((silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_cash_value != '') || (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_cash_back_value != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_value != '') || (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_back_value != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_back_unallowed_value != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_id != '') || (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_detail != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_value != '') || (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_id != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_detail != '') || (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_value != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_id.trim() != '') || (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_value != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_back_value != '') || (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_back_unallowed_value != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_transaction_id != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_transaction_detail != '') || (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_value != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_id.trim() != '') || (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_id != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_detail != '') || (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_value != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_id.trim() != '') || (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_id != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_detail != '') || (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_value != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_id.trim() != '') || (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_id != '') ||
			(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_detail != '')) {

			ret_value = true;
		}

		return ret_value;

	}




	static open_cash_desk(cashdesk_id = '', fct_callback_msg = null, operator_id = '', operator_name = '')
	{
		if ((!cashdesk_id) || ((cashdesk_id) && (cashdesk_id.trim() == ''))) {

            if (fct_callback_msg) {
                let ret = [];
                ret[ret.length] = [2, __("Cash desk is mandatory before executing this action")];
				fct_callback_msg(ret);
				return false;
            }else{
			    frappe.msgprint({title: __("Message"), message: __("Cash desk is mandatory before executing this action"), indicator: "red"});
                raise;
            }

		}

		let meth = path_cash_desk + '.ioi_cash_desk_get_info';


		let role_to_open = '';
		let cashdesk_status = 0;
		let current_cash_value = 0;
		let current_spec1_value = 0;
		let current_spec2_value = 0;
		let current_spec3_value = 0;

		frappe.call({  	method: meth,
						args: {"name": cashdesk_id},
                        async: false,
						callback:function(r)	{

							if (r.message.length != 0) {
								role_to_open = r.message[0].role_to_open;
								cashdesk_status = r.message[0].ioistatus;
								current_cash_value = r.message[0].cash_value;
								current_spec1_value = r.message[0].check_spec1_value;
								current_spec2_value = r.message[0].check_spec2_value;
								current_spec3_value = r.message[0].check_spec3_value;
							}
						}
		});

		if (cashdesk_status == 1) {

            if (fct_callback_msg) {

                let ret = [];
                ret[ret.length] = [2, __("This cash desk is already open")];
				fct_callback_msg(ret);
				return false;

            }else{

			    frappe.msgprint({title: __("Message"), message: __("This cash desk is already open"), indicator: "red"});
			    raise;
            }
		}

		let can_open_cash_desk = false;

		if (frappe.user.name.toUpperCase() == 'ADMINISTRATOR') {
			can_open_cash_desk = true;
		}else {
			if (role_to_open != '') {
				if (frappe.user.has_role(role_to_open)) {
					can_open_cash_desk = true;
				}
			}
		}

		if (can_open_cash_desk) {


			let techno = '';
			let p_ipaddress = '';
			let p_port = '';

			let method = path_cash_desk + '.ioi_cash_desk_get_printer_techno'

			frappe.call({  	method: method,
							args: {"name": cashdesk_id},
							async: false,
							callback:function(r)	{

								techno = r.message.printing_techno_id;
								p_ipaddress = r.message.printer_ip_address;
								p_port = r.message.printer_port;

							}
			});

			let title = __('Open cask desk');
			let bt_primary_caption = __('Open cash desk');

			var d = new frappe.ui.Dialog({
				'title': title,
				'fields': [
					{'fieldname': 'html_open_cash_desk', 'fieldtype': 'HTML'}

				],
				primary_action_label: bt_primary_caption,
				secondary_action_label: __('Cancel'),
				primary_action: function(){

					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

					let cash_value = 0;

					if (document.getElementById('open_cash_desk_content_new_cash_value').value.trim() != '') {

						if (parseFloat(document.getElementById('open_cash_desk_content_new_cash_value').value) < 0) {

							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Counted value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						cash_value = parseFloat(document.getElementById('open_cash_desk_content_new_cash_value').value);
					}


					let cash_delta = 0;

					if (document.getElementById('open_cash_desk_content_delta_cash_value').value.trim() != '') {

						cash_delta = parseFloat(document.getElementById('open_cash_desk_content_delta_cash_value').value);
					}



					let spec1_value = 0;

					if (document.getElementById('open_cash_desk_content_new_check_spec1_value').value.trim() != '') {

						if (parseFloat(document.getElementById('open_cash_desk_content_new_check_spec1_value').value) < 0) {

							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Counted spec1 value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						spec1_value = parseFloat(document.getElementById('open_cash_desk_content_new_check_spec1_value').value);
					}


					let spec1_delta = 0;

					if (document.getElementById('open_cash_desk_content_delta_check_spec1_value').value.trim() != '') {

						spec1_delta = parseFloat(document.getElementById('open_cash_desk_content_delta_check_spec1_value').value);
					}



					let spec2_value = 0;

					if (document.getElementById('open_cash_desk_content_new_check_spec2_value').value.trim() != '') {

						if (parseFloat(document.getElementById('open_cash_desk_content_new_check_spec2_value').value) < 0) {

							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Counted spec2 value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						spec2_value = parseFloat(document.getElementById('open_cash_desk_content_new_check_spec2_value').value);
					}


					let spec2_delta = 0;

					if (document.getElementById('open_cash_desk_content_delta_check_spec2_value').value.trim() != '') {

						spec2_delta = parseFloat(document.getElementById('open_cash_desk_content_delta_check_spec2_value').value);
					}


					let spec3_value = 0;

					if (document.getElementById('open_cash_desk_content_new_check_spec3_value').value.trim() != '') {

						if (parseFloat(document.getElementById('open_cash_desk_content_new_check_spec3_value').value) < 0) {

							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Counted spec3 value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						spec3_value = parseFloat(document.getElementById('open_cash_desk_content_new_check_spec3_value').value);
					}


					let spec3_delta = 0;

					if (document.getElementById('open_cash_desk_content_delta_check_spec3_value').value.trim() != '') {

						spec3_delta = parseFloat(document.getElementById('open_cash_desk_content_delta_check_spec3_value').value);
					}



					let op = '/';
					let ba = ''

					if (operator_id.indexOf("/") != -1) {
						op = operator_id.substring(0, operator_id.indexOf("/")-1).trim();
						ba = operator_id.substring(operator_id.indexOf("/")+1, operator_id.length).trim();

					}else{
						op = operator_id;
					}

					let method = path_cash_desk + '.ioi_cash_desk_open';

					frappe.call({  	method: method,
									args: {	"name": cashdesk_id, "cash_value": cash_value, "cash_delta": cash_delta, "operator_id": op, "badge_id": ba,
											"spec1_value": spec1_value, "spec1_delta": spec1_delta, "spec2_value": spec2_value, "spec2_delta": spec2_delta,
											"spec3_value": spec3_value, "spec3_delta": spec3_delta
									},
									async: false,
									callback:function(r)	{

										if (r.message.error != 0)
											{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
												frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
												raise;
											}
									}
					});

					d.hide();

					if (fct_callback_msg) {

						let ret = [];
						ret[ret.length] = [0, __("This cash desk is open")];
						fct_callback_msg(ret);
						return false;
					}

				},
				secondary_action: function(){
					d.hide();
				}

			});


			if (document.getElementById('open_cash_desk_content')) {
				document.getElementById('open_cash_desk_content').remove();
			}

			if (document.getElementById('open_cash_desk_content_value_cash_label')) {
				document.getElementById('open_cash_desk_content_value_cash_label').remove();
			}

			if (document.getElementById('open_cash_desk_content_value_cash')) {
				document.getElementById('open_cash_desk_content_value_cash').remove();
			}

			if (document.getElementById('open_cash_desk_content_new_cash_value')) {
				document.getElementById('open_cash_desk_content_new_cash_value').remove();
			}

			if (document.getElementById('open_cash_desk_content_delta_cash_value')) {
				document.getElementById('open_cash_desk_content_delta_cash_value').remove();
			}



			if (document.getElementById('open_cash_desk_content_value_check_spec1_label')) {
				document.getElementById('open_cash_desk_content_value_check_spec1_label').remove();
			}

			if (document.getElementById('open_cash_desk_content_value_check_spec1_value')) {
				document.getElementById('open_cash_desk_content_value_check_spec1_value').remove();
			}

			if (document.getElementById('open_cash_desk_content_new_check_spec1_value')) {
				document.getElementById('open_cash_desk_content_new_check_spec1_value').remove();
			}

			if (document.getElementById('open_cash_desk_content_delta_check_spec1_value')) {
				document.getElementById('open_cash_desk_content_delta_check_spec1_value').remove();
			}




			if (document.getElementById('open_cash_desk_content_value_check_spec2_label')) {
				document.getElementById('open_cash_desk_content_value_check_spec2_label').remove();
			}

			if (document.getElementById('open_cash_desk_content_value_check_spec2_value')) {
				document.getElementById('open_cash_desk_content_value_check_spec2_value').remove();
			}

			if (document.getElementById('open_cash_desk_content_new_check_spec2_value')) {
				document.getElementById('open_cash_desk_content_new_check_spec2_value').remove();
			}

			if (document.getElementById('open_cash_desk_content_delta_check_spec2_value')) {
				document.getElementById('open_cash_desk_content_delta_check_spec2_value').remove();
			}



			if (document.getElementById('open_cash_desk_content_value_check_spec3_label')) {
				document.getElementById('open_cash_desk_content_value_check_spec3_label').remove();
			}

			if (document.getElementById('open_cash_desk_content_value_check_spec3_value')) {
				document.getElementById('open_cash_desk_content_value_check_spec3_value').remove();
			}

			if (document.getElementById('open_cash_desk_content_new_check_spec3_value')) {
				document.getElementById('open_cash_desk_content_new_check_spec3_value').remove();
			}

			if (document.getElementById('open_cash_desk_content_delta_check_spec3_value')) {
				document.getElementById('open_cash_desk_content_delta_check_spec3_value').remove();
			}


			if (document.getElementById('open_cash_desk_content_subtitle_section')) {
				document.getElementById('open_cash_desk_content_subtitle_section').remove();
			}

			if (document.getElementById('open_cash_desk_content_subtitle')) {
				document.getElementById('open_cash_desk_content_subtitle').remove();
			}



			if (document.getElementById('open_cash_desk_content_remark_section')) {
				document.getElementById('open_cash_desk_content_remark_section').remove();
			}

			if (document.getElementById('open_cash_desk_content_remark')) {
				document.getElementById('open_cash_desk_content_remark').remove();
			}


			let html = '';

			let who = '';

			if ((operator_name) && (operator_name.trim() != '')) {
				who = operator_name;
			}else{

				if ((!operator_id) || (operator_id) && (operator_id == '')) {
					who = frappe.session.user;
				}else{
					who = operator_id;
				}
			}



			html += '<div id="open_cash_desk_content_subtitle_section" style="overflow-x: auto;height:30px;">';
			html += '		<label id="open_cash_desk_content_subtitle" style="position: absolute; top: 0px; left: 2px;">' + __("Opening proceed by") + ' <b>' + who + '</b></label>';
			html += '</div>';


			html += '<div id="open_cash_desk_content" style="overflow-x: auto;height:190px;">';

			html += '	<div style="position: relative; top:0px; left: 0px; width:560px;">';
			html += '		<label id="close_cash_desk_content_current_title" style="position: absolute; top: 0px; left: 150px;">' + __("Current value") + '</label>';
			html += '		<label id="close_cash_desk_content_new_title" style="position: absolute; top: 0px; left: 290px;">' + __("Counted value") + '</label>';
			html += '		<label id="close_cash_desk_content_delta_title" style="position: absolute; top: 0px; left: 430px;">' + __("Difference") + '</label>';
			html += '	</div>';

			html += '	<div style="position: relative; top: 25px; left: 0px; width:170px;">';
			html += '		<label id="open_cash_desk_content_value_cash_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Cash value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 130px; height: 25px;"> ';
			html += '			<input id="open_cash_desk_content_value_cash" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + current_cash_value + '" disabled>';
			html += '		</div>';
			html += '	</div>';

			html += '	<div style="position: relative; top: 25px; left: 0px; width:170px;">';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 290px; width: 130px; height: 25px;"> ';
			html += '			<input id="open_cash_desk_content_new_cash_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';
			html += '	</div>';

			html += '	<div style="position: relative; top: 25px; left: 0px; width:170px;">';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 430px; width: 130px; height: 25px;"> ';
			html += '			<input id="open_cash_desk_content_delta_cash_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="" disabled>';
			html += '		</div>';
			html += '	</div>'

			// Check spec 1 value
			html += '	<div style="position: relative; top: 65px; left: 0px; width:170px;">';
			html += '		<label id="open_cash_desk_content_check_spec1_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Check spec 1 value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 130px; height: 25px;"> ';
			html += '			<input id="open_cash_desk_content_value_check_spec1_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + current_spec1_value + '" disabled>';
			html += '		</div>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 290px; width: 130px; height: 25px;"> ';
			html += '			<input id="open_cash_desk_content_new_check_spec1_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 430px; width: 130px; height: 25px;"> ';
			html += '			<input id="open_cash_desk_content_delta_check_spec1_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="" disabled>';
			html += '		</div>';
			html += '	</div>';

			// Check spec 2 value
			html += '	<div style="position: relative; top: 105px; left: 0px; width:170px;">';
			html += '		<label id="open_cash_desk_content_check_spec2_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Check spec 2 value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 130px; height: 25px;"> ';
			html += '			<input id="open_cash_desk_content_value_check_spec2_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + current_spec2_value + '" disabled>';
			html += '		</div>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 290px; width: 130px; height: 25px;"> ';
			html += '			<input id="open_cash_desk_content_new_check_spec2_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';


			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 430px; width: 130px; height: 25px;"> ';
			html += '			<input id="open_cash_desk_content_delta_check_spec2_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="" disabled>';
			html += '		</div>';
			html += '	</div>';


			// Check spec 3 value
			html += '	<div style="position: relative; top: 145px; left: 0px; width:170px;">';
			html += '		<label id="open_cash_desk_content_check_spec3_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Check spec 3 value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 130px; height: 25px;"> ';
			html += '			<input id="open_cash_desk_content_value_check_spec3_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + current_spec3_value + '" disabled>';
			html += '		</div>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 290px; width: 130px; height: 25px;"> ';
			html += '			<input id="open_cash_desk_content_new_check_spec3_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 430px; width: 130px; height: 25px;"> ';
			html += '			<input id="open_cash_desk_content_delta_check_spec3_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="" disabled>';
			html += '		</div>';
			html += '	</div>';



			html += '</div>';

			html += '<div id="open_cash_desk_content_remark_section" style="overflow-x: auto;height:30px;">';
			html += '	<label id="open_cash_desk_content_remark" style="position: relative; top: 0px; left: 2px;color:blue;">' + __("Please be exact in your opening. Important process") + '</label>';
			html += '</div>';



			d.fields_dict.html_open_cash_desk.$wrapper.html(html);
			d.$wrapper.find('.modal-dialog').css("max-width", "610px").css("width", "610px");
			d.show();

			silicon_ioi.doctype.ioiCashDesk.sleep_static(400).then(() => {

				let fct_key_down = function(event) {

					if (event.keyCode == 13) {

						if (techno == 'EPOSDEVICE') {
							if ((p_ipaddress.trim() != '') && (p_port != '') && (p_port != '0')) {
								let epson_print = new silicon_ioi.doctype.ioiEPosDevice(p_ipaddress, p_port, 500, 'DRAWER_PULSE', false, null, null);
							}
						}
					}

				}
				document.getElementById('open_cash_desk_content_new_cash_value').onkeydown = fct_key_down;

				silicon_ioi.doctype.ioiCashDesk.sleep_static(500).then(() => {

					document.getElementById('open_cash_desk_content_new_cash_value').focus();

					let fct_change_cash = function()
					{
						let current_value = 0;

						if ((document.getElementById('open_cash_desk_content_value_cash').value) && (document.getElementById('open_cash_desk_content_value_cash').value != 0)) {
							current_value = parseFloat(document.getElementById('open_cash_desk_content_value_cash').value);
						}

						let v = 0;

						if ((document.getElementById('open_cash_desk_content_new_cash_value').value) && (document.getElementById('open_cash_desk_content_new_cash_value').value != '')) {
							v = parseFloat(document.getElementById('open_cash_desk_content_new_cash_value').value)
						}

						let delta = parseFloat(v) - parseFloat(current_value);

						document.getElementById('open_cash_desk_content_delta_cash_value').value = delta;

						document.getElementById('open_cash_desk_content_delta_cash_value').style.backgroundColor = '';

						if (delta > 0) {
							document.getElementById('open_cash_desk_content_delta_cash_value').style.backgroundColor = '#cffce0';
						}else if (delta < 0) {
							document.getElementById('open_cash_desk_content_delta_cash_value').style.backgroundColor = '#fcb8b3';
						}
					}

					document.getElementById('open_cash_desk_content_new_cash_value').onkeyup = fct_change_cash;
					document.getElementById('open_cash_desk_content_new_cash_value').onchange = fct_change_cash;

					fct_change_cash();


					let fct_change_spec1 = function()
					{
						let current_value = 0;

						if ((document.getElementById('open_cash_desk_content_value_check_spec1_value').value) && (document.getElementById('open_cash_desk_content_value_check_spec1_value').value != 0)) {
							current_value = parseFloat(document.getElementById('open_cash_desk_content_value_check_spec1_value').value);
						}

						let v = 0;

						if ((document.getElementById('open_cash_desk_content_new_check_spec1_value').value) && (document.getElementById('open_cash_desk_content_new_check_spec1_value').value != '')) {
							v = parseFloat(document.getElementById('open_cash_desk_content_new_check_spec1_value').value)
						}

						let delta = parseFloat(v) - parseFloat(current_value);

						document.getElementById('open_cash_desk_content_delta_check_spec1_value').value = delta;

						document.getElementById('open_cash_desk_content_delta_check_spec1_value').style.backgroundColor = '';

						if (delta > 0) {
							document.getElementById('open_cash_desk_content_delta_check_spec1_value').style.backgroundColor = '#cffce0';
						}else if (delta < 0) {
							document.getElementById('open_cash_desk_content_delta_check_spec1_value').style.backgroundColor = '#fcb8b3';
						}
					}

					document.getElementById('open_cash_desk_content_new_check_spec1_value').onkeyup = fct_change_spec1;
					document.getElementById('open_cash_desk_content_new_check_spec1_value').onchange = fct_change_spec1;

					fct_change_spec1();

					let fct_change_spec2 = function()
					{
						let current_value = 0;

						if ((document.getElementById('open_cash_desk_content_value_check_spec2_value').value) && (document.getElementById('open_cash_desk_content_value_check_spec2_value').value != 0)) {
							current_value = parseFloat(document.getElementById('open_cash_desk_content_value_check_spec2_value').value);
						}

						let v = 0;

						if ((document.getElementById('open_cash_desk_content_new_check_spec2_value').value) && (document.getElementById('open_cash_desk_content_new_check_spec2_value').value != '')) {
							v = parseFloat(document.getElementById('open_cash_desk_content_new_check_spec2_value').value)
						}

						let delta = parseFloat(v) - parseFloat(current_value);

						document.getElementById('open_cash_desk_content_delta_check_spec2_value').value = delta;

						document.getElementById('open_cash_desk_content_delta_check_spec2_value').style.backgroundColor = '';

						if (delta > 0) {
							document.getElementById('open_cash_desk_content_delta_check_spec2_value').style.backgroundColor = '#cffce0';
						}else if (delta < 0) {
							document.getElementById('open_cash_desk_content_delta_check_spec2_value').style.backgroundColor = '#fcb8b3';
						}
					}

					document.getElementById('open_cash_desk_content_new_check_spec2_value').onkeyup = fct_change_spec2;
					document.getElementById('open_cash_desk_content_new_check_spec2_value').onchange = fct_change_spec2;

					fct_change_spec2();



					let fct_change_spec3 = function()
					{
						let current_value = 0;

						if ((document.getElementById('open_cash_desk_content_value_check_spec3_value').value) && (document.getElementById('open_cash_desk_content_value_check_spec3_value').value != 0)) {
							current_value = parseFloat(document.getElementById('open_cash_desk_content_value_check_spec3_value').value);
						}

						let v = 0;

						if ((document.getElementById('open_cash_desk_content_new_check_spec3_value').value) && (document.getElementById('open_cash_desk_content_new_check_spec3_value').value != '')) {
							v = parseFloat(document.getElementById('open_cash_desk_content_new_check_spec3_value').value)
						}

						let delta = parseFloat(v) - parseFloat(current_value);

						document.getElementById('open_cash_desk_content_delta_check_spec3_value').value = delta;

						document.getElementById('open_cash_desk_content_delta_check_spec3_value').style.backgroundColor = '';

						if (delta > 0) {
							document.getElementById('open_cash_desk_content_delta_check_spec3_value').style.backgroundColor = '#cffce0';
						}else if (delta < 0) {
							document.getElementById('open_cash_desk_content_delta_check_spec3_value').style.backgroundColor = '#fcb8b3';
						}
					}

					document.getElementById('open_cash_desk_content_new_check_spec3_value').onkeyup = fct_change_spec3;
					document.getElementById('open_cash_desk_content_new_check_spec3_value').onchange = fct_change_spec3;

					fct_change_spec3();

				});
			});

		}else{
            if (fct_callback_msg) {

				let ret = [];
				ret[ret.length] = [2, __("You're not authorized to open this cash desk")];
				fct_callback_msg(ret);
				return false;

            }else{

			    frappe.msgprint({title: __("Message"), message: __("You're not authorized to open this cash desk"), indicator: "red"});
			    raise;

			}
		}

	}


	static close_cash_desk(cashdesk_id = '', fct_callback_msg = null, operator_id = '', operator_name = '')
	{


		if ((!cashdesk_id) || ((cashdesk_id) && (cashdesk_id.trim() == ''))) {

            if (fct_callback_msg) {
                let ret = [];
                ret[ret.length] = [2, __("Cash desk is mandatory before executing this action")];
				fct_callback_msg(ret);
				return false;
            }else{
			    frappe.msgprint({title: __("Message"), message: __("Cash desk is mandatory before executing this action"), indicator: "red"});
                raise;
            }
		}

		let meth = path_cash_desk + '.ioi_cash_desk_get_info';


		let role_to_close = '';
		let cashdesk_status = 0;

		let current_cash_value = 0;
		let current_bank_card_value = 0;
		let current_credit_card_value = 0;
		let current_check_value = 0;
		let current_paypal_value = 0;
		let current_emoney_value = 0;
		let current_check_spec1_value = 0;
		let current_check_spec2_value = 0;
		let current_check_spec3_value = 0;



		frappe.call({  	method: meth,
						args: {"name": cashdesk_id},
						async: false,
						callback:function(r)	{

							if (r.message.length != 0) {
								role_to_close = r.message[0].role_to_close;
								cashdesk_status = r.message[0].ioistatus;

								current_cash_value = r.message[0].cash_value;
								current_bank_card_value = r.message[0].bank_card_value;
								current_credit_card_value = r.message[0].credit_card_value;
								current_check_value = r.message[0].check_value;
								current_paypal_value = r.message[0].paypal_value;
								current_emoney_value = r.message[0].emoney_value;
								current_check_spec1_value = r.message[0].check_spec1_value;
								current_check_spec2_value = r.message[0].check_spec2_value;
								current_check_spec3_value = r.message[0].check_spec3_value;

							}
						}
		});

		if (cashdesk_status == 0) {

            if (fct_callback_msg) {

				let ret = [];
				ret[ret.length] = [2, __("This cash desk is disabled")];
				fct_callback_msg(ret);
				return false;

            }else{

			    frappe.msgprint({title: __("Message"), message: __("This cash desk is disabled"), indicator: "red"});
			    raise;

			}

		}

		if (cashdesk_status == 2) {

            if (fct_callback_msg) {

				let ret = [];
				ret[ret.length] = [2, __("This cash desk is already closed")];
				fct_callback_msg(ret);
				return false;

            }else{

			    frappe.msgprint({title: __("Message"), message: __("This cash desk is already closed"), indicator: "red"});
			    raise;

			}


		}



		let can_close_cash_desk = false;

		if (frappe.user.name.toUpperCase() == 'ADMINISTRATOR') {
			can_close_cash_desk = true;
		}else {
			if (role_to_close != '') {
				if (frappe.user.has_role(role_to_close)) {
					can_close_cash_desk = true;
				}
			}
		}

		if (can_close_cash_desk) {

			let techno = '';
			let p_ipaddress = '';
			let p_port = '';

			let method = path_cash_desk + '.ioi_cash_desk_get_printer_techno'

			frappe.call({  	method: method,
							args: {"name": cashdesk_id},
							async: false,
							callback:function(r)	{

								techno = r.message.printing_techno_id;
								p_ipaddress = r.message.printer_ip_address;
								p_port = r.message.printer_port;

							}
			});

			let total_get = 0;
			let total_put = 0;



			method = path_cash_desk + '.ioi_cash_desk_closing_get_put_value';

			frappe.call({  	method: method,
							args: {	"cashdesk_id": cashdesk_id,
							},
							async: false,
							callback:function(r)	{
								total_get = r.message.get;
								total_put = r.message.put;
							}
			});


			let me = this;

			let title = __('Close cask desk');
			let bt_primary_caption = __('Close cash desk');

			var d = new frappe.ui.Dialog({
				'title': title,
				'fields': [
					{'fieldname': 'html_close_cash_desk', 'fieldtype': 'HTML'}

				],
				primary_action_label: bt_primary_caption,
				secondary_action_label: __('Cancel'),
				primary_action: function(){

					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

					let new_cash_value = 0;

					if (document.getElementById('close_cash_desk_content_new_cash_value').value.trim() != '') {

						if (parseFloat(document.getElementById('close_cash_desk_content_new_cash_value').value) < 0) {

							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Cash counted value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						new_cash_value = parseFloat(document.getElementById('close_cash_desk_content_new_cash_value').value);
					}

					let new_bank_card_value = 0;

					if (document.getElementById('close_cash_desk_content_new_bank_card_value').value.trim() != '') {

						if (parseFloat(document.getElementById('close_cash_desk_content_new_bank_card_value').value) < 0) {

							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Bank card value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						new_bank_card_value = parseFloat(document.getElementById('close_cash_desk_content_new_bank_card_value').value);
					}


					let new_credit_card_value = 0;

					if (document.getElementById('close_cash_desk_content_new_credit_card_value').value.trim() != '') {

						if (parseFloat(document.getElementById('close_cash_desk_content_new_credit_card_value').value) < 0) {

							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Credit card value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						new_credit_card_value = parseFloat(document.getElementById('close_cash_desk_content_new_credit_card_value').value);
					}


					let new_check_value = 0;

					if (document.getElementById('close_cash_desk_content_new_check_value').value.trim() != '') {

						if (parseFloat(document.getElementById('close_cash_desk_content_new_check_value').value) < 0) {

							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Check value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						new_check_value = parseFloat(document.getElementById('close_cash_desk_content_new_check_value').value);
					}


					let new_paypal_value = 0;

					if (document.getElementById('close_cash_desk_content_new_paypal_value').value.trim() != '') {

						if (parseFloat(document.getElementById('close_cash_desk_content_new_paypal_value').value) < 0) {

							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("PayPal value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						new_paypal_value = parseFloat(document.getElementById('close_cash_desk_content_new_paypal_value').value);
					}




					let new_emoney_value = 0;

					if (document.getElementById('close_cash_desk_content_new_emoney_value').value.trim() != '') {

						if (parseFloat(document.getElementById('close_cash_desk_content_new_emoney_value').value) < 0) {

							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("EMoney value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						new_emoney_value = parseFloat(document.getElementById('close_cash_desk_content_new_emoney_value').value);
					}


					let new_check_spec1_value = 0;

					if (document.getElementById('close_cash_desk_content_new_check_spec1_value').value.trim() != '') {

						if (parseFloat(document.getElementById('close_cash_desk_content_new_check_spec1_value').value) < 0) {

							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Check spec 1 value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						new_check_spec1_value = parseFloat(document.getElementById('close_cash_desk_content_new_check_spec1_value').value);
					}



					let new_check_spec2_value = 0;

					if (document.getElementById('close_cash_desk_content_new_check_spec2_value').value.trim() != '') {

						if (parseFloat(document.getElementById('close_cash_desk_content_new_check_spec2_value').value) < 0) {

							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Check spec 2 value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						new_check_spec2_value = parseFloat(document.getElementById('close_cash_desk_content_new_check_spec2_value').value);
					}


					let new_check_spec3_value = 0;

					if (document.getElementById('close_cash_desk_content_new_check_spec3_value').value.trim() != '') {

						if (parseFloat(document.getElementById('close_cash_desk_content_new_check_spec3_value').value) < 0) {

							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Check spec 3 value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						new_check_spec3_value = parseFloat(document.getElementById('close_cash_desk_content_new_check_spec3_value').value);
					}

					let method = path_cash_desk + '.ioi_cash_desk_close';

					frappe.call({  	method: method,
									args: {	"name": cashdesk_id,
											"new_cash_value": new_cash_value,
											"new_bank_card_value": new_bank_card_value,
											"new_credit_card_value": new_credit_card_value,
											"new_check_value": new_check_value,
											"new_paypal_value": new_paypal_value,
											"new_emoney_value": new_emoney_value,
											"new_check_spec1_value": new_check_spec1_value,
											"new_check_spec2_value": new_check_spec2_value,
											"new_check_spec3_value": new_check_spec3_value,
											"operator_id": operator_id,
											"badge_id": '',
											"delta_cash": document.getElementById('close_cash_desk_content_delta_cash_value').value,
											"delta_spec1": document.getElementById('close_cash_desk_content_delta_check_spec1_value').value,
											"delta_spec2": document.getElementById('close_cash_desk_content_delta_check_spec1_value').value,
											"delta_spec3": document.getElementById('close_cash_desk_content_delta_check_spec1_value').value,




									},
									async: false,
									callback:function(r)	{

										if (r.message.error != 0)
										{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
											frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
											raise;
										}else{
											if (fct_callback_msg) {

												let ret = [];
												ret[ret.length] = [0, __("This cashdesk is closed")];
												fct_callback_msg(ret);
												return false;

											}
										}
									}
					});


					d.hide();

				},
				secondary_action: function(){
					d.hide();
				}

			});


			if (document.getElementById('close_cash_desk_content')) {
				document.getElementById('close_cash_desk_content').remove();
			}

			if (document.getElementById('close_cash_desk_content_current_title')) {
				document.getElementById('close_cash_desk_content_current_title').remove();
			}

			if (document.getElementById('close_cash_desk_content_new_title')) {
				document.getElementById('close_cash_desk_content_new_title').remove();
			}

			if (document.getElementById('close_cash_desk_content_delta_title')) {
				document.getElementById('close_cash_desk_content_delta_title').remove();
			}



			if (document.getElementById('close_cash_desk_content_cash_value_label')) {
				document.getElementById('close_cash_desk_content_cash_value_label').remove();
			}

			if (document.getElementById('close_cash_desk_content_current_cash_value')) {
				document.getElementById('close_cash_desk_content_current_cash_value').remove();
			}

			if (document.getElementById('close_cash_desk_content_new_cash_value')) {
				document.getElementById('close_cash_desk_content_new_cash_value').remove();
			}

			if (document.getElementById('close_cash_desk_content_delta_cash_value')) {
				document.getElementById('close_cash_desk_content_delta_cash_value').remove();
			}



			if (document.getElementById('close_cash_desk_content_bank_card_value_label')) {
				document.getElementById('close_cash_desk_content_bank_card_value_label').remove();
			}

			if (document.getElementById('close_cash_desk_content_current_bank_card_value')) {
				document.getElementById('close_cash_desk_content_current_bank_card_value').remove();
			}

			if (document.getElementById('close_cash_desk_content_new_bank_card_value')) {
				document.getElementById('close_cash_desk_content_new_bank_card_value').remove();
			}


			if (document.getElementById('close_cash_desk_content_credit_card_value_label')) {
				document.getElementById('close_cash_desk_content_credit_card_value_label').remove();
			}

			if (document.getElementById('close_cash_desk_content_current_credit_card_value')) {
				document.getElementById('close_cash_desk_content_current_credit_card_value').remove();
			}

			if (document.getElementById('close_cash_desk_content_new_credit_card_value')) {
				document.getElementById('close_cash_desk_content_new_credit_card_value').remove();
			}


			if (document.getElementById('close_cash_desk_content_check_value_label')) {
				document.getElementById('close_cash_desk_content_check_value_label').remove();
			}

			if (document.getElementById('close_cash_desk_content_current_check_value')) {
				document.getElementById('close_cash_desk_content_current_check_value').remove();
			}

			if (document.getElementById('close_cash_desk_content_new_check_value')) {
				document.getElementById('close_cash_desk_content_new_check_value').remove();
			}

			if (document.getElementById('close_cash_desk_content_paypal_value_label')) {
				document.getElementById('close_cash_desk_content_paypal_value_label').remove();
			}

			if (document.getElementById('close_cash_desk_content_current_paypal_value')) {
				document.getElementById('close_cash_desk_content_current_paypal_value').remove();
			}

			if (document.getElementById('close_cash_desk_content_new_paypal_value')) {
				document.getElementById('close_cash_desk_content_new_paypal_value').remove();
			}


			if (document.getElementById('close_cash_desk_content_emoney_value_label')) {
				document.getElementById('close_cash_desk_content_emoney_value_label').remove();
			}

			if (document.getElementById('close_cash_desk_content_current_emoney_value')) {
				document.getElementById('close_cash_desk_content_current_emoney_value').remove();
			}

			if (document.getElementById('close_cash_desk_content_new_emoney_value')) {
				document.getElementById('close_cash_desk_content_new_emoney_value').remove();
			}

			if (document.getElementById('close_cash_desk_content_check_spec1_value_label')) {
				document.getElementById('close_cash_desk_content_check_spec1_value_label').remove();
			}

			if (document.getElementById('close_cash_desk_content_current_check_spec1_value')) {
				document.getElementById('close_cash_desk_content_current_check_spec1_value').remove();
			}

			if (document.getElementById('close_cash_desk_content_new_check_spec1_value')) {
				document.getElementById('close_cash_desk_content_new_check_spec1_value').remove();
			}


			if (document.getElementById('close_cash_desk_content_delta_check_spec1_value')) {
				document.getElementById('close_cash_desk_content_delta_check_spec1_value').remove();
			}




			if (document.getElementById('close_cash_desk_content_check_spec2_value_label')) {
				document.getElementById('close_cash_desk_content_check_spec2_value_label').remove();
			}

			if (document.getElementById('close_cash_desk_content_current_check_spec2_value')) {
				document.getElementById('close_cash_desk_content_current_check_spec2_value').remove();
			}

			if (document.getElementById('close_cash_desk_content_new_check_spec2_value')) {
				document.getElementById('close_cash_desk_content_new_check_spec2_value').remove();
			}


			if (document.getElementById('close_cash_desk_content_delta_check_spec2_value')) {
				document.getElementById('close_cash_desk_content_delta_check_spec2_value').remove();
			}



			if (document.getElementById('close_cash_desk_content_check_spec3_value_label')) {
				document.getElementById('close_cash_desk_content_check_spec3_value_label').remove();
			}

			if (document.getElementById('close_cash_desk_content_current_check_spec3_value')) {
				document.getElementById('close_cash_desk_content_current_check_spec3_value').remove();
			}

			if (document.getElementById('close_cash_desk_content_new_check_spec3_value')) {
				document.getElementById('close_cash_desk_content_new_check_spec3_value').remove();
			}

			if (document.getElementById('close_cash_desk_content_delta_check_spec3_value')) {
				document.getElementById('close_cash_desk_content_delta_check_spec3_value').remove();
			}


			if (document.getElementById('close_cash_desk_content_remark_section')) {
				document.getElementById('close_cash_desk_content_remark_section').remove();
			}
			if (document.getElementById('close_cash_desk_content_remark')) {
				document.getElementById('close_cash_desk_content_remark').remove();
			}



			let html = '';

			let who = '';

			if ((operator_name) && (operator_name.trim() != '')) {
				who = operator_name;
			}else{

				if ((!operator_id) || (operator_id) && (operator_id == '')) {
					who = frappe.session.user;
				}else{
					who = operator_id;
				}
			}

			html += '<div id="close_cash_desk_content_subtitle_section" style="overflow-x: auto;height:30px;">';
			html += '		<label id="close_cash_desk_content_subtitle" style="position: absolute; top: 0px; left: 2px;">' + __("Recount proceed by") + ' <b>' + who + '</b></label>';
			html += '</div>';


			html += '<div id="close_cash_desk_content" style="overflow-x: auto;height:190px;">';



			html += '	<div style="position: relative; top:0px; left: 0px; width:500px;">';
			html += '		<label id="close_cash_desk_content_current_title" style="position: absolute; top: 0px; left: 150px;">' + __("Current value") + '</label>';
			html += '		<label id="close_cash_desk_content_new_title" style="position: absolute; top: 0px; left: 290px;">' + __("Counted value") + '</label>';
			html += '		<label id="close_cash_desk_content_delta_title" style="position: absolute; top: 0px; left: 430px;">' + __("Difference") + '</label>';
			html += '	</div>';

			// Cash value
			html += '	<div style="position: relative; top: 25px; left: 0px; width:170px;">';
			html += '		<label id="close_cash_desk_content_cash_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Cash value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_current_cash_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + current_cash_value + '" disabled>';
			html += '		</div>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 290px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_new_cash_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 430px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_delta_cash_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="" disabled>';
			html += '		</div>';

			html += '	</div>';


			// Bank card value
			html += '	<div style="position: relative; top: 65px; left: 0px; width:170px;display:none">';
			html += '		<label id="close_cash_desk_content_bank_card_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Bank card value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_current_bank_card_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + current_bank_card_value + '" disabled>';
			html += '		</div>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 290px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_new_bank_card_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="" disabled>';
			html += '		</div>';
			html += '	</div>';


			// Credit card value
			html += '	<div style="position: relative; top: 105px; left: 0px; width:170px;display:none">';
			html += '		<label id="close_cash_desk_content_credit_card_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Credit card value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_current_credit_card_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + current_credit_card_value + '" disabled>';
			html += '		</div>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 290px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_new_credit_card_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="" disabled>';
			html += '		</div>';
			html += '	</div>';


			// Check value
			html += '	<div style="position: relative; top: 145px; left: 0px; width:170px;display:none">';
			html += '		<label id="close_cash_desk_content_check_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Check value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_current_check_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + current_check_value + '" disabled>';
			html += '		</div>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 290px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_new_check_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="" disabled>';
			html += '		</div>';
			html += '	</div>';

			// PayPal value
			html += '	<div style="position: relative; top: 185px; left: 0px; width:170px;display:none">';
			html += '		<label id="close_cash_desk_content_paypal_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("PayPal value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_current_paypal_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + current_paypal_value + '" disabled>';
			html += '		</div>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 290px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_new_paypal_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="" disabled>';
			html += '		</div>';
			html += '	</div>';

			// EMoney value
			html += '	<div style="position: relative; top: 225px; left: 0px; width:170px;display:none">';
			html += '		<label id="close_cash_desk_content_emoney_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Emoney value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_current_emoney_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + current_emoney_value + '" disabled>';
			html += '		</div>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 290px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_new_emoney_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="" disabled>';
			html += '		</div>';
			html += '	</div>';

			// Check spec 1 value
			html += '	<div style="position: relative; top: 65px; left: 0px; width:170px;">';
			html += '		<label id="close_cash_desk_content_check_spec1_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Check spec 1 value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_current_check_spec1_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + current_check_spec1_value + '" disabled>';
			html += '		</div>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 290px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_new_check_spec1_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 430px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_delta_check_spec1_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="" disabled>';
			html += '		</div>';

			html += '	</div>';

			// Check spec 2 value
			html += '	<div style="position: relative; top: 105px; left: 0px; width:170px;">';
			html += '		<label id="close_cash_desk_content_check_spec2_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Check spec 2 value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_current_check_spec2_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + current_check_spec2_value + '" disabled>';
			html += '		</div>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 290px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_new_check_spec2_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 430px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_delta_check_spec2_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="" disabled>';
			html += '		</div>';

			html += '	</div>';

			// Check spec 3 value
			html += '	<div style="position: relative; top: 145px; left: 0px; width:170px;">';
			html += '		<label id="close_cash_desk_content_check_spec3_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Check spec 3 value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_current_check_spec3_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + current_check_spec3_value + '" disabled>';
			html += '		</div>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 290px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_new_check_spec3_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 430px; width: 130px; height: 25px;"> ';
			html += '			<input id="close_cash_desk_content_delta_check_spec3_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="" disabled>';
			html += '		</div>';

			html += '	</div>';


			html += '</div>';


			d.fields_dict.html_close_cash_desk.$wrapper.html(html);
			d.$wrapper.find('.modal-dialog').css("max-width", "605px").css("width", "605px");
			d.show();

			silicon_ioi.doctype.ioiCashDesk.sleep_static(400).then(() => {

				let fct_key_down = function(event) {

					if (event.keyCode == 13) {

						if (techno == 'EPOSDEVICE') {
							if ((p_ipaddress.trim() != '') && (p_port != '') && (p_port != '0')) {
								let epson_print = new silicon_ioi.doctype.ioiEPosDevice(p_ipaddress, p_port, 500, 'DRAWER_PULSE', false, null, null);
							}
						}
					}
				}

				document.getElementById('close_cash_desk_content_new_cash_value').onkeydown = fct_key_down;

				silicon_ioi.doctype.ioiCashDesk.sleep_static(500).then(() => {


					document.getElementById('close_cash_desk_content_new_cash_value').focus();

					let fct_change_cash = function()
					{
						let current_value = 0;

						if ((document.getElementById('close_cash_desk_content_current_cash_value').value) && (document.getElementById('close_cash_desk_content_current_cash_value').value != 0)) {
							current_value = parseFloat(document.getElementById('close_cash_desk_content_current_cash_value').value);
						}

						let v = 0;

						if ((document.getElementById('close_cash_desk_content_new_cash_value').value) && (document.getElementById('close_cash_desk_content_new_cash_value').value != '')) {
							v = parseFloat(document.getElementById('close_cash_desk_content_new_cash_value').value)
						}

						let delta = parseFloat(v) - parseFloat(current_value);

						document.getElementById('close_cash_desk_content_delta_cash_value').value = delta;

						document.getElementById('close_cash_desk_content_delta_cash_value').style.backgroundColor = '';

						if (delta > 0) {
							document.getElementById('close_cash_desk_content_delta_cash_value').style.backgroundColor = '#cffce0';
						}else if (delta < 0) {
							document.getElementById('close_cash_desk_content_delta_cash_value').style.backgroundColor = '#fcb8b3';
						}




					}


					document.getElementById('close_cash_desk_content_new_cash_value').onkeyup = fct_change_cash;
					document.getElementById('close_cash_desk_content_new_cash_value').onchange = fct_change_cash;

					fct_change_cash();



					let fct_change_spec1 = function()
					{
						let current_value = 0;

						if ((document.getElementById('close_cash_desk_content_current_check_spec1_value').value) && (document.getElementById('close_cash_desk_content_current_check_spec1_value').value != 0)) {
							current_value = parseFloat(document.getElementById('close_cash_desk_content_current_check_spec1_value').value);
						}

						let v = 0;

						if ((document.getElementById('close_cash_desk_content_new_check_spec1_value').value) && (document.getElementById('close_cash_desk_content_new_check_spec1_value').value != '')) {
							v = parseFloat(document.getElementById('close_cash_desk_content_new_check_spec1_value').value)
						}

						let delta = parseFloat(v) - parseFloat(current_value);

						document.getElementById('close_cash_desk_content_delta_check_spec1_value').value = delta;

						document.getElementById('close_cash_desk_content_delta_check_spec1_value').style.backgroundColor = '';

						if (delta > 0) {
							document.getElementById('close_cash_desk_content_delta_check_spec1_value').style.backgroundColor = '#cffce0';
						}else if (delta < 0) {
							document.getElementById('close_cash_desk_content_delta_check_spec1_value').style.backgroundColor = '#fcb8b3';
						}


					}

					document.getElementById('close_cash_desk_content_new_check_spec1_value').onkeyup = fct_change_spec1;
					document.getElementById('close_cash_desk_content_new_check_spec1_value').onchange = fct_change_spec1;

					fct_change_spec1();


					let fct_change_spec2 = function()
					{
						let current_value = 0;

						if ((document.getElementById('close_cash_desk_content_current_check_spec2_value').value) && (document.getElementById('close_cash_desk_content_current_check_spec2_value').value != 0)) {
							current_value = parseFloat(document.getElementById('close_cash_desk_content_current_check_spec2_value').value);
						}

						let v = 0;

						if ((document.getElementById('close_cash_desk_content_new_check_spec2_value').value) && (document.getElementById('close_cash_desk_content_new_check_spec2_value').value != '')) {
							v = parseFloat(document.getElementById('close_cash_desk_content_new_check_spec2_value').value)
						}

						let delta = parseFloat(v) - parseFloat(current_value);

						document.getElementById('close_cash_desk_content_delta_check_spec2_value').value = delta;

						document.getElementById('close_cash_desk_content_delta_check_spec2_value').style.backgroundColor = '';

						if (delta > 0) {
							document.getElementById('close_cash_desk_content_delta_check_spec2_value').style.backgroundColor = '#cffce0';
						}else if (delta < 0) {
							document.getElementById('close_cash_desk_content_delta_check_spec2_value').style.backgroundColor = '#fcb8b3';
						}



					}

					document.getElementById('close_cash_desk_content_new_check_spec2_value').onkeyup = fct_change_spec2;
					document.getElementById('close_cash_desk_content_new_check_spec2_value').onchange = fct_change_spec2;

					fct_change_spec2();


					let fct_change_spec3 = function()
					{
						let current_value = 0;

						if ((document.getElementById('close_cash_desk_content_current_check_spec3_value').value) && (document.getElementById('close_cash_desk_content_current_check_spec3_value').value != 0)) {
							current_value = parseFloat(document.getElementById('close_cash_desk_content_current_check_spec3_value').value);
						}

						let v = 0;

						if ((document.getElementById('close_cash_desk_content_new_check_spec3_value').value) && (document.getElementById('close_cash_desk_content_new_check_spec3_value').value != '')) {
							v = parseFloat(document.getElementById('close_cash_desk_content_new_check_spec3_value').value)
						}

						let delta = parseFloat(v) - parseFloat(current_value);

						document.getElementById('close_cash_desk_content_delta_check_spec3_value').value = delta;

						document.getElementById('close_cash_desk_content_delta_check_spec3_value').style.backgroundColor = '';

						if (delta > 0) {
							document.getElementById('close_cash_desk_content_delta_check_spec3_value').style.backgroundColor = '#cffce0';
						}else if (delta < 0) {
							document.getElementById('close_cash_desk_content_delta_check_spec3_value').style.backgroundColor = '#fcb8b3';
						}

					}


					document.getElementById('close_cash_desk_content_new_check_spec3_value').onkeyup = fct_change_spec3;
					document.getElementById('close_cash_desk_content_new_check_spec3_value').onchange = fct_change_spec3;

					fct_change_spec3();



				});

			});

		}else{
            if (fct_callback_msg) {

				let ret = [];
				ret[ret.length] = [2, __("You're not authorized to close this cash desk")];
				fct_callback_msg(ret);
				return false;

            }else{

			    frappe.msgprint({title: __("Message"), message: __("You're not authorized to close this cash desk"), indicator: "red"});
			    raise;

			}

		}
	}

	static do_pay_get_cash(cashdesk_id, doctype, doctype_name, ioistatus, document_value, current_cash_value, fct_callback_action = null, operator_id = '')
	{



		let techno = '';
		let p_ipaddress = '';
		let p_port = '';

		let method = path_cash_desk + '.ioi_cash_desk_get_printer_techno'

		frappe.call({  	method: method,
						args: {"name": cashdesk_id},
						async: false,
						callback:function(r)	{

							techno = r.message.printing_techno_id;
							p_ipaddress = r.message.printer_ip_address;
							p_port = r.message.printer_port;

						}
		});



		let title = __('Get cash');
		let bt_primary_caption = __('Get cash');

		var d = new frappe.ui.Dialog({
			'title': title,
			'fields': [
				{'fieldname': 'html_get_cash', 'fieldtype': 'HTML'}

			],
			primary_action_label: bt_primary_caption,
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let cash_value = 0;

				if (document.getElementById('get_cash_desk_content_cash_value').value.trim() != '') {

					if (parseFloat(document.getElementById('get_cash_desk_content_cash_value').value) <= 0) {

						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
						frappe.msgprint({title: __("Message"), message: __("Cash value has to be greater than zero"), indicator: "red"});
						raise;
					}

					if (parseFloat(document.getElementById('get_cash_desk_content_cash_value').value) > parseFloat(current_cash_value)) {

						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
						frappe.msgprint({title: __("Message"), message: __("No enough cash in this cash desk"), indicator: "red"});
						raise;
					}


					cash_value = parseFloat(document.getElementById('get_cash_desk_content_cash_value').value);
				}

				if (cash_value == 0) {
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					frappe.msgprint({title: __("Message"), message: __("Cash value has to be greater than zero"), indicator: "red"});
					raise;
				}

				if (document.getElementById('get_cash_desk_content_remark').value.trim() == '') {
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					frappe.msgprint({title: __("Message"), message: __("Remark is mandatory"), indicator: "red"});
					raise;
				}

				let cb_cashback = 0;

				if (document.getElementById('get_cash_desk_content_linked_to_document').checked) {
					cb_cashback = 1;
				}


				let method = path_cash_desk + '.ioi_cash_desk_get_cash';

				frappe.call({  	method: method,
								args: { "name": cashdesk_id,
										"operation_type": 1,
										"doctype": doctype,
										"doctype_name": doctype_name,
										"document_value": document_value,
										"cash_value": cash_value,
										"remark": document.getElementById('get_cash_desk_content_remark').value,
										"is_cashback": cb_cashback,
										"operator_id" : operator_id},
								async: false,
								callback:function(r)	{

									if (r.message.error == 0) {

										if (fct_callback_action) {
											silicon_ioi.doctype.ioiSalesPOS.display_on_screen('GET_CASH', null, cash_value.toFixed(2));
											fct_callback_action(cash_value, cb_cashback);

										}

									}else
									{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
										frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
										raise;
									}
								}
				});

				d.hide();

			},
			secondary_action: function(){
				d.hide();
			}

		});

		if (document.getElementById('get_cash_desk_content')) {
			document.getElementById('get_cash_desk_content').remove();
		}

		if (document.getElementById('get_cash_desk_content_cash_value_label')) {
			document.getElementById('get_cash_desk_content_cash_value_label').remove();
		}

		if (document.getElementById('get_cash_desk_content_cash_value')) {
			document.getElementById('get_cash_desk_content_cash_value').remove();
		}


		if (document.getElementById('get_cash_desk_content_remark_label')) {
			document.getElementById('get_cash_desk_content_remark_label').remove();
		}


		if (document.getElementById('get_cash_desk_content_remark')) {
			document.getElementById('get_cash_desk_content_remark').remove();
		}

		if (document.getElementById('get_cash_desk_content_linked_to_document_label')) {
			document.getElementById('get_cash_desk_content_linked_to_document_label').remove();
		}

		if (document.getElementById('get_cash_desk_content_linked_to_document')) {
			document.getElementById('get_cash_desk_content_linked_to_document').remove();
		}



		let html = '';

		html += '<div id="get_cash_desk_content" style="overflow-x: auto;height:140px;">';
		html += '	<div style="position: relative; top: 0px; left: 0px; width:170px;">';
		html += '		<label id="get_cash_desk_content_cash_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Cash value") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 170px; height: 25px;"> ';
		html += '			<input id="get_cash_desk_content_cash_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 40px; left: 0px; width:320px;">';
		html += '		<label id="get_cash_desk_content_remark_label" style="position: absolute; top: 2px; left: 2px;z-index:5;">' + __("Remark") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 320px; height: 25px;"> ';
		html += '			<input id="get_cash_desk_content_remark" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 110px; left: 0px; width:320px;">';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 2px; width: 320px; height: 25px;"> ';
		html += '			<input id="get_cash_desk_content_linked_to_document" type="checkbox" class="input-with-feedback form-control bold" ';

		if (ioistatus != 0) {
			if (cur_frm) {

				if ((cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {
					html += '';
				}else{
					html += ' disabled ';
				}

			}else{
				html += ' disabled ';
			}
		}
		html += '> ';
		html += '		</div>';
		html += '		<label id="get_cash_desk_content_linked_to_document_label" style="position: absolute; top: 0px; left: 25px;z-index:5;">' + __("Cash back to the customer") + '</label>';
		html += '	</div>';

		html += '</div>';


		d.fields_dict.html_get_cash.$wrapper.html(html);
		d.$wrapper.find('.modal-dialog').css("width", "365px");
		d.show();

		silicon_ioi.doctype.ioiCashDesk.sleep_static(400).then(() => {

			let fct_key_down = function(event) {

				if (event.keyCode == 13) {

					if (techno == 'EPOSDEVICE') {
						if ((p_ipaddress.trim() != '') && (p_port != '') && (p_port != '0')) {
							let epson_print = new silicon_ioi.doctype.ioiEPosDevice(p_ipaddress, p_port, 500, 'DRAWER_PULSE', false, null, null);
						}
					}
				}

			}

			document.getElementById('get_cash_desk_content_cash_value').onkeydown = fct_key_down;

			silicon_ioi.doctype.ioiCashDesk.sleep_static(500).then(() => {

				document.getElementById('get_cash_desk_content_cash_value').focus();
			});

		});

	}

	static do_pay_put_cash(cashdesk_id, doctype, doctype_name, ioistatus, document_value, fct_callback_action = null, operator_id = '')
	{
		let techno = '';
		let p_ipaddress = '';
		let p_port = '';

		let method = path_cash_desk + '.ioi_cash_desk_get_printer_techno'

		frappe.call({  	method: method,
						args: {"name": cashdesk_id},
						async: false,
						callback:function(r)	{

							techno = r.message.printing_techno_id;
							p_ipaddress = r.message.printer_ip_address;
							p_port = r.message.printer_port;

						}
		});

		let title = __('Put cash');
		let bt_primary_caption = __('Put cash');

		let me = this;

		var d = new frappe.ui.Dialog({
			'title': title,
			'fields': [
				{'fieldname': 'html_put_cash', 'fieldtype': 'HTML'}

			],
			primary_action_label: bt_primary_caption,
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let cash_value = 0;

				if (document.getElementById('put_cash_desk_content_cash_value').value.trim() != '') {

					if (parseFloat(document.getElementById('put_cash_desk_content_cash_value').value) <= 0) {

						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
						frappe.msgprint({title: __("Message"), message: __("Cash value has to be greater than zero"), indicator: "red"});
						raise;
					}

					cash_value = parseFloat(document.getElementById('put_cash_desk_content_cash_value').value);
				}

				if (cash_value == 0) {
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					frappe.msgprint({title: __("Message"), message: __("Cash value has to be greater than zero"), indicator: "red"});
					raise;
				}

				if (document.getElementById('put_cash_desk_content_remark').value.trim() == '') {
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					frappe.msgprint({title: __("Message"), message: __("Remark is mandatory"), indicator: "red"});
					raise;
				}

				let cb_received = 0;

				if (document.getElementById('put_cash_desk_content_linked_to_document').checked) {
					cb_received = 1;
				}

				let method = path_cash_desk + '.ioi_cash_desk_put_cash';

				frappe.call({  	method: method,
								args: { "name": cashdesk_id,
										"operation_type": 1,
										"doctype": doctype,
										"doctype_name": doctype_name,
										"document_value": document_value,
										"cash_value": cash_value,
										"remark": document.getElementById('put_cash_desk_content_remark').value,
										"is_received" : cb_received,
										"operator_id": operator_id
									},
								async: false,
								callback:function(r)	{

									if (r.message.error == 0) {

										if (fct_callback_action) {

											silicon_ioi.doctype.ioiSalesPOS.display_on_screen('PUT_CASH', null, cash_value.toFixed(2));
											fct_callback_action(cash_value, cb_received);

										}

									}else
									{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
										frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
										raise;
									}
								}
				});

				d.hide();

			},
			secondary_action: function(){
				d.hide();
			}

		});

		if (document.getElementById('put_cash_desk_content')) {
			document.getElementById('put_cash_desk_content').remove();
		}

		if (document.getElementById('put_cash_desk_content_cash_value_label')) {
			document.getElementById('put_cash_desk_content_cash_value_label').remove();
		}

		if (document.getElementById('put_cash_desk_content_cash_value')) {
			document.getElementById('put_cash_desk_content_cash_value').remove();
		}


		if (document.getElementById('put_cash_desk_content_remark_label')) {
			document.getElementById('put_cash_desk_content_remark_label').remove();
		}

		if (document.getElementById('put_cash_desk_content_remark')) {
			document.getElementById('put_cash_desk_content_remark').remove();
		}

		if (document.getElementById('put_cash_desk_content_linked_to_document')) {
			document.getElementById('put_cash_desk_content_linked_to_document').remove();
		}

		if (document.getElementById('put_cash_desk_content_linked_to_document_label')) {
			document.getElementById('put_cash_desk_content_linked_to_document_label').remove();
		}





		let html = '';

		html += '<div id="put_cash_desk_content" style="overflow-x: auto;height:140px;">';

		html += '	<div style="position: relative; top: 0px; left: 0px; width:170px;">';
		html += '		<label id="put_cash_desk_content_cash_value_label" style="position: absolute; top: 6px; left: 2px;z-index:5;">' + __("Cash value") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 150px; width: 170px; height: 25px;"> ';
		html += '			<input id="put_cash_desk_content_cash_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 40px; left: 0px; width:320px;">';
		html += '		<label id="put_cash_desk_content_remark_label" style="position: absolute; top: 2px; left: 2px;z-index:5;">' + __("Remark") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 320px; height: 25px;"> ';
		html += '			<input id="put_cash_desk_content_remark" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 110px; left: 0px; width:320px;">';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 2px; width: 320px; height: 25px;"> ';
		html += '			<input id="put_cash_desk_content_linked_to_document" type="checkbox" class="input-with-feedback form-control bold" ';


		if (ioistatus != 0) {
			html += ' disabled ';
		}

		html += '		</div>';
		html += '		<label id="put_cash_desk_content_linked_to_document_label" style="position: absolute; top: 0px; left: 25px;z-index:5;">' + __("Received from the customer") + '</label>';
		html += '	</div>';

		html += '</div>';


		d.fields_dict.html_put_cash.$wrapper.html(html);
		d.$wrapper.find('.modal-dialog').css("width", "365px");
		d.show();

		silicon_ioi.doctype.ioiCashDesk.sleep_static(400).then(() => {


			let fct_key_down = function(event) {

				if (event.keyCode == 13) {

					if (techno == 'EPOSDEVICE') {
						if ((p_ipaddress.trim() != '') && (p_port != '') && (p_port != '0')) {
							let epson_print = new silicon_ioi.doctype.ioiEPosDevice(p_ipaddress, p_port, 500, 'DRAWER_PULSE', false, null, null);
						}
					}
				}

			}
			document.getElementById('put_cash_desk_content_cash_value').onkeydown = fct_key_down;

			silicon_ioi.doctype.ioiCashDesk.sleep_static(500).then(() => {

				document.getElementById('put_cash_desk_content_cash_value').focus();
			});

		});

	}

	static verifify_pending_payment(doctype, docname, ignore_cash_desk_movement_registered){
		let is_pending_transaction = false;

		frappe.call({
			method: "silicon_ioi.ioi_system.doctype.ioi_payment_transaction.ioi_payment_transaction.is_pending_payment_transactions",
			args: {
				doctype : doctype,
				docname : docname,
				ignore_cash_desk_movement_registered : ignore_cash_desk_movement_registered
			},
			async: false,
			callback: (r) => {
				is_pending_transaction = r.message;
			}
		});
		return is_pending_transaction
	}


	static do_pay_now(cashdesk_id, doctype, doctype_name, site_id, division_id, currency_id, currency_rate, currency_inv_rate, document_value, par_total_still_to_pay, cashdesk_check_id_required,
					  cashdesk_check_spec_1_id_required, cashdesk_check_spec_1_description, cashdesk_check_spec_2_id_required, cashdesk_check_spec_2_description, cashdesk_check_spec_3_id_required,
					  cashdesk_check_spec_3_description, cashdesk_cash_value_enabled, cashdesk_bank_card_value_enabled, cashdesk_credit_card_value_enabled, cashdesk_check_value_enabled,
					  cashdesk_emoney_value_enabled, cashdesk_check_spec_1_value_enabled, cashdesk_check_spec_2_value_enabled, cashdesk_check_spec_3_value_enabled, cashdesk_check_spec_1_electronic_transac,
					  cashdesk_check_spec_2_electronic_transac, cashdesk_check_spec_3_electronic_transac, fct_callback_action, operator_id = '',
					  cashdesk_payconiq_enabled = 0, cashdesk_ccv_enabled = 0, cashdesk_check_spec_1_type = 0, cashdesk_check_spec_2_type = 0,cashdesk_check_spec_3_type = 0)
	{

		silicon_ioi.doctype.ioiCashDesk.clear_pending_payment();

		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_type = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_subtype = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status = 0;


		let techno = '';
		let p_ipaddress = '';
		let p_port = '';


		let method = path_cash_desk + '.ioi_cash_desk_get_printer_techno'

		frappe.call({  	method: method,
						args: {"name": cashdesk_id},
						async: false,
						callback:function(r)	{

							techno = r.message.printing_techno_id;
							p_ipaddress = r.message.printer_ip_address;
							p_port = r.message.printer_port;

						}
		});


		let cashdesk_cash_value = 0;
		let max_cash_back = 0;
		let apply_cash_rounding = 0;

		method = path_cash_desk + '.ioi_cash_desk_get_info';

		frappe.call({  	method: method,
						args: {"name": cashdesk_id},
						async: false,
						callback:function(r)	{

							if (r.message.length != 0) {
								cashdesk_cash_value = r.message[0].cash_value;
								max_cash_back = r.message[0].max_cash_back;
								apply_cash_rounding = r.message[0].apply_cash_rounding;
							}
						}
		});


		let max_spec1_amount = 0;
		let max_spec2_amount = 0;
		let max_spec3_amount = 0;


		method = path_cash_desk + '.ioi_cash_desk_get_max_amount_for_check_specs';

		frappe.call({  	method: method,
			args: {"doctype": doctype, "name": doctype_name},
			async: false,
			callback:function(r)	{

				if (r.message) {
					if (r.message.length > 0) {

						max_spec1_amount = r.message[0].spec1_max;
						max_spec2_amount = r.message[0].spec2_max;
						max_spec3_amount = r.message[0].spec3_max;

					}
				}
			}
		});


		let me = this;

		let title = __('Pending payment') + ' : ';

		let color = '#60A367';

		let exp = Math.pow(10, 2);


		if (Math.round(parseFloat(par_total_still_to_pay) * exp) / exp > 0) {
			color = '#ED514C';
		}


		title += '<font color="' + color + '">' + parseFloat(par_total_still_to_pay).toFixed(2).toString() + ' ' + currency_id + '</font>';


		let still_to_pay_decimal_part = parseFloat(parseFloat(par_total_still_to_pay).toFixed(2) % 1).toFixed(2)

		let last_dec = parseInt(parseFloat(still_to_pay_decimal_part).toFixed(2) * 100);

		let cash_rounding = 0;

		if ((last_dec == 0) || (last_dec == 5)) {
			cash_rounding = 0;
		}else if ((last_dec == 1) || (last_dec == 6)) {
			cash_rounding = -0.01;
		}else if ((last_dec == 2) || (last_dec == 7)) {
			cash_rounding = -0.02;
		}else if ((last_dec == 3) || (last_dec == 8)) {
			cash_rounding = +0.02;
		}else if ((last_dec == 4) || (last_dec == 9)) {
			cash_rounding = +0.01;
		}


		if (apply_cash_rounding == 0) {
			cash_rounding = 0;
		}


		if (document.getElementById('pay_cash_desk_payconic_div')) {
			document.getElementById('pay_cash_desk_payconic_div').remove();
		}

		if (document.getElementById('pay_cash_desk_payconic_button')) {
			document.getElementById('pay_cash_desk_payconic_button').remove();
		}


		if (document.getElementById('pay_cash_desk_ccv_div')) {
			document.getElementById('pay_cash_desk_ccv_div').remove();
		}


		if (document.getElementById('pay_cash_desk_ccv_button')) {
			document.getElementById('pay_cash_desk_ccv_button').remove();
		}


		let title_bar = '';

		title_bar += '<div style="width:100%; height:50px;">';

		// Title
		title_bar += '	<div style="position: relative; top: 12px; left: 0px; width:320px;">';
		title_bar += '		<label style="position: absolute; top: 0px; left: 2px;">' + title + '</label>';
		title_bar += '	</div>';

		let logo_left = 320;
		let logo_top = 0;

		if (cashdesk_payconiq_enabled == 1) {

			// Payconiq
			title_bar += '	<div id="pay_cash_desk_payconic_div" style="position: relative; top: ' + logo_top.toString() + 'px; left: ' + logo_left.toString() + 'px; height:55px; width:110px; border: 1px solid #E8EAEB; border-radius: 6px; padding: 4px; display: auto;">';
			title_bar += '		<img id="pay_cash_desk_payconic_button" title="' + __("Payconiq") + '" src="/assets/silicon_ioi/images/buttons/payconiq.svg" width="100px" height="50px" style="position:absolute;top:0px; left:0px;width:110px;height:50px"></img>';
			title_bar += '	</div>';

			logo_left += 120;
			logo_top -= 55;
		}

		if (cashdesk_ccv_enabled == 1) {

			// CCV
			title_bar += '	<div id="pay_cash_desk_ccv_div" style="position: relative; top: ' + logo_top.toString() + 'px; left: ' + logo_left.toString() + 'px; height:55px; width:110px; border: 1px solid #E8EAEB; border-radius: 6px; padding: 4px; display: auto;">';
			title_bar += '		<img id="pay_cash_desk_ccv_button" title="' + __("CCV") + '" src="/assets/silicon_ioi/images/buttons/ccv.png" width="80px" height="50px" style="position:absolute;top:0px; left:15px;width:80px;height:50px"></img>';
			title_bar += '	</div>';
		}



		title_bar += '</div>';

		let bt_primary_caption = __('Pay now');

		var d = new frappe.ui.Dialog({
			'title': title_bar,
			'static': true,
			'fields': [
				{'fieldname': 'html_pay_now', 'fieldtype': 'HTML'}

			],
			primary_action_label: bt_primary_caption,
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				if (parseFloat(document.getElementById('pay_still_to_pay').innerText) < 0) {

					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					frappe.msgprint({title: __("Message"), message: __("Still to pay has to be greater than or equal to zero"), indicator: "red"});
					raise;
				}

				if (document.getElementById('pay_cash_desk_content_check_id')) {

					if (cashdesk_check_id_required == 1) {

						if (document.getElementById('pay_cash_desk_content_check_id').value.trim() == '') {

							if ((document.getElementById('pay_cash_desk_content_check_value').value != '') && (document.getElementById('pay_cash_desk_content_check_value').value != 0)) {
								document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
								frappe.msgprint({title: __("Message"), message: __("Check identification is required"), indicator: "red"});
								raise;
							}
						}
					}
				}

				if (document.getElementById('pay_cash_desk_content_check_spec_1_id')) {

					if (cashdesk_check_spec_1_id_required == 1) {

						if (document.getElementById('pay_cash_desk_content_check_spec_1_id').value.trim() == '') {


							if ((document.getElementById('pay_cash_desk_content_check_spec_1_value').value != '') && (document.getElementById('pay_cash_desk_content_check_spec_1_value').value != 0)) {
								document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;

								if (cashdesk_check_spec_1_description != '') {
									frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_1_description) + ' ' +  __("identification is required"), indicator: "red"});
								}else{
									frappe.msgprint({title: __("Message"), message: __("Check spec 1 identification is required"), indicator: "red"});
								}
								raise;
							}
						}
					}
				}


				if (document.getElementById('pay_cash_desk_content_check_spec_2_id')) {

					if (cashdesk_check_spec_2_id_required == 1) {

						if (document.getElementById('pay_cash_desk_content_check_spec_2_id').value.trim() == '') {


							if ((document.getElementById('pay_cash_desk_content_check_spec_2_value').value != '') && (document.getElementById('pay_cash_desk_content_check_spec_2_value').value != 0)) {
								document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;

								if (cashdesk_check_spec_2_description != '') {
									frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_2_description) + ' ' +  __("identification is required"), indicator: "red"});
								}else{
									frappe.msgprint({title: __("Message"), message: __("Check spec 2 identification is required"), indicator: "red"});
								}
								raise;
							}
						}
					}
				}

				if (document.getElementById('pay_cash_desk_content_check_spec_3_id')) {

					if (cashdesk_check_spec_3_id_required == 1) {

						if (document.getElementById('pay_cash_desk_content_check_spec_3_id').value.trim() == '') {


							if ((document.getElementById('pay_cash_desk_content_check_spec_3_value').value != '') && (document.getElementById('pay_cash_desk_content_check_spec_3_value').value != 0)) {
								document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;

								if (cashdesk_check_spec_3_description != '') {
									frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_3_description) + ' ' +  __("identification is required"), indicator: "red"});
								}else{
									frappe.msgprint({title: __("Message"), message: __("Check spec 3 identification is required"), indicator: "red"});
								}
								raise;
							}
						}
					}
				}

				let do_save = false;

				if (document.getElementById('pay_cash_desk_content_cash_value')) {
					if ((document.getElementById('pay_cash_desk_content_cash_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_cash_value').value != 0)) {
						do_save = true;
					}
				}

				if (!do_save) {

					if (document.getElementById('pay_cash_desk_content_bank_card_value')) {
						if ((document.getElementById('pay_cash_desk_content_bank_card_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_bank_card_value').value != 0)) {
							do_save = true;
						}
					}
				}

				if (!do_save) {

					if (document.getElementById('pay_cash_desk_content_credit_card_value')) {
						if ((document.getElementById('pay_cash_desk_content_credit_card_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_credit_card_value').value != 0)) {
							do_save = true;
						}
					}
				}

				if (!do_save) {

					if (document.getElementById('pay_cash_desk_content_check_value')) {
						if ((document.getElementById('pay_cash_desk_content_check_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_check_value').value != 0)) {
							do_save = true;
						}
					}
				}

				if (!do_save) {

					if (document.getElementById('pay_cash_desk_content_emoney_value')) {
						if ((document.getElementById('pay_cash_desk_content_emoney_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_emoney_value').value != 0)) {
							do_save = true;
						}
					}
				}


				if (!do_save) {

					if (document.getElementById('pay_cash_desk_content_check_spec_1_value')) {
						if ((document.getElementById('pay_cash_desk_content_check_spec_1_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_check_spec_1_value').value != 0)) {
							do_save = true;
						}
					}
				}


				if (!do_save) {

					if (document.getElementById('pay_cash_desk_content_check_spec_2_value')) {
						if ((document.getElementById('pay_cash_desk_content_check_spec_2_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_check_spec_2_value').value != 0)) {
							do_save = true;
						}
					}
				}


				if (!do_save) {

					if (document.getElementById('pay_cash_desk_content_check_spec_3_value')) {
						if ((document.getElementById('pay_cash_desk_content_check_spec_3_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_check_spec_3_value').value != 0)) {
							do_save = true;
						}
					}
				}

				if (!do_save) {

					if (document.getElementById('pay_cash_desk_content_cash_back_value')) {

						if ((document.getElementById('pay_cash_desk_content_cash_back_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_cash_back_value').value != 0)) {
							do_save = true;
						}
					}
				}


				if (!do_save) {

					if (document.getElementById('pay_cash_desk_content_bank_card_back_value')) {

						if ((document.getElementById('pay_cash_desk_content_bank_card_back_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_bank_card_back_value').value != 0)) {
							do_save = true;
						}
					}
				}


				if (!do_save) {

					if (document.getElementById('pay_cash_desk_content_emoney_back_value')) {

						if ((document.getElementById('pay_cash_desk_content_emoney_back_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_emoney_back_value').value != 0)) {
							do_save = true;
						}
					}
				}


				if (!do_save) {
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					frappe.msgprint({title: __("Message"), message: __("Fill an amount, please"), indicator: "red"});
					raise;

				}


				let cash_value = 0;
				let bank_card_value = 0;
				let bank_transaction_id = '';
				let bank_transaction_detail = '';
				let credit_card_value = 0;
				let credit_transaction_id = '';
				let credit_transaction_detail = '';
				let check_value = 0;
				let check_id = '';
				let emoney_value = 0;
				let check_spec_1_value = 0;
				let check_spec_1_description = '';
				let emoney_transaction_id = '';
				let emoney_transaction_detail = '';
				let check_spec_1_id = '';
				let spec1_transaction_id = '';
				let spec1_transaction_detail = '';
				let check_spec_2_value = 0;
				let check_spec_2_description = '';
				let check_spec_2_id = '';
				let spec2_transaction_id = '';
				let spec2_transaction_detail = '';

				let check_spec_3_value = 0;
				let check_spec_3_description = '';
				let check_spec_3_id = '';
				let spec3_transaction_id = '';
				let spec3_transaction_detail = '';
				let total_amount = 0;

				let status_tansaction = 0;
				let error_bank_value = 0;

				let error_credit_value = 0;
				let error_check_value = 0;
				let error_emoney_value = 0;


				let error_spec1_value = 0;
				let error_spec2_value = 0;
				let error_spec3_value = 0;





				if (document.getElementById('pay_cash_desk_content_cash_value')) {
					if ((document.getElementById('pay_cash_desk_content_cash_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_cash_value').value != 0)) {

						if (parseFloat(document.getElementById('pay_cash_desk_content_cash_value').value) < 0) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Cash value has to be greater than or equal to zero"), indicator: "red"});
							raise;

						}

						cash_value = parseFloat(document.getElementById('pay_cash_desk_content_cash_value').value);
						total_amount += parseFloat(document.getElementById('pay_cash_desk_content_cash_value').value);
					}
				}


				if (document.getElementById('pay_cash_desk_content_bank_card_value')) {
					if ((document.getElementById('pay_cash_desk_content_bank_card_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_bank_card_value').value != 0)) {

						if (parseFloat(document.getElementById('pay_cash_desk_content_bank_card_value').value) <= 0) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Bank card value has to be greater than zero"), indicator: "red"});
							raise;
						}

						bank_card_value = parseFloat(document.getElementById('pay_cash_desk_content_bank_card_value').value);
						total_amount += parseFloat(document.getElementById('pay_cash_desk_content_bank_card_value').value);
					}
				}

				if (document.getElementById('pay_cash_desk_content_bank_card_transaction_id')) {
					bank_transaction_id = document.getElementById('pay_cash_desk_content_bank_card_transaction_id').value;
				}

				if (document.getElementById('pay_cash_desk_content_bank_card_transaction_detail')) {
					bank_transaction_detail = document.getElementById('pay_cash_desk_content_bank_card_transaction_detail').value;
				}

				if (bank_transaction_id.trim() == '') {

					if ((cashdesk_payconiq_enabled == 1) || (cashdesk_ccv_enabled == 1)) {

						if (parseFloat(bank_card_value) > 0) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Bank transaction is mandatory"), indicator: "red"});
							raise;
						}
					}
				}



				if (document.getElementById('pay_cash_desk_content_credit_card_value')) {
					if ((document.getElementById('pay_cash_desk_content_credit_card_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_credit_card_value').value != 0)) {

						if (parseFloat(document.getElementById('pay_cash_desk_content_credit_card_value').value) <= 0) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Credit card value has to be greater than zero"), indicator: "red"});
							raise;
						}

						credit_card_value = parseFloat(document.getElementById('pay_cash_desk_content_credit_card_value').value);
						total_amount += parseFloat(document.getElementById('pay_cash_desk_content_credit_card_value').value);
					}
				}

				if (document.getElementById('pay_cash_desk_content_credit_card_transaction_id')) {
					credit_transaction_id = document.getElementById('pay_cash_desk_content_credit_card_transaction_id').value;
				}

				if (document.getElementById('pay_cash_desk_content_credit_card_transaction_detail')) {
					credit_transaction_detail = document.getElementById('pay_cash_desk_content_credit_card_transaction_detail').value;
				}

				if (credit_transaction_id.trim() == '') {

					if ((cashdesk_payconiq_enabled == 1) || (cashdesk_ccv_enabled == 1)) {

						if (parseFloat(credit_card_value) > 0) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Credit transaction is mandatory"), indicator: "red"});
							raise;
						}
					}
				}


				if (document.getElementById('pay_cash_desk_content_check_value')) {
					if ((document.getElementById('pay_cash_desk_content_check_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_check_value').value != 0)) {

						if (parseFloat(document.getElementById('pay_cash_desk_content_check_value').value) < 0) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Check value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						check_value = parseFloat(document.getElementById('pay_cash_desk_content_check_value').value);
						total_amount += parseFloat(document.getElementById('pay_cash_desk_content_check_value').value);
					}
				}

				if (document.getElementById('pay_cash_desk_content_check_id')) {
					check_id = document.getElementById('pay_cash_desk_content_check_id').value;
				}

				if (check_id != '') {

					if (check_value == 0) {
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
						frappe.msgprint({title: __("Message"), message: __("Check value has to be filled"), indicator: "red"});
						raise;
					}
				}



				if (document.getElementById('pay_cash_desk_content_emoney_value')) {
					if ((document.getElementById('pay_cash_desk_content_emoney_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_emoney_value').value != 0)) {

						if (parseFloat(document.getElementById('pay_cash_desk_content_emoney_value').value) < 0) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Emoney value has to be greater than zero"), indicator: "red"});
							raise;
						}

						emoney_value = parseFloat(document.getElementById('pay_cash_desk_content_emoney_value').value);
						total_amount += parseFloat(document.getElementById('pay_cash_desk_content_emoney_value').value);
					}
				}


				if (document.getElementById('pay_cash_desk_content_emoney_transaction_id')) {
					emoney_transaction_id = document.getElementById('pay_cash_desk_content_emoney_transaction_id').value;
				}

				if (document.getElementById('pay_cash_desk_content_credit_card_transaction_detail')) {
					emoney_transaction_detail = document.getElementById('pay_cash_desk_content_credit_card_transaction_detail').value;
				}


				if (document.getElementById('pay_cash_desk_content_check_spec_1_value')) {
					if ((document.getElementById('pay_cash_desk_content_check_spec_1_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_check_spec_1_value').value != 0)) {

						if (parseFloat(document.getElementById('pay_cash_desk_content_check_spec_1_value').value) < 0) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;

							if (cashdesk_check_spec_1_description != '') {
								frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_1_description) + ' ' +  __("has to be greater than or equal to zero"), indicator: "red"});
							}else{
								frappe.msgprint({title: __("Message"), message: __("Check spec 1 has to be greater than or equal to zero"), indicator: "red"});
							}

							raise;
						}

						check_spec_1_value = parseFloat(document.getElementById('pay_cash_desk_content_check_spec_1_value').value);

						let already_check_spec_1_value = 0

						if (document.getElementById('pay_cash_desk_content_already_check_spec_1_value').value) {
							already_check_spec_1_value = parseFloat(document.getElementById('pay_cash_desk_content_already_check_spec_1_value').value)
						}

						if ((parseFloat(check_spec_1_value) + parseFloat(already_check_spec_1_value)) > parseFloat(max_spec1_amount)) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_1_description) + " " + __("can not be greater than") + " " +  parseFloat(max_spec1_amount).toFixed(2), indicator: "red"});
							raise;
						}


						total_amount += parseFloat(document.getElementById('pay_cash_desk_content_check_spec_1_value').value);

						if ((cashdesk_check_spec_1_electronic_transac)&&
							(document.getElementById('pay_cash_desk_content_check_spec_1_transaction_id').value.trim() == '')) {

							if (parseFloat(check_spec_1_value) > 0) {
								document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
								frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_1_description)+" "+__("transaction is mandatory (by CCV)"), indicator: "red"});
								raise;
							}
						}
					}
				}

				if (cashdesk_check_spec_1_description == '') {
					check_spec_1_description = __('Check spec 1 value');
				}

				if (document.getElementById('pay_cash_desk_content_check_spec_1_id')) {
					check_spec_1_id = document.getElementById('pay_cash_desk_content_check_spec_1_id').value;
				}

				if (check_spec_1_id != '') {

					if (check_spec_1_value == 0) {

						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;

						if (cashdesk_check_spec_1_description != '') {
							frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_1_description) + ' ' +  __("value has to be filled"), indicator: "red"});
						}else{
							frappe.msgprint({title: __("Message"), message: __("Check spec 1 value has to be filled"), indicator: "red"});
						}
						raise;
					}
				}

				if (document.getElementById('pay_cash_desk_content_check_spec_1_transaction_id')) {
					spec1_transaction_id = document.getElementById('pay_cash_desk_content_check_spec_1_transaction_id').value;
				}

				if (document.getElementById('pay_cash_desk_content_check_spec_1_transaction_detail')) {
					spec1_transaction_detail = document.getElementById('pay_cash_desk_content_check_spec_1_transaction_detail').value;
				}



				if (document.getElementById('pay_cash_desk_content_check_spec_2_value')) {
					if ((document.getElementById('pay_cash_desk_content_check_spec_2_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_check_spec_2_value').value != 0)) {

						if (parseFloat(document.getElementById('pay_cash_desk_content_check_spec_2_value').value) < 0) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;

							if (cashdesk_check_spec_2_description != '') {
								frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_2_description) + ' ' +  __("has to be greater than or equal to zero"), indicator: "red"});
							}else{
								frappe.msgprint({title: __("Message"), message: __("Check spec 2 has to be greater than or equal to zero"), indicator: "red"});
							}

							raise;
						}

						check_spec_2_value = parseFloat(document.getElementById('pay_cash_desk_content_check_spec_2_value').value);

						let already_check_spec_2_value = 0;

						if (document.getElementById('pay_cash_desk_content_already_check_spec_2_value').value) {
							already_check_spec_2_value = parseFloat(document.getElementById('pay_cash_desk_content_already_check_spec_2_value').value)
						}

						if ((parseFloat(check_spec_2_value) + parseFloat(already_check_spec_2_value)) > parseFloat(max_spec2_amount)) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_2_description) + " " + __("can not be greater than") + " " +  parseFloat(max_spec2_amount).toFixed(2), indicator: "red"});
							raise;
						}

						total_amount += parseFloat(document.getElementById('pay_cash_desk_content_check_spec_2_value').value);

						if ((cashdesk_check_spec_2_electronic_transac)&&
							(document.getElementById('pay_cash_desk_content_check_spec_2_transaction_id').value.trim() == '')) {

							if (parseFloat(check_spec_2_value) > 0) {
								document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
								frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_2_description)+" "+__("transaction is mandatory (by CCV)"), indicator: "red"});
								raise;
							}
						}
					}
				}


				if (cashdesk_check_spec_2_description == '') {
					check_spec_2_description = __('Check spec 2 value');
				}



				if (document.getElementById('pay_cash_desk_content_check_spec_2_id')) {
					check_spec_2_id = document.getElementById('pay_cash_desk_content_check_spec_2_id').value;
				}


				if (check_spec_2_id != '') {

					if (check_spec_2_value == 0) {

						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;

						if (cashdesk_check_spec_2_description != '') {
							frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_2_description) + ' ' +  __("value has to be filled"), indicator: "red"});
						}else{
							frappe.msgprint({title: __("Message"), message: __("Check spec 2 value has to be filled"), indicator: "red"});
						}
						raise;
					}
				}

				if (document.getElementById('pay_cash_desk_content_check_spec_2_transaction_id')) {
					spec2_transaction_id = document.getElementById('pay_cash_desk_content_check_spec_2_transaction_id').value;
				}

				if (document.getElementById('pay_cash_desk_content_check_spec_2_transaction_detail')) {
					spec2_transaction_detail = document.getElementById('pay_cash_desk_content_check_spec_2_transaction_detail').value;
				}


				if (document.getElementById('pay_cash_desk_content_check_spec_3_value')) {
					if ((document.getElementById('pay_cash_desk_content_check_spec_3_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_check_spec_3_value').value != 0)) {

						if (parseFloat(document.getElementById('pay_cash_desk_content_check_spec_3_value').value) < 0) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;

							if (cashdesk_check_spec_3_description != '') {
								frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_3_description) + ' ' +  __("has to be greater than or equal to zero"), indicator: "red"});
							}else{
								frappe.msgprint({title: __("Message"), message: __("Check spec 3 has to be greater than or equal to zero"), indicator: "red"});
							}

							raise;
						}

						check_spec_3_value = parseFloat(document.getElementById('pay_cash_desk_content_check_spec_3_value').value);


						let already_check_spec_3_value = 0;

						if (document.getElementById('pay_cash_desk_content_already_check_spec_3_value').value) {
							already_check_spec_3_value = parseFloat(document.getElementById('pay_cash_desk_content_already_check_spec_3_value').value)
						}

						if ((parseFloat(check_spec_3_value) + parseFloat(already_check_spec_3_value)) > parseFloat(max_spec3_amount)) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_3_description) + " " + __("can not be greater than") + " " +  parseFloat(max_spec3_amount).toFixed(2), indicator: "red"});
							raise;
						}

						total_amount += parseFloat(document.getElementById('pay_cash_desk_content_check_spec_3_value').value);

						if ((cashdesk_check_spec_3_electronic_transac)&&
							(document.getElementById('pay_cash_desk_content_check_spec_3_transaction_id').value.trim() == '')) {

							if (parseFloat(check_spec_3_value) > 0) {
								document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
								frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_3_description)+" "+__("transaction is mandatory (by CCV)"), indicator: "red"});
								raise;
							}
						}
					}
				}




				if (cashdesk_check_spec_3_description == '') {
					check_spec_3_description = __('Check spec 3 value');
				}



				if (document.getElementById('pay_cash_desk_content_check_spec_3_id')) {
					check_spec_3_id = document.getElementById('pay_cash_desk_content_check_spec_3_id').value;
				}

				if (check_spec_3_id != '') {

					if (check_spec_3_value == 0) {

						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;

						if (cashdesk_check_spec_3_description != '') {
							frappe.msgprint({title: __("Message"), message: __(cashdesk_check_spec_3_description) + ' ' +  __("value has to be filled"), indicator: "red"});
						}else{
							frappe.msgprint({title: __("Message"), message: __("Check spec 3 value has to be filled"), indicator: "red"});
						}
						raise;
					}
				}

				if (document.getElementById('pay_cash_desk_content_check_spec_3_transaction_id')) {
					spec3_transaction_id = document.getElementById('pay_cash_desk_content_check_spec_3_transaction_id').value;
				}

				if (document.getElementById('pay_cash_desk_content_check_spec_3_transaction_detail')) {
					spec3_transaction_detail = document.getElementById('pay_cash_desk_content_check_spec_3_transaction_detail').value;
				}


				// Cash back
				let cash_back_value = 0;

				if (document.getElementById('pay_cash_desk_content_cash_back_value')) {

					if ((document.getElementById('pay_cash_desk_content_cash_back_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_cash_back_value').value != 0)) {

						if (parseFloat(document.getElementById('pay_cash_desk_content_cash_back_value').value) < 0) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Cash back value has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						cash_back_value = parseFloat(document.getElementById('pay_cash_desk_content_cash_back_value').value);
					}
				}

				let bank_card_back_value = 0;

				if (document.getElementById('pay_cash_desk_content_bank_card_back_value')) {

					if ((document.getElementById('pay_cash_desk_content_bank_card_back_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_bank_card_back_value').value != 0)) {


						if (parseFloat(document.getElementById('pay_cash_desk_content_bank_card_back_value').value) < 0) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Cash back value (bank) has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						bank_card_back_value = parseFloat(document.getElementById('pay_cash_desk_content_bank_card_back_value').value);
					}
				}


				let emoney_back_value = 0;

				if (document.getElementById('pay_cash_desk_content_emoney_back_value')) {

					if ((document.getElementById('pay_cash_desk_content_emoney_back_value').value.trim() != '') && (document.getElementById('pay_cash_desk_content_emoney_back_value').value != 0)) {

						if (parseFloat(document.getElementById('pay_cash_desk_content_emoney_back_value').value) < 0) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __("Cash back value (Emoney) has to be greater than or equal to zero"), indicator: "red"});
							raise;
						}

						emoney_back_value = parseFloat(document.getElementById('pay_cash_desk_content_emoney_back_value').value);
					}
				}

				// Check if enough in cashdesk
				if ((document.getElementById('pay_cash_desk_content_cash_back_value')) || (document.getElementById('pay_cash_desk_content_bank_card_back_value')) || (document.getElementById('pay_cash_desk_content_emoney_back_value'))) {

					if (document.getElementById('pay_cash_desk_content_cash_back_value')) {

						if (parseFloat(document.getElementById('pay_cash_desk_content_cash_back_value').value) > 0) {

							if (parseFloat(document.getElementById('pay_cash_desk_content_cash_back_value').value) > cashdesk_cash_value) {
								document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
								frappe.msgprint({title: __("Message"), message: __("not enough cash value in the cash desk"), indicator: "red"});
								raise;
							}
						}
					}

					if (document.getElementById('pay_cash_desk_content_bank_card_back_value')) {

						if (parseFloat(document.getElementById('pay_cash_desk_content_bank_card_back_value').value) > 0) {

							if (parseFloat(document.getElementById('pay_cash_desk_content_bank_card_back_value').value) > cashdesk_cash_value) {
								document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
								frappe.msgprint({title: __("Message"), message: __("not enough cash value in the cash desk"), indicator: "red"});
								raise;
							}

							if (parseFloat(max_cash_back) != 0) {

								if (parseFloat(document.getElementById('pay_cash_desk_content_bank_card_back_value').value) > parseFloat(max_cash_back)) {
									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
									frappe.msgprint({title: __("Message"), message: __("Cash back (bank) is limited to") + ' ' + max_cash_back.toString() + ' ' + currency_id , indicator: "red"});
									raise;
								}
							}

						}
					}

					if (document.getElementById('pay_cash_desk_content_emoney_back_value')) {

						if (parseFloat(document.getElementById('pay_cash_desk_content_emoney_back_value').value) > 0) {

							if (parseFloat(document.getElementById('pay_cash_desk_content_emoney_back_value').value) > cashdesk_cash_value) {
								document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
								frappe.msgprint({title: __("Message"), message: __("not enough cash value in the cash desk"), indicator: "red"});
								raise;
							}
						}

						if (parseFloat(max_cash_back) != 0) {

							if (parseFloat(document.getElementById('pay_cash_desk_content_emoney_back_value').value) > parseFloat(max_cash_back)) {
								document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
								frappe.msgprint({title: __("Message"), message: __("Cash back (emoney) is limited to") + ' ' + max_cash_back.toString() + ' ' + currency_id , indicator: "red"});
								raise;
							}
						}
					}
				}

				if (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_type == 'BANK') {

					//if (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_subtype == 'PAYCONIQ') {

						if (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status != 0) {

							status_tansaction = silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status;
							bank_card_value = 0;
							bank_card_back_value = 0;
							error_bank_value = document.getElementById('pay_cash_desk_content_bank_card_value').value;

						}

					//}

				} else if (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_type == 'CREDIT') {
						if (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status != 0) {
							status_tansaction = silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status;
							credit_card_value = 0;
							error_credit_value = document.getElementById('pay_cash_desk_content_credit_card_value').value;
						}
				}else if (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_type == 'SPEC1') {
					if (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status != 0) {
						status_tansaction = silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status;
						check_spec_1_value = 0;
						error_spec1_value = document.getElementById('pay_cash_desk_content_check_spec_1_value').value;
					}
				}else if (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_type == 'SPEC2') {
					if (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status != 0) {
						status_tansaction = silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status;
						check_spec_2_value = 0;
						error_spec2_value = document.getElementById('pay_cash_desk_content_check_spec_2_value').value;
					}
				}else if (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_type == 'SPEC3') {
					if (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status != 0) {
						status_tansaction = silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status;
						check_spec_3_value = 0;
						error_spec3_value = document.getElementById('pay_cash_desk_content_check_spec_3_value').value;
					}
				}

				let method = path_cash_desk_movement + '.ioi_cash_desk_movement_generate_payments';

				frappe.call({  	method: method,
								args: {	"cashdesk_id": cashdesk_id,
										"doctype": doctype,
										"doctype_name": doctype_name,
										"site_id": site_id,
										"division_id": division_id,
										"currency_id": currency_id,
										"currency_rate": currency_rate,
										"currency_inv_rate": currency_inv_rate,
										"document_value" : document_value,
										"cash_value": cash_value,
										"bank_card_value": bank_card_value,
										"bank_transaction_id": bank_transaction_id,
										"bank_transaction_detail": bank_transaction_detail,
										"credit_card_value": credit_card_value,
										"credit_transaction_id": credit_transaction_id,
										"credit_transaction_detail": credit_transaction_detail,
										"check_value": check_value,
										"check_id": check_id,
										"emoney_value": emoney_value,
										"emoney_transaction_id": emoney_transaction_id,
										"emoney_transaction_detail": emoney_transaction_detail,
										"check_spec_1_value": check_spec_1_value,
										"check_spec_1_description": check_spec_1_description,
										"check_spec_1_id": check_spec_1_id,
										"spec1_transaction_id": spec1_transaction_id,
										"spec1_transaction_detail": spec1_transaction_detail,
										"check_spec_2_value": check_spec_2_value,
										"check_spec_2_description": check_spec_2_description,
										"check_spec_2_id": check_spec_2_id,
										"spec2_transaction_id": spec2_transaction_id,
										"spec2_transaction_detail": spec2_transaction_detail,
										"check_spec_3_value": check_spec_3_value,
										"check_spec_3_description": check_spec_3_description,
										"check_spec_3_id": check_spec_3_id,
										"spec3_transaction_id": spec3_transaction_id,
										"spec3_transaction_detail": spec3_transaction_detail,
										"cash_back_value" : cash_back_value,
										"bank_card_back_value": bank_card_back_value,
										"emoney_back_value": emoney_back_value,
										"operator_id": operator_id,
										"status" : status_tansaction,
										"error_bank_value": error_bank_value,
										"error_credit_value": error_credit_value,
										"error_check_value": error_check_value,
										"error_emoney_value": error_emoney_value,
										"error_spec1_value": error_spec1_value,
										"error_spec2_value": error_spec2_value,
										"error_spec3_value": error_spec3_value,
										"cash_rounding": cash_rounding

									},
								async: false,
								callback:function(r)	{

									if (r.message.error == 0) {

										if (fct_callback_action) {

											fct_callback_action();

										}
										let tmp_pay_type = silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_type;

										if ( (tmp_pay_type == 'BANK') ||
											 (tmp_pay_type == 'CREDIT') ||
											 ((tmp_pay_type == 'SPEC1') && (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_id)) ||
											 ((tmp_pay_type == 'SPEC2') && (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_id)) ||
											 ((tmp_pay_type == 'SPEC3') && (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_id))
										) {

											if (['PAYCONIQ','CCV'].includes(silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_subtype)) {

												if (silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status == 0) {

													let method = path_cash_desk_movement + '.ioi_cash_desk_movement_validate_payment_transaction';
													let transaction_id;
													switch(tmp_pay_type){
														case 'BANK':
															transaction_id = silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_id;
															break;
														case 'CREDIT':
															transaction_id = silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_id;
															break;
														case 'SPEC1':
															transaction_id = silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_id;
															break;
														case 'SPEC2':
															transaction_id = silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_id;
															break;
														case 'SPEC3':
															transaction_id = silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_id;
															break;

													}
													if (transaction_id){
														frappe.call({  	method: method,
																		args: {	"transaction_id": transaction_id
																		},
																		async: false,
																		callback:function(r)	{
																		}
														});
													}else{
														console.error("Validate transaction error : Missing transaction ID")
													}


												}

											}

										}


										silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_type = '';
										silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_subtype = '';
										silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status = 0;



										silicon_ioi.doctype.ioiCashDesk.clear_pending_payment();
										silicon_ioi.doctype.ioiSalesPOS.refresh_screen();

									}else
									{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
										frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
										raise;
									}
								}
				});


				d.hide();

				if (silicon_ioi.doctype.ioiCashDesk.rounded_still_to_paid > 0){
					if (document.getElementById('ioi_sales_pos_pay_button_pay_deliver')) {
						document.getElementById('ioi_sales_pos_pay_button_pay_deliver').click();
					}

					// }else if (document.getElementById('payment_movement_button_pay')){
					// 	setTimeout(() => {
					// 		console.log('ok');
					// 	  	document.getElementById('payment_movement_button_pay').click(); }, 6000)

					// }
				}

			},
			secondary_action: function(){
				d.hide();
			},


		});

		if (document.getElementById('pay_cash_desk_content')) {
			document.getElementById('pay_cash_desk_content').remove();
		}

		// Titles
		if (document.getElementById('pay_cash_desk_content_already_paid_title')) {
			document.getElementById('pay_cash_desk_content_already_paid_title').remove();
		}
		if (document.getElementById('pay_cash_desk_content_pay_now_title')) {
			document.getElementById('pay_cash_desk_content_pay_now_title').remove();
		}
		if (document.getElementById('pay_cash_desk_content_cash_back_title')) {
			document.getElementById('pay_cash_desk_content_cash_back_title').remove();
		}
		if (document.getElementById('pay_cash_desk_content_cash_back_unallowed_title')) {
			document.getElementById('pay_cash_desk_content_cash_back_unallowed_title').remove();
		}

		if (document.getElementById('pay_cash_desk_content_identification_card_title')) {
			document.getElementById('pay_cash_desk_content__identification_card_title').remove();
		}


		// Cash value
		if (document.getElementById('pay_cash_desk_content_cash_value_label')) {
			document.getElementById('pay_cash_desk_content_cash_value_label').remove();
		}

		if (document.getElementById('pay_cash_desk_content_already_cash_value')) {
			document.getElementById('pay_cash_desk_content_already_cash_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_cash_value')) {
			document.getElementById('pay_cash_desk_content_cash_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_cash_back_value')) {
			document.getElementById('pay_cash_desk_content_cash_back_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_cash_rounding_value')) {
			document.getElementById('pay_cash_desk_content_cash_rounding_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_cash_rounding_label')) {
			document.getElementById('pay_cash_desk_content_cash_rounding_label').remove();
		}


		// Bank card
		if (document.getElementById('pay_cash_desk_content_bank_card_value_label')) {
			document.getElementById('pay_cash_desk_content_bank_card_value_label').remove();
		}

		if (document.getElementById('pay_cash_desk_content_already_bank_card_value')) {
			document.getElementById('pay_cash_desk_content_already_bank_card_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_bank_card_value')) {
			document.getElementById('pay_cash_desk_content_bank_card_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_bank_card_back_value')) {
			document.getElementById('pay_cash_desk_content_bank_card_back_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_bank_card_back_unallowed_value')) {
			document.getElementById('pay_cash_desk_content_bank_card_back_unallowed_value').remove();
		}


		if (document.getElementById('pay_cash_desk_content_bank_card_transaction_id')) {
			document.getElementById('pay_cash_desk_content_bank_card_transaction_id').remove();
		}

		if (document.getElementById('pay_cash_desk_content_bank_card_transaction_detail')) {
			document.getElementById('pay_cash_desk_content_bank_card_transaction_detail').remove();
		}

		if (document.getElementById('pay_cash_desk_content_emoney_back_value')) {
			document.getElementById('pay_cash_desk_content_emoney_back_value').remove();
		}


		// Credit card
		if (document.getElementById('pay_cash_desk_content_credit_card_value_label')) {
			document.getElementById('pay_cash_desk_content_credit_card_value_label').remove();
		}

		if (document.getElementById('pay_cash_desk_content_already_credit_card_value')) {
			document.getElementById('pay_cash_desk_content_already_credit_card_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_credit_card_value')) {
			document.getElementById('pay_cash_desk_content_credit_card_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_credit_card_transaction_id')) {
			document.getElementById('pay_cash_desk_content_credit_card_transaction_id').remove();
		}

		if (document.getElementById('pay_cash_desk_content_credit_card_transaction_detail')) {
			document.getElementById('pay_cash_desk_content_credit_card_transaction_detail').remove();
		}


		// Check value
		if (document.getElementById('pay_cash_desk_content_check_value_label')) {
			document.getElementById('pay_cash_desk_content_check_value_label').remove();
		}

		if (document.getElementById('pay_cash_desk_content_already_check_value')) {
			document.getElementById('pay_cash_desk_content_already_check_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_value')) {
			document.getElementById('pay_cash_desk_content_check_value').remove();
		}


		if (document.getElementById('pay_cash_desk_content_check_id')) {
			document.getElementById('pay_cash_desk_content_check_id').remove();
		}


		// EMoney value
		if (document.getElementById('pay_cash_desk_content_emoney_value_label')) {
			document.getElementById('pay_cash_desk_content_emoney_value_label').remove();
		}

		if (document.getElementById('pay_cash_desk_content_already_emoney_value')) {
			document.getElementById('pay_cash_desk_content_already_emoney_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_emoney_value')) {
			document.getElementById('pay_cash_desk_content_emoney_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_emoney_back_value')) {
			document.getElementById('pay_cash_desk_content_emoney_back_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_emoney_back_unallowed_value')) {
			document.getElementById('pay_cash_desk_content_emoney_back_unallowed_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_emoney_transaction_id')) {
			document.getElementById('pay_cash_desk_content_emoney_transaction_id').remove();
		}

		if (document.getElementById('pay_cash_desk_content_emoney_transaction_detail')) {
			document.getElementById('pay_cash_desk_content_emoney_transaction_detail').remove();
		}


		// Check spec 1 value
		if (document.getElementById('pay_cash_desk_content_check_spec_1_value_label')) {
			document.getElementById('pay_cash_desk_content_check_spec_1_value_label').remove();
		}

		if (document.getElementById('pay_cash_desk_content_already_check_spec_1_value')) {
			document.getElementById('pay_cash_desk_content_already_check_spec_1_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_1_value')) {
			document.getElementById('pay_cash_desk_content_check_spec_1_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_1_max_value')) {
			document.getElementById('pay_cash_desk_content_check_spec_1_max_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_1_description')) {
			document.getElementById('pay_cash_desk_content_check_spec_1_description').remove();
		}


		if (document.getElementById('pay_cash_desk_content_check_spec_1_id')) {
			document.getElementById('pay_cash_desk_content_check_spec_1_id').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_1_transaction_id')) {
			document.getElementById('pay_cash_desk_content_check_spec_1_transaction_id').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_1_transaction_detail')) {
			document.getElementById('pay_cash_desk_content_check_spec_1_transaction_detail').remove();
		}

		// Check spec 2 value
		if (document.getElementById('pay_cash_desk_content_check_spec_2_value_label')) {
			document.getElementById('pay_cash_desk_content_check_spec_2_value_label').remove();
		}

		if (document.getElementById('pay_cash_desk_content_already_check_spec_2_value')) {
			document.getElementById('pay_cash_desk_content_already_check_spec_2_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_2_value')) {
			document.getElementById('pay_cash_desk_content_check_spec_2_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_2_max_value')) {
			document.getElementById('pay_cash_desk_content_check_spec_2_max_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_2_description')) {
			document.getElementById('pay_cash_desk_content_check_spec_2_description').remove();
		}


		if (document.getElementById('pay_cash_desk_content_check_spec_2_id')) {
			document.getElementById('pay_cash_desk_content_check_spec_2_id').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_2_transaction_id')) {
			document.getElementById('pay_cash_desk_content_check_spec_2_transaction_id').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_2_transaction_detail')) {
			document.getElementById('pay_cash_desk_content_check_spec_2_transaction_detail').remove();
		}





		// Check spec 3 value
		if (document.getElementById('pay_cash_desk_content_check_spec_3_value_label')) {
			document.getElementById('pay_cash_desk_content_check_spec_3_value_label').remove();
		}

		if (document.getElementById('pay_cash_desk_content_already_check_spec_3_value')) {
			document.getElementById('pay_cash_desk_content_already_check_spec_3_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_3_value')) {
			document.getElementById('pay_cash_desk_content_check_spec_3_value').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_3_max_value')) {
			document.getElementById('pay_cash_desk_content_check_spec_3_max_value').remove();
		}



		if (document.getElementById('pay_cash_desk_content_check_spec_3_description')) {
			document.getElementById('pay_cash_desk_content_check_spec_3_description').remove();
		}


		if (document.getElementById('pay_cash_desk_content_check_spec_3_id')) {
			document.getElementById('pay_cash_desk_content_check_spec_3_id').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_3_transaction_id')) {
			document.getElementById('pay_cash_desk_content_check_spec_3_transaction_id').remove();
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_3_transaction_detail')) {
			document.getElementById('pay_cash_desk_content_check_spec_3_transaction_detail').remove();
		}

		// Still to pay
		if (document.getElementById('pay_still_to_pay_label')) {
			document.getElementById('pay_still_to_pay_label').remove();
		}

		if (document.getElementById('pay_still_to_pay')) {
			document.getElementById('pay_still_to_pay').remove();
		}





		let meth = path_cash_desk_movement + '.ioi_cash_desk_movement_get_already_paid_for_document'

		let res_paid = [];


		frappe.call({  	method: meth,
						args: 	{	"doctype": doctype,
									"name": doctype_name,
									"cashdesk_id": cashdesk_id
						},
						async: false,
						callback: function(r)	{

							res_paid = r.message;

						}
		});


		let html = '';

		let top = 0;

		let height = 30;


		if ((cashdesk_cash_value_enabled) && (cashdesk_cash_value_enabled == 1)) {
			height += 40;
		}

		if ((cashdesk_bank_card_value_enabled) && (cashdesk_bank_card_value_enabled == 1)) {
			height += 40;
		}

		if ((cashdesk_credit_card_value_enabled) && (cashdesk_credit_card_value_enabled == 1)) {
			height += 40;
		}

		if ((cashdesk_check_value_enabled) && (cashdesk_check_value_enabled == 1)) {
			height += 40;
		}

		if ((cashdesk_emoney_value_enabled) && (cashdesk_emoney_value_enabled == 1)) {
			height += 40;
		}

		if ((cashdesk_check_spec_1_value_enabled) && (cashdesk_check_spec_1_value_enabled == 1)) {
			height += 40;
		}

		if ((cashdesk_check_spec_2_value_enabled) && (cashdesk_check_spec_2_value_enabled == 1)) {
			height += 40;
		}

		if ((cashdesk_check_spec_3_value_enabled) && (cashdesk_check_spec_3_value_enabled == 1)) {
			height += 40;
		}

		height += 40;

		html += '<div id="pay_cash_desk_content" style="width:1000px; height:' + height.toString() +'px;">';

		// Titles
		html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:1500px;">';
		html += '		<label id="pay_cash_desk_content_already_paid_title" style="position: absolute; top: 6px; left: 200px;"><u>' + __("Already paid") + '</u></label>';
		html += '		<label id="pay_cash_desk_content_pay_now_title" style="position: absolute; top: 6px; left: 320px;"><u>' + __("Payment") + '</u></label>';
		html += '		<label id="pay_cash_desk_content_cash_back_title" style="position: absolute; top: 6px; left: 440px;"><u>' + __("Cash back") + '</u></label>';

		if (((cashdesk_bank_card_value_enabled) && (cashdesk_bank_card_value_enabled == 1)) ||
			((cashdesk_emoney_value_enabled) && (cashdesk_emoney_value_enabled == 1))) {
			html += '		<label id="pay_cash_desk_content_cash_back_unallowed_title" style="position: absolute; top: 6px; left: 560px;"><u>' + __("Unallowed") + '</u></label>';
		}

		if (((cashdesk_check_value_enabled) && (cashdesk_check_value_enabled == 1)) ||
			((cashdesk_check_spec_1_value_enabled) && (cashdesk_check_spec_1_value_enabled == 1)) ||
			((cashdesk_check_spec_2_value_enabled) && (cashdesk_check_spec_2_value_enabled == 1)) ||
			((cashdesk_check_spec_3_value_enabled) && (cashdesk_check_spec_3_value_enabled == 1))) {
			html += '		<label id="pay_cash_desk_content_identification_card_title" style="position: absolute; top: 6px; left: 680px;"><u>' + __("Check identification") + '</u></label>';

		}
		html += '	</div>';

		top += 30;

		if ((cashdesk_cash_value_enabled) && (cashdesk_cash_value_enabled == 1)) {

			// Cash value
			html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:170px;">';
			html += '		<label id="pay_cash_desk_content_cash_value_label" style="position: absolute; top: 10px; left: 2px;z-index:5; cursor: pointer;">' + __("Cash value") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_already_cash_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="';

			if (res_paid.length > 0) {

				for (var i = 0; i < res_paid.length; i++) {
					if (res_paid[i].value_mode == 0) {

						if ((res_paid[i].value == null) || (res_paid[i].value == '') || (res_paid[i].value == 0)) {
							// html += '';
						}else{
							html += res_paid[i].value.toFixed(2).toString();
						}
						break;
					}
				}

			}else{
				// html += '';
			}

			html += '" disabled>';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 320px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_cash_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_cash_value + '">';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 440px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_cash_back_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_cash_back_value + '" disabled>';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 680px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_cash_rounding_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="0" disabled>';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 10px; left: 800px; width: 110px; height: 25px;"> ';
			html += '			<label id="pay_cash_desk_content_cash_rounding_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' +  __("Cash rounding") + '</label>';
			html += '		</div>';


			html += '	</div>';

			top += 40;
		}

		if ((cashdesk_bank_card_value_enabled) && (cashdesk_bank_card_value_enabled == 1)) {



			// Bank card
			html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:170px;">';
			html += '		<label id="pay_cash_desk_content_bank_card_value_label" style="position: absolute; top: 10px; left: 2px;z-index:5; cursor: pointer;">' + __("Bank card value") + '</label>';

			html += '		<div style="position: absolute; top:10px; left: 170px; height: 30px; width:30px">';
			html += `			<i class="fa fa-solid fa-credit-card" title="${__("Electronic payment")}"></i>`;
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 110px; height: 25px;"> ';

			html += '			<input id="pay_cash_desk_content_already_bank_card_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="';

			if (res_paid.length > 0) {

				for (var i = 0; i < res_paid.length; i++) {
					if (res_paid[i].value_mode == 1) {

						if ((res_paid[i].value == null) || (res_paid[i].value == '') || (res_paid[i].value == 0)) {
							// html += '';
						}else{
							html += res_paid[i].value.toFixed(2).toString();
						}
						break;
					}
				}

			}else{
				// html += '';
			}


			html += '" disabled>';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 320px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_bank_card_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_value + '">';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 440px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_bank_card_back_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_back_value + '" disabled>';
			html += '		</div>';


			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 560px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_bank_card_back_unallowed_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_back_unallowed_value + '" disabled>';
			html += '		</div>';


			html += '		<input id="pay_cash_desk_content_bank_card_transaction_id" type="hidden" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_id + '">';
			html += '		<input id="pay_cash_desk_content_bank_card_transaction_detail" type="hidden" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_detail + '">';
			html += '	</div>';

			top += 40;

		}

		if ((cashdesk_credit_card_value_enabled) && (cashdesk_credit_card_value_enabled == 1)) {

			// Credit card
			html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:170px;">';
			html += '		<label id="pay_cash_desk_content_credit_card_value_label" style="position: absolute; top: 10px; left: 2px;z-index:5; cursor: pointer;">' + __("Credit card value") + '</label>';

			html += '		<div style="position: absolute; top:10px; left: 170px; height: 30px; width:30px">';
			html += `			<i class="fa fa-solid fa-credit-card" title="${__("Electronic payment")}"></i>`;
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_already_credit_card_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="';

			if (res_paid.length > 0) {

				for (var i = 0; i < res_paid.length; i++) {
					if (res_paid[i].value_mode == 2) {

						if ((res_paid[i].value == null) || (res_paid[i].value == '') || (res_paid[i].value == 0)) {
							// html += '';
						}else{
							html += res_paid[i].value.toFixed(2).toString();
						}
						break;
					}
				}

			}else{
				// html += '';
			}


			html += '" disabled>';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 320px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_credit_card_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_value + '">';
			html += '		</div>';

			html += '		<input id="pay_cash_desk_content_credit_card_transaction_id" type="hidden" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_id + '">';
			html += '		<input id="pay_cash_desk_content_credit_card_transaction_detail" type="hidden" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_detail + '">';
			html += '	</div>';

			top += 40;

		}

		if ((cashdesk_check_value_enabled) && (cashdesk_check_value_enabled == 1)) {

			// Check value
			html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:170px;">';
			html += '		<label id="pay_cash_desk_content_check_value_label" style="position: absolute; top: 10px; left: 2px;z-index:5; cursor: pointer;">' + __("Check / bank transfer") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_already_check_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="';

			if (res_paid.length > 0) {

				for (var i = 0; i < res_paid.length; i++) {
					if (res_paid[i].value_mode == 3) {

						if ((res_paid[i].value == null) || (res_paid[i].value == '') || (res_paid[i].value == 0)) {
							// html += '';
						}else{
							html += res_paid[i].value.toFixed(2).toString();
						}
						break;
					}
				}

			}else{
				// html += '';
			}


			html += '" disabled>';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 320px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_check_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_value + '">';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 680px; width: 300px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_check_id" type="text" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_id + '">';
			html += '		</div>';
			html += '	</div>';

			top += 40;
		}

		if ((cashdesk_emoney_value_enabled) && (cashdesk_emoney_value_enabled == 1)) {

			// EMoney value
			html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:170px;">';
			html += '		<label id="pay_cash_desk_content_emoney_value_label" style="position: absolute; top: 10px; left: 2px;z-index:5; cursor: pointer;">' + __("Emoney value") + '</label>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_already_emoney_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="';

			if (res_paid.length > 0) {

				for (var i = 0; i < res_paid.length; i++) {
					if (res_paid[i].value_mode == 4) {

						if ((res_paid[i].value == null) || (res_paid[i].value == '') || (res_paid[i].value == 0)) {
							// html += '';
						}else{
							html += res_paid[i].value.toFixed(2).toString();
						}
						break;
					}
				}

			}else{
				// html += '';
			}


			html += '" disabled>';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 320px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_emoney_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_value + '">';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 440px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_emoney_back_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_back_value + '" disabled>';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 560px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_emoney_back_unallowed_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_back_unallowed_value + '" disabled>';
			html += '		</div>';



			html += '		<input id="pay_cash_desk_content_emoney_transaction_id" type="hidden" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_transaction_id + '">';
			html += '		<input id="pay_cash_desk_content_emoney_transaction_detail" type="hidden" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_transaction_detail + '">';

			html += '	</div>';

			top += 40;
		}

		if ((cashdesk_check_spec_1_value_enabled) && (cashdesk_check_spec_1_value_enabled == 1)) {

			// Check spec 1 value
			html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:170px;">';
			html += '		<label id="pay_cash_desk_content_check_spec_1_value_label" style="position: absolute; top: 10px; left: 2px;z-index:5; cursor: pointer;">';

			if ((cashdesk_check_spec_1_description) && (cashdesk_check_spec_1_description != '')) {
				html += __(cashdesk_check_spec_1_description);
			}else{
				html += __("Check spec 1 value");
			}
			html += 		'</label>';

			if (cashdesk_check_spec_1_electronic_transac == 1){
				html += '		<div style="position: absolute; top:10px; left: 170px; height: 30px; width:30px">';
				html += `			<i class="fa fa-solid fa-credit-card" title="${__("Electronic payment")}"></i>`;
				html += '		</div>';
			}

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_already_check_spec_1_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="';

			if (res_paid.length > 0) {

				for (var i = 0; i < res_paid.length; i++) {
					if (res_paid[i].value_mode == 5) {

						if ((res_paid[i].value == null) || (res_paid[i].value == '') || (res_paid[i].value == 0)) {
							// html += '';
						}else{
							html += res_paid[i].value.toFixed(2).toString();
						}
						break;
					}
				}

			}else{
				// html += '';
			}


			html += '" disabled>';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 320px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_check_spec_1_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_value + '">';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 8px; left: 440px; width: 220px; height: 25px;"> ';

			if (parseFloat(max_spec1_amount) != 0) {
				html += '			<label id="pay_cash_desk_content_check_spec_1_max_value"><b><font color="#ED514C">' + __("Max(") + parseFloat(max_spec1_amount).toFixed(2) + '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id + ')' + '</font></b></label>';
			}else{
				html += '			<label id="pay_cash_desk_content_check_spec_1_max_value"></label>';
			}
			html += '		</div>';




			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 680px; width: 300px; height: 25px;"> ';
			html += `			<input id="pay_cash_desk_content_check_spec_1_id" type="${ !cashdesk_check_spec_1_electronic_transac || cashdesk_check_spec_1_id_required ? "text" : "hidden"}" class="input-with-feedback form-control bold" value="${silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_id}">`;
			html += '		</div>';



			html += '		<input id="pay_cash_desk_content_check_spec_1_transaction_id" type="hidden" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_id + '">';
			html += '		<input id="pay_cash_desk_content_check_spec_1_transaction_detail" type="hidden" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_detail + '">';

			html += '	</div>';

			top += 40;
		}

		if ((cashdesk_check_spec_2_value_enabled) && (cashdesk_check_spec_2_value_enabled == 1)) {

			// Check spec 2 value
			html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:170px;">';
			html += '		<label id="pay_cash_desk_content_check_spec_2_value_label" style="position: absolute; top: 10px; left: 2px;z-index:5; cursor: pointer;">';

			if ((cashdesk_check_spec_2_description) && (cashdesk_check_spec_2_description != '')) {
				html += __(cashdesk_check_spec_2_description);
			}else{
				html += __("Check spec 2 value");
			}
			html += 		'</label>';

			if (cashdesk_check_spec_2_electronic_transac == 1){
				html += '		<div style="position: absolute; top:10px; left: 170px; height: 30px; width:30px">';
				html += `			<i class="fa fa-solid fa-credit-card" title="${__("Electronic payment")}"></i>`;
				html += '		</div>';
			}

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_already_check_spec_2_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="';

			if (res_paid.length > 0) {

				for (var i = 0; i < res_paid.length; i++) {
					if (res_paid[i].value_mode == 6) {

						if ((res_paid[i].value == null) || (res_paid[i].value == '') || (res_paid[i].value == 0)) {
							// html += '';
						}else{
							html += res_paid[i].value.toFixed(2).toString();
						}
						break;
					}
				}

			}else{
				// html += '';
			}


			html += '" disabled>';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 320px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_check_spec_2_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_value + '">';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 8px; left: 440px; width: 220px; height: 25px;"> ';

			if (parseFloat(max_spec2_amount) != 0) {
				html += '			<label id="pay_cash_desk_content_check_spec_2_max_value"><b><font color="#ED514C">' + __("Max(") + parseFloat(max_spec2_amount).toFixed(2) + '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id + ')' + '</font></b></label>';
			}else{
				html += '			<label id="pay_cash_desk_content_check_spec_2_max_value"></label>';
			}
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 680px; width: 300px; height: 25px;"> ';
			html += `			<input id="pay_cash_desk_content_check_spec_2_id" type="${ !cashdesk_check_spec_2_electronic_transac || cashdesk_check_spec_2_id_required ? "text" : "hidden"}" class="input-with-feedback form-control bold" value="${silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_id}">`;
			html += '		</div>';


			html += '		<input id="pay_cash_desk_content_check_spec_2_transaction_id" type="hidden" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_id + '">';
			html += '		<input id="pay_cash_desk_content_check_spec_2_transaction_detail" type="hidden" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_detail + '">';

			html += '	</div>';

			top += 40;

		}

		if ((cashdesk_check_spec_3_value_enabled) && (cashdesk_check_spec_3_value_enabled == 1)) {

			// Check spec 3 value
			html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:170px;">';
			html += '		<label id="pay_cash_desk_content_check_spec_3_value_label" style="position: absolute; top: 10px; left: 2px;z-index:5; cursor: pointer;">';

			if ((cashdesk_check_spec_3_description) && (cashdesk_check_spec_3_description != '')) {
				html += __(cashdesk_check_spec_3_description);
			}else{
				html += __("Check spec 3 value");
			}
			html += 		'</label>';

			if (cashdesk_check_spec_3_electronic_transac == 1){
				html += '		<div style="position: absolute; top:10px; left: 170px; height: 30px; width:30px">';
				html += `			<i class="fa fa-solid fa-credit-card" title="${__("Electronic payment")}"></i>`;
				html += '		</div>';
			}

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 200px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_already_check_spec_3_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="';

			if (res_paid.length > 0) {

				for (var i = 0; i < res_paid.length; i++) {
					if (res_paid[i].value_mode == 7) {

						if ((res_paid[i].value == null) || (res_paid[i].value == '') || (res_paid[i].value == 0)) {
							// html += '';
						}else{
							html += res_paid[i].value.toFixed(2).toString();
						}
						break;
					}
				}

			}else{
				// html += '';
			}


			html += '" disabled>';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 320px; width: 110px; height: 25px;"> ';
			html += '			<input id="pay_cash_desk_content_check_spec_3_value" type="number" step="any" style="text-align: right;" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_value + '">';
			html += '		</div>';

			html += '		<div class="control-input" style="position: absolute; top: 8px; left: 440px; width: 220px; height: 25px;"> ';

			if (parseFloat(max_spec3_amount) != 0) {
				html += '			<label id="pay_cash_desk_content_check_spec_3_max_value"><b><font color="#ED514C">' + __("Max(") + parseFloat(max_spec3_amount).toFixed(2) + '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id + ')' + '</font></b></label>';
			}else{
				html += '			<label id="pay_cash_desk_content_check_spec_3_max_value"></label>';
			}
			html += '		</div>';


			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 680px; width: 300px; height: 25px;"> ';
			html += `			<input id="pay_cash_desk_content_check_spec_3_id" type="${ !cashdesk_check_spec_3_electronic_transac || cashdesk_check_spec_3_id_required ? "text" : "hidden"}" class="input-with-feedback form-control bold" value="${silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_id}">`;
			html += '		</div>';

			html += '		<input id="pay_cash_desk_content_check_spec_3_transaction_id" type="hidden" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_id + '">';
			html += '		<input id="pay_cash_desk_content_check_spec_3_transaction_detail" type="hidden" value="' + silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_detail + '">';


			html += '	</div>';

			top += 40;
		}

		// Still to pay

		color = '#60A367';

		if (parseFloat(par_total_still_to_pay) > 0) {
			color = '#ED514C';
		}

		top -= 15;

		html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:1500px;">';
		html += '		<hr color="black" style="position: absolute; top: 6px; left: 320px;width:110px;" />';
		if (((cashdesk_cash_value_enabled) && (cashdesk_cash_value_enabled == 1)) || ((cashdesk_bank_card_value_enabled) && (cashdesk_bank_card_value_enabled == 1)) || ((cashdesk_emoney_value_enabled) && (cashdesk_emoney_value_enabled == 1))) {
			html += '		<hr color="black" style="position: absolute; top: 6px; left: 440px;width:110px;" />';
		};
		if (((cashdesk_bank_card_value_enabled) && (cashdesk_bank_card_value_enabled == 1)) || ((cashdesk_emoney_value_enabled) && (cashdesk_emoney_value_enabled == 1))) {
			html += '		<hr color="black" style="position: absolute; top: 6px; left: 560px;width:110px;" />';
		}
		html += '	</div>';

		if (document.getElementById('pay_total_label')) {
			document.getElementById('pay_total_label').remove();
		}

		if (document.getElementById('pay_total_payment')) {
			document.getElementById('pay_total_payment').remove();
		}

		if (document.getElementById('pay_total_cash_back')) {
			document.getElementById('pay_total_cash_back').remove();
		}

		if (document.getElementById('pay_total_unallowed_cash_back')) {
			document.getElementById('pay_total_unallowed_cash_back').remove();
		}





		top += 20;

		html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:1500px;">';
		html += '		<label id="pay_total_label" style="position: absolute; top: 6px; left: 200px;"><b>' + __("Total") + '</b></label>';
		html += '		<b><label id="pay_total_payment" style="position: absolute; top: 6px; left: 320px;width:110px;text-align:right;"></label></b>';
		html += '		<b><label id="pay_total_cash_back" style="position: absolute; top: 6px; left: 430px;width:110px;text-align:right;"></label></b>';

		if (((cashdesk_bank_card_value_enabled) && (cashdesk_bank_card_value_enabled == 1)) ||
			((cashdesk_emoney_value_enabled) && (cashdesk_emoney_value_enabled == 1))) {
			html += '		<b><label id="pay_total_unallowed_cash_back" style="position: absolute; top: 6px; left: 560px;width:110px;text-align:right;"></label></b>';
			}
		html += '	</div>';



		top += 20;



		let still_value = '<font color="' + color + '">' + parseFloat(par_total_still_to_pay).toFixed(2).toString() + ' ' + currency_id + '</font>';


		html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: 0px; width:1500px;">';
		html += '		<label id="pay_still_to_pay_label" style="position: absolute; top: 6px; left: 200px;"><b>' + __("Still to pay") + '</b></label>';
		html += '		<label id="pay_still_to_pay" style="position: absolute; top: 6px; left: 320px;width:110px;text-align:right;"><b>' + still_value + '</b></label>';
		html += '	</div>';



		html += '</div>';

		let has_crash_transactions = 0;

		method = path_cash_desk_movement + '.ioi_cash_desk_movement_has_crash_payment_transaction';

		frappe.call({  	method: method,
						args: {	"doctype" : doctype,
								"name" : doctype_name,
								"cashdesk_id": cashdesk_id

							},
						async: false,
						callback:function(r)	{
							has_crash_transactions = r.message;
						}
		});


		if (has_crash_transactions == 1) {

			let action_payment_retrieve = function () {

				silicon_ioi.doctype.ioiCashDesk.create_payment_from_crash_transaction(doctype, doctype_name, cashdesk_id, d);
			}

			d.add_custom_action(__("Payment transaction not completed"), action_payment_retrieve, "btn-warning");
			d.custom_actions.children().removeClass("btn-secondary")
		}

		let open_cash_desk_for_money = function () {

			if (techno == 'EPOSDEVICE') {
				if ((p_ipaddress.trim() != '') && (p_port != '') && (p_port != '0')) {
					let epson_print = new silicon_ioi.doctype.ioiEPosDevice(p_ipaddress, p_port, 500, 'DRAWER_PULSE', false, null, null);
				}
			}

		}

		let auto_cash_payment;

		d.add_custom_action(__("Open cash desk"), open_cash_desk_for_money, "btn-warning");

		frappe.db.get_single_value("ioi Sales Settings", "glory_payment").then((value) => {
			if(value == 1){
				d.add_custom_action(__("Pay with auto cash desk"), glory_payment, "btn-warning ml-3");
			}
		});


		let glory_payment = function (){

			let ping_req = silicon_ioi.payments.ioiGlory.ping();
			ping_req.then(function(ping) {
				if (ping.success) {
					function promptLogin() {
						return new Promise((resolve) => {
							let login_dialog = new frappe.ui.Dialog({
								title: __('Cashier login'),
								fields: [{
									'label': 'Username',
									'fieldname': 'username',
									'fieldtype': 'Data',
								}, {
									'label': 'Password',
									'fieldname': 'password',
									'fieldtype': 'Password',
								}],
								primary_action_label: __('Login'),
								primary_action(data) {
									sessionStorage.setItem("username", data.username);
									sessionStorage.setItem("password", data.password);
									login_dialog.hide();
									resolve();
								}
							});
							login_dialog.show();
						});
					}

					let loginPromise = sessionStorage.getItem("username") === null ? promptLogin() : Promise.resolve();

					loginPromise.then(() => {
						let login = silicon_ioi.payments.ioiGlory.login(sessionStorage.getItem("username"), sessionStorage.getItem("password"));
						login.then(function(loginResponse) {
							if (loginResponse.success) {
								let status = silicon_ioi.payments.ioiGlory.get_status();
								status.then(function(statusResponse) {
									if(statusResponse.success){

										const dialog_fields = [
											{
												'fieldname': 'glory_html',
												'fieldtype': 'HTML',
											}
										]

										auto_cash_payment = new frappe.ui.Dialog({
											title: __('Automatic Cash Payment'),
											fields: dialog_fields,
											primary_action_label: __('Close'),
											primary_action() {
												auto_cash_payment.hide();
											},
											on_page_show: () => {

												let html_section = `<section style="display:flex;justify-content:center;gap:30px;padding:20px;">
													<div style="width:100px;height:100px;border: 2px solid var(--primary);border-radius: 30px;display: flex;justify-content: space-evenly;align-items: center;flex-direction:column;cursor:pointer;" id="pay">
														<svg style="width: 40px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="var(--primary)" d="M64 0C46.3 0 32 14.3 32 32l0 64c0 17.7 14.3 32 32 32l80 0 0 32-57 0c-31.6 0-58.5 23.1-63.3 54.4L1.1 364.1C.4 368.8 0 373.6 0 378.4L0 448c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-69.6c0-4.8-.4-9.6-1.1-14.4L488.2 214.4C483.5 183.1 456.6 160 425 160l-217 0 0-32 80 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32L64 0zM96 48l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L96 80c-8.8 0-16-7.2-16-16s7.2-16 16-16zM64 432c0-8.8 7.2-16 16-16l352 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L80 448c-8.8 0-16-7.2-16-16zm48-168a24 24 0 1 1 0-48 24 24 0 1 1 0 48zm120-24a24 24 0 1 1 -48 0 24 24 0 1 1 48 0zM160 344a24 24 0 1 1 0-48 24 24 0 1 1 0 48zM328 240a24 24 0 1 1 -48 0 24 24 0 1 1 48 0zM256 344a24 24 0 1 1 0-48 24 24 0 1 1 0 48zM424 240a24 24 0 1 1 -48 0 24 24 0 1 1 48 0zM352 344a24 24 0 1 1 0-48 24 24 0 1 1 0 48z"/></svg>
														<p style="margin:0;">${__('Pay')}</p>
													</div>
													<div onclick="glory_logout()" style="width:100px;height:100px;border: 2px solid var(--primary);border-radius: 30px;display: flex;justify-content: space-evenly;align-items: center;flex-direction:column;cursor:pointer;" id="logout">
														<svg style="width: 40px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="var(--primary)" d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"/></svg>
														<p style="margin:0;">${__('Logout')}</p>
													</div>
												</section>`;

												let glory_html_sect = auto_cash_payment.get_field("glory_html").wrapper;
												glory_html_sect.innerHTML = html_section;

												auto_cash_payment.show();

												auto_cash_payment.$wrapper.find('#pay')[0].onclick = function(){
													glory_transac();
												}

												auto_cash_payment.$wrapper.find('#logout')[0].onclick = function(){
													let logout = silicon_ioi.payments.ioiGlory.logout();
													logout.then(function(logoutRes) {
														if (logoutRes.success) {
															sessionStorage.removeItem("username");
															sessionStorage.removeItem("password");
															auto_cash_payment.hide();
														}
													});
												}
											}
										});
										auto_cash_payment.show();
									}
								});
							}else{
								sessionStorage.removeItem("username");
								sessionStorage.removeItem("password");
								frappe.throw(__('Login or password incorrect.'));
							}
						});
					});
				}else{
					frappe.throw(__("CI-Activate isn't running."));
				}
			});
		}

		let glory_transac = function(){

			const dialog_fields = [
				{
					'fieldname': 'transac_html',
					'fieldtype': 'HTML',
				}
			]

			let transac_dial = new frappe.ui.Dialog({
				title: __('Pay'),
				fields: dialog_fields,
				primary_action_label: __('Close'),
				primary_action() {
					transac_dial.hide();
				},
				on_page_show: () => {
					let html =
						`<div style="margin: 0 auto;display: flex;flex-direction: column;align-items: center;width: 250px;gap: 5px;">
							<div style="width:100%;padding: 0;border: solid 1px var(--primary);height: 45px;display: flex;justify-content: center;align-items: center;background-color: white;"><input id="amount" type="text" inputmode="number" value="0,00" style="width:90%;padding: 0;border: none;height: 30px;font-size: 17px;"></div>

							<div style="width:100%;display: grid;grid-template-columns: repeat(3, 1fr);grid-template-rows: repeat(4, 1fr);background-color: var(--primary);gap: 0.9px;border: solid 1px var(--primary);">
								<div class="digit_pay" style="grid-area: 1 / 1 / 2 / 2;display:flex;justify-content:center;align-items:center;height:50px;background-color: white;">7</div>
								<div class="digit_pay" style="grid-area: 1 / 2 / 2 / 3;display:flex;justify-content:center;align-items:center;height:50px;background-color: white;">8</div>
								<div class="digit_pay" style="grid-area: 1 / 3 / 2 / 4;display:flex;justify-content:center;align-items:center;height:50px;background-color: white;">9</div>
								<div class="digit_pay" style="grid-area: 2 / 1 / 3 / 2;display:flex;justify-content:center;align-items:center;height:50px;background-color: white;">4</div>
								<div class="digit_pay" style="grid-area: 2 / 2 / 3 / 3;display:flex;justify-content:center;align-items:center;height:50px;background-color: white;">5</div>
								<div class="digit_pay" style="grid-area: 2 / 3 / 3 / 4;display:flex;justify-content:center;align-items:center;height:50px;background-color: white;">6</div>
								<div class="digit_pay" style="grid-area: 3 / 1 / 4 / 2;display:flex;justify-content:center;align-items:center;height:50px;background-color: white;">1</div>
								<div class="digit_pay" style="grid-area: 3 / 2 / 4 / 3;display:flex;justify-content:center;align-items:center;height:50px;background-color: white;">2</div>
								<div class="digit_pay" style="grid-area: 3 / 3 / 4 / 4;display:flex;justify-content:center;align-items:center;height:50px;background-color: white;">3</div>
								<div id="del" style="grid-area: 4 / 1 / 5 / 2;display:flex;justify-content:center;align-items:center;height:50px;background-color: white;">X</div>
								<div id="0" style="grid-area: 4 / 2 / 5 / 3;display:flex;flex-direction:column;justify-content:center;align-items:center;height:50px;background-color: white;">
									0
									<div style="display: flex;justify-content: space-evenly;width: 100%;">
										<span>+</span>
										<span>-</span>
									</div>
								</div>
								<div id="conf" style="grid-area: 4 / 3 / 5 / 4;display:flex;justify-content:center;align-items:center;height:50px;background-color: white;">V</div>
							</div>

							<div style="display: flex;width: 100%;justify-content: space-between;">
								<div id="cancel" style="display:flex;justify-content:center;align-items:center;height:50px;border: solid 1px var(--primary);width: 49%;background-color: white;">Cancel</div>
								<div id="start" style="display:flex;justify-content:center;align-items:center;height:50px;border: solid 1px var(--primary);width: 49%;background-color: white;">Start</div>
							</div>
						</div>`;

					let transac_html_sect = transac_dial.get_field("transac_html").wrapper;
					transac_html_sect.innerHTML = html;

					transac_dial.show();

					let amount = transac_dial.$wrapper.find('#amount')[0];

					let addDigit = function(digit) {
						let currentValue = amount.value.replace(",", ".");
						let isNegative = currentValue.startsWith("-");
						if (isNegative) {
							currentValue = currentValue.substring(1);
						}

						let numericValue = parseFloat(currentValue)*100;

						numericValue = (numericValue * 10 + digit) / 100;

						if (isNegative) {
							numericValue = -numericValue;
						}

						amount.value = numericValue.toFixed(2).replace(".", ",");
					}

					let digits = transac_dial.$wrapper.find('.digit_pay');
					for(let i = 0;i<digits.length;i++){
						digits[i].addEventListener('click' , function(){
							addDigit(parseInt(digits[i].innerText));
						})
					}

					transac_dial.$wrapper.find('#del')[0].addEventListener('click' , function(){
						amount.value = "0,00";
					});

					let isHolding = false;
					transac_dial.$wrapper.find('#0')[0].addEventListener('mousedown', function(event) {
						isHolding = false;
						let timeout = setTimeout(function() {
							isHolding = true;
							if (amount.value.startsWith("-")) {
								amount.value = amount.value.substring(1);
							} else {
								amount.value = "-" + amount.value;
							}
						}, 1000);

						document.addEventListener("mouseup", function() {
						clearTimeout(timeout);
					}, { once: true });
					});

					transac_dial.$wrapper.find('#0')[0].addEventListener('click', function(event) {
						if (isHolding) {
							event.preventDefault();
						} else {
							addDigit(0);
						}
					});

					transac_dial.$wrapper.find('#start')[0].addEventListener('click' , function(){
						let currentValue = amount.value.replace(",", ".");
						let transaction = silicon_ioi.payments.ioiGlory.transaction(parseFloat(currentValue));
						transaction.then(function(transactionRes) {
							if (transactionRes.success) {
								let data = JSON.parse(transactionRes.data);
								if(data.status == 0){
									d.$wrapper.find('#pay_cash_desk_content_cash_value')[0].value = parseFloat(currentValue);
									transac_dial.hide();
									auto_cash_payment.hide();
									d.get_primary_btn().trigger('click');
									d.hide();
								}
							}
						});
					});

					transac_dial.$wrapper.find('#cancel')[0].addEventListener('click' , function(){
						let cancel = silicon_ioi.payments.ioiGlory.cancel_pay();
						cancel.then(function(cancelRes) {
							if (cancelRes.success) {
								amount.value = "0,00";
							}
						});
					});
				}
			});
			transac_dial.show();
		}

		d.fields_dict.html_pay_now.$wrapper.html(html);

		let dialog_width = '590px';

		if (((cashdesk_check_value_enabled) && (cashdesk_check_value_enabled == 1)) ||
			((cashdesk_check_spec_1_value_enabled) && (cashdesk_check_spec_1_value_enabled == 1)) ||
			((cashdesk_check_spec_2_value_enabled) && (cashdesk_check_spec_2_value_enabled == 1)) ||
			((cashdesk_check_spec_3_value_enabled) && (cashdesk_check_spec_3_value_enabled == 1))) {

			dialog_width = '1025px';

		}else{

			if (((cashdesk_bank_card_value_enabled) && (cashdesk_bank_card_value_enabled == 1)) ||
				((cashdesk_emoney_value_enabled) && (cashdesk_emoney_value_enabled == 1))) {
				dialog_width = '955px';
			}
		}



		d.$wrapper.find('.modal-dialog').css("max-width", dialog_width).css("width", dialog_width);

		d.show();

		silicon_ioi.doctype.ioiCashDesk.sleep_static(500).then(() => {
			if (has_crash_transactions == 1){
				d.disable_primary_action();
				d.get_primary_btn().css("pointer-events","none");
			}

			document.getElementsByClassName('modal-dialog')[document.getElementsByClassName('modal-dialog').length-1].getElementsByClassName('modal-content')[0].getElementsByClassName('modal-footer')[0].getElementsByClassName('standard-actions')[0].getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[0].hidden = false;

			if (parseFloat(par_total_still_to_pay) < 0) {
				document.getElementsByClassName('modal-dialog')[document.getElementsByClassName('modal-dialog').length-1].getElementsByClassName('modal-content')[0].getElementsByClassName('modal-footer')[0].getElementsByClassName('standard-actions')[0].getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[0].hidden = true;
			}


			if (document.getElementById('pay_cash_desk_payconic_div')) {

				let fct_payconiq_mouseover = function() {
					this.style.cursor = 'pointer';
				};

				let fct_payconiq_mouseleave = function() {
					this.style.cursor = 'none';
				};

				let fct_payconiq_click = async function() {
					let tmp_doc;
					let transaction_ok = false;
					let transaction_type = "BANK";
					let amount = 0;

					switch(cur_frm.doctype){
						case 'ioi Sales POS':
							tmp_doc = silicon_ioi.doctype.ioiSalesPOS.doc;
							break;
						case 'ioi Sales Order':
						case 'ioi Sales Delivery':
						case 'ioi Sales Invoice':
							tmp_doc = cur_frm.doc;
							break;
					}

					if (ioiCashDesk.verifify_pending_payment(tmp_doc.doctype, tmp_doc.name, false)){
						cur_dialog.hide();
						frappe.throw("The transactions are currently being processed. Please try again later");

						return;
					}


					frappe.call({
						method: "silicon_ioi.utils.lib.payconiq.payconiq.payconiq_settings_exists",
						args: {},
						async: false,
						callback: function (r) {
							if (!r.message){
								frappe.throw(__("Payconiq not yet configured"));
							}
						}
					})

					if ( (document.getElementById('pay_cash_desk_content_cash_value').value == '') &&
						 (document.getElementById('pay_cash_desk_content_bank_card_value').value == '') &&
						 (document.getElementById('pay_cash_desk_content_credit_card_value').value == '') &&
						 (document.getElementById('pay_cash_desk_content_emoney_value').value == '') &&
						 (document.getElementById('pay_cash_desk_content_check_value').value == '') &&
						!(document.getElementById('pay_cash_desk_content_check_spec_1_value')?.value)&&
						!(document.getElementById('pay_cash_desk_content_check_spec_2_value')?.value)&&
						!(document.getElementById('pay_cash_desk_content_check_spec_3_value')?.value)) {
						let s = document.getElementById('pay_still_to_pay').innerText;
						s = s.substring(0, s.indexOf(' '));
						s = s.trim();

						if (s != '') {
							if (parseFloat(s) > 0) {
								document.getElementById('pay_cash_desk_content_bank_card_value').value = s;
								silicon_ioi.doctype.ioiCashDesk.compute_still_to_pay(document.getElementById('pay_cash_desk_content_bank_card_value').id, currency_id, par_total_still_to_pay, max_cash_back);
							}
						}
					}
					if (document.getElementById('pay_cash_desk_content_bank_card_value').value > 0){
						amount = parseFloat(document.getElementById('pay_cash_desk_content_bank_card_value').value);
						transaction_type = "BANK";
					}else if (document.getElementById('pay_cash_desk_content_credit_card_value').value > 0){
						amount = parseFloat(document.getElementById('pay_cash_desk_content_credit_card_value').value);
						transaction_type = "CREDIT";
					}else if (document.getElementById('pay_cash_desk_content_check_spec_1_value').value > 0){
						amount = parseFloat(document.getElementById('pay_cash_desk_content_check_spec_1_value').value);
						transaction_type = "SPEC1";
					}else if (document.getElementById('pay_cash_desk_content_check_spec_2_value').value > 0){
						amount = parseFloat(document.getElementById('pay_cash_desk_content_check_spec_2_value').value);
						transaction_type = "SPEC2";
					}else if (document.getElementById('pay_cash_desk_content_check_spec_3_value').value > 0){
						amount = parseFloat(document.getElementById('pay_cash_desk_content_check_spec_3_value').value);
						transaction_type = "SPEC3";
					}


					if (
						(parseFloat(document.getElementById('pay_cash_desk_content_cash_value')?.value) > 0) ||
						(parseFloat(document.getElementById('pay_cash_desk_content_emoney_value')?.value) > 0) ||
						(parseFloat(document.getElementById('pay_cash_desk_content_check_value')?.value) > 0) ||
						((parseFloat(document.getElementById('pay_cash_desk_content_check_spec_1_value')?.value) > 0) && (cashdesk_check_spec_1_electronic_transac != 1)) ||
						((parseFloat(document.getElementById('pay_cash_desk_content_check_spec_2_value')?.value) > 0) && (cashdesk_check_spec_2_electronic_transac != 1)) ||
						((parseFloat(document.getElementById('pay_cash_desk_content_check_spec_3_value')?.value) > 0) && (cashdesk_check_spec_3_electronic_transac != 1)) ){
							frappe.throw(__("Only electronic payment for Payconiq"));
						}

					transaction_ok = true;

					let payconiq_output = await silicon_ioi.payments.ioiPayconiq.do_initiate_payconiq_payment(amount, /*Payment amount*/
																								tmp_doc.name, /*description*/
																								tmp_doc.name,/*reference*/
																								tmp_doc.doctype, /*linked dt*/
																								tmp_doc.name, /*linked document name*/
																								tmp_doc.total_tvac,  /*linked document total tvac */
																								tmp_doc.cashdesk_id, /*origin cash desk id*/
																								tmp_doc.author_id); /* origin author id trigger payment */


					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_type = transaction_type;
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_subtype = 'PAYCONIQ';
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status = payconiq_output.status_code;

					if (transaction_type == "BANK"){
						document.getElementById('pay_cash_desk_content_bank_card_transaction_id').value = payconiq_output.payment_transaction_name;
						document.getElementById('pay_cash_desk_content_bank_card_transaction_detail').value = payconiq_output.payment_transaction_name;

						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_id = document.getElementById('pay_cash_desk_content_bank_card_transaction_id').value;
						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_detail = document.getElementById('pay_cash_desk_content_bank_card_transaction_detail').value;

					}else if  (transaction_type == "CREDIT"){
						document.getElementById('pay_cash_desk_content_credit_card_transaction_id').value = payconiq_output.payment_transaction_name;
						document.getElementById('pay_cash_desk_content_credit_card_transaction_detail').value = payconiq_output.payment_transaction_name;

						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_id = document.getElementById('pay_cash_desk_content_credit_card_transaction_id').value;
						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_detail = document.getElementById('pay_cash_desk_content_credit_card_transaction_detail').value;

					}else if  (transaction_type == "SPEC1"){
						document.getElementById('pay_cash_desk_content_check_spec_1_transaction_id').value = payconiq_output.payment_transaction_name;
						document.getElementById('pay_cash_desk_content_check_spec_1_transaction_detail').value = payconiq_output.payment_transaction_name;

						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_id = document.getElementById('pay_cash_desk_content_check_spec_1_transaction_id').value;
						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_detail = document.getElementById('pay_cash_desk_content_check_spec_1_transaction_detail').value;

					}else if  (transaction_type == "SPEC2"){
						document.getElementById('pay_cash_desk_content_check_spec_2_transaction_id').value = payconiq_output.payment_transaction_name;
						document.getElementById('pay_cash_desk_content_check_spec_2_transaction_detail').value = payconiq_output.payment_transaction_name;

						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_id = document.getElementById('pay_cash_desk_content_check_spec_2_transaction_id').value;
						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_detail = document.getElementById('pay_cash_desk_content_check_spec_2_transaction_detail').value;

					}else if  (transaction_type == "SPEC3"){
						document.getElementById('pay_cash_desk_content_check_spec_3_transaction_id').value = payconiq_output.payment_transaction_name;
						document.getElementById('pay_cash_desk_content_check_spec_3_transaction_detail').value = payconiq_output.payment_transaction_name;

						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_id = document.getElementById('pay_cash_desk_content_check_spec_3_transaction_id').value;
						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_detail = document.getElementById('pay_cash_desk_content_check_spec_3_transaction_detail').value;

					}

					cur_dialog.primary_action();

				}

				document.getElementById('pay_cash_desk_payconic_div').onmouseover = fct_payconiq_mouseover;
				document.getElementById('pay_cash_desk_payconic_div').onmouseleave = fct_payconiq_mouseleave;
				document.getElementById('pay_cash_desk_payconic_div').onclick = fct_payconiq_click;
			}


			if (document.getElementById('pay_cash_desk_ccv_div')) {

				let fct_ccv_mouseover = function() {
					this.style.cursor = 'pointer';
				};

				let fct_ccv_mouseleave = function() {
					this.style.cursor = 'none';
				};

				let fct_ccv_click = async function() {

					let tmp_doc;
					let transaction_ok = false;
					let transaction_type = "BANK";
					let amount = 0;

					if (has_crash_transactions == 1){
						frappe.throw("Payment transaction not completed");
						return;
					}

					switch(cur_frm.doctype){
						case 'ioi Sales POS':
							tmp_doc = silicon_ioi.doctype.ioiSalesPOS.doc;
							break;
						case 'ioi Sales Order':
						case 'ioi Sales Delivery':
						case 'ioi Sales Invoice':
							tmp_doc = cur_frm.doc;
							break;
					}

					if (ioiCashDesk.verifify_pending_payment(tmp_doc.doctype, tmp_doc.name, false)){
						cur_dialog.hide();
						frappe.throw("The transactions are currently being processed. Please try again later");

						return;
					}

					if ( (document.getElementById('pay_cash_desk_content_cash_value').value == '') &&
						 (document.getElementById('pay_cash_desk_content_bank_card_value').value == '') &&
						 (document.getElementById('pay_cash_desk_content_credit_card_value').value == '') &&
						 (document.getElementById('pay_cash_desk_content_emoney_value').value == '') &&
						 (document.getElementById('pay_cash_desk_content_check_value').value == '') &&
						!(document.getElementById('pay_cash_desk_content_check_spec_1_value')?.value)&&
						!(document.getElementById('pay_cash_desk_content_check_spec_2_value')?.value)&&
						!(document.getElementById('pay_cash_desk_content_check_spec_3_value')?.value)) {
						let s = document.getElementById('pay_still_to_pay').innerText;
						s = s.substring(0, s.indexOf(' '));
						s = s.trim();

						if (s != '') {
							if (parseFloat(s) > 0) {
								document.getElementById('pay_cash_desk_content_bank_card_value').value = s;
								let event = new Event('input');
								document.getElementById('pay_cash_desk_content_bank_card_value').dispatchEvent(event);
								silicon_ioi.doctype.ioiCashDesk.compute_still_to_pay(document.getElementById('pay_cash_desk_content_bank_card_value').id, currency_id, par_total_still_to_pay, max_cash_back);
							}
						}
					}

					if (document.getElementById('pay_cash_desk_content_bank_card_value').value > 0){
						amount = parseFloat(document.getElementById('pay_cash_desk_content_bank_card_value').value);
						transaction_type = "BANK";
					}else if (document.getElementById('pay_cash_desk_content_credit_card_value').value > 0){
						amount = parseFloat(document.getElementById('pay_cash_desk_content_credit_card_value').value);
						transaction_type = "CREDIT";
					}else if (document.getElementById('pay_cash_desk_content_check_spec_1_value').value > 0){
						amount = parseFloat(document.getElementById('pay_cash_desk_content_check_spec_1_value').value);
						transaction_type = "SPEC1";
					}else if (document.getElementById('pay_cash_desk_content_check_spec_2_value').value > 0){
						amount = parseFloat(document.getElementById('pay_cash_desk_content_check_spec_2_value').value);
						transaction_type = "SPEC2";
					}else if (document.getElementById('pay_cash_desk_content_check_spec_3_value').value > 0){
						amount = parseFloat(document.getElementById('pay_cash_desk_content_check_spec_3_value').value);
						transaction_type = "SPEC3";
					}


					if (
						(parseFloat(document.getElementById('pay_cash_desk_content_cash_value')?.value) > 0) ||
						(parseFloat(document.getElementById('pay_cash_desk_content_emoney_value')?.value) > 0) ||
						(parseFloat(document.getElementById('pay_cash_desk_content_check_value')?.value) > 0) ||
						((parseFloat(document.getElementById('pay_cash_desk_content_check_spec_1_value')?.value) > 0) && (cashdesk_check_spec_1_electronic_transac != 1)) ||
						((parseFloat(document.getElementById('pay_cash_desk_content_check_spec_2_value')?.value) > 0) && (cashdesk_check_spec_2_electronic_transac != 1)) ||
						((parseFloat(document.getElementById('pay_cash_desk_content_check_spec_3_value')?.value) > 0) && (cashdesk_check_spec_3_electronic_transac != 1)) ){
							frappe.throw(__("Only electronic payment for CCV"));
						}

					transaction_ok = true;

					switch(cur_frm.doctype){
						case 'ioi Sales POS':
							tmp_doc = silicon_ioi.doctype.ioiSalesPOS.doc;
							break;
						case 'ioi Sales Order':
						case 'ioi Sales Delivery':
						case 'ioi Sales Invoice':
							tmp_doc = cur_frm.doc;
							break;
					}
					let cashdesk_configured;

					await frappe.call({
						method: "silicon_ioi.utils.lib.ccv.is_cashdesk_configured",
						args: {
							cashdesk_id: tmp_doc.cashdesk_id
						},
						async: false,
						callback: function (r) {
							cashdesk_configured = r.message;
						}
					})

					if (!cashdesk_configured){
						frappe.throw(__("Cashdesk not yet configured for CCV"));
					}

					let ccv_output = await silicon_ioi.payments.ioiCCV.do_initiate_ccv_payment(amount, /*Payment amount*/
																								tmp_doc.name, /*description*/
																								tmp_doc.name,/*reference*/
																								tmp_doc.doctype, /*linked dt*/
																								tmp_doc.name, /*linked document name*/
																								tmp_doc.total_tvac,  /*linked document total tvac */
																								tmp_doc.cashdesk_id, /*origin cash desk id*/
																								tmp_doc.author_id); /* origin author id trigger payment */

					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_type = transaction_type;
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_subtype = 'CCV';
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status = ccv_output.status_code;

					if (transaction_type == "BANK"){
						document.getElementById('pay_cash_desk_content_bank_card_transaction_id').value = ccv_output.payment_transaction_name;
						document.getElementById('pay_cash_desk_content_bank_card_transaction_detail').value = ccv_output.payment_transaction_name;

						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_id = document.getElementById('pay_cash_desk_content_bank_card_transaction_id').value;
						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_detail = document.getElementById('pay_cash_desk_content_bank_card_transaction_detail').value;

					}else if  (transaction_type == "CREDIT"){
						document.getElementById('pay_cash_desk_content_credit_card_transaction_id').value = ccv_output.payment_transaction_name;
						document.getElementById('pay_cash_desk_content_credit_card_transaction_detail').value = ccv_output.payment_transaction_name;

						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_id = document.getElementById('pay_cash_desk_content_credit_card_transaction_id').value;
						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_detail = document.getElementById('pay_cash_desk_content_credit_card_transaction_detail').value;

					}else if  (transaction_type == "SPEC1"){
						document.getElementById('pay_cash_desk_content_check_spec_1_transaction_id').value = ccv_output.payment_transaction_name;
						document.getElementById('pay_cash_desk_content_check_spec_1_transaction_detail').value = ccv_output.payment_transaction_name;

						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_id = document.getElementById('pay_cash_desk_content_check_spec_1_transaction_id').value;
						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_detail = document.getElementById('pay_cash_desk_content_check_spec_1_transaction_detail').value;

					}else if  (transaction_type == "SPEC2"){
						document.getElementById('pay_cash_desk_content_check_spec_2_transaction_id').value = ccv_output.payment_transaction_name;
						document.getElementById('pay_cash_desk_content_check_spec_2_transaction_detail').value = ccv_output.payment_transaction_name;

						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_id = document.getElementById('pay_cash_desk_content_check_spec_2_transaction_id').value;
						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_detail = document.getElementById('pay_cash_desk_content_check_spec_2_transaction_detail').value;

					}else if  (transaction_type == "SPEC3"){
						document.getElementById('pay_cash_desk_content_check_spec_3_transaction_id').value = ccv_output.payment_transaction_name;
						document.getElementById('pay_cash_desk_content_check_spec_3_transaction_detail').value = ccv_output.payment_transaction_name;

						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_id = document.getElementById('pay_cash_desk_content_check_spec_3_transaction_id').value;
						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_detail = document.getElementById('pay_cash_desk_content_check_spec_3_transaction_detail').value;

					}

					d.primary_action();
				}


				document.getElementById('pay_cash_desk_ccv_div').onmouseover = fct_ccv_mouseover;
				document.getElementById('pay_cash_desk_ccv_div').onmouseleave = fct_ccv_mouseleave;
				document.getElementById('pay_cash_desk_ccv_div').onclick = fct_ccv_click;

			}



			let fct_key_down = function (event) {

				if (event.keyCode == 13) {

					if (this.id == 'pay_cash_desk_content_cash_value') {

						if (techno == 'EPOSDEVICE') {
							if ((p_ipaddress.trim() != '') && (p_port != '') && (p_port != '0')) {
								let epson_print = new silicon_ioi.doctype.ioiEPosDevice(p_ipaddress, p_port, 500, 'DRAWER_PULSE', false, null, null);
							}
						}
					}

					return false;
				}
			}


			let fct_change_value = function(event) {

				if (event.keyCode == 13) {
					return false;
				}

				if (event.target != $('#pay_cash_desk_content_cash_value')[0]){
					$('#pay_cash_desk_content_cash_value')?.val('');
					document.getElementById('pay_cash_desk_content_cash_rounding_value').value = 0;
				}else{
					let cash_value = 0;

					if ((document.getElementById('pay_cash_desk_content_cash_value').value) && (document.getElementById('pay_cash_desk_content_cash_value').value != '')) {
						cash_value = parseFloat(document.getElementById('pay_cash_desk_content_cash_value').value)
					}

					if (parseFloat(cash_value) >= parseFloat(par_total_still_to_pay) + parseFloat(cash_rounding)) {
						document.getElementById('pay_cash_desk_content_cash_rounding_value').value = cash_rounding;
					}else{
						document.getElementById('pay_cash_desk_content_cash_rounding_value').value = 0;
					}
				}
				if (event.target != $('#pay_cash_desk_content_bank_card_value')[0]){
					$('#pay_cash_desk_content_bank_card_value')?.val('');
				}
				if (event.target != $('#pay_cash_desk_content_credit_card_value')[0]){
					$('#pay_cash_desk_content_credit_card_value')?.val('');
				}
				if (event.target != $('#pay_cash_desk_content_check_value')[0]){
					$('#pay_cash_desk_content_check_value')?.val('');
				}
				if (event.target != $('#pay_cash_desk_content_emoney_value')[0]){
					$('#pay_cash_desk_content_emoney_value')?.val('');
				}
				if (event.target != $('#pay_cash_desk_content_check_spec_1_value')[0]){
					$('#pay_cash_desk_content_check_spec_1_value')?.val('');
				}
				if (event.target != $('#pay_cash_desk_content_check_spec_2_value')[0]){
					$('#pay_cash_desk_content_check_spec_2_value')?.val('');
				}
				if (event.target != $('#pay_cash_desk_content_check_spec_3_value')[0]){
					$('#pay_cash_desk_content_check_spec_3_value')?.val('');
				}

				silicon_ioi.doctype.ioiCashDesk.compute_still_to_pay(this.id, currency_id, par_total_still_to_pay, max_cash_back, cash_rounding);

				silicon_ioi.doctype.ioiCashDesk.memorize_fields(this);
			}

			function fct_focus_out(fo_event){
				if (parseFloat(fo_event.target.value) >  parseFloat(par_total_still_to_pay)){
					fo_event.target.value = parseFloat(par_total_still_to_pay).toFixed(2);
					let ev = new Event('input');
					fo_event.target.dispatchEvent(ev);
					frappe.throw(__("No cash back for this type of payment"))
				}
			}

			if (document.getElementById('pay_cash_desk_content_cash_value')) {
				document.getElementById('pay_cash_desk_content_cash_value').oninput = fct_change_value;
				document.getElementById('pay_cash_desk_content_cash_value').onkeydown = fct_key_down;
			}

			if (document.getElementById('pay_cash_desk_content_bank_card_value')) {
				document.getElementById('pay_cash_desk_content_bank_card_value').oninput = fct_change_value;
			}

			if (document.getElementById('pay_cash_desk_content_credit_card_value')) {
				document.getElementById('pay_cash_desk_content_credit_card_value').oninput = fct_change_value;
				document.getElementById('pay_cash_desk_content_credit_card_value').onfocusout = fct_focus_out;
			}

			if (document.getElementById('pay_cash_desk_content_check_value')) {
				document.getElementById('pay_cash_desk_content_check_value').oninput = fct_change_value;
				document.getElementById('pay_cash_desk_content_check_value').onfocusout = fct_focus_out;
			}

			if (document.getElementById('pay_cash_desk_content_emoney_value')) {
				document.getElementById('pay_cash_desk_content_emoney_value').oninput = fct_change_value;
			}

			if (document.getElementById('pay_cash_desk_content_check_spec_1_value')) {
				document.getElementById('pay_cash_desk_content_check_spec_1_value').oninput = fct_change_value;
				document.getElementById('pay_cash_desk_content_check_spec_1_value').onfocusout = fct_focus_out;
			}

			if (document.getElementById('pay_cash_desk_content_check_spec_2_value')) {
				document.getElementById('pay_cash_desk_content_check_spec_2_value').oninput = fct_change_value;
				document.getElementById('pay_cash_desk_content_check_spec_2_value').onfocusout = fct_focus_out;
			}

			if (document.getElementById('pay_cash_desk_content_check_spec_3_value')) {
				document.getElementById('pay_cash_desk_content_check_spec_3_value').oninput = fct_change_value;
				document.getElementById('pay_cash_desk_content_check_spec_3_value').onfocusout = fct_focus_out;
			}

			let fct_other_key_up = function () {
				silicon_ioi.doctype.ioiCashDesk.memorize_fields(this);
			}



			if (document.getElementById('pay_cash_desk_content_check_id')) {
				document.getElementById('pay_cash_desk_content_check_id').onkeyup = fct_other_key_up;
			}

			if (document.getElementById('pay_cash_desk_content_check_spec_1_id')) {
				document.getElementById('pay_cash_desk_content_check_spec_1_id').onkeyup = fct_other_key_up;
			}

			if (document.getElementById('pay_cash_desk_content_check_spec_2_id')) {
				document.getElementById('pay_cash_desk_content_check_spec_2_id').onkeyup = fct_other_key_up;
			}

			if (document.getElementById('pay_cash_desk_content_check_spec_3_id')) {
				document.getElementById('pay_cash_desk_content_check_spec_3_id').onkeyup = fct_other_key_up;
			}


			let fct_button_keyup = function (event) {

				if (event.keyCode == 13) {
					return false;
				}
			}

			// Cash back
			if (document.getElementById('pay_cash_desk_content_cash_back_value')) {
				document.getElementById('pay_cash_desk_content_cash_back_value').onkeyup = fct_change_value;
			}

			if (document.getElementById('pay_cash_desk_content_bank_card_back_value')) {
				document.getElementById('pay_cash_desk_content_bank_card_back_value').onkeyup = fct_change_value;
			}

			if (document.getElementById('pay_cash_desk_content_emoney_back_value')) {
				document.getElementById('pay_cash_desk_content_emoney_back_value').onkeyup = fct_change_value;
			}

			let fct_id_keyup = function () {

				if (this.id == 'pay_cash_desk_content_check_id') {
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_id = this.value;
				}else if (this.id == 'pay_cash_desk_content_check_spec_1_id') {
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_id = this.value;
				}else if (this.id == 'pay_cash_desk_content_check_spec_2_id') {
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_id = this.value;
				}else if (this.id == 'pay_cash_desk_content_check_spec_3_id') {
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_id = this.value;
				}


				if (document.getElementById('ioi_sales_pos_pay_button_pay_deliver')) {

					if (silicon_ioi.doctype.ioiCashDesk.has_pending_payment()) {
						document.getElementById('ioi_sales_pos_pay_button_pay_deliver').src = '/assets/silicon_ioi/images/buttons/pay_deliver_warning.svg';
					}else{
						document.getElementById('ioi_sales_pos_pay_button_pay_deliver').src = '/assets/silicon_ioi/images/buttons/pay_deliver.svg';
					}
				}
			}


			if (document.getElementById('pay_cash_desk_content_check_id')) {
				document.getElementById('pay_cash_desk_content_check_id').onkeyup = fct_id_keyup;
			}

			if (document.getElementById('pay_cash_desk_content_check_spec_1_id')) {
				document.getElementById('pay_cash_desk_content_check_spec_1_id').onkeyup = fct_id_keyup;
			}

			if (document.getElementById('pay_cash_desk_content_check_spec_2_id')) {
				document.getElementById('pay_cash_desk_content_check_spec_2_id').onkeyup = fct_id_keyup;
			}

			if (document.getElementById('pay_cash_desk_content_check_spec_3_id')) {
				document.getElementById('pay_cash_desk_content_check_spec_3_id').onkeyup = fct_id_keyup;
			}

			function fct_label_click(e){
				let input;
				let amount;
				switch(e.target.id){
					case 'pay_cash_desk_content_cash_value_label':
						amount = parseFloat(par_total_still_to_pay);
						input = document.getElementById('pay_cash_desk_content_cash_value');
						input.value = amount;

						break;
					case 'pay_cash_desk_content_bank_card_value_label':
						amount = parseFloat(par_total_still_to_pay);
						input = document.getElementById('pay_cash_desk_content_bank_card_value');
						input.value = amount;

						break;
					case 'pay_cash_desk_content_credit_card_value_label':
						amount = parseFloat(par_total_still_to_pay);
						input = document.getElementById('pay_cash_desk_content_credit_card_value');
						input.value = amount;

						break;
					case 'pay_cash_desk_content_check_value_label':
						amount = parseFloat(par_total_still_to_pay);
						input = document.getElementById('pay_cash_desk_content_check_value');
						input.value = amount;

						break;
					case 'pay_cash_desk_content_emoney_value_label':
						amount = parseFloat(par_total_still_to_pay);
						input = document.getElementById('pay_cash_desk_content_emoney_value');
						input.value = amount;

						break;
					case 'pay_cash_desk_content_check_spec_1_value_label':

						if (parseFloat(max_spec1_amount) != 0) {
							let already_check_spec_1_value = 0;

							if (document.getElementById('pay_cash_desk_content_already_check_spec_1_value').value) {
								already_check_spec_1_value = parseFloat(document.getElementById('pay_cash_desk_content_already_check_spec_1_value').value)
							}
							amount = parseFloat((parseFloat(max_spec1_amount) - parseFloat(already_check_spec_1_value)).toFixed(2));
						}else{
							amount = parseFloat(par_total_still_to_pay);
						}
						input = document.getElementById('pay_cash_desk_content_check_spec_1_value');
						input.value = amount;

						break;
					case 'pay_cash_desk_content_check_spec_2_value_label':

						if (parseFloat(max_spec2_amount) != 0) {

							let already_check_spec_2_value = 0;

							if (document.getElementById('pay_cash_desk_content_already_check_spec_2_value').value) {
								already_check_spec_2_value = parseFloat(document.getElementById('pay_cash_desk_content_already_check_spec_2_value').value)
							}
							amount = parseFloat((parseFloat(max_spec2_amount) - parseFloat(already_check_spec_2_value)).toFixed(2));
						}else{
							amount = parseFloat(par_total_still_to_pay);
						}
						input = document.getElementById('pay_cash_desk_content_check_spec_2_value');
						input.value = amount;

						break;
					case 'pay_cash_desk_content_check_spec_3_value_label':

						if (parseFloat(max_spec3_amount) != 0) {
							let already_check_spec_3_value = 0;

							if (document.getElementById('pay_cash_desk_content_already_check_spec_3_value').value) {
								already_check_spec_3_value = parseFloat(document.getElementById('pay_cash_desk_content_already_check_spec_3_value').value)
							}
							amount = parseFloat((parseFloat(max_spec3_amount) - parseFloat(already_check_spec_3_value)).toFixed(2));
						}else{
							amount = parseFloat(par_total_still_to_pay);
						}
						input = document.getElementById('pay_cash_desk_content_check_spec_3_value');
						input.value = amount;

						break;
			}

				if (input){

					let ev = new Event('input');
					input.dispatchEvent(ev);

				}
			}

			function fct_label_mouse_enter(me_event){
				me_event.target.style.fontWeight = 'bold'
			}

			function fct_label_mouse_leave(ml_event){
				ml_event.target.style.fontWeight = ''
			}

			let labels = [	'pay_cash_desk_content_cash_value_label','pay_cash_desk_content_bank_card_value_label', 'pay_cash_desk_content_credit_card_value_label',
							'pay_cash_desk_content_check_value_label', 'pay_cash_desk_content_emoney_value_label', 'pay_cash_desk_content_check_spec_1_value_label',
							'pay_cash_desk_content_check_spec_2_value_label', 'pay_cash_desk_content_check_spec_3_value_label']

			labels.forEach((el) => {
				if (document.getElementById(el)){
					document.getElementById(el).onclick = fct_label_click;
					document.getElementById(el).onmouseenter = fct_label_mouse_enter;
					document.getElementById(el).onmouseleave = fct_label_mouse_leave;
				}
			})

			silicon_ioi.doctype.ioiCashDesk.compute_still_to_pay_onload(currency_id, par_total_still_to_pay);
			silicon_ioi.doctype.ioiCashDesk.compute_still_to_pay(null, currency_id, par_total_still_to_pay, max_cash_back);

			if (document.getElementById('pay_cash_desk_content_cash_value')) {
				document.getElementById('pay_cash_desk_content_cash_value').focus();
			}else if (document.getElementById('pay_cash_desk_content_bank_card_value')) {
				document.getElementById('pay_cash_desk_content_bank_card_value').focus();
			}else if (document.getElementById('pay_cash_desk_content_credit_card_value')) {
				document.getElementById('pay_cash_desk_content_credit_card_value').focus();
			}else if (document.getElementById('pay_cash_desk_content_check_value')) {
				document.getElementById('pay_cash_desk_content_check_value').focus();
			}else if (document.getElementById('pay_cash_desk_content_emoney_value')) {
				document.getElementById('pay_cash_desk_content_emoney_value').focus();
			}else if (document.getElementById('pay_cash_desk_content_check_spec_1_value')) {
				document.getElementById('pay_cash_desk_content_check_spec_1_value').focus();
			}else if (document.getElementById('pay_cash_desk_content_check_spec_2_value')) {
				document.getElementById('pay_cash_desk_content_check_spec_2_value').focus();
			}else if (document.getElementById('pay_cash_desk_content_check_spec_3_value')) {
				document.getElementById('pay_cash_desk_content_check_spec_3_value').focus();
			}
		});
	}

	static memorize_fields(obj)
	{
		if (obj.id == 'pay_cash_desk_content_cash_value') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_cash_value = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_bank_card_value') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_value = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_credit_card_value') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_value = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_check_value') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_value = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_emoney_value') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_value = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_check_spec_1_value') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_value = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_check_spec_2_value') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_value = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_check_spec_3_value') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_value = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_cash_back_value') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_cash_back_value = obj.value;


		}else if (obj.id == 'pay_cash_desk_content_bank_card_back_value') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_back_value = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_bank_card_transaction_id') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_id = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_bank_card_transaction_detail') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_detail = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_credit_card_transaction_id') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_id = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_credit_card_transaction_detail') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_detail = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_check_id') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_id = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_emoney_back_value') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_back_value = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_emoney_transaction_id') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_transaction_id = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_emoney_transaction_detail') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_transaction_detail = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_check_spec_1_id') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_id = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_check_spec_1_transaction_id') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_id = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_check_spec_1_transaction_id') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_id = obj.value;



		}else if (obj.id == 'pay_cash_desk_content_check_spec_2_id') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_id = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_check_spec_2_transaction_id') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_id = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_check_spec_2_transaction_id') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_id = obj.value;


		}else if (obj.id == 'pay_cash_desk_content_check_spec_3_id') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_id = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_check_spec_3_transaction_id') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_id = obj.value;

		}else if (obj.id == 'pay_cash_desk_content_check_spec_3_transaction_id') {
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_id = obj.value;
		}

		if (document.getElementById('ioi_sales_pos_pay_button_pay_deliver')) {

			if (silicon_ioi.doctype.ioiCashDesk.has_pending_payment()) {
				document.getElementById('ioi_sales_pos_pay_button_pay_deliver').src = '/assets/silicon_ioi/images/buttons/pay_deliver_warning.svg';
			}else{
				document.getElementById('ioi_sales_pos_pay_button_pay_deliver').src = '/assets/silicon_ioi/images/buttons/pay_deliver.svg';
			}
		}


	}

	static create_payment_from_crash_transaction(doctype, doctype_name, cashdesk_id, d)
	{
		if ((parseFloat(document.getElementById('pay_cash_desk_content_credit_card_value')?.value) > 0) ||
			(parseFloat(document.getElementById('pay_cash_desk_content_bank_card_value')?.value) > 0) ||
			(parseFloat(document.getElementById('pay_cash_desk_content_cash_value')?.value) > 0) ||
			(parseFloat(document.getElementById('pay_cash_desk_content_emoney_value')?.value) > 0) ||
			(parseFloat(document.getElementById('pay_cash_desk_content_check_spec_1_value')?.value) > 0) ||
			(parseFloat(document.getElementById('pay_cash_desk_content_check_spec_2_value')?.value) > 0) ||
			(parseFloat(document.getElementById('pay_cash_desk_content_check_spec_3_value')?.value) > 0)){
			frappe.throw(__("All amount values have top be empty"));
		}

		let title = __("Payment transaction not completed");


		var p = new frappe.ui.Dialog({
			'title': title,
			'fields': [
				{'fieldname': 'html_crash_transaction', 'fieldtype': 'HTML'}

			],
			primary_action_label:  __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){


				if (!document.getElementById('html_crash_transaction_grid_detail')) {

					frappe.msgprint({title: __("Message"), message: __('No transaction selected'), indicator: "red"});
					raise;
				}

				if (!document.getElementById('html_crash_transaction_grid_detail_checked_id_0')) {

					frappe.msgprint({title: __("Message"), message: __('No transaction selected'), indicator: "red"});
					raise;
				}

				let one_selected = false;
				let transaction_id = ''
				let platform_id = '';
				let amount = 0;

				for (var i = 0; i < document.getElementById('html_crash_transaction_grid_detail').rows.length; i++) {

					if (document.getElementById('html_crash_transaction_grid_detail_checked_id_' + i.toString())) {

						if (document.getElementById('html_crash_transaction_grid_detail_checked_id_' + i.toString()).checked) {

							one_selected = true;
							transaction_id = document.getElementById('html_crash_transaction_grid_detail_name_' + i.toString()).value;
							platform_id = document.getElementById('html_crash_transaction_grid_detail_platform_' + i.toString()).value;
							amount = document.getElementById('html_crash_transaction_grid_detail_amount_' + i.toString()).value;
							break;
						}
					}
				}

				if (!one_selected) {

					frappe.msgprint({title: __("Message"), message: __('No transaction selected'), indicator: "red"});
					raise;

				}

				p.hide();

				if (platform_id.toUpperCase() == 'PAYCONIQ') {

					document.getElementById('pay_cash_desk_content_bank_card_transaction_id').value = transaction_id;
					document.getElementById('pay_cash_desk_content_bank_card_transaction_detail').value = transaction_id;

					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_id = document.getElementById('pay_cash_desk_content_bank_card_transaction_id').value;
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_detail = document.getElementById('pay_cash_desk_content_bank_card_transaction_detail').value;

					document.getElementById('pay_cash_desk_content_bank_card_value').value = amount;

					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_type = 'BANK';
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_subtype = 'PAYCONIQ';
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status = 0;


					d.primary_action();


				}else if (platform_id.toUpperCase() == 'CCV') {
					document.getElementById('pay_cash_desk_content_bank_card_transaction_id').value = transaction_id;
					document.getElementById('pay_cash_desk_content_bank_card_transaction_detail').value = transaction_id;

					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_id = document.getElementById('pay_cash_desk_content_bank_card_transaction_id').value;
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_detail = document.getElementById('pay_cash_desk_content_bank_card_transaction_detail').value;

					document.getElementById('pay_cash_desk_content_bank_card_value').value = amount;

					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_type = 'BANK';
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_subtype = 'CCV';
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_pay_status = 0;

					d.primary_action();
				}

			},

			secondary_action: function(){
				p.hide();
			}
		});



		let html = '';

		let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		html = silicon_ioi.doctype.ioiCashDesk.refresh_payment_transaction_list(doctype, doctype_name, cashdesk_id, is_dark_mode);

		p.fields_dict.html_crash_transaction.$wrapper.html(html);
		p.$wrapper.find('.modal-dialog').css("max-width", "580px").css("width", "580px");
		p.show();

		silicon_ioi.doctype.ioiCashDesk.sleep_static(500).then(() => {

			if (document.getElementById('html_crash_transaction_grid_detail')) {

				let fct_click = function() {

					for (var i = 0; i < document.getElementById('html_crash_transaction_grid_detail').rows.length; i++) {
						if (document.getElementById('html_crash_transaction_grid_detail_checked_id_' + i.toString())) {
							document.getElementById('html_crash_transaction_grid_detail_checked_id_' + i.toString()).checked = false;
						}
					}

					this.checked = true;


				};

				for (var i = 0; i < document.getElementById('html_crash_transaction_grid_detail').rows.length; i++) {
					if (document.getElementById('html_crash_transaction_grid_detail_checked_id_' + i.toString())) {
						document.getElementById('html_crash_transaction_grid_detail_checked_id_' + i.toString()).onclick = fct_click;
					}
				}
			}

		});

	}


	static refresh_payment_transaction_list(doctype, name, cashdesk_id, is_dark_mode)
	{

		if (document.getElementById('html_crash_transaction_content_grid')) {
			document.getElementById('html_crash_transaction_content_grid').remove();
		}

		if (document.getElementById('html_crash_transaction_grid_header')) {
			document.getElementById('html_crash_transaction_grid_header').remove();
		}


		if (document.getElementById('html_crash_transaction_grid_detail')) {

			for (var i = 0; i < document.getElementById('html_crash_transaction_grid_detail').rows.length; i++) {

				if (document.getElementById('html_crash_transaction_grid_detail_checked_id_' + i.toString())) {
					document.getElementById('html_crash_transaction_grid_detail_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('html_crash_transaction_grid_detail_name_' + i.toString())) {
					document.getElementById('html_crash_transaction_grid_detail_name_' + i.toString()).remove();
				}

				if (document.getElementById('html_crash_transaction_grid_detail_platform_' + i.toString())) {
					document.getElementById('html_crash_transaction_grid_detail_platform_' + i.toString()).remove();
				}
				if (document.getElementById('html_crash_transaction_grid_detail_amount_' + i.toString())) {
					document.getElementById('html_crash_transaction_grid_detail_amount_' + i.toString()).remove();
				}
			}

			document.getElementById('html_crash_transaction_grid_detail').remove()
		}


		let html = '';

		html += '<div id="html_crash_transaction_content_grid" style="overflow-x: auto;height:200px;">';

		let bgcolor = '';

		if (is_dark_mode == 0) {
			bgcolor = '#D0E7FB';
		}else{
			bgcolor = '#1C2126';
		}

		html += '<table id="html_crash_transaction_grid_header" bgcolor="' + bgcolor + '" width=530px border=1 style="border: 1px solid #E8EAEB">';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Operation date") + '</b></td>';
		html += '<td width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Platform") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Amount") + '</b></td>';
		html += '<td width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Currency") + '</b></td>';
		html += '</tr>';
		html += '</table>';


		let method = path_cash_desk_movement + '.ioi_cash_desk_movement_crash_payment_transaction_list'

		frappe.call({  	method: method,
						args: 	{	"doctype": doctype,
									"name": name,
									"cashdesk_id": cashdesk_id
						},
						async: false,
						callback: function(r)	{


							if (r.message.length == 0) {

								html += '<table id="html_crash_transaction_grid_detail" width=530px border=1 style="border: 1px solid #E8EAEB">';
								html += '<tr style="height:30px">';
								html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
								html += '</tr>';
								html += '</table>';


							}else{
								html += '<table id="html_crash_transaction_grid_detail" width=530px border=1 style="border: 1px solid #E8EAEB">';

								for (var i = 0; i < r.message.length; i++) {

									html += '<input id="html_crash_transaction_grid_detail_name_' + i.toString() + '" type="hidden" value="' + r.message[i].name + '">';
									html += '<input id="html_crash_transaction_grid_detail_platform_' + i.toString() + '" type="hidden" value="' + r.message[i].platform + '">';
									html += '<input id="html_crash_transaction_grid_detail_amount_' + i.toString() + '" type="hidden" value="' + r.message[i].amount + '">';

									let bgcolor_is_set = false;

									if (r.message[i].record_cancelled == 'Y') {
										html += '<tr bgcolor="#D7D7D9" style="height:30px">';
										bgcolor_is_set = true;
									}else{
										html += '<tr style="height:30px">';
									}


									html += '<td width=30px style="vertical-align: middle;" align="center">';

									html += '<input type="checkbox" id="html_crash_transaction_grid_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 2px; left: 2px;" ';
									if (i == 0) {
										html += ' checked ';
									}
									html += '>';


									html += '</td>';

									if (r.message[i].modified != null) {
										html += '<td width=150px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '">&nbsp;' + r.message[i].modified.toString().substring(0, 16) + '</td>';
									}else{
										html += '<td width=150px style="vertical-align: middle;">&nbsp;></td>';
									}

									if (r.message[i].platform != null) {
										html += '<td width=150px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '">&nbsp;' + r.message[i].platform + '</td>';
									}else{
										html += '<td width=150px style="vertical-align: middle;" align="right">&nbsp;</td>';
									}



									if (r.message[i].amount != null) {
										html += '<td width=120px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '" align="right">' + r.message[i].amount.toFixed(2).toString() + '&nbsp;</td>';
									}else{
										html += '<td width=120px style="vertical-align: middle;" align="right">&nbsp;</td>';
									}

									if (r.message[i].currency != null) {
										html += '<td width=80px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '">&nbsp;' + r.message[i].currency + '</td>';
									}else{
										html += '<td width=80px style="vertical-align: middle;" align="right">&nbsp;</td>';
									}

									html += '</tr>';
								}

								html += '</table>';


							}
						}
		});


		html += '</div>';

		return html;
	}



	static compute_still_to_pay(id, currency_id, par_total_still_to_pay, max_cash_back, cash_rounding = 0)
	{
		let total_filled = 0;

		if (document.getElementById('pay_cash_desk_content_cash_value')) {
			if (document.getElementById('pay_cash_desk_content_cash_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_cash_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_bank_card_value')) {
			if (document.getElementById('pay_cash_desk_content_bank_card_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_bank_card_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_credit_card_value')) {
			if (document.getElementById('pay_cash_desk_content_credit_card_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_credit_card_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_check_value')) {
			if (document.getElementById('pay_cash_desk_content_check_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_check_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_emoney_value')) {
			if (document.getElementById('pay_cash_desk_content_emoney_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_emoney_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_1_value')) {
			if (document.getElementById('pay_cash_desk_content_check_spec_1_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_check_spec_1_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_2_value')) {
			if (document.getElementById('pay_cash_desk_content_check_spec_2_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_check_spec_2_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_3_value')) {
			if (document.getElementById('pay_cash_desk_content_check_spec_3_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_check_spec_3_value').value);
			}
		}


		// Cash back

		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_cash_back_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_back_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_back_value = '';

		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_back_unallowed_value = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_back_unallowed_value = '';


		//let still_to_pay = parseFloat(par_total_still_to_pay) - parseFloat(total_filled);


		if (document.getElementById('pay_cash_desk_content_cash_back_value')) {
			document.getElementById('pay_cash_desk_content_cash_back_value').value = '';
		}

		if (document.getElementById('pay_cash_desk_content_bank_card_back_value')) {
			document.getElementById('pay_cash_desk_content_bank_card_back_value').value = '';
		}

		if (document.getElementById('pay_cash_desk_content_emoney_back_value')) {
			document.getElementById('pay_cash_desk_content_emoney_back_value').value = '';
		}

		if (document.getElementById('pay_cash_desk_content_bank_card_back_unallowed_value')) {
			document.getElementById('pay_cash_desk_content_bank_card_back_unallowed_value').value = '';
		}

		if (document.getElementById('pay_cash_desk_content_emoney_back_unallowed_value')) {
			document.getElementById('pay_cash_desk_content_emoney_back_unallowed_value').value = '';
		}



		let cash_value = 0;
		let cash_back_value = 0;
		let bank_value = 0;
		let bank_back_value = 0;
		let emoney_value = 0;
		let emoney_back_value = 0;

		let total_unallowed = 0;

		let max_bank_emoney_cash_back = max_cash_back;


		if (document.getElementById('pay_cash_desk_content_cash_value')) {

			if ((document.getElementById('pay_cash_desk_content_cash_value').value != '') && (document.getElementById('pay_cash_desk_content_cash_value').value != 0)) {
				cash_value = parseFloat(document.getElementById('pay_cash_desk_content_cash_value').value);
			}


			if (parseFloat(cash_value) != 0) {

				if (parseFloat(par_total_still_to_pay) - parseFloat(cash_value) + (cash_rounding) < 0) {

					let exp = Math.pow(10, 2);

					cash_back_value = Math.round( ((parseFloat(par_total_still_to_pay) - parseFloat(cash_value)) * -1.00)  * exp) / exp;
					cash_back_value += (cash_rounding * -1);
					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_cash_back_value = cash_back_value;
					document.getElementById('pay_cash_desk_content_cash_back_value').value = cash_back_value;

				}
			}

		}

		if (document.getElementById('pay_cash_desk_content_bank_card_value')) {

			if ((document.getElementById('pay_cash_desk_content_bank_card_value').value != '') && (document.getElementById('pay_cash_desk_content_bank_card_value').value != 0)) {
				bank_value = parseFloat(document.getElementById('pay_cash_desk_content_bank_card_value').value);
			}

			if ((parseFloat(par_total_still_to_pay) - parseFloat(cash_value)) > 0) {

				if (parseFloat(bank_value) != 0) {

					if (parseFloat(par_total_still_to_pay) - parseFloat(cash_value) - parseFloat(bank_value) < 0) {

						bank_back_value = (parseFloat(par_total_still_to_pay) - parseFloat(cash_value) - parseFloat(bank_value)) * -1.00;

						if (parseFloat(bank_back_value) > parseFloat(max_bank_emoney_cash_back)) {

							let exp = Math.pow(10, 2);

							total_unallowed = total_unallowed + (Math.round( (parseFloat(bank_back_value) - parseFloat(max_bank_emoney_cash_back)) * exp) / exp);

							document.getElementById('pay_cash_desk_content_bank_card_back_unallowed_value').value = Math.round( (parseFloat(bank_back_value) - parseFloat(max_bank_emoney_cash_back)) * exp) / exp;
							silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_back_unallowed_value = Math.round( (parseFloat(bank_back_value) - parseFloat(max_bank_emoney_cash_back)) * exp) / exp;
							bank_back_value = parseFloat(max_bank_emoney_cash_back);
							max_bank_emoney_cash_back = 0;
						}

						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_back_value = bank_back_value;
						document.getElementById('pay_cash_desk_content_bank_card_back_value').value = bank_back_value;



					}
				}
			}else{

				if (parseFloat(bank_value) != 0) {

					bank_back_value = bank_value;

					if (parseFloat(bank_back_value) > parseFloat(max_bank_emoney_cash_back)) {

						let exp = Math.pow(10, 2);

						total_unallowed = total_unallowed + (Math.round( (parseFloat(bank_back_value) - parseFloat(max_bank_emoney_cash_back)) * exp) / exp);

						document.getElementById('pay_cash_desk_content_bank_card_back_unallowed_value').value = Math.round( (parseFloat(bank_back_value) - parseFloat(max_bank_emoney_cash_back)) * exp) / exp;
						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_back_unallowed_value = Math.round( (parseFloat(bank_back_value) - parseFloat(max_bank_emoney_cash_back)) * exp) / exp;
						bank_back_value = parseFloat(max_bank_emoney_cash_back);
						max_bank_emoney_cash_back = 0;
					}

					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_back_value = bank_back_value;
					document.getElementById('pay_cash_desk_content_bank_card_back_value').value = bank_back_value;
				}

			}
		}


		if (document.getElementById('pay_cash_desk_content_emoney_value')) {

			if ((document.getElementById('pay_cash_desk_content_emoney_value').value != '') && (document.getElementById('pay_cash_desk_content_emoney_value').value != 0)) {
				emoney_value = parseFloat(document.getElementById('pay_cash_desk_content_emoney_value').value);
			}

			if ((parseFloat(par_total_still_to_pay) - parseFloat(cash_value) - parseFloat(bank_value)) > 0) {

				if (parseFloat(emoney_value) != 0) {

					if (parseFloat(par_total_still_to_pay) - parseFloat(cash_value) - parseFloat(bank_value) - parseFloat(emoney_value) < 0) {

						emoney_back_value = (parseFloat(par_total_still_to_pay) - parseFloat(cash_value) - parseFloat(bank_value) - parseFloat(emoney_value)) * -1.00;

						if (parseFloat(emoney_back_value) > parseFloat(max_bank_emoney_cash_back)) {

							let exp = Math.pow(10, 2);

							total_unallowed = total_unallowed + (Math.round( (parseFloat(emoney_back_value) - parseFloat(max_bank_emoney_cash_back)) * exp) / exp);

							document.getElementById('pay_cash_desk_content_emoney_back_unallowed_value').value = Math.round( (parseFloat(emoney_back_value) - parseFloat(max_bank_emoney_cash_back)) * exp) / exp;
							silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_back_unallowed_value = Math.round( (parseFloat(emoney_back_value) - parseFloat(max_bank_emoney_cash_back)) * exp) / exp;
							emoney_back_value = parseFloat(max_bank_emoney_cash_back);
							max_bank_emoney_cash_back = 0;
						}

						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_back_value = emoney_back_value;
						document.getElementById('pay_cash_desk_content_emoney_back_value').value = emoney_back_value;
					}
				}

			}else{

				if (parseFloat(emoney_value) != 0) {

					emoney_back_value = emoney_value;

					if (parseFloat(emoney_back_value) > parseFloat(max_bank_emoney_cash_back)) {

						let exp = Math.pow(10, 2);

						total_unallowed = total_unallowed + (Math.round( (parseFloat(emoney_back_value) - parseFloat(max_bank_emoney_cash_back)) * exp) / exp);


						document.getElementById('pay_cash_desk_content_emoney_back_unallowed_value').value = Math.round( (parseFloat(emoney_back_value) - parseFloat(max_bank_emoney_cash_back)) * exp) / exp;
						silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_back_unallowed_value = Math.round( (parseFloat(emoney_back_value) - parseFloat(max_bank_emoney_cash_back)) * exp) / exp;
						emoney_back_value = parseFloat(max_bank_emoney_cash_back);
						max_bank_emoney_cash_back = 0;
					}


					silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_back_value = emoney_back_value;
					document.getElementById('pay_cash_desk_content_emoney_back_value').value = emoney_back_value;
				}

			}
		}



		let total_back = cash_back_value + bank_back_value + emoney_back_value;

		let exp = Math.pow(10, 2);



		if (document.getElementById('pay_total_payment')) {
			document.getElementById('pay_total_payment').innerHTML = parseFloat(Math.round(parseFloat(total_filled) * exp) / exp).toFixed(2).toString() + ' ' + currency_id;
		}

		if (document.getElementById('pay_total_cash_back')) {
			document.getElementById('pay_total_cash_back').innerHTML = parseFloat(Math.round(parseFloat(total_back) * exp) / exp).toFixed(2).toString() + ' ' + currency_id;
		}

		if (document.getElementById('pay_total_unallowed_cash_back')) {
			document.getElementById('pay_total_unallowed_cash_back').innerHTML = parseFloat(Math.round(parseFloat(total_unallowed) * exp) / exp).toFixed(2).toString() + ' ' + currency_id;
		}


		let still_to_pay = parseFloat(par_total_still_to_pay) - parseFloat(total_filled) + parseFloat(total_back) + parseFloat(cash_rounding);


		let me = this;
		let color = '#60A367';



		if (Math.round(parseFloat(still_to_pay) * exp) / exp > 0) {
			color = '#ED514C';
		}

		document.getElementsByClassName('modal-dialog')[document.getElementsByClassName('modal-dialog').length-1].getElementsByClassName('modal-content')[0].getElementsByClassName('modal-footer')[0].getElementsByClassName('standard-actions')[0].getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[0].hidden = false;

		if (Math.round(parseFloat(still_to_pay) * exp) / exp < 0) {
			document.getElementsByClassName('modal-dialog')[document.getElementsByClassName('modal-dialog').length-1].getElementsByClassName('modal-content')[0].getElementsByClassName('modal-footer')[0].getElementsByClassName('standard-actions')[0].getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[0].hidden = true;
		}

		silicon_ioi.doctype.ioiCashDesk.rounded_still_to_paid = Math.round(parseFloat(still_to_pay) * exp) / exp;


		let still_value = '<b><font color="' + color + '">' + parseFloat(silicon_ioi.doctype.ioiCashDesk.rounded_still_to_paid).toFixed(2).toString() + ' ' + currency_id + '</font></b>';


		document.getElementById('pay_still_to_pay').innerHTML = still_value;
	}



	static compute_still_to_pay_onload(currency_id, par_total_still_to_pay)
	{
		let total_filled = 0;

		if (document.getElementById('pay_cash_desk_content_cash_value')) {
			if (document.getElementById('pay_cash_desk_content_cash_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_cash_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_bank_card_value')) {
			if (document.getElementById('pay_cash_desk_content_bank_card_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_bank_card_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_credit_card_value')) {
			if (document.getElementById('pay_cash_desk_content_credit_card_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_credit_card_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_check_value')) {
			if (document.getElementById('pay_cash_desk_content_check_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_check_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_emoney_value')) {
			if (document.getElementById('pay_cash_desk_content_emoney_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_emoney_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_1_value')) {
			if (document.getElementById('pay_cash_desk_content_check_spec_1_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_check_spec_1_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_2_value')) {
			if (document.getElementById('pay_cash_desk_content_check_spec_2_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_check_spec_2_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_check_spec_3_value')) {
			if (document.getElementById('pay_cash_desk_content_check_spec_3_value').value != '') {
				total_filled += parseFloat(document.getElementById('pay_cash_desk_content_check_spec_3_value').value);
			}
		}

		// Cash back
		if (document.getElementById('pay_cash_desk_content_cash_back_value')) {
			if (document.getElementById('pay_cash_desk_content_cash_back_value').value != '') {
				total_filled -= parseFloat(document.getElementById('pay_cash_desk_content_cash_back_value').value);
			}
		}

		if (document.getElementById('pay_cash_desk_content_bank_card_back_value')) {
			if (document.getElementById('pay_cash_desk_content_bank_card_back_value').value != '') {
				total_filled -= parseFloat(document.getElementById('pay_cash_desk_content_bank_card_back_value').value);
			}
		}


		if (document.getElementById('pay_cash_desk_content_emoney_back_value')) {
			if (document.getElementById('pay_cash_desk_content_emoney_back_value').value != '') {
				total_filled -= parseFloat(document.getElementById('pay_cash_desk_content_emoney_back_value').value);
			}
		}


		let still_to_pay = parseFloat(par_total_still_to_pay) - parseFloat(total_filled);


		let me = this;
		let color = '#60A367';

		let exp = Math.pow(10, 2);


		if (Math.round(parseFloat(still_to_pay) * exp) / exp > 0) {
			color = '#ED514C';
		}

		document.getElementsByClassName('modal-dialog')[document.getElementsByClassName('modal-dialog').length-1].getElementsByClassName('modal-content')[0].getElementsByClassName('modal-footer')[0].getElementsByClassName('standard-actions')[0].getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[0].hidden = false;

		if (Math.round(parseFloat(still_to_pay) * exp) / exp < 0) {
			document.getElementsByClassName('modal-dialog')[document.getElementsByClassName('modal-dialog').length-1].getElementsByClassName('modal-content')[0].getElementsByClassName('modal-footer')[0].getElementsByClassName('standard-actions')[0].getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[0].hidden = true;
		}

		let rounded_still_to_paid = Math.round(parseFloat(still_to_pay) * exp) / exp

		let still_value = '<b><font color="' + color + '">' + parseFloat(rounded_still_to_paid).toFixed(2).toString() + ' ' + currency_id + '</font></b>';


		document.getElementById('pay_still_to_pay').innerHTML = still_value;
	}


	static bank_card_connect(currency_id, par_total_still_to_pay, max_cash_back)
	{
		if (document.getElementById('pay_cash_desk_content_bank_card_value').value == '') {

			let s = document.getElementById('pay_still_to_pay').innerText;
			s = s.substring(0, s.indexOf(' '));
			s = s.trim();

			if (s != '') {
				if (parseFloat(s) > 0) {
					document.getElementById('pay_cash_desk_content_bank_card_value').value = s;
					silicon_ioi.doctype.ioiCashDesk.compute_still_to_pay(document.getElementById('pay_cash_desk_content_bank_card_value').id, currency_id, par_total_still_to_pay, max_cash_back);
				}
			}
		}

		if ((document.getElementById('pay_cash_desk_content_bank_card_value').value == '') || (document.getElementById('pay_cash_desk_content_bank_card_value').value == 0)) {
			frappe.msgprint({title: __("Message"), message: __("Fill the amount before executing this action"), indicator: "red"});
			raise;
		}

		if (parseFloat(document.getElementById('pay_cash_desk_content_bank_card_value').value) < 0) {
			frappe.msgprint({title: __("Message"), message: __("The amount has to be greater than zero"), indicator: "red"});
			raise;
		}

		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_detail = '';


		silicon_ioi.doctype.ioiCashDesk.do_electronic_transaction('BANK').then( () =>  {

			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_id = document.getElementById('pay_cash_desk_content_bank_card_transaction_id').value;
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_bank_card_transaction_detail = document.getElementById('pay_cash_desk_content_bank_card_transaction_detail').value;

			if (document.getElementById('ioi_sales_pos_pay_button_pay_deliver')) {
				document.getElementById('ioi_sales_pos_pay_button_pay_deliver').src = '/assets/silicon_ioi/images/buttons/pay_deliver_warning.svg';
			}

			cur_dialog.primary_action();
		});

	}



	static credit_card_connect(currency_id, par_total_still_to_pay, max_cash_back)
	{
		if (document.getElementById('pay_cash_desk_content_credit_card_value').value == '') {

			let s = document.getElementById('pay_still_to_pay').innerText;
			s = s.substring(0, s.indexOf(' '));
			s = s.trim();

			if (s != '') {
				if (parseFloat(s) > 0) {
					document.getElementById('pay_cash_desk_content_credit_card_value').value = s;
					silicon_ioi.doctype.ioiCashDesk.compute_still_to_pay(document.getElementById('pay_cash_desk_content_credit_card_value').i, currency_id, par_total_still_to_pay, max_cash_back);
				}
			}
		}

		if ((document.getElementById('pay_cash_desk_content_credit_card_value').value == '') || (document.getElementById('pay_cash_desk_content_credit_card_value').value == 0)) {
			frappe.msgprint({title: __("Message"), message: __("Fill the amount before executing this action"), indicator: "red"});
			raise;
		}

		if (parseFloat(document.getElementById('pay_cash_desk_content_credit_card_value').value) < 0) {
			frappe.msgprint({title: __("Message"), message: __("The amount has to be greater than zero"), indicator: "red"});
			raise;
		}


		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_detail = '';


		if (silicon_ioi.doctype.ioiCashDesk.do_electronic_transaction('CREDIT')) {

			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_id = document.getElementById('pay_cash_desk_content_credit_card_transaction_id').value;
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_credit_card_transaction_detail = document.getElementById('pay_cash_desk_content_credit_card_transaction_detail').value;

			if (document.getElementById('ioi_sales_pos_pay_button_pay_deliver')) {
				document.getElementById('ioi_sales_pos_pay_button_pay_deliver').src = '/assets/silicon_ioi/images/buttons/pay_deliver_warning.svg';
			}

			cur_dialog.primary_action();
		}
	}

	static emoney_connect(currency_id, par_total_still_to_pay, max_cash_back)
	{
		if (document.getElementById('pay_cash_desk_content_emoney_value').value == '') {

			let s = document.getElementById('pay_still_to_pay').innerText;
			s = s.substring(0, s.indexOf(' '));
			s = s.trim();

			if (s != '') {
				if (parseFloat(s) > 0) {
					document.getElementById('pay_cash_desk_content_emoney_value').value = s;
					silicon_ioi.doctype.ioiCashDesk.compute_still_to_pay(document.getElementById('pay_cash_desk_content_emoney_value').id, currency_id, par_total_still_to_pay, max_cash_back);
				}
			}
		}

		if ((document.getElementById('pay_cash_desk_content_emoney_value').value == '') || (document.getElementById('pay_cash_desk_content_emoney_value').value == 0)) {
			frappe.msgprint({title: __("Message"), message: __("Fill the amount before executing this action"), indicator: "red"});
			raise;
		}

		if (parseFloat(document.getElementById('pay_cash_desk_content_emoney_value').value) < 0) {
			frappe.msgprint({title: __("Message"), message: __("The amount has to be greater than zero"), indicator: "red"});
			raise;
		}

		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_transaction_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_transaction_detail = '';


		if (silicon_ioi.doctype.ioiCashDesk.do_electronic_transaction('EMONEY')) {

			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_transaction_id = document.getElementById('pay_cash_desk_content_emoney_transaction_id').value;
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_emoney_transaction_detail =document.getElementById('pay_cash_desk_content_emoney_transaction_detail').value;

			if (document.getElementById('ioi_sales_pos_pay_button_pay_deliver')) {
				document.getElementById('ioi_sales_pos_pay_button_pay_deliver').src = '/assets/silicon_ioi/images/buttons/pay_deliver_warning.svg';
			}

			cur_dialog.primary_action();
		}
	}


	static spec1_connect(currency_id, par_total_still_to_pay, max_cash_back)
	{
		if (document.getElementById('pay_cash_desk_content_check_spec_1_value').value == '') {

			let s = document.getElementById('pay_still_to_pay').innerText;
			s = s.substring(0, s.indexOf(' '));
			s = s.trim();

			if (s != '') {
				if (parseFloat(s) > 0) {
					document.getElementById('pay_cash_desk_content_check_spec_1_value').value = s;
					silicon_ioi.doctype.ioiCashDesk.compute_still_to_pay(document.getElementById('pay_cash_desk_content_check_spec_1_value').id, currency_id, par_total_still_to_pay, max_cash_back);
				}
			}
		}

		if ((document.getElementById('pay_cash_desk_content_check_spec_1_value').value == '') || (document.getElementById('pay_cash_desk_content_check_spec_1_value').value == 0)) {
			frappe.msgprint({title: __("Message"), message: __("Fill the amount before executing this action"), indicator: "red"});
			raise;
		}

		if (parseFloat(document.getElementById('pay_cash_desk_content_check_spec_1_value').value) < 0) {
			frappe.msgprint({title: __("Message"), message: __("The amount has to be greater than zero"), indicator: "red"});
			raise;
		}

		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_detail = '';

		if (silicon_ioi.doctype.ioiCashDesk.do_electronic_transaction('SPEC1')) {

			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_id = document.getElementById('pay_cash_desk_content_check_spec_1_transaction_id').value;
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_1_transaction_detail = document.getElementById('pay_cash_desk_content_check_spec_1_transaction_detail').value;

			if (document.getElementById('ioi_sales_pos_pay_button_pay_deliver')) {
				document.getElementById('ioi_sales_pos_pay_button_pay_deliver').src = '/assets/silicon_ioi/images/buttons/pay_deliver_warning.svg';
			}

			cur_dialog.primary_action();
		}
	}

	static spec2_connect(currency_id, par_total_still_to_pay, max_cash_back)
	{
		if (document.getElementById('pay_cash_desk_content_check_spec_2_value').value == '') {

			let s = document.getElementById('pay_still_to_pay').innerText;
			s = s.substring(0, s.indexOf(' '));
			s = s.trim();

			if (s != '') {
				if (parseFloat(s) > 0) {
					document.getElementById('pay_cash_desk_content_check_spec_2_value').value = s;
					silicon_ioi.doctype.ioiCashDesk.compute_still_to_pay(document.getElementById('pay_cash_desk_content_check_spec_2_value').id, currency_id, par_total_still_to_pay, max_cash_back);
				}
			}
		}

		if ((document.getElementById('pay_cash_desk_content_check_spec_2_value').value == '') || (document.getElementById('pay_cash_desk_content_check_spec_2_value').value == 0)) {
			frappe.msgprint({title: __("Message"), message: __("Fill the amount before executing this action"), indicator: "red"});
			raise;
		}

		if (parseFloat(document.getElementById('pay_cash_desk_content_check_spec_2_value').value) < 0) {
			frappe.msgprint({title: __("Message"), message: __("The amount has to be greater than zero"), indicator: "red"});
			raise;
		}

		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_detail = '';

		if (silicon_ioi.doctype.ioiCashDesk.do_electronic_transaction('SPEC2')) {

			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_id = document.getElementById('pay_cash_desk_content_check_spec_2_transaction_id').value;
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_2_transaction_detail = document.getElementById('pay_cash_desk_content_check_spec_2_transaction_detail').value;

			if (document.getElementById('ioi_sales_pos_pay_button_pay_deliver')) {
				document.getElementById('ioi_sales_pos_pay_button_pay_deliver').src = '/assets/silicon_ioi/images/buttons/pay_deliver_warning.svg';
			}

			cur_dialog.primary_action();
		}

	}

	static spec3_connect(currency_id, par_total_still_to_pay, max_cash_back)
	{
		if (document.getElementById('pay_cash_desk_content_check_spec_3_value').value == '') {

			let s = document.getElementById('pay_still_to_pay').innerText;
			s = s.substring(0, s.indexOf(' '));
			s = s.trim();

			if (s != '') {
				if (parseFloat(s) > 0) {
					document.getElementById('pay_cash_desk_content_check_spec_3_value').value = s;
					silicon_ioi.doctype.ioiCashDesk.compute_still_to_pay(document.getElementById('pay_cash_desk_content_check_spec_3_value').id, currency_id, par_total_still_to_pay, max_cash_back);
				}
			}
		}

		if ((document.getElementById('pay_cash_desk_content_check_spec_3_value').value == '') || (document.getElementById('pay_cash_desk_content_check_spec_3_value').value == 0)) {
			frappe.msgprint({title: __("Message"), message: __("Fill the amount before executing this action"), indicator: "red"});
			raise;
		}

		if (parseFloat(document.getElementById('pay_cash_desk_content_check_spec_3_value').value) < 0) {
			frappe.msgprint({title: __("Message"), message: __("The amount has to be greater than zero"), indicator: "red"});
			raise;
		}


		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_id = '';
		silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_detail = '';

		if (silicon_ioi.doctype.ioiCashDesk.do_electronic_transaction('SPEC3')) {

			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_id = document.getElementById('pay_cash_desk_content_check_spec_3_transaction_id').value;
			silicon_ioi.doctype.ioiCashDesk.pay_cash_desk_content_check_spec_3_transaction_detail = document.getElementById('pay_cash_desk_content_check_spec_3_transaction_detail').value;

			if (document.getElementById('ioi_sales_pos_pay_button_pay_deliver')) {
				document.getElementById('ioi_sales_pos_pay_button_pay_deliver').src = '/assets/silicon_ioi/images/buttons/pay_deliver_warning.svg';
			}

			cur_dialog.primary_action();
		}

	}


	static async do_electronic_transaction(tp)
	{
		let transaction_ok = false;

		let amount = 0;
		let transaction_id = '';
		let transaction_detail = '';

		if (tp == 'BANK') {
			amount = parseFloat(document.getElementById('pay_cash_desk_content_bank_card_value').value);
		}else if (tp == 'CREDIT') {
			amount = parseFloat(document.getElementById('pay_cash_desk_content_credit_card_value').value);
		}else if (tp == 'EMONEY') {
			amount = parseFloat(document.getElementById('pay_cash_desk_content_emoney_value').value);
		}else if (tp == 'SPEC1') {
			amount = parseFloat(document.getElementById('pay_cash_desk_content_check_spec_1_value').value);
		}else if (tp == 'SPEC2') {
			amount = parseFloat(document.getElementById('pay_cash_desk_content_check_spec_2_value').value);
		}else if (tp == 'SPEC3') {
			amount = parseFloat(document.getElementById('pay_cash_desk_content_check_spec_3_value').value);
		}

		transaction_ok = true;


		if (tp == 'BANK') {
		}


		// Run the Electronic transaction
		// If success :
		//		- set transaction_ok = true
		// 		- set transaction_id
		//		- set transaction_detail


		// transaction_id =
		// transaction_detail =




		if (transaction_ok) {

			if (tp == 'BANK') {
				document.getElementById('pay_cash_desk_content_bank_card_transaction_id').value = transaction_id;
				document.getElementById('pay_cash_desk_content_bank_card_transaction_detail').value = transaction_detail;
			}else if (tp == 'CREDIT') {
				document.getElementById('pay_cash_desk_content_credit_card_transaction_id').value = transaction_id;
				document.getElementById('pay_cash_desk_content_credit_card_transaction_detail').value = transaction_detail;
			}else if (tp == 'EMONEY') {
				document.getElementById('pay_cash_desk_content_emoney_transaction_id').value = transaction_id;
				document.getElementById('pay_cash_desk_content_emoney_transaction_detail').value = transaction_detail;
			}else if (tp == 'SPEC1') {
				document.getElementById('pay_cash_desk_content_check_spec_1_transaction_id').value = transaction_id;
				document.getElementById('pay_cash_desk_content_check_spec_1_transaction_detail').value = transaction_detail;
			}else if (tp == 'SPEC2') {
				document.getElementById('pay_cash_desk_content_check_spec_2_transaction_id').value = transaction_id;
				document.getElementById('pay_cash_desk_content_check_spec_2_transaction_detail').value = transaction_detail;
			}else if (tp == 'SPEC3') {
				document.getElementById('pay_cash_desk_content_check_spec_3_transaction_id').value = transaction_id;
				document.getElementById('pay_cash_desk_content_check_spec_3_transaction_detail').value = transaction_detail;
			}
		}

		return transaction_ok;
	}

	static do_pay_activity(doctype, name, cashdesk_id, ioistatus, is_dark_mode, fct_callback_action, operator_id = '')
	{

		let title = __('Payment activity');
		let bt_secondary_caption = __('Cancel selected payment');

		var p = new frappe.ui.Dialog({
			'title': title,
			'fields': [
				{'fieldname': 'html_payment_activity', 'fieldtype': 'HTML'}

			],
			primary_action_label:  __('Close'),
			secondary_action_label: bt_secondary_caption,
			primary_action: function(){

				p.hide();

			},
			secondary_action: function(){

				if (ioistatus != 0) {
					frappe.msgprint({title: __("Message"), message: __('Document has to be "in Preparation"'), indicator: "red"});
					raise;

				}

				if (!document.getElementById('payment_movement_grid_detail_checked_id_0')) {
					frappe.msgprint({title: __("Message"), message: __("No payment to cancel"), indicator: "red"});
					raise;
				}

				let payment_name = '';

				for (var i = 0; i < document.getElementById('payment_movement_grid_detail').rows.length; i++) {

					if (document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString())) {

						if (document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString()).checked) {

							payment_name = document.getElementById('payment_movement_grid_detail_name_' + i.toString()).value;
							break;
						}

					}
				}

				if (payment_name == '') {
					frappe.msgprint({title: __("Message"), message: __("No payment to cancel"), indicator: "red"});
					raise;
				}

				let meth = path_cash_desk + '.ioi_cash_desk_get_info';

				let cashdesk_status = 0;

				frappe.call({  	method: meth,
								args: {"name": cashdesk_id},
								async: false,
								callback:function(r)	{

									if (r.message.length != 0) {
										cashdesk_status = r.message[0].ioistatus;
									}
								}
				});

				if (cashdesk_status != 1) {
					frappe.msgprint({title: __("Message"), message: __("This cash desk is not open"), indicator: "red"});
					raise;

				}


				let top = 10;

				let s =  __('Are you sure to cancel this selected payment ?') + '';
				let msg = '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

				top += 30;
				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + __("Remark") + '</label>';


				if (document.getElementById('payment_movement_cancel_remark')) {
					document.getElementById('payment_movement_cancel_remark').remove();
				}

				top += 30;
				msg += '<input id="payment_movement_cancel_remark" type="text" style="position: absolute; top: ' + top.toString() + 'px; left: 20px; width:350px" class="input-with-feedback form-control bold" value="">';


				top += 20;
				msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


				var d = frappe.confirm(	msg,
								() => 	{
											if (document.getElementById('payment_movement_cancel_remark').value.trim() == '') {
												frappe.msgprint({title: __("Message"), message: __("Remark is mandatory"), indicator: "red"});
												raise;
											}

											document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

											let method = path_cash_desk_movement + '.ioi_cash_desk_movement_cancel_movement';

											frappe.call({  	method: method,
															args: {"name_to_cancel": payment_name, "remark": document.getElementById('payment_movement_cancel_remark').value, "operator_id": operator_id},
															async: false,
															callback:function(r)	{

																if (r.message.error == 0) {

																	if (fct_callback_action) {

																		fct_callback_action();
																	}

																}else
																{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
																	frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
																	raise;
																}

																d.hide();

																let html = '';

																html = silicon_ioi.doctype.ioiCashDesk.refresh_payment_movement(doctype, name, cashdesk_id, is_dark_mode);

																p.fields_dict.html_payment_activity.$wrapper.html(html);

															}
											});


										},
								() => 	{
										}
				);

				d.$wrapper.find('.modal-dialog').css("width", "400px");
				d.show()

				silicon_ioi.doctype.ioiCashDesk.sleep_static(1000).then(() => {

					document.getElementById('payment_movement_cancel_remark').focus();

				});

			}

		});



		let html = '';

		html = silicon_ioi.doctype.ioiCashDesk.refresh_payment_movement(doctype, name, cashdesk_id, is_dark_mode);

		p.fields_dict.html_payment_activity.$wrapper.html(html);
		p.$wrapper.find('.modal-dialog').css("max-width", "1000px").css("width", "1000px");
		p.show();

		silicon_ioi.doctype.ioiCashDesk.sleep_static(500).then(() => {


			document.getElementsByClassName('modal-dialog')[document.getElementsByClassName('modal-dialog').length-1].getElementsByClassName('modal-content')[0].getElementsByClassName('modal-footer')[0].getElementsByClassName('standard-actions')[0].getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary')[0].hidden = false;

			if (ioistatus != 0) {
				document.getElementsByClassName('modal-dialog')[document.getElementsByClassName('modal-dialog').length-1].getElementsByClassName('modal-content')[0].getElementsByClassName('modal-footer')[0].getElementsByClassName('standard-actions')[0].getElementsByClassName('btn btn-secondary btn-sm btn-modal-secondary')[0].hidden = true;
			}

			if (document.getElementById('payment_movement_grid_detail')) {

				let fct_click = function() {

					for (var i = 0; i < document.getElementById('payment_movement_grid_detail').rows.length; i++) {
						if (document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString())) {
							document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString()).checked = false;
						}
					}

					this.checked = true;


				};

				for (var i = 0; i < document.getElementById('payment_movement_grid_detail').rows.length; i++) {
					if (document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString())) {
						document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString()).onclick = fct_click;
					}
				}
			}


		});

	}


	static refresh_payment_movement(doctype, name, cashdesk_id, is_dark_mode)
	{

		if (document.getElementById('payment_content_grid')) {
			document.getElementById('payment_content_grid').remove();
		}

		if (document.getElementById('payment_movement_grid_header')) {
			document.getElementById('payment_movement_grid_header').remove();
		}

		if (document.getElementById('payment_movement_grid_detail')) {

			for (var i = 0; i < document.getElementById('payment_movement_grid_detail').rows.length; i++) {
				if (document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString())) {
					document.getElementById('payment_movement_grid_detail_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('payment_movement_grid_detail_name_' + i.toString())) {
					document.getElementById('payment_movement_grid_detail_name_' + i.toString()).remove();
				}

				if (document.getElementById('payment_movement_grid_detail_value_mode_' + i.toString())) {
					document.getElementById('payment_movement_grid_detail_value_mode_' + i.toString()).remove();
				}
				if (document.getElementById('payment_movement_grid_detail_record_cancelled_' + i.toString())) {
					document.getElementById('payment_movement_grid_detail_record_cancelled_' + i.toString()).remove();
				}
			}

			document.getElementById('payment_movement_grid_detail').remove()
		}


		let html = '';

		html += '<div id="payment_content_grid" style="overflow-x: auto;height:350px;">';

		let bgcolor = '';

		if (is_dark_mode == 0) {
			bgcolor = '#D0E7FB';
		}else{
			bgcolor = '#1C2126';
		}

		html += '<table id="payment_movement_grid_header" bgcolor="' + bgcolor + '" width=2140px border=1 style="border: 1px solid #E8EAEB">';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Operation date") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Value") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Cash rounding") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Error value") + '</b></td>';
		html += '<td width=60px style="vertical-align: middle;"><b>&nbsp;' + __("Curr.") + '</b></td>';
		html += '<td width=240px style="vertical-align: middle;"><b>&nbsp;' + __("Value mode") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Check id") + '</b></td>';
		html += '<td width=500px style="vertical-align: middle;"><b>&nbsp;' + __("Transaction id") + '</b></td>';
		html += '<td width=300px style="vertical-align: middle;"><b>&nbsp;' + __("Remark") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Operator") + '</b></td>';
		html += '</tr>';
		html += '</table>';


		let method = path_cash_desk_movement + '.ioi_cash_desk_movement_get_for_document'

		frappe.call({  	method: method,
						args: 	{	"doctype": doctype,
									"name": name,
									"cashdesk_id": cashdesk_id
						},
						async: false,
						callback: function(r)	{


							if (r.message.length == 0) {

								html += '<table id="payment_movement_grid_detail" width=2140px border=1 style="border: 1px solid #E8EAEB">';
								html += '<tr style="height:30px">';
								html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=60px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=240px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=500px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=300px style="vertical-align: middle;">&nbsp;</td>';
								html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
								html += '</tr>';
								html += '</table>';


							}else{
								html += '<table id="payment_movement_grid_detail" width=2140px border=1 style="border: 1px solid #E8EAEB">';

								for (var i = 0; i < r.message.length; i++) {

									html += '<input id="payment_movement_grid_detail_name_' + i.toString() + '" type="hidden" value="' + r.message[i].name + '">';
									html += '<input id="payment_movement_grid_detail_value_mode_' + i.toString() + '" type="hidden" value="' + r.message[i].value_mode + '">';
									html += '<input id="payment_movement_grid_detail_record_cancelled_' + i.toString() + '" type="hidden" value="' + r.message[i].record_cancelled + '">';

									let bgcolor_is_set = false;

									if (r.message[i].record_cancelled == 'Y') {
										html += '<tr bgcolor="#D7D7D9" style="height:30px">';
										bgcolor_is_set = true;
									}else{
										html += '<tr style="height:30px">';
									}


									html += '<td width=30px style="vertical-align: middle;" align="center">';

									html += '<input type="checkbox" id="payment_movement_grid_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 2px; left: 2px;" ';
									if (i == 0) {
										html += ' checked ';
									}
									html += '>';


									html += '</td>';

									if (r.message[i].operation_datetime != null) {
										html += '<td width=150px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '">&nbsp;' + r.message[i].operation_datetime.toString().substring(0, 16) + '</td>';
									}else{
										html += '<td width=150px style="vertical-align: middle;">&nbsp;></td>';
									}


									if ((r.message[i].value != null) && (r.message[i].value != 0)) {
										html += '<td width=120px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '" align="right">' + r.message[i].value.toFixed(2).toString() + '&nbsp;</td>';
									}else{
										html += '<td width=120px style="vertical-align: middle;" align="right">&nbsp;</td>';
									}

									if ((r.message[i].cash_rounding != null) && (r.message[i].cash_rounding != 0)) {
										html += '<td width=120px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '" align="right">' + r.message[i].cash_rounding.toFixed(2).toString() + '&nbsp;</td>';
									}else{
										html += '<td width=120px style="vertical-align: middle;" align="right">&nbsp;</td>';
									}


									if ((r.message[i].error_value != null) && (r.message[i].error_value != 0)) {
										html += '<td width=120px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '" align="right">' + r.message[i].error_value.toFixed(2).toString() + '&nbsp;</td>';
									}else{
										html += '<td width=120px style="vertical-align: middle;" align="right">&nbsp;</td>';
									}


									if (r.message[i].currency_id != null) {
										html += '<td width=60px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '">&nbsp;' + r.message[i].currency_id + '</td>';
									}else{
										html += '<td width=60px style="vertical-align: middle;">&nbsp;</td>';
									}

									if (r.message[i].value_mode_desc != null) {
										html += '<td width=240px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '">&nbsp;' + r.message[i].value_mode_desc + '</td>';
									}else{
										html += '<td width=240px style="vertical-align: middle;">&nbsp;</td>';
									}


									if (r.message[i].check_id != null) {
										html += '<td width=250px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '">&nbsp;' + r.message[i].check_id + '</td>';
									}else{
										html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
									}

									if (r.message[i].transaction_id != null) {
										html += '<td width=500px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '">&nbsp;<u><a href="#" onclick="window.open(\'/app/ioi-payment-transaction/' + r.message[i].transaction_id + '\');">' + r.message[i].transaction_id + '</a></u></td>';
									}else{
										html += '<td width=500px style="vertical-align: middle;">&nbsp;</td>';
									}


									if (r.message[i].remark != null) {
										html += '<td width=300px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '">&nbsp;' + r.message[i].remark + '</td>';
									}else{
										html += '<td width=300px style="vertical-align: middle;">&nbsp;</td>';
									}



									if (r.message[i].operator_id != null) {
										html += '<td width=250px style="vertical-align: middle;';

										if (bgcolor_is_set) {
											html += 'color:#000000;'
										}

										html += '">&nbsp;' + r.message[i].operator_id + '</td>';
									}else{
										html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
									}

									html += '</tr>';

								}


								html += '</table>';


							}
						}
		});


		html += '</div>';

		return html;
	}


	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}
}

silicon_ioi.doctype.ioiCashDesk = ioiCashDesk;
