frappe.provide('silicon_ioi.doctype');

export class ioiDedicatedStorageFrame {
	static this
	static dossier_id
	static description
	static path
	static stock_output_prefixes = []

	static build_panel(tab) {
		let panel = '';

		panel += '<div class="d-flex flex-column">';

		panel += '	<div class="d-flex w-100 flex-row flex-wrap mb-2">';

		// Dedicated Storage Search
		panel += `	<div class="mr-2" style="width:280px;">
						<label id="${tab}_search_label" style="">${__("Search")}</label>
						<div class="control-input" style="width: 280px;">
							<input id="${tab}_search" type="text" class="input-with-feedback form-control bold">
						</div>
					</div>`

		// Topn
		panel += `	<div class="mr-3" style="width:90px;">
						<label id="${tab}_topn_label" style="">${__("No records")}</label>
						<div class="control-input"> 
							<input id="${tab}_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">
						</div>
					</div>`

		panel += '	</div>'

		panel += `	<div class="d-flex w-100 flex-row mb-2 flex-wrap" style="height: 28px;">
						<button id="${tab}_bt_search" title="${__("Search")}" data-label="Search" class="btn btn-xs btn-default ellipsis mr-2" style="width: 116px;" onclick="">${__("Search")}</button>
						<button id="${tab}_bt_clear" title="${__("...")}" data-label="Search" class="btn btn-xs btn-default ellipsis mr-2" style="width: 50px;" onclick="">${__("...")}</button>
						<button id="${tab}_bt_export" title="${__("Export")}" data-label="Export" class="btn btn-xs btn-default ellipsis mr-2" style="width: 100px;" onclick="">${__("Export")}</button>
						<button id="${tab}_bt_stock_output" title="${__("Out from Storage")}" data-label="Stock output" class="btn btn-xs btn-default ellipsis mr-2" style="width: 200px;" onclick="">${__("Out from Storage")}</button>
					</div>`

		panel += `	<input id="${tab}_orderby" type="hidden" value="name">`;
		panel += `	<input id="${tab}_order" type="hidden" value="asc">`;
		panel += '</div>';

		return panel
	}

	static show_dedicated_storage_label(tab_name) {
		if (document.getElementById(`${tab_name}_desc_label`)) {
			document.getElementById(`${tab_name}_desc_label`).remove();
		}

		if (document.getElementById(`${tab_name}_dedicated_storage_label`)) {
			document.getElementById(`${tab_name}_dedicated_storage_label`).remove();
		}

		let html = '';

		let doctype = silicon_ioi.doctype.ioiDedicatedStorageFrame.this.frm.doctype

		let dedicated_storage_label = `${silicon_ioi.doctype.ioiDedicatedStorageFrame.dossier_id}`
		if (!!silicon_ioi.doctype.ioiDedicatedStorageFrame.description) dedicated_storage_label += ` - ${silicon_ioi.doctype.ioiDedicatedStorageFrame.description}`

		html += '<div class="d-flex flex-column">';
		html += `	<label id="${tab_name}_desc_label">${__(doctype.replace('ioi ', ''))}</label>`
		html += `	<label id="${tab_name}_dedicated_storage_label">${dedicated_storage_label}</label>`
		html += '</div>';

		return html
	}

	static build_dedicated_storage() {
		let dedicated_storage_tabs = [
			{ tab_name: 'dedicated_storage', dt: 'ioi Warehouse Location' },
		]

		dedicated_storage_tabs.map(tab => {
			const container = `
				<div id="html_dedicated_storage_content" class="w-100">
					<div class="d-flex flex-row flex-wrap">
						${silicon_ioi.doctype.ioiDedicatedStorageFrame.build_panel(tab.tab_name)}
						${silicon_ioi.doctype.ioiDedicatedStorageFrame.show_dedicated_storage_label(tab.tab_name)}
					</div>
					<div id="${tab.tab_name}_grid" class="table table-bordered" data-custom-grid="true"></div>
				</div>`

			const dedicated_storage_content = $('#html_dedicated_storage_content')[0]

			if (dedicated_storage_content) dedicated_storage_content.remove()
			silicon_ioi.doctype.ioiDedicatedStorageFrame.this.frm.fields_dict[`html_${tab.tab_name}`].$wrapper.append(container);

			silicon_ioi.doctype.ioiDedicatedStorageFrame.build_dedicated_storage_grid(tab.tab_name, tab.dt)
		})
	}

	static build_dedicated_storage_grid(tab, dt) {

		let columns = [
			{ field: "checkbox", formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerHozAlign: "center", headerSort: false, minWidth: 40, maxWidth: 40 },
			{ title: __('Item'), field: 'item_id' },
			{ title: __('Batch/SN'), field: 'batch_sn_id' },
			{ title: __('Location'), field: 'location' },
			{ title: __('Stock qty'), field: 'q_stock', hozAlign: "right" },
			{ title: __('To check qty'), field: 'q_tocheck', hozAlign: "right" },
			{ title: __('Unit'), field: 'unit_id' },
			{ title: __('Locked qty'), field: 'q_locked', hozAlign: "right" },
			{ title: __('Locked tocheck qty'), field: 'q_lockedtocheck', hozAlign: "right" },
			{ title: __('Item description'), field: 'item_description' },
			{ title: __('Block'), field: 'block_id' },
			{ title: __('Row'), field: 'row_id' },
			{ title: __('Col'), field: 'col_id' },
			{ title: __('Level'), field: 'level_id' }
		]

		let table = new ioi.Tabulator(`#${tab}_grid`, {
			maxHeight: 800,
			data: [],
			layout: "fitColumns",
			movableColumns: true,
			resizableColumns: true,
			showProfiles: true,
			autoRedraw: true,
			columns: columns,
			columnDefaults: {
				minWidth: 100,
			},
		});

		table.on("tableBuilt", () => {
			table.footerManager.element.setAttribute('data-custom-grid-bottom', 'true')
		});

		table.on("headerClick", function (e, column) {
			let ordered_field = document.getElementById(`${tab}_orderby`)
			ordered_field.value = column.getField()

			let ordered_dir = document.getElementById(`${tab}_order`)
			ordered_dir.value = table.getSorters()[0].dir

			silicon_ioi.doctype.ioiDedicatedStorageFrame.col_click(tab)
		});

		silicon_ioi.doctype.ioiDedicatedStorageFrame.dedicated_storage_grid_search(table, dt, tab)

		document.getElementById(`${tab}_bt_search`).onclick = () => silicon_ioi.doctype.ioiDedicatedStorageFrame.dedicated_storage_grid_search(table, dt, tab)
		document.getElementById(`${tab}_bt_export`).onclick = () => silicon_ioi.doctype.ioiDedicatedStorageFrame.export_data(table, tab)
		document.getElementById(`${tab}_bt_stock_output`).onclick = () => silicon_ioi.doctype.ioiDedicatedStorageFrame.stock_output(table, tab)
		document.getElementById(`${tab}_bt_clear`).onclick = () => {
			document.getElementById(`${tab}_search`).value = ''
			document.getElementById(`${tab}_topn`).value = 100
			table.clearData()
		}

		document.getElementById('dedicated_storage_search').addEventListener("keypress", function(event) {
			if (event.key === "Enter") {
				event.preventDefault();
				silicon_ioi.doctype.ioiDedicatedStorageFrame.dedicated_storage_grid_search(table, dt, tab)
			}
		});

		document.getElementById('dedicated_storage_topn').addEventListener("keypress", function(event) {
			if (event.key === "Enter") {
				event.preventDefault();
				silicon_ioi.doctype.ioiDedicatedStorageFrame.dedicated_storage_grid_search(table, dt, tab)
			}
		});
	}

	static col_click(tab) {
		if (document.getElementById(`${tab}_orderby`).value == 'name') {
			document.getElementById(`${tab}_orderby`).value = 'a.name';
		}

		document.getElementById(`${tab}_bt_search`).click();
	}

	static dedicated_storage_grid_search(table, dt, tab) {

		frappe.call({
			method: silicon_ioi.doctype.ioiDedicatedStorageFrame.path,
			args: {
				"dossier_id": silicon_ioi.doctype.ioiDedicatedStorageFrame.dossier_id,
				"search_term": document.getElementById('dedicated_storage_search').value,
				"topn": document.getElementById('dedicated_storage_topn').value
			},
			async: false,
			callback: function (r) {
				if (r.message.length > 0) {
					if (table.initialized) {
						table.setData(r.message)
					} else {
						table.on('tableBuilt', () => table.setData(r.message))
					}
				} else {
					if (table && table.initialized) table.clearData()
				}
			}
		})
	}

	static stock_output(table, tab)
	{
		let rows = table.getSelectedRows()

		if ((!rows) || ((rows) && (rows == ''))) {
			frappe.msgprint({title: __("Message"), message: __('No selected data in the grid'), indicator: "red"});
			return false;	
		}
		
		let items = []
		let locations = []
		let batchsns = []
		let stock_qties = []

		for (var i = 0; i < rows.length; i++) {

			if (((!rows[i].getData()["q_stock"]) || (rows[i].getData()["q_stock"] == '') || (rows[i].getData()["q_stock"] == 0))) {
					frappe.msgprint({title: __("Message"), message: __('Row') + ' ' + (i+1).toString() + ': ' + _('No stock qty qty'), indicator: "red"});
					return false;	
			}

			items[items.length] = rows[i].getData()["item_id"];
			locations[locations.length] = rows[i].getData()["location"];

			if ((rows[i].getData()["batch_sn_id"] != null) && (rows[i].getData()["batch_sn_id"] != '')) {
				batchsns[batchsns.length] = rows[i].getData()["batch_sn_id"];
			}else{
				batchsns[batchsns.length] = '';
			}

			if ((rows[i].getData()["q_stock"] != null) && (rows[i].getData()["q_stock"] != '') && (rows[i].getData()["q_stock"] != 0)) {
				stock_qties[stock_qties.length] = parseFloat(rows[i].getData()["q_stock"]);
			}else{
				stock_qties[stock_qties.length] = 0;
			}
		}



		let title = __('Out from storage');

		var outFromStorageForm = new frappe.ui.Dialog({
			'title': title,
			'fields': [
				{'fieldname': 'html_out_from_storage_form', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){
				cur_dialog.disable_primary_action()

				if ((!document.getElementById('html_out_from_storage_form_prefix_id').value) ||
					((document.getElementById('html_out_from_storage_form_prefix_id').value) && (document.getElementById('html_out_from_storage_form_prefix_id').value.trim() == ''))) {
						frappe.msgprint({title: __("Message"), message: __("The prefix has to be filled"), indicator: "red"});
						cur_dialog.enable_primary_action()
						return false;
				}

				let change_status= 0;

				if (document.getElementById('html_out_from_storage_form_change_status').checked) {
					change_status = 1;
				}

				let data = {};

				frappe.call({
					method: 'silicon_ioi.ioi_trakker.doctype.ioi_dossier.ioi_dossier.ioi_dossier_out_from_storage',
					args: {
						"dossier_id": silicon_ioi.doctype.ioiDedicatedStorageFrame.dossier_id,
						"prefix_id": document.getElementById('html_out_from_storage_form_prefix_id').value,
						"items": items, 
						"locations": locations, 
						"batchsns": batchsns,
						"stock_qties": stock_qties,
						"change_status": change_status
					},
					async: false,
					callback: function (r) {
						data = r.message;
					}
				});

				if (data.error != 0) {
					frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
					cur_dialog.enable_primary_action()
					return false;					

				}else{
					outFromStorageForm.hide();
					

					if (data.stock_output_id != '') {
						window.open('/app/ioi-stock-output/'+ data.stock_output_id);
					}

					window.location.reload();

				}
				
			},
			secondary_action: function(){
				outFromStorageForm.hide();
			}

		});

		if (document.getElementById('html_out_from_storage_form_prefix_label')) {
			document.getElementById('html_out_from_storage_form_prefix_label').remove();
		}

		if (document.getElementById('html_out_from_storage_form_prefix_id')) {
			document.getElementById('html_out_from_storage_form_prefix_id').remove();
		}	

		if (document.getElementById('html_out_from_storage_form_change_status_label')) {
			document.getElementById('html_out_from_storage_form_change_status_label').remove();
		}

		if (document.getElementById('html_out_from_storage_form_change_status')) {
			document.getElementById('html_out_from_storage_form_change_status').remove();
		}			

		
		let html = '';

		html += '<div style="overflow: auto; overflow-x: hidden; height:130px; width: 300px">';

		// Prefixes
		html += '	<div style="position: relative; top:0px; left: 0px; width:250px;">';
		html += '		<label id="html_out_from_storage_form_prefix_label" style="position: absolute; top: 0px; left: 2px;">' + __("Prefix") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 250px; height: 30px;"> ';
		html += '			<select id="html_out_from_storage_form_prefix_id" class="input-with-feedback form-control bold"> ';

		let method = 'silicon_ioi.ioi_trakker.doctype.ioi_dossier.ioi_dossier.ioi_dossier_out_from_storage_get_output_prefixes'

		silicon_ioi.doctype.ioiDedicatedStorageFrame.stock_output_prefixes = [];

		frappe.call({  	method: method,
			args: {
			},
			async: false,
			callback: function(r) {	
				
				silicon_ioi.doctype.ioiDedicatedStorageFrame.stock_output_prefixes = r.message;

				if (silicon_ioi.doctype.ioiDedicatedStorageFrame.stock_output_prefixes.length > 0) {

					for (var i = 0; i < silicon_ioi.doctype.ioiDedicatedStorageFrame.stock_output_prefixes.length; i++) {

						if (i == 0) {
							html += '	<option value="' + silicon_ioi.doctype.ioiDedicatedStorageFrame.stock_output_prefixes[i].prefix + '" selected>' + silicon_ioi.doctype.ioiDedicatedStorageFrame.stock_output_prefixes[i].prefix + '</option> ';
						}else{
							html += '	<option value="' + silicon_ioi.doctype.ioiDedicatedStorageFrame.stock_output_prefixes[i].prefix + '">' + silicon_ioi.doctype.ioiDedicatedStorageFrame.stock_output_prefixes[i].prefix + '</option> ';
						}

					}
				}
			}
		});

		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 65px; left: 0px; width:300px;">';
		html += '		<input type="checkbox" id="html_out_from_storage_form_change_status" style="position: absolute; top: 8px; left: 2px;" checked>';
		html += '		<label id="html_out_from_storage_form_change_status_label" style="position: absolute; top: 6px; left: 30px;">' + __("Set output in Delivered") + '</label>';
		html += '	</div>';		

		html += '</div>';



		outFromStorageForm.fields_dict.html_out_from_storage_form.$wrapper.html(html);
		outFromStorageForm.$wrapper.find('.modal-dialog').css("max-width", "300px").css("width", "300px");
		outFromStorageForm.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");

		outFromStorageForm.show();
	}



	static export_data(table, default_filename)
	{
		let export_tabulator = () => {
			let filename = document.getElementById('export_filename').value;
			let filetype = document.getElementById('export_file_type').value;

			if (filename.trim() == '') {
				filename = default_filename;
			}

			if (filetype.toUpperCase() == 'XLSX') {
				table.download("xlsx", `${filename}.xlsx`);
			} else {
				table.download("csv", `${filename}.csv`, { delimiter: ";" })
			}
		}

 		silicon_ioi.ioiCommon.export_form(export_tabulator, true);
	}

	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}	
}

silicon_ioi.doctype.ioiDedicatedStorageFrame = ioiDedicatedStorageFrame;