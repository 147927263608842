import { Draggable } from "@fullcalendar/interaction";
import { TodoPopupManager } from "./ioi_todo_popup";

const messages = {
	list_title: __("Tasks"),
	no_items: "No tasks",
	error: "Error while loading tasks",
	task_title: (title) => (__("Task") + " - " + title),
	refresh_button: __("Refresh"),
	set_resource_button: __("Tasks"),
	open_todo: __("Open {0}", [__("Task")]),
	drop_help_text: __("Drag and drop to create a new event."),
	create_task: __("Move to {0}", [__("Task")]), // Move to Task
	dialog_title: __("Move to {0}", [__("Task")]),
	user_selector_title: __("Selected User"),
	new_task_button: __("New Task")
};

export class ioiResourceTodoDragger {
	/**
	 * @param {object} arg
	 * @param {frappe.views.ioiResource & { list_view: frappe.views.ResourcesView }} arg.view - ResourcesView or CalendarView instance
	 */
	constructor({ view }) {
		frappe.model.with_doctype("ToDo"); // to fetch required fields of ToDo for dialog

		this._state = {
			/** @type {{ doctype: string, name: string }?} */
			resource: null,
			user: frappe.session.user
		};

		this.view = view;
		this.todo_list = this._make_todo_list();
	}

	setState(state) {
		Object.assign(this._state, state);
		this.refresh();
	}

	refresh() {
		this.todo_list?.refresh();
	}

	/**
	 * @see https://fullcalendar.io/docs/eventReceive
	 */
	eventReceive(info, resource, list_view) {
		this.make_event_from_fullcalendar_info(info, resource, list_view);
	}

	eventDidMount(info) {
		info.el.addEventListener("contextmenu", /** @param {MouseEvent} e */ (e) => {
			e.preventDefault();
			const dialog = new ioiTaskCreationDialog({ fullcalendarInfo: info, user: this._state.user });
			dialog.show();
		});
	}

	resourceLabelDidMount(info) {
		this._appendTasksButtonToFullcalendar(info);
	}

	headerCellDidMount(info) {
		this._appendTasksButtonToFullcalendar(info);
	}

	/** @private */
	_appendTasksButtonToFullcalendar(info) {
		if (info.resource.extendedProps?.resource_link !== "User") return;

		const btn = this._create_task_button();
		const is_vertical_view = this._is_vertical_view();

		info.el.appendChild(btn);
		this._apply_button_styles(info, is_vertical_view, btn);
		this._attach_button_events(info, is_vertical_view, btn);
		this._highlight_user_cell(is_vertical_view);
	}

	_create_task_button() {
		const btn = document.createElement("button");
		btn.innerText = messages.set_resource_button;
		btn.classList.add("btn", "btn-default");
		return btn;
	}
	_is_vertical_view() {
		return this.view.fullcalendar.currentData.viewSpec.buttonTextOverride === __("Vertical");
	}

	_apply_button_styles(info, is_vertical_view, btn) {
		if (!is_vertical_view) {
			Object.assign(info.el.style, {
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				padding: "0 10px",
				fontWeight: "bold"
			});
		} else {
			btn.style.marginBottom = "5px";
		}
	}

	_attach_button_events(info, is_vertical_view, btn) {
		btn.addEventListener('click', () => this._handle_button_click(info, is_vertical_view));
		btn.addEventListener("mouseover", () => {
			btn.title = __(`Display ${is_vertical_view ? info.resource._resource.title : info.fieldValue}'s tasks`);
		});
	}

	_handle_button_click(info, is_vertical_view) {
		const user_id = info?.resource?.id || null;
		if (!user_id) return;

		this._reset_cell_styles(is_vertical_view);

		const target_cell = this._get_target_cell(user_id, is_vertical_view);
		if (target_cell)
			this._apply_cell_highlighting(target_cell);

		this.setState({ user: user_id });
	}

	_reset_cell_styles(is_vertical_view) {
		document.querySelectorAll(is_vertical_view ? '.fc-col-header-cell-cushion' : '.fc-datagrid-cell-frame').forEach((cell) => {
			cell.style.textDecoration = 'none';
		});
	}

	_get_target_cell(user_id, is_vertical_view) {
		return is_vertical_view
			? document.querySelector(`th[data-resource-id="${user_id}"]`)?.firstElementChild?.firstElementChild
			: document.querySelector(`td[data-resource-id="${user_id}"]`)?.firstElementChild;
	}

	_apply_cell_highlighting(cell) {
		Object.assign(cell.style, {
			textDecoration: 'underline',
			textDecorationColor: '#a1cdf7',
			textDecorationThickness: '2.5px',
		});
	}

	_highlight_user_cell(is_vertical_view) {
		const userId = this._state?.user;
		if (userId) {
			const targetCell = this._get_target_cell(userId, is_vertical_view);
			if (targetCell) {
				this._apply_cell_highlighting(targetCell);
			}
		}
	}

	/** @private */
	async make_event_from_fullcalendar_info(info, resource, list_view) {
		const view = this.view;

		const values = Object.assign({}, info.event.extendedProps, {
			doctype: view.doctype,
			[view.field_map.start]: view.get_system_datetime(info.event.start),
			[view.field_map.end]: view.get_system_datetime(info.event.end),
			[view.field_map.allDay]: Boolean(info.event.allDay),
		})

		// Create document and children in locals
		await frappe.model.with_doctype(values.doctype);
		const doc = frappe.model.copy_doc(values);
		doc.__todo = info.event.extendedProps.todo;


		if (resource && Object.keys(resource).length && info.event._def.resourceIds.length) {
			const selected_resource = resource.id
			if (resource?.extendedProps.resource_link && resource?.extendedProps.parentfield) {
				const resource_link = list_view.calendar_settings.dynamic_resources_map[list_view.resource]
				const split_str = resource_link.split('.')
				const resource_dt = split_str[0].replace("tab", "").replaceAll("`", "")
				const resource_link_field = split_str[split_str.length - 1]

				const split_resource = list_view.resource.split('.')
				const resource_name = split_resource[split_resource.length - 1]

				const resource_is_already_linked = doc[resource.extendedProps.parentfield].filter(f =>  f[resource_name] == selected_resource && f[resource_link_field] == resource.extendedProps.resource_link)
				if (!resource_is_already_linked.length) {
					const child_doc = frappe.model.get_new_doc(resource_dt, doc, resource.extendedProps.parentfield, 1)
					child_doc[resource_name] = selected_resource
					child_doc[resource_link_field] = resource?.extendedProps.resource_link
				}
			} else {
				doc[list_view.resource] = selected_resource;
			}
		}

		// Open the new document in a form
		frappe.set_route("Form", doc.doctype, doc.name);

		// Remove the draft event from the calendar, since it will be added again when the form is saved
		info.event.remove();

		// info.event.setProp("borderColor", "#ff0000");
		// info.event.setProp("title", __("Draft") + " - " + doc.name);
		// info.event.setProp("url", frappe.utils.get_form_link(doc.doctype, doc.name));
	}

	/** @private */
	_make_todo_list() {
		if (!this.view?.sidebar_menu) return;
		const sidebar = this.view.sidebar_menu.last();
		sidebar.find(".calendar-todo-list").remove();

		const todo_list = $("<div class='sidebar-section calendar-todo-list'></div>");
		todo_list.insertAfter(sidebar.find(".sidebar-section.views-section"))

		return new CalendarTodoList({
			wrapper: todo_list.get(0),
			parent: this,
			config: {
				event_from_todo: (todo) => {
					// console.log(todo);
				},
				get_query: () => {
					return {
						user: this._state.user,
					};
				}
			},
		});
	}
}

export class CalendarTodoList {
	/**
	 * @param {object} arg
	 * @param {HTMLElement} arg.wrapper
	 * @param {object} arg.config
	 * @param {number} arg.config.refreshInterval
	 * @param {number} arg.config.maxItems
	 * @param {string} arg.config.defaultDuration
	 * @param {string} arg.config.method
	 * @param {() => object} arg.config.get_query A function that returns arguments to pass to the method
	 * @param {Record<string, string>?} arg.config.color_map Map of todo status to pill color (NOT hex color, only named colors from the limited set of pill colors)
	 * @param {((todo: Record<string, any>) => Record<string, any>)?} arg.config.event_from_todo
	 */
	constructor({ wrapper, parent, config = {} }) {
		this.wrapper = wrapper;
		this.parent = parent;
		this._state = {
			requestId: 0,
			todos: [],
		};
		this.config = {
			refreshInterval: 30000,
			maxItems: 100,
			defaultDuration: "01:00",
			method: "silicon_ioi.ioi_core.doctype.ioi_event_resource.ioi_event_resource.get_todos",
			/**
			 * @type {Record<string, string>?}
			 * @example
			 * {
			 * 	"Open": "orange",
			 * 	"Closed": "green",
			 * 	"Cancelled": "red",
			 * }
			 */
			color_map: {},
			/** @type {((todo: Record<string, any>) => Record<string, any>)?} */
			event_from_todo: null,
			...config,
		};
		this.make();

		if (this.config.refreshInterval) {
			setInterval(() => {
				if (frappe.get_route_str().startsWith("List/Event/Resources")) {
					this.refresh();
				}
			}, this.config.refreshInterval || 30000);
		}
	}

	make() {
		this.body?.remove();
		this.wrapper.innerHTML = "";

		this.body = document.createElement("div");
		this.body.classList.add("calendar-todo-list__body");
		this.wrapper.appendChild(this.body);
		this.refresh();
	}

	setState(state) {
		this.parent.setState(state);
	}

	refresh() {
		const requestId = ++this._state.requestId;
		const args = {
			count: this.config.maxItems,
			...this.config.get_query?.() || {},
		}
		frappe.xcall(this.config.method, args).then((todos) => {
			if (!this._checkRequest(requestId)) return;
			this._state.todos = todos;
			this._render(this.render_list(todos));
		}).catch((exc) => {
			if (!this._checkRequest(requestId)) return;
			this._render(this.render_error(exc));
		});
	}

	/** @private */
	_checkRequest(requestId) {
		return requestId === this._state.requestId;
	}

	/** @private */
	_render(content) {
		this.body.innerHTML = "";
		this.body.appendChild(content);
	}

	/** @protected */
	render_empty() {
		return document.createTextNode(messages.no_items);
	}

	/** @protected */
	render_error(exc) {
		console.error(exc);
		return document.createTextNode(messages.error);
	}

	/** @protected */
	render_list_skeleton() {
		const root = document.createElement("div");
		root.style.position = "relative";

		const button = document.createElement("button");
		button.title = messages.refresh_button;
		button.innerHTML = frappe.utils.icon("refresh", "sm")
		button.classList.add("btn", "btn-xs", "icon-btn", "btn-default");
		button.addEventListener("click", () => this.refresh());
		Object.assign(button.style, {
			position: "absolute",
			top: "0",
			right: "35px",
		});
		root.appendChild(button);

		const new_button = document.createElement("button");
		new_button.title = messages.new_task_button;
		new_button.innerHTML = frappe.utils.icon("add", "sm")
		new_button.classList.add("btn", "btn-xs", "icon-btn", "btn-default");
		new_button.addEventListener("click", () => {
			const todo = frappe.model.get_new_doc("ToDo");
			todo.allocated_to = this.parent._state.user;
			todo.assigned_by = frappe.session.user;
			frappe.new_doc("ToDo", { quick_entry: true });

		});
		Object.assign(new_button.style, {
			position: "absolute",
			top: "0",
			right: "0",
		});
		root.appendChild(new_button);

		const title = document.createElement("h3");
		title.innerText = messages.list_title;
		root.appendChild(title);

		const user_selector = document.createElement("div");
		const user_selector_title = document.createElement("h6");
		user_selector_title.innerText = messages.user_selector_title;
		user_selector.appendChild(user_selector_title);
		user_selector.classList.add("calendar-todo-list__user-selector", "my-4");
		root.appendChild(user_selector);

		const help = document.createElement("p");
		help.classList.add("text-muted", "text-sm");
		help.innerText = messages.drop_help_text;
		root.appendChild(help);

		const body = document.createElement("ul");
		body.classList.add("list-unstyled");
		root.appendChild(body);

		return { root, user_selector, body };
	}

	/** @protected */
	render_list(todos) {
		const { root, user_selector, body } = this.render_list_skeleton();
		this.make_user_selector(user_selector)
		for (const todo of todos) {
			body.appendChild(this.render_item(todo));
		}
		return root;
	}

	/** @protected */
	render_item(todo) {
		const root = document.createElement("li");
		root.classList.add("pb-1");

		const item = document.createElement("div");
		const link = document.createElement("button");
		root.appendChild(item);
		item.appendChild(link);

		const hexcolor = this.get_color(todo);

		link.classList.add("ellipsis", "indicator-pill", "no-indicator-dot");
		if (hexcolor.startsWith("#")) {
			link.style.backgroundColor = "white";
			link.style.color = hexcolor;
		} else {
			link.classList.add(hexcolor);
		}
		link.innerText = this.get_title(todo);
		link.style.border = "1px solid currentColor";

		link.title = this.get_title(todo);

		this.addTodoPopup(link, todo)

		new Draggable(item, {
			eventData: () => {
				return this.get_fullcalendar_event_data_from_todo(todo)
			}
		});

		return root;
	}

	async addTodoPopup(link, todo) {
		const todoPopupManager = new TodoPopupManager({
			doctype: "ToDo",
			doc: todo,
			container: this.wrapper,
			resource_todo_dragger: this
		});
		await todoPopupManager.setup();

		todoPopupManager.addTodoPopup(link);
	}

	/** @protected */
	get_todo_link(todo) {
		return frappe.utils.get_form_link("ToDo", todo.name);
		// return frappe.utils.get_form_link(todo.reference_doctype, todo.reference_name);
	}

	/** @protected */
	get_color(todo) {
		if (todo.color) { return todo.color; }
		const key = todo.priority;
		const colorName = this.config.color_map?.[key] ?? frappe.utils.guess_colour(key) ?? "black";
		return frappe.ui.color.get(colorName);
	}

	/** @protected */
	get_title(todo) {
		// Get title from `_title`, or `name`, and truncate if necessary
		let title = this.strip_html(todo._title || todo.name);

		// TODO: truncate at Extended Grapheme Cluster boundary
		const max_length = 60;
		if (title.length > max_length) {
			title = [...title].slice(0, max_length).join("") + "…";
		}
		return `${todo.date ? frappe.datetime.obj_to_user(todo.date) : __("No Date")} | ${todo.priority[0]} | ${title}`;
	}

	/** @protected */
	strip_html(text) {
		const parsed = new DOMParser().parseFromString(String(text), "text/html");
		return parsed.body.textContent || "";
	}

	/** @protected */
	get_description(todo) {
		let desc = todo.description || todo._title || todo.name || "";
		// Insert link to ToDo
		desc += `<br><hr><br><a href="${this.get_todo_link(todo)}">${messages.open_todo}</a>`;
		return desc;
	}

	/**
	 * @see https://fullcalendar.io/docs/external-dragging
	 */
	get_fullcalendar_event_data_from_todo(todo) {
		const res = this.config.event_from_todo?.(todo, this, this.view);
		if (res) return res;

		const hexcolor = this.get_color(todo);
		const title = messages.task_title(this.get_title(todo));
		return {
			color: hexcolor,
			allDay: false,
			duration: this.config.defaultDuration,
			title: title,

			extendedProps: {
				...todo,
				color: hexcolor,
				subject: this.strip_html(todo._title || todo.name),
				status: todo.status,
				description: this.get_description(todo),
				event_type: "Private",
				doctype: "Event",
				todo: todo.name,
				resource_link: "User",
				parentfield: "event_participants",
				event_participants: [
					{
						doctype: "Event Participants",
						reference_doctype: "User",
						reference_docname: this.config.get_query?.()?.user,
					},
				],
			},
		};
	}

	make_user_selector(wrapper) {
		const config = this.config.get_query?.() || {}

		const df = {
			fieldname: "todo_user",
			fieldtype: "Link",
			label: __("User"),
			options: "User",
			change: () => {
				if (config.user != f.get_value()) {
					this.setState({ user: f.get_value() });
				}
			}
		}

		let f = frappe.ui.form.make_control({
			df: df,
			parent: wrapper,
			only_input: true,
		});
		if (config.user) {
			f.set_value(config.user)
		}
		f.refresh();
	}
}

export class ioiTaskCreationDialog {
	constructor({ fullcalendarInfo, user }) {
		this.fullcalendarInfo = fullcalendarInfo;
		this.user = user;
	}

	async show() {
		const fields = await this.get_fields();
		const values = await this.get_default_values();
		this.dialog = new frappe.ui.Dialog({
			title: messages.dialog_title,
			fields: fields,
			doc: values,
			primary_action: this.primary_action.bind(this),
			primary_action_label: this.primary_action_label,
		});
		this.dialog.show();
	}

	async get_fields() {
		const meta = frappe.get_meta("ToDo");
		const base_fields = new Set([
			"description",
			"allocated_to",
			"reference_type",
			"reference_name",
			"ioi_category_id",
		]);

		const fields = [];
		for (const df of meta.fields) {
			let keep = df.reqd || df.bold;
			keep ||= base_fields.has(df.fieldname);
			if (keep) {
				// NOTE: Do not hide fields
				fields.push(df);
			}
		}
		return fields;
	}

	async get_default_values() {
		const event_name = this.fullcalendarInfo.event.extendedProps.name;
		const todo = await frappe.xcall("silicon_ioi.ioi_core.doctype.ioi_event_resource.ioi_event_resource.map_event_to_task", { event_name })

		return {
			allocated_to: this.user,
			...todo,
		};
	}

	get primary_action_label() {
		return messages.create_task;
	}

	primary_action(values) {
		// this.fullcalendarInfo.event.remove();
		values.doctype = "ToDo";
		const doc = frappe.model.copy_doc(values);
		doc._autosave = true;
		frappe.set_route("Form", doc.doctype, doc.name);

		frappe.db.set_value("Event", this.fullcalendarInfo.event.id, "status", "Closed");
	}
}
