import QueryProfile from  "./QueryProfileBrowser.vue";
import { createApp, h } from 'vue';

frappe.provide("frappe.views");

frappe.views.ExpertView = class ioiExpertView extends frappe.views.Factory {
	constructor(opts) {
		super(opts);

		let me = this
		this.doctype = opts.doctype;
		this.page_name = frappe.get_route_str();
		this.current_select = {}
		this.fieldnames = {}
		this.query_fields = []
		this.saved_query_profile = ""
		this.current_site = ""
		this.global_filtering = []
		this.formatted_dt_name = this.doctype.toLowerCase().replaceAll(' ', '_')

		this.page = frappe.container.page.page
		this.meta = frappe.get_meta(this.doctype)

		frappe.breadcrumbs.add(
			locals.DocType[me.doctype].module,
			me.doctype
		);

		// Fix awesome bar
		frappe.container.page.list_view = this

		this.show()
	}

	get view_name() {
		return "ioi List Expert";
	}

	show() {
		this.render_page()
	}

	async render_page() {
		await this.check_user_site()

		silicon_ioi.ioiCommon.add_dropdown_prefix('EL', this.doctype);

		this.set_logo()
		this.page.set_title(__(this.doctype));

		// Force Sidebar open
		if (this.page.sidebar.is(":hidden")) {
			this.page.wrapper.find(".sidebar-toggle-btn").click()
		}

		if (this.is_multi_site)	await this.add_site_button()

		this.setup_view_menu()
		this.set_primary_action()
		this.set_menu_items()

		this.load_status();

		this.page.main.addClass("frappe-card");

		if (document.getElementById('ioi_expert_view')) {
			document.getElementById('ioi_expert_view').remove()
		}

		$(`<div id="ioi_expert_view" class="ioi_expert_view table table-responsive m-0 rounded" data-custom-grid="true"></div>`).appendTo(this.page.main);

		await this.get_profile_list()
		await this.create_query()
		this.update_query_user_settings(this.saved_query_profile)

		this.render_ioi_expert_grid()
		this.render_profile_selector()
		this.render_global_filter()
		this.render_saved_filters_menu()

		let me = this
		$(document).ready(function () { window.setTimeout(() => me.after_render(), 100) });
	}

	after_render() {
		this.init_list_side_view()
	}

	set_logo() {

		if (this.page.wrapper.find(".title-image")[0]) {
			this.page.wrapper.find(".title-image")[0].remove()
		}

		let html_before = this.page.wrapper.find(".title-area")[0]
		let html = `<div class="title-image mr-2"><img src='/assets/silicon_ioi/images/modules/${this.doctype.replace(/ /g, '_').toLowerCase()}.svg' width="56px" height="56px" style="horizontal-align:left;"></img></div>`

		html_before.insertAdjacentHTML("afterbegin", html)
	}

	async get_profile_list() {
		this.query_profile_list = await frappe.db.get_list('ioi Query Profile', {
			fields: [ 'name', 'is_public', 'is_standard' ],
			filters: { 'doc_type': this.doctype },
			or_filters: [ ['is_public', '=', 1], ['user_name', '=', frappe.session.user] ],
			limit: null
		})
	}

	async render_profile_selector() {

		const side_section = this.page.wrapper.find(".layout-side-section")[0]
		if (side_section.querySelector('.select-profile-section')) side_section.querySelector('.select-profile-section').remove()

		const container_html = `
			<div class="sidebar-section select-profile-section">
				<div class="list-group-by">
					<div id="ev_profile_select_container" class="list-group-by-fields d-flex align-items-center flex-row-reverse">
						<a id="ev_select_profile_settings" class="mx-2 mt-4">
							<svg class="icon" style="filter: opacity(0.5)">
								<use class="" href="#icon-setting-gear"></use>
							</svg>
						</a>
					</div>
					<div class="d-flex flex-wrap justify-content-start align-items-center">
						<li id="ev_save_column_button" class="add-list-group-by sidebar-action list-unstyled mt-2 mr-2">
							${this.saved_query_profile === "Default" ? '' : `
							<button class="btn btn-outline-secondary btn-xs">
								${__('Save Column Display')}
							</button>`}
						</li>
						<li id="ev_reset_column_button" class="add-list-group-by sidebar-action list-unstyled mt-2">
							${this.saved_query_profile === "Default" ? '' : `
							<button class="btn btn-outline-secondary btn-xs">
								${__('Reset Column Display')}
							</button>`}
						</li>
					</div>
				</div>
			</div>`

		side_section.insertAdjacentHTML("afterbegin", container_html)

		const options = this.query_profile_list.map(el => el.name)
		options.unshift('Default')

		const profile_switcher = frappe.ui.form.make_control({
			parent: this.page.wrapper.find("#ev_profile_select_container")[0],
			df: {
				label: __('Select Profile'),
				fieldname: 'switch_profile',
				fieldtype: 'Select',
				options: options,
			},
			render_input: true
		})

		profile_switcher.$wrapper.find('.form-group').css('margin-bottom', '0px')
		profile_switcher.$wrapper.addClass("w-100 mr-1")
		profile_switcher.$input.addClass("pr-5")
		profile_switcher.$input.val(this.saved_query_profile || 'Default')

		profile_switcher.$input.change(async (e) => {
			toggle_columns_button(e.target.value)
			this.saved_query_profile = e.target.value

			if (this.ioi_expert_view_table) this.ioi_expert_view_table.destroy()

			await this.get_query_fields()
			this.update_query_user_settings(e.target.value)
			this.render_ioi_expert_grid()
			this.render_global_filter()
			this.render_saved_filters_menu()
		})

		const settings = this.page.wrapper.find("#ev_select_profile_settings")[0]
		settings.onclick = () => this.show_query_profile_settings()

		const toggle_columns_button = (value) => {
			const save_button = this.page.wrapper.find("#ev_save_column_button")[0];

			if (value === "Default") {
				if (save_button.children.length) save_button.firstElementChild.remove();
			} else {
				if (save_button.children.length) save_button.firstElementChild.remove();
				save_button.insertAdjacentHTML('afterbegin', `<button class="btn btn-outline-secondary btn-xs">${__('Save Column Display')}</button>`);
				save_button.querySelector('button').onclick = () => this.save_table_display(frappe.model.user_settings[this.doctype]["ioi Expert Profile"], value)
			}

			const reset_button = this.page.wrapper.find("#ev_reset_column_button")[0];

			if (value === "Default") {
				if (reset_button.children.length) reset_button.firstElementChild.remove();
			} else {
				if (reset_button.children.length) reset_button.firstElementChild.remove();
				reset_button.insertAdjacentHTML('afterbegin', `<button class="btn btn-outline-secondary btn-xs">${__('Reset Column Display')}</button>`);
				reset_button.querySelector('button').onclick = () => this.reset_table_display(value)
			}
		}

		toggle_columns_button(this.saved_query_profile)
	}

	render_global_filter() {

		const side_section = this.page.wrapper.find(".layout-side-section")[0].querySelector('.select-profile-section')
		if (side_section.querySelector('.global-filter-section')) side_section.querySelector('.global-filter-section').remove()

		const container_html = `
			<div class="sidebar-section global-filter-section mt-4">
				<li class="sidebar-label">${__('Global Filter')}</li>
					<li class="input-area list-unstyled mt-3">
						<div class="frappe-control input-max-width" data-fieldtype="Data">
							<div class="form-group">
								<div class="control-input-wrapper">
									<div class="control-input">
										<input id="expert_view_global_filters_input" style="background-color:var(--control-bg-on-gray);" type="text" autocomplete="off" class="input-with-feedback form-control input-xs" maxlength="140" data-fieldtype="Data" placeholder="${__('Filter All Columns')}">
									</div>
									<div class="control-value like-disabled-input" style="display: none;"></div>
									<p class="help-box small text-muted"></p>
								</div>
							</div>
						</div>
					</li>
				</li>
			</div>`

		side_section.insertAdjacentHTML("beforeend", container_html)

		const input = this.page.wrapper.find("#expert_view_global_filters_input")[0]
		
		let timeoutId;

		input.oninput = (e) => {
			clearTimeout(timeoutId);

			timeoutId = setTimeout(() => {

				// Reset all exisiting filters
				if (this.filter_selected) {
					this.page.wrapper[0].querySelector(`#expert_view_saved_filters_container .list-link[data-filter-name='${this.filter_selected}']`).classList.replace('btn-primary-light', 'btn-default')
					this.ioi_expert_view_table.clearFilter(true)
					this.filter_selected = undefined
				}

				// Set global filters
				let value = e.target.value;

				const global_filter = this.ioi_expert_view_table.getColumns().map(col => {
					const field = col.getField()

					if (field !== "responsiveCollapse" && !!value) {
						return { field: field, type: "like", value: value }
					}
				}).filter(el => !!el)

				this.global_filtering = global_filter.reduce((acc, filter) => {

					if (filter.field === "name" && filter.value.includes('/')) {
						filter.value = filter.value.replace(/\s*\/\s*/, ' • ').replace(/\s+/g, ' ').trim();
					}

					if (filter.value.includes('|')) {
						const sub_filters = filter.value.split('|').map(value => ({
							"field": filter.field,
							"type": filter.type,
							"value": value.trim(),
						}));

						acc.push(...sub_filters);
					} else {

						acc.push(filter);
					}

					return acc;
				}, []);

				this.ioi_expert_view_table.setFilter(global_filter)
				localStorage.setItem(`tabulator-ioi_expert_view_${this.formatted_dt_name}-globalFilter`, JSON.stringify(global_filter));
				localStorage.setItem(`tabulator-ioi_expert_view_${this.formatted_dt_name}-saveFilter`, "");

			}, 300);
		}
	}

	async render_saved_filters_menu(new_added_profile=undefined) {
		const side_section = this.page.wrapper.find(".layout-side-section")[0].querySelector('.select-profile-section')
		if (side_section.querySelector('.save-filter-section')) side_section.querySelector('.save-filter-section').remove()

		this.filter_selected = new_added_profile ? new_added_profile : undefined
		const profile = this.saved_query_profile

		const container_html = `
			<div class="sidebar-section save-filter-section mt-4">
				<li class="sidebar-label">${__('Save Filter')}</li>
					<li class="input-area list-unstyled mt-3">
						<div class="frappe-control input-max-width" data-fieldtype="Data">
							<div class="form-group">
								<div class="control-input-wrapper">
									<div class="control-input">
										<input id="expert_view_saved_filters_input" style="background-color:var(--control-bg-on-gray);" type="text" autocomplete="off" class="input-with-feedback form-control input-xs" maxlength="140" data-fieldtype="Data" placeholder="${__('Filter Name')}">
									</div>
									<div class="control-value like-disabled-input" style="display: none;"></div>
									<p class="help-box small text-muted"></p>
								</div>
							</div>
						</div>
						<div id="expert_view_checkboxes" class="form-group frappe-control input-max-width hide-control" data-fieldtype="Check">
							<div class="checkbox">
								<label>
									<span class="input-area">
										<input type="checkbox" class="input-with-feedback" data-fieldtype="Check" checked>
									</span>
									<span class="label-area">${__('Is Public')}</span>
								</label>
							</div>
						</div>
					</li>
					<div id="expert_view_saved_filters_container" class="saved-filters" style=""></div>
				</li>
			</div>`

		side_section.insertAdjacentHTML("beforeend", container_html)

		const input = this.page.wrapper.find("#expert_view_saved_filters_input")[0]
		const label = input.closest(".control-input-wrapper").querySelector("p")
		const checkboxes = this.page.wrapper.find('#expert_view_checkboxes')[0]
		const saved_filters = this.page.wrapper.find('#expert_view_saved_filters_container')[0]

		const profile_saved_filters = await frappe.call('silicon_ioi.utils.lib.system.get_saved_filters', { parent: this.saved_query_profile }).then(r => {
			let user_filters = []

			if (r.message.length) {
				user_filters = r.message.filter(filter => filter.is_public === 1 || (filter.is_public === 0 && filter.owner === frappe.session.user));
			}

			return user_filters
		})

		if (profile_saved_filters.length) profile_saved_filters.forEach(filter => this.add_new_filters(saved_filters, filter.filter_name, profile, filter.filters))

		let timeoutId;
		let hide_show_checkboxes = (opts) => checkboxes.classList.replace(`${opts === "hide" ? "show" : "hide"}-control`, `${opts === "show" ? "show" : "hide"}-control`)

		input.oninput = (e) => {
			clearTimeout(timeoutId);

			timeoutId = setTimeout(() => {
				let value = e.target.value;

				if (!value || value === "") {
					label.textContent = '';
					hide_show_checkboxes("hide")
				} else if (this.saved_query_profile === "Default") {
					label.textContent = __('Filters cannot be saved on a default profile')
					hide_show_checkboxes("hide")
				} else if (saved_filters.children.length) {
					if ([...saved_filters.querySelectorAll('.list-link .filter-name')].find(filter => value == filter.textContent)) {
						label.textContent = __('Duplicate Filter Name')
						hide_show_checkboxes("hide")
					} else {
						label.textContent = __('Press Enter to save');
						hide_show_checkboxes("show")
					}
				} else {
					label.textContent = __('Press Enter to save');
					hide_show_checkboxes("show")
				}
			}, 300);
		}

		input.onkeydown = (e) => {
			if (e.key === "Enter") {
				setTimeout(async () => {
					if (label.textContent === __('Press Enter to save')) {
						const value = input.value
						const is_public = checkboxes.querySelector('input').checked ? 1 : 0

						if (profile && profile !== "Default") await frappe.call('silicon_ioi.utils.lib.system.update_saved_filters', { profile: profile, filter_name: value, is_public: is_public, filters: this.ioi_expert_view_table.getFilters(true), action: "add" })
						return this.render_saved_filters_menu(value)
					}
				}, 250)
			}
		}
	}

	add_new_filters(saved_filters, value, profile, filters) {
		const filter_element_html = `
			<div class="list-link filter-pill list-sidebar-button btn btn-default w-100 mt-3 d-flex justify-content-between align-items-center" style="height: 27.5px; padding: 0 1rem;" data-filter-name="${value}">
				<a class="ellipsis filter-name text-left w-100 h-100 pt-1">${value}</a>
				<a class="remove">
					<svg class="icon  icon-sm" style="">
						<use class="" href="#icon-close"></use>
					</svg>
				</a>
			</div>`

		saved_filters.insertAdjacentHTML("afterbegin", filter_element_html)

		const filter_el = saved_filters.querySelector(`.list-link[data-filter-name='${value}']`)
		const del_arrow = saved_filters.querySelector(`[data-filter-name='${value}'] .remove`)

		if (value === this.filter_selected) filter_el.classList.replace('btn-default', 'btn-primary-light')

		filter_el.firstElementChild.onclick = (e) => {
			this.ioi_expert_view_table.clearFilter(true)
			this.page.wrapper.find("#expert_view_global_filters_input").val('')

			if (this.filter_selected === value) {
				e.target.parentElement.classList.replace('btn-primary-light', 'btn-default')
				this.filter_selected = undefined
				localStorage.setItem(`tabulator-ioi_expert_view_${this.formatted_dt_name}-saveFilter`, "");

			} else {
				if (this.filter_selected) saved_filters.querySelector(`.list-link[data-filter-name='${this.filter_selected}']`).classList.replace('btn-primary-light', 'btn-default')
				e.target.parentElement.classList.replace('btn-default', 'btn-primary-light')

				this.filter_selected = value
				const format_filters = JSON.parse(filters)

				if (format_filters.length) {
					Array.from(document.querySelectorAll('.tabulator-header-filter input')).forEach(col => col.value = "")

					this.ioi_expert_view_table.getColumns().forEach(col => {
						let field = format_filters.find(filter => filter.field === col.getField())

						if (col.getElement().querySelector('input') && field) {
							col.getElement().querySelector('input').value = field.value
						}
					})

					this.ioi_expert_view_table.setFilter(format_filters)
					localStorage.setItem(`tabulator-ioi_expert_view_${this.formatted_dt_name}-saveFilter`, JSON.stringify(value));
					localStorage.setItem(`tabulator-ioi_expert_view_${this.formatted_dt_name}-globalFilter`, []);
				}
			}
		}

		del_arrow.onclick = () => frappe.confirm(__(`Are you sure you want to remove the <strong>{0}</strong> filter?`,[value]),
			() => {
				if (this.filter_selected === value) {
					this.filter_selected = undefined
					this.ioi_expert_view_table.clearFilter(true)
				}

				if (profile && profile !== "Default") {
					frappe.call('silicon_ioi.utils.lib.system.update_saved_filters', { profile: profile, filter_name: value, action: "remove" }).then(() => filter_el.remove())
				} else {
					filter_el.remove()
				}
			}, () => {})
	}

	async check_user_site() {
		let me = this;

		await frappe.call({
			method: 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_has_site',
			args: {
				check_if_multi_site: true
			},
			async: false,
			callback: function (r) {
				me.current_site = r.message.site_id;
				me.is_multi_site = r.message.multi_site
			}
		})
	}

	get_search_params() {
		let search_params = new URLSearchParams();

		this.get_filters_for_args().forEach((filter) => {
			if (filter[2] === "=") {
				search_params.append(filter[1], filter[3]);
			} else {
				search_params.append(filter[1], JSON.stringify([filter[2], filter[3]]));
			}
		});
		return search_params;
	}

	get_filters_for_args() {
		// filters might have a fifth param called hidden,
		// we don't want to pass that server side
		return this.filter_area ? this.filter_area.get().map((filter) => filter.slice(0, 4)) : [];
	}

	setup_view_menu() {

		if (this.page.wrapper.find(".custom-btn-group")[0]) {
			this.page.wrapper.find(".custom-btn-group")[0].remove()
		}

		this.list_view = this
		this.list_view.settings = {}

		if (frappe.boot.desk_settings.view_switcher && !this.meta.force_re_route_to_default_view) {
			const icon_map = {
				Image: "image-view",
				List: "list",
				Report: "small-file",
				Calendar: "calendar",
				Gantt: "gantt",
				Kanban: "kanban",
				Dashboard: "dashboard",
				Map: "map",
				'ioi List Expert': "list",
				'ioi Pivot Expert': "list"
			};

			const label_map = {
				List: __("List View"),
				Report: __("Report View"),
				Dashboard: __("Dashboard View"),
				Gantt: __("Gantt View"),
				Kanban: __("Kanban View"),
				Calendar: __("Calendar View"),
				Image: __("Image View"),
				Inbox: __("Inbox View"),
				Tree: __("Tree View"),
				Map: __("Map View"),
				'ioi List Expert': __('ioi List Expert'),
				'ioi Pivot Expert': __('ioi Pivot Expert')
			};

			this.views_menu = this.page.add_custom_button_group(
					label_map[this.view_name] || label_map["List"],
					icon_map[this.view_name] || "list"
			);
			this.views_list = new frappe.views.ListViewSelect({
				doctype: this.doctype,
				parent: this.views_menu,
				page: this.page,
				list_view: this,
				sidebar: this.list_sidebar,
				icon_map: icon_map,
				label_map: label_map,
			});
		}

		if (!frappe.listview_settings[this.doctype] || (frappe.listview_settings[this.doctype] && !frappe.listview_settings[this.doctype].kanban)) {
			this.views_menu.find('[data-view="Kanban"]').hide();
		}

		this.add_refresh_grid_button()
	}

	set_menu_items() {
		this.menu_items = [
			{ label: __('Export Current List'), action: () => this.data_export(), standard: true },
			{ label: __('List Settings'), action: () => this.show_query_profile_settings(), standard: true },
		]

		this.menu_items.map((item) => {
			if (item.condition && item.condition() === false) {
				return;
			}
			const $item = this.page.add_menu_item(
				item.label,
				item.action,
				item.standard,
				item.shortcut
			);
			if (item.class) {
				$item && $item.addClass(item.class);
			}
		});
	}

	data_export () {
		let me = this

		let download = (data=null) => {
			let filename = document.getElementById('export_filename').value;
			let filetype = document.getElementById('export_file_type').value;

			if (filename.trim() == '') {
				filename = this.doctype;
			}

			if (data != null) {
				me.ioi_expert_view_table.replaceData(data)
			}

			if (filetype.toUpperCase() == 'XLSX') {
				me.ioi_expert_view_table.download("xlsx", `${filename}.xlsx`);
			} else {
				me.ioi_expert_view_table.download("csv", `${filename}.csv`, { delimiter: ";" })
			}
		}

		let export_tabulator = () => {
			let config = { method: 'get' }
			let params = {}
			let export_limit = undefined

			if (document.getElementById('export_file_limit').value != this.ioi_expert_view_table.getData().length) {
				export_limit = document.getElementById('export_file_limit').value;
			}

			if (!export_limit) {
				download()
			} else {

				const export_with_limit = () => {
					let url = this.generate_url('/api/method/silicon_ioi.utils.lib.system.get_custom_query_list', config, params, export_limit)
					let current_data = this.ioi_expert_view_table.getData()

					$.ajax({
						url: url,
					}).done(function (r) {
						download(r.message.data)
						me.ioi_expert_view_table.replaceData(current_data)
					})
				}

				if (export_limit == "all") {
					frappe.confirm(__('Accessing all records may cause a temporary delay'),
						() => {
							export_with_limit()
						}, () => {
							// action to perform if No is selected
						})
				} else {
					export_with_limit()
				}
			}
		}

		silicon_ioi.ioiCommon.export_form(export_tabulator, true, true);
	}

	add_refresh_grid_button() {

		if (document.getElementById('expert_view_refresh_button')) {
			document.getElementById('expert_view_refresh_button').remove()
		}

		let html = `
			<button id="expert_view_refresh_button" class="text-muted btn btn-default icon-btn" data-original-title="Refresh">
				<svg class="es-icon es-line  icon-sm" style="" aria-hidden="true">
					<use class="" href="#es-line-reload"></use>
				</svg>
			</button>`

		let action = () => {
			this.render_global_filter()
			this.render_saved_filters_menu()

			if (this.ioi_expert_view_table) {
				this.ioi_expert_view_table.destroy()
			}

			this.render_ioi_expert_grid()
			frappe.show_alert({ message: __('Table display has been redrawn'), indicator:'green' }, 5)
		}
		this.add_custom_action(html, action)
	}

	async add_site_button() {
		await frappe.db.get_value('ioi Module', this.doctype, 'select_site').then(r => {
			if (r.message.select_site == 1) {

				if (document.getElementById('expert_view_site_button')) {
					document.getElementById('expert_view_site_button').remove()
				}

				let html = `<button id="expert_view_site_button" class="btn btn-default btn-sm ellipsis">${__('Site')} : ${this.current_site}</button>`

				let action = () => this.show_select_site(true)
				this.add_custom_action(html, action)
			} else {
				this.current_site = ''
			}
		})
	}

	show_select_site(reload = false) {
		if (!document.getElementById('ioi_site_list')) {

			let html = '';

			let me = this;
			let method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_site'

			frappe.call({
				method: method,
				args: {},
				async: false,
				callback: function (r) {
					me.current_site = r.message;
				}
			});

			let division_tab = []
			method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_division_with_sites'

			frappe.call({
				method: method,
				args: {},
				async: false,
				callback: function (r) {
					division_tab = r.message;
				}
			});

			let current_division = ''

			if ((me.current_site) && (me.current_site.trim() != '')) {

				method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_division_for_site'

				frappe.call({
					method: method,
					args: { "site_id": me.current_site },
					async: false,
					callback: function (r) {
						current_division = r.message;
					}
				});

			} else {
				if (division_tab.length != 0) {
					current_division = division_tab[0].name;
				}
			}

			if (document.getElementById('ioi_site_list_label')) {
				document.getElementById('ioi_site_list_label').remove();
			}
			if (document.getElementById('ioi_site_list')) {
				document.getElementById('ioi_site_list').remove();
			}

			html += '<div style="position: relative; top: -20px; left: 0px; width:300px; height: 25px;">';
			html += '	<label id="ioi_site_list_label" style="position: absolute; top: 0px; left: 2px;">' + __("Select a site") + '</label>';
			html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width:300px; height: 25px;"> ';
			html += '		<select id="ioi_site_list" class="input-with-feedback form-control bold"> ';

			if (current_division != '') {

				method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_sites_for_division'

				frappe.call({
					method: method,
					args: { "division_id": current_division },
					async: false,
					callback: function (r) {

						for (var i = 0; i < r.message.length; i++) {
							if (me.current_site == r.message[i].name) {
								html += '		<option value="' + r.message[i].name + '" selected>' + r.message[i].description + '</option> ';
							} else {
								html += '		<option value="' + r.message[i].name + '">' + r.message[i].description + '</option> ';
							}
						}
					}
				});
			}

			html += '		</select> ';
			html += '	</div>';
			html += '</div>';

			if (division_tab.length > 1) {

				html += '<div style="position: relative; top: -45px; left: 310px; width:200px; height: 32px;">';
				html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width:200px; height: 32px;"> ';
				html += '		<input id="ioi_select_division" type="button" class="btn btn-default ellipsis" value="' + __("Change division") + '" style="width:100%; height:100%">';
				html += '	</div>';
				html += '</div>';

				if (document.getElementById('ioi_select_division')) {
					document.getElementById('ioi_select_division').remove();
				}
			}

			frappe.confirm(html,
				() => {
					// action to perform if Yes is selected
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 1].disabled = true;

					if ((!document.getElementById('ioi_site_list')) || (document.getElementById('ioi_site_list').value.trim() == '')) {
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 1].disabled = false;
						frappe.msgprint({ title: __("Message"), message: __('Site is mandatory'), indicator: "red" });
						raise;
					}

					me.current_site = document.getElementById('ioi_site_list').value;

					let method_user = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_save_site'
					frappe.call({
						method: method_user,
						args: { "site_id": document.getElementById('ioi_site_list').value },
						async: false,
						callback: function (r) {
							if (reload) {
								window.location.reload();
							}
						}
					});
				},
				() => {
					// action to perform if No is selected or if click outsite the modal screen
					if (reload) {
						window.location.reload();
					}
				}
			);

			this.sleep_ms(300).then(() => {

				let fct_click = function () {

					var changeDivisionDialog = new frappe.ui.Dialog({
						title: __("Change division"),
						static: true,
						fields: [{ 'fieldname': 'html_change_division', 'fieldtype': 'HTML' }
						],
						primary_action_label: __("Ok"),
						primary_action: function () {

							let value = document.getElementById('html_change_division_id').value;

							changeDivisionDialog.hide();

							if (value != current_division) {

								let method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_sites_for_division';

								frappe.call({
									method: method,
									args: { "division_id": value },
									async: false,
									callback: function (r) {

										while (document.getElementById('ioi_site_list').options.length > 0) {
											document.getElementById('ioi_site_list').remove(0);
										}

										for (var i = 0; i < r.message.length; i++) {

											var option = document.createElement("option");
											option.value = r.message[i].name;
											option.text = r.message[i].description;
											document.getElementById('ioi_site_list').add(option);
										}
									}
								});

								current_division = value;
							}
						},
						secondary_action_label: __("Cancel"),
						secondary_action: function () {

							changeDivisionDialog.hide();
						}
					});

					if (document.getElementById('html_change_division_label')) {
						document.getElementById('html_change_division_label').remove();
					}

					if (document.getElementById('html_change_division_id')) {
						document.getElementById('html_change_division_id').remove();
					}

					let html = ""
					html += '<div style="height: 70px;">';
					html += '	<div style="position: relative; top: 0px; left: 0px; width:300px; height: 25px;">';
					html += '		<label id="html_change_division_label" style="position: absolute; top: 0px; left: 2px;">' + __("Select a division") + '</label>';
					html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width:300px; height: 25px;"> ';
					html += '			<select id="html_change_division_id" class="input-with-feedback form-control bold"> ';

					for (var i = 0; i < division_tab.length; i++) {
						if (current_division == division_tab[i].name) {
							html += '		<option value="' + division_tab[i].name + '" selected>' + division_tab[i].description + '</option> ';
						} else {
							html += '		<option value="' + division_tab[i].name + '">' + division_tab[i].description + '</option> ';
						}
					}

					html += '			</select> ';
					html += '		</div>';
					html += '	</div>';
					html += '</div>';

					changeDivisionDialog.fields_dict.html_change_division.$wrapper.html(html);
					changeDivisionDialog.$wrapper.find('.modal-dialog').css("max-width", "340px").css("width", "340px");
					changeDivisionDialog.show();
				}

				document.getElementById('ioi_select_division').onclick = fct_click;

			});

			this.sleep_ms(200).then(() => {

				if (division_tab.length <= 1) {
					document.getElementsByClassName('modal-content')[document.getElementsByClassName('modal-content').length - 1].style.width = '340px';
				} else {
					document.getElementsByClassName('modal-content')[document.getElementsByClassName('modal-content').length - 1].style.width = '555px';
				}
			});
		}
	}

	sleep_ms(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}


	add_custom_action(html, action) {
		this.page.wrapper.find(".custom-actions")[0].classList.replace('hide', 'show')

		let action_button =  $(html);
		this.page.custom_actions.append(action_button);

		action && action_button.click(action);
	}

	set_primary_action() {
		const label = `${__("Add", null, "Primary action in list view")} ${__(this.doctype)}`
		this.page.set_primary_action(
			label,
			() => {
				frappe.new_doc(this.doctype)
			},
			"add"
		)
	}

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////                  Profile Query Settings                   /////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	setup_query_fields() {

		let fields_html = this.query_profile_settings.get_field("selected_fields");
		fields_html.html(``);

		let fields = ``;

		const arrow_down = `<button class="btn btn-outline-secondary btn-xs insert_query_field" style="font-size: var(--text-xs);" title="${__('Insert below')}">
								<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
									<path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
								</svg>
							</button>`

		const edit_icon = ` <button class="btn btn-outline-secondary btn-xs edit_query_field" style="font-size: var(--text-xs);" title="${__('Edit label')}">
								<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
									<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
								</svg>
							</button>`

		const del_icon = `  <button class="btn btn-outline-secondary btn-xs remove_query_field" style="font-size: var(--text-xs);" title="${__('Remove field')}">
								<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
									<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
									<path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
								</svg>
							</button>`

		const curr_profile = this.query_profile_list.find(el => el.name == this.saved_query_profile)
		const is_standard = curr_profile?.name !== "Default" && curr_profile?.is_standard === 1;

		for (let idx in this.query_fields) {
			fields += `
				<div class="control-input form-control ${this.query_fields[idx].field === "_assign" ? 'hide' : ''}" style="margin-bottom: 5px; padding-top: 4px; font-size: var(--text-md);" data-fieldname="${this.query_fields[idx].field}" data-idx="${idx}">
					<div class="row d-flex justify-content-between align-items-center mx-1">
						<div class="text-truncate">
							${this.query_fields[idx].path}
						</div>
						<div>
							<a class="text-muted insert-field" data-fieldname="${this.query_fields[idx].path}">
								${	this.saved_query_profile != "Default" &&
									!!this.saved_query_profile &&
									!is_standard ?
									arrow_down : ""
								}
							</a>
							<a class="text-muted edit-field" data-fieldname="${this.query_fields[idx].path}">
								${	this.saved_query_profile != "Default" &&
									!!this.saved_query_profile &&
									!is_standard ?
									edit_icon : ""
								}
							</a>
							<a class="text-muted remove-field" data-fieldname="${this.query_fields[idx].path}">
								${	this.saved_query_profile != "Default" &&
									!!this.saved_query_profile &&
									this.query_fields[idx].path != 'name' &&
									!is_standard ?
									del_icon : ""
								}
							</a>
						</div>
					</div>
				</div>`;
		}

		fields_html.html(`
			<div class="form-group">
				<div class="clearfix">
					<label class="control-label mb-3" style="padding-right: 0px;">${__('Selected fields')}</label>
				</div>
				<div class="control-input-wrapper" style="max-height: 580px; overflow: auto;">
					${fields.length ? fields : `
						<span class="d-flex justify-content-center" style="font-size: var(--text-sm);">${__('No field selected')}</span>
					`}
				</div>
				<div class="help-box small text-muted mt-3">${__('The order of the columns can be adjusted by dragging them within the table. Remember to save your changes to keep these modifications.')}</div>
			</div>
		`);

		fields_html.$wrapper.off("click", ".insert_query_field");
		fields_html.$wrapper.off("click", ".edit_query_field");

		fields_html.$wrapper.on("click", ".insert_query_field", (e) => {
			const $idx = $(e.currentTarget).closest('.control-input').data('idx');
			this.add_field(this.query_profile_settings.fields_dict, Number($idx) + 1);
		});

		fields_html.$wrapper.on("click", ".edit_query_field", (e) => {
			const $fieldname = $(e.currentTarget).closest('.control-input').data('fieldname');
			this.edit_query_field($fieldname);
		});

		this.setup_remove_fields()
	}

	edit_query_field(fieldname) {
		let me  = this

		let d = new frappe.ui.Dialog({
			title: __('Update label'),
			fields: [
				{
					label: __('New label'),
					fieldname: 'new_label',
					fieldtype: 'Data',
					description: __('Current label is "{0}"', [__(me.query_fields.find(el => el.field == fieldname).label)])
				},
			],
			size: 'small',
			primary_action_label: __('Update'),
			primary_action(values) {

				me.query_fields.find(el => el.field == fieldname).label = values.new_label
				frappe.call('silicon_ioi.utils.lib.system.edit_query_field_label', { name: me.query_profile_settings.fields_dict.profile.value, fields: me.query_fields }).then(() => d.hide())

			},
			secondary_action_label: __('Cancel'),
			secondary_action() {
				d.hide();
			}
		});

		d.show();
	}

	async on_query_profile_change(changeValue = null) {
		const dialog = this.query_profile_settings
		const fields_dict = dialog.fields_dict

		const value = changeValue ?? fields_dict.profile.value;
		this.saved_query_profile = value;

		const is_default = value === 'Default';

		const toggle_visibility = (fields, hidden) => {
			fields.forEach(field => {
				fields_dict[field].wrapper.hidden = hidden;
			});
		};

		const custom_actions = dialog.custom_actions[0]

		custom_actions.children[0].classList.toggle('hide', is_default);
		custom_actions.children[1].classList.toggle('hide', is_default);
		fields_dict.add_button.input_area.hidden = is_default;

		toggle_visibility(["is_public", "is_standard", "show_assigned_users", "show_last_updated_on", "show_creation"], is_default);

		const curr_profile = this.query_profile_list.find(el => el.name === value);
		this.toggle_dialog_readonly(fields_dict, custom_actions, curr_profile)

		await this.get_query_fields();
		this.setup_query_fields();
	}

	toggle_dialog_readonly(fields_dict, custom_actions, curr_profile) {
		const is_readonly = curr_profile == undefined || curr_profile?.is_standard === 1;

		// Toggle test query/remove button visibility
		custom_actions.children[0].classList.toggle('hide', is_readonly);
		custom_actions.children[1].classList.toggle('hide', is_readonly);

		fields_dict.is_public.$wrapper.find('input').prop('disabled', is_readonly);
		fields_dict.show_assigned_users.$wrapper.find('input').prop('disabled', is_readonly);
		fields_dict.show_last_updated_on.$wrapper.find('input').prop('disabled', is_readonly);
		fields_dict.show_creation.$wrapper.find('input').prop('disabled', is_readonly);
		fields_dict.add_button.$wrapper.find('button').prop('hidden', is_readonly);

		const warning_message = is_readonly ? __('This profile cannot be modified') : '';
		fields_dict.profile.$wrapper.find('#warning_default_message').text(warning_message);

		// Set main content to read only
		const pointer_events = is_readonly ? "none" : "";
		const opacity = is_readonly ? "0.65" : "1";

		fields_dict.structure_html.$wrapper.css({"pointer-events": pointer_events, "opacity": opacity});
		fields_dict.selected_fields.$wrapper.css({"pointer-events": pointer_events, "opacity": opacity});
		fields_dict.show_assigned_users.$wrapper.css({"pointer-events": pointer_events, "opacity": opacity});
		fields_dict.show_last_updated_on.$wrapper.css({"pointer-events": pointer_events, "opacity": opacity});
		fields_dict.show_creation.$wrapper.css({"pointer-events": pointer_events, "opacity": opacity});
	}

	async show_query_profile_settings() {
		let me = this
		this.is_fields_changed = false

		await this.get_profile_list()

		const dialog_fields = [
			{
				'label': __('Select a profile'),
				'fieldname': 'profile',
				'fieldtype': 'Select',
				'default': me.saved_query_profile,
				'description':`<p id="warning_default_message" class="text-danger">${ me.saved_query_profile == "Default" || me.saved_query_profile == "" ? __('This profile cannot be modified'): ""}</p>`
			},
			{
				'label': __('New profile'),
				'fieldname': 'new_button',
				'fieldtype': 'Button',
				'btn_size': 'xs',
			},
			{
				'label': __('Is public'),
				'fieldname': 'is_public',
				'fieldtype': 'Check',
			},
			{
				'label': __('Is standard'),
				'fieldname': 'is_standard',
				'fieldtype': 'Check',
				'read_only': true,
			},
			{
				"fieldname": "section",
				"fieldtype": "Section Break",
			},
			{
				'fieldtype': 'HTML',
				'fieldname': 'structure_html'
			},
			{
				'label': __('Add field'),
				'fieldname': 'add_button',
				'fieldtype': 'Button',
				'btn_size': 'xs',
			},
			{
				"fieldname": "section",
				"fieldtype": "Column Break",
			},
			{
				"fieldname": "selected_fields",
				"fieldtype": "HTML",
			},
			{
				'label': __('Show assigned users'),
				'fieldname': 'show_assigned_users',
				'fieldtype': 'Check',
				'default': this.query_fields.some(el => el.field == "_assign"),
			},
			{
				'label': __('Show last updated on'),
				'fieldname': 'show_last_updated_on',
				'fieldtype': 'Check',
				'default': this.query_fields.some(el => el.field == "modified"),
			},
			{
				'label': __('Show creation date'),
				'fieldname': 'show_creation',
				'fieldtype': 'Check',
				'default': this.query_fields.some(el => el.field == "creation"),
			},
		]

		this.query_profile_settings = new frappe.ui.Dialog({
			size: 'extra-large',
			title: __('Query Profile Settings'),
			fields: dialog_fields,
			primary_action_label: __('Save'),
			primary_action(values) {

				const curr_profile = me.query_profile_list.find(el => el.name === values.profile);
				const is_standard = curr_profile?.name !== "Default" && curr_profile?.is_standard === 1;

				if (is_standard != 1) {
					if (me.is_fields_changed && me.saved_query_profile != "Default") {
						frappe.db.set_value('ioi Query Profile', me.query_profile_settings.fields_dict.profile.value, 'display', '')
					} else {
						me.save_table_display(frappe.model.user_settings[me.doctype]["ioi Expert Profile"], me.query_profile_settings.fields_dict.profile.value)
					}
				}

				me.update_query_user_settings(me.query_profile_settings.fields_dict.profile.value)

				me.query_profile_settings.hide();

				if (me.ioi_expert_view_table) {
					me.ioi_expert_view_table.destroy()
				}

				me.render_ioi_expert_grid()
				me.render_profile_selector()
				me.render_global_filter()
				me.render_saved_filters_menu()
			},
			on_page_show: () => {
				me.format_profile_list(fields_dict)
			}
		})

		let fields_dict = this.query_profile_settings.fields_dict

		fields_dict.profile.$input[0].onchange = (e) => this.on_query_profile_change(e.target.value)
		fields_dict.add_button.$input[0].onclick = () => this.add_field(fields_dict)
		fields_dict.is_public.$input[0].onclick = () => frappe.db.set_value('ioi Query Profile', fields_dict.profile.value, 'is_public', fields_dict.is_public.value == 1 ? 0 : 1)
		fields_dict.new_button.$input[0].onclick = () => me.create_new_query_profile()
		fields_dict.show_assigned_users.$input[0].onclick = (e) => me.show_assigned_users(e, fields_dict)
		fields_dict.show_last_updated_on.$input[0].onclick = (e) => me.show_last_updated_on(e, fields_dict)
		fields_dict.show_creation.$input[0].onclick = (e) => me.show_creation(e, fields_dict)

		this.query_profile_settings.add_custom_action(__('Remove'), function () {
			me.remove_query_profile(fields_dict.profile.value)
			me.on_query_profile_change()
		}, 'btn-danger');

		this.query_profile_settings.add_custom_action(__('Reset Column Display'), () => me.reset_table_display(fields_dict.profile.value), 'ml-2');

		this.query_profile_settings.show();
	}

	format_profile_list(fields_dict) {

		const r = this.query_profile_list

		fields_dict.profile.df.options = r.map(el => el.name)
		fields_dict.profile.df.options.unshift('Default')
		let curr_profile = undefined

		if (fields_dict.profile.value == 'Default') {

			this.set_default_profile(fields_dict)

		} else {

			curr_profile = r.find(el => el.name == fields_dict.profile.value)

			if (curr_profile) {

				fields_dict.is_public.value = curr_profile.is_public
				fields_dict.is_standard.value = curr_profile.is_standard
			} else {

				this.saved_query_profile = "Default"
				fields_dict.profile.value == 'Default'
				this.set_default_profile(fields_dict)
			}
		}

		this.query_profile_settings.refresh()
		this.toggle_dialog_readonly(fields_dict, this.query_profile_settings.custom_actions[0], curr_profile)

		this.setup_query_fields()
		this.display_structure()

		fields_dict.add_button.$wrapper[0].classList.add("d-flex", "justify-content-end", 'mt-4')
	}

	set_default_profile(fields_dict) {
		this.query_profile_settings.custom_actions[0].children[0].classList.add('hide')
		this.query_profile_settings.custom_actions[0].children[1].classList.add('hide')

		fields_dict.add_button.input_area.hidden = true
		fields_dict.is_public.wrapper.hidden = true
		fields_dict.is_standard.wrapper.hidden = true
		fields_dict.show_assigned_users.wrapper.hidden = true
		fields_dict.show_last_updated_on.wrapper.hidden = true
		fields_dict.show_creation.wrapper.hidden = true

		this.update_query_user_settings("Default")
	}

	async reset_table_display(profile) {
		await frappe.db.set_value('ioi Query Profile', profile, 'display', '')

		if (this.query_profile_settings) this.query_profile_settings.hide();
		if (this.ioi_expert_view_table) this.ioi_expert_view_table.destroy();

		this.render_ioi_expert_grid()
	}

	show_assigned_users(e, fields_dict) {
		const show_assign = e.target.checked

		if (show_assign && this.saved_query_profile != "Default") {
			let field_assign = { path: '_assign', field: '_assign', label: __('Assigned To') }

			if (!this.query_fields.some(obj => { return obj.path == "_assign" })) {
				this.query_fields.push(field_assign)
			}
		} else if (!show_assign && this.saved_query_profile != "Default") {

			this.query_fields = this.query_fields.filter(obj => obj.path != "_assign");
		}

		frappe.db.set_value('ioi Query Profile', fields_dict.profile.value, 'fields', this.query_fields)
	}

	show_last_updated_on(e, fields_dict) {
		const show_luo = e.target.checked

		if (show_luo && this.saved_query_profile != "Default") {
			let field_luo = { path: 'modified', field: 'modified', label: __('Last updated on') }

			if (!this.query_fields.some(obj => { return obj.path == "modified" })) {
				this.query_fields.push(field_luo)
			}
		} else if (!show_luo && this.saved_query_profile != "Default") {

			this.query_fields = this.query_fields.filter(obj => obj.path != "modified");
		}

		frappe.db.set_value('ioi Query Profile', fields_dict.profile.value, 'fields', this.query_fields)
	}

	show_creation(e, fields_dict) {
		const show_creation = e.target.checked

		if (show_creation && this.saved_query_profile != "Default") {
			let field_creation = { path: 'creation', field: 'creation', label: __('Creation date') }

			if (!this.query_fields.some(obj => { return obj.path == "creation" })) {
				this.query_fields.push(field_creation)
			}
		} else if (!show_creation && this.saved_query_profile != "Default") {

			this.query_fields = this.query_fields.filter(obj => obj.path != "creation");
		}

		frappe.db.set_value('ioi Query Profile', fields_dict.profile.value, 'fields', this.query_fields)
	}

	add_field(fields_dict, idx=null) {
		if (fields_dict.profile.value != "Default" && fields_dict.profile.value != null) {
			let me = this

			let path = ''
			let node = this.current_select

			if (node.field) {

				if (node.field.parent_node) {
					let parent = this.fieldnames[node.field.parent_node]
					let parents = [parent.name]

					while (parent.parent !== null) {
						parent = this.fieldnames[parent.parent]
						parents.unshift(parent.name)
					}

					for (let i = 0; i < parents.length; i++) {
						path += path != '' ? `.${parents[i]}` : parents[i]
					}
				}

				// Allow to choose site for q_stock
				if (node.field.fieldname === "q_stock") {

					frappe.call('silicon_ioi.utils.lib.system.get_visibles_sites_for_stock', {current_site: this.current_site}).then(r => {

						let d = new frappe.ui.Dialog({
							title: __('Select a site'),
							fields: [
								{
									label: __('Sites'),
									fieldname: 'q_stock_site',
									fieldtype: 'Select',
									options: r.message,
									default: this.current_site || ""
								},
							],
							size: 'small',
							primary_action_label: __('Select'),
							primary_action(values) {

								const site = values.q_stock_site
								const label = __('Site {0} stock qty', [site])
								const fieldname = `${site.toLowerCase()}_${node.field.fieldname}`

								path += path != '' ? `.${fieldname}` : fieldname

								if (idx) {
									me.query_fields.splice(idx, 0, {path: path, field: path, label: label})
								} else {
									me.query_fields.push({path: path, field: path, label: label})
								}

								me.setup_query_fields()
								me.is_fields_changed = true

								frappe.db.set_value('ioi Query Profile', fields_dict.profile.value, 'fields', me.query_fields)

								d.hide();
							}
						});

						d.show();
					})

				} else {

					path += path != '' ? `.${node.field.fieldname}` : node.field.fieldname

					if (idx) {
						this.query_fields.splice(idx, 0, {path: path, field: path.replace('.', '___'), label: this.current_select.label})
					} else {
						this.query_fields.push({path: path, field: path.replace('.', '___'), label: this.current_select.label})
					}

					this.setup_query_fields()
					this.is_fields_changed = true

					frappe.db.set_value('ioi Query Profile', fields_dict.profile.value, 'fields', this.query_fields)
				}

			} else {

				frappe.show_alert({
					message: __('Select a field first'),
					indicator: 'red'
				}, 5);

				return;
			}
		}
	}

	display_structure() {
		createApp({
			render: () => h(QueryProfile, {
				doctype: this.doctype,
				docname: this.docname
			})
		}).mount(this.query_profile_settings.get_field("structure_html").wrapper);
	}

	remove_query_profile(profile) {
		this.query_profile_settings.fields_dict.profile.value = "Default"
		this.saved_query_profile = "Default"

		frappe.db.delete_doc('ioi Query Profile', profile).then(() => {
			this.query_profile_settings.hide();
			this.show_query_profile_settings()
			this.render_profile_selector()
		})
	}

	create_new_query_profile() {
		let me = this
		this.query_profile_settings.hide();

		this.new_query_profile_dialog = new frappe.ui.Dialog({
			title: __('Create a new profile'),
			fields: [
				{
					label: __('Profile name'),
					fieldname: 'new_profile_name',
					fieldtype: 'Data',
				},
				{
					label: __('Copy selected fields from current profile'),
					fieldname: 'copy_fields',
					fieldtype: 'Check',
					default: 1,
				},
			],
			size: 'small',
		});

		this.new_query_profile_dialog.set_primary_action(__("Create"), () => {
			let values = me.new_query_profile_dialog.get_values();
			let query_fields = []

			if (values.copy_fields == 1) {
				// Set fields from current profile
				query_fields = this.query_fields
			}

			frappe.db.insert({ doctype: 'ioi Query Profile', doc_type: me.doctype, user_name: frappe.session.user, profile_name: values.new_profile_name, fields: JSON.stringify(query_fields) }).then(() => {
				let profile_name = `${me.doctype} • ${frappe.session.user} • ${values.new_profile_name}`

				me.query_profile_settings.fields_dict.profile.value = profile_name
				me.saved_query_profile = profile_name

				me.new_query_profile_dialog.hide();
				me.show_query_profile_settings()
				me.on_query_profile_change()
			})
		})

		this.new_query_profile_dialog.show()
	}

	setup_remove_fields() {
		let fields_html = this.query_profile_settings.get_field("selected_fields");
		let remove_fields_buttons = fields_html.$wrapper[0].getElementsByClassName("remove-field");

		for (let idx = 0; idx < remove_fields_buttons.length; idx++) {
			remove_fields_buttons.item(idx).onclick = () =>
				this.remove_fields(remove_fields_buttons.item(idx));
		}
	}

	remove_fields(remove_fields_buttons) {
		let me = this;
		let attr = remove_fields_buttons.getAttribute("data-fieldname")

		for (let idx in me.query_fields) {
			let field = me.query_fields[idx];

			if (field.path == attr) {
				me.query_fields.splice(idx, 1);
				me.setup_query_fields()

				frappe.db.set_value('ioi Query Profile', me.query_profile_settings.fields_dict.profile.value, 'fields', me.query_fields)

				break;
			}
		}

		this.is_fields_changed = true
	}

	update_query_user_settings(profile) {
		return frappe.model.user_settings.save(this.doctype, "ioi Expert Profile", !!profile ? profile : "Default")
	}

	async get_query_fields() {
		if (this.saved_query_profile != "Default" && !!this.saved_query_profile) {
			await frappe.db.get_value('ioi Query Profile', { name: this.saved_query_profile }, ['fields', 'is_public', 'is_standard']).then(r => {

				if (this.query_profile_settings) {
					this.query_profile_settings.fields_dict.is_public.input.checked = r.message.is_public == 1 ? true : false

					const input_is_standard = this.query_profile_settings.fields_dict.is_standard.disp_area.querySelector('input')
					input_is_standard.classList = r.message.is_standard == 1 ? 'disabled-selected' : 'disabled-deselected'
				}

				if (r.message.fields == null) {
					this.query_fields = []
					if (this.query_profile_settings) {
						this.query_profile_settings.fields_dict.show_assigned_users.input.checked = false
						this.query_profile_settings.fields_dict.show_last_updated_on.input.checked = false
						this.query_profile_settings.fields_dict.show_creation.input.checked = false
					}
				} else {

					const parsed_fields = JSON.parse(r.message.fields)
					this.query_fields = parsed_fields

					if (this.query_profile_settings) {
						this.query_profile_settings.fields_dict.show_assigned_users.input.checked = parsed_fields.some(el => el.field == "_assign")
						this.query_profile_settings.fields_dict.show_last_updated_on.input.checked = parsed_fields.some(el => el.field == "modified")
						this.query_profile_settings.fields_dict.show_creation.input.checked = parsed_fields.some(el => el.field == "creation")
					}
				}
			})
		} else {
			let custom_default_dt = ["ioi Item", "ioi Manufacturer Catalog"]

			if (custom_default_dt.includes(this.doctype)) {
				this.set_custom_default_profile()
			} else {
				let default_fields = this.meta.fields.filter(field => field.in_list_view == 1)

				this.query_fields = await default_fields.map(field => {
					let path = field.fieldname
					return {path: path, field: field.fieldname, label: field.label}
				})
			}
		}

		let field_name = { path: 'name', field: 'name', label: 'ID' }

		if (!this.query_fields.some(obj => { return obj.path == "name" })) {
			this.query_fields.unshift(field_name)
		}
	}

	set_custom_default_profile() {
		if (this.doctype == "ioi Item") {
			// Customized default profile for ioi Item
			return this.query_fields = [
				{path: ('description'), field: ('description'), label: __('Description')},
				{path: (`${this.current_site}_q_stock`), field: (`${this.current_site}_q_stock`), label: __('Site {0} Stock qty', [this.current_site])},
				{path: ('unit_id'), field: ('unit_id'), label: __('Unit')},
				{path: ('mode'), field: ('mode'), label: __('Mode')},
				{path: ('lib_ioistatus'), field: ('lib_ioistatus'), label: __('Status')},
				{path: ('family_id'), field: ('family_id'), label: __('Family')},
			]
		} else if (this.doctype == "ioi Manufacturer Catalog") {
			// Customized default profile for ioi Manufacturer Catalog
			return this.query_fields = [
				{ path: ('description'), field: ('description'), label: __('Description') },
				{ path: ('unit_id'), field: ('unit_id'), label: __('Unit') },
				{ path: ('family_id'), field: ('family_id'), label: __('Family') },
				{ path: ('tags'), field: ('tags'), label: __('Tags') },
			]
		}
	}

	async create_query() {
		let user_settings = frappe.model.user_settings[this.doctype]["ioi Expert Profile"]

		if (!!user_settings && this.query_profile_list.some(el => el.name === user_settings)) {
			this.saved_query_profile = user_settings
		} else {
			this.saved_query_profile = "Default"
			this.update_query_user_settings("Default");
		}

		await this.get_query_fields()
	}

	load_status() {
		let me = this;

		this.status = [];

		frappe.call({
			method: 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status.ioi_module_status_get_list_for_listview',
			args: { "doctype": this.doctype },
			async: false,
			callback: function (r) {
				if (r.message.length > 0) {
					for (var i = 0; i < r.message.length; i++) {
						//			 dt.ioistatus, dt.background_color, dt.font_color, dt.description, dt.description_lg1, dt.description_lg2, dt.description_lg3, dt.description_lg4, description_language
						me.status[i] = [r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8], r.message[i][9], r.message[i][10]];
					}
				}
			}
		});

		this.user_status = [];

		frappe.call({
			method: 'silicon_ioi.ioi_configuration.doctype.ioi_module_user_status.ioi_module_user_status.ioi_module_user_status_get_list_for_listview',
			args: { "doctype": this.doctype },
			async: false,
			callback: function (r) {
				if (r.message.length > 0) {
					for (var i = 0; i < r.message.length; i++) {
						//			 dt.ioistatus, dt.background_color, dt.font_color, dt.description, dt.description_lg1, dt.description_lg2, dt.description_lg3, dt.description_lg4, description_language
						me.user_status[i] = [r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8], r.message[i][9], r.message[i][10]];
					}
				}
			}
		});

		this.clocking_status = [];

		if (this.doctype !== "ioi Period") {
			frappe.call({
				method: 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status.ioi_module_status_get_list_for_listview',
				args: { "doctype": "ioi Clocking Reg" },
				async: false,
				callback: function (r) {
					if (r.message.length > 0) {
						for (var i = 0; i < r.message.length; i++) {
							me.clocking_status[i] = [r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8], r.message[i][9], r.message[i][10]];
						}
					}
				}
			});
		}
	}

	save_table_display(old_profile, new_profile) {
		if (this.saved_query_profile != "Default") {
			if (old_profile == new_profile) {
				let columnLayout = this.ioi_expert_view_table.getColumnLayout();
				columnLayout = columnLayout.filter(col => col.field != "responsiveCollapse")

				let total_col_width = 0
				columnLayout.forEach(col => total_col_width += col.width)

				if (total_col_width < this.ioi_expert_view_table.element.clientWidth) {
					frappe.db.set_value('ioi Query Profile', this.saved_query_profile, 'display', columnLayout).then(() => frappe.show_alert({
						message: __('Table display has been saved'),
						indicator:'green'
					}, 5))
				} else {
					frappe.show_alert({
						message: __('The column size cannot exceed the size of the table'),
						indicator:'red'
					}, 5)
				}
			}
		}
	}

	generate_url(url, config, params, export_limit=undefined) {
		const doctype_src = this.doctype
		const fields = this.query_fields.map(field => field.path)
		let sorters = []
		let filters = []
		let path = "name"
		let sort = "asc"
		let page = 1
		let size = 20
		let site_id = (this.meta.fields.filter(field => field.fieldname == "site_id")).length ? this.current_site : ''
		let prefix_id = $('#global_document_prefix_drowpdown_el').val()

		if (this.ioi_expert_view_table && this.ioi_expert_view_table.initialized) {
			sorters = this.ioi_expert_view_table.getSorters()

			if (sorters.length) {
				path = sorters[0].column.getElement().querySelector('.tabulator-col-title')
				path = path.getAttribute('data-field-path')
				sort = sorters[0].dir === 'asc' ? 1 : 0
			}

			let table_filters = this.ioi_expert_view_table.getFilters(true);

			const is_global = (filter, or_filters=false) => {
				const is_global = this.global_filtering.some(
					glob_filter => glob_filter.field === filter.field && glob_filter.value === filter.value
				);

				if (is_global) {
					return {
						...filter,
						"global_filters": 1
					};
				} else if (or_filters) {
					return {
						...filter,
						"or_filters": 1
					};
				}

				return filter;
			};

			filters = table_filters.reduce((acc, filter) => {

				if (filter.field === "name" && filter.value.includes('/')) {
					filter.value = filter.value.replace(/\s*\/\s*/, ' • ').replace(/\s+/g, ' ').trim();
				}

				if (filter.value.includes('|')) {

					let sub_filters = filter.value.split('|').map(value => ({
						"field": filter.field,
						"type": filter.type,
						"value": value.trim(),
					}));

					sub_filters = sub_filters.map(sub_filter => is_global(sub_filter, true))

					acc.push(...sub_filters);
				} else {

					acc.push(is_global(filter));
				}

				return acc;
			}, []);

			page = this.ioi_expert_view_table.getPage()

			if (export_limit) {
				size = export_limit
				prefix_id = ""
			} else {
				size = this.ioi_expert_view_table.getPageSize()
			}
		}

		const order_by = [{
			name: path,
			desc: sort
		}]

		params = { doctype_src, fields, order_by, filters, site_id, prefix_id, size, page }
		return url + "?params=" + encodeURI(JSON.stringify(params));
	}

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////                      Table Settings                       /////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	format_cell_bg_colors(element, value, new_element_html) {
		let doctype = this.doctype;
		element.innerHTML = '';

		const color_prefix_doctypes = [
			'IOI SALES QUOTE', 'IOI SALES ORDER', 'IOI SALES DELIVERY', 'IOI SALES INVOICE', 'IOI PURCHASES PRICE REQUEST', 'IOI PURCHASES ORDER', 'IOI PURCHASES RECEIPT', 'IOI PURCHASES INVOICE',
			'IOI STOCK ENTRY', 'IOI STOCK OUTPUT', 'IOI STOCK TRANSFER', 'IOI STOCK QUALIFICATION', 'IOI STOCK INVENTORY', 'IOI DOSSIER', 'IOI PRODUCTION', 'IOI SUBSCRIPTION', 'IOI OPPORTUNITY'
		];

		const color_budget_doctypes = ['IOI ITEM', 'IOI MANUFACTURER CATALOG'];

		if (color_prefix_doctypes.includes(doctype.toUpperCase())) {
			if (value.indexOf('•') != -1) {
				value = value.substring(0, value.indexOf('•') - 1).trim();

				if (silicon_ioi.ioiCommon.tab_prefix_color.length) {
					const settings = silicon_ioi.ioiCommon.tab_prefix_color.find(el => el.prefix == value && el.doctype == doctype);

					if (settings?.color_list == 1) {
						element.style.padding = "0 12px";
						return element.innerHTML = `<div class="text-truncate" style="background-color: ${settings.bgcolor}!important; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${new_element_html}</div>`;
					}
				}
			}
		} else if (color_budget_doctypes.includes(doctype.toUpperCase())) {
			if (silicon_ioi.ioiCommon.tab_item_budget_color.length) {

				const budget_id = this.budget_colors?.find(el => el.name == value)?.budget_id;
				const settings = silicon_ioi.ioiCommon.tab_item_budget_color.find(el => el.name == budget_id);

				if (settings?.color_on_item == 1) {
					element.style.padding = "0 12px";
					return element.innerHTML = `<div class="text-truncate" style="background-color: ${settings.bgcolor}!important; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${new_element_html}</div>`;
				}
			}
		}

		if (element.innerHTML == '') return element.innerHTML = new_element_html;
	}

	async set_columns() {
		let me = this
		let columns = []

		let getPath = (cell, path) => {
			cell.getElement().setAttribute("data-field-path", path)
		}

		let columns_layout = []

		if (me.saved_query_profile != "Default") {
			await frappe.db.get_value('ioi Query Profile', me.saved_query_profile, 'display').then(r => {
				if (r.message.display != "" && r.message.display != null) {
					columns_layout = JSON.parse(r.message.display)
				}
			})
		}

		columns.push({ field: "responsiveCollapse", formatter:"responsiveCollapse", maxWidth: 45, minWidth: 45, hozAlign:"center", resizable:false, headerSort:false })

		if (columns_layout.length) {
			this.query_fields.map(field => {
				let item = columns_layout.find(col => col.field === field.field)
				let index = columns_layout.indexOf(item) + 1

				if (item) {
					columns.push({index: index, title: __(`${field.label}`), field: field.field, headerFilter:"input", headerFilterPlaceholder: __(`${field.label}`), width: item.width, titleFormatter: (cell) => getPath(cell, field.path)})
				} else {
					columns.push({index: columns_layout.length, title: __(`${field.label}`), field: field.field, headerFilter:"input", headerFilterPlaceholder: __(`${field.label}`), titleFormatter: (cell) => getPath(cell, field.path)})
				}
			})
		} else {
			this.query_fields.map(field => columns.push({ title: __(`${field.label}`), field: field.field, headerFilter:"input", headerFilterPlaceholder: __(`${field.label}`), titleFormatter: (cell) => getPath(cell, field.path)}))
		}

		columns.sort(function(a, b){ return a.index-b.index });
		columns.forEach(col => delete col.index)

		return columns
	}

	format_name_style(cell) {
		let value = cell ? cell.getValue() : null

		if (value != null && value != "") {
			let element = cell.getElement()
			let convert_dt = this.doctype.toLowerCase().replace(/\s+/g, '-')
			let url = `/app/${convert_dt}/${cell.getData().name}`

			const title_field = cell.getData().title_field

			element.style.fontWeight = 600
			element.classList.add("text-truncate")
			const new_element_html = `<a class="ioi_expert_name_link text-truncate" href="${url}">${title_field ? title_field : cell.getValue()}</a>`

			this.format_cell_bg_colors(element, value, new_element_html)
		}
	}

	get_status_from_dt(cell, row, status, end_path) {
		frappe.call({
			method: 'silicon_ioi.ioi_configuration.doctype' + end_path,
			args: { "doctype": this.doctype, "name": row.getData().name },
			async: false,
			callback: function (r) {
				if (r.message.length) {
					let dt_status = status.filter((el) => el[0] == r.message)
					cell.getElement().style.padding = "0 12px"

					if (dt_status.length) {
						cell.getElement().innerHTML = `<div class="text-truncate" style="background-color: ${dt_status[0][1]}; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${dt_status[0][8]}</div>`
					} else {
						cell.getElement().innerHTML = `<div class="text-truncate" style="background-color: #C3C2C4; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${__('Undefined')}</div>`
					}
				}
			}
		})
	}

	format_status = (row, field) => {
		let status_fields_list = ['ioistatus', 'lib_ioistatus']
		let cell = row.getCell(status_fields_list.find(el => el == field))

		if (cell != "") {
			let cell_value = cell.getValue()
			let status = cell_value != null ? this.status.filter((el) => el.includes(Number(cell_value))) : ""

			if (status != "") {
				let element = cell.getElement()
				element.style.padding = "0 12px"
				element.innerHTML = `<div class="text-truncate" style="background-color: ${status[0] ? status[0][1] : ""}; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${status[0] ? status[0][8] : ""}</div>`
			} else {
				let me = this
				this.get_status_from_dt(cell, row, me.status, ".ioi_module_status.ioi_module_status.ioi_module_status_get_status_value_from_doctype")
			}
		}
	}

	format_user_status(row, field) {
		let user_status_fields_list = ['ioiuserstatus', 'lib_ioiuserstatus']
		let cell = row.getCell(user_status_fields_list.find(el => el == field))

		if (cell != "") {
			let cell_value = cell.getValue()
			let user_status = cell_value != null ? this.user_status.filter((el) => el.includes(cell.getField() === "ioiuserstatus" ? Number(cell_value) : cell_value)) : ""

			if (user_status != "") {
				let element = cell.getElement()
				element.style.padding = "0 12px"
				element.innerHTML = `<div class="text-truncate" style="background-color: ${user_status[0] ? user_status[0][1] : ""}; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${user_status[0] ? user_status[0][8] : ""}</div>`
			}
		}
	}

	format_clocking_status = (row) => {
		let cell = row.getCell('clocking_status')

		if (cell != "") {
			let cell_value = cell.getValue()
			let clocking_status = cell_value != null ? this.clocking_status.filter((el) => el.includes(Number(cell_value))) : ""

			if (clocking_status != "") {
				cell.getElement().style.padding = "0 12px"
				cell.getElement().innerHTML = `<div class="text-truncate" style="background-color: ${clocking_status[0] ? clocking_status[0][1] : ""}; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${clocking_status[0] ? clocking_status[0][8] : ""}</div>`
			}
		}
	}

	format_clocking_status_for_period = (row, field) => {
		let cell = row.getCell(field)

		if (cell != "") {
			let cell_value = cell.getValue()
			let clocking_status = cell_value == 0 ? __('Pending') : cell_value == 1 ? __('Open') : cell_value == 2 ? __('Closed') : cell_value

			if (clocking_status != "") {
				cell.getElement().innerHTML = `<div class="text-truncate">${clocking_status}</div>`
			}
		}
	}

	format_enabled(row) {
		let cell = row.getCell('enabled')
		let value = cell.getValue()

		if (value == 1) {
			return cell.getElement().innerHTML =
				`<span class="indicator-pill blue filterable ellipsis">
						<span class="ellipsis"> Enabled</span>
					</span>`
		} else if (value == 0) {
			return cell.getElement().innerHTML =
				`<span class="indicator-pill grey filterable ellipsis">
						<span class="ellipsis"> Disabled</span>
					</span>`
		}
	}

	format_to_pill(text) {
		return `<span class="indicator-pill gray filterable ellipsis">
					<span class="ellipsis"> ${text}</span>
				</span>`
	}

	format_mode(row) {
		if (this.doctype == 'ioi Item') {
			let cell = row.getCell('mode')
			let value = cell.getValue()

			if (value != null && value != "") {
				let converted_value = value == 0 ? __('Regular') : value == 1 ? __('Free') : value == 2 ? __('Virtual') : value == 3 ? __('Batch') : value == 4 ? __('Serial') : ''
				cell.getElement().innerHTML = this.format_to_pill(converted_value)
			}
		}
	}

	format_scheduling_mode(row) {
		if (this.doctype == 'ioi Item') {
			let cell = row.getCell('scheduling_mode')
			let value = cell.getValue()

			if (value != null && value != "") {
				let converted_value = value == 0 ? __('Undefined') : value == 1 ? __('Purchased') : value == 2 ? __('Manufactured') : value == 3 ? __('Subcontracted') : ''
				cell.getElement().innerHTML = this.format_to_pill(converted_value)
			}
		}
	}

	format_assign_to(row) {
		let cell = row.getCell('_assign')
		let value = cell.getValue()
		let array = value ? JSON.parse(cell.getValue()) : [];

		if (array.length) {
			cell.getElement().innerHTML = `<div class="list-assignments d-flex align-items-center">
				${frappe.avatar_group(JSON.parse(value), 3, { filterable: true })[0].outerHTML}
			</div>`;
		}
	}

	format_cashdesk_payment_status(row) {
		let cell = row.getCell('cashdesk_payment_status_id')
		let value = cell.getValue()
		let bgcolor = ""
		let description = ""

		if ((!value) || ((value) && ((value == '') || (value == 0)))) {
			description = __("Unpaid");
			bgcolor = '#D9D3D2';
		} else if (value == 1) {
			description = __("Partial");
			bgcolor = '#FFC869';
		} else {
			description = __("Total")
			bgcolor = '#7ECC6E';
		}

		cell.getElement().style.padding = "0 12px"
		cell.getElement().innerHTML = `<div class="text-truncate" style="background-color: ${bgcolor}; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">${description}</div>`
	}

	format_trigger_eval(row) {
		let trigger_eval_cell = row.getCell('trigger_eval')
		let trigger_eval_last_calc_cell = row.getCell('trigger_eval_last_calc')
	
		let trigger_eval_value = trigger_eval_cell && trigger_eval_cell.getValue()
		let trigger_eval_last_calc_value = trigger_eval_last_calc_cell && trigger_eval_last_calc_cell.getValue()
	
		let eval_element = trigger_eval_cell && trigger_eval_cell.getElement()
		let last_calc_element = trigger_eval_last_calc_cell && trigger_eval_last_calc_cell.getElement()
	
		let bg_color = ""
	
		if (trigger_eval_cell && !isNaN(trigger_eval_value) && trigger_eval_value !== null) {
			let num = parseInt(trigger_eval_value, 10)
	
			if (num < 0) {
				bg_color = num >= -6 ? "#ffcf4d" : "#fc6a65"
			} else {
				bg_color = "#64de84"
			}

			eval_element.style.padding = "0 12px"
			eval_element.innerHTML = `
				<div class="text-truncate" style="background-color: ${bg_color}; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">
					${trigger_eval_value}
				</div>`
		}
	
		if (trigger_eval_last_calc_cell && !!trigger_eval_last_calc_value) {
			let date_parts = trigger_eval_last_calc_value
			bg_color = ""

			if (date_parts && date_parts != "") {
				let last_calc_moment = moment(date_parts)
				let now = moment()
				let diff_hours = Math.abs(now.diff(last_calc_moment, 'hours'))
	
				if (diff_hours >= 48) {
					bg_color = "#fc6a65"
				} else if (diff_hours >= 24) {
					bg_color = "#ffcf4d"
				}
			}

			last_calc_element.style.padding = "0 12px"
			last_calc_element.innerHTML = `
				<div class="text-truncate" style="background-color: ${bg_color}; color: black; border-radius: 6px; padding: 4px; margin: 8.75px 0 8.75px 0;">
					${remove_seconds_from_date(trigger_eval_last_calc_value)}
				</div>`
		}
	}

	remove_seconds_from_date(value) {
		if (value) return value.split(".")[0]
		return value
	}

	format_pagination_counter(pageSize, currentRow, currentPage, totalRows, totalPages) {
		let currentRowMax = pageSize * currentPage
		if (currentRowMax > totalRows) currentRowMax = totalRows
		if (totalRows == 0) currentRow = 0

		return __("{0} - {1} of {2}",[currentRow,currentRowMax,totalRows]);
	}

	render_placeholder(filters) {
		return `
			<div class="no-result text-muted flex justify-center align-center" style="">
				<div class="no-result text-muted flex justify-center align-center">
					<div class="msg-box no-border flex flex-column justify-center align-center">
					${filters ?
						`<div>
							<img src="/assets/frappe/images/ui-states/list-empty-state.svg" alt="Generic Empty State" class="null-state">
						</div>
						<p style="font-weight: 400;">${__(`No "{0}" found with matching filters. Clear filters to see all "{0}".`, [this.doctype])}</p>`
						:
						`<div>
							<img src="/assets/frappe/images/ui-states/list-empty-state.svg" alt="Generic Empty State" class="null-state">
						</div>
						<p style="font-weight: 400;">${__(`You haven't created a "{0}" yet`), [this.doctype]}</p>`
					}
					</div>
				</div>
			</div>`
	}

	async render_ioi_expert_grid() {
		let me = this
		const persistence_id = `ioi_expert_view_${this.formatted_dt_name}`

		this.ioi_expert_view_table = new ioi.Tabulator($('#ioi_expert_view')[0], {
			paginationMode: "remote",
			filterMode: "remote",
			sortMode: "remote",
			headerFilterLiveFilterDelay: 500,
			initialSort:[{ column: "name", dir: "desc" }],
			minHeight: "calc(100vh - 165px)",
			maxHeight: "calc(100vh - 165px)",
			responsiveLayout: 'collapse',
			responsiveLayoutCollapseStartOpen: false,
			wrapLines: false,
			pagination: true,
			paginationSize: 20,
			paginationSizeSelector: [20, 100, 500],
			paginationCounter: (pageSize, currentRow, currentPage, totalRows, totalPages) => this.format_pagination_counter(pageSize, currentRow, currentPage, totalRows, totalPages),
			paginationButtonCount: 0,
			persistence: {
				headerFilter: true, //persist header filters
				sort: true, //persist column sorting
			},
			persistenceID: persistence_id,
			ajaxURL: "/api/method/silicon_ioi.utils.lib.system.get_custom_query_list",
			ajaxURLGenerator: (url, config, params) => this.generate_url(url, config, params),
			ajaxResponse: function (url, params, response) {
				return {
					last_page: response.message.last_page,
					last_row: response.message.last_row,
					data: response.message.data
				};
			},
			placeholder: () => this.render_placeholder(this.ioi_expert_view_table.initialized ? this.ioi_expert_view_table.getHeaderFilters().length : false),
			layout: "fitColumns",
			rowHeight: 45,
			movableColumns: true,
			resizableColumns: true,
			selectableRows: 1,
			columns: await this.set_columns(),
			columnDefaults: {
				minWidth: 100,
			},
			resizableColumnFit: true,
			rowFormatter:function(row){
				let data = row.getData()

				if ('name' in data) {
					me.format_name_style(row.getCell("name"))
				}
				if ('enabled' in data) {
					me.format_enabled(row)
				}
				if ('ioistatus' in data) {
					me.format_status(row, 'ioistatus')
				}
				if ('lib_ioistatus' in data) {
					me.format_status(row, 'lib_ioistatus')
				}
				if ('ioiuserstatus' in data) {
					me.format_user_status(row, 'ioiuserstatus')
				}
				if ('lib_ioiuserstatus' in data) {
					me.format_user_status(row, 'lib_ioiuserstatus')
				}
				if ('mode' in data) {
					me.format_mode(row)
				}
				if ('scheduling_mode' in data) {
					me.format_scheduling_mode(row)
				}
				if (me.doctype !== "ioi Period" && 'clocking_status' in data) {
					me.format_clocking_status(row)
				}
				if (me.doctype === "ioi Period") {
					const status_fields = ['clocking_status', 'acc_finances_status', 'acc_miscellaneous_status', 'acc_purchases_status', 'purchases_status', 'acc_sales_status', 'sales_status', 'wms_status'];
					for (let field of status_fields) if (field in data) me.format_clocking_status_for_period(row, field);
				}
				if ('_assign' in data) {
					me.format_assign_to(row)
				}
				if ('modified' in data) {
					row.getCell('modified').getElement().innerHTML = me.remove_seconds_from_date(data.modified)
				}
				if ('creation' in data) {
					row.getCell('creation').getElement().innerHTML = me.remove_seconds_from_date(data.creation)
				}
				if ('cashdesk_payment_status_id' in data) {
					me.format_cashdesk_payment_status(row)
				}
				if ('trigger_eval' in data || 'trigger_eval_last_calc' in data) {
					me.format_trigger_eval(row)
				}
			},
		})

		this.ioi_expert_view_table.on('tableBuilt', () => {
			me.page.wrapper.find('.tabulator-paginator .tabulator-page-size').addClass('btn pb-1 mr-3 border-0')
			me.page.wrapper.find('.tabulator-paginator label').remove()

			me.page.wrapper.find('.tabulator-col .tabulator-header-filter input').attr('title', __("Formulate your search using '>, >=, <=, <' signs and use '|' to indicate 'OR' relations"))

			// Set global filter from localStorage
			const storage_global_filter = localStorage.getItem(`tabulator-ioi_expert_view_${this.formatted_dt_name}-globalFilter`)
			const parsed_storage_global_filter = !!storage_global_filter ? JSON.parse(storage_global_filter) : []
			const global_filter_value = parsed_storage_global_filter.length ? parsed_storage_global_filter[0].value : ""

			if (!!global_filter_value) me.page.wrapper.find("#expert_view_global_filters_input").val(global_filter_value).trigger('input');

			// Set save filter from localStorage
			const storage_save_filter = localStorage.getItem(`tabulator-ioi_expert_view_${this.formatted_dt_name}-saveFilter`);
			const save_filter_value = !!storage_save_filter ? storage_save_filter : ""

			if (!!save_filter_value) me.page.wrapper.find(`.list-link[data-filter-name=${save_filter_value}] a.filter-name`).trigger('click');

		})

		this.ioi_expert_view_table.on("rowClick", (e, row) => {

			if (!me.ioi_expert_view_table.getSelectedData().length) {
				row.select()
			}

			const link_target = e.target.classList.contains('ioi_expert_name_link')

			const pdf_content = $(`#${me.formatted_dt_name}_list_pdf_content`)[0]
			const highlight_content = $(`#${me.formatted_dt_name}_list_highlight_info_content`)[0]
			const todo_content = $(`#${me.formatted_dt_name}_list_todo_sv_content`)[0]
			const related_content = $(`#${me.formatted_dt_name}_list_related_content`)[0]

			if (!link_target) {
				const name = row.getData().name

				if (highlight_content) {
					return me.get_highlight_list('', name)
				} else if (pdf_content) {
					return me.build_pdf_content(name)
				} else if (todo_content) {
					return me.todo_sv_grid(name, [])
				} else if (related_content) {
					return me.related_data_grid(name)
				}
			}
		});

		this.ioi_expert_view_table.on("dataLoaded", (data) => {
			if (me.doctype == 'ioi Item' || me.doctype == 'ioi Manufacturer Catalog') {

				let method = 
					me.doctype === 'ioi Item' ? 
					'silicon_ioi.ioi_items.doctype.ioi_item.ioi_item.ioi_item_get_item_budget_for_items' :
					me.doctype === 'ioi Manufacturer Catalog' ?
					'silicon_ioi.ioi_items.doctype.ioi_manufacturer_catalog.ioi_manufacturer_catalog.ioi_manufacturer_catalog_get_item_budget_for_manufacturer_refs' :
					'';

				if (!!method) {
					frappe.call({
						method: method,
						args: { "items_list": data },
						async: false,
						callback: function (r) {
							me.budget_colors = r.message;
						}
					});
				}
			}
		});

		const element = $(`#ioi_expert_view`);
		let timeoutId;

		const prev_next_row_event = (e) => {
			if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
				clearTimeout(timeoutId);

				const table = me.ioi_expert_view_table;
				const selected_row = table.getSelectedRows()?.[0];
				const table_length = table.getDataCount("active");

				if (selected_row && table_length > 0) {
					let target_row = null;

					if (e.key === 'ArrowUp') {
						target_row = selected_row.getPrevRow();
					} else if (e.key === 'ArrowDown') {
						target_row = selected_row.getNextRow();
					}

					if (target_row) {
						const position = target_row.getPosition();
						if ((e.key === 'ArrowUp' && position >= 0) || (e.key === 'ArrowDown' && position < table_length + 1)) {
							e.preventDefault();
							this.clear_side_view_content();

							timeoutId = setTimeout(() => {
								if (target_row.getElement()) {
									target_row.getElement().click();
								}
							}, 200);
						}
					}
				}
			}
		};

		element.off('keydown');
		element.on('keydown', prev_next_row_event);
	}

	clear_side_view_content() {
		let pdf_content = $(`#${this.formatted_dt_name}_list_pdf_content`)
		let highlight_content = $(`#${this.formatted_dt_name}_list_highlight_info_content`)
		let todo_content = $(`#${this.formatted_dt_name}_list_todo_sv_content`)
		let related_content = $(`#${this.formatted_dt_name}_list_related_content`)

		if (pdf_content) pdf_content.html('')
		if (highlight_content) highlight_content.html('')
		if (todo_content) todo_content.html('')
		if (related_content) related_content.html('')
	}

	/////////////////////////
	/////// Side View ///////
	/////////////////////////

	init_list_side_view() {

		this.add_menu_side_bar()

		frappe.call('silicon_ioi.utils.lib.system.get_doctype_preview', { doctype: this.doctype, side_view_origin: 'LIST' }).then(r => {
			const res = r.message

			if (res && res.side_view_type) {
				if (res.side_view_type === "LIST_PDF") this.show_pdf_side_view(res);
				if (res.side_view_type === "LIST_TODO") this.show_todo_side_view(res);
				if (res.side_view_type === "LIST_HIGHLIGHT") this.show_highlight_side_view(res);
				if (res.side_view_type === "LIST_RELATED") this.show_related_data_side_view(res);
			}
		})
	}

	add_menu_side_bar() {
		let me = this;

		const buttons = [
			{ label: __("PDF Side View"), type: "LIST_PDF", action: me.show_pdf_side_view },
			{ label: __("Todo Side View"), type: "LIST_TODO", action: me.show_todo_side_view },
			{ label: __("Highlight Info Side View"), type: "LIST_HIGHLIGHT", action: me.show_highlight_side_view },
			{ label: __("Related Data Side View"), type: "LIST_RELATED", action: me.show_related_data_side_view },
		];

		const icon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-layout-sidebar-inset-reverse" viewBox="0 0 16 16">
							<path d="M2 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm12-1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z"/>
							<path d="M13 4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1z"/>
						</svg>`;

		buttons.forEach(({ label, type, action, condition }) => {
			if (condition ? condition : true) {
				me.page.add_inner_button(label, function() {
					frappe.call('silicon_ioi.utils.lib.system.get_doctype_preview', { doctype: me.doctype, side_view_origin: 'LIST' }).then(res => {
						frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 1, doctype: me.doctype, type: type, is_new: res.message ? 0 : 1, side_view_origin: 'LIST' }).then(r => action.call(me, r.message));
					});
				}, icon);
			}
		})
	}

	clear_ioi_list_side_view() {
		let side_view = $(`[data-page-route="List/${this.doctype}/Expert"]`).find(`#${this.formatted_dt_name}_list_side_view`)
		let side_view_resizer = $(`[data-page-route="List/${this.doctype}/Expert"]`).find(`#${this.formatted_dt_name}_list_side_view_resizer`)

		if (side_view) side_view.remove()
		if (side_view_resizer) side_view_resizer.remove()
	}

	check_side_view_res(saved_width) {
		if (saved_width > (window.innerWidth/2.25)) {
			saved_width = 0
			frappe.call('silicon_ioi.utils.lib.system.save_pdf_viewer_width', { doctype: this.doctype, width: 0 });
		}

		return saved_width
	}

	create_resizer(doctype) {
		const file_preview = $(`#${this.formatted_dt_name}_list_side_view`)[0];
		const resizer = $(`#${this.formatted_dt_name}_list_side_view_resizer`)[0];

		if (file_preview) {
			let x = 0;
			let file_preview_width = 0;
			let original_width = 0;
			let timeoutId = null;

			const cursor_style_display = (toggle) => {
				const prevSibling = resizer.previousElementSibling;
				const nextSibling = resizer.nextElementSibling;

				if (toggle === "add") {
					document.body.style.cursor = "ew-resize";
					prevSibling.style.userSelect = 'none';
					prevSibling.style.pointerEvents = 'none';
					nextSibling.style.userSelect = 'none';
					nextSibling.style.pointerEvents = 'none';
				} else {
					document.body.style.removeProperty('cursor');
					prevSibling.style.removeProperty('user-select');
					prevSibling.style.removeProperty('pointer-events');
					nextSibling.style.removeProperty('user-select');
					nextSibling.style.removeProperty('pointer-events');
				}
			}

			const mouse_down_handler = function(e) {
				x = e.clientX;
				const rect = file_preview.getBoundingClientRect();
				file_preview_width = rect.width;
				original_width = file_preview_width;

				cursor_style_display("add")

				document.addEventListener('mousemove', mouse_move_handler);
				document.addEventListener('mouseup', mouse_up_handler);

				clearTimeout(timeoutId);
			};

			const mouse_move_handler = function(e) {
				const dx = e.clientX - x;
				const new_width = file_preview_width - dx;

				if (new_width > 0 && new_width < (window.innerWidth/2.25) ) {
					file_preview.style.width = new_width + 'px';
				}

				clearTimeout(timeoutId);
			};

			const mouse_up_handler = function() {
				cursor_style_display("remove")

				document.removeEventListener('mousemove', mouse_move_handler);
				document.removeEventListener('mouseup', mouse_up_handler);

				clearTimeout(timeoutId);

				timeoutId = setTimeout(() => {
					if (Math.round(parseFloat(file_preview.style.width)) !== original_width) {
						frappe.call('silicon_ioi.utils.lib.system.save_pdf_viewer_width', { doctype: doctype, width: Math.round(parseFloat(file_preview.style.width)) });
					}
				}, 2000);
			};

			resizer.addEventListener('mousedown', mouse_down_handler);
		}
	}

	/////////////////////////////
	/////// PDF Side View ///////
	/////////////////////////////

	async show_pdf_side_view(curr_doctype_infos) {
		if (frappe.get_route()[0] == 'List') {
			if (curr_doctype_infos && curr_doctype_infos.enabled == 1) {

				const saved_width = this.check_side_view_res(curr_doctype_infos.preview_width)

				const buttons = `
					<div id="${this.formatted_dt_name}_list_side_view_buttons" class="d-flex flex-row justify-content-between" style="margin-bottom: 8px;">
						<div class="d-flex w-100">
							<div class="frappe-control input-max-width w-75" data-fieldtype="Select">
								<div class="form-group">
									<div class="control-input-wrapper">
										<div class="control-input flex align-center">
											<select id="${this.formatted_dt_name}_list_side_view_select" type="text" autocomplete="off" class="input-with-feedback form-control ellipsis"></select>
											<div class="select-icon ">
												<svg class="icon  icon-sm" style="">
													<use class="" href="#icon-select"></use>
												</svg>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<span class="page-icon-group">
							<button id="${this.formatted_dt_name}_list_side_view_previous" class="text-muted btn btn-default icon-btn" title="${__('Previous')}">
								<svg class="icon  icon-sm" style="">
									<use class="" href="#icon-left"></use>
								</svg>
							</button>
							<button id="${this.formatted_dt_name}_list_side_view_next" class="text-muted btn btn-default icon-btn mx-2" title="${__('Next')}">
								<svg class="icon  icon-sm" style="">
									<use class="" href="#icon-right"></use>
								</svg>
							</button>
						</span>
					</div>`

				const container = `
					<div id="${this.formatted_dt_name}_list_side_view" class="p-4 ml-3 rounded position-relative" style="width: ${saved_width && saved_width != 0 ? `${saved_width}px` : '30%'}; height: calc(-165px + 100vh)">
						<button id="${this.formatted_dt_name}_list_side_view_close" class="btn btn-xs position-absolute shadow-none" style="top: 0px; right: 0px;">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
								<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
							</svg>
						</button>
						<label class="control-label" style="padding-right: 0px; font-size: var(--text-md);">${__('Document PDF files')}</label>
						<span id="${this.formatted_dt_name}_list_side_view_name" style="padding-right: 0px; font-size: var(--text-md); font-weight: 600;"></span>
						<div id="${this.formatted_dt_name}_list_pdf_hidden_content" style="display: none;">
							${buttons}
						</div>
						<div id="${this.formatted_dt_name}_list_pdf_content" style="height: calc(100vh - 350px);">
							<div id="${this.formatted_dt_name}_list_pdf_empty" class="align-items-center justify-content-center mt-5" style="display: flex;">${__('Please select a row from the list to view linked PDFs')}</div>
						</div>
					</div>`;

				const resizer_html = `<div id="${this.formatted_dt_name}_list_side_view_resizer" class="resizer rounded" style="height: calc(-165px + 100vh - 50px);"></div>`;
				const main_layout = $(`[data-page-route="List/${this.doctype}/Expert"]`).find(".row.layout-main");

				this.clear_ioi_list_side_view()

				main_layout.append(resizer_html).append(container)
				this.create_resizer(this.doctype);

				// On PDF change in viewer
				const toggle_attachment = (update) => {
					let options = $(`#${this.formatted_dt_name}_list_side_view_select`)[0].options

					if (update === "increase" && options[options.selectedIndex + 1]) {
						options.selectedIndex = options.selectedIndex + 1
						document.querySelector(`#${this.formatted_dt_name}_list_side_view object`).data = options[options.selectedIndex].value
					} else if (update === "decrease" && options[options.selectedIndex - 1]) {
						options.selectedIndex = options.selectedIndex - 1
						document.querySelector(`#${this.formatted_dt_name}_list_side_view object`).data = options[options.selectedIndex].value
					}
				}

				$(`#${this.formatted_dt_name}_list_side_view_select`)[0].onchange = (e) => document.querySelector(`#${this.formatted_dt_name}_list_side_view object`).data = e.target.value
				$(`#${this.formatted_dt_name}_list_side_view_previous`)[0].onclick = () => toggle_attachment("decrease");
				$(`#${this.formatted_dt_name}_list_side_view_next`)[0].onclick = () => toggle_attachment("increase")
				$(`#${this.formatted_dt_name}_list_side_view_close`)[0].onclick = () => {
					this.clear_ioi_list_side_view()
					frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 0, doctype: this.doctype, type: curr_doctype_infos.side_view_type, is_new: 0, side_view_origin: 'LIST' })
				}
			} else if (curr_doctype_infos && curr_doctype_infos.enabled == 0) {
				this.clear_ioi_list_side_view()
			}
		} else {
			this.clear_ioi_list_side_view()
		}
	}

	async build_pdf_content(name) {
		$(`#${this.formatted_dt_name}_list_pdf_empty`).hide()
		$(`#${this.formatted_dt_name}_list_pdf_hidden_content`).show()

		$(`#${this.formatted_dt_name}_list_side_view_name`).text(name ? ` : ${name}` : '')

		const files = !!name ? await frappe.call('silicon_ioi.utils.lib.system.get_pdf_files_side_view', { doctype: this.doctype, name: name }).then(r => r.message) : [];

		const select_element = $(`#${this.formatted_dt_name}_list_side_view_select`);
		select_element.html('');

		const pdf_content = $(`#${this.formatted_dt_name}_list_pdf_content`);
		let pdf_file_content = '';
	
		if (files.length) {
			pdf_file_content = `<object id="${this.formatted_dt_name}_list_side_view_file_prev" width="100%" height="100%" data="${files[0].file_url}" type="application/pdf" style="background:#323639; border-radius: var(--border-radius-md); border: 1px solid var(--border-color);"></object>`;

			files.forEach(file => {
				select_element.append($('<option>', {
					value: file.file_url,
					text: file.file_name
				}));
			});
		} else {
			pdf_file_content = `<div class="w-100 d-flex justify-content-center mt-4">${__('No PDF documents to display')}</div>`;
		}
	
		pdf_content.html(pdf_file_content);
	}

	/////////////////////////////
	/////// ToDo Side View //////
	/////////////////////////////

	show_todo_side_view(curr_doctype_infos) {
		if (frappe.get_route()[0] == 'List') {
			if (curr_doctype_infos && curr_doctype_infos.enabled == 1) {
				const saved_width = this.check_side_view_res(curr_doctype_infos.preview_width)

				const html = `
					<div id="${this.formatted_dt_name}_list_side_view" class="p-4 ml-3 rounded" style="width: ${saved_width && saved_width != 0 ? `${saved_width}px` : '30%'}; height: calc(-165px + 100vh)">
						<label class="control-label" style="padding-right: 0px; font-size: var(--text-md);">${__('ToDo')}</label>
						<span id="${this.formatted_dt_name}_list_side_view_name" style="padding-right: 0px; font-size: var(--text-md); font-weight: 600;"></span>
					</div>`;
				const resizer_html = `<div id="${this.formatted_dt_name}_list_side_view_resizer" class="resizer rounded" style="height: calc(-165px + 100vh - 50px);"></div>`;
				const main_layout = $(`[data-page-route="List/${this.doctype}/Expert"]`).find(".row.layout-main");

				this.clear_ioi_list_side_view()
				main_layout.append(resizer_html).append(html)

				this.todo_sv_build_page()
				this.create_resizer(this.doctype);

			} else if (curr_doctype_infos && curr_doctype_infos.enabled == 0) {
				this.clear_ioi_list_side_view()
			}
		} else {
			this.clear_ioi_list_side_view()
		}
	}

	async todo_sv_build_page() {

		this.event_categories = await frappe.call('silicon_ioi.utils.lib.system.get_categories', { doctype: this.doctype }).then(r => [''].concat(r.message))
		this.allocated_users = await frappe.call('silicon_ioi.utils.lib.system.get_allocated_users').then(r => [''].concat(r.message))

		const search_input = `	<div class="m-1" style="width: 150px;">
									<label style="font-size: var(--text-md);">${__('Search')}</label>
									<div class="control-input" >
										<input id="${this.formatted_dt_name}_list_todo_sv_search" type="text" class="input-with-feedback form-control bold">
									</div>
								</div>`

		const from_button = `<div class="m-1">
								<label style="font-size: var(--text-md);">${__('From')}</label>
								<div class="control-input">
									<input id="${this.formatted_dt_name}_list_todo_sv_dt_from" type="date" class="input-with-feedback form-control bold">
								</div>
							</div>`

		const to_button = `	<div class="m-1">
								<label style="font-size: var(--text-md);">${__('To')}</label>
								<div class="control-input">
									<input id="${this.formatted_dt_name}_list_todo_sv_dt_to" type="date" class="input-with-feedback form-control bold" pattern="\d{4}-\d{2}-\d{2}">
								</div>
							</div>`

		const status_button = `	<div class="m-1">
									<label style="font-size: var(--text-md);">${__('Status')}</label>
									<div class="control-input">
										<select id="${this.formatted_dt_name}_list_todo_sv_status" class="input-with-feedback form-control bold">
											<option value="" selected></option>
											<option value="${__('Open')}">${__('Open')}</option>
											<option value="${__('Close')}">${__('Close')}</option>
											<option value="${__('Cancelled')}">${__('Cancelled')}</option>
										</select>
									</div>
								</div>`

		const only_open = `<div class="form-check form-check-inline">
								<input class="form-check-input" type="checkbox" id="${this.formatted_dt_name}_list_todo_sv_only_open" checked>
								<label class="form-check-label" style="font-size: var(--text-md);" for="${this.formatted_dt_name}_list_todo_sv_only_open">${__('Only open')}</label>
							</div>`

		const only_mine = `<div class="form-check form-check-inline">
								<input class="form-check-input" type="checkbox" id="${this.formatted_dt_name}_list_todo_sv_only_mine" checked>
								<label class="form-check-label" style="font-size: var(--text-md);" for="${this.formatted_dt_name}_list_todo_sv_only_mine">${__('Only mine')}</label>
							</div>`

		const documents = () => {
			let html = ''

			if ((this.doctype.toUpperCase() == 'IOI CUSTOMER') || (this.doctype.toUpperCase() == 'IOI SUPPLIER')) {
				html += `<div class="m-1">
					<label id="${this.formatted_dt_name}_list_todo_sv_document_label" style="font-size: var(--text-md);">${__("Documents")}</label>
					<div class="control-input d-flex flex-row flex-wrap p-2 rounded" style="min-height: 33.5px; background-color: var(--control-bg);">`;

				if (this.doctype.toUpperCase() == 'IOI CUSTOMER') {
					const sales = ['ioi Sales Quote', 'ioi Sales Order', 'ioi Sales Delivery', 'ioi Sales Invoice']

					sales.map((item, i) => html += `<div class="form-check form-check-inline">
														<input type="hidden" id="${this.formatted_dt_name}_list_todo_sv_nb_document" value="4">
														<input class="form-check-input" value="${item}" type="checkbox" id="${this.formatted_dt_name}_list_todo_sv_document_${i}" checked>
														<label class="form-check-label" style="font-size: var(--text-md);" for="${this.formatted_dt_name}_list_todo_sv_document_${i}">${__(item.replace('ioi', '').trim())}</label>
													</div>`)

				} else if (this.doctype.toUpperCase() == 'IOI SUPPLIER') {
					const purchases = ['ioi Purchases Price Request', 'ioi Purchases Order', 'ioi Purchases Receipt', 'ioi Purchases Invoice']

					purchases.map((item, i) => html += `<div class="form-check form-check-inline">
														<input type="hidden" id="${this.formatted_dt_name}_list_todo_sv_nb_document" value="4">
														<input class="form-check-input" value="${item}" type="checkbox" id="${this.formatted_dt_name}_list_todo_sv_document_${i}" checked>
														<label class="form-check-label" style="font-size: var(--text-md);" for="${this.formatted_dt_name}_list_todo_sv_document_${i}">${__(item.replace('ioi', '').trim())}</label>
													</div>`)
				}
				html += `</div>
					</div>`;
			}
			return html
		}

		const ioi_category_button = `<div class="m-1">
										<label id="${this.formatted_dt_name}_list_todo_sv_ioi_category_label" style="font-size: var(--text-md);">${__("ioi Category")}</label>
										<div class="control-input" style="">
											<select id="${this.formatted_dt_name}_list_todo_sv_ioi_category" class="input-with-feedback form-control bold">
												${ this.event_categories.map(el => `<option value="${el}">${el}</option>`) }
											</select>
										</div>
									</div>`

		const topn_button = `<div class="m-1">
								<label id="${this.formatted_dt_name}_list_todo_sv_topn_label" style="font-size: var(--text-md);">${__("No records")}</label>
								<div class="control-input" style="">
									<input id="${this.formatted_dt_name}_list_todo_sv_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">
								</div>
							</div>`

		const search_button = `<div class="m-1">
									<button id="${this.formatted_dt_name}_list_todo_sv_bt_search" title="${__("Refresh")}" data-label="${__("Search")}" class="btn btn-default btn-xs ellipsis" style="" onclick="">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
											<path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
											<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
										</svg>
									</button>
								</div>`

		const clear_button = `<div class="m-1">
								<button id="${this.formatted_dt_name}_list_todo_sv_bt_clear" title="${__("Clear")}" data-label="${__("Search")}" class="btn btn-default btn-xs ellipsis" onclick="">${__("...")}</button>
							</div>`

		const new_button = `<div id="${this.formatted_dt_name}_list_todo_sv_bt_new" class="m-1">
								<button title="${__("Add")}" data-label="Add todo" class="btn btn-default btn-xs ellipsis">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
										<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
									</svg>
								</button>
							</div>`

		const duplicate_button = `<div id="${this.formatted_dt_name}_list_todo_sv_bt_duplicate" class="m-1">
									<button title="${__("Duplicate ToDo")}" data-label="Duplicate todo" class="btn btn-default btn-xs ellipsis">
										<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
											<path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
										</svg>
									</button>
								</div>`

		const delete_button = `	<div id="${this.formatted_dt_name}_list_todo_sv_bt_delete" class="m-1">
									<button title="${__("Delete")}" data-label="Delete" class="btn btn-default btn-xs ellipsis">
										<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
											<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
											<path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
										</svg>
									</button>
								</div>`;

		const hide_button = `<button id="${this.formatted_dt_name}_list_side_view_close" class="btn btn-xs position-absolute shadow-none" style="top: -45px; right: -21px;">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
									<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
								</svg>
							</button>`

		const control_panel = `
			<div id="${this.formatted_dt_name}_list_todo_sv_content" class="d-flex flex-column w-100 position-relative" style="max-height: 100%;">
				${hide_button}
				<div id="${this.formatted_dt_name}_list_todo_sv_hidden_content" style="display: none;">
					<div class="d-flex flex-row flex-wrap align-items-start justify-content-start">
						${search_input}
						${from_button}
						${to_button}
						${status_button}
						${ioi_category_button}
						${topn_button}
						${documents()}
					</div>
					<div class="d-flex flex-row flex-wrap-reverse m-1 justify-content-between">
						<div class="d-flex my-1">
							<div class="d-flex flex-row mr-3">
								${search_button}
								${clear_button}
							</div>
							<div id="${this.formatted_dt_name}_list_todo_sv_actions" class="d-flex flex-row">
								${new_button}
								${duplicate_button}
								${delete_button}
							</div>
						</div>
						<div class="d-flex flex-row m-1">
							${only_open}
							${only_mine}
						</div>
					</div>
					<input id="${this.formatted_dt_name}_list_todo_sv_orderby" type="hidden" value="date">
					<input id="${this.formatted_dt_name}_list_todo_sv_order" type="hidden" value="desc">
					<div id="${this.formatted_dt_name}_list_todo_sv_grid" class="table table-bordered" style="max-height: 100%; font-size: var(--text-md);" data-custom-grid="true"></div>
				</div>
				<div id="${this.formatted_dt_name}_list_todo_empty" class="align-items-center justify-content-center mt-5" style="display: flex;">${__('Please select a row from the list to view linked todos')}</div>
			</div>`

		$(`#${this.formatted_dt_name}_list_side_view`).append(control_panel);

		const todo_sv_search_key_down = (e) => {
			if (e.keyCode == 13) this.todo_sv_search();
		}

		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_search`).onkeydown = (e) => todo_sv_search_key_down(e);
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_topn`).onkeydown = (e) => todo_sv_search_key_down(e);
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_dt_from`).onkeydown = (e) => todo_sv_search_key_down(e);
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_dt_to`).onkeydown = (e) => todo_sv_search_key_down(e);

		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_topn`).onchange = () => this.todo_sv_search();
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_dt_from`).onchange = () => this.todo_sv_search();
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_dt_to`).onchange = () => this.todo_sv_search();
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_status`).onchange = () => this.todo_sv_search();
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_ioi_category`).onchange = () => this.todo_sv_search();

		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_only_open`).onclick = () => this.todo_sv_search();
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_only_mine`).onclick = () => this.todo_sv_search();

		if (document.getElementById(`${this.formatted_dt_name}_list_todo_sv_nb_document`)) {
			for (var i = 0; i < document.getElementById(`${this.formatted_dt_name}_list_todo_sv_nb_document`).value; i++) {
				if (document.getElementById(`${this.formatted_dt_name}_list_todo_sv_document_` + i.toString())) {
					document.getElementById(`${this.formatted_dt_name}_list_todo_sv_document_` + i.toString()).onclick = () => this.todo_sv_search();
				}
			}
		}

		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_bt_search`).onclick = () => this.todo_sv_search();
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_bt_clear`).onclick = () => this.todo_sv_clear();
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_bt_new`).onclick = () => this.todo_sv_add();

		$(`#${this.formatted_dt_name}_list_side_view_close`)[0].onclick = () => {
			this.clear_ioi_list_side_view()
			frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 0, doctype: this.doctype, type: "", is_new: 0, side_view_origin: 'LIST' })
		}

		$(`#${this.formatted_dt_name}_list_todo_sv_bt_duplicate`)[0].onclick = () => this.todo_sv_duplicate(this.todo_sv_table)
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_bt_delete`).onclick = () => this.todo_sv_delete(this.todo_sv_table);
	}

	todo_sv_add() {
		let me = this

		// filter ioi Category with Reference Doctype
		frappe.new_doc('ToDo', { reference_type: me.doctype, reference_name: this.todo_sv_table.options.row_name }, doc => {
			doc.fields_dict.ioi_category_id.get_query = function() {
				return {
					filters: {
						related_doctype: me.doctype
					}
				};
			};
		});
	}

	todo_sv_delete() {
		let me = this
		let selected_rows = this.todo_sv_table.getSelectedRows()

		if (selected_rows.length <= 1) {
			if (selected_rows.lenght == 0) {
				return false;
			} else {
				if (!document.getElementById(`${this.formatted_dt_name}_list_todo_sv_name_0`)) {
					return false;
				}
			}
		}

		let names = selected_rows.map(row => row.getData().name).join(';')

		if (names.trim() != '') {
			let msg = 'Delete selected todo(s) ?'

			frappe.confirm(msg,
				() => {
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 1].disabled = true;

					frappe.call({
						method: 'silicon_ioi.ioi_crm.event_todo_file.module_todo_delete',
						args: { "names": names },
						async: false,
						callback: function (r) {
							me.todo_sv_search();
						}
					});
				},
				() => {}
			);
		}
	}

	todo_sv_grid(name) {
		let me = this

		$(`#${this.formatted_dt_name}_list_todo_empty`).hide()
		$(`#${this.formatted_dt_name}_list_todo_sv_hidden_content`).show()

		if (this.todo_sv_table) this.todo_sv_table.destroy();

		let format_due_date = (cell) => {
			let date = cell.getValue()
			let status = cell.getData().status

			if (date) {
				let today = frappe.datetime.get_today()
				let container = frappe.ui.form.make_control({
					parent: cell.getElement(),
					df: {
						fieldname: 'date',
						fieldtype: 'Date'
					},
					render_input: true
				})

				container.input.value = cell.getValue()
				container.input.classList.add('m-1', 'p-0', "text-center")

				if (today > date && (status && status == __('Open'))) {
					container.input.classList.add('bg-danger')
				} else if (today == date && (status && status == __('Open'))) {
					container.input.classList.add('bg-warning')
				} else if (today < date && (status && status == __('Open'))) {
					container.input.classList.add('bg-success')
				}

				return container.input
			}
		}

		let format_status = (cell) => {
			if (cell.getValue()) {
				return `<div class="d-flex flex-row justify-content-center w-100">
							<div style="width: 15px; height: 15px; border-radius: 50%; background:${cell.getValue().toUpperCase() == __("OPEN") ? "#E7A4A4" : "#9ABBA7"}"></div>
						</div>`
			}
		}

		let format_action = (cell) => {
			const row = cell.getRow()
			const index = row.getPosition() - 1

			const build_buttons = () => {
				if (cell.getData().status.toLowerCase() === "open") {
					return `<div class="d-flex flex-column w-100 h-100 justify-content-around align-items-center m-1">
								<button id="${this.formatted_dt_name}_list_todo_sv_status_bt_close_${index}" type="button" class="btn btn-xs btn-secondary p-1" style="height: 29px; width: 29px;">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-closed" style="pointer-events: none;" viewBox="0 0 16 16">
										<path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3zm1 13h8V2H4z"/>
										<path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0"/>
									</svg>
								</button>
								<button id="${this.formatted_dt_name}_list_todo_sv_status_bt_cancel_${index}" type="button" class="btn btn-xs btn-secondary p-1" style="height: 29px; width: 29px;">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" style="pointer-events: none;" viewBox="0 0 16 16">
										<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
									</svg>
								</button>
							</div>`
				} else {
					return `<div class="d-flex flex-column align-items-center w-100 m-1">
								<button id="${this.formatted_dt_name}_list_todo_sv_status_bt_reopen_${index}" type="button" class="btn btn-xs btn-secondary p-1" style="height: 29px; width: 29px;">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-open" style="pointer-events: none;" viewBox="0 0 16 16">
										<path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1"/>
										<path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117M11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5M4 1.934V15h6V1.077z"/>
									</svg>
								</button>
							</div>`
				}
			}

			const build_empty = () => `<div class="w-100"></div>`

			if (frappe.session.user.toUpperCase() == 'ADMINISTRATOR') {
				return build_buttons()
			} else {
				const allocated_to = row.getData().allocated_to

				if (allocated_to && allocated_to !== "") {
					if ((frappe.session.user.toUpperCase() == allocated_to.toUpperCase()) || (frappe.session.user_email.toUpperCase() == allocated_to.toUpperCase())) {
						return build_buttons()
					} else {
						return build_empty()
					}
				} else {
					return build_empty()
				}
			}
		}

		let action_click = (e, cell) => {
			const index = cell.getRow().getPosition() - 1

			if (e.target.id == `${this.formatted_dt_name}_list_todo_sv_status_bt_close_${index}`) {
				this.todo_sv_close(cell.getData().name, this.todo_sv_table)
			} else if (e.target.id == `${this.formatted_dt_name}_list_todo_sv_status_bt_cancel_${index}`) {
				this.todo_sv_cancel(cell.getData().name, this.todo_sv_table)
			} else if (e.target.id == `${this.formatted_dt_name}_list_todo_sv_status_bt_reopen_${index}`) {
				this.todo_sv_reopen(cell.getData().name, this.todo_sv_table)
			}
		}

		let format_link = (cell, formatterParams) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				const data = cell.getData()
				const index = cell.getRow().getPosition() - 1

				let s = data.reference_type;
				s = s.replace('ioi ', '');

				const div = document.createElement('div')
				div.innerHTML = cell.getValue()
				div.firstElementChild ? div.firstElementChild.style.pointerEvents = "none" : ''

				let html = `<div class="d-flex flex-row align-items-center w-100 h-100 position-relative p-2 rounded my-2" style="overflow: auto; max-width: 100%; background-color: var(--control-bg);">
								<div id="${this.formatted_dt_name}_list_todo_sv_edit_${index}" style="position: absolute; top: 5px; right: 5px">
									<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-pencil-square" style="pointer-events: none;" viewBox="0 0 16 16">
										<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
										<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
									</svg>
								</div>
								${data.color ? `<div class="mr-2" style="width: 15px; min-width: 15px; height: 15px; border-radius: 50%; background:${data.color}"></div>` : ''}
								<div class="d-flex flex-column">
									<a id="${this.formatted_dt_name}_list_todo_sv_id_${index}">${div.innerHTML}</a>
									${data.reference_type != me.doctype && data.reference_name ? `
										<div>
											<u>${s}</u>&nbsp;:&nbsp;
											<a id="${this.formatted_dt_name}_list_todo_sv_jump_id_${index}" onmouseover="this.style.textDecoration ='underline';" onmouseout="this.style.textDecoration = 'none';">${data.reference_name}</a>
										</div>`
									: ''}
								</div>
							</div>`

				return html
			}
		}

		let link_onclick = (e, cell) => {
			const value = cell.getValue()
			const row = cell.getRow()
			const index = row.getPosition() - 1

			if (value && value !== "") {
				if (cell.getColumn().getField() == "description") {
					if (e.target.id == `${this.formatted_dt_name}_list_todo_sv_jump_id_${index}`) {
						this.open_link(row.getData().reference_type, row.getData().reference_name)
					} else if (e.target.id == `${this.formatted_dt_name}_list_todo_sv_edit_${index}`) {
						edit_todo_sv_description(e, cell, index)
					}
				}
			}
		}

		let edit_todo_sv_description = (e, cell, index) => {
			const desc_link = document.getElementById(`${this.formatted_dt_name}_list_todo_sv_id_${index}`)

			let d = new frappe.ui.Dialog({
				title: __('Update Description'),
				fields: [
					{
						fieldname: 'description',
						fieldtype: 'Text Editor',
						default: desc_link.innerHTML
					},
				],
				size: 'small',
				primary_action_label: 'Submit',
				primary_action(values) {
					desc_link.innerHTML = values.description
					desc_link.firstElementChild ? desc_link.firstElementChild.style.pointerEvents = "none" : ''

					desc_link.classList.add('desc_updated')

					d.hide();
				}
			});

			d.show();
		}

		let format_ioi_category = (cell) => {
			let options = this.event_categories

			let container = frappe.ui.form.make_control({
				parent: cell.getElement(),
				df: {
					fieldname: 'ioi_category',
					fieldtype: 'Select',
					options: options,
				},
				render_input: true
			})

			const select = container.wrapper.querySelector('select')
			select.value = cell.getValue()
			select.style.paddingRight = "30px"

			container.wrapper.classList.add('w-100', 'm-1')

			return container.wrapper
		}

		let format_allocated = (cell) => {
			let container = frappe.ui.form.make_control({
				parent: cell.getElement(),
				df: {
					fieldname: 'allocated_to',
					fieldtype: 'Select',
					options: this.allocated_users.map(allocated_user => allocated_user),
				},
				render_input: true
			})

			if (cell.getValue()) {
				container.wrapper.querySelector('select').value = cell.getValue()
			}

			container.wrapper.classList.add('w-100', 'my-2')
			container.wrapper.style.maxWidth = "100%"

			return container.wrapper
		}

		let save_changes_onclick = (e, cell) => {
			if (e.target.id.includes("sv_btn_save_changes")) {
				let data = cell.getData()

				let updated_allocated = cell.getData().allocated_to && cell.getRow().getElement().querySelector('[data-fieldname="allocated_to"] select')
				let updated_ioi_category = cell.getRow().getElement().querySelector('[data-fieldname="ioi_category"] select')
				let updated_date = cell.getData().date && cell.getRow().getElement().querySelector('[data-fieldname="date"]')
				let updated_description = cell.getRow().getElement().querySelector(`#${this.formatted_dt_name}_list_todo_sv_id_${cell.getRow().getPosition() - 1}`)

				if (updated_date && (data.date != updated_date.value)) {
					this.todo_sv_update_datas(cell.getData().name, "date", updated_date.value, this.todo_sv_table)
				}
				if (data.ioi_category_id != updated_ioi_category.value) {
					this.todo_sv_update_datas(cell.getData().name, "ioi_category_id", updated_ioi_category.value, this.todo_sv_table)
				}
				if (updated_allocated && (data.allocated_to != updated_allocated.value)) {
					this.todo_sv_update_datas(cell.getData().name, "allocated_to", updated_allocated.value, this.todo_sv_table)
				}
				if (updated_description && updated_description.classList.contains('desc_updated')) {
					this.todo_sv_update_datas(cell.getData().name, "description", updated_description.innerHTML, this.todo_sv_table)
				}
			} else if (e.target.id.includes("sv_btn_jump")) {
				let row = cell.getRow()
				window.open('/app/todo/' + row.getData().name);
			}
		}

		let format_save_changes = () => {
			return `<div class="d-flex flex-column justify-content-around align-items-center w-100 h-100">
						<button id="sv_btn_save_changes" type="button" class="btn btn-secondary btn-xs">
							${__('Save')}
						</button>
						<button id="sv_btn_jump" type="button" class="btn btn-secondary btn-xs">
							${__('Show')}
						</button>
					</div>`
		}

		const check_type_render = (td, func) => {
			if (typeof(func) === "object") {
				return td.appendChild(func)
			} else if (typeof(func) === "string") {
				return td.innerHTML = func
			}
		}

		$(`#${this.formatted_dt_name}_list_side_view_name`).text(name ? ` : ${name}` : '')

		this.todo_sv_table = new ioi.Tabulator(`#${this.formatted_dt_name}_list_todo_sv_grid`, {
			row_name: name,
			maxHeight: "100%",
			minHeight: 150,
			wrapLines: false,
			width: "100%",
			data: [],
			layout: "fitColumns",
			autoRedraw: true,
			columnDefaults: {
				vertAlign: "middle",
			},
			initialSort: [
				{ column: "date", dir: "desc" },
			],
			rowFormatter: (row) => {
				row.getElement().id = `${this.formatted_dt_name}_list_todo_sv_name_${row.getPosition() - 1}`

				var element = row.getElement()

				let rowTable = document.createElement("table")
				rowTable.style.width = "100%"

				let rowTabletr = document.createElement("tr");
				let rowTabletd

				row.getCells().forEach(cell => {
					const title = cell.getColumn().getDefinition().title

					if (title && title !== "") {
						const field = cell.getColumn().getDefinition().field

						if (field === "description" || field === "allocated_to") {
							rowTabletd = document.createElement("td");

							rowTabletd.classList.add('d-flex', 'align-items-center', 'justify-content-between', "px-3", "border-top")
							rowTabletd.style.minHeight = "75px"
							field === "allocated_to" ? rowTabletd.style.height = "75px" : ''
							rowTabletd.style.backgroundColor = "var(--card-bg)"

							rowTabletr.appendChild(rowTabletd)

							const format = field === "description" ? format_link(cell) : field === "allocated_to" ? format_allocated(cell) : ""

							check_type_render(rowTabletd, format)
							rowTabletd.addEventListener('click', (e) => link_onclick(e, cell))
							rowTabletd.insertAdjacentHTML('afterbegin', `<div class="mr-2 bold">${cell.getColumn().getDefinition().title} :</div>`)
						}
					}
				})

				rowTable.appendChild(rowTabletr);
				element.append(rowTable);
			},
			columns: [
				{ field: "checkbox", formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerHozAlign: "center", headerSort: false, minWidth: 40, maxWidth: 40 },
				{ title: __("Due Date"), field: "date",	minWidth: 120, maxWidth: 120, formatter: format_due_date },
				{ title: __("Status"), field: "status", minWidth: 50, maxWidth: 50, headerSort: false, formatter: format_status },
				{ title: __("Action"), field: "action", minWidth: 60, maxWidth: 60, headerSort: false, formatter: format_action, cellClick: (e, cell) => action_click(e, cell) },
				{ title: __("Description"), field: "description", visible: false },
				{ title: __("ioi Category"), field: "ioi_category_id", minWidth: 100, formatter: format_ioi_category },
				{ title: __("Allocated to"), field: "allocated_to", visible: false },
				{ field: "save_changes", minWidth: 70, formatter: format_save_changes, cellClick: (e, cell) => save_changes_onclick(e, cell), hozAlign: "center", headerSort: false },
			]
		});

		let columns_not_to_order = ['checkbox', 'action', 'header_menu']

		this.todo_sv_table.on("headerClick", (e, column) => {
			let ordered_field = document.getElementById(`${this.formatted_dt_name}_list_todo_sv_orderby`)
			ordered_field.value = column.getField()

			let ordered_dir = document.getElementById(`${this.formatted_dt_name}_list_todo_sv_order`)
			ordered_dir.value = me.todo_sv_table.getSorters()[0].dir

			let field = column.getField()

			if (!columns_not_to_order.includes(field)) {
				// me.#todo_col_click(column.getField(), me.todo_sv_table)
			}
		});

		this.todo_sv_search(name)
	}

	todo_sv_clear() {
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_search`).value = '';
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_topn`).value = '100';

		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_dt_from`).value = '';
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_dt_to`).value = '';
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_status`).selectedIndex = 0;
		document.getElementById(`${this.formatted_dt_name}_list_todo_sv_ioi_category`).selectedIndex = 0;

		this.todo_sv_table.clearData()
	}

	todo_sv_search(name) {
		let me = this

		let other_doctypes = '';
		let match_fields = '';

		if (document.getElementById(`${this.formatted_dt_name}_list_todo_sv_nb_document`)) {
			for (var i = 0; i < document.getElementById(`${this.formatted_dt_name}_list_todo_sv_nb_document`).value; i++) {
				if (document.getElementById(`${this.formatted_dt_name}_list_todo_sv_document_` + i.toString())) {
					if (document.getElementById(`${this.formatted_dt_name}_list_todo_sv_document_` + i.toString()).checked) {
						other_doctypes += document.getElementById(`${this.formatted_dt_name}_list_todo_sv_document_` + i.toString()).value + ';'

						if (this.doctype.toUpperCase() == 'IOI CUSTOMER') {
							if ((document.getElementById(`${this.formatted_dt_name}_list_todo_sv_document_` + i.toString()).value.toUpperCase() == 'IOI SALES QUOTE') ||
								(document.getElementById(`${this.formatted_dt_name}_list_todo_sv_document_` + i.toString()).value.toUpperCase() == 'IOI SALES ORDER') ||
								(document.getElementById(`${this.formatted_dt_name}_list_todo_sv_document_` + i.toString()).value.toUpperCase() == 'IOI SALES DELIVERY') ||
								(document.getElementById(`${this.formatted_dt_name}_list_todo_sv_document_` + i.toString()).value.toUpperCase() == 'IOI SALES INVOICE')) {
								match_fields += 'order_customer_id,delivery_customer_id,invoice_customer_id;'
							}
						} else if (this.doctype.toUpperCase() == 'IOI SUPPLIER') {
							if ((document.getElementById(`${this.formatted_dt_name}_list_todo_sv_document_` + i.toString()).value.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') ||
								(document.getElementById(`${this.formatted_dt_name}_list_todo_sv_document_` + i.toString()).value.toUpperCase() == 'IOI PURCHASES ORDER') ||
								(document.getElementById(`${this.formatted_dt_name}_list_todo_sv_document_` + i.toString()).value.toUpperCase() == 'IOI PURCHASES RECEIPT') ||
								(document.getElementById(`${this.formatted_dt_name}_list_todo_sv_document_` + i.toString()).value.toUpperCase() == 'IOI PURCHASES INVOICE')) {
								match_fields += 'order_supplier_id,delivery_supplier_id,invoice_supplier_id;'
							}
						}
					}
				}
			}
		}

		let row_name = !!name ? name : this.todo_sv_table.options.row_name || ''

		frappe.call({
			method: 'silicon_ioi.ioi_crm.event_todo_file.module_todo_get_data',
			args: {
				"doctype": this.doctype,
				"name": row_name,
				"search": document.getElementById(`${this.formatted_dt_name}_list_todo_sv_search`).value,
				"dt_from": document.getElementById(`${this.formatted_dt_name}_list_todo_sv_dt_from`).value,
				"dt_to": document.getElementById(`${this.formatted_dt_name}_list_todo_sv_dt_to`).value,
				"status": document.getElementById(`${this.formatted_dt_name}_list_todo_sv_status`).value,
				"ioi_category": document.getElementById(`${this.formatted_dt_name}_list_todo_sv_ioi_category`).value,
				"topn": document.getElementById(`${this.formatted_dt_name}_list_todo_sv_topn`).value,
				"orderby": document.getElementById(`${this.formatted_dt_name}_list_todo_sv_orderby`).value || "date",
				"order": document.getElementById(`${this.formatted_dt_name}_list_todo_sv_order`).value,
				"only_mine": document.getElementById(`${this.formatted_dt_name}_list_todo_sv_only_mine`).checked,
				"only_open": document.getElementById(`${this.formatted_dt_name}_list_todo_sv_only_open`).checked,
				"other_doctypes": other_doctypes,
				"match_fields": match_fields
			},
			async: true,
			callback: function (r) {
				if (r.message.length > 0) {
					me.todo_sv_table.replaceData(r.message)
				} else {
					me.todo_sv_table.clearData()
				}
			}
		})
	}

	todo_sv_close(name) {
		let new_status = __("Closed")
		this.todo_sv_change_status(name, new_status)
	}

	todo_sv_cancel(name) {
		let new_status = __("Cancelled")
		this.todo_sv_change_status(name, new_status)
	}

	todo_sv_reopen(name) {
		let new_status = __("Open")
		this.todo_sv_change_status(name, new_status)
	}

	todo_sv_update_datas(name, fieldname, new_value) {
		let me = this;

		frappe.call({
			method: 'silicon_ioi.ioi_crm.event_todo_file.module_todo_update_datas',
			args: {
				"name": name,
				"fieldname": fieldname,
				"new_value": new_value
			},
			async: false,
			callback: function (r) {

				if (r.message.error == 1) {
					frappe.msgprint({ title: __("Message"), message: r.message.error_msg, indicator: "red" });
					raise;
				} else {
					me.todo_sv_search();
				}
			}
		});
	}

	todo_sv_change_status(name, new_status) {
		let me = this;

		frappe.call({
			method: 'silicon_ioi.ioi_crm.event_todo_file.module_todo_change_status',
			args: {
				"name": name,
				"new_status": new_status
			},
			async: false,
			callback: function (r) {

				if (r.message.error == 1) {
					frappe.msgprint({ title: __("Message"), message: r.message.error_msg, indicator: "red" });
					raise;
				} else {
					me.todo_sv_search();
				}
			}
		});
	}

	todo_sv_duplicate() {
		let me = this;
	
		let d = new frappe.ui.Dialog({
			title: __('Duplicate ToDo'),
			fields: [
				{
					label: __('Allocated to'),
					fieldname: 'user',
					fieldtype: 'Autocomplete',
					options: this.allocated_users
				},
			],
			size: 'small',
			primary_action_label: __('Duplicate'),
			async primary_action(values) {
				const rows = me.todo_sv_table.getSelectedRows();
				const row_name = me.todo_sv_table.options.row_name || '';
	
				for (const row of rows) {
					const data = row.getData();
	
					await frappe.db.insert({
						doctype: 'ToDo',
						status: data.status,
						ioi_category_id: data.ioi_category_id,
						color: data.color,
						date: data.date,
						allocated_to: values.user,
						description: data.description,
						reference_type: me.doctype,
						reference_name: row_name,
						role: data.role,
						assigned_by: data.assigned_by,
						assignment_rule: data.assignment_rule
					});
				}
	
				me.todo_sv_search();
				d.hide();
			}
		});
	
		d.show();
	}

	////////////////////////////////////////
	//////// Related data Side View ////////
	////////////////////////////////////////

	show_related_data_side_view(curr_doctype_infos) {
		if (frappe.get_route()[0] == 'List') {
			if (curr_doctype_infos && curr_doctype_infos.enabled == 1) {

				const saved_width = this.check_side_view_res(curr_doctype_infos.preview_width)

				const refresh_button = `
					<button id="${this.formatted_dt_name}_list_side_view_refresh" class="text-muted btn btn-xs btn-default mr-2 my-1" title="${__('Refresh')}">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
							<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
						</svg>
					</button>`

				const add_button = `
					<div id="${this.formatted_dt_name}_list_side_view_add" class="my-1 mr-1">
						<button title="${__("Add Row")}" class="btn btn-secondary btn-xs ellipsis">
							${__("Add Row")}
						</button>
					</div>`

				const remove_button = `
					<div id="${this.formatted_dt_name}_list_side_view_remove" class="m-1 hide">
						<button title="${__("Remove")}" class="btn btn-danger btn-xs ellipsis">
							${__("Remove")}
						</button>
					</div>`

				const container = `
					<div id="${this.formatted_dt_name}_list_side_view" class="p-4 ml-3 rounded position-relative" style="width: ${saved_width && saved_width != 0 ? `${saved_width}px` : '30%'};">
						<button id="${this.formatted_dt_name}_list_side_view_close" class="btn btn-xs position-absolute shadow-none" style="top: 0px; right: 0px;">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
								<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
							</svg>
						</button>
						<label class="control-label" style="padding-right: 0px; font-size: var(--text-md);">${__('Related Data')}</label>
						<span id="${this.formatted_dt_name}_list_side_view_name" style="padding-right: 0px; font-size: var(--text-md); font-weight: 600;"></span>
						<div id="${this.formatted_dt_name}_list_related_content">
							<div id="${this.formatted_dt_name}_list_related_hidden_content" style="display: none;">
								<div id="${this.formatted_dt_name}_list_side_view_grid" class="table table-bordered mt-2 mb-1" style="max-height: 100%; font-size: var(--text-md);" data-custom-grid="true"></div>
								<div class="d-flex flex-row flex-wrap align-items-start justify-content-start mt-3">
									${refresh_button}
									${add_button}
									${remove_button}
								</div>
							</div>
							<div id="${this.formatted_dt_name}_list_related_empty" class="align-items-center justify-content-center mt-5" style="display: flex;">${__('Please select a row from the list to view related datas')}</div>
						</div>
					</div>`;

				const resizer_html = `<div id="${this.formatted_dt_name}_list_side_view_resizer" class="resizer rounded" style="height: calc(-165px + 100vh - 50px);"></div>`;
				const main_layout = $(`[data-page-route="List/${this.doctype}/Expert"]`).find(".row.layout-main");

				this.clear_ioi_list_side_view()

				main_layout.append(resizer_html).append(container)
				this.create_resizer(this.doctype);

				let me = this

				$(`#${this.formatted_dt_name}_list_side_view_refresh`)[0].onclick = () => this.related_data_search()
				$(`#${this.formatted_dt_name}_list_side_view_add`)[0].onclick = () => this.related_data_add()
				$(`#${this.formatted_dt_name}_list_side_view_remove`)[0].onclick = () => 
					frappe.call('silicon_ioi.utils.lib.system.remove_related_data', { rows: this.related_data_table.getSelectedData() }).then(() => me.related_data_search())

				$(`#${this.formatted_dt_name}_list_side_view_close`)[0].onclick = () => {
					this.clear_ioi_list_side_view()
					frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 0, doctype: this.doctype, type: curr_doctype_infos.side_view_type, is_new: 0, side_view_origin: 'LIST' })
				}

			} else if (curr_doctype_infos && curr_doctype_infos.enabled == 0) {
				this.clear_ioi_list_side_view()
			}
		} else {
			this.clear_ioi_list_side_view()
		}
	}

	related_data_add() {
		let me = this

		let d = new frappe.ui.Dialog({
			title: __('New Related Data'),
			fields: [
				{
					label: __('Remark'),
					fieldname: 'remark',
					fieldtype: 'Small Text'
				},
				{
					label: __('Doctype'),
					fieldname: 'doc_type',
					fieldtype: 'Link',
					options: 'DocType',
					reqd: 1
				},
				{
					label: __('Document'),
					fieldname: 'document',
					fieldtype: 'Dynamic Link',
					options: 'doc_type',
					reqd: 1
				}
			],
			size: 'small',
			primary_action_label: __('Create'),
			primary_action(values) {
				const docname = me.related_data_table.options.row_name

				frappe.call('silicon_ioi.utils.lib.system.create_related_data', {
					remark: values.remark, master_dt: me.doctype, master_doc: docname, linked_dt: values.doc_type, linked_doc: values.document
				}).then(() => me.related_data_search())

				d.hide();
			}
		});
		d.show();
	}

	related_data_grid(name) {

		$(`#${this.formatted_dt_name}_list_related_empty`).hide()
		$(`#${this.formatted_dt_name}_list_related_hidden_content`).show()

		$(`#${this.formatted_dt_name}_list_side_view_name`).text(name ? ` : ${name}` : '')

		let format_checkbox = (cell) => {
			let value = cell.getValue()

			if (value != null) {
				const data = cell.getData()
				const div = document.createElement('div')

				div.classList.add('w-100', 'h-100', 'd-flex', 'justify-content-center', 'align-items-center')
				div.innerHTML = `<input id="related_deprecated_checkbox" type="checkbox" value="" ${value == 0 ? '' : 'checked'}>`

				div.onclick = (e) => {
					if (e.target.id == 'related_deprecated_checkbox') frappe.call('silicon_ioi.utils.lib.system.related_data_toggle_deprecated', { name: data.parent, checkbox: data.deprecated }).then(() => this.related_data_search())
				}

				return div
			}
		}

		let format_child = (cell) => {
			const value = cell.getValue()

			if (value) {
				const field = cell.getField()

				if (field.includes('doc_type')) {
					return value
				} else if (field.includes('document')) {
					const title_field = cell.getData().title_field
					const div = document.createElement('div')
					const html = `<span style="cursor: pointer; text-decoration: underline;">${title_field ? title_field : value}</span>`
					const dt = cell.getData().doc_type.toLowerCase().replaceAll(' ', '-')

					div.innerHTML = html
					div.onclick = () => window.open(`/app/${dt}/${value}`, "_blank")

					return div
				}
			}
		}

		let format_date = (cell) => {
			return cell.getValue().split(".")[0]
		}

		let format_linked_info = (cell) => {
			const value = cell.getValue()

			if (value) {
				const info_fn = cell.getData().info_from_fieldname.split(';')
				const array = value.split(';')

				const formatted_array = array.map((el, i) => {
					if (el && el !== "") {
						if (info_fn[i] === "title_id" || info_fn[i] === "salutation") {
							info_fn[i] = __("Title")
						} else if (info_fn[i] === "lastname" || info_fn[i] === "last_name") {
							info_fn[i] = __("Name")
						} else if (info_fn[i] === "firstname" || info_fn[i] === "first_name") {
							info_fn[i] = __("First name")
						} else if (info_fn[i] === "full_name") {
							info_fn[i] = __("Name")
						} else if (info_fn[i] === "description") {
							info_fn[i] = __("Description")
						}

						return `${info_fn[i]} : ${el}`
					} else {
						i++
					}
				})

				return `<div class="" style="width: 100%;">${formatted_array.filter(el => el !== undefined).join(', ')}</div>`
			}
		}

		const check_type_render = (td, func) => {
			if (typeof(func) === "object") {
				return td.appendChild(func)
			} else if (typeof(func) === "string") {
				return td.innerHTML = func
			}
		}

		let columns = [
			{ field: "checkbox", formatter: "rowSelection", titleFormatter: "rowSelection", headerHozAlign: "center", hozAlign: "center", headerSort: false, resizable: false, minWidth: 40, maxWidth: 40 },
			{ title: __("Link type"), field: "doc_type", formatter: format_child },
			{ title: __("Linked to"), field: "document", formatter: format_child },
			{ title: __("Linked info"), field: "linked_info", visible: false },
			{ title: __("Info from fieldname"), field: "info_from_fieldname", visible: false },
			{ title: __("Last update on"), field: "modified", formatter: format_date },
			{ title: __("Deprecated"), field: "deprecated", minWidth: 40, formatter: format_checkbox },
			{ title: __("Remark"), field: "remark", visible: false },
			{ title: __("Author"), field: "author", visible: false },
		]

		this.related_data_table = new ioi.Tabulator(`#${this.formatted_dt_name}_list_side_view_grid`, {
			row_name: name,
			maxHeight: "100%",
			data: [],
			layout: "fitColumns",
			autoRedraw: true,
			initialSort: [
				{ column: "modified", dir: "desc" },
			],
			columnDefaults: {
				minWidth: 85,
				vertAlign: "middle",
			},
			columns: columns,
			selectableRows: true,
			rowFormatter: (row) => {
				var element = row.getElement()

				let rowTable = document.createElement("table")
				rowTable.style.width = "100%"

				let rowTabletr = document.createElement("tr");
				let rowTabletd

				row.getCells().forEach(cell => {
					const title = cell.getColumn().getDefinition().title

					if (title && title !== "") {
						const field = cell.getColumn().getDefinition().field

						if (field === "linked_info" || field === "remark" || field === "author") {
							rowTabletd = document.createElement("td");

							rowTabletd.classList.add('d-flex', 'align-items-center', 'justify-content-between', "px-3", "border-top")
							rowTabletd.style.backgroundColor = "var(--card-bg)"

							rowTabletr.appendChild(rowTabletd)

							const format = field === "linked_info" ? format_linked_info(cell) : `<div class="" style="width: 100%;">${cell.getElement().innerHTML}</div>`

							check_type_render(rowTabletd, format)
							rowTabletd.insertAdjacentHTML('afterbegin', `<div class="mr-2 bold">${cell.getColumn().getDefinition().title} :</div>`)
						}
					}
				})

				rowTable.appendChild(rowTabletr);
				element.append(rowTable);
			},
		});

		this.related_data_table.on("rowSelectionChanged", (data, rows, selected, deselected) => {
			const remove_btn = $(`#${this.formatted_dt_name}_list_side_view_remove`)

			if (rows.length) {
				remove_btn.addClass('show');
				remove_btn.removeClass('hide');
			} else {
				remove_btn.addClass('hide');
				remove_btn.removeClass('show');
			}
		});

		this.related_data_search(name);
	}

	related_data_search(name) {
		let me = this;
		let row_name = !!name ? name : this.related_data_table.options.row_name || ''

		frappe.call({
			method: "silicon_ioi.utils.lib.system.get_related_data_list",
			args: {
				doctype: this.doctype,
				docname: row_name,
			},
			async: true,
			callback: function (r) {
				if (r.message.length > 0) {
					if (me.related_data_table.initialized) {
						me.related_data_table.replaceData(r.message)
					} else {
						me.related_data_table.on('tableBuilt', () => me.related_data_table.replaceData(r.message))
					}
				} else {
					me.related_data_table.clearData()
				}
			}
		})
	}

	////////////////////////////////////////
	/////// Highlight Info Side View ///////
	////////////////////////////////////////

	show_highlight_side_view(curr_doctype_infos) {
		if (frappe.get_route()[0] == 'List') {
			if (curr_doctype_infos && curr_doctype_infos.enabled == 1) {

				const saved_width = this.check_side_view_res(curr_doctype_infos.preview_width)

				const container = `
					<div id="${this.formatted_dt_name}_list_side_view" class="p-4 ml-3 rounded position-relative overflow-auto" style="width: ${saved_width && saved_width != 0 ? `${saved_width}px` : '30%'}; height: calc(-165px + 100vh)">
						<button id="${this.formatted_dt_name}_list_side_view_close" class="btn btn-xs position-absolute shadow-none" style="top: 0px; right: 0px;">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
								<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
							</svg>
						</button>
						<div class="d-flex flex-row flex-wrap align-items-start justify-content-between">
							<div>
								<label class="control-label" style="padding-right: 0px; font-size: var(--text-md);">${__('Highlight Info')}</label>
								<span id="${this.formatted_dt_name}_list_side_view_name" style="padding-right: 0px; font-size: var(--text-md); font-weight: 600;"></span>
							</div>
							<div class="hidden align-self-en" id="${this.formatted_dt_name}_list_highlight_info_buttons" style="margin: 0 8%;">
								<div class="d-flex w-100">
									<div class="frappe-control input-max-width" data-fieldtype="Select">
										<div class="form-group">
											<div class="clearfix">
												<label class="control-label" style="padding-right: 0px; font-size: var(--text-md);">${__('View Selector')}</label>
											</div>
											<div class="control-input-wrapper">
												<div class="control-input flex align-center">
													<select id="${this.formatted_dt_name}_side_view_select" type="text" autocomplete="off" class="input-with-feedback form-control ellipsis"></select>
													<div class="select-icon ">
														<svg class="icon  icon-sm" style="">
															<use class="" href="#icon-select"></use>
														</svg>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="mt-3" id="${this.formatted_dt_name}_list_highlight_info_content"></div>
					</div>`;

				const resizer_html = `<div id="${this.formatted_dt_name}_list_side_view_resizer" class="resizer rounded" style="height: calc(-165px + 100vh - 50px);"></div>`;
				const main_layout = $(`[data-page-route="List/${this.doctype}/Expert"]`).find(".row.layout-main");

				this.clear_ioi_list_side_view()

				main_layout.append(resizer_html).append(container)
				this.create_resizer(this.doctype);

				this.get_highlight_list(0, undefined)

				$(`#${this.formatted_dt_name}_list_side_view_close`)[0].onclick = () => {
					this.clear_ioi_list_side_view()
					frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 0, doctype: this.doctype, type: curr_doctype_infos.side_view_type, is_new: 0, side_view_origin: 'LIST' })
				}

				$(`#${this.formatted_dt_name}_side_view_select`)[0].onchange = () => {
					this.get_highlight_list($(`#${this.formatted_dt_name}_side_view_select`).val(), this.ioi_expert_view_table.getSelectedData()[0].name)
				}

			} else if (curr_doctype_infos && curr_doctype_infos.enabled == 0) {
				this.clear_ioi_list_side_view()
			}
		} else {
			this.clear_ioi_list_side_view()
		}
	}

	get_highlight_list(view, name_from_data_list) {
		let me = this
		$(`#${this.formatted_dt_name}_list_highlight_info_content`)[0].innerHTML = ''
		$(`#${this.formatted_dt_name}_list_side_view_name`).text(name_from_data_list ? ` : ${name_from_data_list}` : '')

		if (name_from_data_list) {

			frappe.call({
				method: "silicon_ioi.utils.lib.system.get_highlight_list",
				args: {
					doctype: this.doctype,
					view: view,
					mode: 'LIST'
				},
				callback: async function (r) {

					if (r.message.length > 0) {
						let result = r.message

						if (result.find(el => !!el.view_selector)) {
							const select_container = $(`#${me.formatted_dt_name}_list_highlight_info_buttons`)
							const select = select_container.find(`#${me.formatted_dt_name}_side_view_select`)

							if (select_container.hasClass('hidden')) select_container.removeClass('hidden')

							if (select[0].options.length == 0) {
								const unique_view_selectors = new Set();

								select.html(
									result
									.filter(el => {
										if (!!el.view_selector && !unique_view_selectors.has(el.view_selector)) {
											unique_view_selectors.add(el.view_selector);
											return true;
										}
										return false;
									})
									.sort((a, b) => a.view_selector - b.view_selector)
									.map(el => `<option value="${el.view_selector}">${el.view_selector}</option>`)
									.join('')
								)
							}

							result = result.filter(el => el.view_selector == $(`#${me.formatted_dt_name}_side_view_select`).val())
						}

						for (const item of result) await me.build_highlight_content(item, name_from_data_list);

					} else {
						const div = document.createElement('div')

						div.innerHTML = `
							<div class="w-100 d-flex justify-content-center mt-5" style="text-align: center;">
								${__('Nothing to show')} <br/>
								${__('Define Highlight info in ioi Module')}
							</div>`

						$(`#${me.formatted_dt_name}_list_highlight_info_content`).append(div)
					}
				}
			})
		} else {
			const div = document.createElement('div')

			div.innerHTML = `
				<div class="w-100 d-flex justify-content-center mt-5" style="text-align: center;">
					${__('Please select a row from the list to view the highlighted informations')}
				</div>`

			$(`#${me.formatted_dt_name}_list_highlight_info_content`).append(div)
		}
	}

	async build_highlight_content(item, name_from_data_list) {

		if ($(`#${this.formatted_dt_name}_list_highlight_query_grid_${item.display_order}`).length || $(`#${this.formatted_dt_name}_list_highlight_html_render_${item.display_order}`).length) return;

		const div = document.createElement('div')
		div.classList.add('card', 'mb-4', 'border-0')
		div.style.height = `${item.card_height}px`

		const limit_text = isNaN(item.limited_to) ? __('No records limit') : __('Limited to {0} records', [item.limited_to])

		div.innerHTML = `
			<div class="card-header border-0" style="font-family: var(--font-stack); font-size: 0.84rem;">
				${item.title}
			</div>
			<div class="card-body ${item.sql_for_query_grid ? 'p-3' : ''} rounded-bottom" style="font-size: var(--text-sm); background-color: var(--card-bg); overflow: auto; border: 1px solid var(--border-color);">
				${
					item.display_mode === "HTML" ?
						item.fields_to_display ?
							`
							<div id="${this.formatted_dt_name}_list_highlight_html_render_${item.display_order}">
								${await this.highlight_content_filter_html(item.fields_to_display, name_from_data_list)}
							</div>
							`
						: ''
					: item.display_mode === "Query Grid" ?
						item.sql_for_query_grid ?
							`
							<div id="${this.formatted_dt_name}_list_highlight_query_grid_${item.display_order}" class="table table-bordered my-0" data-custom-grid="true"></div>
							<p class="help-box small text-muted">${limit_text}</p>
							`
						: ''
					: item.display_mode === "Child Grid" ?
						`
							<div id="${this.formatted_dt_name}_list_highlight_child_grid_${item.display_order}"></div>
						`
					: ''
				}
			</div>
		`

		$(`#${this.formatted_dt_name}_list_highlight_info_content`).append(div)

		if (item.display_mode === "Query Grid" && item.sql_for_query_grid) {
			await this.build_query_grid(item, name_from_data_list)
		} else if (item.display_mode === "Child Grid") {
			await this.build_child_grid_content(item, name_from_data_list)
		}
	}

	get_params(el) {
		let param = el.match(/\[(.*?)\]/)

		if (param) {
			param = param[0]
			el = el.replace(param, '')
		} else {
			param = null
			el = el
		}

		return [param, el]
	}

	async highlight_content_filter_html(text, name_from_data_list) {

		const translate_text = (text) => {
			const regex = /__\('([^']+)'\)/g;
			return text.replace(regex, (match, p1) => {
				return __(p1);
			});
		};

		text = translate_text(text);

		// Search {}
		const regex = /\{([^}]+)\}/g
		const matches = text.match(regex);

		if (matches) {
			let modified_text = text;

			let simple_fields = []
			let linked_fields = []
			let composite_fields = []

			const set_params_and_format = (el) => {
				let new_value = el.value
				let field_to_replace = `{${el.field}}`

				if (el.param && el.param !== "") {
					if (el.param === "[LINK]") {
						let url = ''

						if (!!el.value && el.value !== "undefined") {

							if (el.field.includes('•')) {
								url = el.doctype && el.name ? `/app/${this.doctype.toLowerCase().replaceAll(' ', '-')}/${name_from_data_list}?id=${el.field_name}` : ''
							} else {
								if (el.doctype && el.name) {
									url = `/app/${el.doctype.toLowerCase().replaceAll(' ', '-')}/${el.name}#${el.field.split('.')[1]}`
								} else if (el.field === "name" && el.field) {
									url = `/app/${this.doctype.toLowerCase().replaceAll(' ', '-')}/${el.name}`
								} else {
									url = ''
								}
							}

							new_value = `<span class="text-underline"><a href="${url}" target="_blank">${el.value}</a></span>`
							field_to_replace = `{${el.field}[LINK]}`

						} else {
							field_to_replace = __('undefined')
						}
					}
				}

				return modified_text = modified_text.replace(field_to_replace, new_value);
			}

			const meta_fields = await frappe.call("silicon_ioi.utils.lib.system.get_meta_for_side_view", { doctype: this.doctype }).then(r => r.message.fields)

			// Prepare to format values
			matches.forEach(el => {
				// Remove {}
				el = el.replace(/\{|\}/g, '')

				if (el.includes('.') && !el.includes('•')) {

					const [param, new_el] = this.get_params(el)
					const [link, field] = new_el.split('.')
					const meta_field = meta_fields.find(el => el.fieldname == link)
					const doctype = meta_field?.options

					const link_data = undefined

					linked_fields.push({ link_data, doctype, field, link, param })

				} else if (el.includes('•')) {

					let [param, new_el] = this.get_params(el)
					let doctype = new_el.split(', ')[1].trim()

					new_el = new_el.split(', ')[0]
					let [comp, field] = new_el.split('.')

					// curr_field is current doc field value
					let curr_field = comp.split('•')[0].trim()
					const curr_field_name = curr_field

					curr_field = undefined

					comp = comp.split('•')[1].trim()

					composite_fields.push({ comp, field, curr_field, curr_field_name, doctype, param })

				} else {

					const [param, field] = this.get_params(el)
					const meta_field = meta_fields.find(el => el.fieldname == field)

					const doctype = meta_field?.options
					const value = __('undefined')
					const name = value

					simple_fields.push({ field, doctype, value, param, name })
				}
			})

			// Get document fields
			const get_document_fields = await frappe.call("silicon_ioi.utils.lib.system.get_highlight_html_document_fields", { simple_fields, linked_fields, composite_fields, 'doctype': this.doctype, 'name': name_from_data_list }).then(r => r.message)

			simple_fields = get_document_fields.simple_fields
			linked_fields = get_document_fields.linked_fields
			composite_fields = get_document_fields.composite_fields

			// Format text for simple fields
			simple_fields.forEach(el => {
				set_params_and_format(el)
			});

			// Format text for linked and composites fields
			if (linked_fields.length || composite_fields.length) {
				const r = await frappe.call("silicon_ioi.utils.lib.system.get_highlight_content_fields", {
					linked_fields: linked_fields,
					composite_fields: composite_fields,
				})

				const result = r.message

				if (result) {
					if (result.linked_fields) result.linked_fields.forEach(el => set_params_and_format(el))
					if (result.composite_fields) result.composite_fields.forEach(el => set_params_and_format(el))
				}
			}

			// Format text for left undefined values
			const last_matches = modified_text.match(regex);
			if (last_matches) last_matches.map(el => modified_text = modified_text.replace(el, __('undefined')))

			return modified_text;
		}

		return text;
	}

	query_grid_link_formatter(cell, query) {
		let value = cell.getValue();
	
		if (value && value !== "") {
			const doctype_match = query.match(/FROM `tab(\w+)` WHERE/);
			const doctype = doctype_match ? doctype_match[1] : null;
			const url = doctype ? `/app/${doctype.toLowerCase().replaceAll(' ', '-')}/${encodeURIComponent(value)}` : "#";

			return `<a class="text-truncate" href="${url}" target="_blank">${value}</a>`;
		} else {
			return value;
		}
	}

	async build_query_grid(item, name_from_data_list) {
		const query = item.sql_for_query_grid
		const regex = /\{([^}]+)\}/g
		const matches = query.match(regex);
		let error = false
		let me = this

		if (matches) {
			let fields = []
			let modified_query = query;

			const set_params_and_format = (el) => {
				let value = el.new_el
				let field_to_replace = `{${value}}`

				if (el.param && el.param !== "") {
					if (el.param === "[COLLAPSED]") {
						field_to_replace = `{${value}[COLLAPSED]}`
					}
				}

				return modified_query = modified_query.replace(field_to_replace, `'${el.value}'`);
			}

			matches.forEach(el => {
				// Remove {}
				el = el.replace(/\{|\}/g, '')

				const [param, new_el] = this.get_params(el)
				fields.push({ new_el, param })
			})

			const get_document_fields = await frappe.call("silicon_ioi.utils.lib.system.get_highlight_query_document_fields", { fields, 'doctype': this.doctype, 'name': name_from_data_list }).then(r => r.message)

			fields = get_document_fields.fields

			fields.forEach(el => {
				if (el.value == __('undefined')) {
					error = true
					return $(`#${this.formatted_dt_name}_list_highlight_query_grid_${item.display_order}`).parent().html(`<div class="d-flex justify-content-center align-items-center h-100 text-danger text-center">${__('Error : Field {0} is undefined', [el.new_el])}</div>`)
				} else {
					set_params_and_format(el)
				}
			})

			if (error) {
				return;
			}

			frappe.call("silicon_ioi.utils.lib.system.get_highlight_content_query", {
				query: modified_query,
				limit: item.limited_to
			}).then(r => {

				if (r.message.length && $(`#${this.formatted_dt_name}_list_highlight_query_grid_${item.display_order}`).length) {
					const select = modified_query.match(/SELECT(.*?)FROM/i)
					let columns = []

					const saved_column_sizes = item.query_column_sizes && item.query_column_sizes
						.split(';')
						.filter(el => el)
						.map(el => {
							const array = el.split(':')
							return { [array[0]]: array[1] }

						})

					const saved_column_params = item.query_column_params && item.query_column_params
						.split(';')
						.filter(el => el)
						.map(el => {
							const array = el.split(':')
							return { [array[0]]: array[1] }
						})

					if (select && select.length > 1) {
						const select_array = select[1].split(',')

						if (item.collapsible_only_for_query_grid) columns.push({ formatter: "responsiveCollapse", headerSort: false })

						select_array.forEach(el => {
							const is_size_saved = saved_column_sizes && saved_column_sizes.find(col => Object.keys(col)[0] == el.trim())
							const saved_sizes = is_size_saved ? Object.values(is_size_saved)[0] : ""

							const is_params_saved = saved_column_params && saved_column_params.find(col => Object.keys(col)[0] == el.trim())
							const saved_params = is_params_saved ? Object.values(is_params_saved)[0] : ""

							const field = el.trim()
							const title = field.charAt(0).toUpperCase() + field.slice(1)

							let column = { title: __(title.replaceAll('_', ' ')), field: field, width: saved_sizes && saved_sizes != 0 ? `${saved_sizes}%` : "" }

							if (saved_params) {
								column = {
									...column, formatter: function (cell) {
										return me.query_grid_link_formatter(cell, modified_query);
									}
								}
							}

							columns.push(column)
						})
					}

					let table = new ioi.Tabulator(`#${this.formatted_dt_name}_list_highlight_query_grid_${item.display_order}`, {
						maxHeight: `${item.card_height - 100}px`,
						data: r.message,
						layout: saved_column_sizes ? "fitData" : "fitColumns",
						columns: columns,
						responsiveLayout: item.collapsible_only_for_query_grid ? "collapse" : false,
						columnDefaults: {
							minWidth: item.collapsible_only_for_query_grid ? 100 : '100%',
						},
					});
				}
			})
		}
	}

	async build_child_grid_content(item, name_from_data_list) {

		const parent = $(`#${this.formatted_dt_name}_list_highlight_child_grid_${item.display_order}`)
		const meta_fields = await frappe.call("silicon_ioi.utils.lib.system.get_meta_for_side_view", { doctype: this.doctype }).then(r => r.message.fields.filter(el => el.fieldtype == 'Table'))

		// /!\ If update here, update in ioi Module too
		const bypassed_doctypes = [`${this.doctype} Analysis`, `${this.doctype} Error Log`]

		let options = meta_fields.map(el => el.options)
		options = options.filter(opt => !bypassed_doctypes.includes(opt))

		// Build select
		const select = frappe.ui.form.make_control({
			parent: parent,
			df: {
				label: __('Select a child table'),
				fieldname: 'select_child_grid',
				fieldtype: 'Select',
				options: options,
				onchange: async (e) => {
					await this.build_child_grid(parent, e.currentTarget.value, name_from_data_list)
				},
			},
			render_input: true
		})

		select.$input.val(item.default_child_value || options[0])
		select.$wrapper.addClass('w-75')

		if (options.length) {
			await this.build_child_grid(parent, options[0], name_from_data_list)
		}
	}

	async build_child_grid(parent, value, name_from_data_list) {

		if (parent.find('[data-fieldtype="Table"]').length) {
			parent.find('[data-fieldtype="Table"]').remove()
		}

		const table_data = await frappe.call("silicon_ioi.utils.lib.system.get_child_table_data", { doctype: value, name: name_from_data_list }).then(r => r.message)
		let fields = table_data[0].map(el => ({'fieldname': el.fieldname, 'label': __(el.label), 'fieldtype': el.fieldtype, 'in_list_view': 1, 'colsize': 1, 'read_only': 1}))
		const data = table_data[1]

		// Build table
		const table = frappe.ui.form.make_control({
			parent: parent,
			df: {
				fieldname: 'child_table_side_view',
				fieldtype: 'Table',
				fields: fields,
				read_only: 1,
				data: data
			},
			render_input: true
		})

		// Disable adding rows to the child table
		table.grid.cannot_add_rows = true;

		// Use jQuery to hide various action buttons in the child table
		table.grid.wrapper.find('.grid-add-row').hide();			// Hide "Add Row" button
		table.grid.wrapper.find('.grid-remove-rows').hide();		// Hide "Remove Rows" button
		table.grid.wrapper.find('.grid-remove-all-rows').hide();	// Hide "Remove All Rows" button

		// Hide row-level checkboxes in the child table grid
		table.grid.wrapper.find('.grid-row-check').each(function () {
			$(this).prop('disabled', true);
		});

		const static_area_rows = parent.find(".rows .static-area")
		const static_area_header = parent.find(".grid-heading-row .static-area")

		static_area_rows.removeClass('ellipsis')
		static_area_header.removeClass('ellipsis')

		static_area_rows.each((i, el) => {
			$(el).closest('.grid-row').find('.col').css('height', 'inherit')
		})

		$(static_area_header[0]).closest('.grid-heading-row').css('height', 'inherit')

		static_area_header.each((i, el) => {
			$(el).closest('.grid-row').find('.col').css('height', 'inherit')
		})
	}
};
