// *******************************************************************************************************************************************
// Parent Class of all Stock Documents (Transfer)
// *******************************************************************************************************************************************

import { ioiDocumentStatus } from "./ioi_document_status";

frappe.provide('silicon_ioi.doctype');

export class ioiProductionDocumentDocType extends silicon_ioi.doctype.ioiDocType
{

	path_general_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings';
	path_module_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status';
	path_module_user_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_user_status.ioi_module_user_status';
	path_module_status_accessible_fields = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status_accessible_fields.ioi_module_status_accessible_fields';
	path_document_change_status = 'silicon_ioi.common.document_change_status';
    path_currency = 'silicon_ioi.ioi_configuration.doctype.ioi_currency.ioi_currency';
    path_production_journal = 'silicon_ioi.ioi_production.doctype.ioi_production_journal.ioi_production_journal';
	path_production = 'silicon_ioi.ioi_production.doctype.ioi_production.ioi_production';
	path_production_qty_log = 'silicon_ioi.ioi_production.doctype.ioi_production_qty_log.ioi_production_qty_log';
	path_item = 'silicon_ioi.ioi_items.doctype.ioi_item.ioi_item';
	path_search_definition = 'silicon_ioi.ioi_configuration.doctype.ioi_module_search_definition.ioi_module_search_definition'
	path_item_unit = 'silicon_ioi.ioi_configuration.doctype.ioi_item_unit.ioi_item_unit';
	path_workcenter = 'silicon_ioi.ioi_enterprise.doctype.ioi_workcenter.ioi_workcenter';
	path_workcenter_budget = 'silicon_ioi.ioi_enterprise.doctype.ioi_workcenter_budget.ioi_workcenter_budget';
	path_line_machine = 'silicon_ioi.ioi_enterprise.doctype.ioi_line_machine.ioi_line_machine';
	path_item_manufacturing_definition = 'silicon_ioi.ioi_items.doctype.ioi_item_manufacturing_definition.ioi_item_manufacturing_definition';
	path_staff_skill = 'silicon_ioi.ioi_enterprise.doctype.ioi_staff_skill.ioi_staff_skill';
	path_time_vector = 'silicon_ioi.ioi_system.doctype.ioi_time_vector.ioi_time_vector';
	path_item_budget = 'silicon_ioi.ioi_configuration.doctype.ioi_item_budget.ioi_item_budget';

	path_stock_document = 'silicon_ioi.common.stock_document';

	path_period = 'silicon_ioi.ioi_enterprise.doctype.ioi_period.ioi_period';
    path_dossier = 'silicon_ioi.ioi_trakker.doctype.ioi_dossier.ioi_dossier';
    path_warehouse = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse.ioi_warehouse';
	path_warehouse_location = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_location.ioi_warehouse_location';
	path_user_change_status_action = 'silicon_ioi.ioi_system.doctype.ioi_user_change_status_action.ioi_user_change_status_action';

	is_dark_mode = 0;

	user_language = '';
	language_1 = '';
	language_2 = '';
	language_3 = '';
	language_4 = '';

	system_currency = '';
	system_currency_rate = 1;
	system_currency_rate_inv = 1;
	system_currency_digit_rounding = 8;



    journal_division_id = '';
    journal_site_id = '';

    // Production
    journal_production_prefix = '';
    journal_production_order_last_number = 0;
    journal_production_order_nb_digits = 0;
    journal_production_sublevel_separator = '.';
    journal_production_sublevel_nb_digits = 2;
    journal_production_output_warehouse_id = '';
    journal_production_entry_warehouse_id = '';
    journal_production_allowed = 0;
    journal_production_structured_sub_production = 0;
    journal_production_bad_qty_in_goal = 0;
    journal_production_disable_production = 0;
	journal_production_allow_direct_registry = 0;
    journal_production_role_limit = 0;

    // Sub Contracting
    journal_sub_contracting_prefix = '';
    journal_sub_contracting_order_last_number = 0;
    journal_sub_contracting_order_nb_digits = 0;
    journal_sub_contracting_sublevel_separator = '.';
    journal_sub_contracting_sublevel_nb_digits = 2;
    journal_sub_contracting_allowed = 0;
    journal_sub_contracting_structured_sub_contracting = 0;
    journal_sub_contracting_bad_qty_in_goal = 0;
    journal_sub_contracting_disable_sub_contracting = 0;
	journal_sub_contracting_allow_direct_registry = 0;
    journal_sub_contracting_role_limit = 0;

    // Production Request
    journal_production_request_prefix = '';
    journal_production_request_last_number = 0;
    journal_production_request_nb_digits = 0;
    journal_production_request_sublevel_separator = '.';
    journal_production_request_sublevel_nb_digits = 2;
    journal_production_request_output_warehouse_id = '';
    journal_production_request_entry_warehouse_id = '';
    journal_production_request_allowed = 0;
    journal_production_request_structured_sub_production = 0;
    journal_production_request_bad_qty_in_goal = 0;
    journal_production_request_disable_production = 0;
    journal_production_request_role_limit = 0;

    // Sub contracting request
    journal_sub_contracting_request_prefix = '';
    journal_sub_contracting_request_last_number = 0;
    journal_sub_contracting_request_nb_digits = 0;
    journal_sub_contracting_request_sublevel_separator = '.';
    journal_sub_contracting_request_sublevel_nb_digits = 0;
    journal_sub_contracting_request_allowed = 0;
    journal_sub_contracting_request_structured_sub_contracting = 0;
    journal_sub_contracting_request_bad_qty_in_goal = 0;
    journal_sub_contracting_request_disable_sub_contracting = 0;
    journal_sub_contracting_request_role_limit = 0;

	DocumentStatus = Object;
    ioistatus = [];
	ioiuserstatus = [];


    prefixes = [];

	available_status = [];
	available_scheduled_status = [];

	static warehouse_location = [];
	static batch_id = [];


	// ***************************************************************************************************************************************
	// Constructor
	// ***************************************************************************************************************************************
	constructor(frm)
	{
		super(frm);

		let me = this;
		this.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		this.DocumentStatus = new ioiDocumentStatus(frm);
		this.DocumentStatus.fill_status();
		this.ioistatus = this.DocumentStatus.ioistatus;

		setTimeout(me.grid_bom_layout(), 500);
		setTimeout(me.grid_routing_layout(), 500);
	}

	// ***************************************************************************************************************************************
	// Can access to the module
	// ***************************************************************************************************************************************

	can_access_to_document(document_type, prefix_id)
	{
		let can_access = false;

		let me = this;

		let method = this.path_production + '.ioi_production_can_access_to_document';

		frappe.call({  	method: method,
						args: {	"document_type": document_type,
								"prefix_id": prefix_id},
						async: false,
						callback:function(r)	{

							if (r.message == 1) {
								can_access = true;
							}

						}
		});

		return can_access;
	}


	// ***************************************************************************************************************************************
	// Load General Settings
	// ***************************************************************************************************************************************

	load_general_settings()
	{
		let me = this;

		let method = this.path_general_settings + '.ioi_general_settings_get_data';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
													me.system_currency = r.message.currency;

													if (me.system_currency.trim() != '')
													{
														method = me.path_currency + '.ioi_currency_get_data';

														frappe.call({  	method: method,
																		args: {"currency_id": me.system_currency},
																		async: false,
																		callback:function(r)	{
                                                                                                    me.language_1 = r.message.language_1;
                                                                                                    me.language_2 = r.message.language_2;
                                                                                                    me.language_3 = r.message.language_3;
                                                                                                    me.language_4 = r.message.language_4;
																									me.system_currency_rate = r.message.sales_rate;
																									me.system_currency_rate_inv = r.message.sales_rate_inv;
																									me.system_currency_digit_rounding = r.message.decimals;
																								}
														});
													}
						}
		});
	}

	// ***************************************************************************************************************************************
	// Load Production Journal data
	// ***************************************************************************************************************************************

	load_production_journal_data()
	{
		if ((this.frm.doc.journal_id) && (this.frm.doc.journal_id.trim() != ''))
		{
			let me = this;

			let method = this.path_production_journal + '.ioi_production_journal_get_data';

			frappe.call({  	method: method,
							args: { "journal_id": this.frm.doc.journal_id},
							async: false,
							callback:function(r)	{

								me.user_language = r.message.user_language;

								me.journal_division_id = r.message.division_id;
								me.journal_site_id = r.message.site_id;

								// Production
								me.journal_production_prefix = r.message.production_prefix;
                                me.journal_production_order_last_number = r.message.production_order_last_number;
                                me.journal_production_order_nb_digits = r.message.production_order_nb_digits;
                                me.journal_production_sublevel_separator = r.message.production_sublevel_separator;
                                me.journal_production_sublevel_nb_digits = r.message.production_sublevel_nb_digits;
                                me.journal_production_output_warehouse_id = r.message.production_output_warehouse_id;
                                me.journal_production_entry_warehouse_id = r.message.production_entry_warehouse_id;
                                me.journal_production_allowed = r.message.production_allowed;
                                me.journal_production_structured_sub_production = r.message.production_structured_sub_production;
                                me.journal_production_bad_qty_in_goal = r.message.production_bad_qty_in_goal;
                                me.journal_production_disable_production = r.message.production_disable_production;
								me.journal_production_allow_direct_registry = r.message.production_allow_direct_registry;
                                me.journal_production_role_limit = r.message.production_role_limit;

                                // Sub Contracting
                                me.journal_sub_contracting_prefix = r.message.sub_contracting_prefix;
                                me.journal_sub_contracting_order_last_number = r.message.sub_contracting_order_last_number;
                                me.journal_sub_contracting_order_nb_digits = r.message.sub_contracting_order_nb_digits;
                                me.journal_sub_contracting_sublevel_separator = r.message.sub_contracting_sublevel_separator;
                                me.journal_sub_contracting_sublevel_nb_digits = r.message.sub_contracting_sublevel_nb_digits;
                                me.journal_sub_contracting_allowed = r.message.sub_contracting_allowed;
                                me.journal_sub_contracting_structured_sub_contracting = r.message.sub_contracting_structured_sub_contracting;
                                me.journal_sub_contracting_bad_qty_in_goal = r.message.sub_contracting_bad_qty_in_goal;
                                me.journal_sub_contracting_disable_sub_contracting = r.message.sub_contracting_disable_sub_contracting;
								me.journal_sub_contracting_allow_direct_registry = r.message.sub_contracting_allow_direct_registry;
                                me.journal_sub_contracting_role_limit = r.message.sub_contracting_role_limit;

                                // Production Request
                                me.journal_production_request_prefix = r.message.production_request_prefix;
                                me.journal_production_request_last_number = r.message.production_request_last_number;
                                me.journal_production_request_nb_digits = r.message.production_request_nb_digits;
                                me.journal_production_request_sublevel_separator = r.message.production_request_sublevel_separator;
                                me.journal_production_request_sublevel_nb_digits = r.message.production_request_sublevel_nb_digits;
                                me.journal_production_request_output_warehouse_id = r.message.production_request_output_warehouse_id;
                                me.journal_production_request_entry_warehouse_id = r.message.production_request_entry_warehouse_id;
                                me.journal_production_request_allowed = r.message.production_request_allowed;
                                me.journal_production_request_structured_sub_production = r.message.production_request_structured_sub_production;
                                me.journal_production_request_bad_qty_in_goal = r.message.production_request_bad_qty_in_goal;
                                me.journal_production_request_disable_production = r.message.production_request_disable_production;
                                me.journal_production_request_role_limit = r.message.production_request_role_limit;

                                // Sub contracting request
                                me.journal_sub_contracting_request_prefix = r.message.sub_contracting_request_prefix;
                                me.journal_sub_contracting_request_last_number = r.message.sub_contracting_request_last_number;
                                me.journal_sub_contracting_request_nb_digits = r.message.sub_contracting_request_nb_digits;
                                me.journal_sub_contracting_request_sublevel_separator = r.message.sub_contracting_request_sublevel_separator;
                                me.journal_sub_contracting_request_sublevel_nb_digits = r.message.sub_contracting_request_sublevel_nb_digits;
                                me.journal_sub_contracting_request_allowed = r.message.sub_contracting_request_allowed;
                                me.journal_sub_contracting_request_structured_sub_contracting = r.message.sub_contracting_request_structured_sub_contracting;
                                me.journal_sub_contracting_request_bad_qty_in_goal = r.message.sub_contracting_request_bad_qty_in_goal;
                                me.journal_sub_contracting_request_disable_sub_contracting = r.message.sub_contracting_request_disable_sub_contracting;
                                me.journal_sub_contracting_request_role_limit = r.message.sub_contracting_request_role_limit;

							}
			});
		}
	}

	// ***************************************************************************************************************************************
	// Load User Status
	// ***************************************************************************************************************************************

	fill_ioiuserstatus()
	{
		this.ioiuserstatus = [];

		let me = this;

		let method = this.path_module_user_status + '.ioi_module_user_status_get_list';

		frappe.call({  	method: method,
						args: {"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (r.message.length != 0)
													{
														me.ioiuserstatus = [];

														for (var i = 0; i < r.message.length; i++)
														{	//                    code            description       bgcolor          fontcolor
															me.ioiuserstatus[i] = [r.message[i][2],r.message[i][5], r.message[i][3], r.message[i][4]];

														}
													}

													if (me.ioiuserstatus.length == 0)
													{	me.ioiuserstatus[0] = [0, 'Undefined', '#C3C2C4', '#000000'];
													}else
													{
														let found_zero = false;

														for (var i = 0; i < me.ioiuserstatus.length; i++)
														{
															if (me.ioiuserstatus[i][0] == 0)
															{
																found_zero = true;
																break;
															}
														}

														if (!found_zero)
														{	me.ioiuserstatus[me.ioiuserstatus.length] = [0, 'Undefined', '#C3C2C4', '#000000'];
														}
													}
												}
		});
	}

	// ***************************************************************************************************************************************
	// Fill Document type
	// ***************************************************************************************************************************************

	fill_document_type()
	{

		this.frm.set_df_property('document_type', 'options', []);

		let method = this.path_production_journal + '.ioi_production_journal_get_document_types';

		let options = '';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{

							if (r.message.length > 0) {
								options = '[';

								for (var i = 0; i < r.message.length; i++) {

									options += '{ "label": "' + r.message[i].document_type_desc + '", "value": "' + r.message[i].document_type + '"}';

									if (i < r.message.length-1) {
										options += ',';
									}

								}

								options += ']';

							}else{
								options = '[]';
							}

					}
		});


		this.frm.set_df_property('document_type', 'options', JSON.parse(options));
		this.frm.refresh_field('document_type');
	}

	// ***************************************************************************************************************************************
	// Assign query to Link Fields
	// ***************************************************************************************************************************************

    assign_query_to_link_fields()
    {
		let me = this;

		this.frm.set_df_property('scheduling_pivot', 'options', [	{ label: __("At the latest"), value: 0},
																	{ label: __("At the soonest"), value: 1}
		]);

		this.frm.set_df_property('scheduling_mode', 'options', [	{ label: __("Infinite capacity"), value: 0},
																	{ label: __("Finite capacity"), value: 1},
																	{ label: __("Single delay"), value: 2}
		]);

		this.set_query('period_id', '', this.path_production + '.ioi_production_get_enabled_period', '{"division":"' + this.frm.doc.division_id + '"}');

		let fct_search_period = function() {

			let title = __("Select a period");
			let form_width_pixel = '600px';
			let table = "ioi Period";
			let fields = 'name';
			let fields_len = '500';
			let fields_desc = __('Period');
			let where = "division = '" + me.frm.doc.division_id.replaceAll("'", "''") + "' and enabled = 1 ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('period_id', return_value);
				me.frm.refresh_field('period_id');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['period_id'].open_advanced_search = fct_search_period;

		this.set_query('entry_warehouse_id', '', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');
		this.set_query('output_warehouse_id', '', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');

		// *********************************************************************************************************************************************
		// BOM detail
		// *********************************************************************************************************************************************

		this.set_query('component_id', 'bom_detail', this.path_search_definition + '.set_search_definition_filters');
		this.set_query('warehouse_id', 'bom_detail', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');
		this.set_query('item_budget_id', 'bom_detail', this.path_item_budget + '.ioi_item_budget_get_enabled', '');



		frappe.meta.get_docfield('ioi Production BOM', 'mode').options = [
			{ label: __('Purchased'),		value: 0},
			{ label: __('Manufactured'),	value: 1}
		];

		this.frm.fields_dict['bom_detail'].grid.update_docfield_property("mode", "options", [
			{ label: __('Purchased'),		value: 0},
			{ label: __('Manufactured'),	value: 1}
		]);


		frappe.meta.get_docfield('ioi Production BOM', 'item_kind').options = [
			{ label: __('Normal'),		value: 0},
			{ label: __('Consumable'),	value: 1},
			{ label: __('Packing'),		value: 2},
			{ label: __('Subproduct'),	value: 3},
			{ label: __('Waste'),		value: 4},
		];

		this.frm.fields_dict['bom_detail'].grid.update_docfield_property("item_kind", "options", [
			{ label: __('Normal'),		value: 0},
			{ label: __('Consumable'),	value: 1},
			{ label: __('Packing'),		value: 2},
			{ label: __('Subproduct'),	value: 3},
			{ label: __('Waste'),		value: 4},
		]);


		frappe.meta.get_docfield('ioi Production BOM', 'stored_qty_mode').options = [
			{ label: __('Good'),		value: 0},
			{ label: __('To check'),	value: 1},
			{ label: __('Bad only'),	value: 2}
		];

		this.frm.fields_dict['bom_detail'].grid.update_docfield_property("stored_qty_mode", "options", [
			{ label: __('Good'),		value: 0},
			{ label: __('To check'),	value: 1},
			{ label: __('Bad only'),	value: 2}
		]);



		// *********************************************************************************************************************************************
		// Routing detail
		// *********************************************************************************************************************************************

		this.set_query('workcenter_id', 'routing_detail', this.path_workcenter + '.ioi_workcenter_get_enabled');
		this.set_query('workcenter_budget_id', 'routing_detail', this.path_workcenter_budget + '.ioi_workcenter_budget_get_enabled', '');
		this.set_query('line_machine_id', 'routing_detail', this.path_line_machine + '.ioi_line_machine_get_enabled');
		this.set_query('staff_skill_id', 'routing_detail', this.path_staff_skill + '.ioi_staff_skill_get_enabled');
		this.set_query('unit_id', 'routing_detail', this.path_item_unit + '.ioi_item_unit_get_enabled');


		frappe.meta.get_docfield('ioi Production Routing', 'workcenter_unit_time_def').options = [
			{ label: __('Duration'),	value: 0},
			{ label: __('Rate'),		value: 1}
		];

		this.frm.fields_dict['routing_detail'].grid.update_docfield_property("workcenter_unit_time_def", "options", [
			{ label: __('Duration'),	value: 0},
			{ label: __('Rate'),		value: 1}
		]);


		frappe.meta.get_docfield('ioi Production Routing', 'worker_unit_time_def').options = [
			{ label: __('Duration'),	value: 0},
			{ label: __('Rate'),		value: 1}
		];

		this.frm.fields_dict['routing_detail'].grid.update_docfield_property("worker_unit_time_def", "options", [
			{ label: __('Duration'),	value: 0},
			{ label: __('Rate'),		value: 1}
		]);


		frappe.meta.get_docfield('ioi Production Routing', 'line_mode').options = [
			{ label: __('Undefined'),	value: 0},
			{ label: __('Required'),	value: 1},
			{ label: __('Recommended'),	value: 2}
		];

		this.frm.fields_dict['routing_detail'].grid.update_docfield_property("line_mode", "options", [
			{ label: __('Undefined'),	value: 0},
			{ label: __('Required'),	value: 1},
			{ label: __('Recommended'),	value: 2}
		]);


		frappe.meta.get_docfield('ioi Production Routing', 'before_execution_mode').options = [
			{ label: __('Calendar'),	value: 0},
			{ label: __('Open hours'),	value: 1}
		];

		this.frm.fields_dict['routing_detail'].grid.update_docfield_property("before_execution_mode", "options", [
			{ label: __('Calendar'),	value: 0},
			{ label: __('Open hours'),	value: 1}
		]);


		frappe.meta.get_docfield('ioi Production Routing', 'after_execution_mode').options = [
			{ label: __('Calendar'),	value: 0},
			{ label: __('Open hours'),	value: 1}
		];

		this.frm.fields_dict['routing_detail'].grid.update_docfield_property("after_execution_mode", "options", [
			{ label: __('Calendar'),	value: 0},
			{ label: __('Open hours'),	value: 1}
		]);


		frappe.meta.get_docfield('ioi Production Routing', 'scheduling_update').options = [
			{ label: __('Realized time'),	value: 0},
			{ label: __('Realized quantity'),	value: 1},
			{ label: __('Manual Completed'),	value: 2}
		];

		this.frm.fields_dict['routing_detail'].grid.update_docfield_property("scheduling_update", "options", [
			{ label: __('Realized time'),	value: 0},
			{ label: __('Realized quantity'),	value: 1},
			{ label: __('Manual Completed'),	value: 2}
		]);


		frappe.meta.get_docfield('ioi Production Routing', 'subcontractor_flow').options = [
			{ label: __('No transaction'),		value: 0},
			{ label: __('Receive only'),		value: 1},
			{ label: __('Send Receive'),		value: 2},
			{ label: __('Order/Send/Receive'),	value: 3}
		];

		this.frm.fields_dict['routing_detail'].grid.update_docfield_property("subcontractor_flow", "options", [
			{ label: __('No transaction'),		value: 0},
			{ label: __('Receive only'),		value: 1},
			{ label: __('Send Receive'),		value: 2},
			{ label: __('Order/Send/Receive'),	value: 3}
		]);


		frappe.meta.get_docfield('ioi Production Routing', 'start_mode').options = [
			{ label: __('Scheduled'),	value: 0},
			{ label: __('Adjust fix'),	value: 1},
			{ label: __('Hard fix'),	value: 2}
		];

		this.frm.fields_dict['routing_detail'].grid.update_docfield_property("start_mode", "options", [
			{ label: __('Scheduled'),	value: 0},
			{ label: __('Adjust fix'),	value: 1},
			{ label: __('Hard fix'),	value: 2}
		]);


		frappe.meta.get_docfield('ioi Production Routing', 'end_mode').options = [
			{ label: __('Scheduled'),	value: 0},
			{ label: __('Adjust fix'),	value: 1},
			{ label: __('Hard fix'),	value: 2}
		];

		this.frm.fields_dict['routing_detail'].grid.update_docfield_property("end_mode", "options", [
			{ label: __('Scheduled'),	value: 0},
			{ label: __('Adjust fix'),	value: 1},
			{ label: __('Hard fix'),	value: 2}
		]);


		frappe.meta.get_docfield('ioi Production Routing', 'header_scheduling_pivot').options = [
			{ label: __('At the latest'),	value: 0},
			{ label: __('At the soonest'),	value: 1}
		];

		this.frm.fields_dict['routing_detail'].grid.update_docfield_property("header_scheduling_pivot", "options", [
			{ label: __('At the latest'),	value: 0},
			{ label: __('At the soonest'),	value: 1}
		]);


	}


	// ***************************************************************************************************************************************
	// Set query
	// ***************************************************************************************************************************************

	set_query(fieldname, childfieldname, method, filter = '')
	{
		if (childfieldname.trim() == '')
		{
			if (filter.trim() != '')
			{
				this.frm.set_query(fieldname, () => {
					return  {   query: method,
								filters : JSON.parse(filter)
							}
				});
			}else
			{
				this.frm.set_query(fieldname, () => {
					return  {   query: method
							}
				});

			}
		}else
		{
			if (filter.trim() != '')
			{

				this.frm.set_query(fieldname, childfieldname, () => {
					return  {   query: method,
								filters: JSON.parse(filter)
							}
				});

			}else
			{
				this.frm.set_query(fieldname, childfieldname, () => {
					return  {   query: method
							}
				});

			}
		}
	}

	// ***************************************************************************************************************************************
	// Refresh ioiuserstatus (User Status)
	// ***************************************************************************************************************************************

	refresh_ioiuserstatus()
	{
		let html = '';
		this.frm.fields_dict['html_ioiuserstatus'].$wrapper.empty();


		let bgcolor = '';
		let color = '';
		let description = '';

		let cur_userstatus = 0;

		if (this.frm.doc.ioiuserstatus)
		{	cur_userstatus = this.frm.doc.ioiuserstatus;
		}

		for (var i = 0; i < this.ioiuserstatus.length; i++)
		{

			if (cur_userstatus == this.ioiuserstatus[i][0])
			{
				description = this.ioiuserstatus[i][1];
				bgcolor = this.ioiuserstatus[i][2];
				color = this.ioiuserstatus[i][3];
				break;
			}
		}


		html = '<table width=100%>';
		html += '<tr>';
		html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("User status") +'</font></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100%>';
		html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
		html += '</div></td>';

		html += '</tr>';

		html += '<tr>';
		html += '<td width=100% height=7px>&nbsp;</td>';
		html += '</tr>';

		html += '</table>';

		this.frm.fields_dict['html_ioiuserstatus'].$wrapper.append(html);

	}

	// ***************************************************************************************************************************************
	// Refresh Manufacturing release status
	// ***************************************************************************************************************************************

	refresh_manuf_release_status()
	{
		let html = '';
		this.frm.fields_dict['html_manuf_release_status'].$wrapper.empty();


			let bgcolor = '';
			let color = '#000000';
			let description = '';

		if ((this.frm.doc.manufactured_revision_id) && (this.frm.doc.manufactured_revision_id.trim() != '')) {

			let me = this;

			let method = this.path_item_manufacturing_definition + '.ioi_item_manufacturing_definition_get_status';

			frappe.call({  	method: method,
							args: {	"name": this.frm.doc.manufactured_revision_id,
							},
							async: false,
							callback:function(r)	{

								if (r.message.length > 0) {

									description = r.message[0].ioistatus_description;
									bgcolor = r.message[0].ioistatus_bgcolor;
								}
							}
			});
		}


		html = '<table width=100%>';
		html += '<tr>';
		html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Manuf. release status") +'</font></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100%>';
		html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
		html += '</div></td>';

		html += '</tr>';

		html += '<tr>';
		html += '<td width=100% height=7px>&nbsp;</td>';
		html += '</tr>';

		html += '</table>';

		this.frm.fields_dict['html_manuf_release_status'].$wrapper.append(html);

	}

	// ***************************************************************************************************************************************
	// Refresh Prefixes
	// ***************************************************************************************************************************************
	refresh_prefixes()
	{
		this.fill_prefixes();
	}

	// ***************************************************************************************************************************************
	// Fill Prefixes
	// ***************************************************************************************************************************************

	fill_prefixes()
	{
		let me = this;

		let amethod = '';

		if (this.frm.is_new()) {
			if ((this.frm.doc.level_tp != 1) && (this.frm.doc.level_tp != 2)) {
				this.frm.doc.prefix_id = '';
				this.frm.doc.id_prefix = '';
				this.frm.doc.id_number = 0;
				this.frm.doc.identification = '?';
				this.frm.doc.allow_direct_registry = 0;
			}
		}


		this.frm.refresh_field('journal_id');
		this.frm.refresh_field('prefix_id');
		this.frm.refresh_field('id_prefix');
		this.frm.refresh_field('id_number');
		this.frm.refresh_field('identification');
		this.frm.refresh_field('allow_direct_registry');

		this.load_production_journal_data();

        amethod = this.path_production_journal + '.ioi_production_journal_get_prefixes';

	    let tp = this.frm.doc.document_type;

		let prefix_enabled = 0

		if (this.frm.is_new())
		{	prefix_enabled = 1
		}

		frappe.call({  	method: amethod,
					   	args: {"enabled":1, "prefix_enabled" : prefix_enabled, "tp":tp, "current_prefix": this.current_prefix},
						async: false,
						callback:function(r){
												let options = '[';

                                                me.prefixes = [];

												for (var i = 0; i < r.message.length; i++)
												{

													me.prefixes[i] = [r.message[i][0], r.message[i][1], r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8]];
													options += '{"label": "' + me.prefixes[i][1] + '", "value": "' + me.prefixes[i][1] + '"}';

													if (i != r.message.length-1)
													{	options += ',';
													}
												}


												options += ']';

												me.frm.set_df_property('prefix_id', 'options', JSON.parse(options));
												me.frm.refresh_field("prefix_id");

                                                if (me.frm.is_new())
                                                {
													if (me.prefixes.length > 0)
													{

														let mtd = 'silicon_ioi.ioi_system.doctype.ioi_user_module_params.ioi_user_module_params.ioi_user_module_params_get_prefix';

														let selected_pref = ''

														frappe.call({
															method: mtd,
															args: {"doctype":  me.frm.doctype},
															async: false,
															callback:function(r){
																selected_pref = r.message;

																if (!selected_pref) {
																	selected_pref = '';
																}
															}
														});


														let found = false;

														for (var w = 0; w < me.prefixes.length; w++) {

															if (me.prefixes[w][1].toUpperCase() == selected_pref.toUpperCase()) {
																found = true;
																break;
															}
														}

														if (!found) {
															me.frm.set_value('prefix_id', me.prefixes[0][1]);
															me.frm.set_value('allow_direct_registry', me.prefixes[0][8]);
														}else{
															me.frm.set_value('prefix_id', selected_pref);

															for (var w = 0; w < me.prefixes.length; w++) {

																if (me.prefixes[w][1].toUpperCase() == selected_pref.toUpperCase()) {
																	me.frm.set_value('allow_direct_registry', me.prefixes[w][8]);
																	break;
																}
															}
														}
													}
                                                }

											}
		});


	}


	// ***************************************************************************************************************************************
	// Load items buttons
	// ***************************************************************************************************************************************

	load_items_buttons()
	{
		this.frm.fields_dict['html_production_item_buttons'].$wrapper.empty();

		let html = '';

		if (this.frm.doc.ioistatus == 0) {

			if (document.getElementById('ioi_production_item_select')) {
				document.getElementById('ioi_production_item_select').remove();
			}

			if (document.getElementById('ioi_production_item_select')) {
				document.getElementById('ioi_production_item_clear').remove();
			}

			html += '<div style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:45px;border: 0px solid #E6E9EC;">';

			// Search
			html += '<div style="position: relative; top: 2px; left: 0px; width:110px;">';
			html += '	<div style="position: absolute; top: 0px; left: 2px; height: 30px">';
			html +='		<button id="ioi_production_item_select" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Select") + '</button>';
			html += '	</div>';
			html += '</div>';

			// Clear
			html += '<div style="position: relative; top: 2px; left: 120px; width:110px;">';
			html += '	<div style="position: absolute; top: 0px; left: 2px; height: 30px;">';
			html +='		<button id="ioi_production_item_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Clear") + '</button>';
			html += '	</div>';
			html += '</div>';

			html += '</div>';


			this.frm.fields_dict['html_production_item_buttons'].$wrapper.append(html);

			let me = this;

			this.#sleep(300).then(() => {

				let fct_select = function() {

					if (cur_frm.is_new() || cur_frm.is_dirty())	{

						let fct_callback = function () { me.item_select(); };
						cur_frm.save('Save', fct_callback);
					}else {
						me.item_select();
					}

				}

				document.getElementById('ioi_production_item_select').onclick = fct_select


				let fct_clear = function() {
					me.item_clear();
				}

				document.getElementById('ioi_production_item_clear').onclick = fct_clear

			});
		}

	}


	// ***************************************************************************************************************************************
	// Sleep
	// ***************************************************************************************************************************************

	#sleep(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	// ***************************************************************************************************************************************
	// Sleep static
	// ***************************************************************************************************************************************

	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}


	// ***************************************************************************************************************************************
	// Item select
	// ***************************************************************************************************************************************

	item_select()
	{
		if (this.frm.doc.ioistatus == 0) {

			let data = {};

			let method = this.path_production + '.ioi_production_can_access_to_item_selection';

			frappe.call({  	method: method,
							args: {"name" : this.frm.doc.name},
							async: false,
							callback:function(r)	{
								data = r.message;
							}
			});

			if (data.error != 0) {
				frappe.msgprint({title: __("Message"), message: __("Cannot select item") + ' : ' + data.error_message, indicator: "red"});
				return false;
			}

			let me = this;

			var production_select_item_form = new frappe.ui.Dialog({
				'title': __("Select item & manufactured revision"),
				'fields': [
					{'fieldname': 'html_production_select_manual_item', 'fieldtype': 'HTML'}

				],
				primary_action_label: __('Generate'),
				secondary_action_label: __('Cancel'),
				primary_action: function(){

					if ((!document.getElementById('html_production_select_manual_item_entry_whs_id').value) ||
						((document.getElementById('html_production_select_manual_item_entry_whs_id').value) && (document.getElementById('html_production_select_manual_item_entry_whs_id').value.trim() == ''))) {

						frappe.msgprint({title: __("Message"), message: __("Entry warehouse is mandatory"), indicator: "red"});
						return false;
					}


					if ((!document.getElementById('html_production_select_manual_item_output_whs_id').value) ||
						((document.getElementById('html_production_select_manual_item_output_whs_id').value) && (document.getElementById('html_production_select_manual_item_output_whs_id').value.trim() == ''))) {

						frappe.msgprint({title: __("Message"), message: __("Output warehouse is mandatory"), indicator: "red"});
						return false;
					}


					if (document.getElementById('html_production_select_manual_item_scheduling_priority').value == null) {
						document.getElementById('html_production_select_manual_item_scheduling_priority').value = 0;
					}

					let already_manufactured = 0;

					if (document.getElementById('html_production_select_manual_item_already_manufactured').checked) {
						already_manufactured = 1;
					}

					let already_prepared = 0;

					if (document.getElementById('html_production_select_manual_item_already_prepared').checked) {
						already_prepared = 1;
					}

					let no_elasticity = 0;

					if (document.getElementById('html_production_select_manual_item_scheduling_no_elasticity').checked) {
						no_elasticity = 1;
					}
					if (me.frm.doc.scheduling_no_elasticity == 1) {
						document.getElementById('html_production_select_manual_item_scheduling_no_elasticity').checked = true;
					}

					let min_order_qty = 0;

					if ((document.getElementById('html_production_select_manual_item_min_order').value) && (document.getElementById('html_production_select_manual_item_min_order').value.trim() != '')) {
						min_order_qty = parseFloat(document.getElementById('html_production_select_manual_item_min_order').value);
					}

					let to_produce = 0;

					if ((document.getElementById('html_production_select_manual_item_toproduce').value) && (document.getElementById('html_production_select_manual_item_toproduce').value.trim() != '')) {
						to_produce = parseFloat(document.getElementById('html_production_select_manual_item_toproduce').value);
					}

					if (parseFloat(to_produce) < parseFloat(min_order_qty)) {
						frappe.msgprint({title: __("Message"), message: __("To produce has to be geater or equals to") + ' ' + min_order_qty.toString(), indicator: "red"});
						return false;
					}

					let method = me.path_production + '.ioi_production_get_data_for_production';

					frappe.call({  	method: method,
									args: {	"site_id" : me.frm.doc.site_id,
											"name" : me.frm.doc.name,
											"journal_id" : me.frm.doc.journal_id,
											"document_type": me.frm.doc.document_type,
											"item_id": document.getElementById('html_production_select_manual_item_item_id').value,
											"release_mode": 0,
											"manuf_release_id": document.getElementById('html_production_select_manual_item_manuf_release_id').value,
											"bom_release_id": document.getElementById('html_production_select_manual_item_bom_release_id').value,
											"routing_release_id": document.getElementById('html_production_select_manual_item_routing_release_id').value,
											"ordered_qty": document.getElementById('html_production_select_manual_item_toproduce').value,
											"multi_qty": document.getElementById('html_production_select_manual_item_multiple').value,
											"part_over_ordered_qty": 0,
											"ordered_unit_id": document.getElementById('html_production_select_manual_item_unit_id').value,
											"stock_unit_id": document.getElementById('html_production_select_manual_item_stock_unit_id').value,
											"already_manufactured": already_manufactured,
											"already_prepared": already_prepared,
											"scheduling_priority": document.getElementById('html_production_select_manual_item_scheduling_priority').value,
											"scheduling_pivot": document.getElementById('html_production_select_manual_item_scheduling_pivot').value,
											"scheduling_no_elasticity": no_elasticity,
											"scheduling_mode": document.getElementById('html_production_select_manual_item_scheduling_mode').value,
											"single_delay": document.getElementById('html_production_select_manual_item_single_delay').value,
											"structured_subproduct": 0,
											"force_qty": 0,
											"process_mode" : 1,
											"document_date" : me.frm.doc.document_date,
											"period_id" : me.frm.doc.period_id,
											"start_after": document.getElementById('html_production_select_manual_item_start_after').value,
											"delivery_date": document.getElementById('html_production_select_manual_item_to_deliver').value,
											"entry_whs_id": document.getElementById('html_production_select_manual_item_entry_whs_id').value,
											"output_whs_id": document.getElementById('html_production_select_manual_item_output_whs_id').value

											},
									async: false,
									callback:function(r)	{

										if (r.message.error == 2) {

											frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});


										}else{

											production_select_item_form.hide();
											window.location.reload();


										}
									}
					});




				},
				secondary_action: function(){
					production_select_item_form.hide();
				}
			});



			if (document.getElementById('html_production_select_manual_item_manuf_release_label')) {
				document.getElementById('html_production_select_manual_item_manuf_release_label').remove();
			}
			if (document.getElementById('html_production_select_manual_item_manuf_release_id')) {
				document.getElementById('html_production_select_manual_item_manuf_release_id').remove();
			}
			if (document.getElementById('html_production_select_manual_item_manuf_release_button')) {
				document.getElementById('html_production_select_manual_item_manuf_release_button').remove();
			}
			if (document.getElementById('html_production_select_manual_item_manuf_release_description_label')) {
				document.getElementById('html_production_select_manual_item_manuf_release_description_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_manuf_release_description')) {
				document.getElementById('html_production_select_manual_item_manuf_release_description').remove();
			}

			if (document.getElementById('html_production_select_manual_item_manuf_release_status_description')) {
				document.getElementById('html_production_select_manual_item_manuf_release_status_description').remove();
			}

			if (document.getElementById('html_production_select_manual_item_manuf_release_status_id')) {
				document.getElementById('html_production_select_manual_item_manuf_release_status_id').remove();
			}

			if (document.getElementById('html_production_select_manual_item_manuf_release_status_bgcolor')) {
				document.getElementById('html_production_select_manual_item_manuf_release_status_bgcolor').remove();
			}

			if (document.getElementById('html_production_select_manual_item_item_label')) {
				document.getElementById('html_production_select_manual_item_item_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_item_id')) {
				document.getElementById('html_production_select_manual_item_item_id').remove();
			}


			if (document.getElementById('html_production_select_manual_item_item_description_label')) {
				document.getElementById('html_production_select_manual_item_item_description_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_item_description')) {
				document.getElementById('html_production_select_manual_item_item_description').remove();
			}

			if (document.getElementById('html_production_select_manual_item_unit_label')) {
				document.getElementById('html_production_select_manual_item_unit_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_unit_id')) {
				document.getElementById('html_production_select_manual_item_unit_id').remove();
			}

			if (document.getElementById('html_production_select_manual_item_unit_button')) {
				document.getElementById('html_production_select_manual_item_unit_button').remove();
			}

			if (document.getElementById('html_production_select_manual_item_stock_unit_label')) {
				document.getElementById('html_production_select_manual_item_stock_unit_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_stock_unit_id')) {
				document.getElementById('html_production_select_manual_item_stock_unit_id').remove();
			}




			if (document.getElementById('html_production_select_manual_item_bom_release_label')) {
				document.getElementById('html_production_select_manual_item_bom_release_label').remove();
			}
			if (document.getElementById('html_production_select_manual_item_bom_release_id')) {
				document.getElementById('html_production_select_manual_item_bom_release_id').remove();
			}
			if (document.getElementById('html_production_select_manual_item_bom_release_description_label')) {
				document.getElementById('html_production_select_manual_item_bom_release_description_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_bom_release_description')) {
				document.getElementById('html_production_select_manual_item_bom_release_description').remove();
			}

			if (document.getElementById('html_production_select_manual_item_bom_release_status_description')) {
				document.getElementById('html_production_select_manual_item_bom_release_status_description').remove();
			}

			if (document.getElementById('html_production_select_manual_item_bom_release_status_id')) {
				document.getElementById('html_production_select_manual_item_bom_release_status_id').remove();
			}

			if (document.getElementById('html_production_select_manual_item_bom_release_status_bgcolor')) {
				document.getElementById('html_production_select_manual_item_bom_release_status_bgcolor').remove();
			}


			if (document.getElementById('html_production_select_manual_item_routing_release_label')) {
				document.getElementById('html_production_select_manual_item_routing_release_label').remove();
			}
			if (document.getElementById('html_production_select_manual_item_routing_release_id')) {
				document.getElementById('html_production_select_manual_item_routing_release_id').remove();
			}
			if (document.getElementById('html_production_select_manual_item_routing_release_description_label')) {
				document.getElementById('html_production_select_manual_item_routing_release_description_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_routing_release_description')) {
				document.getElementById('html_production_select_manual_item_routing_release_description').remove();
			}

			if (document.getElementById('html_production_select_manual_item_routing_release_status_description')) {
				document.getElementById('html_production_select_manual_item_routing_release_status_description').remove();
			}

			if (document.getElementById('html_production_select_manual_item_routing_release_status_id')) {
				document.getElementById('html_production_select_manual_item_routing_release_status_id').remove();
			}

			if (document.getElementById('html_production_select_manual_item_routing_release_status_bgcolor')) {
				document.getElementById('html_production_select_manual_item_routing_release_status_bgcolor').remove();
			}



			if (document.getElementById('html_production_select_manual_item_toproduce_label')) {
				document.getElementById('html_production_select_manual_item_toproduce_label').remove();
			}
			if (document.getElementById('html_production_select_manual_item_toproduce')) {
				document.getElementById('html_production_select_manual_item_toproduce').remove();
			}

			if (document.getElementById('html_production_select_manual_item_toproduce_button_up')) {
				document.getElementById('html_production_select_manual_item_toproduce_button_up').remove();
			}

			if (document.getElementById('html_production_select_manual_item_toproduce_button_down')) {
				document.getElementById('html_production_select_manual_item_toproduce_button_down').remove();
			}

			if (document.getElementById('html_production_select_manual_item_multiple_label')) {
				document.getElementById('html_production_select_manual_item_multiple_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_multiple')) {
				document.getElementById('html_production_select_manual_item_multiple').remove();
			}

			if (document.getElementById('html_production_select_manual_item_min_order')) {
				document.getElementById('html_production_select_manual_item_min_order').remove();
			}


			if (document.getElementById('html_production_select_manual_item_already_manufactured')) {
				document.getElementById('html_production_select_manual_item_already_manufactured').remove();
			}

			if (document.getElementById('html_production_select_manual_item_already_manufactured_label')) {
				document.getElementById('html_production_select_manual_item_already_manufactured_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_already_prepared')) {
				document.getElementById('html_production_select_manual_item_already_prepared').remove();
			}

			if (document.getElementById('html_production_select_manual_item_already_prepared_label')) {
				document.getElementById('html_production_select_manual_item_already_prepared_label').remove();
			}



			if (document.getElementById('html_production_select_manual_item_entry_whs_label')) {
				document.getElementById('html_production_select_manual_item_entry_whs_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_entry_whs_id')) {
				document.getElementById('html_production_select_manual_item_entry_whs_id').remove();
			}

			if (document.getElementById('html_production_select_manual_item_entry_whs_button')) {
				document.getElementById('html_production_select_manual_item_entry_whs_button').remove();
			}



			if (document.getElementById('html_production_select_manual_item_output_whs_label')) {
				document.getElementById('html_production_select_manual_item_output_whs_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_output_whs_id')) {
				document.getElementById('html_production_select_manual_item_output_whs_id').remove();
			}

			if (document.getElementById('html_production_select_manual_item_output_whs_button')) {
				document.getElementById('html_production_select_manual_item_output_whs_button').remove();
			}


			if (document.getElementById('html_production_select_manual_item_scheduling_priority_label')) {
				document.getElementById('html_production_select_manual_item_scheduling_priority_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_scheduling_priority')) {
				document.getElementById('html_production_select_manual_item_scheduling_priority').remove();
			}


			if (document.getElementById('html_production_select_manual_item_scheduling_pivot_label')) {
				document.getElementById('html_production_select_manual_item_scheduling_pivot_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_scheduling_pivot')) {
				document.getElementById('html_production_select_manual_item_scheduling_pivot').remove();
			}


			if (document.getElementById('html_production_select_manual_item_scheduling_no_elasticity')) {
				document.getElementById('html_production_select_manual_item_scheduling_no_elasticity').remove();
			}

			if (document.getElementById('html_production_select_manual_item_scheduling_no_elasticity_label')) {
				document.getElementById('html_production_select_manual_item_scheduling_no_elasticity_label').remove();
			}



			if (document.getElementById('html_production_select_manual_item_scheduling_mode_label')) {
				document.getElementById('html_production_select_manual_item_scheduling_mode_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_scheduling_mode')) {
				document.getElementById('html_production_select_manual_item_scheduling_mode').remove();
			}


			if (document.getElementById('html_production_select_manual_item_single_delay_label')) {
				document.getElementById('html_production_select_manual_item_single_delay_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_single_delay')) {
				document.getElementById('html_production_select_manual_item_single_delay').remove();
			}


			if (document.getElementById('html_production_select_manual_item_start_after_label')) {
				document.getElementById('html_production_select_manual_item_start_after_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_start_after')) {
				document.getElementById('html_production_select_manual_item_start_after').remove();
			}


			if (document.getElementById('html_production_select_manual_item_to_deliver_label')) {
				document.getElementById('html_production_select_manual_item_to_deliver_label').remove();
			}

			if (document.getElementById('html_production_select_manual_item_to_deliver')) {
				document.getElementById('html_production_select_manual_item_to_deliver').remove();
			}


			let html = '';

			html += '<div data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;">';
			html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Item & Manufactured revision") +  '</b></label>';
			html += '</div>'

			html += '<div style="overflow: auto; overflow-x: auto; height:8px;"></div>';

			html += '<div style="overflow: auto; overflow-x: auto; height:270px; width: 100%;">';


			// Manufacturing definition
			html += '	<div style="position: relative; top: 0px; left: 0px; width:170px;">';

			html += '		<label id="html_production_select_manual_item_manuf_release_label" style="position: absolute; top: 0px; left: 2px; width:250px; z-index:5;">' + __("Manufactured revision") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 250px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_manuf_release_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" readonly value="">';
			html += '		</div>';

			html += '		<div  style="position: absolute; top: 25px; left: 262px; width: 30px; height: 25px;"> ';
			html +='			<button id="html_production_select_manual_item_manuf_release_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border: none;" onclick="">' + __("...") + '</button>';
			html += '		</div>';

			html += '		<label id="html_production_select_manual_item_manuf_release_description_label" style="position: absolute; top: 0px; left: 302px; width:400px; z-index:5;">' + __("Description") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 302px; width: 400px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_manuf_release_description" type="text" class="input-with-feedback form-control bold" readonly value="">';
			html += '		</div>';

			html += '		<label id="html_production_select_manual_item_manuf_release_status_description_label" style="position: absolute; top: 0px; left: 712px; width:220px; z-index:5;">' + __("Status") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 712px; width: 220px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_manuf_release_status_description" type="text" class="input-with-feedback form-control bold" readonly value="">';
			html += '		</div>';

			html += '		<input id="html_production_select_manual_item_manuf_release_status_id" type="hidden" value="">';
			html += '		<input id="html_production_select_manual_item_manuf_release_status_bgcolor" type="hidden" value="">';

			html += '	</div>';


			// Item
			html += '	<div style="position: relative; top: 65px; left: 0px; width:170px;">';

			html += '		<label id="html_production_select_manual_item_item_label" style="position: absolute; top: 0px; left: 2px; width:290px; z-index:5;">' + __("Item") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 290px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_item_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" readonly value="">';
			html += '		</div>';


			html += '		<label id="html_production_select_manual_item_item_description_label" style="position: absolute; top: 0px; left: 302px; width: 400px; z-index:5;">' + __("Description") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 302px; width: 400px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_item_description" type="text" class="input-with-feedback form-control bold" readonly value="">';
			html += '		</div>';

			html += '		<label id="html_production_select_manual_item_unit_label" style="position: absolute; top: 0px; left: 712px; width:80px; z-index:5;">' + __("Unit") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 712px; width: 80px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_unit_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" readonly value="">';
			html += '		</div>';

			html += '		<div  style="position: absolute; top: 25px; left: 802px; width: 30px; height: 25px;"> ';
			html +='			<button id="html_production_select_manual_item_unit_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border: none;" onclick="">' + __("...") + '</button>';
			html += '		</div>';

			html += '		<label id="html_production_select_manual_item_stock_unit_label" style="position: absolute; top: 0px; left: 842px; width:90px; z-index:5;">' + __("Stock Unit") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 842px; width: 90px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_stock_unit_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" readonly value="">';
			html += '		</div>';

			html += '	</div>';



			// BOM release
			html += '	<div style="position: relative; top: 130px; left: 0px; width:170px;">';

			html += '		<label id="html_production_select_manual_item_bom_release_label" style="position: absolute; top: 0px; left: 2px; width:290px; z-index:5;">' + __("BOM release") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 290px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_bom_release_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" readonly value="">';
			html += '		</div>';

			html += '		<label id="html_production_select_manual_item_bom_release_description_label" style="position: absolute; top: 0px; left: 302px; width:400px; z-index:5;">' + __("Description") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 302px; width: 400px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_bom_release_description" type="text" class="input-with-feedback form-control bold" readonly value="">';
			html += '		</div>';

			html += '		<label id="html_production_select_manual_item_bom_release_status_description_label" style="position: absolute; top: 0px; left: 712px; width:220px; z-index:5;">' + __("Status") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 712px; width: 220px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_bom_release_status_description" type="text" class="input-with-feedback form-control bold" readonly value="">';
			html += '		</div>';

			html += '		<input id="html_production_select_manual_item_bom_release_status_id" type="hidden" value="">';
			html += '		<input id="html_production_select_manual_item_bom_release_status_bgcolor" type="hidden" value="">';

			html += '	</div>';


			// Routing release
			html += '	<div style="position: relative; top: 195px; left: 0px; width:170px;">';

			html += '		<label id="html_production_select_manual_item_routing_release_label" style="position: absolute; top: 0px; left: 2px; width:290px; z-index:5;">' + __("Routing release") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 290px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_routing_release_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" readonly value="">';
			html += '		</div>';

			html += '		<label id="html_production_select_manual_item_routing_release_description_label" style="position: absolute; top: 0px; left: 302px; width:400px; z-index:5;">' + __("Description") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 302px; width: 400px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_routing_release_description" type="text" class="input-with-feedback form-control bold" readonly value="">';
			html += '		</div>';

			html += '		<label id="html_production_select_manual_item_routing_release_status_description_label" style="position: absolute; top: 0px; left: 712px; width:220px; z-index:5;">' + __("Status") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 712px; width: 220px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_routing_release_status_description" type="text" class="input-with-feedback form-control bold" readonly value="">';
			html += '		</div>';

			html += '		<input id="html_production_select_manual_item_routing_release_status_id" type="hidden" value="">';
			html += '		<input id="html_production_select_manual_item_routing_release_status_bgcolor" type="hidden" value="">';

			html += '	</div>';

			html += '</div>';

			html += '<div data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;">';
			html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("To produce & scheduling") +  '</b></label>';
			html += '</div>'

			html += '<div style="overflow: auto; overflow-x: auto; height:8px;"></div>';

			html += '<div style="overflow: auto; overflow-x: auto; height:140px; width: 100%;">';

			// To produce
			html += '	<div style="position: relative; top: 0px; left: 0px; width:170px;">';

			html += '		<label id="html_production_select_manual_item_toproduce_label" style="position: absolute; top: 0px; left: 2px; width:125px; z-index:5;">' + __("To produce") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 125px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_toproduce" type="number" step="any" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';

			html += '		<div  style="position: absolute; top: 25px; left: 137px; width: 30px; height: 25px;"> ';
			html +='			<button id="html_production_select_manual_item_toproduce_button_up" style="position:absolute; height: 30px; width: 30px; z-index:10; border: none;" onclick="">+</button>';
			html += '		</div>';

			html += '		<div  style="position: absolute; top: 25px; left: 177px; width: 30px; height: 25px;"> ';
			html +='			<button id="html_production_select_manual_item_toproduce_button_down" style="position:absolute; height: 30px; width: 30px; z-index:10; border: none;" onclick="">-</button>';
			html += '		</div>';

			html += '		<label id="html_production_select_manual_item_multiple_label" style="position: absolute; top: 0px; left: 217px; width:75px; z-index:5;">' + __("Multiple qty") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 217px; width: 75px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_multiple" type="number" step="any" class="input-with-feedback form-control bold" readonly value="">';
			html += '		</div>';

			html += '		<input id="html_production_select_manual_item_min_order" type="hidden" value="">';

			html += '	</div>';


			// Already manufactured & already prepared
			html += '	<div style="position: relative; top: 15px; left: 0px; width:800px;">';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 302px; width: 250px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_already_manufactured" type="checkbox" class="input-with-feedback form-control bold">';
			html += '		</div>';
			html += '		<label id="html_production_select_manual_item_already_manufactured_label" style="position: absolute; top: 0px; left: 327px; width:250px; z-index:5;">' + __("Already manufactured") + '</label>';


			html += '		<div class="control-input" style="position: absolute; top: 27px; left: 302px; width: 250px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_already_prepared" type="checkbox" class="input-with-feedback form-control bold">';
			html += '		</div>';
			html += '		<label id="html_production_select_manual_item_already_prepared_label" style="position: absolute; top: 25px; left: 327px; width:250px; z-index:5;">' + __("Already prepared") + '</label>';

			html += '	</div>';

			// Entry warehouse
			html += '	<div style="position: relative; top: 0px; left: 500px; width:170px;">';

			html += '		<label id="html_production_select_manual_item_entry_whs_label" style="position: absolute; top: 0px; left: 2px; width:160px; z-index:5;">' + __("Entry warehouse") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 160px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_entry_whs_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
			html += '		</div>';

			html += '		<div  style="position: absolute; top: 25px; left: 172px; width: 30px; height: 25px;"> ';
			html +='			<button id="html_production_select_manual_item_entry_whs_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border: none;" onclick="">' + __("...") + '</button>';
			html += '		</div>';

			html += '	</div>';


			// Output warehouse
			html += '	<div style="position: relative; top: 0px; left: 712px; width:170px;">';

			html += '		<label id="html_production_select_manual_item_output_whs_label" style="position: absolute; top: 0px; left: 2px; width:180px; z-index:5;">' + __("Output warehouse") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 180px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_output_whs_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
			html += '		</div>';

			html += '		<div  style="position: absolute; top: 25px; left: 192px; width: 30px; height: 25px;"> ';
			html +='			<button id="html_production_select_manual_item_output_whs_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border: none;" onclick="">' + __("...") + '</button>';
			html += '		</div>';

			html += '	</div>';

			// Scheduling priority
			html += '	<div style="position: relative; top: 65px; left: 0px; width:170px;">';

			html += '		<label id="html_production_select_manual_item_scheduling_priority_label" style="position: absolute; top: 0px; left: 2px; width:125px; z-index:5;">' + __("Scheduling priority") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 125px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_scheduling_priority" type="number" step="any" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';

			html += '	</div>';


			// Scheduling pivot
			html += '	<div style="position: relative; top: 65px; left: 135px; width:170px;">';

			html += '		<label id="html_production_select_manual_item_scheduling_pivot_label" style="position: absolute; top: 0px; left: 2px; width:155px; z-index:5;">' + __("Scheduling pivot") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 155px; height: 25px;"> ';
			html += '			<select id="html_production_select_manual_item_scheduling_pivot" class="input-with-feedback form-control bold">';
			html += '				<option value="0" selected>' + __('At the latest')	+ '</option>';
			html += '				<option value="1">' + __('At the soonest')	+ '</option>';
			html += '			</select>';
			html += '		</div>';

			html += '	</div>';

			// Scheduling no elasticity
			html += '	<div style="position: relative; top: 95px; left: 0px; width:800px;">';

			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 302px; width: 250px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_scheduling_no_elasticity" type="checkbox" class="input-with-feedback form-control bold">';
			html += '		</div>';
			html += '		<label id="html_production_select_manual_item_scheduling_no_elasticity_label" style="position: absolute; top: 0px; left: 327px; width:250px; z-index:5;">' + __("Scheduling no elasticity") + '</label>';

			html += '	</div>';

			// Scheduling mode
			html += '	<div style="position: relative; top: 65px; left: 500px; width:170px;">';

			html += '		<label id="html_production_select_manual_item_scheduling_mode_label" style="position: absolute; top: 0px; left: 2px; width:200px; z-index:5;">' + __("Scheduling mode") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 200px; height: 25px;"> ';
			html += '			<select id="html_production_select_manual_item_scheduling_mode" class="input-with-feedback form-control bold">';
			html += '				<option value="0" selected>' + __('Infinite capacity')	+ '</option>';
			html += '				<option value="1">' + __('Finite capacity')	+ '</option>';
			html += '				<option value="2">' + __('single delay')	+ '</option>';
			html += '			</select>';
			html += '		</div>';

			html += '	</div>';

			// Single delay
			html += '	<div style="position: relative; top: 65px; left: 712px; width:170px;">';

			html += '		<label id="html_production_select_manual_item_single_delay_label" style="position: absolute; top: 0px; left: 2px; width:125px; z-index:5;">' + __("Single delay") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 125px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_single_delay" type="number" step="any" class="input-with-feedback form-control bold" readonly value="0">';
			html += '		</div>';

			html += '	</div>';

			html += "</div>";

			html += '<div data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;">';
			html += '	<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Date") +  '</b></label>';
			html += '</div>'

			html += '<div style="overflow: auto; overflow-x: auto; height:8px;"></div>';

			html += '<div style="overflow: auto; overflow-x: auto; height:70px; width: 100%;">';

			// Start after
			html += '	<div style="position: relative; top: 0px; left: 0px; width:170px;">';

			html += '		<label id="html_production_select_manual_item_start_after_label" style="position: absolute; top: 0px; left: 2px; width:200px; z-index:5;">' + __("Start after") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 200px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_start_after" type="datetime-local" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';

			html += '	</div>';


			// To deliver
			html += '	<div style="position: relative; top: 0px; left: 210px; width:170px;">';

			html += '		<label id="html_production_select_manual_item_to_deliver_label" style="position: absolute; top: 0px; left: 2px; width:200px; z-index:5;">' + __("To deliver") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 200px; height: 25px;"> ';
			html += '			<input id="html_production_select_manual_item_to_deliver" type="datetime-local" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';

			html += '	</div>';


			html += '</div>';

			production_select_item_form.fields_dict.html_production_select_manual_item.$wrapper.html(html);
			production_select_item_form.$wrapper.find('.modal-dialog').css("max-width", "975px").css("width", "975px");
			production_select_item_form.show();

			me.#sleep(400).then(() => {

				if ((me.frm.doc.item_id) && (me.frm.doc.item_id.trim() != '')) {

					// Item
					document.getElementById('html_production_select_manual_item_item_id').value = me.frm.doc.item_id;
					document.getElementById('html_production_select_manual_item_item_description').value = me.frm.doc.item_description;
					document.getElementById('html_production_select_manual_item_unit_id').value = me.frm.doc.unit_id;

					document.getElementById('html_production_select_manual_item_stock_unit_id').value = me.frm.doc.stock_unit_id;


					// Manuf release
					document.getElementById('html_production_select_manual_item_manuf_release_id').value = me.frm.doc.manufactured_revision_id;


					// BOM release
					document.getElementById('html_production_select_manual_item_bom_release_id').value = me.frm.doc.bom_release_id;

					// Routing release
					document.getElementById('html_production_select_manual_item_routing_release_id').value = me.frm.doc.routing_release_id;

					// To produce
					document.getElementById('html_production_select_manual_item_toproduce').value = me.frm.doc.ordered_qty;

					// Already manufactured & already prepared
					document.getElementById('html_production_select_manual_item_already_manufactured').checked = false;
					document.getElementById('html_production_select_manual_item_already_prepared').checked = false;


					// Entry warehouse
					if (me.frm.doc.document_type == 'PRODUCTION') {
						document.getElementById('html_production_select_manual_item_entry_whs_id').value = me.journal_production_entry_warehouse_id;
					}else if (me.frm.doc.document_type == 'PRODUCTION_REQ') {
						document.getElementById('html_production_select_manual_item_entry_whs_id').value = me.journal_production_request_entry_warehouse_id;
					}

					if ((me.frm.doc.entry_warehouse_id) && (me.frm.doc.entry_warehouse_id.trim() != '')) {
						document.getElementById('html_production_select_manual_item_entry_whs_id').value = me.frm.doc.entry_warehouse_id;
					}

					// Output warehouse
					if (me.frm.doc.document_type == 'PRODUCTION') {
						document.getElementById('html_production_select_manual_item_output_whs_id').value = me.journal_production_output_warehouse_id;
					}else if (me.frm.doc.document_type == 'PRODUCTION_REQ') {
						document.getElementById('html_production_select_manual_item_output_whs_id').value = me.journal_production_request_output_warehouse_id;
					}

					if ((me.frm.doc.output_warehouse_id) && (me.frm.doc.output_warehouse_id.trim() != '')) {
						document.getElementById('html_production_select_manual_item_output_whs_id').value = me.frm.doc.output_warehouse_id;
					}


					// Scheduling priority
					document.getElementById('html_production_select_manual_item_scheduling_priority').value = me.frm.doc.scheduling_priority;


					// Scheduling pivot
					document.getElementById('html_production_select_manual_item_scheduling_pivot').value = me.frm.doc.scheduling_pivot;



					// Scheduling no elasticity
					document.getElementById('html_production_select_manual_item_scheduling_no_elasticity').checked = false;
					if (me.frm.doc.scheduling_no_elasticity == 1) {
						document.getElementById('html_production_select_manual_item_scheduling_no_elasticity').checked = true;
					}


					// Scheduling mode
					document.getElementById('html_production_select_manual_item_scheduling_mode').value = me.frm.doc.scheduling_mode;


					document.getElementById('html_production_select_manual_item_single_delay').readOnly = true;
					if (document.getElementById('html_production_select_manual_item_scheduling_mode').value == 2) {
						document.getElementById('html_production_select_manual_item_single_delay').readOnly = false;

					}

					// Single delay
					document.getElementById('html_production_select_manual_item_single_delay').value = me.frm.doc.single_delay;



					// Start after
					document.getElementById('html_production_select_manual_item_start_after').value = me.frm.doc.start_after;

					// To deliver
					document.getElementById('html_production_select_manual_item_to_deliver').value = me.frm.doc.delivery_date;


					let method = this.path_production + '.ioi_production_select_item_dialog_get_data';

					frappe.call({  	method: method,
									args: {	"site_id" : me.frm.doc.site_id,
											"item_id": me.frm.doc.item_id,
											"manuf_release_id": me.frm.doc.manufactured_revision_id,
											"bom_release_id": me.frm.doc.bom_release_id,
											"routing_release_id": me.frm.doc.routing_release_id
											},
									async: false,
									callback:function(r)	{

										document.getElementById('html_production_select_manual_item_manuf_release_description').value = r.message.manuf_release_description;
										document.getElementById('html_production_select_manual_item_manuf_release_status_description').value = r.message.manuf_release_status_description;
										document.getElementById('html_production_select_manual_item_manuf_release_status_id').value = r.message.manuf_release_status_id;
										document.getElementById('html_production_select_manual_item_manuf_release_status_bgcolor').value = r.message.manuf_release_status_bgcolor;

										if (r.message.manuf_release_status_bgcolor != '') {
											document.getElementById('html_production_select_manual_item_manuf_release_status_description').style.backgroundColor = r.message.manuf_release_status_bgcolor;
										}

										document.getElementById('html_production_select_manual_item_bom_release_description').value = r.message.bom_release_description;
										document.getElementById('html_production_select_manual_item_bom_release_status_description').value = r.message.bom_release_status_description;
										document.getElementById('html_production_select_manual_item_bom_release_status_id').value = r.message.bom_release_status_id;
										document.getElementById('html_production_select_manual_item_bom_release_status_bgcolor').value = r.message.bom_release_status_bgcolor;

										if (r.message.bom_release_status_bgcolor != '') {
											document.getElementById('html_production_select_manual_item_bom_release_status_description').style.backgroundColor = r.message.bom_release_status_bgcolor;
										}


										document.getElementById('html_production_select_manual_item_routing_release_description').value = r.message.routing_release_description;
										document.getElementById('html_production_select_manual_item_routing_release_status_description').value = r.message.routing_release_status_description;
										document.getElementById('html_production_select_manual_item_routing_release_status_id').value = r.message.routing_release_status_id;
										document.getElementById('html_production_select_manual_item_routing_release_status_bgcolor').value = r.message.routing_release_status_bgcolor;

										if (r.message.routing_release_status_bgcolor != '') {
											document.getElementById('html_production_select_manual_item_routing_release_status_description').style.backgroundColor = r.message.routing_release_status_bgcolor;
										}


										document.getElementById('html_production_select_manual_item_multiple').value = r.message.manuf_order_qty_multiple;
										document.getElementById('html_production_select_manual_item_min_order').value = r.message.manuf_order_qty;


										let value = 0

										if ((document.getElementById('html_production_select_manual_item_multiple').value) && (document.getElementById('html_production_select_manual_item_multiple').value.trim() != '0') && (document.getElementById('html_production_select_manual_item_multiple').value.trim() != '')) {
											value = parseFloat(document.getElementById('html_production_select_manual_item_multiple').value);
										}

										let unit_coeff = 1

										if (me.frm.doc.unit_coeff != null) {
											if (parseFloat(me.frm.doc.unit_coeff) != 0) {
												unit_coeff = parseFloat(me.frm.doc.unit_coeff)
											}
										}

										let stock_unit_coeff = 1

										if (me.frm.doc.stock_unit_coeff != null) {
											if (parseFloat(me.frm.doc.stock_unit_coeff) != 0) {
												stock_unit_coeff = parseFloat(me.frm.doc.stock_unit_coeff)
											}
										}

										document.getElementById('html_production_select_manual_item_multiple').value = parseFloat(value) / parseFloat(unit_coeff) * parseFloat(stock_unit_coeff)


										value = 0;

										if ((document.getElementById('html_production_select_manual_item_min_order').value) && (document.getElementById('html_production_select_manual_item_min_order').value.trim() != '0') && (document.getElementById('html_production_select_manual_item_min_order').value.trim() != '')) {
											value = parseFloat(document.getElementById('html_production_select_manual_item_min_order').value);
										}

										document.getElementById('html_production_select_manual_item_min_order').value = parseFloat(value) / parseFloat(unit_coeff) * parseFloat(stock_unit_coeff)




										if (r.message.manuf_already_manufactured == 1) {
											document.getElementById('html_production_select_manual_item_already_manufactured').checked = true;
										}

										if (me.frm.doc.already_manufactured == 1) {
											document.getElementById('html_production_select_manual_item_already_manufactured').checked = true;
										}else{
											document.getElementById('html_production_select_manual_item_already_manufactured').checked = false;
										}

										if (r.message.manuf_already_prepared == 1) {
											document.getElementById('html_production_select_manual_item_already_prepared').checked = true;
										}

										if (me.frm.doc.already_prepared == 1) {
											document.getElementById('html_production_select_manual_item_already_prepared').checked = true;
										}else{
											document.getElementById('html_production_select_manual_item_already_prepared').checked = false;
										}


										if ((r.message.spec_entry_warehouse_id) && (r.message.spec_entry_warehouse_id != '')) {
											document.getElementById('html_production_select_manual_item_entry_whs_id').value = r.message.spec_entry_warehouse_id;
										}

										if ((r.message.spec_output_warehouse_id) && (r.message.spec_output_warehouse_id != '')) {
											document.getElementById('html_production_select_manual_item_output_whs_id').value = r.message.spec_output_warehouse_id;
										}

									}
					});
				}else{

					if (me.frm.doc.already_manufactured == 1) {
						document.getElementById('html_production_select_manual_item_already_manufactured').checked = true;
					}else{
						document.getElementById('html_production_select_manual_item_already_manufactured').checked = false;
					}

					if (me.frm.doc.already_prepared == 1) {
						document.getElementById('html_production_select_manual_item_already_prepared').checked = true;
					}else{
						document.getElementById('html_production_select_manual_item_already_prepared').checked = false;
					}


					// Entry warehouse
					if (me.frm.doc.document_type == 'PRODUCTION') {
						document.getElementById('html_production_select_manual_item_entry_whs_id').value = me.journal_production_entry_warehouse_id;
					}else if (me.frm.doc.document_type == 'PRODUCTION_REQ') {
						document.getElementById('html_production_select_manual_item_entry_whs_id').value = me.journal_production_request_entry_warehouse_id;
					}

					if ((me.frm.doc.entry_warehouse_id) && (me.frm.doc.entry_warehouse_id.trim() != '')) {
						document.getElementById('html_production_select_manual_item_entry_whs_id').value = me.frm.doc.entry_warehouse_id;
					}

					// Output warehouse
					if (me.frm.doc.document_type == 'PRODUCTION') {
						document.getElementById('html_production_select_manual_item_output_whs_id').value = me.journal_production_output_warehouse_id;
					}else if (me.frm.doc.document_type == 'PRODUCTION_REQ') {
						document.getElementById('html_production_select_manual_item_output_whs_id').value = me.journal_production_request_output_warehouse_id;
					}

					if ((me.frm.doc.output_warehouse_id) && (me.frm.doc.output_warehouse_id.trim() != '')) {
						document.getElementById('html_production_select_manual_item_output_whs_id').value = me.frm.doc.output_warehouse_id;
					}


					// Scheduling pivot
					document.getElementById('html_production_select_manual_item_scheduling_pivot').value = 0;

					// Scheduling no elasticity
					document.getElementById('html_production_select_manual_item_scheduling_no_elasticity').checked = false;
					if (me.frm.doc.scheduling_no_elasticity == 1) {
						document.getElementById('html_production_select_manual_item_scheduling_no_elasticity').checked = true;
					}


					// Start after
					let dt = new Date();

					let y = dt.getFullYear();
					let m = dt.getMonth() + 1;
					let d = dt.getDate();

					let sdt = y.toString() + '-'

					if (m < 10) {
						sdt += '0';
					}

					sdt += m.toString() + '-';

					if (d < 10) {
						sdt += '0';
					}

					sdt += d.toString() + ' 00:00';

					document.getElementById('html_production_select_manual_item_start_after').value = sdt;


					dt.setDate(dt.getDate() + 2);

					y = dt.getFullYear();
					m = dt.getMonth() + 1;
					d = dt.getDate();

					sdt = y.toString() + '-'

					if (m < 10) {
						sdt += '0';
					}

					sdt += m.toString() + '-';

					if (d < 10) {
						sdt += '0';
					}

					sdt += d.toString() + ' 00:00';

					document.getElementById('html_production_select_manual_item_to_deliver').value = sdt;

				}




				let fct_keydown = function(event) {

					if (event.keyCode == 13) {
						return false;
					}

				}


				let fct_item_focus = function() {
					this.oldvalue = this.value;
				};

				document.getElementById('html_production_select_manual_item_item_id').onfocus = fct_item_focus;

				let fct_item_blur = function() {


					if (this.oldvalue != this.value) {
						me.get_item_data();
					}

				}

				document.getElementById('html_production_select_manual_item_item_id').onblur = fct_item_blur;

				let fct_item_keydown = function(event) {

					if (event.keyCode == 13) {
						this.blur();
						return false;
					}


				}


				document.getElementById('html_production_select_manual_item_item_id').onkeydown = fct_item_keydown;


				let fct_unit_click = function() {

					if ((!document.getElementById('html_production_select_manual_item_item_id').value) || ((document.getElementById('html_production_select_manual_item_item_id').value) && (document.getElementById('html_production_select_manual_item_item_id').value.trim() == ''))) {

						frappe.msgprint({title: __("Message"), message: __('Item is mandatory is mandatory'), indicator: "red"});
						raise;

					}

					let title = __("Select an unit");
					let form_width_pixel = '710px';
					let fields = 'name, description';
					let table = "ioi Item Unit";
					let fields_len = '250, 380';
					let fields_desc = __('Identification') + ',' + __('Description');
					let where = '';
					let order_by = 'name asc';


					if ((!document.getElementById('html_production_select_manual_item_stock_unit_id').value) || ((document.getElementById('html_production_select_manual_item_stock_unit_id').value) && (document.getElementById('html_production_select_manual_item_stock_unit_id').value.trim() == ''))) {
						where = "enabled = 1 ";
					}else{

						let method = me.path_item_unit + '.ioi_item_unit_get_enabled_same_family_for_ref_unit';

						frappe.call({	method: method,
										args: {"ref_unit_id": document.getElementById('html_production_select_manual_item_stock_unit_id').value},
										async: false,
										callback: function(r){

											where = "enabled = 1 and name in (";

											if (r.message)
											{
												for (var z = 0; z < r.message.length; z++) {

													where += "'" + r.message[z][0].replaceAll("'", "''") + "'";

													if (z < r.message.length-1) {
														where += ',';
													}

												}
											}

											where += ")";
										}
						});

					}


					let fct_callback = function (return_value) {

						let old_unit_id = document.getElementById('html_production_select_manual_item_unit_id').value;
						let old_coefficient = 1;

						document.getElementById('html_production_select_manual_item_unit_id').value = return_value;
						let new_coefficient = 1;

						if (old_unit_id != '') {

							let method = me.path_item_unit + '.ioi_item_unit_get_data';

							frappe.call({  	method: method,
											args: {"unit_id": old_unit_id},
											async: false,
											callback:function(r)	{

												let coefficient = 1;

												if (r.message.length > 0) {

													if ((r.message[0].coefficient) && (r.message[0].coefficient != 0)) {

														coefficient = parseFloat(r.message[0].coefficient);
													}
												}
												old_coefficient = coefficient;
											}
							});

						}

						let method = me.path_item_unit + '.ioi_item_unit_get_data';

						frappe.call({  	method: method,
										args: {"unit_id": return_value},
										async: false,
										callback:function(r)	{

											let coefficient = 1;

											if (r.message.length > 0) {

												if ((r.message[0].coefficient) && (r.message[0].coefficient != 0)) {

													coefficient = parseFloat(r.message[0].coefficient);
												}
											}
											new_coefficient = coefficient;
										}
						});

						// Convert To produce

						let value = 0;

						if ((document.getElementById('html_production_select_manual_item_toproduce').value) && (document.getElementById('html_production_select_manual_item_toproduce').value.trim() != '')) {
							value = parseFloat(document.getElementById('html_production_select_manual_item_toproduce').value);
						}

						let result = parseFloat(value) * parseFloat(old_coefficient) / parseFloat(new_coefficient);

						document.getElementById('html_production_select_manual_item_toproduce').value = result;

						// Convert multi
						value = 0;

						if ((document.getElementById('html_production_select_manual_item_multiple').value) && (document.getElementById('html_production_select_manual_item_multiple').value.trim() != '')) {
							value = parseFloat(document.getElementById('html_production_select_manual_item_multiple').value);
						}

						result = parseFloat(value) * parseFloat(old_coefficient) / parseFloat(new_coefficient);

						document.getElementById('html_production_select_manual_item_multiple').value = result;


						// Convert min order

						value = 0;

						if ((document.getElementById('html_production_select_manual_item_min_order').value) && (document.getElementById('html_production_select_manual_item_min_order').value.trim() != '')) {
							value = parseFloat(document.getElementById('html_production_select_manual_item_min_order').value);
						}

						result = parseFloat(value) * parseFloat(old_coefficient) / parseFloat(new_coefficient);

						document.getElementById('html_production_select_manual_item_min_order').value = result;




					};

					silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

				}

				document.getElementById('html_production_select_manual_item_unit_button').onclick = fct_unit_click;



				let fct_manuf_release_click = function() {

					let title = __("Select a manufacturing version");
					let form_width_pixel = '770px';
					let table = "ioi Item Manufacturing Definition";
					let fields = 'name, description, ioistatus';
					let fields_len = '250, 300, 120';
					let fields_desc = __('Identification') + ',' + __('Description') + ',' + __('Status');
					let where = "ioistatus in (-1, 2) "
					let order_by = 'name asc';

					let fct_callback = function (return_value) {

						document.getElementById('html_production_select_manual_item_manuf_release_id').value = return_value;
						me.get_manuf_release_data();
					}
					silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

				}


				document.getElementById('html_production_select_manual_item_manuf_release_button').onclick = fct_manuf_release_click;



				let fct_entry_whs_click = function() {

					let title = __("Select an entry warehouse");
					let form_width_pixel = '600px';
					let table = "ioi Warehouse";
					let fields = 'name, description';
					let fields_len = '200, 300';
					let fields_desc = __('Identification') + ',' + __('Description');
					let where = "enabled = 1 and site_id = '" + me.frm.doc.site_id.replaceAll("'", "''") + "' "
					let order_by = 'name asc';

					let fct_callback = function (return_value) {

						document.getElementById('html_production_select_manual_item_entry_whs_id').value = return_value;

					}
					silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

				}


				document.getElementById('html_production_select_manual_item_entry_whs_button').onclick = fct_entry_whs_click;



				let fct_output_whs_click = function() {


					let title = __("Select an output warehouse");
					let form_width_pixel = '600px';
					let table = "ioi Warehouse";
					let fields = 'name, description';
					let fields_len = '200, 300';
					let fields_desc = __('Identification') + ',' + __('Description');
					let where = "enabled = 1 and site_id = '" + me.frm.doc.site_id.replaceAll("'", "''") + "' "
					let order_by = 'name asc';

					let fct_callback = function (return_value) {

						document.getElementById('html_production_select_manual_item_output_whs_id').value = return_value;
					}
					silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

				}


				document.getElementById('html_production_select_manual_item_output_whs_button').onclick = fct_output_whs_click;


				let fct_change_scheduling_mode = function() {

					document.getElementById('html_production_select_manual_item_single_delay').readOnly = true;

					if (document.getElementById('html_production_select_manual_item_scheduling_mode').value == 2) {
						document.getElementById('html_production_select_manual_item_single_delay').readOnly = false;
					}
				}

				document.getElementById('html_production_select_manual_item_scheduling_mode').onchange = fct_change_scheduling_mode;

				let fct_button_up = function() {

					let multi = 1;

					if ((document.getElementById('html_production_select_manual_item_multiple').value) && (document.getElementById('html_production_select_manual_item_multiple').value > 1)) {
						multi = document.getElementById('html_production_select_manual_item_multiple').value;
					}

					let value = 0;

					if (document.getElementById('html_production_select_manual_item_toproduce').value) {
						value = parseFloat(document.getElementById('html_production_select_manual_item_toproduce').value);
					}

					let result = parseFloat(value) + parseFloat(multi);

					document.getElementById('html_production_select_manual_item_toproduce').value = parseFloat(result);

				}

				document.getElementById('html_production_select_manual_item_toproduce_button_up').onclick = fct_button_up;

				let fct_button_down = function() {

					let multi = 1;

					if ((document.getElementById('html_production_select_manual_item_multiple').value) && (document.getElementById('html_production_select_manual_item_multiple').value > 1)) {
						multi = document.getElementById('html_production_select_manual_item_multiple').value;
					}

					let value = 0;

					if (document.getElementById('html_production_select_manual_item_toproduce').value) {
						value = parseFloat(document.getElementById('html_production_select_manual_item_toproduce').value);
					}

					let result = parseFloat(value) - parseFloat(multi);

					if (result >= 0) {
						document.getElementById('html_production_select_manual_item_toproduce').value = parseFloat(result);
					}

				}


				document.getElementById('html_production_select_manual_item_toproduce_button_down').onclick = fct_button_down;



				document.getElementById('html_production_select_manual_item_item_description').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_unit_id').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_manuf_release_id').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_manuf_release_description').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_manuf_release_status_description').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_bom_release_id').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_bom_release_description').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_bom_release_status_description').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_routing_release_id').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_routing_release_description').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_routing_release_status_description').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_toproduce').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_multiple').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_entry_whs_id').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_output_whs_id').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_scheduling_priority').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_single_delay').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_start_after').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_to_deliver').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_already_manufactured').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_already_prepared').onkeydown = fct_keydown;
				document.getElementById('html_production_select_manual_item_scheduling_no_elasticity').onkeydown = fct_keydown;



				let fct_change_start_after = function() {

					if (document.getElementById('html_production_select_manual_item_start_after').value) {

						if (document.getElementById('html_production_select_manual_item_to_deliver').value) {

							if (document.getElementById('html_production_select_manual_item_to_deliver').value < document.getElementById('html_production_select_manual_item_start_after').value) {
								document.getElementById('html_production_select_manual_item_to_deliver').value = document.getElementById('html_production_select_manual_item_start_after').value;
							}
						}
					}
				}
				document.getElementById('html_production_select_manual_item_start_after').onchange = fct_change_start_after;
				document.getElementById('html_production_select_manual_item_start_after').onblur = fct_change_start_after;
				
				let fct_change_to_deliver = function() {

					if (document.getElementById('html_production_select_manual_item_to_deliver').value) {

						if (document.getElementById('html_production_select_manual_item_start_after').value) {

							if (document.getElementById('html_production_select_manual_item_start_after').value > document.getElementById('html_production_select_manual_item_to_deliver').value) {
								document.getElementById('html_production_select_manual_item_start_after').value = document.getElementById('html_production_select_manual_item_to_deliver').value;
							}
						}
					}						
				}

				document.getElementById('html_production_select_manual_item_to_deliver').onblur = fct_change_to_deliver;
				

				if ((!document.getElementById('html_production_select_manual_item_manuf_release_id').value) || ((document.getElementById('html_production_select_manual_item_manuf_release_id').value) && (document.getElementById('html_production_select_manual_item_manuf_release_id').value.trim() == '')))  {
					document.getElementById('html_production_select_manual_item_manuf_release_button').click();
				}

			});
		}

	}

	// ***************************************************************************************************************************************
	// Item clear
	// ***************************************************************************************************************************************

	item_clear()
	{
		if (this.frm.doc.ioistatus == 0) {

			let data = {};

			let method = this.path_production + '.ioi_production_can_access_to_item_selection';

			frappe.call({  	method: method,
							args: {"name" : this.frm.doc.name},
							async: false,
							callback:function(r)	{
								data = r.message;
							}
			});

			if (data.error != 0) {
				frappe.msgprint({title: __("Message"), message: __("Cannot clear item")  + ' : ' + data.error_message, indicator: "red"});
				return false;
			}

			let top = 10;

			let msg = '';

			let s = __("Do you want to remove the component, its bom and release definition ?");
			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
			top += 30;

			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';

			let me = this;

			frappe.confirm(	msg,
				() => 	{
							me.#sleep(200).then(() => {

								me.frm.set_value('item_id', '');
								me.frm.set_value('no_master_item', 1);
								me.frm.set_value('item_description', '');

								me.frm.set_value('ordered_qty', 0);
								me.frm.set_value('part_over_ordered_qty', 0);
								me.frm.set_value('produced_good_qty', 0);
								me.frm.set_value('produced_tocheck_qty', 0);
								me.frm.set_value('still_to_produce', 0);
								me.frm.set_value('still_to_deliver', 0);
								me.frm.set_value('produced_bad_qty', 0);
								me.frm.set_value('unit_id', '');
								me.frm.set_value('unit_coeff', 1);
								me.frm.set_value('already_manufactured', 0);
								me.frm.set_value('already_prepared', 0);
								me.frm.set_value('bad_qty_in_goal', 0);

								me.frm.set_value('stock_ordered_qty', 0);
								me.frm.set_value('stock_part_over_ordered_qty', 0);
								me.frm.set_value('stock_produced_good_qty', 0);
								me.frm.set_value('stock_produced_tocheck_qty', 0);
								me.frm.set_value('stock_still_to_produce', 0);
								me.frm.set_value('stock_still_to_deliver', 0);

								me.frm.set_value('stock_produced_bad_qty', 0);

								me.frm.set_value('delivered_qty', 0);
								me.frm.set_value('tocheck_qty', 0);
								me.frm.set_value('bad_qty', 0);

								me.frm.set_value('stock_delivered_qty', 0);
								me.frm.set_value('stock_tocheck_qty', 0);
								me.frm.set_value('stock_bad_qty', 0);

								me.frm.set_value('stock_unit_id', '');
								me.frm.set_value('stock_unit_coeff', 1);


								me.frm.set_value('manufactured_revision_id', '');
								me.frm.set_value('manuf_rev_ioistatus', '');
								me.frm.set_value('bom_release_id', '');
								me.frm.set_value('routing_release_id', '');

								me.frm.set_value('tocheck_only_manufacturing', 0);

								me.frm.set_value('frozen_bom', 0);
								me.frm.set_value('frozen_routing', 0);


								me.frm.doc.bom_detail = [];
								me.frm.refresh_field('bom_detail');

								me.frm.doc.routing_detail = [];
								me.frm.refresh_field('routing_detail');

								me.refresh_manuf_release_status();

							});
						},
				() => 	{
						}
			);




		}
	}


	// ***************************************************************************************************************************************
	// Get Item data
	// ***************************************************************************************************************************************

	get_item_data()
	{
		let me = this;

		let method = me.path_production + '.ioi_production_select_item_get_data';

		frappe.call({  	method: method,
						args: {	"site_id" : me.frm.doc.site_id,
								"item_id": document.getElementById('html_production_select_manual_item_item_id').value
																},
						async: false,
						callback:function(r)	{

							document.getElementById('html_production_select_manual_item_item_description').value = '';
							document.getElementById('html_production_select_manual_item_unit_id').value = '';
							document.getElementById('html_production_select_manual_item_stock_unit_id').value = '';

							document.getElementById('html_production_select_manual_item_toproduce').value = 0;
							document.getElementById('html_production_select_manual_item_multiple').value = 0;
							document.getElementById('html_production_select_manual_item_min_order').value = 0;

							document.getElementById('html_production_select_manual_item_already_manufactured').cheked = false;
							document.getElementById('html_production_select_manual_item_already_prepared').cheked = false;

							// Entry warehouse
							document.getElementById('html_production_select_manual_item_entry_whs_id').value = '';

							if (me.frm.doc.document_type == 'PRODUCTION') {
								document.getElementById('html_production_select_manual_item_entry_whs_id').value = me.journal_production_entry_warehouse_id;
							}else if (me.frm.doc.document_type == 'PRODUCTION_REQ') {
								document.getElementById('html_production_select_manual_item_entry_whs_id').value = me.journal_production_request_entry_warehouse_id;
							}


							// Output warehouse
							document.getElementById('html_production_select_manual_item_output_whs_id').value = '';

							if (me.frm.doc.document_type == 'PRODUCTION') {
								document.getElementById('html_production_select_manual_item_output_whs_id').value = me.journal_production_output_warehouse_id;
							}else if (me.frm.doc.document_type == 'PRODUCTION_REQ') {
								document.getElementById('html_production_select_manual_item_output_whs_id').value = me.journal_production_request_output_warehouse_id;
							}

							document.getElementById('html_production_select_manual_item_scheduling_priority').value = '';
							document.getElementById('html_production_select_manual_item_single_delay').value = '';
							document.getElementById('html_production_select_manual_item_scheduling_mode').value = 0;
							document.getElementById('html_production_select_manual_item_scheduling_pivot').value = 0;
							document.getElementById('html_production_select_manual_item_scheduling_no_elasticity').cheked = false;


							if (r.message.error != 0) {
								frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
								raise;
							}else{

								document.getElementById('html_production_select_manual_item_item_description').value = r.message.item_description;
								document.getElementById('html_production_select_manual_item_unit_id').value = r.message.unit_id;
								document.getElementById('html_production_select_manual_item_stock_unit_id').value = r.message.unit_id;

								document.getElementById('html_production_select_manual_item_toproduce').value = r.message.manuf_order_qty;
								document.getElementById('html_production_select_manual_item_multiple').value = r.message.manuf_order_qty_multiple;
								document.getElementById('html_production_select_manual_item_min_order').value = r.message.manuf_order_qty;

								document.getElementById('html_production_select_manual_item_already_manufactured').cheked = false;

								if (r.message.manuf_already_manufactured == 1) {
									document.getElementById('html_production_select_manual_item_already_manufactured').cheked = true;
								}

								document.getElementById('html_production_select_manual_item_already_prepared').cheked = false;

								if (r.message.manuf_already_prepared == 1) {
									document.getElementById('html_production_select_manual_item_already_prepared').cheked = true;
								}

								// Entry warehouse
								if (me.frm.doc.document_type == 'PRODUCTION') {
									document.getElementById('html_production_select_manual_item_entry_whs_id').value = me.journal_production_entry_warehouse_id;
								}else if (me.frm.doc.document_type == 'PRODUCTION_REQ') {
									document.getElementById('html_production_select_manual_item_entry_whs_id').value = me.journal_production_request_entry_warehouse_id;
								}

								// Output warehouse
								if (me.frm.doc.document_type == 'PRODUCTION') {
									document.getElementById('html_production_select_manual_item_output_whs_id').value = me.journal_production_output_warehouse_id;
								}else if (me.frm.doc.document_type == 'PRODUCTION_REQ') {
									document.getElementById('html_production_select_manual_item_output_whs_id').value = me.journal_production_request_output_warehouse_id;
								}


								if (r.message.spec_entry_warehouse_id != '') {
									document.getElementById('html_production_select_manual_item_entry_whs_id').value = r.message.spec_entry_warehouse_id;
								}

								if (r.message.spec_output_warehouse_id != '') {
									document.getElementById('html_production_select_manual_item_output_whs_id').value = r.message.spec_output_warehouse_id;
								}

								document.getElementById('html_production_select_manual_item_scheduling_priority').value = r.message.manuf_default_priority;

								document.getElementById('html_production_select_manual_item_single_delay').value = r.message.manuf_single_delay;

								if (r.message.manuf_single_delay != 0) {
									document.getElementById('html_production_select_manual_item_scheduling_mode').value = 2;
								}

								document.getElementById('html_production_select_manual_item_single_delay').readOnly = true;
								if (document.getElementById('html_production_select_manual_item_scheduling_mode').value == 2) {
									document.getElementById('html_production_select_manual_item_single_delay').readOnly = false;

								}

							}


						}
		});
	}

	// ***************************************************************************************************************************************
	// Get Manuf release data
	// ***************************************************************************************************************************************

	get_manuf_release_data()
	{
		let me = this;

		let method = me.path_production + '.ioi_production_get_manuf_bom_routing_data';

		frappe.call({  	method: method,
						args: {	"manuf_release_id" : document.getElementById('html_production_select_manual_item_manuf_release_id').value
								},
						async: false,
						callback:function(r)	{

							// Manuf release
							document.getElementById('html_production_select_manual_item_manuf_release_description').value = r.message.manuf_release_description;
							document.getElementById('html_production_select_manual_item_manuf_release_status_description').value = r.message.manuf_release_status_description;
							document.getElementById('html_production_select_manual_item_manuf_release_status_id').value = r.message.manuf_release_status_id;
							document.getElementById('html_production_select_manual_item_manuf_release_status_bgcolor').value = r.message.manuf_release_status_bgcolor;
							document.getElementById('html_production_select_manual_item_manuf_release_status_description').style.backgroundColor = r.message.manuf_release_status_bgcolor;


							// BOM release
							document.getElementById('html_production_select_manual_item_bom_release_id').value = r.message.bom_release_id;
							document.getElementById('html_production_select_manual_item_bom_release_description').value = r.message.bom_release_description;
							document.getElementById('html_production_select_manual_item_bom_release_status_description').value = r.message.bom_release_status_description;
							document.getElementById('html_production_select_manual_item_bom_release_status_id').value = r.message.bom_release_status_id;
							document.getElementById('html_production_select_manual_item_bom_release_status_bgcolor').value = r.message.bom_release_status_bgcolor;
							document.getElementById('html_production_select_manual_item_bom_release_status_description').style.backgroundColor = r.message.bom_release_status_bgcolor;


							// Routing release
							document.getElementById('html_production_select_manual_item_routing_release_id').value = r.message.routing_release_id;
							document.getElementById('html_production_select_manual_item_routing_release_description').value = r.message.routing_release_description;
							document.getElementById('html_production_select_manual_item_routing_release_status_description').value = r.message.routing_release_status_description;
							document.getElementById('html_production_select_manual_item_routing_release_status_id').value = r.message.routing_release_status_id;
							document.getElementById('html_production_select_manual_item_routing_release_status_bgcolor').value = r.message.routing_release_status_bgcolor;
							document.getElementById('html_production_select_manual_item_routing_release_status_description').style.backgroundColor = r.message.routing_release_status_bgcolor;


							document.getElementById('html_production_select_manual_item_item_id').value = r.message.item_id;
							me.get_item_data();

						}
		});

	}

	// ***************************************************************************************************************************************
	// Convert header quantities
	// ***************************************************************************************************************************************

	convert_header_qties()
	{
		let unit_coeff = 1;

		if ((this.frm.doc.unit_coeff) && (parseFloat(this.frm.doc.unit_coeff) != 0)) {
			unit_coeff = parseFloat(this.frm.doc.unit_coeff);
		}

		let stock_unit_coeff = 1;

		if ((this.frm.doc.stock_unit_coeff) && (parseFloat(this.frm.doc.stock_unit_coeff) != 0)) {
			stock_unit_coeff = parseFloat(this.frm.doc.stock_unit_coeff);
		}

		let stock_ordered_qty = parseFloat(this.frm.doc.ordered_qty) * parseFloat(unit_coeff) / parseFloat(stock_unit_coeff);
		let stock_part_over_ordered_qty = parseFloat(this.frm.doc.part_over_ordered_qty) * parseFloat(unit_coeff) / parseFloat(stock_unit_coeff);
		let stock_produced_good_qty = parseFloat(this.frm.doc.produced_good_qty) * parseFloat(unit_coeff) / parseFloat(stock_unit_coeff);
		let stock_produced_tocheck_qty = parseFloat(this.frm.doc.produced_tocheck_qty) * parseFloat(unit_coeff) / parseFloat(stock_unit_coeff);
		let stock_produced_bad_qty = parseFloat(this.frm.doc.produced_bad_qty) * parseFloat(unit_coeff) / parseFloat(stock_unit_coeff);

		let stock_delivered_qty = parseFloat(this.frm.doc.delivered_qty) * parseFloat(unit_coeff) / parseFloat(stock_unit_coeff);
		let stock_tocheck_qty = parseFloat(this.frm.doc.tocheck_qty) * parseFloat(unit_coeff) / parseFloat(stock_unit_coeff);
		let stock_bad_qty = parseFloat(this.frm.doc.bad_qty) * parseFloat(unit_coeff) / parseFloat(stock_unit_coeff);



		let still_to_produce = parseFloat(this.frm.doc.ordered_qty) - (parseFloat(this.frm.doc.produced_good_qty) + parseFloat(this.frm.doc.produced_tocheck_qty))

		if (parseFloat(still_to_produce) < 0) {
			still_to_produce = 0
		}

		this.frm.set_value('still_to_produce', parseFloat(still_to_produce));


		let still_to_deliver = (parseFloat(this.frm.doc.produced_good_qty) + parseFloat(this.frm.doc.produced_tocheck_qty)) - (parseFloat(this.frm.doc.delivered_qty) + parseFloat(this.frm.doc.tocheck_qty));

		if (parseFloat(still_to_deliver) < 0) {
			still_to_deliver = 0
		}

		this.frm.set_value('still_to_deliver', parseFloat(still_to_deliver));



		let stock_still_to_produce = parseFloat(this.frm.doc.still_to_produce) * parseFloat(unit_coeff) / parseFloat(stock_unit_coeff);
		let stock_still_to_deliver = parseFloat(this.frm.doc.still_to_deliver) * parseFloat(unit_coeff) / parseFloat(stock_unit_coeff);


		this.frm.set_value('stock_ordered_qty', parseFloat(stock_ordered_qty));
		this.frm.set_value('stock_part_over_ordered_qty', parseFloat(stock_part_over_ordered_qty));
		this.frm.set_value('stock_produced_good_qty', parseFloat(stock_produced_good_qty));
		this.frm.set_value('stock_produced_tocheck_qty', parseFloat(stock_produced_tocheck_qty));
		this.frm.set_value('stock_produced_bad_qty', parseFloat(stock_produced_bad_qty));
		this.frm.set_value('stock_delivered_qty', parseFloat(stock_delivered_qty));
		this.frm.set_value('stock_tocheck_qty', parseFloat(stock_tocheck_qty));
		this.frm.set_value('stock_bad_qty', parseFloat(stock_bad_qty));
		this.frm.set_value('stock_still_to_produce', parseFloat(stock_still_to_produce));
		this.frm.set_value('stock_still_to_deliver', parseFloat(stock_still_to_deliver));



	}

	// ***************************************************************************************************************************************
	// Set button spacer
	// ***************************************************************************************************************************************

	set_button_spacer(fieldname, height)
	{
		this.frm.fields_dict[fieldname].$wrapper.empty();

		let html = '<div style="overflow: auto; overflow-x: auto; height:' + height.toString() + 'px; width: 100%;"></div>';

		this.frm.fields_dict[fieldname].$wrapper.append(html);
	}

	// ***************************************************************************************************************************************
	// Direct registry produce qty
	// ***************************************************************************************************************************************

	direct_registry_produce_qty(tp)
	{
		let me = this;

		let title = '';


		if (tp.toUpperCase() == 'GOOD') {
			title = __('Direct good production registry');
		}else if (tp.toUpperCase() == 'TOCHECK') {
			title = __('Direct to check production registry');
		}else if (tp.toUpperCase() == 'BAD') {
			title = __('Direct bad production registry');
		}

		let fields = [];

		if ((tp.toUpperCase() == 'GOOD') || (tp.toUpperCase() == 'TOCHECK')) {
			fields = [	{	label: __("Already produced"),  fieldname: 'direct_reg_already_produced',	fieldtype: 'Data', "read_only": 1 },
						{	label: '', 						fieldname: 'col1',							fieldtype: 'Column Break'},
						{	label: __("Still to produce"), 	fieldname: 'direct_reg_still_to_produce',	fieldtype: 'Data', "read_only": 1 },
						{	label: '', 						fieldname: 'col2',							fieldtype: 'Column Break'},
						{	label: __("Qty produced now"), 	fieldname: 'direct_reg_produced_qty',		fieldtype: 'Float' },
						{	label: '', 						fieldname: 'msg_section',					fieldtype: 'Section Break',			collapsible: 0 },
						{	label: '', 						fieldname: 'direct_reg_msg',				fieldtype: 'HTML'},
					 ];
		}else{
			fields = [	{	label: __("Qty produced now"), 	fieldname: 'direct_reg_produced_qty',		fieldtype: 'Float' },
						{	label: '', 						fieldname: 'msg_section',					fieldtype: 'Section Break',			collapsible: 0 },
						{	label: '', 						fieldname: 'direct_reg_msg',				fieldtype: 'HTML'},
					 ];
		}

		var directProdRegDialog = new frappe.ui.Dialog({
			title: title,
			static: true,
			fields: fields,
			primary_action_label: __("Ok"),
			primary_action: function(){
				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				directProdRegDialog.set_df_property('msg_section', 'hidden', 1);
				directProdRegDialog.set_df_property('direct_reg_msg', 'hidden', 1);


				let value = cur_dialog.fields_dict['direct_reg_produced_qty'].value;

				if ((value == null) || (value == '')) {
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					frappe.msgprint({title: __("Message"), message: __("Qty is mandatory"), indicator: "red"});
					return false;
				}

				let final_qty = 0;

				if (tp.toUpperCase() == 'GOOD') {
					if (me.frm.doc.produced_good_qty) {
						final_qty = parseFloat(me.frm.doc.produced_good_qty);
					}
				}else if (tp.toUpperCase() == 'TOCHECK') {
					if (me.frm.doc.produced_tocheck_qty) {
						final_qty = parseFloat(me.frm.doc.produced_tocheck_qty);
					}
				}else if (tp.toUpperCase() == 'BAD') {
					if (me.frm.doc.produced_bad_qty) {
						final_qty = parseFloat(me.frm.doc.produced_bad_qty);
					}
				}

				if (parseFloat(value) < 0) {

					if (Math.abs(value) > Math.abs(final_qty)) {
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
						me.direct_registry_set_msg(directProdRegDialog, __("Negative production result not allowed"), 'red');
						return false;
					}
				}

				me.#sleep(200).then(() => {

					directProdRegDialog.hide();

					let method = me.path_production + '.ioi_production_direct_registry';

					frappe.call({  	method: method,
									args: {	"production_id": me.frm.doc.name,
											"tp": tp,
											"value": parseFloat(value)
									},
									async: false,
									callback:function(r)	{

										if (r.message.error != 0) {
											document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
											frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
										}else{
											window.location.reload();
										}

									}
					});


				});

			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){

				directProdRegDialog.hide();
			}

		});

		let form_width = "400px";

		if ((tp.toUpperCase() == 'GOOD') || (tp.toUpperCase() == 'TOCHECK')) {
			form_width = "600px";
		}

		directProdRegDialog.$wrapper.find('.modal-dialog').css("max-width", form_width).css("width", form_width);


		directProdRegDialog.show();

		this.#sleep(200).then(() => {

			let fct_value_keyup = function() {

				cur_dialog.set_df_property('msg_section', 'hidden', 1);
				cur_dialog.set_df_property('direct_reg_msg', 'hidden', 1);
				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].style.display = '';

				let value = this.value;

				if ((value != null) && (value != '')) {

					let final_qty = 0;

					if (tp.toUpperCase() == 'GOOD') {
						if (me.frm.doc.produced_good_qty) {
							final_qty = parseFloat(me.frm.doc.produced_good_qty);
						}
					}else if (tp.toUpperCase() == 'TOCHECK') {
						if (me.frm.doc.produced_tocheck_qty) {
							final_qty = parseFloat(me.frm.doc.produced_tocheck_qty);
						}
					}else if (tp.toUpperCase() == 'BAD') {
						if (me.frm.doc.produced_bad_qty) {
							final_qty = parseFloat(me.frm.doc.produced_bad_qty);
						}
					}

					if (parseFloat(value) < 0) {

						if (Math.abs(value) > Math.abs(final_qty)) {
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].style.display = 'none';
							me.direct_registry_set_msg(directProdRegDialog, __("Negative production result not allowed"), 'red');
							return false;
						}
					}else{

						if ((tp.toUpperCase() == 'GOOD') || (tp.toUpperCase() == 'TOCHECK')) {

							let produced_good_qty = 0;

							if (me.frm.doc.produced_good_qty) {
								produced_good_qty = parseFloat(me.frm.doc.produced_good_qty);
							}

							let produced_tocheck_qty = 0;

							if (me.frm.doc.produced_good_qty) {
								produced_tocheck_qty = parseFloat(me.frm.doc.produced_tocheck_qty);
							}

							let ordered_qty = 0;

							if (me.frm.doc.ordered_qty) {
								ordered_qty = parseFloat(me.frm.doc.ordered_qty);
							}

							if (parseFloat(produced_good_qty) + parseFloat(produced_tocheck_qty) + parseFloat(value) > parseFloat(ordered_qty)) {
								me.direct_registry_set_msg(directProdRegDialog, __("You are overproducing"), '#ffbd08');
								return false;
							}

						}

					}
				}
			}

			directProdRegDialog.fields_dict['direct_reg_produced_qty'].$input[0].onkeyup = fct_value_keyup;

			directProdRegDialog.set_df_property('msg_section', 'hidden', 1);
			directProdRegDialog.set_df_property('direct_reg_msg', 'hidden', 1);


			if ((tp.toUpperCase() == 'GOOD') || (tp.toUpperCase() == 'TOCHECK')) {

				let produced_qood_qty = 0;

				if (me.frm.doc.produced_good_qty != null) {
					produced_qood_qty = parseFloat(me.frm.doc.produced_good_qty);
				}


				if (tp.toUpperCase() == 'GOOD') {
					directProdRegDialog.set_value('direct_reg_already_produced', produced_qood_qty.toString() + ' ' + me.frm.doc.unit_id);
				}else{
					let tocheck_qty = 0
					if (me.frm.doc.produced_tocheck_qty != null) {
						tocheck_qty = parseFloat(me.frm.doc.produced_tocheck_qty);
					}
					directProdRegDialog.set_value('direct_reg_already_produced', tocheck_qty.toString() + ' ' + me.frm.doc.unit_id);
				}

				let ordered_qty = 0;

				if (me.frm.doc.ordered_qty != null) {
					ordered_qty = parseFloat(me.frm.doc.ordered_qty);
				}


				let produced_tocheck_qty = 0;

				if (me.frm.doc.produced_tocheck_qty != null) {
					produced_tocheck_qty = parseFloat(me.frm.doc.produced_tocheck_qty);
				}


				let still_to_produce = parseFloat(ordered_qty) - (parseFloat(produced_qood_qty) + parseFloat(produced_tocheck_qty))

				if (parseFloat(still_to_produce) < 0) {
					still_to_produce = 0;
				}

				directProdRegDialog.set_value('direct_reg_still_to_produce', still_to_produce.toString() + ' ' + me.frm.doc.unit_id);
			}

		});
	}

	direct_registry_set_msg(d, msg, color)
	{
		d.set_df_property('msg_section', 'hidden', 0);
		d.set_df_property('direct_reg_msg', 'hidden', 0);

		d.fields_dict['direct_reg_msg'].$wrapper.empty();

		let html = '';
		html += '<div style="width:100%" align="center">';
		html += '<label><font color="' + color + '">' + msg + '</font></label>'
		html += '</div>';

		d.fields_dict['direct_reg_msg'].$wrapper.append(html);
	}




	// ***************************************************************************************************************************************
	// Refresh ioistatus (Status)
	// ***************************************************************************************************************************************

	refresh_ioistatus()
	{
		let html = '';
		this.frm.fields_dict['html_ioistatus'].$wrapper.empty();


		let bgcolor = '';
		let color = '';
		let description = '';

		for (var i = 0; i < this.ioistatus.length; i++)
		{
			if (this.frm.doc.ioistatus == this.ioistatus[i][0])
			{
				description = this.ioistatus[i][1];
				bgcolor = this.ioistatus[i][2];
				color = this.ioistatus[i][3];
				break;
			}
		}


		html = '<table width=100%>';
		html += '<tr>';
		html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Status") +'</font></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100%>';
		html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
		html += '</div></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100% height=7px>&nbsp;</td>';
		html += '</tr>';

		html += '</table>';

		this.frm.fields_dict['html_ioistatus'].$wrapper.append(html);
	}

	// ***************************************************************************************************************************************
	// Disable frm except fields
	// ***************************************************************************************************************************************

	disable_frm_except_fields()
	{
		if ((document.getElementById('hidden_can_edit_document').value == '0') || (this.frm.doc.frozen_data == 1))
		{
			this.frm.fields.forEach((field) => {
				this.frm.set_df_property(field.df.fieldname, "read_only", "1");
			  });

			this.frm.disable_save();

			let me = this;

			let method = this.path_module_status_accessible_fields + '.ioi_module_status_accessible_fields_get_status_fields';

			frappe.call({  	method: method,
							args: {"doctype":this.frm.doctype, "ioistatus":this.frm.doc.ioistatus},
							async: false,
							callback:function(r)	{
														if (r.message.length > 0)
														{
															let field_child = [];

															for(var i = 0; i < r.message.length; i++)
															{
																// 0 : doctype_id, 1 : field_id, 2 : fieldname, 3 :fieldtype

																if (r.message[i][0] == me.frm.doctype)
																{
																	me.frm.set_df_property(r.message[i][1], 'read_only', 0);
																}else
																{	if (r.message[i][2] != null)
																	{
																		me.frm.set_df_property(r.message[i][2], 'read_only', 0);

																		if (r.message[i][3].toUpperCase() == 'TABLE')
																		{
																			me.frm.fields_dict[r.message[i][2]].grid.docfields.forEach((field) => {
																				if (field_child.indexOf(r.message[i][2] + "|" + field.fieldname) == -1)
																				{
																					me.frm.fields_dict[r.message[i][2]].grid.update_docfield_property(field.fieldname, "read_only", 1);
																				}
																			});

																			if (field_child.indexOf(r.message[i][2] + "|" + r.message[i][1]) == -1)
																			{
																				field_child.push(r.message[i][2] + "|" + r.message[i][1]);
																			}

																			me.frm.fields_dict[r.message[i][2]].grid.update_docfield_property(r.message[i][1], "read_only", 0);
																		}
																	}
																}
															}

															me.frm.enable_save();
														}
													}
			});

		}

	}

	// ***************************************************************************************************************************************
	// Display select label when readonly
	// ***************************************************************************************************************************************

	display_select_label()
	{
		for (var i = 0; i < this.frm.fields.length; i++)
		{
			if (this.frm.fields[i].df)
			{
				if (this.frm.fields[i].df.read_only)
				{
					if (this.frm.fields[i].df.read_only == 1)
					{
						let display = true;

						if (this.frm.fields[i].df.hidden)
						{
							if (this.frm.fields[i].df.hidden == 1)
							{
								display = false;
							}
						}

						if (display)
						{
							if (this.frm.fields[i].df.fieldname)
							{
								if (this.frm.fields_dict[this.frm.fields[i].df.fieldname])
								{
									if (this.frm.fields_dict[this.frm.fields[i].df.fieldname].df)
									{
										if (this.frm.fields_dict[this.frm.fields[i].df.fieldname].df.fieldtype.toUpperCase() == 'SELECT')
										{
											if (this.frm.fields[i].last_options)
											{
												let t = JSON.parse(this.frm.fields[i].last_options);

												for (var j = 0; j < t.length; j++)
												{
													if (t[j].value == this.frm.fields[i].value)
													{
														this.frm.fields_dict[this.frm.fields[i].df.fieldname].$wrapper[0].children[0].children[1].children[1].innerText = __(t[j].label);
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

	}

	// ***************************************************************************************************************************************
	// Display Sub Productions
	// ***************************************************************************************************************************************

	display_sub_productions()
	{
		this.frm.fields_dict['html_subproduct'].$wrapper.empty();

		let html = '';

		let data = [];

		if (this.frm.is_new()) {
			html = '<table width=100%><tr height=20px><td>&nbsp;' + __("No subproduct") + '</td></tr></table>';
		}else{

			let me = this;

			let method = this.path_production + '.ioi_production_get_sub_productions';

			frappe.call({  	method: method,
							args: {	"production_id": this.frm.doc.name
							},
							async: false,
							callback:function(r)	{

								data = r.message;

							}
			});

			if (data.length > 0) {
				html += '<div id="sub_productions_container" class="table table-bordered" data-custom-grid="true" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:300px;border: 1px solid #E6E9EC;">';
				html += '</div>';

			}else{
				html = '<table width=100%><tr height=20px><td>&nbsp;' + __("No subproduct") + '</td></tr></table>';
			}

		}


		this.frm.fields_dict['html_subproduct'].$wrapper.append(html);

		if (data.length > 0) {

			let format_cell = (cell, formatterParams, onRendered) => {

				if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_NAME') {

					cell.getElement().style.backgroundColor = cell.getRow().getData()['ioistatus_bgcolor'];

					if ((cell.getValue() != null) && (cell.getValue() != '')) {

						let nb_spaces = cell.getRow().getData()['nb_space'];

						let s = '';

						for (var i = 1; i <= nb_spaces; i++) {
							s += '&nbsp;';
						}

						s += '&#8627;&nbsp;';
						s += '<u>' + cell.getValue() + '</u>';

						return s;
					}

				}else if (cell.getColumn().getField().toUpperCase() == 'IOISTATUS') {

					cell.getElement().style.backgroundColor = cell.getRow().getData()['ioistatus_bgcolor'];

					if ((cell.getValue() != null) && (cell.getValue() != '')) {
						let s = cell.getRow().getData()['ioistatus_description'] + ' ( ' + cell.getValue() + ' )'

						return s;
					}

				}else if ((cell.getColumn().getField().toUpperCase() == 'ITEM_ID') || (cell.getColumn().getField().toUpperCase() == 'MANUFACTURED_REVISION_ID') ||
						  (cell.getColumn().getField().toUpperCase() == 'BOM_RELEASE_ID') || (cell.getColumn().getField().toUpperCase() == 'ROUTING_RELEASE_ID')) {

					if ((cell.getValue() != null) && (cell.getValue() != '')) {
						let s = '<u>' + cell.getValue() + '</u>';

						return s;
					}
				}

			}

			let cell_onclick = (e, cell) => {

				if ((cell.getColumn().getField().toUpperCase() == 'FORMATTED_NAME') || (cell.getColumn().getField().toUpperCase() == 'ITEM_ID') ||
					(cell.getColumn().getField().toUpperCase() == 'MANUFACTURED_REVISION_ID') || (cell.getColumn().getField().toUpperCase() == 'BOM_RELEASE_ID') ||
					(cell.getColumn().getField().toUpperCase() == 'ROUTING_RELEASE_ID')) {

					if ((cell.getValue() != null) && (cell.getValue() != '')) {

						let dc = '';

						if (cell.getColumn().getField().toUpperCase() == 'FORMATTED_NAME') {
							dc = 'ioi-production';
						}else if (cell.getColumn().getField().toUpperCase() == 'ITEM_ID') {
							dc = 'ioi-item';
						}else if (cell.getColumn().getField().toUpperCase() == 'MANUFACTURED_REVISION_ID') {
							dc = 'ioi-item-manufacturing-definition';
						}else if (cell.getColumn().getField().toUpperCase() == 'BOM_RELEASE_ID') {
							dc = 'ioi-bom-release';
						}else if (cell.getColumn().getField().toUpperCase() == 'ROUTING_RELEASE_ID') {
							dc = 'ioi-routing-release';
						}

						let url = '/app/' + dc + '/' + cell.getValue();

						window.open(url, "_blank");
					}

				}
			}


			let SubProductTable = new ioi.Tabulator('#sub_productions_container', {
				data: data,
				layout: 'fitDataStretch',
				movableColumns: false,
				columns: [
					{title: __('Name'), field: 'name', width: 300, visible: false},
					{title: __('Sub production'), field: 'formatted_name', width: 300, frozen: true, headerSort: false, formatter: format_cell, cellClick: cell_onclick},

					{title: __('Item'), field: 'item_id', width: 250, headerSort: false, formatter: format_cell, cellClick: cell_onclick},
					{title: __('Item description'), field: 'item_description', width: 350, headerSort: false},
					{title: __('Unit'), field: 'unit_id', width: 80, headerSort: false},
					{title: __('Ordered qty'), field: 'ordered_qty', width: 180, hozAlign: "right", headerSort: false},
					{title: __('Part over ordered qty'), field: 'part_over_ordered_qty', width: 180, hozAlign: "right", headerSort: false},
					{title: __('Produced good qty'), field: 'produced_good_qty', width: 180, hozAlign: "right", headerSort: false},
					{title: __('Produced to check qty'), field: 'produced_tocheck_qty', width: 180, hozAlign: "right", headerSort: false},
					{title: __('Produced bad qty'), field: 'produced_bad_qty', width: 180, hozAlign: "right", headerSort: false},
					{title: __('Bad qty in goal'), field: 'bad_qty_in_goal', width: 180, hozAlign: "right", headerSort: false},
					{title: __('Manuf revision'), field: 'manufactured_revision_id', width: 180, formatter: format_cell, headerSort: false, cellClick: cell_onclick},
					{title: __('BOM release'), field: 'bom_release_id', width: 180, formatter: format_cell, headerSort: false, cellClick: cell_onclick},
					{title: __('Routing release'), field: 'routing_release_id', width: 180, formatter: format_cell, headerSort: false, cellClick: cell_onclick},
					{title: __('Document date'), field: 'document_date', width: 150, headerSort: false},
					{title: __('Start after'), field: 'start_after', width: 180, headerSort: false},
					{title: __('Delivery date'), field: 'delivery_date', width: 180, headerSort: false},
					{title: __('Status'), field: 'ioistatus', width: 300, headerSort: false, formatter: format_cell},
					{title: __('Status description'), field: 'ioistatus_description', width: 300, visible: false},

				]
			})
		};

	}


	// ***************************************************************************************************************************************
	// Action after change status
	// ***************************************************************************************************************************************

	action_after_change_status()
	{
		if (this.frm.doctype.toUpperCase() == 'IOI PRODUCTION')
		{
			if (this.frm.doc.ioistatus == 1)
			{
				//this.module_execute_action('ASK_TO_CREATE_LINKED_DOCUMENT');
			}
		}
	}

	// ***************************************************************************************************************************************
	// Module execute action
	// ***************************************************************************************************************************************

	module_execute_action(action)
	{
		let me = this;

		let method = this.path_user_change_status_action + '.ioi_user_change_status_action_exists';

		frappe.call({  	method: method,
						args: {"doctype" : this.frm.doctype, "name" : this.frm.doc.name, "to_status" : this.frm.doc.ioistatus, "action" : action},
						async: false,
						callback:function(r)	{
													if (r.message == 1)
													{
														let msg = '';



														frappe.confirm(	msg,
																		() => 	{
																					// action to perform if Yes is selected

																					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

																					method = me.path_user_change_status_action + '.ioi_user_change_status_action_delete';

																					frappe.call({  	method: method,
																									args: {"doctype" : me.frm.doctype, "name" : me.frm.doc.name, "to_status" : me.frm.doc.ioistatus, "action" : action},
																									async: false,
																									callback:function(r)	{ 	}
																					});

																				},
																		() => 	{
																					// action to perform if No is selected or if click outsite the modal screen
																					method = me.path_user_change_status_action + '.ioi_user_change_status_action_delete';

																					frappe.call({  	method: method,
																									args: {"doctype" : me.frm.doctype, "name" : me.frm.doc.name, "to_status" : me.frm.doc.ioistatus, "action" : action},
																									async: false,
																									callback:function(r)	{ 	}
																					});

																				}
														);
													}
						}
		});

	}


	// ***************************************************************************************************************************************
	// New document
	// ***************************************************************************************************************************************

	new_document()
    {

		this.init_values_when_new_or_duplicate();

        if ((!this.frm.doc.document_type) || ((this.frm.doc.document_type) && (this.frm.doc.document_type.trim() == '')))
        {
			this.frm.set_value('document_type', 'PRODUCTION');
        }

        if ((this.frm.doc.prefix_id) && (this.frm.doc.prefix_id.trim() != ''))
        {	this.frm.set_df_property('document_type', 'read_only', 1);
            this.frm.set_df_property('prefix_id', 'read_only', 1);
        }else
        {	this.frm.set_df_property('document_type', 'read_only', 0);
            this.frm.set_df_property('prefix_id', 'read_only', 0);
        }

        if ((!this.frm.doc.identification) || ((this.frm.doc.identification) && (this.frm.doc.identification.trim() == '')))
        {	this.frm.set_value('identification', '?');
        }


        this.frm.set_df_property('identification', 'read_only', 1);

        this.frm.set_value('ioistatus', 0);
        this.frm.set_value('lib_ioistatus', __('In preparation'));
    }


	// ***************************************************************************************************************************************
	// Get next number
	// ***************************************************************************************************************************************

	get_next_number()
	{
		if (this.frm.is_new())
		{
			if (!this.frm.doc.level_tp)
			{	this.frm.doc.level_tp = 0;
			}

			if ((this.frm.doc.level_tp == 0) || (this.frm.doc.level_tp == 3))
			{
				this.frm.set_intro('', '');

				for (var i = 0; i < this.prefixes.length; i++)
				{
					if (this.prefixes[i][1] == this.frm.doc.prefix_id)
					{

						this.frm.set_value('identification', this.prefixes[i][2]+1);

						let id_journal = '';
						let id_prefix = '';
						let id_last_number = 0;
						let id_number_digits = 0;
						let id_formatted = '';

						id_journal = this.prefixes[i][0];

						this.frm.doc.journal_id = id_journal;

						this.frm.refresh_field('journal_id');
						this.frm.refresh_field('prefix_id');
						this.frm.refresh_field('id_prefix');
						this.frm.refresh_field('id_number');
						this.frm.refresh_field('identification');

						let me = this;

						this.load_production_journal_data();

						id_prefix = this.prefixes[i][1];

						this.frm.doc.division_id = this.prefixes[i][6];
						this.frm.doc.site_id = this.prefixes[i][7];


						this.frm.refresh_field('division_id');
						this.frm.refresh_field('site_id');

						this.frm.doc.allow_direct_registry = this.prefixes[i][8];
						this.frm.refresh_field('allow_direct_registry');

						this.fill_period();
						this.set_query('period_id', '', this.path_production + '.ioi_production_get_enabled_period', '{"division":"' + this.frm.doc.division_id + '"}');

                        id_last_number = this.prefixes[i][2]+1;

                        id_number_digits = this.prefixes[i][3];

                        if (id_number_digits != 0)
                        {
                            let n = id_number_digits - id_last_number.toString().trim().length;

                            if (n >= 0)
                            {
                                for (var j = 0; j < n; j++)
                                {	id_formatted += '0';
                                }
                            }
                        }

                        id_formatted += id_last_number.toString().trim();

                        this.frm.doc.level_tp = '0';
						this.frm.set_value('journal_id', id_journal);
						this.frm.set_value('id_prefix', id_prefix);
						this.frm.set_value('id_number', id_last_number);
						this.frm.set_value('identification', id_formatted);
						this.frm.set_df_property('identification', 'read_only', 1);
					}
				}
			}

		}
	}

	// ***************************************************************************************************************************************
	// Fill Period from document date
	// ***************************************************************************************************************************************

	fill_period()
	{
		if ((!this.frm.doc.document_date) || ((this.frm.doc.document_date) && (this.frm.doc.document_date.toString().trim() == '')))
		{
			this.frm.doc.period_id = '';
			this.frm.refresh_field('period_id');
		}else
		{
			let me = this;
			let method = this.path_stock_document + '.ioi_stock_document_get_period_from_date';

			let division = this.journal_division_id;

			if ((!division) || (division.trim() == '')) {
				division = silicon_ioi.ioiAccUtils.get_main_division_id();
			}


			this.frm.doc.division_id = division;

			frappe.call({  	method: method,
							args: {"division_id" : division, "document_date" : this.frm.doc.document_date},
							async: false,
							callback:function(r)	{
															if (r.message.length == 0)
															{
																me.frm.doc.period_id = '';
															}else
															{
																me.frm.doc.period_id = r.message[0].name;
															}
													}
			});

			this.frm.refresh_field('period_id');
		}
	}


	// ***************************************************************************************************************************************
	// Create subdocument (same level)
	// ***************************************************************************************************************************************

	create_subdocument_same_level()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_create_subdocument_same_level(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_create_subdocument_same_level();
		}
	}

	do_create_subdocument_same_level()
	{
		let sublevel_separator = '';
		let sublevel_nb_digit = 0;

		for (var i = 0; i < this.prefixes.length; i++)
		{
			if (this.prefixes[i][1] == this.frm.doc.prefix_id)
			{
				sublevel_separator = this.prefixes[i][4];
				sublevel_nb_digit = this.prefixes[i][5];
				break;
			}
		}

		let me = this;

		let new_identification = '';

        let amethod =  this.path_production + '.ioi_production_get_next_subdoc_samelevel';


		frappe.call({  	method: amethod,
						args: {"prefix_id": this.frm.doc.prefix_id, "identification" : this.frm.doc.identification, "sublevel_separator" : sublevel_separator, "sublevel_nb_digits": sublevel_nb_digit},
						async: false,
		   				callback: function(r)	{
													new_identification = r.message;
												}
		});

		let current_document_type = me.frm.doc.document_type;
		let current_prefix_id = me.frm.doc.prefix_id;
		let current_journal_id = me.frm.doc.journal_id;
		let current_identification = me.frm.doc.parent_id;

		let new_root = ''

		if ((!me.frm.doc.root_id) || ((me.frm.doc.root_id) && (me.frm.doc.root_id.trim() == '')))
		{
			new_root = me.frm.doc.parent_id;
		}else
		{
			new_root = me.frm.doc.root_id;
		}

		frappe.call({  	method: this.path_production + '.ioi_production_get_document',
						args: {"doctype": this.frm.doctype, "name": me.frm.doc.parent_id},
						async: false,
						callback: function(r){

							let keys = Object.keys(r.message);
							let values = Object.values(r.message);

							frappe.new_doc(me.frm.doctype, {}, doc => {

								doc["document_type"] = current_document_type;
								doc["journal_id"] = current_journal_id;
								doc["prefix_id"] = current_prefix_id;
								doc["id_prefix"] = current_prefix_id;
								doc["identification"] = new_identification;

								doc["parent_id"] = current_identification;
								doc["root_id"] = new_root;
								doc["level_tp"] = 1;


							});
						}
		});

	}


	// ***************************************************************************************************************************************
	// Create subdocument (sub level)
	// ***************************************************************************************************************************************

	create_subdocument_sub_level()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_create_subdocument_sub_level(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_create_subdocument_sub_level();
		}
	}

	do_create_subdocument_sub_level()
	{
		let sublevel_separator = '';
		let sublevel_nb_digit = 0;

		for (var i = 0; i < this.prefixes.length; i++)
		{
			if (this.prefixes[i][1] == this.frm.doc.prefix_id)
			{
				sublevel_separator = this.prefixes[i][4];
				sublevel_nb_digit = this.prefixes[i][5];
				break;
			}
		}

		let me = this;

		let new_identification = '';

        let amethod = this.path_production + '.ioi_production_get_next_subdoc_sublevel';


		frappe.call({  	method: amethod,
						args: {"prefix_id": this.frm.doc.prefix_id, "identification" : this.frm.doc.identification, "sublevel_separator" : sublevel_separator, "sublevel_nb_digits": sublevel_nb_digit},
						async: false,
		   				callback: function(r)	{
													new_identification = r.message;
												}
		});

		let current_document_type = me.frm.doc.document_type;
		let current_prefix_id = me.frm.doc.prefix_id;
		let current_journal_id = me.frm.doc.journal_id;
		let current_identification = me.frm.doc.name;

		let new_root = ''

		if ((!me.frm.doc.root_id) || ((me.frm.doc.root_id) && (me.frm.doc.root_id.trim() == '')))
		{
			new_root = me.frm.doc.name;
		}else
		{
			new_root = me.frm.doc.root_id;
		}

		frappe.call({  	method: this.path_production + '.ioi_production_get_document',
						args: {"doctype": this.frm.doctype, "name": this.frm.doc.name},
						async: false,
						callback: function(r){

							let keys = Object.keys(r.message);
							let values = Object.values(r.message);

							frappe.new_doc(me.frm.doctype, {}, doc => {

								doc["document_type"] = current_document_type;
								doc["journal_id"] = current_journal_id;
								doc["prefix_id"] = current_prefix_id;
								doc["id_prefix"] = current_prefix_id;
								doc["identification"] = new_identification;

								doc["parent_id"] = current_identification;
								doc["root_id"] = new_root;
								doc["level_tp"] = 2;

							});
						}
		});
	}


	// ***************************************************************************************************************************************
	// Change user Status
	// ***************************************************************************************************************************************

	change_user_status()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_change_user_status(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_change_user_status();
		}
	}

	do_change_user_status()
	{

		let me = this;

		var z = new frappe.ui.Dialog({
			'title': __("User status"),
			'fields': [
				{'fieldname': 'html_select_user_status', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;

				if (document.getElementById('user_status_nb_record').value == 0)
				{
					go = false;
				}

				if ((go) && (document.getElementById('user_status_nb_record').value != 0))
				{	go = false
					for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
					{

						if (document.getElementById('user_status_checked_id_' + i.toString()))
						{
							if (document.getElementById('user_status_checked_id_' + i.toString()).checked)
							{
								document.getElementById('selected_user_status').value = document.getElementById('user_status_id_' + i.toString()).value;
								go = true;
								break;
							}
						}
					}
				}

				if (go)
				{
					z.hide();

					method = me.path_module_user_status + '.ioi_module_user_status_change_status';

					let can_change = false;

					let cur_user_status = 0;

					if (me.frm.doc.ioiuserstatus)
					{
						cur_user_status = me.frm.doc.ioiuserstatus;
					}

					frappe.call({  	method: method,
									args: {"doctype": me.frm.doctype, "name" : me.frm.doc.name, "from_status" : cur_user_status, "to_status" : document.getElementById('selected_user_status').value, "silent_mode" : true },
									async: false,
										callback:function(r)	{
																		can_change = true;
																}
					});

					if (can_change)
					{
						window.location.reload();
					}
				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('user_status_nb_record'))
		{
			for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
			{
				if (document.getElementById('user_status_checked_id_' + i.toString()))
				{
					document.getElementById('user_status_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('user_status_id_' + i.toString()))
				{
					document.getElementById('user_status_id_' + i.toString()).remove();
				}

			}

			document.getElementById('user_status_nb_record').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:300px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=100% data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=5% align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=95% style="vertical-align: middle;">&nbsp;<b>' + __("User status") + '</b></td>';
		html += '</tr>';
		html += '</table>';



		let method = me.path_module_user_status + '.ioi_module_user_status_get_list';


		frappe.call({  	method: method,
						args: {	"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														html += '<table border=1 style="border: 1px solid #E8EAEB" width=100%>';

														let cur_userstatus = 0;

														if (me.frm.doc.ioiuserstatus)
														{
															cur_userstatus = me.frm.doc.ioiuserstatus;
														}

														let cpt = 0


														for (var i = 0; i < r.message.length; i++)
														{
															if (cur_userstatus != r.message[i][2])
															{
																html += '<tr style="height:30px">';

																html += '<td width=5% align="center" style="vertical-align: middle;">';
																html += '<input type="checkbox" id="user_status_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
																html += '       onclick=" ';
																html += '					for (var i = 0; i < document.getElementById(\'user_status_nb_record\').value; i++) ';
																html += '					{   if (document.getElementById(\'user_status_checked_id_\' + i.toString())) ';
																html += '						{ '
																html += '							if (document.getElementById(\'user_status_checked_id_\' + i.toString()).id != this.id) ';
																html += '							{ ';
																html += '								document.getElementById(\'user_status_checked_id_\' + i.toString()).checked = false; ';
																html += '							} ';
																html += '						} ';
																html += '					} ';
																html += '" ';

																html += '>';
																html += '<input type="hidden" id="user_status_id_' + cpt.toString() + '" value="' +  r.message[i][2] + '">';
																html += '</td>';

																html += '<td bgcolor="' + r.message[i][3] + '" width=95% style="vertical-align: middle;">&nbsp;<font color="' + r.message[i][4] + '">' + r.message[i][5] + ' ( ' + r.message[i][2] + ' )' + '</font></td>'

																html += '</tr>';
															}

															cpt++;
														}
														html += '<input type="hidden" id="user_status_nb_record" value="' + cpt.toString() + '">';
														html += '</table>';
													}else
													{
														html += '<input type="hidden" id="user_status_nb_record" value="0">';
													}
												}
		});

		html += '<input type="hidden" id="selected_user_status" value="">';
		html += '</div>';


		z.fields_dict.html_select_user_status.$wrapper.html(html);

		z.show();
	}

	format_number(fieldname)
	{
		this.frm.fields_dict[fieldname].$input_wrapper[0].children[1].innerText = this.frm.fields_dict[fieldname].value;
	}

	format_header_numbers()
	{
		this.format_number('ordered_qty');
		this.format_number('part_over_ordered_qty');
		this.format_number('produced_good_qty');
		this.format_number('produced_tocheck_qty');
		this.format_number('produced_bad_qty');
		this.format_number('unit_coeff');
		this.format_number('delivered_qty');
		this.format_number('tocheck_qty');
		this.format_number('bad_qty');


		this.format_number('stock_ordered_qty');
		this.format_number('stock_part_over_ordered_qty');
		this.format_number('stock_produced_good_qty');
		this.format_number('stock_produced_tocheck_qty');
		this.format_number('stock_produced_bad_qty');
		this.format_number('stock_unit_coeff');

		this.format_number('stock_delivered_qty');
		this.format_number('stock_tocheck_qty');
		this.format_number('stock_bad_qty');
	}

	static grid_format_float_qty(element, gridcell)
	{

		let original_value = element.innerText.trim()

		if (gridcell != null) {

			let fct_blur = function() {
				silicon_ioi.doctype.ioiStockDocumentDocType.grid_format_float_qty(element, gridcell);
			}

			gridcell.onblur = fct_blur;
		}


		if ((original_value != '') && (original_value != 0)) {

			original_value = original_value.replaceAll(' ', '');

			let idx_coma = -1;
			let idx_point = -1;

			if (original_value.indexOf(',') != -1) {
				idx_coma = original_value.indexOf(',');
			}

			if (original_value.indexOf('.') != -1) {
				idx_point = original_value.indexOf('.');
			}

			if ((idx_coma != -1) || (idx_point != -1)) {

				let is_point = false;

				if (idx_coma < idx_point) {

					if (idx_coma != -1) {
						original_value = original_value.replaceAll(',', '');
						is_point = true;
					}

				}else if (idx_coma > idx_point)
				{
					if (idx_point != -1) {
						original_value = original_value.replaceAll('.', '');
					}
				}

				let can_continue = false;

				if (is_point)
				{
					if (original_value.indexOf('.') != -1) {
						can_continue = true;
					}

				}else{
					if (original_value.indexOf(',') != -1) {
						can_continue = true;
					}

				}

				if (can_continue) {

					can_continue = true;

					let is_number = true;

					if (isNaN(original_value)) {

						if (original_value.indexOf('.') != -1) {
							original_value = original_value.replaceAll('.', ',');
						}else if (original_value.indexOf(',') != -1) {
							original_value = original_value.replaceAll(',', '.');
						}else{
							can_continue = false;
						}

						is_number = false;
					}

					if (can_continue) {

						let modified_value = parseFloat(original_value);

						if (!is_number) {

							if (modified_value.toString().indexOf('.') != -1) {
								modified_value = modified_value.toString().replaceAll('.', ',');
							}else if (modified_value.toString().indexOf(',') != -1) {
								modified_value = modified_value.toString().replaceAll(',', '.');
							}
						}

						element.innerText = modified_value;
					}
				}
			}
		}

	}


	refresh_qty_log()
	{
		let me = this;

		if (document.getElementById('production_qty_log_table_container')) {
			document.getElementById('production_qty_log_table_container').remove();
		}

		let html = '';

		html += '<div id="production_qty_log_table_container" class="table table-bordered" data-custom-grid="true" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:600px;border: 1px solid #E6E9EC;">';
		html += '</div>';

		this.frm.fields_dict['html_production_qty_log'].$wrapper.empty();
		this.frm.fields_dict['html_production_qty_log'].$wrapper.append(html);

		if (!this.frm.is_new()) {

			this.#sleep(200).then(() => {

				let data = [];

				let method = me.path_production_qty_log + '.ioi_production_qty_log_get_list';

				frappe.call({  	method: method,
								args: {	"production_id": me.frm.doc.name
								},
								async: false,
								callback:function(r)	{
									data = r.message;

								}
				});


				me.ioiTable = new ioi.Tabulator('#production_qty_log_table_container', {
					data: data,
					maxHeight: 600,
					rowHeight: null,
					selectableRows: 1,
					showProfiles: false,
					movableColumns: true,
					resizableColumns: true,
					autoRedraw: true,

					columns: [
						{title: __('Date / Time'), field: 'dt_creation', width: 125},
						{title: __('Step'), field: 'step_id', width: 150},
						{title: __('Qty kind'), field: 'tp', width: 100},
						{title: __('Qty'), field: 'qty_produced_now', width: 125, hozAlign: "right" },
						{title: __('Old produced qty'), field: 'old_produced_qty', width: 150, hozAlign: "right"},
						{title: __('New produced qty'), field: 'new_produced_qty', width: 150, hozAlign: "right"},
						{title: __('Unit'), field: 'unit_id', width: 100},
						{title: __('User'), field: 'modified_by', width: 250},
						{title: __('Operator'), field: 'operator_id', width: 250}
					]
				})
			});
		}
	}


	// ***************************************************************************************************************************************
	// Fill available status
	// ***************************************************************************************************************************************
	fill_bom_available_status()
	{
		let available_status_desc = '';
		let available_status_bgcolor = '';

		if ((!this.frm.doc.bom_available_status) || ((this.frm.doc.bom_available_status) && (this.frm.doc.bom_available_status == 0)))
		{
			available_status_desc = __("Uncalculated");
			available_status_bgcolor = '#DCDCDC';

		}else if (this.frm.doc.bom_available_status == 1)
		{
			available_status_desc = __("Nothing");
			available_status_bgcolor = '#FA6E6B';

		}else if (this.frm.doc.bom_available_status == 2)
		{
			available_status_desc = __("Partiel");
			available_status_bgcolor = '#759CFF';

		}else if (this.frm.doc.bom_available_status == 3)
		{
			available_status_desc = __("Full");
			available_status_bgcolor = '#5DE861';

		}

		let html = '';
		this.frm.fields_dict['html_bom_available_status'].$wrapper.empty();

		html = '<div style="overflow: auto; overflow-x: auto; height:100px;">';
		html += '	<label id="ioi_available_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("BOM Available status") + '</label>';
		html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + available_status_bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '		<label style="position: absolute; top: 7px; left: 10px;"><font color="black">' + available_status_desc + '</font></label>';
		html += '	</div>';


		let available_need_recompute = false;

		if (this.frm.doc.bom_detail)
		{
			for(var i = 0; i < this.frm.doc.bom_detail.length; i++)
			{
				if (this.frm.doc.bom_detail[i].to_execute == 1) {
					available_need_recompute = true;
					break;
				}

			}
		}

		if (available_need_recompute)
		{
			available_need_recompute = false;

			if ((!this.frm.doc.bom_available_calc_datetime) || ((this.frm.doc.bom_available_calc_datetime) && (this.frm.doc.bom_available_calc_datetime == ''))) {
				available_need_recompute = true;
			}else {
				available_need_recompute = true;
			}

			if (available_need_recompute) {
				if (this.is_dark_mode == 0) {
					html += '	<label id="ioi_available_need_recompile" style="position: absolute; top: 65px; left: 2px;"><font color="blue">' + __("BOM Availables will be recomputed") + '</font></label>';
				}else{
					html += '	<label id="ioi_available_need_recompile" style="position: absolute; top: 65px; left: 2px;"><font color="#FFFFFF">' + __("BOM Availables will be recomputed") + '</font></label>';

				}
				this.frm.fields_dict['bom_available_calc_datetime'].$wrapper[0].children[0].children[1].children[1].style.backgroundColor = '#FAD487';
			}

		}

		html += '</div>';


		this.frm.fields_dict['html_bom_available_status'].$wrapper.append(html);
	}


	// ***************************************************************************************************************************************
	// Fill BOM available scheduled status
	// ***************************************************************************************************************************************
	fill_bom_available_scheduled_status()
	{
		let available_scheduled_status_desc = '';
		let available_scheduled_status_bgcolor = '';

		if ((!this.frm.doc.bom_available_scheduled_status) || ((this.frm.doc.bom_available_scheduled_status) && (this.frm.doc.bom_available_scheduled_status == 0)))
		{
			available_scheduled_status_desc = __("Uncalculated");
			available_scheduled_status_bgcolor = '#DCDCDC';

		}else if (this.frm.doc.bom_available_scheduled_status == 1)
		{
			available_scheduled_status_desc = __("Nothing");
			available_scheduled_status_bgcolor = '#FA6E6B';

		}else if (this.frm.doc.bom_available_scheduled_status == 2)
		{
			available_scheduled_status_desc = __("Partiel");
			available_scheduled_status_bgcolor = '#759CFF';

		}else if (this.frm.doc.bom_available_scheduled_status == 3)
		{
			available_scheduled_status_desc = __("Full");
			available_scheduled_status_bgcolor = '#5DE861';

		}

		let html = '';
		this.frm.fields_dict['html_bom_available_scheduled_status'].$wrapper.empty();

		html = '<div style="overflow: auto; overflow-x: auto; height:70px;">';
		html += '	<label id="ioi_available_scheduled_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("BOM Available scheduled status") + '</label>';
		html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + available_scheduled_status_bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '		<label style="position: absolute; top: 7px; left: 10px;"><font color="black">' + available_scheduled_status_desc + '</font></label>';
		html += '	</div>';
		html += '</div>';

		this.frm.fields_dict['html_bom_available_scheduled_status'].$wrapper.append(html);
	}


	// ***************************************************************************************************************************************
	// Compute available
	// ***************************************************************************************************************************************
	compute_available()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_compute_available(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_compute_available();
		}
	}

	do_compute_available()
	{

		let me = this;

		frappe.msgprint({title: __("Message"), message : __("Calculation in progress, please wait ..."), indicator: "blue"});


		silicon_ioi.doctype.ioiProductionDocumentDocType.sleep_static(250).then(() => {

			let method = me.path_time_vector + '.ioi_time_vector_compute_available_for_one_production';

			frappe.call({  	method: method,
							args: {	"name": me.frm.doc.name	},
							async: false,
							callback:function(r) {
													window.location.reload();
												}
			});
		});
	}


	// ***************************************************************************************************************************************
	// Compute registered hours
	// ***************************************************************************************************************************************
	compute_remaining_hours()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_compute_remaining_hours(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_compute_remaining_hours();
		}
	}

	do_compute_remaining_hours()
	{

		let me = this;

		silicon_ioi.doctype.ioiProductionDocumentDocType.sleep_static(250).then(() => {

			let method = me.path_production + '.ioi_production_compute_remaining_hours';

			frappe.call({  	method: method,
							args: {	"name": me.frm.doc.name	},
							async: false,
							callback:function(r) {
													window.location.reload();
												}
			});
		});
	}


	// ***************************************************************************************************************************************
	// Change close date
	// ***************************************************************************************************************************************

	change_close_date()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_change_close_date(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_change_close_date();
		}

	}

	do_change_close_date()
	{
		let me = this;

		if (this.frm.doc.ioistatus != 4) {
			frappe.msgprint({title: __("Message"), message: __('Production status jas to be Closed (4)'), indicator: "red"});
			return false;
		}

		let can_change_close_date = 0;

		let method = this.path_production + '.ioi_production_can_change_close_date';

		frappe.call({  	method: method,
						args: {
						},
						async: false,
						callback:function(r)	{
							can_change_close_date = r.message;
						}
		});

		if (can_change_close_date != 1) {
			frappe.msgprint({title: __("Message"), message: __('"ioi Production Master Manager" role required for this action'), indicator: "red"});
			return false;
		}

		if ((!this.frm.doc.period_id) || ((this.frm.doc.period_id) && (this.frm.doc.period_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __('Period is mandatory'), indicator: "red"});
			return false;
		}

		let closed_on_dt = this.frm.doc.closed_on;

		let period_beginning_date = '';

		method = this.path_production  + '.ioi_production_get_period_data';

		frappe.call({  	method: method,
						args: {	"name": this.frm.doc.period_id
						},
						async: false,
						callback:function(r)	{
							period_beginning_date = r.message.beginning_date;
						}
		});


		var changeCloseDateDialog = new frappe.ui.Dialog({
			title: __("Change close date"),
			static: true,
			fields: [	{'fieldname': 'html_change_close_date', 'fieldtype': 'HTML'}
					],
			primary_action_label: __("Ok"),
			primary_action: function(){

				if ((!document.getElementById('html_change_close_date_date').value) || ((document.getElementById('html_change_close_date_date').value) && (document.getElementById('html_change_close_date_date').value.toString().trim() == ''))) {

					frappe.msgprint({title: __("Message"), message: __('Date is mandatory'), indicator: "red"});
					return false;
				}

				let dt = new Date(document.getElementById('html_change_close_date_date').value);

				let dt_period = new Date(period_beginning_date)

				if (dt < dt_period) {
					frappe.msgprint({title: __("Message"), message: __('Date has to be greater than the period beginning date'), indicator: "red"});
					return false;
				}

				let doc_dt = new Date(me.frm.doc.document_date)

				if (dt < doc_dt) {
					frappe.msgprint({title: __("Message"), message: __('Date has to be greater than the document date'), indicator: "red"});
					return false;
				}

				changeCloseDateDialog.hide();

				if (closed_on_dt != document.getElementById('html_change_close_date_date').value) {

					cur_frm.dirty();
					cur_frm.set_value('closed_on', document.getElementById('html_change_close_date_date').value);
					cur_frm.save();

				}
			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){

				changeCloseDateDialog.hide();
			}

		});

		if (document.getElementById('html_change_close_date_date_label')) {
			document.getElementById('html_change_close_date_date_label').remove();
		}

		if (document.getElementById('html_change_close_date_date')) {
			document.getElementById('html_change_close_date_date').remove();
		}

		let html = ""
		html += '<div style="overflow: auto; overflow-x: auto; height:70px; width: 100%;">';
		html += '	<div style="position: relative; top: 0px; left: 2px; width:290px;">';
		html += '		<label id="html_change_close_date_date_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Closed on") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 290px; height: 25px;"> ';
		html += '			<input id="html_change_close_date_date" type="date" class="input-with-feedback form-control bold" value="' + closed_on_dt.toString() + '">';
		html += '		</div>';
		html += '	</div>';
		html += "</div>"

		changeCloseDateDialog.fields_dict.html_change_close_date.$wrapper.html(html);
		changeCloseDateDialog.$wrapper.find('.modal-dialog').css("max-width", "335px").css("width", "335px");
		changeCloseDateDialog.show();


		me.#sleep(500).then(() => {

			let fct_keyup = function(event) {

				if (event.keyCode == 13) {
					changeCloseDateDialog.primary_action()
					return false;
				}
			}
			document.getElementById('html_change_close_date_date').onkeyup = fct_keyup;
			document.getElementById('html_change_close_date_date').focus();

		});



	}

	// ***************************************************************************************************************************************
	// Change goal
	// ***************************************************************************************************************************************

	change_goal()
	{
		if (this.frm.doc.ioistatus == 0) {

			let me = this;

			if (cur_frm.is_new() || cur_frm.is_dirty())	{
				let fct_callback = function () { me.do_change_goal(); };
				cur_frm.save('Save', fct_callback);
			}else {
				me.do_change_goal();
			}
		}else{
			frappe.msgprint({title: __("Message"), message: __("Production has to be in preparation"), indicator: "red"});
		}
	}

	do_change_goal()
	{
		let min_order_qty = 0;
		let multi_qty = 0;
		let stock_unit_id = '';
		let stock_unit_coef = 1;

		if ((this.frm.doc.item_id) && (this.frm.doc.item_id.trim() != '')) {

			let method = this.path_production + '.ioi_production_get_item_min_order_qty';

			frappe.call({  	method: method,
							args: {	"site_id": this.frm.doc.site_id,
									"item_id": this.frm.doc.item_id
							},
							async: false,
							callback:function(r)	{

								min_order_qty = parseFloat(r.message.ordered_qty);
								multi_qty = parseFloat(r.message.multi_qty);
								stock_unit_id = r.message.stock_unit_id;
								stock_unit_coef = parseFloat(r.message.stock_unit_coef);
							}
			});

		}

		if ((this.frm.doc.unit_id) && (this.frm.doc.unit_id.trim() != '')) {

			let unit_coef = 1;

			if ((this.frm.doc.unit_coeff) && (parseFloat(this.frm.doc.unit_coeff) != 0)) {
				unit_coef = parseFloat(this.frm.doc.unit_coeff);
			}

			let converted_ordered_qty = 0;

			if (parseFloat(min_order_qty) != 0) {
				converted_ordered_qty = parseFloat(min_order_qty) * parseFloat(stock_unit_coef) / parseFloat(unit_coef)
			}

			min_order_qty = parseFloat(converted_ordered_qty);

			let converted_multi_qty = 0;

			if (parseFloat(multi_qty) != 0) {
				converted_multi_qty = parseFloat(multi_qty) * parseFloat(stock_unit_coef) / parseFloat(unit_coef)
			}

			multi_qty = converted_multi_qty;
		}


		let has_subproduction = 0;
		let data = {};

		let method = this.path_production + '.ioi_production_has_subproduction';

		frappe.call({  	method: method,
						args: {	"name": this.frm.doc.name
						},
						async: false,
						callback:function(r)	{
							data = r.message;
						}
		});

		if (data.error != 0) {
			frappe.msgprint({title: __("Message"), message: data.error_message, indicator: "red"});
			return false;
		}

		has_subproduction = parseInt(data.has_subproduction);



		let me = this;

		var changegoalDialog = new frappe.ui.Dialog({
			title: __("Change goal"),
			static: true,
			fields: [{	label: __("Ordered qty"), fieldname: 'change_goal_ordered_qty',	fieldtype: 'Float' },
					 {	label: __("Update subproduction(s)"), fieldname: 'update_subproduction',	fieldtype: 'Check', "options":[0, 1], "default":1, "hidden": 1},
					 {	label: '', fieldname: 'col1',	fieldtype: 'Column Break'},
					 {	label: __("Min Ordered qty"), fieldname: 'change_goal_min_ordered_qty',	fieldtype: 'Float', "read_only": 1},
					 {	label: '', fieldname: 'col2',	fieldtype: 'Column Break'},
					 {	label: __("Multiple of"), fieldname: 'change_goal_multi_qty',	fieldtype: 'Float', "read_only": 1}
					],
			primary_action_label: __("Ok"),
			primary_action: function(){

				let value = cur_dialog.fields_dict['change_goal_ordered_qty'].value;

				if ((value == null) || (value == '')) {
					frappe.msgprint({title: __("Message"), message: __("Ordered qty is mandatory"), indicator: "red"});
					return false;
				}

				if (parseFloat(value) <= 0) {
					frappe.msgprint({title: __("Message"), message: __("Ordered qty has to be greater than zero"), indicator: "red"});
					return false;
				}

				let min_order_value = cur_dialog.fields_dict['change_goal_min_ordered_qty'].value;

				if (parseFloat(value) < parseFloat(min_order_value)) {
					frappe.msgprint({title: __("Message"), message: __("Ordered qty has to be greater or eaqual to") + ' ' + min_order_value.toString(), indicator: "red"});
					return false;
				}

				let multi_value = cur_dialog.fields_dict['change_goal_multi_qty'].value;

				if (parseFloat(multi_value) > 0) {
					if (parseFloat(value) % parseFloat(multi_value) != 0) {
						frappe.msgprint({title: __("Message"), message: __("Ordered qty has to be a multiple of") + ' ' + multi_value.toString(), indicator: "red"});
						return false;
					}
				}

				me.#sleep(200).then(() => {

					let update_subproduction = cur_dialog.fields_dict['update_subproduction'].value;

					changegoalDialog.hide();

					let method = me.path_production + '.ioi_production_change_goal';

					frappe.call({  	method: method,
									args: {	"production_id": me.frm.doc.name,
											"new_goal_value": parseFloat(value),
											"has_subproduction": has_subproduction,
											"update_subproduction": update_subproduction
									},
									async: false,
									callback:function(r)	{

										if (r.message.error != 0) {
											frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
										}else{
											window.location.reload();
										}

									}
					});


				});

			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){

				changegoalDialog.hide();
			}

		});


		changegoalDialog.$wrapper.find('.modal-dialog').css("max-width", "600px").css("width", "600px");


		changegoalDialog.show();

		this.#sleep(200).then(() => {
			changegoalDialog.set_value('change_goal_ordered_qty', me.frm.doc.ordered_qty);
			changegoalDialog.set_value('change_goal_min_ordered_qty', min_order_qty);
			changegoalDialog.set_value('change_goal_multi_qty', multi_qty);

			if (has_subproduction == 1) {
				changegoalDialog.set_df_property('update_subproduction', 'hidden', 0);
			}

		});


	}

	// ***************************************************************************************************************************************
	// Change over ordered
	// ***************************************************************************************************************************************

	change_over_ordered()
	{
		if (this.frm.doc.ioistatus == 0) {

			let me = this;

			if (cur_frm.is_new() || cur_frm.is_dirty())	{
				let fct_callback = function () { me.do_change_over_ordered(); };
				cur_frm.save('Save', fct_callback);
			}else {
				me.do_change_over_ordered();
			}
		}else{
			frappe.msgprint({title: __("Message"), message: __("Production has to be in preparation"), indicator: "red"});
		}
	}

	do_change_over_ordered()
	{
		let min_order_qty = 0;
		let multi_qty = 0;
		let stock_unit_id = '';
		let stock_unit_coef = 1;

		let old_over_value = 0;

		if ((this.frm.doc.part_over_ordered_qty) && (parseFloat(this.frm.doc.part_over_ordered_qty) != 0)) {
			old_over_value = parseFloat(this.frm.doc.part_over_ordered_qty);
		}

		if ((this.frm.doc.item_id) && (this.frm.doc.item_id.trim() != '')) {

			let method = this.path_production + '.ioi_production_get_item_min_order_qty';

			frappe.call({  	method: method,
							args: {	"site_id": this.frm.doc.site_id,
									"item_id": this.frm.doc.item_id
							},
							async: false,
							callback:function(r)	{

								min_order_qty = parseFloat(r.message.ordered_qty);
								multi_qty = parseFloat(r.message.multi_qty);
								stock_unit_id = r.message.stock_unit_id;
								stock_unit_coef = parseFloat(r.message.stock_unit_coef);
							}
			});

		}

		if ((this.frm.doc.unit_id) && (this.frm.doc.unit_id.trim() != '')) {

			let unit_coef = 1;

			if ((this.frm.doc.unit_coeff) && (parseFloat(this.frm.doc.unit_coeff) != 0)) {
				unit_coef = parseFloat(this.frm.doc.unit_coeff);
			}

			let converted_ordered_qty = 0;

			if (parseFloat(min_order_qty) != 0) {
				converted_ordered_qty = parseFloat(min_order_qty) * parseFloat(stock_unit_coef) / parseFloat(unit_coef)
			}

			min_order_qty = parseFloat(converted_ordered_qty);

			let converted_multi_qty = 0;

			if (parseFloat(multi_qty) != 0) {
				converted_multi_qty = parseFloat(multi_qty) * parseFloat(stock_unit_coef) / parseFloat(unit_coef)
			}

			multi_qty = converted_multi_qty;
		}

		let has_subproduction = 0;
		let data = {};

		let method = this.path_production + '.ioi_production_has_subproduction';

		frappe.call({  	method: method,
						args: {	"name": this.frm.doc.name
						},
						async: false,
						callback:function(r)	{
							data = r.message;
						}
		});

		if (data.error != 0) {
			frappe.msgprint({title: __("Message"), message: data.error_message, indicator: "red"});
			return false;
		}

		has_subproduction = parseInt(data.has_subproduction);


		let me = this;

		var changeoverorderedDialog = new frappe.ui.Dialog({
			title: __("Change part over ordered qty"),
			static: true,
			fields: [{	label: __("Over ordered qty"), fieldname: 'change_over_ordered_qty',	fieldtype: 'Float',},
					 {	label: __("Update subproduction(s)"), fieldname: 'update_subproduction',	fieldtype: 'Check', "options":[0, 1], "default":1, "hidden": 1},
					 {	label: '', fieldname: 'col1',	fieldtype: 'Column Break'},
					 {	label: __("Ordered qty"), fieldname: 'change_goal_ordered_qty',	fieldtype: 'Float', "read_only": 1 },
					 {	label: '', fieldname: 'col2',	fieldtype: 'Column Break'},
					 {	label: __("Min Ordered qty"), fieldname: 'change_goal_min_ordered_qty',	fieldtype: 'Float', "read_only": 1},
					 {	label: '', fieldname: 'col3',	fieldtype: 'Column Break'},
					 {	label: __("Multiple of"), fieldname: 'change_goal_multi_qty',	fieldtype: 'Float', "read_only": 1}
					],
			primary_action_label: __("Ok"),
			primary_action: function(){

				let over_value = cur_dialog.fields_dict['change_over_ordered_qty'].value;

				let value = cur_dialog.fields_dict['change_goal_ordered_qty'].value;

				if ((over_value == null) || (over_value == '')) {
					over_value = 0;
				}

				if (parseFloat(over_value) < 0) {
					frappe.msgprint({title: __("Message"), message: __("Over ordered qty has to be greater than or equal to zero"), indicator: "red"});
					return false;
				}

				let new_ordered_value = parseFloat(value) - parseFloat(old_over_value) + parseFloat(over_value)

				if (parseInt(new_ordered_value) <= 0) {
					frappe.msgprint({title: __("Message"), message: __("New ordered qty") + ' ( ' + new_ordered_value.toString() + ' ) ' + _("has to be greater than zero"), indicator: "red"});
					return false;
				}

				let min_order_value = cur_dialog.fields_dict['change_goal_min_ordered_qty'].value;

				if (parseFloat(new_ordered_value) < parseFloat(min_order_value)) {
					frappe.msgprint({title: __("Message"), message: __("New ordered qty") + ' ( ' + new_ordered_value.toString() + ' ) ' + __("has to be greater or eaqual to") + ' ' + min_order_value.toString(), indicator: "red"});
					return false;
				}

				let multi_value = cur_dialog.fields_dict['change_goal_multi_qty'].value;

				if (parseFloat(multi_value) > 0) {
					if (parseFloat(new_ordered_value) % parseFloat(multi_value) != 0) {
						frappe.msgprint({title: __("Message"), message: __("New ordered qty") + ' ( ' +  new_ordered_value.toString() + ' ) ' + __("has to be a multiple of") + ' ' + multi_value.toString(), indicator: "red"});
						return false;
					}
				}

				me.#sleep(200).then(() => {

					let update_subproduction = cur_dialog.fields_dict['update_subproduction'].value;

					changeoverorderedDialog.hide();

					let method = me.path_production + '.ioi_production_change_goal';

					frappe.call({  	method: method,
									args: {	"production_id": me.frm.doc.name,
											"new_goal_value": parseFloat(new_ordered_value),
											"part_over_ordered_qty": parseFloat(over_value),
											"has_subproduction": has_subproduction,
											"update_subproduction": update_subproduction
									},
									async: false,
									callback:function(r)	{

										if (r.message.error != 0) {
											frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
										}else{
											window.location.reload();
										}

									}
					});


				});

			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){

				changeoverorderedDialog.hide();
			}

		});


		changeoverorderedDialog.$wrapper.find('.modal-dialog').css("max-width", "600px").css("width", "600px");


		changeoverorderedDialog.show();

		this.#sleep(200).then(() => {
			changeoverorderedDialog.set_value('change_over_ordered_qty', me.frm.doc.part_over_ordered_qty);
			changeoverorderedDialog.set_value('change_goal_ordered_qty', me.frm.doc.ordered_qty);
			changeoverorderedDialog.set_value('change_goal_min_ordered_qty', min_order_qty);
			changeoverorderedDialog.set_value('change_goal_multi_qty', multi_qty);

			if (has_subproduction == 1) {
				changegoalDialog.set_df_property('update_subproduction', 'hidden', 0);
			}

		});

	}

	static assign_event_on_bom_grid_first_column()
	{
		if (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') {

			if (cur_frm.doc.bom_detail)
			{
				for (var i = 0; i < cur_frm.doc.bom_detail.length; i++)
				{
					if (cur_frm.fields_dict['bom_detail'].grid)
					{
						if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i])
						{
							if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[0])
							{
								let idx = i;

								const cell = cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[0];

								if (cell.onmouseenter == null) {
									cell.onmouseenter = (e) => {
										let timeout = setTimeout(() => {
											silicon_ioi.doctype.ioiProductionDocumentDocType.display_custom_info(idx, 1, e);
										}, 600);
	
										cell.onmouseleave = () => {
											clearTimeout(timeout)};
									};
								}
							}
						}
					}

				}
			}


			silicon_ioi.doctype.ioiProductionDocumentDocType.sleep_static(500).then(() => {

				silicon_ioi.doctype.ioiProductionDocumentDocType.assign_event_on_bom_grid_first_column();
			});
		}
	}

	static assign_event_on_routing_grid_first_column()
	{
		if (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') {

			if (cur_frm.doc.routing_detail)
			{
				for (var i = 0; i < cur_frm.doc.routing_detail.length; i++)
				{
					if (cur_frm.fields_dict['routing_detail'].grid)
					{
						if (cur_frm.fields_dict['routing_detail'].grid.grid_rows[i])
						{
							if (cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[0])
							{
								let idx = i;

								const cell = cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[0];

								if (cell.onmouseenter == null) {
									cell.onmouseenter = (e) => {
										let timeout = setTimeout(() => {
											silicon_ioi.doctype.ioiProductionDocumentDocType.display_custom_info(idx, 2, e);
										}, 600);
	
										cell.onmouseleave = () => {
											clearTimeout(timeout)};
									};
								}
							}
						}
					}

				}
			}


			silicon_ioi.doctype.ioiProductionDocumentDocType.sleep_static(500).then(() => {

				silicon_ioi.doctype.ioiProductionDocumentDocType.assign_event_on_routing_grid_first_column();
			});
		}
	}

	static display_custom_info(idx, tooltip_id, e)
	{
		if (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') {

			let fn = ''

			if (tooltip_id == 1) {
				fn = 'bom_detail';
			}else{
				fn = 'routing_detail';
			}

			if (cur_frm.fields_dict[fn].grid)
			{
				if (cur_frm.fields_dict[fn].grid.grid_rows[idx])
				{
					let name = cur_frm.fields_dict[fn].grid.grid_rows[idx].doc['name'];

					let method = 'silicon_ioi.common.common.ioi_common_get_tooltip';

					frappe.call({  	method: method,
									args: {"tooltip_id": tooltip_id, "doctype": cur_frm.doctype, "parent": cur_frm.doc.name, "name": name},
									async: false,
									callback:function(r)	{
																let html = r.message.content;
																let time_display = r.message.time_display;

																if (html.trim() != '') {
																	silicon_ioi.ioiCommon.show_grid_info_popup(html, time_display, e);
																}
									}
					});
				}
			}
		}
	}

	static assign_event_on_grid_qty()
	{
		let qty_field = 'ordered_qty';
		let qty_field2 = 'delivered_qty';
		let qty_field3 = 'reserved_qty';

		if (cur_frm.doc.bom_detail)
		{
			for (var i = 0; i < cur_frm.doc.bom_detail.length; i++)
			{
				if (cur_frm.fields_dict['bom_detail'].grid)
				{
					if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i])
					{
						if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].columns[qty_field])
						{
							let idx = i;

							const cell = cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].columns[qty_field][0];

							if (cell.onmouseenter == null) {
								cell.onmouseenter = (e) => {
									let timeout = setTimeout(() => {
										silicon_ioi.doctype.ioiProductionDocumentDocType.display_stock_info(idx, e);
									}, 600);

									cell.onmouseleave = () => {
										clearTimeout(timeout)};
								};
							}
						}

						if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].columns[qty_field2])
						{
							let idx = i;

							const cell = cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].columns[qty_field2][0];

							if (cell.onmouseenter == null) {
								cell.onmouseenter = (e) => {
									let timeout = setTimeout(() => {
										silicon_ioi.doctype.ioiProductionDocumentDocType.display_stock_info(idx, e);
									}, 600);

									cell.onmouseleave = () => {
										clearTimeout(timeout)};
								};
							}
						}

						if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].columns[qty_field3])
						{
							let idx = i;

							const cell = cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].columns[qty_field3][0];

							if (cell.onmouseenter == null) {
								cell.onmouseenter = (e) => {
									let timeout = setTimeout(() => {
										silicon_ioi.doctype.ioiProductionDocumentDocType.display_stock_info(idx, e);
									}, 600);

									cell.onmouseleave = () => {
										clearTimeout(timeout)};
								};
							}
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiStockDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiProductionDocumentDocType.assign_event_on_grid_qty();
		});


	}


	static display_stock_info(idx, e)
	{
		if (cur_frm.fields_dict['bom_detail'].grid)
		{
			if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[idx])
			{
				if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[idx].doc['component_id'])
				{
					if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[idx].doc['component_id'].trim() != '')
					{
						let item_id = cur_frm.fields_dict['bom_detail'].grid.grid_rows[idx].doc['component_id']

						let from_warehouse_id = '';
						let from_warehouse_location_id = '';

						let to_warehouse_id = '';
						let to_warehouse_location_id = '';


						// Warehouse
						if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[idx].doc['warehouse_id'])
						{
							if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[idx].doc['warehouse_id'].trim() != '')
							{
								from_warehouse_id = cur_frm.fields_dict['bom_detail'].grid.grid_rows[idx].doc['warehouse_id'];
							}else
							{
								if (cur_frm.doc.output_warehouse_id)
								{
									if (cur_frm.doc.output_warehouse_id.trim() != '')
									{
										from_warehouse_id = cur_frm.doc.output_warehouse_id;
									}
								}
							}

						}else
						{
							if (cur_frm.doc.output_warehouse_id)
							{
								if (cur_frm.doc.output_warehouse_id.trim() != '')
								{
									from_warehouse_id = cur_frm.doc.output_warehouse_id;
								}
							}

						}


						let line_storedqtymode = 0;


						let me = this;

						let method = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_stock.ioi_warehouse_stock.ioi_warehouse_stock_get_site_fromwhs_fromloc_towhs_toloc_stock_for_an_item';


						frappe.call({  	method: method,
										args: {	"item_id": item_id,
												"from_warehouse_id": from_warehouse_id,
												"from_warehouse_location_id": from_warehouse_location_id,
												"to_warehouse_id": to_warehouse_id,
												"to_warehouse_location_id" : to_warehouse_location_id
										},
										async: false,
										callback:function(r)	{
																	let site_qt = 0;

																	if (line_storedqtymode == 0) {
																		if (r.message.site_stock_qty != 0) {
																			site_qt = r.message.site_stock_qty
																		}
																	}

																	let from_whs_qt = 0;

																	if (line_storedqtymode == 0) {
																		if (r.message.from_whs_stock_qty != 0) {
																			from_whs_qt = r.message.from_whs_stock_qty
																		}
																	}

																	let from_loc_qt = 0;

																	if (line_storedqtymode == 0) {
																		if (r.message.from_loc_stock_qty != 0) {
																			from_loc_qt = r.message.from_loc_stock_qty
																		}
																	}

																	let lib_qt = '';

																	if (line_storedqtymode == 0) {
																		lib_qt = 'Stock qty';
																	}

																	let html = '';

																	html += '<div style="overflow: auto; overflow-x: auto; height:100px; width: 100%;">';

																	if (r.message.is_obsolete == 1) {
																		html += '<div style="position: relative; top: 0px; left: 0px; width: 100%">';
																		html += '	<label style="position: absolute; top: 0px; left: 0px;"><font color="red">' + __("This component is obsolete") + '</font></label>';
																		html += '</div><br>';


																	}

																	html += '<table border=0 width=285px>';

																	// Site

																	html += '<tr height=20px>';

																	html += '<td colspan=2>&nbsp;<u><b>' + __("Current stock") + '</b></u></td>';
																	html += '<td width=125px align="right"><u><b>' + lib_qt + '</b></u>&nbsp;</td>';

																	html += '</tr>';

																	html += '<tr height=20px>';

																	html += '<td width=80px>&nbsp;<b>' + __("Site") + '</b></td>';
																	html += '<td width=80px>&nbsp;' + r.message.site_id + '</td>';
																	html += '<td width=125px align="right">' + site_qt + '&nbsp;' + r.message.site_unit + '&nbsp;</td>';


																	html += '</tr>';


																	// Whs
																	html += '<tr height=20px>';

																	html += '<td width=80px>&nbsp;<b>' + __("whs") + '</b></td>';
																	html += '<td width=80px>&nbsp;' + r.message.from_whs_id + '</td>';
																	html += '<td width=125px align="right">' + from_whs_qt + '&nbsp;' + r.message.from_whs_unit + '&nbsp;</td>';
																	html += '</tr>';

																	// Locations

																	html += '<tr height=20px>';

																	html += '<td width=80px>&nbsp;<b>' + __("loc") + '</b></td>';

																	let s = r.message.from_loc_id;

																	if ((s != null) && (s != ''))
																	{
																		s = s.substring(s.indexOf('•')+1, s.length)
																		s = s.trim();
																	}

																	html += '<td width=80px>&nbsp;' + s + '</td>';
																	html += '<td width=125px align="right">' + from_loc_qt + '&nbsp;' + r.message.from_loc_unit + '&nbsp;</td>';

																	html += '</tr>';


																	html += '</table>';

																	html += '</div>';

																	silicon_ioi.ioiCommon.show_grid_info_popup(html, 1, e)
										}
						});

					}
				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// Set the label with currency
	// ***************************************************************************************************************************************
	set_original_caption_with_currency(fieldname, format_float=false) {
		let caption = __(this.frm.fields_dict[fieldname]._label);
		caption += '&nbsp;<b>( ' + this.system_currency + ' )</b>';

		this.frm.fields_dict[fieldname].wrapper.children[0].children[0].children[0].innerHTML = caption;

		if (format_float) {
			this.format_float(fieldname)
		} else {
			const value = this.frm.fields_dict[fieldname].value
			this.frm.fields_dict[fieldname].$input_wrapper[0].children[1].innerText = value;
		}
	}

	format_float(fieldname) {
		// Set system settings for float precision
		const value = this.frm.fields_dict[fieldname].value
		this.frm.fields_dict[fieldname].$input_wrapper[0].children[1].innerText = window.format_number(value)
	}

	// ***************************************************************************************************************************************
	// Lien Detail : Set the label with unit
	// ***************************************************************************************************************************************
	detail_set_original_caption_with_unit(item, detail, fieldname)
	{
		if (this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form)
		{
			if (this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].disp_status)
			{
				if (this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].disp_status != 'None')
				{
					let caption = '';

					caption = __(this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname]._label);

					if ((fieldname.toUpperCase() != 'STOCK_ITEM_COST') && (fieldname.toUpperCase() != 'STOCK_ITEM_COST_STD_STAMP') &&
						(fieldname.toUpperCase() != 'STOCK_MANUAL_COST') && (fieldname.toUpperCase() != 'STOCK_COST_VALUE'))
					{
						if ((this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict['cons_unit_id'].value) && (this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict['cons_unit_id'].value.trim() != ''))
						{
							if (fieldname.toUpperCase() != 'COST_VALUE') {
								caption += '&nbsp;<b>( ' + this.system_currency + ' / ' + this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict['cons_unit_id'].value + ' )</b>';
							}else{
								caption += '&nbsp;<b>( ' + this.system_currency + ' / ' + this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict['ordered_qty'].value + ' ' + this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict['cons_unit_id'].value + ' )</b>';
							}
						}
					}else
					{
						if ((this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict['stock_unit_id'].value) && (this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict['stock_unit_id'].value.trim() != ''))
						{
							if (fieldname.toUpperCase() != 'STOCK_COST_VALUE') {
								caption += '&nbsp;<b>( ' + this.system_currency + ' / ' + this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict['stock_unit_id'].value + ' )</b>';
							}else{
								caption += '&nbsp;<b>( ' + this.system_currency + ' / ' + this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict['stock_ordered_qty'].value + ' ' + this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict['stock_unit_id'].value + ' )</b>';
							}
						}
					}

					this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[0].children[0].innerHTML = caption;

				}
			}
		}
	}

	compute_cost_now()
	{
		let me = this;

		let period_id = '';

		let method = me.path_production + '.ioi_production_get_period_from_current_date';

		frappe.call({  	method: method,
						args: {	"division_id": me.frm.doc.division_id
						},
						async: false,
						callback:function(r)	{
							period_id = r.message;
						}
		});


		var computecosttDialog = new frappe.ui.Dialog({
			title: __("Compute cost now"),
			static: false,
			fields: [{	label: __("Period"),
						fieldname: 'compute_cost_now_period_id',
						fieldtype: 'Link',
						options: 'ioi Period',
						"filters": {"division" : this.frm.doc.division_id},
						default: period_id
					}],
			primary_action_label: __("Ok"),
			primary_action: function(){

				let period = cur_dialog.fields_dict['compute_cost_now_period_id'].value;

				if (period == null) {
					period = '';
				}

				computecosttDialog.hide();

				me.#sleep(250).then(() => {

					let method = me.path_production + '.ioi_production_compute_all_cost';

					frappe.call({  	method: method,
									args: {	"parent_production_id": me.frm.doc.name,
											"period_id": period},
									async: false,
									callback:function(r)	{

										if (r.message.error == 1) {
											frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
										}else{
											window.location.reload();
										}

									}
					});


				});

			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){
				computecosttDialog.hide();
			}

		});

		computecosttDialog.$wrapper.find('.modal-dialog').css("max-width", "350px").css("width", "350px");
		computecosttDialog.show();

	}



	// ***************************************************************************************************************************************
	// Init value when new document or duplicate
	// ***************************************************************************************************************************************

	init_values_when_new_or_duplicate()
	{


        if ((this.frm.doc.level_tp != 1) && (this.frm.doc.level_tp != 2)) {
            let me = this;

            let amethod = this.path_production + '.ioi_production_document_get_fields_for_initialization'
            frappe.call({  	method: amethod,
                            args: {"doctype": this.frm.doctype, "doctype_detail" : this.frm.doctype + ' BOM'},
                            async: false,
                            callback:function(r){

                                if (r.message.length > 0) {

                                    for (var i = 0; i < r.message.length; i++) {

                                        if (me.frm.doctype == r.message[i].parent) {
                                            me.frm.set_value(r.message[i].fieldname, r.message[i].default_value);
                                            me.frm.refresh_field(r.message[i].fieldname);
                                        }
                                    }

                                    if (me.frm.doc.bom_detail)
                                    {
                                        for (var j = 0; j < me.frm.doc.bom_detail.length; j++) {

                                            for (var i = 0; i < r.message.length; i++) {

                                                if (me.frm.doctype + ' BOM' == r.message[i].parent) {

                                                    me.frm.doc.bom_detail[j][r.message[i].fieldname] = r.message[i].default_value;
                                                }
                                            }
                                        }

                                        me.frm.refresh_field('bom_detail');
                                    }
                                }
                            }
            });

            amethod = this.path_production + '.ioi_production_document_get_fields_for_initialization';

            frappe.call({  	method: amethod,
                            args: {"doctype": this.frm.doctype, "doctype_detail" : this.frm.doctype + ' Routing'},
                            async: false,
                            callback:function(r){

                                if (r.message.length > 0) {

                                    for (var i = 0; i < r.message.length; i++) {

                                        if (me.frm.doctype == r.message[i].parent) {
                                            me.frm.set_value(r.message[i].fieldname, r.message[i].default_value);
                                            me.frm.refresh_field(r.message[i].fieldname);
                                        }
                                    }

                                    if (me.frm.doc.routing_detail)
                                    {
                                        for (var j = 0; j < me.frm.doc.routing_detail.length; j++) {

                                            for (var i = 0; i < r.message.length; i++) {

                                                if (me.frm.doctype + ' Routing' == r.message[i].parent) {

                                                    me.frm.doc.routing_detail[j][r.message[i].fieldname] = r.message[i].default_value;
                                                }
                                            }
                                        }

                                        me.frm.refresh_field('routing_detail');
                                    }
                                }
                            }
            });

        }

	}

	create_stock_entry()
	{
		if (this.frm.is_dirty()) {
			let me = this;
			let fct_callback = function () { me.do_create_stock_entry(); };
			this.frm.save('Save', fct_callback);
		}else {
			this.do_create_stock_entry();
		}
	}

	do_create_stock_entry()
	{
		if ((this.frm.doc.ioistatus != 2) && (this.frm.doc.ioistatus != 3)) {
			frappe.msgprint({title: __("Message"), message: __("Status has to be in Production (2) or Ended (3)"), indicator: "red"});
			return false;
		}

		if ((!this.frm.doc.item_id) || ((this.frm.doc.item_id) && (this.frm.doc.item_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Item is mandatory"), indicator: "red"});
			return false;
		}

		let produced_good_qty = 0;
		let produced_tocheck_qty = 0;
		let produced_bad_qty = 0;

		if ((this.frm.doc.stock_produced_good_qty) && (this.frm.doc.stock_produced_good_qty != 0)) {
			produced_good_qty = parseFloat(this.frm.doc.stock_produced_good_qty);
		}

		if ((this.frm.doc.stock_produced_tocheck_qty) && (this.frm.doc.stock_produced_tocheck_qty != 0)) {
			produced_tocheck_qty = parseFloat(this.frm.doc.stock_produced_tocheck_qty)
		}

		if ((this.frm.doc.stock_produced_bad_qty) && (this.frm.doc.stock_produced_bad_qty != 0)) {
			produced_bad_qty = parseFloat(this.frm.doc.stock_produced_bad_qty)
		}

		let total_produced = parseFloat(produced_good_qty) + parseFloat(produced_tocheck_qty) + parseFloat(produced_bad_qty);


		let delivered_good_qty = 0;
		let delivered_tocheck_qty = 0;
		let delivered_bad_qty = 0;


		if ((this.frm.doc.stock_delivered_qty) && (this.frm.doc.stock_delivered_qty != 0)) {
			delivered_good_qty = parseFloat(this.frm.doc.stock_delivered_qty);
		}

		if ((this.frm.doc.stock_tocheck_qty) && (this.frm.doc.stock_tocheck_qty != 0)) {
			delivered_tocheck_qty = parseFloat(this.frm.doc.stock_tocheck_qty)
		}

		if ((this.frm.doc.stock_bad_qty) && (this.frm.doc.stock_bad_qty != 0)) {
			delivered_bad_qty = parseFloat(this.frm.doc.stock_bad_qty)
		}

		let still_good_qty = parseFloat(produced_good_qty) - parseFloat(delivered_good_qty);
		let still_tocheck_qty = parseFloat(produced_tocheck_qty) - parseFloat(delivered_tocheck_qty);
		let still_bad_qty = parseFloat(produced_bad_qty) - parseFloat(delivered_bad_qty);


		if (parseFloat(total_produced) == 0) {
			frappe.msgprint({title: __("Message"), message: __("No produced (good, tocheck, bad) qty"), indicator: "red"});
			return false;
		}


		let me = this;

		let parameters = {}

		let title = ("Create a stock entry");

		var ProductionCreateStockEntryDialog = new frappe.ui.Dialog({
			title: title,
			static: true,
			fields: [
						{	fieldname: 'production_create_stock_entry_content',	fieldtype: 'HTML'}
					],
			primary_action_label: __("Ok"),
			primary_action: function(){

				if (document.getElementById('production_create_stock_entry_content_panel_parameters_creation_mode').value == 1) {

					let stock_entry = document.getElementById('production_create_stock_entry_content_panel_parameters_stock_entry').value;

					if ((!stock_entry) || ((stock_entry) && (stock_entry.trim() == ''))) {
						frappe.msgprint({title: __("Message"), message: __("Stock entry is mandatory"), indicator: "red"});
						return false;
					}

					let error = 0;
					let error_msg = '';

					let method = me.path_production + '.ioi_production_check_stock_entry_allowed';

					frappe.call({  	method: method,
									args: {
										"document_date": me.frm.doc.document_date,
										"stock_entry": stock_entry
									},
									async: false,
									callback:function(r)	{
										error = r.message.error;
										error_msg = r.message.error_msg;
									}
					});

					if (error == 1) {
						frappe.msgprint({title: __("Message"), message: error_msg, indicator: "red"});
						return false;
					}

				}else{
					if ((!document.getElementById('production_create_stock_entry_content_panel_parameters_prefix').value) || ((document.getElementById('production_create_stock_entry_content_panel_parameters_prefix').value) && (document.getElementById('production_create_stock_entry_content_panel_parameters_prefix').value.trim() == ''))) {
						frappe.msgprint({title: __("Message"), message: __("Stock entry prefix is mandatory"), indicator: "red"});
						return false;

					}
				}

				if (!document.getElementById('production_create_stock_entry_content_panel_qties_still_good_qty').disabled) {

					let still_value = 0;

					if ((document.getElementById('production_create_stock_entry_content_panel_qties_still_good_qty').value) && (document.getElementById('production_create_stock_entry_content_panel_qties_still_good_qty').value != '')) {

						if (parseFloat(document.getElementById('production_create_stock_entry_content_panel_qties_still_good_qty').value) != 0) {
							still_value = parseFloat(document.getElementById('production_create_stock_entry_content_panel_qties_still_good_qty').value);
						}
					}

					if (parseFloat(still_value) != 0) {

						if (parseFloat(still_value) > parseFloat(still_good_qty)) {
							frappe.msgprint({title: __("Message"), message: __("Good qty to enter can not be higher than") + ' ' + still_good_qty.toString(), indicator: "red"});
							return false;
						}

						if (parseFloat(still_value) < 0) {
							if (Math.abs(parseFloat(still_value)) > Math.abs(parseFloat(delivered_good_qty))) {
								if (parseFloat(delivered_good_qty) != 0) {
									frappe.msgprint({title: __("Message"), message: __("Good qty to enter (absolute) can not be lower than") + ' -' + delivered_good_qty.toString(), indicator: "red"});
								}else{
									frappe.msgprint({title: __("Message"), message: __("Good qty to enter (absolute) can not be lower than") + ' ' + delivered_good_qty.toString(), indicator: "red"});
								}
								return false;
							}
						}

						if (document.getElementById('production_create_stock_entry_content_panel_parameters_creation_mode').value == 0) {

							if ((!document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').value) ||
								((document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').value) && (document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').value.trim() == ''))) {

								frappe.msgprint({title: __("Message"), message: __("Prefix (for good qty) is mandatory"), indicator: "red"});
								return false;
							}
						}
					}
				}


				if (!document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_qty').disabled) {

					let still_value = 0;

					if ((document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_qty').value) && (document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_qty').value != '')) {

						if (parseFloat(document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_qty').value) != 0) {
							still_value = parseFloat(document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_qty').value);
						}
					}

					if (parseFloat(still_value) != 0) {

						if (parseFloat(still_value) > parseFloat(still_tocheck_qty)) {
							frappe.msgprint({title: __("Message"), message: __("Tocheck qty to enter can not be higher than") + ' ' + still_tocheck_qty.toString(), indicator: "red"});
							return false;
						}

						if (parseFloat(still_value) < 0) {
							if (Math.abs(parseFloat(still_value)) > Math.abs(parseFloat(delivered_tocheck_qty))) {
								if (parseFloat(delivered_tocheck_qty) != 0) {
									frappe.msgprint({title: __("Message"), message: __("Tocheck qty to enter (absolute) can not be lower than") + ' -' + delivered_tocheck_qty.toString(), indicator: "red"});
								}else{
									frappe.msgprint({title: __("Message"), message: __("Tocheck qty to enter (absolute) can not be lower than") + ' ' + delivered_tocheck_qty.toString(), indicator: "red"});
								}
								return false;
							}
						}

						if (document.getElementById('production_create_stock_entry_content_panel_parameters_creation_mode').value == 0) {

							if ((!document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix').value) ||
								((document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix').value) && (document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix').value.trim() == ''))) {

								frappe.msgprint({title: __("Message"), message: __("Prefix (for tocheck qty) is mandatory"), indicator: "red"});
								return false;

							}
						}
					}

				}


				if (!document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_qty').disabled) {

					let still_value = 0;

					if ((document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_qty').value) && (document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_qty').value != '')) {

						if (parseFloat(document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_qty').value) != 0) {
							still_value = parseFloat(document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_qty').value);
						}
					}

					if (parseFloat(still_value) != 0) {

						if (parseFloat(still_value) > parseFloat(still_bad_qty)) {
							frappe.msgprint({title: __("Message"), message: __("Bad qty to enter can not be higher than") + ' ' + still_bad_qty.toString(), indicator: "red"});
							return false;
						}

						if (parseFloat(still_value) < 0) {
							if (Math.abs(parseFloat(still_value)) > Math.abs(parseFloat(delivered_bad_qty))) {
								if (parseFloat(delivered_bad_qty) != 0) {
									frappe.msgprint({title: __("Message"), message: __("Bad qty to enter (absolute) can not be lower than") + ' -' + delivered_bad_qty.toString(), indicator: "red"});
								}else{
									frappe.msgprint({title: __("Message"), message: __("Bad qty to enter (absolute) can not be lower than") + ' ' + delivered_bad_qty.toString(), indicator: "red"});
								}
								return false;
							}
						}

						if (document.getElementById('production_create_stock_entry_content_panel_parameters_creation_mode').value == 0) {

							if ((!document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix').value) ||
								((document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix').value) && (document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix').value.trim() == ''))) {

								frappe.msgprint({title: __("Message"), message: __("Prefix (for bad qty) is mandatory"), indicator: "red"});
								return false;

							}
						}

					}

				}

				me.#sleep(200).then(() => {

						let prefix_id = '';
						let good_prefix_id = '';
						let tocheck_prefix_id = '';
						let bad_prefix_id = '';
						let stock_entry = '';


						if (document.getElementById('production_create_stock_entry_content_panel_parameters_creation_mode').value == 0) {
							prefix_id = document.getElementById('production_create_stock_entry_content_panel_parameters_prefix').value;
							good_prefix_id = document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').value;
							tocheck_prefix_id = document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix').value;
							bad_prefix_id = document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix').value;
						}else{
							stock_entry = document.getElementById('production_create_stock_entry_content_panel_parameters_stock_entry').value;
						}

						let good_qty = 0;

						if ((document.getElementById('production_create_stock_entry_content_panel_qties_still_good_qty').value) && (document.getElementById('production_create_stock_entry_content_panel_qties_still_good_qty').value != '')) {

							if (parseFloat(document.getElementById('production_create_stock_entry_content_panel_qties_still_good_qty').value) != 0) {
								good_qty = parseFloat(document.getElementById('production_create_stock_entry_content_panel_qties_still_good_qty').value);
							}
						}


						let tocheck_qty = 0;

						if ((document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_qty').value) && (document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_qty').value != '')) {

							if (parseFloat(document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_qty').value) != 0) {
								tocheck_qty = parseFloat(document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_qty').value);
							}
						}

						let bad_qty = 0;

						if ((document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_qty').value) && (document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_qty').value != '')) {

							if (parseFloat(document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_qty').value) != 0) {
								bad_qty = parseFloat(document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_qty').value);
							}
						}

						let cb_distinct = 0;

						if (document.getElementById('production_create_stock_entry_content_panel_parameters_creation_mode').value == 0) {

							if (document.getElementById('production_create_stock_entry_content_panel_parameters_distinct').checked) {
								cb_distinct = 1;
							}
						}


						let data = {};

						let method = me.path_production + '.ioi_production_create_stock_entry';

						frappe.call({  	method: method,
										args: {	"production_id": me.frm.doc.name,
												"prefix_id": prefix_id,
												"stock_entry" : stock_entry,
												"item_id": me.frm.doc.item_id,
												"stock_unit_id": me.frm.doc.stock_unit_id,
												"good_qty": good_qty,
												"tocheck_qty": tocheck_qty,
												"bad_qty": bad_qty,
												"good_prefix_id": good_prefix_id,
												"tocheck_prefix_id": tocheck_prefix_id,
												"bad_prefix_id": bad_prefix_id,
												"distinct_document": cb_distinct
										},
										async: false,
										callback:function(r)	{
											data = r.message;
										}
						});

						if (data.error == 1) {
							frappe.msgprint({title: __("Message"), message: data.error_msg, indicator: "red"});
							return false;
						}else{

							for(var k = 0; k < data.stock_entries.length; k++) {
								window.open('/app/ioi-stock-entry/' + data.stock_entries[k][0], '_blank');
							}

							ProductionCreateStockEntryDialog.hide();
						}
				});
			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){

				ProductionCreateStockEntryDialog.hide();
			}

		});

		this.remove_html_element('production_create_stock_entry_content_panel_parameters_section');
		this.remove_html_element('production_create_stock_entry_content_panel_parameters_title');

		this.remove_html_element('production_create_stock_entry_content_panel_parameters');

		this.remove_html_element('production_create_stock_entry_content_panel_parameters_creation_mode_label');
		this.remove_html_element('production_create_stock_entry_content_panel_parameters_creation_mode');

		this.remove_html_element('production_create_stock_entry_content_panel_parameters_prefix_label');
		this.remove_html_element('production_create_stock_entry_content_panel_parameters_prefix');

		this.remove_html_element('production_create_stock_entry_content_panel_parameters_stock_entry_label');
		this.remove_html_element('production_create_stock_entry_content_panel_parameters_stock_entry');
		this.remove_html_element('production_create_stock_entry_content_panel_parameters_stock_entry_button');

		this.remove_html_element('production_create_stock_entry_content_panel_parameters_distinct_label');
		this.remove_html_element('production_create_stock_entry_content_panel_parameters_distinct');

		let method = this.path_production + '.ioi_production_get_parameter_from_journal';

		frappe.call({  	method: method,
					   	args: {"journal_id": this.frm.doc.journal_id},
						async: false,
						callback:function(r){
							parameters = r.message;
						}
		});


		let html = '';

		html += '<div id="production_create_stock_entry_content_panel_parameters_section" data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="production_create_stock_entry_content_panel_parameters_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Parameters") + '</b></label>';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'


		html += '<div id="production_create_stock_entry_content_panel_parameters" style="overflow-x: auto;height:80px;">';

		// Creation mode
		html += '	<div style="position: relative; top: 0px; left: 2px; width:220px;">';
		html += '		<label id="production_create_stock_entry_content_panel_parameters_creation_mode_label" style="position: absolute; top: 0px; left: 2px;">' + __("Creation mode") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 220px; height: 30px;"> ';
		html += '			<select id="production_create_stock_entry_content_panel_parameters_creation_mode" class="input-with-feedback form-control bold" style="width:220px"> ';
		html += '				<option value="0" selected>' + __("New stock entry") + '</option> ';
		html += '				<option value="1">' + __("Existing stock entry") + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		let entry_prefixes = []

		method = this.path_production + '.ioi_production_get_stock_entry_prefixes';

		frappe.call({  	method: method,
					   	args: {},
						async: false,
						callback:function(r){
							entry_prefixes = r.message;
						}
		});

		// Prefix
		html += '	<div style="position: relative; top: 0px; left: 230px; width:260px;">';
		html += '		<label id="production_create_stock_entry_content_panel_parameters_prefix_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Prefix") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 260px; height: 30px;"> ';
		html += '			<select id="production_create_stock_entry_content_panel_parameters_prefix" class="input-with-feedback form-control bold" style="width:260px; z-index:5;"> ';

		if (entry_prefixes.length > 0) {

			for (var i = 0; i < entry_prefixes.length; i++) {

				if (i == 0) {
					html += '	<option value="' + entry_prefixes[i].wms_entry_prefix + '" selected>' + entry_prefixes[i].wms_entry_prefix + '</option> ';
				}else{
					html += '	<option value="' + entry_prefixes[i].wms_entry_prefix + '">' + entry_prefixes[i].wms_entry_prefix + '</option> ';
				}
			}
		}


		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Distinct document
		html += '	<div style="position: relative; top: 30px; left: 502px; width:200px;">';
		html += '		<label id="production_create_stock_entry_content_panel_parameters_distinct_label" style="position: absolute; top: -3px; left: 24px;z-index:5;">' + __("Distinct document") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 1px; left: 2px; width: 200px; height: 25px;"> ';
		html += '			<input id="production_create_stock_entry_content_panel_parameters_distinct" type="checkbox" class="input-with-feedback form-control bold">';

		html += '		</div>';
		html += '	</div>';



		// Stock Entry
		html += '	<div style="position: relative; top: 0px; left: 230px; width:220px;">';
		html += '		<label id="production_create_stock_entry_content_panel_parameters_stock_entry_label" style="position: absolute; top: 0px; left: 2px;z-index:1;display:none;">' + __("Stock Entry") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="production_create_stock_entry_content_panel_parameters_stock_entry" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase; display:none; z-index:1;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 230px; width: 30px; height: 25px;"> ';
		html +='			<button id="production_create_stock_entry_content_panel_parameters_stock_entry_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none; display:none; z-index:1;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';

		this.remove_html_element('production_create_stock_entry_content_panel_qties_section');
		this.remove_html_element('production_create_stock_entry_content_panel_qties_title');

		this.remove_html_element('production_create_stock_entry_content_panel_qties');

		this.remove_html_element('production_create_stock_entry_content_panel_qties_produced_good_qty_label');
		this.remove_html_element('production_create_stock_entry_content_panel_qties_produced_good_qty');

		this.remove_html_element('production_create_stock_entry_content_panel_qties_produced_tocheck_qty_label');
		this.remove_html_element('production_create_stock_entry_content_panel_qties_produced_tocheck_qty');

		this.remove_html_element('production_create_stock_entry_content_panel_qties_produced_bad_qty_label');
		this.remove_html_element('production_create_stock_entry_content_panel_qties_produced_bad_qty');


		this.remove_html_element('production_create_stock_entry_content_panel_qties_entered_good_qty_label');
		this.remove_html_element('production_create_stock_entry_content_panel_qties_entered_good_qty');

		this.remove_html_element('production_create_stock_entry_content_panel_qties_entered_tocheck_qty_label');
		this.remove_html_element('production_create_stock_entry_content_panel_qties_entered_tocheck_qty');

		this.remove_html_element('production_create_stock_entry_content_panel_qties_entered_bad_qty_label');
		this.remove_html_element('production_create_stock_entry_content_panel_qties_entered_bad_qty');


		this.remove_html_element('production_create_stock_entry_content_panel_qties_still_good_qty_label');
		this.remove_html_element('production_create_stock_entry_content_panel_qties_still_good_qty');

		this.remove_html_element('production_create_stock_entry_content_panel_qties_still_tocheck_qty_label');
		this.remove_html_element('production_create_stock_entry_content_panel_qties_still_tocheck_qty');

		this.remove_html_element('production_create_stock_entry_content_panel_qties_still_bad_qty_label');
		this.remove_html_element('production_create_stock_entry_content_panel_qties_still_bad_qty');

		this.remove_html_element('production_create_stock_entry_content_panel_qties_still_good_prefix_label');
		this.remove_html_element('production_create_stock_entry_content_panel_qties_still_good_prefix');

		this.remove_html_element('production_create_stock_entry_content_panel_qties_still_tocheck_prefix_label');
		this.remove_html_element('production_create_stock_entry_content_panel_qties_still_tocheck_prefix');

		this.remove_html_element('production_create_stock_entry_content_panel_qties_still_bad_prefix_label');
		this.remove_html_element('production_create_stock_entry_content_panel_qties_still_bad_prefix');



		html += '<div id="production_create_stock_entry_content_panel_qties_section" data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="production_create_stock_entry_content_panel_qties_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Quantities to entry") + ' ( ' + this.frm.doc.stock_unit_id + ' )</b></label>';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'



		html += '<div id="production_create_stock_entry_content_panel_qties" style="overflow-x: auto;height:200px;">';

		// Produced Good
		html += '	<div style="position: relative; top: 0px; left: 2px; width:150px;">';
		html += '		<label id="production_create_stock_entry_content_panel_qties_produced_good_qty_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Produced Good qty") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 25px;"> ';
		html += '			<input id="production_create_stock_entry_content_panel_qties_produced_good_qty" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="' + produced_good_qty + '" disabled>';
		html += '		</div>';
		html += '	</div>';

		// Entered Good
		html += '	<div style="position: relative; top: 0px; left: 162px; width:150px;">';
		html += '		<label id="production_create_stock_entry_content_panel_qties_entered_good_qty_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Entered Good qty") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 25px;"> ';
		html += '			<input id="production_create_stock_entry_content_panel_qties_entered_good_qty" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="' + delivered_good_qty + '" disabled>';
		html += '		</div>';
		html += '	</div>';

		// Still Good
		html += '	<div style="position: relative; top: 0px; left: 322px; width:170px;">';
		html += '		<label id="production_create_stock_entry_content_panel_qties_still_good_qty_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Good qty to enter") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';

		if (parseFloat(produced_good_qty) == 0) {
			html += '			<input id="production_create_stock_entry_content_panel_qties_still_good_qty" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="' + still_good_qty + '" disabled>';
		}else{
			html += '			<input id="production_create_stock_entry_content_panel_qties_still_good_qty" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="' + still_good_qty + '">';
		}
		html += '		</div>';
		html += '	</div>';


		// Prefix (Good)
		html += '	<div style="position: relative; top: 0px; left: 502px; width:250px;">';
		html += '		<label id="production_create_stock_entry_content_panel_qties_still_good_prefix_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Prefix") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 250px; height: 30px;"> ';
		html += '			<select id="production_create_stock_entry_content_panel_qties_still_good_prefix" class="input-with-feedback form-control bold" style="width:250px; z-index:5;"> ';

		if (entry_prefixes.length > 0) {

			for (var i = 0; i < entry_prefixes.length; i++) {

				if (i == 0) {
					html += '	<option value="' + entry_prefixes[i].wms_entry_prefix + '" selected>' + entry_prefixes[i].wms_entry_prefix + '</option> ';
				}else{
					html += '	<option value="' + entry_prefixes[i].wms_entry_prefix + '">' + entry_prefixes[i].wms_entry_prefix + '</option> ';
				}
			}
		}

		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';



		// Produced To check
		html += '	<div style="position: relative; top: 65px; left: 2px; width:150px;">';
		html += '		<label id="production_create_stock_entry_content_panel_qties_produced_tocheck_qty_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Produced Tocheck qty") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 25px;"> ';
		html += '			<input id="production_create_stock_entry_content_panel_qties_produced_tocheck_qty" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="' + produced_tocheck_qty + '" disabled>';
		html += '		</div>';
		html += '	</div>';

		// Entered To check
		html += '	<div style="position: relative; top: 65px; left: 162px; width:150px;">';
		html += '		<label id="production_create_stock_entry_content_panel_qties_entered_tocheck_qty_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Entered Tocheck qty") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 25px;"> ';
		html += '			<input id="production_create_stock_entry_content_panel_qties_entered_tocheck_qty" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="' + delivered_tocheck_qty + '" disabled>';
		html += '		</div>';
		html += '	</div>';

		// Still To check
		html += '	<div style="position: relative; top: 65px; left: 322px; width:170px;">';
		html += '		<label id="production_create_stock_entry_content_panel_qties_still_tocheck_qty_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Tocheck qty to enter") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';

		if (parseFloat(produced_tocheck_qty) == 0) {
			html += '			<input id="production_create_stock_entry_content_panel_qties_still_tocheck_qty" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="' + still_tocheck_qty + '" disabled>';
		}else{
			html += '			<input id="production_create_stock_entry_content_panel_qties_still_tocheck_qty" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="' + still_tocheck_qty + '">';
		}
		html += '		</div>';
		html += '	</div>';



		// Prefix (Tocheck)
		html += '	<div style="position: relative; top: 65px; left: 502px; width:250px;">';
		html += '		<label id="production_create_stock_entry_content_panel_qties_still_tocheck_prefix_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Prefix") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 250px; height: 30px;"> ';
		html += '			<select id="production_create_stock_entry_content_panel_qties_still_tocheck_prefix" class="input-with-feedback form-control bold" style="width:250px; z-index:5;"> ';

		if (entry_prefixes.length > 0) {

			for (var i = 0; i < entry_prefixes.length; i++) {

				if (i == 0) {
					html += '	<option value="' + entry_prefixes[i].wms_entry_prefix + '" selected>' + entry_prefixes[i].wms_entry_prefix + '</option> ';
				}else{
					html += '	<option value="' + entry_prefixes[i].wms_entry_prefix + '">' + entry_prefixes[i].wms_entry_prefix + '</option> ';
				}
			}
		}

		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		// Produced Bad
		html += '	<div style="position: relative; top: 130px; left: 2px; width:150px;">';
		html += '		<label id="production_create_stock_entry_content_panel_qties_produced_bad_qty_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Produced Bad qty") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 25px;"> ';
		html += '			<input id="production_create_stock_entry_content_panel_qties_produced_bad_qty" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="' + produced_bad_qty + '" disabled>';
		html += '		</div>';
		html += '	</div>';

		// Entered Bad
		html += '	<div style="position: relative; top: 130px; left: 162px; width:150px;">';
		html += '		<label id="production_create_stock_entry_content_panel_qties_entered_bad_qty_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Entered Bad qty") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 25px;"> ';
		html += '			<input id="production_create_stock_entry_content_panel_qties_entered_bad_qty" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="' + delivered_bad_qty + '" disabled>';
		html += '		</div>';
		html += '	</div>';


		// Still Bad
		html += '	<div style="position: relative; top: 130px; left: 322px; width:170px;">';
		html += '		<label id="production_create_stock_entry_content_panel_qties_still_bad_qty_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Bad qty to enter") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';

		if (parseFloat(produced_bad_qty) == 0) {
			html += '			<input id="production_create_stock_entry_content_panel_qties_still_bad_qty" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="' + still_bad_qty + '" disabled>';
		}else{
			html += '			<input id="production_create_stock_entry_content_panel_qties_still_bad_qty" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="' + still_bad_qty + '">';
		}

		html += '		</div>';
		html += '	</div>';

		// Prefix (Bad)
		html += '	<div style="position: relative; top: 130px; left: 502px; width:250px;">';
		html += '		<label id="production_create_stock_entry_content_panel_qties_still_bad_prefix_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Prefix") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 250px; height: 30px;"> ';
		html += '			<select id="production_create_stock_entry_content_panel_qties_still_bad_prefix" class="input-with-feedback form-control bold" style="width:250px; z-index:5;"> ';

		if (entry_prefixes.length > 0) {

			for (var i = 0; i < entry_prefixes.length; i++) {

				if (i == 0) {
					html += '	<option value="' + entry_prefixes[i].wms_entry_prefix + '" selected>' + entry_prefixes[i].wms_entry_prefix + '</option> ';
				}else{
					html += '	<option value="' + entry_prefixes[i].wms_entry_prefix + '">' + entry_prefixes[i].wms_entry_prefix + '</option> ';
				}
			}
		}

		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';




		html += '</div>';

		ProductionCreateStockEntryDialog.fields_dict.production_create_stock_entry_content.$wrapper.html(html);
		ProductionCreateStockEntryDialog.$wrapper.find('.modal-dialog').css("max-width", "800px").css("width", "800px");
		ProductionCreateStockEntryDialog.show();

		this.#sleep(200).then(() => {


			if ((parameters.good_entry_prefix_id) && (parameters.good_entry_prefix_id.trim() != '')) {
				document.getElementById('production_create_stock_entry_content_panel_parameters_prefix').value = parameters.good_entry_prefix_id;
			}

			document.getElementById('production_create_stock_entry_content_panel_parameters_distinct').checked = false;

			if (parameters.generate_distinct_document == 1) {
				document.getElementById('production_create_stock_entry_content_panel_parameters_distinct').checked = true;
			}

			if ((parameters.good_entry_prefix_id) && (parameters.good_entry_prefix_id.trim() != '')) {
				document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').value = parameters.good_entry_prefix_id;
			}

			if ((parameters.tocheck_entry_prefix_id) && (parameters.tocheck_entry_prefix_id.trim() != '')) {
				document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix').value = parameters.tocheck_entry_prefix_id;
			}

			if ((parameters.bad_entry_prefix_id) && (parameters.bad_entry_prefix_id.trim() != '')) {
				document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix').value = parameters.bad_entry_prefix_id;
			}

			let fct_creation_mode_change = function() {
				if (this.value == 0) {

					document.getElementById('production_create_stock_entry_content_panel_parameters_prefix_label').style.display = 'block';
					document.getElementById('production_create_stock_entry_content_panel_parameters_prefix').style.display = 'block';
					document.getElementById('production_create_stock_entry_content_panel_parameters_distinct_label').style.display = 'block';
					document.getElementById('production_create_stock_entry_content_panel_parameters_distinct').style.display = 'block';
					document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix_label').style.display = 'block';
					document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').style.display = 'block';
					document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix_label').style.display = 'block';
					document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix').style.display = 'block';
					document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix_label').style.display = 'block';
					document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix').style.display = 'block';

					document.getElementById('production_create_stock_entry_content_panel_parameters_stock_entry_label').style.display = 'none';
					document.getElementById('production_create_stock_entry_content_panel_parameters_stock_entry').style.display = 'none';
					document.getElementById('production_create_stock_entry_content_panel_parameters_stock_entry_button').style.display = 'none';


				}else{
					document.getElementById('production_create_stock_entry_content_panel_parameters_prefix_label').style.display = 'none';
					document.getElementById('production_create_stock_entry_content_panel_parameters_prefix').style.display = 'none';
					document.getElementById('production_create_stock_entry_content_panel_parameters_distinct_label').style.display = 'none';
					document.getElementById('production_create_stock_entry_content_panel_parameters_distinct').style.display = 'none';
					document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix_label').style.display = 'none';
					document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').style.display = 'none';
					document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix_label').style.display = 'none';
					document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix').style.display = 'none';
					document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix_label').style.display = 'none';
					document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix').style.display = 'none';

					document.getElementById('production_create_stock_entry_content_panel_parameters_stock_entry_label').style.display = 'block';
					document.getElementById('production_create_stock_entry_content_panel_parameters_stock_entry').style.display = 'block';
					document.getElementById('production_create_stock_entry_content_panel_parameters_stock_entry_button').style.display = 'block';

				}
			}

			document.getElementById('production_create_stock_entry_content_panel_parameters_creation_mode').onchange = fct_creation_mode_change;


			if (document.getElementById('production_create_stock_entry_content_panel_parameters_prefix')) {

				if (document.getElementById('production_create_stock_entry_content_panel_parameters_creation_mode').value == 0) {


					let old_prefix_value = ''

					let fct_change_prefix = function() {

						if (document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').value == old_prefix_value) {
							document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').value = this.value;
						}

						if (document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix').value == old_prefix_value) {
							document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix').value = this.value;
						}

						if (document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix').value == old_prefix_value) {
							document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix').value = this.value;
						}

						if ((!document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').value) ||
							((document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').value) && (document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').value.trim() == ''))) {
							document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').value = this.value;
						}

						if ((!document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix').value) ||
							((document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix').value) && (document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix').value.trim() == ''))) {
							document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_prefix').value = this.value;
						}

						if ((!document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix').value) ||
							((document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix').value) && (document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix').value.trim() == ''))) {
							document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_prefix').value = this.value;
						}

						document.getElementById('production_create_stock_entry_content_panel_qties_still_good_prefix').focus();

					}

					document.getElementById('production_create_stock_entry_content_panel_parameters_prefix').onchange = fct_change_prefix;


					let fct_focus_prefix = function() {
						old_prefix_value = this.value;
					}

					document.getElementById('production_create_stock_entry_content_panel_parameters_prefix').onfocus = fct_focus_prefix;
				}

			}

			let fct_keydown = function(event) {
				if (event.keyCode == 13) {
					return false;
				}
			}

			let me = this;

			let fct_stock_entry_click = function() {

				let title = __("Select a stock entry");
				let form_width_pixel = '1000px';
				let table = "ioi Stock Entry";
				let fields = 'name, document_date, production_id, dossier_id';
				let fields_len = '250, 150, 250, 250';
				let fields_desc = __('Identification') + ',' + __('Document date') + ',' + __('Production') + ',' + __('Dossier');
				let where = "ioistatus = 0 and document_date >= '" + me.frm.doc.document_date + "'";
				let order_by = 'name desc';

				let fct_callback = function (return_value) {
					document.getElementById('production_create_stock_entry_content_panel_parameters_stock_entry').value = return_value;
					document.getElementById('production_create_stock_entry_content_panel_parameters_stock_entry').focus();
				}
				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('production_create_stock_entry_content_panel_parameters_stock_entry_button').onclick = fct_stock_entry_click;

			document.getElementById('production_create_stock_entry_content_panel_parameters_stock_entry').onkeydown = fct_keydown;

			document.getElementById('production_create_stock_entry_content_panel_qties_still_good_qty').onkeydown = fct_keydown;
			document.getElementById('production_create_stock_entry_content_panel_qties_still_tocheck_qty').onkeydown = fct_keydown;
			document.getElementById('production_create_stock_entry_content_panel_qties_still_bad_qty').onkeydown = fct_keydown;

			if (parameters.bypass_intermediate_screen == 1) {

				me.#sleep(200).then(() => {
					cur_dialog.primary_action();
				});
			}

		});



	}

	create_stock_output()
	{
		if (this.frm.is_dirty()) {
			let me = this;
			let fct_callback = function () { me.do_create_stock_output(); };
			this.frm.save('Save', fct_callback);
		}else {
			this.do_create_stock_output();
		}
	}

	do_create_stock_output()
	{

		if ((this.frm.doc.ioistatus != 1) && (this.frm.doc.ioistatus != 2)) {
			frappe.msgprint({title: __("Message"), message: __("Status has to be in Waiting (1) or in Production (2)"), indicator: "red"});
			return false;
		}


		let me = this;

		let title = ("Create a stock output");

		var ProductionCreateStockOutputDialog = new frappe.ui.Dialog({
			title: title,
			static: true,
			fields: [
						{	fieldname: 'production_create_stock_output_content',	fieldtype: 'HTML'}
					],
			primary_action_label: __("Ok"),
			primary_action: function(){

				if ((!document.getElementById('production_create_stock_output_content_panel_parameters_prefix').value) || ((document.getElementById('production_create_stock_output_content_panel_parameters_prefix').value) && (document.getElementById('production_create_stock_output_content_panel_parameters_prefix').value.trim() == ''))) {
					frappe.msgprint({title: __("Message"), message: __("Stock output prefix is mandatory"), indicator: "red"});
					return false;

				}

				me.#sleep(200).then(() => {

						let	prefix_id = document.getElementById('production_create_stock_output_content_panel_parameters_prefix').value;

						let data = {};

						let method = me.path_production + '.ioi_production_create_stock_output';

						frappe.call({  	method: method,
										args: {	"production_id": me.frm.doc.name,
												"prefix_id": prefix_id
										},
										async: false,
										callback:function(r)	{
											data = r.message;
										}
						});

						if (data.error == 1) {
							frappe.msgprint({title: __("Message"), message: data.error_msg, indicator: "red"});
							return false;
						}else{
							if ((data.stock_output) && (data.stock_output.trim() != '')) {
								window.open('/app/ioi-stock-output/' + data.stock_output);
							}
							ProductionCreateStockOutputDialog.hide();
						}
				});
			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){

				ProductionCreateStockOutputDialog.hide();
			}

		});

		this.remove_html_element('production_create_stock_output_content_panel_parameters_section');
		this.remove_html_element('production_create_stock_output_content_panel_parameters_title');

		this.remove_html_element('production_create_stock_output_content_panel_parameters');

		this.remove_html_element('production_create_stock_output_content_panel_parameters_prefix_label');
		this.remove_html_element('production_create_stock_output_content_panel_parameters_prefix');


		let html = '';

		html += '<div id="production_create_stock_output_content_panel_parameters_section" data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="production_create_stock_output_content_panel_parameters_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Parameter") + '</b></label>';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'


		html += '<div id="production_create_stock_output_content_panel_parameters" style="overflow-x: auto;height:80px;">';

		// Prefix
		html += '	<div style="position: relative; top: 0px; left: 2px; width:260px;">';
		html += '		<label id="production_create_stock_output_content_panel_parameters_prefix_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Prefix") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 260px; height: 30px;"> ';
		html += '			<select id="production_create_stock_output_content_panel_parameters_prefix" class="input-with-feedback form-control bold" style="width:260px; z-index:5;"> ';


		let method = this.path_production + '.ioi_production_get_stock_output_prefixes';

		frappe.call({  	method: method,
					   	args: {},
						async: false,
						callback:function(r){

							if (r.message) {

								for (var i = 0; i < r.message.length; i++) {

									if (i == 0) {
										html += '				<option value="' + r.message[i].wms_output_prefix + '" selected>' +  r.message[i].wms_output_prefix + '</option> ';
									}else{
										html += '				<option value="' + r.message[i].wms_output_prefix + '">' +  r.message[i].wms_output_prefix + '</option> ';
									}
								}

							}
						}
		});

		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';

		ProductionCreateStockOutputDialog.fields_dict.production_create_stock_output_content.$wrapper.html(html);
		ProductionCreateStockOutputDialog.$wrapper.find('.modal-dialog').css("max-width", "310px").css("width", "310px");
		ProductionCreateStockOutputDialog.show();

	}

	conditional_reactive_in_bom()
	{
		if (this.frm.is_dirty()) {
			let me = this;
			let fct_callback = function () { me.do_condtional_reactivate_in_bom(); };
			this.frm.save('Save', fct_callback);
		}else {
			this.do_condtional_reactivate_in_bom();
		}

	}

	do_condtional_reactivate_in_bom()
	{
		let top = 10;

		let msg = '';

		let s = __("Do you want to re-activate BOM ?");
		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
		top += 30;

		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';

		let me = this;

		frappe.confirm(	msg,
			() => 	{
						me.#sleep(200).then(() => {

							let method = me.path_production + '.ioi_production_contional_reactive_in_bom';

							let data = {};

							frappe.call({  	method: method,
											   args: {"production_id": me.frm.doc.name},
											async: false,
											callback:function(r){
												data = r.message;
											}
							});

							if (data.error == 1) {
								frappe.msgprint({title: __("Message"), message: data.error_msg, indicator: "red"});
								return false;
							}else{
								window.location.reload();
							}

						});
					},
			() => 	{
					}
		);
	}

	conditional_reactive_in_routing()
	{
		if (this.frm.is_dirty()) {
			let me = this;
			let fct_callback = function () { me.do_conditional_reactive_in_routing(); };
			this.frm.save('Save', fct_callback);
		}else {
			this.do_conditional_reactive_in_routing();
		}

	}

	do_conditional_reactive_in_routing()
	{

		let top = 10;

		let msg = '';

		let s = __("Do you want to re-activate Routing ?");
		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
		top += 30;

		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';

		let me = this;

		frappe.confirm(	msg,
			() => 	{
						me.#sleep(200).then(() => {

							let method = me.path_production + '.ioi_production_conditional_reactive_in_routing';

							let data = {};

							frappe.call({  	method: method,
											   args: {"production_id": me.frm.doc.name},
											async: false,
											callback:function(r){
												data = r.message;
											}
							});

							if (data.error == 1) {
								frappe.msgprint({title: __("Message"), message: data.error_msg, indicator: "red"});
								return false;
							}else{
								window.location.reload();
							}

						});
					},
			() => 	{
					}
		);

	}

	jit_keep_unkeep()
	{
		if ((this.frm.doc.ioistatus == 0) || (this.frm.doc.ioistatus == -2)) {

			let was_dirty = false;

			if (this.frm.is_dirty()) {
				was_dirty = true;
			}

			if (this.frm.doc.jit_keep_it == 1) {
				this.frm.set_value('jit_keep_it', 0);
			}else{
				this.frm.set_value('jit_keep_it', 1);
			}

			if (!was_dirty) {
				this.frm.save()
			}

		}else{
			frappe.msgprint({title: __("Message"), message: __('Production has to be in "Preparation (0)" or in "To handle (-2)"'), indicator: "red"});
		}
	}


	// ***************************************************************************************************************************************
	//
	// Production BOM
	//
	// ***************************************************************************************************************************************


	// ***************************************************************************************************************************************
	// Format BOM Grid
	// ***************************************************************************************************************************************

	grid_bom_layout(dm = null)
	{
		let me = this;

		if (dm == null)
		{
			dm = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;
		}

		if (cur_frm.doc.bom_detail)
		{

			for(var i = 0; i < cur_frm.doc.bom_detail.length; i++)
			{
				let bgcolor = '';

				for (var k = 0; k < me.ioistatus.length; k++)
				{
					if (cur_frm.doc.bom_detail[i].ioistatus == me.ioistatus[k][0])
					{
						bgcolor = me.ioistatus[k][2];
						break;
					}
				}


				if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i])
				{
					cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
					cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[1].style.color = "black";

					for (var m = 0; m < cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list.length; m++)
					{
						if (dm == 0) {
							cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FFFFFF';
						}else{
							cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#1C2126';
						}
					}


					for (var m = 0; m < cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list.length; m++)
					{
						if ((cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'CONS_UNIT_QTY') ||

							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'CONS_FIX_QTY') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'PARENT_CONS_UNIT_QTY') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'PARENT_CONS_FIX_QTY') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'CONS_UNIT_CONV_COEF') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'ORDERED_QTY') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'DELIVERED_QTY') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'RESERVED_QTY') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'OVERSTOCKING_FACTOR') ||

							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'STOCK_UNIT_QTY') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'STOCK_FIX_QTY') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'PARENT_STOCK_UNIT_QTY') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'PARENT_STOCK_FIX_QTY') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'STOCK_UNIT_CONV_COEF') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'STOCK_ORDERED_QTY') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'STOCK_DELIVERED_QTY') ||
							(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'STOCK_RESERVED_QTY'))
						{

							let fn = cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname;

							let gridelement = cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1].children[0];

							let gridcell = null;

						 	if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].columns[fn][0].children[0]) {


								if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0]) {

									if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0].children[0]) {
										gridcell = cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0].children[0];
									}
								}
							}

							silicon_ioi.doctype.ioiProductionDocumentDocType.grid_format_float_qty(gridelement, gridcell);
						}else{
							if ((cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'AVAILABLE_QTY') ||
								(cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'AVAILABLE_SCHEDULED_QTY')) {

								if (cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'AVAILABLE_QTY') {
									if ((!cur_frm.doc.bom_detail[i].available_status) || ((cur_frm.doc.bom_detail[i].available_status) && (cur_frm.doc.bom_detail[i].available_status == 0))) {
										cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#DCDCDC';
									}else if (cur_frm.doc.bom_detail[i].available_status == 1) {
										cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FA6E6B';
									}else if (cur_frm.doc.bom_detail[i].available_status == 2) {
										cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#759CFF';
									}else if (cur_frm.doc.bom_detail[i].available_status == 3) {
										cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#5DE861';
									}
								}else{

									if ((!cur_frm.doc.bom_detail[i].available_scheduled_status) || ((cur_frm.doc.bom_detail[i].available_scheduled_status) && (cur_frm.doc.bom_detail[i].available_scheduled_status == 0))) {
										cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#DCDCDC';
									}else if (cur_frm.doc.bom_detail[i].available_scheduled_status == 1) {
										cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FA6E6B';
									}else if (cur_frm.doc.bom_detail[i].available_scheduled_status == 2) {
										cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#759CFF';
									}else if (cur_frm.doc.bom_detail[i].available_scheduled_status == 3) {
										cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#5DE861';
									}
								}

								cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
							}

						}

					}


					if ((cur_frm.doc.bom_detail[i].item_budget_id) && (cur_frm.doc.bom_detail[i].item_budget_id.trim() != '')) {


						for (var m = 0; m < cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list.length; m++) {

							if (cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'COMPONENT_ID') {

								if ((cur_frm.doc.bom_detail[i].component_id) && (cur_frm.doc.bom_detail[i].component_id.trim() != '')) {

									for (var h = 0; h < silicon_ioi.ioiCommon.tab_item_budget_color.length; h++) {

										if (cur_frm.doc.bom_detail[i].item_budget_id == silicon_ioi.ioiCommon.tab_item_budget_color[h].name) {
			
											if (silicon_ioi.ioiCommon.tab_item_budget_color[h].color_on_item == 1) {
												cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = silicon_ioi.ioiCommon.tab_item_budget_color[h].bgcolor
												cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = '#000000';
											}
											break;	
										}
									}										

								}
								
							}
						}							
					}
				


					if ((cur_frm.doc.bom_detail[i].is_warning) && (cur_frm.doc.bom_detail[i].is_warning != 0))
					{
						for (var m = 0; m < cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list.length; m++)
						{
							if (cur_frm.doc.bom_detail[i].is_warning == 1)
							{	cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FCA874';
							}else
							{	cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FEADAD';
							}

							cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
						}
					}

					if ((this.frm.doc.frozen_bom == 1) || (this.frm.doc.ioistatus != 0)) {

						for (var m = 0; m < cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list.length; m++)
						{
							if (cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'MODE') {

								let fn = cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let mode_value = gridelement.innerText.trim()

									if ((!mode_value) || ((mode_value) && (mode_value == 0))) {
										gridelement.innerText = __('Purchased');
									}else if (mode_value == 1) {
										gridelement.innerText = __('Manufactured');
									}
								}
							}else if (cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'ITEM_KIND') {

								let fn = cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let item_kind_value = gridelement.innerText.trim()

									if ((!item_kind_value) || ((item_kind_value) && (item_kind_value == 0))) {
										gridelement.innerText = __('Normal');
									}else if (item_kind_value == 1) {
										gridelement.innerText = __('Consumable');
									}else if (item_kind_value == 2) {
										gridelement.innerText = __('Packing');
									}else if (item_kind_value == 3) {
										gridelement.innerText = __('Subproduct');
									}else if (item_kind_value == 4) {
										gridelement.innerText = __('Waste');
									}
								}
							}else if (cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'STORED_QTY_MODE') {

								let fn = cur_frm.fields_dict['bom_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let stored_qty_mode_value = gridelement.innerText.trim()

									if ((!stored_qty_mode_value) || ((stored_qty_mode_value) && (stored_qty_mode_value == 0))) {
										gridelement.innerText = __('Good');
									}else if (stored_qty_mode_value == 1) {
										gridelement.innerText = __('To check');
									}else if (stored_qty_mode_value == 2) {
										gridelement.innerText = __('Bad only');
									}
								}
							}
						}
					}
				}
			}
		}

		let fct_grid = function () { me.grid_bom_layout(dm); };
		setTimeout(fct_grid, 500);

	}

	// ***************************************************************************************************************************************
	// Format BOM Detail form
	// ***************************************************************************************************************************************

	format_bom_detail_form(frm, cdt, cdn)
	{
		this.frm.refresh_field('bom_detail');

		let item = locals[cdt][cdn];

		if ((item.is_warning) && (item.is_warning != 0))
		{
			let msg_bgcolor = '';
			let msg_fontcolor = ''

			if (item.is_warning == 1)
			{	msg_bgcolor = '#FFF5F0';
				msg_fontcolor = '#F57231';
			}else
			{	msg_bgcolor = '#FEADAD';
				msg_fontcolor = 'black';
			}

			if (this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form)
			{

				if (this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'])
				{
					this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.empty();

					let html = '';

					html = '<br><div align="center" style="overflow:auto; vertical-align: middle; height:28px;border-radius:6px;padding:4px;background-color:' + msg_bgcolor + ';">';
					html += '<font color="' + msg_fontcolor +'">' + item.warninfo + '</font>';
					html += '</div>';

					this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.append(html);
				}
			}
		}else
		{
			if (this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form)
			{
				if (this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'])
				{
					this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.empty();
				}
			}
		}

		this.#refresh_bom_detail_ioistatus(frm, cdt, cdn);
		this.#refresh_bom_detail_ioiuserstatus(frm, cdt, cdn);
		this.#refresh_bom_detail_production_routing(frm, cdt, cdn);
		this.form_bom_format_all_numbers(item.idx);

		this.detail_set_original_caption_with_unit(item, 'bom_detail', 'item_cost');
		this.detail_set_original_caption_with_unit(item, 'bom_detail', 'item_cost_std_stamp');
		this.detail_set_original_caption_with_unit(item, 'bom_detail', 'manual_cost');
		this.detail_set_original_caption_with_unit(item, 'bom_detail', 'cost_value');

		this.detail_set_original_caption_with_unit(item, 'bom_detail', 'stock_item_cost');
		this.detail_set_original_caption_with_unit(item, 'bom_detail', 'stock_item_cost_std_stamp');
		this.detail_set_original_caption_with_unit(item, 'bom_detail', 'stock_manual_cost');
		this.detail_set_original_caption_with_unit(item, 'bom_detail', 'stock_cost_value');



		if ((item.item_budget_id) && (item.item_budget_id.trim() != '')) {

			for (var h = 0; h < silicon_ioi.ioiCommon.tab_item_budget_color.length; h++) {

				if (item.item_budget_id == silicon_ioi.ioiCommon.tab_item_budget_color[h].name) {

					if (silicon_ioi.ioiCommon.tab_item_budget_color[h].color_on_item == 1) {
						this.update_item_manufacturer_ref_color(null, null, null, 'component_id', item, silicon_ioi.ioiCommon.tab_item_budget_color[h].bgcolor);
						

					}
					break;	
				}
			}	
		}else{
			this.update_item_manufacturer_ref_color(null, null, null, 'component_id', item, null);
			
		}									



		if ((this.frm.doc.frozen_bom == 1) || (this.frm.doc.ioistatus != 0)) {

			let mode_value = cur_frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].get_field('mode').value;

			if ((!mode_value) || ((mode_value) && (mode_value == 0))) {
				this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].get_field('mode').$input_wrapper[0].children[1].innerText = __('Purchased');
			}else if (mode_value == 1) {
				this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].get_field('mode').$input_wrapper[0].children[1].innerText = __('Manufactured');
			}

			let item_kind_value = cur_frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].get_field('item_kind').value;

			if ((!item_kind_value) || ((item_kind_value) && (item_kind_value == 0))) {
				this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].get_field('item_kind').$input_wrapper[0].children[1].innerText = __('Normal');
			}else if (item_kind_value == 1) {
				this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].get_field('item_kind').$input_wrapper[0].children[1].innerText = __('Consumable');
			}else if (item_kind_value == 2) {
				this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].get_field('item_kind').$input_wrapper[0].children[1].innerText = __('Packing');
			}else if (item_kind_value == 3) {
				this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].get_field('item_kind').$input_wrapper[0].children[1].innerText = __('Subproduct');
			}else if (item_kind_value == 4) {
				this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].get_field('item_kind').$input_wrapper[0].children[1].innerText = __('Waste');
			}

			let stored_qty_mode_value = cur_frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].get_field('stored_qty_mode').value;

			if ((!stored_qty_mode_value) || ((stored_qty_mode_value) && (stored_qty_mode_value == 0))) {
				this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].get_field('stored_qty_mode').$input_wrapper[0].children[1].innerText = __('Good');
			}else if (stored_qty_mode_value == 1) {
				this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].get_field('stored_qty_mode').$input_wrapper[0].children[1].innerText = __('To check');
			}else if (stored_qty_mode_value == 2) {
				this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].get_field('stored_qty_mode').$input_wrapper[0].children[1].innerText = __('Bad only');
			}

		}



		if (this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form)
		{

			this.bom_fill_line_available_status(item);
			this.bom_fill_line_available_scheduled_status(item);


			for (var i = 0; i < this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections.length; i++)
			{
				if (this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head)
				{
					this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.attr('data-custom-section-head', 'true');
					this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('border-radius', '6px');
					this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('padding', '4px');

					if (this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].is_collapsed())
					{	this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.click();
					}
				}

			}
		}

	}


	update_item_manufacturer_ref_color(frm, cdt, cdn, fieldname, it, color)
	{
		let item = '';
		let color_grid = ''

		if (!it)
		{	item = locals[cdt][cdn];
		}else
		{	item = it;
		}

		if (!color)
		{
			color_grid = '#FFFFFF';
		}else
		{
			color_grid = color;
		}

		if ((item[fieldname]) && (item[fieldname].trim() != '')) {

			if (this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form)
			{
				if (!color)
				{ 	color = '#F4F5F6';

					if (this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].df.read_only == 1)
					{
						color = '#F9FAFA';
					}
				}

				if (this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].df.read_only == 0)
				{
					if (this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[0].hasChildNodes())
					{	
						this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[0].children[0].children[0].children[0].style.backgroundColor = color;
					}else
					{
						this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].style.backgroundColor = color;
					}
				}else
				{	this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[1].children[1].style.backgroundColor = color;
				}
			}
		}

	}


	// ***************************************************************************************************************************************
	// BOM detail : add new line
	// ***************************************************************************************************************************************

	bom_detail_add(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		line.manual_line = 1;
		line.mode = 0;
		line.item_kind = 0;
		line.stored_qty_mode = 0;
		line.ioistatus = 0;
		line.ioiuserstatus = 0;
		line.warehouse_id = this.frm.doc.entry_warehouse_id;

		line.stock_unit_qty = 1;
		line.cons_unit_qty = 1;



		line.ordered_qty = this.frm.doc.ordered_qty;
		line.stock_ordered_qty = this.frm.doc.stock_ordered_qty;
		line.cons_unit_id = this.frm.doc.unit_id;
		line.stock_unit_id = this.frm.doc.stock_unit_id;

		line.cons_unit_conv_coef = this.frm.doc.unit_coeff;
		line.stock_unit_conv_coef = this.frm.doc.stock_unit_coeff;

		line.item_cost = 0;
		line.item_cost_std_stamp = 0;
		line.manual_cost = 0;
		line.cost_value = 0;

		line.stock_item_cost = 0;
		line.stock_item_cost_std_stamp = 0;
		line.stock_manual_cost = 0;
		line.stock_cost_value = 0


		cur_frm.refresh_field('bom_detail');


		let position_id_int = 0;

		for (var i = 0; i < this.frm.doc.bom_detail.length; i++) {

			let curr_position = this.frm.doc.bom_detail[i].position_id;

			if (curr_position != null) {

				if (curr_position.indexOf('.') != -1) {
					curr_position = curr_position.toString().substring(0, curr_position.indexOf('.'));
				}

				if (parseInt(curr_position) > position_id_int) {
					position_id_int = parseInt(curr_position);
				}
			}

		}

		position_id_int += 10;

		let me = this;

		var insertDialog = new frappe.ui.Dialog({
			title: __("New position"),
			static: true,
			fields: [{	label: __("Position"),
						fieldname: 'html_bom_definition_dialog_position',
						fieldtype: 'Int'
					}],
			primary_action_label: __("Ok"),
			primary_action: function(){

				let value = cur_dialog.fields_dict['html_bom_definition_dialog_position'].value

				if ((value == null) || (value == '')) {
					frappe.msgprint({title: __("Message"), message: __("Position is mandatory"), indicator: "red"});
					return false;
				}

				if (parseInt(value) <= 0) {
					frappe.msgprint({title: __("Message"), message: __("Position has to be greater than zero"), indicator: "red"});
					return false;
				}

				for (var i = 0; i < me.frm.doc.bom_detail.length; i++) {

					let curr_position = me.frm.doc.bom_detail[i].position_id;

					if (curr_position != null) {

						if (curr_position.indexOf('.') != -1) {
							curr_position = curr_position.toString().substring(0, curr_position.indexOf('.'));
						}

						if (parseInt(curr_position) == value) {
							frappe.msgprint({title: __("Message"), message: __("This position already exists in the grid"), indicator: "red"});
							return false;
						}
					}
				}

				let nb = 6 - value.toString().length;

				let formatted_position = '';

				for (var i = 1; i <= nb; i++) {
					formatted_position += '0';
				}

				formatted_position += value.toString();

				line.position_id_int = value;
				line.position_id = formatted_position;
				cur_frm.refresh_field('bom_detail');

				insertDialog.hide();
			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){
				cur_frm.get_field('bom_detail').grid.grid_rows[line.idx - 1].remove();
				insertDialog.hide();
			}

		});


		insertDialog.$wrapper.find('.modal-dialog').css("max-width", "300px").css("width", "300px");

		insertDialog.show();

		this.#sleep(200).then(() => {
			insertDialog.set_value('html_bom_definition_dialog_position', position_id_int);
		});


	}

	// ***************************************************************************************************************************************
	// BOM : Change user status
	// ***************************************************************************************************************************************

	bom_change_user_status(frm, cdt, cdn)
	{
		if (this.frm.doc.ioistatus != 0) {
			return false;
		}

		let me = this;
		let line = locals[cdt][cdn];

		if (line.ioistatus != 0) {
			return false;
		}

		var z = new frappe.ui.Dialog({
			'title': __("User status"),
			'fields': [
				{'fieldname': 'html_select_user_status', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;

				if (document.getElementById('user_status_nb_record').value == 0)
				{
					go = false;
				}

				if ((go) && (document.getElementById('user_status_nb_record').value != 0))
				{	go = false
					for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
					{

						if (document.getElementById('user_status_checked_id_' + i.toString()))
						{
							if (document.getElementById('user_status_checked_id_' + i.toString()).checked)
							{
								document.getElementById('selected_user_status').value = document.getElementById('user_status_id_' + i.toString()).value;
								go = true;
								break;
							}
						}
					}
				}

				if (go)
				{
					z.hide();

					me.frm.dirty();

					line.ioiuserstatus = document.getElementById('selected_user_status').value,

					me.#refresh_bom_detail_ioiuserstatus(frm, cdt, cdn);

				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('user_status_nb_record'))
		{
			for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
			{
				if (document.getElementById('user_status_checked_id_' + i.toString()))
				{
					document.getElementById('user_status_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('user_status_id_' + i.toString()))
				{
					document.getElementById('user_status_id_' + i.toString()).remove();
				}

			}

			document.getElementById('user_status_nb_record').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:300px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=100% data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=5% align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=95% style="vertical-align: middle;">&nbsp;<b>' + __("User status") + '</b></td>';
		html += '</tr>';
		html += '</table>';



		let method = me.path_module_user_status + '.ioi_module_user_status_get_list';


		frappe.call({  	method: method,
						args: {	"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														html += '<table border=1 style="border: 1px solid #E8EAEB" width=100%>';

														let cur_userstatus = 0;

														if (line.ioiuserstatus)
														{
															cur_userstatus = line.ioiuserstatus;
														}

														let cpt = 0


														for (var i = 0; i < r.message.length; i++)
														{
															if (cur_userstatus != r.message[i][2])
															{
																html += '<tr style="height:30px">';

																html += '<td width=5% align="center" style="vertical-align: middle;">';
																html += '<input type="checkbox" id="user_status_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
																html += '       onclick=" ';
																html += '					for (var i = 0; i < document.getElementById(\'user_status_nb_record\').value; i++) ';
																html += '					{   if (document.getElementById(\'user_status_checked_id_\' + i.toString())) ';
																html += '						{ '
																html += '							if (document.getElementById(\'user_status_checked_id_\' + i.toString()).id != this.id) ';
																html += '							{ ';
																html += '								document.getElementById(\'user_status_checked_id_\' + i.toString()).checked = false; ';
																html += '							} ';
																html += '						} ';
																html += '					} ';
																html += '" ';

																html += '>';
																html += '<input type="hidden" id="user_status_id_' + cpt.toString() + '" value="' +  r.message[i][2] + '">';
																html += '</td>';

																html += '<td bgcolor="' + r.message[i][3] + '" width=95% style="vertical-align: middle;">&nbsp;<font color="' + r.message[i][4] + '">' + r.message[i][5] + ' ( ' + r.message[i][2] + ' )' + '</font></td>'

																html += '</tr>';
															}

															cpt++;
														}
														html += '<input type="hidden" id="user_status_nb_record" value="' + cpt.toString() + '">';
														html += '</table>';
													}else
													{
														html += '<input type="hidden" id="user_status_nb_record" value="0">';
													}
												}
		});

		html += '<input type="hidden" id="selected_user_status" value="">';
		html += '</div>';


		z.fields_dict.html_select_user_status.$wrapper.html(html);

		z.show();
	}

	// ***************************************************************************************************************************************
	// BOM : Compute cost
	// ***************************************************************************************************************************************
	compute_cost(cdt, cdn)
	{
		let line = locals[cdt][cdn];

		let manual_cost = 0;

		if ((line.manual_cost) && (line.manual_cost != 0)) {
			manual_cost = line.manual_cost
		}

		let item_cost = 0;

		if ((line.item_cost) && (line.item_cost != 0)) {
			item_cost = line.item_cost;
		}

		let ordered_qty = 0

		if ((line.ordered_qty) && (line.ordered_qty != 0)) {
			ordered_qty = line.ordered_qty;
		}

		if (manual_cost > item_cost) {
			line.cost_value = manual_cost * ordered_qty;
		}else{
			line.cost_value = item_cost * ordered_qty;
		}

		line.stock_item_cost = item_cost * line.stock_unit_conv_coef / line.cons_unit_conv_coef;

		let item_cost_std_stamp = 0;

		if ((line.item_cost_std_stamp) && (line.item_cost_std_stamp != 0)) {
			item_cost_std_stamp = line.item_cost_std_stamp;
		}


		line.stock_item_cost_std_stamp = item_cost_std_stamp * line.stock_unit_conv_coef / line.cons_unit_conv_coef;
		line.stock_cost_value = line.cost_value * line.stock_unit_conv_coef / line.cons_unit_conv_coef;


	}


	// ***************************************************************************************************************************************
	// BOM : Select component_id
	// ***************************************************************************************************************************************

	select_component(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if ((!line.component_id) || ((line.component_id) && (line.component_id.trim() == ''))) {
			line.description = '';
			line.item_budget_id = '';

			line.item_cost = 0;
			line.item_cost_std_stamp = 0;

			line.stock_item_cost = 0;
			line.stock_item_cost_std_stamp = 0;

			this.compute_cost(cdt, cdn);


			this.frm.get_field('bom_detail').grid.grid_rows[line.idx-1].set_field_property('description', 'read_only', 0);
		}else{

			this.frm.get_field('bom_detail').grid.grid_rows[line.idx-1].set_field_property('description', 'read_only', 1);

			let me = this;
			let method = this.path_item + '.ioi_item_get_data';

			frappe.call({  	method: method,
							args: {"item_id": line.component_id},
							async: false,
							callback:function(r) {

								line.description = r.message.description;
								line.item_budget_id = r.message.budget_id;

								line.item_cost = r.message.calc_cost;
								line.item_cost_std_stamp = r.message.std_cost;
								line.stock_item_cost = r.message.calc_cost;
								line.stock_item_cost_std_stamp = r.message.std_cost;

								line.cons_unit_id = r.message.unit_id;

								line.cons_unit_conv_coef = 1;

								if ((r.message.conv_coefficient) && (r.message.conv_coefficient != 0)) {
									line.cons_unit_conv_coef = r.message.conv_coefficient;
								}

								line.stock_unit_id = r.message.unit_id;

								line.stock_unit_conv_coef = 1;

								if ((r.message.conv_coefficient) && (r.message.conv_coefficient != 0)) {
									line.stock_unit_conv_coef = r.message.conv_coefficient;
								}


								let stock_unit_conv_coef = line.stock_unit_conv_coef;
								let cons_unit_conv_coef = line.cons_unit_conv_coef;

								let stock_unit_qty = 0;

								if (line.stock_unit_qty) {
									stock_unit_qty = line.stock_unit_qty;
								}

								let stock_fix_qty = 0;

								if (line.stock_fix_qty) {
									stock_fix_qty = line.stock_fix_qty;
								}


								line.cons_unit_qty = stock_unit_qty / stock_unit_conv_coef * cons_unit_conv_coef;
								line.cons_fix_qty = stock_fix_qty / stock_unit_conv_coef * cons_unit_conv_coef;

								me.compute_cost(cdt, cdn);
							}
			});

		}
		this.frm.refresh_field('bom_detail');

		if ((line.stock_unit_id) && (line.stock_unit_id.trim() != '')) {
			this.set_query('cons_unit_id', 'bom_detail', this.path_item_unit + '.ioi_item_unit_get_enabled_same_family', '{"ref_unit_id":"' + line.stock_unit_id + '"}');
		}else{
			this.set_query('cons_unit_id', 'bom_detail', this.path_item_unit + '.ioi_item_unit_get_enabled_same_family', '');
		}


		this.compute_ordered_qty(frm, cdt, cdn);
		this.convert_stock_ordered_qty(frm, cdt, cdn);
		this.frm.refresh_field('bom_detail');

	}

	// ***************************************************************************************************************************************
	// BOM : Change Cons Unit
	// ***************************************************************************************************************************************

	change_cons_unit(frm, cdt, cdn)
	{
		if ((this.frm.doc.frozen_bom != 1) && (this.frm.doc.ioistatus == 0)) {

			let me = this;

			let line = locals[cdt][cdn];


			let title = __("Select an unit");
			let form_width_pixel = '600px';
			let table = "ioi Item Unit";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';



			if ((!line.stock_unit_id) || ((line.stock_unit_id) && (line.stock_unit_id.trim() == ''))) {
				where = "enabled = 1 ";
			}else{

				let method = me.path_item_unit + '.ioi_item_unit_get_enabled_same_family_for_ref_unit';

				frappe.call({	method: method,
								args: {"ref_unit_id": line.stock_unit_id},
								async: false,
								callback: function(r){

									where = "enabled = 1 and name in (";

									if (r.message)
									{
										for (var z = 0; z < r.message.length; z++) {

											where += "'" + r.message[z][0].replaceAll("'", "''") + "'";

											if (z < r.message.length-1) {
												where += ',';
											}

										}
									}

									where += ")";
								}
				});

			}

			let fct_callback = function (return_value) {
				line.cons_unit_id = return_value;


				let method = me.path_item_unit + '.ioi_item_unit_get_data';

				frappe.call({  	method: method,
								args: {"unit_id": return_value},
								async: false,
								callback:function(r)	{

									let coefficient = 1;

									if (r.message.length > 0) {

										if ((r.message[0].coefficient) && (r.message[0].coefficient != 0)) {

											coefficient = parseFloat(r.message[0].coefficient);
										}
									}
									line.cons_unit_conv_coef = coefficient;

									let stock_unit_conv_coef = line.stock_unit_conv_coef;
									let cons_unit_conv_coef = line.cons_unit_conv_coef;

									let stock_unit_qty = 0;

									if (line.stock_unit_qty) {
										stock_unit_qty = line.stock_unit_qty;
									}

									let stock_fix_qty = 0;

									if (line.stock_fix_qty) {
										stock_fix_qty = line.stock_fix_qty;
									}


									line.cons_unit_qty = stock_unit_qty * stock_unit_conv_coef / cons_unit_conv_coef;
									line.cons_fix_qty = stock_fix_qty * stock_unit_conv_coef / cons_unit_conv_coef;
									me.frm.dirty();
									me.compute_ordered_qty(frm, cdt, cdn);
									me.convert_stock_ordered_qty(frm, cdt, cdn);


									let stock_item_cost = 0;

									if ((line.stock_item_cost) && (line.stock_item_cost != 0)) {
										stock_item_cost = line.stock_item_cost;
									}


									let stock_item_cost_std_stamp = 0;

									if ((line.stock_item_cost_std_stamp) && (line.stock_item_cost_std_stamp != 0)) {
										stock_item_cost_std_stamp = line.stock_item_cost_std_stamp;
									}

									let stock_manual_cost = 0;

									if ((line.stock_manual_cost) && (line.stock_manual_cost != 0)) {
										stock_manual_cost = line.stock_manual_cost
									}


									let stock_cost_value = 0;

									if ((line.stock_cost_value) && (line.stock_cost_value != 0)) {
										stock_cost_value = line.stock_cost_value;
									}

									line.item_cost = stock_item_cost / stock_unit_conv_coef * cons_unit_conv_coef;
									line.item_cost_std_stamp = stock_item_cost_std_stamp / stock_unit_conv_coef * cons_unit_conv_coef;
									line.manual_cost = stock_manual_cost / stock_unit_conv_coef * cons_unit_conv_coef;
									line.cost_value = stock_cost_value;




									me.frm.refresh_field('bom_detail');
									me.format_bom_detail_form(frm, cdt, cdn);
								}
				});
			}
			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
		}
	}

	// ***************************************************************************************************************************************
	// BOM : Convert Stock Unit Qty
	// ***************************************************************************************************************************************

	convert_stock_unit_qty(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if ((!line.cons_unit_qty) || ((line.cons_unit_qty) && (parseFloat(line.cons_unit_qty) == 0))) {
			line.stock_unit_qty = 0;
		}else{

			let cons_unit_conv_coef = 1;

			if ((!line.cons_unit_conv_coef) || ((line.cons_unit_conv_coef) && (parseFloat(line.cons_unit_conv_coef) != 0))) {
				cons_unit_conv_coef = parseFloat(line.cons_unit_conv_coef);
			}

			let stock_unit_conv_coef = 1;

			if ((!line.stock_unit_conv_coef) || ((line.stock_unit_conv_coef) && (parseFloat(line.stock_unit_conv_coef) != 0))) {
				stock_unit_conv_coef = parseFloat(line.stock_unit_conv_coef);
			}

			let value = parseFloat(line.cons_unit_qty) * parseFloat(cons_unit_conv_coef) / parseFloat(stock_unit_conv_coef);
			line.stock_unit_qty = parseFloat(value);
		}

		this.frm.refresh_field('bom_detail')
	}

	// ***************************************************************************************************************************************
	// BOM : Convert Stock Fix Qty
	// ***************************************************************************************************************************************

	convert_stock_fix_qty(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if ((!line.cons_fix_qty) || ((line.cons_fix_qty) && (parseFloat(line.cons_fix_qty) == 0))) {
			line.stock_fix_qty = 0;
		}else{

			let cons_unit_conv_coef = 1;

			if ((!line.cons_unit_conv_coef) || ((line.cons_unit_conv_coef) && (parseFloat(line.cons_unit_conv_coef) != 0))) {
				cons_unit_conv_coef = parseFloat(line.cons_unit_conv_coef);
			}

			let stock_unit_conv_coef = 1;

			if ((!line.stock_unit_conv_coef) || ((line.stock_unit_conv_coef) && (parseFloat(line.stock_unit_conv_coef) != 0))) {
				stock_unit_conv_coef = parseFloat(line.stock_unit_conv_coef);
			}

			let value = parseFloat(line.cons_fix_qty) * parseFloat(cons_unit_conv_coef) / parseFloat(stock_unit_conv_coef);
			line.stock_fix_qty = parseFloat(value);
		}

		this.frm.refresh_field('bom_detail')
	}

	// ***************************************************************************************************************************************
	// BOM : Compute Ordered Qty
	// ***************************************************************************************************************************************

	compute_ordered_qty(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		let ordered_qty = 0;

		if (this.frm.doc.ordered_qty) {
			ordered_qty = parseFloat(this.frm.doc.ordered_qty);
		}

		let	unit_qty = 0;

		if (line.cons_unit_qty) {
			unit_qty = parseFloat(line.cons_unit_qty);
		}

		let fix_qty = 0;

		if (line.cons_fix_qty) {
			fix_qty = parseFloat(line.cons_fix_qty);
		}


		let parent_cons_unit_qty = 0;

		if (line.parent_cons_unit_qty) {
			parent_cons_unit_qty = parseFloat(line.parent_cons_unit_qty);
		}

		let parent_cons_fix_qty = 0;

		if (line.parent_cons_fix_qty) {
			parent_cons_fix_qty = parseFloat(line.parent_cons_fix_qty);
		}

		let value = 0

		if (parent_cons_unit_qty != 0) {
			value = (parent_cons_unit_qty * unit_qty * ordered_qty) + parent_cons_fix_qty + fix_qty;
		}else{
			value = (unit_qty * ordered_qty) +fix_qty;
		}

		line.ordered_qty = parseFloat(value);
		this.frm.refresh_field('bom_detail')
	}

	// ***************************************************************************************************************************************
	// BOM : Convert Stock Ordered Qty
	// ***************************************************************************************************************************************
	convert_stock_ordered_qty(frm, cdt, cdn)
	{

		let line = locals[cdt][cdn];

		if ((!line.ordered_qty) || ((line.ordered_qty) && (parseFloat(line.ordered_qty) == 0))) {
			line.stock_ordered_qty = 0;
		}else{

			let cons_unit_conv_coef = 1;

			if ((!line.cons_unit_conv_coef) || ((line.cons_unit_conv_coef) && (parseFloat(line.cons_unit_conv_coef) != 0))) {
				cons_unit_conv_coef = parseFloat(line.cons_unit_conv_coef);
			}

			let stock_unit_conv_coef = 1;

			if ((!line.stock_unit_conv_coef) || ((line.stock_unit_conv_coef) && (parseFloat(line.stock_unit_conv_coef) != 0))) {
				stock_unit_conv_coef = parseFloat(line.stock_unit_conv_coef);
			}

			let value = parseFloat(line.ordered_qty) * parseFloat(cons_unit_conv_coef) / parseFloat(stock_unit_conv_coef);
			line.stock_ordered_qty = parseFloat(value);
		}


		let stock_item_cost = 0;

		if ((line.stock_item_cost) && (line.stock_item_cost != 0)) {
			stock_item_cost = line.stock_item_cost;
		}


		let stock_item_cost_std_stamp = 0;

		if ((line.stock_item_cost_std_stamp) && (line.stock_item_cost_std_stamp != 0)) {
			stock_item_cost_std_stamp = line.stock_item_cost_std_stamp;
		}

		let stock_manual_cost = 0;

		if ((line.stock_manual_cost) && (line.stock_manual_cost != 0)) {
			stock_manual_cost = line.stock_manual_cost
		}


		let stock_cost_value = 0;

		if ((line.stock_cost_value) && (line.stock_cost_value != 0)) {
			stock_cost_value = line.stock_cost_value;
		}

		let value = 0

		if (stock_manual_cost > stock_item_cost) {
			value = stock_manual_cost;
		}else{
			value = stock_item_cost;
		}

		line.stock_cost_value = value * line.stock_ordered_qty;

		line.cost_value = line.stock_cost_value;


		this.frm.refresh_field('bom_detail')
	}

	// ***************************************************************************************************************************************
	// BOM : Refresh ioistatus (Status)
	// ***************************************************************************************************************************************

	#refresh_bom_detail_ioistatus(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		let bgcolor = '';
		let color = '';
		let description = '';

		for (var i = 0; i < this.ioistatus.length; i++)
		{
			if (item.ioistatus == this.ioistatus[i][0])
			{
				description = this.ioistatus[i][1];
				bgcolor = this.ioistatus[i][2];
				color = this.ioistatus[i][3];
				break;
			}
		}

		if (this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form)
		{
			let html = '';

			this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioistatus'].$wrapper.empty();

			html = '<table width=100%>';
			html += '<tr>';
			html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Status") +'</font></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100%>';
			html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
			html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
			html += '</div></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100% height=7px>&nbsp;</td>';
			html += '</tr>';

			html += '</table>';

			this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioistatus'].$wrapper.append(html);
		}

		if (this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1])
		{
			this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
		}
	}


	// ***************************************************************************************************************************************
	// BOM : Refresh ioiuserstatus (Status)
	// ***************************************************************************************************************************************

	#refresh_bom_detail_ioiuserstatus(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		let bgcolor = '';
		let color = '';
		let description = '';

		for (var i = 0; i < this.ioiuserstatus.length; i++)
		{
			if (item.ioiuserstatus == this.ioiuserstatus[i][0])
			{
				description = this.ioiuserstatus[i][1];
				bgcolor = this.ioiuserstatus[i][2];
				color = this.ioiuserstatus[i][3];
				break;
			}
		}

		if (this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form)
		{
			let html = '';

			this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioiuserstatus'].$wrapper.empty();

			html = '<table width=100%>';
			html += '<tr>';
			html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("User status") +'</font></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100%>';
			html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
			html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
			html += '</div></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100% height=7px>&nbsp;</td>';
			html += '</tr>';

			html += '</table>';

			this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioiuserstatus'].$wrapper.append(html);
		}

		if (this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1])
		{
			this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
		}
	}


	// ***************************************************************************************************************************************
	// BOM : Refresh Production routing
	// ***************************************************************************************************************************************

	#refresh_bom_detail_production_routing(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		let bgcolor = '#C3C2C4';
		let color = '';
		let description = __('Undefined');

		if ((item.production_routing_id) && (item.production_routing_id.trim() != '')) {

			let method = this.path_production + '.ioi_production_get_production_routing';

			frappe.call({  	method: method,
							args: {	"parent": this.frm.doc.name,
									"name": item.production_routing_id},
							async: false,
							callback:function(r)	{

								if (r.message.length > 0) {

									description = r.message[0].parent + ' | ' + r.message[0].step_id;
									bgcolor = r.message[0].ioistatus_bgcolor;

								}

							}
			});
		}

		if (this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form)
		{
			let html = '';

			this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_production_routing'].$wrapper.empty();

			html = '<table width=100%>';
			html += '<tr>';
			html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Production Routing") +'</font></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100%>';
			html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
			html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
			html += '</div></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100% height=7px>&nbsp;</td>';
			html += '</tr>';

			html += '</table>';

			this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_production_routing'].$wrapper.append(html);
		}

		if (this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1])
		{
			this.frm.fields_dict['bom_detail'].grid.grid_rows[item.idx-1].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
		}
	}



	// ***************************************************************************************************************************************
	// BOM : Format all numbers
	// ***************************************************************************************************************************************

	form_bom_format_all_numbers(grid_idx)
	{

		this.form_format_qty_float('bom_detail', 'cons_unit_qty', grid_idx);
		this.form_format_qty_float('bom_detail', 'cons_fix_qty', grid_idx);
		this.form_format_qty_float('bom_detail', 'parent_cons_unit_qty', grid_idx);
		this.form_format_qty_float('bom_detail', 'parent_cons_fix_qty', grid_idx);
		this.form_format_qty_float('bom_detail', 'cons_unit_conv_coef', grid_idx);
		this.form_format_qty_float('bom_detail', 'ordered_qty', grid_idx);
		this.form_format_qty_float('bom_detail', 'delivered_qty', grid_idx);
		this.form_format_qty_float('bom_detail', 'reserved_qty', grid_idx);
		this.form_format_qty_float('bom_detail', 'overstocking_factor', grid_idx);

		this.form_format_qty_float('bom_detail', 'stock_unit_qty', grid_idx);
		this.form_format_qty_float('bom_detail', 'stock_fix_qty', grid_idx);
		this.form_format_qty_float('bom_detail', 'parent_stock_unit_qty', grid_idx);
		this.form_format_qty_float('bom_detail', 'parent_stock_fix_qty', grid_idx);
		this.form_format_qty_float('bom_detail', 'stock_unit_conv_coef', grid_idx);
		this.form_format_qty_float('bom_detail', 'stock_ordered_qty', grid_idx);
		this.form_format_qty_float('bom_detail', 'stock_delivered_qty', grid_idx);
		this.form_format_qty_float('bom_detail', 'stock_reserved_qty', grid_idx);

	}


	form_format_qty_float(childfieldname, fieldname, grid_idx)
	{
		if (cur_frm.get_field(childfieldname).grid.grid_rows[grid_idx-1].grid_form) {

			let original_value = '';

			if (cur_frm.get_field(childfieldname).grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input) {
				original_value = cur_frm.get_field(childfieldname).grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input.value.trim();

				let me = this;
				let fct_blur = function() {
					me.form_format_qty_float(childfieldname, fieldname, grid_idx);
				}
				cur_frm.get_field(childfieldname).grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input.onblur = fct_blur;

			}else{
				original_value = cur_frm.get_field(childfieldname).grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].$input_wrapper[0].children[1].innerText.trim();
			}


			if ((original_value != '') && (original_value != 0)) {

				original_value = original_value.replaceAll(' ', '');

				let idx_coma = -1;
				let idx_point = -1;

				if (original_value.indexOf(',') != -1) {
					idx_coma = original_value.indexOf(',');
				}

				if (original_value.indexOf('.') != -1) {
					idx_point = original_value.indexOf('.');
				}

				if ((idx_coma != -1) || (idx_point != -1)) {

					let is_point = false;

					if (idx_coma < idx_point) {

						if (idx_coma != -1) {
							original_value = original_value.replaceAll(',', '');
							is_point = true;
						}

					}else if (idx_coma > idx_point)
					{
						if (idx_point != -1) {
							original_value = original_value.replaceAll('.', '');
						}
					}

					let can_continue = false;

					if (is_point)
					{
						if (original_value.indexOf('.') != -1) {
							can_continue = true;
						}

					}else{
						if (original_value.indexOf(',') != -1) {
							can_continue = true;
						}

					}

					if (can_continue) {

						can_continue = true;

						let is_number = true;

						if (isNaN(original_value)) {

							if (original_value.indexOf('.') != -1) {
								original_value = original_value.replaceAll('.', ',');
							}else if (original_value.indexOf(',') != -1) {
								original_value = original_value.replaceAll(',', '.');
							}else{
								can_continue = false;
							}

							is_number = false;
						}

						if (can_continue) {

							let modified_value = parseFloat(original_value);

							if (!is_number) {

								if (modified_value.toString().indexOf('.') != -1) {
									modified_value = modified_value.toString().replaceAll('.', ',');
								}else if (modified_value.toString().indexOf(',') != -1) {
									modified_value = modified_value.toString().replaceAll(',', '.');
								}
							}


							if (cur_frm.get_field(childfieldname).grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input) {
								cur_frm.get_field(childfieldname).grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input.value = modified_value;
							}else{
								cur_frm.get_field(childfieldname).grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].$input_wrapper[0].children[1].innerText = modified_value;
							}

						}
					}
				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// BOM : Frame VT in BOM detail
	// ***************************************************************************************************************************************

	static set_vt_button_in_grid()
	{
		if (cur_frm.fields_dict["bom_detail"].grid) {
			if (cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0]) {

				let from_idx = (parseInt(cur_frm.fields_dict['bom_detail'].grid.grid_pagination.page_index) * parseInt(cur_frm.fields_dict['bom_detail'].grid.grid_pagination.page_length)) - (parseInt(cur_frm.fields_dict['bom_detail'].grid.grid_pagination.page_length))

				for (var i = 0; i < cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].childElementCount; i++) {

					if (document.getElementById('vt_button_' + (i + from_idx).toString())) {
						document.getElementById('vt_button_' + (i + from_idx).toString()).remove();
					}

					let item_id = '';

					if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i + from_idx].doc.component_id) {
						item_id = cur_frm.fields_dict['bom_detail'].grid.grid_rows[i + from_idx].doc.component_id;
					}

					let to_execute = 0;

					if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i + from_idx].doc.to_execute) {
						to_execute = cur_frm.fields_dict['bom_detail'].grid.grid_rows[i + from_idx].doc.to_execute;
					}

					if ((item_id != '') && (to_execute == 1)) {
						// Add button VT
						let html = '';
						html += '<button id="vt_button_' + (i + from_idx).toString() + '" title="' + __("Time Vector") + '" style="width:30px; height:25px; border: none;">...</button>';
						cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].insertAdjacentHTML('afterbegin', html);
/*
						// Remove CheckBox
						if (cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[1]) {
							cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[1].remove();
						}
							*/
					}else {
						/*
						// Remove CheckBox
						if (cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[0]) {
							cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[0].remove();
						}
							*/
					}
				}


				silicon_ioi.doctype.ioiProductionDocumentDocType.sleep_static(200).then(() => {

					let fct_click = function() {
						let s = this.id;

						while (s.indexOf('_') != -1) {
							s = s.substring(s.indexOf('_')+1, s.length)
						}

						s = s.trim();

						let item_id = '';

						if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[s].doc.component_id) {
							item_id = cur_frm.fields_dict['bom_detail'].grid.grid_rows[s].doc.component_id;
						}

						let to_execute = 0;

						if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[s].doc.to_execute) {
							to_execute = cur_frm.fields_dict['bom_detail'].grid.grid_rows[s].doc.to_execute;
						}

						document.getElementById('sb_vt_data_header_title').innerHTML = '<b>' + __("Time Vector") + '</b> ' + item_id;

						silicon_ioi.doctype.ioiTimeVectorFrame.item_id = item_id;
						silicon_ioi.doctype.ioiTimeVectorFrame.doctype = '';
						silicon_ioi.doctype.ioiTimeVectorFrame.origin = 'IOI PRODUCTION BOM';
						silicon_ioi.doctype.ioiTimeVectorFrame.frame_height = 520;
						silicon_ioi.doctype.ioiTimeVectorFrame.pk_reference = cur_frm.doc.name + ' | ' + (parseInt(s)+1).toString();

						silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_refresh();

						if (item_id != '') {
							if (to_execute == 1) {
								document.getElementById('sb_vt_data').style.display = 'block';
							}else{
								document.getElementById('sb_vt_data').style.display = 'none';
							}
						}else{
							document.getElementById('sb_vt_data').style.display = 'none';
						}

						cur_frm.fields_dict['bom_detail'].grid.wrapper[0].getElementsByClassName('rows')[0].children[s].children[0].click();
					}

					from_idx = (parseInt(cur_frm.fields_dict['bom_detail'].grid.grid_pagination.page_index) * parseInt(cur_frm.fields_dict['bom_detail'].grid.grid_pagination.page_length)) - (parseInt(cur_frm.fields_dict['bom_detail'].grid.grid_pagination.page_length))

					for (var i = 0; i < cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].childElementCount; i++) {

						if (document.getElementById('vt_button_' + (i + from_idx).toString())) {
							document.getElementById('vt_button_' + (i + from_idx).toString()).onclick = fct_click;
						}
					}
				});
			}
		}
	}

	static build_vt_section()
	{
		if (cur_frm.doc.ioistatus != 0) {

			if (cur_frm.fields_dict["bom_detail"]) {

				if (cur_frm.fields_dict["bom_detail"].grid) {

					cur_frm.fields_dict["bom_detail"].grid.wrapper[0].onchange = silicon_ioi.doctype.ioiProductionDocumentDocType.set_vt_button_in_grid;

					if (document.getElementById('sb_vt_start_move')) {
						document.getElementById('sb_vt_start_move').remove();
					}


					if (document.getElementById('sb_vt_data')) {
						document.getElementById('sb_vt_data').remove();
					}

					if (document.getElementById('sb_vt_data_header')) {
						document.getElementById('sb_vt_data_header').remove();
					}

					if (document.getElementById('sb_vt_data_header_title')) {
						document.getElementById('sb_vt_data_header_title').remove();
					}

					if (document.getElementById('sb_vt_data_header_updown_section')) {
						document.getElementById('sb_vt_data_header_updown_section').remove();
					}

					if (document.getElementById('sb_vt_data_updown')) {
						document.getElementById('sb_vt_data_updown').remove();
					}



					if (document.getElementById('sb_vt_data_header_close_section')) {
						document.getElementById('sb_vt_data_header_close_section').remove();
					}

					if (document.getElementById('sb_vt_data_close')) {
						document.getElementById('sb_vt_data_close').remove();
					}

					if (document.getElementById('sb_vt_data_separator')) {
						document.getElementById('sb_vt_data_separator').remove();
					}


					if (document.getElementById('sb_vt_data_margin')) {
						document.getElementById('sb_vt_data_margin').remove();
					}

					if (document.getElementById('sb_vt_data_content')) {
						document.getElementById('sb_vt_data_content').remove();
					}

					silicon_ioi.doctype.ioiProductionDocumentDocType.vt_data_up = true;



					let content_bgcolor = "#FFFFFF";

					if (silicon_ioi.doctype.ioiProductionDocumentDocType.static_is_dark_mode != 0) {
						content_bgcolor = "#1C2126";
					}

					let title_font_color = '#000000';

					let div_height = 560;
					let content_height = 520;

					if (!silicon_ioi.doctype.ioiProductionDocumentDocType.vt_data_up) {
						div_height = 30;
					}

					if (document.body.clientHeight <= div_height)
					{
						div_height = document.body.clientHeight - 100;
						content_height = div_height - 38;
					}

					let fct_window_resize = function() {

						let div_height = 560;
						let content_height = 520;

						if (!silicon_ioi.doctype.ioiProductionDocumentDocType.vt_data_up) {
							div_height = 30;
						}

						if (document.body.clientHeight <= div_height)
						{
							div_height = document.body.clientHeight - 100;
							content_height = div_height - 38;
						}

						if (document.getElementById('sb_vt_data')) {
							document.getElementById('sb_vt_data').style.height = div_height.toString() + 'px';
							document.getElementById('sb_vt_data_detail').style.height = content_height.toString() + 'px';

							document.getElementById('sb_vt_data').style.top = document.body.clientHeight - div_height + window.scrollY + 'px';
						}

					};

					window.onresize = fct_window_resize;

					let div_top = document.body.clientHeight - div_height + window.scrollY;

					let html = '';
					html += '<input id="sb_vt_start_move" type="hidden" value="0">';
					html += '<div id="sb_vt_data" style="position:absolute;top:' + div_top + 'px; left:0px; width:100%;height:' + div_height.toString() + 'px;background-color:' + content_bgcolor + ';z-index:1000;display:none">';
					html += '	<div id="sb_vt_data_header" style="width:100%;height:30px;background-color:#D0E7FB;">';
					html += '		<label id="sb_vt_data_header_title" style="position:absolute;top:4px; left: 8px; width: 300px; color:' + title_font_color + '"><b>' + __("Time Vector") + '</b></label>';

					html += '		<div id="sb_vt_data_header_close_section" style="float: right; width:30px;height:30px;background-color:#D0E7FB;">';
					html += '			<img id="sb_vt_data_close" src="/assets/silicon_ioi/images/buttons/close.png" width="20px" height="20px" style="position:relative;top:4px; left:4px;"></img>';
					html += '		</div>';

					html += '		<div id="sb_vt_data_header_updown_section" style="float: right; width:30px;height:30px;background-color:#D0E7FB;">';
					html += '			<img id="sb_vt_data_updown" src="/assets/silicon_ioi/images/buttons/arrow_down.png" width="20px" height="20px" style="display:none;position:relative;top:4px; left:4px;"></img>';
					html += '		</div>';

					html += '	</div>';



					html += '	<div id="sb_vt_data_separator" style="overflow: auto; overflow-x: auto; height:8px;">';
					html += '	</div>';


					html += '	<div id="sb_vt_data_detail" style="width:100%;height:' + content_height.toString() + 'px;background-color:' + content_bgcolor + ';overflow-x: auto;">';

					html += '		<div id="sb_vt_data_margin" style="float:left;width:1%;height:100%;background-color:' + content_bgcolor + ';overflow-x: auto;">';
					html += '		</div>';

					html += '		<div id="sb_vt_data_content" style="float:left;width:99%;height:100%;background-color:' + content_bgcolor + ';overflow-x: auto;">';
					html += '		</div>';

					html += '	</div>';

					html += '</div>';

					parent.body.insertAdjacentHTML('beforeend', html);


					for (var i = 0; i < cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].childElementCount; i++) {

						if (document.getElementById('vt_button_' + i.toString())) {
							document.getElementById('vt_button_' + i.toString()).remove();
						}

						let item_id = '';

						if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].doc.component_id) {
							item_id = cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].doc.component_id;
						}

						let to_execute = 0;

						if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].doc.to_execute) {
							to_execute = cur_frm.fields_dict['bom_detail'].grid.grid_rows[i].doc.to_execute;
						}

						if ((item_id != '') && (to_execute == 1)) {
							// Add button VT
							html = '';
							html += '<button id="vt_button_' + i.toString() + '" title="' + __("Time Vector") + '" style="width:30px; height:25px; border: none;">...</button>';
							cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].insertAdjacentHTML('afterbegin', html);
/*
							// Remove CheckBox
							if (cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[1]) {
								cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[1].remove();
							}
								*/
						}else {
							/*
							// Remove CheckBox
							if (cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[0]) {
								cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[0].remove();
							}
								*/
						}
					}


					silicon_ioi.doctype.ioiProductionDocumentDocType.sleep_static(200).then(() => {

						let fct_header_mousedown = function() {
							document.getElementById('sb_vt_start_move').value = 1;
							this.style.cursor = 'n-resize';
						};

						document.getElementById('sb_vt_data_header').onmousedown = fct_header_mousedown;


						let fct_window_mouseup = function() {
							if (document.getElementById('sb_vt_start_move')) {
								document.getElementById('sb_vt_start_move').value = 0;
							}

							if (document.getElementById('sb_vt_data_header')) {
								document.getElementById('sb_vt_data_header').style.cursor = 'default';
							}
						};

						window.onmouseup = fct_window_mouseup;


						let fct_window_mousemove = function(e) {

							if (document.getElementById('sb_vt_start_move')) {

								if (document.getElementById('sb_vt_start_move').value == 1) {

									let top = e.clientY + window.pageYOffset;

									if (top < (document.body.clientHeight + window.scrollY - 38)) {

										div_height = document.body.clientHeight - top +  window.scrollY;
										document.getElementById('sb_vt_data').style.height = (document.body.clientHeight - top +  window.scrollY).toString() + 'px';
										document.getElementById('sb_vt_data').style.top = top.toString() + 'px';
									}

								}
							}

						};

						window.onmousemove = fct_window_mousemove;

						if (!silicon_ioi.doctype.ioiProductionDocumentDocType.vt_data_up) {
							document.getElementById('sb_vt_data_separator').style.display = 'none';
							document.getElementById('sb_vt_data_detail').style.display = 'none';
						}else{
							document.getElementById('sb_vt_data_separator').style.display = 'block';
							document.getElementById('sb_vt_data_detail').style.display = 'block';
						}

						silicon_ioi.doctype.ioiTimeVectorFrame.frame_height = 520;
						silicon_ioi.doctype.ioiTimeVectorFrame.refresh_time_vector('', 'sb_vt_data_content', '');


						document.onscroll = function() {
							document.getElementById('sb_vt_data').style.top = document.body.clientHeight - div_height + window.scrollY + 'px';
						};

						let fct_hide = function() {
							document.getElementById('sb_vt_data').style.display = 'none';
						};

						let fct_mouseover = function() {
							this.style.cursor = 'pointer';
						}

						let fct_mouseleave = function() {
							this.style.cursor = 'none';
						}

						let fct_updown = function () {

							silicon_ioi.doctype.ioiProductionDocumentDocType.vt_data_up = !silicon_ioi.doctype.ioiProductionDocumentDocType.vt_data_up;

							if (silicon_ioi.doctype.ioiProductionDocumentDocType.vt_data_up) {
								this.src = '/assets/silicon_ioi/images/buttons/arrow_down.png';
							}else{
								this.src = '/assets/silicon_ioi/images/buttons/arrow_up.png';
							}

							if (!silicon_ioi.doctype.ioiProductionDocumentDocType.vt_data_up) {
								document.getElementById('sb_vt_data_separator').style.display = 'none';
								document.getElementById('sb_vt_data_detail').style.display = 'none';
							}else{
								document.getElementById('sb_vt_data_separator').style.display = 'block';
								document.getElementById('sb_vt_data_detail').style.display = 'block';
							}

							let go = false;

							if (!document.getElementById('sb_vt_start_move')) {
								go = true;
							}else{
								if (document.getElementById('sb_vt_start_move').value == 0) {
									go = true;
								}
							}

							if (go) {

								if (!silicon_ioi.doctype.ioiProductionDocumentDocType.vt_data_up) {
									div_height = 30;
								}

								if (document.body.clientHeight <= div_height)
								{
									div_height = document.body.clientHeight - 100;

								}

								content_height = div_height - 38;

								if (document.getElementById('sb_vt_data')) {
									document.getElementById('sb_vt_data').style.height = div_height.toString() + 'px';
									document.getElementById('sb_vt_data_detail').style.height = content_height.toString() + 'px';

									document.getElementById('sb_vt_data').style.top = document.body.clientHeight - div_height + window.scrollY + 'px';
								}
							}

						}

						document.getElementById('sb_vt_data_updown').onclick = fct_updown;
						document.getElementById("sb_vt_data_updown").onmouseover = fct_mouseover;
						document.getElementById("sb_vt_data_updown").onmouseleave = fct_mouseleave;


						document.getElementById('sb_vt_data_close').onclick = fct_hide;
						document.getElementById("sb_vt_data_close").onmouseover = fct_mouseover;
						document.getElementById("sb_vt_data_close").onmouseleave = fct_mouseleave;


						let fct_click = function() {
							let s = this.id;

							while (s.indexOf('_') != -1) {
								s = s.substring(s.indexOf('_')+1, s.length)
							}

							s = s.trim();

							let item_id = '';

							if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[s].doc.component_id) {
								item_id = cur_frm.fields_dict['bom_detail'].grid.grid_rows[s].doc.component_id;
							}

							let to_execute = 0;

							if (cur_frm.fields_dict['bom_detail'].grid.grid_rows[s].doc.to_execute) {
								to_execute = cur_frm.fields_dict['bom_detail'].grid.grid_rows[s].doc.to_execute;
							}

							document.getElementById('sb_vt_data_header_title').innerHTML = '<b>' + __("Time Vector") + '</b> ' + item_id;

							silicon_ioi.doctype.ioiTimeVectorFrame.item_id = item_id;
							silicon_ioi.doctype.ioiTimeVectorFrame.doctype = '';
							silicon_ioi.doctype.ioiTimeVectorFrame.origin = 'IOI PRODUCTION BOM';
							silicon_ioi.doctype.ioiTimeVectorFrame.pk_reference = cur_frm.doc.name + ' | ' + (parseInt(s)+1).toString();
							silicon_ioi.doctype.ioiTimeVectorFrame.frame_height = 520;
							silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_refresh();

							if (item_id != '') {
								if (to_execute == 1) {
									document.getElementById('sb_vt_data').style.display = 'block';
								}else{
									document.getElementById('sb_vt_data').style.display = 'none';
								}
							}else{
								document.getElementById('sb_vt_data').style.display = 'none';
							}

							cur_frm.fields_dict['bom_detail'].grid.wrapper[0].getElementsByClassName('rows')[0].children[s].children[0].click();
						}

						for (var i = 0; i < cur_frm.fields_dict["bom_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].childElementCount; i++) {

							if (document.getElementById('vt_button_' + i.toString())) {
								document.getElementById('vt_button_' + i.toString()).onclick = fct_click;
							}
						}
					});
				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// BOM Fill Line available status
	// ***************************************************************************************************************************************

	bom_fill_line_available_status(item)
	{
		let available_status_desc = '';
		let available_status_bgcolor = '';

		let available_status_value = this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['available_status'].doc['available_status']

		if ((!available_status_value) || ((available_status_value) && (available_status_value == 0)))
		{
			available_status_desc = __("Uncalculated");
			available_status_bgcolor = '#DCDCDC';

		}else if (available_status_value == 1)
		{
			available_status_desc = __("Nothing");
			available_status_bgcolor = '#FA6E6B';

		}else if (available_status_value == 2)
		{
			available_status_desc = __("Partiel");
			available_status_bgcolor = '#759CFF';

		}else if (available_status_value == 3)
		{
			available_status_desc = __("Full");
			available_status_bgcolor = '#5DE861';

		}

		let html = '';

		this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_available_status'].$wrapper.empty();

		html = '<div style="overflow: auto; overflow-x: auto; height:70px;">';
		html += '	<label id="ioi_available_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("Available status") + '</label>';
		html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + available_status_bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '		<label style="position: absolute; top: 7px; left: 10px;"><font color="black">' + available_status_desc + '</font></label>';
		html += '	</div>';

		let available_need_recompute = false;

		let to_execute = this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['to_execute'].doc['to_execute'];

		let calc_datetime = this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['available_calc_datetime'].doc['available_calc_datetime'];

		if ((to_execute == null) || (to_execute == ''))	{
			to_execute = 0;
		}

		if (to_execute == 1) {
			available_need_recompute = true;
		}


		html += '</div>';

		this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_available_status'].$wrapper.append(html);

	}

	// ***************************************************************************************************************************************
	// BOM : Fill Line available scheduled status
	// ***************************************************************************************************************************************

	bom_fill_line_available_scheduled_status(item)
	{
		let available_scheduled_status_desc = '';
		let available_scheduled_status_bgcolor = '';

		let available_scheduled_status_value = this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['available_scheduled_status'].doc['available_scheduled_status']

		if ((!available_scheduled_status_value) || ((available_scheduled_status_value) && (available_scheduled_status_value == 0)))
		{
			available_scheduled_status_desc = __("Uncalculated");
			available_scheduled_status_bgcolor = '#DCDCDC';

		}else if (available_scheduled_status_value == 1)
		{
			available_scheduled_status_desc = __("Nothing");
			available_scheduled_status_bgcolor = '#FA6E6B';

		}else if (available_scheduled_status_value == 2)
		{
			available_scheduled_status_desc = __("Partiel");
			available_scheduled_status_bgcolor = '#759CFF';

		}else if (available_scheduled_status_value == 3)
		{
			available_scheduled_status_desc = __("Full");
			available_scheduled_status_bgcolor = '#5DE861';

		}

		let html = '';

		this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_available_scheduled_status'].$wrapper.empty();

		html = '<div style="overflow: auto; overflow-x: auto; height:70px;">';
		html += '	<label id="ioi_available_scheduled_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("Available scheduled status") + '</label>';
		html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + available_scheduled_status_bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '		<label style="position: absolute; top: 7px; left: 10px;"><font color="black">' + available_scheduled_status_desc + '</font></label>';
		html += '	</div>';
		html += '</div>';

		this.frm.get_field('bom_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_available_scheduled_status'].$wrapper.append(html);
	}

	// ***************************************************************************************************************************************
	// BOM use it (alternate of)
	// ***************************************************************************************************************************************

	bom_use_it(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if (this.frm.doc.ioistatus == 0) {

			if (line.ioistatus == 0) {

				if (line.is_alternate == 1) {

					if ((line.alternate_of_item_id) && (line.alternate_of_item_id.trim() != '')) {

						let found_one = false;

						for (var i = 0; i < this.frm.doc.bom_detail.length; i++) {
							if (this.frm.doc.bom_detail[i].component_id == line.alternate_of_item_id) {
								this.frm.doc.bom_detail[i].is_alternate = 1;
								this.frm.doc.bom_detail[i].alternate_of_item_id = line.component_id
								line.is_alternate = 0;
								found_one = true;
							}
						}

						if (found_one) {
							this.frm.refresh_field('bom_detail');
							this.frm.dirty();
							this.format_bom_detail_form(frm, cdt, cdn);
						}
					}
				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// BOM : change item_kind
	// ***************************************************************************************************************************************


	bom_change_item_kind(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if (line.item_kind != 3) {
			line.sub_ratio_value = 0;
			this.frm.refresh_field('bom_detail');
			this.format_bom_detail_form(frm, cdt, cdn);
		}

		const grid_row = this.frm.fields_dict["bom_detail"].grid.grid_rows_by_docname[cdn];

		if (grid_row) {


			const sub_ratio_value = grid_row.docfields.filter(f => f.fieldname == "sub_ratio_value")[0];

			if (line.item_kind != 3) {
				sub_ratio_value.read_only = true;
			}else{
				sub_ratio_value.read_only = false;
			}

			this.frm.refresh_field('bom_detail');
			this.format_bom_detail_form(frm, cdt, cdn);


		}
	}

	// ***************************************************************************************************************************************
	// BOM : reactive a Routing line
	// ***************************************************************************************************************************************

	reactive_bom(cdt, cdn)
	{
		if (this.frm.doc.ioistatus != 0) {
			frappe.msgprint({title: __("Message"), message: __("Production status has to be in Preparation (0)"), indicator: "red"});
			return false;
		}

		let line = locals[cdt][cdn];

		if (line.ioistatus != 3) {
			frappe.msgprint({title: __("Message"), message: __("Production BOM status has to be Ended (3)"), indicator: "red"});
			return false;

		}

		let top = 10;

		let msg = '';

		let s = __("Do you want to re-activate this BOM ?");
		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
		top += 30;

		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';

		let me = this;

		frappe.confirm(	msg,
			() => 	{
						me.#sleep(200).then(() => {

							if (this.frm.is_dirty()) {
								let fct_callback = function () { me.do_reactive_bom(cdt, cdn); };
								me.frm.save('Save', fct_callback);
							}else {
								me.do_reactive_bom(cdt, cdn);
							}
						});
					},
			() => 	{
					}
		);
	}

	do_reactive_bom(cdt, cdn)
	{
		let line = locals[cdt][cdn];
		line.ioistatus = 0;
		this.frm.dirty();
		this.frm.save();
	}



	// ***************************************************************************************************************************************
	//
	// Production Routing
	//
	// ***************************************************************************************************************************************


	// ***************************************************************************************************************************************
	// Format Routing Grid
	// ***************************************************************************************************************************************

	grid_routing_layout(dm = null)
	{
		let me = this;

		if (dm == null)
		{
			dm = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;
		}

		if (cur_frm.doc.routing_detail)
		{

			for(var i = 0; i < cur_frm.doc.routing_detail.length; i++)
			{
				let bgcolor = '';

				for (var k = 0; k < me.ioistatus.length; k++)
				{
					if (cur_frm.doc.routing_detail[i].ioistatus == me.ioistatus[k][0])
					{
						bgcolor = me.ioistatus[k][2];
						break;
					}
				}


				if (cur_frm.fields_dict['routing_detail'].grid.grid_rows[i])
				{
					cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
					cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[1].style.color = "black";

					for (var m = 0; m < cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list.length; m++)
					{
						if (dm == 0) {
							cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FFFFFF';
						}else{
							cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#1C2126';
						}
					}


					for (var m = 0; m < cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list.length; m++)
					{
						if ((cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'WORKCENTER_UNIT_RATE') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'WORKCENTER_FULL_SETUP_DURATION') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'WORKCENTER_UNIT_DURATION') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'WORKCENTER_PREPARATION_DURATION') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'REQUIRED_WORKCENTER_HOURS') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'REGISTERED_WORKCENTER_HOURS') ||

							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'WORKER_UNIT_RATE') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'WORKER_FULL_SETUP_DURATION') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'WORKER_UNIT_DURATION') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'WORK_PREPARATION_DURATION') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'REQUIRED_WORKER_HOURS') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'WORKER_WORKCENTER_RATE') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'REGISTERED_WORKER_HOURS') ||

							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'RECEIVED_QTY') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'PRODUCED_QTY') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'TO_CHECK_QTY') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'BAD_QTY') ||
							(cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'TRANSMITTED_QTY'))
						{

							let fn = cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname;

							let gridelement = cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1].children[0];

							let gridcell = null;

						 	if (cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].columns[fn][0].children[0]) {


								if (cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0]) {

									if (cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0].children[0]) {
										gridcell = cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0].children[0];
									}
								}
							}

							silicon_ioi.doctype.ioiProductionDocumentDocType.grid_format_float_qty(gridelement, gridcell);
						}

					}

					if ((cur_frm.doc.routing_detail[i].is_warning) && (cur_frm.doc.routing_detail[i].is_warning != 0))
					{
						for (var m = 0; m < cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list.length; m++)
						{
							if (cur_frm.doc.routing_detail[i].is_warning == 1)
							{	cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FCA874';
							}else
							{	cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FEADAD';
							}

							cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
						}
					}

					if ((this.frm.doc.frozen_routing == 1) || (this.frm.doc.ioistatus != 0)) {

						for (var m = 0; m < cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list.length; m++)
						{
							if (cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'WORKCENTER_UNIT_TIME_DEF') {

								let fn = cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let value = gridelement.innerText.trim()

									if ((!value) || ((value) && (value == 0))) {
										gridelement.innerText = __('Duration');
									}else if (value == 1) {
										gridelement.innerText = __('rate');
									}
								}
							}else if (cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'WORKER_UNIT_TIME_DEF') {

								let fn = cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let value = gridelement.innerText.trim()

									if ((!value) || ((value) && (value == 0))) {
										gridelement.innerText = __('Duration');
									}else if (value == 1) {
										gridelement.innerText = __('rate');
									}
								}
							}else if (cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'LINE_MODE') {

								let fn = cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let value = gridelement.innerText.trim()

									if ((!value) || ((value) && (value == 0))) {
										gridelement.innerText = __('Undefined');
									}else if (value == 1) {
										gridelement.innerText = __('Required');
									}else if (value == 3) {
										gridelement.innerText = __('Recommended');
									}
								}
							}else if (cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'BEFORE_EXECUTION_MODE') {

								let fn = cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let value = gridelement.innerText.trim()

									if ((!value) || ((value) && (value == 0))) {
										gridelement.innerText = __('Calendar');
									}else if (value == 1) {
										gridelement.innerText = __('Open hours');
									}
								}
							}else if (cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'AFTER_EXECUTION_MODE') {

								let fn = cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let value = gridelement.innerText.trim()

									if ((!value) || ((value) && (value == 0))) {
										gridelement.innerText = __('Calendar');
									}else if (value == 1) {
										gridelement.innerText = __('Open hours');
									}
								}
							}else if (cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'SCHEDULING_UPDATE') {

								let fn = cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let value = gridelement.innerText.trim()

									if ((!value) || ((value) && (value == 0))) {
										gridelement.innerText = __('Realized time');
									}else if (value == 1) {
										gridelement.innerText = __('Realized quantity');
									}else if (value == 2) {
										gridelement.innerText = __('Manual Completed');
									}

								}
							}else if (cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'SUBCONTRACTOR_FLOW') {

								let fn = cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let value = gridelement.innerText.trim()

									if ((!value) || ((value) && (value == 0))) {
										gridelement.innerText = __('No transaction');
									}else if (value == 1) {
										gridelement.innerText = __('Receive only');
									}else if (value == 2) {
										gridelement.innerText = __('Send Receive');
									}else if (value == 3) {
										gridelement.innerText = __('Order/Send/Receive');
									}
								}
							}else if (cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'START_MODE') {

								let fn = cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let value = gridelement.innerText.trim()

									if ((!value) || ((value) && (value == 0))) {
										gridelement.innerText = __('Scheduled');
									}else if (value == 1) {
										gridelement.innerText = __('Adjust fix');
									}else if (value == 2) {
										gridelement.innerText = __('Hard fix');
									}
								}
							}else if (cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'END_MODE') {

								let fn = cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let value = gridelement.innerText.trim()

									if ((!value) || ((value) && (value == 0))) {
										gridelement.innerText = __('Scheduled');
									}else if (value == 1) {
										gridelement.innerText = __('Adjust fix');
									}else if (value == 2) {
										gridelement.innerText = __('Hard fix');
									}
								}
							}else if (cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'HEADER_SCHEDULING_PIVOT') {

								let fn = cur_frm.fields_dict['routing_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['routing_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1];

								if (gridelement) {

									let value = gridelement.innerText.trim()

									if ((!value) || ((value) && (value == 0))) {
										gridelement.innerText = __('At the lastest');
									}else if (value == 1) {
										gridelement.innerText = __('At the soonest');
									}
								}
							}
						}
					}

				}
			}
		}

		let fct_grid = function () { me.grid_routing_layout(dm); };
		setTimeout(fct_grid, 500);

	}



	// ***************************************************************************************************************************************
	// Format Routing Detail form
	// ***************************************************************************************************************************************

	format_routing_detail_form(frm, cdt, cdn)
	{
		this.frm.refresh_field('routing_detail');

		let item = locals[cdt][cdn];

		if (this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form) {

			if (this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_spacer'])
			{
				this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_spacer'].$wrapper.empty();

				let html = '';

				html = '<div align="center" style="overflow:auto; height:140px;">';
				html += '</div>';

				this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_spacer'].$wrapper.append(html);
			}
		}

		if ((item.is_warning) && (item.is_warning != 0))
		{
			let msg_bgcolor = '';
			let msg_fontcolor = ''

			if (item.is_warning == 1)
			{	msg_bgcolor = '#FFF5F0';
				msg_fontcolor = '#F57231';
			}else
			{	msg_bgcolor = '#FEADAD';
				msg_fontcolor = 'black';
			}

			if (this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form)
			{

				if (this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'])
				{
					this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.empty();

					let html = '';

					html = '<br><div align="center" style="overflow:auto; vertical-align: middle; height:28px;border-radius:6px;padding:4px;background-color:' + msg_bgcolor + ';">';
					html += '<font color="' + msg_fontcolor +'">' + item.warninfo + '</font>';
					html += '</div>';

					this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.append(html);
				}
			}
		}else
		{
			if (this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form)
			{
				if (this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'])
				{
					this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.empty();
				}
			}
		}

		this.#refresh_routing_detail_ioistatus(frm, cdt, cdn);
		this.#refresh_routing_detail_ioiuserstatus(frm, cdt, cdn);

		this.format_workcenter_unit_duration(frm, cdt, cdn);
		this.format_worker_unit_duration(frm, cdt, cdn);

		this.form_routing_format_all_numbers(item.idx);

		if ((item.workcenter_id) && (item.workcenter_id.trim() != '')) {
			this.set_query('line_machine_id', 'routing_detail', this.path_line_machine + '.ioi_line_machine_get_enabled', '{"workcenter_id" : "' + item.workcenter_id + '"}');
		}else{
			this.set_query('line_machine_id', 'routing_detail', this.path_line_machine + '.ioi_line_machine_get_none', '');
		}

		if ((this.frm.doc.frozen_routing == 1) || (this.frm.doc.ioistatus != 0)) {

			let workcenter_unit_time_def = cur_frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('workcenter_unit_time_def').value;

			if ((!workcenter_unit_time_def) || ((workcenter_unit_time_def) && (workcenter_unit_time_def == 0))) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('workcenter_unit_time_def').$input_wrapper[0].children[1].innerText = __('Duration');
			}else if (workcenter_unit_time_def == 1) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('workcenter_unit_time_def').$input_wrapper[0].children[1].innerText = __('Rate');
			}


			let worker_unit_time_def = cur_frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('worker_unit_time_def').value;

			if ((!worker_unit_time_def) || ((worker_unit_time_def) && (worker_unit_time_def == 0))) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('worker_unit_time_def').$input_wrapper[0].children[1].innerText = __('Duration');
			}else if (worker_unit_time_def == 1) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('worker_unit_time_def').$input_wrapper[0].children[1].innerText = __('Rate');
			}


			let line_mode = cur_frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('line_mode').value;

			if ((!line_mode) || ((line_mode) && (line_mode == 0))) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('line_mode').$input_wrapper[0].children[1].innerText = __('Undefined');
			}else if (line_mode == 1) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('worker_unit_time_def').$input_wrapper[0].children[1].innerText = __('Required');
			}else if (line_mode == 2) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('worker_unit_time_def').$input_wrapper[0].children[1].innerText = __('Recommended');
			}



			let before_execution_mode = cur_frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('before_execution_mode').value;

			if ((!before_execution_mode) || ((before_execution_mode) && (before_execution_mode == 0))) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('before_execution_mode').$input_wrapper[0].children[1].innerText = __('Calendar');
			}else if (before_execution_mode == 1) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('before_execution_mode').$input_wrapper[0].children[1].innerText = __('Open hours');
			}


			let after_execution_mode = cur_frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('after_execution_mode').value;

			if ((!after_execution_mode) || ((after_execution_mode) && (after_execution_mode == 0))) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('after_execution_mode').$input_wrapper[0].children[1].innerText = __('Calendar');
			}else if (after_execution_mode == 1) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('after_execution_mode').$input_wrapper[0].children[1].innerText = __('Open hours');
			}


			let scheduling_update = cur_frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('scheduling_update').value;

			if ((!scheduling_update) || ((scheduling_update) && (scheduling_update == 0))) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('scheduling_update').$input_wrapper[0].children[1].innerText = __('Realized time');
			}else if (scheduling_update == 1) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('scheduling_update').$input_wrapper[0].children[1].innerText = __('Realized quantity');
			}else if (scheduling_update == 2) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('scheduling_update').$input_wrapper[0].children[1].innerText = __('Manual Completed');
			}


			let subcontractor_flow = cur_frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('subcontractor_flow').value;

			if ((!subcontractor_flow) || ((subcontractor_flow) && (subcontractor_flow == 0))) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('subcontractor_flow').$input_wrapper[0].children[1].innerText = __('No transaction');
			}else if (subcontractor_flow == 1) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('subcontractor_flow').$input_wrapper[0].children[1].innerText = __('Receive only');
			}else if (subcontractor_flow == 2) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('subcontractor_flow').$input_wrapper[0].children[1].innerText = __('Send Receive');
			}else if (subcontractor_flow == 3) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('subcontractor_flow').$input_wrapper[0].children[1].innerText = __('Order/Send/Receive');
			}

			let start_mode = cur_frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('start_mode').value;

			if ((!start_mode) || ((start_mode) && (start_mode == 0))) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('start_mode').$input_wrapper[0].children[1].innerText = __('Scheduled');
			}else if (start_mode == 1) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('start_mode').$input_wrapper[0].children[1].innerText = __('Adjust fix');
			}else if (start_mode == 2) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('start_mode').$input_wrapper[0].children[1].innerText = __('Hard fix');
			}


			let end_mode = cur_frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('end_mode').value;

			if ((!end_mode) || ((end_mode) && (end_mode == 0))) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('end_mode').$input_wrapper[0].children[1].innerText = __('Scheduled');
			}else if (end_mode == 1) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('end_mode').$input_wrapper[0].children[1].innerText = __('Adjust fix');
			}else if (start_mode == 2) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('end_mode').$input_wrapper[0].children[1].innerText = __('Hard fix');
			}


			let header_scheduling_pivot = cur_frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('header_scheduling_pivot').value;

			if ((!header_scheduling_pivot) || ((header_scheduling_pivot) && (header_scheduling_pivot == 0))) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('header_scheduling_pivot').$input_wrapper[0].children[1].innerText = __('At the lastest');
			}else if (header_scheduling_pivot == 1) {
				this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].get_field('header_scheduling_pivot').$input_wrapper[0].children[1].innerText = __('At the soonest');
			}


		}


		if (this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form)
		{
			for (var i = 0; i < this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections.length; i++)
			{
				if (this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head)
				{
					this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.attr('data-custom-section-head', 'true');
					this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('border-radius', '6px');
					this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('padding', '4px');

					if (this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].is_collapsed())
					{	this.frm.get_field('routing_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.click();
					}
				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// Routing detail : add new line
	// ***************************************************************************************************************************************

	routing_detail_add(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		line.manual_line = 1;
		line.ioistatus = 0;
		line.ioiuserstatus = 0;
		line.workcenter_unit_time_def = 0;
		line.line_mode = 0;
		line.worker_unit_time_def = 0;
		line.start_mode = 0;
		line.end_mode = 0;
		line.before_execution_mode = 0;
		line.after_execution_mode = 0;
		line.subcontractor_flow = 0;
		line.parent_cons_unit_qty = 1;


		line.workcenter_cost = 0
		line.usual_operator_cost = 0

		line.stock_unit_conv_coef = this.frm.doc.stock_unit_coeff;


		cur_frm.refresh_field('routing_detail');


		let step_id_int = 0;

		for (var i = 0; i < this.frm.doc.routing_detail.length; i++) {

			let curr_step = this.frm.doc.routing_detail[i].step_id;

			if (curr_step != null) {

				if (curr_step.indexOf('.') != -1) {
					curr_step = curr_step.toString().substring(0, curr_step.indexOf('.'));
				}

				if (parseInt(curr_step) > step_id_int) {
					step_id_int = parseInt(curr_step);
				}
			}

		}

		step_id_int += 10;

		let me = this;

		var insertDialog = new frappe.ui.Dialog({
			title: __("New step"),
			static: true,
			fields: [{	label: __("Step"),
						fieldname: 'html_routing_definition_dialog_step',
						fieldtype: 'Int'
					}],
			primary_action_label: __("Ok"),
			primary_action: function(){

				let value = cur_dialog.fields_dict['html_routing_definition_dialog_step'].value

				if ((value == null) || (value == '')) {
					frappe.msgprint({title: __("Message"), message: __("Step is mandatory"), indicator: "red"});
					return false;
				}

				if (parseInt(value) <= 0) {
					frappe.msgprint({title: __("Message"), message: __("Step has to be greater than zero"), indicator: "red"});
					return false;
				}

				for (var i = 0; i < me.frm.doc.routing_detail.length; i++) {

					let curr_step = me.frm.doc.routing_detail[i].step_id;

					if (curr_step != null) {

						if (curr_step.indexOf('.') != -1) {
							curr_step = curr_step.toString().substring(0, curr_step.indexOf('.'));
						}

						if (parseInt(curr_step) == value) {
							frappe.msgprint({title: __("Message"), message: __("This step already exists in the grid"), indicator: "red"});
							return false;
						}
					}
				}

				let nb = 6 - value.toString().length;

				let formatted_step = '';

				for (var i = 1; i <= nb; i++) {
					formatted_step += '0';
				}

				formatted_step += value.toString();

				line.step_id_int = value;
				line.step_id = formatted_step;
				cur_frm.refresh_field('routing_detail');

				insertDialog.hide();
			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){
				cur_frm.get_field('routing_detail').grid.grid_rows[line.idx - 1].remove();
				insertDialog.hide();
			}

		});


		insertDialog.$wrapper.find('.modal-dialog').css("max-width", "300px").css("width", "300px");

		insertDialog.show();

		this.#sleep(200).then(() => {
			insertDialog.set_value('html_routing_definition_dialog_step', step_id_int);
		});

	}




	// ***************************************************************************************************************************************
	// Routing : Refresh ioistatus (Status)
	// ***************************************************************************************************************************************

	#refresh_routing_detail_ioistatus(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		let bgcolor = '';
		let color = '';
		let description = '';

		for (var i = 0; i < this.ioistatus.length; i++)
		{
			if (item.ioistatus == this.ioistatus[i][0])
			{
				description = this.ioistatus[i][1];
				bgcolor = this.ioistatus[i][2];
				color = this.ioistatus[i][3];
				break;
			}
		}

		if (this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].grid_form)
		{
			let html = '';

			this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioistatus'].$wrapper.empty();

			html = '<table width=100%>';
			html += '<tr>';
			html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Status") +'</font></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100%>';
			html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
			html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
			html += '</div></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100% height=7px>&nbsp;</td>';
			html += '</tr>';

			html += '</table>';

			this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioistatus'].$wrapper.append(html);
		}

		if (this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1])
		{
			this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
		}
	}


	// ***************************************************************************************************************************************
	// Routing : Refresh ioiuserstatus (Status)
	// ***************************************************************************************************************************************

	#refresh_routing_detail_ioiuserstatus(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		let bgcolor = '';
		let color = '';
		let description = '';

		for (var i = 0; i < this.ioiuserstatus.length; i++)
		{
			if (item.ioiuserstatus == this.ioiuserstatus[i][0])
			{
				description = this.ioiuserstatus[i][1];
				bgcolor = this.ioiuserstatus[i][2];
				color = this.ioiuserstatus[i][3];
				break;
			}
		}

		if (this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].grid_form)
		{
			let html = '';

			this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioiuserstatus'].$wrapper.empty();

			html = '<table width=100%>';
			html += '<tr>';
			html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("User status") +'</font></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100%>';
			html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
			html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
			html += '</div></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100% height=7px>&nbsp;</td>';
			html += '</tr>';

			html += '</table>';

			this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioiuserstatus'].$wrapper.append(html);
		}

		if (this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1])
		{
			this.frm.fields_dict['routing_detail'].grid.grid_rows[item.idx-1].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
		}
	}

	// ***************************************************************************************************************************************
	// Routing : Change user status
	// ***************************************************************************************************************************************

	routing_change_user_status(frm, cdt, cdn)
	{

		if (this.frm.doc.ioistatus != 0) {
			return false;
		}

		let me = this;
		let line = locals[cdt][cdn];

		if (line.ioistatus != 0) {
			return false;
		}


		var z = new frappe.ui.Dialog({
			'title': __("User status"),
			'fields': [
				{'fieldname': 'html_select_user_status', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;

				if (document.getElementById('user_status_nb_record').value == 0)
				{
					go = false;
				}

				if ((go) && (document.getElementById('user_status_nb_record').value != 0))
				{	go = false
					for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
					{

						if (document.getElementById('user_status_checked_id_' + i.toString()))
						{
							if (document.getElementById('user_status_checked_id_' + i.toString()).checked)
							{
								document.getElementById('selected_user_status').value = document.getElementById('user_status_id_' + i.toString()).value;
								go = true;
								break;
							}
						}
					}
				}

				if (go)
				{
					z.hide();

					me.frm.dirty();

					line.ioiuserstatus = document.getElementById('selected_user_status').value,

					me.#refresh_routing_detail_ioiuserstatus(frm, cdt, cdn);

				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('user_status_nb_record'))
		{
			for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
			{
				if (document.getElementById('user_status_checked_id_' + i.toString()))
				{
					document.getElementById('user_status_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('user_status_id_' + i.toString()))
				{
					document.getElementById('user_status_id_' + i.toString()).remove();
				}

			}

			document.getElementById('user_status_nb_record').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:300px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=100% data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=5% align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=95% style="vertical-align: middle;">&nbsp;<b>' + __("User status") + '</b></td>';
		html += '</tr>';
		html += '</table>';



		let method = me.path_module_user_status + '.ioi_module_user_status_get_list';


		frappe.call({  	method: method,
						args: {	"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														html += '<table border=1 style="border: 1px solid #E8EAEB" width=100%>';

														let cur_userstatus = 0;

														if (line.ioiuserstatus)
														{
															cur_userstatus = line.ioiuserstatus;
														}

														let cpt = 0


														for (var i = 0; i < r.message.length; i++)
														{
															if (cur_userstatus != r.message[i][2])
															{
																html += '<tr style="height:30px">';

																html += '<td width=5% align="center" style="vertical-align: middle;">';
																html += '<input type="checkbox" id="user_status_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
																html += '       onclick=" ';
																html += '					for (var i = 0; i < document.getElementById(\'user_status_nb_record\').value; i++) ';
																html += '					{   if (document.getElementById(\'user_status_checked_id_\' + i.toString())) ';
																html += '						{ '
																html += '							if (document.getElementById(\'user_status_checked_id_\' + i.toString()).id != this.id) ';
																html += '							{ ';
																html += '								document.getElementById(\'user_status_checked_id_\' + i.toString()).checked = false; ';
																html += '							} ';
																html += '						} ';
																html += '					} ';
																html += '" ';

																html += '>';
																html += '<input type="hidden" id="user_status_id_' + cpt.toString() + '" value="' +  r.message[i][2] + '">';
																html += '</td>';

																html += '<td bgcolor="' + r.message[i][3] + '" width=95% style="vertical-align: middle;">&nbsp;<font color="' + r.message[i][4] + '">' + r.message[i][5] + ' ( ' + r.message[i][2] + ' )' + '</font></td>'

																html += '</tr>';
															}

															cpt++;
														}
														html += '<input type="hidden" id="user_status_nb_record" value="' + cpt.toString() + '">';
														html += '</table>';
													}else
													{
														html += '<input type="hidden" id="user_status_nb_record" value="0">';
													}
												}
		});

		html += '<input type="hidden" id="selected_user_status" value="">';
		html += '</div>';


		z.fields_dict.html_select_user_status.$wrapper.html(html);

		z.show();
	}



	// ***************************************************************************************************************************************
	// Routing : Format all numbers
	// ***************************************************************************************************************************************

	form_routing_format_all_numbers(grid_idx)
	{
		this.form_format_qty_float('routing_detail', 'workcenter_unit_rate', grid_idx);
		this.form_format_qty_float('routing_detail', 'workcenter_full_setup_duration', grid_idx);
		this.form_format_qty_float('routing_detail', 'workcenter_unit_duration', grid_idx);
		this.form_format_qty_float('routing_detail', 'workcenter_preparation_duration', grid_idx);
		this.form_format_qty_float('routing_detail', 'required_workcenter_hours', grid_idx);
		this.form_format_qty_float('routing_detail', 'registered_workcenter_hours', grid_idx);

		this.form_format_qty_float('routing_detail', 'worker_unit_rate', grid_idx);
		this.form_format_qty_float('routing_detail', 'worker_full_setup_duration', grid_idx);
		this.form_format_qty_float('routing_detail', 'worker_unit_duration', grid_idx);
		this.form_format_qty_float('routing_detail', 'work_preparation_duration', grid_idx);
		this.form_format_qty_float('routing_detail', 'required_worker_hours', grid_idx);
		this.form_format_qty_float('routing_detail', 'worker_workcenter_rate', grid_idx);
		this.form_format_qty_float('routing_detail', 'registered_worker_hours', grid_idx);

		this.form_format_qty_float('routing_detail', 'received_qty', grid_idx);
		this.form_format_qty_float('routing_detail', 'produced_qty', grid_idx);
		this.form_format_qty_float('routing_detail', 'to_check_qty', grid_idx);
		this.form_format_qty_float('routing_detail', 'bad_qty', grid_idx);
		this.form_format_qty_float('routing_detail', 'transmitted_qty', grid_idx);
	}


	// ***************************************************************************************************************************************
	// Routing : set workcenter description
	// ***************************************************************************************************************************************

	set_workcenter_descriptions(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if ((line.workcenter_id) && (line.workcenter_id.trim() != '')) {

			let me = this;
			let method = this.path_workcenter + '.ioi_workcenter_get_data';

			frappe.call({  	method: method,
							args: {"name": line.workcenter_id},
							async: false,
							callback:function(r) {
								line.description = r.message[0].description;
								line.workcenter_budget_id = r.message[0].workcenter_budget_id
								line.line_machine_id = '';

								line.workcenter_cost = r.message[0].workcenter_cost;
								line.usual_operator_cost = r.message[0].usual_operator_cost;

								me.set_query('line_machine_id', 'routing_detail', me.path_line_machine + '.ioi_line_machine_get_enabled', '{"workcenter_id" : "' + line.workcenter_id + '"}');
							}
			});

		}else{
			line.description = '';
			line.workcenter_budget_id = ''
			line.line_machine_id =  '';
			line.workcenter_cost = 0;
			line.usual_operator_cost = 0;

			this.set_query('line_machine_id', 'routing_detail', this.path_line_machine + '.ioi_line_machine_get_none');
		}
	}

	// ***************************************************************************************************************************************
	// Routing : set enable_read_only fields
	// ***************************************************************************************************************************************

	set_enable_readonly_fields(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if ((!line.workcenter_unit_time_def) || ((line.workcenter_unit_time_def) && (line.workcenter_unit_time_def == 0))) {
			//this.frm.set_df_property('workcenter_unit_duration', 'read_only', 0);

			//this.frm.set_df_property('workcenter_unit_rate', 'read_only', 1);

			if ((line.workcenter_unit_duration) && (line.workcenter_unit_duration != 0)) {
				line.workcenter_unit_rate = parseFloat(1/parseFloat(line.workcenter_unit_duration));
			}else{
				line.workcenter_unit_rate =  0;
			}

		}else{
			//this.frm.set_df_property('workcenter_unit_duration', 'read_only', 1);

			if ((line.workcenter_unit_rate) && (line.workcenter_unit_rate != 0)) {
				line.workcenter_unit_duration = parseFloat(1/parseFloat(line.workcenter_unit_rate));
			}else{
				this.frm.set_value('workcenter_unit_duration', 0);
			}

			//this.frm.set_df_property('workcenter_unit_rate', 'read_only', 0);
		}

		this.frm.refresh_field('routing_detail');
	}


	// ***************************************************************************************************************************************
	// Routing : assign workcenter unit rate
	// ***************************************************************************************************************************************

	assign_workcenter_unit_rate(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if ((line.workcenter_unit_duration) && (line.workcenter_unit_duration != 0)) {
			line.workcenter_unit_rate = parseFloat(1/parseFloat(line.workcenter_unit_duration));
		}else{
			line.workcenter_unit_rate = 0;
		}

		this.frm.refresh_field('routing_detail');
	}

	// ***************************************************************************************************************************************
	// Routing : format workcenter unit duration
	// ***************************************************************************************************************************************

	format_workcenter_unit_duration(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		let html = '';
		this.frm.fields_dict['routing_detail'].grid.grid_rows[line.idx-1].grid_form.fields_dict['workcenter_unit_duration_html'].$wrapper.empty();

		if ((!line.workcenter_unit_duration) || ((line.workcenter_unit_duration) && ((line.workcenter_unit_duration == 0) || (line.workcenter_unit_duration.toString() == '')))) {
			html = '';
		}else{

			let value = parseFloat(line.workcenter_unit_duration);

			let h = Math.trunc(value);
			let m = Math.round((value - h ) * 60);

			let s = '';

			if (h != 0) {

				if (h == 1) {
					s += h.toString() + ' ' + __("hour");
				}else{
					s += h.toString() + ' ' + __("hours");
				}
			}

			if (m != 0) {

				if (s != '') {
					s += ' ';
				}

				if (m == 1) {
					s += m.toString() + ' ' + __("minute");
				}else{
					s += m.toString() + ' ' + __("minutes");
				}
			}

			if (s.trim() != '') {
				s = '<u>' + __("Duration") + '</u> ' + s;
			}

			html += s;

		}
		this.frm.fields_dict['routing_detail'].grid.grid_rows[line.idx-1].grid_form.fields_dict['workcenter_unit_duration_html'].$wrapper.append(html);
	}

	// ***************************************************************************************************************************************
	// Routing : assign workcenter unit duration
	// ***************************************************************************************************************************************

	assign_workcenter_unit_duration(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if ((line.workcenter_unit_rate) && (line.workcenter_unit_rate != 0)) {
			line.workcenter_unit_duration = parseFloat(1/parseFloat(line.workcenter_unit_rate));
		}else{
			line.workcenter_unit_duration =  0;
		}

		this.frm.refresh_field('routing_detail');

	}


	// ***************************************************************************************************************************************
	// Routing : set enable readonly worker fields
	// ***************************************************************************************************************************************

	set_enable_readonly_worker_fields(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if ((!line.worker_unit_time_def) || ((line.worker_unit_time_def) && (line.worker_unit_time_def == 0))) {
			//this.frm.set_df_property('worker_unit_duration', 'read_only', 0);

			//this.frm.set_df_property('worker_unit_rate', 'read_only', 1);

			if ((line.worker_unit_duration) && (line.worker_unit_duration != 0)) {
				line.worker_unit_rate = parseFloat(1/parseFloat(line.worker_unit_duration));
			}else{
				line.worker_unit_rate = 0;
			}

		}else{
			//this.frm.set_df_property('worker_unit_duration', 'read_only', 1);

			if ((line.worker_unit_rate) && (line.worker_unit_rate != 0)) {
				line.worker_unit_duration = parseFloat(1/parseFloat(line.worker_unit_rate));
			}else{
				line.worker_unit_duration = 0;
			}

			//this.frm.set_df_property('worker_unit_rate', 'read_only', 0);
		}

		this.frm.refresh_field('routing_detail');
	}


	// ***************************************************************************************************************************************
	// Routing : assign worker unit rate
	// ***************************************************************************************************************************************

	assign_worker_unit_rate(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if ((line.worker_unit_duration) && (line.worker_unit_duration != 0)) {
			line.worker_unit_rate = parseFloat(1/parseFloat(line.worker_unit_duration));
		}else{
			line.worker_unit_rate = 0;
		}

		this.frm.refresh_field('routing_detail');
	}

	// ***************************************************************************************************************************************
	// Routing : format worker unit duration
	// ***************************************************************************************************************************************

	format_worker_unit_duration(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		let html = '';

		this.frm.fields_dict['routing_detail'].grid.grid_rows[line.idx-1].grid_form.fields_dict['worker_unit_duration_html'].$wrapper.empty();


		if ((!line.worker_unit_duration) || ((line.worker_unit_duration) && ((line.worker_unit_duration == 0) || (line.worker_unit_duration.toString() == '')))) {
			html = '';
		}else{

			let value = parseFloat(line.worker_unit_duration);

			let h = Math.trunc(value);
			let m = Math.round((value - h ) * 60);

			let s = '';

			if (h != 0) {

				if (h == 1) {
					s += h.toString() + ' ' + __("hour");
				}else{
					s += h.toString() + ' ' + __("hours");
				}
			}

			if (m != 0) {

				if (s != '') {
					s += ' ';
				}

				if (m == 1) {
					s += m.toString() + ' ' + __("minute");
				}else{
					s += m.toString() + ' ' + __("minutes");
				}
			}

			if (s.trim() != '') {
				s = '<u>' + __("Duration") + '</u> ' + s;
			}

			html += s;

		}

		this.frm.fields_dict['routing_detail'].grid.grid_rows[line.idx-1].grid_form.fields_dict['worker_unit_duration_html'].$wrapper.append(html);

	}

	// ***************************************************************************************************************************************
	// Routing : assign worker unit duration
	// ***************************************************************************************************************************************

	assign_worker_unit_duration(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if ((line.worker_unit_rate) && (line.worker_unit_rate != 0)) {
			line.worker_unit_duration = parseFloat(1/parseFloat(line.worker_unit_rate));
		}else{
			line.worker_unit_duration = 0;
		}

		this.frm.refresh_field('routing_detail');
	}


	// ***************************************************************************************************************************************
	// Routing : apply workcenter rate
	// ***************************************************************************************************************************************

	apply_workcenter_rate(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if (line.worker_workcenter_rate) {

			if ((parseFloat(line.worker_workcenter_rate) < 0) || (parseFloat(line.worker_workcenter_rate) > 100)) {
				frappe.msgprint({title: __("Message"), message: __('Worker workcenter rate has to be between 0 and 100'), indicator: "red"});
				return false;
			}

			if (parseFloat(line.worker_workcenter_rate) != 0) {

				line.worker_unit_time_def = line.workcenter_unit_time_def;
				line.worker_unit_duration = parseFloat(line.workcenter_unit_duration) * (parseFloat(line.worker_workcenter_rate) / 100.00);
				line.worker_unit_rate = parseFloat(line.workcenter_unit_rate) * (parseFloat(line.worker_workcenter_rate) / 100.00);

				if ((!line.worker_unit_time_def) || ((line.worker_unit_time_def) && (line.worker_unit_time_def == 0))) {

					if (parseFloat(line.worker_unit_duration) != 0) {
						line.worker_unit_rate = 1.00 / parseFloat(line.worker_unit_duration);
					}else{
						line.worker_unit_rate = 0;
					}
				}else{
					if (parseFloat(line.worker_unit_rate) != 0) {
						line.worker_unit_duration = 1.00 / parseFloat(line.worker_unit_rate);
					}else{
						line.worker_unit_duration = 0;
					}
				}
			}
		}

		this.frm.refresh_field('routing_detail');
	}

	// ***************************************************************************************************************************************
	// Routing : compute required workcenter hours
	// ***************************************************************************************************************************************

	compute_required_workcenter_hours(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		let workcenter_full_setup_duration = 0;

		if ((!this.frm.doc.already_manufactured) || ((this.frm.doc.already_manufactured) && (this.frm.doc.already_manufactured == 0))) {
			workcenter_full_setup_duration = parseFloat(line.workcenter_full_setup_duration);
		}

		let workcenter_preparation_duration = 0;

		if ((!this.frm.doc.already_prepared) || ((this.frm.doc.already_prepared) && (this.frm.doc.already_prepared == 0))) {
			workcenter_preparation_duration = parseFloat(line.workcenter_preparation_duration);
		}

		line.required_workcenter_hours = parseFloat(workcenter_full_setup_duration) + parseFloat(workcenter_preparation_duration) + (parseFloat(line.workcenter_unit_duration) * parseFloat(line.parent_cons_unit_qty) * parseFloat(this.frm.doc.ordered_qty));

		this.frm.refresh_field('routing_detail');
	}

	// ***************************************************************************************************************************************
	// Routing : compute required worker hours
	// ***************************************************************************************************************************************

	compute_required_worker_hours(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		let worker_full_setup_duration = 0;

		if ((!this.frm.doc.already_manufactured) || ((this.frm.doc.already_manufactured) && (this.frm.doc.already_manufactured == 0))) {
			worker_full_setup_duration = parseFloat(line.worker_full_setup_duration);
		}

		let work_preparation_duration = 0;

		if ((!this.frm.doc.already_prepared) || ((this.frm.doc.already_prepared) && (this.frm.doc.already_prepared == 0))) {
			work_preparation_duration = parseFloat(line.work_preparation_duration);
		}

		line.required_worker_hours = parseFloat(worker_full_setup_duration) + parseFloat(work_preparation_duration) + (parseFloat(line.worker_unit_duration) * parseFloat(line.parent_cons_unit_qty) * parseFloat(this.frm.doc.ordered_qty));

		this.frm.refresh_field('routing_detail');
	}

	// ***************************************************************************************************************************************
	// Routing : reactive a Routing line
	// ***************************************************************************************************************************************

	reactive_routing(cdt, cdn)
	{
		if (this.frm.doc.ioistatus != 0) {
			frappe.msgprint({title: __("Message"), message: __("Production status has to be in Preparation (0)"), indicator: "red"});
			return false;
		}

		let line = locals[cdt][cdn];

		if (line.ioistatus != 3) {
			frappe.msgprint({title: __("Message"), message: __("Production Routing status has to be Ended (3)"), indicator: "red"});
			return false;

		}

		let top = 10;

		let msg = '';

		let s = __("Do you want to re-activate this Routing ?");
		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
		top += 30;

		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';

		let me = this;

		frappe.confirm(	msg,
			() => 	{
						me.#sleep(200).then(() => {

							if (this.frm.is_dirty()) {
								let fct_callback = function () { me.do_reactive_routing(cdt, cdn); };
								me.frm.save('Save', fct_callback);
							}else {
								me.do_reactive_routing(cdt, cdn);
							}
						});
					},
			() => 	{
					}
		);

	}

	do_reactive_routing(cdt, cdn)
	{
		let line = locals[cdt][cdn];
		line.ioistatus = 0;
		this.frm.dirty();
		this.frm.save();
	}

	select_line(cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if ((!line.ioistatus) || ((line.ioistatus) && (line.ioistatus == 0))) {

			if ((!line.workcenter_id) || ((line.workcenter_id) && (line.workcenter_id.trim() == ''))) {
				frappe.msgprint({title: __("Message"), message: __('The workcenter is mandatory'), indicator: "red"});
				return false;
			}

			let title = __("Select a line machine");
			let form_width_pixel = '680px';
			let table = "ioi Line Machine";
			let fields = 'name, description';

			let fields_len = '250, 350';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1 and workcenter_id = '" + line.workcenter_id.replaceAll("'", "''") + "' ";
			let order_by = 'name asc';

			let me = this;


			let fct_callback = function (return_value) {

				if (!me.frm.is_dirty()) {
					me.frm.dirty();
				}
				line.line_machine_id = return_value;


				me.frm.refresh_field('routing_detail');
				return false;
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

		}else{
			frappe.msgprint({title: __("Message"), message: __('Routing has to be in "Preparation"'), indicator: "red"});
		}
	}

	clear_line(cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if ((!line.ioistatus) || ((line.ioistatus) && (line.ioistatus == 0))) {

			if (!this.frm.is_dirty()) {
				this.frm.dirty();
			}
			line.line_machine_id = '';

			this.frm.refresh_field('routing_detail');

		}else{
			frappe.msgprint({title: __("Message"), message: __('Routing has to be in "Preparation"'), indicator: "red"});
		}
	}


	remove_html_element(id)
	{
		if (document.getElementById(id)) {
			document.getElementById(id).remove();
		}
	}

}

silicon_ioi.doctype.ioiProductionDocumentDocType = ioiProductionDocumentDocType;
