// *******************************************************************************************************************************************
// Status Class
// *******************************************************************************************************************************************

frappe.provide('silicon_ioi.doctype');

export class ioiDocumentStatus
{
	path_module_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status';
	path_module_constraint = 'silicon_ioi.ioi_configuration.doctype.ioi_module_constraint.ioi_module_constraint';
	path_purchases_journal = 'silicon_ioi.ioi_purchases.doctype.ioi_purchases_journal.ioi_purchases_journal';
	static path_document_change_status = 'silicon_ioi.common.document_change_status';
	img_logo_path = '/assets/silicon_ioi/images/logo_100x100.png';

	ioistatus = [];
	ioistatus_inactive = [];

	static to_status = -20;

	constructor(frm)
	{
		this.frm = frm;

		let html = '';

		if (document.getElementById('hidden_ioistatus'))
		{	document.getElementById('hidden_ioistatus').remove();
		}

		if (document.getElementById('hidden_can_edit_document'))
		{	document.getElementById('hidden_can_edit_document').remove();
		}

		if (document.getElementById('ioistatus_id'))
		{	document.getElementById('ioistatus_id').remove();
		}

		if (document.getElementById('ioistatus_color'))
		{	document.getElementById('ioistatus_color').remove();
		}


		html = '<input id="hidden_ioistatus" name="hidden_ioistatus" type="hidden" value="0">';
		html += '<input id="hidden_can_edit_document" name="hidden_can_edit_document" type="hidden" value="0">';
		html += '<select id="ioistatus_id" name="ioistatus_id" hidden></select>';
		html += '<select id="ioistatus_color" name="ioistatus_color" hidden></select>';
		html += '<input id="buttons_disabled" name="buttons_disabled" type="hidden" value="0">';

		if (this.frm.$wrapper[0].childElementCount < 3)
		{	this.frm.$wrapper[0].children[1].children[1].children[0].insertAdjacentHTML('beforeend', html);
		}else
		{	this.frm.$wrapper[0].children[3].children[1].children[0].insertAdjacentHTML('beforeend', html);
		}

	}

	// ***************************************************************************************************************************************
	// Fill Status
	// ***************************************************************************************************************************************
	fill_status()
	{
		let me = this;

		let method = this.path_module_status + '.ioi_module_status_get_list';

		frappe.call({  	method: method,
						args: {"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (r.message.length != 0)
													{
														me.ioistatus = [];

														for (var i = 0; i < r.message.length; i++)
														{	//                 code            description      bgcolor          fontcolor        shape            shape_disabled   can_edit_document
															me.ioistatus[i] = [r.message[i][2],r.message[i][5], r.message[i][3], r.message[i][4], r.message[i][7], r.message[i][8], r.message[i][9]];

															let option1 = document.createElement("option");
															option1.text = r.message[i][2];
															document.getElementById("ioistatus_id").add(option1)

															let option2 = document.createElement("option");
															option2.text = r.message[i][3];
															document.getElementById("ioistatus_color").add(option2)

														}
													}
												}
		});

		let st = 0;

		if ((this.frm.doc.ioistatus) && (this.frm.doc.ioistatus.toString().trim() != ''))
		{
			st = this.frm.doc.ioistatus;
		}





		if (((this.frm.doctype.toUpperCase() != 'IOI SALES QUOTE') && (this.frm.doctype.toUpperCase() != 'IOI PURCHASES PRICE REQUEST')) ||
			((this.frm.doctype.toUpperCase() == 'IOI SALES QUOTE') && (this.frm.doc.document_type != 'CONTRACT')) ||
			((me.frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') && (this.frm.doc.document_type != 'CONTRACT')))
		{

			method = this.path_module_constraint + '.ioi_module_document_constraint_get_constraint_for_a_status';

			frappe.call({  	method: method,
							args: {"doctype": this.frm.doctype, "status" : st},
							async: false,
							callback:function(r)	{
														if (r.message.length != 0)
														{
															me.ioistatus_inactive = [];

															for (var i = 0; i < r.message.length; i++)
															{
																me.ioistatus_inactive[i] = r.message[i];
															}
														}
													}
			});

		}else
		{
			me.ioistatus_inactive = [];

			for (var i = -9; i <= 9; i++)
			{
				if (this.frm.doc.ioistatus != 0)
				{
					if ((i != -1) && (i != 0) && (i != 4) && (i != 5) && (i != 6))
					{
						me.ioistatus_inactive[i+9] = i;
					}
				}else
				{
					if ((i != -1) && (i != 0) && (i != 6))
					{
						me.ioistatus_inactive[i+9] = i;
					}

				}
			}

		}


		if (this.frm.doc.ioistatus)
		{
			if (this.frm.doc.ioistatus.toString() == '0')
			{
				document.getElementById('hidden_ioistatus').value = '0';
				document.getElementById('hidden_can_edit_document').value = '1';
			}else
			{
				for (var i = 0; i < this.ioistatus.length; i++)
				{
					if (this.ioistatus[i][0] == this.frm.doc.ioistatus)
					{
						document.getElementById('hidden_ioistatus').value = this.frm.doc.ioistatus;
						document.getElementById('hidden_can_edit_document').value = this.ioistatus[i][6];
					}
				}
			}
		}else
		{
			document.getElementById('hidden_ioistatus').value = '0';
			document.getElementById('hidden_can_edit_document').value = '1';
		}
	}

	// ***************************************************************************************************************************************
	// Build status frame
	// ***************************************************************************************************************************************
	build_status_frame(fct_instead_of_change_status=null)
	{
		let deliveries_reserve_on_sale = 0;

		if (!this.frm.is_new()) {

			if (this.frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') {

				let method = this.path_purchases_journal + '.ioi_purchases_journal_get_data';

				frappe.call({  	method: method,
								args: {"journal_id": this.frm.doc.journal_id},
								async: false,
								callback:function(r)	{

									deliveries_reserve_on_sale = r.message.deliveries_reserve_on_sale;
								}
				});
			}
		}

		let html = '';
		let cpt = 0;

 		//  code            description      bgcolor          fontcolor        shape            shape_disabled

		let disk = 'width: 30px; height: 30px; border-radius: 50%';
		let rectangle = 'width: 150px; height: 30px';
		let rounded_rectangle = 'width: 150px; height: 30px; border-radius:6px; padding:4px; display: auto';
		let square = 'width: 30px; height: 30px';
		let rounded_square = 'width: 30px; height: 30px; border-radius:6px; padding:4px; display: auto';

		let shape = disk;

		html += '<head>';
		html += '<style id="style_status">';
		html += '.step { ';
		html += '	width: 50px; ';
		html += '	height: 30px; ';
		html += '	position: relative; ';
		html += '	background: red; ';
		html += '} ';
		html += '.step_after { ';
		html += '	content: ""; ';
		html += '	position: absolute; ';
		html += '	left: 0px; ';
		html += '	bottom: 0; ';
		html += '	width: 0; ';
		html += '	height: 0; ';

		// #F9FAFA;
		html += '	border-left: 15px solid transparent; ';
		html += '	border-top: 15px solid transparent; ';
		html += '	border-bottom: 15px solid transparent; ';
		html += '	} ';
		html += '.step_before { ';
		html += '	content: ""; ';
		html += '	position: absolute; ';
		html += '	right: -20px; ';
		html += '	bottom: 0; ';
		html += '	width: 0; ';
		html += '	height: 0; ';
		html += '	border-top: 15px solid transparent; ';
		html += '	border-bottom: 15px solid transparent; ';
		html += '} ';

		html += '.lock { ';
		html += '	font-size: 8px; ';
		html += '	position: relative; ';
		html += '	width: 60px; ';
		html += '	height: 30px; ';
		html += '	border-radius: 2em; ';
		html += '	top: 10em; ';
		html += '	box-sizing: border-box; ';
		html += '	border: 3.5em solid red; ';
		html += '	border-right-width: 7.5em; ';
		html += '	border-left-width: 7.5em; ';
		html += '	margin: 0 0 6rem 0; ';
		html += '} ';
		html += '.lock_before { ';
		html += '	content: ""; ';
		html += '	box-sizing: border-box; ';
		html += '	position: absolute; ',
		html += '	border: 2.5em solid red; ';
		html += '	width: 14em; ';
		html += '	height: 12em; ';
		html += '	left: 50%; ';
		html += '	margin-left: -7em; ';
		html += '	top: -12em; ';
		html += '	border-top-left-radius: 7em; ';
		html += '	border-top-right-radius: 7em; ';
		html += '} ';
		html += '.lock_after { ';
		html += '	content: ""; ';
		html += '	box-sizing: border-box; ';
		html += '	position: absolute; ';
		html += '	border: 1em solid red; ';
		html += '	width: 5em; ';
		html += '	height: 8em; ';
		html += '	border-radius: 2.5em; ';
		html += '	left: 50%; ';
		html += '	top: -1em; ';
		html += '	margin-left: -2.5em; ';
		html += '} ';

		html += '</style>';
		html += '</head>';

		html += '<div id="banner_status" align="center">';


		if (this.frm.$wrapper[0].childElementCount < 3)
		{
			html += '<table><tr><td id="banner_col_1" width=' + this.frm.$wrapper[0].children[1].children[1].children[0].children[2].children[0].clientWidth + 'px>&nbsp;</td>';
			html += '<td id="banner_col_2" width=' + this.frm.$wrapper[0].children[1].children[1].children[0].children[2].children[1].clientWidth + 'px>';
		}else
		{
			html += '<table><tr><td id="banner_col_1" width=' + this.frm.$wrapper[0].children[3].children[1].children[0].children[2].children[0].clientWidth + 'px>&nbsp;</td>';
			html += '<td id="banner_col_2" width=' + this.frm.$wrapper[0].children[3].children[1].children[0].children[2].children[1].clientWidth + 'px>';
		}

		html += '<div style="overflow:none; height:60px;">';
		html += '<table id="banner_table_shape" align="center" border=0 style="border: 0px solid #E8EAEB">';
		html += '<tr>';

		let s = 'STEP';
		let set_inactive = false

		let i = 0;

		for (i = 0; i < this.ioistatus.length; i++)
		{
			set_inactive = false;
			for (var k = 0; k < this.ioistatus_inactive.length; k++)
			{
				if (this.ioistatus_inactive[k] == this.ioistatus[i][0])
				{
					set_inactive = true;
					break;
				}
			}

			s = this.ioistatus[i][4].toUpperCase();

			if ((s == 'STEP') || (s == 'STEP_START') || (s == 'STEP_END'))
			{
				if (this.frm.$wrapper[0].childElementCount < 3)
				{
					if (this.frm.$wrapper[0].children[1].children[1].children[0].children[2].children[1].clientWidth <= 600)
					{
						s = 'ROUNDED_SQUARE';
					}
				}else
				{
					if (cur_frm.$wrapper[0].children[3].children[1].children[0].children[2].children[1].clientWidth <= 600)
					{
						s = 'ROUNDED_SQUARE';
					}
				}
			}

			if (s == 'STEP')
			{
				html += '<td style="vertical-align: middle;">';
				html += '<div id="shape_' + i.toString() + '" class="step" ';

				if (set_inactive)
				{	html += 'style="background-color: gray; text-align: center;" ';
				}else
				{	html += 'style="background:' + this.ioistatus[i][2] + '; text-align: center;" ';
				}

				html += ' onmousemove="silicon_ioi.doctype.ioiDocumentStatus.mousemove(this, document.getElementById(\'status_' + i.toString() + '\'), document.getElementById(\'status_desc_' + i.toString() + '\'), document.getElementById(\'shape_desc\'), cur_frm);" ';
				html += ' onmouseout="silicon_ioi.doctype.ioiDocumentStatus.mouseout(this, document.getElementById(\'status_' + i.toString() + '\'), document.getElementById(\'status_desc_' + i.toString() + '\'), document.getElementById(\'shape_desc\'), cur_frm);" ';

				if (!set_inactive)
				{
					html += ' onclick="silicon_ioi.doctype.ioiDocumentStatus.mouseclick(this, document.getElementById(\'status_' + i.toString() + '\'), document.getElementById(\'status_desc_' + i.toString() + '\'), cur_frm,' + fct_instead_of_change_status + ');" ';
					html += ' onmousedown="silicon_ioi.doctype.ioiDocumentStatus.mousedown(document.getElementById(\'status_' + i.toString() + '\'));" ';
				}
				html += ' align="center">';


				if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') {

					if (deliveries_reserve_on_sale == 1) {

						if (this.ioistatus[i][0] == 1) {

							if (!set_inactive) {
								html += '<div style="position:absolute; top: 8px; left: 20px; width: 20px; height: 15px;background-color: #FFFF6A; text-align: center; border: 1px solid #FFFF6A;"></div>';
							}else{
								html += '<div style="position:absolute; top: 8px; left: 20px; width: 20px; height: 15px;background-color: black; text-align: center; border: 1px solid black;"></div>';
							}

						}
					}
				}


				html += '<div id="shape_' + i.toString() + '_1" class="step_before" ';

				if (set_inactive)
				{	html += 'style="border-left: 20px solid gray; ';
				}else
				{	html += 'style="border-left: 20px solid ' + this.ioistatus[i][2]+ '; ';
				}

				html += '">';
				html += '</div>';

				if (((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') 	&& ((this.ioistatus[i][0] == 4) || (this.ioistatus[i][0] == 5))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') 			&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') 		&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 				&& ((this.ioistatus[i][0] == 4) || (this.ioistatus[i][0] == 5))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 				&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') 			&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))))
				{
					if (!set_inactive)
					{
						html += '<p style="font-size:18px;"><b>&nbsp;&nbsp;&nbsp;+</b></p>';
					}
				}


				html += '<div id="shape_' + i.toString() + '_2" class="step_after"></div> ';

				html += '</div>';

				html += '<input id="status_' + i.toString() + '" type="hidden" value="' + this.ioistatus[i][0] + '">'
				html += '<input id="status_desc_' + i.toString() + '" type="hidden" value="' + this.ioistatus[i][1] + '">'
				html += '</td>';
				html += '<td width=18px style="vertical-align: middle;">&nbsp;</td>';

			}else if (s == 'STEP_START')
			{

				if (i != 0)
				{
					if (this.ioistatus[i-1][4].toUpperCase() != 'STEP')
					{
						html += '<td width=15px style="vertical-align: middle;">&nbsp;</td>';

					}
				}
				html += '<td style="vertical-align: middle;">';
				html += '<div id="shape_' + i.toString() + '" class="step" ';


				if (set_inactive)
				{
					html += 'style="background-color: gray; ';
				}else
				{	html += 'style="background:' + this.ioistatus[i][2] +'; ';
				}

				html += 'border-radius: 6px 0px 0px 6px;text-align: center;" ';

				html += ' onmousemove="silicon_ioi.doctype.ioiDocumentStatus.mousemove(this, document.getElementById(\'status_' + i.toString() + '\'), document.getElementById(\'status_desc_' + i.toString() + '\'), document.getElementById(\'shape_desc\'), cur_frm);" ';
				html += ' onmouseout="silicon_ioi.doctype.ioiDocumentStatus.mouseout(this, document.getElementById(\'status_' + i.toString() + '\'), document.getElementById(\'status_desc_' + i.toString() + '\'), document.getElementById(\'shape_desc\'), cur_frm);" ';

				if (!set_inactive)
				{	html += ' onclick="silicon_ioi.doctype.ioiDocumentStatus.mouseclick(this, document.getElementById(\'status_' + i.toString() + '\'), document.getElementById(\'status_desc_' + i.toString() + '\'), cur_frm, ' + fct_instead_of_change_status + ');" ';
					html += ' onmousedown="silicon_ioi.doctype.ioiDocumentStatus.mousedown(document.getElementById(\'status_' + i.toString() + '\'));" ';
				}
				html += '>';

				if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') {

					if (deliveries_reserve_on_sale == 1) {

						if (this.ioistatus[i][0] == 1) {

							if (!set_inactive) {
								html += '<div style="position:absolute; top: 8px; left: 20px; width: 20px; height: 15px;background-color: #FFFF6A; text-align: center; border: 1px solid #FFFF6A;"></div>';
							}else{
								html += '<div style="position:absolute; top: 8px; left: 20px; width: 20px; height: 15px;background-color: black; text-align: center; border: 1px solid black;"></div>';
							}

						}
					}
				}



				if (((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') 	&& ((this.ioistatus[i][0] == 4) || (this.ioistatus[i][0] == 5))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') 			&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') 		&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 				&& ((this.ioistatus[i][0] == 4) || (this.ioistatus[i][0] == 5))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 				&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') 			&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))))
				{
					if (!set_inactive)
					{
						html += '<p style="font-size:18px;"><b>+</b></p>';
					}
				}


				html += '<div id="shape_' + i.toString() + '_1" class="step_before" ';

				if (set_inactive)
				{	html += 'style="border-left: 20px solid gray;"></div> ';
				}else
				{	html += 'style="border-left: 20px solid ' + this.ioistatus[i][2]+ '"></div>';
				}


				html += '<input id="status_' + i.toString() + '" type="hidden" value="' + this.ioistatus[i][0] + '">'
				html += '<input id="status_desc_' + i.toString() + '" type="hidden" value="' + this.ioistatus[i][1] + '">'
				html += '</td>';
				html += '<td width=18px style="vertical-align: middle;">&nbsp;</td>';

			}else if (s == 'STEP_END')
			{
				html += '<td style="vertical-align: middle;">';
				html += '<div id="shape_' + i.toString() + '" class="step" ';

				if (set_inactive)
				{
					html += 'style="background-color: gray; width:60px; border-radius: 0px 6px 6px 0px; text-align: center;" ';
				}else
				{	html += 'style="background:' + this.ioistatus[i][2] +'; width:60px; border-radius: 0px 6px 6px 0px; text-align: center;" ';
				}

				html += ' onmousemove="silicon_ioi.doctype.ioiDocumentStatus.mousemove(this, document.getElementById(\'status_' + i.toString() + '\'), document.getElementById(\'status_desc_' + i.toString() + '\'), document.getElementById(\'shape_desc\'), cur_frm);" ';
				html += ' onmouseout="silicon_ioi.doctype.ioiDocumentStatus.mouseout(this, document.getElementById(\'status_' + i.toString() + '\'), document.getElementById(\'status_desc_' + i.toString() + '\'), document.getElementById(\'shape_desc\'), cur_frm);" ';

				if (!set_inactive)
				{	html += ' onclick="silicon_ioi.doctype.ioiDocumentStatus.mouseclick(this, document.getElementById(\'status_' + i.toString() + '\'), document.getElementById(\'status_desc_' + i.toString() + '\'), cur_frm, ' + fct_instead_of_change_status + ');" ';
					html += ' onmousedown="silicon_ioi.doctype.ioiDocumentStatus.mousedown(document.getElementById(\'status_' + i.toString() + '\'));" ';
				}
				html += '>';

				if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') {

					if (deliveries_reserve_on_sale == 1) {

						if (this.ioistatus[i][0] == 1) {

							if (!set_inactive) {
								html += '<div style="position:absolute; top: 8px; left: 20px; width: 20px; height: 15px;background-color: #FFFF6A; text-align: center; border: 1px solid #FFFF6A;"></div>';
							}else{
								html += '<div style="position:absolute; top: 8px; left: 20px; width: 20px; height: 15px;background-color: black; text-align: center; border: 1px solid black;"></div>';
							}

						}
					}
				}



				if (((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') 	&& ((this.ioistatus[i][0] == 4) || (this.ioistatus[i][0] == 5))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') 			&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') 		&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 				&& ((this.ioistatus[i][0] == 4) || (this.ioistatus[i][0] == 5))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 				&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') 			&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))))
				{
					if (!set_inactive)
					{
						html += '<p style="font-size:18px;"><b>+</b></p>';
					}
				}

				html += '<div id="shape_' + i.toString() + '_1" class="step_after" ';

				if (set_inactive)
				{	html += 'style="border-left: -20px solid gray; "></div> ';
				}else
				{	html += 'style="border-left: -20px solid ' + this.ioistatus[i][2]+ '; border-radius: 0px 6px 6px 0px;"></div>';
				}

				html += '<input id="status_' + i.toString() + '" type="hidden" value="' + this.ioistatus[i][0] + '">'
				html += '<input id="status_desc_' + i.toString() + '" type="hidden" value="' + this.ioistatus[i][1] + '">'
				html += '</td>';
				html += '<td width=12px style="vertical-align: middle;">&nbsp;</td>';
			}else
			{
				if (s == 'DISK')
				{	shape = disk;
				}else if (s == 'RECTANGLE')
				{	shape = rectangle;
				}else if (s == 'ROUNDED_RECTANGLE')
				{	shape = rounded_rectangle;
				}else if (s == 'SQUARE')
				{	shape = square;
				}else if (s == 'ROUNDED_SQUARE')
				{	shape = rounded_square;
				}

				html += '<td style="vertical-align: middle;">';
				html += '<div id="shape_' + i.toString() + '"  style="' + shape + '; ';

				if (set_inactive)
				{	html += 'background-color: gray; text-align: center; ';
				}else
				{	html += 'background:' + this.ioistatus[i][2] +'; text-align: center; ';
				}

				html += 'border: 1px solid #E8EAEB" ';

				html += ' onmousemove="silicon_ioi.doctype.ioiDocumentStatus.mousemove(this, document.getElementById(\'status_' + i.toString() + '\'), document.getElementById(\'status_desc_' + i.toString() + '\'), document.getElementById(\'shape_desc\'), cur_frm);" ';
				html += ' onmouseout="silicon_ioi.doctype.ioiDocumentStatus.mouseout(this, document.getElementById(\'status_' + i.toString() + '\'), document.getElementById(\'status_desc_' + i.toString() + '\'), document.getElementById(\'shape_desc\'), cur_frm);" ';
				if (!set_inactive)
				{
					html += ' onclick="silicon_ioi.doctype.ioiDocumentStatus.mouseclick(this, document.getElementById(\'status_' + i.toString() + '\'), document.getElementById(\'status_desc_' + i.toString() + '\'), cur_frm, ' + fct_instead_of_change_status + ');" ';
					html += ' onmousedown="silicon_ioi.doctype.ioiDocumentStatus.mousedown(document.getElementById(\'status_' + i.toString() + '\'));" ';
				}

				html += '>';


				if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') {

					if (deliveries_reserve_on_sale == 1) {

						if (this.ioistatus[i][0] == 1) {

							if ((s == 'DISK') || (s == 'SQUARE') || (s == 'ROUNDED_SQUARE')) {

								if ((s == 'DISK') || (s == 'SQUARE')) {

									if (!set_inactive) {
										html += '<div style="position:relative; top: 6px; left: 7px; width: 15px; height: 15px;background-color: #FFFF6A; text-align: center; border: 1px solid #FFFF6A;"></div>';
									}else{
										html += '<div style="position:relative; top: 6px; left: 7px; width: 15px; height: 15px;background-color: black; text-align: center; border: 1px solid black;"></div>';
									}

								}else{
									if (!set_inactive) {
										html += '<div style="position:relative; top: 2px; left: 3px; width: 15px; height: 15px;background-color: #FFFF6A; text-align: center; border: 1px solid #FFFF6A;"></div>';
									}else{
										html += '<div style="position:relative; top: 2px; left: 3px; width: 15px; height: 15px;background-color: black; text-align: center; border: 1px solid black;"></div>';
									}

								}

							}else{

								if (s == 'RECTANGLE') {

									if (!set_inactive) {
										html += '<div style="position:relative; top: 6px; left: 65px; width: 20px; height: 15px;background-color: #FFFF6A; text-align: center; border: 1px solid #FFFF6A;"></div>';
									}else{
										html += '<div style="position:relative; top: 6px; left: 65px; width: 20px; height: 15px;background-color: black; text-align: center; border: 1px solid black;"></div>';
									}

								}else{

									if (!set_inactive) {
										html += '<div style="position:relative; top: 2px; left: 65px; width: 20px; height: 15px;background-color: #FFFF6A; text-align: center; border: 1px solid #FFFF6A;"></div>';
									}else{
										html += '<div style="position:relative; top: 2px; left: 65px; width: 20px; height: 15px;background-color: black; text-align: center; border: 1px solid black;"></div>';
									}

								}

							}


						}
					}
				}


				if (((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') 	&& ((this.ioistatus[i][0] == 4) || (this.ioistatus[i][0] == 5))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') 			&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') 		&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 				&& ((this.ioistatus[i][0] == 4) || (this.ioistatus[i][0] == 5))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 				&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') 			&& ((this.ioistatus[i][0] == 3) || (this.ioistatus[i][0] == 4))))
				{
					if (!set_inactive)
					{
						html += '<p style="font-size:18px;"><b>+</b></p>';
					}
				}

				html += '</div>';
				html += '<input id="status_' + i.toString() + '" type="hidden" value="' + this.ioistatus[i][0] + '">'
				html += '<input id="status_desc_' + i.toString() + '" type="hidden" value="' + this.ioistatus[i][1] + '">'
				html += '</td>';
				html += '<td width=8px style="vertical-align: middle;">&nbsp;</td>';
			}

			cpt += 2;
		}

		html += '<input id="nb_element" type="hidden" value="' + i.toString() + '">'



		html += '</tr>';

		let cur_st = 0;

		if (this.frm.doc.ioistatus)
		{
			cur_st = this.frm.doc.ioistatus;
		}

		// underline the current status
		html += '<tr height="3px"><td></td></tr>';

		html += '<tr height="2px">';
		for (i = 0; i < this.ioistatus.length; i++)
		{
			s = this.ioistatus[i][4].toUpperCase();

			if ((s == 'STEP') || (s == 'STEP_START') || (s == 'STEP_END'))
			{
				if (this.frm.$wrapper[0].childElementCount < 3)
				{
					if (this.frm.$wrapper[0].children[1].children[1].children[0].children[2].children[1].clientWidth <= 600)
					{
						s = 'ROUNDED_SQUARE';
					}
				}else
				{
					if (cur_frm.$wrapper[0].children[3].children[1].children[0].children[2].children[1].clientWidth <= 600)
					{
						s = 'ROUNDED_SQUARE';
					}
				}
			}

			if (s == 'STEP')
			{
				if (this.ioistatus[i][0] == cur_st)
				{
					html += '<td style="vertical-align: middle;" bgcolor="#A1CDF7"></td>';
					html += '<td width=18px style="vertical-align: middle;" bgcolor="#A1CDF7"></td>';
				}else
				{
					html += '<td style="vertical-align: middle;"></td>';
					html += '<td width=18px style="vertical-align: middle;"></td>';
				}
			}else if (s == 'STEP_START')
			{
				if (i != 0)
				{
					if (this.ioistatus[i-1][4].toUpperCase() != 'STEP')
					{
						html += '<td width=15px style="vertical-align: middle;"></td>';
					}
				}

				if (this.ioistatus[i][0] == cur_st)
				{
					html += '<td width=50px style="vertical-align: middle;" bgcolor="#A1CDF7"></td>';
					html += '<td width=18px style="vertical-align: middle;" bgcolor="#A1CDF7"></td>';
				}else
				{
					html += '<td width=50px style="vertical-align: middle;"></td>';
					html += '<td width=18px style="vertical-align: middle;"></td>';
				}
			}else if (s == 'STEP_END')
			{
				if (this.ioistatus[i][0] == cur_st)
				{
					html += '<td style="vertical-align: middle;" bgcolor="#A1CDF7"></td>';
					html += '<td width=12px style="vertical-align: middle;"></td>';
				}else
				{
					html += '<td style="vertical-align: middle;"></td>';
					html += '<td width=12px style="vertical-align: middle;"></td>';
				}
			}else
			{
				if (this.ioistatus[i][0] == cur_st)
				{
					html += '<td style="vertical-align: middle;" bgcolor="#A1CDF7"></td>';
					html += '<td width=8px style="vertical-align: middle;"></td>';
				}else
				{
					html += '<td style="vertical-align: middle;"></td>';
					html += '<td width=8px style="vertical-align: middle;"></td>';
				}
			}
		}

		html += '</tr>';

		html += '<tr>';
		html += '<td align="center" colspan=' + cpt.toString() + '>';
		html += '<p align="center" id="shape_desc"></p>';
		html += '</td>';
		html += '</tr>';

		html += '</table>';
		html += '</div></td></tr></table>';


		if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') ||
			(cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE')			 || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER')	 ||	(cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')	   || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE'))
		{
			if ((cur_frm.doc.approval_status == 1) || (cur_frm.doc.approval_status == 2)) {

				if (this.frm.$wrapper[0].childElementCount < 3)
				{
					html += '<table><tr><td id="banner_col_3" width=' + this.frm.$wrapper[0].children[1].children[1].children[0].children[2].children[0].clientWidth + 'px>&nbsp;</td>';
					html += '<td id="banner_col_4" width=' + this.frm.$wrapper[0].children[1].children[1].children[0].children[2].children[1].clientWidth + 'px>';
				}else
				{
					html += '<table><tr><td id="banner_col_3" width=' + this.frm.$wrapper[0].children[3].children[1].children[0].children[2].children[0].clientWidth + 'px>&nbsp;</td>';
					html += '<td id="banner_col_4" width=' + this.frm.$wrapper[0].children[3].children[1].children[0].children[2].children[1].clientWidth + 'px>';
				}

				html += '<div style="overflow:none; height:30px;">';
				html += '<table align="center" border=0 style="border: 0px solid #E8EAEB">';
				html += '<tr>';
				html += '<td align="center" colspan=' + cpt.toString() + ' onmousemove="" onmouseout="">';
				html += '<div align="center" style="vertical-align: middle; height:30px; border-radius:6px; padding: 4px; ';

				if (cur_frm.doc.approval_status == 1) {
					html += 'background-color: #FEFFAD; color:#000000;"><b>&nbsp;&nbsp;&nbsp;' + __('Approval is required') + '&nbsp;&nbsp;&nbsp;</b>';
				}else{
					html += 'background-color: #FAC67D; color:#000000;"><b>&nbsp;&nbsp;&nbsp;' + __('This document is not fully approved') + '&nbsp;&nbsp;&nbsp;</b>';
				}

				html += '</div>';
				html += '</td>';
				html += '</tr>';
				html += '</table>';
				html += '</div></td></tr></table>';



				if (this.frm.$wrapper[0].childElementCount < 3)
				{
					html += '<table><tr><td id="banner_col_5" width=' + this.frm.$wrapper[0].children[1].children[1].children[0].children[2].children[0].clientWidth + 'px>&nbsp;</td>';
					html += '<td id="banner_col_6" width=' + this.frm.$wrapper[0].children[1].children[1].children[0].children[2].children[1].clientWidth + 'px>';
				}else
				{
					html += '<table><tr><td id="banner_col_5" width=' + this.frm.$wrapper[0].children[3].children[1].children[0].children[2].children[0].clientWidth + 'px>&nbsp;</td>';
					html += '<td id="banner_col_6" width=' + this.frm.$wrapper[0].children[3].children[1].children[0].children[2].children[1].clientWidth + 'px>';
				}

				html += '<div style="overflow:none; height:10px;">&nbsp;';
				html += '</div></td></tr></table>';

			}else{

				if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {

					if (cur_frm.doc.ioistatus == 0) {

						if (cur_frm.doc.cashdesk_pay_on_delivery == 1) {

							if (cur_frm.doc.cashdesk_payment_status_id != 2) {

								if (this.frm.$wrapper[0].childElementCount < 3)
								{
									html += '<table><tr><td id="banner_col_3" width=' + this.frm.$wrapper[0].children[1].children[1].children[0].children[2].children[0].clientWidth + 'px>&nbsp;</td>';
									html += '<td id="banner_col_4" width=' + this.frm.$wrapper[0].children[1].children[1].children[0].children[2].children[1].clientWidth + 'px>';
								}else
								{
									html += '<table><tr><td id="banner_col_3" width=' + this.frm.$wrapper[0].children[3].children[1].children[0].children[2].children[0].clientWidth + 'px>&nbsp;</td>';
									html += '<td id="banner_col_4" width=' + this.frm.$wrapper[0].children[3].children[1].children[0].children[2].children[1].clientWidth + 'px>';
								}

								html += '<div style="overflow:none; height:30px;">';
								html += '<table align="center" border=0 style="border: 0px solid #E8EAEB">';
								html += '<tr>';
								html += '<td align="center" colspan=' + cpt.toString() + ' onmousemove="" onmouseout="">';
								html += '<div align="center" style="vertical-align: middle; height:30px; border-radius:6px; padding: 4px; ';

								html += 'background-color: #FEFFAD; color:#000000;"><b>&nbsp;&nbsp;&nbsp;' + __('Payment is required') + '&nbsp;&nbsp;&nbsp;</b>';

								html += '</div>';
								html += '</td>';
								html += '</tr>';
								html += '</table>';
								html += '</div></td></tr></table>';

								if (this.frm.$wrapper[0].childElementCount < 3)
								{
									html += '<table><tr><td id="banner_col_5" width=' + this.frm.$wrapper[0].children[1].children[1].children[0].children[2].children[0].clientWidth + 'px>&nbsp;</td>';
									html += '<td id="banner_col_6" width=' + this.frm.$wrapper[0].children[1].children[1].children[0].children[2].children[1].clientWidth + 'px>';
								}else
								{
									html += '<table><tr><td id="banner_col_5" width=' + this.frm.$wrapper[0].children[3].children[1].children[0].children[2].children[0].clientWidth + 'px>&nbsp;</td>';
									html += '<td id="banner_col_6" width=' + this.frm.$wrapper[0].children[3].children[1].children[0].children[2].children[1].clientWidth + 'px>';
								}

								html += '<div style="overflow:none; height:10px;">&nbsp;';
								html += '</div></td></tr></table>';



							}
						}
					}
				}
			}

		}


		html += '<div id="splash_screen" style="z-index: 1000; position:absolute; top: 210px; left: 0px; width:100%; height:100%; display:none;vertical-align:middle; justify-content: center; align-items: center;">';

		html += '<table id="table_splash" border=0 width="100%" height="300px" style="z-index:1001;">';
		html += '<tr>';
		html += '<td width=40% height=50px align="center">&nbsp;</td>';
		html += '<td width=20% height=50px align="center">&nbsp;</td>';
		html += '<td width=40% height=50px align="center">&nbsp;</td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=40% align="center">&nbsp;</td>';
		html += '<td width=20% align="center">';

			html += '<table id="table_splash_2" border=1 width="100%" height="250px" style="z-index:1001;">';
			html += '<tr>';
			html += '<td>';
				html += '<table id="table_splash_3" border=0 width="100%" height="250px" style="z-index:1001;">';
				html += '<tr>';
				html += '<td bgcolor="white" width=20% height=150px align="center"><img id="ioi_change_status_ioi_logo" src="' + this.img_logo_path + '" width="100px" height="100px"></img></td>';
				html += '</tr>';


				html += '<tr>';
				html += '<td width="20%" bgcolor="white" height=60px align="center"><div style="opacity: 10"><b><font size=5>' + __("Please wait ...") + '</font></b></div></td>';
				html += '</tr>';

				html += '<tr>';
				html += '<td bgcolor="white" width=20% height=30px align="center">';
					html += '<table id="table_splash_4" border=0 width="200px" height="100%">';
					html += '<tr>';
					html += '<td width=30px><div id="splash_square_1" style="width:30px;height:30px;background-color:#D9C8FB;""></div></td>';
					html += '<td width=10px></td>';
					html += '<td width=30px><div id="splash_square_2" style="width:30px;height:30px;background-color:#D9C8FB;""></div></td>';
					html += '<td width=10px></td>';
					html += '<td width=30px><div id="splash_square_3" style="width:30px;height:30px;background-color:#D9C8FB;""></div></td>';
					html += '<td width=10px></td>';
					html += '<td width=30px><div id="splash_square_4" style="width:30px;height:30px;background-color:#D9C8FB;""></div></td>';
					html += '<td width=10px></td>';
					html += '<td width=30px><div id="splash_square_5" style="width:30px;height:30px;background-color:#D9C8FB;""></div></td>';
					html += '<td width=10px></td>';
					html += '</tr>';
					html += '</table>';
				html+= '</td>';
				html += '</tr>';

				html += '<tr>';
				html += '<td bgcolor="white" width=20% height=20px align="center">&nbsp;</td>';
				html += '</tr>';


				html += '</table>';
			html +='</td>';
			html += '</tr>';
			html += '</table>';

		html += '</td>';
		html += '<td width=40% align="center">&nbsp;</td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=40% height=50px align="center">&nbsp;</td>';
		html += '<td width=20% height=50px align="center">&nbsp;</td>';
		html += '<td width=40% height=50px align="center">&nbsp;</td>';
		html += '</tr>';


		html += '</table>';

		html += '</div>';

		html += '</div>';


		if (document.getElementById('banner_col_1'))
		{	document.getElementById('banner_col_1').remove();
		}

		if (document.getElementById('banner_col_2'))
		{	document.getElementById('banner_col_2').remove();
		}

		if (document.getElementById('banner_col_3'))
		{	document.getElementById('banner_col_3').remove();
		}

		if (document.getElementById('banner_col_4'))
		{	document.getElementById('banner_col_4').remove();
		}

		if (document.getElementById('banner_col_5'))
		{	document.getElementById('banner_col_5').remove();
		}

		if (document.getElementById('banner_col_6'))
		{	document.getElementById('banner_col_6').remove();
		}


		if (document.getElementById('shape_desc'))
		{	document.getElementById('shape_desc').remove();
		}

		if (document.getElementById('splash_screen'))
		{	document.getElementById('splash_screen').remove();
		}

		if (document.getElementById('table_splash_4')) {
			document.getElementById('table_splash_4').remove();
		}

		if (document.getElementById('table_splash_3')) {
			document.getElementById('table_splash_3').remove();
		}

		if (document.getElementById('table_splash_2')) {
			document.getElementById('table_splash_2').remove();
		}

		if (document.getElementById('table_splash'))
		{	document.getElementById('table_splash').remove();
		}

		if (document.getElementById('splash_square_1'))
		{	document.getElementById('splash_square_1').remove();
		}

		if (document.getElementById('splash_square_2'))
		{	document.getElementById('splash_square_2').remove();
		}

		if (document.getElementById('splash_square_3'))
		{	document.getElementById('splash_square_3').remove();
		}

		if (document.getElementById('splash_square_4'))
		{	document.getElementById('splash_square_4').remove();
		}

		if (document.getElementById('splash_square_5'))
		{	document.getElementById('splash_square_5').remove();
		}

		if (document.getElementById('ioi_change_status_ioi_logo'))
		{	document.getElementById('ioi_change_status_ioi_logo').remove();
		}



		if (document.getElementById('nb_element'))
		{

			for (var k=0; k<parseInt(document.getElementById('nb_element').value); k++)
			{
				if (document.getElementById("shape_" + k.toString()))
				{
					document.getElementById("shape_" + k.toString()).remove();
				}

				if (document.getElementById("shape_" + k.toString() + "_1"))
				{
					document.getElementById("shape_" + k.toString() + "_1").remove();
				}

				if (document.getElementById("shape_" + k.toString() + "_2"))
				{
					document.getElementById("shape_" + k.toString() + "_2").remove();
				}

				if (document.getElementById("status_" + k.toString()))
				{
					document.getElementById("status_" + k.toString()).remove();
				}

				if (document.getElementById("status_desc_" + k.toString()))
				{
					document.getElementById("status_desc_" + k.toString()).remove();
				}

			}

			document.getElementById('nb_element').remove();
		}




		if (document.getElementById('style_status'))
		{	document.getElementById('style_status').remove();
		}

		if (document.getElementById('banner_status'))
		{	document.getElementById('banner_status').remove();
		}

		if (document.getElementById('banner_table_shape')) {
			document.getElementById('banner_table_shape').remove();
		}


		this.frm.$wrapper[0].children[1].insertAdjacentHTML('beforebegin', html);

		silicon_ioi.doctype.ioiDocumentStatus.replace_banner();

	}

	static replace_banner()
	{
		if (cur_frm.$wrapper[0].children[3])
		{
			if (document.getElementById("banner_col_1")) {
				document.getElementById("banner_col_1").width = cur_frm.$wrapper[0].children[3].children[1].children[0].children[2].children[0].clientWidth;
			}

			if (document.getElementById("banner_col_2")) {
				document.getElementById("banner_col_2").width = cur_frm.$wrapper[0].children[3].children[1].children[0].children[2].children[1].clientWidth;
			}

			if (document.getElementById("banner_col_3")) {
				document.getElementById("banner_col_3").width = cur_frm.$wrapper[0].children[3].children[1].children[0].children[2].children[0].clientWidth;
			}

			if (document.getElementById("banner_col_4")) {
				document.getElementById("banner_col_4").width = cur_frm.$wrapper[0].children[3].children[1].children[0].children[2].children[1].clientWidth;
			}

			if (document.getElementById("banner_col_5")) {
				document.getElementById("banner_col_5").width = cur_frm.$wrapper[0].children[3].children[1].children[0].children[2].children[0].clientWidth;
			}

			if (document.getElementById("banner_col_6")) {
				document.getElementById("banner_col_6").width = cur_frm.$wrapper[0].children[3].children[1].children[0].children[2].children[1].clientWidth;
			}
		}

		setTimeout(silicon_ioi.doctype.ioiDocumentStatus.replace_banner, 100);
	}

	// ***************************************************************************************************************************************
	// Mouse move
	// ***************************************************************************************************************************************
	static mousemove(shape, status, status_desc, label, cur_frm)
	{
		if (shape.style.backgroundColor != 'gray')
		{
			if (cur_frm.doc.ioistatus == status.value)
			{

				if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST'))
				{
					if ((status.value == 4) || (status.value == 5))
					{
						shape.style.cursor = 'pointer';
					}else{
						shape.style.cursor = 'not-allowed';
					}
				}else if ((cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER'))
				{
					if ((status.value == 3) || (status.value == 4))
					{
						shape.style.cursor = 'pointer';
					}else{
						shape.style.cursor = 'not-allowed';
					}
				}else if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT'))
				{
					if ((status.value == 3) || (status.value == 4))
					{
						shape.style.cursor = 'pointer';
					}else{
						shape.style.cursor = 'not-allowed';
					}
				}else{
					shape.style.cursor = 'not-allowed';
				}
			}else
			{
				shape.style.cursor = 'pointer';
			}
		}else
		{	shape.style.cursor = 'not-allowed';
		}

		label.innerHTML = status_desc.value + ' ( ' + status.value + ' ) ';

	}

	// ***************************************************************************************************************************************
	// Mouse out
	// ***************************************************************************************************************************************
	static mouseout(shape, status, status_desc, label, cur_frm)
	{
		shape.style.cursor = '';

		label.innerHTML = '';
	}

	// ***************************************************************************************************************************************
	// Mouse Down
	// ***************************************************************************************************************************************
	static mousedown(status)
	{
		let e = window.event;

		let right_click = 0;

		if ("which" in e)
		{
			if (e.which == 3)
			{	right_click = 1;
			}
		}else
		{
			if ("button" in e)
			{
				if (button == 2)
				{
					right_click = 1;
				}
			}
		}

		if (right_click == 1)
		{
			return false;
		}

		if (document.getElementById('buttons_disabled').value == 0)
		{
			if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 			 || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 	 || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')    		|| (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') ||
				(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') 		|| (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE'))
			{

				if (status.value == 0)
				{
					if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 	|| (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') ||
						(cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 	|| (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') ||
						(cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') ||
						(cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') 	|| (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE')) {

						let go = false;
						let method = 'silicon_ioi.ioi_configuration.doctype.ioi_approval_document.ioi_approval_document.ioi_approval_document_has_approvals';

						frappe.call({  	method: method,
										args: { "doctype": cur_frm.doctype, "name": cur_frm.doc.name },
										async: false,
										callback:function(r)	{

											if (r.message == 0) {
												go = true;
											}
										}
						});

						if (go) {
							if (window.innerWidth >= 600) {
								/*
								document.getElementById("splash_screen").style.height = screen.height.toString() + 'px';
								document.getElementById("splash_screen").style.verticalAlign = 'middle';
								document.getElementById('splash_screen').style.display = 'block';
								silicon_ioi.doctype.ioiDocumentStatus.refresh_msg(1);
								*/
							}
						}

					}else{
						if (window.innerWidth >= 600) {
							/*
							document.getElementById("splash_screen").style.height = screen.height.toString() + 'px';
							document.getElementById("splash_screen").style.verticalAlign = 'middle';
							document.getElementById('splash_screen').style.display = 'block';
							silicon_ioi.doctype.ioiDocumentStatus.refresh_msg(1);
							*/
						}
					}

				}else{

					if (((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') && ((cur_frm.doc.ioistatus == -1) || (cur_frm.doc.ioistatus == 1))) ||
						((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') && ((cur_frm.doc.ioistatus == -1) || (cur_frm.doc.ioistatus == 1))) ||
						((cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') && (cur_frm.doc.ioistatus == 1)) ||
						((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') && (cur_frm.doc.ioistatus == 1)) ||
						((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') && (cur_frm.doc.ioistatus == 1)) ||
						((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') && (cur_frm.doc.ioistatus == 1)) ||
						((cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') && (cur_frm.doc.ioistatus == 1)) ||
						((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') && (cur_frm.doc.ioistatus == 1))) {

						if ((!cur_frm.doc.approval_status) || ((cur_frm.doc.approval_status) && (cur_frm.doc.approval_status == 0)))
						{
							if (window.innerWidth >= 600) {
								/*
								document.getElementById("splash_screen").style.height = screen.height.toString() + 'px';
								document.getElementById("splash_screen").style.verticalAlign = 'middle';
								document.getElementById('splash_screen').style.display = 'block';
								silicon_ioi.doctype.ioiDocumentStatus.refresh_msg(1);
								*/
							}

						}else{

							if (cur_frm.doc.approval_status == 3) {

								if (window.innerWidth >= 600) {
									/*
									document.getElementById("splash_screen").style.height = screen.height.toString() + 'px';
									document.getElementById("splash_screen").style.verticalAlign = 'middle';
									document.getElementById('splash_screen').style.display = 'block';
									silicon_ioi.doctype.ioiDocumentStatus.refresh_msg(1);
									*/
								}
							}
						}
					}else{

						if (window.innerWidth >= 600) {
							/*
							document.getElementById("splash_screen").style.height = screen.height.toString() + 'px';
							document.getElementById("splash_screen").style.verticalAlign = 'middle';
							document.getElementById('splash_screen').style.display = 'block';
							silicon_ioi.doctype.ioiDocumentStatus.refresh_msg(1);
							*/
						}
					}

				}

			}else {
				if (window.innerWidth >= 600) {

					if (cur_frm.doctype.toUpperCase() != 'IOI CASH DESK') {
						/*
						document.getElementById("splash_screen").style.height = screen.height.toString() + 'px';
						document.getElementById("splash_screen").style.verticalAlign = 'middle';
						document.getElementById('splash_screen').style.display = 'block';
						silicon_ioi.doctype.ioiDocumentStatus.refresh_msg(1);
						*/
					}
				}
			}
		}

	}

	static refresh_msg(i)
	{
/*
		if (i > 5)
		{ 	i = 1;
		}

		for (var j = 1; j <= 5; j++)
		{
			if (document.getElementById("splash_square_" + j.toString())) {
				document.getElementById("splash_square_" + j.toString()).style.backgroundColor = '#D9C8FB';
			}
		}

		if (document.getElementById("splash_square_" + i.toString())) {
			document.getElementById("splash_square_" + i.toString()).style.backgroundColor = '#5A14E6';
		}

		i++;


		setTimeout(() => {
			silicon_ioi.doctype.ioiDocumentStatus.refresh_msg(i);
		}, 400);
*/
	}


	// ***************************************************************************************************************************************
	// Mouse click
	// ***************************************************************************************************************************************
	static mouseclick(shape, status, status_desc, cur_frm, fct_instead_of_change_status)
	{
		let e = window.event;

		let right_click = 0;

		if ("which" in e)
		{
			if (e.which == 3)
			{	right_click = 1;
			}
		}else
		{
			if ("button" in e)
			{
				if (button == 2)
				{
					right_click = 1;
				}
			}
		}

		if (right_click == 1)
		{
			return false;
		}

		if (document.getElementById('buttons_disabled').value == 0)
		{
			document.getElementById('buttons_disabled').value = 1;

			let shape_click = shape.onclick;
			shape.onclick = null;

			if (shape.style.cursor != 'not-allowed')
			{

				if 	(
					(status.value != cur_frm.doc.ioistatus) ||

					((status.value == cur_frm.doc.ioistatus) &&	((	((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') && ((status.value == 4) || (status.value == 5))) ||
																  	((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') && ((status.value == 4) || (status.value == 5))) ||
			 													  	((cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') && ((status.value == 3) || (status.value == 4))) ||
																  	((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') && ((status.value == 3) || (status.value == 4))) ||
																  	((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') && ((status.value == 3) || (status.value == 4))) ||
																  	((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') && ((status.value == 3) || (status.value == 4))) )) )
					)
				{

					if (cur_frm.is_dirty())
					{
						let fct_callback = function () {
							silicon_ioi.doctype.ioiDocumentStatus.do_mouseclick(shape, status, status_desc, cur_frm, shape_click, fct_instead_of_change_status);
						};

						let fct_error = function () {
							// document.getElementById('splash_screen').style.visibility = 'hidden';
							document.getElementById('buttons_disabled').value = 0;
							shape.onclick = shape_click;
						};

						cur_frm.save('Save', fct_callback, null, fct_error);
					}else
					{
						silicon_ioi.doctype.ioiDocumentStatus.do_mouseclick(shape, status, status_desc, cur_frm, shape_click, fct_instead_of_change_status);
					}
				}

				else
				{	//document.getElementById('splash_screen').style.visibility = 'hidden';
					document.getElementById('buttons_disabled').value = 0;
					shape.onclick = shape_click;
				}

			}else
			{
				//document.getElementById('splash_screen').style.visibility = 'hidden';
				document.getElementById('buttons_disabled').value = 0;
				shape.onclick = shape_click;
			}
		}
	}

	static do_mouseclick(shape, status, status_desc, cur_frm, shape_click, fct_instead_of_change_status)
	{

		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') 			 || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 	 || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')    	|| (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') ||
			(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') 	|| (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE'))
		{

			if (status.value == 0)
			{
				if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE')  		|| (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') ||
					(cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') 		|| (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') ||
					(cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') 	|| (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') ||
					(cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') 		|| (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE')) {

					let go = false;
					let method = 'silicon_ioi.ioi_configuration.doctype.ioi_approval_document.ioi_approval_document.ioi_approval_document_has_approvals';

					frappe.call({  	method: method,
									args: { "doctype": cur_frm.doctype, "name": cur_frm.doc.name },
									async: false,
									callback:function(r)	{

										if (r.message == 0) {
											go = true;
										}
									}
					});

					if (go) {
						silicon_ioi.doctype.ioiDocumentStatus.execute_mouseclick(shape, status, status_desc, cur_frm, shape_click, fct_instead_of_change_status);
					}else{

						let msg = '';

						let top = 10;

						msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + __("This document contains approvals.") + '</label>';

						top += 25;

						msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + __("These approvals will be lost if you go to 'Preparation'.") + '</label>';

						top += 35;


						let s = '<font color="blue">' + __('Are you sure to continue ?') + '</font>';
						msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

						top += 25;
						msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


						frappe.confirm(	msg,
							() => 	{	silicon_ioi.doctype.ioiDocumentStatus.execute_mouseclick(shape, status, status_desc, cur_frm, shape_click, fct_instead_of_change_status);
									},
							() => 	{	document.getElementById('buttons_disabled').value = 0;
										shape.onclick = shape_click;
									}
						);
					}

				}else{
					silicon_ioi.doctype.ioiDocumentStatus.execute_mouseclick(shape, status, status_desc, cur_frm, shape_click, fct_instead_of_change_status);
				}

			}else{

				if (((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') && ((cur_frm.doc.ioistatus == -1) || (cur_frm.doc.ioistatus == 1))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') && ((cur_frm.doc.ioistatus == -1) || (cur_frm.doc.ioistatus == 1))) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') && (cur_frm.doc.ioistatus == 1)) ||
					((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') && (cur_frm.doc.ioistatus == 1)) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') && (cur_frm.doc.ioistatus == 1)) ||
					((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') && (cur_frm.doc.ioistatus == 1)) ||
					((cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') && (cur_frm.doc.ioistatus == 1)) ||
					((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') && (cur_frm.doc.ioistatus == 1))) {

					if ((!cur_frm.doc.approval_status) || ((cur_frm.doc.approval_status) && (cur_frm.doc.approval_status == 0)))
					{
						silicon_ioi.doctype.ioiDocumentStatus.execute_mouseclick(shape, status, status_desc, cur_frm, shape_click, fct_instead_of_change_status);
					}else{

						if (cur_frm.doc.approval_status == 3) {
							silicon_ioi.doctype.ioiDocumentStatus.execute_mouseclick(shape, status, status_desc, cur_frm, shape_click, fct_instead_of_change_status);
						}else if (cur_frm.doc.approval_status == 4) {
							frappe.msgprint({title: __("Message"), message: __("Document has been refused (not approved)"), indicator: "red"});
							document.getElementById('buttons_disabled').value = 0;
							shape.onclick = shape_click;
						}else{
							frappe.msgprint({title: __("Message"), message: __("Document is not fully approved"), indicator: "red"});
							document.getElementById('buttons_disabled').value = 0;
							shape.onclick = shape_click;
						}
					}
				}else{
					silicon_ioi.doctype.ioiDocumentStatus.execute_mouseclick(shape, status, status_desc, cur_frm, shape_click, fct_instead_of_change_status);
				}
			}

		}else{
			if (cur_frm.doctype.toUpperCase() == 'IOI CASH DESK') {
				//document.getElementById('splash_screen').style.visibility = 'hidden';
				document.getElementById('buttons_disabled').value = 0;
				shape.onclick = shape_click;
			}

			silicon_ioi.doctype.ioiDocumentStatus.execute_mouseclick(shape, status, status_desc, cur_frm, shape_click, fct_instead_of_change_status);
		}
	}

	static execute_mouseclick(shape, status, status_desc, cur_frm, shape_click, fct_instead_of_change_status)
	{
		silicon_ioi.doctype.ioiDocumentStatus.to_status = status;

		if (cur_frm.doctype.toUpperCase() == 'IOI STAFF')
		{
			silicon_ioi.doctype.ioiDocumentStatus.ioi_staff_change_status(status, cur_frm);
		}

		let method = this.path_document_change_status + '.change_document_status';

		let can_change = false;


		let manual_mode = false;
		let execute_fct_instead = false

		if (((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') && ((status.value == 4) || (status.value == 5))) ||
			((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') && ((status.value == 4) || (status.value == 5))) ||
			((cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') && ((status.value == 3) || (status.value == 4))) ||
			((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') && ((status.value == 3) || (status.value == 4))) ||
			((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') && ((status.value == 3) || (status.value == 4))) ||
			((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') && ((status.value == 3) || (status.value == 4))) ||
			(cur_frm.doctype.toUpperCase() == 'IOI CASH DESK') || (cur_frm.doctype.toUpperCase() == 'IOI ITEM MANUFACTURING DEFINITION') ||
			((cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') && (status.value == 2)))
		{
			manual_mode = true;
			execute_fct_instead = true
		}

		if (execute_fct_instead)
		{
			if (fct_instead_of_change_status == null)
			{
				execute_fct_instead = false;
			}
		}

		if (cur_frm.doctype.toUpperCase() == 'IOI TICKET') {

			if ((status.value == 5) || (status.value == 6) || (status.value == 7)) {
				execute_fct_instead = false;
			}

		}

		if (!execute_fct_instead)
		{
			frappe.call({  	method: method,
							args: {"doctype": cur_frm.doctype, "name" : cur_frm.doc.name, "from_status" : cur_frm.doc.ioistatus, "to_status" : status.value, "silent_mode" : true, "manual_mode" : manual_mode },
							async: false,
							callback:function(r)	{

														if (r.message.error != '0')
														{
															document.getElementById('buttons_disabled').value = 0;
															shape.onclick = shape_click;

															if ((cur_frm.doctype.toUpperCase() != 'IOI SALES QUOTE') 			 && (cur_frm.doctype.toUpperCase() != 'IOI SALES ORDER') 	 && (cur_frm.doctype.toUpperCase() != 'IOI SALES DELIVERY')    				&& (cur_frm.doctype.toUpperCase() != 'IOI SALES INVOICE') &&
																(cur_frm.doctype.toUpperCase() != 'IOI PURCHASES PRICE REQUEST') && (cur_frm.doctype.toUpperCase() != 'IOI PURCHASES ORDER') && (cur_frm.doctype.toUpperCase() != 'IOI PURCHASES RECEIPT') 				&& (cur_frm.doctype.toUpperCase() != 'IOI PURCHASES INVOICE') &&
																(cur_frm.doctype.toUpperCase() != 'IOI CUSTOMER') 				 && (cur_frm.doctype.toUpperCase() != 'IOI SUPPLIER') 		 && (cur_frm.doctype.toUpperCase() != 'IOI STAFF') 			   				&& (cur_frm.doctype.toUpperCase() != 'IOI ITEM') &&
																(cur_frm.doctype.toUpperCase() != 'IOI SALES FTG DISCOUNT') 	 && (cur_frm.doctype.toUpperCase() != 'IOI DOSSIER') 		 && (cur_frm.doctype.toUpperCase() != 'IOI PURCHASES FTG DISCOUNT') 		&& (cur_frm.doctype.toUpperCase() != 'IOI STOCK TRANSFER') &&
																(cur_frm.doctype.toUpperCase() != 'IOI BOM RELEASE')			 && (cur_frm.doctype.toUpperCase() != 'IOI ROUTING RELEASE') && (cur_frm.doctype.toUpperCase() != 'IOI ITEM MANUFACTURING DEFINITION')	&& (cur_frm.doctype.toUpperCase() != 'IOI PRODUCTION') &&
																(cur_frm.doctype.toUpperCase() != 'IOI STOCK OUTPUT')			 && (cur_frm.doctype.toUpperCase() != 'IOI STOCK ENTRY')	 && (cur_frm.doctype.toUpperCase() != 'IOI STOCK INVENTORY')				&& (cur_frm.doctype.toUpperCase() != 'IOI PARK')		&&
																(cur_frm.doctype.toUpperCase() != 'IOI STOCK QUALIFICATION'))
															{
																if (r.message.error == '2')
																{	frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "red"});
																}else
																{	frappe.msgprint({title: __("Message"), message: r.message.error_message, indicator: "orange"});
																}


																silicon_ioi.doctype.ioiDocumentStatus.sleep_static(1000).then(() => {

																	//document.getElementById('splash_screen').style.visibility = 'hidden';
																	// Need to force a reload if something has changed in the record by the "change status" process
	
																	if (cur_frm.doctype.toUpperCase() == 'IOI TICKET') {

																		if ((status.value == 5) || (status.value == 6) || (status.value == 7)) {
																			if (fct_instead_of_change_status !== null){
																				fct_instead_of_change_status();
																			}else{
																				window.location.reload();
																			}
																		}else{
																			window.location.reload();
																		}
															
																	}else{				
																		window.location.reload();
																	}																	
																});
															}else
															{
																can_change = true;
															}
														}else
														{	can_change = true;
														}
													}
			});


			if (can_change)
			{

				if (cur_frm.doctype.toUpperCase() == 'IOI TICKET') {

					if ((status.value == 5) || (status.value == 6) || (status.value == 7)) {
						if (fct_instead_of_change_status !== null){
							fct_instead_of_change_status();
						}else{
							window.location.reload();
						}
					}else{
						window.location.reload();
					}
		
				}else{				
					window.location.reload();
				}
			}
		}else
		{	//document.getElementById('splash_screen').style.visibility = 'hidden';
			document.getElementById('buttons_disabled').value = 0;
			shape.onclick = shape_click;

			if ((cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') || (cur_frm.doctype.toUpperCase() == 'IOI ITEM MANUFACTURING DEFINITION')) {
				let ret_status = silicon_ioi.doctype.ioiDocumentStatus.to_status.value;
				if (fct_instead_of_change_status !== null){
					fct_instead_of_change_status(ret_status);
				}
			}else{
				if (fct_instead_of_change_status !== null){
					fct_instead_of_change_status();
				}
			}
		}
	}

	static ioi_staff_change_status(status, cur_frm)
	{
		if (status.value == 2)
		{
			if ((!cur_frm.doc.in_company_since) || ((cur_frm.doc.in_company_since) && (cur_frm.doc.in_company_since.trim() == '')))
			{
				//document.getElementById('splash_screen').style.visibility = 'hidden';
				document.getElementById('buttons_disabled').value = 0;
				//shape.onclick = shape_click;

				cur_frm.set_df_property('in_company_since', 'read_only', 0);
				cur_frm.refresh_field('in_company_since');

				frappe.msgprint({title: __("Message"), message: __("In company since is mandatory"), indicator: "red"});
				raise;

			}

		}else if (status.value == 3)
		{
			if ((!cur_frm.doc.out_of_company_since) || ((cur_frm.doc.out_of_company_since) && (cur_frm.doc.out_of_company_since.trim() == '')))
			{
				//document.getElementById('splash_screen').style.visibility = 'hidden';
				document.getElementById('buttons_disabled').value = 0;
				//shape.onclick = shape_click;
				cur_frm.set_df_property('out_of_company_since', 'read_only', 0);
				cur_frm.refresh_field('out_of_company_since');

				frappe.msgprint({title: __("Message"), message: __("Out of company since is mandatory"), indicator: "red"});
				raise;

			}
		}
	}

	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}



}

silicon_ioi.doctype.ioiDocumentStatus = ioiDocumentStatus;
