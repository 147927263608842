import "./ioi_event_popup.html"
import { ioiTaskCreationDialog } from './ioi_resource_todo_dragger';

export class EventPopupManager {
	/**
	 * @typedef {import('@fullcalendar/core').EventApi} EventApi
	 *
	 * @typedef {EventApi & { el: HTMLElement }} EventApiWithElement
	 *
	 * @typedef {object} EventPopupOptions
	 * @property {string} doctype
	 * @property {string} container
	 * @property {boolean} [fetch]
	 */

	constructor(/** @type {EventPopupOptions} */ options) {
		const requiredOptions = ["doctype", "container", "fullcalendarInfo", "user"];
		for (const option of requiredOptions) {
			if (!options[option]) {
				console.error(`Calendar View: EventPopupManager: Option '${option}' is required`);
			}
		}
		this.options = options;
	}

	async setup() {
		await frappe.model.with_doctype(this.options.doctype);
		this.meta = frappe.get_meta(this.options.doctype);
	}

	/** @private */
	getFields() {
		return this.meta.fields
			.filter((df) => {
				if (["subject", "starts_on", "ends_on", "all_day"].includes(df.fieldname)) {
					return false;
				}
				if (df.in_list_view || df.bold) {
					return true;
				}
			})
			.map((df) => ({
				...df,
				reqd: false,
				read_only: true,
				readonly_depends_on: "",
			}));
	}

	addEventPopup(
		/** @type {EventApiWithElement} */ info,
	) {
		info.el.ariaLabel = __("Click to view details");
		info.el.href = frappe.utils.get_form_link(this.options.doctype, info.event.id);
		// this.showEventPopup(info);

		info.el.addEventListener("click", (event) => {
			if (event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button !== 0) {
				return;
			}

			event.preventDefault();
			event.stopPropagation();
			this.toggleEventPopup(info);
		});

		info.el.addEventListener("dblclick", () => {
			frappe.router.set_route("Form", this.options.doctype, info.event.id);
			this.hideEventPopup(info);
		})

		// this.toggleEventPopup(info); // debug
	}

	removeEventPopup(
		/** @type {EventApiWithElement} */ info,
	) {
		this.hideEventPopup(info);
	}

	/** @private */
	toggleEventPopup(
		/** @type {EventApiWithElement} */ info,
	) {
		if (EventPopupManager.current) {
			if (EventPopupManager.current !== info) {
				this.hideEventPopup(EventPopupManager.current);
			}
			EventPopupManager.current = null;
		}
		if (info.el._eventPopup) {
			this.hideEventPopup(info);
		} else {
			this.showEventPopup(info);
		}
	}

	/** @private */
	async showEventPopup(
		/** @type {EventApiWithElement} */ info,
	) {
		if (!info.el._eventPopup) {
			await this.buildEventPopup(info);
			info.el._eventPopup = true;
		}
		$(info.el).popover("show");
		EventPopupManager.current = info;
	}

	/** @private */
	hideEventPopup(
		/** @type {EventApiWithElement} */ info,
	) {
		if (info.el._eventPopup) {
			$(info.el).popover("hide");
			info.el._eventPopup = null;
		}
	}

	/** @private */
	async buildEventPopup(
		/** @type {EventApiWithElement} */ info,
	) {
		const body = document.createElement("div");
		body.innerHTML = frappe.render_template("ioi_event_popup", {});

		const qs = (selector) => {
			const element = body.querySelector(selector);
			if (!element) {
				console.error(`Calendar View: EventPopupManager: Element '${selector}' not found`);
			}
			return element;
		}

		const buttons = {
			close: qs("[data-action='close']"),
			delete: qs("[data-action='delete']"),
			edit: qs("[data-action='edit']"),
			todo: qs("[data-action='todo']"),
		};

		const elements = {
			subject: qs("[data-key='subject']"),
			timestamp: qs("[data-key='timestamp']"),
			topborder: qs("[data-key='topborder']"),
			description: qs("[data-key='description']"),
			links: qs("[data-key='links']"),
			fields: qs("[data-key='fields']"),
		};

		buttons.close.title = buttons.close.ariaLabel = __("Close");
		buttons.close.addEventListener("click", () => this.hideEventPopup(info));

		buttons.delete.title = buttons.delete.ariaLabel = __("Delete");
		buttons.delete.addEventListener("click", () => {
			frappe.confirm(
				__("Are you sure you want to delete this event?"),
				() => {
					this.hideEventPopup(info);
					frappe.model.delete_doc(this.options.doctype, info.event.id, function () {
						info.event.remove();
					});
				}
			);
		});

		buttons.edit.title = buttons.edit.ariaLabel = __("Edit");
		buttons.edit.addEventListener("click", (e) => {
			if (e.metaKey || e.ctrlKey || e.shiftKey || e.altKey || e.button !== 0) {
				return;
			}
			this.hideEventPopup(info);
			frappe.set_route("Form", this.options.doctype, info.event.id);
		});
		buttons.edit.href = frappe.utils.get_form_link(this.options.doctype, info.event.id);

		buttons.todo.title = buttons.todo.ariaLabel = __("Todo");
		buttons.todo.addEventListener("click", () => this.createTodo(info));
		elements.topborder.style.backgroundColor = info.event.backgroundColor;


		if (info.event.title) {
			elements.subject.textContent = info.event.title;
		}

		const formatter = new Intl.DateTimeFormat(frappe.boot.lang, {
			dateStyle: 'medium',
			...(info.event.allDay ? {} : { timeStyle: 'short' }),
		});
		elements.timestamp.textContent = formatter.formatRange(info.event.start, info.event.end);

		if (info.event.extendedProps.description) {
			const MAX_LENGTH = 250;
			let desc = info.event.extendedProps.description;
			if (desc?.length > MAX_LENGTH) {
				desc = desc?.slice(0, MAX_LENGTH) + "…";
			}
			elements.description.innerHTML = desc;
			elements.description.closest(".evp-row").removeAttribute("hidden");
		}

		await this.populateAsync(info, elements);

		$(info.el).popover({
			content: body,
			container: this.options.container,
			boundary: "viewport",
			html: true,
			placement: "auto",
			trigger: "manual",
			template: '<div class="popover evp-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
		});

		return true;
	}

	/** @private */
	async populateAsync(info, elements) {
		const doc = await frappe.model.with_doc(this.options.doctype, info.event.id);
		await this.populateFields(elements.fields, doc);
		await this.populateLinks(elements.links, doc);
	}

	/** @private */
	async populateLinks(
		/** @type {HTMLElement} */ element,
		/** @type {object} */ doc,
	) {
		if (!doc?.event_participants?.length) {
			return;
		}

		element.closest(".evp-row").removeAttribute("hidden");
		element.innerHTML = "";
		const ul = document.createElement("ul");
		ul.classList.add("p-0", "pl-4");
		element.appendChild(ul);

		const EXCLUDE_LIST = ["User", "Contact"];
		for (const row of doc.event_participants || []) {
			if (EXCLUDE_LIST.includes(row.reference_doctype)) {
				continue;
			}

			const li = document.createElement("li");
			ul.appendChild(li);

			const anchor = document.createElement("a");
			li.appendChild(anchor);

			const url = frappe.utils.get_form_link(row.reference_doctype, row.reference_docname);
			anchor.href = url;
			anchor.textContent = row.reference_docname;
			anchor.title = __("{0}: {1}", [__(row.reference_doctype), row.reference_docname]);
			anchor.classList.add("underline");
		}
	}

	/** @private */
	async populateFields(
		/** @type {HTMLElement} */ element,
		/** @type {object} */ doc,
	) {
		for (const df of this.getFields()) {
			const wrapper = document.createElement("div");
			wrapper.classList.add("evp-row", "evp-row--field")
			element.before(wrapper);
			const control = frappe.ui.form.make_control({
				df: df,
				parent: wrapper,
				render_input: true,
				doc: doc,
			});
		}
	}

	/** @private */
	async createTodo(info) {
		this.hideEventPopup(info);
		const dialog = new ioiTaskCreationDialog({ fullcalendarInfo: this.options.fullcalendarInfo, user: this.options.user });
		dialog.show();
	}
}
