// *******************************************************************************************************************************************
// Class ioiDuplicate
// *******************************************************************************************************************************************

frappe.provide('silicon_ioi.doctype');

const path_sales_document = 'silicon_ioi.common.sales_document';
const path_purchases_document = 'silicon_ioi.common.purchases_document';
const path_stock_document = 'silicon_ioi.common.stock_document';
const path_dossier = 'silicon_ioi.ioi_trakker.doctype.ioi_dossier.ioi_dossier';
const path_production = 'silicon_ioi.ioi_production.doctype.ioi_production.ioi_production';
const path_park = 'silicon_ioi.ioi_helpdesk.doctype.ioi_park.ioi_park';
const path_batchsn = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
const path_customer = 'silicon_ioi.ioi_sales.doctype.ioi_customer.ioi_customer';
const path_supplier = 'silicon_ioi.ioi_purchases.doctype.ioi_supplier.ioi_supplier';
const path_manufacturer = 'silicon_ioi.ioi_items.doctype.ioi_manufacturer.ioi_manufacturer';
const path_item = 'silicon_ioi.ioi_items.doctype.ioi_item.ioi_item';
const path_manufacturer_catalog = 'silicon_ioi.ioi_items.doctype.ioi_manufacturer_catalog.ioi_manufacturer_catalog';
const path_subscription = 'silicon_ioi.ioi_park_manager.doctype.ioi_subscription.ioi_subscription';
const path_cash_desk = 'silicon_ioi.ioi_configuration.doctype.ioi_cash_desk.ioi_cash_desk';

export class ioiDuplicate
{

	static create_from_this_document()
	{
		
		if (cur_frm.is_dirty()) {	
			let fct_callback = function () { silicon_ioi.doctype.ioiDuplicate.do_create_from_this_document(); };
			cur_frm.save('Save', fct_callback);
		}else {
			silicon_ioi.doctype.ioiDuplicate.do_create_from_this_document(); 
		}
	}

	static do_create_from_this_document()
	{
		let title = '';
		let doctype = cur_frm.doctype.toUpperCase();

		if (cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
			title += __("Duplicate this sales quote");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
			title += __("Duplicate this sales order");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
			title += __("Duplicate this sales delivery");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
			title += __("Duplicate this sales invoice");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') {
			title += __("Duplicate this purchases price request");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') {
			title += __("Duplicate this purchases order");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') {
			title += __("Duplicate this purchases receipt");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') {
			title += __("Duplicate this purchases invoice");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') {
			title += __("Duplicate this stock entry");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') {
			title += __("Duplicate this stock output");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') {
			title += __("Duplicate this stock transfer");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') {
			title += __("Duplicate this stock qualification");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') {
			title += __("Duplicate this dossier");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') {
			title += __("Duplicate this production");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PARK') {
			title += __("Duplicate this park");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') {
			title += __("Duplicate this batch sn");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') {
			title += __("Duplicate this customer");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') {
			title += __("Duplicate this supplier");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') {
			title += __("Duplicate this manufacturer");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI ITEM') {
			title += __("Duplicate this item");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') {
			title += __("Duplicate this manufacturer catalog");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') {
			title += __("Duplicate this subscription");
		}else if (cur_frm.doctype.toUpperCase() == 'IOI CASH DESK') {
			title += __("Duplicate this cash desk");
		}	
				

		var p = new frappe.ui.Dialog({
			'title': title,
			'static': true,
			'fields': [
				{'fieldname': 'html_create_from_document', 'fieldtype': 'HTML'}

			],
			primary_action_label:  __('Ok'),
			secondary_action_label:  __('Cancel'),
			primary_action: function(){

				document.getElementById('html_create_from_document_message').innerHTML = '';

				if ((!document.getElementById('html_create_from_document_site_id').value) ||
					((document.getElementById('html_create_from_document_site_id').value) && (document.getElementById('html_create_from_document_site_id').value.trim() == ''))) {

					document.getElementById('html_create_from_document_message').innerHTML = __('Site has to be filled');
					return false;
				}

				if ((cur_frm.doctype.toUpperCase() != 'IOI PARK') && (cur_frm.doctype.toUpperCase() != 'IOI CUSTOMER') && (cur_frm.doctype.toUpperCase() != 'IOI SUPPLIER') && 
					(cur_frm.doctype.toUpperCase() != 'IOI MANUFACTURER') && (cur_frm.doctype.toUpperCase() != 'IOI ITEM') && (cur_frm.doctype.toUpperCase() != 'IOI MANUFACTURER CATALOG') &&
					(cur_frm.doctype.toUpperCase() != 'IOI CASH DESK')) {

					if ((!document.getElementById('html_create_from_document_journal_id').value) ||
						((document.getElementById('html_create_from_document_journal_id').value) && (document.getElementById('html_create_from_document_journal_id').value.trim() == ''))) {
						document.getElementById('html_create_from_document_message').innerHTML = __('Journal has to be filled');
						return false;
					}
				}


				let from_document_id = cur_frm.doc.name
				let to_site_id = document.getElementById('html_create_from_document_site_id').value;
				let to_journal_id = '';

				if (cur_frm.doctype.toUpperCase() != 'IOI PARK') {
					to_journal_id = document.getElementById('html_create_from_document_journal_id').value;
				}

				let discard_price = 0;

				if (document.getElementById('html_create_from_document_discard_price').checked) {
					discard_price = 1;
				}

				let remove_original = 0;

				if (document.getElementById('html_create_from_document_remove_original')) {
					if (document.getElementById('html_create_from_document_remove_original').checked) {
						remove_original = 1;
					}
				}

			

				let park_identification = '';
				let park_description = '';
				let park_code_ref = '';

				let customer_identification = '';
				let customer_name = '';


				let supplier_identification = '';
				let supplier_name = '';			

				let manufacturer_identification = '';	
				let manufacturer_description = '';		

				let item_identification = '';	
				let item_description = '';		
				
				let manufacturer_id = '';	
				let manufacturer_cat_identification = '';	
				let manufacturer_cat_description = '';

				let cashdesk_identification = '';
				let cashdesk_currency_id = '';

				if (cur_frm.doctype.toUpperCase() == 'IOI PARK') {

					if (document.getElementById('html_create_from_document_park_identification').style.display != 'none') {

						if ((!document.getElementById('html_create_from_document_park_identification').value) ||
							((document.getElementById('html_create_from_document_park_identification').value) && (document.getElementById('html_create_from_document_park_identification').value.trim() == ''))) {

							document.getElementById('html_create_from_document_message').innerHTML = __('Identification has to be filled');
							return false;
						}

						park_identification = document.getElementById('html_create_from_document_park_identification').value.toUpperCase();
					}


					if ((!document.getElementById('html_create_from_document_park_description').value) ||
						((document.getElementById('html_create_from_document_park_description').value) && (document.getElementById('html_create_from_document_park_description').value.trim() == ''))) {

						document.getElementById('html_create_from_document_message').innerHTML = __('Description has to be filled');
						return false;
					}

					park_description = document.getElementById('html_create_from_document_park_description').value.toUpperCase();


					if ((!document.getElementById('html_create_from_document_park_code_ref').value) ||
						((document.getElementById('html_create_from_document_park_code_ref').value) && (document.getElementById('html_create_from_document_park_code_ref').value.trim() == ''))) {

						document.getElementById('html_create_from_document_message').innerHTML = __('Code ref has to be filled');
						return false;
					}

					park_code_ref = document.getElementById('html_create_from_document_park_code_ref').value.toUpperCase();

				}else if (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') {

					if (document.getElementById('html_create_from_document_customer_identification').style.display != 'none') {

						if ((!document.getElementById('html_create_from_document_customer_identification').value) ||
							((document.getElementById('html_create_from_document_customer_identification').value) && (document.getElementById('html_create_from_document_customer_identification').value.trim() == ''))) {

							document.getElementById('html_create_from_document_message').innerHTML = __('Identification has to be filled');
							return false;
						}

						customer_identification = document.getElementById('html_create_from_document_customer_identification').value.toUpperCase();
					}


					if ((!document.getElementById('html_create_from_document_customer_description').value) ||
						((document.getElementById('html_create_from_document_customer_description').value) && (document.getElementById('html_create_from_document_customer_description').value.trim() == ''))) {

						document.getElementById('html_create_from_document_message').innerHTML = __('Customer name has to be filled');
						return false;
					}

					customer_name = document.getElementById('html_create_from_document_customer_description').value;	

				}else if (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') {

					if (document.getElementById('html_create_from_document_supplier_identification').style.display != 'none') {

						if ((!document.getElementById('html_create_from_document_supplier_identification').value) ||
							((document.getElementById('html_create_from_document_supplier_identification').value) && (document.getElementById('html_create_from_document_supplier_identification').value.trim() == ''))) {

							document.getElementById('html_create_from_document_message').innerHTML = __('Identification has to be filled');
							return false;
						}

						supplier_identification = document.getElementById('html_create_from_document_supplier_identification').value.toUpperCase();
					}


					if ((!document.getElementById('html_create_from_document_supplier_description').value) ||
						((document.getElementById('html_create_from_document_supplier_description').value) && (document.getElementById('html_create_from_document_supplier_description').value.trim() == ''))) {

						document.getElementById('html_create_from_document_message').innerHTML = __('Supplier name has to be filled');
						return false;
					}

					supplier_name = document.getElementById('html_create_from_document_supplier_description').value;		

				}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') {


					if (document.getElementById('html_create_from_document_manufacturer_identification').style.display != 'none') {

						if ((!document.getElementById('html_create_from_document_manufacturer_identification').value) ||
							((document.getElementById('html_create_from_document_manufacturer_identification').value) && (document.getElementById('html_create_from_document_manufacturer_identification').value.trim() == ''))) {

							document.getElementById('html_create_from_document_message').innerHTML = __('Identification has to be filled');
							return false;
						}

						manufacturer_identification = document.getElementById('html_create_from_document_manufacturer_identification').value.toUpperCase();
					}					

					if ((!document.getElementById('html_create_from_document_manufacturer_description').value) ||
						((document.getElementById('html_create_from_document_manufacturer_description').value) && (document.getElementById('html_create_from_document_manufacturer_description').value.trim() == ''))) {

						document.getElementById('html_create_from_document_message').innerHTML = __('Description has to be filled');
						return false;
					}

					manufacturer_description = document.getElementById('html_create_from_document_manufacturer_description').value;

				}else if (cur_frm.doctype.toUpperCase() == 'IOI ITEM') {

					if (document.getElementById('html_create_from_document_item_identification').style.display != 'none') {

						if ((!document.getElementById('html_create_from_document_item_identification').value) ||
							((document.getElementById('html_create_from_document_item_identification').value) && (document.getElementById('html_create_from_document_item_identification').value.trim() == ''))) {

							document.getElementById('html_create_from_document_message').innerHTML = __('Identification has to be filled');
							return false;
						}

						item_identification = document.getElementById('html_create_from_document_item_identification').value.toUpperCase();
					}


					if ((!document.getElementById('html_create_from_document_item_description').value) ||
						((document.getElementById('html_create_from_document_item_description').value) && (document.getElementById('html_create_from_document_item_description').value.trim() == ''))) {

						document.getElementById('html_create_from_document_message').innerHTML = __('Description has to be filled');
						return false;
					}

					item_description = document.getElementById('html_create_from_document_item_description').value;	

				}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') {

					if ((!document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_id').value) ||
						((document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_id').value) && (document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_id').value.trim() == ''))) {

						document.getElementById('html_create_from_document_message').innerHTML = __('Manufacturer has to be filled');
						return false;
					}

					manufacturer_id = document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_id').value.toUpperCase();
										

					if ((!document.getElementById('html_create_from_document_manufacturer_cat_identification').value) ||
						((document.getElementById('html_create_from_document_manufacturer_cat_identification').value) && (document.getElementById('html_create_from_document_manufacturer_cat_identification').value.trim() == ''))) {

						document.getElementById('html_create_from_document_message').innerHTML = __('Identification has to be filled');
						return false;
					}

					manufacturer_cat_identification = document.getElementById('html_create_from_document_manufacturer_cat_identification').value.toUpperCase();
	

					if ((!document.getElementById('html_create_from_document_manufacturer_cat_description').value) ||
						((document.getElementById('html_create_from_document_manufacturer_cat_description').value) && (document.getElementById('html_create_from_document_manufacturer_cat_description').value.trim() == ''))) {

						document.getElementById('html_create_from_document_message').innerHTML = __('Description has to be filled');
						return false;
					}

					manufacturer_cat_description = document.getElementById('html_create_from_document_manufacturer_cat_description').value;

				}else if (cur_frm.doctype.toUpperCase() == 'IOI CASH DESK') {


					if ((!document.getElementById('html_create_from_document_cash_desk_identification').value) ||
						((document.getElementById('html_create_from_document_cash_desk_identification').value) && (document.getElementById('html_create_from_document_cash_desk_identification').value.trim() == ''))) {

						document.getElementById('html_create_from_document_message').innerHTML = __('Identification has to be filled');
						return false;
					}

					cashdesk_identification = document.getElementById('html_create_from_document_cash_desk_identification').value.toUpperCase();
	

					if ((!document.getElementById('html_create_from_document_cash_desk_currency_id').value) ||
						((document.getElementById('html_create_from_document_cash_desk_currency_id').value) && (document.getElementById('html_create_from_document_cash_desk_currency_id').value.trim() == ''))) {

						document.getElementById('html_create_from_document_message').innerHTML = __('Currency has to be filled');
						return false;
					}

					cashdesk_currency_id = document.getElementById('html_create_from_document_cash_desk_currency_id').value.toUpperCase();
				}


				let method = '';

				if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {
					method = path_sales_document + '.ioi_sales_document_create_from_document';
				}else if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE')) {
					method = path_purchases_document + '.ioi_purchases_document_create_from_document';
				}else if ((cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION')) {
					method = path_stock_document + '.ioi_stock_document_create_from_document';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') {
					method = path_dossier + '.ioi_dossier_create_from_document';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') {
					method = path_production + '.ioi_production_create_from_document';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI PARK') {
					method = path_park + '.ioi_park_create_from_document';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') {
					method = path_batchsn + '.ioi_batch_sn_create_from_document';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') {
					method = path_customer + '.ioi_customer_create_from_customer';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') {
					method = path_supplier + '.ioi_supplier_create_from_supplier';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') {
					method = path_manufacturer + '.ioi_manufacturer_create_from_manufacturer';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI ITEM') {
					method = path_item + '.ioi_item_create_from_item';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') {
					method = path_manufacturer_catalog + '.ioi_manufacturer_catalog_create_from_manufacturer_catalog';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') {
					method = path_subscription + '.ioi_subscription_create_from_subscription';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI CASH DESK') {
					method = path_cash_desk + '.ioi_cash_desk_create_from_cash_desk';
				}
				
				let duplication_profile_id = '';

				if ((document.getElementById('html_create_from_document_profile_id').value) && (document.getElementById('html_create_from_document_profile_id').value.trim() != '')) {
					duplication_profile_id = document.getElementById('html_create_from_document_profile_id').value;
				}

				let parameters = {};

				if ((cur_frm.doctype.toUpperCase() != 'IOI PARK') && (cur_frm.doctype.toUpperCase() != 'IOI CUSTOMER') && (cur_frm.doctype.toUpperCase() != 'IOI SUPPLIER') &&
					(cur_frm.doctype.toUpperCase() != 'IOI MANUFACTURER') && (cur_frm.doctype.toUpperCase() != 'IOI ITEM') && (cur_frm.doctype.toUpperCase() != 'IOI MANUFACTURER CATALOG') &&
					(cur_frm.doctype.toUpperCase() != 'IOI CASH DESK')) {
					parameters = { 
						"doctype": doctype,
						"document_type": cur_frm.doc.document_type,
						"from_document_id": from_document_id,
						"to_site_id": to_site_id,
						"to_journal_id": to_journal_id,
						"discard_refresh_price": discard_price,
						"remove_original": remove_original,
						"duplication_profile_id": duplication_profile_id
					}
				}else if (cur_frm.doctype.toUpperCase() == 'IOI PARK') {
					parameters = { 
						"doctype": doctype,
						"document_type": cur_frm.doc.document_type,
						"from_document_id": from_document_id,
						"identification": park_identification,
						"description": park_description,
						"code_ref": park_code_ref,
						"discard_refresh_price": discard_price,
						"remove_original": remove_original,
						"duplication_profile_id": duplication_profile_id
					}					
				}else if (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') {
					parameters = { 
						"doctype": doctype,
						"document_type": 'CUSTOMER',
						"from_document_id": from_document_id,
						"identification": customer_identification,
						"customer_name": customer_name,
						"discard_refresh_price": discard_price,
						"remove_original": remove_original,
						"duplication_profile_id": duplication_profile_id
					}					
				}else if (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') {
					parameters = { 
						"doctype": doctype,
						"document_type": 'SUPPLIER',
						"from_document_id": from_document_id,
						"identification": supplier_identification,
						"supplier_name": supplier_name,
						"discard_refresh_price": discard_price,
						"remove_original": remove_original,
						"duplication_profile_id": duplication_profile_id
					}					
				}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') {
					parameters = { 
						"doctype": doctype,
						"document_type": 'MANUFACTURER',
						"from_document_id": from_document_id,
						"identification":  manufacturer_identification,
						"manufacturer_description": manufacturer_description,
						"discard_refresh_price": discard_price,
						"remove_original": remove_original,
						"duplication_profile_id": duplication_profile_id
					}					
				}else if (cur_frm.doctype.toUpperCase() == 'IOI ITEM') {

					let copy_sales_prices = 0;

					if (document.getElementById('html_create_from_document_item_copy_sales_price')) {

						if (document.getElementById('html_create_from_document_item_copy_sales_price').checked) {
							copy_sales_prices = 1;
						}
					}	
					
					let copy_purchases_prices = 0;
		
					if (document.getElementById('html_create_from_document_item_copy_purchases_price')) {

						if (document.getElementById('html_create_from_document_item_copy_purchases_price').checked) {
							copy_purchases_prices = 1;
						}						
					}	

					parameters = { 
						"doctype": doctype,
						"document_type": 'ITEM',
						"from_document_id": from_document_id,
						"identification": item_identification,
						"description": item_description,
						"discard_refresh_price": discard_price,
						"remove_original": remove_original,
						"duplication_profile_id": duplication_profile_id,
						"copy_sales_prices": copy_sales_prices,
						"copy_purchases_prices": copy_purchases_prices
					}					
				}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') {
					parameters = { 
						"doctype": doctype,
						"document_type": 'MANUFACTURER_CAT',
						"from_document_id": from_document_id,
						"manufacturer_id":  manufacturer_id,
						"identification":  manufacturer_cat_identification,
						"manufacturer_description": manufacturer_cat_description,
						"discard_refresh_price": discard_price,
						"remove_original": remove_original,
						"duplication_profile_id": duplication_profile_id
					}				
				}else if (cur_frm.doctype.toUpperCase() == 'IOI CASH DESK') {
					parameters = { 
						"doctype": doctype,
						"document_type": 'CASHDESK',
						"from_document_id": from_document_id,
						"site_id": to_site_id,
						"currency_id": cashdesk_currency_id,
						"identification":  cashdesk_identification,
						"duplication_profile_id": duplication_profile_id
					}				
				}	
				
				
			

				frappe.call({  	method: method,
								args: parameters,
								async: false,
								callback:function(r)	{

									if (r.message.error == 1) {
										document.getElementById('html_create_from_document_message').innerHTML = r.message.error_msg;
										raise;

									}else{
										p.hide();


										let url = '';

										if (cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') {
											url = '/app/ioi-sales-quote/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') {
											url = '/app/ioi-sales-order/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') {
											url = '/app/ioi-sales-delivery/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') {
											url = '/app/ioi-sales-invoice/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') {
											url = '/app/ioi-purchases-price-request/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') {
											url = '/app/ioi-purchases-order/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') {
											url = '/app/ioi-purchases-receipt/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') {
											url = '/app/ioi-purchases-invoice/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') {
											url = '/app/ioi-stock-output/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') {
											url = '/app/ioi-stock-entry/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') {
											url = '/app/ioi-stock-transfer/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') {
											url = '/app/ioi-stock-qualification/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') {
											url = '/app/ioi-dossier/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') {
											url = '/app/ioi-production/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI PARK') {
											url = '/app/ioi-park/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') {
											url = '/app/ioi-batch-sn/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') {
											url = '/app/ioi-customer/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') {
											url = '/app/ioi-supplier/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') {
											url = '/app/ioi-manufacturer/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI ITEM') {
											url = '/app/ioi-item/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') {
											url = '/app/ioi-manufacturer-catalog/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') {
											url = '/app/ioi-subscription/';
										}else if (cur_frm.doctype.toUpperCase() == 'IOI CASH DESK') {
											url = '/app/ioi-cash-desk/';
										}


										if (remove_original == 1) {
											window.location.href = url;
										}

										window.open(url + r.message.new_document_id)

									}
								}
				});


			},
			secondary_action: function(){
				p.hide();
			}

		});

		if (document.getElementById('html_create_from_document_site_label')) {
			document.getElementById('html_create_from_document_site_label').remove();
		}

		if (document.getElementById('html_create_from_document_site_id')) {
			document.getElementById('html_create_from_document_site_id').remove();
		}

		if (document.getElementById('html_create_from_document_journal_label')) {
			document.getElementById('html_create_from_document_journal_label').remove();
		}

		if (document.getElementById('html_create_from_document_journal_id')) {
			document.getElementById('html_create_from_document_journal_id').remove();
		}

		if (document.getElementById('html_create_from_document_profile_label')) {
			document.getElementById('html_create_from_document_profile_label').remove();
		}

		if (document.getElementById('html_create_from_document_profile_id')) {
			document.getElementById('html_create_from_document_profile_id').remove();
		}

		if (document.getElementById('html_create_from_document_discard_price_label')) {
			document.getElementById('html_create_from_document_discard_price_label').remove();
		}

		if (document.getElementById('html_create_from_document_discard_price')) {
			document.getElementById('html_create_from_document_discard_price').remove();
		}


		if (document.getElementById('html_create_from_document_remove_original_label')) {
			document.getElementById('html_create_from_document_remove_original_label').remove();
		}

		if (document.getElementById('html_create_from_document_remove_original')) {
			document.getElementById('html_create_from_document_remove_original').remove();
		}

		if (document.getElementById('html_create_from_document_message')) {
			document.getElementById('html_create_from_document_message').remove();
		}

		// Remove original document
		let show_remove = false;

		let method = '';

		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {
			method = path_sales_document + '.ioi_sales_document_create_from_document_can_remove_orginal_document';
			}else if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE')) {
			method = path_purchases_document + '.ioi_purchases_document_create_from_document_can_remove_orginal_document';
		}else if ((cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION')) {
			method = path_stock_document + '.ioi_stock_document_create_from_document_can_remove_orginal_document';
		}

		if ((cur_frm.doctype.toUpperCase() != 'IOI DOSSIER') && (cur_frm.doctype.toUpperCase() != 'IOI PRODUCTION') && (cur_frm.doctype.toUpperCase() != 'IOI PARK') && (cur_frm.doctype.toUpperCase() != 'IOI BATCH SN') &&
			(cur_frm.doctype.toUpperCase() != 'IOI CUSTOMER') && (cur_frm.doctype.toUpperCase() != 'IOI SUPPLIER') && (cur_frm.doctype.toUpperCase() != 'IOI MANUFACTURER') && (cur_frm.doctype.toUpperCase() != 'IOI ITEM') &&
			(cur_frm.doctype.toUpperCase() != 'IOI MANUFACTURER CATALOG') && (cur_frm.doctype.toUpperCase() != 'IOI SUBSCRIPTION') && (cur_frm.doctype.toUpperCase() != 'IOI CASH DESK')) {

			frappe.call({
				method: method,
				args: {"doctype": doctype, "from_document_id" : cur_frm.doc.name},
				async: false,
				callback:function(r) {

					if (r.message.error == 0) {
						if (r.message.can_remove == 1) {
							show_remove = true;
						}
					}
				}
			});
		}


		let html = '';

		let form_width = 500;

		let identification_visible = true;

		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {
			form_width = 500;
		}else if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE')) {
			form_width = 500;
		}else if ((cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION')) {
			form_width = 500;
		}else if (cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') {
			form_width = 500;
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') {
			form_width = 500;
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') {
			form_width = 500;
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PARK') {

			let method = path_park + '.ioi_park_identification_is_numbering'

			frappe.call({  	method: method,
				args: {},
				async: false,
				callback:function(r)	{

					if (r.message == 1) {
						identification_visible = false;

					}
				}
			});	
			
			if (identification_visible) {
				form_width = 810;
			}else{
				form_width = 580;
			}
		}else if (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') {


			let method = path_customer + '.ioi_customer_identification_is_numbering'

			frappe.call({  	method: method,
				args: {},
				async: false,
				callback:function(r)	{

					if (r.message == 1) {
						identification_visible = false;

					}
				}
			});	
			
			if (identification_visible) {
				form_width = 580;
			}else{
				form_width = 580;
			}			
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') {


			let method = path_supplier + '.ioi_supplier_identification_is_numbering'

			frappe.call({  	method: method,
				args: {},
				async: false,
				callback:function(r)	{

					if (r.message == 1) {
						identification_visible = false;

					}
				}
			});	
			
			if (identification_visible) {
				form_width = 580;
			}else{
				form_width = 580;
			}			
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') {
			form_width = 580;

		}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') {
			form_width = 580;			
	
		}else if (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') {
			form_width = 500;
		}else if (cur_frm.doctype.toUpperCase() == 'IOI ITEM') {


			let method = path_item + '.ioi_item_identification_is_numbering'

			frappe.call({  	method: method,
				args: {},
				async: false,
				callback:function(r)	{

					if (r.message == 1) {
						identification_visible = false;

					}
				}
			});	
			
			if (identification_visible) {
				form_width = 580;
			}else{
				form_width = 580;
			}			
		}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') {
			form_width = 810;
		}else if (cur_frm.doctype.toUpperCase() == 'IOI CASH DESK') {
			form_width = 725;
		}

		let form_height = 215;

		if ((cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') || (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') ||
			(cur_frm.doctype.toUpperCase() == 'IOI PARK') || (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') ||
			(cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') || (cur_frm.doctype.toUpperCase() == 'IOI ITEM') ||
			(cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') || (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') ||
			(cur_frm.doctype.toUpperCase() == 'IOI CASH DESK')) {	

			if (cur_frm.doctype.toUpperCase() == 'IOI ITEM') {
				form_height = 205;
			}else{
				form_height = 165;	
			}
		}else{
			if ((cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') ||
				(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION')) {
				if (!show_remove) {
					form_height = 185;
				}
			}			
		}	
		
		

		html += '<div style="overflow: auto; overflow-x: auto; height:' + form_height.toString() + 'px; width: 100%;">';

		// Destination Site
		html += '	<div style="position: relative; top: 0px; left: 2px; width:220px;">';
		html += '		<label id="html_create_from_document_site_label" style="position: absolute; top: 0px; left: 0px; '

		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') ||
			(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') || (cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') || (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') ||
			(cur_frm.doctype.toUpperCase() == 'IOI PARK') || (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') || (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') || (cur_frm.doctype.toUpperCase() == 'IOI ITEM') || (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') || (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION')) {
			html += ' display:none ';
		}
		html += '"><b>' + __("Site") + '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 220px; height: 25px;"> ';

		html += '			<select id="html_create_from_document_site_id" class="input-with-feedback form-control bold" ';
		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') ||
			(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') || (cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') || (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') ||
			(cur_frm.doctype.toUpperCase() == 'IOI PARK') || (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') || (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') || (cur_frm.doctype.toUpperCase() == 'IOI ITEM') || (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') || (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION')) {		
			html += ' style="display:none" ';
		}		
		html += '> ';

		method = '';

		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {
			 method = path_sales_document + '.ioi_sales_document_create_from_document_get_sites'
		}else if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE')) {
			 method = path_purchases_document + '.ioi_purchases_document_create_from_document_get_sites'
		}else if ((cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') || 
				  (cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') || (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') || (cur_frm.doctype.toUpperCase() == 'IOI PARK') || (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') ||
				  (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') || (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') || (cur_frm.doctype.toUpperCase() == 'IOI ITEM') ||
				  (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') || (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') || (cur_frm.doctype.toUpperCase() == 'IOI CASH DESK')) {
			method = path_purchases_document + '.ioi_purchases_document_create_from_document_get_sites'
	    }				


		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
													for (var i = 0; i < r.message.length; i++)
													{
														if (r.message[i].name == cur_frm.doc.site_id) {
															html += '		<option value="' + r.message[i].name + '" selected ';
														}else{
															html += '		<option value="' + r.message[i].name + '" ';
														}
														html += '>' + r.message[i].name + '</option> ';
													}
												}
		});

		html += '			</select> ';

		html += '		</div>';
		html += '	</div>';

		let journal_left = 232;

		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || 
			(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') || (cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') || (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') ||
			(cur_frm.doctype.toUpperCase() == 'IOI PARK') || (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') || (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') || (cur_frm.doctype.toUpperCase() == 'IOI ITEM') || (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') || (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION')) {		

			journal_left = 2;
		}


		let journal_width = 220;

		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {

			if ((cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {
				journal_width = form_width - 50;
			}

		}else if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE')) {
			journal_width = form_width - 50;
		}else if ((cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION')) {
			journal_width = form_width - 50;
		}else if (cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') {
			journal_width = form_width - 50;
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') {
			journal_width = form_width - 50;
		}else if (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') {
			journal_width = form_width - 50;
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') {
			journal_width = form_width - 50;
		}	

		// Destination Journal
		html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:' + journal_width.toString() + 'px;">';
		html += '		<label id="html_create_from_document_journal_label" style="position: absolute; top: 0px; left: 0px;z-index:5;';

		if ((cur_frm.doctype.toUpperCase() == 'IOI PARK') || (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') || (cur_frm.doctype.toUpperCase() == 'IOI ITEM') || (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') ||
			(cur_frm.doctype.toUpperCase() == 'IOI CASH DESK')){
			html += "display:none; "
		}
		
		html += '"><b>';
		
		if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {
			html += __("Sales journal") 
		}else if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE')) {
			html += __("Purchases journal") 
		}else if ((cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION')) {
			html += __("Warehouse journal") 
		}else if (cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') {
			html += __("Dossier journal") 
		}else if (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') {
			html += __("Production journal") 
		}else if (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') {
			html += __("Batch SN Prefix") 
		}else if (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') {
			html += __("Subscription journal") 
		}
		
		html += '</b></label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: ' + journal_width.toString() + 'px; height: 25px;"> ';
		html += '			<select id="html_create_from_document_journal_id" class="input-with-feedback form-control bold" ';

		if ((cur_frm.doctype.toUpperCase() == 'IOI PARK') || (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') || (cur_frm.doctype.toUpperCase() == 'IOI ITEM') || (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') ||
			(cur_frm.doctype.toUpperCase() == 'IOI CASH DESK')) {
			html += 'style="display:none;" '
		}		
		
		html += '> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		if (cur_frm.doctype.toUpperCase() == 'IOI PARK') {

			if (document.getElementById('html_create_from_document_park_identification_label')) {
				document.getElementById('html_create_from_document_park_identification_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_park_identification')) {
				document.getElementById('html_create_from_document_park_identification').remove();
			}


			if (document.getElementById('html_create_from_document_park_description_label')) {
				document.getElementById('html_create_from_document_park_description_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_park_description')) {
				document.getElementById('html_create_from_document_park_description').remove();
			}	
			
			if (document.getElementById('html_create_from_document_park_code_ref_label')) {
				document.getElementById('html_create_from_document_park_code_ref_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_park_code_ref')) {
				document.getElementById('html_create_from_document_park_code_ref').remove();
			}		

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:220px;">';
			html += '		<label id="html_create_from_document_park_identification_label" style="position: absolute; top: 0px; left: 0px;z-index:5;'

			if (identification_visible) {
				html += 'display:block;';
			}else{
				html += 'display:none;';
			}
			
			html += '"><b>' + __("Identification")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 220px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_park_identification" class="input-with-feedback form-control bold" style="text-transform: uppercase;';
			
			if (identification_visible) {
				html += 'display:block;';
			}else{
				html += 'display:none;';
			}			
			
			html += '" value=""> ';
			html += '		</div>';
			html += '	</div>';	

			if (identification_visible) {			
			
				journal_left += 230;
			}

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:300px;">';
			html += '		<label id="html_create_from_document_park_description_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Description")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 300px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_park_description" class="input-with-feedback form-control bold" value=""> ';
			html += '		</div>';
			html += '	</div>';	
			
			journal_left += 310;
			
			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:220px;">';
			html += '		<label id="html_create_from_document_park_code_ref_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Serial number")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 220px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_park_code_ref" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value=""> ';
			html += '		</div>';
			html += '	</div>';	
			
						
	
		}else if (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') {

			if (document.getElementById('html_create_from_document_customer_identification_label')) {
				document.getElementById('html_create_from_document_customer_identification_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_customer_identification')) {
				document.getElementById('html_create_from_document_customer_identification').remove();
			}

			if (document.getElementById('html_create_from_document_customer_description_label')) {
				document.getElementById('html_create_from_document_customer_description_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_customer_description')) {
				document.getElementById('html_create_from_document_customer_description').remove();
			}	

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:220px;">';
			html += '		<label id="html_create_from_document_customer_identification_label" style="position: absolute; top: 0px; left: 0px;z-index:5;'

			if (identification_visible) {
				html += 'display:block;';
			}else{
				html += 'display:none;';
			}
			
			html += '"><b>' + __("Identification")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 220px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_customer_identification" class="input-with-feedback form-control bold" style="text-transform: uppercase;';
			
			if (identification_visible) {
				html += 'display:block;';
			}else{
				html += 'display:none;';
			}			
			
			html += '" value=""> ';
			html += '		</div>';
			html += '	</div>';			

			let description_width = 300;
			
			if (identification_visible) {			
			
				journal_left += 230;
			}else{
				description_width = form_width - 50;
			}

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:' + description_width.toString() + 'px;">';
			html += '		<label id="html_create_from_document_customer_description_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Customer name")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width:' + description_width.toString() + 'px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_customer_description" class="input-with-feedback form-control bold" value=""> ';
			html += '		</div>';
			html += '	</div>';	

		}else if (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') {

			if (document.getElementById('html_create_from_document_supplier_identification_label')) {
				document.getElementById('html_create_from_document_supplier_identification_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_supplier_identification')) {
				document.getElementById('html_create_from_document_supplier_identification').remove();
			}

			if (document.getElementById('html_create_from_document_supplier_description_label')) {
				document.getElementById('html_create_from_document_supplier_description_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_supplier_description')) {
				document.getElementById('html_create_from_document_supplier_description').remove();
			}	

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:220px;">';
			html += '		<label id="html_create_from_document_supplier_identification_label" style="position: absolute; top: 0px; left: 0px;z-index:5;'

			if (identification_visible) {
				html += 'display:block;';
			}else{
				html += 'display:none;';
			}
			
			html += '"><b>' + __("Identification")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 220px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_supplier_identification" class="input-with-feedback form-control bold" style="text-transform: uppercase;';
			
			if (identification_visible) {
				html += 'display:block;';
			}else{
				html += 'display:none;';
			}			
			
			html += '" value=""> ';
			html += '		</div>';
			html += '	</div>';			

			let description_width = 300;
			
			if (identification_visible) {			
			
				journal_left += 230;
			}else{
				description_width = form_width - 50;
			}

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:' + description_width.toString() + 'px;">';
			html += '		<label id="html_create_from_document_supplier_description_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Supplier name")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width:' + description_width.toString() + 'px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_supplier_description" class="input-with-feedback form-control bold" value=""> ';
			html += '		</div>';
			html += '	</div>';	
	

		}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') {

			if (document.getElementById('html_create_from_document_manufacturer_identification_label')) {
				document.getElementById('html_create_from_document_manufacturer_identification_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_manufacturer_identification')) {
				document.getElementById('html_create_from_document_manufacturer_identification').remove();
			}

			if (document.getElementById('html_create_from_document_manufacturer_description_label')) {
				document.getElementById('html_create_from_document_manufacturer_description_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_manufacturer_description')) {
				document.getElementById('html_create_from_document_manufacturer_description').remove();
			}	

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:220px;">';
			html += '		<label id="html_create_from_document_manufacturer_identification_label" style="position: absolute; top: 0px; left: 0px;z-index:5;'
			html += 'display:block;"><b>' + __("Identification")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 220px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_manufacturer_identification" class="input-with-feedback form-control bold" style="text-transform: uppercase;';
			html += 'display:block;" value=""> ';
			html += '		</div>';
			html += '	</div>';			

			let description_width = 300;
			
			journal_left += 230;
			

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:' + description_width.toString() + 'px;">';
			html += '		<label id="html_create_from_document_manufacturer_description_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Description")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width:' + description_width.toString() + 'px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_manufacturer_description" class="input-with-feedback form-control bold" value=""> ';
			html += '		</div>';
			html += '	</div>';	
	

		}else if (cur_frm.doctype.toUpperCase() == 'IOI ITEM') {

			if (document.getElementById('html_create_from_document_item_identification_label')) {
				document.getElementById('html_create_from_document_item_identification_label').remove();
			}

			if (document.getElementById('html_create_from_document_item_identification')) {
				document.getElementById('html_create_from_document_item_identification').remove();
			}

			if (document.getElementById('html_create_from_document_item_description_label')) {
				document.getElementById('html_create_from_document_item_description_label').remove();
			}

			if (document.getElementById('html_create_from_document_item_description')) {
				document.getElementById('html_create_from_document_item_description').remove();
			}	

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:220px;">';
			html += '		<label id="html_create_from_document_item_identification_label" style="position: absolute; top: 0px; left: 0px;z-index:5;'

			if (identification_visible) {
				html += 'display:block;';
			}else{
				html += 'display:none;';
			}
			
			html += '"><b>' + __("Identification")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 220px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_item_identification" class="input-with-feedback form-control bold" style="text-transform: uppercase;';
			
			if (identification_visible) {
				html += 'display:block;';
			}else{
				html += 'display:none;';
			}			
			
			html += '" value=""> ';
			html += '		</div>';
			html += '	</div>';			

			let description_width = 300;
			
			if (identification_visible) {			
			
				journal_left += 230;
			}else{
				description_width = form_width - 50;
			}

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:' + description_width.toString() + 'px;">';
			html += '		<label id="html_create_from_document_item_description_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Description")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width:' + description_width.toString() + 'px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_item_description" class="input-with-feedback form-control bold" value=""> ';
			html += '		</div>';
			html += '	</div>';	

		}else if (cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') {

			if (document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_label')) {
				document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_id')) {
				document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_id').remove();
			}		

			if (document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_button')) {
				document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_button').remove();
			}		
						

			if (document.getElementById('html_create_from_document_manufacturer_cat_identification_label')) {
				document.getElementById('html_create_from_document_manufacturer_cat_identification_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_manufacturer_cat_identification')) {
				document.getElementById('html_create_from_document_manufacturer_cat_identification').remove();
			}

			if (document.getElementById('html_create_from_document_manufacturer_cat_description_label')) {
				document.getElementById('html_create_from_document_manufacturer_cat_description_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_manufacturer_cat_description')) {
				document.getElementById('html_create_from_document_manufacturer_cat_description').remove();
			}	

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:220px;">';
			html += '		<label id="html_create_from_document_manufacturer_cat_manufacturer_label" style="position: absolute; top: 0px; left: 0px;z-index:5;'
			html += 'display:block;"><b>' + __("Manufacturer")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 180px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_manufacturer_cat_manufacturer_id" class="input-with-feedback form-control bold" style="text-transform: uppercase;';
			html += 'display:block;" value="' + cur_frm.doc.manufacturer_id + '"> ';
		
			html += '		</div>';

			html += '		<div style="position: absolute; top:25px; left: 190px; height: 30px; width:30px">';
			html +='			<button id="html_create_from_document_manufacturer_cat_manufacturer_button" class="btn btn-default ellipsis" style="height: 30px; width: 30px;" onclick="">...</button>';
			html += '		</div>';		
			
			html += '	</div>';			

			let description_width = 300;
			
			journal_left += 230;			

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:220px;">';
			html += '		<label id="html_create_from_document_manufacturer_cat_identification_label" style="position: absolute; top: 0px; left: 0px;z-index:5;'
			html += 'display:block;"><b>' + __("Identification")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 220px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_manufacturer_cat_identification" class="input-with-feedback form-control bold" style="text-transform: uppercase;';
			html += 'display:block;" value=""> ';
			html += '		</div>';

			html += '	</div>';			

			description_width = 300;
			
			journal_left += 230;
			

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:' + description_width.toString() + 'px;">';
			html += '		<label id="html_create_from_document_manufacturer_cat_description_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Description")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width:' + description_width.toString() + 'px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_manufacturer_cat_description" class="input-with-feedback form-control bold" value=""> ';
			html += '		</div>';
			html += '	</div>';	
	
		}else if (cur_frm.doctype.toUpperCase() == 'IOI CASH DESK') {

			if (document.getElementById('html_create_from_document_cash_desk_identification_label')) {
				document.getElementById('html_create_from_document_cash_desk_identification_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_cash_desk_identification')) {
				document.getElementById('html_create_from_document_cash_desk_identification').remove();
			}			

			if (document.getElementById('html_create_from_document_cash_desk_currency_label')) {
				document.getElementById('html_create_from_document_cash_desk_currency_label').remove();
			}
	
			if (document.getElementById('html_create_from_document_cash_desk_currency_id')) {
				document.getElementById('html_create_from_document_cash_desk_currency_id').remove();
			}		

			if (document.getElementById('html_create_from_document_cash_desk_currency_button')) {
				document.getElementById('html_create_from_document_cash_desk_currency_button').remove();
			}

			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:220px;">';
			html += '		<label id="html_create_from_document_cash_desk_identification_label" style="position: absolute; top: 0px; left: 0px;z-index:5;'
			html += 'display:block;"><b>' + __("Identification")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 220px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_cash_desk_identification" class="input-with-feedback form-control bold" style="text-transform: uppercase;';
			html += 'display:block;" value=""> ';
			html += '		</div>';

			html += '	</div>';			

			
			journal_left += 230;	


			html += '	<div style="position: relative; top: 0px; left: ' + journal_left.toString() + 'px; width:215px;">';
			html += '		<label id="html_create_from_document_cash_desk_currency_label" style="position: absolute; top: 0px; left: 0px;z-index:5;'
			html += 'display:block;"><b>' + __("Currency")  + '</b></label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 175px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_cash_desk_currency_id" class="input-with-feedback form-control bold" style="text-transform: uppercase;';
			html += 'display:block;" value="' + cur_frm.doc.currency_id + '"> ';
		
			html += '		</div>';

			html += '		<div style="position: absolute; top:25px; left: 185px; height: 30px; width:30px">';
			html +='			<button id="html_create_from_document_cash_desk_currency_button" class="btn btn-default ellipsis" style="height: 30px; width: 30px;" onclick="">...</button>';
			html += '		</div>';		
			
			html += '	</div>';			

		}

		let profile_width = form_width - 50;

		// Profile
		html += '	<div style="position: relative; top: 65px; left: 2px; width: ' + profile_width.toString() + 'px;">';
		html += '		<label id="html_create_from_document_profile_label" style="position: absolute; top: 0px; left: 0px;z-index:5;"><b>' + __("Duplication profile") + '</b>&nbsp;&nbsp;(&nbsp;<font color="blue">' + __("defined in ioi Module") + '</font>&nbsp;)</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: ' + profile_width.toString() + 'px; height: 25px;"> ';
		html += '			<select id="html_create_from_document_profile_id" class="input-with-feedback form-control bold"> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';	
		
		if (cur_frm.doctype.toUpperCase() == 'IOI ITEM') {

			if (document.getElementById('html_create_from_document_item_copy_sales_price_label')) {
				document.getElementById('html_create_from_document_item_copy_sales_price_label').remove()
			}

			if (document.getElementById('html_create_from_document_item_copy_sales_price')) {
				document.getElementById('html_create_from_document_item_copy_sales_price').remove()
			}			

			if (document.getElementById('html_create_from_document_item_copy_purchases_price_label')) {
				document.getElementById('html_create_from_document_item_copy_purchases_price_label').remove()
			}

			if (document.getElementById('html_create_from_document_item_copy_purchases_price')) {
				document.getElementById('html_create_from_document_item_copy_purchases_price').remove()
			}			

			// Copy Sales Prices
			html += '	<div style="position: relative; top: 130px; left: 2px; width:250px;">';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 250px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_item_copy_sales_price" type="checkbox" class="input-with-feedback form-control bold" style="z-index:100;" checked>';
			html += '		</div>';
			html += '		<label id="html_create_from_document_item_copy_sales_price_label" style="position: absolute; top: 0px; left: 30px;z-index:100;"><b>' + __("Copy sales prices") + '</b></label>';
			html += '	</div>';

			// Copy Purchases Prices
			html += '	<div style="position: relative; top: 150px; left: 2px; width:250px;">';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 250px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_item_copy_purchases_price" type="checkbox" class="input-with-feedback form-control bold" style="z-index:100;" checked>';
			html += '		</div>';
			html += '		<label id="html_create_from_document_item_copy_purchases_price_label" style="position: absolute; top: 0px; left: 30px;z-index:100;"><b>' + __("Copy purchases prices") + '</b></label>';
			html += '	</div>';

		}


		// Discard prices
		html += '	<div style="position: relative; top: 130px; left: 2px; width:250px;">';
		html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 250px; height: 25px;"> ';
		html += '			<input id="html_create_from_document_discard_price" type="checkbox" class="input-with-feedback form-control bold" checked ';


		if ((cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') ||
			(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') || 
			(cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') || (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') ||
			(cur_frm.doctype.toUpperCase() == 'IOI PARK') || (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') ||
			(cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') || (cur_frm.doctype.toUpperCase() == 'IOI ITEM') ||
			(cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') || (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') ||
			(cur_frm.doctype.toUpperCase() == 'IOI CASH DESK')) {
			html += ' style="display:none" ';
		}		
		
		html += '>';
		html += '		</div>';

		html += '		<label id="html_create_from_document_discard_price_label" style="position: absolute; top: 0px; left: 30px;z-index:5;';
		
		if ((cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') ||
			(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') || 
			(cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') || (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') || 
			(cur_frm.doctype.toUpperCase() == 'IOI PARK') || (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') ||
			(cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') || (cur_frm.doctype.toUpperCase() == 'IOI ITEM') ||
			(cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') || (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') ||
			(cur_frm.doctype.toUpperCase() == 'IOI CASH DESK')) {
			html += ' display:none ';
		}				
		html += '"><b>' + __("Discard prices refresh") + '</b></label>';

		html += '	</div>';




		let top_remark = 155;

		if (show_remove) {
			html += '	<div style="position: relative; top: 150px; left: 2px; width:250px;">';
			html += '		<div class="control-input" style="position: absolute; top: 2px; left: 0px; width: 250px; height: 25px;"> ';
			html += '			<input id="html_create_from_document_remove_original" type="checkbox" class="input-with-feedback form-control bold" checked>';
			html += '		</div>';

			html += '		<label id="html_create_from_document_remove_original_label" style="position: absolute; top: 0px; left: 30px;z-index:5;"><b>' + __("Remove the original document") + '</b></label>';
			html += '	</div>';

			top_remark += 30;
		}

		if ((cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') || (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') ||
			(cur_frm.doctype.toUpperCase() == 'IOI PARK') || (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') ||
			(cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER') ||
			(cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER') || (cur_frm.doctype.toUpperCase() == 'IOI ITEM') ||
			(cur_frm.doctype.toUpperCase() == 'IOI MANUFACTURER CATALOG') || (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') ||
			(cur_frm.doctype.toUpperCase() == 'IOI CASH DESK')) {	

			if (cur_frm.doctype.toUpperCase() == 'IOI ITEM') {
				top_remark = 180;
			}else{
				top_remark = 130;
			}
				
			
		}else{
			if ((cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') ||
				(cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION')) {
				if (!show_remove) {
					top_remark = 150;
				}
			}			
		}

		html += '	<div style="position: relative; top: ' + top_remark.toString() + 'px; left: 2px; width:99%;">';
		html += '		<label id="html_create_from_document_message" style="position: absolute; top: 0px; left: 0px;z-index:5;color:red"></label>';
		html += '	</div>';

		html += '</div>';



		p.fields_dict.html_create_from_document.$wrapper.html(html);


		p.$wrapper.find('.modal-dialog').css("max-width", form_width.toString() + "px").css("width", form_width.toString() + "px");
		p.show();



		silicon_ioi.doctype.ioiDuplicate.sleep_static(200).then(() => {

			if (document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_button')) {

				let fct_manuf_click = function() {
				
						let title = __("Select a manufacturer");
						let form_width_pixel = '710px';
						let table = "ioi Manufacturer";
						let fields = 'name, description';
						let fields_len = '250, 380';
						let fields_desc = __('Identification') + ',' + __('Description');
						let where = 'enabled = 1';
						let order_by = 'name asc';
						
						let fct_callback = function (return_value) {
							document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_id').value = return_value;
							document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_id').focus();
						}
			
						silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

				}

				document.getElementById('html_create_from_document_manufacturer_cat_manufacturer_button').onclick = fct_manuf_click;

			}

			if (document.getElementById('html_create_from_document_cash_desk_currency_button')) {

				let fct_manuf_click = function() {
				
						let title = __("Select a currency");
						let form_width_pixel = '710px';
						let table = "ioi Currency";
						let fields = 'name, description';
						let fields_len = '250, 380';
						let fields_desc = __('Identification') + ',' + __('Description');
						let where = 'enabled = 1';
						let order_by = 'name asc';
						
						let fct_callback = function (return_value) {
							document.getElementById('html_create_from_document_cash_desk_currency_id').value = return_value;
							document.getElementById('html_create_from_document_cash_desk_currency_id').focus();
						}
			
						silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

				}

				document.getElementById('html_create_from_document_cash_desk_currency_button').onclick = fct_manuf_click;

			}


			silicon_ioi.doctype.ioiDuplicate.create_from_document_load_duplication_profiles();

			silicon_ioi.doctype.ioiDuplicate.create_from_document_refresh_journals(true);

			let fct_change_site = function() {

				silicon_ioi.doctype.ioiDuplicate.create_from_document_refresh_journals(false);
			}

			document.getElementById('html_create_from_document_site_id').onchange = fct_change_site;

			

		});


	}

	static create_from_document_load_duplication_profiles()
	{
		let data = [];
		let method = 'silicon_ioi.ioi_configuration.doctype.ioi_module_duplicate_header.ioi_module_duplicate_header.ioi_module_duplicate_header_get_enabled_profiles_list'
		
		frappe.call({  	method: method, 
						args: {
							"doctype": cur_frm.doctype
						},
						async: false,
						callback:function(r)	{ 
							data = r.message;
						
						}
		});		
		
		if (document.getElementById('html_create_from_document_profile_id').options.length > 0) {

			for (var i = document.getElementById('html_create_from_document_profile_id').options.length-1; i >= 0; i--) {
				document.getElementById('html_create_from_document_profile_id').remove(i);
			}
		}	
		
		if (data.length > 0) {

			for (var i = 0; i < data.length; i++) {

				let option = document.createElement("option");
				option.value = data[i].name;
				option.text = data[i].description;

				if (data[i].is_default == 1) {
					option.selected = true;
				}

				document.getElementById('html_create_from_document_profile_id').add(option);						
				
			}			

		}
	}


	static create_from_document_refresh_journals(is_loading = false) {

		if ((cur_frm.doctype.toUpperCase() != 'IOI PARK') && (cur_frm.doctype.toUpperCase() != 'IOI CUSTOMER') && (cur_frm.doctype.toUpperCase() != 'IOI SUPPLIER') &&
			(cur_frm.doctype.toUpperCase() != 'IOI MANUFACTURER') && (cur_frm.doctype.toUpperCase() != 'IOI ITEM') && (cur_frm.doctype.toUpperCase() != 'IOI MANUFACTURER CATALOG') &&
			(cur_frm.doctype.toUpperCase() != 'IOI CASH DESK'))  {
			if (document.getElementById('html_create_from_document_journal_id').options.length > 0) {

				for (var i = document.getElementById('html_create_from_document_journal_id').options.length-1; i >= 0; i--) {
					document.getElementById('html_create_from_document_journal_id').remove(i);
				}
			}

			if ((document.getElementById('html_create_from_document_site_id').value) && (document.getElementById('html_create_from_document_site_id').value.trim() != '')) {

				let method = '';

				if ((cur_frm.doctype.toUpperCase() == 'IOI SALES QUOTE') || (cur_frm.doctype.toUpperCase() == 'IOI SALES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI SALES DELIVERY') || (cur_frm.doctype.toUpperCase() == 'IOI SALES INVOICE')) {
					method = path_sales_document + '.ioi_sales_document_create_from_document_get_journals';
				}else if ((cur_frm.doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (cur_frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE')) {
					method = path_purchases_document + '.ioi_purchases_document_create_from_document_get_journals';
				}else if ((cur_frm.doctype.toUpperCase() == 'IOI STOCK ENTRY') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION')) {
					method = path_stock_document + '.ioi_stock_document_create_from_document_get_journals';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') {
					method = path_dossier + '.ioi_dossier_create_from_document_get_journals';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI PRODUCTION') {
					method = path_production + '.ioi_production_create_from_document_get_journals';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') {
					method = path_batchsn + '.ioi_batch_sn_create_from_document_get_prefixes';
				}else if (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') {
					method = path_subscription + '.ioi_subscription_create_from_document_get_journals';
				}	
				
				let document_type = ''
				let site_id = ''

				if (cur_frm.doctype.toUpperCase() == 'IOI DOSSIER') {
					document_type = 'DOSSIER';
					site_id = ''
				}else if (cur_frm.doctype.toUpperCase() == 'IOI BATCH SN') {
					document_type = 'BATCH_SN';

				}else if (cur_frm.doctype.toUpperCase() == 'IOI SUBSCRIPTION') {
						document_type = 'SUBSCRIPTION';
				}else{
					document_type = cur_frm.doc.document_type
				}
		

				frappe.call({
					method: method,
					args: {"doctype": cur_frm.doctype, "document_type": document_type, "site_id": document.getElementById('html_create_from_document_site_id').value.toUpperCase()},
					async: false,
					callback:function(r)	{

						for (var i = 0; i < r.message.length; i++) {

							var option = document.createElement("option");
							option.value = r.message[i].name;
							option.text = r.message[i].name;

							if (is_loading) {
								if (cur_frm.doctype.toUpperCase() != 'IOI BATCH SN') {
									if (cur_frm.doc.journal_id == r.message[i].name) {
										option.selected = true;
									}
								}else{
									if (cur_frm.doc.prefix_id == r.message[i].name) {
										option.selected = true;
									}
								}
							}
							document.getElementById("html_create_from_document_journal_id").add(option);
						}
					}
				});
			}
		}
	}

	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiDuplicate = ioiDuplicate;
