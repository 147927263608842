frappe.provide('silicon_ioi');

const paths = {
	ioi_doc_scanner: 'silicon_ioi.ioi_core.doctype.ioi_doc_scanner.ioi_doc_scanner',
	ioi_sale_entry: 'silicon_ioi.ioi_accounting.doctype.ioi_sale_entry.ioi_sale_entry',
	ioi_purchase_entry: 'silicon_ioi.ioi_accounting.doctype.ioi_purchase_entry.ioi_purchase_entry',
	ioi_financial_entry: 'silicon_ioi.ioi_accounting.doctype.ioi_financial_entry.ioi_financial_entry',
	ioi_miscellaneous_entry: 'silicon_ioi.ioi_accounting.doctype.ioi_miscellaneous_entry.ioi_miscellaneous_entry',
	ioi_sale_entry_template: 'silicon_ioi.ioi_accounting.doctype.ioi_sale_entry_template.ioi_sale_entry_template',
	ioi_purchase_entry_template: 'silicon_ioi.ioi_accounting.doctype.ioi_purchase_entry_template.ioi_purchase_entry_template',
	ioi_financial_entry_template: 'silicon_ioi.ioi_accounting.doctype.ioi_financial_entry_template.ioi_financial_entry_template',
	ioi_miscellaneous_entry_template: 'silicon_ioi.ioi_accounting.doctype.ioi_miscellaneous_entry_template.ioi_miscellaneous_entry_template',
	ioi_purchase_entry_payment_approval: 'silicon_ioi.ioi_accounting.doctype.ioi_purchase_entry_payment_approval.ioi_purchase_entry_payment_approval',
	ioi_general_account: 'silicon_ioi.ioi_accounting.doctype.ioi_general_account.ioi_general_account',
	ioi_general_accounts_usage_history: 'silicon_ioi.ioi_accounting.doctype.ioi_general_accounts_usage_history.ioi_general_accounts_usage_history',
	ioi_accounting_journal: 'silicon_ioi.ioi_accounting.doctype.ioi_accounting_journal.ioi_accounting_journal',
	ioi_vat_code: 'silicon_ioi.ioi_accounting.doctype.ioi_vat_code.ioi_vat_code',
	ioi_vat_matching: 'silicon_ioi.ioi_accounting.doctype.ioi_vat_matching.ioi_vat_matching',
	ioi_accounting_opening: 'silicon_ioi.ioi_accounting.doctype.ioi_accounting_opening.ioi_accounting_opening',
	ioi_financial_calculation_template: 'silicon_ioi.ioi_accounting.doctype.ioi_financial_calculation_template.ioi_financial_calculation_template',
	ioi_financial_calculation: 'silicon_ioi.ioi_accounting.doctype.ioi_financial_calculation.ioi_financial_calculation',
	ioi_vat_return: 'silicon_ioi.ioi_accounting.doctype.ioi_vat_return.ioi_vat_return',
	ioi_vat_turnover_reconciliation: 'silicon_ioi.ioi_accounting.doctype.ioi_vat_turnover_reconciliation.ioi_vat_turnover_reconciliation',
	ioi_intracom_statement: 'silicon_ioi.ioi_accounting.doctype.ioi_intracom_statement.ioi_intracom_statement',
	ioi_list_of_customers_subject_to_vat: 'silicon_ioi.ioi_accounting.doctype.ioi_list_of_customers_subject_to_vat.ioi_list_of_customers_subject_to_vat',
	ioi_customer: 'silicon_ioi.ioi_sales.doctype.ioi_customer.ioi_customer',
	ioi_supplier: 'silicon_ioi.ioi_purchases.doctype.ioi_supplier.ioi_supplier',
	ioi_analytic_1: 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_1.ioi_analytic_1',
	ioi_analytic_2: 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_2.ioi_analytic_2',
	ioi_analytic_3: 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_3.ioi_analytic_3',
	ioi_analytic_4: 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_4.ioi_analytic_4',
	ioi_analytic_pattern: 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_pattern.ioi_analytic_pattern',
	ioi_company: 'silicon_ioi.ioi_enterprise.doctype.ioi_company.ioi_company',
	ioi_division: 'silicon_ioi.ioi_enterprise.doctype.ioi_division.ioi_division',
	ioi_period: 'silicon_ioi.ioi_enterprise.doctype.ioi_period.ioi_period',
	ioi_currency: 'silicon_ioi.ioi_configuration.doctype.ioi_currency.ioi_currency',
	ioi_country: 'silicon_ioi.ioi_configuration.doctype.ioi_country.ioi_country',
	ioi_postal_code: 'silicon_ioi.ioi_configuration.doctype.ioi_postal_code.ioi_postal_code',
	ioi_payment_terms: 'silicon_ioi.ioi_configuration.doctype.ioi_payment_terms.ioi_payment_terms',
	ioi_general_settings: 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings',
	ioi_accounting_settings: 'silicon_ioi.ioi_configuration.doctype.ioi_accounting_settings.ioi_accounting_settings',
	ioi_default_general_accounts: 'silicon_ioi.ioi_configuration.doctype.ioi_default_general_accounts.ioi_default_general_accounts',
	ioi_sage_bob_import: 'silicon_ioi.ioi_accounting.doctype.ioi_sage_bob_import.ioi_sage_bob_import',
	ioi_payment_envelope: 'silicon_ioi.ioi_accounting.doctype.ioi_payment_envelope.ioi_payment_envelope',
	ioi_domiciliation_request: 'silicon_ioi.ioi_accounting.doctype.ioi_domiciliation_request.ioi_domiciliation_request',
	ioi_payment_reminder_category: 'silicon_ioi.ioi_accounting.doctype.ioi_payment_reminder_category.ioi_payment_reminder_category',
	ioi_payment_reminder_template: 'silicon_ioi.ioi_accounting.doctype.ioi_payment_reminder_template.ioi_payment_reminder_template',
	ioi_payment_reminder_campaign: 'silicon_ioi.ioi_accounting.doctype.ioi_payment_reminder_campaign.ioi_payment_reminder_campaign',
	ioi_customer_transaction: 'silicon_ioi.ioi_accounting.doctype.ioi_customer_transaction.ioi_customer_transaction',
	ioi_customer_balance: 'silicon_ioi.ioi_accounting.doctype.ioi_customer_balance.ioi_customer_balance',
	ioi_supplier_transaction: 'silicon_ioi.ioi_accounting.doctype.ioi_supplier_transaction.ioi_supplier_transaction',
	ioi_supplier_balance: 'silicon_ioi.ioi_accounting.doctype.ioi_supplier_balance.ioi_supplier_balance',
	ioi_account_transaction: 'silicon_ioi.ioi_accounting.doctype.ioi_account_transaction.ioi_account_transaction',
	ioi_account_balance: 'silicon_ioi.ioi_accounting.doctype.ioi_account_balance.ioi_account_balance',
	ioi_account_budget: 'silicon_ioi.ioi_accounting.doctype.ioi_account_budget.ioi_account_budget',
	ioi_analytic_transaction: 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_transaction.ioi_analytic_transaction',
	ioi_analytic_balance: 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_balance.ioi_analytic_balance',
	ioi_analytic_budget: 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_budget.ioi_analytic_budget',
	ioi_budget_line: 'silicon_ioi.ioi_accounting.doctype.ioi_budget_line.ioi_budget_line',
	ioi_line_budget: 'silicon_ioi.ioi_accounting.doctype.ioi_line_budget.ioi_line_budget',
	ioi_accounting_operation_profile: 'silicon_ioi.ioi_accounting.doctype.ioi_accounting_operation_profile.ioi_accounting_operation_profile',
	ioi_expense_deferral: 'silicon_ioi.ioi_accounting.doctype.ioi_expense_deferral.ioi_expense_deferral',
	ioi_banking_transaction_keyword: 'silicon_ioi.ioi_accounting.doctype.ioi_banking_transaction_keyword.ioi_banking_transaction_keyword',
	ioi_fixed_asset: 'silicon_ioi.ioi_accounting.doctype.ioi_fixed_asset.ioi_fixed_asset',
	ioi_fixed_asset_category: 'silicon_ioi.ioi_accounting.doctype.ioi_fixed_asset_category.ioi_fixed_asset_category',
	ioi_fixed_asset_management: 'silicon_ioi.ioi_accounting.doctype.ioi_fixed_asset_management.ioi_fixed_asset_management',
	ioi_fixed_asset_operation: 'silicon_ioi.ioi_accounting.doctype.ioi_fixed_asset_operation.ioi_fixed_asset_operation',
	ioi_general_accounts_dictionary: 'silicon_ioi.ioi_accounting.doctype.ioi_general_accounts_dictionary.ioi_general_accounts_dictionary',
	ioi_vat_codes_dictionary: 'silicon_ioi.ioi_accounting.doctype.ioi_vat_codes_dictionary.ioi_vat_codes_dictionary',
	ioi_lu_vat_return: 'silicon_ioi.ioi_accounting.doctype.ioi_lu_vat_return.ioi_lu_vat_return',
	ioi_lu_acquisition_and_depreciation_tables: 'silicon_ioi.ioi_accounting.doctype.ioi_lu_acquisition_and_depreciation_tables.ioi_lu_acquisition_and_depreciation_tables',
	ioi_disallowed_expense_category: 'silicon_ioi.ioi_accounting.doctype.ioi_disallowed_expense_category.ioi_disallowed_expense_category',
	ioi_table_of_disallowed_expenses: 'silicon_ioi.ioi_accounting.doctype.ioi_table_of_disallowed_expenses.ioi_table_of_disallowed_expenses',
}

export class ioiAccUtils {

	static get paths() {
		Object.freeze(paths);
		return paths;
	}

	static sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	static get_rounded_float(flt_value, nr_decimals) {
		let i = Math.pow(10, nr_decimals);
		return Math.round(flt_value * i) / i;
	}

	static get_str_formatted_float(flt_value, nr_decimals) {
		return flt(flt_value).toFixed(nr_decimals).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
	}

	static get_journal_type_label(journal_type) {
		let result = '?';
		switch (journal_type || '') {
			case 'CAS': result = __('Cash Book'); break;
			case 'PRI': result = __('Misc. Operations'); break;
			case 'PRO': result = __('Re-Opening'); break;
			case 'PUC': result = __('CN/Purchases'); break;
			case 'PUR': result = __('Purchases'); break;
			case 'SAC': result = __('CN/Sales'); break;
			case 'SAL': result = __('Sales'); break;
		};
		return result;
	}

	static get_general_settings_data() {
		let result = {};
		frappe.call({
			method: this.paths.ioi_general_settings + '.ioi_general_settings_get_data',
			args: {},
			async: false,
			callback: function(r) {
				result.currency = r.message.currency;
				result.language_1 = r.message.language_1;
				result.language_2 = r.message.language_2;
				result.language_3 = r.message.language_3;
				result.language_4 = r.message.language_4;
			}
		});
		return result;
	}

	static get_currency_data(currency_id) {
		let result = {};
		if (currency_id) {
			frappe.call({
				method: this.paths.ioi_currency + '.ioi_currency_get_data',
				args: {'currency_id': currency_id},
				async: false,
				callback: function(r) {
					result.symbol = r.message.symbol;
					result.decimals = r.message.decimals;
					result.additional_decimals = r.message.additional_decimals;
					result.additional_decimals_tot = r.message.additional_decimals_tot;
					result.purchases_rate = r.message.purchases_rate;
					result.purchases_rate_inv = r.message.purchases_rate_inv;
					result.sales_rate = r.message.sales_rate;
					result.sales_rate_inv = r.message.sales_rate_inv;
					result.financial_rate = r.message.financial_rate;
				}
			});
		};
		return result;
	}

	static get_main_division_id() {
		let result = '';
		frappe.call({
			method: this.paths.ioi_division + '.ioi_division_get_main',
			args: {},
			async: false,
			callback: function(r) {result = r.message;}
		});
		return result;
	}

	static get_main_division_legality() {
		let result = '';
		frappe.call({
			method: this.paths.ioi_division + '.ioi_division_get_main_legality',
			args: {},
			async: false,
			callback: function(r) {result = r.message;}
		});
		return result;
	}

	static get_division_legality(division_id) {
		let result = '';
		frappe.call({
			method: this.paths.ioi_division + '.ioi_division_get_legality',
			args: {'division_id': division_id},
			async: false,
			callback: function(r) {result = r.message;}
		});
		return result;
	}

	static warn(title, message_html, proceed_action, cancel_action, primary_label, is_minimizable) {
		const d = new frappe.ui.Dialog({
			title: title,
			indicator: 'red',
			primary_action_label: primary_label,
			primary_action: () => {
				if (proceed_action) proceed_action();
				d.hide();
			},
			secondary_action_label: __("Cancel", null, "Secondary button in warning dialog"),
			secondary_action: () => {
				if (cancel_action) cancel_action();
				d.hide();
			},
			minimizable: is_minimizable
		});
		d.$body.append(`<div class="frappe-confirm-message">${message_html}</div>`);
		d.standard_actions.find('.btn-primary').removeClass('btn-primary').addClass('btn-danger');
		d.standard_actions.find('.btn-primary').removeClass('btn-primary').addClass('btn-danger');
		d.show();
		return d;
	}

	static get_month_label(month_number) {
		let r = '';
		switch (cint(month_number)) {
			case 1: r = __('January'); break;
			case 2: r = __('February'); break;
			case 3: r = __('March'); break;
			case 4: r = __('April'); break;
			case 5: r = __('May'); break;
			case 6: r = __('June'); break;
			case 7: r = __('July'); break;
			case 8: r = __('August'); break;
			case 9: r = __('September'); break;
			case 10: r = __('October'); break;
			case 11: r = __('November'); break;
			case 12: r = __('December'); break;
		}
		return r;
	}

}

silicon_ioi.ioiAccUtils = ioiAccUtils;
