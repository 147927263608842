frappe.provide('silicon_ioi');

export const MODULE_STATUS_FETCH_METHODS = {
	JS: 'js',
	FRAMEWORK: 'framework',
	QUERY_BUILDER: 'query_builder',
};

const DOCTYPES_NAMES = {
	MODULE_STATUS: 'ioi Module Status',
	MODULE_USER_STATUS: 'ioi Module User Status',
};

class IoiModuleStatusHelper {
	constructor(fetch_method = MODULE_STATUS_FETCH_METHODS.QUERY_BUILDER) {
		this.fetch_method = fetch_method;
		this.module_status_list = [];
		this.module_user_status_list = [];
		this.module_status_fields = this._get_module_status_fields();
		this.module_user_status_fields = this._get_module_user_status_fields();
		this.path_module_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status';

		this.load_status().then(() => this.on_status_loaded());
	}

	async load_status() {
		switch (this.fetch_method) {
			case MODULE_STATUS_FETCH_METHODS.JS:
				await this.fetch_JS();
				break;
			case MODULE_STATUS_FETCH_METHODS.FRAMEWORK:
			case MODULE_STATUS_FETCH_METHODS.QUERY_BUILDER:
				await this.fetch_backend();
				break;
		}
	}
	on_status_loaded() {
		//console.log(this.module_status_list);
		//console.log(this.module_user_status_list);
	}

	async fetch_JS() {
		const [module_status_data, module_user_status_data] = await Promise.all([
			frappe.db.get_list(DOCTYPES_NAMES.MODULE_STATUS, { fields: this.module_status_fields, limit: 5000 }),
			frappe.db.get_list(DOCTYPES_NAMES.MODULE_USER_STATUS, { fields: this.module_user_status_fields, limit: 5000 }),
		]);

		this.module_status_list = module_status_data;
		this.module_user_status_list = module_user_status_data;
	}

	async fetch_backend() {
		await new Promise((resolve, reject) => {
			frappe.call({
				method: `${this.path_module_status}.fetch_module_status`,
				args: {
					fetch_method: this.fetch_method,
					module_status_fields: this.module_status_fields,
					module_user_status_fields: this.module_user_status_fields,
				},
				callback: (response) => {
					if (response && response.message) {
						this.module_status_list = response.message.modules_status;
						this.module_user_status_list = response.message.modules_user_status;
						resolve(response.message);
					} else {
						reject(new Error('No data returned from backend'));
					}
				},
			});
		});
	}

	async _test_fetch_methods_performance(iterations_count) {
		for (const fetch_method of [
			MODULE_STATUS_FETCH_METHODS.JS,
			MODULE_STATUS_FETCH_METHODS.FRAMEWORK,
			MODULE_STATUS_FETCH_METHODS.QUERY_BUILDER,
		]) {
			let totalTime = 0;
			for (let i = 0; i < iterations_count; i++) {
				const start = performance.now();
				const helper = new IoiModuleStatusHelper(fetch_method);
				await helper.load_status();
				const end = performance.now();
				totalTime += end - start;
			}
			const averageTime = totalTime / iterations_count;
			console.log(`Average ellapsed time for ${fetch_method}: ${averageTime}ms`);
		}
	}

	get_module_status_name(module_name, status_id) {
		return this._get_status_property(DOCTYPES_NAMES.MODULE_STATUS, module_name, status_id, 'description');
	}

	get_module_user_status_name(module_name, status_id) {
		return this._get_status_property(DOCTYPES_NAMES.MODULE_USER_STATUS, module_name, status_id, 'description');
	}

	get_module_status_abbreviation(module_name, status_id) {
		return this._get_status_property(DOCTYPES_NAMES.MODULE_STATUS, module_name, status_id, 'abbreviation');
	}

	get_module_user_status_abbreviation(module_name, status_id) {
		return this._get_status_property(DOCTYPES_NAMES.MODULE_USER_STATUS, module_name, status_id, 'abbreviation');
	}

	get_module_status_color_font(module_name, status_id) {
		return this._get_status_property(DOCTYPES_NAMES.MODULE_STATUS, module_name, status_id, 'font_color');
	}

	get_module_user_status_color_font(module_name, status_id) {
		return this._get_status_property(DOCTYPES_NAMES.MODULE_USER_STATUS, module_name, status_id, 'font_color');
	}

	get_module_status_color_background(module_name, status_id) {
		return this._get_status_property(DOCTYPES_NAMES.MODULE_STATUS, module_name, status_id, 'background_color');
	}

	get_module_user_status_color_background(module_name, status_id) {
		return this._get_status_property(DOCTYPES_NAMES.MODULE_USER_STATUS, module_name, status_id, 'background_color');
	}

	_get_status_property(doctype_name, module_name, status_id, property) {
		const status = this._get_status_by_id(doctype_name, module_name, status_id);
		return status ? status[property] : null;
	}

	_get_status_by_id(doctype_name, module_name, status_id) {
		const statusList = doctype_name === DOCTYPES_NAMES.MODULE_STATUS ? this.module_status_list : this.module_user_status_list;
		const statusKey = doctype_name === DOCTYPES_NAMES.MODULE_STATUS ? 'ioistatus' : 'ioiuserstatus';
		return statusList.find((module) => module.identification === module_name && module[statusKey] === status_id);
	}

	_get_module_status_fields() {
		return ['name', 'description', 'abbreviation', 'identification', 'ioistatus', 'enabled', 'font_color', 'background_color'];
	}

	_get_module_user_status_fields() {
		return ['name', 'description', 'abbreviation', 'identification', 'ioiuserstatus', 'enabled', 'font_color', 'background_color'];
	}
}

silicon_ioi.ioiModuleStatusHelper = new IoiModuleStatusHelper(MODULE_STATUS_FETCH_METHODS.QUERY_BUILDER);
