frappe.provide("frappe.views");

class ioiCommunicationComposer extends frappe.views.CommunicationComposer {
	constructor(opts) {
		super(opts);
	}

	prepare(){
		this.attach_document_print = false;
		this.dialog.fields_dict.attach_document_print.$wrapper.hide();
		super.prepare();
	}
}


frappe.views.CommunicationComposer = ioiCommunicationComposer;
