frappe.provide('ioi_doc_splitter');

export const GROUP_PAGES_COLORS = ['#FF6347', '#32CD32', '#1E90FF', '#FFD700', '#8A2BE2', '#FF69B4', '#DC143C', '#00FFFF', '#FFD700', '#C71585', '#20B2AA'];

let dialog = null;
let is_in_preview_mode = false;
let is_in_group_preview_mode = false;
let current_preview_page = 0;
let current_preview_group = 0;
let preview_container, group_preview_container;
let pdf_structure = {};
let old_pdf_structure = {};
let page_urls = [];

ioi_doc_splitter.show_pdf_dialog = function (_pdf_structure) {
	old_pdf_structure = _pdf_structure;
	pdf_structure = initialize_pdf_structure(_pdf_structure);
	dialog = create_pdf_dialog();
	dialog.show();
	preview_container = dialog.fields_dict.preview.$wrapper[0];
	group_preview_container = dialog.fields_dict.group_preview.$wrapper[0];
	show_pdf_previews(URL.createObjectURL(pdf_structure.file));
	dialog.$wrapper.find('.modal-dialog').css('max-width', '40%').css('width', '40%');
	return dialog;
};

function initialize_pdf_structure(_pdf_structure) {
	return {
		..._pdf_structure,
		separator_indexes: _pdf_structure.separator_indexes || [],
		nbr_pages: _pdf_structure.nbr_pages || 0,
		pages: _pdf_structure.pages || [],
		skipped_pages: _pdf_structure.skipped_pages || [],
		groups: _pdf_structure.groups || [],
		grouped_pages: _pdf_structure.grouped_pages || [],
	};
}

ioi_doc_splitter.get_pdf_structure = function () {
	return pdf_structure;
};

function create_pdf_dialog() {
	return new frappe.ui.Dialog({
		title: __('PDF Splitter'),
		size: 'large',
		fields: [
			{ label: 'Aperçu des groupes', fieldname: 'group_preview', fieldtype: 'HTML' },
			{ label: 'Aperçu des pages', fieldname: 'preview', fieldtype: 'HTML' },
		],
		primary_action_label: __('Split'),
		primary_action: () => {
			process_pdf_splitting();
			dialog.hide();
		},
		secondary_action_label: __('Cancel'),
		secondary_action: () => process_cancel(),
		onhide: () => {},
	});
}

function process_pdf_splitting() {
	update_groups();

	const preview_container = dialog.fields_dict.preview.$wrapper[0];

	const separator_indexes_set = get_separator_indexes(preview_container);
	const selected_pages = get_selected_pages(preview_container);
	const all_pages = get_all_pages(preview_container);

	pdf_structure.separator_indexes = [...separator_indexes_set];
	pdf_structure.pages = all_pages;
	pdf_structure.skipped_pages = all_pages.filter((page) => !selected_pages.includes(page));
	pdf_structure.grouped_pages = group_pages(all_pages, separator_indexes_set);
}

function get_all_pages(preview_container) {
	return [...preview_container.querySelectorAll('.page-checkbox')].map((checkbox) => parseInt(checkbox.dataset.page, 10));
}

function get_selected_pages(preview_container) {
	return [...preview_container.querySelectorAll('.page-checkbox:checked')].map((checkbox) => parseInt(checkbox.dataset.page, 10));
}

function get_separator_indexes(preview_container) {
	return new Set(
		[...preview_container.querySelectorAll('.separator')]
			.map((separator, index) => (separator.getAttribute('data-toggled') === 'true' ? index : -1))
			.filter((index) => index !== -1)
	);
}

function process_cancel() {
	pdf_structure = old_pdf_structure;
	dialog.hide();
}

function group_pages(pages, separator_indexes_set) {
	return pages.reduce((groups, page, index) => {
		if (!groups.length || separator_indexes_set.has(index - 1)) {
			groups.push([]);
		}
		groups[groups.length - 1].push(page);
		return groups;
	}, []);
}

show_pdf_previews = async function (file_url) {
	await load_pdf_js();
	const loading_task = pdfjsLib.getDocument(file_url);
	loading_task.promise.then(
		function (pdf) {
			let preview_html = '';
			let page_promises = [];
			pdf_structure.nbr_pages = pdf.numPages;
			let pages =
				pdf_structure.grouped_pages.length > 0
					? pdf_structure.grouped_pages.flat()
					: Array.from({ length: pdf_structure.nbr_pages }, (_, i) => i + 1);
			pages.forEach((page_num) => {
				const page_promise = pdf.getPage(page_num).then(function (page) {
					const viewport = page.getViewport({ scale: 1.0 });
					const canvas = document.createElement('canvas');
					const context = canvas.getContext('2d');
					canvas.width = viewport.width;
					canvas.height = viewport.height;
					let checked = !is_page_skipped(page_num);

					return page.render({ canvasContext: context, viewport: viewport }).promise.then(function () {
						let page_html = `
							<div class="page-group" data-page="${page_num}" style="display: flex; flex-direction: row; align-items: center; justify-content:center; opacity: ${
							checked ? '1' : '0.4'
						};">
								<input type="checkbox" class="page-checkbox" data-page="${page_num}" ${
							checked ? 'checked' : ''
						} style="margin: 10px; height: 30px; width: 30px !important;">

								<div class="pdf-page-container" style="position: relative; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); border-radius: 5px; padding: 10px; cursor: zoom-in;"
									data-url="${canvas.toDataURL()}" data-page="${page_num}">
									<img src="${canvas.toDataURL()}" style="height: 450px; width: auto; border-radius: 5px;">
								</div>
							</div>
						`;
						return page_html;
					});
				});
				page_promises.push(page_promise);
			});

			preview_container.innerHTML = preview_html;
			preview_container.style.display = 'flex';
			preview_container.style.flexDirection = 'column';
			preview_container.style.alignItems = 'center';
			preview_container.style.overflowY = 'auto';
			preview_container.style.padding = '10px';
			preview_container.innerHTML = `
			<div class="spinner-border" role="status" style="margin: 50px;">
				<span class="sr-only"/>
			</div>`;

			Promise.all(page_promises).then(function (pages_html) {
				preview_container.innerHTML = pages_html.join('');

				preview_container.querySelectorAll('.page-checkbox').forEach((checkbox) => {
					checkbox.addEventListener('change', function () {
						const pageContainer = this.closest('.page-group');
						pageContainer.style.opacity = this.checked ? '1' : '0.4';
					});
				});

				preview_container.querySelectorAll('.separator').forEach(function (separator) {
					separator.addEventListener('click', function () {
						toggle_separator_state(separator, preview_container);
						process_pdf_splitting();
					});
				});

				page_urls = pages_html.map((html) => {
					const parser = new DOMParser();
					const doc = parser.parseFromString(html, 'text/html');
					return doc.querySelector('.pdf-page-container').getAttribute('data-url');
				});

				new Sortable(preview_container, {
					handle: '.pdf-page-container',
					filter: '.separator, .page-checkbox',
					animation: 150,
					onEnd: (evt) => {
						update_groups();
						update_group_preview();
						apply_group_color_filters(preview_container);
						add_separators();
						process_pdf_splitting();
					},
				});

				add_separators(pdf_structure.separator_indexes);
				update_groups();
				update_group_preview();
				apply_group_color_filters(preview_container);
				create_enlarged_page_preview_container();
			});
		},
		function (error) {
			frappe.msgprint(__('Error loading PDF file.'));
			frappe.hide_progress();
		}
	);
};

function add_separators(_separator_indexes = []) {
	preview_container.querySelectorAll('.separator').forEach((separator, index) => {
		if (separator.dataset.toggled === 'true') {
			_separator_indexes.push(index);
		}
		separator.remove();
	});

	preview_container.querySelectorAll('.separator').forEach((separator) => separator.remove());

	let pageContainers = preview_container.querySelectorAll('.page-group');
	let lastPage = pageContainers.length - 1;

	pageContainers.forEach((page_container, idx) => {
		if (idx === lastPage) return;
		let separator = document.createElement('div');
		let toggled = _separator_indexes.includes(idx);
		separator.className = 'separator';
		separator.style.cssText = `width: 500px; height: 10px; margin:15px; cursor: pointer; border-radius: 5px;${
			toggled ? 'background-color: #525252;' : 'background-color: #ededed;'
		}`;
		separator.setAttribute('data-toggled', toggled ? 'true' : 'false');
		page_container.insertAdjacentElement('afterend', separator);
	});

	const separators = preview_container.querySelectorAll('.separator');
	separators.forEach(function (separator) {
		separator.addEventListener('click', function () {
			toggle_separator_state(separator, preview_container);
			process_pdf_splitting();
		});
	});
}

function create_group_preview_HTML(group, idx) {
	const color = group ? group.color : '#dedede';
	const startPage = group ? group.start_page : 1;
	const endPage = group ? group.end_page : pdf_structure.nbr_pages;

	return `<span class="group-preview" data-group-index=${idx} style="
		background-color: ${color}75;
		padding: 10px;
		margin-right: 10px;
		border-radius: 5px;
		cursor: pointer;
		transition: background-color 0.3s ease;">
		[${startPage}-${endPage}]
	</span>`;
}

async function render_group_preview(groupPreviewPages) {
	const loadingTask = pdfjsLib.getDocument(URL.createObjectURL(pdf_structure.file));
	const pdf = await loadingTask.promise;

	const pagePromises = groupPreviewPages.map(async (pageNum) => {
		const page = await pdf.getPage(pageNum);
		const viewport = page.getViewport({ scale: 0.7 });
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		canvas.width = viewport.width;
		canvas.height = viewport.height;

		await page.render({ canvasContext: context, viewport }).promise;
		return canvas.toDataURL();
	});

	return await Promise.all(pagePromises);
}

async function update_group_preview() {

	Object.assign(group_preview_container.style, {
		display: 'flex',
		overflowY: 'scroll',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '5px',
		marginBottom: '10px',
		borderBottom: '3px solid #ccc',
		width: '100%',
	});

	group_preview_container.innerHTML =
		pdf_structure.groups.length > 1
			? pdf_structure.groups.map((group, index) => create_group_preview_HTML(group, index)).join('')
			: create_group_preview_HTML(null, 0);

	document.querySelectorAll('.group-preview').forEach((group_preview, idx) => {
		group_preview.addEventListener('click', async () => {
			const group_index = group_preview.getAttribute('data-group-index');
			if (group_index === null) return;

			current_preview_group = parseInt(group_index, 10);

			const group_preview_pages = pdf_structure.grouped_pages[group_index];
			if (!group_preview_pages || group_preview_pages.length === 0) return;

			const pagesUrls = await render_group_preview(group_preview_pages);

			const enlarged_preview_container = document.getElementById('enlarged-preview-container');
			const overlay = document.getElementById('enlarged-preview-overlay');
			is_in_group_preview_mode = true;

			enlarged_preview_container.innerHTML = pagesUrls
				.map(
					(url, idx) =>
						`<img src="${url}" style="box-shadow: 1px 1px 5px rgba(0,0,0,0.5); margin:15px; ${
							is_page_skipped(idx + 1) ? 'opacity: 0.4;' : 'opacity: 1;'
						}">`
				)
				.join('');

			if (current_preview_group > 0) enlarged_preview_container.appendChild(create_arrow('left', true));
			if (current_preview_group < pdf_structure.groups.length - 1) enlarged_preview_container.appendChild(create_arrow('right', true));

			enlarged_preview_container.style.display = 'flex';
			overlay.style.display = 'block';
		});
	});
}

async function update_group_preview_container() {
	const group_preview_pages = pdf_structure.grouped_pages[current_preview_group];
	if (!group_preview_pages || group_preview_pages.length === 0) return;

	const pagesUrls = await render_group_preview(group_preview_pages);

	const enlarged_preview_container = document.getElementById('enlarged-preview-container');
	const overlay = document.getElementById('enlarged-preview-overlay');

	enlarged_preview_container.innerHTML = pagesUrls
		.map(
			(url, idx) =>
				`<img src="${url}" style="box-shadow: 1px 1px 5px rgba(0,0,0,0.5); margin:15px; ${
					is_page_skipped(idx + 1) ? 'opacity: 0.4;' : 'opacity: 1;'
				}">`
		)
		.join('');

	if (current_preview_group > 0) enlarged_preview_container.appendChild(create_arrow('left', true));
	if (current_preview_group < pdf_structure.groups.length - 1) enlarged_preview_container.appendChild(create_arrow('right', true));

	enlarged_preview_container.style.display = 'flex';
	overlay.style.display = 'block';
}

function handle_key_down_group_preview(e) {
	if (!is_in_group_preview_mode) return;

	if (e.key === 'ArrowRight' && current_preview_group < pdf_structure.groups.length - 1) {
		current_preview_group++;
		update_group_preview_container();
	} else if (e.key === 'ArrowLeft' && current_preview_group > 0) {
		current_preview_group--;
		update_group_preview_container();
	} else if (e.key === 'Escape') {
		close_preview_mode();
	}
}

function apply_group_color_filters(preview_container) {
	preview_container.querySelectorAll('.pdf-page-container').forEach((page_container) => {
		page_container.querySelector('.overlay')?.remove();
	});

	if (pdf_structure.groups.length <= 1) return;

	preview_container.querySelectorAll('.pdf-page-container').forEach((page_container, index) => {
		const group = pdf_structure.groups.find((group) => index + 1 >= group.start_page && index + 1 <= group.end_page);
		if (!group) return;

		page_container.style.position = 'relative';

		const overlay = document.createElement('div');
		overlay.classList.add('overlay');
		overlay.style.cssText = `
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: ${group.color};
			opacity: 0.15;
		`;
		page_container.appendChild(overlay);
	});
}

function toggle_separator_state(separator, preview_container) {
	const toggled = separator.getAttribute('data-toggled') === 'true';
	separator.setAttribute('data-toggled', toggled ? 'false' : 'true');
	separator.style.backgroundColor = toggled ? '#ededed' : '#525252';
	update_groups();
	update_group_preview(dialog.fields_dict.group_preview.$wrapper[0]);
	apply_group_color_filters(preview_container);
}

function update_groups() {
	pdf_structure.groups = [];
	let current_group = [];
	let color_index = 0;

	current_group.push(1);

	dialog.fields_dict.preview.$wrapper[0].querySelectorAll('.separator').forEach((separator, index) => {
		if (separator.getAttribute('data-toggled') === 'true') {
			pdf_structure.groups.push({
				start_page: current_group[0],
				end_page: current_group[current_group.length - 1],
				color: GROUP_PAGES_COLORS[color_index % GROUP_PAGES_COLORS.length],
			});

			current_group = [];
			color_index++;
		}

		current_group.push(index + 2);
	});

	if (current_group.length > 0) {
		pdf_structure.groups.push({
			start_page: current_group[0],
			end_page: current_group[current_group.length - 1],
			color: GROUP_PAGES_COLORS[color_index % GROUP_PAGES_COLORS.length],
		});
	}
}

function create_enlarged_page_preview_container() {
	$('#enlarged-preview-overlay')?.remove();
	let overlay = document.createElement('div');
	overlay.className = 'enlarged-preview-overlay';
	overlay.id = 'enlarged-preview-overlay';
	overlay.style.cssText = `
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 9998;
	`;

	$('#enlarged-preview-container')?.remove();
	let enlarged_preview_container = document.createElement('div');
	enlarged_preview_container.className = 'enlarged-preview-container';
	enlarged_preview_container.id = 'enlarged-preview-container';
	enlarged_preview_container.style.cssText = `
		display: none;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
		background-color: white;
		padding: 20px;
		border: 2px solid #ccc;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 1);
		overflow: auto;
	`;

	document.body.appendChild(enlarged_preview_container);
	document.body.appendChild(overlay);

	overlay.addEventListener('click', function () {
		enlarged_preview_container.style.display = 'none';
		overlay.style.display = 'none';
		is_in_preview_mode = false;
	});

	setTimeout(() => {

		document.querySelectorAll('.pdf-page-container').forEach((page_group) => {
			page_group.addEventListener('click', function () {

				process_pdf_splitting();

				const enlarged_preview_container = document.getElementById('enlarged-preview-container');
				const overlay = document.getElementById('enlarged-preview-overlay');
				if (!enlarged_preview_container || !overlay) return;

				const page_index = pdf_structure.pages.indexOf(parseInt(page_group.getAttribute('data-page'), 10));
				if (page_index === -1) return;

				update_pdf_preview_container(page_index);
				enlarged_preview_container.style.display = 'flex';
				overlay.style.display = 'block';
				is_in_preview_mode = true;
			});
		});
	}, 500);

	document.removeEventListener('keydown', handle_key_down_page_preview);
	document.addEventListener('keydown', handle_key_down_page_preview);

	document.removeEventListener('keydown', handle_key_down_group_preview);
	document.addEventListener('keydown', handle_key_down_group_preview);

}

function update_pdf_preview_container(page_index) {
	if (page_index < 0 || page_index >= page_urls.length) return;

	current_preview_page = page_index;
	const enlarged_preview_container = document.getElementById('enlarged-preview-container');
	enlarged_preview_container.innerHTML = get_inner_pdf_preview_html(page_urls[pdf_structure.pages[page_index] - 1], pdf_structure.pages[page_index]);
	enlarged_preview_container.appendChild(create_preview_cross());

	update_navigation_arrows(enlarged_preview_container, page_index);
}

function update_navigation_arrows(container, page_index) {
	if (page_index > 0) container.appendChild(create_arrow('left'));
	if (page_index < pdf_structure.nbr_pages - 1) container.appendChild(create_arrow('right'));
}

function handle_key_down_page_preview(e) {
	if (!is_in_preview_mode) return;
	if (e.key === 'ArrowRight' && current_preview_page < pdf_structure.nbr_pages - 1) {
		update_pdf_preview_container(current_preview_page + 1);
	} else if (e.key === 'ArrowLeft' && current_preview_page > 0) {
		update_pdf_preview_container(current_preview_page - 1);
	} else if (e.key === 'Escape') {
		close_preview_mode();
	}
}

function close_preview_mode() {
	document.getElementById('enlarged-preview-container').style.display = 'none';
	document.getElementById('enlarged-preview-overlay').style.display = 'none';
	is_in_preview_mode = false;
	is_in_group_preview_mode = false;
}

function get_inner_pdf_preview_html(page_url, page_number) {
	return `<img src="${page_url}" alt="Preview" style="${is_page_skipped(page_number) ? 'opacity: 0.4;' : 'opacity: 1;'}">`;
}

function create_preview_title(page_number) {
	let title = document.createElement('h3');
	title.innerHTML = `Page ${page_number}`;
	title.style.cssText = `
		text-decoration: ${is_page_skipped(page_number) ? 'line-through' : 'none'};
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		background: rgba(0, 0, 0, 0.5);
		color: white;
		border: none;
		font-size: 24px;
		padding: 10px;
	`;
	return title;
}

function create_preview_cross() {
	let cross = document.createElement('button');
	cross.innerHTML = '✖';
	cross.className = 'nav-cross';
	cross.style.cssText = `
		position: absolute;
		top: 10px;
		right: 10px;
		background: rgba(0, 0, 0, 0.5);
		color: white;
		border: none;
		font-size: 24px;
		padding: 10px;
		cursor: pointer;
	`;
	cross.addEventListener('click', function () {
		close_preview_mode();
	});
	return cross;
}

const is_page_skipped = (page_num) => pdf_structure.skipped_pages.includes(page_num);

function create_arrow(direction, is_group_arrow = false) {
	let arrow = document.createElement('button');
	arrow.innerHTML = direction === 'left' ? '◀' : '▶';
	arrow.className = `nav-arrow ${direction}-arrow`;
	arrow.style.cssText = `
		position: absolute;
		${direction === 'left' ? 'left' : 'right'}: 10px;
		top: 50%;
		transform: translateY(-50%);
		background: rgba(0, 0, 0, 0.5);
		color: white;
		border: none;
		font-size: 24px;
		padding: 10px;
		cursor: pointer;
	`;

	if (is_group_arrow) {
		arrow.addEventListener('click', function () {
			handle_key_down_group_preview({ key: direction === 'left' ? 'ArrowLeft' : 'ArrowRight' });
		});
		return arrow;
	}

	arrow.addEventListener('click', function () {
		handle_key_down_page_preview({ key: direction === 'left' ? 'ArrowLeft' : 'ArrowRight' });
	});

	return arrow;
}

async function load_pdf_js() {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.min.js';
		script.onload = () => resolve(pdfjsLib);
		script.onerror = reject;
		document.head.appendChild(script);
	});
}

document.addEventListener('DOMContentLoaded', () => {
	document.body.insertAdjacentHTML(
		'beforeend',
		`
		<style>
			.group-preview:hover {
				background-color: rgba(0, 0, 0, 0.1) !important;
			}
			.pdf-page-container:hover {
				background-color: rgba(0, 0, 0, 0.1) !important;
			}
			.nav-arrow:hover {
				color: black !important;
				transform: scale(1.1);
				transition: opacity 0.3s ease, transform 0.3s ease;
			}
			.nav-cross {
				color: white !important;
				transition: opacity 0.3s ease, transform
			}
			.nav-cross:hover {
				color: #FF7F7F !important;
				transform: scale(1.1);
				transition: opacity 0.3s ease, transform 0.3s ease;
			}
			.nav-arrow-hover-effect {
				color: black !important;
				transform: scale(1.1);
				transition: opacity 0.3s ease, transform 0.3s ease;
			}
		</style>
	`
	);
});
