class ioiAttachments extends frappe.ui.form.Attachments {
	remove_attachment(fileid, callback) {
		super.remove_attachment(fileid, callback).then((r) => {
			if (document.getElementById('file_bt_search')) {
				document.getElementById('file_bt_search').click();
			}
		});
	}
	attachment_uploaded(attachments) {
		this.dialog && this.dialog.hide();
		Array.of(attachments).forEach((attachment) => {
			this.update_attachment(attachment);
			this.frm.sidebar.reload_docinfo();

			if (this.fieldname) {
				this.frm.set_value(this.fieldname, attachment.file_url);
			}
		});
	}
}

$(document).ready(() => {
	frappe.ui.form.Attachments = ioiAttachments;
});
