
	frappe.templates['ioi_event_popup'] = `<article class="evp-root">
	<div data-key="topborder" style="border-radius: 13px 13px 0px 0px; height: 1vh;"></div>

	<div class="evp-toolbar" >

		<div class="evp-subject" data-key="subject" role="heading" aria-level="1"></div>

		<button data-action="todo" type="button" class="btn-reset evp-btn">
			{{ frappe.utils.icon("milestone", "md") }}
		</button>
		<a data-action="edit" role="button" type="button" class="btn-reset evp-btn">
			{{ frappe.utils.icon("edit", "md") }}
		</a>
		<button data-action="delete" type="button" class="btn-reset evp-btn">
			{{ frappe.utils.icon("delete", "md") }}
		</button>
		<div></div>
		<button data-action="close" type="button" class="btn-reset evp-btn">
			{{ frappe.utils.icon("close", "md") }}
		</button>
	</div>
	<div class="evp-scroller">

		<div class="evp-row" style="display: flex; align-items: center; justify-content: flex-start;">
			<div>
				{{ frappe.utils.icon("calendar", "md") }}
			</div>
			<div class="evp-content" data-key="timestamp"></div>
		</div>

		<div class="evp-row" hidden>
			<div>
				{{ frappe.utils.icon("list-alt", "md") }}
			</div>
		</div>

		<div class="evp-row" hidden>
			<div>
				{{ frappe.utils.icon("text", "md") }}
			</div>
			<div class="evp-content" data-key="description"></div>
		</div>

		<div class="evp-row" hidden>
			<div>
				{{ frappe.utils.icon("attachment", "md") }}
			</div>
			<div>
				<div class="evp-content" data-key="links">
				</div>
			</div>
		</div>


		<template hidden data-key="fields"></template>
	</div>
</article>
`;
