frappe.provide('silicon_ioi.doctype');

export class ioiSalesToPurchases
{
	static path_common = 'silicon_ioi.common.common';
	static path_sales_to_purchases = 'silicon_ioi.common.sales_to_purchases';
	static path_purchases_proposals = 'silicon_ioi.ioi_purchases.doctype.ioi_purchases_proposals.ioi_purchases_proposals'

    static is_dark_mode = 0;
	static bgcolor = 0;
	static sales_order_name = null;
	static direct_order_grid_width = 0;
	static default_purchases_journal_id = '';
	static default_warehouse_id = '';

	static purchases_journal = []
	static force_refresh_po_for_supplier = false

	static load_config()
	{
		silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		silicon_ioi.doctype.ioiSalesToPurchases.default_purchases_journal_id = '';
		silicon_ioi.doctype.ioiSalesToPurchases.default_warehouse_id = '';

		let method = silicon_ioi.doctype.ioiSalesToPurchases.path_purchases_proposals + '.ioi_purchases_proposals_get_default_purchases_journal_whs';

		frappe.call({  	method: method,
						args: { },
						async: false,
						callback:function(r)	{
							silicon_ioi.doctype.ioiSalesToPurchases.default_purchases_journal_id = r.message.default_purchases_journal_id;
							silicon_ioi.doctype.ioiSalesToPurchases.default_warehouse_id = r.message.default_warehouse_id;

						}
		});



	}

	static direct_order(sales_order_id = null)
	{
		silicon_ioi.doctype.ioiSalesToPurchases.load_config();

		silicon_ioi.doctype.ioiSalesToPurchases.sales_order_name = null;

		if ((sales_order_id != null) && (sales_order_id != '')) {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_name = sales_order_id;
		}

		silicon_ioi.doctype.ioiSalesToPurchases.build_screen();
	}

	static build_screen()
	{
		if (document.getElementById('sales_to_order_direct_order_grid_content')) {
			document.getElementById('sales_to_order_direct_order_grid_content').remove();
		}

		let title_dialog = __('Direct purchase');

		if (silicon_ioi.doctype.ioiSalesToPurchases.sales_order_name != null) {
			title_dialog += ' : ' + silicon_ioi.doctype.ioiSalesToPurchases.sales_order_name;
		}

		for (var i = 0; i < 10; i++) {
			title_dialog += '&nbsp;'

		}

		title_dialog += '<font color="blue">(' + __("Purchase can be automated with the JIT engine") + ')</font>';

		var direct_order_dialog = new frappe.ui.Dialog({
			'title': title_dialog,
			'fields': [
				{'fieldname': 'html_sales_to_order_direct_order', 'fieldtype': 'HTML'}

			],
			primary_action_label: __('Close'),
			primary_action: function(){
				direct_order_dialog.hide();
				window.location.reload();
			}
		});

		let html = '';

		html += silicon_ioi.doctype.ioiSalesToPurchases.build_sales_order_filters_area();

		html += '<div id="sales_to_order_direct_order_grid_content" style="overflow-x: auto;height:440px;">';

		html += silicon_ioi.doctype.ioiSalesToPurchases.build_sales_order_grid_header();
		html += silicon_ioi.doctype.ioiSalesToPurchases.build_sales_order_grid_empty_row();

		html += '</div>';

		html += silicon_ioi.doctype.ioiSalesToPurchases.build_sales_order_action_buttons();
		html += silicon_ioi.doctype.ioiSalesToPurchases.build_sales_order_tabs();



		direct_order_dialog.fields_dict.html_sales_to_order_direct_order.$wrapper.html(html);

		direct_order_dialog.$wrapper.find('.modal-dialog').css("max-width", "100%").css("width", "100%");
		direct_order_dialog.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");
		direct_order_dialog.show();


		silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {

			silicon_ioi.doctype.ioiSalesToPurchases.build_po_for_item();
			silicon_ioi.doctype.ioiSalesToPurchases.build_po_for_supplier();
			silicon_ioi.doctype.ioiSalesToPurchases.build_po_for_supplier_form_header();
			silicon_ioi.doctype.ioiSalesToPurchases.build_purchases_prices();

			silicon_ioi.doctype.ioiSalesToPurchases.build_sales_order_assign_events();
			silicon_ioi.doctype.ioiSalesToPurchases.refresh_sales_order();
		});




	}

	// ****************************************************************************************************************************************************************
	// Sales Orders area
	// ****************************************************************************************************************************************************************
	static build_sales_order_filters_area()
	{
		if (document.getElementById('sales_to_order_direct_order_filters_panel_filters')) {
			document.getElementById('sales_to_order_direct_order_filters_panel_filters').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_panel_filters_title')) {
			document.getElementById('sales_to_order_direct_order_filters_panel_filters_title').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters')) {
			document.getElementById('sales_to_order_direct_order_filters').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_filter_label')) {
			document.getElementById('sales_to_order_direct_order_filters_filter_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_filter')) {
			document.getElementById('sales_to_order_direct_order_filters_filter').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_item_label')) {
			document.getElementById('sales_to_order_direct_order_filters_item_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_item_id')) {
			document.getElementById('sales_to_order_direct_order_filters_item_id').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_item_button')) {
			document.getElementById('sales_to_order_direct_order_filters_item_button').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_manufactured_label')) {
			document.getElementById('sales_to_order_direct_order_filters_manufactured_label').remove();
		}
		if (document.getElementById('sales_to_order_direct_order_filters_manufactured')) {
			document.getElementById('sales_to_order_direct_order_filters_manufactured').remove();
		}
		if (document.getElementById('sales_to_order_direct_order_filters_subcontracted_label')) {
			document.getElementById('sales_to_order_direct_order_filters_subcontracted_label').remove();
		}
		if (document.getElementById('sales_to_order_direct_order_filters_subcontracted')) {
			document.getElementById('sales_to_order_direct_order_filters_subcontracted').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_supplier_label')) {
			document.getElementById('sales_to_order_direct_order_filters_supplier_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_supplier_id')) {
			document.getElementById('sales_to_order_direct_order_filters_supplier_id').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_supplier_button')) {
			document.getElementById('sales_to_order_direct_order_filters_supplier_button').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_search_label')) {
			document.getElementById('sales_to_order_direct_order_filters_search_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_search')) {
			document.getElementById('sales_to_order_direct_order_filters_search').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_filters_button_search')) {
			document.getElementById('sales_to_order_direct_order_filters_button_search').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_button_clear')) {
			document.getElementById('sales_to_order_direct_order_filters_button_clear').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_filters_info_label')) {
			document.getElementById('sales_to_order_direct_order_filters_info_label').remove();
		}




		let html = '';


		html += '<div id="sales_to_order_direct_order_filters_panel_filters" data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="sales_to_order_direct_order_filters_panel_filters_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Filters") + '</b></label>';
		html += '</div>'


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'


		html += '<div id="sales_to_order_direct_order_filters" style="overflow-x: auto;height:75px;">';

		// Filter
		html += '	<div style="position: relative; top: 0px; left: 0px; width:280px;">';
		html += '		<label id="sales_to_order_direct_order_filters_filter_label" style="position: absolute; top: 0px; left: 2px;">' + __("Filter") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 280px; height: 30px;"> ';
		html += '		<select id="sales_to_order_direct_order_filters_filter" class="input-with-feedback form-control bold" style="width:280px"> ';
		html += '			<option value="-1">' + __("All") + '</option> ';
		html += '			<option value="0">' + __("Only To order") + '</option> ';
		html += '			<option value="1">' + __("Only missing on available") + '</option> ';
		html += '			<option value="2">' + __("Only missing on Scheduled available") + '</option> ';
		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';

		// Item
		html += '	<div style="position: relative; top: 0px; left: 290px; width:220px;">';
		html += '		<label id="sales_to_order_direct_order_filters_item_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Item") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="sales_to_order_direct_order_filters_item_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 230px; width: 30px; height: 25px;"> ';
		html +='			<button id="sales_to_order_direct_order_filters_item_button" style="position:absolute; height: 30px; width: 30px; z-index:10;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Manufactured
		html += '	<div style="position: relative; top: 20px; left: 560px; width:170px;">';

		html += '		<div class="control-input" style="position: absolute; top: 4px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="sales_to_order_direct_order_filters_manufactured" type="checkbox" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';

		html += '		<label id="sales_to_order_direct_order_filters_manufactured_label" style="position: absolute; top: 2px; left: 25px;z-index:5;">' + __("Show to manufacture") + '</label>';
		html += '	</div>';


		// Subcontracted
		html += '	<div style="position: relative; top: 40px; left: 560px; width:170px;">';

		html += '		<div class="control-input" style="position: absolute; top: 4px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="sales_to_order_direct_order_filters_subcontracted" type="checkbox" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';

		html += '		<label id="sales_to_order_direct_order_filters_subcontracted_label" style="position: absolute; top: 2px; left: 25px;z-index:5;">' + __("Show to sub-contract") + '</label>';
		html += '	</div>';

		// Supplier
		html += '	<div style="position: relative; top: 0px; left: 740px; width:170px;">';
		html += '		<label id="sales_to_order_direct_order_filters_supplier_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Supplier") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';
		html += '			<input id="sales_to_order_direct_order_filters_supplier_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 180px; width: 30px; height: 25px;"> ';
		html +='			<button id="sales_to_order_direct_order_filters_supplier_button" style="position:absolute; height: 30px; width: 30px; z-index:10;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Search Value
		html += '	<div style="position: relative; top: 0px; left: 960px; width:240px;">';
		html += '		<label id="sales_to_order_direct_order_filters_search_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 240px; height: 25px;"> ';
		html += '			<input id="sales_to_order_direct_order_filters_search" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';


		// Search
		html += '	<div style="position: relative; top: 25px; left: 1210px; width:110px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_filters_button_search" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Search") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Clear
		html += '	<div style="position: relative; top: 25px; left: 1330px; width:50px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='			<button id="sales_to_order_direct_order_filters_button_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		html += '	<div style="position: relative; top: 30px; left: 1420px; width:500px;">';
		html += '		<label id="sales_to_order_direct_order_filters_info_label" style="position: absolute; top: 0px; left: 2px;z-index:5;color:blue;">' + __("Purch. Price auto filled if marked ‘prefered’") + '</label>';
		html += '	</div>';



		html += '</div>';

		return html;
	}

	static build_sales_order_grid_header()
	{
		if (document.getElementById('sales_to_order_direct_order_grid_header')) {
			document.getElementById('sales_to_order_direct_order_grid_header').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_grid_header_check_all_none')) {
			document.getElementById('sales_to_order_direct_order_grid_header_check_all_none').remove();
		}


		let html = '';

		if (silicon_ioi.doctype.ioiSalesToPurchases.sales_order_name == null) {
			silicon_ioi.doctype.ioiSalesToPurchases.direct_order_grid_width = 3585;
		}else{
			silicon_ioi.doctype.ioiSalesToPurchases.direct_order_grid_width = 3325;
		}


		html += '<table id="sales_to_order_direct_order_grid_header" width=' + silicon_ioi.doctype.ioiSalesToPurchases.direct_order_grid_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB" data-custom-grid="true">';
		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">';
		html += '<input type="checkbox" id="sales_to_order_direct_order_grid_header_check_all_none" style="postion:absolute; top: 2px; left: 2px;" checked ';
		html += '       onclick="  ';
		html += '					for (var i = 0; i < document.getElementById(\'sales_to_order_direct_order_grid_detail\').rows.length; i++) ';
		html += '					{	';
		html += '						if (document.getElementById(\'sales_to_order_direct_order_grid_detail_checked_id_\' + i.toString())) { '
		html += '							document.getElementById(\'sales_to_order_direct_order_grid_detail_checked_id_\' + i.toString()).checked = this.checked; ';
		html += '						} ';
		html += '					} ';
		html += '                " ';
		html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
		html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
		html += '>';
		html += '</td>';



		if (silicon_ioi.doctype.ioiSalesToPurchases.sales_order_name == null) {
			html += '<td width=160px style="vertical-align: middle;"><b>&nbsp;' + __("Order") 				+ '</b></td>';
		}
		html += '<td width=60px  style="vertical-align: middle;"><b>&nbsp;' + __("No") 						+ '</b></td>';
		html += '<td width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Item") 					+ '</b></td>';
		html += '<td width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Manufacturer ref")		+ '</b></td>';
		html += '<td width=110px style="vertical-align: middle;"><b>&nbsp;' + __("To deliver") 				+ '</b></td>';
		html += '<td width=110px style="vertical-align: middle;"><b>&nbsp;' + __("Required") 				+ '</b></td>';
		html += '<td width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Unit") 					+ '</b></td>';
		html += '<td width=110px style="vertical-align: middle;"><b>&nbsp;' + __("In stock") 				+ '</b></td>';
		html += '<td width=110px style="vertical-align: middle;"><b>&nbsp;' + __("Stock Unit") 				+ '</b></td>';
		html += '<td width=110px style="vertical-align: middle;"><b>&nbsp;' + __("Available") 				+ '</b></td>';

		html += '<td width=110px style="vertical-align: middle;"><b>&nbsp;' + __("Sched. Avail.")			+ '</b></td>';
		html += '<td width=110px style="vertical-align: middle;"><b>&nbsp;' + __("To order") 				+ '</b></td>';
		html += '<td width=130px style="vertical-align: middle;"><b>&nbsp;' + __("Pur. To order") 			+ '</b></td>';
		html += '<td width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Pur. Unit")				+ '</b></td>';
		html += '<td width=30px  style="vertical-align: middle;">&nbsp;</td>';

		html += '<td width=180px style="vertical-align: middle;"><b>&nbsp;' + __("Supplier") 				+ '</b></td>';
		html += '<td width=30px  style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=60px style="vertical-align: middle;"><b>&nbsp;' + __("Price ?") 					+ '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Price exists ?")			+ '</b></td>';


		html += '<td width=30px  style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Pur. price") 				+ '</b></td>';
		html += '<td width=60px style="vertical-align: middle;"><b>&nbsp;' + __("Curr.")					+ '</b></td>';
		html += '<td width=130px style="vertical-align: middle;"><b>&nbsp;' + __("Sched. mode")				+ '</b></td>';
		html += '<td width=350px style="vertical-align: middle;"><b>&nbsp;' + __("Item description")		+ '</b></td>';
		html += '<td width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Item delay") 				+ '</b></td>';
		html += '<td width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Supplier delay")			+ '</b></td>';
		html += '<td width=145px style="vertical-align: middle;"><b>&nbsp;' + __("Sch. del. date") 		 	+ '</b></td>';
		html += '<td width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Avail. calc datetime") 	+ '</b></td>';
		html += '<td width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Min. order qty") 			+ '</b></td>';
		html += '</tr>';
		html += '</table>';

		return html;

	}

	static build_sales_order_grid_empty_row()
	{
		silicon_ioi.doctype.ioiSalesToPurchases.remove_sales_order_grid();

		let html = '';

		html += '<table id="sales_to_order_direct_order_grid_detail" width=' + silicon_ioi.doctype.ioiSalesToPurchases.direct_order_grid_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB">';
		html += '<tr style="height:30px" style="display:table-row;">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';

		if (silicon_ioi.doctype.ioiSalesToPurchases.sales_order_name == null) {
			html += '<td width=160px style="vertical-align: middle;">&nbsp;</td>';
		}
		html += '<td width=60px  style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';

		html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';

		html += '<td width=130px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=30px  style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=180px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=30px  style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=60px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=30px  style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=60px style="vertical-align: middle;">&nbsp;</td>';

		html += '<td width=130px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=145px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';

		return html;
	}

	static build_sales_order_action_buttons()
	{
		if (document.getElementById('sales_to_order_direct_order_action_button_select_all')) {
			document.getElementById('sales_to_order_direct_order_action_button_select_all').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_action_button_select_none')) {
			document.getElementById('sales_to_order_direct_order_action_button_select_none').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_action_button_fill_with_required')) {
			document.getElementById('sales_to_order_direct_order_action_button_fill_with_required').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_action_button_fill_with_missing_available')) {
			document.getElementById('sales_to_order_direct_order_action_button_fill_with_missing_available').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_action_button_fill_with_missing_scheduled_available')) {
			document.getElementById('sales_to_order_direct_order_action_button_fill_with_missing_scheduled_available').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_action_button_fill_with_a_supplier')) {
			document.getElementById('sales_to_order_direct_order_action_button_fill_with_a_supplier').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_action_button_update_sched_delivery_datetime')) {
			document.getElementById('sales_to_order_direct_order_action_button_update_sched_delivery_datetime').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_action_button_update_delivery_datetime')) {
			document.getElementById('sales_to_order_direct_order_action_button_update_delivery_datetime').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_action_button_clear_toorder')) {
			document.getElementById('sales_to_order_direct_order_action_button_clear_toorder').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_action_button_clear_supplier')) {
			document.getElementById('sales_to_order_direct_order_action_button_clear_supplier').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_action_button_create_po')) {
			document.getElementById('sales_to_order_direct_order_action_button_create_po').remove();
		}		

		if (document.getElementById('sales_to_order_direct_order_action_button_create_pp')) {
			document.getElementById('sales_to_order_direct_order_action_button_create_pp').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_action_panel')) {
			document.getElementById('sales_to_order_direct_order_action_panel').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_action_panel_title')) {
			document.getElementById('sales_to_order_direct_order_action_panel_title').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_action_journal_label')) {
			document.getElementById('sales_to_order_direct_order_action_journal_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_action_journal')) {
			document.getElementById('sales_to_order_direct_order_action_journal').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_action_purchases_condition')) {
			document.getElementById('sales_to_order_direct_order_action_purchases_condition').remove();
		}




		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div id="sales_to_order_direct_order_action_panel" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="sales_to_order_direct_order_action_panel_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Actions (Some actions could take some time depending on the amount of data to be processed)") + '</b></label>';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'


		html += '<div style="overflow: auto; overflow-x: auto; height:95px;">';



		// Select all
		html += '	<div style="position: relative; top: 2px; left: 0px; width:85px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_action_button_select_all" class="btn btn-default ellipsis" style="height: 35px; width: 85px;" onclick="">' + __("All") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Fill with required qty
		html += '	<div style="position: relative; top: 2px; left: 95px; width:250px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_action_button_fill_with_required" class="btn btn-default ellipsis" style="height: 35px; width: 250px;" onclick="">' + __("Fill with required qty") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Fill with missing available
		html += '	<div style="position: relative; top: 2px; left: 355px; width:250px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_action_button_fill_with_missing_available" class="btn btn-default ellipsis" style="height: 35px; width: 250px;" onclick="">' + __("Fill with missing available") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Fill with missing scheduled available
		html += '	<div style="position: relative; top: 2px; left: 615px; width:250px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_action_button_fill_with_missing_scheduled_available" class="btn btn-default ellipsis" style="height: 35px; width: 250px;" onclick="">' + __("Fill with missing sched. available") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Select none
		html += '	<div style="position: relative; top: 50px; left: 0px; width:85px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_action_button_select_none" class="btn btn-default ellipsis" style="height: 35px; width: 85px;" onclick="">' + __("None") + '</button>';
		html += '		</div>';
		html += '	</div>';




		// Fill with a supplier
		html += '	<div style="position: relative; top: 50px; left: 95px; width:250px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_action_button_fill_with_a_supplier" class="btn btn-default ellipsis" style="height: 35px; width: 250px;border: none; outline:none;" onclick="">' + __("Fill with a supplier") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Clear Supplier
		html += '	<div style="position: relative; top: 50px; left: 355px; width:250px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_action_button_clear_supplier" class="btn btn-default ellipsis" style="height: 35px; width: 250px;" onclick="">' + __("Clear supplier") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Clear "To order"
		html += '	<div style="position: relative; top: 50px; left: 615px; width:250px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_action_button_clear_toorder" class="btn btn-default ellipsis" style="height: 35px; width: 250px;" onclick="">' + __("Clear 'To order'") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Create PO / PP
		html += '	<div style="position: relative; top: 2px; left: 875px; width:250px;">';

		html += '		<div style="position: absolute; top:0px; left: 2px; height: 35px">';
		html +='			<button id="sales_to_order_direct_order_action_button_create_po" class="btn btn-default ellipsis" style="height: 35px; width: 250px;" onclick="">' + __("Create Purch. Order") + '</button>';
		html += '		</div>';		

		html += '		<div style="position: absolute; top:50px; left: 2px; height: 35px">';
		html +='			<button id="sales_to_order_direct_order_action_button_create_pp" class="btn btn-default ellipsis" style="height: 35px; width: 250px;" onclick="">' + __("Create Purch. Proposal(s)") + '</button>';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; top: 2px; left: 1135px; width:180px;">';
		html += '		<label id="sales_to_order_direct_order_action_journal_label" style="position: absolute; top: 0px; left: 2px;">' + __("Purchases journal") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 180px; height: 30px;"> ';
		html += '		<select id="sales_to_order_direct_order_action_journal" class="input-with-feedback form-control bold" style="width:180px"> ';


		silicon_ioi.doctype.ioiSalesToPurchases.purchases_journal = [];

		let method = 'silicon_ioi.ioi_purchases.doctype.ioi_purchases_journal.ioi_purchases_journal.ioi_purchases_journal_get_journals_for_current_site'

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
													for (var i = 0; i < r.message.length; i++)
													{
														if (silicon_ioi.doctype.ioiSalesToPurchases.default_purchases_journal_id != '') {
															if (silicon_ioi.doctype.ioiSalesToPurchases.default_purchases_journal_id == r.message[i].name) {
																html += '		<option value="' + r.message[i].name + '" selected>' + r.message[i].name + '</option> ';
															}else{
																html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
															}
														}else{
															html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
														}

														silicon_ioi.doctype.ioiSalesToPurchases.purchases_journal[silicon_ioi.doctype.ioiSalesToPurchases.purchases_journal.length] = [r.message[i].name, r.message[i].purchases_condition_id]
													}
												}
		});

		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';




		html += '<input id="sales_to_order_direct_order_action_purchases_condition" type="hidden" value = ""> ';




		// Update sched delivery datetime
		html += '	<div style="position: relative; top: 2px; left: 1370px; width:250px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_action_button_update_sched_delivery_datetime" class="btn btn-default ellipsis" style="height: 35px; width: 250px;" onclick="">' + __("Update sched. delivery datetime") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Update delivery datetime
		html += '	<div style="position: relative; top: 50px; left: 1370px; width:250px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_action_button_update_delivery_datetime" class="btn btn-default ellipsis" style="height: 35px; width: 250px;" onclick="">' + __("Update delivery datetime") + '</button>';
		html += '		</div>';
		html += '	</div>';






		html += '</div>';

		return html;
	}

	static remove_sales_order_grid()
	{

		if (document.getElementById('sales_to_order_direct_order_grid_detail_selected_line')) {
			document.getElementById('sales_to_order_direct_order_grid_detail_selected_line').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_grid_detail_selected_parent')) {
			document.getElementById('sales_to_order_direct_order_grid_detail_selected_parent').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_grid_detail_selected_name')) {
			document.getElementById('sales_to_order_direct_order_grid_detail_selected_name').remove();
		}
		if (document.getElementById('sales_to_order_direct_order_grid_detail_selected_item_id')) {
			document.getElementById('sales_to_order_direct_order_grid_detail_selected_item_id').remove();
		}



		if (document.getElementById('sales_to_order_direct_order_grid_detail')) {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_grid_detail_row_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_row_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_button_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_button_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_button_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_supplier_button_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_idx_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_idx_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_ioistatus_bgcolor_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_ioistatus_bgcolor_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_available_bgcolor_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_available_bgcolor_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_bgcolor_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_bgcolor_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_shipping_scheduled_datetime_bgcolor_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_shipping_scheduled_datetime_bgcolor_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).remove();
				}
				if (document.getElementById('sales_to_order_direct_order_grid_detail_available_qty_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_available_qty_' + i.toString()).remove();
				}
				if (document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_qty_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_qty_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_item_delai_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_item_delai_' + i.toString()).remove();
				}
				if (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + i.toString()).remove();
				}
				if (document.getElementById('sales_to_order_direct_order_grid_detail_security_day_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_security_day_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_manufacturer_ref_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_manufacturer_ref_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_currency_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_currency_id_' + i.toString()).remove();
				}
				if (document.getElementById('sales_to_order_direct_order_grid_detail_unit_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_unit_id_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_stock_unit_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_stock_unit_id_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_grid_detail_in_stock_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_in_stock_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_price_button_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_price_button_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_grid_detail_scheduling_mode_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_scheduling_mode_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_scheduling_mode_desc_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_scheduling_mode_desc_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_grid_detail_item_description_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_item_description_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_shipping_scheduled_datetime_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_shipping_scheduled_datetime_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_calc_datetime_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_calc_datetime_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_min_order_qty_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_min_order_qty_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_cell_parent_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_cell_parent_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_cell_idx_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_cell_idx_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_cell_available_qty_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_cell_available_qty_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_cell_available_scheduled_qty_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_cell_available_scheduled_qty_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_cell_shipping_scheduled_datetime_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_cell_shipping_scheduled_datetime_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_grid_detail_cell_supplier_delai_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_grid_detail_cell_supplier_delai_' + i.toString()).remove();
				}


			}

			document.getElementById('sales_to_order_direct_order_grid_detail').remove();
		}
	}


	static build_sales_order_assign_events()
	{
		let fct_keydown = function(event) {

			if (event.keyCode == 13) {
				return false;
			}

		};

		let fct_keyup = function(event) {

			if (event.keyCode == 13) {
				return false;
			}

		};


		let fct_keydown_refresh = function(event) {

			if (event.keyCode == 13) {
				silicon_ioi.doctype.ioiSalesToPurchases.sales_order_apply_filters();
				return false;
			}

		};

		let fct_change = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_apply_filters();
		};


		// Filter
		document.getElementById('sales_to_order_direct_order_filters_filter').onchange = fct_change;
		document.getElementById('sales_to_order_direct_order_filters_filter').onkeydown = fct_keydown;
		document.getElementById('sales_to_order_direct_order_filters_filter').onkeydown = fct_keyup;



		// Item
		let fct_item_button_click = function () {

			let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
			let fields_len = '300, 500, 125, 150, 150, 300';
			let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
			let where = 'ioistatus = 2';
			let order_by = 'a.name asc';
			let parameter_with_stock = false;
			let site_id = '';

			let fct_callback = function (return_value) {

				document.getElementById("sales_to_order_direct_order_filters_item_id").value = return_value;
				document.getElementById('sales_to_order_direct_order_filters_item_id').focus();
				silicon_ioi.doctype.ioiSalesToPurchases.sales_order_apply_filters();
			}

			silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);
		}

		document.getElementById("sales_to_order_direct_order_filters_item_button").onclick = fct_item_button_click;
		document.getElementById("sales_to_order_direct_order_filters_item_button").onkeydown = fct_keydown;
		document.getElementById("sales_to_order_direct_order_filters_item_id").onkeydown = fct_keydown_refresh;
		document.getElementById("sales_to_order_direct_order_filters_item_id").onkeyup = fct_keyup;


		let fct_check_click = function () {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_apply_filters();
		}

		document.getElementById("sales_to_order_direct_order_filters_manufactured").onclick = fct_check_click;
		document.getElementById("sales_to_order_direct_order_filters_manufactured").onkeydown = fct_keydown_refresh;
		document.getElementById("sales_to_order_direct_order_filters_manufactured").onkeyup = fct_keyup;

		document.getElementById("sales_to_order_direct_order_filters_subcontracted").onclick = fct_check_click;
		document.getElementById("sales_to_order_direct_order_filters_subcontracted").onkeydown = fct_keydown_refresh;
		document.getElementById("sales_to_order_direct_order_filters_subcontracted").onkeyup = fct_keyup;


		// Supplier
		let fct_supplier_button_click = function() {

			let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
			let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
			let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
			let where = 'ioistatus = 2';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				document.getElementById("sales_to_order_direct_order_filters_supplier_id").value = return_value;
				document.getElementById('sales_to_order_direct_order_filters_supplier_id').focus();
				silicon_ioi.doctype.ioiSalesToPurchases.sales_order_apply_filters();
			}

			silicon_ioi.ioiCommon.select_supplier(fields, fields_len, fields_desc, where, order_by, fct_callback);
		}

		document.getElementById("sales_to_order_direct_order_filters_supplier_button").onclick = fct_supplier_button_click;
		document.getElementById("sales_to_order_direct_order_filters_supplier_button").onkeydown = fct_keydown;
		document.getElementById('sales_to_order_direct_order_filters_supplier_id').onkeydown = fct_keydown_refresh;
		document.getElementById('sales_to_order_direct_order_filters_supplier_id').onkeyup = fct_keyup;

		// Search
		document.getElementById('sales_to_order_direct_order_filters_search').onkeydown = fct_keydown_refresh;
		document.getElementById('sales_to_order_direct_order_filters_search').onkeyup = fct_keyup;


		let fct_search_click = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_apply_filters();
		};



		// Search button
		document.getElementById("sales_to_order_direct_order_filters_button_search").onclick = fct_search_click;
		document.getElementById("sales_to_order_direct_order_filters_button_search").onkeydown = fct_keydown;

		let fct_clear_click = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.clear_sales_order();
		};

		// Clear
		document.getElementById("sales_to_order_direct_order_filters_button_clear").onclick = fct_clear_click;
		document.getElementById("sales_to_order_direct_order_filters_button_clear").onkeydown = fct_keydown;


		// Select All
		let fct_select_all = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_select_all();
		};

		document.getElementById("sales_to_order_direct_order_action_button_select_all").onclick = fct_select_all;


		// Select None
		let fct_select_none = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_select_none();
		};

		document.getElementById("sales_to_order_direct_order_action_button_select_none").onclick = fct_select_none;



		// Fill with required
		let fct_fill_with_required = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_fill_with_required();
		};

		document.getElementById("sales_to_order_direct_order_action_button_fill_with_required").onclick = fct_fill_with_required;


		// Fill with missing available
		let fct_fill_with_missing_available = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_fill_with_missing_available();
		};

		document.getElementById("sales_to_order_direct_order_action_button_fill_with_missing_available").onclick = fct_fill_with_missing_available;


		// Fill with missing scheduled available
		let fct_fill_with_missing_scheduled_available = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_fill_with_missing_scheduled_available();
		};

		document.getElementById("sales_to_order_direct_order_action_button_fill_with_missing_scheduled_available").onclick = fct_fill_with_missing_scheduled_available;

		// Fill with a supplier
		let fct_fill_with_a_supplier = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_fill_with_a_supplier();
		};

		document.getElementById("sales_to_order_direct_order_action_button_fill_with_a_supplier").onclick = fct_fill_with_a_supplier;


		// Update sched. delivery datetime
		let fct_update_sched_delivery_datetime = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_update_sched_delivery_datetime();
		};

		document.getElementById("sales_to_order_direct_order_action_button_update_sched_delivery_datetime").onclick = fct_update_sched_delivery_datetime;

		// Update delivery datetime
		let fct_update_delivery_datetime = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_update_delivery_datetime();
		};

		document.getElementById("sales_to_order_direct_order_action_button_update_delivery_datetime").onclick = fct_update_delivery_datetime;


		// Clear to order
		let fct_clear_toorder = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_clear_toorder();
		};

		document.getElementById("sales_to_order_direct_order_action_button_clear_toorder").onclick = fct_clear_toorder;


		// Clear supplier
		let fct_clear_supplier = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_clear_supplier();
		};

		document.getElementById("sales_to_order_direct_order_action_button_clear_supplier").onclick = fct_clear_supplier;

		document.getElementById('sales_to_order_direct_order_action_purchases_condition').value = silicon_ioi.doctype.ioiSalesToPurchases.purchases_journal[0][1];

		let fct_change_journal = function() {

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesToPurchases.purchases_journal.length; i++)  {
				if (document.getElementById("sales_to_order_direct_order_action_journal").value == silicon_ioi.doctype.ioiSalesToPurchases.purchases_journal[i][0]) {
					document.getElementById('sales_to_order_direct_order_action_purchases_condition').value = silicon_ioi.doctype.ioiSalesToPurchases.purchases_journal[i][1];
				}
			}

		};

		document.getElementById("sales_to_order_direct_order_action_journal").onchange = fct_change_journal;


		// Create Purchase Order
		let fct_create_po = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.new_purchases_order();
		};

		document.getElementById("sales_to_order_direct_order_action_button_create_po").onclick = fct_create_po;

		// Create Purchase Proposal
		let fct_create_pp = function() {
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_create_pp();
		};

		document.getElementById("sales_to_order_direct_order_action_button_create_pp").onclick = fct_create_pp;

		

		let fct_tab_mouseover = function()  { silicon_ioi.doctype.ioiSalesToPurchases.tab_mouseover(this); };
		let fct_tab_mouseleave = function() { silicon_ioi.doctype.ioiSalesToPurchases.tab_mouseleave(this); };
		let fct_tab_click = function() { silicon_ioi.doctype.ioiSalesToPurchases.tab_click(this); };

		// tab 1
		document.getElementById('sales_to_order_direct_order_purchases_prices_tab_label').onmouseover = fct_tab_mouseover;
		document.getElementById('sales_to_order_direct_order_purchases_prices_tab_label').onmouseleave = fct_tab_mouseleave;
		document.getElementById('sales_to_order_direct_order_purchases_prices_tab').onclick = fct_tab_click;

		// tab 2
		document.getElementById('sales_to_order_direct_order_po_for_item_tab_label').onmouseover = fct_tab_mouseover;
		document.getElementById('sales_to_order_direct_order_po_for_item_tab_label').onmouseleave = fct_tab_mouseleave;
		document.getElementById('sales_to_order_direct_order_po_for_item_tab').onclick = fct_tab_click;

		// tab 3
		document.getElementById('sales_to_order_direct_order_po_for_supplier_tab_label').onmouseover = fct_tab_mouseover;
		document.getElementById('sales_to_order_direct_order_po_for_supplier_tab_label').onmouseleave = fct_tab_mouseleave;
		document.getElementById('sales_to_order_direct_order_po_for_supplier_tab').onclick = fct_tab_click;

		// tab 4
		document.getElementById('sales_to_order_direct_order_po_tab_label').onmouseover = fct_tab_mouseover;
		document.getElementById('sales_to_order_direct_order_po_tab_label').onmouseleave = fct_tab_mouseleave;
		document.getElementById('sales_to_order_direct_order_po_tab').onclick = fct_tab_click;

		document.getElementById('sales_to_order_direct_order_po_for_supplier_tab').click();
	}



	static clear_sales_order()
	{
		document.getElementById('sales_to_order_direct_order_filters_filter').selectedIndex = 0;
		document.getElementById('sales_to_order_direct_order_filters_item_id').value = '';
		document.getElementById('sales_to_order_direct_order_filters_manufactured').checked = false;
		document.getElementById('sales_to_order_direct_order_filters_subcontracted').checked = false;
		document.getElementById('sales_to_order_direct_order_filters_supplier_id').value = '';
		document.getElementById('sales_to_order_direct_order_filters_search').value = '';
		silicon_ioi.doctype.ioiSalesToPurchases.sales_order_apply_filters();
	}


	static refresh_sales_order()
	{
		silicon_ioi.doctype.ioiSalesToPurchases.remove_sales_order_grid();

		let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + ".direct_order_get_sales_order_list";

		frappe.call({  	method: method,
						args: {	"sales_order_name": silicon_ioi.doctype.ioiSalesToPurchases.sales_order_name
						},
						async : false,
						callback:function(r) {

							let at_least_one_row = false;

							if (r.message.length > 0) {

								at_least_one_row = true;

								let html = '';

								html += '<input id="sales_to_order_direct_order_grid_detail_selected_line" 	 	type="hidden" value = "0">';
								html += '<input id="sales_to_order_direct_order_grid_detail_selected_parent" 	type="hidden" value = "">';
								html += '<input id="sales_to_order_direct_order_grid_detail_selected_name" 	 	type="hidden" value = "">';
								html += '<input id="sales_to_order_direct_order_grid_detail_selected_item_id" 	type="hidden" value = "">';


								html += '<table id="sales_to_order_direct_order_grid_detail" width=' + silicon_ioi.doctype.ioiSalesToPurchases.direct_order_grid_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB">';

								for (var i = 0; i < r.message.length; i++) {

									html += '<input id="sales_to_order_direct_order_grid_detail_parent_' + i.toString() + '" 								type="hidden" value = "' + r.message[i].parent + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_name_' + i.toString() + '" 									type="hidden" value = "' + r.message[i].name + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_idx_' + i.toString() + '" 									type="hidden" value = "' + r.message[i].idx + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_ioistatus_bgcolor_' + i.toString() + '" 					type="hidden" value = "' + r.message[i].ioistatus_bgcolor + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_available_bgcolor_' + i.toString() + '" 					type="hidden" value = "' + r.message[i].available_bgcolor + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_available_scheduled_bgcolor_' + i.toString() + '" 			type="hidden" value = "' + r.message[i].available_scheduled_bgcolor + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_shipping_scheduled_datetime_bgcolor_' + i.toString() + '" 	type="hidden" value = "' + r.message[i].shipping_scheduled_datetime_bgcolor + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString() + '" 							type="hidden" value = "' + r.message[i].to_deliver + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_required_' + i.toString() + '" 								type="hidden" value = "' + r.message[i].required + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_available_qty_' + i.toString() + '" 						type="hidden" value = "' + r.message[i].available_qty + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_available_scheduled_qty_' + i.toString() + '" 				type="hidden" value = "' + r.message[i].available_scheduled_qty + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_item_delai_' + i.toString() + '" 							type="hidden" value = "' + r.message[i].item_delai + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_supplier_delai_' + i.toString() + '" 						type="hidden" value = "' + r.message[i].supplier_delai + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_security_day_' + i.toString() + '" 							type="hidden" value = "' + r.message[i].security_day + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString() + '" 				type="hidden" value = "' + r.message[i].purchases_condition_id + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_item_id_' + i.toString() + '" 								type="hidden" value = "' + r.message[i].item_id + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_manufacturer_ref_' + i.toString() + '" 						type="hidden" value = "' + r.message[i].manufacturer_ref + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_currency_id_' + i.toString() + '" 							type="hidden" value = "' + r.message[i].currency_id + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_unit_id_' + i.toString() + '" 								type="hidden" value = "' + r.message[i].unit_id + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_stock_unit_id_' + i.toString() + '" 						type="hidden" value = "' + r.message[i].stock_unit_id + '">';

									html += '<input id="sales_to_order_direct_order_grid_detail_in_stock_' + i.toString() + '" 								type="hidden" value = "' + r.message[i].q_stock + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_scheduling_mode_' + i.toString() + '" 						type="hidden" value = "' + r.message[i].scheduling_mode + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_scheduling_mode_desc_' + i.toString() + '" 					type="hidden" value = "' + r.message[i].scheduling_mode_desc + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_item_description_' + i.toString() + '" 						type="hidden" value = "' + r.message[i].item_description + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_shipping_scheduled_datetime_' + i.toString() + '" 			type="hidden" value = "' + r.message[i].shipping_scheduled_datetime + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_calc_datetime_' + i.toString() + '" 						type="hidden" value = "' + r.message[i].available_calc_datetime + '">';
									html += '<input id="sales_to_order_direct_order_grid_detail_min_order_qty_' + i.toString() + '" 						type="hidden" value = "' + r.message[i].min_order_qty + '">';

									html += '<input id="sales_to_order_direct_order_grid_detail_price_name_' + i.toString() + '" 							type="hidden" value = "' + r.message[i].price_name + '">';





									html += '<input id="sales_to_order_direct_order_grid_detail_row_modified_' + i.toString() + '" 							type="hidden" value = "0">';


									html += '<tr id="sales_to_order_direct_order_grid_detail_row_' + i.toString() + '" style="height:30px" style="display:table-row;">';

									html += '<td width=30px align="center" style="vertical-align: middle;">';
									html += '<input type="checkbox" id="sales_to_order_direct_order_grid_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 2px; left: 2px;" checked ';
									html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
									html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
									html += '>';

									html += '</td>';



									if (silicon_ioi.doctype.ioiSalesToPurchases.sales_order_name == null) {
										if ((r.message[i].ioistatus_bgcolor != null) && (r.message[i].ioistatus_bgcolor != '')) {
											html += '<td id="sales_to_order_direct_order_grid_detail_cell_parent_' + i.toString() + '" width=160px style="vertical-align: middle;color:#000000;" bgcolor="' + r.message[i].ioistatus_bgcolor + '">&nbsp;' + r.message[i].parent + '</td>';
										}else{
											html += '<td id="sales_to_order_direct_order_grid_detail_cell_parent_' + i.toString() + '" width=160px style="vertical-align: middle;">&nbsp;' + r.message[i].parent + '</td>';
										}
									}

									if ((r.message[i].ioistatus_bgcolor != null) && (r.message[i].ioistatus_bgcolor != '')) {
										html += '<td id="sales_to_order_direct_order_grid_detail_cell_idx_' + i.toString() + '" width=60px style="vertical-align: middle;color:#000000;" align="right" bgcolor="' + r.message[i].ioistatus_bgcolor + '">' + r.message[i].idx + '&nbsp;</td>';
									}else{
										html += '<td id="sales_to_order_direct_order_grid_detail_cell_idx_' + i.toString() + '" width=60px style="vertical-align: middle;" align="right">' + r.message[i].idx + '&nbsp;</td>';
									}

									html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].item_id + '</td>';
									html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].manufacturer_ref + '</td>';
									html += '<td width=110px style="vertical-align: middle;" align="right" >' + r.message[i].to_deliver + '&nbsp;</td>';
									html += '<td width=110px style="vertical-align: middle;" align="right">' + r.message[i].required + '&nbsp;</td>';
									html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].unit_id + '</td>';
									html += '<td width=110px style="vertical-align: middle;" align="right">' + r.message[i].q_stock + '&nbsp;</td>';
									html += '<td width=110px style="vertical-align: middle;">&nbsp;' + r.message[i].stock_unit_id + '</td>';




									if ((r.message[i].available_bgcolor != null) && (r.message[i].available_bgcolor != '')) {
										html += '<td id="sales_to_order_direct_order_grid_detail_cell_available_qty_' + i.toString() + '" width=110px style="vertical-align: middle;color:#000000;" align="right" bgcolor="' + r.message[i].available_bgcolor + '">' + r.message[i].available_qty + '&nbsp;</td>';
									}else{
										html += '<td id="sales_to_order_direct_order_grid_detail_cell_available_qty_' + i.toString() + '" width=110px style="vertical-align: middle;" align="right">' + r.message[i].available_qty + '&nbsp;</td>';
									}

									if ((r.message[i].available_scheduled_bgcolor != null) && (r.message[i].available_scheduled_bgcolor != '')) {
										html += '<td id="sales_to_order_direct_order_grid_detail_cell_available_scheduled_qty_' + i.toString() + '" width=110px style="vertical-align: middle;color:#000000;" align="right" bgcolor="' + r.message[i].available_scheduled_bgcolor + '">' + r.message[i].available_scheduled_qty + '&nbsp;</td>';
									}else{
										html += '<td id="sales_to_order_direct_order_grid_detail_cell_available_scheduled_qty_' + i.toString() + '" width=110px style="vertical-align: middle;" align="right">' + r.message[i].available_scheduled_qty + '&nbsp;</td>';
									}


									html += '<td width=110px style="vertical-align: middle;">';

									html += '<input id="sales_to_order_direct_order_grid_detail_toorder_' + i.toString() + '" type="number" step="any" style="width:106px;height:30px;text-align:right;" ';

									if ((r.message[i].to_order != null) && (r.message[i].to_order != 0)) {
										html += 'value="' + r.message[i].to_order + '">';
									}else{
										html += 'value="">';
									}

									html += '</td>';



									html += '<td width=130px style="vertical-align: middle;">';

									html += '<input id="sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString() + '" type="text" style="width:126px;height:30px;text-align:right;" ';

									if ((r.message[i].purchases_to_order != null) && (r.message[i].purchases_to_order != 0)) {
										html += 'value="' + r.message[i].to_order + '" disabled>';
									}else{
										html += 'value="" disabled>';
									}

									html += '</td>';

									html += '<td width=100px style="vertical-align: middle;">';

									html += '<input id="sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString() + '" type="text" style="width:96px;height:30px;text-transform: uppercase;" ';

									if ((r.message[i].purchases_unit_id != null) && (r.message[i].purchases_unit_id != 0)) {
										html += 'value="' + r.message[i].purchases_unit_id + '">';
									}else{
										html += 'value="">';
									}

									html += '</td>';

									html += '<td width=30px  style="vertical-align: middle;">';

									html += '<button id="sales_to_order_direct_order_grid_detail_purchases_unit_button_' + i.toString() + '" style="height: 30px; width: 26px;" onclick="">...</button>';

									html += '</td>';




									html += '<td width=180px style="vertical-align: middle;">';
									html += '<input id="sales_to_order_direct_order_grid_detail_supplier_' + i.toString() + '" type="text" style="text-transform: uppercase;width:176px;height:30px;" value="' + r.message[i].supplier_id + '" ';

									if ((r.message[i].item_id == null) || ((r.message[i].item_id != null) && (r.message[i].item_id.trim() == ''))) {

										if ((r.message[i].manufacturer_ref != null) && (r.message[i].manufacturer_ref.trim() != '')) {

											if ((r.message[i].supplier_id != null) && (r.message[i].supplier_id.trim() != '')) {
												html += ' disabled ';
											}
										}
									}
									html += '>';
									html += '</td>';
									html += '<td width=30px  style="vertical-align: middle;">';

									html += '<button id="sales_to_order_direct_order_grid_detail_supplier_button_' + i.toString() + '" style="height: 30px; width: 26px;" onclick="" ';

									if ((r.message[i].item_id == null) || ((r.message[i].item_id != null) && (r.message[i].item_id.trim() == ''))) {

										if ((r.message[i].manufacturer_ref != null) && (r.message[i].manufacturer_ref.trim() != '')) {

											if ((r.message[i].supplier_id != null) && (r.message[i].supplier_id.trim() != '')) {
												html += ' disabled ';
											}
										}
									}

									html += '>...</button>';

									html += '</td>';


									html += '<td width=60px style="vertical-align: middle;" align="center">';
									html += '<input type="checkbox" id="sales_to_order_direct_order_grid_detail_has_price_' + i.toString() + '" style="postion:absolute; top: 2px; left: 2px;" ';
									if ((r.message[i].price_name != null) && (r.message[i].price_name != '')) {
										html += ' checked ';
									}
									html += 'disabled>';

									html += '</td>';


									html += '<td width=120px style="vertical-align: middle;" align="center">';
									html += '<input type="checkbox" id="sales_to_order_direct_order_grid_detail_price_exists_' + i.toString() + '" style="postion:absolute; top: 2px; left: 2px;" ';
									if (r.message[i].price_exists == 'Y') {
										html += ' checked ';
									}
									html += 'disabled>';
									html += '</td>';



									html += '<td width=30px  style="vertical-align: middle;">';
									html += '<button id="sales_to_order_direct_order_grid_detail_price_button_' + i.toString() + '" style="height: 30px; width: 26px;" onclick="">...</button>';
									html += '</td>';


									html += '<td width=100px style="vertical-align: middle;">';
									html += '<input id="sales_to_order_direct_order_grid_detail_price_' + i.toString() + '" type="text" style="width:96px;height:30px;text-align:right;" disabled ';

									if ((r.message[i].purchases_unit_price != null) && (r.message[i].purchases_unit_price != 0)) {
										html += 'value="' + r.message[i].purchases_unit_price + '">';
									}else{
										html += 'value="">';
									}
									html += '</td>';


									html += '<td width=60px style="vertical-align: middle;">';

									html += '<input id="sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString() + '" type="text" style="width:56px;height:30px;text-transform: uppercase;" disabled ';

									if ((r.message[i].purchases_currency_id != null) && (r.message[i].purchases_currency_id != 0)) {
										html += 'value="' + r.message[i].purchases_currency_id + '">';
									}else{
										html += 'value="">';
									}


									html += '</td>';


									html += '<td width=130px style="vertical-align: middle;">&nbsp;' + r.message[i].scheduling_mode_desc + '</td>';
									html += '<td width=350px style="vertical-align: middle;">&nbsp;' + r.message[i].item_description + '</td>';
									html += '<td width=100px style="vertical-align: middle;" align="right">' + r.message[i].item_delai	+ '&nbsp;</td>';
									html += '<td id="sales_to_order_direct_order_grid_detail_cell_supplier_delai_' + i.toString() + '" width=100px style="vertical-align: middle;" align="right">' + r.message[i].supplier_delai	+ '&nbsp;</td>';

									if ((r.message[i].shipping_scheduled_datetime != null) && (r.message[i].shipping_scheduled_datetime != '')) {

										if ((r.message[i].shipping_scheduled_datetime_bgcolor != null) && (r.message[i].shipping_scheduled_datetime_bgcolor != '')) {
											html += '<td id="sales_to_order_direct_order_grid_detail_cell_shipping_scheduled_datetime_' + i.toString() + '" width=145px style="vertical-align: middle;color:#000000;" bgcolor="' + r.message[i].shipping_scheduled_datetime_bgcolor + '">&nbsp;' + r.message[i].shipping_scheduled_datetime.toString().substring(0, 16) + '</td>';
										}else{
											html += '<td id="sales_to_order_direct_order_grid_detail_cell_shipping_scheduled_datetime_' + i.toString() + '" width=145px style="vertical-align: middle;">&nbsp;' + r.message[i].shipping_scheduled_datetime.toString().substring(0, 16) + '</td>';

										}
									}else{
										html += '<td id="sales_to_order_direct_order_grid_detail_cell_shipping_scheduled_datetime_' + i.toString() + '" width=125px style="vertical-align: middle;">&nbsp;</td>';
									}

									if ((r.message[i].available_calc_datetime != null) && (r.message[i].available_calc_datetime != '')) {
										html += '<td width=150px style="vertical-align: middle;">&nbsp;' + r.message[i].available_calc_datetime.toString().substring(0, 16)	+ '</td>';
									}else{
										html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
									}
									html += '<td width=150px style="vertical-align: middle;" align="right"' + r.message[i].min_order_qty 			+ '>&nbsp;</td>';
									html += '</tr>';
								}

								html += '</table>';

								document.getElementById('sales_to_order_direct_order_grid_header').insertAdjacentHTML('afterend', html);

								silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(400).then(() => {

									if (at_least_one_row) {

										let fct_row_click = function () { silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_row_click(this.id); };
										let fct_change_toorder_value = function () { silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_change_toorder_value(this.id); };
										let fct_change_supplier_value = function () { silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_change_supplier_value(this.id); };
										let fct_select_supplier_click = function () { silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_select_supplier(this.id); };
										let fct_change_purchases_unit_value = function () { silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_change_purchases_unit_value(this.id); };
										let fct_select_purchases_unit_click = function () { silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_select_purchases_unit(this.id); };
										let fct_select_purchases_price_click = function () { silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_select_purchases_price(this.id); };

										let fct_keydown = function(event) {

											if (event.keyCode == 13) {
												return false;
											}

										};

										for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

											document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].onclick = fct_row_click;

											document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).onchange = fct_change_toorder_value;
											document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).onkeydown = fct_keydown;

											document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).onchange = fct_change_supplier_value;
											document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).onkeydown = fct_keydown;

											document.getElementById('sales_to_order_direct_order_grid_detail_supplier_button_' + i.toString()).onclick = fct_select_supplier_click;

											document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).onchange = fct_change_purchases_unit_value;
											document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).onkeydown = fct_keydown;
											document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_button_' + i.toString()).onclick = fct_select_purchases_unit_click;

											document.getElementById('sales_to_order_direct_order_grid_detail_price_button_' + i.toString()).onclick = fct_select_purchases_price_click;
										}


										for (var j = 0; j < document.getElementById('sales_to_order_direct_order_grid_detail').rows[0].cells.length; j++) {

											if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
												document.getElementById('sales_to_order_direct_order_grid_detail').rows[0].cells[j].style.backgroundColor = '#b1fcd9';
											}else{
												document.getElementById('sales_to_order_direct_order_grid_detail').rows[0].cells[j].style.backgroundColor = 'green';
											}
										}

										silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_row_click(document.getElementById('sales_to_order_direct_order_grid_detail').rows[0].id);
									}

									silicon_ioi.doctype.ioiSalesToPurchases.sales_order_apply_filters();

								});



							}else{
								let html = silicon_ioi.doctype.ioiSalesToPurchases.build_sales_order_grid_empty_row();
								document.getElementById('sales_to_order_direct_order_grid_header').insertAdjacentHTML('afterend', html);

							}
						}
		});

	}

	static sales_order_apply_filters()
	{
		if (document.getElementById('sales_to_order_direct_order_grid_detail').rows.length > 0) {

			let first_visible_row = -1;

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

				let display_row = false;

				if (document.getElementById('sales_to_order_direct_order_filters_filter').value == '-1') {
					display_row = true;
				}else if (document.getElementById('sales_to_order_direct_order_filters_filter').value == '0') {

					let toorder = 0;

					if 	((document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value != null) &&
						(document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value.trim() != '') &&
						(document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value.trim() != '0')) {
						toorder = parseFloat(document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value);
					}

					if (toorder > 0) {
						display_row = true;
					}

				}else if (document.getElementById('sales_to_order_direct_order_filters_filter').value == '1') {

					let todeliver = 0;

					if 	((document.getElementById('sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString()).value != null) &&
						(document.getElementById('sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString()).value.trim() != '') &&
						(document.getElementById('sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString()).value.trim() != '0')) {
						todeliver = parseFloat(document.getElementById('sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString()).value);
					}

					let avail = 0;

					if 	((document.getElementById('sales_to_order_direct_order_grid_detail_available_qty_' + i.toString()).value != null) &&
						(document.getElementById('sales_to_order_direct_order_grid_detail_available_qty_' + i.toString()).value.trim() != '') &&
						(document.getElementById('sales_to_order_direct_order_grid_detail_available_qty_' + i.toString()).value.trim() != '0')) {
						avail = parseFloat(document.getElementById('sales_to_order_direct_order_grid_detail_available_qty_' + i.toString()).value);
					}


					if (parseFloat(avail) < parseFloat(todeliver)) {
						display_row = true;
					}

				}else if (document.getElementById('sales_to_order_direct_order_filters_filter').value == '2') {

					let todeliver = 0;

					if 	((document.getElementById('sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString()).value != null) &&
						(document.getElementById('sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString()).value.trim() != '') &&
						(document.getElementById('sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString()).value.trim() != '0')) {
						todeliver = parseFloat(document.getElementById('sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString()).value);
					}

					let sched_avail = 0;

					if 	((document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_qty_' + i.toString()).value != null) &&
						(document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_qty_' + i.toString()).value.trim() != '') &&
						(document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_qty_' + i.toString()).value.trim() != '0')) {
						sched_avail = parseFloat(document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_qty_' + i.toString()).value);
					}


					if (parseFloat(sched_avail) < parseFloat(todeliver)) {
						display_row = true;
					}
				}

				if (display_row) {

					display_row = false;

					if (document.getElementById('sales_to_order_direct_order_filters_item_id').value.trim() != '') {

						let filter_item = document.getElementById('sales_to_order_direct_order_filters_item_id').value.toUpperCase();

						let item_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value.trim() != '')) {
							item_row = document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value.toUpperCase();
						}

						if (item_row == filter_item) {
							display_row = true;
						}
					}else{
						display_row = true;
					}
				}


				if (display_row) {

					if (!document.getElementById('sales_to_order_direct_order_filters_manufactured').checked) {

						if (document.getElementById('sales_to_order_direct_order_grid_detail_scheduling_mode_' + i.toString()).value == 2) {
							display_row = false;
						}
					}
				}

				if (display_row) {

					if (!document.getElementById('sales_to_order_direct_order_filters_subcontracted').checked) {

						if 	(document.getElementById('sales_to_order_direct_order_grid_detail_scheduling_mode_' + i.toString()).value == 3) {
							display_row = false;
						}
					}
				}


				if (display_row) {

					display_row = false;

					if (document.getElementById('sales_to_order_direct_order_filters_supplier_id').value.trim() != '') {

						let filter_supplier = document.getElementById('sales_to_order_direct_order_filters_supplier_id').value.toUpperCase();

						let supplier_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value.trim() != '')) {
							supplier_row = document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value.toUpperCase();
						}

						if (supplier_row.indexOf(filter_supplier) != -1) {
							display_row = true;
						}
					}else{
						display_row = true;
					}
				}


				if (display_row) {

					display_row = false;

					if (document.getElementById('sales_to_order_direct_order_filters_search').value.trim() != '') {

						let filter_search = document.getElementById('sales_to_order_direct_order_filters_search').value.toUpperCase();

						let parent_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString()).value.trim() != '')) {
							parent_row = document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString()).value.toUpperCase();
						}


						let name_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString()).value.trim() != '')) {
							name_row = document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString()).value.toUpperCase();
						}

						let idx_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_idx_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_idx_' + i.toString()).value.trim() != '')) {
							idx_row = document.getElementById('sales_to_order_direct_order_grid_detail_idx_' + i.toString()).value.toUpperCase();
						}

						let item_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value.trim() != '')) {
							item_row = document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value.toUpperCase();
						}

						let todeliver_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString()).value.trim() != '')) {
							todeliver_row = document.getElementById('sales_to_order_direct_order_grid_detail_to_deliver_' + i.toString()).value.toUpperCase();
						}

						let required_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value.trim() != '')) {
							required_row = document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value.toUpperCase();
						}


						let unit_id_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_unit_id_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_unit_id_' + i.toString()).value.trim() != '')) {
							unit_id_row = document.getElementById('sales_to_order_direct_order_grid_detail_unit_id_' + i.toString()).value.toUpperCase();
						}

						let in_stock_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_in_stock_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_in_stock_' + i.toString()).value.trim() != '')) {
							in_stock_row = document.getElementById('sales_to_order_direct_order_grid_detail_in_stock_' + i.toString()).value.toUpperCase();
						}


						let stock_unit_id_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_stock_unit_id_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_stock_unit_id_' + i.toString()).value.trim() != '')) {
							stock_unit_id_row = document.getElementById('sales_to_order_direct_order_grid_detail_stock_unit_id_' + i.toString()).value.toUpperCase();
						}


						let available_qty_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_available_qty_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_available_qty_' + i.toString()).value.trim() != '')) {
							available_qty_row = document.getElementById('sales_to_order_direct_order_grid_detail_available_qty_' + i.toString()).value.toUpperCase();
						}

						let sched_available_qty_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_qty_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_qty_' + i.toString()).value.trim() != '')) {
							sched_available_qty_row = document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_qty_' + i.toString()).value.toUpperCase();
						}

						let toorder_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value.trim() != '')) {
							toorder_row = document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value.toUpperCase();
						}


						let supplier_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value.trim() != '')) {
							supplier_row = document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value.toUpperCase();
						}

						let purchases_toorder_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value.trim() != '')) {
							purchases_toorder_row = document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value.toUpperCase();
						}

						let purchases_unit_id_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value.trim() != '')) {
							purchases_unit_id_row = document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value.toUpperCase();
						}


						let purchases_price_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString()).value.trim() != '')) {
							purchases_price_row = document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString()).value.toUpperCase();
						}

						let purchases_currency_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).value.trim() != '')) {
							purchases_currency_row = document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).value.toUpperCase();
						}

						let item_description_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_item_description_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_item_description_' + i.toString()).value.trim() != '')) {
							item_description_row = document.getElementById('sales_to_order_direct_order_grid_detail_item_description_' + i.toString()).value.toUpperCase();
						}


						let item_delai_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_item_delai_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_item_delai_' + i.toString()).value.trim() != '')) {
							item_delai_row = document.getElementById('sales_to_order_direct_order_grid_detail_item_delai_' + i.toString()).value.toUpperCase();
						}


						let supplier_delai_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + i.toString()).value.trim() != '')) {
							supplier_delai_row = document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + i.toString()).value.toUpperCase();
						}

						let shipping_scheduled_datetime_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_shipping_scheduled_datetime_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_shipping_scheduled_datetime_' + i.toString()).value.trim() != '')) {
							shipping_scheduled_datetime_row = document.getElementById('sales_to_order_direct_order_grid_detail_shipping_scheduled_datetime_' + i.toString()).value.toUpperCase().substring(0, 16);
						}


						let calc_datetime_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_calc_datetime_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_calc_datetime_' + i.toString()).value.trim() != '')) {
							calc_datetime_row = document.getElementById('sales_to_order_direct_order_grid_detail_calc_datetime_' + i.toString()).value.toUpperCase().substring(0,16);
						}

						let min_order_qty_row = '';

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_min_order_qty_' + i.toString()).value != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_min_order_qty_' + i.toString()).value.trim() != '')) {
							min_order_qty_row = document.getElementById('sales_to_order_direct_order_grid_detail_min_order_qty_' + i.toString()).value.toUpperCase();
						}


						if ((parent_row.indexOf(filter_search) != -1) || (name_row.indexOf(filter_search) != -1) || (idx_row.indexOf(filter_search) != -1) || (item_row.indexOf(filter_search) != -1) ||
							(todeliver_row.indexOf(filter_search) != -1) || (required_row.indexOf(filter_search) != -1) || (unit_id_row.indexOf(filter_search) != -1) || (in_stock_row.indexOf(filter_search) != -1) ||
							(stock_unit_id_row.indexOf(filter_search) != -1) ||	(available_qty_row.indexOf(filter_search) != -1) || (sched_available_qty_row.indexOf(filter_search) != -1) || (toorder_row.indexOf(filter_search) != -1) ||
							(purchases_toorder_row.indexOf(filter_search) != -1) || (purchases_unit_id_row.indexOf(filter_search) != -1) || (purchases_price_row.indexOf(filter_search) != -1) || (purchases_currency_row.indexOf(filter_search) != -1) ||
							(supplier_row.indexOf(filter_search) != -1) || (item_description_row.indexOf(filter_search) != -1) || (item_delai_row.indexOf(filter_search) != -1) ||
							(supplier_delai_row.indexOf(filter_search) != -1) || (shipping_scheduled_datetime_row.indexOf(filter_search) != -1) ||  (calc_datetime_row.indexOf(filter_search) != -1) || (min_order_qty_row.indexOf(filter_search) != -1))
						{
							display_row = true;
						}
					}else{
						display_row = true;
					}
				}


				document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked = false;

				if (display_row) {

					if (first_visible_row == -1) {
						first_visible_row = i;
					}
					document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display = 'table-row';
					document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked = true;
				}else{
					document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display = 'none';
				}

			}



			if (first_visible_row != -1) {

				silicon_ioi.doctype.ioiSalesToPurchases.force_refresh_po_for_supplier = true;

				silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_row_click(document.getElementById('sales_to_order_direct_order_grid_detail').rows[first_visible_row].id);

			}else{
				document.getElementById('sales_to_order_direct_order_grid_detail_selected_item_id').value = '';

				silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
				silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();

				document.getElementById('sales_to_order_direct_order_po_for_supplier_selected_name').value = '';
				silicon_ioi.doctype.ioiSalesToPurchases.load_purchases_orders_form_detail();



				silicon_ioi.doctype.ioiSalesToPurchases.load_purchases_prices();

			}
		}
	}

	static sales_order_grid_select_supplier(id)
	{
		let s = id;
		s = s.substring(56, s.length);


		let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
		let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
		let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
		let where = 'ioistatus = 2';
		let order_by = 'name asc';

		let fct_callback = function (return_value) {

			let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_get_sales_order_get_supplier_delai';

			frappe.call({  	method: method,
							args: { "item_id": document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + s).value,
								 	"supplier_id": return_value.toUpperCase()
							},
							async: false,
							callback:function(r)	{



								if (r.message.error == '0') {

									if ((r.message.supplier_delai != null) && (r.message.supplier_delai != '')) {
										document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + s).value = r.message.supplier_delai;
										document.getElementById('sales_to_order_direct_order_grid_detail_cell_supplier_delai_' + s).innerHTML = r.message.supplier_delai + '&nbsp;';
									}

									if (r.message.price_exists == 'Y') {
										document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + s).checked = true;
									}else{
										document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + s).checked = false;
									}


								}
							}
			});


			document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + s).value = return_value;
			document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + s).value = '1';
			document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + s).focus();

			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_get_purchases_price(s);
			silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
			silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();


		}

		silicon_ioi.ioiCommon.select_supplier(fields, fields_len, fields_desc, where, order_by, fct_callback);


	}

	static sales_order_grid_select_purchases_unit(id)
	{
		let s = id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}


		let title = __("Select an Unit");
		let form_width_pixel = '710px';
		let table = "ioi Item Unit";
		let fields = 'name, description';
		let fields_len = '250, 380';
		let fields_desc = 'Identification, Description';
		let where = 'enabled = 1';
		let order_by = 'name asc';

		let fct_callback = function (return_value) {

			document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + s).value = return_value;
			document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + s).focus();
			document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + s).value = '1';

			silicon_ioi.doctype.ioiSalesToPurchases.convert_in_purchases_unit(s);
			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_get_purchases_price(s);
		}

		silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
	}


	static sales_order_grid_select_purchases_price(id)
	{
		let i = id;

		while (i.indexOf('_') != -1) {
			i = i.substring(i.indexOf('_')+1, i.length);
		}

		if (document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i).value.trim() != '') {

			let fields = 'name, unit_price, base_discount, extra_discount ';
			let fields_len = '500, 130, 150, 150';
			let fields_desc = 'Name, Unit Price, Base discount, Extra discount';
			let where = "item_id = '" + document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i).value.replaceAll("'", "''") + "' ";

			if (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i).value.trim() != '') {
				where += "and supplier_id = '" + document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i).value.toUpperCase().replaceAll("'", "''") + "' ";
			}

			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let s = return_value;

				let supplier_id = s.substring(0, s.indexOf('•')-1);
				supplier_id = supplier_id.trim();

				s = s.substring(s.indexOf('•')+1);

				let item_id = s.substring(0, s.indexOf('•')-1);
				item_id = item_id.trim();

				s = s.substring(s.indexOf('•')+1);

				let pur_cond = s.substring(0, s.indexOf('•')-1);
				pur_cond = pur_cond.trim();

				s = s.substring(s.indexOf('•')+1);


				let unit = s.substring(0, s.indexOf('•')-1);
				unit = unit.trim();

				s = s.substring(s.indexOf('•')+1);


				let currency = '';

				if (s.indexOf('•') != -1) {
					currency = s.substring(0, s.indexOf('•')-1);
				}else{
					currency = s.substring(0, s.length);
				}

				currency = currency.trim();


				document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i).value = return_value;
				document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i).value = supplier_id;
				document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i).value = pur_cond;
				document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i).value = unit;
				document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i).value = currency;
				document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i).checked = true;
				document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i).checked = true;

				document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + i).value = '1';

				let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_get_purchases_unit_price';

				frappe.call({  	method: method,
								args: { "purchases_price_name" : document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i).value },
								async: false,
								callback:function(r)	{

									document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i).value = r.message[0].unit_price;

									silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_get_purchases_price(i);
								}
				});
				silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
				silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();


			}

			silicon_ioi.ioiCommon.select_purchases_prices(fields, fields_len, fields_desc, where, order_by, fct_callback);
		}
	}

	static sales_order_grid_change_toorder_value(id)
	{

		let s = id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}


		silicon_ioi.doctype.ioiSalesToPurchases.convert_in_purchases_unit(s);
		silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_get_purchases_price(s);

		if (document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + s)) {
			document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + s).value = '1';
		}
	}

	static sales_order_grid_get_purchases_price(i)
	{
		if (document.getElementById('sales_to_order_direct_order_grid_detail_manufacturer_ref_' + i.toString()).value.trim() == '') {

			if ((document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value.trim() == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value.trim() == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value.trim() == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value.trim() == '')) {

					document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString()).value = '';
					document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString()).value = '';
					document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString()).checked = false;
					document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString()).checked = false;
					document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + i.toString()).value = '1';

			}else{

				let item_id = document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value;
				let supplier_id = document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value;
				let purchases_condition_id = document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value;
				let currency_id = document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).value;
				let qty = document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value;
				let unit_id = document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value;


				let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_get_price';

				frappe.call({  	method: method,
								args: { "item_id": item_id,
										"supplier_id": supplier_id,
										"purchases_condition_id": purchases_condition_id,
										"currency_id": currency_id,
										"qty": qty,
										"unit_id": unit_id
								},
								async: false,
								callback:function(r)	{

									if (r.message.error == 1) {

										document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString()).value = '';
										document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString()).value = '';
										document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString()).checked = false;
										document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString()).checked = false;

									}else{

										document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString()).value = '';
										document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString()).value = '';
										document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString()).checked = false;
										document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString()).checked = false;


										if (r.message.purchases_price_name != null && r.message.purchases_price_name != '') {
											document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString()).value = r.message.unit_price;
											document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString()).value = r.message.purchases_price_name;
											document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString()).checked = true;
											document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString()).checked = true;
										}
									}

									document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + i.toString()).value = '1';

								}
				});


			}
		}
	}

	static sales_order_grid_get_purchases_price_list()
	{

		let selected_parents = '';
		let selected_names = '';
		let selected_items = '';
		let selected_suppliers = '';
		let selected_purchases_conditions = '';
		let selected_purchases_units = '';
		let selected_purchases_currencies = '';
		let selected_qties = '';

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {


			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


				if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value.trim() != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value.trim() != '') &&
						(document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value.trim() != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value.trim() != '') &&
						(((document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value.trim() != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value.trim() != '')) ||
						 ((document.getElementById('sales_to_order_direct_order_action_purchases_condition').value.trim() != null) && (document.getElementById('sales_to_order_direct_order_action_purchases_condition').value.trim() != ''))) &&
						(document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value.trim() != '') && (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value.trim() != '') &&
						(document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).value.trim() != '') && (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).value.trim() != '')) {

						selected_parents += document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString()).value + '#sbsepa#';
						selected_names += document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString()).value + '#sbsepa#';
						selected_items += document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value + '#sbsepa#';

						selected_suppliers += document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value + '#sbsepa#';

							if ((document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value.trim() != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value.trim() != '')) {
								selected_purchases_conditions += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value + '#sbsepa#';
							}else{
								selected_purchases_conditions += document.getElementById('sales_to_order_direct_order_action_purchases_condition').value + '#sbsepa#';
							}


						selected_purchases_units += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value + '#sbsepa#';
						selected_purchases_currencies += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).value + '#sbsepa#';
						selected_qties += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value + '#sbsepa#';
					}
				}
			}

		}

		if (selected_parents != '') {

			let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_get_price_list';

			frappe.call({  	method: method,
							args: { "selected_parents": selected_parents,
									"selected_names": selected_names,
									"selected_items": selected_items,
									"selected_suppliers": selected_suppliers,
									"selected_purchases_conditions": selected_purchases_conditions,
									"selected_purchases_units": selected_purchases_units,
									"selected_purchases_currencies": selected_purchases_currencies,
									"selected_qties": selected_qties
							},
							async: false,
							callback:function(r)	{

								for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {


									if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
										(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
										(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


										if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

											if (!document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).disabled) {

												document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString()).value = '';
												document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString()).value = '';
												document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString()).checked = false;
												document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString()).checked = false;

												if (r.message.detail.length > 0) {

													for (var k = 0; k < r.message.detail.length; k++) {

														if ((r.message.detail[k][0].parent == document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString()).value) &&
															(r.message.detail[k][0].name == document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString()).value )) {

															if (r.message.detail[k][0].purchases_price_name != null && r.message.detail[k][0].purchases_price_name != '') {

																document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString()).value = r.message.detail[k][0].unit_price;
																document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString()).value = r.message.detail[k][0].purchases_price_name;
																document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString()).checked = true;
																document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString()).checked = true;
																break;
															}
														}
													}
												}
											}
										}
									}
								}
							}
			});
		}
	}


	static sales_order_grid_change_supplier_value(id) {

		let s = id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}


		if (document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + s)) {
			document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + s).value = '1';
		}

		let val = document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + s).value.trim();



		if (val == '') {
			document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + s).value = 0;
			document.getElementById('sales_to_order_direct_order_grid_detail_cell_supplier_delai_' + s).innerHTML = '0&nbsp;';

			document.getElementById('sales_to_order_direct_order_grid_detail_price_' + s).value = '';
			document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + s).value = '';
			document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + s).checked = false;
			document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + s).checked = false;


		}else{
			let item_id = document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + s).value.trim();

			let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_get_sales_order_get_supplier_delai';



			frappe.call({  	method: method,
							args: { "item_id": item_id,
									"supplier_id": val.toUpperCase()
							},
							async: false,
							callback:function(r)	{


								if (r.message.error == '0') {

									if ((r.message.supplier_delai != null) && (r.message.supplier_delai != '')) {
										document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + s).value = r.message.supplier_delai;
										document.getElementById('sales_to_order_direct_order_grid_detail_cell_supplier_delai_' + s).innerHTML = r.message.supplier_delai + '&nbsp;';
									}else{
										document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + s).value = '0';
										document.getElementById('sales_to_order_direct_order_grid_detail_cell_supplier_delai_' + s).innerHTML = '0&nbsp;';
									}

									if (r.message.price_exists == 'Y') {
										document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + s).checked = true;
									}else{
										document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + s).checked = false;
									}

									silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_get_purchases_price(s);
								}else{
									document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + s).value = '0';
									document.getElementById('sales_to_order_direct_order_grid_detail_cell_supplier_delai_' + s).innerHTML = '0&nbsp;';

									document.getElementById('sales_to_order_direct_order_grid_detail_price_' + s).value = '';
									document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + s).value = '';
									document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + s).checked = false;
									document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + s).checked = false;


								}
							}
			});

		}
		silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
		silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();

	}


	static sales_order_grid_change_purchases_unit_value(id)
	{
		let s = id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}

		silicon_ioi.doctype.ioiSalesToPurchases.convert_in_purchases_unit(s);
		silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_get_purchases_price(s);

		if (document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + s)) {
			document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + s).value = '1';
		}

	}



	static sales_order_grid_row_click(line_id) {

		let s = line_id;
		s = s.substring(44, s.length);


		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if (i == document.getElementById('sales_to_order_direct_order_grid_detail_selected_line').value) {

				for (var j = 0; j < document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells.length; j++)
				{
					if ((document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].id != 'sales_to_order_direct_order_grid_detail_cell_parent_' + i.toString()) &&
						(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].id != 'sales_to_order_direct_order_grid_detail_cell_idx_' + i.toString()) &&
						(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].id != 'sales_to_order_direct_order_grid_detail_cell_available_qty_' + i.toString()) &&
						(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].id != 'sales_to_order_direct_order_grid_detail_cell_available_scheduled_qty_' + i.toString()) &&
						(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].id != 'sales_to_order_direct_order_grid_detail_cell_shipping_scheduled_datetime_' + i.toString())) {

						if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
							document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = '#FFFFFF';
						}else{
							document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = '#1C2126';
						}

					}else{

						if ((document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].id == 'sales_to_order_direct_order_grid_detail_cell_parent_' + i.toString()) ||
							(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].id == 'sales_to_order_direct_order_grid_detail_cell_idx_' + i.toString()))

						{

							if (document.getElementById('sales_to_order_direct_order_grid_detail_ioistatus_bgcolor_' + i.toString()).value.trim() == '') {

								if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
									document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = '#FFFFFF';
								}else{
									document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = '#1C2126';
								}
							}else{
								document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = document.getElementById('sales_to_order_direct_order_grid_detail_ioistatus_bgcolor_' + i.toString()).value;
							}

						}else if (document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].id == 'sales_to_order_direct_order_grid_detail_cell_available_qty_' + i.toString()) {

							if (document.getElementById('sales_to_order_direct_order_grid_detail_available_bgcolor_' + i.toString()).value.trim() == '') {

								if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
									document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = '#FFFFFF';
								}else{
									document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = '#1C2126';
								}
							}else{
								document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = document.getElementById('sales_to_order_direct_order_grid_detail_available_bgcolor_' + i.toString()).value;
							}
						}else if (document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].id == 'sales_to_order_direct_order_grid_detail_cell_available_scheduled_qty_' + i.toString()) {

							if (document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_bgcolor_' + i.toString()).value.trim() == '') {

								if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
									document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = '#FFFFFF';
								}else{
									document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = '#1C2126';
								}
							}else{
								document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_bgcolor_' + i.toString()).value;
							}
						}

						else if (document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].id == 'sales_to_order_direct_order_grid_detail_cell_shipping_scheduled_datetime_' + i.toString()) {

							if (document.getElementById('sales_to_order_direct_order_grid_detail_shipping_scheduled_datetime_bgcolor_' + i.toString()).value.trim() == '') {

								if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
									document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = '#FFFFFF';
								}else{
									document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = '#1C2126';
								}
							}else{
								document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].cells[j].style.backgroundColor = document.getElementById('sales_to_order_direct_order_grid_detail_shipping_scheduled_datetime_bgcolor_' + i.toString()).value;
							}
						}
					}

				}
			}
		}

		let old_selected_idx = -1;

		if (document.getElementById('sales_to_order_direct_order_grid_detail_selected_line').value != '') {
			old_selected_idx = document.getElementById('sales_to_order_direct_order_grid_detail_selected_line').value;
		}



		let old_item_id = document.getElementById('sales_to_order_direct_order_grid_detail_selected_item_id').value;

		document.getElementById('sales_to_order_direct_order_grid_detail_selected_line').value = s;
		document.getElementById('sales_to_order_direct_order_grid_detail_selected_parent').value = document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + s).value;
		document.getElementById('sales_to_order_direct_order_grid_detail_selected_name').value = document.getElementById('sales_to_order_direct_order_grid_detail_name_' + s).value;
		document.getElementById('sales_to_order_direct_order_grid_detail_selected_item_id').value = document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + s).value;

		// Refresh tabs
		if (old_item_id != document.getElementById('sales_to_order_direct_order_grid_detail_selected_item_id').value) {
			silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
		}

		if (old_selected_idx == -1) {
			silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();

		}else{

			if (silicon_ioi.doctype.ioiSalesToPurchases.force_refresh_po_for_supplier) {
				silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();
				silicon_ioi.doctype.ioiSalesToPurchases.force_refresh_po_for_supplier = false;

			}else{

				if (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + old_selected_idx.toString()).value != document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + document.getElementById('sales_to_order_direct_order_grid_detail_selected_line').value).value ) {
					silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();

				}
			}

		}

		silicon_ioi.doctype.ioiSalesToPurchases.load_purchases_prices();

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail_row_'+s).cells.length; i++)
		{
			if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
				document.getElementById('sales_to_order_direct_order_grid_detail_row_'+s).cells[i].style.backgroundColor = '#b1fcd9';
			}else
			{
				document.getElementById('sales_to_order_direct_order_grid_detail_row_'+s).cells[i].style.backgroundColor = 'green';
			}
		}
	}

	static convert_in_purchases_unit(i)
	{
		if ((document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value == '') || (document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value == '0')) {
			document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value = '';
		}else{

			if ((document.getElementById('sales_to_order_direct_order_grid_detail_unit_id_' + i.toString()).value == '') || (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value == '')) {
				document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value = document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value;
			}else{

				if (document.getElementById('sales_to_order_direct_order_grid_detail_unit_id_' + i.toString()).value == document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value) {
					document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value = document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value;
				}else{

					let qty = parseFloat(document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value)
					let sales_unit = document.getElementById('sales_to_order_direct_order_grid_detail_unit_id_' + i.toString()).value;
					let purchases_unit = document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value;
					let item_id = document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value;



					let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_conversion';

					frappe.call({  	method: method,
									args: { "item_id": item_id,
											"sales_unit_id": sales_unit,
											"purchases_unit_id": purchases_unit,
											"qty": qty
									},
									async: false,
									callback:function(r){

										document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value = r.message.ret_value;
										document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value = r.message.purchases_unit;
									}
					});
				}
			}
		}
	}

	static sales_order_select_all()
	{
		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {
				document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked = true;
			}
		}

		document.getElementById('sales_to_order_direct_order_grid_header_check_all_none').checked = true;
	}

	static sales_order_select_none()
	{
		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {
				document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked = false;
			}
		}

		document.getElementById('sales_to_order_direct_order_grid_header_check_all_none').checked = false;
	}


	static sales_order_fill_with_required()
	{
		if (!silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales order line selected'), indicator: "red"});
			raise;
		}

		let nb_selected = 0;

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {

				if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {
					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {
						nb_selected++;
					}
				}
			}
		}


		let top = 10;

		let msg = '';
		let s = '';

		if (nb_selected == 1) {
			s = __("Number of selected sales order line") + ' <b>' + nb_selected.toString() + '</b>';
		}else{
			s = __("Number of selected sales order lines") + ' <b>' + nb_selected.toString() + '</b>';
		}


		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
		top += 30;


		s = '<font color="blue">' + __('Fill "to order" with the required quantity ?') + '</font>';

		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		top += 30;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';



		frappe.confirm(	msg,
			() => 	{
						silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {
							silicon_ioi.doctype.ioiSalesToPurchases.do_sales_order_fill_with_required();
						});
					},
			() => 	{
					}
		);
	}

	static do_sales_order_fill_with_required()
	{
		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {

				if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_scheduling_mode_' + i.toString()).value == 0) ||
						(document.getElementById('sales_to_order_direct_order_grid_detail_scheduling_mode_' + i.toString()).value == 1)) {

						document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value = document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value;

					}else{
						document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value = '';
					}

					silicon_ioi.doctype.ioiSalesToPurchases.convert_in_purchases_unit(i);

					document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + i.toString()).value = '1';
				}
			}
		}

		silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_get_purchases_price_list();
	}

	static sales_order_fill_with_missing_available()
	{
		if (!silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales order line selected'), indicator: "red"});
			raise;
		}

		let nb_selected = 0;

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {

				if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {
					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {
						nb_selected++;
					}
				}
			}
		}


		let top = 10;

		let msg = '';
		let s = '';

		if (nb_selected == 1) {
			s = __("Number of selected sales order line") + ' <b>' + nb_selected.toString() + '</b>';
		}else{
			s = __("Number of selected sales order lines") + ' <b>' + nb_selected.toString() + '</b>';
		}


		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
		top += 30;


		s = '<font color="blue">' + __('Fill "to order" with the missing available quantity ?') + '</font>';

		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		top += 30;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';



		frappe.confirm(	msg,
			() => 	{
						silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {
							silicon_ioi.doctype.ioiSalesToPurchases.do_sales_order_fill_with_missing_available();
						});
					},
			() => 	{
					}
		);

	}

	static do_sales_order_fill_with_missing_available()
	{
		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


				if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

					let req = 0;

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value != '') && (document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value != null)) {
						req = parseFloat(document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value);
					}

					let avail = 0;

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_available_qty_' + i.toString()).value != '') && (document.getElementById('sales_to_order_direct_order_grid_detail_available_qty_' + i.toString()).value != null)) {
						avail = parseFloat(document.getElementById('sales_to_order_direct_order_grid_detail_available_qty_' + i.toString()).value);
					}

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_scheduling_mode_' + i.toString()).value == 0) ||
						(document.getElementById('sales_to_order_direct_order_grid_detail_scheduling_mode_' + i.toString()).value == 1)) {

						if ((parseFloat(req) - parseFloat(avail)) > 0) {
							document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value = parseFloat(req) - parseFloat(avail);
						}else{
							document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value = '';
						}
					}else{
						document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value = '';
					}

					silicon_ioi.doctype.ioiSalesToPurchases.convert_in_purchases_unit(i);


					document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + i.toString()).value = '1';
				}
			}
		}

		silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_get_purchases_price_list();
	}

	static sales_order_fill_with_missing_scheduled_available()
	{
		if (!silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales order line selected'), indicator: "red"});
			raise;
		}

		let nb_selected = 0;

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {

				if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {
					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {
						nb_selected++;
					}
				}
			}
		}


		let top = 10;

		let msg = '';
		let s = '';

		if (nb_selected == 1) {
			s = __("Number of selected sales order line") + ' <b>' + nb_selected.toString() + '</b>';
		}else{
			s = __("Number of selected sales order lines") + ' <b>' + nb_selected.toString() + '</b>';
		}


		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
		top += 30;


		s = '<font color="blue">' + __('Fill "to order" with the missing scheduled available quantity ?') + '</font>';

		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		top += 30;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';



		frappe.confirm(	msg,
			() => 	{
						silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {
							silicon_ioi.doctype.ioiSalesToPurchases.do_sales_order_fill_with_missing_scheduled_available();
						});
					},
			() => 	{
					}
		);
	}

	static do_sales_order_fill_with_missing_scheduled_available()
	{
		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


				if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

					let req = 0;

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value != '') && (document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value != null)) {
						req = parseFloat(document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value);
					}

					let sched_avail = 0;

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_qty_' + i.toString()).value != '') && (document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_qty_' + i.toString()).value != null)) {
						sched_avail = parseFloat(document.getElementById('sales_to_order_direct_order_grid_detail_available_scheduled_qty_' + i.toString()).value);
					}

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_scheduling_mode_' + i.toString()).value == 0) ||
						(document.getElementById('sales_to_order_direct_order_grid_detail_scheduling_mode_' + i.toString()).value == 1)) {

						if ((parseFloat(req) - parseFloat(sched_avail)) > 0) {
							document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value = parseFloat(req) - parseFloat(sched_avail);
						}else{
							document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value = '';
						}
					}else{
						document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value = '';
					}

					silicon_ioi.doctype.ioiSalesToPurchases.convert_in_purchases_unit(i);


					document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + i.toString()).value = '1';

				}
			}
		}

		silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_get_purchases_price_list();
	}

	static sales_order_fill_with_a_supplier()
	{

		if (!silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales order line selected'), indicator: "red"});
			raise;
		}

		let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
		let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
		let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
		let where = 'ioistatus = 2';
		let order_by = 'name asc';

		let fct_callback = function (return_value) {


			let selected_parents = '';
			let selected_names = '';
			let selected_items = '';


			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

				if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
					(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
					(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {

					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

						selected_parents += document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString()).value + '#sbsepa#';
						selected_names += document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString()).value + '#sbsepa#'
						selected_items += document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value + '#sbsepa#'
					}
				}
			}

			let supplier_delai = 0;
			let price_exists = [];


			if (selected_parents != '') {

				let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_get_sales_order_get_supplier_delai_list';

				frappe.call({  	method: method,
								args: { "selected_parents" : selected_parents,
										"selected_names" : selected_names,
										"selected_items" : selected_items,
										"supplier_id": return_value.toUpperCase()
								},
								async: false,
								callback:function(r)	{

									if (r.message.error == '0') {

										if ((r.message.supplier_delai != null) && (r.message.supplier_delai != '')) {
											supplier_delai = r.message.supplier_delai;
										}

										price_exists = [];


										if (r.message.detail.length > 0) {

											for (var k = 0; k < r.message.detail.length; k++) {
												price_exists[price_exists.length] = [r.message.detail[k][0].parent, r.message.detail[k][0].name, r.message.detail[k][0].item_id, r.message.detail[k][0].price_exists];
											}

										}
									}
								}
				});

			}


			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {


				if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
					(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
					(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

						if (!document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).disabled) {

							document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value = return_value;
							document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + i.toString()).value = '1';

							document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + i.toString()).value = supplier_delai;
							document.getElementById('sales_to_order_direct_order_grid_detail_cell_supplier_delai_' + i.toString()).innerHTML = supplier_delai + '&nbsp;';


							document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString()).checked = false;

							for (var k = 0; k < price_exists.length; k++) {

								if ((price_exists[k][0] == document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString()).value) &&
									(price_exists[k][1] == document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString()).value)) {

									if (price_exists[k][3] == 'Y') {
										document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString()).checked = true;
									}else{
										document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString()).checked = false;
									}

									break;
								}
							}
						}
					}
				}
			}

			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_get_purchases_price_list();
			silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
			silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();



		}

		silicon_ioi.ioiCommon.select_supplier(fields, fields_len, fields_desc, where, order_by, fct_callback);

	}


	static sales_order_update_sched_delivery_datetime()
	{
		if (!silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales order line selected'), indicator: "red"});
			raise;
		}

		let nb_selected = 0;

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


				if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {
					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {
						nb_selected++;
					}
				}
			}
		}


		let top = 10;

		let msg = '';
		let s = '';

		if (nb_selected == 1) {
			s = __("Number of selected sales order line") + ' <b>' + nb_selected.toString() + '</b>';
		}else{
			s = __("Number of selected sales order lines") + ' <b>' + nb_selected.toString() + '</b>';
		}


		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
		top += 30;


		s = '<font color="blue">' + __('Update the scheduled delivery datetime ?') + '</font>';

		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		top += 30;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';



		frappe.confirm(	msg,
			() => 	{
						silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {
							silicon_ioi.doctype.ioiSalesToPurchases.do_sales_order_update_sched_delivery_datetime();
						});
					},
			() => 	{
					}
		);


	}

	static do_sales_order_update_sched_delivery_datetime()
	{
		let selected_parents = '';
		let selected_names = '';
		let selected_items_delai = '';
		let selected_suppliers_delai = '';
		let selected_security_days = ''


		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


				if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

					let item_delai = 0;

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_item_delai_' + i.toString()).value != '') && (document.getElementById('sales_to_order_direct_order_grid_detail_item_delai_' + i.toString()).value != null)) {
						item_delai = parseInt(document.getElementById('sales_to_order_direct_order_grid_detail_item_delai_' + i.toString()).value);
					}

					let supplier_delai = 0;

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + i.toString()).value != '') && (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + i.toString()).value != null)) {
						supplier_delai = parseInt(document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + i.toString()).value);
					}

					let security_days = 0;

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_security_day_' + i.toString()).value != '') && (document.getElementById('sales_to_order_direct_order_grid_detail_security_day_' + i.toString()).value != null)) {
						security_days = parseInt(document.getElementById('sales_to_order_direct_order_grid_detail_security_day_' + i.toString()).value);
					}

					selected_parents += document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString()).value + '#sbsepa#';
					selected_names += document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString()).value + '#sbsepa#'


					selected_items_delai += item_delai.toString() + '#sbsepa#';
					selected_suppliers_delai += supplier_delai.toString() + '#sbsepa#';
					selected_security_days += security_days.toString() + '#sbsepa#';


				}
			}
		}


		if (selected_parents.trim() != '') {

			let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_get_sales_order_compute_date_and_update_scheduled_datetime';

			frappe.call({  	method: method,
							args: { "selected_parents": selected_parents,
									"selected_names": selected_names,
									"selected_items_delai": selected_items_delai,
									"selected_suppliers_delai": selected_suppliers_delai,
									"selected_security_days": selected_security_days
							},
							async: false,
							callback:function(r)	{
								silicon_ioi.doctype.ioiSalesToPurchases.refresh_sales_order();
							}
			});
		}
	}

	static sales_order_update_delivery_datetime()
	{
		if (!silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales order line selected'), indicator: "red"});
			raise;
		}

		let nb_selected = 0;

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


				if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {
					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {
						nb_selected++;
					}
				}
			}
		}


		let top = 10;

		let msg = '';
		let s = '';

		if (nb_selected == 1) {
			s = __("Number of selected sales order line") + ' <b>' + nb_selected.toString() + '</b>';
		}else{
			s = __("Number of selected sales order lines") + ' <b>' + nb_selected.toString() + '</b>';
		}


		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
		top += 30;


		s = '<font color="blue">' + __('Update the delivery datetime ?') + '</font>';

		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		top += 30;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';



		frappe.confirm(	msg,
			() => 	{
						silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {
							silicon_ioi.doctype.ioiSalesToPurchases.do_sales_order_update_delivery_datetime();
						});
					},
			() => 	{
					}
		);

	}

	static do_sales_order_update_delivery_datetime()
	{

		let selected_parents = '';
		let selected_names = '';
		let selected_items_delai = '';
		let selected_suppliers_delai = '';
		let selected_security_days = ''


		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


				if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

					let item_delai = 0;

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_item_delai_' + i.toString()).value != '') && (document.getElementById('sales_to_order_direct_order_grid_detail_item_delai_' + i.toString()).value != null)) {
						item_delai = parseInt(document.getElementById('sales_to_order_direct_order_grid_detail_item_delai_' + i.toString()).value);
					}

					let supplier_delai = 0;

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + i.toString()).value != '') && (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + i.toString()).value != null)) {
						supplier_delai = parseInt(document.getElementById('sales_to_order_direct_order_grid_detail_supplier_delai_' + i.toString()).value);
					}

					let security_days = 0;

					if ((document.getElementById('sales_to_order_direct_order_grid_detail_security_day_' + i.toString()).value != '') && (document.getElementById('sales_to_order_direct_order_grid_detail_security_day_' + i.toString()).value != null)) {
						security_days = parseInt(document.getElementById('sales_to_order_direct_order_grid_detail_security_day_' + i.toString()).value);
					}

					selected_parents += document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString()).value + '#sbsepa#';
					selected_names += document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString()).value + '#sbsepa#'


					selected_items_delai += item_delai.toString() + '#sbsepa#';
					selected_suppliers_delai += supplier_delai.toString() + '#sbsepa#';
					selected_security_days += security_days.toString() + '#sbsepa#';


				}
			}
		}




		if (selected_parents.trim() != '') {

			let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_get_sales_order_compute_date_and_update_shipping_datetime';

			frappe.call({  	method: method,
							args: { "selected_parents": selected_parents,
									"selected_names": selected_names,
									"selected_items_delai": selected_items_delai,
									"selected_suppliers_delai": selected_suppliers_delai,
									"selected_security_days": selected_security_days
							},
							async: false,
							callback:function(r)	{
								silicon_ioi.doctype.ioiSalesToPurchases.refresh_sales_order();
							}
			});
		}
	}

	static sales_order_clear_toorder()
	{
		if (!silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales order line selected'), indicator: "red"});
			raise;
		}

		let nb_selected = 0;

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


				if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {
					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {
						nb_selected++;
					}
				}
			}
		}


		let top = 10;

		let msg = '';
		let s = '';

		if (nb_selected == 1) {
			s = __("Number of selected sales order line") + ' <b>' + nb_selected.toString() + '</b>';
		}else{
			s = __("Number of selected sales order lines") + ' <b>' + nb_selected.toString() + '</b>';
		}


		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
		top += 30;


		s = '<font color="blue">' + __('Clear the "To order" quantity ?') + '</font>';

		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		top += 30;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';



		frappe.confirm(	msg,
			() => 	{
						silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {
							silicon_ioi.doctype.ioiSalesToPurchases.do_sales_order_clear_toorder();
						});
					},
			() => 	{
					}
		);

	}

	static do_sales_order_clear_toorder()
	{
		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


				if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

					if (document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value != '') {
						document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value = '';
						document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value = '';
						document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + i.toString()).value = '1';
					}
				}
			}
		}

	}

	static sales_order_clear_supplier()
	{
		if (!silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales order line selected'), indicator: "red"});
			raise;
		}

		let nb_selected = 0;

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


				if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {
					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {
						nb_selected++;
					}
				}
			}
		}


		let top = 10;

		let msg = '';
		let s = '';

		if (nb_selected == 1) {
			s = __("Number of selected sales order line") + ' <b>' + nb_selected.toString() + '</b>';
		}else{
			s = __("Number of selected sales order lines") + ' <b>' + nb_selected.toString() + '</b>';
		}


		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
		top += 30;


		s = '<font color="blue">' + __('Clear the supplier ?') + '</font>';

		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		top += 30;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';



		frappe.confirm(	msg,
			() => 	{
						silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {
							silicon_ioi.doctype.ioiSalesToPurchases.do_sales_order_clear_supplier();
						});
					},
			() => 	{
					}
		);

	}

	static do_sales_order_clear_supplier()
	{
		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


				if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

					if (!document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).disabled) {

						if (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value != '') {
							document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value = '';
							document.getElementById('sales_to_order_direct_order_grid_detail_row_modified_' + i.toString()).value = '1';

							document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString()).checked = false;
							document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString()).checked = false;
							document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString()).value = '';
							document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString()).value = '';
						}
					}
				}
			}
		}

		silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
		silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();
	}


	static sales_order_create_pp()
	{
		if (!silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales order line selected'), indicator: "red"});
			raise;
		}

		let nb_selected = 0;

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {


				if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {
					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {
						nb_selected++;
					}
				}
			}
		}


		let top = 10;

		let msg = '';
		let s = '';

		if (nb_selected == 1) {
			s = __("Number of selected sales order line") + ' <b>' + nb_selected.toString() + '</b>';
		}else{
			s = __("Number of selected sales order lines") + ' <b>' + nb_selected.toString() + '</b>';
		}


		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
		top += 20;

		s = __("To order qty has to be different of zero");

		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
		top += 30;


		if (nb_selected == 1) {
			s = '<font color="blue">' + __('Create a purchases proposal ?') + '</font>';
		}else{
			s = '<font color="blue">' + __('Create') + ' ' + nb_selected.toString() + ' ' + __('purchases proposals ?') + '</font>';
		}

		msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

		top += 30;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';



		frappe.confirm(	msg,
			() => 	{
						silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {
							silicon_ioi.doctype.ioiSalesToPurchases.do_sales_order_create_pp();
						});
					},
			() => 	{
					}
		);
	}


	static do_sales_order_create_pp()
	{
		let selected_parents = '';
		let selected_names = '';

		let selected_items = '';
		let selected_items_desc = '';
		let selected_manufacturer_refs = '';
		let selected_sales_toorders = '';
		let selected_sales_units = '';
		let selected_sales_required = '';

		let selected_purchases_toorders = '';
		let selected_purchases_units = '';
		let selected_suppliers = '';
		let selected_price_names = '';
		let selected_currencies = '';
		let selected_purchases_conditions = '';

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {

				if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {

					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value.trim() != '') && (parseFloat(document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value) != 0)) {

							selected_parents += document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString()).value + '#sbsepa#';
							selected_names += document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString()).value + '#sbsepa#'


							selected_items += document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value + '#sbsepa#';
							selected_items_desc += document.getElementById('sales_to_order_direct_order_grid_detail_item_description_' + i.toString()).value + '#sbsepa#'

							selected_manufacturer_refs += document.getElementById('sales_to_order_direct_order_grid_detail_manufacturer_ref_' + i.toString()).value + '#sbsepa#';

							selected_sales_toorders += document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value + '#sbsepa#'
							selected_sales_units += document.getElementById('sales_to_order_direct_order_grid_detail_unit_id_' + i.toString()).value + '#sbsepa#'

							selected_sales_required += document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value + '#sbsepa#'

							selected_purchases_toorders += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value + '#sbsepa#'
							selected_purchases_units += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value + '#sbsepa#'

							selected_suppliers += document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value + '#sbsepa#'
							selected_currencies += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).value + '#sbsepa#'
							selected_purchases_conditions += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value + '#sbsepa#'
						}

					}
				}
			}
		}

		if (selected_items != '') {


			let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_create_purchases_proposal';

			frappe.call({  	method: method,
							args: {
								"purchases_journal": document.getElementById('sales_to_order_direct_order_action_journal').value,
								"selected_parents": selected_parents,
								"selected_names": selected_names,
								"selected_items": selected_items,
								"selected_items_desc": selected_items_desc,
								"selected_manufacturer_refs" : selected_manufacturer_refs,
								"selected_sales_toorders": selected_sales_toorders,
								"selected_sales_units": selected_sales_units,
								"selected_sales_required" : selected_sales_required,
								"selected_purchases_toorders": selected_purchases_toorders,
								"selected_purchases_units": selected_purchases_units,
								"selected_suppliers": selected_suppliers,
								"selected_currencies": selected_currencies,
								"selected_purchases_conditions": selected_purchases_conditions

							},
							async: false,
							callback:function(r)	{
								frappe.msgprint({title: __("Message"), message: __('Purchases proposal(s) has/bave been created'), indicator: "blue"});
								silicon_ioi.doctype.ioiSalesToPurchases.refresh_sales_order();

							}
			});
		}else{
			frappe.msgprint({title: __("Message"), message: __('No purchases proposal to create'), indicator: "red"});
			raise;

		}
	}




	static sales_order_grid_line_selected()
	{
		let ret_val = true;

		if (!document.getElementById('sales_to_order_direct_order_grid_detail')) {
			ret_val = false;
		}else {
			if (document.getElementById('sales_to_order_direct_order_grid_detail').rows.length == 0) {
				ret_val = false;
			}else {

				let at_least_one_checked = false;

				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

					if (!document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()))	{
						ret_val = false;
						break;
					}else {

						if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
							(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
							(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {

							if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {
								at_least_one_checked = true;
								break;
							}
						}
					}
				}

				if (!at_least_one_checked) {
					ret_val = false;
				}
			}
		}

		return ret_val;
	}

	static build_sales_order_tabs(html_field)
	{

		// tab 1
		if (document.getElementById('sales_to_order_direct_order_purchases_prices_tab')) {
			document.getElementById('sales_to_order_direct_order_purchases_prices_tab').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_purchases_prices_tab_label')) {
			document.getElementById('sales_to_order_direct_order_purchases_prices_tab_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_purchases_prices_tab_underline')) {
			document.getElementById('sales_to_order_direct_purchases_prices_tab_underline').remove();
		}

		// tab 2
		if (document.getElementById('sales_to_order_direct_order_po_for_item_tab')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_tab').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_tab_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_tab_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_tab_underline')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_tab_underline').remove();
		}


		// tab 3
		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_tab')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_tab').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_tab_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_tab_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_tab_underline')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_tab_underline').remove();
		}

		// tab 4
		if (document.getElementById('sales_to_order_direct_order_po_tab')) {
			document.getElementById('sales_to_order_direct_order_po_tab').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_tab_label')) {
			document.getElementById('sales_to_order_direct_order_po_tab_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_tab_underline')) {
			document.getElementById('sales_to_order_direct_order_po_tab_underline').remove();
		}


		// Contents
		if (document.getElementById('sales_to_order_direct_order_purchases_prices_content')) {
			document.getElementById('sales_to_order_direct_order_purchases_prices_content').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_content')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_content').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_content')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_content').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_content_left')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_content_left').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_content_right')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_content_right').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_tabs_panel')) {
			document.getElementById('sales_to_order_direct_order_tabs_panel').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_tabs_panel_title')) {
			document.getElementById('sales_to_order_direct_order_tabs_panel_title').remove();
		}


		let html = '';


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div id="sales_to_order_direct_order_tabs_panel" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="sales_to_order_direct_order_tabs_panel_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Related purchases orders, prices") + '</b></label>';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'


		html += '<div style="overflow-x: auto; height:35px;">';

		html += '	<div style="position:relative;"> ';

		// tab 1
		html += '		<div id="sales_to_order_direct_order_purchases_prices_tab" style="position:absolute; top:0px; left:0px; width:150px; height:30px;"> ';
		html += '			<label align="center" id="sales_to_order_direct_order_purchases_prices_tab_label" style="position:absolute; top:2px;width:150px;"><font size="2">' + __("Purchases prices") + '</font></label>';
		html += '			<div id="sales_to_order_direct_order_purchases_prices_tab_underline" style="position:absolute; top:25px; left:0px; width:100%; height:1px; background-color:#2490EF;display:none;" data-custom-section-head="true"></div>';
		html += '		</div>';


		// tab 2
		html += '		<div id="sales_to_order_direct_order_po_for_item_tab" style="position:relative; top:0px; left:150px; width:180px; height:30px;"> ';
		html += '			<label align="center" id="sales_to_order_direct_order_po_for_item_tab_label" style="position:absolute; top:2px;width:180px;"><font size="2">' + __("Same item, to handle") + '</font></label>';
		html += '			<div id="sales_to_order_direct_order_po_for_item_tab_underline" style="position:absolute; top:25px; left:0px; width:100%; height:1px; background-color:#2490EF;" data-custom-section-head="true"></div>';
		html += '		</div>';


		// tab 3
		html += '		<div id="sales_to_order_direct_order_po_for_supplier_tab" style="position:absolute; top:0px; left:330px; width:210px; height:30px;"> ';
		html += '			<label align="center" id="sales_to_order_direct_order_po_for_supplier_tab_label" style="position:absolute; top:2px;width:210px;"><font size="2">' + __("Orders, same supplier, to handle") + '</font></label>';
		html += '			<div id="sales_to_order_direct_order_po_for_supplier_tab_underline" style="position:absolute; top:25px; left:0px; width:100%; height:1px; background-color:#2490EF;display:none;" data-custom-section-head="true"></div>';
		html += '		</div>';


		// tab 4
		html += '		<div id="sales_to_order_direct_order_po_tab" style="position:absolute; top:0px; left:540px; width:180px; height:30px;"> ';
		html += '			<label align="center" id="sales_to_order_direct_order_po_tab_label" style="position:absolute; top:2px;width:180px;"><font size="2">' + __("Purchases Order") + '</font></label>';
		html += '			<div id="sales_to_order_direct_order_po_tab_underline" style="position:absolute; top:25px; left:0px; width:100%; height:1px; background-color:#2490EF;display:none;" data-custom-section-head="true"></div>';
		html += '		</div>';


		html += '	</div>';
		html += '</div>';


		html += '<div id="sales_to_order_direct_order_purchases_prices_content" style="overflow-x: auto;height:425px;"></div>';

		html += '<div id="sales_to_order_direct_order_po_for_item_content" style="overflow-x: auto;height:425px;"></div>';

		html += '<div id="sales_to_order_direct_order_po_for_supplier_content" style="overflow-x: auto;height:480px;">';

		html += '	<div id="sales_to_order_direct_order_po_for_supplier_content_left" 	style="float:left; overflow-x: auto;width: 100%;height:100%;"></div>';

		html += '</div>';

		html += '<div id="sales_to_order_direct_order_po_for_supplier_content_right" style="overflow-x: auto;width: 100%;height:425px;"></div>';

		return html;

	}

	static tab_mouseover(obj)
	{
		obj.style.cursor = 'pointer';
	}

	static tab_mouseleave(obj)
	{
		obj.style.cursor = 'none';
	}

	static tab_click(obj)
	{
		// tab 1
		document.getElementById('sales_to_order_direct_order_po_for_item_tab_underline').style.display = 'none';
		document.getElementById('sales_to_order_direct_order_po_for_item_tab_label').style.fontWeight = 'normal';
		document.getElementById('sales_to_order_direct_order_po_for_item_content').style.display = 'none';

		// tab 2
		document.getElementById('sales_to_order_direct_order_po_for_supplier_tab_underline').style.display = 'none';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_tab_label').style.fontWeight = 'normal';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_content').style.display = 'none';

		// tab 3
		document.getElementById('sales_to_order_direct_order_purchases_prices_tab_underline').style.display = 'none';
		document.getElementById('sales_to_order_direct_order_purchases_prices_tab_label').style.fontWeight = 'normal';
		document.getElementById('sales_to_order_direct_order_purchases_prices_content').style.display = 'none';

		// tab 4
		document.getElementById('sales_to_order_direct_order_po_tab_underline').style.display = 'none';
		document.getElementById('sales_to_order_direct_order_po_tab_label').style.fontWeight = 'normal';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_content_right').style.display = 'none';

		if (obj.id == 'sales_to_order_direct_order_po_for_item_tab')
		{
			// tab 1
			document.getElementById('sales_to_order_direct_order_po_for_item_tab_underline').style.display = 'block';
			document.getElementById('sales_to_order_direct_order_po_for_item_tab_label').style.fontWeight = 'bold';
			document.getElementById('sales_to_order_direct_order_po_for_item_content').style.display = 'block';

		}else if (obj.id == 'sales_to_order_direct_order_po_for_supplier_tab')
		{
			// tab 2
			document.getElementById('sales_to_order_direct_order_po_for_supplier_tab_underline').style.display = 'block';
			document.getElementById('sales_to_order_direct_order_po_for_supplier_tab_label').style.fontWeight = 'bold';
			document.getElementById('sales_to_order_direct_order_po_for_supplier_content').style.display = 'block';

		}else if (obj.id == 'sales_to_order_direct_order_purchases_prices_tab')
		{
			// tab 3
			document.getElementById('sales_to_order_direct_order_purchases_prices_tab_underline').style.display = 'block';
			document.getElementById('sales_to_order_direct_order_purchases_prices_tab_label').style.fontWeight = 'bold';
			document.getElementById('sales_to_order_direct_order_purchases_prices_content').style.display = 'block';

		} else if (obj.id == 'sales_to_order_direct_order_po_tab')
		{
			// tab 4
			document.getElementById('sales_to_order_direct_order_po_tab_underline').style.display = 'block';
			document.getElementById('sales_to_order_direct_order_po_tab_label').style.fontWeight = 'bold';
			document.getElementById('sales_to_order_direct_order_po_for_supplier_content_right').style.display = 'block';
		}
	}



	// ********************************************************************************************************************************************************************************************************
	//
	// Tab PO for this item
	//
	// ********************************************************************************************************************************************************************************************************

	static build_po_for_item() {


		if (document.getElementById('sales_to_order_direct_order_po_for_item_grid')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_grid').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail')) {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_po_for_item_row_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_row_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_item_parent_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_parent_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_po_for_item_name_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_name_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + i.toString()).remove();
				}

			}

			document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_po_for_item_grid_content')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_grid_content').remove();
		}



		if (document.getElementById('sales_to_order_direct_order_po_for_item_params')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_params').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_po_for_item_params_supplier_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_params_supplier_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_params_supplier_id')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_params_supplier_id').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_params_supplier_button')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_params_supplier_button').remove();
		}



		if (document.getElementById('sales_to_order_direct_order_po_for_item_params_topn_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_params_topn_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_params_topn')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_params_topn').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_params_search_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_params_search_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_params_search')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_params_search').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_orderby_field')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_orderby_field').remove();
		}
		if (document.getElementById('sales_to_order_direct_order_po_for_item_orderby_dir')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_orderby_dir').remove();
		}



		let html = '';


		html = '<div id="sales_to_order_direct_order_po_for_item_params" style="overflow-x: auto;height:80px">';

		html += '	<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '	</div>'

		// Supplier
		html += '	<div style="position: relative; top: 0px; left: 0px; width:170px;">';
		html += '		<label id="sales_to_order_direct_order_po_for_item_params_supplier_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Supplier") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';
		html += '			<input id="sales_to_order_direct_order_po_for_item_params_supplier_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 180px; width: 30px; height: 25px;"> ';
		html +='			<button id="sales_to_order_direct_order_po_for_item_params_supplier_button" style="position:absolute; height: 30px; width: 30px; z-index:10;border: none; outline:none;" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Search
		html += '	<div style="position: relative; top: 0px; left: 220px; width:250px;">';
		html += '		<label id="sales_to_order_direct_order_po_for_item_params_search_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 250px; height: 25px;"> ';
		html += '			<input id="sales_to_order_direct_order_po_for_item_params_search" type="text"  class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		// Top N
		html += '	<div style="position: relative; top: 0px; left: 480px; width:140px;">';
		html += '		<label id="sales_to_order_direct_order_po_for_item_params_topn_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Number of record") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 140px; height: 25px;"> ';
		html += '			<input id="sales_to_order_direct_order_po_for_item_params_topn" type="number" step="any" class="input-with-feedback form-control bold" value="100">';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div id="sales_to_order_direct_order_po_for_item_grid_content" style="overflow-x: auto;height:285px">';

		html += '<input id="sales_to_order_direct_order_po_for_item_orderby_field" type="hidden" value="expected_arrival_on">';
		html += '<input id="sales_to_order_direct_order_po_for_item_orderby_dir"   type="hidden" value="desc">';


		html += '<table id="sales_to_order_direct_order_po_for_item_grid" border=1 style="border: 1px solid #E8EAEB" width=4940px data-custom-grid="true">';

		html += '<tr style="height:30px">';
		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';

		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_order_supplier_id" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Order supplier") + 					'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_order_supplier_id" style="width:30px; height:8px" align="right"></label></b></td>';

		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_item_id" width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Item") + 										'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_item_id"	style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_ordered_qty" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Ordered qty") + 							'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_ordered_qty"	style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_item_description" width=350px style="vertical-align: middle;"><b>&nbsp;' + __("Item description") + 				'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_item_description" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_expected_arrival_on" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Expected arrival on") + 			'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_expected_arrival_on" style="width:30px; height:8px" align="right">&uarr;</label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_unit_price" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Unit price") + 							'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_unit_price" style="width:30px; height:8px" align="right"></label></b></td>';

		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_identification" width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Identification") + 					'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_identification" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_min_order_qty" width=140px style="vertical-align: middle;"><b>&nbsp;' + __("Min order qty") + 						'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_min_order_qty" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_base_discount" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Base disc.") + 							'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_base_discount" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_extra_discount" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Extra disc.") + 						'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_extra_discount" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_currency_id" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Curr.") + 								'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_currency_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_order_supplier_name" width=350px style="vertical-align: middle;"><b>&nbsp;' + __("Order supplier name") + 			'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_order_supplier_name" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_required_qty" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Required qty") + 						'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_required_qty" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_asked_arrival_date" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Asked arrival date") +   			'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_asked_arrival_date"	style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_external_ref" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("External ref.") + 						'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_external_ref" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_free_part_qty" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Free part qty") + 						'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_free_part_qty" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_document_date" width=170px style="vertical-align: middle;"><b>&nbsp;' + __("Document date") + 						'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_document_date" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_base_discount_h" width=140px style="vertical-align: middle;"><b>&nbsp;' + __("Base disc. (h)") + 					'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_base_discount_h" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_extra_discount_h" width=140px style="vertical-align: middle;"><b>&nbsp;' + __("Extra disc. (h)") + 					'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_extra_discount_h" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_author_id" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Author") + 									'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_author_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_responsible_id" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Responsible") + 						'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_responsible_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_delivery_supplier_id" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Delivery supplier") + 			'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_delivery_supplier_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_delivery_supplier_name" width=350px style="vertical-align: middle;"><b>&nbsp;' + __("Delivery supplier name") + 	'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_delivery_supplier_name" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_invoice_supplier_id" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Invoice supplier") + 				'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_invoice_supplier_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_item_grid_col_invoice_supplier_name" width=350px style="vertical-align: middle;"><b>&nbsp;' + __("invoice supplier name") + 		'<label id="sales_to_order_direct_order_po_for_item_grid_col_label_invoice_supplier_name" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '</tr>';
		html += '</table>';


		html += '<table id="sales_to_order_direct_order_po_for_item_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=4940px>';
		html += '<tr style="height:30px">';
		html += '<td width=30px>&nbsp;</td>';
		html += '<td width=200px>&nbsp;</td>';
		html += '<td width=250px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=350px>&nbsp;</td>';
		html += '<td width=200px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';

		html += '<td width=250px>&nbsp;</td>';
		html += '<td width=140px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=100px>&nbsp;</td>';
		html += '<td width=350px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=200px>&nbsp;</td>';
		html += '<td width=200px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=170px>&nbsp;</td>';
		html += '<td width=140px>&nbsp;</td>';
		html += '<td width=140px>&nbsp;</td>';
		html += '<td width=200px>&nbsp;</td>';
		html += '<td width=200px>&nbsp;</td>';
		html += '<td width=200px>&nbsp;</td>';
		html += '<td width=350px>&nbsp;</td>';
		html += '<td width=200px>&nbsp;</td>';
		html += '<td width=350px>&nbsp;</td>';
		html += '</tr>';
		html += '</table>';

		html += '</div>';

		html += '<input id="sales_to_order_direct_order_po_for_item_selected_parent" type="hidden" value="">';
		html += '<input id="sales_to_order_direct_order_po_for_item_selected_name" type="hidden" value="">';


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:40px;">';

		// Merge on this line
		html += '	<div style="position: relative; top: 2px; left: 0px; width:250px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_po_for_item_button_merge_on_this_line" class="btn btn-default ellipsis" style="height: 35px; width: 250px;" onclick="">' + __("Merge on this line") + '</button>';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';




		document.getElementById('sales_to_order_direct_order_po_for_item_content').innerHTML = html;


		silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {

			let fct_keydown_search = function(event) {

				if (event.keyCode == 13)
				{
					silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
					return false;
				}

			};

			let fct_keydown = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};


			let fct_keyup = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};

			let fct_change = function(event) {

				silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
			};

			let fct_click_search = function(event) {

				silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();

			};

			// Supplier
			let fct_supplier_button_click = function() {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					document.getElementById("sales_to_order_direct_order_po_for_item_params_supplier_id").value = return_value;
					document.getElementById('sales_to_order_direct_order_po_for_item_params_supplier_id').focus();

					silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
				}

				silicon_ioi.ioiCommon.select_supplier(fields, fields_len, fields_desc, where, order_by, fct_callback);
			}

			document.getElementById("sales_to_order_direct_order_po_for_item_params_supplier_button").onclick = fct_supplier_button_click;
			document.getElementById("sales_to_order_direct_order_po_for_item_params_supplier_button").onkeydown = fct_keydown;
			document.getElementById('sales_to_order_direct_order_po_for_item_params_supplier_id').onkeydown = fct_keydown_search;
			document.getElementById('sales_to_order_direct_order_po_for_item_params_supplier_id').onkeyup = fct_keyup;


			document.getElementById('sales_to_order_direct_order_po_for_item_params_topn').onkeydown = fct_keydown_search;
			document.getElementById('sales_to_order_direct_order_po_for_item_params_topn').onkeyup = fct_keyup;

			document.getElementById('sales_to_order_direct_order_po_for_item_params_search').onkeydown = fct_keydown_search;
			document.getElementById('sales_to_order_direct_order_po_for_item_params_search').onkeyup = fct_keyup;

			let fct_col_click = function () {
				silicon_ioi.doctype.ioiSalesToPurchases.po_for_item_col_click(this);
			};
			let fct_col_over = function () {
				silicon_ioi.doctype.ioiSalesToPurchases.po_for_item_col_mouse_over(this);
			};
			let fct_col_leave = function () {
				silicon_ioi.doctype.ioiSalesToPurchases.po_for_item_col_mouse_leave(this);
			};

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_item_grid').rows[0].cells.length; i++) {

				document.getElementById('sales_to_order_direct_order_po_for_item_grid').rows[0].cells[i].onclick = fct_col_click;
				document.getElementById('sales_to_order_direct_order_po_for_item_grid').rows[0].cells[i].onmouseover = fct_col_over;
				document.getElementById('sales_to_order_direct_order_po_for_item_grid').rows[0].cells[i].onmouseleave = fct_col_leave;
			}

			let fct_po_for_item_merge_on_this_line_button_click = function() {
				silicon_ioi.doctype.ioiSalesToPurchases.po_for_item_merge_on_this_line(this);
			}

			document.getElementById("sales_to_order_direct_order_po_for_item_button_merge_on_this_line").onclick = fct_po_for_item_merge_on_this_line_button_click;






			silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
		});

	}

	static po_for_item_col_click(obj)
	{
		let s = obj.id;
		s = s.substring(49, s.length);

		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_item_id').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_ordered_qty').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_item_description').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_expected_arrival_on').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_unit_price').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_order_supplier_id').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_identification').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_min_order_qty').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_base_discount').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_extra_discount').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_currency_id').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_order_supplier_name').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_required_qty').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_asked_arrival_date').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_external_ref').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_free_part_qty').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_document_date').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_base_discount_h').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_extra_discount_h').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_author_id').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_responsible_id').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_delivery_supplier_id').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_delivery_supplier_name').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_invoice_supplier_id').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_invoice_supplier_name').innerHTML = '';


		if (document.getElementById('sales_to_order_direct_order_po_for_item_orderby_field').value == s)
		{
			if (document.getElementById('sales_to_order_direct_order_po_for_item_orderby_dir').value == 'desc')
			{	document.getElementById('sales_to_order_direct_order_po_for_item_orderby_dir').value = 'asc';

				document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_' + s).innerHTML = '&darr;';
			}else
			{	document.getElementById('sales_to_order_direct_order_po_for_item_orderby_dir').value = 'desc';
				document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_' + s).innerHTML = '&uarr;';
			}
		}else
		{	document.getElementById('sales_to_order_direct_order_po_for_item_orderby_field').value = s;
			document.getElementById('sales_to_order_direct_order_po_for_item_orderby_dir').value = 'desc';
			document.getElementById('sales_to_order_direct_order_po_for_item_grid_col_label_' + s).innerHTML = '&uarr;';
		}

		silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();

	}

	static po_for_item_col_mouse_over(obj)
	{
		obj.style.cursor = 'pointer';
	}

	static po_for_item_col_mouse_leave(obj)
	{
		obj.style.cursor = 'none';
	}

	static load_po_for_item()
	{

		if (document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail')) {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_po_for_item_row_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_row_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_item_parent_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_parent_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_item_name_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_name_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_item_row_ioistatus_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_row_ioistatus_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_item_bgcolor_ioistatus_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_bgcolor_ioistatus_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_po_for_item_row_pk_formatted_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_row_pk_formatted_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_item_bgcolor_pk_formatted_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_bgcolor_pk_formatted_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_po_for_item_row_supplier_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_row_supplier_' + i.toString()).remove();
				}


			}

			document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_selected_parent')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_selected_parent').value = '';
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_selected_name')) {
			document.getElementById('sales_to_order_direct_order_po_for_item_selected_name').value = '';
		}


		let html = '';


		let color_row = false;
		html += '<table id="sales_to_order_direct_order_po_for_item_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=4940px>';

		if (((!document.getElementById('sales_to_order_direct_order_grid_detail_selected_item_id')) || ((document.getElementById('sales_to_order_direct_order_grid_detail_selected_item_id')) && (document.getElementById('sales_to_order_direct_order_grid_detail_selected_item_id').value.trim() == '')))) {

			html += '<tr style="height:30px">';
			html += '<td width=30px>&nbsp;</td>';
			html += '<td width=200px>&nbsp;</td>';
			html += '<td width=250px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=350px>&nbsp;</td>';
			html += '<td width=200px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=250px>&nbsp;</td>';
			html += '<td width=140px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=100px>&nbsp;</td>';
			html += '<td width=350px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=200px>&nbsp;</td>';
			html += '<td width=200px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=170px>&nbsp;</td>';
			html += '<td width=140px>&nbsp;</td>';
			html += '<td width=140px>&nbsp;</td>';
			html += '<td width=200px>&nbsp;</td>';
			html += '<td width=200px>&nbsp;</td>';
			html += '<td width=200px>&nbsp;</td>';
			html += '<td width=350px>&nbsp;</td>';
			html += '<td width=200px>&nbsp;</td>';
			html += '<td width=350px>&nbsp;</td>';
			html += '</tr>';

		}else {

			let method = 'silicon_ioi.common.sales_to_purchases.direct_order_po_for_this_item';

			frappe.call({  	method: method,
							args: {
									"item_id" : document.getElementById('sales_to_order_direct_order_grid_detail_selected_item_id').value,
									"supplier_id": document.getElementById('sales_to_order_direct_order_po_for_item_params_supplier_id').value,
									"search":  document.getElementById('sales_to_order_direct_order_po_for_item_params_search').value,
									"order_field": document.getElementById('sales_to_order_direct_order_po_for_item_orderby_field').value,
									"order_dir": document.getElementById('sales_to_order_direct_order_po_for_item_orderby_dir').value,
									"topn": document.getElementById('sales_to_order_direct_order_po_for_item_params_topn').value
							},
							async: false,
							callback:function(r)	{


								if (r.message.length > 0)
								{

									color_row = true;

									for (var i = 0; i < r.message.length; i++)
									{
										html += '<tr id="sales_to_order_direct_order_po_for_item_row_' + i.toString() + '" style="height:30px">';
										html += '<input id="sales_to_order_direct_order_po_for_item_parent_' + i.toString() + '" type="hidden" value="' + r.message[i].parent + '">';
										html += '<input id="sales_to_order_direct_order_po_for_item_name_' + i.toString() + '" type="hidden" value="' + r.message[i].name + '">';


										html += '<td width=30px align="center" style="vertical-align: middle;">';


										html += '<input type="checkbox" id="sales_to_order_direct_order_po_for_item_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" ';

										if (i == 0) {
											html += "checked ";
										}

										html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
										html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
										html += '>';
										html += '</td>';


										if (r.message[i].order_supplier_id != null)
										{
											if (r.message[i].order_supplier_id.toUpperCase() != document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + document.getElementById('sales_to_order_direct_order_grid_detail_selected_line').value).value.toUpperCase()) {

												html += '<td id="sales_to_order_direct_order_po_for_item_row_supplier_' + i.toString() + '" width=200px style="vertical-align: middle;color:#000000;background-color:#FCC1A2;">&nbsp;' + r.message[i].order_supplier_id + '</td>';
											}else{
												html += '<td id="sales_to_order_direct_order_po_for_item_row_supplier_' + i.toString() + '" width=200px style="vertical-align: middle;color:#000000;">&nbsp;' + r.message[i].order_supplier_id + '</td>';
											}
										}else
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
										}



										if (r.message[i].item_id != null)
										{
											html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].item_id + '</td>';
										}else
										{	html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].ordered_qty != null) && (r.message[i].ordered_qty != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].ordered_qty + '&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].item_description != null)
										{	html += '<td width=350px style="vertical-align: middle;">&nbsp;' + r.message[i].item_description + '</td>';
										}else
										{	html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].expected_arrival_on != null)
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].expected_arrival_on + '</td>';
										}else
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].unit_price != null) && (r.message[i].unit_price != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].unit_price + '&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}





										if (r.message[i].pk_formatted != null)
										{
											if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
												html += '<td id="sales_to_order_direct_order_po_for_item_row_pk_formatted_' + i.toString() + '" bgcolor="' + r.message[i].ioistatus_bgcolor + '" width=250px style="vertical-align: middle;color:#000000;">&nbsp;' + r.message[i].pk_formatted + '</td>';
											}else{
												html += '<td id="sales_to_order_direct_order_po_for_item_row_pk_formatted_' + i.toString() + '" bgcolor="' + r.message[i].ioistatus_bgcolor + '" width=250px style="vertical-align: middle;"><font color="#000000">&nbsp;' + r.message[i].pk_formatted + '</font></td>';
											}

											html += '<input id="sales_to_order_direct_order_po_for_item_bgcolor_pk_formatted_' + i.toString() + '" type="hidden" value="' + r.message[i].ioistatus_bgcolor + '">';

										}else
										{	html += '<td id="sales_to_order_direct_order_po_for_item_row_pk_formatted_' + i.toString() + '" width=250px style="vertical-align: middle;">&nbsp;</td>';
											html += '<input id="sales_to_order_direct_order_po_for_item_bgcolor_pk_formatted_' + i.toString() + '" type="hidden" value="">';
										}



										if ((r.message[i].min_order_qty != null) && (r.message[i].min_order_qty != 0))
										{	html += '<td width=140px style="vertical-align: middle;" align="right">' + r.message[i].min_order_qty + '&nbsp;</td>';
										}else
										{	html += '<td width=140px style="vertical-align: middle;">&nbsp;</td>';
										}


										if ((r.message[i].base_discount != null) && (r.message[i].base_discount != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].base_discount + '&nbsp;%&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}


										if ((r.message[i].extra_discount != null) && (r.message[i].extra_discount != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].extra_discount + '&nbsp;%&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].currency_id != null)
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].currency_id + '</td>';
										}else
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].order_supplier_name != null)
										{	html += '<td width=350px style="vertical-align: middle;">&nbsp;' + r.message[i].order_supplier_name + '</td>';
										}else
										{	html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].required_qty != null) && (r.message[i].required_qty != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].required_qty + '&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].asked_arrival_date != null)
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].asked_arrival_date + '</td>';
										}else
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].external_ref != null)
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].external_ref + '</td>';
										}else
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].free_part_qty != null) && (r.message[i].free_part_qty != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].free_part_qty + '&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}


										if (r.message[i].document_date != null)
										{	html += '<td width=170px style="vertical-align: middle;">&nbsp;' + r.message[i].document_date + '</td>';
										}else
										{	html += '<td width=170px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].base_discount_h != null) && (r.message[i].base_discount_h != 0))
										{	html += '<td width=140px style="vertical-align: middle;" align="right">' + r.message[i].base_discount_h + '&nbsp;%&nbsp;</td>';
										}else
										{	html += '<td width=140px style="vertical-align: middle;">&nbsp;</td>';
										}


										if ((r.message[i].extra_discount_h != null) && (r.message[i].extra_discount_h != 0))
										{	html += '<td width=140px style="vertical-align: middle;" align="right">' + r.message[i].extra_discount_h + '&nbsp;%&nbsp;</td>';
										}else
										{	html += '<td width=140px style="vertical-align: middle;">&nbsp;</td>';
										}


										if (r.message[i].author_id != null)
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].author_id + '</td>';
										}else
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].responsible_id != null)
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].responsible_id + '</td>';
										}else
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].delivery_supplier_id != null)
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].delivery_supplier_id + '</td>';
										}else
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
										}


										if (r.message[i].delivery_supplier_name != null)
										{	html += '<td width=350px style="vertical-align: middle;">&nbsp;' + r.message[i].delivery_supplier_name + '</td>';
										}else
										{	html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
										}


										if (r.message[i].invoice_supplier_id != null)
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].invoice_supplier_id + '</td>';
										}else
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].invoice_supplier_name != null)
										{	html += '<td width=350px style="vertical-align: middle;">&nbsp;' + r.message[i].invoice_supplier_name + '</td>';
										}else
										{	html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
										}



										html += '</tr>';
									}

									html += '</table>';

								}
								else
								{
									html += '<tr style="height:30px">';

									html += '<td width=30px>&nbsp;</td>';
									html += '<td width=200px>&nbsp;</td>';
									html += '<td width=250px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=350px>&nbsp;</td>';
									html += '<td width=200px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=250px>&nbsp;</td>';
									html += '<td width=140px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=100px>&nbsp;</td>';
									html += '<td width=350px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=200px>&nbsp;</td>';
									html += '<td width=200px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=170px>&nbsp;</td>';
									html += '<td width=140px>&nbsp;</td>';
									html += '<td width=140px>&nbsp;</td>';
									html += '<td width=200px>&nbsp;</td>';
									html += '<td width=200px>&nbsp;</td>';
									html += '<td width=200px>&nbsp;</td>';
									html += '<td width=350px>&nbsp;</td>';
									html += '<td width=200px>&nbsp;</td>';
									html += '<td width=350px>&nbsp;</td>';
									html += '</tr>';
								}

							}
			});


		}
		html += '</table>';

		document.getElementById('sales_to_order_direct_order_po_for_item_grid').insertAdjacentHTML('afterend', html);

		silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {


			let fct_check_click = function() {

				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows.length; i++) {

					document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + i.toString()).checked = false;

					if (document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + i.toString()).id == this.id) {
						document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + i.toString()).checked = true;
					}

				}

			};

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + i.toString()).onclick = fct_check_click;
				}

			}



			if (color_row)
			{
				let fct_row_click = function () {

					silicon_ioi.doctype.ioiSalesToPurchases.po_for_item_row_click(this.id);
				};


				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows.length; i++)
				{
					document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[i].onclick = fct_row_click;
				}

				for (var j = 0; j < document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[0].cells.length; j++)
				{
					if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
						document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[0].cells[j].style.backgroundColor = '#b1fcd9';
					}else{
						document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[0].cells[j].style.backgroundColor = 'green';
					}
				}
			}


		});


	}

	static po_for_item_row_click(line_id)
	{
		let s = line_id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}

		s = s.trim()

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows.length; i++) {

			if (document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + i.toString())) {
				document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + i.toString()).checked = false;

				for (var j = 0; j < document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[i].cells.length; j++) {


					if (document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[i].cells[j].id == 'sales_to_order_direct_order_po_for_item_row_pk_formatted_' + i.toString()) {

						document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[i].cells[j].style.backgroundColor = document.getElementById('sales_to_order_direct_order_po_for_item_bgcolor_pk_formatted_' + i.toString()).value;
						document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[i].cells[j].style.color = '#000000';

					}
					else if ((document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[i].cells[j].id == 'sales_to_order_direct_order_po_for_item_row_supplier_' + i.toString()) &&
							(document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[i].cells[j].innerText.trim() != document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + document.getElementById('sales_to_order_direct_order_grid_detail_selected_line').value).value.toUpperCase())) {

						document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[i].cells[j].style.backgroundColor = '#FCC1A2';
						document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[i].cells[j].style.color = '#000000';

					}else{
						if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
							document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[i].cells[j].style.backgroundColor = '#FFFFFF';
						}else{
							document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows[i].cells[j].style.backgroundColor = '#1C2126';
						}

					}

				}
			}
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_parent_'+s)) {

			document.getElementById('sales_to_order_direct_order_po_for_item_selected_parent').value = document.getElementById('sales_to_order_direct_order_po_for_item_parent_'+s).value;
			document.getElementById('sales_to_order_direct_order_po_for_item_selected_name').value = document.getElementById('sales_to_order_direct_order_po_for_item_name_'+s).value;
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_item_row_'+s)) {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_item_row_'+s).cells.length; i++)
			{
				if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
					document.getElementById('sales_to_order_direct_order_po_for_item_row_'+s).cells[i].style.backgroundColor = '#b1fcd9';
				}else{
					document.getElementById('sales_to_order_direct_order_po_for_item_row_'+s).cells[i].style.backgroundColor = 'green';
				}
			}
		}

		document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + s).checked = true;

	}

	static po_for_item_merge_on_this_line(obj)
	{
		silicon_ioi.doctype.ioiSalesToPurchases.merge_on_this_purchases_order_line('ITEM');
	}




	// ********************************************************************************************************************************************************************************************************
	//
	// Tab PO for this supplier
	//
	// ********************************************************************************************************************************************************************************************************


	static build_po_for_supplier() {


		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_grid')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_grid').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail')) {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_row_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_row_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_name_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_name_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_checked_id_' + i.toString()).remove();
				}

			}

			document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_content')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_content').remove();
		}



		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_params')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_params').remove();
		}





		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_params_topn_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_params_topn_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_params_topn')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_params_topn').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_params_search_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_params_search_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_params_search')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_params_search').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_orderby_field')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_orderby_field').remove();
		}
		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_orderby_dir')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_orderby_dir').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_selected_name')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_selected_name').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_params_button_new')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_params_button_new').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_params_button_goto')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_params_button_goto').remove();
		}





		let html = '';


		html = '<div id="sales_to_order_direct_order_po_for_supplier_params" style="overflow-x: auto;height:115px">';

		html += '	<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '	</div>'


		html += '	<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '		<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Filters") + '</b></label>';
		html += '	</div>';

		html += '	<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '	</div>';



		// Search
		html += '	<div style="position: relative; top: 0px; left: 0px; width:250px;">';
		html += '		<label id="sales_to_order_direct_order_po_for_supplier_params_search_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 250px; height: 25px;"> ';
		html += '			<input id="sales_to_order_direct_order_po_for_supplier_params_search" type="text"  class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		// Top N
		html += '	<div style="position: relative; top: 0px; left: 260px; width:140px;">';
		html += '		<label id="sales_to_order_direct_order_po_for_supplier_params_topn_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Number of record") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 140px; height: 25px;"> ';
		html += '			<input id="sales_to_order_direct_order_po_for_supplier_params_topn" type="number" step="any" class="input-with-feedback form-control bold" value="100">';
		html += '		</div>';
		html += '	</div>';

		// New
		html += '	<div style="position: relative; top: 25px; left: 410px; width:180px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_po_for_supplier_params_button_new" class="btn btn-default ellipsis" style="height: 30px; width: 180px;" onclick="">' + __("In new Purch. Order") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Go to module
		html += '	<div style="position: relative; top: 25px; left: 600px; width:180px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="sales_to_order_direct_order_po_for_supplier_params_button_goto" class="btn btn-default ellipsis" style="height: 30px; width: 180px;" onclick="">' + __("Go to this Purch. Order") + '</button>';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div id="sales_to_order_direct_order_po_for_supplier_grid_content" style="overflow-x: auto;height:325px">';

		html += '<input id="sales_to_order_direct_order_po_for_supplier_orderby_field" type="hidden" value="expected_arrival_on">';
		html += '<input id="sales_to_order_direct_order_po_for_supplier_orderby_dir"   type="hidden" value="desc">';


		html += '<table id="sales_to_order_direct_order_po_for_supplier_grid" border=1 style="border: 1px solid #E8EAEB" width=870px data-custom-grid="true">';

		html += '<tr style="height:30px">';
		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';

		html += '<td id = "sales_to_order_direct_order_po_for_supplier_grid_col_name" width=220px style="vertical-align: middle;"><b>&nbsp;' + __("Identification") + 								'<label id="sales_to_order_direct_order_po_for_supplier_grid_col_label_name"	style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_supplier_grid_col_document_date" width=170px style="vertical-align: middle;"><b>&nbsp;' + __("Document date") + 						'<label id="sales_to_order_direct_order_po_for_supplier_grid_col_label_document_date" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_supplier_grid_col_expected_arrival_on" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Expected arrival on") + 			'<label id="sales_to_order_direct_order_po_for_supplier_grid_col_label_expected_arrival_on" style="width:30px; height:8px" align="right">&uarr;</label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_supplier_grid_col_total_htva" width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Total w/o VAT") + 							'<label id="sales_to_order_direct_order_po_for_supplier_grid_col_label_total_htva" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id = "sales_to_order_direct_order_po_for_supplier_grid_col_currency_id" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Curr.") + 								'<label id="sales_to_order_direct_order_po_for_supplier_grid_col_label_currency_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '</tr>';
		html += '</table>';


		html += '<table id="sales_to_order_direct_order_po_for_supplier_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=870px>';
		html += '<tr style="height:30px">';
		html += '<td width=30px>&nbsp;</td>';
		html += '<td width=220px>&nbsp;</td>';
		html += '<td width=170px>&nbsp;</td>';
		html += '<td width=200px>&nbsp;</td>';
		html += '<td width=150px>&nbsp;</td>';
		html += '<td width=100px>&nbsp;</td>';
		html += '</tr>';
		html += '</table>';

		html += '</div>';

		html += '<input id="sales_to_order_direct_order_po_for_supplier_selected_name" type="hidden" value="">';


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'





		document.getElementById('sales_to_order_direct_order_po_for_supplier_content_left').innerHTML = html;


		silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {


			let fct_keydown_search = function(event) {

				if (event.keyCode == 13)
				{
					silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();
					return false;
				}

			};

			let fct_keydown = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};


			let fct_keyup = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};

			let fct_change = function(event) {

				silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();
			};

			let fct_click_search = function(event) {

				silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();

			};

			document.getElementById('sales_to_order_direct_order_po_for_supplier_params_topn').onkeydown = fct_keydown_search;
			document.getElementById('sales_to_order_direct_order_po_for_supplier_params_topn').onkeyup = fct_keyup;

			document.getElementById('sales_to_order_direct_order_po_for_supplier_params_search').onkeydown = fct_keydown_search;
			document.getElementById('sales_to_order_direct_order_po_for_supplier_params_search').onkeyup = fct_keyup;


			let fct_new_click = function() {

				silicon_ioi.doctype.ioiSalesToPurchases.new_purchases_order();
			};

			document.getElementById('sales_to_order_direct_order_po_for_supplier_params_button_new').onclick = fct_new_click;



			let fct_goto_click = function() {

				silicon_ioi.doctype.ioiSalesToPurchases.do_goto_purchases_order();
			};

			document.getElementById('sales_to_order_direct_order_po_for_supplier_params_button_goto').onclick = fct_goto_click;




			let fct_col_click = function () {
				silicon_ioi.doctype.ioiSalesToPurchases.po_for_supplier_col_click(this);
			};
			let fct_col_over = function () {
				silicon_ioi.doctype.ioiSalesToPurchases.po_for_supplier_col_mouse_over(this);
			};
			let fct_col_leave = function () {
				silicon_ioi.doctype.ioiSalesToPurchases.po_for_supplier_col_mouse_leave(this);
			};

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_grid').rows[0].cells.length; i++) {

				document.getElementById('sales_to_order_direct_order_po_for_supplier_grid').rows[0].cells[i].onclick = fct_col_click;
				document.getElementById('sales_to_order_direct_order_po_for_supplier_grid').rows[0].cells[i].onmouseover = fct_col_over;
				document.getElementById('sales_to_order_direct_order_po_for_supplier_grid').rows[0].cells[i].onmouseleave = fct_col_leave;
			}


			silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();

		});

	}

	static po_for_supplier_col_click(obj)
	{
		let s = obj.id;
		s = s.substring(53, s.length);

		document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_col_label_name').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_col_label_document_date').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_col_label_expected_arrival_on').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_col_label_total_htva').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_col_label_currency_id').innerHTML = '';

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_orderby_field').value == s)
		{
			if (document.getElementById('sales_to_order_direct_order_po_for_supplier_orderby_dir').value == 'desc')
			{	document.getElementById('sales_to_order_direct_order_po_for_supplier_orderby_dir').value = 'asc';

				document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_col_label_' + s).innerHTML = '&darr;';
			}else
			{	document.getElementById('sales_to_order_direct_order_po_for_supplier_orderby_dir').value = 'desc';
				document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_col_label_' + s).innerHTML = '&uarr;';
			}
		}else
		{	document.getElementById('sales_to_order_direct_order_po_for_supplier_orderby_field').value = s;
			document.getElementById('sales_to_order_direct_order_po_for_supplier_orderby_dir').value = 'desc';
			document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_col_label_' + s).innerHTML = '&uarr;';
		}

		silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();


	}

	static po_for_supplier_col_mouse_over(obj)
	{
		obj.style.cursor = 'pointer';
	}

	static po_for_supplier_col_mouse_leave(obj)
	{
		obj.style.cursor = 'none';
	}

	static load_po_for_supplier()
	{

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail')) {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_row_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_row_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_name_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_name_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_row_ioistatus_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_row_ioistatus_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_bgcolor_ioistatus_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_bgcolor_ioistatus_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_row_pk_formatted_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_row_pk_formatted_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_bgcolor_pk_formatted_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_bgcolor_pk_formatted_' + i.toString()).remove();
				}





			}

			document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').remove();
		}


		let html = '';


		let color_row = false;
		html += '<table id="sales_to_order_direct_order_po_for_supplier_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=870px>';



		let supplier_id = '';

		if (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + document.getElementById('sales_to_order_direct_order_grid_detail_selected_line').value)) {

			if (document.getElementById('sales_to_order_direct_order_grid_detail')) {

				if (document.getElementById('sales_to_order_direct_order_grid_detail').rows.length > 0) {
					let first_index_found = -1;

					for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

						if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
							(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
							(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {
							first_index_found = i;
							break;
						}
					}

					if (first_index_found != -1) {
						supplier_id = document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + document.getElementById('sales_to_order_direct_order_grid_detail_selected_line').value).value;
					}
				}
			}
		}


		if (supplier_id == '') {

			html += '<tr style="height:30px">';
			html += '<td width=30px>&nbsp;</td>';
			html += '<td width=220px>&nbsp;</td>';
			html += '<td width=170px>&nbsp;</td>';
			html += '<td width=200px>&nbsp;</td>';
			html += '<td width=150px>&nbsp;</td>';
			html += '<td width=100px>&nbsp;</td>';
			html += '</tr>';

		}else {

			let method = 'silicon_ioi.common.sales_to_purchases.direct_order_po_for_this_supplier';

			frappe.call({  	method: method,
							args: {	"supplier_id": supplier_id,
									"search":  document.getElementById('sales_to_order_direct_order_po_for_supplier_params_search').value,
									"order_field": document.getElementById('sales_to_order_direct_order_po_for_supplier_orderby_field').value,
									"order_dir": document.getElementById('sales_to_order_direct_order_po_for_supplier_orderby_dir').value,
									"topn": document.getElementById('sales_to_order_direct_order_po_for_supplier_params_topn').value
							},
							async: false,
							callback:function(r)	{


								if (r.message.length > 0)
								{

									color_row = true;

									for (var i = 0; i < r.message.length; i++)
									{
										html += '<tr id="sales_to_order_direct_order_po_for_supplier_row_' + i.toString() + '" style="height:30px">';

										html += '<input id="sales_to_order_direct_order_po_for_supplier_name_' + i.toString() + '" type="hidden" value="' + r.message[i].name + '">';


										html += '<input id="sales_to_order_direct_order_po_for_supplier_bgcolor_pk_formatted_' + i.toString() + '" type="hidden" value="' + r.message[i].ioistatus_bgcolor + '">';
										html += '<input id="sales_to_order_direct_order_po_for_supplier_row_pk_formatted_' + i.toString() + '" type="hidden" value="' + r.message[i].name + '">';


										if (i == 0) {
											document.getElementById('sales_to_order_direct_order_po_for_supplier_selected_name').value = r.message[i].name;
										}



										html += '<td width=30px align="center" style="vertical-align: middle;">';


										html += '<input type="checkbox" id="sales_to_order_direct_order_po_for_supplier_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" ';

										if (i == 0) {
											html += "checked ";
										}

										html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
										html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
										html += '>';
										html += '</td>';

										html += '<td id="sales_to_order_direct_order_po_for_supplier_row_pk_formatted_' + i.toString() + '" width=220px bgcolor="' + r.message[i].ioistatus_bgcolor + '" style="vertical-align: middle;color:#000000;">&nbsp;' + r.message[i].name + '</td>';

										html += '<td width=170px style="vertical-align: middle;">&nbsp;' + r.message[i].document_date + '</td>';

										if (r.message[i].expected_arrival_on != null)
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].expected_arrival_on + '</td>';
										}else
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].total_htva != null) && (r.message[i].total_htva != 0))
										{	html += '<td width=150px style="vertical-align: middle;" align="right">' + r.message[i].total_htva + '&nbsp;</td>';
										}else
										{	html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
										}


										if (r.message[i].currency_id != null)
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].currency_id + '</td>';
										}else
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
										}


										html += '</tr>';
									}

									html += '</table>';

								}
								else
								{
									html += '<tr style="height:30px">';
									html += '<td width=30px>&nbsp;</td>';
									html += '<td width=220px>&nbsp;</td>';
									html += '<td width=170px>&nbsp;</td>';
									html += '<td width=200px>&nbsp;</td>';
									html += '<td width=150px>&nbsp;</td>';
									html += '<td width=100px>&nbsp;</td>';
									html += '</tr>';
								}

							}
			});


		}
		html += '</table>';

		document.getElementById('sales_to_order_direct_order_po_for_supplier_grid').insertAdjacentHTML('afterend', html);

		silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {


			let fct_check_click = function() {

				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows.length; i++) {

					document.getElementById('sales_to_order_direct_order_po_for_supplier_checked_id_' + i.toString()).checked = false;

					if (document.getElementById('sales_to_order_direct_order_po_for_supplier_checked_id_' + i.toString()).id == this.id) {
						document.getElementById('sales_to_order_direct_order_po_for_supplier_checked_id_' + i.toString()).checked = true;
					}

				}

			};

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_checked_id_' + i.toString()).onclick = fct_check_click;
				}

			}



			if (color_row)
			{

				let fct_row_click = function () {

					silicon_ioi.doctype.ioiSalesToPurchases.po_for_supplier_row_click(this.id);
				};


				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows.length; i++)
				{
					document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows[i].onclick = fct_row_click;
				}

				for (var j = 0; j < document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows[0].cells.length; j++)
				{
					if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
						document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows[0].cells[j].style.backgroundColor = '#b1fcd9';
					}else{
						document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows[0].cells[j].style.backgroundColor = 'green';
					}
				}

				document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows[0].click();
			}else{
				document.getElementById('sales_to_order_direct_order_po_for_supplier_selected_name').value = '';
				silicon_ioi.doctype.ioiSalesToPurchases.load_purchases_orders_form_detail();

			}




		});


	}

	static po_for_supplier_row_click(line_id)
	{
		let s = line_id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}

		s = s.trim()

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows.length; i++) {

			if (document.getElementById('sales_to_order_direct_order_po_for_supplier_checked_id_' + i.toString())) {

				document.getElementById('sales_to_order_direct_order_po_for_supplier_checked_id_' + i.toString()).checked = false;

				for (var j = 0; j < document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows[i].cells.length; j++) {


					if (document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows[i].cells[j].id == 'sales_to_order_direct_order_po_for_supplier_row_pk_formatted_' + i.toString()) {

						document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows[i].cells[j].style.backgroundColor = document.getElementById('sales_to_order_direct_order_po_for_supplier_bgcolor_pk_formatted_' + i.toString()).value;
						document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows[i].cells[j].style.color = '#000000';

					}else{
						if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
							document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows[i].cells[j].style.backgroundColor = '#FFFFFF';
						}else{
							document.getElementById('sales_to_order_direct_order_po_for_supplier_grid_detail').rows[i].cells[j].style.backgroundColor = '#1C2126';
						}

					}

				}
			}
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_name_'+s)) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_selected_name').value = document.getElementById('sales_to_order_direct_order_po_for_supplier_name_'+s).value;
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_row_'+s)) {
			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_row_'+s).cells.length; i++)
			{
				if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_row_'+s).cells[i].style.backgroundColor = '#b1fcd9';
				}else{
					document.getElementById('sales_to_order_direct_order_po_for_supplier_row_'+s).cells[i].style.backgroundColor = 'green';
				}
			}

			document.getElementById('sales_to_order_direct_order_po_for_supplier_checked_id_' + s).checked = true;
		}

		silicon_ioi.doctype.ioiSalesToPurchases.load_purchases_orders_form_detail();

	}




	// *************************************************************************************************************************************************************************************************************
	//
	// Formulaire PO
	//
	// *************************************************************************************************************************************************************************************************************


	static build_po_for_supplier_form_header()
	{

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_identification_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_identification_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_identification')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_identification').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_status_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_status_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_status')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_status').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_ioistatus')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_ioistatus').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_supplier_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_supplier_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_supplier_id')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_supplier_id').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_supplier_name_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_supplier_name_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_supplier_name')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_supplier_name').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_document_date_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_document_date_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_document_date')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_document_date').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_total_htva_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_total_htva_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_total_htva')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_total_htva').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_currency_label')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_currency_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_currency_id')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_currency_id').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_button_new')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_button_new').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_button_goto')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_button_goto').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_selected_parent')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_selected_parent').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_selected_name')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_selected_name').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail')) {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_parent_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_parent_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_name_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_name_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + i.toString()).remove();
				}

			}

			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_field')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_field').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_dir')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_dir').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_button_add')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_button_add').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_button_merge')) {
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_button_merge').remove();
		}




		let header_identification = '';
		let header_status = '';
		let header_status_desc = '';
		let header_ioistatus_bgcolor = '';
		let header_supplier_id = '';
		let header_supplier_name = '';
		let header_document_date = '';
		let header_total_htva = '';
		let header_currency = '';

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_selected_name').value != '') {

			let method = this.path_sales_to_purchases + '.direct_order_po_for_this_supplier_get_purchases_order';
			frappe.call({  	method: method,
							args: {"name": document.getElementById('sales_to_order_direct_order_po_for_supplier_selected_name').value},
							async: false,
							callback:function(r)	{


								header_identification = r.message[0].name;
								header_status = r.message[0].ioistatus;
								header_status_desc = r.message[0].ioistatus_description;
								header_ioistatus_bgcolor = r.message[0].ioistatus_bgcolor;
								header_supplier_id = r.message[0].order_supplier_id;
								header_supplier_name = r.message[0].order_supplier_name;
								header_document_date = r.message[0].document_date;
								header_total_htva = r.message[0].total_htva;
								header_currency = r.message[0].currency_id;

							}
			});
		}



		let html = '';


		html = '<div id="sales_to_order_direct_order_po_for_supplier_form_header" style="overflow-x: auto;height:480px">';

		html += '	<div id="sales_to_order_direct_order_po_for_supplier_form_header_right" style="float:left; overflow-x: auto; width: 100%; height:100%;">';

		html += '		<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '		</div>';


		html += '		<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '			<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Purchases Order") + '</b></label>';
		html += '		</div>';

		html += '		<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '		</div>';

		// Identification
		html += '		<div style="position: relative; top: 0px; left: 0px; width:180px;">';
		html += '			<label id="sales_to_order_direct_order_po_for_supplier_form_header_identification_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Identification") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 180px; height: 25px;"> ';
		html += '				<input id="sales_to_order_direct_order_po_for_supplier_form_header_identification" type="text"  class="input-with-feedback form-control bold" value="' + header_identification + '" readonly>';
		html += '			</div>';
		html += '		</div>';

		// Status
		html += '		<div style="position: relative; top: 0px; left: 190px; width:150px;">';
		html += '			<label id="sales_to_order_direct_order_po_for_supplier_form_header_status_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Status") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 25px;"> ';
		html += '				<input id="sales_to_order_direct_order_po_for_supplier_form_header_status" ';

		if ((header_ioistatus_bgcolor) && (header_ioistatus_bgcolor != null) && (header_ioistatus_bgcolor != '')) {
			html += ' 			style="background:' + header_ioistatus_bgcolor +';color=#000000;"';
		}
		html += '				type="text"  class="input-with-feedback form-control bold" value="' + header_status_desc + '" readonly>';
		html += '			</div>';
		html += '		</div>';

		html += '<input id="sales_to_order_direct_order_po_for_supplier_form_header_ioistatus" type="hidden" value="' + header_status + '"> ';

		// Total w/o VAT
		html += '		<div style="position: relative; top: 0px; left: 350px; width:132px;">';
		html += '			<label id="sales_to_order_direct_order_po_for_supplier_form_header_total_htva_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Total w/o VAT") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 132px; height: 25px;"> ';
		html += '				<input id="sales_to_order_direct_order_po_for_supplier_form_header_total_htva" type="text"  class="input-with-feedback form-control bold" style="text-align: right;" ';

		if ((header_total_htva == '') || (header_total_htva == null)) {
			html += 'value="0" ';

		}else{
			html += 'value="' + header_total_htva + '" ';
		}

		html += ' readonly>';
		html += '			</div>';
		html += '		</div>';

		// Currency
		html += '		<div style="position: relative; top: 0px; left: 493px; width:60px;">';
		html += '			<label id="sales_to_order_direct_order_po_for_supplier_form_header_currency_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Currency") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 66px; height: 25px;"> ';
		html += '				<input id="sales_to_order_direct_order_po_for_supplier_form_header_currency_id" type="text"  class="input-with-feedback form-control bold" value="' + header_currency + '" readonly>';
		html += '			</div>';
		html += '		</div>';


		// Supplier id
		html += '		<div style="position: relative; top: 65px; left: 0px; width:180px;">';
		html += '			<label id="sales_to_order_direct_order_po_for_supplier_form_header_supplier_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Supplier") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 180px; height: 25px;"> ';
		html += '				<input id="sales_to_order_direct_order_po_for_supplier_form_header_supplier_id" type="text"  class="input-with-feedback form-control bold" value="' + header_supplier_id + '" readonly>';
		html += '			</div>';
		html += '		</div>';

		// Supplier name
		html += '		<div style="position: relative; top: 65px; left: 190px; width:250px;">';
		html += '			<label id="sales_to_order_direct_order_po_for_supplier_form_header_supplier_name_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Supplier name") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 250px; height: 25px;"> ';
		html += '				<input id="sales_to_order_direct_order_po_for_supplier_form_header_supplier_name" type="text"  class="input-with-feedback form-control bold" value="' + header_supplier_name + '" readonly>';
		html += '			</div>';
		html += '		</div>';

		// Document date
		html += '		<div style="position: relative; top: 65px; left: 450px; width:110px;">';
		html += '			<label id="sales_to_order_direct_order_po_for_supplier_form_header_document_date_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Document date") + '</label>';
		html += '			<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 110px; height: 25px;"> ';
		html += '				<input id="sales_to_order_direct_order_po_for_supplier_form_header_document_date" type="text"  class="input-with-feedback form-control bold" value="' + header_document_date + '" readonly>';
		html += '			</div>';
		html += '		</div>';

		// New
		html += '		<div style="position: relative; top: 135px; left: 0px; width:180px;">';
		html += '			<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='				<button id="sales_to_order_direct_order_po_for_supplier_form_header_button_new" class="btn btn-default ellipsis" style="height: 30px; width: 180px;" onclick="">' + __("In new Purch. Order") + '</button>';
		html += '			</div>';
		html += '		</div>';

		// Go to module
		html += '		<div style="position: relative; top: 135px; left: 190px; width:180px;">';
		html += '			<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='				<button id="sales_to_order_direct_order_po_for_supplier_form_header_button_goto" class="btn btn-default ellipsis" style="height: 30px; width: 180px;" onclick="">' + __("Go to this Purch. Order") + '</button>';
		html += '			</div>';
		html += '		</div>';

		// Add on this PO
		html += '		<div style="position: relative; top: 135px; left: 380px; width:180px;">';
		html += '			<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='				<button id="sales_to_order_direct_order_po_for_supplier_form_header_button_add" class="btn btn-default ellipsis" style="height: 30px; width: 180px;" onclick="">' + __("Add on this Purch. Order") + '</button>';
		html += '			</div>';
		html += '		</div>';


		// Merge on this line
		html += '		<div style="position: relative; top: 135px; left: 570px; width:180px;">';
		html += '			<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='				<button id="sales_to_order_direct_order_po_for_supplier_form_header_button_merge" class="btn btn-default ellipsis" style="height: 30px; width: 180px;" onclick="">' + __("Merge on this line") + '</button>';
		html += '			</div>';
		html += '		</div>';



		html += '		<div id="sales_to_order_direct_order_po_for_supplier_form_header_data" style="position: relative; top: 180px; left: 0px; overflow-x: auto; height:223px">';

		html += '			<input id="sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_field" type="hidden" value="idx">';
		html += '			<input id="sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_dir"   type="hidden" value="asc">';


		html += '			<input id="sales_to_order_direct_order_po_for_supplier_form_header_detail_selected_parent" type="hidden" value="">';
		html += '			<input id="sales_to_order_direct_order_po_for_supplier_form_header_detail_selected_name"   type="hidden" value="">';


		html += '			<table id="sales_to_order_direct_order_po_for_supplier_form_header_detail_grid" border=1 style="border: 1px solid #E8EAEB" width=2410px data-custom-grid="true">';

		html += '			<tr style="height:30px">';
		html += '			<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_idx" width=80px style="vertical-align: middle;"><b>&nbsp;' + __("No") + 									'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_idx"	style="width:30px; height:8px" align="right">&darr;</label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_item_id" width=250px style="vertical-align: middle;"><b>&nbsp;' + __("Item") + 							'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_item_id"	style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_ordered_qty" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Ordered qty") + 				'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_ordered_qty"	style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_unit_id" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Unit") + 							'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_unit_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_unit_price" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Unit price") + 					'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_unit_price" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_currency_id" width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Curr.") + 						'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_currency_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_item_description" width=350px style="vertical-align: middle;"><b>&nbsp;' + __("Item description") + 		'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_item_description" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_required_qty" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Required qty") + 				'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_required_qty" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_base_discount" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Base disc.") + 				'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_base_discount" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_extra_discount" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Extra disc.") + 				'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_extra_discount" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_min_order_qty" width=140px style="vertical-align: middle;"><b>&nbsp;' + __("Min order qty") + 			'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_min_order_qty" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_free_part_qty" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Free part qty") + 			'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_free_part_qty" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_asked_arrival_date" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Asked arrival date") +   '<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_asked_arrival_date"	style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_external_ref" width=200px style="vertical-align: middle;"><b>&nbsp;' + __("External ref.") + 				'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_external_ref" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_is_option" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Is option") + 					'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_is_option" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			<td id = "sales_to_order_direct_order_po_for_supplier_form_header_detail_col_option_id" width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Option") + 						'<label id="sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_option_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '			</tr>';
		html += '			</table>';

		html += '			<table id="sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=2410px>';
		html += '			<tr style="height:30px">';
		html += '			<td width=30px>&nbsp;</td>';
		html += '			<td width=80px>&nbsp;</td>';
		html += '			<td width=250px>&nbsp;</td>';
		html += '			<td width=120px>&nbsp;</td>';
		html += '			<td width=100px>&nbsp;</td>';
		html += '			<td width=120px>&nbsp;</td>';
		html += '			<td width=100px>&nbsp;</td>';
		html += '			<td width=350px>&nbsp;</td>';
		html += '			<td width=120px>&nbsp;</td>';
		html += '			<td width=120px>&nbsp;</td>';
		html += '			<td width=120px>&nbsp;</td>';
		html += '			<td width=140px>&nbsp;</td>';
		html += '			<td width=120px>&nbsp;</td>';
		html += '			<td width=200px>&nbsp;</td>';
		html += '			<td width=200px>&nbsp;</td>';
		html += '			<td width=120px>&nbsp;</td>';
		html += '			<td width=120px>&nbsp;</td>';
		html += '			</tr>';
		html += '			</table>';


		html +=	'		</div>';



		html +=	'	</div>';
		html += '</div>';




		document.getElementById('sales_to_order_direct_order_po_for_supplier_content_right').innerHTML = html;


		silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {


			let fct_col_click = function () { silicon_ioi.doctype.ioiSalesToPurchases.sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_col_click(this); };
			let fct_col_over = function () { silicon_ioi.doctype.ioiSalesToPurchases.sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_col_mouse_over(this); };
			let fct_col_leave = function () { silicon_ioi.doctype.ioiSalesToPurchases.sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_col_mouse_leave(this); };

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid').rows[0].cells.length; i++) {

				document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid').rows[0].cells[i].onclick = fct_col_click;
				document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid').rows[0].cells[i].onmouseover = fct_col_over;
				document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid').rows[0].cells[i].onmouseleave = fct_col_leave;
			}



			let fct_new_click = function() {

				silicon_ioi.doctype.ioiSalesToPurchases.new_purchases_order();
			};


			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_button_new').onclick = fct_new_click;

			let fct_goto_click = function() {

				silicon_ioi.doctype.ioiSalesToPurchases.do_goto_purchases_order();
			};


			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_button_goto').onclick = fct_goto_click;


			let fct_add_click = function() {

				silicon_ioi.doctype.ioiSalesToPurchases.add_on_this_purchases_order_line();
			};


			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_button_add').onclick = fct_add_click;

			let fct_merge_click = function() {

				silicon_ioi.doctype.ioiSalesToPurchases.merge_on_this_purchases_order_line('SUPPLIER');
			};


			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_button_merge').onclick = fct_merge_click;


			silicon_ioi.doctype.ioiSalesToPurchases.load_purchases_orders_form_detail();
		});

	}



	static sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_col_click(obj)
	{

		let s = obj.id;


		s = s.substring(67, obj.id.length);


		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_idx').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_item_id').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_ordered_qty').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_unit_id').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_unit_price').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_currency_id').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_item_description').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_required_qty').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_base_discount').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_extra_discount').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_min_order_qty').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_free_part_qty').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_asked_arrival_date').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_external_ref').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_is_option').innerHTML = '';
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_option_id').innerHTML = '';


		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_field').value == s)
		{
			if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_dir').value == 'desc')
			{	document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_dir').value = 'asc';

				document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_' + s).innerHTML = '&darr;';
			}else
			{	document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_dir').value = 'desc';
				document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_' + s).innerHTML = '&uarr;';
			}
		}else
		{	document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_field').value = s;
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_dir').value = 'desc';
			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_col_label_' + s).innerHTML = '&uarr;';
		}

		silicon_ioi.doctype.ioiSalesToPurchases.load_purchases_orders_form_detail();



	}

	static sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_col_mouse_over(obj)
	{
		obj.style.cursor = 'pointer';
	}

	static sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_col_mouse_leave(obj)
	{
		obj.style.cursor = 'none';
	}

	static load_purchases_orders_form_detail()
	{

		let header_identification = '';
		let header_status = '';
		let header_status_desc = '';
		let header_ioistatus_bgcolor = '';
		let header_supplier_id = '';
		let header_supplier_name = '';
		let header_document_date = '';
		let header_total_htva = '';
		let header_currency = '';

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_selected_name').value != '') {

			let method = this.path_sales_to_purchases + '.direct_order_po_for_this_supplier_get_purchases_order';
			frappe.call({  	method: method,
							args: {"name": document.getElementById('sales_to_order_direct_order_po_for_supplier_selected_name').value},
							async: false,
							callback:function(r)	{


								header_identification = r.message[0].name;
								header_status = r.message[0].ioistatus;
								header_status_desc = r.message[0].ioistatus_description;
								header_ioistatus_bgcolor = r.message[0].ioistatus_bgcolor;
								header_supplier_id = r.message[0].order_supplier_id;
								header_supplier_name = r.message[0].order_supplier_name;
								header_document_date = r.message[0].document_date;
								header_total_htva = r.message[0].total_htva;
								header_currency = r.message[0].currency_id;

							}
			});
		}

		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_identification').value = header_identification;
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_status').value = header_status_desc;

		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_status').style.backgroundColor = header_ioistatus_bgcolor;

		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_ioistatus').value = header_status;
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_supplier_id').value = header_supplier_id;
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_supplier_name').value = header_supplier_name;
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_document_date').value = header_document_date;
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_total_htva').value = header_total_htva;
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_currency_id').value = header_currency;

		silicon_ioi.doctype.ioiSalesToPurchases.load_purchases_orders_form_detail_lines();
	}

	static load_purchases_orders_form_detail_lines()
	{
		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail')) {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_row_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_row_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_parent_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_parent_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_name_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_name_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_item_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_item_id_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_item_description_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_item_description_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + i.toString()).remove();
				}


				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_row_idx_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_row_idx_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_row_bgcolor_idx_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_row_bgcolor_idx_' + i.toString()).remove();
				}




			}

			document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').remove();
		}



		let html = '';
		let color_row = false;
		let color_idx = -1;

		html += '<table id="sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=2410px>';



		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_identification').value.trim() == '') {

			html += '<tr style="height:30px">';
			html += '<td width=30px>&nbsp;</td>';
			html += '<td width=80px>&nbsp;</td>';
			html += '<td width=250px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=100px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=100px>&nbsp;</td>';
			html += '<td width=350px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=140px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=200px>&nbsp;</td>';
			html += '<td width=200px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '</tr>';

		}else {


			let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases +  '.direct_order_po_for_this_supplier_get_purchases_order_detail'

			frappe.call({  	method: method,
							args: {	"name": document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_identification').value,
							"order_field": document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_field').value,
							"order_dir": document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_orderby_dir').value

							},



							async: false,
							callback:function(r)	{


								if (r.message.length > 0)
								{

									color_row = true;
									color_idx = -1;

									for (var i = 0; i < r.message.length; i++) {


										if (i == 0) { //document.getElementById('sales_to_order_direct_order_po_for_supplier_selected_name').value == r.message[i].name) {

											document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_selected_name').value = r.message[i].name;

											color_idx = i;
										}


										html += '<tr id="sales_to_order_direct_order_po_for_supplier_form_header_detail_row_' + i.toString() + '" style="height:30px">';

										html += '<input id="sales_to_order_direct_order_po_for_supplier_form_header_detail_parent_' + i.toString() + '" type="hidden" value="' + r.message[i].parent + '">';
										html += '<input id="sales_to_order_direct_order_po_for_supplier_form_header_detail_name_' + i.toString() + '" type="hidden" value="' + r.message[i].name + '">';


										html += '<input id="sales_to_order_direct_order_po_for_supplier_form_header_detail_item_id_' + i.toString() + '" type="hidden" value="' + r.message[i].item_id + '">';
										html += '<input id="sales_to_order_direct_order_po_for_supplier_form_header_detail_item_description_' + i.toString() + '" type="hidden" value="' + r.message[i].item_description + '">';


										html += '<td width=30px align="center" style="vertical-align: middle;">';


										html += '<input type="checkbox" id="sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" ';

										if (i == 0) {
											html += " checked ";
										}


										html += '	onkeydown="   if (event.keyCode == 13) { return false; } " ';
										html += '	onkeyup="   if (event.keyCode == 13) { return false; } " ';
										html += '>';
										html += '</td>';


										if (r.message[i].idx != null)
										{
											if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
												html += '<td id="sales_to_order_direct_order_po_for_supplier_form_header_detail_row_idx_' + i.toString() + '" bgcolor="' + r.message[i].ioistatus_bgcolor + '" width=80px style="vertical-align: middle;color:#000000;" align="right">' + r.message[i].idx + '&nbsp;</td>';
											}else{
												html += '<td id="sales_to_order_direct_order_po_for_supplier_form_header_detail_row_idx_' + i.toString() + '" bgcolor="' + r.message[i].ioistatus_bgcolor + '" width=80px style="vertical-align: middle;" align="right"><font color="#000000">' + r.message[i].idx + '&nbsp;</font></td>';
											}
											html += '<input id="sales_to_order_direct_order_po_for_supplier_form_header_detail_row_bgcolor_idx_' + i.toString() + '" type="hidden" value="' + r.message[i].ioistatus_bgcolor + '">';

										}else
										{	html += '<td id="sales_to_order_direct_order_po_for_supplier_form_header_detail_row_idx_' + i.toString() + '" width=80px style="vertical-align: middle;">&nbsp;</td>';
											html += '<input id="sales_to_order_direct_order_po_for_supplier_form_header_detail_row_bgcolor_idx_' + i.toString() + '" type="hidden" value="">';
										}



										if (r.message[i].item_id != null)
										{
											html += '		<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].item_id + '</td>';
										}else
										{	html += '		<td width=250px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].ordered_qty != null) && (r.message[i].ordered_qty != 0))
										{	html += '		<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].ordered_qty + '&nbsp;</td>';
										}else
										{	html += '		<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}


										if (r.message[i].unit_id != null)
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].unit_id + '</td>';
										}else
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].unit_price != null) && (r.message[i].unit_price != 0))
										{	html += '		<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].unit_price + '&nbsp;</td>';
										}else
										{	html += '		<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].currency_id != null)
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].currency_id + '</td>';
										}else
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
										}


										if (r.message[i].item_description != null)
										{	html += '<td width=350px style="vertical-align: middle;">&nbsp;' + r.message[i].item_description + '</td>';
										}else
										{	html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].required_qty != null) && (r.message[i].required_qty != 0))
										{	html += '		<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].required_qty + '&nbsp;</td>';
										}else
										{	html += '		<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].base_discount != null) && (r.message[i].base_discount != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].base_discount + '&nbsp;%&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}


										if ((r.message[i].extra_discount != null) && (r.message[i].extra_discount != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].extra_discount + '&nbsp;%&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].min_order_qty != null) && (r.message[i].min_order_qty != 0))
										{	html += '<td width=140px style="vertical-align: middle;" align="right">' + r.message[i].min_order_qty + '&nbsp;</td>';
										}else
										{	html += '<td width=140px style="vertical-align: middle;">&nbsp;</td>';
										}


										if ((r.message[i].free_part_qty != null) && (r.message[i].free_part_qty != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].free_part_qty + '&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}


										if (r.message[i].asked_arrival_date != null)
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].asked_arrival_date + '</td>';
										}else
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].external_ref != null)
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].external_ref + '</td>';
										}else
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
										}



										if (r.message[i].is_option != null)
										{	html += '<td width=120px style="vertical-align: middle;" align="center">';

											if (r.message[i].is_option == 1) {
												html += '<input type="checkbox" style="postion:absolute; top: 2px; left: 2px;" disabled checked> ';
											}else{
												html += '<input type="checkbox" style="postion:absolute; top: 2px; left: 2px;" disabled> ';
											}

											html += '</td>';

										}else
										{	html += '<td width=120px style="vertical-align: middle;" align="center"><input type="checkbox" style="postion:absolute; top: 2px; left: 2px;" disabled></td>';
										}

										if (r.message[i].option_id != null)
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;' + r.message[i].option_id + '</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}


										html += '</tr>';
									}


								}
								else
								{
									html += '<tr style="height:30px">';
									html += '<td width=30px>&nbsp;</td>';
									html += '<td width=80px>&nbsp;</td>';
									html += '<td width=250px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=100px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=100px>&nbsp;</td>';
									html += '<td width=350px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=140px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=200px>&nbsp;</td>';
									html += '<td width=200px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '</tr>';
								}

							}
			});


		}
		html += '</table>';

		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid').insertAdjacentHTML('afterend', html);

		silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {


			let fct_check_click = function() {

				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows.length; i++) {

					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + i.toString()).checked = false;

					if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + i.toString()).id == this.id) {
						document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + i.toString()).checked = true;
					}

				}

			};

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + i.toString()).onclick = fct_check_click;
				}

			}



			if (color_row)
			{

				let fct_row_click = function () { silicon_ioi.doctype.ioiSalesToPurchases.po_line_detail_click(this.id); };


				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows.length; i++)
				{
					document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows[i].onclick = fct_row_click;
				}

				let idx = -1;

				if (color_idx != -1) {
					idx = color_idx;
				}


				if (idx != -1) {

					for (var j = 0; j < document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows[idx].cells.length; j++)
					{
						if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
							document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows[idx].cells[j].style.backgroundColor = '#b1fcd9';
						}else{
							document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows[idx].cells[j].style.backgroundColor = 'green';
						}
					}
				}
			}

		});


	}

	static po_line_detail_click(line_id)
	{
		let s = line_id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}



		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_selected_name').value.trim() != '') {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows.length; i++) {

				document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + i.toString()).checked = false;

				for (var j = 0; j < document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows[i].cells.length; j++) {

					if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows[i].cells[j].id == 'sales_to_order_direct_order_po_for_supplier_form_header_detail_row_idx_' + i.toString()) {

						document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows[i].cells[j].style.backgroundColor = document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_row_bgcolor_idx_' + i.toString()).value;
						document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows[i].cells[j].style.color = '#000000';

					}else{

						if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
							document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows[i].cells[j].style.backgroundColor = '#FFFFFF';
						}else{
							document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows[i].cells[j].style.backgroundColor = '#1C2126';
						}
					}
				}
			}
		}

		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_selected_parent').value = document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_parent_'+s).value;
		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_selected_name').value = document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_name_'+s).value;


		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_row_'+s).cells.length; i++)
		{
			if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
				document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_row_'+s).cells[i].style.backgroundColor = '#b1fcd9';
			}else{
				document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_row_'+s).cells[i].style.backgroundColor = 'green';
			}
		}

		document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + s).checked = true;


	}

	static new_purchases_order()
	{
		if (!silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales order line selected'), indicator: "red"});
			raise;
		}

		if (document.getElementById('sales_to_order_direct_order_action_journal').options.length == 0) {

			frappe.msgprint({title: __("Message"), message: __('Purchases journal undefined'), indicator: "red"});
			raise;

		}

		let first_supplier_found = '';
		let first_purchases_currency = ''

		let selected_parents = '';
		let selected_names = '';
		let selected_items = '';
		let selected_items_desc = '';
		let selected_suppliers = '';
		let selected_purchases_conditions = '';
		let selected_purchases_units = '';
		let selected_purchases_currencies = '';
		let selected_qties = '';

		let selected_sales_qties = '';
		let selected_sales_units = '';
		let selected_required = '';



		let nb_selected = 0;



		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {

				if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {

					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

						if (document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value.trim() == '') {

							frappe.msgprint({title: __("Message"), message: __('The supplier has to be filled'), indicator: "red"});
							raise;
						}else{

							if (first_supplier_found == '') {
								first_supplier_found = document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value.toUpperCase();
							}

							if (first_supplier_found != document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value.toUpperCase()) {

								frappe.msgprint({title: __("Message"), message: __('The supplier has to be the same for all the selected lines'), indicator: "red"});
								raise;
							}
						}

						if (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value.trim() == '') {

							frappe.msgprint({title: __("Message"), message: __('The purchases unit has to be filled'), indicator: "red"});
							raise;

						}

						if (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).value != '') {

							if (first_purchases_currency == '') {
								first_purchases_currency = document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).value.toUpperCase();
							}

							if (first_purchases_currency != document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).value.toUpperCase()) {

								frappe.msgprint({title: __("Message"), message: __('The purchases currency as to the same for all the selected lines'), indicator: "red"});
								raise;
							}
						}

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value.trim() != '') && (parseFloat(document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value))) {

							selected_parents += document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString()).value + '#sbsepa#';
							selected_names += document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString()).value + '#sbsepa#';
							selected_items += document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value + '#sbsepa#';
							selected_items_desc += document.getElementById('sales_to_order_direct_order_grid_detail_item_description_' + i.toString()).value + '#sbsepa#';

							selected_suppliers += document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value + '#sbsepa#';

							if ((document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value.trim() != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value.trim() != '')) {
								selected_purchases_conditions += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value + '#sbsepa#';
							}else{
								selected_purchases_conditions += document.getElementById('sales_to_order_direct_order_action_purchases_condition').value + '#sbsepa#';
							}


							selected_purchases_units += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value + '#sbsepa#';
							selected_purchases_currencies += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).value + '#sbsepa#';

							if (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value.trim() != '') {
								selected_qties += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value + '#sbsepa#';
							}else{
								selected_qties += '0#sbsepa#';
							}


							if (document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value.trim() != '') {
								selected_sales_qties += document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value + '#sbsepa#';
							}else{
								selected_sales_qties += '0#sbsepa#';
							}

							selected_sales_units += document.getElementById('sales_to_order_direct_order_grid_detail_unit_id_' + i.toString()).value + '#sbsepa#';


							if (document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value.trim() != '') {
								selected_required += document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value + '#sbsepa#';
							}else{
								selected_required += '0#sbsepa#';
							}
						}



						nb_selected++;

					}
				}
			}
		}

		if (selected_parents != '') {

			let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_po_for_this_supplier_check_if_all_data_exist';

			let can_continue = true;

			frappe.call({  	method: method,
							args: { "selected_suppliers": selected_suppliers,
									"selected_purchases_units" : selected_purchases_units,
									"selected_purchases_currencies" : selected_purchases_currencies,
									"selected_purchases_conditions" : selected_purchases_conditions
							},
							async: false,
							callback:function(r)	{

								if (r.message.error == 1) {

									can_continue = false
									frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
								}
							}
			});


			if (can_continue) {

				let top = 10;

				let msg = '';
				let s = '';

				if (nb_selected == 1) {
					s = __("Number of selected sales order line") + ' <b>' + nb_selected.toString() + '</b>';
				}else{
					s = __("Number of selected sales order lines") + ' <b>' + nb_selected.toString() + '</b>';
				}


				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
				top += 25;


				s = '<font color="blue">' + __('Create a new Purchase Order ?') + '</font>';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';



				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_confirm_open_po')) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_confirm_open_po').remove();

				}

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_confirm_open_po_label')) {
					document.getElementById('sales_to_order_direct_order_po_for_supplier_confirm_open_po_label').remove();

				}

				top += 35;



				s = '<div style="position: relative; top: 35px; left: 0px; width:350px;">';
				s += '	<input type="checkbox" id="sales_to_order_direct_order_po_for_supplier_confirm_open_po" style="position: absolute; top: 8px; left: 2px;">';
				s += '	<label id="sales_to_order_direct_order_po_for_supplier_confirm_open_po_label" style="position: absolute; top: 6px; left: 30px;">' + __("Open the new purchases order") + '</label>';
				s += '</div>';



				msg += s;

				top += 20;

				msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';



				frappe.confirm(	msg,
					() => 	{
								silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {

									silicon_ioi.doctype.ioiSalesToPurchases.do_new_purchases_order( selected_parents, selected_names, selected_items, selected_items_desc, selected_suppliers, selected_purchases_conditions,
																									selected_purchases_units, selected_purchases_currencies, selected_qties, selected_sales_qties, selected_sales_units, selected_required);



								});
							},
					() => 	{
							}
				);
			}
		}else{
			frappe.msgprint({title: __("Message"), message: __('No purchases order to create'), indicator: "red"});
			raise;

		}

	}

	static do_new_purchases_order(selected_parents, selected_names, selected_items, selected_items_desc, selected_suppliers, selected_purchases_conditions, selected_purchases_units, selected_purchases_currencies, selected_qties,
								  selected_sales_qties, selected_sales_units, selected_required)
	{
		let meth = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_po_for_this_supplier_create_purchases_order';

		frappe.call({  	method: meth,
						args: {	"journal_id": document.getElementById('sales_to_order_direct_order_action_journal').value,
								"selected_parents": selected_parents,
								"selected_names": selected_names,
								"selected_items": selected_items,
								"selected_items_desc": selected_items_desc,
								"selected_suppliers": selected_suppliers,
								"selected_purchases_conditions": selected_purchases_conditions,
								"selected_purchases_units": selected_purchases_units,
								"selected_purchases_currencies": selected_purchases_currencies,
								"selected_qties": selected_qties,
								"selected_sales_qties": selected_sales_qties,
								"selected_sales_units": selected_sales_units,
								"selected_required": selected_required
						},
						async: false,
						callback:function(r)	{

							if (r.message.error != 0) {

								frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
								raise;

							}

							let run_window = false;

							if (document.getElementById('sales_to_order_direct_order_po_for_supplier_confirm_open_po')) {

								if (document.getElementById('sales_to_order_direct_order_po_for_supplier_confirm_open_po').checked) {

									run_window = true;
									window.open('/app/ioi-purchases-order/' + r.message.po_name);
								}
							}

							if (!run_window) {
								frappe.msgprint({title: __("Message"), message: __("Purchases Order") + ' ' + r.message.po_name + ' has been created.', indicator: "blue"});
							}


							silicon_ioi.doctype.ioiSalesToPurchases.refresh_sales_order();
							silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
							silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();
						}
		});

	}

	static do_goto_purchases_order()
	{
		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_identification').value.trim() != '') {
			window.open('/app/ioi-purchases-order/' + document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_identification').value);
		}

	}

	static add_on_this_purchases_order_line()
	{
		if (!silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales order line selected'), indicator: "red"});
			raise;
		}

		if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_identification').value.trim() == '') {
			frappe.msgprint({title: __("Message"), message: __('Purchases Order is mandatory'), indicator: "red"});
			raise;
		}


		let selected_parents = '';
		let selected_names = '';
		let selected_items = '';
		let selected_items_desc = '';
		let selected_manufacturer_refs = '';

		let selected_purchases_conditions = '';
		let selected_purchases_units = '';

		let selected_qties = '';

		let selected_sales_qties = '';
		let selected_sales_units = '';
		let selected_required = '';


		let nb_selected = 0;



		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {

				if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {

					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

						if (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value.trim() == '') {

							frappe.msgprint({title: __("Message"), message: __('The purchases unit has to be filled'), indicator: "red"});
							raise;

						}

						if ((document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value.trim() != '') && (parseFloat(document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value) != 0)) {


							selected_parents += document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + i.toString()).value + '#sbsepa#';
							selected_names += document.getElementById('sales_to_order_direct_order_grid_detail_name_' + i.toString()).value + '#sbsepa#';
							selected_items += document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + i.toString()).value + '#sbsepa#';
							selected_items_desc += document.getElementById('sales_to_order_direct_order_grid_detail_item_description_' + i.toString()).value + '#sbsepa#';
							selected_manufacturer_refs += document.getElementById('sales_to_order_direct_order_grid_detail_manufacturer_ref_' + i.toString()).value + '#sbsepa#';


							if ((document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value.trim() != null) && (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value.trim() != '')) {
								selected_purchases_conditions += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value + '#sbsepa#';
							}else{
								selected_purchases_conditions += document.getElementById('sales_to_order_direct_order_action_purchases_condition').value + '#sbsepa#';
							}


							selected_purchases_units += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value + '#sbsepa#';


							if (document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value.trim() != '') {
								selected_qties += document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + i.toString()).value + '#sbsepa#';
							}else{
								selected_qties += '0#sbsepa#';
							}


							if (document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value.trim() != '') {
								selected_sales_qties += document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + i.toString()).value + '#sbsepa#';
							}else{
								selected_sales_qties += '0#sbsepa#';
							}

							selected_sales_units += document.getElementById('sales_to_order_direct_order_grid_detail_unit_id_' + i.toString()).value + '#sbsepa#';


							if (document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value.trim() != '') {
								selected_required += document.getElementById('sales_to_order_direct_order_grid_detail_required_' + i.toString()).value + '#sbsepa#';
							}else{
								selected_required += '0#sbsepa#';
							}
						}

						nb_selected++;

					}
				}
			}
		}

		if (selected_parents != '') {


			let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_po_for_this_supplier_check_if_units_and_purch_cond_exist';

			let can_continue = true;

			frappe.call({  	method: method,
							args: { "selected_purchases_units" : selected_purchases_units,
									"selected_purchases_conditions" : selected_purchases_conditions,

							},
							async: false,
							callback:function(r)	{

								if (r.message.error == 1) {

									can_continue = false
									frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
								}
							}
			});


			if (can_continue) {

				let top = 10;

				let msg = '';
				let s = '';

				if (nb_selected == 1) {
					s = __("Number of selected sales order line") + ' <b>' + nb_selected.toString() + '</b>';
				}else{
					s = __("Number of selected sales order lines") + ' <b>' + nb_selected.toString() + '</b>';
				}


				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
				top += 25;


				s = '<font color="blue">' + __('Add on this Purchase Order ?') + '</font>';

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

				top += 20;

				msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';



				frappe.confirm(	msg,
					() => 	{
								silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {

									silicon_ioi.doctype.ioiSalesToPurchases.do_add_on_this_purchases_order_line( selected_parents, selected_names, selected_items, selected_items_desc, selected_manufacturer_refs, selected_purchases_conditions,
																												selected_purchases_units, selected_qties, selected_sales_qties, selected_sales_units, selected_required)



								});
							},
					() => 	{
							}
				);
			}
		}else{
			frappe.msgprint({title: __("Message"), message: __("No purchases order line to create"), indicator: "red"});
			raise;

		}

	}

	static do_add_on_this_purchases_order_line( selected_parents, selected_names, selected_items, selected_items_desc, selected_manufacturer_refs, selected_purchases_conditions, selected_purchases_units, selected_qties,
												selected_sales_qties, selected_sales_units, selected_required)
	{

		let meth = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_po_for_this_supplier_add_on_purchases_order';

		frappe.call({  	method: meth,
						args: {	"name": document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_identification').value,
								"selected_parents": selected_parents,
								"selected_names": selected_names,
								"selected_items": selected_items,
								"selected_items_desc": selected_items_desc,
								"selected_manufacturer_refs": selected_manufacturer_refs,
								"selected_purchases_conditions": selected_purchases_conditions,
								"selected_purchases_units": selected_purchases_units,
								"selected_qties": selected_qties,
								"selected_sales_qties": selected_sales_qties,
								"selected_sales_units": selected_sales_units,
								"selected_required": selected_required
						},
						async: false,
						callback:function(r)	{

							if (r.message.error != 0) {

								frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
								raise;

							}

							silicon_ioi.doctype.ioiSalesToPurchases.refresh_sales_order();
							silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
							silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();

						}
		});


	}

	static merge_on_this_purchases_order_line(origin = 'ITEM')
	{
		if (!silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales order line selected'), indicator: "red"});
			raise;
		}

		// Check only one row selected

		let nb_selected = 0;
		let idx = -1;

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {


			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {

				if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

					if (idx == -1) {
						idx = i;
					}

					nb_selected++;
				}
			}

			if (nb_selected > 1) {
				frappe.msgprint({title: __("Message"), message: __('Only one sales order line has to be selected'), indicator: "red"});
				raise;
			}
		}

		let po_parent_name = '';
		let po_detail_name = '';


		if (origin.toUpperCase() == 'SUPPLIER') {

			if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_identification').value.trim() == '') {
				frappe.msgprint({title: __("Message"), message: __('Purchases Order is mandatory'), indicator: "red"});
				raise;
			}

			let idx_po_line = -1;

			if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows.length == 0) {

				frappe.msgprint({title: __("Message"), message: __('No selected purchases order line'), indicator: "red"});
				raise;
			}

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + i.toString())) {
					if (document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_checked_id_' + i.toString()).checked) {
						idx_po_line = i;
						break;
					}
				}
			}

			if (idx_po_line == -1) {

				frappe.msgprint({title: __("Message"), message: __('The purchases order line has to be selected'), indicator: "red"});
				raise;
			}

			po_parent_name = document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_identification').value;
			po_detail_name = document.getElementById('sales_to_order_direct_order_po_for_supplier_form_header_detail_name_' + idx_po_line.toString()).value;
		}else{

			let idx_po_line = -1;

			if (document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows.length == 0) {

				frappe.msgprint({title: __("Message"), message: __('No selected purchases order line'), indicator: "red"});
				raise;
			}

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_po_for_item_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + i.toString())) {
					if (document.getElementById('sales_to_order_direct_order_po_for_item_checked_id_' + i.toString()).checked) {
						idx_po_line = i;
						break;
					}
				}
			}

			if (idx_po_line == -1) {

				frappe.msgprint({title: __("Message"), message: __('The purchases order line has to be selected'), indicator: "red"});
				raise;
			}

			po_parent_name = document.getElementById('sales_to_order_direct_order_po_for_item_parent_' + idx_po_line.toString()).value;
			po_detail_name = document.getElementById('sales_to_order_direct_order_po_for_item_name_' + idx_po_line.toString()).value;
		}

		let so_parent_name = document.getElementById('sales_to_order_direct_order_grid_detail_parent_' + idx.toString()).value;
		let so_detail_name = document.getElementById('sales_to_order_direct_order_grid_detail_name_' + idx.toString()).value;


		let sales_qty = document.getElementById('sales_to_order_direct_order_grid_detail_toorder_' + idx.toString()).value;
		let sales_unit_id = document.getElementById('sales_to_order_direct_order_grid_detail_unit_id_' + idx.toString()).value;


		let purchases_qty = document.getElementById('sales_to_order_direct_order_grid_detail_purchases_toorder_' + idx.toString()).value;
		let purchases_unit_id = document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + idx.toString()).value;
		let required_qty = document.getElementById('sales_to_order_direct_order_grid_detail_required_' + idx.toString()).value;
		let required_unit_id = document.getElementById('sales_to_order_direct_order_grid_detail_unit_id_' + idx.toString()).value;


		let top = 10;

		let s = '<font color="blue">' + __('Are you sure to merge on this purchases order line ?') + '</font>';
		let msg = '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';


		top += 20;
		msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


		frappe.confirm(	msg,
						() => 	{
									document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

									silicon_ioi.doctype.ioiSalesToPurchases.do_merge_on_this_purchases_order_line(po_parent_name, po_detail_name, so_parent_name, so_detail_name, sales_qty, sales_unit_id, purchases_qty,
																												  purchases_unit_id, required_qty, required_unit_id);

								},
						() => 	{
								}
		);
	}

	static do_merge_on_this_purchases_order_line(po_parent_name, po_detail_name, so_parent_name, so_detail_name, sales_qty, sales_unit_id, purchases_qty, purchases_unit_id, required_qty, required_unit_id)
	{

		let meth = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases +  '.direct_order_po_for_this_supplier_merge_on_this_po_line';

		frappe.call({  	method: meth,
						args: {	"po_parent_name": po_parent_name,
								"po_detail_name": po_detail_name,
								"so_parent_name": so_parent_name,
								"so_detail_name": so_detail_name,
								"sales_qty": sales_qty,
								"sales_unit_id": sales_unit_id,
								"purchases_qty": purchases_qty,
								"purchases_unit_id": purchases_unit_id,
								"required_qty": required_qty,
								"required_unit_id": required_unit_id
						},
						async: false,
						callback:function(r)	{



							if (r.message.error != 0) {

								frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
								raise;

							}

							frappe.msgprint({title: __("Message"), message: __("The selected order line has been merged."), indicator: "blue"});

							silicon_ioi.doctype.ioiSalesToPurchases.refresh_sales_order();
							silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_item();
							silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();

						}
		});
	}




	// ********************************************************************************************************************************************************************************************************
	//
	// Tab 3 : Purchases Prices
	//
	// ********************************************************************************************************************************************************************************************************


	static build_purchases_prices() {


		if (document.getElementById('sales_to_order_direct_order_purchases_prices_grid')) {
			document.getElementById('sales_to_order_direct_order_purchases_prices_grid').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail')) {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_row_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_row_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_name_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_name_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString()).remove();
				}

			}

			document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_purchases_prices_param')) {
			document.getElementById('sales_to_order_direct_order_purchases_prices_param').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_purchases_prices')) {
			document.getElementById('sales_to_order_direct_order_purchases_prices').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_purchases_prices_search_label')) {
			document.getElementById('sales_to_order_direct_order_purchases_prices_search_label').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_purchases_prices_search')) {
			document.getElementById('sales_to_order_direct_order_purchases_prices_search').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty')) {
			document.getElementById('sales_to_order_direct_order_purchases_prices_qty').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_title')) {
			document.getElementById('sales_to_order_direct_order_purchases_prices_qty_title').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid')) {
			document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail')) {
			document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_actions')) {
			document.getElementById('sales_to_order_direct_order_purchases_prices_qty_actions').remove();
		}

		if (document.getElementById('button_purchases_prices_use_price_and_condition')) {
			document.getElementById('button_purchases_prices_use_price_and_condition').remove();
		}

		if (document.getElementById('button_purchases_prices_set_as_prefered')) {
			document.getElementById('button_purchases_prices_set_as_prefered').remove();
		}
		if (document.getElementById('button_purchases_prices_delete_site_price')) {
			document.getElementById('button_purchases_prices_delete_site_price').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_supplier_id')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_supplier_id').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_purchases_condition_id')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_purchases_condition_id').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_id')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_id').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_currency_id')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_currency_id').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_price')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_price').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_prefered')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_prefered').remove();
		}



		let html = '';


		html = '<div id="sales_to_order_direct_order_purchases_prices_param" style="overflow-x: auto;height:110px">';

		html += '	<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '	</div>'


		html += '	<div style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '		<label style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Filters & actions") + '</b></label>';
		html += '	</div>'

		html += '	<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '	</div>'


		// Search
		html += '	<div style="position: relative; top: 0px; left: 0px; width:250px;">';
		html += '		<label id="sales_to_order_direct_order_purchases_prices_search_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 250px; height: 25px;"> ';
		html += '			<input id="sales_to_order_direct_order_purchases_prices_search" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';



		// Use price and condition
		html += '	<div style="position: relative; top: 27px; left: 260px; width:220px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="button_purchases_prices_use_price_and_condition" class="btn btn-default ellipsis" style="height: 30px; width: 220px;" onclick="">' + __("Use price and condition") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Set as prefered
		html += '<div style="position: relative; top: 27px; left: 490px; width:220px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='		<button id="button_purchases_prices_set_as_prefered" class="btn btn-default ellipsis" style="height: 30px; width: 220px;" onclick="">' + __("Set as prefered") + '</button>';
		html += '	</div>';
		html += '</div>';

		// Delete site price
		html += '<div style="position: relative; top: 27px; left: 720px; width:220px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='		<button id="button_purchases_prices_delete_site_price" class="btn btn-default ellipsis" style="height: 30px; width: 220px;" onclick="">' + __("Delete site price") + '</button>';
		html += '	</div>';
		html += '</div>';


		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<input id="sales_to_order_direct_order_selected_purchases_prices_name" type="hidden" value="">';
		html += '<input id="sales_to_order_direct_order_selected_purchases_prices_supplier_id" type="hidden" value="">';
		html += '<input id="sales_to_order_direct_order_selected_purchases_prices_purchases_condition_id" type="hidden" value="">';
		html += '<input id="sales_to_order_direct_order_selected_purchases_prices_unit_id" type="hidden" value="">';
		html += '<input id="sales_to_order_direct_order_selected_purchases_prices_currency_id" type="hidden" value="">';
		html += '<input id="sales_to_order_direct_order_selected_purchases_prices_unit_price" type="hidden" value="">';
		html += '<input id="sales_to_order_direct_order_selected_purchases_prices_prefered" type="hidden" value="0">';


		html += '<div id="sales_to_order_direct_order_purchases_prices" style="overflow-x: auto;height:200px">';

		html += '<table id="sales_to_order_direct_order_purchases_prices_grid" border=1 style="border: 1px solid #E8EAEB" width=1810px data-custom-grid="true">';

		html += '<tr style="height:30px">';
		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Supplier") + '</b></td>';
		html += '<td width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Purchases cond.") + '</b></td>';
		html += '<td width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Unit") + '</b></td>';
		html += '<td width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Curr.") + '</b></td>';
		html += '<td width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Site") + '</b></td>';
		html += '<td width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Start date") + '</b></td>';
		html += '<td width=100px style="vertical-align: middle;"><b>&nbsp;' + __("End date") + '</b></td>';
		html += '<td width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Prefered") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Unit price") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Fixed price") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Base discount") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Extra discount") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Value discount") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Min order qty") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Mult. order qty") + '</b></td>';
		html += '</tr>';
		html += '</table>';


		html += '<table id="sales_to_order_direct_order_purchases_prices_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=1810px>';
		html += '<tr style="height:30px">';
		html += '<td width=30px>&nbsp;</td>';
		html += '<td width=30px>&nbsp;</td>';
		html += '<td width=200px>&nbsp;</td>';
		html += '<td width=150px>&nbsp;</td>';
		html += '<td width=100px>&nbsp;</td>';
		html += '<td width=80px>&nbsp;</td>';
		html += '<td width=80px>&nbsp;</td>';
		html += '<td width=100px>&nbsp;</td>';
		html += '<td width=100px>&nbsp;</td>';
		html += '<td width=100px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '</tr>';
		html += '</table>';



		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div id="sales_to_order_direct_order_purchases_prices_qty" style="overflow-x: auto;height:40px">';

		// Price per quantity title
		html += '	<div style="position: relative; top: 0px; left: 0px; width:250px;">';
		html += '		<label id="sales_to_order_direct_order_purchases_prices_qty_title" style="position: absolute; top: 0px; left: 2px;z-index:5;"><b><u>' + __("Price per quantity") + '</u></b></label>';
		html += '	</div>';
		html += '</div>';


		html += '<div id="sales_to_order_direct_order_purchases_prices_qty_content" style="overflow-x: auto;height:150px">';

		html += '<table id="sales_to_order_direct_order_purchases_prices_qty_grid" border=1 style="border: 1px solid #E8EAEB" width=630px data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Trigger qty") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Unit price") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Fixed price") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Base discount") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Extra discount") + '</b></td>';
		html += '</tr>';
		html += '</table>';


		html += '<table id="sales_to_order_direct_order_purchases_prices_qty_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=630px>';
		html += '<tr style="height:30px">';
		html += '<td width=30px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '<td width=120px>&nbsp;</td>';
		html += '</tr>';
		html += '</table>';

		html += '</div>';


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'





		document.getElementById('sales_to_order_direct_order_purchases_prices_content').innerHTML = html;

		silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {

			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value = '';

			let fct_keydown_search = function(event) {

				if (event.keyCode == 13)
				{
					silicon_ioi.doctype.ioiSalesToPurchases.load_purchases_prices()
					return false;
				}

			};

			let fct_keyup = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};



			document.getElementById('sales_to_order_direct_order_purchases_prices_search').onkeydown = fct_keydown_search;
			document.getElementById('sales_to_order_direct_order_purchases_prices_search').onkeyup = fct_keyup;


			let fct_purchases_prices_use_price_and_condition = function() {
				silicon_ioi.doctype.ioiSalesToPurchases.purchases_prices_use_price_and_condition();
			};
			let fct_purchases_prices_set_as_prefered = function() {
				silicon_ioi.doctype.ioiSalesToPurchases.purchases_prices_set_as_prefered();
			};

			let fct_purchases_prices_delete_site_price = function() {
				silicon_ioi.doctype.ioiSalesToPurchases.purchases_prices_delete_site_price();
			};



			document.getElementById('button_purchases_prices_use_price_and_condition').onclick = fct_purchases_prices_use_price_and_condition;
			document.getElementById('button_purchases_prices_use_price_and_condition').onkeyup = fct_keyup;

			document.getElementById('button_purchases_prices_set_as_prefered').onclick = fct_purchases_prices_set_as_prefered;
			document.getElementById('button_purchases_prices_set_as_prefered').onkeyup = fct_keyup;

			document.getElementById('button_purchases_prices_delete_site_price').onclick = fct_purchases_prices_delete_site_price;
			document.getElementById('button_purchases_prices_delete_site_price').onkeyup = fct_keyup;


			silicon_ioi.doctype.ioiSalesToPurchases.load_purchases_prices();
		});

	}

	static load_purchases_prices()
	{
		if (document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail')) {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_row_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_row_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_name_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_name_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_supplier_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_supplier_id_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_purchases_condition_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_purchases_condition_id_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_unit_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_unit_id_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_currency_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_currency_id_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_unit_price_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_unit_price_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_prefered_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_prefered_' + i.toString()).remove();
				}



			}

			document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').remove();
		}

		if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail')) {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_row_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_qty_row_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_name_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_qty_name_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_qty_checked_id_' + i.toString()).remove();
				}



			}

			document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').remove();
		}


		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value = '';
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_supplier_id')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_supplier_id').value = '';
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_purchases_condition_id')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_purchases_condition_id').value = '';
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_id')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_id').value = '';
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_currency_id')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_currency_id').value = '';
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_price')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_price').value = '';
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_prefered')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_prefered').value = 0;
		}

		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_current_item_id')) {
			document.getElementById('sales_to_order_direct_order_selected_purchases_prices_current_item_id').remove();
		}


		let html = '';
		let color_row = false;
		html += '<table id="sales_to_order_direct_order_purchases_prices_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=1810px>';


		let nb_selected = 0;
		let can_display = true;
		let item_id = '';

		html += '<input id="sales_to_order_direct_order_selected_purchases_prices_qty_name" type="hidden" value="">';

		if (document.getElementById('sales_to_order_direct_order_grid_detail').rows.length == 0) {
			can_display = false;
		}else{
			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

				if (i == document.getElementById('sales_to_order_direct_order_grid_detail_selected_line').value) {

					if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
						(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
						(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {
						item_id = document.getElementById('sales_to_order_direct_order_grid_detail_item_id_' + document.getElementById('sales_to_order_direct_order_grid_detail_selected_line').value).value;
						can_display = true;
					}else{
						can_display = false;
					}
					break;
				}
			}
		}

		document.getElementById('button_purchases_prices_set_as_prefered').innerHTML = __("Set as unprefered");

		if (!can_display) {

			html += '<tr style="height:30px">';
			html += '<td width=30px>&nbsp;</td>';
			html += '<td width=30px>&nbsp;</td>';
			html += '<td width=200px>&nbsp;</td>';
			html += '<td width=150px>&nbsp;</td>';
			html += '<td width=100px>&nbsp;</td>';
			html += '<td width=80px>&nbsp;</td>';
			html += '<td width=80px>&nbsp;</td>';
			html += '<td width=100px>&nbsp;</td>';
			html += '<td width=100px>&nbsp;</td>';
			html += '<td width=100px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '</tr>';

		}else {

			html += '<input id="sales_to_order_direct_order_selected_purchases_prices_current_item_id" type="hidden" value="' + item_id + '">';


			let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_get_purchases_prices';

			frappe.call({  	method: method,
							args: {	"item_id" : item_id,
									"search_term": document.getElementById('sales_to_order_direct_order_purchases_prices_search').value
							},
							async: false,
							callback:function(r)	{


								if (r.message.length > 0)
								{

									color_row = true;

									for (var i = 0; i < r.message.length; i++)
									{


										if (i == 0)
										{	document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value = r.message[i].name;


											document.getElementById('sales_to_order_direct_order_selected_purchases_prices_supplier_id').value = r.message[i].supplier_id;
											document.getElementById('sales_to_order_direct_order_selected_purchases_prices_purchases_condition_id').value = r.message[i].purchases_condition_id;
											document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_id').value = r.message[i].unit_id;
											document.getElementById('sales_to_order_direct_order_selected_purchases_prices_currency_id').value = r.message[i].currency_id;
											document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_price').value = r.message[i].unit_price;
											document.getElementById('sales_to_order_direct_order_selected_purchases_prices_prefered').value = 0;

											if (r.message[i].prefered == 1) {
												document.getElementById('sales_to_order_direct_order_selected_purchases_prices_prefered').value = 1;
											}

										}



										html += '<tr id="sales_to_order_direct_order_purchases_prices_row_' + i.toString() + '" style="height:30px">';
										html += '<input id="sales_to_order_direct_order_purchases_prices_name_' + i.toString() + '" type="hidden" value="' + r.message[i].name + '">';

										if (r.message[i].supplier_id != null) {
											html += '<input id="sales_to_order_direct_order_purchases_prices_supplier_id_' + i.toString() + '" type="hidden" value="' + r.message[i].supplier_id + '">';
										}else{
											html += '<input id="sales_to_order_direct_order_purchases_prices_supplier_id_' + i.toString() + '" type="hidden" value="">';
										}

										if (r.message[i].purchases_condition_id != null) {
											html += '<input id="sales_to_order_direct_order_purchases_prices_purchases_condition_id_' + i.toString() + '" type="hidden" value="' + r.message[i].purchases_condition_id + '">';
										}else{
											html += '<input id="sales_to_order_direct_order_purchases_prices_purchases_condition_id_' + i.toString() + '" type="hidden" value="">';
										}

										if (r.message[i].unit_id != null) {
											html += '<input id="sales_to_order_direct_order_purchases_prices_unit_id_' + i.toString() + '" type="hidden" value="' + r.message[i].unit_id + '">';
										}else{
											html += '<input id="sales_to_order_direct_order_purchases_prices_unit_id_' + i.toString() + '" type="hidden" value="">';
										}

										if (r.message[i].currency_id != null) {
											html += '<input id="sales_to_order_direct_order_purchases_prices_currency_id_' + i.toString() + '" type="hidden" value="' + r.message[i].currency_id + '">';
										}else{
											html += '<input id="sales_to_order_direct_order_purchases_prices_currency_id_' + i.toString() + '" type="hidden" value="">';
										}

										if (r.message[i].unit_price != null) {
											html += '<input id="sales_to_order_direct_order_purchases_prices_unit_price_' + i.toString() + '" type="hidden" value="' + r.message[i].unit_price + '">';
										}else{
											html += '<input id="sales_to_order_direct_order_purchases_prices_unit_price_' + i.toString() + '" type="hidden" value="0">';
										}

										if (r.message[i].prefered == 1) {
											html += '<input id="sales_to_order_direct_order_purchases_prices_prefered_' + i.toString() + '" type="hidden" value="1">';
										}else{
											html += '<input id="sales_to_order_direct_order_purchases_prices_prefered_' + i.toString() + '" type="hidden" value="0">';
										}


										html += '<td width=30px align="center" style="vertical-align: middle;">';


										html += '<input type="checkbox" id="sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" ';

										if (i == 0) {
											html += "checked ";
										}

										html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
										html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
										html += '>';
										html += '</td>';



										html += '<td width=30px align="center" style="vertical-align: middle;">';

										let s = '/app/ioi-purchases-price/' + r.message[i].name;
										html += '<button style="position:relative; height: 25px; width: 25px; z-index:10;" onclick="window.open(\'' + s + '\');">' + __("...") + '</button>';

										html += '</td>';

										if (r.message[i].supplier_id != null)
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].supplier_id + '</td>';
										}else
										{	html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].purchases_condition_id != null)
										{	html += '<td width=150px style="vertical-align: middle;">&nbsp;' + r.message[i].purchases_condition_id + '</td>';
										}else
										{	html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].unit_id != null)
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].unit_id + '</td>';
										}else
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].currency_id != null)
										{	html += '<td width=80px style="vertical-align: middle;">&nbsp;' + r.message[i].currency_id + '</td>';
										}else
										{	html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].site_id != null)
										{
											if (r.message[i].site_id != r.message[i].current_site) {
												html += '<td width=80px style="vertical-align: middle;background-color:#FFB870; color:#000000;">&nbsp;' + r.message[i].site_id + '</td>';
											}else{
												html += '<td width=80px style="vertical-align: middle;">&nbsp;' + r.message[i].site_id + '</td>';
											}
										}else
										{	html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
										}


										if (r.message[i].start_date != null)
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].start_date + '</td>';
										}else
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].end_date != null)
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].end_date + '</td>';
										}else
										{	html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
										}

										if (r.message[i].prefered != null)
										{	html += '<td width=100px style="vertical-align: middle;" align="center">';

											if (r.message[i].prefered == 1) {
												html += '<input  type="checkbox" style="postion:absolute; top: 2px; left: 2px;" disabled checked> ';
											}else{
												html += '<input type="checkbox" style="postion:absolute; top: 2px; left: 2px;" disabled> ';
											}

											html += '</td>';

										}else
										{	html += '<td width=100px style="vertical-align: middle;" align="center">';
											html += '<input type="checkbox" style="postion:absolute; top: 2px; left: 2px;" disabled> ';
											html += '</td>';
										}

										if ((r.message[i].unit_price != null) && (r.message[i].unit_price != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].unit_price + '&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].fixed_price != null) && (r.message[i].fixed_price != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].fixed_price + '&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].base_discount != null) && (r.message[i].base_discount != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].base_discount + '&nbsp;%&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].extra_discount != null) && (r.message[i].extra_discount != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].extra_discount + '&nbsp;%&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].discount_in_value != null) && (r.message[i].discount_in_value != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].discount_in_value + '&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].min_order_qty != null) && (r.message[i].min_order_qty != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].min_order_qty + '&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].multiple_order_qty != null) && (r.message[i].multiple_order_qty != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].multiple_order_qty + '&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}


										html += '</tr>';
									}

									html += '</table>';

								}
								else
								{
									html += '<tr style="height:30px">';
									html += '<td width=30px>&nbsp;</td>';
									html += '<td width=30px>&nbsp;</td>';
									html += '<td width=200px>&nbsp;</td>';
									html += '<td width=150px>&nbsp;</td>';
									html += '<td width=100px>&nbsp;</td>';
									html += '<td width=80px>&nbsp;</td>';
									html += '<td width=80px>&nbsp;</td>';
									html += '<td width=100px>&nbsp;</td>';
									html += '<td width=100px>&nbsp;</td>';
									html += '<td width=100px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '</tr>';
								}

							}
			});


		}
		html += '</table>';

		document.getElementById('sales_to_order_direct_order_purchases_prices_grid').insertAdjacentHTML('afterend', html);

		silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {


			let fct_check_click = function() {

				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows.length; i++) {

					document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString()).checked = false;

					if (document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString()).id == this.id) {
						document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString()).checked = true;
					}

				}

			};

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString()).onclick = fct_check_click;
				}

			}



			if (color_row)
			{
				let fct_row_click = function () {
					silicon_ioi.doctype.ioiSalesToPurchases.line_purchases_prices_click(this.id);
				};


				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows.length; i++)
				{
					document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[i].onclick = fct_row_click;
				}

				for (var j = 0; j < document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[0].cells.length; j++)
				{
					if (j == 6) {

						if ((document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[0].cells[j].style.backgroundColor.toString() == 'rgb(255, 255, 255)') ||
							(document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[0].cells[j].style.backgroundColor.toString() == 'rgb(177, 252, 217)') ||
							(document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[0].cells[j].style.backgroundColor.toString().trim() == '') ||
							(document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[0].cells[j].style.backgroundColor.toString().trim() == 'green'))
						{
							if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
								document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[0].cells[j].style.backgroundColor = '#b1fcd9';
							}else{
								document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[0].cells[j].style.backgroundColor = 'green';
							}

						}
					}else{
						if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
							document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[0].cells[j].style.backgroundColor = '#b1fcd9';
						}else{
							document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[0].cells[j].style.backgroundColor = 'green';
						}
					}
				}

				silicon_ioi.doctype.ioiSalesToPurchases.line_purchases_prices_click(document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[0].id);
			}



			silicon_ioi.doctype.ioiSalesToPurchases.do_load_purchases_prices_qty();


		});




	}


	static line_purchases_prices_click(line_id)
	{
		let s = line_id;


		s = s.substring(49, s.length);


		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value.trim() != '') {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows.length; i++) {

				document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString()).checked = false;

				for (var j = 0; j < document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[i].cells.length; j++) {

						if (j == 6) {

							if ((document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[i].cells[j].style.backgroundColor.toString() == 'rgb(255, 255, 255)') ||
								(document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[i].cells[j].style.backgroundColor.toString() == 'rgb(177, 252, 217)') ||
								(document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[i].cells[j].style.backgroundColor.toString().trim() == '') ||
								(document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[0].cells[j].style.backgroundColor.toString().trim() == 'green'))


							{
								if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
									document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[i].cells[j].style.backgroundColor = '#FFFFFF';
								}else{
									document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[i].cells[j].style.backgroundColor = '#1C2126';
								}

							}
						}else{
							if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
								document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[i].cells[j].style.backgroundColor = '#FFFFFF';
							}else{
								document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[i].cells[j].style.backgroundColor = '#1C2126';
							}
						}
				}
			}
		}

		document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value = document.getElementById('sales_to_order_direct_order_purchases_prices_name_'+s).value;

		document.getElementById('sales_to_order_direct_order_selected_purchases_prices_supplier_id').value = document.getElementById('sales_to_order_direct_order_purchases_prices_supplier_id_'+s).value;
		document.getElementById('sales_to_order_direct_order_selected_purchases_prices_purchases_condition_id').value = document.getElementById('sales_to_order_direct_order_purchases_prices_purchases_condition_id_'+s).value;
		document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_id').value = document.getElementById('sales_to_order_direct_order_purchases_prices_unit_id_'+s).value;
		document.getElementById('sales_to_order_direct_order_selected_purchases_prices_currency_id').value = document.getElementById('sales_to_order_direct_order_purchases_prices_currency_id_'+s).value;
		document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_price').value = document.getElementById('sales_to_order_direct_order_purchases_prices_unit_price_'+s).value;
		document.getElementById('sales_to_order_direct_order_selected_purchases_prices_prefered').value = document.getElementById('sales_to_order_direct_order_purchases_prices_prefered_'+s).value;


		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_row_'+s).cells.length; i++)
		{
			if (i == 6) {


				if ((document.getElementById('sales_to_order_direct_order_purchases_prices_row_'+s).cells[i].style.backgroundColor.toString() == 'rgb(255, 255, 255)') ||
					(document.getElementById('sales_to_order_direct_order_purchases_prices_row_'+s).cells[i].style.backgroundColor.toString() == 'rgb(177, 252, 217)') ||
					(document.getElementById('sales_to_order_direct_order_purchases_prices_row_'+s).cells[i].style.backgroundColor.toString().trim() == '') ||
					(document.getElementById('sales_to_order_direct_order_purchases_prices_row_'+s).cells[i].style.backgroundColor.toString().trim() == 'green'))
				{
					if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
						document.getElementById('sales_to_order_direct_order_purchases_prices_row_'+s).cells[i].style.backgroundColor = '#b1fcd9';
					}else{
						document.getElementById('sales_to_order_direct_order_purchases_prices_row_'+s).cells[i].style.backgroundColor = 'green';
					}

				}
			}else{
				if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_row_'+s).cells[i].style.backgroundColor = '#b1fcd9';
				}else{
					document.getElementById('sales_to_order_direct_order_purchases_prices_row_'+s).cells[i].style.backgroundColor = 'green';
				}
			}
		}

		document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + s).checked = true;

		if (document.getElementById('sales_to_order_direct_order_purchases_prices_prefered_'+s)) {
			if (document.getElementById('sales_to_order_direct_order_purchases_prices_prefered_' + s).value == '1') {

				document.getElementById('button_purchases_prices_set_as_prefered').innerHTML = __("Set as unprefered");
			}else{
				document.getElementById('button_purchases_prices_set_as_prefered').innerHTML = __("Set as prefered");
			}
		}


		silicon_ioi.doctype.ioiSalesToPurchases.do_load_purchases_prices_qty();

	}


	static do_load_purchases_prices_qty()
	{
		if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail')) {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows.length; i++) {

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_row_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_qty_row_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_name_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_qty_name_' + i.toString()).remove();
				}

				if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_checked_id_' + i.toString())) {
					document.getElementById('sales_to_order_direct_order_purchases_prices_qty_checked_id_' + i.toString()).remove();
				}

			}

			document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').remove();
		}


		let html = '';
		let color_row = false;

		html += '<table id="sales_to_order_direct_order_purchases_prices_qty_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=630px>';

		document.getElementById('sales_to_order_direct_order_selected_purchases_prices_qty_name').value = ''

		if ((!document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name')) || ((document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name')) && (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value.trim() == ''))) {

			html += '<tr style="height:30px">';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '<td width=120px>&nbsp;</td>';
			html += '</tr>';

		}else {

			let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_get_purchases_prices_qty'

			frappe.call({  	method: method,
							args: {	"parent" : document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value
							},
							async: false,
							callback:function(r)	{


								if (r.message.length > 0)
								{

									color_row = true;

									for (var i = 0; i < r.message.length; i++)
									{


										if (i == 0)
										{	document.getElementById('sales_to_order_direct_order_selected_purchases_prices_qty_name').value = r.message[i].name;
										}


										html += '<tr id="sales_to_order_direct_order_purchases_prices_qty_row_' + i.toString() + '" style="height:30px">';
										html += '<input id="sales_to_order_direct_order_purchases_prices_qty_name_' + i.toString() + '" type="hidden" value="' + r.message[i].name + '">';


										html += '<td width=30px align="center" style="vertical-align: middle;">';


										html += '<input type="checkbox" id="sales_to_order_direct_order_purchases_prices_qty_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;" ';

										if (i == 0) {
											html += "checked ";
										}

										html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
										html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
										html += '>';
										html += '</td>';


										if ((r.message[i].trigger_qty != null) && (r.message[i].trigger_qty != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].trigger_qty + '&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}


										if ((r.message[i].unit_price != null) && (r.message[i].unit_price != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].unit_price + '&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].fixed_price != null) && (r.message[i].fixed_price != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].fixed_price + '&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].base_discount != null) && (r.message[i].base_discount != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].base_discount + '&nbsp;%&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}

										if ((r.message[i].extra_discount != null) && (r.message[i].extra_discount != 0))
										{	html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].extra_discount + '&nbsp;%&nbsp;</td>';
										}else
										{	html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
										}



										html += '</tr>';
									}

									html += '</table>';

								}
								else
								{
									html += '<tr style="height:30px">';
									html += '<td width=30px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '<td width=120px>&nbsp;</td>';
									html += '</tr>';
								}

							}
			});


		}
		html += '</table>';

		document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid').insertAdjacentHTML('afterend', html);


		silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {


			let fct_check_click = function() {

				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows.length; i++) {

					document.getElementById('sales_to_order_direct_order_purchases_prices_qty_checked_id_' + i.toString()).checked = false;

					if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_checked_id_' + i.toString()).id == this.id) {
						document.getElementById('sales_to_order_direct_order_purchases_prices_qty_checked_id_' + i.toString()).checked = true;
					}

				}

			};

			if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail')) {

				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows.length; i++) {

					if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_checked_id_' + i.toString())) {
						document.getElementById('sales_to_order_direct_order_purchases_prices_qty_checked_id_' + i.toString()).onclick = fct_check_click;
					}

				}



				if (color_row)
				{
					let fct_row_click = function () { silicon_ioi.doctype.ioiSalesToPurchases.line_purchases_prices_qty_click(this.id); };


					for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows.length; i++)
					{
						document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows[i].onclick = fct_row_click;
					}

					if (document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows[0]) {

						for (var j = 0; j < document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows[0].cells.length; j++)
						{
							if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
								document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows[0].cells[j].style.backgroundColor = '#b1fcd9';
							}else{
								document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows[0].cells[j].style.backgroundColor = 'green';
							}
						}
					}
				}
			}


		});

	}

	static line_purchases_prices_qty_click(line_id)
	{
		let s = line_id;

		s = s.substring(53, s.length);


		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_qty_name').value.trim() != '') {

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows.length; i++) {

				document.getElementById('sales_to_order_direct_order_purchases_prices_qty_checked_id_' + i.toString()).checked = false;

				for (var j = 0; j < document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows[i].cells.length; j++) {

					if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
						document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows[i].cells[j].style.backgroundColor = '#FFFFFF';
					}else{
						document.getElementById('sales_to_order_direct_order_purchases_prices_qty_grid_detail').rows[i].cells[j].style.backgroundColor = '#1C2126';
					}
				}
			}
		}

		document.getElementById('sales_to_order_direct_order_selected_purchases_prices_qty_name').value = document.getElementById('sales_to_order_direct_order_purchases_prices_qty_name_'+s).value;


		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_qty_row_'+s).cells.length; i++)
		{
			if (silicon_ioi.doctype.ioiSalesToPurchases.is_dark_mode == 0) {
				document.getElementById('sales_to_order_direct_order_purchases_prices_qty_row_'+s).cells[i].style.backgroundColor = '#b1fcd9';
			}else{
				document.getElementById('sales_to_order_direct_order_purchases_prices_qty_row_'+s).cells[i].style.backgroundColor = 'green';
			}


		}

		document.getElementById('sales_to_order_direct_order_purchases_prices_qty_checked_id_' + s).checked = true;

	}



	static purchases_prices_use_price_and_condition()
	{

		if (!document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail')) {
			frappe.msgprint({title: __("Message"), message: __('No purchases price selected'), indicator: "red"});
			raise;
		}else {
			if (document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows.length == 0) {
				frappe.msgprint({title: __("Message"), message: __('No purchases price selected'), indicator: "red"});
				raise;
			}else {

				let at_least_one_checked = false;

				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows.length; i++) {

					if (!document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString()))
					{
						frappe.msgprint({title: __("Message"), message: __('No purchases price selected'), indicator: "red"});
						raise;
					}else {
						if (document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString()).checked)
						{
							at_least_one_checked = true;
							break;
						}
					}
				}

				if (!at_least_one_checked) {
					frappe.msgprint({title: __("Message"), message: __('No purchases price selected'), indicator: "red"});
					raise;
				}
			}
		}

		if (!silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_line_selected()) {
			frappe.msgprint({title: __("Message"), message: __('No sales order line selected'), indicator: "red"});
			raise;
		}

		let nb_selected = 0;

		for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

			if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
				(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {

				if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {
					if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {
						nb_selected++;
					}
				}
			}
		}


		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value.trim() != '') {

			let top = 10;

			let msg = '';
			let s = '';

			if (nb_selected > 1) {
				s = __("Only the current order line or all the selected order lines with same item") + ' ?';
				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
				top += 25;

				if (document.getElementById('sales_to_order_only_current_order_line_label')) {
					document.getElementById('sales_to_order_only_current_order_line_label').remove();
				}

				if (document.getElementById('sales_to_order_only_current_order_line')) {
					document.getElementById('sales_to_order_only_current_order_line').remove();
				}


				s = '<div style="position: absolute; top: ' + top.toString() + 'px; left: 0px; width:350px;">';
				s += '	<input type="checkbox" id="sales_to_order_only_current_order_line" style="position: absolute; top: 8px; left: 20px;">';
				s += '	<label id="sales_to_order_only_current_order_line_label" style="position: absolute; top: 4px; left: 40px;">' + __("All the selected order lines with same item") + '</label>';
				s += '</div>';


				msg += s;

				top += 40;

			}



			s = '<font color="blue">' + __('Use this price and condition ?') + '</font>';
			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

			top += 30;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


			frappe.confirm(	msg,
				() => 	{

							silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {

								let current_line_only = true;

								if (document.getElementById('sales_to_order_only_current_order_line')) {

									if (document.getElementById('sales_to_order_only_current_order_line').checked) {
										current_line_only = false;
									}

								}


								silicon_ioi.doctype.ioiSalesToPurchases.do_purchases_prices_use_price_and_condition(current_line_only);
							});
						},
				() => 	{
						}
			);

		}
	}

	static do_purchases_prices_use_price_and_condition(current_line_only)
	{
		let qty = 0;

		if (current_line_only) {

			let idx = document.getElementById('sales_to_order_direct_order_grid_detail_selected_line').value;

			document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + idx.toString()).value = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value;
			document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + idx.toString()).value = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_supplier_id').value;
			document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + idx.toString()).value = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_purchases_condition_id').value;
			document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + idx.toString()).value = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_id').value;
			document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + idx.toString()).value = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_currency_id').value;

			document.getElementById('sales_to_order_direct_order_grid_detail_price_' + idx.toString()).value = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_price').value;

			document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + idx.toString()).checked = true;


			silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_get_purchases_price(idx);

			document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + idx.toString()).checked = false;

			if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_prefered').value == 1) {
				document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + idx.toString()).checked = true;
			}

			silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();

		}else{

			let current_item_id = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_current_item_id').value;

			for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

				if ((!document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display) ||
					(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == '') ||
					(document.getElementById('sales_to_order_direct_order_grid_detail').rows[i].style.display == 'table-row')) {

					if (current_item_id == document.getElementById("sales_to_order_direct_order_grid_detail_item_id_" + i.toString()).value) {

						if (document.getElementById("sales_to_order_direct_order_grid_detail_checked_id_" + i.toString())) {
							if (document.getElementById('sales_to_order_direct_order_grid_detail_checked_id_' + i.toString()).checked) {

								document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString()).value = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value;
								document.getElementById('sales_to_order_direct_order_grid_detail_supplier_' + i.toString()).value = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_supplier_id').value;
								document.getElementById('sales_to_order_direct_order_grid_detail_purchases_condition_id_' + i.toString()).value = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_purchases_condition_id').value;
								document.getElementById('sales_to_order_direct_order_grid_detail_purchases_unit_' + i.toString()).value = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_id').value;
								document.getElementById('sales_to_order_direct_order_grid_detail_purchases_currency_' + i.toString()).value = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_currency_id').value;

								document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString()).value = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_unit_price').value;

								document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString()).checked = true;

								silicon_ioi.doctype.ioiSalesToPurchases.sales_order_grid_get_purchases_price(i);

								document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString()).checked = false;

								if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_prefered').value == 1) {
									document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString()).checked = true;
								}

							}
						}
					}
				}
			}

			silicon_ioi.doctype.ioiSalesToPurchases.load_po_for_supplier();


		}
	}


	static purchases_prices_set_as_prefered()
	{
		let current_prefered = 0;

		if (!document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail')) {
			frappe.msgprint({title: __("Message"), message: __('No purchases price selected'), indicator: "red"});
			raise;
		}else {
			if (document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows.length == 0) {
				frappe.msgprint({title: __("Message"), message: __('No purchases price selected'), indicator: "red"});
				raise;
			}else {

				let at_least_one_checked = false;

				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows.length; i++) {

					if (!document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString()))
					{
						frappe.msgprint({title: __("Message"), message: __('No purchases price selected'), indicator: "red"});
						raise;
					}else {
						if (document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString()).checked)
						{
							if (document.getElementById('sales_to_order_direct_order_purchases_prices_prefered_' + i.toString())) {
								if (document.getElementById('sales_to_order_direct_order_purchases_prices_prefered_' + i.toString()).value == 1) {
									current_prefered = 1;
								}
							}

							at_least_one_checked = true;
							break;
						}
					}
				}

				if (!at_least_one_checked) {
					frappe.msgprint({title: __("Message"), message: __('No purchases price selected'), indicator: "red"});
					raise;
				}
			}
		}


		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value.trim() != '') {

			let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_can_update_purchases_prices';

			let go = false;

			frappe.call({  	method: method,
							args: {	"name" : document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value
							},
							async: false,
							callback:function(r)	{

								if (r.message == 1) {
									go = true;
								}
							}
			});


			if (!go) {
				frappe.msgprint({title: __("Message"), message: __('The site of this selected purchases price has to be the current site or empty'), indicator: "red"});
				raise;
			}

			let top = 10;

			let msg = '';
			let s = '';

			if (current_prefered == 1) {
				s = '<font color="blue">' + __('Set as unprefered this selected purchases price ?') + '</font>';

			}else{
				s = '<font color="blue">' + __('Set as prefered this selected purchases price ?') + '</font>';

			}

			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

			top += 30;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


			frappe.confirm(	msg,
				() => 	{

							silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {

								silicon_ioi.doctype.ioiSalesToPurchases.do_purchases_prices_set_as_prefered(current_prefered);

							});
						},
				() => 	{
						}
			);
		}

	}

	static do_purchases_prices_set_as_prefered(current_prefered)
	{
		let meth = '';

		if (current_prefered == 0) {
			meth = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_purchases_price_set_as_prefered';
		}else{
			meth = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_purchases_price_set_as_unprefered';
		}

		frappe.call({  	method: meth,
						args: {	"name": document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value
						},
						async: true,
						callback:function(r)	{
													if (r.message.error != 0) {
														frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
														raise;

													}


													let id = document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value;

													silicon_ioi.doctype.ioiSalesToPurchases.load_purchases_prices();

													silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {

														let idx_found = -1;

														for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows.length; i++) {

															if (document.getElementById('sales_to_order_direct_order_purchases_prices_name_' + i.toString()))
															{
																if (document.getElementById('sales_to_order_direct_order_purchases_prices_name_' + i.toString()).value == id) {
																	idx_found = i;
																	silicon_ioi.doctype.ioiSalesToPurchases.line_purchases_prices_click(document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows[i].id);
																}
															}
														}

														if (idx_found != -1) {

															if (document.getElementById('sales_to_order_direct_order_grid_detail')) {

																for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

																	if (document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString()).value == document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value) {

																		document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString()).checked = false;

																		if (document.getElementById('sales_to_order_direct_order_purchases_prices_prefered_' + idx_found.toString()).value == 1) {
																			document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString()).checked = true;
																		}


																	}
																}
															}
														}

													});
												}

		});

	}



	static purchases_prices_delete_site_price()
	{
		if (!document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail')) {
			frappe.msgprint({title: __("Message"), message: __('No purchases price selected'), indicator: "red"});
			raise;
		}else {
			if (document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows.length == 0) {
				frappe.msgprint({title: __("Message"), message: __('No purchases price selected'), indicator: "red"});
				raise;
			}else {

				let at_least_one_checked = false;

				for (var i = 0; i < document.getElementById('sales_to_order_direct_order_purchases_prices_grid_detail').rows.length; i++) {

					if (!document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString()))
					{
						frappe.msgprint({title: __("Message"), message: __('No purchases price selected'), indicator: "red"});
						raise;
					}else {
						if (document.getElementById('sales_to_order_direct_order_purchases_prices_checked_id_' + i.toString()).checked)
						{
							at_least_one_checked = true;
							break;
						}
					}
				}

				if (!at_least_one_checked) {
					frappe.msgprint({title: __("Message"), message: __('No purchases price selected'), indicator: "red"});
					raise;
				}
			}
		}


		if (document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value.trim() != '') {

			let purchases_price_site_name = '';

			let method = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_can_delete_site_price';

			let go = false;

			frappe.call({  	method: method,
							args: {	"name" : document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value
							},
							async: false,
							callback:function(r)	{

								if (r.message.ret_value == 1) {
									go = true;
									purchases_price_site_name = r.message.purchases_price_site_name;
								}else{

								}
							}
			});


			if (!go) {
				frappe.msgprint({title: __("Message"), message: __('The site of this selected purchases price has to be the current site'), indicator: "red"});
				raise;
			}


			let nb_rows = 0;

			if (purchases_price_site_name != '') {

				if (document.getElementById('sales_to_order_direct_order_grid_detail')) {

					for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

						if (document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString()).value == purchases_price_site_name) {
							nb_rows++;
						}
					}
				}
			}



			let top = 10;

			let msg = '';
			let s = '';

			if (nb_rows > 0) {
				if (nb_rows == 1) {
					s = __("The site purchases price is referenced in") + ' '  + nb_rows.toString() + ' ' + __("sales order line");
				}else{
					s = __("The site purchases price is referenced in") + ' '  + nb_rows.toString() + ' ' + __("sales order lines");
				}

				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
				top += 30;
			}


			s = '<font color="blue">' + __('Are you sure to delete this selected purchases price ?') + '</font>';
			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

			top += 30;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';


			frappe.confirm(	msg,
				() => 	{

							silicon_ioi.doctype.ioiSalesToPurchases.sleep_static(200).then(() => {

								silicon_ioi.doctype.ioiSalesToPurchases.do_purchases_prices_delete_site_price(purchases_price_site_name);

							});
						},
				() => 	{
						}
			);
		}

	}

	static do_purchases_prices_delete_site_price(purchases_price_site_name)
	{
		let meth = silicon_ioi.doctype.ioiSalesToPurchases.path_sales_to_purchases + '.direct_order_purchases_price_delete';

		frappe.call({  	method: meth,
						args: {	"name": document.getElementById('sales_to_order_direct_order_selected_purchases_prices_name').value
						},
						async: true,
						callback:function(r)	{
													if (r.message.error != 0) {
														frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
														raise;

													}

													if (purchases_price_site_name != '') {

														if (document.getElementById('sales_to_order_direct_order_grid_detail')) {

															for (var i = 0; i < document.getElementById('sales_to_order_direct_order_grid_detail').rows.length; i++) {

																if (document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString()).value == purchases_price_site_name) {

																	document.getElementById('sales_to_order_direct_order_grid_detail_has_price_' + i.toString()).checked = false;
																	document.getElementById('sales_to_order_direct_order_grid_detail_price_exists_' + i.toString()).checked = false;
																	document.getElementById('sales_to_order_direct_order_grid_detail_price_' + i.toString()).value = '';
																	document.getElementById('sales_to_order_direct_order_grid_detail_price_name_' + i.toString()).value = '';

																}
															}
														}
													}

													silicon_ioi.doctype.ioiSalesToPurchases.load_purchases_prices();
												}
		});
	}






	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

}

silicon_ioi.doctype.ioiSalesToPurchases = ioiSalesToPurchases;
