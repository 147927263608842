// *******************************************************************************************************************************************
// Class ioiSalesPOS for ioi Sales POS
// *******************************************************************************************************************************************

frappe.provide('silicon_ioi.doctype');

const path_sales_pos = 'silicon_ioi.ioi_sales.doctype.ioi_sales_pos.ioi_sales_pos';
const path_user = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user';
const path_general_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings';
const path_module_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status';
const path_currency = 'silicon_ioi.ioi_configuration.doctype.ioi_currency.ioi_currency';
const path_sales_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_sales_settings.ioi_sales_settings';
const path_sales_document = 'silicon_ioi.common.sales_document';
const path_sales_price = 'silicon_ioi.ioi_items.doctype.ioi_sales_price.ioi_sales_price'
const path_sales_journal = 'silicon_ioi.ioi_sales.doctype.ioi_sales_journal.ioi_sales_journal';
const path_cash_desk = 'silicon_ioi.ioi_configuration.doctype.ioi_cash_desk.ioi_cash_desk';
const path_cash_desk_movement = 'silicon_ioi.ioi_configuration.doctype.ioi_cash_desk_movement.ioi_cash_desk_movement';
const path_receipt_template_definition = 'silicon_ioi.ioi_configuration.doctype.ioi_receipt_template_definition.ioi_receipt_template_definition';
const path_printer = 'silicon_ioi.ioi_configuration.doctype.ioi_printer.ioi_printer';
const path_item_budget = 'silicon_ioi.ioi_configuration.doctype.ioi_item_budget.ioi_item_budget';
const path_user_grid_profile = 'silicon_ioi.ioi_configuration.doctype.ioi_sales_pos_user_grid_profile.ioi_sales_pos_user_grid_profile';

export class ioiSalesPOS
{
	static is_dark_mode = 0;
	static old_left_width = -1;
	static currency_symbol = [];
	static decimal_separator = '.';
	static thousand_separator = '';
	static batchuser = '';
	static batchuser_userid = '';

	static ioistatus = []
    static current_profile = '';
	static printer_id = '';
	static printer_ip = '';
	static printer_port = '';
	static printer_paper_width = 0;
	static printer_techno_id = '';
	static has_epson_display = 'N';
	static receipt_template_id = '';
	static canvas_name = '';



	static current_log = []
    static site_id = '';
    static journal_id = '';
    static delivery_prefix_id = '';
    static cashdesk_id = '';
    static warehouse_id = '';
	static doc = [];
	static state = '';
	static sales_pos_grid_width


	static user_language = '';
	static system_currency = '';
	static language_1 = '';
	static language_2 = '';
	static language_3 = '';
	static language_4 = '';
	static system_currency_rate = 0;
	static system_currency_rate_inv = 0;
	static system_currency_digit_rounding = 0;


	static journal_division_id = '';
	static journal_site_id = '';
	static journal_sales_catalog_id = '';
	static journal_sales_condition_id = '';
	static journal_warehouse_id = '';
	static journal_unit_price_decimal_number = 2;
	static journal_vat_type = '';
	static journal_spec_vat_rate = 0;
	static journal_default_alert_margin_rate = 0;
	static journal_margin_mode = 0;

	static journal_deliveries_prefix = '';
	static journal_deliveries_last_number = 0;
	static journal_deliveries_nb_digits = 0;
	static journal_deliveries_sublevel_separator = '';
	static journal_deliveries_sublevel_nb_digits = 0;
	static journal_deliveries_period_break_numbering = 0;
	static journal_deliveries_period_break_position = 0;
	static journal_deliveries_reservation_allowed = 0;
	static journal_deliveries_enable_price_access = 0;
	static journal_deliveries_enable_price_access_role = 0;
	static journal_deliveries_negative_qty_warehouse = '';
	static journal_deliveries_no_price_alert = 1;
	static journal_deliveries_account_analytic1_mandatory = 0;
	static journal_deliveries_accountid_mandatory = 0;
	static journal_deliveries_account_analytic2_mandatory = 0;
	static journal_deliveries_fill_thumbnail = 0;
	static journal_deliveries_account_analytic3_mandatory = 0;
	static journal_deliveries_fill_memo = 0;
	static journal_deliveries_account_analytic4_mandatory = 0;
	static journal_deliveries_disabled = 0;
	static journal_deliveries_route_for_invoice_journal = '';
	static journal_deliveries_do_not_apply_index = 0;
	static journal_deliveries_qty_to_prepare_mode = 0;
	static journal_deliveries_approval_scheme_id = '';
	static journal_deliveries_approval_default_delay = 0;
	static journal_deliveries_approval_required = 0;
	static journal_deliveries_pos_customer_id = '';
	static journal_deliveries_cashdesk_active = 0;
	static journal_deliveries_lock_payment_terms = 0;


	static old_customer_id = '';
	static customer_base_discount_in_header = 0;
	static customer_extra_discount_in_header = 0;
	static use_triggered_budget_discount = 0;
	static customer_manual_sales_condition_id = '';

	static profile_grid = 'DEF';
	static selected_line_color = '#f4f5f6';

	static meta = ''
	static current_line_id = -1;

	static load_meta_line()
	{

		silicon_ioi.doctype.ioiSalesPOS.meta = '';
		let method = path_sales_pos + '.ioi_sales_pos_get_meta';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{

							silicon_ioi.doctype.ioiSalesPOS.meta = r.message;
						}
		});

	}

	static load_currency_symbol_abbreviation()
	{

		silicon_ioi.doctype.ioiSalesPOS.currency_symbol = [];

		let method = path_sales_pos + '.ioi_sales_pos_load_currency_symbol';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{

							for (var i = 0; i < r.message.length; i++) {
								silicon_ioi.doctype.ioiSalesPOS.currency_symbol[silicon_ioi.doctype.ioiSalesPOS.currency_symbol.length] = [r.message[i].name, r.message[i].symbol_abb];
							}
						}
		});

	}


	static load_general_settings()
	{


		let format_number = frappe.sys_defaults.number_format;

		silicon_ioi.doctype.ioiSalesPOS.decimal_separator = '.';
		silicon_ioi.doctype.ioiSalesPOS.thousand_separator = '';

		let n = 0;

		for (var i = 0; i < format_number.length; i++) {

			if (format_number[i] != '#') {
				n++;
			}
		}

		if (n == 1) {

			for (var i = 0; i < format_number.length; i++) {
				if (format_number[i] != '#') {
					silicon_ioi.doctype.ioiSalesPOS.decimal_separator = format_number[i];
				}
			}

		}else if (n == 2) {

			let first = 1;

			for (var i = 0; i < format_number.length; i++) {

				if (format_number[i] != '#') {
					if (first == 1) {

						silicon_ioi.doctype.ioiSalesPOS.thousand_separator = format_number[i];
						first = 0;

					}else{
						silicon_ioi.doctype.ioiSalesPOS.decimal_separator = format_number[i];
					}
				}
			}

		}




		silicon_ioi.doctype.ioiSalesPOS.batchuser = '';
		silicon_ioi.doctype.ioiSalesPOS.system_currency = '';
		silicon_ioi.doctype.ioiSalesPOS.language_1 = '';
		silicon_ioi.doctype.ioiSalesPOS.language_2 = '';
		silicon_ioi.doctype.ioiSalesPOS.language_3 = '';
		silicon_ioi.doctype.ioiSalesPOS.language_4 = '';
		silicon_ioi.doctype.ioiSalesPOS.system_currency_rate = 0;
		silicon_ioi.doctype.ioiSalesPOS.system_currency_rate_inv = 0;
		silicon_ioi.doctype.ioiSalesPOS.system_currency_digit_rounding = 0;





		let method = path_general_settings + '.ioi_general_settings_get_data';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{

							silicon_ioi.doctype.ioiSalesPOS.system_currency = r.message.currency;

							if (silicon_ioi.doctype.ioiSalesPOS.system_currency.trim() != '')
							{
								method = path_currency + '.ioi_currency_get_data';

								frappe.call({  	method: method,
												args: {"currency_id": silicon_ioi.doctype.ioiSalesPOS.system_currency},
												async: false,
												callback:function(r)	{
													silicon_ioi.doctype.ioiSalesPOS.language_1 = r.message.language_1;
													silicon_ioi.doctype.ioiSalesPOS.language_2 = r.message.language_2;
													silicon_ioi.doctype.ioiSalesPOS.language_3 = r.message.language_3;
													silicon_ioi.doctype.ioiSalesPOS.language_4 = r.message.language_4;
													silicon_ioi.doctype.ioiSalesPOS.system_currency_rate = r.message.sales_rate;
													silicon_ioi.doctype.ioiSalesPOS.system_currency_rate_inv = r.message.sales_rate_inv;
													silicon_ioi.doctype.ioiSalesPOS.system_currency_digit_rounding = r.message.decimals;
												}
								});
							}
						}
		});
	}




	static load_sales_settings()
	{
		silicon_ioi.doctype.ioiSalesPOS.customer_base_discount_in_header = 0;
		silicon_ioi.doctype.ioiSalesPOS.customer_extra_discount_in_header = 0;
		silicon_ioi.doctype.ioiSalesPOS.use_triggered_budget_discount = 0;
		silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id = '';

		let method = path_sales_settings + '.ioi_sales_settings_get_data';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
							silicon_ioi.doctype.ioiSalesPOS.customer_base_discount_in_header = r.message.customer_base_discount_in_header;
							silicon_ioi.doctype.ioiSalesPOS.customer_extra_discount_in_header = r.message.customer_extra_discount_in_header;
							silicon_ioi.doctype.ioiSalesPOS.use_triggered_budget_discount = r.message.use_triggered_budget_discount;
							silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id = r.message.customer_manual_sales_condition_id;
						}
		});
	}

	static load_sales_journal_data()
	{

		silicon_ioi.doctype.ioiSalesPOS.user_language = '';

		// General
		silicon_ioi.doctype.ioiSalesPOS.journal_division_id = '';
		silicon_ioi.doctype.ioiSalesPOS.journal_site_id = '';
		silicon_ioi.doctype.ioiSalesPOS.journal_sales_catalog_id = '';
		silicon_ioi.doctype.ioiSalesPOS.journal_sales_condition_id = '';
		silicon_ioi.doctype.ioiSalesPOS.journal_warehouse_id = '';
		silicon_ioi.doctype.ioiSalesPOS.journal_unit_price_decimal_number = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_vat_type = '';
		silicon_ioi.doctype.ioiSalesPOS.journal_spec_vat_rate = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_default_alert_margin_rate = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_margin_mode = 0;


		// Deliveries
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_prefix = '';
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_last_number = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_nb_digits = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_sublevel_separator = '';
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_sublevel_nb_digits = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_period_break_numbering = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_period_break_position = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_reservation_allowed = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_enable_price_access = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_enable_price_access_role = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_negative_qty_warehouse = '';
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_no_price_alert = 1;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_account_analytic1_mandatory = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_accountid_mandatory = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_account_analytic2_mandatory = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_fill_thumbnail = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_account_analytic3_mandatory = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_fill_memo = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_account_analytic4_mandatory = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_disabled = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_route_for_invoice_journal = '';
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_do_not_apply_index = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_qty_to_prepare_mode = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_approval_scheme_id = '';
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_approval_default_delay = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_approval_required = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_pos_customer_id = '';
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_cashdesk_active = 0;
		silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_lock_payment_terms = 0;




		if ((silicon_ioi.doctype.ioiSalesPOS.doc.journal_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.journal_id.trim() != ''))
		{
			let method = path_sales_journal + '.ioi_sales_journal_get_data';

			frappe.call({  	method: method,
							args: { "journal_id": silicon_ioi.doctype.ioiSalesPOS.doc.journal_id,
									"dynamic_dt": silicon_ioi.doctype.ioiSalesPOS.doc.document_date},
							async: false,
							callback:function(r)	{

								silicon_ioi.doctype.ioiSalesPOS.user_language = r.message.user_language;

								// General
								silicon_ioi.doctype.ioiSalesPOS.journal_division_id = r.message.division_id;
								silicon_ioi.doctype.ioiSalesPOS.journal_site_id = r.message.site_id;
								silicon_ioi.doctype.ioiSalesPOS.journal_sales_catalog_id = r.message.sales_catalog_id;
								silicon_ioi.doctype.ioiSalesPOS.journal_sales_condition_id = r.message.sales_condition_id;
								silicon_ioi.doctype.ioiSalesPOS.journal_warehouse_id = r.message.warehouse_id;
								silicon_ioi.doctype.ioiSalesPOS.journal_unit_price_decimal_number = r.message.unit_price_decimal_number;
								silicon_ioi.doctype.ioiSalesPOS.journal_vat_type = r.message.vat_type;
								silicon_ioi.doctype.ioiSalesPOS.journal_spec_vat_rate = r.message.spec_vat_rate;
								silicon_ioi.doctype.ioiSalesPOS.journal_default_alert_margin_rate = r.message.default_alert_margin_rate;
								silicon_ioi.doctype.ioiSalesPOS.journal_margin_mode = r.message._margin_mode;


								// Deliveries
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_prefix = r.message.deliveries_prefix;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_last_number = r.message.deliveries_last_number;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_nb_digits = r.message.deliveries_nb_digits;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_sublevel_separator = r.message.deliveries_sublevel_separator;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_sublevel_nb_digits = r.message.deliveries_sublevel_nb_digits;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_period_break_numbering = r.message.deliveries_period_break_numbering;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_period_break_position = r.message.deliveries_period_break_position;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_reservation_allowed = r.message.deliveries_reservation_allowed;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_enable_price_access = r.message.deliveries_enable_price_access;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_enable_price_access_role = r.message.deliveries_enable_price_access_role;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_negative_qty_warehouse = r.message.deliveries_negative_qty_warehouse;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_no_price_alert = r.message.deliveries_no_price_alert;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_account_analytic1_mandatory = r.message.deliveries_account_analytic1_mandatory;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_accountid_mandatory = r.message.deliveries_accountid_mandatory;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_account_analytic2_mandatory = r.message.deliveries_account_analytic2_mandatory;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_fill_thumbnail = r.message.deliveries_fill_thumbnail;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_account_analytic3_mandatory = r.message.deliveries_account_analytic3_mandatory;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_fill_memo = r.message.deliveries_fill_memo;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_account_analytic4_mandatory = r.message.deliveries_account_analytic4_mandatory;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_disabled = r.message.deliveries_disabled;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_route_for_invoice_journal = r.message.deliveries_route_for_invoice_journal;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_do_not_apply_index = r.message.deliveries_do_not_apply_index;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_qty_to_prepare_mode = r.message.deliveries_qty_to_prepare_mode;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_approval_scheme_id = r.message.deliveries_approval_scheme_id;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_approval_default_delay = r.message.deliveries_approval_default_delay;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_approval_required = r.message.deliveries_approval_required;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_pos_customer_id = r.message.deliveries_pos_customer_id;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_cashdesk_active = r.message.deliveries_cashdesk_active;
								silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_lock_payment_terms = r.message.deliveries_lock_payment_terms;
							}
			});
		}
	}

	static load_ioistatus()
	{

		silicon_ioi.doctype.ioiSalesPOS.ioistatus = [];

		let method = path_module_status + '.ioi_module_status_get_list';

		frappe.call({  	method: method,
						args: {"doctype": 'ioi Sales Delivery'},
						async: false,
						callback:function(r)	{
													for (var i = 0; i < r.message.length; i++)
													{	//											    code             description      bgcolor          fontcolor        shape            shape_disabled   can_edit_document
														silicon_ioi.doctype.ioiSalesPOS.ioistatus[i] = [r.message[i][2], r.message[i][5], r.message[i][3], r.message[i][4], r.message[i][7], r.message[i][8], r.message[i][9]];

													}
												}
		});
	}

	static detect_if_display()
	{
		silicon_ioi.doctype.ioiSalesPOS.has_epson_display = 'N';

		let method = path_cash_desk + '.ioi_cash_desk_get_printer_techno'

		frappe.call({  	method: method,
						args: {"name": silicon_ioi.doctype.ioiSalesPOS.cashdesk_id},
						async: false,
						callback:function(r)	{

							let techno = r.message.printing_techno_id;
							let display_model = r.message.display_model_id;

							silicon_ioi.doctype.ioiSalesPOS.printer_ip = r.message.printer_ip_address;
							silicon_ioi.doctype.ioiSalesPOS.printer_port = r.message.printer_port;




							if ((display_model != null) && (display_model != '')) {
								if (techno == 'EPOSDEVICE') {
									silicon_ioi.doctype.ioiSalesPOS.has_epson_display = 'Y';
								}
							}
						}
		});
	}


	static display_on_screen(action, idx = null, cash_value = null)
	{
		if (cur_frm) {
			if (cur_frm.doctype.toUpperCase() == 'IOI SALES POS') {


				if (silicon_ioi.doctype.ioiSalesPOS.has_epson_display == 'Y') {

					let callback = function(emonitor) {

						let line1 = '';

						let doc_no = silicon_ioi.doctype.ioiSalesPOS.doc.identification;
						let s = silicon_ioi.doctype.ioiSalesPOS.doc.document_date.toString();
						let y = s.toString().substring(0,4);
						y = y.toString().substring(2, y.length);
						s = s.substring(5, s.length)

						let m = s.substring(0,2);
						s = s.substring(3, s.length)
						let d = s;


						// Total document

						let tot = document.getElementById('ioi_sales_pos_total_tvac').innerText;

						if (tot.lastIndexOf(' ') != -1) {
							tot = tot.substring(0, tot.lastIndexOf(' '));
						}

						tot = tot.replaceAll(' ', '');

						if (tot.indexOf(',') != -1) {
							if (tot.indexOf('.') != -1) {

								if (tot.indexOf(',') < tot.indexOf('.')) {
									tot = tot.replaceAll(',', '');
								}else{
									tot = tot.replaceAll('.', '');
									tot = tot.replaceAll(',', '.');
								}
							}else{
								tot = tot.replaceAll(',', '.');
							}
						}

						// Still to pay

						let stilltopay = document.getElementById('ioi_sales_pos_pay_still_to_pay').innerText;

						if (stilltopay.lastIndexOf(' ') != -1) {
							stilltopay = stilltopay.substring(0, stilltopay.lastIndexOf(' '));
						}

						stilltopay = stilltopay.replaceAll(' ', '');

						if (stilltopay.indexOf(',') != -1) {
							if (stilltopay.indexOf('.') != -1) {

								if (stilltopay.indexOf(',') < stilltopay.indexOf('.')) {
									stilltopay = stilltopay.replaceAll(',', '');
								}else{
									stilltopay = stilltopay.replaceAll('.', '');
									stilltopay = stilltopay.replaceAll(',', '.');
								}
							}else{
								stilltopay = stilltopay.replaceAll(',', '.');
							}
						}




						line1 = doc_no;

						for (var i = 9 - doc_no.length; i > 0; i--) {
							line1 += ' '
						}

						line1 += ' ' + y + '-' + m + '-' + d + ' T:';

						for (var i = 10 - tot.length; i > 0; i--) {
							line1 += ' '
						}

						let curr_symb = ''

						for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.currency_symbol.length; i++) {

							if (silicon_ioi.doctype.ioiSalesPOS.currency_symbol[i][0] == silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) {

								curr_symb = silicon_ioi.doctype.ioiSalesPOS.currency_symbol[i][1];
								break;
							}
						}

						line1 += tot + ' ' + curr_symb;


						let line2 = '';


						if (action.toUpperCase() == 'NEW') {
							line2 = __("New sale");

						}else if ((action.toUpperCase() == 'LOAD') || (action.toUpperCase() == 'NEXT') || (action.toUpperCase() == 'PRIOR') || (action.toUpperCase() == 'SHOW_PARKED') || (action.toUpperCase() == 'SAVE_ALL') || (action.toUpperCase() == 'COMPLETE')) {

							if (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus == 0) {

								if (parseFloat(stilltopay) < parseFloat(tot)) {

									line2 = __("Still to pay")

									let nb = __("Still to pay").length + (' ' + parseFloat(stilltopay).toFixed(2).toString() + ' ' + curr_symb).length;

									for (var i = 33 - nb; i > 0; i--) {
										line2 += ' '
									}

									line2 += ' ' + parseFloat(stilltopay).toFixed(2) + ' ' + curr_symb;
								}else{
									line2 = __("In preparation");
								}
							}else{
								line2 = __("Delivered");
							}
						}else if ((action.toUpperCase() == 'NEW_LINE') || (action.toUpperCase() == 'MODIFY_LINE') || (action.toUpperCase() == 'MODIFY_QT')) {

							let qt = document.getElementById('ioi_sales_pos_detail_table_detail_delivered_qty_' + idx.toString()).innerText;
							let description = document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + idx.toString()).innerText
							let tot_line = document.getElementById('ioi_sales_pos_detail_table_detail_total_' + idx.toString()).innerText;

							if (qt == null) {
								qt = '0';
							}else{
								qt = qt.trim();
							}

							if (description == null) {
								description = '';
							}else{
								description = description.trim();
							}

							if (tot_line == null) {
								tot_line = '0'
							}else{
								tot_line = tot_line.trim();
							}


							tot_line = tot_line.replaceAll(' ', '');

							if (tot_line.indexOf(',') != -1) {
								if (tot_line.indexOf('.') != -1) {

									if (tot_line.indexOf(',') < tot_line.indexOf('.')) {
										tot_line = tot_line.replaceAll(',', '');
									}else{
										tot_line = tot.replaceAll('.', '');
										tot_line = tot.replaceAll(',', '.');
									}
								}else{
									tot_line = tot_line.replaceAll(',', '.');
								}
							}



							line2 = qt.trim() + ' ';

							let nb = (qt.trim() + ' ').length;


							description = description.substring(0, 21-nb);

							line2 += description;

							nb += description.length;

							for (var i = 21-nb; i > 0; i--) {
								line2 += ' ';
							}


							for (var i = 10 - tot_line.length; i > 0; i--) {
								line2 += ' '
							}

							let curr_symb = ''

							for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.currency_symbol.length; i++) {

								if (silicon_ioi.doctype.ioiSalesPOS.currency_symbol[i][0] == silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) {

									curr_symb = silicon_ioi.doctype.ioiSalesPOS.currency_symbol[i][1];
									break;
								}
							}

							line2 += tot_line + ' ' + curr_symb;

						}else if (action.toUpperCase() == 'DELETE_LINE') {

							line2 = __("Remove item");

						}else if (action.toUpperCase() == 'BASE_DISCOUNT_HEADER') {

							let base_discount = 0;

							if (document.getElementById('ioi_sales_pos_base_discount')) {

								if (document.getElementById('ioi_sales_pos_base_discount').value) {

									base_discount = document.getElementById('ioi_sales_pos_base_discount').value
								}
							}

							line2 = __("Base discount") + ' ' + parseFloat(base_discount).toFixed(2) + ' %'

						}else if (action.toUpperCase() == 'EXTRA_DISCOUNT_HEADER') {

							let extra_discount = 0;

							if (document.getElementById('ioi_sales_pos_extra_discount')) {

								if (document.getElementById('ioi_sales_pos_extra_discount').value) {

									extra_discount = document.getElementById('ioi_sales_pos_extra_discount').value
								}
							}

							line2 = __("Extra discount") + ' ' + parseFloat(extra_discount).toFixed(2) + ' %'
						}else if (action.toUpperCase() == 'DELIVERED') {

							line2 = __("Delivered");

						}else if ((action.toUpperCase() == 'GET_CASH') || (action.toUpperCase() == 'PUT_CASH')) {

							if (action.toUpperCase() == 'GET_CASH') {
								line2 = __("Get cash") + ' ';
							}else{
								line2 = __("Put cash") + ' ';
							}

							let curr_symb = ''

							for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.currency_symbol.length; i++) {

								if (silicon_ioi.doctype.ioiSalesPOS.currency_symbol[i][0] == silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) {

									curr_symb = silicon_ioi.doctype.ioiSalesPOS.currency_symbol[i][1];
									break;
								}
							}

							line2 += cash_value + ' ' + curr_symb;


						}

						emonitor.display.clearWindow();

						if (line1.trim() != '') {
							emonitor.display.addText(line1, 1, 1);
						}

						if (line2.trim() != '') {
							emonitor.display.addText(line2, 1, 2);
						}

						emonitor.display.send();
						// emonitor.ePosDev.disconnect();
						// emonitor.display = null;
						// emonitor.ePosDev = null;
						// emonitor = null;

					}

					let emonitor = null;

					try {
						emonitor = new silicon_ioi.doctype.ioiEPosDisplayDevice(silicon_ioi.doctype.ioiSalesPOS.printer_ip, silicon_ioi.doctype.ioiSalesPOS.printer_port, callback);
					} catch (error) {
						emonitor = null;
					}
				}
			}
		}
	}


	static load_default_profile(profile_name = null)
	{
		silicon_ioi.doctype.ioiSalesPOS.current_profile = '';
		silicon_ioi.doctype.ioiSalesPOS.site_id = '';
		silicon_ioi.doctype.ioiSalesPOS.journal_id = '';
		silicon_ioi.doctype.ioiSalesPOS.delivery_prefix_id = '';
		silicon_ioi.doctype.ioiSalesPOS.cashdesk_id = '';
		silicon_ioi.doctype.ioiSalesPOS.warehouse_id = '';
		silicon_ioi.doctype.ioiSalesPOS.identification_required = 0;

		document.getElementById('ioi_sales_pos_parameters_journal_id').innerHTML = '';
		document.getElementById('ioi_sales_pos_parameters_cashdesk_id').innerHTML = '';



		let ret_error = 0;
		let ret_error_msg = '';
		let ret_has_default_profile = 0;
		let ret_has_multi_profiles = 0;
		let ret_current_profile = '';
		let ret_site_id = '';
		let ret_journal_id = '';
		let ret_delivery_prefix_id = '';
		let ret_cashdesk_id = '';
		let ret_warehouse_id = '';
		let ret_identification_required = 0

		let profile_id = '';

		if ((profile_name != null) && (profile_name.trim() != '')) {
			profile_id = profile_name;
		}

		let method = path_sales_pos + '.ioi_sales_pos_load_default_profile'

		frappe.call({  	method: method,
						args: { "profile_name": profile_id
						},
						async: false,
						callback:function(r)	{

							ret_error = r.message.error;
							ret_error_msg = r.message.error_msg;
							ret_has_default_profile = r.message.has_default_profile;
							ret_has_multi_profiles = r.message.has_multi_profiles;
							ret_current_profile = r.message.current_profile;
							ret_site_id = r.message.site_id;
							ret_journal_id = r.message.journal_id;
							ret_delivery_prefix_id = r.message.delivery_prefix_id;
							ret_cashdesk_id = r.message.cashdesk_id;
							ret_warehouse_id = r.message.warehouse_id;
							ret_identification_required = r.message.identification_required;
						}
		});

		if (ret_error == 1) {

			document.getElementById('ioi_sales_last_scan').innerText = '';
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, ret_error_msg);

			return false;
		}

		if (ret_has_multi_profiles == 1) {

			silicon_ioi.doctype.ioiSalesPOS.select_profile();
			return false;

		}else if (ret_has_default_profile == 0)
		{
			silicon_ioi.doctype.ioiSalesPOS.open_profile_window('MAIN', true);
			return false;

		}else {

			silicon_ioi.doctype.ioiSalesPOS.current_profile = ret_current_profile;
			silicon_ioi.doctype.ioiSalesPOS.site_id = ret_site_id;
			silicon_ioi.doctype.ioiSalesPOS.journal_id = ret_journal_id;
			silicon_ioi.doctype.ioiSalesPOS.delivery_prefix_id = ret_delivery_prefix_id;
			silicon_ioi.doctype.ioiSalesPOS.cashdesk_id = ret_cashdesk_id;
			silicon_ioi.doctype.ioiSalesPOS.warehouse_id = ret_warehouse_id;
			silicon_ioi.doctype.ioiSalesPOS.identification_required = ret_identification_required

			if ((ret_cashdesk_id != null) && (ret_cashdesk_id != null)) {
				silicon_ioi.doctype.ioiSalesPOS.detect_if_display();
			}

			document.getElementById('ioi_sales_pos_parameters_journal_id').innerHTML = '<b>' + silicon_ioi.doctype.ioiSalesPOS.journal_id + '</b>';
			document.getElementById('ioi_sales_pos_parameters_cashdesk_id').innerHTML = '<b>' + silicon_ioi.doctype.ioiSalesPOS.cashdesk_id + '</b>';

			return true;

		}
	}

	static select_profile()
	{
		var z = new frappe.ui.Dialog({
			'title': __("Select a profile"),
			'fields': [
				{'fieldname': 'html_sales_pos_select_profile', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];

				button_ok.disabled = true;

				if (!document.getElementById('html_sales_pos_select_profile_detail')) {

					button_ok.disabled = false;
					frappe.msgprint({ title: __("Message"), message: __("No profile selected"), indicator: "red" });
					raise;

				}

				if (document.getElementById('html_sales_pos_select_profile_detail').rows.length == 0) {

					button_ok.disabled = false;
					frappe.msgprint({ title: __("Message"), message: __("No profile selected"), indicator: "red" });
					raise;

				}

				if (!document.getElementById('html_sales_pos_select_profile_detail_checked_id_0')) {

					button_ok.disabled = false;
					frappe.msgprint({ title: __("Message"), message: __("No profile selected"), indicator: "red" });
					raise;
				}

				let selected_profile = '';

				for (var i = 0; i < document.getElementById('html_sales_pos_select_profile_detail').rows.length; i++) {

					if (document.getElementById('html_sales_pos_select_profile_detail_checked_id_' + i.toString())) {

						if (document.getElementById('html_sales_pos_select_profile_detail_checked_id_' + i.toString()).checked) {

							selected_profile = document.getElementById('html_sales_pos_select_profile_detail_name_' + i.toString()).value;
							break;

						}
					}
				}

				if (selected_profile.trim() == '') {

					button_ok.disabled = false;
					frappe.msgprint({ title: __("Message"), message: __("No profile selected"), indicator: "red" });
					raise;
				}

				if (silicon_ioi.doctype.ioiSalesPOS.update_selected_profile(selected_profile)) {
					silicon_ioi.doctype.ioiSalesPOS.load_default_profile(selected_profile);
					z.hide();
				}else{
					button_ok.disabled = true;
					raise;
				}

			},
			secondary_action: function(){

				z.hide();
			}

		});

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_select_profile_search_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_select_profile_search');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_select_profile_content');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_select_profile_button_new');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_select_profile_button_edit');

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:75px; width: 100%;">';

		// Search
		html += '	<div style="position: relative; top: 0px; left: 2px; width:428px;">';
		html += '		<label id="html_sales_pos_select_profile_search_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 428px; height: 25px;"> ';
		html += '			<input id="html_sales_pos_select_profile_search" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:31px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB;" width=430px data-custom-grid="true">';
		html += '<tr style="height:30px">';
		html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + __("Journal") + '</b></td>';
		html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + __("Cash desk") + '</b></td>';
		html += '</tr>';
		html += '</table>';

		html += '</div>';

		html += '<div id="html_sales_pos_select_profile_content" style="overflow: auto; overflow-x: auto; height:300px; width: 100%;"></div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:55px; width: 100%;">';

		// New profile
		html += '	<div style="position: relative; top: 2px; left: 0px; width:150px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 35px">';
		html +='			<button id="html_sales_pos_select_profile_button_new" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("New profile") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Edit profile
		html += '	<div style="position: relative; top: 2px; left: 160px; width:150px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 35px">';
		html +='			<button id="html_sales_pos_select_profile_button_edit" class="btn btn-default ellipsis" style="height: 30px; width: 150px;" onclick="">' + __("Edit profile") + '</button>';
		html += '		</div>';
		html += '	</div>';


		html += '</div>';




		z.fields_dict.html_sales_pos_select_profile.$wrapper.html(html);
		z.$wrapper.find('.modal-dialog').css("max-width", "475px").css("width", "475px");
		z.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
		z.show();

		silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

			let fct_keyup = function(event) {

				if (event.keyCode == 13)
				{	return false;
				}

			};

			let fct_keydown = function(event) {

				if (event.keyCode == 13)
				{
					silicon_ioi.doctype.ioiSalesPOS.get_profiles();
					return false;
				}

			};

			document.getElementById('html_sales_pos_select_profile_search').onkeydown = fct_keydown;
			document.getElementById('html_sales_pos_select_profile_search').onkeyup = fct_keyup;

			let fct_new_profile = function () {

				silicon_ioi.doctype.ioiSalesPOS.create_profile();
			}

			document.getElementById('html_sales_pos_select_profile_button_new').onclick = fct_new_profile;


			let fct_edit_profile = function () {

				silicon_ioi.doctype.ioiSalesPOS.edit_profile();
			}

			document.getElementById('html_sales_pos_select_profile_button_edit').onclick = fct_edit_profile;

			silicon_ioi.doctype.ioiSalesPOS.get_profiles();

		});
	}

	static get_profiles()
	{
		if (document.getElementById('html_sales_pos_select_profile_detail')) {

			for (var i = 0; i < document.getElementById('html_sales_pos_select_profile_detail').rows.length; i++) {

				silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_select_profile_detail_name_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_select_profile_detail_checked_id_' + i.toString());
			}

			silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_select_profile_detail');
		}


		let search_term = '';

		if (document.getElementById('html_sales_pos_select_profile_search').value.trim() != '') {
			search_term = document.getElementById('html_sales_pos_select_profile_search').value;
		}

		let method = path_sales_pos + '.ioi_sales_pos_get_profiles'

		let data = [];

		frappe.call({  	method: method,
						args: {"search_term" : search_term, "journal_id" : '', "cashdesk_id" : ''},
						async: false,
						callback:function(r)	{

							data = r.message;
						}
		});

		let html = ''

		if (data.length == 0) {

			html += '<table id="html_sales_pos_select_profile_detail" border=1 style="border: 1px solid #E8EAEB" width=430px>';
			html += '<tr style="height:30px">';
			html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
			html += '</tr>';
			html += '</table>';

		}else{

			html += '<table id="html_sales_pos_select_profile_detail" border=1 style="border: 1px solid #E8EAEB" width=430px>';

			for (var i = 0; i < data.length; i++) {

				html += '<input id="html_sales_pos_select_profile_detail_name_' + i.toString() + '" type="hidden" value ="' + data[i].name + '">';

				html += '<tr style="height:30px">';
				html += '<td width=30px align="center" style="vertical-align: middle;">';

				html += '<input type="checkbox" id="html_sales_pos_select_profile_detail_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
				html += '       onclick=" ';
				html += '					for (var i = 0; i < document.getElementById(\'html_sales_pos_select_profile_detail\').rows.length; i++) ';
				html += '					{   if (document.getElementById(\'html_sales_pos_select_profile_detail_checked_id_\' + i.toString())) ';
				html += '						{ '
				html += '							if (document.getElementById(\'html_sales_pos_select_profile_detail_checked_id_\' + i.toString()).id != this.id) ';
				html += '							{ ';
				html += '								document.getElementById(\'html_sales_pos_select_profile_detail_checked_id_\' + i.toString()).checked = false; ';
				html += '							} ';
				html += '						} ';
				html += '					} ';
				html += '" ';

				html += '>';

				html += '</td>';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;' + data[i].journal_id + '</td>';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;' + data[i].cashdesk_id + '</td>';
				html += '</tr>';


			}

			html += '</table>';

		}

		document.getElementById('html_sales_pos_select_profile_content').insertAdjacentHTML('beforeend', html);

		silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

			if (document.getElementById('html_sales_pos_select_profile_detail')) {

				let is_checked = false;

				for (var i = 0; i < document.getElementById('html_sales_pos_select_profile_detail').rows.length; i++) {

					if (document.getElementById('html_sales_pos_select_profile_detail_checked_id_' + i.toString())) {

						if (silicon_ioi.doctype.ioiSalesPOS.current_profile == document.getElementById('html_sales_pos_select_profile_detail_name_' + i.toString()).value) {

							document.getElementById('html_sales_pos_select_profile_detail_checked_id_' + i.toString()).checked = true;
							is_checked = true;
							break;
						}

					}
				}

				if (!is_checked) {

					if (document.getElementById('html_sales_pos_select_profile_detail_checked_id_0')) {
						document.getElementById('html_sales_pos_select_profile_detail_checked_id_0').checked = true;
					}

				}
			}

		});
	}

	static update_selected_profile(profile_id)
	{
		let ret_value = true;
		let data = [];


		let method = path_sales_pos + '.ioi_sales_pos_update_selected_profile'

		frappe.call({  	method: method,
						args: { "profile_id": profile_id
						},
						async: false,
						callback:function(r)	{
							data = r.message;
						}
		});

		if (data.error == 1) {
			ret_value = false;
			frappe.msgprint({ title: __("Message"), message: data.error_msg, indicator: "red" });
		}

		return ret_value;
	}

	static create_profile()
	{
		silicon_ioi.doctype.ioiSalesPOS.open_profile_window('', true);
	}

	static edit_profile()
	{

		if (!document.getElementById('html_sales_pos_select_profile_detail')) {

			frappe.msgprint({ title: __("Message"), message: __("No profile selected"), indicator: "red" });
			raise;
		}

		if (document.getElementById('html_sales_pos_select_profile_detail').rows.length == 0) {

			frappe.msgprint({ title: __("Message"), message: __("No profile selected"), indicator: "red" });
			raise;
		}

		if (!document.getElementById('html_sales_pos_select_profile_detail_checked_id_0')) {

			frappe.msgprint({ title: __("Message"), message: __("No profile selected"), indicator: "red" });
			raise;
		}

		let selected_profile = '';

		for (var i = 0; i < document.getElementById('html_sales_pos_select_profile_detail').rows.length; i++) {

			if (document.getElementById('html_sales_pos_select_profile_detail_checked_id_' + i.toString())) {

				if (document.getElementById('html_sales_pos_select_profile_detail_checked_id_' + i.toString()).checked) {

					selected_profile = document.getElementById('html_sales_pos_select_profile_detail_name_' + i.toString()).value;
					break;

				}
			}
		}

		if (selected_profile.trim() == '') {

			frappe.msgprint({ title: __("Message"), message: __("No profile selected"), indicator: "red" });
			raise;
		}


		silicon_ioi.doctype.ioiSalesPOS.open_profile_window('', false, selected_profile);
	}


	static open_profile_window(origin = '', create = false, profile_id = null) {

		let ret_site_id = '';

		let method = path_user + '.ioi_user_get_site'

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{ 	ret_site_id = r.message
												}
		});


		let ret_journal_id = '';
		let ret_cashdesk_id = '';
		let ret_warehouse_id = '';

		let data = []

		if (!create) {

			let method = path_sales_pos + '.ioi_sales_pos_get_profile'

			frappe.call({  	method: method,
							args: { "profile_id": profile_id
							},
							async: false,
							callback:function(r)	{
								data = r.message
							}
			});

		}

		if (data.length > 0) {

			ret_site_id = data[0].site_id
			ret_journal_id = data[0].journal_id;
			ret_cashdesk_id = data[0].cashdesk_id;

			if (data[0].warehouse_id != null) {
				ret_warehouse_id = data[0].warehouse_id;
			}
		}


		var p = new frappe.ui.Dialog({
			'title': __("New profile"),
			'fields': [
				{'fieldname': 'html_sales_pos_new_profile', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];

				button_ok.disabled = true;

				if (create) {

					if (document.getElementById('html_sales_pos_new_profile_journal_id').value.trim() == '') {

						button_ok.disabled = false;
						frappe.msgprint({ title: __("Message"), message: __("Journal is mandatory"), indicator: "red" });
						raise;
					}

					if (document.getElementById('html_sales_pos_new_profile_cashdesk_id').value.trim() == '') {

						button_ok.disabled = false;
						frappe.msgprint({ title: __("Message"), message: __("Cash desk is mandatory"), indicator: "red" });
						raise;
					}
				}

				method = path_sales_pos + '.ioi_sales_pos_create_update_profile'

				let current_profile = ''


				if (create) {
					current_profile = profile_id
				}

				let ret_data = []

				frappe.call({  	method: method,
								args: { "site_id": document.getElementById('html_sales_pos_new_profile_site_id').value,
										"journal_id": document.getElementById('html_sales_pos_new_profile_journal_id').value,
										"cashdesk_id": document.getElementById('html_sales_pos_new_profile_cashdesk_id').value,
										"warehouse_id": document.getElementById('html_sales_pos_new_profile_warehouse_id').value,
										"profile_id" : current_profile
								},
								async: false,
								callback:function(r)	{

									ret_data = r.message

								}
				});

				if (ret_data.error == 1) {
					button_ok.disabled = false;
					frappe.msgprint({ title: __("Message"), message: ret_data.error_msg, indicator: "red" });
					raise;

				}

				if (origin != '') {
					silicon_ioi.doctype.ioiSalesPOS.load_default_profile(ret_data.new_profile_id);
				}else {

					silicon_ioi.doctype.ioiSalesPOS.get_profiles();
				}

				p.hide();

			},
			secondary_action: function(){

				p.hide();
			}

		});

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_new_profile_site_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_new_profile_site_id');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_new_profile_journal_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_new_profile_journal_id');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_new_profile_cashdesk_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_new_profile_cashdesk_id');

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_new_profile_warehouse_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_new_profile_warehouse_id');


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:180px; width: 100%;">';

		// Site
		html += '	<div style="position: relative; top: 0px; left: 2px; width:428px;">';
		html += '		<label id="html_sales_pos_new_profile_site_label" style="position: absolute; top: 8px; left: 0px;">' + __("Site") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 0px; left: 110px; width: 180px; height: 25px;"> ';
		html += '			<input id="html_sales_pos_new_profile_site_id" type="text" class="input-with-feedback form-control bold" value="' + ret_site_id + '" disabled>';
		html += '		</div>';
		html += '	</div>';


		// Journal
		html += '	<div style="position: relative; top: 45px; left: 2px; width:428px;">';
		html += '		<label id="html_sales_pos_new_profile_journal_label" style="position: absolute; top: 8px; left: 0px;">' + __("Journal") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 0px; left: 110px; width: 180px; height: 25px;"> ';

		html += '			<select id="html_sales_pos_new_profile_journal_id" class="input-with-feedback form-control bold"> ';
		html += '				<option value=""></option> ';

		method = path_sales_pos + '.ioi_sales_pos_get_active_journals_for_current_site'

		let is_create = 0

		if (create) {
			is_create = 1
		}

		frappe.call({  	method: method,
						args: {"is_create" : is_create},
						async: false,
						callback:function(r)	{
							for (var i = 0; i < r.message.length; i++) {
								html += '		<option value="' + r.message[i].name + '"';

								if (ret_journal_id.trim() != '') {

									if (ret_journal_id == r.message[i].name ) {
										html += ' selected '
									}
								}

								html += '>' + r.message[i].name + '</option> ';
							}
						}
		});

		html += '			</select> ';

		html += '		</div>';
		html += '	</div>';


		// Cash desk
		html += '	<div style="position: relative; top: 90px; left: 2px; width:428px;">';
		html += '		<label id="html_sales_pos_new_profile_cashdesk_label" style="position: absolute; top: 8px; left: 0px;">' + __("Cash desk") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 0px; left: 110px; width: 180px; height: 25px;"> ';

		html += '			<select id="html_sales_pos_new_profile_cashdesk_id" class="input-with-feedback form-control bold"> ';
		html += '				<option value=""></option> ';

		method = path_sales_pos + '.ioi_sales_pos_get_active_cashdesk'

		frappe.call({  	method: method,
						args: {"is_create" : is_create},
						async: false,
						callback:function(r)	{
							for (var i = 0; i < r.message.length; i++) {
								html += '		<option value="' + r.message[i].name + '"';

								if (ret_cashdesk_id.trim() != '') {

									if (ret_cashdesk_id == r.message[i].name ) {
										html += ' selected '
									}
								}

								html += '>' + r.message[i].name + '</option> ';
							}
						}
		});

		html += '			</select> ';

		html += '		</div>';
		html += '	</div>';


		// Warehouse
		html += '	<div style="position: relative; top: 135px; left: 2px; width:428px;">';
		html += '		<label id="html_sales_pos_new_profile_warehouse_label" style="position: absolute; top: 8px; left: 0px;">' + __("Warehouse") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 0px; left: 110px; width: 180px; height: 25px;"> ';

		html += '			<select id="html_sales_pos_new_profile_warehouse_id" class="input-with-feedback form-control bold"> ';
		html += '				<option value=""></option> ';

		method = path_sales_pos + '.ioi_sales_pos_get_active_warehouse'

		frappe.call({  	method: method,
						args: {"is_create" : is_create},
						async: false,
						callback:function(r)	{
							for (var i = 0; i < r.message.length; i++) {
								html += '		<option value="' + r.message[i].name + '"';

								if (ret_warehouse_id.trim() != '') {

									if (ret_warehouse_id == r.message[i].name ) {
										html += ' selected '
									}
								}

								html += '>' + r.message[i].name + '</option> ';
							}
						}
		});

		html += '			</select> ';

		html += '		</div>';
		html += '	</div>';


		html += '</div>';


		p.fields_dict.html_sales_pos_new_profile.$wrapper.html(html);
		p.$wrapper.find('.modal-dialog').css("max-width", "475px").css("width", "475px");
		p.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
		p.show();

		silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

			document.getElementById('html_sales_pos_new_profile_site_id').disabled = true;

			if (!create) {

				document.getElementById('html_sales_pos_new_profile_journal_id').disabled = true;
				document.getElementById('html_sales_pos_new_profile_cashdesk_id').disabled = true;

			}
		});

	}

	static rebuild_grid_header()
	{
		let user_id = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
		let badge_id = silicon_ioi.doctype.ioiSalesPOS.batchuser;

		let data_fields = [];

		let method = path_user_grid_profile + '.ioi_sales_pos_user_grid_profile_get_grid_fields';

		frappe.call({
			method: method,
			args: {
				"user_id": user_id,
				"badge_id": badge_id
			},
			async: false,
			callback:function(r)	{
				data_fields = r.message
			}
		});

		silicon_ioi.doctype.ioiSalesPOS.profile_grid = 'DEF'

		if (data_fields.length != 0) {

			silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_header');
			silicon_ioi.doctype.ioiSalesPOS.remove_element('sales_pos_grid_configurator');

			let grid_width = 30;

			for(var i=0; i < data_fields.length; i++) {

				silicon_ioi.doctype.ioiSalesPOS.profile_grid = data_fields[i].profile;

				grid_width += parseInt(data_fields[i].width);

				if ((data_fields[i].fieldname == 'delivered_qty') || (data_fields[i].fieldname == 'unit_price') || (data_fields[i].fieldname == 'discount') || (data_fields[i].fieldname == 'total')) {
					grid_width += 30;
				}

			}

			silicon_ioi.doctype.ioiSalesPOS.sales_pos_grid_width = parseInt(grid_width);


			let html = '';

			html += '<table id="ioi_sales_pos_detail_table_header" width=' + silicon_ioi.doctype.ioiSalesPOS.sales_pos_grid_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB;" data-custom-grid="true">';

			html += '<tr style="height:30px">';
			html += '<td width=30px style="vertical-align: middle; horizontal-align: middle;" align = "center">';

			html += '<img id="sales_pos_grid_configurator" src="/assets/silicon_ioi/images/buttons/parameters.svg" title="' + __("Grid configurator") + '" width="20px" height="20px" align="center" style="horizontal-align:center;"></img>';
			html += '</td>';

			for(var i=0; i < data_fields.length; i++) {

				let description = __(data_fields[i].label);

				if (data_fields[i].fieldname == 'delivered_qty') {
					description = __("Qty");
				}

				html += '<td width=' + data_fields[i].width.toString() + 'px style="vertical-align: middle;"><b>&nbsp;' + description  + '</b></td>';

				if ((data_fields[i].fieldname == 'delivered_qty') || (data_fields[i].fieldname == 'unit_price') || (data_fields[i].fieldname == 'discount') || (data_fields[i].fieldname == 'total')) {
					html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
				}
			}

			html += '</tr>';
			html += '</table>';

			document.getElementById('ioi_sales_pos_detail_area_header').insertAdjacentHTML('afterbegin', html);

		}else{


			silicon_ioi.doctype.ioiSalesPOS.sales_pos_grid_width = 717;

			let html = '';

			html += '<table id="ioi_sales_pos_detail_table_header" width=' + silicon_ioi.doctype.ioiSalesPOS.sales_pos_grid_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB" data-custom-grid="true">';
			html += '<tr style="height:30px">';
			html += '<td width=30px style="vertical-align: middle; horizontal-align: middle;" align = "center">';

			html += '<img id="sales_pos_grid_configurator" src="/assets/silicon_ioi/images/buttons/parameters.svg" title="' + __("Grid configurator") + '" width="20px" height="20px" align="center" style="horizontal-align:center;"></img>';
			html += '</td>';
			html += '<td width=267px style="vertical-align: middle;"><b>&nbsp;' + __("Description") + '</b></td>';
			html += '<td width=60px style="vertical-align: middle;"><b>&nbsp;' + __("Qty") + '</b></td>';
			html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Unit price") + '</b></td>';
			html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';

			html += '<td width=70px style="vertical-align: middle;"><b>&nbsp;' + __("Discount") + '</b></td>';
			html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=90px style="vertical-align: middle;"><b>&nbsp;' + __("Total") + '</b></td>';
			html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
			html += '</tr>';
			html += '</table>';

			document.getElementById('ioi_sales_pos_detail_area_header').insertAdjacentHTML('afterbegin', html);

		}

	}


	static get_last_seen_pos_id()
	{
		let method = path_sales_pos+ '.ioi_sales_pos_get_pos_id_user';

		frappe.call({  	method: method,
						args: {'user_id': silicon_ioi.doctype.ioiSalesPOS.batchuser_userid,	'badge_id' : silicon_ioi.doctype.ioiSalesPOS.batchuser},
						async: false,
						callback:function(r)	{

							if (r.message.length > 0) {

								let last_sales_pos_id = r.message[0].last_sales_pos_id;

								if ((last_sales_pos_id != null) && (last_sales_pos_id != '')) {
									silicon_ioi.doctype.ioiSalesPOS.get_document(last_sales_pos_id);
								}
							}
						}
		});
	}

	static save_pos_id_user()
	{
		let id = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc) {
			id = silicon_ioi.doctype.ioiSalesPOS.doc.name;
		}

		let method = path_sales_pos + '.ioi_sales_pos_save_pos_id_user';

		frappe.call({  	method: method,
						args: {"sales_pos_id": id, 'user_id': silicon_ioi.doctype.ioiSalesPOS.batchuser_userid,	'badge_id' : silicon_ioi.doctype.ioiSalesPOS.batchuser
						},
						async: false,
						callback:function(r)	{
						}
		});

	}



	static assign_events()
	{
		let fct_up_down_over = function() {
			this.style.cursor = 'pointer';
		}

		let fct_up_down_leave = function() {
			this.style.cursor = 'none';
		}

		let fct_up_down_click = function() {

			let s = this.src;

			if (s.indexOf('arrow_up.svg') != -1) {
				this.src = '/assets/silicon_ioi/images/buttons/arrow_down.svg';
				this.title = __('Open');

				document.getElementById('ioi_sales_pos_profile_area').style.height = '0px';

				if (document.getElementById('ioi_sales_pos_parameters_cashdesk_id').innerText.trim() != '') {
					document.getElementById('ioi_sales_pos_info_profile_label').innerText = __("Profile") + ' : ' + document.getElementById('ioi_sales_pos_parameters_cashdesk_id').innerText;
				}else{
					document.getElementById('ioi_sales_pos_info_profile_label').innerText = __("Profile");
				}

				document.getElementById('ioi_sales_pos_quick_item_search_result').style.height = '371px';

			}else{
				this.src = '/assets/silicon_ioi/images/buttons/arrow_up.svg';
				this.title = __('Close');
				document.getElementById('ioi_sales_pos_profile_area').style.height = '55px';

				document.getElementById('ioi_sales_pos_info_profile_label').innerText = __("Profile");

				document.getElementById('ioi_sales_pos_quick_item_search_result').style.height = '318px';

			}
		}

		document.getElementById('ioi_sales_pos_info_profile_up_down_button').onmouseover = fct_up_down_over;
		document.getElementById('ioi_sales_pos_info_profile_up_down_button').onmouseleave = fct_up_down_leave;
		document.getElementById('ioi_sales_pos_info_profile_up_down_button').onclick = fct_up_down_click;

		document.getElementById('sales_pos_grid_configurator').onmouseover = fct_up_down_over;
		document.getElementById('sales_pos_grid_configurator').onmouseleave = fct_up_down_leave;

		let fct_grid_configurator_click = function() {
			silicon_ioi.doctype.ioiSalesPOS.configure_grid();
		}

		document.getElementById('sales_pos_grid_configurator').onclick = fct_grid_configurator_click;


		let fct_mouse_move = function() {

			this.style.cursor = 'pointer';

			document.getElementById('ioi_sales_pos_operations_and_scan_buttons_area_title_label').innerText = __("Operations & scan");
			document.getElementById('ioi_sales_pos_pay_label').innerText = __("Payment");
			document.getElementById('ioi_sales_pos_info_profile_label').innerText = __("Profile");
			document.getElementById('ioi_sales_pos_detail_table_header').rows[0].cells[1].innerHTML = '&nbsp;<b>' + __("Description") + '</b>' ;


			if ((this.id == 'ioi_sales_pos_new_button') 	|| (this.id == 'ioi_sales_pos_edit_button') 	|| (this.id == 'ioi_sales_pos_delete_button') ||
				(this.id == 'ioi_sales_pos_save_button') 	|| (this.id == 'ioi_sales_pos_prior_button')  ||
				(this.id == 'ioi_sales_pos_next_button') 	|| (this.id == 'ioi_sales_pos_reprint_button') 	|| (this.id == 'ioi_sales_pos_show_parked_button') ||
				(this.id == 'ioi_sales_pos_customer_button_open_order') || (this.id == 'ioi_sales_pos_customer_button_quick_invoice')  || (this.id == 'ioi_sales_pos_customer_button_invoice') || (this.id == 'ioi_sales_pos_show_log_button') ||
				(this.id == 'ioi_sales_pos_lock_button')) {

				document.getElementById('ioi_sales_pos_operations_and_scan_buttons_area_title_label').innerText = __("Operations & scan") + ' ( ' + this.title + ' ) ';
			}else{

				if ((this.id == 'ioi_sales_pos_pay_button_pay_deliver')	|| (this.id == 'ioi_sales_pos_pay_button_deliver') 	|| (this.id == 'ioi_sales_pos_pay_button_histo') ||
					(this.id == 'ioi_sales_pos_pay_button_get_cash') 	|| (this.id == 'ioi_sales_pos_pay_button_put_cash') || (this.id == 'ioi_sales_pos_pay_button_open_cash_desk')  ||
					(this.id == 'ioi_sales_pos_pay_button_close_cash_desk')) {

						document.getElementById('ioi_sales_pos_pay_label').innerText = __("Payment") + ' ( ' + this.title + ' ) ';
				}else{

					if (this.id == 'ioi_sales_pos_parameters_button') {
						document.getElementById('ioi_sales_pos_info_profile_label').innerText = __("Profile") + ' ( ' + this.title + ' ) ';
					}else{
						if ((this.id == 'ioi_sales_pos_detail_new_button') || (this.id == 'ioi_sales_pos_detail_new_shop_button') || (this.id == 'ioi_sales_pos_detail_new_comment_button') || (this.id == 'ioi_sales_pos_detail_delete_button') 	|| (this.id == 'ioi_sales_pos_qt_add_1_button') ||
							(this.id == 'ioi_sales_pos_qt_dec_1_button') 	|| (this.id == 'ioi_sales_pos_qt_add_10_button') || (this.id == 'ioi_sales_pos_qt_dec_10_button')) {
							document.getElementById('ioi_sales_pos_detail_table_header').rows[0].cells[1].innerHTML = '&nbsp;<b>' + __("Description") + '&nbsp;(&nbsp; ' + this.title + '&nbsp;)</b>';
						}

					}

				}
			}


		}

		let fct_mouse_leave = function() {
			this.style.cursor = 'none';

			document.getElementById('ioi_sales_pos_operations_and_scan_buttons_area_title_label').innerText = __("Operations & scan");
			document.getElementById('ioi_sales_pos_info_profile_label').innerText = __("Profile");
			document.getElementById('ioi_sales_pos_pay_label').innerText = __("Payment");
			document.getElementById('ioi_sales_pos_detail_table_header').rows[0].cells[1].innerHTML = '&nbsp;<b>' + __("Description") + '</b>' ;

		}

		let fct_keydown = function(event) {

			if (event.keyCode == 13) {
				return false;
			}

		}

		// *************************************************************************************************************************************************
		// Scan & operations
		// *************************************************************************************************************************************************

		let fct_scan_keydown = function(event) {

			if (event.keyCode == 13) {

				if ((this.value) && (this.value.trim() != '')) {
					silicon_ioi.doctype.ioiSalesPOS.scan(this);
					this.value = '';
					this.focus();
				}

				return false;
			}

		}


		if (document.getElementById('ioi_sales_pos_scan')) {
			document.getElementById('ioi_sales_pos_scan').onkeydown = fct_scan_keydown;
		}


		if (document.getElementById('ioi_sales_pos_new_button')) {

			let fct_new_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.new();
			}

			document.getElementById('ioi_sales_pos_new_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_new_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_new_button').onclick = fct_new_click;
		}


		if (document.getElementById('ioi_sales_pos_delete_button')) {

			let fct_delete_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.delete();
			}

			document.getElementById('ioi_sales_pos_delete_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_delete_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_delete_button').onclick = fct_delete_click;
		}


		if (document.getElementById('ioi_sales_pos_save_button')) {

			let fct_save_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.save();
			}

			document.getElementById('ioi_sales_pos_save_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_save_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_save_button').onclick = fct_save_click;
		}



		if (document.getElementById('ioi_sales_pos_prior_button')) {

			let fct_prior_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.prior();
			}

			document.getElementById('ioi_sales_pos_prior_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_prior_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_prior_button').onclick = fct_prior_click;
		}


		if (document.getElementById('ioi_sales_pos_next_button')) {

			let fct_next_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.next();
			}

			document.getElementById('ioi_sales_pos_next_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_next_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_next_button').onclick = fct_next_click;
		}


		if (document.getElementById('ioi_sales_pos_edit_button')) {

			let fct_complete_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.complete();
			}

			document.getElementById('ioi_sales_pos_edit_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_edit_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_edit_button').onclick = fct_complete_click;
		}


		if (document.getElementById('ioi_sales_pos_reprint_button')) {

			let fct_reprint_click = function(event) {
				var d = new frappe.ui.Dialog({
					title: __("Receipt reprint - version"),
					primary_action_label: __("Customer"),
					primary_action: () => {
						silicon_ioi.doctype.ioiSalesPOS.reprint(event.shiftKey, false);
						d.hide();
					},
					secondary_action_label: __("Merchant"),
					secondary_action: () => {
						silicon_ioi.doctype.ioiSalesPOS.reprint(event.shiftKey, true);
						d.hide();
					}
				});
				d.show();
			}

			document.getElementById('ioi_sales_pos_reprint_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_reprint_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_reprint_button').onclick = fct_reprint_click;
		}


		if (document.getElementById('ioi_sales_pos_show_parked_button')) {

			let fct_show_parked_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.show_parked();
			}

			document.getElementById('ioi_sales_pos_show_parked_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_show_parked_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_show_parked_button').onclick = fct_show_parked_click;
		}

		if (document.getElementById('ioi_sales_pos_parameters_button')) {

			let fct_parameters_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.parameters();
			}

			document.getElementById('ioi_sales_pos_parameters_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_parameters_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_parameters_button').onclick = fct_parameters_click;
		}

		if (document.getElementById('ioi_sales_pos_show_log_button')) {

			let fct_show_log_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.show_log();
			}

			document.getElementById('ioi_sales_pos_show_log_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_show_log_button').onmouseleave= fct_mouse_leave;
			document.getElementById('ioi_sales_pos_show_log_button').onclick = fct_show_log_click;
		}

		// *************************************************************************************************************************************************
		// Document
		// *************************************************************************************************************************************************

		let fct_doc_mouse_over = function() {

			if (this.innerText.trim() != '') {

				this.style.cursor = 'pointer';
				this.style.textDecoration = 'underline';
			}

		};

		let fct_doc_mouse_leave = function() {

			if (this.innerText.trim() != '') {

				this.style.cursor = 'none';
				this.style.textDecoration = 'none';
			}

		};

		let fct_doc_click = function() {

			if (this.innerText.trim() != '') {

				if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
					let s = '/app/ioi-sales-delivery/' + this.innerText;
					window.open(s);
				}else{
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Save the changes'));
				}
			}

		};


		document.getElementById('ioi_sales_pos_document_id').onclick = fct_doc_click;
		document.getElementById('ioi_sales_pos_document_id').onmouseover = fct_doc_mouse_over;
		document.getElementById('ioi_sales_pos_document_id').onmouseleave = fct_doc_mouse_leave;



		// *************************************************************************************************************************************************
		// Customer
		// *************************************************************************************************************************************************

		if (document.getElementById('ioi_sales_pos_customer_id')) {

			let fct_cust_keydown = function(event) {

				silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
				silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');

				if (event.keyCode == 13) {
					document.getElementById('ioi_sales_pos_customer_id').blur();
					return false;
				}

			}

			document.getElementById('ioi_sales_pos_customer_id').onkeydown = fct_cust_keydown;


			let fct_cust_focus = function() {

				if (document.getElementById('ioi_sales_pos_scan_active').checked) {

					document.getElementById('ioi_sales_pos_scan').focus();
					return false;
				}

				silicon_ioi.doctype.ioiSalesPOS.old_customer_id = this.value;

			}

			document.getElementById('ioi_sales_pos_customer_id').onfocus = fct_cust_focus;


			let fct_cust_blur = function() {

				if (this.value != silicon_ioi.doctype.ioiSalesPOS.old_customer_id) {
					silicon_ioi.doctype.ioiSalesPOS.select_delivery_customer_id(this.value);
					silicon_ioi.doctype.ioiSalesPOS.compute_document();
				}
			}

			document.getElementById('ioi_sales_pos_customer_id').onblur = fct_cust_blur;


		}



		if (document.getElementById('ioi_sales_pos_customer_name')) {
			document.getElementById('ioi_sales_pos_customer_name').onkeydown = fct_keydown;
		}

		if (document.getElementById('ioi_sales_pos_customer_button_search')) {

			let fct_search_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.customer_search();
			}

			document.getElementById('ioi_sales_pos_customer_button_search').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_customer_button_search').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_customer_button_search').onclick = fct_search_click;
		}


		if (document.getElementById('ioi_sales_pos_customer_button_open_order')) {

			let fct_open_order_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.get_open_order();
			}

			document.getElementById('ioi_sales_pos_customer_button_open_order').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_customer_button_open_order').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_customer_button_open_order').onclick = fct_open_order_click;
		}


		if (document.getElementById('ioi_sales_pos_customer_button_quick_invoice')) {

			let fct_invoice_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.invoice(false, true);
			}

			document.getElementById('ioi_sales_pos_customer_button_quick_invoice').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_customer_button_quick_invoice').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_customer_button_quick_invoice').onclick = fct_invoice_click;
		}

		if (document.getElementById('ioi_sales_pos_customer_button_invoice')) {

			let fct_invoice_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.invoice();
			}

			document.getElementById('ioi_sales_pos_customer_button_invoice').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_customer_button_invoice').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_customer_button_invoice').onclick = fct_invoice_click;
		}



		if (document.getElementById('ioi_sales_pos_lock_button')) {

			let fct_lock_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.lock_screen();
			}

			document.getElementById('ioi_sales_pos_lock_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_lock_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_lock_button').onclick = fct_lock_click;
		}




		// *************************************************************************************************************************************************
		// Discounts
		// *************************************************************************************************************************************************

		let fct_discount_mousemove = function() {

			this.style.cursor = 'pointer';
		};

		let fct_discount_mouseleave = function() {

			this.style.cursor = 'none';
		};


		let fct_base_click = function() {

			silicon_ioi.doctype.ioiSalesPOS.edit_discount_header('BASE_DISCOUNT_HEADER')
		};

		let fct_extra_click = function() {

			silicon_ioi.doctype.ioiSalesPOS.edit_discount_header('EXTRA_DISCOUNT_HEADER')
		};



		document.getElementById('ioi_sales_pos_header_base_discount_edit_button').onmousemove = fct_discount_mousemove;
		document.getElementById('ioi_sales_pos_header_base_discount_edit_button').onmouseleave = fct_discount_mouseleave;
		document.getElementById('ioi_sales_pos_header_base_discount_edit_button').onclick = fct_base_click;

		document.getElementById('ioi_sales_pos_header_extra_discount_edit_button').onmousemove = fct_discount_mousemove;
		document.getElementById('ioi_sales_pos_header_extra_discount_edit_button').onmouseleave = fct_discount_mouseleave;
		document.getElementById('ioi_sales_pos_header_extra_discount_edit_button').onclick = fct_extra_click;



		if (document.getElementById('ioi_sales_pos_base_discount')) {

			let fct_base_keydown = function(event) {

				silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
				silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');

				if (event.keyCode == 13) {
					this.blur();
					return false;
				}

			}

			document.getElementById('ioi_sales_pos_base_discount').onkeydown = fct_base_keydown;

			let fct_base_focus = function() {

				if (document.getElementById('ioi_sales_pos_scan_active').checked) {

					document.getElementById('ioi_sales_pos_scan').focus();
					return false;
				}

			}

			document.getElementById('ioi_sales_pos_base_discount').onfocus = fct_base_focus;


			let fct_base_blur = function(event) {

				if (isNaN(this.value)) {
					this.value = 0;
				}

				silicon_ioi.doctype.ioiSalesPOS.doc.base_discount = this.value;

				silicon_ioi.doctype.ioiSalesPOS.compute_document();

				silicon_ioi.doctype.ioiSalesPOS.display_on_screen('BASE_DISCOUNT_HEADER');


			}

			document.getElementById('ioi_sales_pos_base_discount').onblur = fct_base_blur;
		}


		if (document.getElementById('ioi_sales_pos_extra_discount')) {

			let fct_extra_keydown = function(event) {
				silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
				silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');

				if (event.keyCode == 13) {
					this.blur();
					return false;
				}

			}

			document.getElementById('ioi_sales_pos_extra_discount').onkeydown = fct_extra_keydown;

			let fct_extra_focus = function() {

				if (document.getElementById('ioi_sales_pos_scan_active').checked) {

					document.getElementById('ioi_sales_pos_scan').focus();
					return false;
				}

			}

			document.getElementById('ioi_sales_pos_extra_discount').onfocus = fct_extra_focus;



			let fct_extra_blur = function(event) {

				if (isNaN(this.value)) {
					this.value = 0;
				}

				silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount = this.value;

				silicon_ioi.doctype.ioiSalesPOS.compute_document();

				silicon_ioi.doctype.ioiSalesPOS.display_on_screen('EXTRA_DISCOUNT_HEADER');

			}

			document.getElementById('ioi_sales_pos_extra_discount').onblur = fct_extra_blur;

		}


		// *************************************************************************************************************************************************
		// Pay
		// *************************************************************************************************************************************************

		if (document.getElementById('ioi_sales_pos_pay_button_pay_deliver')) {

			let fct_pay_deliver_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.pay_deliver();
			}

			document.getElementById('ioi_sales_pos_pay_button_pay_deliver').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_pay_button_pay_deliver').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_pay_button_pay_deliver').onclick = fct_pay_deliver_click;
		}


		if (document.getElementById('ioi_sales_pos_pay_button_deliver')) {

			let fct_deliver_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.deliver();
			}

			document.getElementById('ioi_sales_pos_pay_button_deliver').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_pay_button_deliver').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_pay_button_deliver').onclick = fct_deliver_click;
		}


		if (document.getElementById('ioi_sales_pos_pay_button_histo')) {

			let fct_histo_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.pay_activity();
			}

			document.getElementById('ioi_sales_pos_pay_button_histo').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_pay_button_histo').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_pay_button_histo').onclick = fct_histo_click;
		}

		if (document.getElementById('ioi_sales_pos_pay_button_get_cash')) {

			let fct_get_cash_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.get_cash();
			}

			document.getElementById('ioi_sales_pos_pay_button_get_cash').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_pay_button_get_cash').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_pay_button_get_cash').onclick = fct_get_cash_click;
		}

		if (document.getElementById('ioi_sales_pos_pay_button_put_cash')) {

			let fct_put_cash_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.put_cash();
			}

			document.getElementById('ioi_sales_pos_pay_button_put_cash').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_pay_button_put_cash').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_pay_button_put_cash').onclick = fct_put_cash_click;
		}


		if (document.getElementById('ioi_sales_pos_pay_button_open_cash_desk')) {

			let fct_open_cash_desk_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.open_cash_desk();
			}

			document.getElementById('ioi_sales_pos_pay_button_open_cash_desk').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_pay_button_open_cash_desk').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_pay_button_open_cash_desk').onclick = fct_open_cash_desk_click;
		}



		if (document.getElementById('ioi_sales_pos_pay_button_close_cash_desk')) {

			let fct_close_cash_desk_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.close_cash_desk();
			}

			document.getElementById('ioi_sales_pos_pay_button_close_cash_desk').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_pay_button_close_cash_desk').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_pay_button_close_cash_desk').onclick = fct_close_cash_desk_click;
		}


		// *************************************************************************************************************************************************
		// Detail
		// *************************************************************************************************************************************************

		if (document.getElementById('ioi_sales_pos_detail_new_button')) {

			let fct_new_line_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.new_line();
			}

			document.getElementById('ioi_sales_pos_detail_new_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_detail_new_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_detail_new_button').onclick = fct_new_line_click;
		}

		if (document.getElementById('ioi_sales_pos_detail_new_shop_button')) {

			let fct_new_line_shop_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.new_line_shop();
			}

			document.getElementById('ioi_sales_pos_detail_new_shop_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_detail_new_shop_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_detail_new_shop_button').onclick = fct_new_line_shop_click;
		}

		if (document.getElementById('ioi_sales_pos_detail_new_comment_button')) {

			let fct_new_line_empty_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.new_empty_line();
			}

			document.getElementById('ioi_sales_pos_detail_new_comment_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_detail_new_comment_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_detail_new_comment_button').onclick = fct_new_line_empty_click;
		}



		if (document.getElementById('ioi_sales_pos_detail_delete_button')) {

			let fct_delete_line_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.delete_line();
			}

			document.getElementById('ioi_sales_pos_detail_delete_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_detail_delete_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_detail_delete_button').onclick = fct_delete_line_click;
		}


		if (document.getElementById('ioi_sales_pos_qt_add_1_button')) {

			let fct_qt_add_1_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.qt_add(1, this);
			}

			document.getElementById('ioi_sales_pos_qt_add_1_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_qt_add_1_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_qt_add_1_button').onclick = fct_qt_add_1_click;
		}


		if (document.getElementById('ioi_sales_pos_qt_dec_1_button')) {

			let fct_qt_dec_1_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.qt_add(-1, this);
			}

			document.getElementById('ioi_sales_pos_qt_dec_1_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_qt_dec_1_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_qt_dec_1_button').onclick = fct_qt_dec_1_click;
		}


		if (document.getElementById('ioi_sales_pos_qt_add_10_button')) {

			let fct_qt_add_10_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.qt_add(10, this);
			}

			document.getElementById('ioi_sales_pos_qt_add_10_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_qt_add_10_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_qt_add_10_button').onclick = fct_qt_add_10_click;
		}

		if (document.getElementById('ioi_sales_pos_qt_dec_10_button')) {

			let fct_qt_dec_10_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.qt_add(-10, this);
			}

			document.getElementById('ioi_sales_pos_qt_dec_10_button').onmousemove = fct_mouse_move;
			document.getElementById('ioi_sales_pos_qt_dec_10_button').onmouseleave = fct_mouse_leave;
			document.getElementById('ioi_sales_pos_qt_dec_10_button').onclick = fct_qt_dec_10_click;
		}

		// *************************************************************************************************************************************************
		// Quick Item Search
		// *************************************************************************************************************************************************

		let fct_quick_item_keydown = function(event) {

			if (event.keyCode == 13) {

				if (this.value == '') {
					document.getElementById('ioi_sales_pos_quick_item_search_header_selected_name').value = '';
				}

				silicon_ioi.doctype.ioiSalesPOS.refresh_quick_items(this.value);
				return false;
			}

		}

		if (document.getElementById('ioi_sales_pos_quick_item_search')) {
			document.getElementById('ioi_sales_pos_quick_item_search').onkeydown = fct_quick_item_keydown;
		}


		document.getElementById('ioi_sales_pos_info_profile_up_down_button').click();

	}

	// *************************************************************************************************************************************************
	// Scan & operations
	// *************************************************************************************************************************************************

	static new()
	{
		document.getElementById('ioi_sales_last_scan').innerText = '';

		if (document.getElementById('ioi_sales_pos_new_button').disabled) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Save the changes'));

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}

			return false;
		}

		if (silicon_ioi.doctype.ioiCashDesk.has_pending_payment()) {

			let top = 10;

			let msg = '';

			let s = __('There are pending payments, create a new document anyway ?');


			msg += '<img src="/assets/silicon_ioi/images/buttons/warning.svg" width="50px" height="50px" style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">';
			msg += '<label style="position: absolute; top: ' + (top+10).toString() + 'px; left: 80px;"> ' + s + '</label>';


			top += 20;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;" align="center">' + msg + '</div>';


			frappe.confirm(	msg,
							() => 	{	silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

											silicon_ioi.doctype.ioiSalesPOS.do_new();
										});
									},
							() => 	{
										if (document.getElementById('ioi_sales_pos_scan_active').checked) {
											document.getElementById('ioi_sales_pos_scan').focus();
										}

									}
			);
		}else{
			silicon_ioi.doctype.ioiSalesPOS.do_new();
		}
	}

	static do_new()
	{

		silicon_ioi.doctype.ioiSalesPOS.change_state('INSERT');

		if (!silicon_ioi.doctype.ioiSalesPOS.load_default_profile()) {

			silicon_ioi.doctype.ioiSalesPOS.change_state('');

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}

			return false;
		}

		silicon_ioi.doctype.ioiSalesPOS.current_log = [];

		let method = path_sales_pos + '.ioi_sales_pos_new_document'

		frappe.call({  	method: method,
						args: { "journal_id": silicon_ioi.doctype.ioiSalesPOS.journal_id,
								"cashdesk_id": silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
								"warehouse_id": silicon_ioi.doctype.ioiSalesPOS.warehouse_id,
								"author_id": silicon_ioi.doctype.ioiSalesPOS.batchuser_userid
						},
						async: false,
						callback:function(r)	{

							if (r.message.error == 1) {

								silicon_ioi.doctype.ioiSalesPOS.state = '';
								silicon_ioi.doctype.ioiSalesPOS.change_state('');
								silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_msg);

							}else{

								silicon_ioi.doctype.ioiCashDesk.clear_pending_payment();

								silicon_ioi.doctype.ioiSalesPOS.state = 'INSERT';
								silicon_ioi.doctype.ioiSalesPOS.doc = r.message.doc;
								silicon_ioi.doctype.ioiSalesPOS.save_pos_id_user();
								silicon_ioi.doctype.ioiSalesPOS.refresh_screen();
								silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('New document') + ' ' + r.message.doc.name);


								silicon_ioi.doctype.ioiSalesPOS.display_on_screen('NEW');
							}

							if (document.getElementById('ioi_sales_pos_scan_active').checked) {
								document.getElementById('ioi_sales_pos_scan').focus();
							}

						}
		});
	}

	static complete()
	{
		document.getElementById('ioi_sales_last_scan').innerText = '';

		if (document.getElementById('ioi_sales_pos_edit_button').disabled) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Save the changes'));

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}

			return false;
		}

		if (!silicon_ioi.doctype.ioiSalesPOS.load_default_profile()) {

			silicon_ioi.doctype.ioiSalesPOS.change_state('');

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}

			return false;
		}

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}

			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus == 1)) {

			let go = true;
			let method = path_sales_pos + '.ioi_sales_pos_check_if_deliver_only';

			frappe.call({  	method: method,
							args: {"name" : silicon_ioi.doctype.ioiSalesPOS.doc.name},
							async: false,
							callback:function(r)	{

								if (r.message.error == 1)
								{
									go = false;
									silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_msg);

								}

							}
			});

			if (!go) {

				if (document.getElementById('ioi_sales_pos_scan_active').checked) {
					document.getElementById('ioi_sales_pos_scan').focus();
				}


				return false;
			}else{
				silicon_ioi.doctype.ioiSalesPOS.get_document(silicon_ioi.doctype.ioiSalesPOS.doc.name);
				silicon_ioi.doctype.ioiSalesPOS.display_on_screen('COMPLETE');

				silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Document is in "Preparation"'));

				if (document.getElementById('ioi_sales_pos_scan_active').checked) {
					document.getElementById('ioi_sales_pos_scan').focus();
				}

			}

		}else {

			if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));

				if (document.getElementById('ioi_sales_pos_scan_active').checked) {
					document.getElementById('ioi_sales_pos_scan').focus();
				}

				return false;
			}

			silicon_ioi.doctype.ioiSalesPOS.add_log(0, __("Document edited"));

			silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
			silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}
		}
	}


	static delete()
	{
		document.getElementById('ioi_sales_last_scan').innerText = '';

		if (document.getElementById('ioi_sales_pos_delete_button').disabled) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Save the changes'));

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}

			return false;
		}

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}

			return false;

		}


		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}

			return false;
		}

		let has_payment = 0;

		let method = path_sales_pos + '.ioi_sales_pos_has_payment'

		frappe.call({  	method: method,
						args: { "delivery_id": silicon_ioi.doctype.ioiSalesPOS.doc.name
						},
						async: false,
						callback:function(r)	{
							has_payment = r.message;
						}
		});

		if (has_payment == 0)
		{
			let msg = __('Do you want to cancel this sales ?');

			frappe.confirm(	msg,
							() => 	{	silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

											silicon_ioi.doctype.ioiSalesPOS.do_delete('');
										});
									},
							() => 	{
										if (document.getElementById('ioi_sales_pos_scan_active').checked) {
											document.getElementById('ioi_sales_pos_scan').focus();
										}

									}
			);

		}else{

			let total_payment = 0;
			let nb_lines = 0;

			method = path_sales_pos + '.ioi_sales_pos_get_payment_total_and_nb_lines'

			frappe.call({  	method: method,
							args: { "delivery_id": silicon_ioi.doctype.ioiSalesPOS.doc.name
							},
							async: false,
							callback:function(r)	{
								total_payment = r.message.total;
								nb_lines = r.message.nb_lines;
							}
			});

			if ((total_payment == 0) && (nb_lines == 0)) {
				silicon_ioi.doctype.ioiSalesPOS.do_delete('CHANGE_STATUS');
			}else{

				var cp = new frappe.ui.Dialog({
					'title': __("Cancel payment"),
					'fields': [
						{'fieldname': 'html_sales_pos_cancel_delivery', 'fieldtype': 'HTML'}
					],
					primary_action_label: __('Cancel'),
					primary_action: function(){
						cp.hide();
					},
					secondary_action_label: __('Delete delivery lines and cancel payment'),
					secondary_action: function(){
						cp.hide();

						silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {
							silicon_ioi.doctype.ioiSalesPOS.do_delete('DELETE_CANCEL');
						});
					}

				});


				let html = '';

				html += '<div style="overflow: auto; overflow-x: auto; height:30px; width: 555px;">';

				html += '	<div style="position: absolute; top: 0px; left: 0px; width:600px;">';
				html += '		<font color="blue"><label style="position: absolute; top: 0px; left: 2px;z-index:5;width:600px;text-alignment:center">' + __("Please, cancel payment for cancelling this sales") + '</label></font>';
				html += '	</div>';

				html += '</div>';


				cp.fields_dict.html_sales_pos_cancel_delivery.$wrapper.html(html);
				cp.$wrapper.find('.modal-dialog').css("max-width", "560px").css("width", "560px");
				cp.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
				cp.show();
			}

		}
	}

	static do_delete(tp = '')
	{

		if (tp == '') {

			// Delete all sales lines
			let method = path_sales_pos + '.ioi_sales_pos_delete_document_lines'

			frappe.call({  	method: method,
							args: { "name": silicon_ioi.doctype.ioiSalesPOS.doc.name
							},
							async: false,
							callback:function(r)	{

								if (r.message.error == 1) {

									silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {
										silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_msg);
									});

									silicon_ioi.doctype.ioiSalesPOS.state = '';
									silicon_ioi.doctype.ioiSalesPOS.change_state('');

								}else{

									let cutomer_id = r.message.deliveries_pos_customer_id;


									let method = path_sales_pos + '.ioi_sales_pos_get_document_id';

									let error = 0;
									let error_msg = '';
									let delivery_doc = ''


									frappe.call({  	method: method,
													args: { "name": silicon_ioi.doctype.ioiSalesPOS.doc.name,
															"journal_id" : silicon_ioi.doctype.ioiSalesPOS.journal_id,
															"cashdesk_id" : silicon_ioi.doctype.ioiSalesPOS.cashdesk_id},
													async: false,
													callback:function(r)	{

														error = r.message.error;
														error_msg = r.message.error_msg;
														delivery_doc = r.message.doc;
													}
									});

									if (error == 1) {
										silicon_ioi.doctype.ioiSalesPOS.add_log(2, error_msg);
										return false;

									}

									silicon_ioi.doctype.ioiSalesPOS.doc = delivery_doc;
									silicon_ioi.doctype.ioiSalesPOS.save_pos_id_user();
									silicon_ioi.doctype.ioiSalesPOS.refresh_screen();

									// Put customer POS

									silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
									document.getElementById('ioi_sales_pos_customer_id').focus();
									document.getElementById('ioi_sales_pos_customer_id').value = cutomer_id;
									document.getElementById('ioi_sales_pos_customer_id').blur();


									silicon_ioi.doctype.ioiSalesPOS.save();

								}

								if (document.getElementById('ioi_sales_pos_scan_active').checked) {
									document.getElementById('ioi_sales_pos_scan').focus();
								}

							}
			});




		}else if (tp == 'DELETE_CANCEL') {

			// Delete all sales lines

			// Delete all sales lines
			let method = path_sales_pos + '.ioi_sales_pos_delete_document_lines'

			frappe.call({  	method: method,
							args: { "name": silicon_ioi.doctype.ioiSalesPOS.doc.name
							},
							async: false,
							callback:function(r)	{

								if (r.message.error == 1) {

									silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {
										silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_msg);
									});

									silicon_ioi.doctype.ioiSalesPOS.state = '';
									silicon_ioi.doctype.ioiSalesPOS.change_state('');

								}else{

									let method = path_sales_pos + '.ioi_sales_pos_get_document_id';

									let error = 0;
									let error_msg = '';
									let delivery_doc = ''


									frappe.call({  	method: method,
													args: { "name": silicon_ioi.doctype.ioiSalesPOS.doc.name,
															"journal_id" : silicon_ioi.doctype.ioiSalesPOS.journal_id,
															"cashdesk_id" : silicon_ioi.doctype.ioiSalesPOS.cashdesk_id},
													async: false,
													callback:function(r)	{

														error = r.message.error;
														error_msg = r.message.error_msg;
														delivery_doc = r.message.doc;
													}
									});

									if (error == 1) {
										silicon_ioi.doctype.ioiSalesPOS.add_log(2, error_msg);
										return false;

									}

									silicon_ioi.doctype.ioiSalesPOS.doc = delivery_doc;
									silicon_ioi.doctype.ioiSalesPOS.save_pos_id_user();
									silicon_ioi.doctype.ioiSalesPOS.refresh_screen();

									document.getElementById('ioi_sales_pos_pay_button_histo').click();


								}

							}
			});


		}else if (tp == 'CHANGE_STATUS') {

			let method = path_sales_pos + '.ioi_sales_pos_change_status_to_not_invoicable';

			let error = 0;
			let error_msg = '';
			let delivery_doc = null;

			frappe.call({  	method: method,
							args: { "name": silicon_ioi.doctype.ioiSalesPOS.doc.name
							},
							async: false,
							callback:function(r)	{

								error = r.message.error;
								error_msg = r.message.error_msg;
								delivery_doc = r.message.doc
							}
			});

			if (error == 1) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, error_msg);
				return false;

			}

			silicon_ioi.doctype.ioiSalesPOS.doc = delivery_doc;
			silicon_ioi.doctype.ioiSalesPOS.save_pos_id_user();
			silicon_ioi.doctype.ioiSalesPOS.refresh_screen();



			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Due to payment movements, this sales will remain and will be delivered as null for administrative purpose. Consider as deleted'));
		}
	}

	static save()
	{
		document.getElementById('ioi_sales_last_scan').innerText = '';

		if (document.getElementById('ioi_sales_pos_save_button').disabled == null) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document is not in edit mode'));

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}

			return false;
		}


		if (document.getElementById('ioi_sales_pos_save_button').disabled) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document is not in edit mode'));

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}

			return false;
		}

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}

			return false;

		}

		if (document.getElementById('ioi_sales_pos_customer_id').value.trim() == '') {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Customer is mandatory'));

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}

			return false;
		}

		let display_warning = false;

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type == '')) {
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type = 'NAT';
				}


				if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price == null) ||
					((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price) == 0))) {

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_pos_free_sales_allowed != 1) {

						display_warning = true;
						break;
					}

				}
			}
		}




		silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_id = document.getElementById('ioi_sales_pos_customer_id').value.toUpperCase();
		silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_name = document.getElementById('ioi_sales_pos_customer_name').value;

		silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id = document.getElementById('ioi_sales_pos_customer_id').value.toUpperCase();
		silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_name = document.getElementById('ioi_sales_pos_customer_name').value;

		silicon_ioi.doctype.ioiSalesPOS.doc.invoice_customer_id = document.getElementById('ioi_sales_pos_customer_id').value.toUpperCase();
		silicon_ioi.doctype.ioiSalesPOS.doc.invoice_customer_name = document.getElementById('ioi_sales_pos_customer_name').value;

		silicon_ioi.doctype.ioiSalesPOS.doc.base_discount = document.getElementById('ioi_sales_pos_base_discount').value;
		silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount = document.getElementById('ioi_sales_pos_extra_discount').value;

		if ((silicon_ioi.doctype.ioiSalesPOS.warehouse_id) && (silicon_ioi.doctype.ioiSalesPOS.warehouse_id.trim() != '')) {
			silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id =silicon_ioi.doctype.ioiSalesPOS.warehouse_id;
		}

		if (silicon_ioi.doctype.ioiSalesPOS.batchuser_userid != '') {
			silicon_ioi.doctype.ioiSalesPOS.doc.author_id = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
		}




		let method = path_sales_pos + '.ioi_sales_pos_save_document'

		let ret_value = false;

		frappe.call({  	method: method,
						args: { "doc": silicon_ioi.doctype.ioiSalesPOS.doc
						},
						async: false,
						callback:function(r)	{

							if (r.message.error == 1) {

								silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_msg);
								ret_value = false;

							}else{
								silicon_ioi.doctype.ioiSalesPOS.doc = r.message.doc;
								silicon_ioi.doctype.ioiSalesPOS.save_pos_id_user();
								silicon_ioi.doctype.ioiSalesPOS.refresh_screen();
								silicon_ioi.doctype.ioiSalesPOS.state = '';
								silicon_ioi.doctype.ioiSalesPOS.change_state('');

								if (display_warning) {
									silicon_ioi.doctype.ioiSalesPOS.add_log(1, __('Document saved but this sales contains items without price'));
								}else{
									silicon_ioi.doctype.ioiSalesPOS.add_log(0, __("Document saved"));
								}
								ret_value = true;

								silicon_ioi.doctype.ioiSalesPOS.display_on_screen('SAVE_ALL');



								silicon_ioi.doctype.ioiSalesPOS.sleep_static(1000).then(() => {

									if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

										for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

											if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {
												document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = false;
											}
										}


										for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

											if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

												if (silicon_ioi.doctype.ioiSalesPOS.current_line_id == i) {
													document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = true;
													break;
												}
											}
										}
									}
								});

							}

							if (document.getElementById('ioi_sales_pos_scan_active').checked) {
								document.getElementById('ioi_sales_pos_scan').focus();
							}

						}
		});

		return ret_value;
	}

	static prior()
	{

		if (silicon_ioi.doctype.ioiSalesPOS.state != '') {

			if (!silicon_ioi.doctype.ioiSalesPOS.save()) {

				if (document.getElementById('ioi_sales_pos_scan_active').checked) {
					document.getElementById('ioi_sales_pos_scan').focus();
				}

				return false;
			}

			let msg = __('This sales is on process. Do you really want to park it ?');

			frappe.confirm(	msg,
							() => 	{
										silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

											silicon_ioi.doctype.ioiSalesPOS.do_prior();
										});
									},
							() => 	{
									}
			);

		}else{
			silicon_ioi.doctype.ioiSalesPOS.do_prior();
		}
	}

	static do_prior()
	{
		if (silicon_ioi.doctype.ioiCashDesk.has_pending_payment()) {

			let top = 10;

			let msg = '';

			let s = __('There are pending payments, leave this document anyway ?');


			msg += '<img src="/assets/silicon_ioi/images/buttons/warning.svg" width="50px" height="50px" style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">';
			msg += '<label style="position: absolute; top: ' + (top+10).toString() + 'px; left: 80px;"> ' + s + '</label>';


			top += 20;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;" align="center">' + msg + '</div>';


			frappe.confirm(	msg,
							() => 	{	silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

											silicon_ioi.doctype.ioiSalesPOS.execute_prior();
										});
									},
							() => 	{
										if (document.getElementById('ioi_sales_pos_scan_active').checked) {
											document.getElementById('ioi_sales_pos_scan').focus();
										}

									}
			);
		}else{
			silicon_ioi.doctype.ioiSalesPOS.execute_prior();
		}
	}

	static execute_prior()
	{

		silicon_ioi.doctype.ioiSalesPOS.current_log = [];

		let current_name = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc != '') {
			current_name = silicon_ioi.doctype.ioiSalesPOS.doc.name;
		}

		let method = path_sales_pos + '.ioi_sales_pos_get_prior_next_document'

		frappe.call({  	method: method,
						args: { "direction": 'PRIOR',
								"journal_id": silicon_ioi.doctype.ioiSalesPOS.journal_id,
								"cashdesk_id": silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
								"current_name": current_name
						},
						async: false,
						callback:function(r)	{

							if (r.message.doc != '') {
								silicon_ioi.doctype.ioiCashDesk.clear_pending_payment();
								silicon_ioi.doctype.ioiSalesPOS.doc = r.message.doc;
								silicon_ioi.doctype.ioiSalesPOS.save_pos_id_user();
								silicon_ioi.doctype.ioiSalesPOS.refresh_screen();
								silicon_ioi.doctype.ioiSalesPOS.display_on_screen('PRIOR');
							}

							if (document.getElementById('ioi_sales_pos_scan_active').checked) {
								document.getElementById('ioi_sales_pos_scan').focus();
							}

						}
		});
	}

	static next()
	{
		if (silicon_ioi.doctype.ioiSalesPOS.state != '') {

			if (!silicon_ioi.doctype.ioiSalesPOS.save()) {

				if (document.getElementById('ioi_sales_pos_scan_active').checked) {
					document.getElementById('ioi_sales_pos_scan').focus();
				}

				return false;
			}

			let msg = __('This sales is on process. Do you really want to park it ?');

			frappe.confirm(	msg,
							() => 	{
										silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

											silicon_ioi.doctype.ioiSalesPOS.do_next();
										});
									},
							() => 	{
									}
			);

		}else{
			silicon_ioi.doctype.ioiSalesPOS.do_next();
		}
	}

	static do_next()
	{
		if (silicon_ioi.doctype.ioiCashDesk.has_pending_payment()) {

			let top = 10;

			let msg = '';

			let s = __('There are pending payments, leave this document anyway ?');


			msg += '<img src="/assets/silicon_ioi/images/buttons/warning.svg" width="50px" height="50px" style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">';
			msg += '<label style="position: absolute; top: ' + (top+10).toString() + 'px; left: 80px;"> ' + s + '</label>';


			top += 20;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;" align="center">' + msg + '</div>';

			frappe.confirm(	msg,
							() => 	{	silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

											silicon_ioi.doctype.ioiSalesPOS.execute_next();
										});
									},
							() => 	{
										if (document.getElementById('ioi_sales_pos_scan_active').checked) {
											document.getElementById('ioi_sales_pos_scan').focus();
										}

									}
			);
		}else{
			silicon_ioi.doctype.ioiSalesPOS.execute_next();
		}
	}

	static execute_next()
	{

		silicon_ioi.doctype.ioiSalesPOS.current_log = [];

		let current_name = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc != '') {
			current_name = silicon_ioi.doctype.ioiSalesPOS.doc.name;
		}

		let method = path_sales_pos + '.ioi_sales_pos_get_prior_next_document'

		frappe.call({  	method: method,
						args: { "direction": 'NEXT',
								"journal_id": silicon_ioi.doctype.ioiSalesPOS.journal_id,
								"cashdesk_id": silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
								"current_name": current_name
						},
						async: false,
						callback:function(r)	{

							if (r.message.doc != '') {
								silicon_ioi.doctype.ioiCashDesk.clear_pending_payment();
								silicon_ioi.doctype.ioiSalesPOS.doc = r.message.doc;
								silicon_ioi.doctype.ioiSalesPOS.save_pos_id_user();
								silicon_ioi.doctype.ioiSalesPOS.refresh_screen();
								silicon_ioi.doctype.ioiSalesPOS.display_on_screen('NEXT');
							}

							if (document.getElementById('ioi_sales_pos_scan_active').checked) {
								document.getElementById('ioi_sales_pos_scan').focus();
							}

						}
		});

	}


	static reprint(is_preview = false, merchant_version = false)
	{
		if (silicon_ioi.doctype.ioiSalesPOS.state != '') {

			if (!silicon_ioi.doctype.ioiSalesPOS.save()) {

				if (document.getElementById('ioi_sales_pos_scan_active').checked) {
					document.getElementById('ioi_sales_pos_scan').focus();
				}

				return false;
			}
		}

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if (!is_preview) {

			if ((!silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) || ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus < 1))) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "Delivered'));
				return false;
			}


			if ((!silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.cashdesk_id == ''))) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select the cash desh id in the parameters'));
				return false;
			}

		}

		if (!silicon_ioi.doctype.ioiSalesPOS.check_printer()) {
			return false;
		}


		silicon_ioi.doctype.ioiSalesPOS.do_print(is_preview, merchant_version);

	}

	static check_printer()
	{
		let ret_value = false;

		silicon_ioi.doctype.ioiSalesPOS.printer_id = '';
		silicon_ioi.doctype.ioiSalesPOS.printer_ip = '';
		silicon_ioi.doctype.ioiSalesPOS.printer_port = '';
		silicon_ioi.doctype.ioiSalesPOS.printer_paper_width = 0;


		frappe.call({ 	method: path_sales_pos + ".ioi_sales_pos_check_printer",
						args: {	"cashdesk_id" : silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
					},
					async : false,
					callback:function(r){

						if (r.message.error == 1) {

							silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_msg);

						}else{

							silicon_ioi.doctype.ioiSalesPOS.printer_id = r.message.printer_id;
							silicon_ioi.doctype.ioiSalesPOS.printer_ip = r.message.printer_ip;
							silicon_ioi.doctype.ioiSalesPOS.printer_port = r.message.printer_port;
							silicon_ioi.doctype.ioiSalesPOS.printer_paper_width = r.message.printer_paper_width;
							silicon_ioi.doctype.ioiSalesPOS.printer_techno_id = r.message.printer_techno_id;
							silicon_ioi.doctype.ioiSalesPOS.receipt_template_id = r.message.receipt_template_id;

							ret_value = true;
						}
					}
		});


		return ret_value
	}

	static do_print(is_preview, merchant_version = false)
	{
		if (!is_preview) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Voucher printing, please wait ...'));
		}

		let printer = null;

		if (!is_preview) {

			//Printer connection
			let connect = function(ePosDev = false){

				if (!ePosDev){
					ePosDev = new ioi.epson.ePOSDevice();
				}


				let cbCreateDevice_printer = function(devobj, retcode) {

					if (retcode == 'OK') {
						printer = devobj;
						executeAddedCode();
						silicon_ioi.doctype.ioiEPosConnect.add_connection('printer', silicon_ioi.doctype.ioiSalesPOS.printer_ip, silicon_ioi.doctype.ioiSalesPOS.printer_port, printer)
					}else{
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, retcode);
					}
				}


				let cbConnect = function (data) {

					if((data == 'OK') || (data == 'SSL_CONNECT_OK')) {
						ePosDev.createDevice('local_printer', ePosDev.DEVICE_TYPE_PRINTER, {'crypto' : false, 'buffer' : false}, cbCreateDevice_printer);
					} else {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Printer connection error : check ip address, port, ...'));
					}
				};

				ePosDev.connect(silicon_ioi.doctype.ioiSalesPOS.printer_ip, silicon_ioi.doctype.ioiSalesPOS.printer_port, cbConnect);
			}

			let executeAddedCode = function () {

				silicon_ioi.doctype.ioiSalesPOS.print_receipt(printer, is_preview, merchant_version);

			}

			let ePosDev = null;
			// connect(ePosDev);

			// Check if the connection is already established
			if (silicon_ioi.doctype.ioiEPosConnect.is_connected('printer', silicon_ioi.doctype.ioiSalesPOS.printer_ip, silicon_ioi.doctype.ioiSalesPOS.printer_port)){
				printer = silicon_ioi.doctype.ioiEPosConnect.get_epos_device('printer', silicon_ioi.doctype.ioiSalesPOS.printer_ip, silicon_ioi.doctype.ioiSalesPOS.printer_port)
				silicon_ioi.doctype.ioiSalesPOS.print_receipt(printer, is_preview, merchant_version);
			}else{
				connect(ePosDev);
			}

		}else{
			silicon_ioi.doctype.ioiSalesPOS.print_receipt(printer, is_preview, merchant_version);
		}
	}

	static print_receipt(printer = null, is_preview = false, merchant_version = false)
	{
		if (silicon_ioi.doctype.ioiSalesPOS.printer_techno_id == 'EPOSDEVICE') {

			if (silicon_ioi.doctype.ioiSalesPOS.receipt_template_id == '') {
				silicon_ioi.doctype.ioiSalesPOS.print_default_receipt(printer, is_preview, merchant_version);
			}else{
				silicon_ioi.doctype.ioiSalesPOS.print_from_template_receipt(printer, is_preview, merchant_version);
			}
		}else{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This printer technology is not supported'));
		}
	}

	static print_default_receipt(printer = null, is_preview = false, merchant_version = false)
	{
		let paper_width = silicon_ioi.doctype.ioiSalesPOS.printer_paper_width;

		if (parseInt(paper_width) == 0) {
			paper_width = 590;
		}

		// ****************************************************************************************************************************************
		// Header
		// ****************************************************************************************************************************************

		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_canvas_header');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_logo_header');


		let canvas_header = document.createElement('canvas') ;
		canvas_header.id = 'ioi_sales_pos_canvas_header';
		canvas_header.width = paper_width;

		canvas_header.height = silicon_ioi.doctype.ioiSalesPOS.canvas_define_height(merchant_version);

		let context = canvas_header.getContext("2d");

		// *********************************************************************************
		// Double line
		// *********************************************************************************

		context.moveTo(0, 2);
		context.lineWidth = 2;
		context.lineTo(paper_width, 2);
		context.stroke();

		context.moveTo(0, 10);
		context.lineWidth = 2;
		context.lineTo(paper_width, 10);
		context.stroke();

		// *********************************************************************************
		// Company information
		// *********************************************************************************

		let site_description = '';
		let company_name = '';
		let company_number = '';
		let vat_number = '';
		let address = '';
		let postal_code = '';
		let city = '';
		let country_id = '';
		let phone = '';
		let website = '';
		let email = '';
		let logo = '';


		frappe.call({ 	method: path_sales_pos + ".ioi_sales_pos_receipt_information",
						args: {	"site_id" : silicon_ioi.doctype.ioiSalesPOS.doc.site_id
						},
						async : false,
						callback:function(r){

							site_description = r.message.site_description;
							company_name = r.message.company_name;
							company_number = r.message.company_number;
							vat_number = r.message.vat_number;
							address = r.message.address;
							postal_code = r.message.postal_code;
							city = r.message.city;
							country_id = r.message.country_id;
							phone = r.message.phone;
							website = r.message.website;
							email = r.message.email;
							logo = r.message.logo;

						}
		});


		// *********************************************************************************
		// Company name
		// *********************************************************************************

		context.font = "48px serif";
		context.textAlign = "center";
		context.fillText(company_name, (canvas_header.width / 2), 50);

		let top = 85;

		// *********************************************************************************
		// Address
		// *********************************************************************************

		if ((address != null) && (address != '')) {

			context.font = "26px serif";
			context.textAlign = "center";
			context.fillText(address, (canvas_header.width / 2), top);
			top += 25;
		}

		// *********************************************************************************
		// Country - Postal Code - City
		// *********************************************************************************

		if (country_id == null) {
			country_id = '';
		}

		if (postal_code == null) {
			postal_code = '';
		}

		if (city == null) {
			city = '';
		}

		if ((country_id != '') || (postal_code != '') || (city != '')) {

			let s = '';

			if (country_id != '') {
				s += country_id;
			}

			if ((s.trim() != '') && (postal_code != '')) {
				s += ' - ';
			}

			if (postal_code != '') {
				s += postal_code
			}

			if ((s.trim() != '') && (city != '')) {
				s += ' '
			}

			if (city != '') {
				s += city
			}

			if (s.trim() != '') {

				context.font = "26px serif";
				context.textAlign = "center";
				context.fillText(s, (canvas_header.width / 2), top);
				top += 25;
			}
		}


		// *********************************************************************************
		// Phone
		// *********************************************************************************

		if ((phone != null) && (phone != '')) {

			context.font = "26px serif";
			context.textAlign = "center";
			context.fillText(__('Phone') + ': ' + phone , (canvas_header.width / 2), top);
			top += 25;
		}

		// *********************************************************************************
		// Website
		// *********************************************************************************

		if ((website != null) && (website != '')) {

			context.font = "26px serif";
			context.textAlign = "center";
			context.fillText(website, (canvas_header.width / 2), top);
			top += 25;
		}


		// *********************************************************************************
		// Logo
		// *********************************************************************************

		let img = document.createElement('img');

		if ((logo != null) && (logo != '')) {

			img.setAttribute('id', 'ioi_sales_pos_logo_header');
			img.setAttribute('src', logo);
			img.setAttribute('width', 200);
			img.setAttribute('height', 200);
			img.style.width = '200px';
			img.style.height = '200px';
			img.style.display = 'none';

			document.body.insertAdjacentElement('beforebegin', img);
		}

		silicon_ioi.doctype.ioiSalesPOS.sleep_static(500).then(() => {

			if ((logo != null) && (logo != '')) {

				img = document.getElementById('ioi_sales_pos_logo_header');
				img.style.display = 'block';
				context.drawImage(img, (canvas_header.width / 2) - 100, top, 200, 200);
				document.getElementById('ioi_sales_pos_logo_header').remove();
				top += 215;
			}

			// *********************************************************************************
			// Double line
			// *********************************************************************************

			context.moveTo(0, top);
			context.lineWidth = 2;
			context.lineTo(paper_width, top);
			context.stroke();
			top += 8;

			context.moveTo(0, top);
			context.lineWidth = 2;
			context.lineTo(paper_width, top);
			context.stroke();
			top += 35;

			// *********************************************************************************
			// Identification
			// *********************************************************************************

			context.font = "36px serif";
			context.textAlign = "center";
			context.fillText(silicon_ioi.doctype.ioiSalesPOS.doc.name, canvas_header.width / 2, top);
			top += 35;

			// *********************************************************************************
			// Document date & print date
			// *********************************************************************************

			context.font = "26px serif";
			context.textAlign = "start";
			context.fillText(__("Date") + ': ' + silicon_ioi.doctype.ioiSalesPOS.doc.document_date , 1, top);

			context.textAlign = "right";
			context.fillText(__("Printed") + ': ' + frappe.datetime.now_datetime(), canvas_header.width-10, top);
			top += 15;

			// *********************************************************************************
			// Single line
			// *********************************************************************************

			context.moveTo(0, top);
			context.lineWidth = 2;
			context.lineTo(paper_width, top);
			context.stroke();

			top += 25;

			// *********************************************************************************
			// Column headers
			// *********************************************************************************

			context.font = "24px serif";
			context.textAlign = "start";
			context.fillText(__('Item'), 1, top);

			context.textAlign = "right";
			context.fillText(__('Qty'), 320, top);

			context.textAlign = "right";
			context.fillText(__('Price'), 450, top);

			context.textAlign = "right";
			context.fillText(__('Total'), canvas_header.width-10, top);


			top += 15;

			// *********************************************************************************
			// Single line
			// *********************************************************************************

			context.moveTo(0, top);
			context.lineWidth = 2;
			context.lineTo(paper_width, top);
			context.stroke();

			top += 25;




			// **********************************************************************************************************************************
			// Detail
			// **********************************************************************************************************************************

			if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

				for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

					// *********************************************************************************
					// Item description
					// *********************************************************************************

					let item_desc = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description;

					let item_to_print = '';

					if (item_desc.length > 20) {

						item_to_print = item_desc.substring(0, 20);

						context.font = "24px serif";
						context.textAlign = "start";
						context.fillText(item_to_print, 1, top);

						item_desc = item_desc.substring(20, item_desc.length);
					}else {
						context.font = "24px serif";
						context.textAlign = "start";
						context.fillText(item_desc, 1, top);
						item_desc = '';

					}


					// *********************************************************************************
					// Quantity
					// *********************************************************************************

					let qt = 0;

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty != null) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty != 0)) {
						qt = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty;
					}

					context.font = "24px serif";
					context.textAlign = "right";
					context.fillText(qt.toFixed(2), 320, top);


					// *********************************************************************************
					// Unit Price inc VAT
					// *********************************************************************************

					let up = 0;

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price != null) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price != 0)) {

						up = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price) + (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price) * parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00));
					}


					context.font = "24px serif";
					context.textAlign = "right";
					context.fillText(up.toFixed(2), 450, top);


					// *********************************************************************************
					// Total line inc VAT
					// *********************************************************************************

					let tot = 0;


					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency != null) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency != 0)) {

						tot = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00));
					}

					context.font = "24px serif";
					context.textAlign = "right";
					context.fillText(tot.toFixed(2), canvas_header.width-10, top);


					top += 25;

					// *********************************************************************************
					// Item description (remaining)
					// *********************************************************************************

					if (item_desc.trim() != '') {

						if (item_desc.length > 20) {

							while (item_desc.length > 20) {

								item_to_print = item_desc.substring(0, 20);

								context.font = "24px serif";
								context.textAlign = "start";
								context.fillText(item_to_print, 1, top);

								item_desc = item_desc.substring(20, item_desc.length);

								top += 25;
							}

							if (item_desc.trim() != '') {
								context.font = "24px serif";
								context.textAlign = "start";
								context.fillText(item_desc, 1, top);
								top += 25;
							}


						}else{
							context.font = "24px serif";
							context.textAlign = "start";
							context.fillText(item_desc, 1, top);
							top += 25;
						}
					}


					// *********************************************************************************
					// Batch SN id
					// *********************************************************************************

					let batchsn = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].batch_sn_id;

					if ((batchsn != null) && (batchsn != '')) {

						context.font = "24px serif";
						context.textAlign = "start";
						context.fillText('• ' + __("Batch/SN") + ': ' + batchsn, 10, top);
						top += 25;
					}

					// *********************************************************************************
					// Code ref
					// *********************************************************************************

					let batchcoderef = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].batch_sn_code_ref;


					if ((batchcoderef != null) && (batchcoderef != '')) {

						context.font = "24px serif";
						context.textAlign = "start";
						context.fillText('• ' + __("Code ref") + ': ' + batchcoderef, 10, top);
						top += 25;
					}


				}

				// *********************************************************************************
				// Single line
				// *********************************************************************************

				top -= 10;
				context.moveTo(0, top);
				context.lineWidth = 2;
				context.lineTo(paper_width, top);
				context.stroke();

				top += 25;


			}

			// ****************************************************************************************************************************************
			// Footer
			// ****************************************************************************************************************************************

			top += 10;

			// *********************************************************************************
			// Total inc. VAT
			// *********************************************************************************

			context.font = "32px serif";
			context.textAlign = "start";
			context.fillText(__("Total"), 1, top);

			let total_tvac = 0;

			if ((silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac != null) && (silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac != 0)) {

				total_tvac = silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac;
			}

			context.textAlign = "right";
			context.fillText(total_tvac.toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id, canvas_header.width-10, top);

			top += 10;

			// *********************************************************************************
			// Single line
			// *********************************************************************************

			context.moveTo(0, top);
			context.lineWidth = 2;
			context.lineTo(paper_width, top);
			context.stroke();

			// *********************************************************************************
			// Paid detail
			// *********************************************************************************

			let tot_paid = 0;
			let tot_cash_rounding = 0;

			frappe.call({ 	method: path_sales_pos + ".ioi_sales_pos_receipt_payment_detail",
							args: {	"name" : silicon_ioi.doctype.ioiSalesPOS.doc.name,
									"cashdesk_id" : silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
									"merchant_version" : merchant_version
							},
							async : false,
							callback:function(r){

								if (r.message.length > 0) {

									for (var i = 0; i < r.message.length; i++) {

										if ((r.message[i].value != null) && (r.message[i].value != 0)) {

											top += 30;

											context.font = "32px serif";
											context.textAlign = "start";
											context.fillText(r.message[i].value_desc, 1, top);

											context.textAlign = "right";
											context.fillText(r.message[i].value.toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id, canvas_header.width-10, top);


											tot_paid += parseFloat(r.message[i].value);

											tot_cash_rounding += parseFloat(r.message[i].cash_rounding);


											if ((r.message[i].transaction_id != null) && (r.message[i].transaction_id != '')) {
												if (!r.message[i].transaction_receipt){
													top += 30;
													context.font = "26px serif";
													context.textAlign = "start";

													let dest_person = merchant_version ? __("Merchant") : __("Customer");
													context.fillText(dest_person+' '+__('receipt not available - Transaction id') + ':', 20, top);

													top += 30;
													context.fillText(r.message[i].transaction_id, 20, top);
												}else{
													let receipt_lines = r.message[i].transaction_receipt;
													for (var receipt_line_idx = 0; receipt_line_idx < receipt_lines.length; receipt_line_idx++) {
														top += 30;

														context.font = "26px serif";
														context.textAlign = "start";
														context.fillText(receipt_lines[receipt_line_idx], 20, top);
													}
													top += 30;
												}
											}

											if ((r.message[i].check_id != null) && (r.message[i].check_id != '')) {

												top += 30;

												context.font = "26px serif";
												context.textAlign = "start";
												context.fillText(__('Id') + ': ' + r.message[i].check_id, 20, top);

											}

										}

									}

								}
							}
			});

			top += 10;

			// *********************************************************************************
			// Single line
			// *********************************************************************************

			context.moveTo(0, top);
			context.lineWidth = 2;
			context.lineTo(paper_width, top);
			context.stroke();

			top += 30;

			// *********************************************************************************
			// Total paid
			// *********************************************************************************

			context.font = "32px serif";
			context.textAlign = "start";
			context.fillText(__("Total Paid"), 1, top);

			let paid = parseFloat(tot_paid).toString()

			context.textAlign = "right";
			context.fillText(parseFloat(paid).toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id, canvas_header.width-10, top);

			let remain = parseFloat(total_tvac) - parseFloat(tot_paid) + parseFloat(tot_cash_rounding);


			if (parseFloat(parseFloat(remain).toFixed(2)) != 0)
			{
				top += 30;


				if (parseFloat(total_tvac) - parseFloat(tot_paid) > 0) {

					context.font = "32px serif";
					context.textAlign = "start";
					context.fillText(__("Still to pay"), 1, top);

					context.textAlign = "right";
					context.fillText((parseFloat(total_tvac) - parseFloat(tot_paid)).toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id, canvas_header.width-10, top);

				}else {
					context.font = "32px serif";
					context.textAlign = "start";
					context.fillText(__("To be returned"), 1, top);

					context.textAlign = "right";
					context.fillText((parseFloat(tot_paid) - parseFloat(total_tvac)).toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id, canvas_header.width-10, top);

				}
			}



			top += 15;


			// *********************************************************************************
			// Single line
			// *********************************************************************************

			context.moveTo(0, top);
			context.lineWidth = 2;
			context.lineTo(paper_width, top);
			context.stroke();

			top += 25;

			// *********************************************************************************
			// Message
			// *********************************************************************************

			context.font = "26px serif";
			context.textAlign = "center";
			context.fillText(__("Thank you for your visit"), canvas_header.width / 2, top);

			top += 25;


			// *********************************************************************************
			// The seller
			// *********************************************************************************

			context.font = "26px serif";
			context.textAlign = "center";
			context.fillText(__("Your seller") + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.author_id, canvas_header.width / 2,  top);

			top += 25;



			// To See Result at screen

			if (is_preview) {

				var pv = new frappe.ui.Dialog({
					'title': __("Preview"),
					'fields': [
						{'fieldname': 'html_sales_pos_preview_receipt', 'fieldtype': 'HTML'}
					],
					primary_action_label: __('Close'),
					primary_action: function(){
						pv.hide();
					}

				});


				silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_preview_receipt_content');

				let html = '';

				html += '<div id="html_sales_pos_preview_receipt_content" style="overflow: auto; overflow-x: auto; height:800px; width: 615px;">';

				html += '</div>';


				pv.fields_dict.html_sales_pos_preview_receipt.$wrapper.html(html);
				pv.$wrapper.find('.modal-dialog').css("max-width", "650px").css("width", "650px");
				pv.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
				pv.show();

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

					document.getElementById('html_sales_pos_preview_receipt_content').insertAdjacentElement('beforeend', canvas_header)

				});

			}else{

				//New method
				//Improve tone (seuil demi-teinte)
				printer.halftone = printer.HALFTONE_ERROR_DIFFUSION
				//Improve clearness, stroke more lisable (by default = 1.0)
				printer.brightness = 0.5
				printer.print(canvas_header, true, printer.MODE_MONO, 60000);

				// printer.addImage(context, 0, 0, paper_width, top, printer.COLOR_1, printer.MODE_MONO);
				// printer.addCut(printer.CUT_FEED);

				let fct_fire_success = function() {
					silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Voucher printed'));
				};

				printer.fireReceiveEvent = fct_fire_success;


				let fct_fire_error = function(status, responseText) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, responseText);
				};

				printer.fireErrorEvent = fct_fire_error;

				// printer.send();
			}
		});

	}

	static canvas_define_height(merchant_version = false)
	{

		// ****************************************************************************************************************************************
		// Header
		// ****************************************************************************************************************************************

		// *********************************************************************************
		// Double line
		// *********************************************************************************

		// *********************************************************************************
		// Company information
		// *********************************************************************************

		let site_description = '';
		let company_name = '';
		let company_number = '';
		let vat_number = '';
		let address = '';
		let postal_code = '';
		let city = '';
		let country_id = '';
		let phone = '';
		let website = '';
		let email = '';
		let logo = '';


		frappe.call({ 	method: path_sales_pos + ".ioi_sales_pos_receipt_information",
						args: {	"site_id" : silicon_ioi.doctype.ioiSalesPOS.doc.site_id
						},
						async : false,
						callback:function(r){

							site_description = r.message.site_description;
							company_name = r.message.company_name;
							company_number = r.message.company_number;
							vat_number = r.message.vat_number;
							address = r.message.address;
							postal_code = r.message.postal_code;
							city = r.message.city;
							country_id = r.message.country_id;
							phone = r.message.phone;
							website = r.message.website;
							email = r.message.email;
							logo = r.message.logo;

						}
		});


		// *********************************************************************************
		// Company name
		// *********************************************************************************


		let top = 85;

		// *********************************************************************************
		// Address
		// *********************************************************************************

		if ((address != null) && (address != '')) {

			top += 25;
		}

		// *********************************************************************************
		// Country - Postal Code - City
		// *********************************************************************************

		if (country_id == null) {
			country_id = '';
		}

		if (postal_code == null) {
			postal_code = '';
		}

		if (city == null) {
			city = '';
		}

		if ((country_id != '') || (postal_code != '') || (city != '')) {

			let s = '';

			if (country_id != '') {
				s += country_id;
			}

			if ((s.trim() != '') && (postal_code != '')) {
				s += ' - ';
			}

			if (postal_code != '') {
				s += postal_code
			}

			if ((s.trim() != '') && (city != '')) {
				s += ' '
			}

			if (city != '') {
				s += city
			}

			if (s.trim() != '') {

				top += 25;
			}
		}


		// *********************************************************************************
		// Phone
		// *********************************************************************************

		if ((phone != null) && (phone != '')) {

			top += 25;
		}

		// *********************************************************************************
		// Website
		// *********************************************************************************

		if ((website != null) && (website != '')) {

			top += 25;
		}


		// *********************************************************************************
		// Logo
		// *********************************************************************************


		if ((logo != null) && (logo != '')) {

			top += 215;
		}

		// *********************************************************************************
		// Double line
		// *********************************************************************************

		top += 8;

		top += 35;

		// *********************************************************************************
		// Identification
		// *********************************************************************************

		top += 35;

		// *********************************************************************************
		// Document date & print date
		// *********************************************************************************

		top += 15;

		// *********************************************************************************
		// Single line
		// *********************************************************************************

		top += 25;

		// *********************************************************************************
		// Column headers
		// *********************************************************************************


		top += 15;

		// *********************************************************************************
		// Single line
		// *********************************************************************************

		top += 25;


		// **********************************************************************************************************************************
		// Detail
		// **********************************************************************************************************************************

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

				// *********************************************************************************
				// Item description
				// *********************************************************************************

				let item_desc = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description;

				let item_to_print = '';

				if (item_desc.length > 20) {

					item_to_print = item_desc.substring(0, 20);


					item_desc = item_desc.substring(20, item_desc.length);
				}else {
					item_desc = '';
				}


				// *********************************************************************************
				// Quantity
				// *********************************************************************************


				// *********************************************************************************
				// Unit Price inc VAT
				// *********************************************************************************

				// *********************************************************************************
				// Total line inc VAT
				// *********************************************************************************



				top += 25;

				// *********************************************************************************
				// Item description (remaining)
				// *********************************************************************************

				if (item_desc.trim() != '') {

					if (item_desc.length > 20) {

						while (item_desc.length > 20) {

							item_desc = item_desc.substring(20, item_desc.length);

							top += 25;
						}

						if (item_desc.trim() != '') {
							top += 25;
						}


					}else{
						top += 25;
					}
				}


				// *********************************************************************************
				// Batch SN id
				// *********************************************************************************

				let batchsn = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].batch_sn_id;

				if ((batchsn != null) && (batchsn != '')) {

					top += 25;
				}

				// *********************************************************************************
				// Code ref
				// *********************************************************************************

				let batchcoderef = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].batch_sn_code_ref;


				if ((batchcoderef != null) && (batchcoderef != '')) {

					top += 25;
				}


			}

			// *********************************************************************************
			// Single line
			// *********************************************************************************

			top -= 10;
			top += 25;


		}

		// ****************************************************************************************************************************************
		// Footer
		// ****************************************************************************************************************************************

		top += 10;

		// *********************************************************************************
		// Total inc. VAT
		// *********************************************************************************

		let total_tvac = 0;

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac != null) && (silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac != 0)) {

			total_tvac = silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac;
		}


		top += 10;

		// *********************************************************************************
		// Single line
		// *********************************************************************************



		// *********************************************************************************
		// Paid detail
		// *********************************************************************************

		let tot_paid = 0;
		let tot_cash_rounding = 0;

		frappe.call({ 	method: path_sales_pos + ".ioi_sales_pos_receipt_payment_detail",
						args: {	"name" : silicon_ioi.doctype.ioiSalesPOS.doc.name,
								"cashdesk_id" : silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
								"merchant_version" : merchant_version
						},
						async : false,
						callback:function(r){

							if (r.message.length > 0) {



								for (var i = 0; i < r.message.length; i++) {

									if ((r.message[i].value != null) && (r.message[i].value != 0)) {

										top += 30;

										tot_paid += parseFloat(r.message[i].value);


										tot_cash_rounding += parseFloat(r.message[i].cash_rounding);


										if ((r.message[i].transaction_id != null) && (r.message[i].transaction_id != '')) {
											if (r.message[i].transaction_receipt ){
												top += 30 * (r.message[i].transaction_receipt.length+1);
											}else{
												top += 60;
											}
										}

										if ((r.message[i].check_id != null) && (r.message[i].check_id != '')) {

											top += 30;
										}


									}

								}

							}
						}
		});


		top += 10;

		// *********************************************************************************
		// Single line
		// *********************************************************************************


		top += 30;

		// *********************************************************************************
		// Total paid
		// *********************************************************************************

		let remain = parseFloat(total_tvac) - parseFloat(tot_paid) + parseFloat(tot_cash_rounding);


		if (parseFloat(parseFloat(remain).toFixed(2)) != 0)
		{
			top += 30;

		}



		top += 15;


		// *********************************************************************************
		// Single line
		// *********************************************************************************

		top += 25;

		// *********************************************************************************
		// Message
		// *********************************************************************************

		top += 25;


		// *********************************************************************************
		// The seller
		// *********************************************************************************


		top += 25;

		return top;

	}


	static print_from_template_receipt(printer, is_preview, merchant_version = false)
	{
		let doc = [];
		let header = [];
		let column_header = [];
		let detail = [];
		let footer = [];
		let child = [];

		let method = path_receipt_template_definition + '.ioi_receipt_template_definition_get_doc'

		frappe.call({  	method: method,
						args: {"name": silicon_ioi.doctype.ioiSalesPOS.receipt_template_id},
						async: false,
						callback:function(r)	{

							doc = r.message.doc;

							header = r.message.header;
							column_header = r.message.column_header;
							detail = r.message.detail;
							footer = r.message.footer;
							child = r.message.child;
						}
		});


		let paper_width = 580;

		if ((silicon_ioi.doctype.ioiSalesPOS.paper_width) && (silicon_ioi.doctype.ioiSalesPOS.paper_width != 0)) {
			paper_width = parseInt(silicon_ioi.doctype.ioiSalesPOS.paper_width);
		}

		if (silicon_ioi.doctype.ioiSalesPOS.canvas_name != '') {
			if (document.getElementById(silicon_ioi.doctype.ioiSalesPOS.canvas_name)) {
				document.getElementById(silicon_ioi.doctype.ioiSalesPOS.canvas_name).remove();
			}
		}

		silicon_ioi.doctype.ioiSalesPOS.canvas_name = 'ioi_sales_pos_canvas_header_' + Date.now().toString();



		let canvas_header = document.createElement('canvas') ;
		canvas_header.id = silicon_ioi.doctype.ioiSalesPOS.canvas_name;
		canvas_header.width = paper_width;

		let context = null;

		let line_spacing = 25;

		if ((!doc.detail_line_spacing) || ((doc.detail_line_spacing) && (doc.detail_line_spacing != '') && (doc.detail_line_spacing.value != 0))) {
			line_spacing = parseInt(doc.detail_line_spacing);
		}

		let area = '';

		let data_live = [];
		let type_live = '';


		let doctype = 'ioi Sales Delivery';

		silicon_ioi.doctype.ioiReceiptPreview.header = header;
		silicon_ioi.doctype.ioiReceiptPreview.column_header = column_header;
		silicon_ioi.doctype.ioiReceiptPreview.detail = detail;
		silicon_ioi.doctype.ioiReceiptPreview.footer = footer;

		let canvas_site = null;
		let canvas_division = null;
		let canvas_company = null;

		method = path_receipt_template_definition + '.ioi_receipt_template_definition_get_data_for_preview'

		frappe.call({  	method: method,
						args: { "doctype": 'ioi Sales Delivery',
								"name": silicon_ioi.doctype.ioiSalesPOS.doc.name
						},
						async: false,
						callback:function(r)	{

							if (r.message.error == 0) {

								canvas_site = r.message.canvas_site;
								canvas_division = r.message.canvas_division;
								canvas_company = r.message.canvas_company;

							}

						}
		});


		let dc_for_pay = 'ioi Sales Delivery';

		canvas_header.height = silicon_ioi.doctype.ioiReceiptPreview.canvas_define_height(context, paper_width, line_spacing, -1, area, data_live, type_live, doc, doctype, silicon_ioi.doctype.ioiSalesPOS.doc, canvas_site, canvas_division, canvas_company, child, dc_for_pay);

		context = canvas_header.getContext("2d");
		context.reset();
		context.clearRect(0, 0, canvas_header.width, canvas_header.height);

		let top = 0;

		top = silicon_ioi.doctype.ioiReceiptPreview.refresh_canvas_part(area, 'HEADER', context, header, data_live, type_live, paper_width, line_spacing, -1, top, -1, false, doctype, silicon_ioi.doctype.ioiSalesPOS.doc, canvas_site, canvas_division, canvas_company, child);
		top = silicon_ioi.doctype.ioiReceiptPreview.refresh_canvas_part(area, 'COLUMN_HEADER', context,column_header, data_live, type_live, paper_width, line_spacing, -1, top, -1, false, doctype, silicon_ioi.doctype.ioiSalesPOS.doc, canvas_site, canvas_division, canvas_company, child);

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {
			for (var j = 0; j < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; j++) {
				top += line_spacing;
				top = silicon_ioi.doctype.ioiReceiptPreview.refresh_canvas_part(area, 'DETAIL', context, detail, data_live, type_live, paper_width, line_spacing, -1, top, j, false, doctype, silicon_ioi.doctype.ioiSalesPOS.doc, canvas_site, canvas_division, canvas_company, child);

			}
		}

		top += 10;

		top = silicon_ioi.doctype.ioiReceiptPreview.refresh_canvas_footer(area, 'FOOTER', context, footer, data_live, type_live, paper_width, line_spacing, -1, top, -2, false, doc, doctype, silicon_ioi.doctype.ioiSalesPOS.doc, canvas_site, canvas_division, canvas_company, child, dc_for_pay);

		top += 50;



		// To See Result at screen

		if (is_preview) {

			var pv = new frappe.ui.Dialog({
				'title': __("Preview"),
				'fields': [
					{'fieldname': 'html_sales_pos_preview_receipt', 'fieldtype': 'HTML'}
				],
				primary_action_label: __('Close'),
				primary_action: function(){
					pv.hide();
				}

			});


			silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_preview_receipt_content');

			let html = '';

			html += '<div id="html_sales_pos_preview_receipt_content" style="overflow: auto; overflow-x: auto; height:800px; width: 615px;">';

			html += '</div>';


			pv.fields_dict.html_sales_pos_preview_receipt.$wrapper.html(html);
			pv.$wrapper.find('.modal-dialog').css("max-width", "650px").css("width", "650px");
			pv.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
			pv.show();

			silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

				document.getElementById('html_sales_pos_preview_receipt_content').insertAdjacentElement('beforeend', canvas_header)

			});

		}else{
			silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

				//New method
				//Improve tone (seuil demi-teinte)
				printer.halftone = printer.HALFTONE_ERROR_DIFFUSION
				//Improve clearness, stroke more lisable (by default = 1.0)
				printer.brightness = 0.5
				printer.print(canvas_header, true, printer.MODE_MONO, 60000);

				// printer.addImage(context, 0, 0, paper_width, top, printer.COLOR_1, printer.MODE_MONO);
				// printer.addCut(printer.CUT_FEED);

				let fct_fire_success = function() {
					silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Voucher printed'));
				};

				printer.fireReceiveEvent = fct_fire_success;


				let fct_fire_error = function(status, responseText) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, responseText);
				};

				printer.fireErrorEvent = fct_fire_error;

				// printer.send();


			});
		}


	}



	static show_parked()
	{

		if (silicon_ioi.doctype.ioiSalesPOS.state != '') {

			if (!silicon_ioi.doctype.ioiSalesPOS.save()) {

				if (document.getElementById('ioi_sales_pos_scan_active').checked) {
					document.getElementById('ioi_sales_pos_scan').focus();
				}

				return false;
			}

			let msg = __('This sales is on process. Do you really want to park it ?');

			frappe.confirm(	msg,
							() => 	{
										silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

											silicon_ioi.doctype.ioiSalesPOS.do_show_parked();
										});
									},
							() => 	{
									}
			);

		}else{
			silicon_ioi.doctype.ioiSalesPOS.do_show_parked();
		}
	}


	static do_show_parked()
	{
		if (silicon_ioi.doctype.ioiCashDesk.has_pending_payment()) {

			let top = 10;

			let msg = '';

			let s = __('There are pending payments, leave this document anyway ?');


			msg += '<img src="/assets/silicon_ioi/images/buttons/warning.svg" width="50px" height="50px" style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">';
			msg += '<label style="position: absolute; top: ' + (top+10).toString() + 'px; left: 80px;"> ' + s + '</label>';


			top += 20;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;" align="center">' + msg + '</div>';



			frappe.confirm(	msg,
							() => 	{	silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

											silicon_ioi.doctype.ioiSalesPOS.execute_show_parked();
										});
									},
							() => 	{
										if (document.getElementById('ioi_sales_pos_scan_active').checked) {
											document.getElementById('ioi_sales_pos_scan').focus();
										}

									}
			);
		}else{
			silicon_ioi.doctype.ioiSalesPOS.execute_show_parked();
		}

	}

	static execute_show_parked()
	{
		if (!silicon_ioi.doctype.ioiSalesPOS.load_default_profile()) {

			silicon_ioi.doctype.ioiSalesPOS.change_state('');

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {
				document.getElementById('ioi_sales_pos_scan').focus();
			}

			return false;
		}

		var sp = new frappe.ui.Dialog({
			'title': __("Show parked"),
			'fields': [
				{'fieldname': 'html_sales_pos_show_parked', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];

				button_ok.disabled = true;

				if (!document.getElementById('html_sales_pos_show_parked_table_detail')) {

					button_ok.disabled = false;

					frappe.msgprint({title: __("Message"), message: __('No selected document'), indicator: "red"});
					raise;

				}

				let found_document = false;
				let document_id = '';

				for (var i = 0; i < document.getElementById('html_sales_pos_show_parked_table_detail').rows.length; i++) {

					if (document.getElementById('html_sales_pos_show_parked_table_detail_checked_id_' + i.toString())) {

						if (document.getElementById('html_sales_pos_show_parked_table_detail_checked_id_' + i.toString()).checked) {

							found_document = true;

							document_id = document.getElementById('html_sales_pos_show_parked_table_detail').rows[i].cells[1].innerText.trim();

						}

					}
				}

				if (!found_document) {

					button_ok.disabled = false;

					frappe.msgprint({title: __("Message"), message: __('No selected document'), indicator: "red"});
					raise;
				}


				sp.hide();

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

					silicon_ioi.doctype.ioiCashDesk.clear_pending_payment();
					silicon_ioi.doctype.ioiSalesPOS.get_document(document_id);
					silicon_ioi.doctype.ioiSalesPOS.display_on_screen('SHOW_PARKED')

					if (document.getElementById('ioi_sales_pos_scan_active').checked) {
						document.getElementById('ioi_sales_pos_scan').focus();
					}

				});
			},
			secondary_action: function(){
				sp.hide();

				if (document.getElementById('ioi_sales_pos_scan_active').checked) {
					document.getElementById('ioi_sales_pos_scan').focus();
				}

				return false;
			}

		});


		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_show_parked_table_header');

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_show_parked_search_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_show_parked_search_value');


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:60px; width: 730px;">';

		html += '	<div style="position: relative; top: 0px; left: 0px; width:220px;">';
		html += '		<label id="html_sales_pos_show_parked_search_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 726px; height: 25px;"> ';
		html += '			<input id="html_sales_pos_show_parked_search_value" type="text" class="input-with-feedback form-control bold" style="" value="">';
		html += '		</div>';

		html += '	</div>';

		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:400px;">';


		html += '<table id="html_sales_pos_show_parked_table_header" width=730px border=1 style="border: 1px solid #E8EAEB" data-custom-grid="true">';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Document") + '</b></td>';
		html += '<td width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Date") + '</b></td>';
		html += '<td width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Customer") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Total") + '</b></td>';
		html += '<td width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Currency") + '</b></td>';
		html += '</tr>';
		html += '</table>';

		html += '</div>';



		sp.fields_dict.html_sales_pos_show_parked.$wrapper.html(html);
		sp.$wrapper.find('.modal-dialog').css("max-width", "780px").css("width", "780px");
		sp.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
		sp.show();

		document.body.disabled = true;

		silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

			let fct_keydown = function(event) {

				if (event.keyCode == 13) {

					silicon_ioi.doctype.ioiSalesPOS.show_parked_data();
					return false;
				}

			};

			document.getElementById('html_sales_pos_show_parked_search_value').onkeydown = fct_keydown;


			silicon_ioi.doctype.ioiSalesPOS.show_parked_data();
		});
	}

	static show_parked_data()
	{
		if (document.getElementById('html_sales_pos_show_parked_table_detail')) {

			for (var i = 0; i < document.getElementById('html_sales_pos_show_parked_table_detail').rows.length; i++) {

				silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_show_parked_table_detail_checked_id_' + i.toString());

			}
			silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_show_parked_table_detail');
		}

		frappe.call({ 	method: path_sales_pos + ".ioi_sales_pos_show_parked",
						args: {	"journal_id" : silicon_ioi.doctype.ioiSalesPOS.journal_id,
								"cashdesk_id" : silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
								"search_term": document.getElementById('html_sales_pos_show_parked_search_value').value,
								"name": ''

						},
						async : false,
						callback:function(r){


			let html = '';

			if (r.message.length > 0)
			{

				let cpt = 0;

				html = '<table id="html_sales_pos_show_parked_table_detail" border=1 style="border: 1px solid #E8EAEB" width=730px>';

				for (var i = 0; i < r.message.length; i++)
				{
					html += '<tr style="height:30px">';

					html += '<td width=30px style="vertical-align: middle;" align="center">';

					html += '<input type="checkbox" id="html_sales_pos_show_parked_table_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 2px; left: 2px;" ';
					html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
					html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
					html += '>';

					html += '</td>';


					html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].name + '</td>';
					html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].document_date + '</td>';

					if (r.message[i].delivery_customer_id != null) {
						html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].delivery_customer_id + '</td>';
					}else{
						html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
					}

					if ((r.message[i].total_tvac != null) && (r.message[i].total_tvac != 0)){
						html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].total_tvac + '&nbsp;</td>';
					}else{
						html += '<td width=120px style="vertical-align: middle;" align="right">&nbsp;</td>';
					}

					if (r.message[i].currency_id != null) {
						html += '<td width=80px style="vertical-align: middle;">&nbsp;' + r.message[i].currency_id + '</td>';
					}else{
						html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
					}
					html += '</tr>';
				}

				html += '</table>';



			}else
			{
				html += '<table id="html_sales_pos_show_parked_table_detail" width=730px border=1 style="border: 1px solid #E8EAEB">';
				html += '<tr style="height:30px">';
				html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
				html += '</tr>';
				html += '</table>';
			}


			document.getElementById('html_sales_pos_show_parked_table_header').insertAdjacentHTML('afterend', html);

			silicon_ioi.doctype.ioiItemPriceFrame.sleep_static(400).then(() => {

				let fct_click = function () {

					if (document.getElementById('html_sales_pos_show_parked_table_detail')) {

						for (var i = 0; i < document.getElementById('html_sales_pos_show_parked_table_detail').rows.length; i++) {

							if (document.getElementById('html_sales_pos_show_parked_table_detail_checked_id_' + i.toString())) {

								document.getElementById('html_sales_pos_show_parked_table_detail_checked_id_' + i.toString()).checked = false;
							}

						}

						this.checked = true;
					}
				};


				if (document.getElementById('html_sales_pos_show_parked_table_detail')) {

					for (var i = 0; i < document.getElementById('html_sales_pos_show_parked_table_detail').rows.length; i++) {

						if (document.getElementById('html_sales_pos_show_parked_table_detail_checked_id_' + i.toString())) {

							document.getElementById('html_sales_pos_show_parked_table_detail_checked_id_' + i.toString()).onclick = fct_click;
						}

						if (i == 0) {
							if (document.getElementById('html_sales_pos_show_parked_table_detail_checked_id_' + i.toString())) {
								document.getElementById('html_sales_pos_show_parked_table_detail_checked_id_' + i.toString()).checked = true;
							}
						}

					}
				}

			});
		}});
	}

	static load_command_help()
	{
		silicon_ioi.doctype.ioiSalesPOS.command = [];
		silicon_ioi.doctype.ioiSalesPOS.command[0] = [__("Global functions"), ''];
		silicon_ioi.doctype.ioiSalesPOS.command[1] = ['$-PRINT', 					__("Print the receipt")];
		silicon_ioi.doctype.ioiSalesPOS.command[2] = ['$-PREVIEW', 					__("Preview the receipt")];
		silicon_ioi.doctype.ioiSalesPOS.command[3] = ['$-SHOWPARKED', 				__("Without parameter, open the show parked list")];
		silicon_ioi.doctype.ioiSalesPOS.command[4] = ['$-SHOWPARKEDXXX/YYY',		__("Get the parked delivery XXX • YYY")];
		silicon_ioi.doctype.ioiSalesPOS.command[5] = ['$-OPENORDER', 				__("Without parameter, open the open orders list, to create the document")];
		silicon_ioi.doctype.ioiSalesPOS.command[6] = ['$-OPENORDERXXX/YYY',			__("Create a new delivery from the open order XXX • YYY")];
		silicon_ioi.doctype.ioiSalesPOS.command[7] = ['$-INVOICE',					__("Create an invoice from the current delivered document")];
		silicon_ioi.doctype.ioiSalesPOS.command[8] = ['$-PARAMETERS', 				__("Without parameters, open the profile window")];
		silicon_ioi.doctype.ioiSalesPOS.command[9] = ['$-PARAMETERSXXX/YYY',		__("Select the journal XXX and the cashdesk YYY")];
		silicon_ioi.doctype.ioiSalesPOS.command[10] = ['$-SHOWLOGS', 				__("Open the logs window")];
		silicon_ioi.doctype.ioiSalesPOS.command[11] = ['$-LOCK', 					__("Lock the screen")];
		silicon_ioi.doctype.ioiSalesPOS.command[12] = ['Your pwd or badge id',		__("Lock / unlock the screen")];
		silicon_ioi.doctype.ioiSalesPOS.command[13] = ['',							__("Assign badge id in <b>ioi User</b>")];


		silicon_ioi.doctype.ioiSalesPOS.command_form = [];
		silicon_ioi.doctype.ioiSalesPOS.command_form[0] = [__("Document functions"), ''];
		silicon_ioi.doctype.ioiSalesPOS.command_form[1] = ['$-NEW',					__("Create a new document")];
		silicon_ioi.doctype.ioiSalesPOS.command_form[2] = ['$-EDIT / $-COMPLETE',	__("Edit / Complete the current document")];
		silicon_ioi.doctype.ioiSalesPOS.command_form[3] = ['$-DELETE', 				__("Delete the current document")];
		silicon_ioi.doctype.ioiSalesPOS.command_form[4] = ['$-SAVE', 				__("Save the current document")];
		silicon_ioi.doctype.ioiSalesPOS.command_form[5] = ['$-PRIOR', 				__("Go to the prior document")];
		silicon_ioi.doctype.ioiSalesPOS.command_form[6] = ['$-NEXT', 				__("Go to the next document")];
		silicon_ioi.doctype.ioiSalesPOS.command_form[7] = ['$-GETXXX/YYY',			__("Go to the delivery XXX • YYYY ")];
		silicon_ioi.doctype.ioiSalesPOS.command_form[8] = ['$-CUST', 				__("Without parameter, open the customer search window")];
		silicon_ioi.doctype.ioiSalesPOS.command_form[9] = ['$-CUSTXXXXXXX', 		__("Set XXXXXXX to the customer")];
		silicon_ioi.doctype.ioiSalesPOS.command_form[10] = ['$-HBDISC',				__("Without parameter, open the base discount window with the calculator")];
		silicon_ioi.doctype.ioiSalesPOS.command_form[11] = ['$-HBDISCXX.XX ', 		__("Set XX.XX to the base discount")];
		silicon_ioi.doctype.ioiSalesPOS.command_form[12] = ['$-HEDISC',				__("Without parameter, open the extra discount window with the calculator")];
		silicon_ioi.doctype.ioiSalesPOS.command_form[13] = ['$-HEDISCXX.XX ', 		__("Set XX.XX to the extra discount")];



		silicon_ioi.doctype.ioiSalesPOS.command_form_detail = [];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[0] = [__("Document detail functions"), ''];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[1] = ['$-NEWLN', 		__("Add a new row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[2] = ['$-NEWLNYYYYYY', 	__("Add a new row with the item YYYYYY")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[3] = ['$-DELLN-XXX', 	__("Remove the row number XXX in the detail")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[4] = ['$-SETLN-XXX', 	__("Select the row number XXX in the detail")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[5] = ['$-SETITEM', 		__("Without parameter, open the item window from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[6] = ['$-SETITEM123456',__("Set the item code 1234546 to the item from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[7] = ['$-INCQTY', 		__("Add 1 to the quantity from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[8] = ['$-INCQTYXX.XX', 	__("Add XXX.XX to the quantity from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[9] = ['$-DECQTY', 		__("Remove 1 to the quantity from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[10] = ['$-DECQTYXX.XX', __("Remove XXX.XX to the quantity from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[11] = ['$-SETQTY', 		__("Without parameter, open the quantity window with the calculator from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[12] = ['$-SETQTYXX.XX',	__("Set XXX.XX to the quantity from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[13] = ['$-SETPRICE', 	__("Without parameter, open the price window with the calculator from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[14] = ['$-SETPRICEXXX.XX',__("Set XXX.XX to the price from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[15] = ['$-SETDISC', 	__("Without parameter, open the discount window with the calculator from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[16] = ['$-SETDISCXX.XX',__("Set XX.XX to the extra discount from the selected row (base discount is set 0)")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[17] = ['$-SETCODEREF', 	__("Without parameter, open the code ref window from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[18] = ['$-SETCODEREFXXXX',__("Set XXXX to the code ref from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[19] = ['$-VOUT-XXX-YYY',__("Create a voucher and assign XXX to item and YYY to the code ref from the selected row")];
		silicon_ioi.doctype.ioiSalesPOS.command_form_detail[20] = ['$-VIN-XXX',__("Get the voucher XXX (code ref or SN) from the selected row")];


		silicon_ioi.doctype.ioiSalesPOS.command_value = [];
		silicon_ioi.doctype.ioiSalesPOS.command_value[0] = [__("Assigning value to field"), ''];
		silicon_ioi.doctype.ioiSalesPOS.command_value[1] = ['$-MFD-XXX-YYY', __("Set value (YYY) to field (XXX) in master")];
		silicon_ioi.doctype.ioiSalesPOS.command_value[2] = ['',              __("<u>Example</u> : <b>$-MFD-OCUST-ABC123</b>")];
		silicon_ioi.doctype.ioiSalesPOS.command_value[3] = ['',              __("Set ABC123 in linked field to OCUST")];
		silicon_ioi.doctype.ioiSalesPOS.command_value[4] = ['$-DFD-XXX-YYY', __("Set value (YYY) to field (XXX) in detail")];
		silicon_ioi.doctype.ioiSalesPOS.command_value[5] = ['',              __("<u>Example</u> : <b>$-DFD-IT-ABC1234</b>")];
		silicon_ioi.doctype.ioiSalesPOS.command_value[6] = ['',              __("Set ABC1234 in linked field to IT")];
		silicon_ioi.doctype.ioiSalesPOS.command_value[7] = ['',               __("Go to <b>ioi Scan Settings</b> (ioi Sales Delivery) to link initials to fields")];


		silicon_ioi.doctype.ioiSalesPOS.prefix_detection = [];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[0] = [__("Payment & deliver functions"), ''];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[1] = ['$-PAYDELIVER', 		__("Pay and deliver the delivery")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[2] = ['$-DELIVER', 		__("Deliver the delivery if no payment operation")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[3] = ['$-PAYCASH', 		__("Without parameter, open the payment window")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[4] = ['$-PAYCASH-XXX.XX', 	__("Create a cash payment with XXX.XX")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[5] = ['$-GETCASH', 		__("Without parameter, open the get cash window")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[6] = ['$-GETCASH-V-R-C', 	__("Create a get cash operation V = value, R = Remark, C = cashback to customer (0/1)")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[7] = ['$-PUTCASH', 		__("Without parameter, open the put cash window")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[8] = ['$-PUTCASH-V-R-C', 	__("Create a put cash operation V = value, R = Remark, C = received from customer (0/1)")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[9] = ['$-PAYACTIVITY', 	__("Open the payment activity window")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[10] = ['$-OPENCASHDESK', 	__("Without paramater, open the cash desk window for opening")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[11] = ['$-OPENCASHDESK-VALUE',__("Open the closed current cash desk and put value in cash")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[12] = ['$-CLOSECASHDESK', 	__("Without paramater, open the cash desk window for closing")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[13] = ['$-CLOSECASHDESK-C-B-CR',__("Close the current cash desk and put values")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[14] = ['&nbsp;&nbsp;&nbsp;-CH-PP-EM-SP1-SP2-SP3',__("C = Cash value, B = Bank value, CR = Credit value, CH = Check value,")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[15] = ['',__("PP = PayPal value, EM = Emoney value, SP1 = Check spec 1 value, SP2 = Check spec 2 value,")];
		silicon_ioi.doctype.ioiSalesPOS.prefix_detection[16] = ['',__("SP3 = Check spec 3 value")];

	}


	static show_help()
	{
		var d = new frappe.ui.Dialog({
			'title': __("Scanning functions help"),
			'fields': [
				{'fieldname': 'html_sales_pos_scanning_help', 'fieldtype': 'HTML'}

			],
			primary_action_label: __('Ok'),
			primary_action: function(){
				d.hide();

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(1000).then(() => {

					document.getElementById('sb_main_scan_input').focus();
				});


			}

		});

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

		html += '<table border=0 style="border: 0px solid #E8EAEB" width=800px>';

		for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.command.length; i++)
		{
			if (i == 0)	{

				html += '<tr style="height:30px;">';
				html += '<td colspan=2 style="vertical-align: middle;">&nbsp;<div style="background-color:#D0E7FB; border-radius: 6px; padding: 4px;"><b><font color="black">' + silicon_ioi.doctype.ioiSalesPOS.command[i][0] + '</font></b></div></td>';
				html += '</tr>';

			}else {

				html += '<tr style="height:30px">';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + silicon_ioi.doctype.ioiSalesPOS.command[i][0] + '</b></td>';
				html += '<td width=600px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.command[i][1] + '</td>';
				html += '</tr>';

			}
		}

		for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.command_form.length; i++)
		{
			if (i == 0)	{

				html += '<tr style="height:30px;">';
				html += '<td colspan=2 style="vertical-align: middle;">&nbsp;<div style="background-color:#D0E7FB; border-radius: 6px; padding: 4px;"><b><font color="black">' + silicon_ioi.doctype.ioiSalesPOS.command_form[i][0] + '</font></b></div></td>';
				html += '</tr>';

			}else {

				html += '<tr style="height:30px">';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + silicon_ioi.doctype.ioiSalesPOS.command_form[i][0] + '</b></td>';
				html += '<td width=600px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.command_form[i][1] + '</td>';
				html += '</tr>';

			}
		}

		for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.command_form_detail.length; i++)
		{
			if (i == 0)	{

				html += '<tr style="height:30px;">';
				html += '<td colspan=2 style="vertical-align: middle;">&nbsp;<div style="background-color:#D0E7FB; border-radius: 6px; padding: 4px;"><b><font color="black">' + silicon_ioi.doctype.ioiSalesPOS.command_form_detail[i][0] + '</font></b></div></td>';
				html += '</tr>';

			}else {

				html += '<tr style="height:30px">';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + silicon_ioi.doctype.ioiSalesPOS.command_form_detail[i][0] + '</b></td>';
				html += '<td width=600px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.command_form_detail[i][1] + '</td>';
				html += '</tr>';

			}

		}


		for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.command_value.length; i++)
		{
			if (i == 0)	{

				html += '<tr style="height:30px;">';
				html += '<td colspan=2 style="vertical-align: middle;">&nbsp;<div style="background-color:#D0E7FB; border-radius: 6px; padding: 4px;"><b><font color="black">' + silicon_ioi.doctype.ioiSalesPOS.command_value[i][0] + '</font></b></div></td>';
				html += '</tr>';

			}else {

				html += '<tr style="height:30px">';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + silicon_ioi.doctype.ioiSalesPOS.command_value[i][0] + '</b></td>';
				html += '<td width=600px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.command_value[i][1] + '</td>';
				html += '</tr>';

			}

		}


		for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.prefix_detection.length; i++)
		{
			if (i == 0)	{

				html += '<tr style="height:30px;">';
				html += '<td colspan=2 style="vertical-align: middle;">&nbsp;<div style="background-color:#D0E7FB; border-radius: 6px; padding: 4px;"><b><font color="black">' + silicon_ioi.doctype.ioiSalesPOS.prefix_detection[i][0] + '</font></b></div></td>';
				html += '</tr>';

			}else {

				html += '<tr style="height:30px">';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;<b>' + silicon_ioi.doctype.ioiSalesPOS.prefix_detection[i][0] + '</b></td>';
				html += '<td width=600px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.prefix_detection[i][1] + '</td>';
				html += '</tr>';

			}

		}



		html += '</table>';
		html += '</div>';

		d.fields_dict.html_sales_pos_scanning_help.$wrapper.html(html);

		if (window.innerWidth <= 500) {
			d.$wrapper.find('.modal-dialog').css("max-width", '100%').css("width", '100%');
		}else {
			d.$wrapper.find('.modal-dialog').css("max-width", '850px').css("width", '850px');
		}
		d.show();
	}


	static scan(obj, scan_value = null, orig = null)
	{
		document.getElementById('ioi_sales_last_scan').innerText = '';



		let v = obj.value;

		if ((scan_value != null) && (scan_value != '')) {
			v = scan_value;
		}

		if ((v) && (v.trim() == '')) {
			return;
		}


		if (v.length < 2) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Scanned value is to short'));
			return false;
		}

		let v_lower = v;

		v = v.toUpperCase();




		silicon_ioi.doctype.ioiSalesPOS.load_command_help();

		if (v.substring(0, 2) == '$-')
		{
			let char_allowed = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 -$%./+*?';


			for (var i = 0; i < v.length; i++) {

				if (char_allowed.indexOf(v[i]) == -1) {

					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid char in scanned value (code 39)'));
					return false;

				}
			}

			v = v.substring(1, v.length);

			if (v.trim().toUpperCase().substring(0, 2) == '-?')
			{
				// ************************************************************
				// Help
				// ************************************************************

				silicon_ioi.doctype.ioiSalesPOS.show_help();

			}else if (v.trim().toUpperCase() == '-NEW') {

				// ************************************************************
				// New document
				// ************************************************************
				obj.value = '';
				obj.focus();

				document.getElementById('ioi_sales_pos_new_button').click();

			}else if ((v.trim().toUpperCase() == '-EDIT') || (v.trim().toUpperCase() == '-COMPLETE')) {

				// ************************************************************
				// Edit / Complete document
				// ************************************************************

				obj.value = '';
				obj.focus();

				document.getElementById('ioi_sales_pos_edit_button').click();


			}else if (v.trim().toUpperCase() == '-DELETE') {

				// ************************************************************
				// Delete document
				// ************************************************************

				obj.value = '';
				obj.focus();

				document.getElementById('ioi_sales_pos_delete_button').click();

			}else if (v.trim().toUpperCase() == '-SAVE') {

				// ************************************************************
				// Save document
				// ************************************************************

				obj.value = '';
				obj.focus();

				document.getElementById('ioi_sales_pos_save_button').click();


			}else if (v.trim().toUpperCase() == '-PRIOR') {

				// ************************************************************
				// Prior document
				// ************************************************************

				obj.value = '';
				obj.focus();

				document.getElementById('ioi_sales_pos_prior_button').click();


			}else if (v.trim().toUpperCase() == '-NEXT') {

				// ************************************************************
				// Next document
				// ************************************************************

				obj.value = '';
				obj.focus();


				document.getElementById('ioi_sales_pos_next_button').click();

			}else if (v.trim().toUpperCase() == '-PRINT') {

				// ************************************************************
				// Print voucher
				// ************************************************************

				obj.value = '';
				obj.focus();

				silicon_ioi.doctype.ioiSalesPOS.reprint(false);

			}else if (v.trim().toUpperCase() == '-PREVIEW') {

				// ************************************************************
				// Preview voucher
				// ************************************************************

				obj.value = '';
				obj.focus();

				silicon_ioi.doctype.ioiSalesPOS.reprint(true);


			}else if (v.trim().toUpperCase().substring(0, 11) == '-SHOWPARKED') {

				// ************************************************************
				// Show parket
				// ************************************************************

				obj.value = '';
				obj.focus();

				if (v.trim().toUpperCase() == '-SHOWPARKED') {
					document.getElementById('ioi_sales_pos_show_parked_button').click();
				}else{

					if (document.getElementById('ioi_sales_pos_show_parked_button').disabled) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Save the changes'));
						return false;
					}

					if ((!silicon_ioi.doctype.ioiSalesPOS.journal_id) || ((silicon_ioi.doctype.ioiSalesPOS.journal_id) && (silicon_ioi.doctype.ioiSalesPOS.journal_id == ''))) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select the journal in the parameters'));
						return false;
					}


					if ((!silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.cashdesk_id == ''))) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select the cash desh id in the parameters'));
						return false;
					}

					let s = v.trim().toUpperCase().substring(11, v.length).trim();

					s = s.replaceAll('/', ' • ');

					frappe.call({ 	method: path_sales_pos + ".ioi_sales_pos_show_parked",
									args: {	"journal_id" : silicon_ioi.doctype.ioiSalesPOS.journal_id,
											"cashdesk_id" : silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
											"search_term": '',
											"name": s

									},
									async : false,
									callback:function(r){


										if (r.message.length == 0)
										{
											silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This document') + ' ' + s + ' ' + __("is not a parked document"));
											return false;

										}else{
											silicon_ioi.doctype.ioiSalesPOS.get_document(r.message[0].name);
										}
									}
					});
				}



			}else if (v.trim().toUpperCase().substring(0, 10) == '-OPENORDER') {

				// ************************************************************
				// Get open order
				// ************************************************************

				obj.value = '';
				obj.focus();

				if (v.trim().toUpperCase() == '-OPENORDER') {
					document.getElementById('ioi_sales_pos_customer_button_open_order').click();
				}else{

					if (document.getElementById('ioi_sales_pos_customer_button_open_order').disabled) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Save the changes'));
						return false;
					}

					if ((!silicon_ioi.doctype.ioiSalesPOS.journal_id) || ((silicon_ioi.doctype.ioiSalesPOS.journal_id) && (silicon_ioi.doctype.ioiSalesPOS.journal_id == ''))) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select the journal in the parameters'));
						return false;
					}


					if ((!silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.cashdesk_id == ''))) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select the cash desh id in the parameters'));
						return false;
					}

					let s = v.trim().toUpperCase().substring(10, v.length).trim();

					s = s.replaceAll('/', ' • ');



					frappe.call({ 	method: path_sales_pos + ".ioi_sales_pos_open_order",
									args: {	"journal_id" : silicon_ioi.doctype.ioiSalesPOS.journal_id,
											"customer_id": '',
											"name": s

									},
									async : false,
									callback:function(r){


										if (r.message.length == 0)
										{
											silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This document') + ' ' + s + ' ' + __("is not an open order"));
											return false;

										}else{
											silicon_ioi.doctype.ioiSalesPOS.create_delivery_from_order(r.message[0].name);
										}
									}
					});


				}


			}else if (v.trim().toUpperCase() == '-INVOICE') {

				// ************************************************************
				// Generate invoice
				// ************************************************************

				obj.value = '';
				obj.focus();

				silicon_ioi.doctype.ioiSalesPOS.invoice(true);


			}else if (v.trim().toUpperCase() == '-LOCK') {

				// ************************************************************
				// Lock Screen
				// ************************************************************

				obj.value = '';
				obj.focus();

				silicon_ioi.doctype.ioiSalesPOS.lock_screen();


			}else if (v.trim().toUpperCase() == '-SHOWLOGS') {

				// ************************************************************
				// Show logs
				// ************************************************************

				obj.value = '';
				obj.focus();

				document.getElementById('ioi_sales_pos_show_log_button').click();


			}else if (v.trim().toUpperCase().substring(0, 11) == '-PARAMETERS') {

				// ************************************************************
				// Parameters
				// ************************************************************

				obj.value = '';
				obj.focus();


				if (v.trim().toUpperCase() == '-PARAMETERS') {
					document.getElementById('ioi_sales_pos_parameters_button').click();
				}else{


					if (document.getElementById('ioi_sales_pos_parameters_button').disabled) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Save the changes'));

						return false;
					}

					let s = v.trim().toUpperCase().substring(11, v.length).trim();

					if (s.indexOf('/') == -1) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Journal and cashdesk are missing (ex $-PARAMETERSJOURNALID/CASHDESKID)'));
						return false;
					}

					let j = s.substring(0, s.indexOf('/'));
					let c = s.substring(s.indexOf('/')+1, s.length);

					let method = path_sales_pos + '.ioi_sales_pos_get_profiles'

					frappe.call({  	method: method,
									args: {"search_term" : '', "journal_id" : j, "cashdesk_id" : c},
									async: false,
									callback:function(r)	{

										if (r.message.length == 0) {
											silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This profile does not exist'));
											return false;

										}else {
											silicon_ioi.doctype.ioiSalesPOS.update_selected_profile(r.message[0].name);
											silicon_ioi.doctype.ioiSalesPOS.load_default_profile(r.message[0].name);
											silicon_ioi.doctype.ioiSalesPOS.load_sales_journal_data();
										}
									}
					});

				}


			}else if (v.trim().toUpperCase().substring(0, 5) == '-CUST') {

				// ************************************************************
				// Set customer
				// ************************************************************

				obj.value = '';
				obj.focus();

				if (v.trim().toUpperCase() == '-CUST') {
					document.getElementById('ioi_sales_pos_customer_button_search').click();

				}else{

					let create_new = false;

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

						create_new = true;

					}

					if ((!create_new) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

						create_new = true;
					}

					let s = v.trim().toUpperCase().substring(5, v.length).trim();

					if (!create_new) {

						silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
						document.getElementById('ioi_sales_pos_customer_id').focus();
						document.getElementById('ioi_sales_pos_customer_id').value = s;
						document.getElementById('ioi_sales_pos_customer_id').blur();

					}else {

						document.getElementById('ioi_sales_pos_new_button').click();

						document.getElementById('ioi_sales_pos_customer_id').focus();
						document.getElementById('ioi_sales_pos_customer_id').value = s;
						document.getElementById('ioi_sales_pos_customer_id').blur();
					}
				}


			}else if (v.trim().toUpperCase().substring(0, 7) == '-HBDISC') {

				// ************************************************************
				// Set header base discount
				// ************************************************************

				obj.value = '';
				obj.focus();

				if (v.trim().toUpperCase() == '-HBDISC') {
					silicon_ioi.doctype.ioiSalesPOS.edit_discount_header('BASE_DISCOUNT_HEADER');

				}else{

					let create_new = false;


					if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

						create_new = true;
					}

					if ((!create_new) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

						create_new = true;
					}

					let s = v.trim().toUpperCase().substring(7, v.length).trim();


					if (isNaN(s)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, s + ' ' + __('is not a valid number'));
						return false;
					}

					let method = path_sales_pos + '.ioi_sales_pos_access_price_discounts';
					let ret = {};

					frappe.call({  	method: method,
									args: {	"tp": 'HBD',
											"prefix_id": silicon_ioi.doctype.ioiSalesPOS.doc.prefix_id,
											"items":'[]',
											"badge_id": silicon_ioi.doctype.ioiSalesPOS.batchuser
									},
									async: false,
									callback:function(r)	{
										ret = r.message;
									}
					});

					silicon_ioi.doctype.ioiSalesPOS.value_max_value = 100;
					silicon_ioi.doctype.ioiSalesPOS.no_min_value = 0;

					if (ret.error == 1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, ret.error_msg);
						return false;
					}else{
						if (ret.value_access == 0) {
							silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You are not authorized to modify the header base discount"));
							return false;
						}else{
							silicon_ioi.doctype.ioiSalesPOS.value_max_value = parseFloat(ret.value_max_amount);
							silicon_ioi.doctype.ioiSalesPOS.no_min_value = ret.no_min_value;
						}
					}

					let min_value = 0;

					if (silicon_ioi.doctype.ioiSalesPOS.doc.def_base_discount) {
						min_value = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.def_base_discount);
					}


					if (silicon_ioi.doctype.ioiSalesPOS.no_min_value == 0) {
						if (parseFloat(s) < parseFloat(min_value)) {
							silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Base discount : min value allowed") + ': ' + parseFloat(min_value).toFixed(2));
							return false;
						}
					}

					if (parseFloat(s) > parseFloat(silicon_ioi.doctype.ioiSalesPOS.value_max_value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Base discount : max value allowed") + ': ' + parseFloat(silicon_ioi.doctype.ioiSalesPOS.value_max_value).toFixed(2));
						return false;
					}

					if (!create_new) {
						silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
						document.getElementById('ioi_sales_pos_base_discount').value = s;
						silicon_ioi.doctype.ioiSalesPOS.doc.base_discount = s;
						silicon_ioi.doctype.ioiSalesPOS.compute_document();
					}else{

						document.getElementById('ioi_sales_pos_new_button').click();
						document.getElementById('ioi_sales_pos_base_discount').value = s;
						silicon_ioi.doctype.ioiSalesPOS.doc.base_discount = s;
						silicon_ioi.doctype.ioiSalesPOS.compute_document();
					}



				}

			}else if (v.trim().toUpperCase().substring(0, 7) == '-HEDISC') {

				// ************************************************************
				// Set header extra discount
				// ************************************************************

				obj.value = '';
				obj.focus();

				if (v.trim().toUpperCase() == '-HEDISC') {
					silicon_ioi.doctype.ioiSalesPOS.edit_discount_header('EXTRA_DISCOUNT_HEADER');

				}else{

					let create_new = false;

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

						create_new = true;
					}

					if ((!create_new) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

						create_new = true;
					}



					let s = v.trim().toUpperCase().substring(7, v.length).trim();

					if (isNaN(s)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, s + ' ' + __('is not a valid number'));
						return false;

					}


					let method = path_sales_pos + '.ioi_sales_pos_access_price_discounts';
					let ret = {};

					let tab_items = [];

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

						for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

							let item_found = false;
							let item_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id;

							if (tab_items.length > 0) {

								for (var j = 0; j < tab_items.length; j++) {
									if (tab_items[j] == item_id) {
										item_found = true;
										break;
									}
								}

							}

							if (!item_found) {
								if (item_id.trim() != '') {
									tab_items[tab_items.length] = item_id;
								}
							}

						}
					}

					frappe.call({  	method: method,
									args: {	"tp": 'HED',
											"prefix_id": silicon_ioi.doctype.ioiSalesPOS.doc.prefix_id,
											"items": tab_items,
											"badge_id": silicon_ioi.doctype.ioiSalesPOS.batchuser
									},
									async: false,
									callback:function(r)	{
										ret = r.message;
									}
					});

					silicon_ioi.doctype.ioiSalesPOS.value_max_value = 100;
					silicon_ioi.doctype.ioiSalesPOS.no_min_value = 0;

					if (ret.error == 1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, ret.error_msg);
						return false;
					}else{
						if (ret.value_access == 0) {
							silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You are not authorized to modify the header extra discount"));
							return false;
						}else{
							silicon_ioi.doctype.ioiSalesPOS.value_max_value = parseFloat(ret.value_max_amount);
							silicon_ioi.doctype.ioiSalesPOS.no_min_value = ret.no_min_value;
						}
					}

					let min_value = 0;

					if (silicon_ioi.doctype.ioiSalesPOS.doc.def_extra_discount) {
						min_value = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.def_extra_discount);
					}


					if (silicon_ioi.doctype.ioiSalesPOS.no_min_value == 0) {

						if (parseFloat(s) < parseFloat(min_value)) {
							silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Extra discount : min value allowed") + ': ' + parseFloat(min_value).toFixed(2));
							return false;
						}
					}

					if (parseFloat(s) > parseFloat(silicon_ioi.doctype.ioiSalesPOS.value_max_value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Extra discount : max value allowed") + ': ' + parseFloat(silicon_ioi.doctype.ioiSalesPOS.value_max_value).toFixed(2));
						return false;
					}


					if (!create_new) {

						silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
						document.getElementById('ioi_sales_pos_extra_discount').value = s;
						silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount = s;
						silicon_ioi.doctype.ioiSalesPOS.compute_document();
					}else{

						document.getElementById('ioi_sales_pos_new_button').click();
						document.getElementById('ioi_sales_pos_extra_discount').value = s;
						silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount = s;
						silicon_ioi.doctype.ioiSalesPOS.compute_document();
					}

				}


			}else if (v.trim().toUpperCase().substring(0, 6) == '-NEWLN')
			{
				// ************************************************************
				// new line in detail
				// ************************************************************

				obj.value = '';
				obj.focus();

				if (v.trim().toUpperCase() == '-NEWLN') {
					document.getElementById('ioi_sales_pos_detail_new_button').click();
				}else{

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
						return false;
					}

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
						return false;
					}

					let s = v.trim().toUpperCase().substring(6, v.length).trim();

					let method = path_sales_pos + '.ioi_sales_pos_check_item'

					frappe.call({  	method: method,
									args: {"item_id" : s},
									async: false,
									callback:function(r)	{

										if (r.message == 0) {
											silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This item') + ' ' + s + ' ' + __('does not exist'));
											return false;

										}else {
											silicon_ioi.doctype.ioiSalesPOS.do_new_line(s, true, 1);
										}
									}
					});
				}

			}else if (v.trim().toUpperCase().substring(0, 6) == '-DELLN') {

				// ************************************************************
				// Delete line in detail
				// ************************************************************

				obj.value = '';
				obj.focus();

				if (v.trim().toUpperCase() == '-DELLN') {
					silicon_ioi.doctype.ioiSalesPOS.delete_line(false);
				}else{

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
						return false;
					}


					let s = v.trim().toUpperCase().substring(6, v.length).trim();

					if (parseInt(s) < 1) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Line no has to be greater than zero'));
						return false;
					}

					if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + (parseInt(s)-1).toString())) {

						document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + (parseInt(s)-1)).click();
						silicon_ioi.doctype.ioiSalesPOS.delete_line(false);
					}else{
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Line no') + ' ' + s + ' ' + __('does not exist'));
						return false;
					}

				}



			}else if (v.trim().toUpperCase().substring(0, 6) == '-SETLN') {

				// ************************************************************
				// Set focus on line in detail
				// ************************************************************

				obj.value = '';
				obj.focus();

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
					return false;
				}


				let s = v.trim().toUpperCase().substring(6, v.length).trim();

				if (s.trim() == '') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Line no is mandatory'));
					return false;

				}

				if (parseInt(s) < 1) {

					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Line no has to be greater than zero'));
					return false;
				}

				if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + (parseInt(s)-1).toString())) {

					document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + (parseInt(s)-1)).click();

				}else{
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Line no') + ' ' + s + ' ' + __('does not exist'));
					return false;
				}

			}else if (v.trim().toUpperCase().substring(0, 7) == '-INCQTY') {

				// ************************************************************
				// Add qty
				// ************************************************************

				obj.value = '';
				obj.focus();

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
					return false;
				}

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
					return false;
				}

				if (v.trim().toUpperCase() == '-INCQTY') {
					silicon_ioi.doctype.ioiSalesPOS.qt_add(1, document.getElementById('ioi_sales_pos_qt_dec_1_button'));

				}else{


					let s = v.trim().toUpperCase().substring(7, v.length).trim();

					if (s.trim() == '') {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Quantity to add is mandatory'));
						return false;
					}

					if (isNaN(s)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Quantity is not a valid number'));
						return false;

					}

					if (parseFloat(s) < 1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Quantity has to be greater than zero'));
						return false;
					}


					silicon_ioi.doctype.ioiSalesPOS.qt_add(parseFloat(s), document.getElementById('ioi_sales_pos_qt_dec_1_button'));
				}

			}else if (v.trim().toUpperCase().substring(0, 7) == '-DECQTY') {

				// ************************************************************
				// Dec qty
				// ************************************************************

				obj.value = '';
				obj.focus();

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
					return false;
				}

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
					return false;
				}

				if (v.trim().toUpperCase() == '-DECQTY') {
					silicon_ioi.doctype.ioiSalesPOS.qt_add(-1, document.getElementById('ioi_sales_pos_qt_dec_1_button'));

				}else{


					let s = v.trim().toUpperCase().substring(7, v.length).trim();

					if (s.trim() == '') {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Quantity to remove is mandatory'));
						return false;
					}

					if (isNaN(s)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Quantity is not a valid number'));
						return false;

					}

					if (parseFloat(s) < 1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Quantity has to be greater than zero'));
						return false;
					}


					silicon_ioi.doctype.ioiSalesPOS.qt_add(parseFloat(s) *-1, document.getElementById('ioi_sales_pos_qt_dec_1_button'));
				}

			}else if (v.trim().toUpperCase().substring(0, 7) == '-SETQTY') {

				// ************************************************************
				// Set qty
				// ************************************************************

				obj.value = '';
				obj.focus();

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
					return false;
				}

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
					return false;
				}

				if (!document.getElementById('ioi_sales_pos_detail_table_detail')) {

					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
					return false;

				}

				let element = '';


				for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

					if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

						if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {
							element = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).id;
							break;
						}
					}
				}

				if (element == '') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
					return false;
				}

				while (element.indexOf('_') != -1) {
					element = element.substring(element.indexOf('_')+1, element.length);
				}

				let idx = parseInt(element);


				if (v.trim().toUpperCase() == '-SETQTY') {
					document.getElementById('ioi_sales_pos_qt_edit_button_' + idx.toString()).click();

				}else{


					let s = v.trim().toUpperCase().substring(7, v.length).trim();

					if (s.trim() == '') {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Quantity to remove is mandatory'));
						return false;
					}

					if (isNaN(s)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Quantity is not a valid number'));
						return false;

					}

					silicon_ioi.doctype.ioiSalesPOS.set_qt(parseFloat(s));

				}



			}else if (v.trim().toUpperCase().substring(0, 9) == '-SETPRICE') {

				// ************************************************************
				// Set price
				// ************************************************************

				obj.value = '';
				obj.focus();

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
					return false;
				}

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
					return false;
				}


				if (!document.getElementById('ioi_sales_pos_detail_table_detail')) {

					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
					return false;

				}

				let element = '';


				for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

					if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

						if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {
							element = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).id;
							break;
						}
					}
				}

				if (element == '') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
					return false;
				}

				while (element.indexOf('_') != -1) {
					element = element.substring(element.indexOf('_')+1, element.length);
				}

				let idx = parseInt(element);



				if (v.trim().toUpperCase() == '-SETPRICE') {

					document.getElementById('ioi_sales_pos_price_edit_button_' + idx.toString()).click();
				}else{

					let s = v.trim().toUpperCase().substring(9, v.length).trim();

					if (isNaN(s)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Unit price is not a valid number'));
						return false;
					}


					let method = path_sales_pos + '.ioi_sales_pos_access_price_discounts';
					let ret = {};

					let tab_items = [];

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

						let item_found = false;
						let item_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id;

						if (tab_items.length > 0) {

							for (var j = 0; j < tab_items.length; j++) {
								if (tab_items[j] == item_id) {
									item_found = true;
									break;
								}
							}

						}

						if (!item_found) {
							if (item_id.trim() != '') {
								tab_items[tab_items.length] = item_id;
							}
						}

					}


					frappe.call({  	method: method,
									args: {	"tp": 'DUP',
											"prefix_id": silicon_ioi.doctype.ioiSalesPOS.doc.prefix_id,
											"items": tab_items,
											"badge_id": silicon_ioi.doctype.ioiSalesPOS.batchuser
									},
									async: false,
									callback:function(r)	{
										ret = r.message;
									}
					});


					if (ret.error == 1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, ret.error_msg);
						return false;
					}else{
						if (ret.value_access == 0) {
							silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You are not authorized to modify the unit price"));
							return false;
						}
					}


					let old_value = 0;

					if (!isNaN(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price)) {
						old_value = (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate / 100.00)));
					}

					let new_value = parseFloat(s);

					if (parseFloat(old_value) != parseFloat(new_value)) {

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].sales_condition_id = silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id;

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price_source = '';

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price = parseFloat(s) / (100 + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate) * 100;
						document.getElementById('ioi_sales_pos_detail_table_detail_unit_price_'+idx).innerHTML = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price.toFixed(2) + '&nbsp;';

						silicon_ioi.doctype.ioiSalesPOS.compute_document();



						let unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate / 100.00));

						document.getElementById('ioi_sales_pos_detail_table_detail_unit_price_' + + idx.toString()).innerHTML = unit_price.toFixed(2) + '&nbsp;';

						let total_line = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate / 100.00));

						document.getElementById('ioi_sales_pos_detail_table_detail_total_' + idx.toString()).innerHTML = total_line.toFixed(2) + '&nbsp';

						if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
							silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
							silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
						}


					}
				}


			}else if (v.trim().toUpperCase().substring(0, 8) == '-SETDISC') {

				// ************************************************************
				// Set discount
				// ************************************************************

				obj.value = '';
				obj.focus();

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
					return false;
				}

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
					return false;
				}


				if (!document.getElementById('ioi_sales_pos_detail_table_detail')) {

					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
					return false;

				}

				let element = '';


				for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

					if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

						if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {
							element = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).id;
							break;
						}
					}
				}

				if (element == '') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
					return false;
				}

				while (element.indexOf('_') != -1) {
					element = element.substring(element.indexOf('_')+1, element.length);
				}

				let idx = parseInt(element);


				if (v.trim().toUpperCase() == '-SETDISC') {
					document.getElementById('ioi_sales_pos_discount_edit_button_' + idx.toString()).click();

				}else{

					let show_discount_form = true;

					if (silicon_ioi.doctype.ioiSalesPOS.use_triggered_budget_discount == 1) {

						if (silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id != silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_condition_id) {

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_budget_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_budget_id.trim() != '')) {

								let init_extra_discount = false;

								if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].order_master_link) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].order_master_link) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].order_master_link.trim() == ''))) {
									init_extra_discount = true;
								}

								if (init_extra_discount) {

									let has_detail_discount = 0

									let method = path_item_budget + '.ioi_item_budget_has_detail_discounts';

									frappe.call({  	method: method,
													args: {"item_budget_id": silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_budget_id, "currency_id": silicon_ioi.doctype.ioiSalesPOS.doc.currency_id},
													async: false,
													callback:function(r)	{
														has_detail_discount = r.message;
													}
									});

									if (has_detail_discount == 1) {
										show_discount_form = false;
									}
								}
							}
						}
					}

					if (!show_discount_form) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Discount not editable (Item budget discount)'));
						return false;
					}

					let s = v.trim().toUpperCase().substring(8, v.length).trim();

					if (isNaN(s)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Discount is not a valid number'));
						return false;
					}


					let method = path_sales_pos + '.ioi_sales_pos_access_price_discounts';
					let ret = {};

					let tab_items = [];

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {


						let item_found = false;
						let item_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id;

						if (tab_items.length > 0) {

							for (var j = 0; j < tab_items.length; j++) {
								if (tab_items[j] == item_id) {
									item_found = true;
									break;
								}
							}

						}

						if (!item_found) {
							if (item_id.trim() != '') {
								tab_items[tab_items.length] = item_id;
							}
						}

					}

					frappe.call({  	method: method,
									args: {	"tp": 'DED',
											"prefix_id": silicon_ioi.doctype.ioiSalesPOS.doc.prefix_id,
											"items": tab_items,
											"badge_id": silicon_ioi.doctype.ioiSalesPOS.batchuser
									},
									async: false,
									callback:function(r)	{
										ret = r.message;
									}
					});

					silicon_ioi.doctype.ioiSalesPOS.value_max_value = 100;
					silicon_ioi.doctype.ioiSalesPOS.no_min_value = 0;

					if (ret.error == 1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, ret.error_msg);
						return false;
					}else{
						if (ret.value_access == 0) {
							silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You are not authorized to modify the detail extra discount"));
							return false;
						}else{
							silicon_ioi.doctype.ioiSalesPOS.value_max_value = parseFloat(ret.value_max_amount);
							silicon_ioi.doctype.ioiSalesPOS.no_min_value = ret.no_min_value;
						}
					}



					let min_value = 0;

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_base_discount) {
						min_value = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_base_discount);
					}

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_extra_discount) {

						if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_extra_discount) < parseFloat(min_value)) {
							min_value = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_extra_discount);
						}
					}

					if (silicon_ioi.doctype.ioiSalesPOS.no_min_value == 0) {

						if (parseFloat(s) < parseFloat(min_value)) {
							silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Min value allowed") + ': ' + parseFloat(min_value).toFixed(2));
							return false;
						}
					}

					if (parseFloat(s) > parseFloat(silicon_ioi.doctype.ioiSalesPOS.value_max_value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Max value allowed") + ': ' + parseFloat(silicon_ioi.doctype.ioiSalesPOS.value_max_value).toFixed(2));
						return false;
					}


					let old_value = 0;

					let discount = 0;

					let base_discount = 0;

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount != null) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount != 0)) {
						base_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount;
					}

					let extra_discount = 0;

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount != null) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount != 0)) {
						extra_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount;
					}


					if ((parseFloat(base_discount) != 0) && (parseFloat(extra_discount) != 0)) {

						discount = ((1 - (parseFloat(base_discount) / 100.00)) * (1 - (parseFloat(extra_discount) / 100.00))) * 100

					}else if (parseFloat(base_discount) != 0) {

						discount = parseFloat(base_discount);

					}else if (parseFloat(extra_discount) != 0) {

						discount = parseFloat(extra_discount);
					}



					if (!isNaN(discount)) {
						old_value = discount;
					}


					let new_value = parseFloat(s);

					if (parseFloat(old_value) != parseFloat(new_value)) {

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].sales_condition_id = silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id;

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].base_discount_source = '';
						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].extra_discount_source = '';


						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].base_discount = 0;
						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].extra_discount = parseFloat(new_value);
						document.getElementById('ioi_sales_pos_detail_table_detail_discount_'+idx).innerHTML = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].extra_discount.toFixed(2) + '&nbsp;%&nbsp;';


						silicon_ioi.doctype.ioiSalesPOS.compute_document();

						let unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate / 100.00));

						document.getElementById('ioi_sales_pos_detail_table_detail_unit_price_' + + idx.toString()).innerHTML = unit_price.toFixed(2) + '&nbsp;';

						let total_line = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate / 100.00));

						document.getElementById('ioi_sales_pos_detail_table_detail_total_' + idx.toString()).innerHTML = total_line.toFixed(2) + '&nbsp';

						if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
							silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
							silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
						}
					}
				}

			}else if (v.trim().toUpperCase().substring(0, 8) == '-SETITEM') {

				// ************************************************************
				// Edit item
				// ************************************************************

				obj.value = '';
				obj.focus();

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
					return false;
				}

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
					return false;
				}

				if (!document.getElementById('ioi_sales_pos_detail_table_detail')) {

					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
					return false;

				}

				let element = '';


				for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

					if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

						if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {
							element = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).id;
							break;
						}
					}
				}

				if (element == '') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
					return false;
				}

				while (element.indexOf('_') != -1) {
					element = element.substring(element.indexOf('_')+1, element.length);
				}

				let idx = parseInt(element);

				if (v.trim().toUpperCase() == '-SETITEM') {
					document.getElementById('ioi_sales_pos_item_edit_button_' + idx.toString()).click();
				}else{


					let s = v.trim().toUpperCase().substring(8, v.length).trim();

					let method = path_sales_pos + '.ioi_sales_pos_check_item'

					frappe.call({  	method: method,
									args: {"item_id" : s},
									async: false,
									callback:function(r)	{

										if (r.message == 0) {
											silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This item') + ' ' + s + ' ' + __('does not exist'));
											return false;

										}else {

											let old_item_id = '';

											if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id) {

												old_item_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id;
											}

											if (old_item_id != s) {

												silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id = s;

												silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_id = '';
												silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_code_ref = '';
												silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_origin = '';

												silicon_ioi.doctype.ioiSalesPOS.populate_line(idx, 'ITEM_id');
												silicon_ioi.doctype.ioiSalesPOS.populate_line_after(idx);


												if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
													silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
													silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
												}

											}

										}
									}
					});
				}



			}else if (v.trim().toUpperCase().substring(0, 11) == '-SETCODEREF') {

				// ************************************************************
				// Edit item
				// ************************************************************

				obj.value = '';
				obj.focus();

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
					return false;
				}

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
					return false;
				}

				if (!document.getElementById('ioi_sales_pos_detail_table_detail')) {

					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
					return false;

				}

				let element = '';


				for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

					if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

						if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {
							element = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).id;
							break;
						}
					}
				}

				if (element == '') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
					return false;
				}

				while (element.indexOf('_') != -1) {
					element = element.substring(element.indexOf('_')+1, element.length);
				}

				let idx = parseInt(element);

				if (v.trim().toUpperCase() == '-SETCODEREF') {
					document.getElementById('ioi_sales_pos_item_edit_button_' + idx.toString()).click();
				}else{


					let s = v.trim().toUpperCase().substring(11, v.length).trim();

					let old_code_ref = '';

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_code_ref) {

						old_code_ref = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_code_ref;
					}


					if (old_code_ref != s) {
						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_code_ref = s;
					}


					if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
						silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
						silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
					}
				}

			}else if (v.trim().toUpperCase().substring(0, 6) == '-VOUT-') {

				obj.value = '';
				obj.focus();

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
					return false;
				}

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
					return false;
				}

				let s = v.trim().toUpperCase().substring(6, v.length).trim();

				if (s.trim() == '') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('VOUT : missing item and code ref'));
					return false;
				}

				let item_id = '';
				let code_ref = '';

				if (s.indexOf('-') == -1) {
					item_id = s;
					s = ''
				}else{
					item_id = s.substring(0, s.indexOf('-'))
					s = s.substring(s.indexOf('-')+1, s.length)
				}

				if (s.trim() != '') {
					code_ref = s;
				}

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.currency_id.trim() == ''))) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('VOUT : document currency is missing'));
					return false;
				}

				let method = path_sales_pos + '.ioi_sales_pos_scan_voucher'

				let data = {}

				frappe.call({
					method: method,
					args: {
						"sens": 'OUT',
						"element" : s,
						"currency_id": silicon_ioi.doctype.ioiSalesPOS.doc.currency_id,
						"currency_inv_rate": silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate_inv,
						"item_id": item_id,
						"code_ref": code_ref
					},
					async: false,
					callback:function(r)	{
						data = r.message;
					}
				});

				if (data.error != 0) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, data.error_msg);
					return false;

				}


				silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-NEWLN' + data.item_id);

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(500).then(() => {

					let element = '';

					for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

						if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

							if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {
								element = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).id;
								break;
							}
						}
					}

					if (element == '') {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
						return false;
					}

					while (element.indexOf('_') != -1) {
						element = element.substring(element.indexOf('_')+1, element.length);
					}

					let idx = parseInt(element);

					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty = 1;
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_code_ref = data.code_ref;
					silicon_ioi.doctype.ioiSalesPOS.compute_document();
					silicon_ioi.doctype.ioiSalesPOS.save();

				});

			}else if (v.trim().toUpperCase().substring(0, 5) == '-VIN-') {

				obj.value = '';
				obj.focus();

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
					return false;
				}

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
					return false;
				}

				let s = v.trim().toUpperCase().substring(5, v.length).trim();

				if (s.trim() == '') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('VIN : missing code ref or SN'));
					return false;
				}

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.currency_id.trim() == ''))) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('VIN : document currency is missing'));
					return false;
				}


				let method = path_sales_pos + '.ioi_sales_pos_scan_voucher'

				let data = {}

				frappe.call({
					method: method,
					args: {
						"sens": 'IN',
						"element" : s,
						"currency_id": silicon_ioi.doctype.ioiSalesPOS.doc.currency_id,
						"currency_inv_rate": silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate_inv
					},
					async: false,
					callback:function(r)	{
						data = r.message;
					}
				});

				if (data.error != 0) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, data.error_msg);
					return false;

				}


				silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-NEWLN' + data.item_id);


				silicon_ioi.doctype.ioiSalesPOS.sleep_static(500).then(() => {

					let element = '';

					for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

						if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

							if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {
								element = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).id;
								break;
							}
						}
					}

					if (element == '') {
						element = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' +  parseInt(document.getElementById('ioi_sales_pos_detail_table_detail').rows.length-1).toString()).id;
					}

					while (element.indexOf('_') != -1) {
						element = element.substring(element.indexOf('_')+1, element.length);
					}
					let idx = parseInt(element);


					if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
						silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
						silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
					}

					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty = -1;

					document.getElementById('ioi_sales_pos_detail_table_detail_delivered_qty_' + idx.toString()).innerText = '-1';

					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].warehouse_id = data.warehouse_id;
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].warehouse_location_id = data.warehouse_location_id;
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_id = data.batch_sn_id;
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_code_ref = data.code_ref;
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_origin = data.origin;
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price = data.voucher_value_htva;
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_tvac = data.voucher_value_tvac;
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate = data.voucher_vat_rate;
					silicon_ioi.doctype.ioiSalesPOS.compute_document();
					silicon_ioi.doctype.ioiSalesPOS.save();

					silicon_ioi.doctype.ioiSalesPOS.sleep_static(500).then(() => {
						for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

							if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {
								document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = false;

								if (i == idx) {
									document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = true;
								}
							}
						}
					});

				});


			}else if (v.trim().toUpperCase().substring(0, 4) == '-MFD') {

				// ************************************************************
				// set value to master field
				// ************************************************************

				obj.value = '';
				obj.focus();

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
					return false;
				}

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
					return false;
				}


				if (v.trim().toUpperCase() == '-MFD') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-MFD-XXX-YYY)'));
					return false;
				}

				let s = v.trim().toUpperCase().substring(4, v.length).trim();

				if (s[0] != '-') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-MFD-XXX-YYY)'));
					return false;
				}

				s = s.substring(1, s.length);


				if (s.indexOf('-') == -1) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-MFD-XXX-YYY)'));
					return false;
				}

				let field = s.substring(0, s.indexOf('-'));
				let value = s.substring(s.indexOf('-')+1, s.length)

				silicon_ioi.doctype.ioiSalesPOS.header_field_set_value(field, value, v);



			}else if (v.trim().toUpperCase().substring(0, 4) == '-DFD') {

				// ************************************************************
				// set value to field in detail
				// ************************************************************

				obj.value = '';
				obj.focus();

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
					return false;
				}

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
					return false;
				}


				if (!document.getElementById('ioi_sales_pos_detail_table_detail')) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
					return false;
				}

				let element = '';


				for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

					if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

						if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {
							element = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).id;
							break;
						}
					}
				}

				if (element == '') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
					return false;
				}

				while (element.indexOf('_') != -1) {
					element = element.substring(element.indexOf('_')+1, element.length);
				}

				let idx = parseInt(element);


				if (v.trim().toUpperCase() == '-DFD') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-DFD-XXX-YYY)'));
					return false;
				}

				let s = v.trim().toUpperCase().substring(4, v.length).trim();

				if (s[0] != '-') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-DFD-XXX-YYY)'));
					return false;
				}

				s = s.substring(1, s.length);


				if (s.indexOf('-') == -1) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-DFD-XXX-YYY)'));
					return false;
				}

				let field = s.substring(0, s.indexOf('-'));
				let value = s.substring(s.indexOf('-')+1, s.length)

				silicon_ioi.doctype.ioiSalesPOS.detail_field_set_value(field, value, idx, v);



			}else if (v.trim().toUpperCase() == '-PAYDELIVER') {

				// ************************************************************
				// Pay & Deliver
				// ************************************************************

				obj.value = '';
				obj.focus();

				document.getElementById('ioi_sales_pos_pay_button_pay_deliver').click();

			}else if (v.trim().toUpperCase() == '-DELIVER') {

				// ************************************************************
				// Deliver (no pay)
				// ************************************************************

				obj.value = '';
				obj.focus();


				if (document.getElementById('ioi_sales_pos_pay_button_deliver').disabled) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Save the changes'));
					return false;
				}

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
					return false;
				}

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('The document has to be "in preparation"'));
					return false;
				}

				silicon_ioi.doctype.ioiSalesPOS.deliver(true);


			}else if (v.trim().toUpperCase() == '-PAYACTIVITY') {

				// ************************************************************
				// Payment activiy
				// ************************************************************

				obj.value = '';
				obj.focus();

				document.getElementById('ioi_sales_pos_pay_button_histo').click();

			}else if (v.trim().toUpperCase().substring(0, 8) == '-GETCASH') {

				// ************************************************************
				// Get cash
				// ************************************************************

				obj.value = '';
				obj.focus();

				if (v.trim().toUpperCase() == '-GETCASH') {

					document.getElementById('ioi_sales_pos_pay_button_get_cash').click();
				}else{

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
						return false;
					}

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('The document has to be "in preparation"'));
						return false;
					}


					if ((!silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id.trim() == ''))) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Customer is mandatory'));
						return false;

					}

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id.trim() == ''))) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Payment term is mandatory'));
						return false;
					}

					if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_cash_value_enabled != 1) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash value is disabled'));
						return false;

					}

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id.trim() == ''))) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash desk is mandatory before executing this action'));
						return false;
					}

					let meth = path_cash_desk + '.ioi_cash_desk_get_info';

					let current_cash_value = 0;
					let role_to_get_cash = '';
					let cashdesk_status = 0;

					frappe.call({  	method: meth,
									args: {"name": silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id},
									async: false,
									callback:function(r)	{

										if (r.message.length != 0) {
											current_cash_value = r.message[0].cash_value;
											role_to_get_cash = r.message[0].role_to_get_cash;
											cashdesk_status = r.message[0].ioistatus;

										}
									}
					});

					if (cashdesk_status != 1) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This cash desk is not open'));
						return false;

					}

					if (parseFloat(current_cash_value) == 0) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No enough cash in this cash desk'));
						return false;
					}


					let can_get_cash = false;

					if (frappe.user.name.toUpperCase() == 'ADMINISTRATOR') {
						can_get_cash = true;
					}else {
						if (role_to_get_cash != '') {
							if (frappe.user.has_role(role_to_get_cash)) {
								can_get_cash = true;
							}
						}
					}

					if (!can_get_cash) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You're not authorized to get cash from this cash desk"));
						return false;

					}


					let s = v.trim().toUpperCase().substring(8, v.length).trim();

					if (s.indexOf('-') == -1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-GETCASH-VALUE-REMARK-ISCASHBACK)'));
						return false;
					}

					s = s.substring(s.indexOf('-')+1, s.length);

					let value = s.substring(0, s.indexOf('-'));
					s = s.substring(s.indexOf('-')+1, s.length);


					if (s.indexOf('-') == -1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-GETCASH-VALUE-REMARK-ISCASHBACK)'));
						return false;
					}

					if (isNaN(value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Value is not a valid amount'));
						return false;

					}

					if (parseFloat(value) <= 0) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Value has to be greater than zero'));
						return false;

					}

					if (parseFloat(value) > parseFloat(current_cash_value)) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No enough cash in this cash desk'));
						return false;
					}


					let comment = s.substring(0, s.indexOf('-'));
					s = s.substring(s.indexOf('-')+1, s.length);

					if (comment.trim() == '') {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Remark is mandatory'));
						return false;

					}

					let iscashback = s;

					if ((iscashback != '0') && (iscashback != '1')) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('ISCASHBACK has to 0 or 1 ($-GETCASH-VALUE-REMARK-ISCASHBACK)'));
						return false;
					}


					let fct_callback_action = function() {

						if (silicon_ioi.doctype.ioiSalesPOS.state == '') {

							silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
							silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';

						}

						silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;


						if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) <= 0) {
							silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 2;
						}else{
							if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) >= parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac)) {
								silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;
							}else{
								silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 1;
							}
						}

						document.getElementById('ioi_sales_pos_save_button').click();

						document.getElementById('ioi_sales_last_scan').innerText = '';

						if (iscashback == '1') {
							silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Get cash : cash back to customer') + ' : ' + parseFloat(value).toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id);
						}else{
							silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Get cash') + ' : ' + parseFloat(value).toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id);
						}


					};


					let method = path_cash_desk + '.ioi_cash_desk_get_cash';

					frappe.call({  	method: method,
									args: { "name": silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
											"operation_type": 1,
											"doctype": 'ioi Sales Delivery',
											"doctype_name": silicon_ioi.doctype.ioiSalesPOS.doc.name,
											"document_value": silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac,
											"cash_value": value,
											"remark": comment,
											"is_cashback": iscashback},
									async: false,
									callback:function(r)	{

										if (r.message.error == 0) {

											fct_callback_action();

										}else
										{
											silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {
												silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_message);
											});

											return false;

										}
									}
					});

				}

			}else if (v.trim().toUpperCase().substring(0, 8) == '-PUTCASH') {

				// ************************************************************
				// Put cash
				// ************************************************************

				obj.value = '';
				obj.focus();

				if (v.trim().toUpperCase() == '-PUTCASH') {
					document.getElementById('ioi_sales_pos_pay_button_put_cash').click();
				}else{

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
						return false;
					}

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('The document has to be "in preparation"'));
						return false;
					}


					if ((!silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id.trim() == ''))) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Customer is mandatory'));
						return false;

					}

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id.trim() == ''))) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Payment term is mandatory'));
						return false;
					}

					if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_cash_value_enabled != 1) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash value is disabled'));
						return false;

					}

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id.trim() == ''))) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash desk is mandatory before executing this action'));
						return false;
					}

					let meth = path_cash_desk + '.ioi_cash_desk_get_info';

					let current_cash_value = 0;
					let role_to_get_cash = '';
					let cashdesk_status = 0;

					frappe.call({  	method: meth,
									args: {"name": silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id},
									async: false,
									callback:function(r)	{

										if (r.message.length != 0) {
											current_cash_value = r.message[0].cash_value;
											role_to_get_cash = r.message[0].role_to_get_cash;
											cashdesk_status = r.message[0].ioistatus;

										}
									}
					});

					if (cashdesk_status != 1) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This cash desk is not open'));
						return false;

					}


					let can_get_cash = false;

					if (frappe.user.name.toUpperCase() == 'ADMINISTRATOR') {
						can_get_cash = true;
					}else {
						if (role_to_get_cash != '') {
							if (frappe.user.has_role(role_to_get_cash)) {
								can_get_cash = true;
							}
						}
					}

					if (!can_get_cash) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You're not authorized to get cash from this cash desk"));
						return false;

					}


					let s = v.trim().toUpperCase().substring(8, v.length).trim();

					if (s.indexOf('-') == -1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-PUTCASH-VALUE-REMARK-ISRECEIVED)'));
						return false;
					}

					s = s.substring(s.indexOf('-')+1, s.length);

					let value = s.substring(0, s.indexOf('-'));
					s = s.substring(s.indexOf('-')+1, s.length);


					if (s.indexOf('-') == -1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-PUTCASH-VALUE-REMARK-ISRECEIVED)'));
						return false;
					}

					if (isNaN(value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Value is not a valid amount'));
						return false;

					}

					if (parseFloat(value) <= 0) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Value has to be greater than zero'));
						return false;

					}


					let comment = s.substring(0, s.indexOf('-'));
					s = s.substring(s.indexOf('-')+1, s.length);

					if (comment.trim() == '') {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Remark is mandatory'));
						return false;

					}

					let isreceived = s;

					if ((isreceived != '0') && (isreceived != '1')) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('ISRECEIVED has to 0 or 1 ($-GETCASH-VALUE-REMARK-ISRECEIVED)'));
						return false;
					}


					let fct_callback_action = function() {

						if (silicon_ioi.doctype.ioiSalesPOS.state == '') {

							silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
							silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';

						}

						silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;


						if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) <= 0) {
							silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 2;
						}else{
							if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) >= parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac)) {
								silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;
							}else{
								silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 1;
							}
						}

						document.getElementById('ioi_sales_pos_save_button').click();

						document.getElementById('ioi_sales_last_scan').innerText = '';

						if (isreceived == '1') {
							silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Put cash : received from customer') + ' : ' + parseFloat(value).toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id);
						}else{
							silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Put cash') + ' : ' + parseFloat(value).toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id);
						}


					};


					let method = path_cash_desk + '.ioi_cash_desk_put_cash';

					frappe.call({  	method: method,
									args: { "name": silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
											"operation_type": 1,
											"doctype": 'ioi Sales Delivery',
											"doctype_name": silicon_ioi.doctype.ioiSalesPOS.doc.name,
											"document_value": silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac,
											"cash_value": value,
											"remark": comment,
											"is_received": isreceived},
									async: false,
									callback:function(r)	{

										if (r.message.error == 0) {

											fct_callback_action();

										}else
										{
											silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {
												silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_message);
											});

											return false;

										}
									}
					});

				}


			}else if (v.trim().toUpperCase().substring(0, 13) == '-OPENCASHDESK') {

				// ************************************************************
				// Open cashdesk
				// ************************************************************

				obj.value = '';
				obj.focus();

				if (v.trim().toUpperCase() == '-OPENCASHDESK') {

					document.getElementById('ioi_sales_pos_pay_button_open_cash_desk').click();

				}else{


					if ((!silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.cashdesk_id.trim() == ''))) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, 'Cash desk is mandatory before executing this action');
						return false;
					}

					let meth = path_cash_desk + '.ioi_cash_desk_get_info';


					let role_to_open = '';
					let cashdesk_status = 0;

					frappe.call({  	method: meth,
									args: {"name": silicon_ioi.doctype.ioiSalesPOS.cashdesk_id},
									async: false,
									callback:function(r)	{

										if (r.message.length != 0) {
											role_to_open = r.message[0].role_to_open;
											cashdesk_status = r.message[0].ioistatus;

										}
									}
					});

					if (cashdesk_status == 1) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, 'This cash desk is already open');
						return false;
					}

					let can_open_cash_desk = false;

					if (frappe.user.name.toUpperCase() == 'ADMINISTRATOR') {
						can_open_cash_desk = true;
					}else {
						if (role_to_open != '') {
							if (frappe.user.has_role(role_to_open)) {
								can_open_cash_desk = true;
							}
						}
					}

					if (!can_open_cash_desk) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, "You're not authorized to open this cash desk");
						return false;

					}


					let s = v.trim().toUpperCase().substring(13, v.length).trim();

					if (s.indexOf('-') == -1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-OPENCASHDESK-VALUE)'));
						return false;
					}

					let value = s.substring(s.indexOf('-')+1, s.length);


					if (isNaN(value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Value is not a valid amount'));
						return false;

					}

					if (parseFloat(value) < 0) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Value has to be greater than or equal to zero'));
						return false;

					}


					let method = path_cash_desk + '.ioi_cash_desk_open';

					frappe.call({  	method: method,
									args: {"name": silicon_ioi.doctype.ioiSalesPOS.cashdesk_id, "cash_value": value},
									async: false,
									callback:function(r)	{

										if (r.message.error != 0)
										{
											silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_message);
										}else{
											silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('This cash desk is now open'));
										}
									}
					});

				}



			}else if (v.trim().toUpperCase().substring(0, 14) == '-CLOSECASHDESK') {

				// ************************************************************
				// Close cashdesk
				// ************************************************************

				obj.value = '';
				obj.focus();


				if (v.trim().toUpperCase() == '-CLOSECASHDESK') {

					document.getElementById('ioi_sales_pos_pay_button_close_cash_desk').click();

				}else{
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Not authorized by scan'));
					return false;



					if ((!silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.cashdesk_id.trim() == ''))) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Cash desk is mandatory before executing this action"));
						return false;
					}


					let meth = path_cash_desk + '.ioi_cash_desk_get_info';


					let role_to_close = '';
					let cashdesk_status = 0;


					frappe.call({  	method: meth,
									args: {"name": silicon_ioi.doctype.ioiSalesPOS.cashdesk_id},
									async: false,
									callback:function(r)	{

										if (r.message.length != 0) {
											role_to_close = r.message[0].role_to_close;
											cashdesk_status = r.message[0].ioistatus;
										}
									}
					});

					if (cashdesk_status == 0) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("This cash desk is disabled"));
						return false;
					}

					if (cashdesk_status == 2) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("This cash desk is already closed"));
						return false;
					}

					let can_close_cash_desk = false;

					if (frappe.user.name.toUpperCase() == 'ADMINISTRATOR') {
						can_close_cash_desk = true;
					}else {
						if (role_to_close != '') {
							if (frappe.user.has_role(role_to_close)) {
								can_close_cash_desk = true;
							}
						}
					}

					if (!can_close_cash_desk) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You're not authorized to close this cash desk"));
						return false;
					}




					let s = v.trim().toUpperCase().substring(14, v.length).trim();

					if (s[0] != '-') {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-CLOSECASHDESK-CASH-BANK-CREDIT-CHECK-EMONEY-CHECKSP1-CHECKSP2-CHECKSP3)'));
						return false;
					}

					s = s.substring(1, s.length);

					if (s.indexOf('-') == -1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-CLOSECASHDESK-CASH-BANK-CREDIT-CHECK-EMONEY-CHECKSP1-CHECKSP2-CHECKSP3)'));
						return false;
					}

					let cash_value = s.substring(0, s.indexOf('-'));

					s = s.substring(s.indexOf('-')+1, s.length);


					if (isNaN(cash_value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash value is not a valid amount'));
						return false;

					}

					if (parseFloat(cash_value) < 0) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash value has to be greater than or equal to zero'));
						return false;
					}



					if (s.indexOf('-') == -1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-CLOSECASHDESK-CASH-BANK-CREDIT-CHECK-EMONEY-CHECKSP1-CHECKSP2-CHECKSP3)'));
						return false;
					}


					let bank_value = s.substring(0, s.indexOf('-'));

					s = s.substring(s.indexOf('-')+1, s.length);


					if (isNaN(bank_value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Bank value is not a valid amount'));
						return false;

					}

					if (parseFloat(bank_value) < 0) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Bank value has to be greater than or equal to zero'));
						return false;

					}


					if (s.indexOf('-') == -1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-CLOSECASHDESK-CASH-BANK-CREDIT-CHECK-EMONEY-CHECKSP1-CHECKSP2-CHECKSP3)'));
						return false;
					}


					let credit_value = s.substring(0, s.indexOf('-'));

					s = s.substring(s.indexOf('-')+1, s.length);


					if (isNaN(credit_value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Credit value is not a valid amount'));
						return false;

					}

					if (parseFloat(credit_value) < 0) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Credit value has to be greater than or equal to zero'));
						return false;

					}



					if (s.indexOf('-') == -1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-CLOSECASHDESK-CASH-BANK-CREDIT-CHECK-EMONEY-CHECKSP1-CHECKSP2-CHECKSP3)'));
						return false;
					}


					let check_value = s.substring(0, s.indexOf('-'));

					s = s.substring(s.indexOf('-')+1, s.length);


					if (isNaN(check_value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Check value is not a valid amount'));
						return false;

					}

					if (parseFloat(check_value) < 0) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Check value has to be greater than or equal to zero'));
						return false;

					}


					if (s.indexOf('-') == -1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-CLOSECASHDESK-CASH-BANK-CREDIT-CHECK-EMONEY-CHECKSP1-CHECKSP2-CHECKSP3)'));
						return false;
					}


					let paypal_value = s.substring(0, s.indexOf('-'));

					s = s.substring(s.indexOf('-')+1, s.length);


					if (isNaN(paypal_value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('PayPal value is not a valid amount'));
						return false;

					}

					if (parseFloat(paypal_value) < 0) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('PayPal value has to be greater than or equal to zero'));
						return false;

					}


					if (s.indexOf('-') == -1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-CLOSECASHDESK-CASH-BANK-CREDIT-CHECK-PAYPAL-EMONEY-CHECKSP1-CHECKSP2-CHECKSP3)'));
						return false;
					}




					let emoney_value = s.substring(0, s.indexOf('-'));

					s = s.substring(s.indexOf('-')+1, s.length);


					if (isNaN(emoney_value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Emoney value is not a valid amount'));
						return false;

					}

					if (parseFloat(emoney_value) < 0) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Emoney value has to be greater than or equal to zero'));
						return false;

					}


					if (s.indexOf('-') == -1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-CLOSECASHDESK-CASH-BANK-CREDIT-CHECK-EMONEY-CHECKSP1-CHECKSP2-CHECKSP3)'));
						return false;
					}


					let checksp1_value = s.substring(0, s.indexOf('-'));

					s = s.substring(s.indexOf('-')+1, s.length);


					if (isNaN(checksp1_value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Check spec 1 value is not a valid amount'));
						return false;

					}

					if (parseFloat(checksp1_value) < 0) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Check spec 1 value has to be greater than or equal to zero'));
						return false;

					}



					if (s.indexOf('-') == -1) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-CLOSECASHDESK-CASH-BANK-CREDIT-CHECK-EMONEY-CHECKSP1-CHECKSP2-CHECKSP3)'));
						return false;
					}


					let checksp2_value = s.substring(0, s.indexOf('-'));

					s = s.substring(s.indexOf('-')+1, s.length);


					if (isNaN(checksp2_value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Check spec 2 value is not a valid amount'));
						return false;

					}

					if (parseFloat(checksp2_value) < 0) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Check spec 2 value has to be greater than or equal to zero'));
						return false;

					}



					let checksp3_value = s;


					if (isNaN(checksp3_value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Check spec 3 value is not a valid amount'));
						return false;

					}

					if (parseFloat(checksp3_value) < 0) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Check spec 3 value has to be greater than or equal to zero'));
						return false;

					}


					let method = path_cash_desk + '.ioi_cash_desk_close';

					frappe.call({  	method: method,
									args: {	"name": silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
											"new_cash_value": cash_value,
											"new_bank_card_value": bank_value,
											"new_credit_card_value": credit_value,
											"new_check_value": check_value,
											"new_paypal°value": paypal_value,
											"new_emoney_value": emoney_value,
											"new_check_spec1_value": checksp1_value,
											"new_check_spec2_value": checksp2_value,
											"new_check_spec3_value": checksp3_value
									},
									async: false,
									callback:function(r)	{

										if (r.message.error != 0)
										{
											silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_message);
											return false;
										}else{
											silicon_ioi.doctype.ioiSalesPOS.add_log(0, __("This cashdesk is closed"));
											return false;
										}
									}
					});

				}
			}else if (v.trim().toUpperCase().substring(0, 8) == '-PAYCASH') {

				// ************************************************************
				// Pay cash
				// ************************************************************

				obj.value = '';
				obj.focus();

				if (v.trim().toUpperCase() == '-PAYCASH') {
					document.getElementById('ioi_sales_pos_pay_button_pay_deliver').click();
				}else{

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
						return false;
					}


					if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('The document has to be "in preparation"'));
						return false;
					}


					if ((!silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id.trim() == ''))) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Customer is mandatory'));
						return false;

					}

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id.trim() == ''))) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Payment term is mandatory'));
						return false;
					}

					if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_cash_value_enabled != 1) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash value is disabled'));
						return false;

					}

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id.trim() == ''))) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash desk is mandatory before executing this action'));
						return false;
					}

					if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac) <= 0) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Total incl. VAT has to be greater than zero'));
						return false;

					}

					if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == 2) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Payment is already closed'));
						return false;

					}



					let meth = path_cash_desk + '.ioi_cash_desk_get_info';

					let cashdesk_status = 0;

					frappe.call({  	method: meth,
									args: {"name": silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id},
									async: false,
									callback:function(r)	{

										if (r.message.length != 0) {
											cashdesk_status = r.message[0].ioistatus;
										}
									}
					});

					if (cashdesk_status != 1) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This cash desk is not open'));
						return false;

					}


					if (!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_cash_value_enabled) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Pay cash is disabled'));
						return false;
					}


					let s = v.trim().toUpperCase().substring(8, v.length)



					if (s[0] != '-') {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid format ($-PAYCASH-VALUE)'));
						return false;

					}

					let value = s.substring(1, s.length);

					if (isNaN(value)) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Value is not a valid amount'));
						return false;

					}

					if (parseFloat(value) <= 0) {
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Value has to be greater than zero'));
						return false;
					}


					let still_to_pay = 0;

					if (document.getElementById('ioi_sales_pos_pay_still_to_pay').innerText.indexOf(' ') != -1)
					{
						let temp = document.getElementById('ioi_sales_pos_pay_still_to_pay').innerText.substring(0, document.getElementById('ioi_sales_pos_pay_still_to_pay').innerText.indexOf(' '));
						temp = temp.trim()

						if (!isNaN(temp)) {
							still_to_pay = parseFloat(temp);
						}
					}

					let cashback = 0;

					if (parseFloat(still_to_pay) - parseFloat(value) < 0) {

						cashback = (parseFloat(still_to_pay) - parseFloat(value)) * -1.00;
					}

					let fct_callback_action = function() {

						if (silicon_ioi.doctype.ioiSalesPOS.state == '') {

							silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
							silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';

						}

						silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;


						if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) <= 0) {
							silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 2;
						}else{
							if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) >= parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac)) {
								silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;
							}else{
								silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 1;
							}
						}

						document.getElementById('ioi_sales_pos_save_button').click();

						document.getElementById('ioi_sales_last_scan').innerText = '';

						let msg =  __('Pay cash') + ' : ' + parseFloat(value).toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id;

						if (parseFloat(cashback) != 0) {
							msg += ', ' + __("cashback to the customer") + ' ' + parseFloat(cashback).toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id;
						}

						silicon_ioi.doctype.ioiSalesPOS.add_log(0, msg);

					};

					let method = path_cash_desk_movement + '.ioi_cash_desk_movement_generate_payments';

					frappe.call({  	method: method,
									args: {	"cashdesk_id": silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
											"doctype": 'ioi Sales Delivery',
											"doctype_name": silicon_ioi.doctype.ioiSalesPOS.doc.name,
											"site_id": silicon_ioi.doctype.ioiSalesPOS.doc.site_id,
											"division_id": silicon_ioi.doctype.ioiSalesPOS.doc.division_id,
											"currency_id": silicon_ioi.doctype.ioiSalesPOS.doc.currency_id,
											"currency_rate": silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate,
											"currency_inv_rate": silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate_inv,
											"document_value" : silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac,
											"cash_value": value,
											"bank_card_value": 0,
											"bank_transaction_id": '',
											"bank_transaction_detail": '',
											"credit_card_value": 0,
											"credit_transaction_id": '',
											"credit_transaction_detail": 0,
											"check_value": 0,
											"check_id": '',
											"emoney_value": 0,
											"emoney_transaction_id": '',
											"emoney_transaction_detail": '',
											"check_spec_1_value": 0,
											"check_spec_1_description": '',
											"check_spec_1_id": '',
											"spec1_transaction_id": '',
											"spec1_transaction_detail": '',
											"check_spec_2_value": 0,
											"check_spec_2_description": '',
											"check_spec_2_id": '',
											"spec2_transaction_id": '',
											"spec2_transaction_detail": '',
											"check_spec_3_value": 0,
											"check_spec_3_description": '',
											"check_spec_3_id": '',
											"spec3_transaction_id": '',
											"spec3_transaction_detail": '',
											"cash_back_value" : cashback,
											"bank_card_back_value": 0,
											"emoney_back_value": 0
										},
									async: false,
									callback:function(r)	{

										if (r.message.error == 0) {

											fct_callback_action();


										}else
										{
											silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_message);
											return false;

										}
									}
					});


				}

			}else if (v.trim().toUpperCase().substring(0, 4) == '-GET') {

				// ************************************************************
				// Get document
				// ************************************************************

				obj.value = '';
				obj.focus();

				if (document.getElementById('ioi_sales_pos_next_button').disabled) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Save the changes'));
					return false;
				}


				if ((!silicon_ioi.doctype.ioiSalesPOS.journal_id) || ((silicon_ioi.doctype.ioiSalesPOS.journal_id) && (silicon_ioi.doctype.ioiSalesPOS.journal_id == ''))) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select the journal in the parameters'));
					return false;
				}


				if ((!silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.cashdesk_id == ''))) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select the cash desh id in the parameters'));
					return false;
				}

				if (v.trim().toUpperCase() == '-GET') {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document number is missing ( ex : $-GETPREF/123456)'));
					return false;
				}

				let s = v.trim().toUpperCase().substring(4, v.length).trim();

				s = s.replaceAll('/', ' • ');


				let method = path_sales_pos + '.ioi_sales_pos_get_document_id';

				let error = 0;
				let error_msg = '';
				let delivery_doc = ''


				frappe.call({  	method: method,
								args: { "name": s,
										"journal_id" : silicon_ioi.doctype.ioiSalesPOS.journal_id,
										"cashdesk_id" : silicon_ioi.doctype.ioiSalesPOS.cashdesk_id},
								async: false,
								callback:function(r)	{

									error = r.message.error;
									error_msg = r.message.error_msg;
									delivery_doc = r.message.doc;
								}
				});

				if (error == 1) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, error_msg);
					return false;

				}

				silicon_ioi.doctype.ioiSalesPOS.doc = delivery_doc;
				silicon_ioi.doctype.ioiSalesPOS.refresh_screen();

			}else{
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This function does not exist'));
				return false;
			}



		} else {

			// ************************************************************
			// User, Batch CashDesk ?
			// ************************************************************

			let s = v_lower;

			let decrypted_value = '';

			for (var i = 0; i < s.length; i++) {

				if ((s[i] == '0') || (s[i] == 'à') || (s[i] == 'À')) {
					decrypted_value += '0';
				}else if ((s[i] == '1') || (s[i] == '&')) {
					decrypted_value += '1';
				}else if ((s[i] == '2') || (s[i] == 'é') || (s[i] == 'É')) {
					decrypted_value += '2';
				}else if ((s[i] == '3') || (s[i] == '"')) {
					decrypted_value += '3';
				}else if ((s[i] == '4') || (s[i] == '\'')) {
					decrypted_value += '4';
				}else if ((s[i] == '5') || (s[i] == '(')) {
					decrypted_value += '5';
				}else if ((s[i] == '6') || (s[i] == '§')) {
					decrypted_value += '6';
				}else if ((s[i] == '7') || (s[i] == 'è') || (s[i] == 'È')) {
					decrypted_value += '7';
				}else if ((s[i] == '8') || (s[i] == '!')) {
					decrypted_value += '8';
				}else if ((s[i] == '9') || (s[i] == 'ç') || (s[i] == 'Ç')) {
					decrypted_value += '9';
				}else{
					decrypted_value += s[i];
				}


			}

			let is_item = false;

			let method = path_sales_pos + '.ioi_sales_pos_check_userbadge_or_pwd';

			frappe.call({
				method: method,
				args: {
					"value": v_lower,
					"batchvalue" : decrypted_value,
				},
				async: false,
				callback: function (r) {

					if (r.message.found == 1) {

						if (r.message.batchuser != '') {
							silicon_ioi.doctype.ioiSalesPOS.batchuser = r.message.batchuser;
							silicon_ioi.doctype.ioiSalesPOS.batchuser_userid = r.message.batchuser_userid;
							silicon_ioi.doctype.ioiSalesPOS.batchuser_name = r.message.batchuser_name;
						}


						silicon_ioi.doctype.ioiSalesPOS.lock_screen();

					}else{

						is_item = true;

					}


				},
			});

			if (is_item)
			{
				let char_allowed = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 -$%./+*?';


				for (var i = 0; i < v.length; i++) {

					if (char_allowed.indexOf(v[i]) == -1) {

						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid char in scanned value (code 39)'));
						return false;

					}
				}


				// ************************************************************
				// Id or barcode ref ?
				// ************************************************************

				let create_new = false;

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

					create_new = true;
				}

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

					create_new = true;
				}

				if (create_new) {
					document.getElementById('ioi_sales_pos_new_button').click();
				}


				let amethod = 'silicon_ioi.ioi_configuration.doctype.ioi_scan_settings.ioi_scan_settings.ioi_scan_settings_find_element';

				let element_found = false;

				let tp = 'SALES';

				let	error = 0;
				let	error_msg = '';
				let what = '';
				let pk = '';
				let duplicate = '';
				let	destination_detail = '';
				let	destination_field = '';
				let	qt_scan = 1;
				let	scan_mode = 0;

				let price_select_type = '';
				let price_catalog_customer_supplier_id = '';
				let price_item_id = '';
				let price_condition_id = '';
				let price_unit_id = '';
				let price_currency_id = '';

				let batch_sn_id = '';
				let batch_sn_item_id = '';
				let batch_sn_code_ref = '';
				let batch_sn_origin = '';
				let qcref = '';





				frappe.call({
					method: amethod,
					args: { "doctype": 'ioi Sales Delivery', "element": v, "tp" : tp},
					async: false,
					callback: function (r) {



						if (r.message) {

							element_found = true;
							error = r.message.error;
							error_msg = r.message.error_msg;
							what = r.message.what;
							pk = r.message.pk;
							duplicate = r.message.duplicate;
							destination_detail = r.message.destination_detail;
							destination_field = r.message.destination_field;
							qt_scan = r.message.qt_scan;
							scan_mode = r.message.scan_mode;

							price_select_type = r.message.price_select_type;
							price_catalog_customer_supplier_id = r.message.price_catalog_customer_supplier_id;
							price_item_id = r.message.price_item_id;
							price_condition_id = r.message.price_condition_id;
							price_unit_id = r.message.price_unit_id;
							price_currency_id = r.message.price_currency_id;

							batch_sn_id = r.message.batch_sn_id;
							batch_sn_item_id = r.message.batch_sn_item_id;
							batch_sn_code_ref = r.message.batch_sn_code_ref;
							batch_sn_origin = r.message.batch_sn_origin;
							qcref = r.message.qcref;



						}
					}
				});



				if (element_found)
				{
					if (error == 1)
					{
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __(error_msg));
						return false;
					}

					if (duplicate == "Y")
					{
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Many records found !"));
						return false;
					}


					if ((what == 'BARCODE_REFERENCE') || (what == 'BARCODE_BOX_REFERENCE') || (what == 'BARCODE_PALLET_REFERENCE') || (what == 'ITEM') || (what == 'SALES_PRICE') || (what == 'PURCHASES_PRICE'))
					{
						if (what == 'SALES_PRICE')
						{
							if (price_select_type.toUpperCase() == 'CATALOG')
							{
								if (price_catalog_customer_supplier_id != silicon_ioi.doctype.ioiSalesPOS.doc.sales_catalog_id)
								{
									silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Sales price : no corresponding catalog !"));
									return false;

								}
							}else
							{
								if (price_catalog_customer_supplier_id != silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_id)
								{
									silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Sales price : no corresponding customer !"));
									return false;

								}
							}

						}else if (what == 'PURCHASES_PRICE')
						{
							silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Purchases price are not applicable here !"));
							return false;
						}



						if (destination_detail.trim() != '')
						{
							silicon_ioi.doctype.ioiSalesPOS.scan_item_or_price( what, pk, destination_field, qt_scan, scan_mode, price_currency_id);


						}else
						{
							silicon_ioi.doctype.ioiSalesPOS.doc[destination_field] = pk;

							if (silicon_ioi.doctype.ioiSalesPOS.state == '') {

								silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
								silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';

							}
						}


					}else if ((what == 'BATCH') || (what == 'SN'))
					{

						if (what == 'BATCH')
						{
							silicon_ioi.doctype.ioiSalesPOS.scan_batch(what, pk, batch_sn_item_id, batch_sn_id, scan_mode, v);
						}else
						{
							silicon_ioi.doctype.ioiSalesPOS.scan_sn(what, pk, batch_sn_item_id, batch_sn_id, scan_mode);
						}
					}
				}else
				{
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid scanned value'));
					return false;
				}

			}
		}



		obj.focus();
	}


	static scan_item_or_price(what, pk, destination_field, qt_scan, scan_mode, price_currency_id)
	{

		let create_new = false;

		if (!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {
			create_new = true;
		}else if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length == 0) {
			create_new = true;
		}else{
			if (scan_mode == 1)
			{
				create_new = true;
			}else
			{

				let found_in_line = false;

				for (var z = 0; z < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; z++) {

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[z][destination_field] == pk) {
						found_in_line = true;
						break;
					}
				}

				if (found_in_line) {
					create_new = false;
				}else{
					if ((what == 'SALES_PRICE') || (what == 'PURCHASES_PRICE'))
					{

						if (price_condition_id != silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length-1]['sales_condition_id'])
						{
							create_new = true;

						}else if (price_unit_id != silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length-1]['unit_id'])
						{
							create_new = true;
						}

					}

				}

			}
		}



		if ((price_currency_id) && (price_currency_id.trim() != ''))
		{
			if (price_currency_id != silicon_ioi.doctype.ioiSalesPOS.doc.currency_id)
			{
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Price currency is not applicable here !"));
				return false;
			}
		}



		if (!create_new)
		{
			create_new = true;
			let can_update_detail = false;
			let current_idx = -1;

			for(var j = 0; j < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; j++)
			{
				if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].item_id.trim() != ''))
				{
					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].item_id == pk)
					{
						current_idx = j;
						create_new = false;
						break;
					}
				}
			}

			can_update_detail = true;

			let qtyfield = 'delivered_qty';


			if (create_new)
			{

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id.trim() == '')))
				{
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Fill the warehouse, please"));
					return false;

				}

				silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-NEWLN' + pk);

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(500).then(() => {

					silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-SETQTY' + qt_scan);
				});



			}else
			{
				silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-SETLN' + (current_idx+1));

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(500).then(() => {

					silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-INCQTY' + qt_scan);
				});
			}

		}else{


			if ((!silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id.trim() == '')))
			{
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Fill the warehouse, please"));
				return false;

			}

			silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-NEWLN' + pk);

			silicon_ioi.doctype.ioiSalesPOS.sleep_static(500).then(() => {

				silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-SETQTY' + qt_scan);
			});




		}
	}

	static scan_batch(what, pk, batch_sn_item_id, batch_sn_id, scan_mode, code_ref)
	{

		// Check site
		let found_in_current_site = 0;

		let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
		let m = pathbatchlocation + '.ioi_batch_sn_in_current_site';

		frappe.call({
			method: m,
			args: { "item_id": batch_sn_item_id, "batch_sn_id": batch_sn_id},
			async: false,
			callback: function (r) {

				found_in_current_site = r.message.found;
			}
		});

		if (found_in_current_site == 0)
		{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("This batch/sn can not be used in this site"));
			return false;

		}

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length == 0)))
		{
			if ((!silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id.trim() == '')))
			{

				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Fill the warehouse, please"));
				return false;

			}
		}


		let batchsn = [];

		m = pathbatchlocation + '.ioi_batch_sn_get_data';


		frappe.call({
			method: m,
			args: {"item_id": batch_sn_item_id, "batch_sn_id": code_ref, "is_batch": 'Y'},
			async: false,
			callback: function (r) {

				batchsn = [];

				for (var i = 0; i < r.message.length; i++)
				{
					batchsn[i] = [r.message[i].item_id, r.message[i].warehouse_id, r.message[i].location_id, r.message[i].batch_sn_id, r.message[i].code_ref, r.message[i].code_origin, r.message[i].qty, 0, r.message[i].qty, r.message[i].qcref, r.message[i].voucher];

				}
			}
		});


		if (batchsn.length == 0)
		{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("This batch has no stock"));
			return false;
		}

		let i = 0;

		let can_create = true;

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {
			for (i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].order_master_link) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].order_master_link != '')) {
					can_create = false;
					break;
				}
			}
		}

		if (!can_create) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Can not add new line (Linked to order)"));
			return false;

		}

		if (can_create) {

			for (var j = 0; j < batchsn.length; j++)
			{
				let qty = batchsn[j][8];
				let bitem = batchsn[j][0];
				let bwarehouse = batchsn[j][1];
				let blocation = batchsn[j][2];
				let bbatch =  batchsn[j][3];

				let fieldwhs = 'warehouse_id';
				let fieldloc = 'warehouse_location_id';

				silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-NEWLN' + bitem);

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(500).then(() => {

					silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-SETQTY1');


					silicon_ioi.ioiScanning.sleep_static(200).then(() => {

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length-1][fieldwhs] = bwarehouse;
						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length-1][fieldloc] = blocation;
						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length-1]['batch_sn_id'] = bbatch;

					});
				});

			}
		}
	}


	static scan_sn(what, pk, batch_sn_item_id, batch_sn_id, scan_mode)
	{
		// Check site
		let found_in_current_site = 0;

		let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
		let m = pathbatchlocation + '.ioi_batch_sn_in_current_site';

		frappe.call({
			method: m,
			args: { "item_id": batch_sn_item_id, "batch_sn_id": batch_sn_id},
			async: false,
			callback: function (r) {

				found_in_current_site = r.message.found;
			}
		});

		if (found_in_current_site == 0)
		{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("This batch/sn can not be used in this site."));
			return false;
		}

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length == 0)))
		{
			if ((!silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id.trim() == '')))
			{
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Fill the warehouse, please"));
				return false;

			}

		}



		let batchsn = [];

		m = pathbatchlocation + '.ioi_batch_sn_get_data';


		frappe.call({
			method: m,
			args: {"item_id": batch_sn_item_id, "batch_sn_id": batch_sn_id},
			async: false,
			callback: function (r) {

				batchsn = [];

				for (var i = 0; i < r.message.length; i++)
				{
					batchsn[i] = [r.message[i].item_id, r.message[i].warehouse_id, r.message[i].location_id, r.message[i].code_ref, r.message[i].code_origin, r.message[i].qty, r.message[i].qcref, r.message[i].voucher];

				}
			}
		});


		if (batchsn.length == 0)
		{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("This SN has no stock"));
			return false;
		}

		let create_new = true;
		let current_warehouse = '';
		let current_location = '';
		let current_idx = -1;
		let can_update_detail = false;
		let voucher = batchsn[0][7];



		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length == 0)))
		{
			create_new = true;

		}else
		{

			for(var j = 0; j < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; j++)
			{
				if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].item_id.trim() != ''))
				{
					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].item_id == batchsn[0][0])
					{
						if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.batch_sn_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].batch_sn_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].batch_sn_id == '')))
						{

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].warehouse_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].warehouse_id != ''))
							{
								current_warehouse = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].warehouse_id;

								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].warehouse_location_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].warehouse_location_id != ''))
								{
									current_location = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].warehouse_location_id;
								}

							}else
							{
								silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Fill the warehouse, please"));
								return false;
							}


							let doc_qty = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[j].delivered_qty;

							if (voucher == 0) {

								if (doc_qty >= 1)  {
									silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("Quantity has to be 0 or 1"));
									return false;
								}
							}

							current_idx = j;
							create_new = false;
							break;
						}
					}
				}
			}
		}

		if (!create_new)
		{
			if (current_warehouse != '')
			{
				if (current_warehouse != batchsn[0][1])
				{
					create_new = true;
					can_update_detail = false;
				}else
				{
					if (current_location != '')
					{
						if (current_location != batchsn[0][2])
						{
							create_new = true;
							can_update_detail = false;
						}
					}
				}
			}
		}



		can_update_detail = true;

		if (can_update_detail)
		{

			let qtyfield = 'delivered_qty';

			if (create_new)
			{
				let current_item = batchsn[0][0];
				let current_warehouse = batchsn[0][1];
				let current_location = batchsn[0][2];
				let current_code_ref = batchsn[0][3];
				let current_origin = batchsn[0][4];
				let current_qcref = batchsn[0][6];



				let qty = batchsn[0][5];

				silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-NEWLN' + current_item);

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(500).then(() => {

					silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-SETLN' + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length));

					silicon_ioi.doctype.ioiSalesPOS.sleep_static(300).then(() => {

						if (voucher == 0) {
							silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-SETQTY1');
						}else{

							silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-SETQTY-1');
						}


						silicon_ioi.ioiScanning.sleep_static(200).then(() => {

							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length-1]['warehouse_id'] = current_warehouse;
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length-1]['warehouse_location_id'] = current_location;
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length-1]['batch_sn_id'] = batch_sn_id;
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length-1]['batch_sn_code_ref'] = current_code_ref;
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length-1]['batch_sn_origin'] = current_origin;
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length-1]['qcsupplier_ref'] = current_qcref;
						});
					});
				});
			}else
			{


				silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-SETLN' + (current_idx+1));

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(500).then(() => {

					if (voucher == 0) {
						silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-INCQTY1');
					}else{
						silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-SETQTY-1');
					}
				});

			}
		}
	}



	static header_field_set_value(field, value, scan_value)
	{
		let fn = field;
		let v = value;

		v = v.replaceAll('-', ' • ');

		let method = 'silicon_ioi.ioi_configuration.doctype.ioi_scan_settings.ioi_scan_settings.ioi_scan_settings_get_field';

		let fieldname = '';
		let fieldtype = '';

		frappe.call({
			method: method,
			args: { "doctype": 'ioi Sales Delivery', "initials": fn },
			async: false,
			callback: function (r) {

				if (r.message.length != 0)
				{
					fieldname = r.message[0].fieldname;
					fieldtype = r.message[0].fieldtype;
				}

			}
		});

		if (fieldname.trim() == '')
		{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Initials not found in ioi Scan Settings (ioi Sales Delivery)'));
			return false;

		}

		if ((fieldtype == 'CURRENCY') || (fieldtype == 'FLOAT') || (fieldtype == 'PERCENT'))
		{
			v = v.trim();

			let q = parseFloat(v);

			if (isNaN(q)) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid number'));
				return;
			}


		}else if (fieldtype == 'INT')
		{
			v = v.trim();

			let q = parseInt(v);

			if (isNaN(q)) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid integer'));
				return;
			}

		}else if (fieldtype == 'DATE')
		{

		}else if (fieldtype == 'DATETIME')
		{

		}else if (fieldtype == 'TIME')
		{

		}else if (fieldtype == 'DURATION')
		{

		}else if (fieldtype == 'CHECK')
		{
			v = v.trim();

			if ((v != "0") && (v != "1")) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Value has to be 0 or 1'));
				return;
			}

		}

		if ((fieldname.toUpperCase() == 'NAME') 			 			|| (fieldname.toUpperCase() == 'IOISTATUS') 					 || (fieldname.toUpperCase() == 'DOCUMENT_DATE') ||
			(fieldname.toUpperCase() == 'ORDER_CUSTOMER_ID') 			|| (fieldname.toUpperCase() == 'INVOICE_CUSTOMER_ID') 	 		 ||
			(fieldname.toUpperCase() == 'ORDER_CUSTOMER_NAME') 			|| (fieldname.toUpperCase() == 'DELIVERY_CUSTOMER_NAME')		 || (fieldname.toUpperCase() == 'INVOICE_CUSTOMER_NAME') ||
			(fieldname.toUpperCase() == 'ORDER_CUSTOMER_CONTACT_ID') 	|| (fieldname.toUpperCase() == 'INVOICE_CUSTOMER_CONTACT_ID') 	 ||
			(fieldname.toUpperCase() == 'ORDER_CUSTOMER_CONTACT_NAME') 	|| (fieldname.toUpperCase() == 'DELIVERY_CUSTOMER_CONTACT_NAME') || (fieldname.toUpperCase() == 'INVOICE_CUSTOMER_CONTACT_NAME') ||
			(fieldname.toUpperCase() == 'ORDER_CUSTOMER_CONTACT_MAIL') 	|| (fieldname.toUpperCase() == 'DELIVERY_CUSTOMER_CONTACT_MAIL') || (fieldname.toUpperCase() == 'INVOICE_CUSTOMER_CONTACT_MAIL')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This field can not be changed'));
			return;

		}

		silicon_ioi.doctype.ioiSalesPOS.doc[fieldname] = v;


		if (fieldname.toUpperCase() == 'DELIVERY_CUSTOMER_ID') {

			document.getElementById('ioi_sales_pos_customer_id').value = v;
			silicon_ioi.doctype.ioiSalesPOS.select_delivery_customer_id(v);
			silicon_ioi.doctype.ioiSalesPOS.compute_document();

		}

		if (fieldname.toUpperCase() == 'BASE_DISCOUNT') {

			silicon_ioi.doctype.ioiSalesPOS.doc.base_discount = parseFloat(v);
			document.getElementById('ioi_sales_pos_base_discount').value = silicon_ioi.doctype.ioiSalesPOS.doc.base_discount;
			silicon_ioi.doctype.ioiSalesPOS.compute_document();
		}

		if (fieldname.toUpperCase() == 'EXTRA_DISCOUNT') {

			silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount = parseFloat(v);
			document.getElementById('ioi_sales_pos_extra_discount').value = silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount;
			silicon_ioi.doctype.ioiSalesPOS.compute_document();
		}

		if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
			silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
			silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
		}
	}


	static detail_field_set_value(field, value, idx, scan_value)
	{
		let fn = field;
		let v = value;


		let method = 'silicon_ioi.ioi_configuration.doctype.ioi_scan_settings.ioi_scan_settings.ioi_scan_settings_scan_field_detail';

		let fieldname = '';
		let fieldtype = '';

		frappe.call({
			method: method,
			args: { "doctype": 'ioi Sales Delivery', "detail": 'line_detail', "initials": fn },
			async: false,
			callback: function (r) {

				if (r.message.length != 0)
				{
					fieldname = r.message[0].fieldname;
					fieldtype = r.message[0].fieldtype;
				}

			}
		});



		if (fieldname.trim() == '')
		{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Initials not found in ioi Scan Settings (ioi Sales Delivery)'));
			return false;
		}


		if ((fieldtype == 'CURRENCY') || (fieldtype == 'FLOAT') || (fieldtype == 'PERCENT'))
		{
			v = v.trim();

			let q = parseFloat(v);

			if (isNaN(q)) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid number'));
				return false;

			}

		}else if (fieldtype == 'INT')
		{
			v = v.trim();

			let q = parseInt(v);

			if (isNaN(q)) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invalid integer'));
				return false;
			}

		}else if (fieldtype == 'DATE')
		{

		}else if (fieldtype == 'DATETIME')
		{

		}else if (fieldtype == 'TIME')
		{

		}else if (fieldtype == 'DURATION')
		{

		}else if (fieldtype == 'CHECK')
		{
			v = v.trim();

			if ((v != "0") && (v != "1")) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Value has to be 0 or 1'));
				return false;

			}

		}else {

		}

		if ((fieldname.toUpperCase() == 'PARENT')					|| (fieldname.toUpperCase() == 'NAME') 			 		|| (fieldname.toUpperCase() == 'IDX') 			 		 	||
			(fieldname.toUpperCase() == 'IOISTATUS') 				|| (fieldname.toUpperCase() == 'DOCUMENT_DATE') 		|| (fieldname.toUpperCase() == 'ITEM_DESCRIPTION') 	 		||
			(fieldname.toUpperCase() == 'INVOICED_QTY') 			|| (fieldname.toUpperCase() == 'ORDERED_QTY')			|| (fieldname.toUpperCase() == 'RESERVED_QTY')  		 	||
			(fieldname.toUpperCase() == 'QTY_TO_PREPARE')			|| (fieldname.toUpperCase() == 'OVERSTOCK_QTY') 		|| (fieldname.toUpperCase() == 'VALUE_LINE_DOC_CURRENCY')	||
			(fieldname.toUpperCase() == 'VALUE_LINE_SYS_CURRENCY')	|| (fieldname.toUpperCase() == 'VALUE_PAID') 			|| (fieldname.toUpperCase() == 'STOCK_DELIVERED_QTY')		||
			(fieldname.toUpperCase() == 'STOCK_INVOICED_QTY')		|| (fieldname.toUpperCase() == 'STOCK_ORDERED_QTY') 	|| (fieldname.toUpperCase() == 'STOCK_FREE_PART_QTY')		||
			(fieldname.toUpperCase() == 'STOCK_RESERVED_QTY')		|| (fieldname.toUpperCase() == 'STOCK_OVERSTOCK_QTY')	|| (fieldname.toUpperCase() == 'UNIT_PRICE_SOURCE')			||
			(fieldname.toUpperCase() == 'UNIT_PRICE_TVAC_SOURCE')	|| (fieldname.toUpperCase() == 'FIXED_PRICE_SOURCE')	|| (fieldname.toUpperCase() == 'BASE_DISCOUNT_SOURCE')		||
			(fieldname.toUpperCase() == 'EXTRA_DISCOUNT_SOURCE')	|| (fieldname.toUpperCase() == 'MIN_ORDER_QTY_SOURCE')	|| (fieldname.toUpperCase() == 'MULTIPLE_ORDER_QTY_SOURCE')	||
			(fieldname.toUpperCase() == 'FREE_PART_NUM_SOURCE')		|| (fieldname.toUpperCase() == 'FREE_PART_DEN_SOURCE')	|| (fieldname.toUpperCase() == 'ORDER_MASTER_LINK')			||
			(fieldname.toUpperCase() == 'ORDER_DETAIL_LINK')		|| (fieldname.toUpperCase() == 'UNIT_PRICE_TVAC')		|| (fieldname.toUpperCase() == 'FIXED_PRICE')				||
			(fieldname.toUpperCase() == 'DISCOUNT_IN_VALUE')		|| (fieldname.toUpperCase() == 'MIN_ORDER_QTY')			|| (fieldname.toUpperCase() == 'MULTIPLE_ORDER_QTY')		||
			(fieldname.toUpperCase() == 'FREE_PART_NUM')			|| (fieldname.toUpperCase() == 'FREE_PART_DEN')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This field can not be changed'));
			return;

		}


		if (fieldname.toUpperCase() == 'ITEM_ID') {

			silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-SETITEM' + v);

		}else if (fieldname.toUpperCase() == 'DELIVERED_QTY') {

			silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-SETQTY' + v);

		}else if (fieldname.toUpperCase() == 'UNIT_PRICE') {

			silicon_ioi.doctype.ioiSalesPOS.scan(document.getElementById('ioi_sales_pos_scan'), '$-SETPRICE' + v);

		}else if (fieldname.toUpperCase() == 'BASE_DISCOUNT') {

			let old_value = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx][fieldname];

			if (old_value != v) {

				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_condition_id = silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx][fieldname] = v;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount_source = '';


				let discount = 0;

				let base_discount = 0;

				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount) {
					base_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount;
				}

				let extra_discount = 0;

				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount) {
					extra_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount;
				}


				if ((parseFloat(base_discount) != 0) && (parseFloat(extra_discount) != 0)) {

					discount = ((1 - (parseFloat(base_discount) / 100.00)) * (1 - (parseFloat(extra_discount) / 100.00))) * 100

				}else if (parseFloat(base_discount) != 0) {

					discount = parseFloat(base_discount);

				}else if (parseFloat(extra_discount) != 0) {

					discount = parseFloat(extra_discount);
				}


				document.getElementById('ioi_sales_pos_detail_table_detail_discount_' + idx.toString()).innerHTML = discount.toFixed(2) + '&nbsp%&nbsp;';


				silicon_ioi.doctype.ioiSalesPOS.compute_document();
				let unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate / 100.00));
				document.getElementById('ioi_sales_pos_detail_table_detail_unit_price_' + + idx.toString()).innerHTML = unit_price.toFixed(2) + '&nbsp;';
				let total_line = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate / 100.00));
				document.getElementById('ioi_sales_pos_detail_table_detail_total_' + idx.toString()).innerHTML = total_line.toFixed(2) + '&nbsp';

			}

		}else if (fieldname.toUpperCase() == 'EXTRA_DISCOUNT') {

			let old_value = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx][fieldname];

			if (old_value != v) {

				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_condition_id = silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx][fieldname] = v;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount_source = '';


				let discount = 0;

				let base_discount = 0;

				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount) {
					base_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount;
				}

				let extra_discount = 0;

				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount) {
					extra_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount;
				}


				if ((parseFloat(base_discount) != 0) && (parseFloat(extra_discount) != 0)) {

					discount = ((1 - (parseFloat(base_discount) / 100.00)) * (1 - (parseFloat(extra_discount) / 100.00))) * 100

				}else if (parseFloat(base_discount) != 0) {

					discount = parseFloat(base_discount);

				}else if (parseFloat(extra_discount) != 0) {

					discount = parseFloat(extra_discount);
				}


				document.getElementById('ioi_sales_pos_detail_table_detail_discount_' + idx.toString()).innerHTML = discount.toFixed(2) + '&nbsp%&nbsp;';


				silicon_ioi.doctype.ioiSalesPOS.compute_document();
				let unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate / 100.00));
				document.getElementById('ioi_sales_pos_detail_table_detail_unit_price_' + + idx.toString()).innerHTML = unit_price.toFixed(2) + '&nbsp;';
				let total_line = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate / 100.00));
				document.getElementById('ioi_sales_pos_detail_table_detail_total_' + idx.toString()).innerHTML = total_line.toFixed(2) + '&nbsp';

			}

		}else{

			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx][fieldname] = v;

		}

		if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
			silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
			silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
		}



		silicon_ioi.doctype.ioiSalesPOS.compute_document();
	}



	static get_document(document_id)
	{

		silicon_ioi.doctype.ioiSalesPOS.current_log = [];

		let method = path_sales_pos + '.ioi_sales_pos_get_document'

		frappe.call({  	method: method,
						args: { "name": document_id
						},
						async: false,
						callback:function(r)	{

							if (r.message.doc != '') {

								silicon_ioi.doctype.ioiSalesPOS.doc = r.message.doc;
								silicon_ioi.doctype.ioiSalesPOS.refresh_screen();
							}
						}
		});

	}


	static parameters()
	{
		if (silicon_ioi.doctype.ioiSalesPOS.state != '') {

			if (!silicon_ioi.doctype.ioiSalesPOS.save()) {
				return false;
			}

		}


		silicon_ioi.doctype.ioiSalesPOS.select_profile();
	}


	static show_log() {

		if (silicon_ioi.doctype.ioiSalesPOS.current_log.length > 0) {

			var z = new frappe.ui.Dialog({
				'title': __("Logs"),
				'fields': [
					{'fieldname': 'html_sales_pos_show_log', 'fieldtype': 'HTML'}
				],
				primary_action_label: __('Close'),
				primary_action: function(){
					z.hide();
				}

			});


			silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_show_log_table_header');
			silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_show_log_content');
			silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_show_log_table_detail');

			let html = '';

			html += '<div style="overflow: auto; overflow-x: auto; height:31px; width: 680px;">';


			html += '<table id="html_sales_pos_show_log_table_header" width=650px border=1 style="border: 1px solid #E8EAEB" data-custom-grid="true">';
			html += '<tr style="height:30px">';
			html += '<td width=650px style="vertical-align: middle;"><b>&nbsp;' + __("Description") + '</b></td>';
			html += '</tr>';
			html += '</table>';

			html += '</div>';

			html += '<div id="html_sales_pos_show_log_content" style="overflow: auto; overflow-x: auto; height:369px; width: 650px;">';

			html += '<table id="html_sales_pos_show_log_table_detail" width=650px border=1 style="border: 1px solid #E8EAEB">';

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.current_log.length; i++) {

				html += '<tr style="height:30px">';

				html += '<td width=30px style="vertical-align: middle;" align="center">';

				html += '<div style="width: 20px; height: 20px; border-radius: 20px; ';

				if (silicon_ioi.doctype.ioiSalesPOS.current_log[i][0] == 0) {
					html += 'background: green;';
				}else if (silicon_ioi.doctype.ioiSalesPOS.current_log[i][0] == 1) {
					html += 'background: #FC793D;';
				}else if (silicon_ioi.doctype.ioiSalesPOS.current_log[i][0] == 2) {
					html += 'background: #FC5C47;';
				}

				html += '"></div>';

				html += '</td>';

				html += '<td width=150px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.current_log[i][2] + '</td>';
				html += '<td width=470px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.current_log[i][1] + '</td>';

				html += '</tr>';

			}


			html += '</table>';


			html += '</div>';


			z.fields_dict.html_sales_pos_show_log.$wrapper.html(html);
			z.$wrapper.find('.modal-dialog').css("max-width", "700px").css("width", "700px");
			z.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
			z.show();

			silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

				document.getElementById('html_sales_pos_show_log_content').scrollTop = document.getElementById('html_sales_pos_show_log_content').scrollHeight;
			});

		}

	}

	// *************************************************************************************************************************************************
	// Customer
	// *************************************************************************************************************************************************

	static customer_search()
	{

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
			return false;
		}

		if (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus == 0) {

			if (document.getElementById('ioi_sales_pos_customer_id').disabled) {
				return false;
			}
		}

		silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
		silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';



		let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
		let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
		let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
		let where = 'ioistatus = 2';
		let order_by = 'name asc';

		let fct_callback = function (return_value) {

			silicon_ioi.doctype.ioiSalesPOS.select_delivery_customer_id(return_value);

		}

		silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);

	}

	static clear_all_delivery_customer_fields()
	{
		silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_id = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_name = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_contact_id = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_contact_name = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_contact_mail = '';

		silicon_ioi.doctype.ioiSalesPOS.doc.invoice_customer_id = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.invoice_customer_name = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.invoice_customer_contact_id = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.invoice_customer_contact_name = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.invoice_customer_contact_mail = '';

		silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_name = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_contact_id = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_contact_name = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_contact_mail = '';

		document.getElementById('ioi_sales_pos_customer_id').value = '';
		document.getElementById('ioi_sales_pos_customer_name').value = '';

	}

	static select_delivery_customer_id(customer_id)
	{

		if ((customer_id) && (customer_id.trim() != ''))
		{

			let current_order_customer_id = '';
			let current_invoice_customer_id = '';

			let dc_type = 'DELIVERY';


			frappe.call({  	method: path_sales_document + '.ioi_sales_document_select_delivery_customer',
							args: 	{	"doctype": 'ioi Sales Delivery',
										"document_type": dc_type,
										"order_customer_id": current_order_customer_id,
										"delivery_customer_id": customer_id,
										"invoice_customer_id": current_invoice_customer_id,
										"document_site_id": silicon_ioi.doctype.ioiSalesPOS.doc.site_id
							},
							async: false,
			   				callback: function(r)	{
														silicon_ioi.doctype.ioiSalesPOS.clear_all_delivery_customer_fields();

								   						if (r.message.error == 1) {

															silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

																silicon_ioi.doctype.ioiSalesPOS.add_log(2, __(r.message.error_message));
															});

															raise;
														}

														if (r.message.intersite_transaction == 1) {

															silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

																silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Intersite customer is not allowed in sales POS'));
															});

															raise;

														}

														silicon_ioi.doctype.ioiSalesPOS.doc.language = r.message.delivery_customer_language;


														silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_id = r.message.order_customer_id.toUpperCase();
														silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_name = r.message.order_customer_name;
														silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_contact_id = '';
														silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_contact_name = '';
														silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_contact_mail = '';

														silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id = r.message.delivery_customer_id;
														document.getElementById('ioi_sales_pos_customer_id').value = r.message.delivery_customer_id.toUpperCase();


														silicon_ioi.doctype.ioiSalesPOS.delivery_customer_name = r.message.delivery_customer_name;
														document.getElementById('ioi_sales_pos_customer_name').value = r.message.delivery_customer_name;
														silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_contact_id = '';
														silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_contact_name = '';
														silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_contact_mail = '';


														silicon_ioi.doctype.ioiSalesPOS.doc.invoice_customer_id = r.message.invoice_customer_id.toUpperCase();
														silicon_ioi.doctype.ioiSalesPOS.doc.invoice_customer_name = r.message.invoice_customer_name;
														silicon_ioi.doctype.ioiSalesPOS.doc.invoice_customer_contact_id = '';
														silicon_ioi.doctype.ioiSalesPOS.doc.invoice_customer_contact_name = '';
														silicon_ioi.doctype.ioiSalesPOS.doc.invoice_customer_contact_mail = '';

														if ((r.message.sales_catalog_id) && (r.message.sales_catalog_id.trim() != '')) {

															silicon_ioi.doctype.ioiSalesPOS.doc.sales_catalog_id = r.message.sales_catalog_id;
														}

														if ((r.message.sales_condition_id) && (r.message.sales_condition_id.trim() != '')) {

															silicon_ioi.doctype.ioiSalesPOS.doc.sales_condition_id = r.message.sales_condition_id;
														}

														if ((r.message.specific_condition_id) && (r.message.specific_condition_id.trim() != '')) {

															silicon_ioi.doctype.ioiSalesPOS.doc.specific_condition_id = r.message.specific_condition_id;
														}

														if ((r.message.delivery_condition_id) && (r.message.delivery_condition_id.trim() != '')) {

															silicon_ioi.doctype.ioiSalesPOS.doc.delivery_condition_id = r.message.delivery_condition_id;
														}


														if ((r.message.currency_id) && (r.message.currency_id.trim() != '')) {

															silicon_ioi.doctype.ioiSalesPOS.doc.currency_id = r.message.currency_id;
															silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate = r.message.currency_rate;
															silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate_inv = r.message.currency_rate_inv;
															silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding = r.message.currency_digit_rounding;
														}

														silicon_ioi.doctype.ioiSalesPOS.doc.accounting_default_account_id = r.message.accounting_default_account_id;


														if ((silicon_ioi.doctype.ioiSalesPOS.customer_base_discount_in_header) && (silicon_ioi.doctype.ioiSalesPOS.customer_base_discount_in_header == 1)) {

															silicon_ioi.doctype.ioiSalesPOS.doc.base_discount = r.message.base_discount;
															document.getElementById('ioi_sales_pos_base_discount').value = r.message.base_discount;

														}

														if ((silicon_ioi.doctype.ioiSalesPOS.customer_extra_discount_in_header) && (silicon_ioi.doctype.ioiSalesPOS.customer_extra_discount_in_header == 1)) {

															silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount = r.message.extra_discount;
															document.getElementById('ioi_sales_pos_extra_discount').value = r.message.extra_discount;
														}

														silicon_ioi.doctype.ioiSalesPOS.doc.def_base_discount = silicon_ioi.doctype.ioiSalesPOS.doc.base_discount;
														silicon_ioi.doctype.ioiSalesPOS.doc.def_extra_discount = silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount;




														silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id = r.message.payment_term_id;
														silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_pay_on_delivery = r.message.pay_on_delivery;



														silicon_ioi.doctype.ioiSalesPOS.doc.vat_type = r.message.vat_type;

														if ((silicon_ioi.doctype.ioiSalesPOS.doc.vat_type == 'N') || (silicon_ioi.doctype.ioiSalesPOS.doc.vat_type == 'NAT')) {

															if ((r.message.specific_vat_code_id) && (r.message.specific_vat_code_id != 0)) {

																silicon_ioi.doctype.ioiSalesPOS.doc.spec_vat_rate = r.message.specific_vat_code_id;
															}

														}else {

															silicon_ioi.doctype.ioiSalesPOS.doc.spec_vat_rate = 0;
														}


														silicon_ioi.doctype.ioiSalesPOS.doc.vat_matching_id = r.message.vat_matching_id;


														silicon_ioi.doctype.ioiSalesPOS.doc.financial_delay = r.message.financial_delay;
														silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode = r.message.financial_discount_mode;
														silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount = r.message.financial_discount;
														silicon_ioi.doctype.ioiSalesPOS.doc.document_calc_mode = r.message.document_calc_mode;
														silicon_ioi.doctype.ioiSalesPOS.doc.sales_agent_id = r.message.sales_agent_id;
														silicon_ioi.doctype.ioiSalesPOS.doc.customer_family_1_id = r.message.family_1_id;
														silicon_ioi.doctype.ioiSalesPOS.doc.customer_family_2_id = r.message.family_2_id;
													}
			});
		}else
		{	silicon_ioi.doctype.ioiSalesPOS.clear_all_delivery_customer_fields();
		}

		silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
		silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';

	}

	static get_open_order()
	{

		if (silicon_ioi.doctype.ioiSalesPOS.state != '') {

			if (!silicon_ioi.doctype.ioiSalesPOS.save()) {
				return false;
			}

		}


		if (!silicon_ioi.doctype.ioiSalesPOS.load_default_profile()) {

			silicon_ioi.doctype.ioiSalesPOS.change_state('');
			return false;
		}

		if (silicon_ioi.doctype.ioiCashDesk.has_pending_payment()) {

			let top = 10;

			let msg = '';

			let s = __('There are pending payments, leave this document anyway ?');


			msg += '<img src="/assets/silicon_ioi/images/buttons/warning.svg" width="50px" height="50px" style="position: absolute; top: ' + top.toString() + 'px; left: 20px;">';
			msg += '<label style="position: absolute; top: ' + (top+10).toString() + 'px; left: 80px;"> ' + s + '</label>';


			top += 20;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;" align="center">' + msg + '</div>';


			frappe.confirm(	msg,
							() => 	{	silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

											silicon_ioi.doctype.ioiSalesPOS.do_get_open_order();
										});
									},
							() => 	{
										if (document.getElementById('ioi_sales_pos_scan_active').checked) {
											document.getElementById('ioi_sales_pos_scan').focus();
										}

									}
			);
		}else{
			silicon_ioi.doctype.ioiSalesPOS.do_get_open_order();
		}
	}

	static do_get_open_order()
	{
		var sp = new frappe.ui.Dialog({
			'title': __("Get open order"),
			'fields': [
				{'fieldname': 'html_sales_pos_open_order', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];

				button_ok.disabled = true;

				if (!document.getElementById('html_sales_pos_open_order_table_detail')) {

					button_ok.disabled = false;

					frappe.msgprint({title: __("Message"), message: __('No selected document'), indicator: "red"});
					raise;

				}

				let found_document = false;
				let document_id = '';

				for (var i = 0; i < document.getElementById('html_sales_pos_open_order_table_detail').rows.length; i++) {

					if (document.getElementById('html_sales_pos_open_order_table_detail_checked_id_' + i.toString())) {

						if (document.getElementById('html_sales_pos_open_order_table_detail_checked_id_' + i.toString()).checked) {

							found_document = true;

							document_id = document.getElementById('html_sales_pos_open_order_table_detail').rows[i].cells[1].innerText.trim();

						}

					}
				}

				if (!found_document) {

					button_ok.disabled = false;

					frappe.msgprint({title: __("Message"), message: __('No selected document'), indicator: "red"});
					raise;
				}


				sp.hide();

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

					silicon_ioi.doctype.ioiSalesPOS.create_delivery_from_order(document_id);
				});
			},
			secondary_action: function(){
				sp.hide();
			}


		});


		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_open_order_table_header');

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_open_order_customer_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_open_order_customer_id');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_open_order_customer_button');


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:65px; width: 730px;">';

		html += '	<div style="position: relative; top: 0px; left: 0px; width:220px;">';
		html += '		<label id="html_sales_pos_open_order_customer_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Customer") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 30px;"> ';
		html += '			<input id="html_sales_pos_open_order_customer_id" type="text" class="input-with-feedback form-control bold" style="" value="' + document.getElementById('ioi_sales_pos_customer_id').value + '">';
		html += '		</div>';
		html += '		<div  style="position: relative; top: 23px; left: 232px; width:32px;height:32px;">';
		html += '			<img id="html_sales_pos_open_order_customer_button" title="' + __("Select customer") + '" src="/assets/silicon_ioi/images/buttons/select_customer.svg" width="32px" height="32px" style="position:relative;top:4px; left:4px;"></img>';
		html += "		</div>";


		html += '	</div>';

		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:400px;">';


		html += '<table id="html_sales_pos_open_order_table_header" width=730px border=1 style="border: 1px solid #E8EAEB" data-custom-grid="true">';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Document") + '</b></td>';
		html += '<td width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Date") + '</b></td>';
		html += '<td width=200px style="vertical-align: middle;"><b>&nbsp;' + __("Customer") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;"><b>&nbsp;' + __("Total") + '</b></td>';
		html += '<td width=80px style="vertical-align: middle;"><b>&nbsp;' + __("Currency") + '</b></td>';
		html += '</tr>';
		html += '</table>';

		html += '</div>';



		sp.fields_dict.html_sales_pos_open_order.$wrapper.html(html);
		sp.$wrapper.find('.modal-dialog').css("max-width", "780px").css("width", "780px");
		sp.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
		sp.show();

		silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

			let fct_keydown = function(event) {

				if (event.keyCode == 13) {

					silicon_ioi.doctype.ioiSalesPOS.open_order_data();
					return false;
				}

			};

			document.getElementById('html_sales_pos_open_order_customer_id').onkeydown = fct_keydown;


			let fct_mouse_move = function() {

				this.style.cursor = 'pointer';
			}

			let fct_mouse_leave = function() {
				this.style.cursor = 'none';
			}


			let fct_customer_click = function() {

				let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
				let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
				let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
				let where = 'ioistatus = 2';
				let order_by = 'name asc';

				let fct_callback = function (return_value) {

					document.getElementById('html_sales_pos_open_order_customer_id').value = return_value;
					document.getElementById('html_sales_pos_open_order_customer_id').focus();

					silicon_ioi.doctype.ioiSalesPOS.open_order_data();

				}

				silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);

			}

			document.getElementById('html_sales_pos_open_order_customer_button').onmousemove = fct_mouse_move;
			document.getElementById('html_sales_pos_open_order_customer_button').onmouseleave = fct_mouse_leave;
			document.getElementById('html_sales_pos_open_order_customer_button').onclick = fct_customer_click;



			silicon_ioi.doctype.ioiSalesPOS.open_order_data();
		});
	}

	static open_order_data()
	{
		if (document.getElementById('html_sales_pos_open_order_table_detail')) {

			for (var i = 0; i < document.getElementById('html_sales_pos_open_order_table_detail').rows.length; i++) {

				silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_open_order_table_detail_checked_id_' + i.toString());

			}
			silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_open_order_table_detail');
		}

		frappe.call({ 	method: path_sales_pos + ".ioi_sales_pos_open_order",
						args: {	"journal_id": silicon_ioi.doctype.ioiSalesPOS.journal_id,
								"customer_id": document.getElementById('html_sales_pos_open_order_customer_id').value,
								"name" : ''
						},
						async : false,
						callback:function(r){


			let html = '';

			if (r.message.length > 0)
			{

				let cpt = 0;

				html = '<table id="html_sales_pos_open_order_table_detail" border=1 style="border: 1px solid #E8EAEB" width=730px>';

				for (var i = 0; i < r.message.length; i++)
				{
					html += '<tr style="height:30px">';

					html += '<td width=30px style="vertical-align: middle;" align="center">';

					html += '<input type="checkbox" id="html_sales_pos_open_order_table_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 2px; left: 2px;" ';
					html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
					html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
					html += '>';

					html += '</td>';


					html += '<td width=200px style="vertical-align: middle;" bgcolor="' + r.message[i].ioistatus_bgcolor + '">&nbsp;' + r.message[i].name + '</td>';
					html += '<td width=100px style="vertical-align: middle;">&nbsp;' + r.message[i].document_date + '</td>';

					if (r.message[i].delivery_customer_id != null) {
						html += '<td width=200px style="vertical-align: middle;">&nbsp;' + r.message[i].delivery_customer_id + '</td>';
					}else{
						html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
					}

					if ((r.message[i].total_tvac != null) && (r.message[i].total_tvac != 0)){
						html += '<td width=120px style="vertical-align: middle;" align="right">' + r.message[i].total_tvac + '&nbsp;</td>';
					}else{
						html += '<td width=120px style="vertical-align: middle;" align="right">&nbsp;</td>';
					}

					if (r.message[i].currency_id != null) {
						html += '<td width=80px style="vertical-align: middle;">&nbsp;' + r.message[i].currency_id + '</td>';
					}else{
						html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
					}
					html += '</tr>';
				}

				html += '</table>';



			}else
			{
				html += '<table id="html_sales_pos_open_order_table_detail" width=730px border=1 style="border: 1px solid #E8EAEB">';
				html += '<tr style="height:30px">';
				html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=200px style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
				html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
				html += '</tr>';
				html += '</table>';
			}


			document.getElementById('html_sales_pos_open_order_table_header').insertAdjacentHTML('afterend', html);

			silicon_ioi.doctype.ioiItemPriceFrame.sleep_static(400).then(() => {

				let fct_click = function () {

					if (document.getElementById('html_sales_pos_open_order_table_detail')) {

						for (var i = 0; i < document.getElementById('html_sales_pos_open_order_table_detail').rows.length; i++) {

							if (document.getElementById('html_sales_pos_open_order_table_detail_checked_id_' + i.toString())) {

								document.getElementById('html_sales_pos_open_order_table_detail_checked_id_' + i.toString()).checked = false;
							}

						}

						this.checked = true;
					}
				};


				if (document.getElementById('html_sales_pos_open_order_table_detail')) {

					for (var i = 0; i < document.getElementById('html_sales_pos_open_order_table_detail').rows.length; i++) {

						if (document.getElementById('html_sales_pos_open_order_table_detail_checked_id_' + i.toString())) {

							document.getElementById('html_sales_pos_open_order_table_detail_checked_id_' + i.toString()).onclick = fct_click;
						}

						if (i == 0) {
							if (document.getElementById('html_sales_pos_open_order_table_detail_checked_id_' + i.toString())) {
								document.getElementById('html_sales_pos_open_order_table_detail_checked_id_' + i.toString()).checked = true;
							}
						}

					}
				}

			});
		}});
	}

	static create_delivery_from_order(document_id)
	{
		frappe.call({ 	method: path_sales_pos + ".ioi_sales_pos_create_delivery_from_order",
						args: {	"order_id" : document_id,
								"cashdesk_id" : silicon_ioi.doctype.ioiSalesPOS.cashdesk_id
						},
						async : false,
						callback:function(r) {

							if (r.message.error != 0) {
								silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_message);

							}else{
								silicon_ioi.doctype.ioiCashDesk.clear_pending_payment();
								silicon_ioi.doctype.ioiSalesPOS.get_document(r.message.delivery_number);
								silicon_ioi.doctype.ioiSalesPOS.display_on_screen('OPEN_ORDER')
								silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('The new delivery from order') + ' ' + document_id + ' ' + __("has been created"));

							}
						}
		});
	}

	static invoice(silent_mode = false, quick_invoice = false)
	{

		if (silicon_ioi.doctype.ioiSalesPOS.state != '') {

			if (!silicon_ioi.doctype.ioiSalesPOS.save()) {
				return false;
			}

		}

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus < 1)) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('The document has to be "Delivered"'));
			return false;
		}

		if (silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_pos_customer_id == silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Invoice generation is only available for customers'));
			return false;

		}

		if (!silent_mode) {

			let already_invoiced = 0

			frappe.call({
				method: path_sales_pos + ".ioi_sales_pos_already_invoiced",
				args: {
					"delivery_id" : silicon_ioi.doctype.ioiSalesPOS.doc.name
				},
				async : false,
				callback:function(r) {
					already_invoiced = r.message;
				}
			});

			if (already_invoiced == 1) {
				silicon_ioi.doctype.ioiSalesPOS.do_invoice(quick_invoice);
			}else{

				let msg = __('Invoice this document ?');

				frappe.confirm(	msg,
								() => 	{
											silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

												silicon_ioi.doctype.ioiSalesPOS.do_invoice(quick_invoice);
											});
										},
								() => 	{
										}
				);
			}
		}else{
			silicon_ioi.doctype.ioiSalesPOS.do_invoice(quick_invoice);
		}
	}

	static do_invoice(quick_invoice = false)
	{
		let change_status = 0

		if (quick_invoice) {
			change_status = 1
		}

		frappe.call({ 	method: path_sales_pos + ".ioi_sales_pos_create_invoice_from_delivery",
						args: {
							"delivery_id" : silicon_ioi.doctype.ioiSalesPOS.doc.name,
							"change_status" : change_status

						},
						async : false,
						callback:function(r) {
							if (r.message.error != 0) {
								silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_message);

								if ((r.message.error == 3) || (r.message.error == 4)) {
									let s = '/app/ioi-sales-invoice/' + r.message.invoice_number;
									window.open(s);
								}

							}else{
								silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('The invoice') + ' ' + r.message.invoice_number + ' ' + __("has been created"));

								let s = '/app/ioi-sales-invoice/' + r.message.invoice_number;
								window.open(s);

								window.location.reload();
							}
						}
		});

	}


	static lock_screen(do_lock_in_db = true) {

		if (do_lock_in_db) {

			if ((!silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.cashdesk_id.trim() == ''))) {

				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash desk is mandatory for locking'));
			}

			let operator_id = frappe.session.user;

			if ((silicon_ioi.doctype.ioiSalesPOS.batchuser_userid) && (silicon_ioi.doctype.ioiSalesPOS.batchuser_userid != '')) {
				operator_id = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
			}


			let method = path_sales_pos + '.ioi_sales_pos_lock_unlock_cashdesk';

			frappe.call({
				method: method,
				args: {
					"cashdesk_id": silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
					"lock" : 1,
					"operator_id": operator_id,
					"operator_badge_id": silicon_ioi.doctype.ioiSalesPOS.batchuser
				},
				async: false,
				callback: function (r) {
				}
			});
		}


		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_screen_locked_unlock_logo');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_screen_locked');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_screen_locked_unlock_value');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_screen_locked_unlock_button');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_screen_locked_unlock_label');


		let bw = 300;
		let bh = 30;


		let h = window.innerHeight;
		h = parseInt(h / 2) - parseInt(bh / 2);

		let w = window.innerWidth;
		w = parseInt(w / 2) - parseInt(bw / 2);



		let html = '';
		html += '<div id="ioi_sales_pos_screen_locked" style="position:absolute; top: 0px; left: 0px; height: 100%; width: 100%; z-index: 20000; background-color: rgba(244,245, 246,0.7);">';

		html += '	<div style="position: relative; top: 0px; left: 0px; width:100%;height:100%">';

		html += '		<div style="position: absolute; top: ' + (h-320).toString() + 'px; left: ' + (w-20).toString() + 'px; width:' +  (bw+20).toString() + 'px; height: ' + (bh+350).toString() + 'px; background-color: #FFFFFF"> ';


		html += '			<div style="position: absolute; top: 10px; left: 10px; width:' + bw.toString() + 'px; height: ' + bh.toString() + 'px;"> ';
		html += '				<img id="ioi_sales_pos_screen_locked_unlock_logo" src="/assets/silicon_ioi/images/logo_800x800.png" width="300px" height="300px"></img>';
		html += "			</div>";


		html += '			<div style="position: absolute; top: 260px; left: 10px; width:' + bw.toString() + 'px; height: ' + bh.toString() + 'px;"> ';
		html += '				<input id="ioi_sales_pos_screen_locked_unlock_value" type="text" style="-webkit-text-security: circle" autocomplete="off" class="input-with-feedback form-control bold" value="">';
		html += '			</div>';

		html += '			<div style="position: relative; top: 310px; left: 10px; width:' + bw.toString() + 'px;height:' + bh.toString() + 'px;">';
		html += '				<input id="ioi_sales_pos_screen_locked_unlock_button" type="button" class="btn btn-default ellipsis" value="' + __("Unlock") + '" style="width:' + bw.toString() + 'px;"> ';
		html += "			</div>";

		html += '			<div style="position: absolute; top: 353px; left: 10px; width:' + bw.toString() + 'px;height:' + bh.toString() + 'px;">';
		html += '				<label id="ioi_sales_pos_screen_locked_unlock_label" style="width:' + bw.toString() + 'px; color:red"></label>';
		html += "			</div>";


		html += "		</div>";

		html += "	</div>";

		html += '</div>';

		document.body.insertAdjacentHTML('beforeend', html);



		silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

			let fct_unlock_click = function() {

				silicon_ioi.doctype.ioiSalesPOS.unlock_screen();

			}

			document.getElementById('ioi_sales_pos_screen_locked_unlock_button').onclick = fct_unlock_click;



			let fct_key_down = function(event) {

				if (event.keyCode == 13) {
					silicon_ioi.doctype.ioiSalesPOS.unlock_screen();
					return false;
				}

			}

			document.getElementById('ioi_sales_pos_screen_locked_unlock_value').onkeydown = fct_key_down;

			document.getElementById('ioi_sales_pos_screen_locked_unlock_value').value = '';
			document.getElementById('ioi_sales_pos_screen_locked_unlock_value').focus();


		});

	}

	static unlock_screen()
	{

		if ((!silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.cashdesk_id.trim() == ''))) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash desk is mandatory for locking'));
		}


		let s = document.getElementById('ioi_sales_pos_screen_locked_unlock_value').value;

		if (s.trim() != '') {

			let decrypted_value = '';

			for (var i = 0; i < s.length; i++) {

				if ((s[i] == '0') || (s[i] == 'à') || (s[i] == 'À')) {
					decrypted_value += '0';
				}else if ((s[i] == '1') || (s[i] == '&')) {
					decrypted_value += '1';
				}else if ((s[i] == '2') || (s[i] == 'é') || (s[i] == 'É')) {
					decrypted_value += '2';
				}else if ((s[i] == '3') || (s[i] == '"')) {
					decrypted_value += '3';
				}else if ((s[i] == '4') || (s[i] == '\'')) {
					decrypted_value += '4';
				}else if ((s[i] == '5') || (s[i] == '(')) {
					decrypted_value += '5';
				}else if ((s[i] == '6') || (s[i] == '§')) {
					decrypted_value += '6';
				}else if ((s[i] == '7') || (s[i] == 'è') || (s[i] == 'È')) {
					decrypted_value += '7';
				}else if ((s[i] == '8') || (s[i] == '!')) {
					decrypted_value += '8';
				}else if ((s[i] == '9') || (s[i] == 'ç') || (s[i] == 'Ç')) {
					decrypted_value += '9';
				}else{
					decrypted_value += s[i];
				}


			}

			let method = path_sales_pos + '.ioi_sales_pos_check_userbadge_or_pwd';

			frappe.call({
				method: method,
				args: {
					"value": document.getElementById('ioi_sales_pos_screen_locked_unlock_value').value,
					"batchvalue" : decrypted_value,
				},
				async: false,
				callback: function (r) {

					if (r.message.found == 1) {


						if (r.message.batchuser != '') {
							silicon_ioi.doctype.ioiSalesPOS.batchuser = r.message.batchuser;
							silicon_ioi.doctype.ioiSalesPOS.batchuser_userid = r.message.batchuser_userid;
							silicon_ioi.doctype.ioiSalesPOS.batchuser_name = r.message.batchuser_name;
						}

						let operator_id = frappe.session.user;

						if (silicon_ioi.doctype.ioiSalesPOS.batchuser_userid != '') {
							operator_id = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
						}


						let method = path_sales_pos + '.ioi_sales_pos_lock_unlock_cashdesk';

						frappe.call({
							method: method,
							args: {
								"cashdesk_id": silicon_ioi.doctype.ioiSalesPOS.cashdesk_id,
								"lock" : 0,
								"operator_id": silicon_ioi.doctype.ioiSalesPOS.batchuser_userid,
								"operator_badge_id": silicon_ioi.doctype.ioiSalesPOS.batchuser
							},
							async: false,
							callback: function (r) {
							}
						});


						silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_screen_locked_unlock_logo');
						silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_screen_locked');
						silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_screen_locked_unlock_value');
						silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_screen_locked_unlock_button');
						silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_screen_locked_unlock_label');

						if (document.getElementById('ioi_sales_pos_operations_and_scan_buttons_area_title_user_label')) {

							let s = '';

							if ((silicon_ioi.doctype.ioiSalesPOS.batchuser) && (silicon_ioi.doctype.ioiSalesPOS.batchuser.trim() != '')) {

								if ((!silicon_ioi.doctype.ioiSalesPOS.batchuser_name) || ((silicon_ioi.doctype.ioiSalesPOS.batchuser_name) && (silicon_ioi.doctype.ioiSalesPOS.batchuser_name.trim() == ''))) {
									s = __("Connected badge") + ' ' + silicon_ioi.doctype.ioiSalesPOS.batchuser;
								}else{
									s = __("Connected badge") + ' ' + silicon_ioi.doctype.ioiSalesPOS.batchuser_name;
								}
							}else{
								s = __("Connected user") + ' ' + silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
							}

							document.getElementById('ioi_sales_pos_operations_and_scan_buttons_area_title_user_label').innerText = s;

						}

					}else{

						document.getElementById('ioi_sales_pos_screen_locked_unlock_label').innerHTML = r.message.msg;
						document.getElementById('ioi_sales_pos_screen_locked_unlock_value').value = '';
						document.getElementById('ioi_sales_pos_screen_locked_unlock_value').focus();

					}

					silicon_ioi.doctype.ioiSalesPOS.rebuild_grid_header();

					silicon_ioi.doctype.ioiSalesPOS.current_line_id = -1;

					if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

						for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

							if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

								if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {
									silicon_ioi.doctype.ioiSalesPOS.current_line_id = i;
									break;
								}
							}
						}
					}


					silicon_ioi.doctype.ioiSalesPOS.refresh_screen();

					if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

						for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

							if (silicon_ioi.doctype.ioiSalesPOS.current_line_id == i) {

								if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

									document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = true;
								}
							}
						}
					}



					let fct_up_down_over = function() {
						this.style.cursor = 'pointer';
					}

					let fct_up_down_leave = function() {
						this.style.cursor = 'none';
					}

					document.getElementById('sales_pos_grid_configurator').onmouseover = fct_up_down_over;
					document.getElementById('sales_pos_grid_configurator').onmouseleave = fct_up_down_leave;

					let fct_grid_configurator_click = function() {
						silicon_ioi.doctype.ioiSalesPOS.configure_grid();
					}

					document.getElementById('sales_pos_grid_configurator').onclick = fct_grid_configurator_click;


				},
			});
		}

	}


	// *************************************************************************************************************************************************
	// Pay
	// *************************************************************************************************************************************************

	static pay_deliver()
	{
		if (silicon_ioi.doctype.ioiSalesPOS.state != '') {

			if (!silicon_ioi.doctype.ioiSalesPOS.save()) {
				return false;
			}

		}


		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('The document has to be "in preparation"'));
			return false;
		}






		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id.trim() == ''))) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Customer is mandatory'));
			return false;
		}


		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id.trim() == ''))) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Payment term is mandatory'));
			return false;

		}

		let tmp_doc = silicon_ioi.doctype.ioiSalesPOS.doc;
		if (silicon_ioi.doctype.ioiCashDesk.verifify_pending_payment(tmp_doc.doctype, tmp_doc.name, true)){
			frappe.throw("The transactions are currently being processed. Please try again later");

			return;
		}

		let stop_process = false;

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

			if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length == 0) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Nothing to pay'));
				return false;
			}

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price == null) ||
					((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price) == 0))) {

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_pos_free_sales_allowed != 1) {

						stop_process = true;
						break;
					}

				}
			}

		}else{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Nothing to pay'));
			return false;

		}

		if (stop_process) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This sales contains items without price'));
			return false;
		}


		if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == 2) {

			let par_still_to_pay = 0;

			if (document.getElementById('ioi_sales_pos_pay_still_to_pay').innerText.indexOf(' ') != -1) {

				let s = document.getElementById('ioi_sales_pos_pay_still_to_pay').innerText;

				s = s.substring(0, s.length-3);
				s = s.trim();
				par_still_to_pay = s;


				par_still_to_pay = par_still_to_pay.trim();

				if (par_still_to_pay == '') {
					par_still_to_pay = '0'
				}
			}

			let exp = Math.pow(10, 2);

			par_still_to_pay = Math.round(parseFloat(par_still_to_pay) * exp) / exp;

			if (par_still_to_pay == 0) {
				silicon_ioi.doctype.ioiSalesPOS.do_deliver(0);
				return false;
			}
		}

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id.trim() == ''))) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash desk is mandatory before executing this action'));
			return false;
		}

		let meth = path_cash_desk + '.ioi_cash_desk_get_info';

		let cashdesk_status = 0;

		frappe.call({  	method: meth,
						args: {"name": silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id},
						async: false,
						callback:function(r)	{

							if (r.message.length != 0) {
								cashdesk_status = r.message[0].ioistatus;
							}
						}
		});

		if (cashdesk_status != 1) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This cash desk is not open'));
			return false;

		}

		let can_pay = true;

		if (((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_cash_value_enabled) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_cash_value_enabled) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_cash_value_enabled != 1))) &&
			((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_bank_card_value_enabled) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_bank_card_value_enabled) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_bank_card_value_enabled != 1))) &&
			((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_credit_card_value_enabled) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_credit_card_value_enabled) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_credit_card_value_enabled != 1))) &&
			((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_value_enabled) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_value_enabled) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_value_enabled != 1))) &&
			((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_emoney_value_enabled) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_emoney_value_enabled) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_emoney_value_enabled != 1))) &&
			((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_1_value_enabled) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_1_value_enabled) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_1_value_enabled != 1))) &&
			((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_2_value_enabled) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_2_value_enabled) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_2_value_enabled != 1))) &&
			((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_3_value_enabled) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_3_value_enabled) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_3_value_enabled != 1)))) {
			can_pay = false;
		}

		if (can_pay) {

			let me = this;

			// Generate withme

			let meth = path_sales_pos + '.ioi_sales_pos_generate_withme';


			frappe.call({  	method: meth,
							args: {"name": silicon_ioi.doctype.ioiSalesPOS.doc.name},
							async: false,
							callback:function(r)	{

								silicon_ioi.doctype.ioiSalesPOS.doc = r.message;
								silicon_ioi.doctype.ioiSalesPOS.save_pos_id_user();
								silicon_ioi.doctype.ioiSalesPOS.refresh_screen();
								silicon_ioi.doctype.ioiSalesPOS.state = '';
								silicon_ioi.doctype.ioiSalesPOS.change_state('');


								let stop_process = false;

								for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price == null) ||
										((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price) == 0))) {

										if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_pos_free_sales_allowed != 1) {

											stop_process = true;
											break;
										}

									}
								}

								if (!stop_process) {

									let fct_callback_action = function(cash_value, cb_cashback) {

										if (silicon_ioi.doctype.ioiSalesPOS.state == '') {

											silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
											silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';

										}

										silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;


										if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) <= 0) {
											silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 2;
										}else{
											if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) >= parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac)) {
												silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;
											}else{
												silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 1;
											}
										}

										document.getElementById('ioi_sales_pos_save_button').click();

										document.getElementById('ioi_sales_last_scan').innerText = '';

										if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == 0) {

											silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Payment is unpaid'));

										}else if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == 1) {

											silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Payment is partial'));

										}else if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == 2) {

											silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Payment is done'));

											silicon_ioi.doctype.ioiSalesPOS.do_deliver(0);
										}


									};

									let par_still_to_pay = 0;

									if (document.getElementById('ioi_sales_pos_pay_still_to_pay').innerText.indexOf(' ') != -1) {

										let s = document.getElementById('ioi_sales_pos_pay_still_to_pay').innerText;

										s = s.substring(0, s.length-3);
										s = s.trim();
										par_still_to_pay = s;

										par_still_to_pay = par_still_to_pay.trim();

										par_still_to_pay = par_still_to_pay.replaceAll(silicon_ioi.doctype.ioiSalesPOS.thousand_separator, '');
										par_still_to_pay = par_still_to_pay.replaceAll(silicon_ioi.doctype.ioiSalesPOS.decimal_separator, '.');


										if (par_still_to_pay == '') {
											par_still_to_pay = '0'
										}
									}

									let exp = Math.pow(10, 2);

									//par_still_to_pay =  Math.round(parseFloat(par_still_to_pay) * exp) / exp;

									let show_window = true;

									let data_ret = {}
									data_ret.error = "0"
									data_ret.error_message = ''

									let method = 'silicon_ioi.common.document_change_status.sales_delivery_check_before_change_status';


									frappe.call({  	method: method,
													args: {"from_status": 0, "to_status": 1, "doc" : silicon_ioi.doctype.ioiSalesPOS.doc, "doctype": 'ioi Sales Delivery', "name": silicon_ioi.doctype.ioiSalesPOS.doc.name, "data": data_ret, "silent_mode": true, "from_sales_pos": true},
													async: false,
													callback:function(r)	{

														if (r.message) {

															if (r.message.error) {

																if (r.message.error != 0) {
																	show_window = false;
																	silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_message);
																	return false;
																}
															}
														}

													}
									});

									if (show_window) {

										silicon_ioi.doctype.ioiCashDesk.do_pay_now( silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id, 'ioi Sales Delivery', silicon_ioi.doctype.ioiSalesPOS.doc.name, silicon_ioi.doctype.ioiSalesPOS.doc.site_id, silicon_ioi.doctype.ioiSalesPOS.doc.division_id, silicon_ioi.doctype.ioiSalesPOS.doc.currency_id, silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate,
																					silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate_inv, silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac, par_still_to_pay,
																					silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_id_required, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_1_id_required, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_1_description,
																					silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_2_id_required, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_2_description, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_3_id_required,
																					silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_3_description, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_cash_value_enabled, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_bank_card_value_enabled,
																					silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_credit_card_value_enabled, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_value_enabled, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_emoney_value_enabled,
																					silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_1_value_enabled, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_2_value_enabled, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_3_value_enabled,
																					silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_1_electronic_transac, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_2_electronic_transac, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_3_electronic_transac,
																					fct_callback_action, silicon_ioi.doctype.ioiSalesPOS.batchuser_userid,
																					silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payconiq_enabled, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_ccv_enabled, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_1_type,
																					silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_2_type,	silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_check_spec_3_type
																					);
									}

								}else{
									silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This sales contains items without price'));
									return false;
								}
							}
			});



		}else{

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No payment allowed for this cash desk'));
			return false;
		}
	}

	static deliver(bypass_msg = false)
	{

		if (silicon_ioi.doctype.ioiSalesPOS.state != '') {

			if (!silicon_ioi.doctype.ioiSalesPOS.save()) {
				return false;
			}

		}


		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('The document has to be "in preparation"'));
			return false;
		}

		let stop_process = false;

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

			if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length == 0) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Nothing to deliver'));
				return false;
			}

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price == null) ||
					((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price) == 0))) {

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_pos_free_sales_allowed != 1) {

						stop_process = true;
						break;
					}

				}
			}

		}else{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Nothing to deliver'));
			return false;

		}

		if (stop_process) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This sales contains items without price'));
			return false;
		}



		if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_pay_on_delivery == 1) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('You have to pay before'));
			return false;
		}



		if (!bypass_msg) {

			let top = 10;

			let msg = '';
			let s = __('Deliver this document without any payment ?');

			msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';

			if ((silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id.trim() != '')) {

				let pay_terms_description = '';

				let method = path_sales_pos + '.ioi_sales_pos_get_customer_payment_terms';

				frappe.call({  	method: method,
								args: {"payment_term_id": silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id},
								async: false,
								callback:function(r)	{
									if (r.message.length > 0) {
										pay_terms_description = r.message[0].description
									};

								}
				});



				top += 30;
				s = __('Customer payment terms') + ' : ' + pay_terms_description + ' ( ' + silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id + ' ) ';
				msg += '<label style="position: absolute; top: ' + top.toString() + 'px; left: 20px;"> ' + s + '</label>';
			}



			top += 30;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;">' + msg + '</div>';



			frappe.confirm(	msg,
							() => 	{
										silicon_ioi.doctype.ioiSalesPOS.do_deliver(1);
									},
							() => 	{
									}
			);
		}else{
			silicon_ioi.doctype.ioiSalesPOS.do_deliver(1);
		}
	}

	static do_deliver(check_without_pay)
	{
		let go = true;
		let method = path_sales_pos + '.ioi_sales_pos_deliver';

		frappe.call({  	method: method,
						args: { "name" : silicon_ioi.doctype.ioiSalesPOS.doc.name,
								"check_without_pay": check_without_pay
						},
						async: false,
						callback:function(r)	{

							if (r.message.error == 1)
							{
								go = false;
								silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_msg);

							}

						}
		});

		if (!go) {
			return false;
		}else{
			silicon_ioi.doctype.ioiSalesPOS.get_document(silicon_ioi.doctype.ioiSalesPOS.doc.name);

			silicon_ioi.doctype.ioiSalesPOS.sleep_static(1000).then(() => {

				silicon_ioi.doctype.ioiSalesPOS.display_on_screen('DELIVERED');

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(2000).then(() => {

					silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Document has been delivered'));
					silicon_ioi.doctype.ioiSalesPOS.reprint(false);
				});
			});
		}

	}



	static pay_activity()
	{
		if (silicon_ioi.doctype.ioiSalesPOS.state != '') {

			if (!silicon_ioi.doctype.ioiSalesPOS.save()) {
				return false;
			}

		}


		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}


		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}


		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id.trim() == ''))) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Customer is mandatory'));
			return false;

		}

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id.trim() == ''))) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash desk is mandatory before executing this action'));
			return false;
		}

		let fct_callback_action = function(cash_value, cb_cashback) {

			if (silicon_ioi.doctype.ioiSalesPOS.state == '') {

				silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
				silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';

			}

			silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;


			if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) <= 0) {
				silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 2;
			}else{
				if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) >= parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac)) {
					silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;
				}else{
					silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 1;
				}
			}

			document.getElementById('ioi_sales_pos_save_button').click();

			document.getElementById('ioi_sales_last_scan').innerText = '';

			silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Payment operation has been cancelled'));

		};

		silicon_ioi.doctype.ioiCashDesk.do_pay_activity('ioi Sales Delivery', silicon_ioi.doctype.ioiSalesPOS.doc.name, silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id, silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus,
														 silicon_ioi.doctype.ioiSalesPOS.is_dark_mode, fct_callback_action,  silicon_ioi.doctype.ioiSalesPOS.batchuser_userid);

	}

	static get_cash()
	{

		document.getElementById('ioi_sales_last_scan').innerText = '';

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id.trim() == ''))) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Payment term is mandatory'));
			return false;
		}

		if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_cash_value_enabled != 1) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash value is disabled'));
			return false;

		}

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id.trim() == ''))) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash desk is mandatory before executing this action'));
			return false;
		}

		let meth = path_cash_desk + '.ioi_cash_desk_get_info';

		let current_cash_value = 0;
		let role_to_get_cash = '';
		let cashdesk_status = 0;

		frappe.call({  	method: meth,
						args: {"name": silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id},
						async: false,
						callback:function(r)	{

							if (r.message.length != 0) {
								current_cash_value = r.message[0].cash_value;
								role_to_get_cash = r.message[0].role_to_get_cash;
								cashdesk_status = r.message[0].ioistatus;

							}
						}
		});

		if (cashdesk_status != 1) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This cash desk is not open'));
			return false;

		}

		if (parseFloat(current_cash_value) == 0) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No enough cash in this cash desk'));
			return false;
		}


		let can_get_cash = false;

		if (frappe.user.name.toUpperCase() == 'ADMINISTRATOR') {
			can_get_cash = true;
		}else {
			if (role_to_get_cash != '') {
				if (frappe.user.has_role(role_to_get_cash)) {
					can_get_cash = true;
				}
			}
		}


		if (can_get_cash) {

			let fct_callback_action = function(cash_value, cb_cashback) {

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) || ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus == 0))) {

					if (silicon_ioi.doctype.ioiSalesPOS.state == '') {

						silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
						silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';

					}

					silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;


					if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) <= 0) {
						silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 2;
					}else{
						if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) >= parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac)) {
							silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;
						}else{
							silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 1;
						}
					}

					document.getElementById('ioi_sales_pos_save_button').click();
				}

				document.getElementById('ioi_sales_last_scan').innerText = '';

				if (cb_cashback == 1) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Get cash : cash back to customer') + ' : ' + cash_value.toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id);
				}else{
					silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Get cash') + ' : ' + cash_value.toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id);
				}



			};

			let ioistatus = 0;

			if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
				ioistatus = silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus;
			}

			silicon_ioi.doctype.ioiCashDesk.do_pay_get_cash(silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id, 'ioi Sales Delivery', silicon_ioi.doctype.ioiSalesPOS.doc.name, ioistatus, silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac, current_cash_value, fct_callback_action, silicon_ioi.doctype.ioiSalesPOS.batchuser_userid);

		}else{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You're not authorized to get cash from this cash desk"));
			return false;
		}

	}

	static put_cash()
	{

		document.getElementById('ioi_sales_last_scan').innerText = '';


		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.payment_term_id.trim() == ''))) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Payment term is mandatory'));
			return false;
		}

		if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_cash_value_enabled != 1) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash value is disabled'));
			return false;
		}


		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id.trim() == ''))) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Cash desk is mandatory before executing this action'));
			return false;
		}


		let meth = path_cash_desk + '.ioi_cash_desk_get_info';


		let role_to_put_cash = '';
		let cashdesk_status = 0;

		frappe.call({  	method: meth,
						args: {"name": silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id},
						async: false,
						callback:function(r)	{

							if (r.message.length != 0) {
								role_to_put_cash = r.message[0].role_to_put_cash;
								cashdesk_status = r.message[0].ioistatus;

							}
						}
		});

		if (cashdesk_status != 1) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('This cash desk is not open'));
			return false;

		}



		let can_put_cash = false;

		if (frappe.user.name.toUpperCase() == 'ADMINISTRATOR') {
			can_put_cash = true;
		}else {
			if (role_to_put_cash != '') {
				if (frappe.user.has_role(role_to_put_cash)) {
					can_put_cash = true;
				}
			}
		}





		if (can_put_cash) {

			let fct_callback_action = function(cash_value, cb_received) {

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) || ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus == 0))) {

					if (silicon_ioi.doctype.ioiSalesPOS.state == '') {

						silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
						silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';

					}

					silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;


					if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) <= 0) {
						silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 2;
					}else{
						if (parseFloat(document.getElementById('ioi_sales_pos_pay_still_to_pay').value) >= parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac)) {
							silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;
						}else{
							silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 1;
						}
					}

					document.getElementById('ioi_sales_pos_save_button').click();
				}

				document.getElementById('ioi_sales_last_scan').innerText = '';

				if (cb_received == 1) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Put cash : receive cash from customer') + ' : ' + cash_value.toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id);
				}else{
					silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Put cash') + ' : ' + cash_value.toFixed(2) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id);
				}



			};

			let ioistatus = 0;

			if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
				ioistatus = silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus;
			}


			silicon_ioi.doctype.ioiCashDesk.do_pay_put_cash(silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_id, 'ioi Sales Delivery', silicon_ioi.doctype.ioiSalesPOS.doc.name, ioistatus, silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac, fct_callback_action, silicon_ioi.doctype.ioiSalesPOS.batchuser_userid);

		}else{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You're not authorized to put cash in this cash desk"));
			return false;
		}
	}

	static open_cash_desk()
	{
		let fct_callback_msg = function(ret_msg) {

			document.getElementById('ioi_sales_last_scan').innerText = '';

			if (ret_msg.length != 0) {

				silicon_ioi.doctype.ioiSalesPOS.add_log(ret_msg[0][0], ret_msg[0][1]);
				return false;

			}
		};


		let who = ''
		let who_name = '';

		if ((silicon_ioi.doctype.ioiSalesPOS.batchuser) && (silicon_ioi.doctype.ioiSalesPOS.batchuser.trim() != '')) {
			who = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid + ' / ' + silicon_ioi.doctype.ioiSalesPOS.batchuser;

			let method = path_cash_desk + '.ioi_cash_desk_who';

			frappe.call({  	method: method,
							args: {"user_id": silicon_ioi.doctype.ioiSalesPOS.batchuser_userid,
								"badge_id": silicon_ioi.doctype.ioiSalesPOS.batchuser
							},
							async: false,
							callback:function(r)	{
								who_name = r.message;
							}
			});

		}else{
			who = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
		}


		silicon_ioi.doctype.ioiCashDesk.open_cash_desk(silicon_ioi.doctype.ioiSalesPOS.cashdesk_id, fct_callback_msg, who, who_name);

	}

	static close_cash_desk()
	{
		let fct_callback_msg = function(ret_msg) {

			document.getElementById('ioi_sales_last_scan').innerText = '';

			if (ret_msg.length != 0) {

				silicon_ioi.doctype.ioiSalesPOS.add_log(ret_msg[0][0], ret_msg[0][1]);
				return false;

			}
		};

		let who = ''
		let who_name = '';

		if ((silicon_ioi.doctype.ioiSalesPOS.batchuser) && (silicon_ioi.doctype.ioiSalesPOS.batchuser.trim() != '')) {
			who = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid + ' / ' + silicon_ioi.doctype.ioiSalesPOS.batchuser;

			let method = path_cash_desk + '.ioi_cash_desk_who';

			frappe.call({  	method: method,
							args: {"user_id": silicon_ioi.doctype.ioiSalesPOS.batchuser_userid,
								"badge_id": silicon_ioi.doctype.ioiSalesPOS.batchuser
							},
							async: false,
							callback:function(r)	{
								who_name = r.message;
							}
			});

		}else{
			who = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
		}


		silicon_ioi.doctype.ioiCashDesk.close_cash_desk(silicon_ioi.doctype.ioiSalesPOS.cashdesk_id, fct_callback_msg, who, who_name);

	}


	// *************************************************************************************************************************************************
	// Detail
	// *************************************************************************************************************************************************

	static new_line(item_id = '', qty = 1)
	{
		document.getElementById('ioi_sales_last_scan').innerText = '';

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
			return false;
		}

		if (item_id != '') {
			silicon_ioi.doctype.ioiSalesPOS.do_new_line(item_id, true, qty);
		}else{
			let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
			let fields_len = '300, 500, 125, 150, 150, 300';
			let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
			let where = 'ioistatus = 2';
			let order_by = 'a.name asc';
			let parameter_with_stock = true;
			let site_id = silicon_ioi.doctype.ioiSalesPOS.doc.site_id;



			let fct_callback = function (return_value) {

				silicon_ioi.doctype.ioiSalesPOS.do_new_line(return_value, false, qty);
			};


			silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);
		}
	}

	static new_empty_line(qty = 0)
	{
		document.getElementById('ioi_sales_last_scan').innerText = '';

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
			return false;
		}

		silicon_ioi.doctype.ioiSalesPOS.do_new_line('', true, qty);

	}



	static new_line_shop(item_id = '', qty = 1)
	{
		document.getElementById('ioi_sales_last_scan').innerText = '';

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
			return false;
		}


		if (item_id != '') {
			silicon_ioi.doctype.ioiSalesPOS.do_new_line(item_id, true, qty);
		}else{
			let fct_callback = function (return_value, return_qty) {

				silicon_ioi.doctype.ioiSalesPOS.do_new_line(return_value, false, return_qty);
			};

			let catalog_id = silicon_ioi.doctype.ioiSalesPOS.doc.sales_catalog_id;
			let customer_id = silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_id;
			let division_id = silicon_ioi.doctype.ioiSalesPOS.doc.division_id;
			let condition_id = silicon_ioi.doctype.ioiSalesPOS.doc.sales_condition_id;
			let warehouse_id = silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id;
			let currency_id = silicon_ioi.doctype.ioiSalesPOS.doc.currency_id;
			let document_date = silicon_ioi.doctype.ioiSalesPOS.doc.document_date;

			silicon_ioi.ioiEShop.select_item_from_shop('700px', division_id, catalog_id, condition_id, customer_id, warehouse_id, fct_callback);
			//silicon_ioi.ioiEShop.select_item_from_shop('700px', division_id, catalog_id, condition_id, customer_id, warehouse_id, currency_id, document_date, fct_callback);
		}

	}


	static do_new_line(item_id = '', bypass_item_selection = false, qty=1)
	{

		if (document.getElementById('ioi_sales_pos_detail_new_button').disabled) {
			return false;
		}

		document.getElementById('ioi_sales_pos_detail_new_button').disabled = true;

		let scan_mode = 0;
		let item_mode = 0;
		let item_unit_id = ''

		if (item_id != '') {

			let method = path_sales_pos + '.ioi_sales_pos_get_item_info';



			frappe.call({  	method: method,
							args: {"item_id" : item_id},
							async: false,
							callback:function(r)	{

								item_mode = r.message.item_mode;
								scan_mode = r.message.scan_mode;
								item_unit_id = r.message.unit_id;

							}
			});
		}

		let idx = -1;

		if (item_id != '') {

			if (scan_mode == 0) {

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length == 0)))  {
					scan_mode = 1;
				}else {
					let item_found = false;

					for (var k = 0; k < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; k++) {

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].item_id.toUpperCase() == item_id.toUpperCase()) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].unit_id.toUpperCase() == item_unit_id.toUpperCase())) {
							idx = k;
							item_found = true;
							break;
						}
					}

					if (!item_found) {
						scan_mode = 1;
					}

				}

			}


			if (scan_mode == 1) {

				if (item_mode == 4) {

					if ((parseFloat(qty) < -1) || (parseFloat(qty) > 1)) {
						document.getElementById('ioi_sales_pos_detail_new_button').disabled = false;
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('SN item quantity has to be between -1 and 1'));
						raise;

					}
				}


				idx = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx] = JSON.parse(silicon_ioi.doctype.ioiSalesPOS.meta);
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].idx = idx+1;

				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].ioistatus = silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_condition_id = silicon_ioi.doctype.ioiSalesPOS.doc.sales_condition_id;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].warehouse_id = silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].dossier_id = silicon_ioi.doctype.ioiSalesPOS.doc.dossier_id;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_type = silicon_ioi.doctype.ioiSalesPOS.doc.vat_type;

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_type == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_type == '')) {
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_type = 'NAT';
				}
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_matching_id = silicon_ioi.doctype.ioiSalesPOS.doc.vat_matching_id;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate = silicon_ioi.doctype.ioiSalesPOS.doc.spec_vat_rate;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_pos_free_sales_allowed = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_tvac = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].fixed_price = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].discount_in_value = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].discount_only = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_included_in_price = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].is_option = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty = parseFloat(qty);
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].overstock_qty = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].invoiced_qty = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].stored_qty_mode = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_qty = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].min_qty_for_price = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_doc_currency = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_sys_currency = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].internal_value_line_option = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sub_total = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_cost_std_stamp = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_cost = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].document_date = silicon_ioi.doctype.ioiSalesPOS.doc.document_date;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].period_id = silicon_ioi.doctype.ioiSalesPOS.doc.period_id;

				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id = item_id;

				silicon_ioi.doctype.ioiSalesPOS.current_line_id = idx;

				silicon_ioi.doctype.ioiSalesPOS.populate_line(idx, 'ITEM_ID', '');

			}else{

				if (item_mode == 4) {

					if (((parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty) + parseFloat(qty)) < -1) ||
						((parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty) + parseFloat(qty)) > 1)) {
						document.getElementById('ioi_sales_pos_detail_new_button').disabled = false;
						silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('SN item quantity has to be between -1 and 1'));
						raise;
					}
				}


				if (item_mode != 3) {
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty) + parseFloat(qty);
				}
				silicon_ioi.doctype.ioiSalesPOS.populate_line(idx, 'QTY', '');
			}

			if (item_id != '') {

				silicon_ioi.doctype.ioiSalesPOS.check_stock();
			}



			if (scan_mode == 1) {

				let last_id = 0;

				if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

					if (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length > 0) {

						for (var k = 0; k < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; k++) {

							let s = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + k.toString()).id;

							while (s.indexOf('_') != -1) {
								s = s.substring(s.indexOf('_')+1, s.length);
							}

							s = s.trim()

							last_id = parseInt(s);
						}

						last_id++;

					}

				}

				let i = last_id;


				let html = '';

				let user_id = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
				let badge_id = silicon_ioi.doctype.ioiSalesPOS.batchuser;



				let data_fields = [];

				let method = path_user_grid_profile + '.ioi_sales_pos_user_grid_profile_get_grid_fields';

				frappe.call({
					method: method,
					args: {
						"user_id": user_id,
						"badge_id": badge_id
					},
					async: false,
					callback:function(r)	{
						data_fields = r.message
					}
				});

				silicon_ioi.doctype.ioiSalesPOS.profile_grid = 'DEF';

				if (data_fields.length != 0) {

					let grid_width = 30;

					for(var m=0; m < data_fields.length; m++) {

						silicon_ioi.doctype.ioiSalesPOS.profile_grid = data_fields[i].profile;

						grid_width += parseInt(data_fields[m].width);

						if ((data_fields[m].fieldname == 'delivered_qty') || (data_fields[m].fieldname == 'unit_price') || (data_fields[m].fieldname == 'discount') || (data_fields[m].fieldname == 'total')) {
							grid_width += 30;
						}

					}

					silicon_ioi.doctype.ioiSalesPOS.sales_pos_grid_width = parseInt(grid_width);



					if (i == 0) {
						html += '<table id="ioi_sales_pos_detail_table_detail" width=' + silicon_ioi.doctype.ioiSalesPOS.sales_pos_grid_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB">';
					}


					html += '<tr id="ioi_sales_pos_detail_table_detail_row_' + i.toString() + '" style="height:30px">';


					html += '<td width=30px style="vertical-align: middle;" align="center">';

					html += '<input type="checkbox" id="ioi_sales_pos_detail_table_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 0px; left: 0px;" ';
					html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
					html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
					html += '>';

					html += '</td>';

					for(var j=0; j < data_fields.length; j++) {

						if (data_fields[j].fieldname == 'item_description') {

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id != '')) {

								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
									html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')' + '</td>';
								}else{
									html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
								}
							}else{

								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty != 0)) {

									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
										html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')' + '</td>';
									}else{
										html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
									}
								}else{
									html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
								}

							}
						}else if (data_fields[j].fieldname == 'delivered_qty') {

							let s = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toString();

							if ((s.indexOf('.') != -1) || (s.indexOf(',') != -1)) {
								html += '<td id="ioi_sales_pos_detail_table_detail_delivered_qty_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toFixed(2)) + '&nbsp;</td>';
							}else{
								html += '<td id="ioi_sales_pos_detail_table_detail_delivered_qty_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty + '&nbsp;</td>';
							}

							html += '<td width=30px style="vertical-align: middle;" align="center">';
							html += '	<img id="ioi_sales_pos_qt_edit_button_' + i.toString() + '" title="' + __("Edit qty") + '" src="/assets/silicon_ioi/images/buttons/edit_qty.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
							html += '</td>';

						}else if (data_fields[j].fieldname == 'unit_price') {

							let unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00));

							html += '<td id="ioi_sales_pos_detail_table_detail_unit_price_' + i.toString() + '" width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(unit_price.toFixed(2)) + '&nbsp;</td>';

							html += '<td width=30px style="vertical-align: middle;">';
							html += '	<img id="ioi_sales_pos_price_edit_button_' + i.toString() + '" title="' + __("Edit price") + '" src="/assets/silicon_ioi/images/buttons/edit_price.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
							html += '</td>';

						}else if (data_fields[j].fieldname == 'discount') {

							let discount = 0;

							if ((parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) != 0) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) != 0)) {

								discount = ((1 - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) / 100.00)) * (1 - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) / 100.00))) * 100

							}else if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) != 0) {

								discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount);

							}else if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) != 0) {

								discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount);
							}

							html += '<td id="ioi_sales_pos_detail_table_detail_discount_' + i.toString() + '" width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(discount.toFixed(2)) + '&nbsp;%&nbsp;</td>';

							html += '<td width=30px style="vertical-align: middle;">';
							html += '	<img id="ioi_sales_pos_discount_edit_button_' + i.toString() + '" title="' + __("Edit discount") + '" src="/assets/silicon_ioi/images/buttons/edit_discount.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"> </img>';
							html += '</td>';

						}else if (data_fields[j].fieldname == 'total') {

							let total_line = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00))

							html += '<td id="ioi_sales_pos_detail_table_detail_total_' + i.toString() + '" width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(total_line.toFixed(2)) + '&nbsp;</td>';

							html += '<td width=30px style="vertical-align: middle;" align="center">';
							html += '	<img id="ioi_sales_pos_item_edit_button_' + i.toString() + '" title="' + __("Item & Code ref") + '" src="/assets/silicon_ioi/images/buttons/edit_qty.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
							html += '</td>';
						}else{

							if ((data_fields[j].fieldtype.toUpperCase() == 'DATA') || (data_fields[j].fieldtype.toUpperCase() == 'ATTACH') || (data_fields[j].fieldtype.toUpperCase() == 'ATTACH IMAGE') ||
								(data_fields[j].fieldtype.toUpperCase() == 'AUTOCOMPLETE') || (data_fields[j].fieldtype.toUpperCase() == 'LINK') || (data_fields[j].fieldtype.toUpperCase() == 'DYNAMIC LINK') ||
								(data_fields[j].fieldtype.toUpperCase() == 'READ ONLY')) {

								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
								}else{
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
								}
							}else if ((data_fields[j].fieldtype.toUpperCase() == 'FLOAT') || (data_fields[j].fieldtype.toUpperCase() == 'PERCENT') || (data_fields[j].fieldtype.toUpperCase() == 'CURRENCY')) {
								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
								}else{

									if (data_fields[j].fieldtype.toUpperCase() == 'PERCENT') {
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname].toFixed(2)) + '&nbsp;%&nbsp;</td>';
									}else{
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname].toFixed(2)) + '&nbsp;</td>';
									}
								}
							}else if (data_fields[j].fieldtype.toUpperCase() == 'INT') {
								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
								}else{
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '&nbsp;</td>';
								}
							}else if (data_fields[j].fieldtype.toUpperCase() == 'DURATION') {
								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
								}else{
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '&nbsp;</td>';
								}
							}else if (data_fields[j].fieldtype.toUpperCase() == 'DATE') {
								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
								}else{
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
								}
							}else if (data_fields[j].fieldtype.toUpperCase() == 'DATETIME') {
								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
								}else{
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname].substring(0, 19) + '</td>';
								}
							}else if (data_fields[j].fieldtype.toUpperCase() == 'TIME') {
								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
								}else{
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname].substring(0, 8) + '</td>';
								}
							}else if (data_fields[j].fieldtype.toUpperCase() == 'SELECT') {
								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
								}else{

									if (data_fields[j].options != '') {
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
									}else{

										if (data_fields[j].fieldname == 'alert_margin_mode') {

											let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

											if ((v == 0) || (v == '0')) {
												v = __("Cost margin");
											}else{
												v = __("Sales margin");
											}

											html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

										}else if (data_fields[j].fieldname == 'invoicing_mode') {

											let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

											if ((v == 0) || (v == '0')) {
												v = __("Default");
											}else if ((v == 1) || (v == '1')) {
												v = __("Up to ordered");
											}else if ((v == 2) || (v == '2')) {
												v = __("Fix ordered");
											}else if ((v == 3) || (v == '3')) {
												v = __("Open order");
											}

											html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

										}else if (data_fields[j].fieldname == 'is_withme') {

											let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

											if ((v == 0) || (v == '0')) {
												v = __("Detail");
											}else{
												v = __("Document");
											}

											html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

										}else if (data_fields[j].fieldname == 'margin_alert') {

											let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

											if ((v == 0) || (v == '0')) {
												v = __("Ok");
											}else if ((v == -1) || (v == '-1')) {
												v = __("Margin alert");
											}else{
												v = __("Margin error");
											}

											html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

										}else if (data_fields[j].fieldname == 'stored_qty_mode') {

											let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

											if ((v == 0) || (v == '0')) {
												v = __("Good");
											}else if ((v == 1) || (v == '1')) {
												v = __("To check");
											}else if ((v == 2) || (v == '2')) {
												v = __("Bad");
											}

											html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

										}else if (data_fields[j].fieldname == 'vat_service') {

											let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

											if (v == '')  {
												v = __("Empty");
											}else if (v == 'G') {
												v = __("Goods");
											}else if (v == 'S') {
												v = __("Service");
											}

											html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

										}else if (data_fields[j].fieldname == 'vat_type') {

											let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

											if (v == 'NAT')  {
												v = __("National");
											}else if (v == 'NCO') {
												v = __("National cocontractant");
											}else if (v == 'EEC') {
												v = __("Europe");
											}else if (v == 'INT') {
												v = __("International");
											}else if (v == 'XXX') {
												v = __("Others");
											}

											html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

										}else{
											html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
										}



									}
								}
							}else if (data_fields[j].fieldtype.toUpperCase() == 'CHECK') {

								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
								}else{
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="center">';

									html += '<input type="checkbox" style="postion:absolute; top: 0px; left: 0px;" disabled ';
									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 1) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == true) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'true')) {
										html += ' checked '
									}
									html += '>';

									html += '</td>';
								}
							}else if (data_fields[j].fieldtype.toUpperCase() == 'COLOR') {
								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
								}else{
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
								}
							}

						}

					}

					html += '</tr>';

					if (i == 0) {
						html += '</table>';
					}

				}else{

					if (i == 0) {

						html += '<table id="ioi_sales_pos_detail_table_detail" width=' + silicon_ioi.doctype.ioiSalesPOS.sales_pos_grid_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB">';
					}


					html += '<tr id="ioi_sales_pos_detail_table_detail_row_' + i.toString() + '" style="height:30px">';


					html += '<td width=30px style="vertical-align: middle;" align="center">';

					html += '<input type="checkbox" id="ioi_sales_pos_detail_table_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 0px; left: 0px;" ';
					html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
					html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
					html += '>';

					html += '</td>';

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id != '')) {

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
							html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')' + '</td>';
						}else{
							html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
						}
					}else{

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty != 0)) {

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
								html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')' + '</td>';
							}else{
								html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
							}
						}else{
							html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
						}

					}

					let s = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toString();

					if ((s.indexOf('.') != -1) || (s.indexOf(',') != -1)) {
						html += '<td id="ioi_sales_pos_detail_table_detail_delivered_qty_' + i.toString() + '"  width=60px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toFixed(2)) + '&nbsp;</td>';
					}else{
						html += '<td id="ioi_sales_pos_detail_table_detail_delivered_qty_' + i.toString() + '"  width=60px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty + '&nbsp;</td>';
					}

					html += '<td width=30px style="vertical-align: middle;" align="center">';
					html += '	<img id="ioi_sales_pos_qt_edit_button_' + i.toString() + '" title="' + __("Edit qty") + '" src="/assets/silicon_ioi/images/buttons/edit_qty.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
					html += '</td>';


					let unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00));

					html += '<td id="ioi_sales_pos_detail_table_detail_unit_price_' + i.toString() + '" width=80px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(unit_price.toFixed(2)) + '&nbsp;</td>';

					html += '<td width=30px style="vertical-align: middle;">';
					html += '	<img id="ioi_sales_pos_price_edit_button_' + i.toString() + '" title="' + __("Edit price") + '" src="/assets/silicon_ioi/images/buttons/edit_price.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
					html += '</td>';

					let discount = 0;

					if ((parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) != 0) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) != 0)) {

						discount = ((1 - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) / 100.00)) * (1 - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) / 100.00))) * 100

					}else if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) != 0) {

						discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount);

					}else if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) != 0) {

						discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount);
					}

					html += '<td id="ioi_sales_pos_detail_table_detail_discount_' + i.toString() + '" width=70px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(discount.toFixed(2)) + '&nbsp;%&nbsp;</td>';

					html += '<td width=30px style="vertical-align: middle;">';
					html += '	<img id="ioi_sales_pos_discount_edit_button_' + i.toString() + '" title="' + __("Edit discount") + '" src="/assets/silicon_ioi/images/buttons/edit_discount.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"> </img>';
					html += '</td>';


					let total_line = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00))

					html += '<td id="ioi_sales_pos_detail_table_detail_total_' + i.toString() + '" width=90px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(total_line.toFixed(2)) + '&nbsp;</td>';

					html += '<td width=30px style="vertical-align: middle;" align="center">';
					html += '	<img id="ioi_sales_pos_item_edit_button_' + i.toString() + '" title="' + __("Item & Code ref") + '" src="/assets/silicon_ioi/images/buttons/edit_qty.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
					html += '</td>';

					html += '</tr>';


					if (i == 0) {
						html += '</table>';
					}
				}

				if (i == 0) {
					document.getElementById('ioi_sales_pos_detail_area').insertAdjacentHTML('beforeend', html);
				}else{
					document.getElementById('ioi_sales_pos_detail_table_detail').insertAdjacentHTML('beforeend', html);
				}

				let new_idx = i;
				silicon_ioi.doctype.ioiSalesPOS.current_line_id = new_idx;

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(400).then(() => {


					document.getElementById('ioi_sales_pos_detail_area').scrollTop = document.getElementById('ioi_sales_pos_detail_area').scrollHeight;

					if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

						let fct_click = function() {

							for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

								if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

									if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {

										if (silicon_ioi.doctype.ioiSalesPOS.is_dark_mode == 0) {
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#FFFFFF';
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#000000';
										}else{
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#1C2126';
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#FFFFFF';


										}
									}

									document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = false;
								}
							}

							this.checked = true;

							for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

								if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

									if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {

										if (silicon_ioi.doctype.ioiSalesPOS.is_dark_mode == 0) {
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;
										}else{
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#000000';
										}

									}
								}

								if (document.getElementById('ioi_sales_pos_detail_table_detail_discount_' + i.toString())) {

									const cell = document.getElementById('ioi_sales_pos_detail_table_detail_discount_' + i.toString());

									if (cell.onmouseenter == null) {
										cell.onmouseenter = (e) => {
											let timeout = setTimeout(() => {
												let i = this.id;

												while (i.indexOf('_') != -1) {
													i = i.substring(i.indexOf('_')+1, i.length);
												}

												silicon_ioi.doctype.ioiSalesPOS.display_next_item_budget_discounts(parseInt(i), e);
											}, 600);

											cell.onmouseleave = () => {
												clearTimeout(timeout)};
										};
									}
								}


							}

							let s = this.id;

							while (s.indexOf('_') != -1) {
								s = s.substring(s.indexOf('_')+1, s.length)
							}

							if (s.trim() != '') {

								if (parseInt(s) == document.getElementById('ioi_sales_pos_detail_table_detail').rows.length-1) {
									document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + s).focus();
								}
							}



						}

						let fct_edit_item = function() {

							silicon_ioi.doctype.ioiSalesPOS.edit_item_line(this);
						}


						let fct_edit_qty = function() {

							silicon_ioi.doctype.ioiSalesPOS.edit_qty_line(this);
						}


						let fct_edit_price = function() {

							silicon_ioi.doctype.ioiSalesPOS.edit_price_line(this);
						}


						let fct_edit_discount = function() {

							silicon_ioi.doctype.ioiSalesPOS.edit_discount_line(this);
						}

						let fct_row_click = function() {

							let s = this.id;

							while (s.indexOf('_') != -1) {
								s = s.substring(s.indexOf('_')+1, s.length);
							}

							s = s.trim(s);

							document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + s).click();

						}


						let fct_check_keydown = function(event)
						{
							if (event.keyCode == 40) {

								let s = this.id;

								while (s.indexOf('_') != -1) {
									s = s.substring(s.indexOf('_')+1, s.length);
								}

								s = s.trim(s);

								if (parseInt(s) == document.getElementById('ioi_sales_pos_detail_table_detail').rows.length-1) {
									document.getElementById('ioi_sales_pos_detail_new_button').click();
								}
							}
						}




						for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

							if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {
								document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).onclick = fct_click;
								document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).onkeydown = fct_check_keydown;

							}

							if (document.getElementById('ioi_sales_pos_item_edit_button_' + i.toString())) {
								document.getElementById('ioi_sales_pos_item_edit_button_' + i.toString()).onclick = fct_edit_item;
							}


							if (document.getElementById('ioi_sales_pos_qt_edit_button_' + i.toString())) {
								document.getElementById('ioi_sales_pos_qt_edit_button_' + i.toString()).onclick = fct_edit_qty;
							}

							if (document.getElementById('ioi_sales_pos_price_edit_button_' + i.toString())) {
								document.getElementById('ioi_sales_pos_price_edit_button_' + i.toString()).onclick = fct_edit_price;
							}


							if (document.getElementById('ioi_sales_pos_discount_edit_button_' + i.toString())) {
								document.getElementById('ioi_sales_pos_discount_edit_button_' + i.toString()).onclick = fct_edit_discount;
							}


							document.getElementById('ioi_sales_pos_detail_table_detail_row_' + i.toString()).onclick = fct_row_click;


						}

						if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + new_idx.toString())) {

							document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + new_idx.toString()).click();
						}


					}

					silicon_ioi.doctype.ioiSalesPOS.populate_line_after(idx, 'NEW_LINE');
					document.getElementById('ioi_sales_pos_detail_new_button').disabled = false;

					silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('New line added : Item') + ' = ' + item_id + ' , ' + __('Qty') + ' = ' + qty.toString());

				});
			}else{

				silicon_ioi.doctype.ioiSalesPOS.populate_line_after(idx, 'NEW_LINE');

				document.getElementById('ioi_sales_pos_detail_new_button').disabled = false;

				document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + (idx).toString()).click();

				if (item_mode != 3) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Line') + ' ' + (parseInt(idx)+1).toString() + ' : ' + __('Add') + ' ' + qty.toString() + ' ' + __('to the quantity'));
				}
			}

			if (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus == 0) {

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length == 0))) {
					document.getElementById('ioi_sales_pos_customer_id').disabled = false;
				}else{
					document.getElementById('ioi_sales_pos_customer_id').disabled = true;
				}
			}
		}else{


			idx = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length;
			silicon_ioi.doctype.ioiSalesPOS.current_line_id = idx;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx] = JSON.parse(silicon_ioi.doctype.ioiSalesPOS.meta);
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].idx = idx+1;

			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].ioistatus = silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_condition_id = silicon_ioi.doctype.ioiSalesPOS.doc.sales_condition_id;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].warehouse_id = silicon_ioi.doctype.ioiSalesPOS.doc.warehouse_id;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].dossier_id = silicon_ioi.doctype.ioiSalesPOS.doc.dossier_id;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_type = silicon_ioi.doctype.ioiSalesPOS.doc.vat_type;

			if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_type == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_type == '')) {
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_type = 'NAT';
			}
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_matching_id = silicon_ioi.doctype.ioiSalesPOS.doc.vat_matching_id;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate = silicon_ioi.doctype.ioiSalesPOS.doc.spec_vat_rate;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_pos_free_sales_allowed = 1;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_tvac = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].fixed_price = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].discount_in_value = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].discount_only = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_included_in_price = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].is_option = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty = parseFloat(qty);
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].overstock_qty = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].invoiced_qty = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].stored_qty_mode = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_qty = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].min_qty_for_price = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_doc_currency = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_sys_currency = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].internal_value_line_option = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sub_total = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_cost_std_stamp = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_cost = 0;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].document_date = silicon_ioi.doctype.ioiSalesPOS.doc.document_date;
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].period_id = silicon_ioi.doctype.ioiSalesPOS.doc.period_id;

			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id = '';
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description = '';

			let method = path_sales_pos + '.ioi_sales_pos_get_default_unit';

			frappe.call({  	method: method,
							args: {
							},
							async: false,
							callback:function(r)	{

								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id = r.message;

							}
			});


			let last_id = 0;

			if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

				if (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length > 0) {

					for (var k = 0; k < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; k++) {

						let s = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + k.toString()).id;

						while (s.indexOf('_') != -1) {
							s = s.substring(s.indexOf('_')+1, s.length);
						}

						s = s.trim()

						last_id = parseInt(s);
					}

					last_id++;

				}

			}

			let i = last_id;


			let html = '';


			let user_id = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
			let badge_id = silicon_ioi.doctype.ioiSalesPOS.batchuser;


			let data_fields = [];

			method = path_user_grid_profile + '.ioi_sales_pos_user_grid_profile_get_grid_fields';

			frappe.call({
				method: method,
				args: {
					"user_id": user_id,
					"badge_id": badge_id
				},
				async: false,
				callback:function(r)	{
					data_fields = r.message
				}
			});

			silicon_ioi.doctype.ioiSalesPOS.profile_grid = 'DEF';

			if (data_fields.length != 0) {

				let grid_width = 30;

				for(var m=0; m < data_fields.length; m++) {

					silicon_ioi.doctype.ioiSalesPOS.profile_grid = data_fields[i].profile;

					grid_width += parseInt(data_fields[m].width);

					if ((data_fields[m].fieldname == 'delivered_qty') || (data_fields[m].fieldname == 'unit_price') || (data_fields[m].fieldname == 'discount') || (data_fields[m].fieldname == 'total')) {
						grid_width += 30;
					}

				}

				silicon_ioi.doctype.ioiSalesPOS.sales_pos_grid_width = parseInt(grid_width);



				if (i == 0) {
					html += '<table id="ioi_sales_pos_detail_table_detail" width=' + silicon_ioi.doctype.ioiSalesPOS.sales_pos_grid_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB">';
				}


				html += '<tr id="ioi_sales_pos_detail_table_detail_row_' + i.toString() + '" style="height:30px">';


				html += '<td width=30px style="vertical-align: middle;" align="center">';

				html += '<input type="checkbox" id="ioi_sales_pos_detail_table_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 0px; left: 0px;" ';
				html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
				html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
				html += '>';

				html += '</td>';

				for(var j=0; j < data_fields.length; j++) {

					if (data_fields[j].fieldname == 'item_description') {

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id != '')) {

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
								html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')' + '</td>';
							}else{
								html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
							}
						}else{

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty != 0)) {

								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
									html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')' + '</td>';
								}else{
									html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
								}
							}else{
								html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
							}

						}
					}else if (data_fields[j].fieldname == 'delivered_qty') {

						let s = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toString();

						if ((s.indexOf('.') != -1) || (s.indexOf(',') != -1)) {
							html += '<td id="ioi_sales_pos_detail_table_detail_delivered_qty_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toFixed(2)) + '&nbsp;</td>';
						}else{
							html += '<td id="ioi_sales_pos_detail_table_detail_delivered_qty_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty + '&nbsp;</td>';
						}

						html += '<td width=30px style="vertical-align: middle;" align="center">';
						html += '	<img id="ioi_sales_pos_qt_edit_button_' + i.toString() + '" title="' + __("Edit qty") + '" src="/assets/silicon_ioi/images/buttons/edit_qty.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
						html += '</td>';

					}else if (data_fields[j].fieldname == 'unit_price') {

						let unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00));

						html += '<td id="ioi_sales_pos_detail_table_detail_unit_price_' + i.toString() + '" width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(unit_price.toFixed(2)) + '&nbsp;</td>';

						html += '<td width=30px style="vertical-align: middle;">';
						html += '	<img id="ioi_sales_pos_price_edit_button_' + i.toString() + '" title="' + __("Edit price") + '" src="/assets/silicon_ioi/images/buttons/edit_price.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
						html += '</td>';

					}else if (data_fields[j].fieldname == 'discount') {

						let discount = 0;

						if ((parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) != 0) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) != 0)) {

							discount = ((1 - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) / 100.00)) * (1 - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) / 100.00))) * 100

						}else if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) != 0) {

							discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount);

						}else if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) != 0) {

							discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount);
						}

						html += '<td id="ioi_sales_pos_detail_table_detail_discount_' + i.toString() + '" width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(discount.toFixed(2)) + '&nbsp;%&nbsp;</td>';

						html += '<td width=30px style="vertical-align: middle;">';
						html += '	<img id="ioi_sales_pos_discount_edit_button_' + i.toString() + '" title="' + __("Edit discount") + '" src="/assets/silicon_ioi/images/buttons/edit_discount.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"> </img>';
						html += '</td>';

					}else if (data_fields[j].fieldname == 'total') {

						let total_line = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00))

						html += '<td id="ioi_sales_pos_detail_table_detail_total_' + i.toString() + '" width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(total_line.toFixed(2)) + '&nbsp;</td>';

						html += '<td width=30px style="vertical-align: middle;" align="center">';
						html += '	<img id="ioi_sales_pos_item_edit_button_' + i.toString() + '" title="' + __("Item & Code ref") + '" src="/assets/silicon_ioi/images/buttons/edit_qty.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
						html += '</td>';
					}else{

						if ((data_fields[j].fieldtype.toUpperCase() == 'DATA') || (data_fields[j].fieldtype.toUpperCase() == 'ATTACH') || (data_fields[j].fieldtype.toUpperCase() == 'ATTACH IMAGE') ||
							(data_fields[j].fieldtype.toUpperCase() == 'AUTOCOMPLETE') || (data_fields[j].fieldtype.toUpperCase() == 'LINK') || (data_fields[j].fieldtype.toUpperCase() == 'DYNAMIC LINK') ||
							(data_fields[j].fieldtype.toUpperCase() == 'READ ONLY')) {

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
							}else{
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
							}
						}else if ((data_fields[j].fieldtype.toUpperCase() == 'FLOAT') || (data_fields[j].fieldtype.toUpperCase() == 'PERCENT') || (data_fields[j].fieldtype.toUpperCase() == 'CURRENCY')) {
							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
							}else{

								if (data_fields[j].fieldtype.toUpperCase() == 'PERCENT') {
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname].toFixed(2)) + '&nbsp;%&nbsp;</td>';
								}else{
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname].toFixed(2)) + '&nbsp;</td>';
								}
							}
						}else if (data_fields[j].fieldtype.toUpperCase() == 'INT') {
							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
							}else{
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '&nbsp;</td>';
							}
						}else if (data_fields[j].fieldtype.toUpperCase() == 'DURATION') {
							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
							}else{
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '&nbsp;</td>';
							}
						}else if (data_fields[j].fieldtype.toUpperCase() == 'DATE') {
							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
							}else{
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
							}
						}else if (data_fields[j].fieldtype.toUpperCase() == 'DATETIME') {
							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
							}else{
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname].substring(0, 19) + '</td>';
							}
						}else if (data_fields[j].fieldtype.toUpperCase() == 'TIME') {
							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
							}else{
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname].substring(0, 8) + '</td>';
							}
						}else if (data_fields[j].fieldtype.toUpperCase() == 'SELECT') {
							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
							}else{

								if (data_fields[j].options != '') {
									html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
								}else{

									if (data_fields[j].fieldname == 'alert_margin_mode') {

										let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

										if ((v == 0) || (v == '0')) {
											v = __("Cost margin");
										}else{
											v = __("Sales margin");
										}

										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

									}else if (data_fields[j].fieldname == 'invoicing_mode') {

										let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

										if ((v == 0) || (v == '0')) {
											v = __("Default");
										}else if ((v == 1) || (v == '1')) {
											v = __("Up to ordered");
										}else if ((v == 2) || (v == '2')) {
											v = __("Fix ordered");
										}else if ((v == 3) || (v == '3')) {
											v = __("Open order");
										}

										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

									}else if (data_fields[j].fieldname == 'is_withme') {

										let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

										if ((v == 0) || (v == '0')) {
											v = __("Detail");
										}else{
											v = __("Document");
										}

										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

									}else if (data_fields[j].fieldname == 'margin_alert') {

										let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

										if ((v == 0) || (v == '0')) {
											v = __("Ok");
										}else if ((v == -1) || (v == '-1')) {
											v = __("Margin alert");
										}else{
											v = __("Margin error");
										}

										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

									}else if (data_fields[j].fieldname == 'stored_qty_mode') {

										let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

										if ((v == 0) || (v == '0')) {
											v = __("Good");
										}else if ((v == 1) || (v == '1')) {
											v = __("To check");
										}else if ((v == 2) || (v == '2')) {
											v = __("Bad");
										}

										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

									}else if (data_fields[j].fieldname == 'vat_service') {

										let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

										if (v == '')  {
											v = __("Empty");
										}else if (v == 'G') {
											v = __("Goods");
										}else if (v == 'S') {
											v = __("Service");
										}

										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

									}else if (data_fields[j].fieldname == 'vat_type') {

										let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

										if (v == 'NAT')  {
											v = __("National");
										}else if (v == 'NCO') {
											v = __("National cocontractant");
										}else if (v == 'EEC') {
											v = __("Europe");
										}else if (v == 'INT') {
											v = __("International");
										}else if (v == 'XXX') {
											v = __("Others");
										}

										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

									}else{
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
									}



								}
							}
						}else if (data_fields[j].fieldtype.toUpperCase() == 'CHECK') {

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
							}else{
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="center">';

								html += '<input type="checkbox" style="postion:absolute; top: 0px; left: 0px;" disabled ';
								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 1) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == true) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'true')) {
									html += ' checked '
								}
								html += '>';

								html += '</td>';
							}
						}else if (data_fields[j].fieldtype.toUpperCase() == 'COLOR') {
							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
							}else{
								html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
							}
						}

					}

				}

				html += '</tr>';

				if (i == 0) {
					html += '</table>';
				}

			}else{

				if (i == 0) {

					html += '<table id="ioi_sales_pos_detail_table_detail" width=' + silicon_ioi.doctype.ioiSalesPOS.sales_pos_grid_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB">';
				}


				html += '<tr id="ioi_sales_pos_detail_table_detail_row_' + i.toString() + '" style="height:30px">';


				html += '<td width=30px style="vertical-align: middle;" align="center">';

				html += '<input type="checkbox" id="ioi_sales_pos_detail_table_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 0px; left: 0px;" ';
				html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
				html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
				html += '>';

				html += '</td>';

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id != '')) {

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
						html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')' + '</td>';
					}else{
						html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
					}
				}else{

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty != 0)) {

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
							html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')' + '</td>';
						}else{
							html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
						}
					}else{
						html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
					}

				}

				let s = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toString();

				if ((s.indexOf('.') != -1) || (s.indexOf(',') != -1)) {
					html += '<td id="ioi_sales_pos_detail_table_detail_delivered_qty_' + i.toString() + '"  width=60px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toFixed(2)) + '&nbsp;</td>';
				}else{
					html += '<td id="ioi_sales_pos_detail_table_detail_delivered_qty_' + i.toString() + '"  width=60px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty + '&nbsp;</td>';
				}

				html += '<td width=30px style="vertical-align: middle;" align="center">';
				html += '	<img id="ioi_sales_pos_qt_edit_button_' + i.toString() + '" title="' + __("Edit qty") + '" src="/assets/silicon_ioi/images/buttons/edit_qty.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
				html += '</td>';


				let unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00));

				html += '<td id="ioi_sales_pos_detail_table_detail_unit_price_' + i.toString() + '" width=80px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(unit_price.toFixed(2)) + '&nbsp;</td>';

				html += '<td width=30px style="vertical-align: middle;">';
				html += '	<img id="ioi_sales_pos_price_edit_button_' + i.toString() + '" title="' + __("Edit price") + '" src="/assets/silicon_ioi/images/buttons/edit_price.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
				html += '</td>';

				let discount = 0;

				if ((parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) != 0) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) != 0)) {

					discount = ((1 - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) / 100.00)) * (1 - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) / 100.00))) * 100

				}else if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) != 0) {

					discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount);

				}else if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) != 0) {

					discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount);
				}

				html += '<td id="ioi_sales_pos_detail_table_detail_discount_' + i.toString() + '" width=70px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(discount.toFixed(2)) + '&nbsp;%&nbsp;</td>';

				html += '<td width=30px style="vertical-align: middle;">';
				html += '	<img id="ioi_sales_pos_discount_edit_button_' + i.toString() + '" title="' + __("Edit discount") + '" src="/assets/silicon_ioi/images/buttons/edit_discount.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"> </img>';
				html += '</td>';


				let total_line = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00))

				html += '<td id="ioi_sales_pos_detail_table_detail_total_' + i.toString() + '" width=90px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(total_line.toFixed(2)) + '&nbsp;</td>';

				html += '<td width=30px style="vertical-align: middle;" align="center">';
				html += '	<img id="ioi_sales_pos_item_edit_button_' + i.toString() + '" title="' + __("Item & Code ref") + '" src="/assets/silicon_ioi/images/buttons/edit_qty.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
				html += '</td>';

				html += '</tr>';


				if (i == 0) {
					html += '</table>';
				}
			}

			if (i == 0) {
				document.getElementById('ioi_sales_pos_detail_area').insertAdjacentHTML('beforeend', html);
			}else{
				document.getElementById('ioi_sales_pos_detail_table_detail').insertAdjacentHTML('beforeend', html);
			}

			let new_idx = i;
			silicon_ioi.doctype.ioiSalesPOS.current_line_id = new_idx;

			silicon_ioi.doctype.ioiSalesPOS.sleep_static(400).then(() => {


				document.getElementById('ioi_sales_pos_detail_area').scrollTop = document.getElementById('ioi_sales_pos_detail_area').scrollHeight;

				if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

					let fct_click = function() {

						for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

							if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

								if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {

									if (silicon_ioi.doctype.ioiSalesPOS.is_dark_mode == 0) {
										document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#FFFFFF';
										document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#000000';
									}else{
										document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#1C2126';
										document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#FFFFFF';


									}
								}

								document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = false;
							}
						}

						this.checked = true;

						for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

							if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

								if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {

									if (silicon_ioi.doctype.ioiSalesPOS.is_dark_mode == 0) {
										document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;
									}else{
										document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;
										document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#000000';
									}

								}
							}

							if (document.getElementById('ioi_sales_pos_detail_table_detail_discount_' + i.toString())) {

								const cell = document.getElementById('ioi_sales_pos_detail_table_detail_discount_' + i.toString());

								if (cell.onmouseenter == null) {
									cell.onmouseenter = (e) => {
										let timeout = setTimeout(() => {
											let i = this.id;

											while (i.indexOf('_') != -1) {
												i = i.substring(i.indexOf('_')+1, i.length);
											}

											silicon_ioi.doctype.ioiSalesPOS.display_next_item_budget_discounts(parseInt(i), e);
										}, 600);

										cell.onmouseleave = () => {
											clearTimeout(timeout)};
									};
								}
							}


						}

						let s = this.id;

						while (s.indexOf('_') != -1) {
							s = s.substring(s.indexOf('_')+1, s.length)
						}

						if (s.trim() != '') {

							if (parseInt(s) == document.getElementById('ioi_sales_pos_detail_table_detail').rows.length-1) {
								document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + s).focus();
							}
						}



					}

					let fct_edit_item = function() {

						silicon_ioi.doctype.ioiSalesPOS.edit_item_line(this);
					}


					let fct_edit_qty = function() {

						silicon_ioi.doctype.ioiSalesPOS.edit_qty_line(this);
					}


					let fct_edit_price = function() {

						silicon_ioi.doctype.ioiSalesPOS.edit_price_line(this);
					}


					let fct_edit_discount = function() {

						silicon_ioi.doctype.ioiSalesPOS.edit_discount_line(this);
					}

					let fct_row_click = function() {

						let s = this.id;

						while (s.indexOf('_') != -1) {
							s = s.substring(s.indexOf('_')+1, s.length);
						}

						s = s.trim(s);

						document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + s).click();

					}


					let fct_check_keydown = function(event)
					{
						if (event.keyCode == 40) {

							let s = this.id;

							while (s.indexOf('_') != -1) {
								s = s.substring(s.indexOf('_')+1, s.length);
							}

							s = s.trim(s);

							if (parseInt(s) == document.getElementById('ioi_sales_pos_detail_table_detail').rows.length-1) {
								document.getElementById('ioi_sales_pos_detail_new_button').click();
							}
						}
					}




					for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

						if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {
							document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).onclick = fct_click;
							document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).onkeydown = fct_check_keydown;

						}

						if (document.getElementById('ioi_sales_pos_item_edit_button_' + i.toString())) {
							document.getElementById('ioi_sales_pos_item_edit_button_' + i.toString()).onclick = fct_edit_item;
						}


						if (document.getElementById('ioi_sales_pos_qt_edit_button_' + i.toString())) {
							document.getElementById('ioi_sales_pos_qt_edit_button_' + i.toString()).onclick = fct_edit_qty;
						}

						if (document.getElementById('ioi_sales_pos_price_edit_button_' + i.toString())) {
							document.getElementById('ioi_sales_pos_price_edit_button_' + i.toString()).onclick = fct_edit_price;
						}


						if (document.getElementById('ioi_sales_pos_discount_edit_button_' + i.toString())) {
							document.getElementById('ioi_sales_pos_discount_edit_button_' + i.toString()).onclick = fct_edit_discount;
						}


						document.getElementById('ioi_sales_pos_detail_table_detail_row_' + i.toString()).onclick = fct_row_click;


					}

					if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + new_idx.toString())) {

						document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + new_idx.toString()).click();
					}


				}

				silicon_ioi.doctype.ioiSalesPOS.populate_line_after(idx, 'NEW_LINE');
				document.getElementById('ioi_sales_pos_detail_new_button').disabled = false;

				silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('New line added : Item') + ' = ' + item_id + ' , ' + __('Qty') + ' = ' + qty.toString());

				silicon_ioi.doctype.ioiSalesPOS.edit_item_line(document.getElementById('ioi_sales_pos_item_edit_button_' + new_idx.toString()), true);


			});

		}


	}

	static check_stock()
	{
		let method = path_sales_pos + '.ioi_sales_pos_check_stock';

		let dc = silicon_ioi.doctype.ioiSalesPOS.doc;

		frappe.call({  	method: method,
						args: { "doc": dc,
						},
						async: false,
						callback:function(r)	{

							silicon_ioi.doctype.ioiSalesPOS.doc = r.message;

						}
		});


	}

	static delete_line(confirm=true)
	{
		document.getElementById('ioi_sales_last_scan').innerText = '';

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
			return false;
		}

		if (!document.getElementById('ioi_sales_pos_detail_table_detail')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
			return false;

		}

		if (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length == 0) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
			return false;

		}


		if (!confirm) {
			silicon_ioi.doctype.ioiSalesPOS.do_delete_line();
		}else{

			let msg = __('Delete this line ?');

			frappe.confirm(	msg,
							() => 	{
										silicon_ioi.doctype.ioiSalesPOS.do_delete_line();
									},
							() => 	{
									}
			);
		}
	}

	static do_delete_line()
	{
		let element = '';

		for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

			if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

				if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {
					element = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).id;
					break;
				}
			}
		}

		if (element != '')
		{
			let s = element;

			while (s.indexOf('_') != -1) {
				s = s.substring(s.indexOf('_')+1, s.length);
			}

			s = s.trim();

			let original_id = s;

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

				if (parseInt(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].idx) == parseInt(s)+1) {

					silicon_ioi.doctype.ioiSalesPOS.display_on_screen('DELETE_LINE', parseInt(s));
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.splice(i, 1);



					document.getElementById("ioi_sales_pos_detail_table_detail").deleteRow(i);


					silicon_ioi.doctype.ioiSalesPOS.compute_document();

					if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
						silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
						silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
					}

					silicon_ioi.doctype.ioiSalesPOS.sleep_static(400).then(() => {

						silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Line') + ' ' + (parseInt(s)+1).toString() + ' : ' + __('Delete line'));
					});

					break;
				}
			}

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].idx = (i+1);
			}



			if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

				for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length ; i++) {

					if (i == parseInt(s)) {

						if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + (i+1).toString())) {

							document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + (i+1).toString()).id = 'ioi_sales_pos_detail_table_detail_checked_id_' + s.toString();
							document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + (i+1).toString()).id = 'ioi_sales_pos_detail_table_detail_item_description_' + s.toString();
							document.getElementById('ioi_sales_pos_detail_table_detail_delivered_qty_' + (i+1).toString()).id = 'ioi_sales_pos_detail_table_detail_delivered_qty_' + s.toString();
							document.getElementById('ioi_sales_pos_detail_table_detail_unit_price_' + (i+1).toString()).id = 'ioi_sales_pos_detail_table_detail_unit_price_' + s.toString();
							document.getElementById('ioi_sales_pos_detail_table_detail_discount_' + (i+1).toString()).id = 'ioi_sales_pos_detail_table_detail_discount_' + s.toString();
							document.getElementById('ioi_sales_pos_detail_table_detail_total_' + (i+1).toString()).id = 'ioi_sales_pos_detail_table_detail_total_' + s.toString();
							document.getElementById('ioi_sales_pos_item_edit_button_' + (i+1).toString()).id = 'ioi_sales_pos_item_edit_button_' + s.toString();
							document.getElementById('ioi_sales_pos_qt_edit_button_' + (i+1).toString()).id = 'ioi_sales_pos_qt_edit_button_' + s.toString();
							document.getElementById('ioi_sales_pos_price_edit_button_' + (i+1).toString()).id = 'ioi_sales_pos_price_edit_button_' + s.toString();
							document.getElementById('ioi_sales_pos_discount_edit_button_' + (i+1).toString()).id = 'ioi_sales_pos_discount_edit_button_' + s.toString();

							document.getElementById('ioi_sales_pos_detail_table_detail_row_' + (i+1).toString()).id = 'ioi_sales_pos_detail_table_detail_row_' + s.toString();

							s = i+1;

						}

					}
				}


				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_checked_id_' + (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length).toString());

				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_item_description_' + (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length).toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_delivered_qty_' + (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length).toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_unit_price_' + (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length).toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_discount_' + (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length).toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_total_' + (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length).toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_item_edit_button_' + (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length).toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_qt_edit_button_' + (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length).toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_price_edit_button_' + (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length).toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_discount_edit_button_' + (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length).toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_row_' + (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length).toString());

				if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + original_id)) {
					document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + original_id).click();
				}

				if (document.getElementById("ioi_sales_pos_detail_table_detail").rows.length == 0) {
					silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail');
				}

			}

			if (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus == 0) {

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length == 0))) {
					document.getElementById('ioi_sales_pos_customer_id').disabled = false;
				}
			}


		}
	}

	static qt_add(qt, obj)
	{

		if (obj.disabled) {
			return false;
		}

		document.getElementById('ioi_sales_last_scan').innerText = '';

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			obj.disabled = false;

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

			obj.disabled = false;

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
			return false;
		}

		if (!document.getElementById('ioi_sales_pos_detail_table_detail')) {

			obj.disabled = false;

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
			return false;

		}

		let element = '';


		for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

			if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

				if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {
					element = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).id;
					break;
				}
			}
		}



		if (element != '')
		{
			let s = element;

			while (s.indexOf('_') != -1) {
				s = s.substring(s.indexOf('_')+1, s.length);
			}

			s = s.trim();

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

				if (parseInt(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].idx) == parseInt(s)+1) {

					let do_update = true;
					let item_mode = 0;
					let scan_mode = 0;

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id.trim() != '')) {

						let method = path_sales_pos + '.ioi_sales_pos_get_item_info';

						frappe.call({  	method: method,
										args: {"item_id": silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id},
										async: false,
										callback:function(r)	{

											item_mode  = r.message.item_mode;
											scan_mode  = r.message.scan_mode;

										}
						});
					}



					if (item_mode == 4) {

						if ((parseFloat(qt) == 1) || (parseFloat(qt) == -1)) {

							if (parseFloat(qt) == 1) {
								if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty) < 1) {
									silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty) + parseFloat(qt);
									do_update = true;
								}
							}else{
								if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty) > -1) {
									silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty) + parseFloat(qt);
									do_update = true;
								}
							}
						}

					}else{

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty) + parseFloat(qt);

						if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_condition_id != silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id)
						{
							silicon_ioi.doctype.ioiSalesPOS.populate_line(i, 'QTY', '');
							silicon_ioi.doctype.ioiSalesPOS.populate_line_after(i);
						}
						do_update = true;
					}


					if (do_update) {

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id.trim() != '')) {
							silicon_ioi.doctype.ioiSalesPOS.check_stock();
						}



						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id != '')) {
							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
								document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + i.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')';
							}else{
								document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + i.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description;
							}
						}else{

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty != 0)) {
								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
									document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + i.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')';
								}else{
									document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + i.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description;
								}
							}else{
								document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + i.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description;
							}
						}

						let Z = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toString();

						if ((Z.indexOf('.') != -1) || (Z.indexOf(',') != -1)) {

							document.getElementById('ioi_sales_pos_detail_table_detail_delivered_qty_'+i.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toFixed(2) + '&nbsp;';
						}else{
							document.getElementById('ioi_sales_pos_detail_table_detail_delivered_qty_'+i.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty + '&nbsp;';
						}

						if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_condition_id != silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id)
						{
							silicon_ioi.doctype.ioiSalesPOS.populate_line(i, 'QTY', silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id);
							silicon_ioi.doctype.ioiSalesPOS.populate_line_after(i);
						}else{
							silicon_ioi.doctype.ioiSalesPOS.compute_document();

							let total_line = (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00))) * silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty;

							document.getElementById('ioi_sales_pos_detail_table_detail_total_' + i.toString()).innerHTML = total_line.toFixed(2) + '&nbsp';


							if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
								silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
								silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
							}

						}

						silicon_ioi.doctype.ioiSalesPOS.sleep_static(400).then(() => {

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id.trim() != '')) {

								let items_mode = [];


								items_mode[items_mode.length] = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id;

								let method = path_sales_pos + '.ioi_sales_pos_get_item_mode';

								frappe.call({  	method: method,
												args: {	"item_mode": items_mode
												},
												async: false,
												callback:function(r)	{

													items_mode = [];

													if (r.message.length > 0) {

														for (var i = 0; i < r.message.length; i++) {
															items_mode[items_mode.length] = [r.message[i].name, r.message[i].mode];
														}
													}
												}
								});



								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty) != 0)) {

									let item_found = false;

									if (items_mode.length > 0) {

										if (items_mode[0][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) {
											if (items_mode[0][1] != 2) {
												item_found = true;
											}
										}
									}

									if (item_found) {
										document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = '#FC793D';
									}
								}else{
									document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = '';
								}
							}


							silicon_ioi.doctype.ioiSalesPOS.display_on_screen('MODIFY_QT', i);

							obj.disabled = false;

							if (parseFloat(qt) >= 0) {
								silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Line') + ' ' + (parseInt(s)+1).toString() + ' : ' + __('Add') + ' ' + qt.toString() + ' ' + __('to the quantity'));
							}else{
								silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Line') + ' ' + (parseInt(s)+1).toString() + ' : ' + __('Remove') + ' ' + qt.toString() + ' ' + __('to the quantity'));
							}
						});




					}else{
						silicon_ioi.doctype.ioiSalesPOS.sleep_static(400).then(() => {
							obj.disabled = false;
							silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Line') + ' ' + (parseInt(s)+1).toString() + ' : ' + __('SN Item quantity has to be between -1 and 1'));
						});

					}

					break;
				}
			}
		}
	}


	static set_qt(qt)
	{

		document.getElementById('ioi_sales_last_scan').innerText = '';

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
			return false;
		}

		if (!document.getElementById('ioi_sales_pos_detail_table_detail')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('No selected line'));
			return false;

		}

		let element = '';


		for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

			if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

				if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {
					element = document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).id;
					break;
				}
			}
		}



		if (element != '')
		{
			let s = element;

			while (s.indexOf('_') != -1) {
				s = s.substring(s.indexOf('_')+1, s.length);
			}

			s = s.trim();

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

				if (parseInt(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].idx) == parseInt(s)+1) {


					let item_mode = 0;
					let scan_mode = 0;

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id.trim() != '')) {

						let method = path_sales_pos + '.ioi_sales_pos_get_item_info';



						frappe.call({  	method: method,
										args: {"item_id": silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id},
										async: false,
										callback:function(r)	{

											item_mode  = r.message.item_mode;
											scan_mode  = r.message.scan_mode;

										}
						});
					}

					let do_update = false;

					if (item_mode == 4) {

						if ((parseFloat(qt) == 1) || (parseFloat(qt) == -1)) {

							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty = parseFloat(qt);
							do_update = true;
						}

					}else{

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty = parseFloat(qt);

						if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_condition_id != silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id)
						{
							silicon_ioi.doctype.ioiSalesPOS.populate_line(i, 'QTY', '');
							silicon_ioi.doctype.ioiSalesPOS.populate_line_after(i);
						}
						do_update = true;
					}

					if (do_update) {

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id.trim() != '')) {
							silicon_ioi.doctype.ioiSalesPOS.check_stock();
						}

						let Z = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toString();

						if ((Z.indexOf('.') != -1) || (Z.indexOf(',') != -1)) {

							document.getElementById('ioi_sales_pos_detail_table_detail_delivered_qty_'+i.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toFixed(2) + '&nbsp;';
						}else{
							document.getElementById('ioi_sales_pos_detail_table_detail_delivered_qty_'+i.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty + '&nbsp;';
						}

						if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_condition_id != silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id)
						{
							silicon_ioi.doctype.ioiSalesPOS.populate_line(i, 'QTY', silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id);
							silicon_ioi.doctype.ioiSalesPOS.populate_line_after(i);
						}else{
							silicon_ioi.doctype.ioiSalesPOS.compute_document();

							let total_line = (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00))) * silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty;

							document.getElementById('ioi_sales_pos_detail_table_detail_total_' + i.toString()).innerHTML = total_line.toFixed(2) + '&nbsp';


							if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
								silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
								silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
							}

						}

						silicon_ioi.doctype.ioiSalesPOS.sleep_static(400).then(() => {
							silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Line') + ' ' + (parseInt(s)+1).toString() + ' : ' + __('Set') + ' ' + qt.toString() + ' ' + __('to the quantity'));
						});




					}else{
						silicon_ioi.doctype.ioiSalesPOS.sleep_static(400).then(() => {
							silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Line') + ' ' + (parseInt(s)+1).toString() + ' : ' + __('SN Item quantity has to be between -1 and 1'));
						});

					}

					break;
				}
			}
		}
	}





	static edit_item_line(obj, empty_line = false)
	{

		document.getElementById('ioi_sales_last_scan').innerText = '';

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
			return false;
		}

		let s = obj.id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}

		let idx = s.trim();

		document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + idx).click();


		let old_item_id = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id) {

			old_item_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id;
		}


		let old_batch_sn = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_id) {

			old_batch_sn = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_id;
		}


		let old_code_ref = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_code_ref) {

			old_code_ref = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_code_ref;
		}

		let old_vat_rate = 0;

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate) {

			old_vat_rate = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate;
		}

		let old_unit_id = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_id) {

			old_unit_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_id;
		}

		let old_warehouse_id = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].warehouse_id) {
			old_warehouse_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].warehouse_id;
		}

		let old_warehouse_location_id = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].warehouse_location_id) {
			old_warehouse_location_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].warehouse_location_id;
		}

		let title = __('Edit item & code ref');

		if (empty_line) {
			title = __('New line');
		}


		var u = new frappe.ui.Dialog({
			'title': title,
			'fields': [
				{'fieldname': 'html_sales_pos_edit_item', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];

				button_ok.disabled = true;

				let go = true;


				if ((document.getElementById('html_sales_pos_edit_item_item_id').value) && (document.getElementById('html_sales_pos_edit_item_item_id').value.trim() != '')) {

					if (old_item_id != document.getElementById('html_sales_pos_edit_item_item_id').value.toUpperCase()) {

						let method = path_sales_pos + '.ioi_sales_pos_check_item';

						frappe.call({  	method: method,
										args: {"item_id" : document.getElementById('html_sales_pos_edit_item_item_id').value.toUpperCase()},
										async: false,
										callback:function(r)	{

											if (r.message == 0) {

												frappe.msgprint({title: __("Message"), message: __('This item does not exist'), indicator: "red"});
												button_ok.disabled = false;
												go = false;
											}
										}
						});

						if (go) {


							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id = document.getElementById('html_sales_pos_edit_item_item_id').value.toUpperCase();

							silicon_ioi.doctype.ioiSalesPOS.populate_line(idx, 'ITEM_id');
							silicon_ioi.doctype.ioiSalesPOS.populate_line_after(idx);
						}


					}


					if (old_unit_id != document.getElementById('html_sales_pos_edit_item_unit_id').value.toUpperCase()) {

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_id = document.getElementById('html_sales_pos_edit_item_unit_id').value.toUpperCase();

						silicon_ioi.doctype.ioiSalesPOS.populate_line(idx, 'ITEM_id', silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_id);

						silicon_ioi.doctype.ioiSalesPOS.compute_document();
					}

					if (old_warehouse_id != document.getElementById('html_sales_pos_edit_item_warehouse_id').value.toUpperCase()) {
						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].warehouse_id = document.getElementById('html_sales_pos_edit_item_warehouse_id').value.toUpperCase();
					}

					if (go) {


						if (document.getElementById('html_sales_pos_edit_item_warehouse_location_id').value.trim() != '') {


							let method = path_sales_pos + '.ioi_sales_pos_check_warehouse_location';

							frappe.call({  	method: method,
											args: {	"warehouse_id": silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].warehouse_id,
													"warehouse_location_id" : document.getElementById('html_sales_pos_edit_item_warehouse_location_id').value.toUpperCase(),
											},
											async: false,
											callback:function(r)	{

												if (r.message.error != 0) {

													frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
													button_ok.disabled = false;
													go = false;
												}
											}
							});
						}

						if (go) {

							if (old_warehouse_location_id != document.getElementById('html_sales_pos_edit_item_warehouse_location_id').value.toUpperCase()) {
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].warehouse_location_id = document.getElementById('html_sales_pos_edit_item_warehouse_location_id').value.toUpperCase();
							}
						}

					}

					if (go) {


						if (document.getElementById('html_sales_pos_edit_item_batch_sn').value.trim() != '') {


							let method = path_sales_pos + '.ioi_sales_pos_check_item_batch';

							frappe.call({  	method: method,
											args: {	"warehouse_id": silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].warehouse_id,
													"item_id" : document.getElementById('html_sales_pos_edit_item_item_id').value.toUpperCase(),
													"batch_sn_id": document.getElementById('html_sales_pos_edit_item_batch_sn').value.toUpperCase()
											},
											async: false,
											callback:function(r)	{

												if (r.message == 0) {

													frappe.msgprint({title: __("Message"), message: __('This batch/sn is not linked to this item'), indicator: "red"});
													button_ok.disabled = false;
													go = false;
												}
											}
							});
						}
					}
				}



				if (go) {

					let new_vat_rate = 0;

					if (document.getElementById('html_sales_pos_edit_item_vat_rate').value != '') {

						if (isNaN(document.getElementById('html_sales_pos_edit_item_vat_rate').value)) {
							frappe.msgprint({title: __("Message"), message: __('VAT rate is not a number'), indicator: "red"});
							button_ok.disabled = false;
							go = false;
						}

						if (go) {

							new_vat_rate = parseFloat(document.getElementById('html_sales_pos_edit_item_vat_rate').value);
						}
					}

					if (go) {

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_description = document.getElementById('html_sales_pos_edit_item_item_description').value;

						if (old_vat_rate != new_vat_rate) {
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate = new_vat_rate;
						}

						if (old_batch_sn != document.getElementById('html_sales_pos_edit_item_batch_sn').value) {
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_id = document.getElementById('html_sales_pos_edit_item_batch_sn').value;
						}



						if (old_code_ref != document.getElementById('html_sales_pos_edit_item_code_ref').value) {
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_code_ref = document.getElementById('html_sales_pos_edit_item_code_ref').value;
						}

						if (document.getElementById('html_sales_pos_edit_item_batch_sn').value.trim() != '') {

							let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
							let method_bis = pathbatchlocation + '.batch_sn_get_voucher_info';

							frappe.call({
								method: method_bis,
								args: {
									"batch_sn_id": document.getElementById('html_sales_pos_edit_item_batch_sn').value,
									"doc_currency_id": silicon_ioi.doctype.ioiSalesPOS.doc.currency_id,
									"doc_rate": silicon_ioi.doctype.ioiSalesPOS.doc.currency_inv_rate
								},
								async: false,
								callback:function(r)	{
									silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate = r.message.voucher_vat_rate;
									document.getElementById('html_sales_pos_edit_item_vat_rate').value = r.message.voucher_vat_rate;

									silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price = r.message.voucher_value_htva;
									silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_tvac = r.message.voucher_value_tvac;

								}
							});
						}




						silicon_ioi.doctype.ioiSalesPOS.compute_document();
						document.getElementById('ioi_sales_pos_detail_table_detail_total_' + idx.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_doc_currency.toFixed(2) + '&nbsp';

						if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
							silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
							silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
						}



						u.hide();

						silicon_ioi.doctype.ioiSalesPOS.sleep_static(400).then(() => {

							silicon_ioi.doctype.ioiSalesPOS.populate_line_after(idx);

							silicon_ioi.doctype.ioiSalesPOS.display_on_screen('MODIFY_LINE', idx);


							document.getElementById('ioi_sales_last_scan').innerText = '';

							if (old_item_id != document.getElementById('html_sales_pos_edit_item_item_id').value.toUpperCase()) {
								silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Line') + ' ' + (parseInt(idx)+1).toString() + ' : ' + __('Modify item from') + ' ' + old_item_id + ' ' + __('to') + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id);
							}

							if (old_code_ref != document.getElementById('html_sales_pos_edit_item_code_ref').value) {
								silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Line') + ' ' + (parseInt(idx)+1).toString() + ' : ' + __('Modify code ref from') + ' ' + old_code_ref + ' ' + __('to') + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_code_ref);
							}


							silicon_ioi.doctype.ioiSalesPOS.save();

							silicon_ioi.doctype.ioiSalesPOS.sleep_static(400).then(() => {

								for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

									if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

										if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {

											if (silicon_ioi.doctype.ioiSalesPOS.is_dark_mode == 0) {
												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#FFFFFF';
												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#000000';
											}else{
												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#1C2126';
												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#FFFFFF';


											}
										}

										document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = false;
									}
								}



								for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

									if (i == parseInt(idx)) {

										document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = true;

										if (silicon_ioi.doctype.ioiSalesPOS.is_dark_mode == 0) {
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;
										}else{
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#000000';
										}

									}

								}
							});

						});
					}
				}


			},
			secondary_action: function(){

				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id = old_item_id;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_id = old_batch_sn;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_code_ref = old_code_ref;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate = old_vat_rate;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_id = old_unit_id;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].warehouse_id = old_warehouse_id;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].warehouse_location_id = old_warehouse_location_id;

				u.hide();
			}

		});

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_item_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_item_id');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_item_button');

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_description_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_item_description');

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_unit_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_unit_id');

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_warehouse_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_warehouse_id');

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_warehouse_location_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_warehouse_location_id');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_warehouse_location_button');

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_batch_sn_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_batch_sn');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_batch_sn_button');

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_code_ref_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_code_ref');


		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_vat_rate_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_item_vat_rate');


		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:520px; width: 340px">';




		// Item
		html += '	<div style="position: relative; top: 0px; left: 2px; width:310px;">';
		html += '		<label id="html_sales_pos_edit_item_item_label" style="position: absolute; top: 0px; left: 0px;">' + __("Item") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 310px; height: 25px;"> ';
		html += '			<input id="html_sales_pos_edit_item_item_id" type="text" class="input-with-feedback form-control bold" value="' + old_item_id + '" style="text-transform: uppercase;">';
		html += '		</div>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 260px; width: 50px;"> ';
		html +='			<button id="html_sales_pos_edit_item_item_button" class="btn btn-default ellipsis" style="height: 30px; width: 50px; display:none;" onclick="" >' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Item Description
		html += '	<div style="position: relative; top: 65px; left: 2px; width:310px;">';
		html += '		<label id="html_sales_pos_edit_item_description_label" style="position: absolute; top: 0px; left: 0px;">' + __("Description") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 310px; height: 25px;"> ';
		html += '			<input id="html_sales_pos_edit_item_item_description" type="text" class="input-with-feedback form-control bold" value="' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description + '">';
		html += '		</div>';
		html += '	</div>';


		// Unit
		html += '	<div style="position: relative; top: 130px; left: 2px; width:310px;">';
		html += '		<label id="html_sales_pos_edit_item_unit_label" style="position: absolute; top: 0px; left: 0px;">' + __("Unit") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 310px; height: 25px;"> ';

		html += '			<select id="html_sales_pos_edit_item_unit_id" class="input-with-feedback form-control bold"> ';

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id.trim() != '')) {

			let method = path_sales_price + '.ioi_sales_price_get_unit_for_item_cat_cust_cond_curr'
			frappe.call({  	method: method,
							args: {	"item_id": silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id,
									"catalog_id": silicon_ioi.doctype.ioiSalesPOS.doc.sales_catalog_id,
									"customer_id": silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_id,
									"condition_id" : silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].sales_condition_id,
									"currency_id" : silicon_ioi.doctype.ioiSalesPOS.doc.currency_id,
									"document_date" : silicon_ioi.doctype.ioiSalesPOS.doc.document_date},
							async: false,
							callback:function(r)	{ 	if (r.message.length > 0) {
															for (var i = 0; i < r.message.length; i++) {

																if (r.message[i] != old_unit_id) {
																	html += '		<option value="' + r.message[i] + '">' + r.message[i] + '</option> ';
																}
															}

														}
														html += '		<option value="' + old_unit_id + '" selected>' + old_unit_id + '</option> ';

													}
			});
		}else{
			html += '		<option value="' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_id + '">' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_id + '</option> ';
		}
		html += '			</select> ';

		html += '		</div>';
		html += '	</div>';


		// Warehouse
		html += '	<div style="position: relative; top: 195px; left: 2px; width:310px;">';
		html += '		<label id="html_sales_pos_edit_item_warehouse_label" style="position: absolute; top: 0px; left: 0px;">' + __("Warehouse") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 310px; height: 25px;"> ';

		html += '			<select id="html_sales_pos_edit_item_warehouse_id" class="input-with-feedback form-control bold"> ';

		let method = path_sales_pos + '.ioi_sales_pos_get_warehouses'
		frappe.call({  	method: method,
						args: {	},
						async: false,
						callback:function(r) {
							if (r.message.length > 0) {
								for (var i = 0; i < r.message.length; i++) {
									html += '		<option value="' + r.message[i].name + '" ';

									if (r.message[i].name == old_warehouse_id) {
										html += ' selected '
									}
									html += '>' + r.message[i].name + '</option> ';
								}

							}
						}
		});

		html += '			</select> ';

		html += '		</div>';
		html += '	</div>';



		// Warehouse Location
		html += '	<div style="position: relative; top: 260px; left: 2px; width:310px;">';
		html += '		<label id="html_sales_pos_edit_item_warehouse_location_label" style="position: absolute; top: 0px; left: 0px;">' + __("Location") + '</label>';

		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 250px; height: 25px;"> ';
		html += '			<input id="html_sales_pos_edit_item_warehouse_location_id" type="text" class="input-with-feedback form-control bold" value="' + old_warehouse_location_id + '" style="text-transform: uppercase;">';
		html += '		</div>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 260px; width: 50px;"> ';
		html +='			<button id="html_sales_pos_edit_item_warehouse_location_button" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">' + __("...") + '</button>';
		html += '		</div>';

		html += '	</div>';


		// Batch SN id
		html += '	<div style="position: relative; top: 325px; left: 2px; width:310px;">';
		html += '		<label id="html_sales_pos_edit_item_batch_sn_label" style="position: absolute; top: 0px; left: 0px;">' + __("Batch / SN") + '</label>';

		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 250px; height: 25px;"> ';
		html += '			<input id="html_sales_pos_edit_item_batch_sn" type="text" class="input-with-feedback form-control bold" value="' + old_batch_sn + '" style="text-transform: uppercase;">';
		html += '		</div>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 260px; width: 50px;"> ';
		html +='			<button id="html_sales_pos_edit_item_batch_sn_button" class="btn btn-default ellipsis" style="height: 30px; width: 50px;" onclick="">' + __("...") + '</button>';
		html += '		</div>';

		html += '	</div>';


		// Code ref
		html += '	<div style="position: relative; top: 390px; left: 2px; width:310px;">';
		html += '		<label id="html_sales_pos_edit_item_code_ref_label" style="position: absolute; top: 0px; left: 0px;">' + __("Code ref") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 310px; height: 25px;"> ';
		html += '			<input id="html_sales_pos_edit_item_code_ref" type="text" class="input-with-feedback form-control bold" value="' + old_code_ref + '" style="text-transform: uppercase;">';
		html += '		</div>';
		html += '	</div>';


		// VAT rate
		html += '	<div style="position: relative; top: 455px; left: 2px; width:310px;">';
		html += '		<label id="html_sales_pos_edit_item_vat_rate_label" style="position: absolute; top: 0px; left: 0px;">' + __("VAT rate") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 310px; height: 25px;"> ';
		html += '			<input id="html_sales_pos_edit_item_vat_rate" type="numeric" step="any" class="input-with-feedback form-control bold" value="' + old_vat_rate + '">';
		html += '		</div>';
		html += '	</div>';


		html += '</div>';

		u.fields_dict.html_sales_pos_edit_item.$wrapper.html(html);
		u.$wrapper.find('.modal-dialog').css("max-width", "360px").css("width", "360px");
		u.$wrapper.find('.modal-dialog').css("max-height", "520px").css("height", "520px");
		u.show();

		silicon_ioi.doctype.ioiSalesPOS.sleep_static(1000).then(() => {

			document.getElementById('html_sales_pos_edit_item_item_id').value = old_item_id;
			document.getElementById('html_sales_pos_edit_item_batch_sn').value = old_batch_sn;
			document.getElementById('html_sales_pos_edit_item_code_ref').value = old_code_ref;
			document.getElementById('html_sales_pos_edit_item_vat_rate').value = old_vat_rate;
			document.getElementById('html_sales_pos_edit_item_warehouse_id').value = old_warehouse_id;
			document.getElementById('html_sales_pos_edit_item_warehouse_location_id').value = old_warehouse_location_id;

			let fct_keydown = function(event) {

				if (event.keyCode == 13) {
					return false;
				}

			};

			document.getElementById('html_sales_pos_edit_item_item_id').disabled = true;
			document.getElementById('html_sales_pos_edit_item_item_button').disabled = true;


			let fct_click = function() {

				let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
				let fields_len = '300, 500, 125, 150, 150, 300';
				let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
				let where = 'ioistatus = 2';
				let order_by = 'a.name asc';
				let parameter_with_stock = true;
				let site_id = silicon_ioi.doctype.ioiSalesPOS.doc.site_id;


				let fct_callback = function (return_value) {

					document.getElementById('html_sales_pos_edit_item_item_id').value = return_value;
					document.getElementById('html_sales_pos_edit_item_item_id').focus();
				};


				silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);


			};

			document.getElementById('html_sales_pos_edit_item_item_id').onkeydown = fct_keydown;

			document.getElementById('html_sales_pos_edit_item_item_button').onkeydown = fct_keydown;
			document.getElementById('html_sales_pos_edit_item_item_button').onclick = fct_click;

			document.getElementById('html_sales_pos_edit_item_item_description').value = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description

			if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id.trim() != '')) {
				document.getElementById('html_sales_pos_edit_item_item_description').disabled = true;
			}

			let fct_whs_change = function () {

				document.getElementById('html_sales_pos_edit_item_warehouse_location_id').value = '';
				document.getElementById('html_sales_pos_edit_item_batch_sn').value = '';
				document.getElementById('html_sales_pos_edit_item_code_ref').value = '';

			}

			document.getElementById('html_sales_pos_edit_item_warehouse_id').onchange = fct_whs_change;

			let fct_location_click = function() {

				if ((document.getElementById('html_sales_pos_edit_item_warehouse_id').value != null) && (document.getElementById('html_sales_pos_edit_item_warehouse_id').value != '')) {


					let callback = function(warehouse_location_id, batch_sn_id, batch_sn_code_ref, batch_sn_origin, batch_sn_cost, dossier_id) {

						document.getElementById('html_sales_pos_edit_item_warehouse_location_id').value = warehouse_location_id;


						if ((dossier_id != null) && (dossier_id != 'null') && (dossier_id != '')) {
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].dossier_id = dossier_id;
						}


						if ((batch_sn_id != null) && (batch_sn_id != 'null')) {
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_id = batch_sn_id;
							document.getElementById('html_sales_pos_edit_item_batch_sn').value = batch_sn_id;
						}


						if ((!batch_sn_id) || ((batch_sn_id) && (batch_sn_id.trim() == ''))) {
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_code_ref = '';
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_origin = '';

							document.getElementById('html_sales_pos_edit_item_code_ref').value = '';
						}else{
							if ((batch_sn_code_ref != null) && (batch_sn_code_ref != 'null')) {
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_code_ref = batch_sn_code_ref;
								document.getElementById('html_sales_pos_edit_item_code_ref').value = batch_sn_code_ref;
							}else{
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_code_ref = '';
								document.getElementById('html_sales_pos_edit_item_code_ref').value = '';
							}

							if ((batch_sn_origin != null) &&(batch_sn_origin != 'null')) {
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_origin = batch_sn_origin;
							}else{
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_origin = '';
							}

							if ((batch_sn_cost != null) && (batch_sn_cost != 'null')) {
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_cost = batch_sn_cost;
							}else{
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_cost = 0;
							}
						}

						if (document.getElementById('html_sales_pos_edit_item_batch_sn').value.trim() != '') {

							let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
							let method_bis = pathbatchlocation + '.batch_sn_get_voucher_info';

							frappe.call({
								method: method_bis,
								args: {
									"batch_sn_id": document.getElementById('html_sales_pos_edit_item_batch_sn').value,
									"doc_currency_id": silicon_ioi.doctype.ioiSalesPOS.doc.currency_id,
									"doc_rate": silicon_ioi.doctype.ioiSalesPOS.doc.currency_inv_rate
								},
								async: false,
								callback:function(r)	{
									silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate = r.message.voucher_vat_rate;
									document.getElementById('html_sales_pos_edit_item_vat_rate').value = r.message.voucher_vat_rate;

									silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price = r.message.voucher_value_htva;
									silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_tvac = r.message.voucher_value_tvac;

								}
							});
						}

						document.getElementById('html_sales_pos_edit_item_warehouse_location_id').focus();
					}

					let tp = 'OUT';

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].delivered_qty)
					{
						if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].delivered_qty) < 0) {
							tp = 'IN'
						}
					}

					let warehouse_id = document.getElementById('html_sales_pos_edit_item_warehouse_id').value;
					let warehouse_location_id = '';
					let dossier_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].dossier_id;
					let dossier_focus = 0;
					let item_id = document.getElementById('html_sales_pos_edit_item_item_id').value;
					let stored_qty_mode = 0;
					let batch_sn_id = null;

					silicon_ioi.ioiCommon.select_location(tp, warehouse_id, warehouse_location_id, dossier_id, dossier_focus, item_id, batch_sn_id, stored_qty_mode, callback);

				}else{
					frappe.msgprint({title: __("Message"), message: __('Warehouse is mandatory'), indicator: "red"});

				}
			};


			document.getElementById('html_sales_pos_edit_item_warehouse_location_id').onkeydown = fct_keydown;

			document.getElementById('html_sales_pos_edit_item_warehouse_location_button').onkeydown = fct_keydown;
			document.getElementById('html_sales_pos_edit_item_warehouse_location_button').onclick = fct_location_click;




			let fct_batch_sn_click = function() {

				if ((item_id != null) && (item_id != '')) {
					let warehouse_id = document.getElementById('html_sales_pos_edit_item_warehouse_id').value;
					silicon_ioi.doctype.ioiSalesPOS.select_batch(idx, item_id, warehouse_id);
				}else{
					frappe.msgprint({title: __("Message"), message: __('Item is mandatory'), indicator: "red"});

				}
			};


			document.getElementById('html_sales_pos_edit_item_batch_sn').onkeydown = fct_keydown;

			document.getElementById('html_sales_pos_edit_item_batch_sn_button').onkeydown = fct_keydown;
			document.getElementById('html_sales_pos_edit_item_batch_sn_button').onclick = fct_batch_sn_click;






			let item_mode = 0;
			let item_id = old_item_id;


			if ((item_id != null) && (item_id != '')) {

				let method = path_sales_pos + '.ioi_sales_pos_get_item_info';

				frappe.call({  	method: method,
								args: {"item_id" : item_id},
								async: false,
								callback:function(r)	{

									item_mode  = r.message.item_mode;

								}
				});

				document.getElementById('html_sales_pos_edit_item_batch_sn').disabled = false;
				document.getElementById('html_sales_pos_edit_item_batch_sn_button').disabled = false;

				if ((item_mode != 3) && (item_mode != 4)) {
					document.getElementById('html_sales_pos_edit_item_batch_sn').disabled = true;
					document.getElementById('html_sales_pos_edit_item_batch_sn_button').disabled = true;
				}
			}


			if (!document.getElementById('html_sales_pos_edit_item_item_description').disabled) {
				document.getElementById('html_sales_pos_edit_item_item_description').focus();
			}else{

				if (document.getElementById('html_sales_pos_edit_item_code_ref').value.trim() == '') {
					document.getElementById('html_sales_pos_edit_item_code_ref').focus();
				}else if ((document.getElementById('html_sales_pos_edit_item_batch_sn').value.trim() == '') && (!document.getElementById('html_sales_pos_edit_item_batch_sn').disabled)) {
					document.getElementById('html_sales_pos_edit_item_batch_sn').focus();
				}else{
					document.getElementById('html_sales_pos_edit_item_vat_rate').focus();
				}
			}

		});


	}



	static select_batch(idx, item_id, warehouse_id)
	{

		let can_show_batch_frm = true;


		if ((can_show_batch_frm) && ((!warehouse_id) || ((warehouse_id) && (warehouse_id.trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __("Warehouse has to be filled"), indicator: "red"});
			can_show_batch_frm = false;
		}

		if (can_show_batch_frm)
		{
			var z = new frappe.ui.Dialog({
				'title': __("Select a batch / SN in " + warehouse_id),
				'fields': [
					{'fieldname': 'html_select_batch', 'fieldtype': 'HTML'}

				],
				primary_action_label: 'Ok',
				secondary_action_label: __('Cancel'),
				primary_action: function(){

					let go = true;

					if (document.getElementById('batch_nb_record').value == 0)
					{
						go = false;
					}

					if ((go) && (document.getElementById('batch_nb_record').value != 0))
					{	go = false
						for (var i = 0; i < document.getElementById('batch_nb_record').value; i++)
						{
							if (document.getElementById('batch_checked_id_' + i.toString()).checked)
							{
								document.getElementById('selected_batch_id').value = document.getElementById('batch_name_id_' + i.toString()).value;
								document.getElementById('selected_batch_location_id').value = document.getElementById('batch_location_id_' + i.toString()).value;

								go = true;
								break;
							}
						}
					}

					if (go)
					{
						z.hide();


						document.getElementById('html_sales_pos_edit_item_batch_sn').value = document.getElementById('selected_batch_id').value;
						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_id = document.getElementById('selected_batch_id').value;

						document.getElementById('html_sales_pos_edit_item_code_ref').value = '';
						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_code_ref = '';
						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_origin = '';



						if (silicon_ioi.doctype.ioiSalesPOS.batch_id)
						{
							for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.batch_id.length; i++)
							{
								if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_id == silicon_ioi.doctype.ioiSalesPOS.batch_id[i][0])
								{
									if (silicon_ioi.doctype.ioiSalesPOS.batch_id[i][3] != null)
									{
										silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_code_ref = silicon_ioi.doctype.ioiSalesPOS.batch_id[i][3];
										document.getElementById('html_sales_pos_edit_item_code_ref').value = silicon_ioi.doctype.ioiSalesPOS.batch_id[i][3];
									}

									if (silicon_ioi.doctype.ioiSalesPOS.batch_id[i][4] != null)
									{
										silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_origin = silicon_ioi.doctype.ioiSalesPOS.batch_id[i][4];
									}

								}
							}
						}

						if (document.getElementById('html_sales_pos_edit_item_batch_sn').value.trim() != '') {

							let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
							let method_bis = pathbatchlocation + '.batch_sn_get_voucher_info';

							frappe.call({
								method: method_bis,
								args: {
									"batch_sn_id": document.getElementById('html_sales_pos_edit_item_batch_sn').value,
									"doc_currency_id": silicon_ioi.doctype.ioiSalesPOS.doc.currency_id,
									"doc_rate": silicon_ioi.doctype.ioiSalesPOS.doc.currency_inv_rate
								},
								async: false,
								callback:function(r)	{
									silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate = r.message.voucher_vat_rate;
									document.getElementById('html_sales_pos_edit_item_vat_rate').value = r.message.voucher_vat_rate;

									silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price = r.message.voucher_value_htva;
									silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_tvac = r.message.voucher_value_tvac;

								}
							});
						}



					}
				},
				secondary_action: function(){
					z.hide();
				}

			});

			if (document.getElementById('batch_nb_record'))
			{
				for (var i = 0; i < document.getElementById('batch_nb_record').value; i++)
				{
					if (document.getElementById('batch_row_' + i.toString()))
					{
						document.getElementById('batch_row_' + i.toString()).remove();
					}

					if (document.getElementById('batch_checked_id_' + i.toString()))
					{
						document.getElementById('batch_checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_name_id_' + i.toString()))
					{
						document.getElementById('batch_name_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_qt_' + i.toString()))
					{
						document.getElementById('batch_qt_' + i.toString()).remove();
					}

					if (document.getElementById('batch_location_id_' + i.toString()))
					{
						document.getElementById('batch_location_id_' + i.toString()).remove();
					}

				}

				document.getElementById('batch_nb_record').remove();
			}

			if (document.getElementById("table_batch"))
			{
				document.getElementById("table_batch").remove();
			}

			if (document.getElementById('batch_filter'))
			{
				document.getElementById('batch_filter').remove();
			}

			if (document.getElementById('selected_batch_id'))
			{
				document.getElementById('selected_batch_id').remove();
			}



			if (document.getElementById('selected_batch_location_id'))
			{
				document.getElementById('selected_batch_location_id').remove();
			}


			let html = '';

			html += '<div style="overflow: auto; overflow-x: auto; height:70px; width: 100%;">';
			html += '	<div style="position: relative; left: 0px; width:600px;">';
			html += '		<label style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 598px; height: 25px;"> ';
			html += '			<input type="text" id="batch_filter" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';
			html += '	</div>';
			html += '</div>'

			html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

			html += '<table border=1 style="border: 1px solid #E8EAEB" width=1410px data-custom-grid="true">';

			html += '<tr style="height:30px">';

			html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Batch") + '</b></td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Code ref") + '</b></td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Origin") + '</b></td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("QC ref") + '</b></td>';
			html += '<td width=300px style="vertical-align: middle;">&nbsp;<b>' + __("Qty") + '</b></td>';
			html += '</tr>';
			html += '</table>';


			let item_mode = 0;
			let item_stored_qty_mode = 0;

			frappe.db.get_list('ioi Item', {fields:["mode"], filters:{"name": item_id}}).then((r) => {

				for (var i = 0; i < r.length; i++)
				{
					item_mode = r[i].mode;
				}

				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].stored_qty_mode)
				{
					item_stored_qty_mode = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].stored_qty_mode;
				}



				let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
				let method_bis = pathbatchlocation + '.ioi_batch_sn_get_enabled_batch_for_item_warehouse';


				let sens = '+';

				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty < 0)
				{
					sens = '-';
				}


				let location_id = '';


				frappe.call({  	method: method_bis,
								args: {	"warehouse_id": warehouse_id, "item_id": item_id, "stored_qty_mode": item_stored_qty_mode, "item_mode": item_mode, "sens" : sens, "location_id": location_id},
								async: false,
								callback:function(r)	{

															silicon_ioi.doctype.ioiSalesPOS.batch_id = [];

															if (r.message.length > 0)
															{
																html += '<input type="hidden" id="batch_nb_record" value="' + r.message.length + '">';
																html += '<table id="table_batch" border=1 style="border: 1px solid #E8EAEB" width=1410px>';

																for (var i = 0; i < r.message.length; i++)
																{
																	silicon_ioi.doctype.ioiSalesPOS.batch_id[i] = [r.message[i].name, r.message[i].qt, r.message[i].location_id, r.message[i].code_ref, r.message[i].origin, r.message[i].qcref];


																	html += '<tr id="batch_row_' + i.toString() + '" style="height:30px">';
																	html += '<td width=30px align="center" style="vertical-align: middle;">';
																	html += '<input type="checkbox" id="batch_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
																	html += '       onclick=" ';
																	html += '					for (var i = 0; i < document.getElementById(\'batch_nb_record\').value; i++) ';
																	html += '					{';
																	html += '						if (document.getElementById(\'batch_checked_id_\' + i.toString()).id != this.id) ';
																	html += '						{ ';
																	html += '							document.getElementById(\'batch_checked_id_\' + i.toString()).checked = false; ';
																	html += '						} ';
																	html += '					} ';
																	html += '" ';

																	if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_id == silicon_ioi.doctype.ioiSalesPOS.batch_id[i][0])
																	{
																		html += ' checked ';
																	}
																	html += '>';
																	html += '<input type="hidden" id="batch_name_id_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiSalesPOS.batch_id[i][0] + '">';
																	html += '<input type="hidden" id="batch_qt_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiSalesPOS.batch_id[i][1] + '">';
																	html += '<input type="hidden" id="batch_location_id_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiSalesPOS.batch_id[i][2] + '">';
																	html += '</td>';

																	let v = silicon_ioi.doctype.ioiSalesPOS.batch_id[i][0];

																	html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>';

																	if (silicon_ioi.doctype.ioiSalesPOS.batch_id[i][3] != null)
																	{
																		v = silicon_ioi.doctype.ioiSalesPOS.batch_id[i][3];
																		html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>';
																	}else
																	{ 	html += '<td width=270px style="vertical-align: middle;">&nbsp;</td>';
																	}

																	if (silicon_ioi.doctype.ioiSalesPOS.batch_id[i][4] != null)
																	{
																		v = silicon_ioi.doctype.ioiSalesPOS.batch_id[i][4];
																		html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>'
																	}else
																	{ 	html += '<td width=270px style="vertical-align: middle;">&nbsp;</td>';
																	}


																	if (silicon_ioi.doctype.ioiSalesPOS.batch_id[i][5] != null)
																	{
																		v = silicon_ioi.doctype.ioiSalesPOS.batch_id[i][5];
																		html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>'
																	}else
																	{ 	html += '<td width=270px style="vertical-align: middle;">&nbsp;</td>';
																	}




																	html += '<td width=300px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.batch_id[i][1] + '</td>'
																	html += '</tr>';

																}
																html += '</table>';
															}else
															{	html += '<input type="hidden" id="batch_nb_record" value="0">';
															}
														}
				});

				html += '<input type="hidden" id="selected_batch_id" value="">';
				html += '<input type="hidden" id="selected_batch_location_id" value="">';


				html += '</div>';


				z.fields_dict.html_select_batch.$wrapper.html(html);
				z.$wrapper.find('.modal-dialog').css("max-width", "50%").css("width", "50%");
				z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");

				z.show();

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(250).then(() => {
					document.getElementById('batch_filter').onkeyup = silicon_ioi.doctype.ioiSalesPOS.batch_filter_keyup;

				});
			});

		}

	}

	static batch_filter_keyup(event)
	{
		silicon_ioi.doctype.ioiSalesDocumentDocType.refresh_batches()
	}

	static refresh_batches()
	{
		if (document.getElementById('batch_nb_record'))
		{
			if (document.getElementById('batch_nb_record').value > 0)
			{
				for (var i = 0; i < document.getElementById('batch_nb_record').value; i++)
				{
					if (document.getElementById('batch_row_' + i.toString()))
					{
						document.getElementById('batch_row_' + i.toString()).remove();
					}

					if (document.getElementById('batch_checked_id_' + i.toString()))
					{
						document.getElementById('batch_checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_name_id_' + i.toString()))
					{
						document.getElementById('batch_name_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_qt_' + i.toString()))
					{
						document.getElementById('batc((can_fifo) &&h_qt_' + i.toString()).remove();
					}

					if (document.getElementById('batch_location_id_' + i.toString()))
					{
						document.getElementById('batch_location_id_' + i.toString()).remove();
					}

				}
			}
		}

		let table = document.getElementById("table_batch");

		let cpt = 0;
		let create_row = false;


		for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.batch_id.length; i++)
		{
			create_row = false;
			if (document.getElementById('batch_filter').value.toString().trim() == '')
			{
				create_row = true;
			}else
			{
				let search = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				if ((silicon_ioi.doctype.ioiSalesPOS.batch_id[i][0].toString().toUpperCase().search(search) != -1) ||
					((silicon_ioi.doctype.ioiSalesPOS.batch_id[i][5] != null) && (silicon_ioi.doctype.ioiSalesPOS.batch_id[i][5].toString().toUpperCase().search(search) != -1)) ||
					((silicon_ioi.doctype.ioiSalesPOS.batch_id[i][1] != null) && (silicon_ioi.doctype.ioiSalesPOS.batch_id[i][1].toString().toUpperCase().search(search) != -1)) ||
					((silicon_ioi.doctype.ioiSalesPOS.batch_id[i][3] != null) && (silicon_ioi.doctype.ioiSalesPOS.batch_id[i][3].toString().toUpperCase().search(search) != -1)) ||
					((silicon_ioi.doctype.ioiSalesPOS.batch_id[i][4] != null) && (silicon_ioi.doctype.ioiSalesPOS.batch_id[i][4].toString().toUpperCase().search(search) != -1)))
				{	create_row = true;
				}
			}

			if (create_row)
			{
				let row = table.insertRow(cpt);
				row.id = 'batch_row_' + cpt.toString();
				row.style = 'height:30px';

				let cell1 = row.insertCell(0);
				cell1.width = '30px;'
				cell1.align = 'center';
				cell1.style = 'vertical-align: middle';

				let html = '';
				html += '<input type="checkbox" id="batch_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
				html += '       onclick=" ';
				html += '					for (var i = 0; i < document.getElementById(\'batch_nb_record\').value; i++) ';
				html += '					{';
				html += '						if (document.getElementById(\'batch_checked_id_\' + i.toString()).id != this.id) ';
				html += '						{ ';
				html += '							document.getElementById(\'batch_checked_id_\' + i.toString()).checked = false; ';
				html += '						} ';
				html += '					} ';
				html += '">';
				html += '<input type="hidden" id="batch_name_id_' + cpt.toString() + '" value="' + silicon_ioi.doctype.ioiSalesPOS.batch_id[i][0] + '">';
				html += '<input type="hidden" id="batch_qt_' + cpt.toString() + '" value="' + silicon_ioi.doctype.ioiSalesPOS.batch_id[i][1] + '">';
				html += '<input type="hidden" id="batch_location_id_' + cpt.toString() + '" value="' + silicon_ioi.doctype.ioiSalesPOS.batch_id[i][2] + '">';

				cell1.innerHTML = html;

				let cell2 = row.insertCell(1);
				cell2.width = '270px;'
				cell2.style = 'vertical-align: middle';

				let v = silicon_ioi.doctype.ioiSalesPOS.batch_id[i][0];

				let search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				let formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;


				cell2.innerHTML = html;

				let cell3 = row.insertCell(2);
				cell3.width = '270px;'
				cell3.style = 'vertical-align: middle';

				v = '';
				if (silicon_ioi.doctype.ioiSalesPOS.batch_id[i][3] != null)
				{
					v = silicon_ioi.doctype.ioiSalesPOS.batch_id[i][3];
				}

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;



				cell3.innerHTML = html;

				let cell4 = row.insertCell(3);
				cell4.width = '270px;'
				cell4.style = 'vertical-align: middle';

				v = '';
				if (silicon_ioi.doctype.ioiSalesPOS.batch_id[i][4] != null)
				{
					v = silicon_ioi.doctype.ioiSalesPOS.batch_id[i][4];
				}

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;
				cell4.innerHTML = html;


				let cell5 = row.insertCell(4);
				cell5.width = '270px;'
				cell5.style = 'vertical-align: middle';

				v = '';
				if (silicon_ioi.doctype.ioiSalesPOS.batch_id[i][5] != null)
				{
					v = silicon_ioi.doctype.ioiSalesPOS.batch_id[i][5];
				}

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;
				cell5.innerHTML = html;


				let cell6 = row.insertCell(5);
				cell6.width = '300px;'
				cell6.style = 'vertical-align: middle';

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();
				v = silicon_ioi.doctype.ioiSalesDocumentDocType.batch_id[i][1].toString();
				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;

				cell6.innerHTML = html;

				cpt++;


			}
		}
		document.getElementById('batch_nb_record').value = cpt.toString();

	}




	static edit_qty_line(obj)
	{

		document.getElementById('ioi_sales_last_scan').innerText = '';

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
			return false;
		}

		let s = obj.id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}

		s = s.trim();

		document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + s).click();

		silicon_ioi.doctype.ioiSalesPOS.edit_value_line(s, 'QTY');

	}

	static edit_price_line(obj)
	{
		document.getElementById('ioi_sales_last_scan').innerText = '';

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
			return false;
		}

		let s = obj.id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}

		s = s.trim();

		document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + s).click();

		silicon_ioi.doctype.ioiSalesPOS.edit_value_line(s, 'UNIT_PRICE');
	}


	static edit_discount_line(obj)
	{
		document.getElementById('ioi_sales_last_scan').innerText = '';

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
			return false;
		}

		let s = obj.id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}

		s = s.trim();

		document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + s).click();

		let show_discount_form = true;


		if (silicon_ioi.doctype.ioiSalesPOS.use_triggered_budget_discount == 1) {

			if (silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id != silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(s)].sales_condition_id) {

				if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(s)].item_budget_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(s)].item_budget_id.trim() != '')) {

					let init_extra_discount = false;

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(s)].order_master_link) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(s)].order_master_link) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(s)].order_master_link.trim() == ''))) {
						init_extra_discount = true;
					}

					if (init_extra_discount) {

						let has_detail_discount = 0

						let method = path_item_budget + '.ioi_item_budget_has_detail_discounts';

						frappe.call({  	method: method,
										args: {"item_budget_id": silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(s)].item_budget_id, "currency_id": silicon_ioi.doctype.ioiSalesPOS.doc.currency_id},
										async: false,
										callback:function(r)	{
											has_detail_discount = r.message;
										}
						});

						if (has_detail_discount == 1) {
							show_discount_form = false;
						}
					}
				}
			}
		}

		if (show_discount_form) {
			silicon_ioi.doctype.ioiSalesPOS.edit_value_line(s, 'DISCOUNT');
		}else{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Discount not editable (Item budget discount)'));
		}
	}

	static edit_discount_header(tp)
	{
		document.getElementById('ioi_sales_last_scan').innerText = '';

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Select a document'));
			return false;
		}

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) && (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0)) {

			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document has to be "In preparation"'));
			return false;
		}

		silicon_ioi.doctype.ioiSalesPOS.edit_value_line('-1', tp);
	}



	static edit_value_line(idx, tp)
	{

		let title = '';

		let old_value = 0;

		if (tp.toUpperCase() == 'QTY') {

			title = __('Edit quantity');

			if (!isNaN(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].delivered_qty)) {
				old_value = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].delivered_qty;
			}


		}else if (tp.toUpperCase() == 'UNIT_PRICE') {

			let method = path_sales_pos + '.ioi_sales_pos_access_price_discounts';
			let ret = {};

			let tab_items = [];

			if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

				let item_found = false;
				let item_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id;

				if (tab_items.length > 0) {

					for (var j = 0; j < tab_items.length; j++) {
						if (tab_items[j] == item_id) {
							item_found = true;
							break;
						}
					}

				}

				if (!item_found) {
					if (item_id.trim() != '') {
						tab_items[tab_items.length] = item_id;
					}
				}

			}


			frappe.call({  	method: method,
							args: {	"tp": 'DUP',
									"prefix_id": silicon_ioi.doctype.ioiSalesPOS.doc.prefix_id,
									"items": tab_items,
									"badge_id": silicon_ioi.doctype.ioiSalesPOS.batchuser
							},
							async: false,
							callback:function(r)	{
								ret = r.message;
							}
			});


			if (ret.error == 1) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, ret.error_msg);
				return false;
			}else{
				if (ret.value_access == 0) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You are not authorized to modify the unit price"));
					return false;
				}
			}

			silicon_ioi.doctype.ioiSalesPOS.no_min_value = 0;

			if (ret.error == 1) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, ret.error_msg);
				return false;
			}else{
				if (ret.value_access == 0) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You are not authorized to modify the detail unit price"));
					return false;
				}else{
					silicon_ioi.doctype.ioiSalesPOS.no_min_value = ret.no_min_value;
				}
			}

			title = __('Edit unit price');

			if (!isNaN(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price)) {
				old_value = (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate / 100.00)));
				old_value = parseFloat(old_value.toFixed(5));
			}

		}else if (tp.toUpperCase() == 'DISCOUNT') {


			let method = path_sales_pos + '.ioi_sales_pos_access_price_discounts';
			let ret = {};

			let tab_items = [];

			if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {


				let item_found = false;
				let item_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id;

				if (tab_items.length > 0) {

					for (var j = 0; j < tab_items.length; j++) {
						if (tab_items[j] == item_id) {
							item_found = true;
							break;
						}
					}

				}

				if (!item_found) {
					if (item_id.trim() != '') {
						tab_items[tab_items.length] = item_id;
					}
				}

			}

			frappe.call({  	method: method,
							args: {	"tp": 'DED',
									"prefix_id": silicon_ioi.doctype.ioiSalesPOS.doc.prefix_id,
									"items": tab_items,
									"badge_id": silicon_ioi.doctype.ioiSalesPOS.batchuser
							},
							async: false,
							callback:function(r)	{
								ret = r.message;
							}
			});

			silicon_ioi.doctype.ioiSalesPOS.value_max_value = 100;
			silicon_ioi.doctype.ioiSalesPOS.no_min_value = 0;

			if (ret.error == 1) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, ret.error_msg);
				return false;
			}else{
				if (ret.value_access == 0) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You are not authorized to modify the detail extra discount"));
					return false;
				}else{
					silicon_ioi.doctype.ioiSalesPOS.value_max_value = parseFloat(ret.value_max_amount);
					silicon_ioi.doctype.ioiSalesPOS.no_min_value = ret.no_min_value;
				}
			}


			title = __('Edit discount');

			let discount = 0;

			let base_discount = 0;

			if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount != null) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount != 0)) {
				base_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount;
			}

			let extra_discount = 0;

			if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount != null) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount != 0)) {
				extra_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount;
			}


			if ((parseFloat(base_discount) != 0) && (parseFloat(extra_discount) != 0)) {

				discount = ((1 - (parseFloat(base_discount) / 100.00)) * (1 - (parseFloat(extra_discount) / 100.00))) * 100

			}else if (parseFloat(base_discount) != 0) {

				discount = parseFloat(base_discount);

			}else if (parseFloat(extra_discount) != 0) {

				discount = parseFloat(extra_discount);
			}



			if (!isNaN(discount)) {
				old_value = discount;
			}

		}else if (tp.toUpperCase() == 'BASE_DISCOUNT_HEADER') {



			let method = path_sales_pos + '.ioi_sales_pos_access_price_discounts';
			let ret = {};

			frappe.call({  	method: method,
							args: {	"tp": 'HBD',
									"prefix_id": silicon_ioi.doctype.ioiSalesPOS.doc.prefix_id,
									"items":'[]',
									"badge_id": silicon_ioi.doctype.ioiSalesPOS.batchuser
							},
							async: false,
							callback:function(r)	{
								ret = r.message;
							}
			});

			silicon_ioi.doctype.ioiSalesPOS.value_max_value = 100;
			silicon_ioi.doctype.ioiSalesPOS.no_min_value = 0;

			if (ret.error == 1) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, ret.error_msg);
				return false;
			}else{
				if (ret.value_access == 0) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You are not authorized to modify the header base discount"));
					return false;
				}else{
					silicon_ioi.doctype.ioiSalesPOS.value_max_value = parseFloat(ret.value_max_amount);
					silicon_ioi.doctype.ioiSalesPOS.no_min_value = ret.no_min_value;
				}
			}

			title = __('Edit base discount');

			let discount = 0;

			if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.base_discount) != 0) {

				discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.base_discount);
			}

			if (!isNaN(discount)) {
				old_value = discount;
			}


		}else  if (tp.toUpperCase() == 'EXTRA_DISCOUNT_HEADER') {

			let method = path_sales_pos + '.ioi_sales_pos_access_price_discounts';
			let ret = {};

			let tab_items = [];

			if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

				for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

					let item_found = false;
					let item_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id;

					if (tab_items.length > 0) {

						for (var j = 0; j < tab_items.length; j++) {
							if (tab_items[j] == item_id) {
								item_found = true;
								break;
							}
						}

					}

					if (!item_found) {
						if (item_id.trim() != '') {
							tab_items[tab_items.length] = item_id;
						}
					}

				}
			}

			frappe.call({  	method: method,
							args: {	"tp": 'HED',
									"prefix_id": silicon_ioi.doctype.ioiSalesPOS.doc.prefix_id,
									"items": tab_items,
									"badge_id": silicon_ioi.doctype.ioiSalesPOS.batchuser
							},
							async: false,
							callback:function(r)	{
								ret = r.message;
							}
			});

			silicon_ioi.doctype.ioiSalesPOS.value_max_value = 100;
			silicon_ioi.doctype.ioiSalesPOS.no_min_value = 0;

			if (ret.error == 1) {
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, ret.error_msg);
				return false;
			}else{
				if (ret.value_access == 0) {
					silicon_ioi.doctype.ioiSalesPOS.add_log(2, __("You are not authorized to modify the header extra discount"));
					return false;
				}else{
					silicon_ioi.doctype.ioiSalesPOS.value_max_value = parseFloat(ret.value_max_amount);
					silicon_ioi.doctype.ioiSalesPOS.no_min_value = ret.no_min_value;
				}
			}


			title = __('Edit extra discount');

			let discount = 0;

			if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount) != 0) {

				discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount);
			}

			if (!isNaN(discount)) {
				old_value = discount;
			}

		}

		var u = new frappe.ui.Dialog({
			'title': title,
			'fields': [
				{'fieldname': 'html_sales_pos_edit_line', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];

				button_ok.disabled = true;

				document.getElementById('html_sales_pos_edit_line_calc_error').innerText = '';

				if (document.getElementById('html_sales_pos_edit_line_value').value == '')
				{
					document.getElementById('html_sales_pos_edit_line_value').value = '0';
				}

				try {

					let v = document.getElementById('html_sales_pos_edit_line_value').value;

					let res = window.eval(v);

					if (res.toString().toUpperCase() == 'INFINITY') {
						document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("Invalid expression");
						button_ok.disabled = false;
						raise;

					}else {
						document.getElementById('html_sales_pos_edit_line_value').value = res;
					}

				} catch (error) {
					document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("Invalid expression");
					button_ok.disabled = false;
					raise;
				}

				if (isNaN(document.getElementById('html_sales_pos_edit_line_value').value)) {
					document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("Invalid expression");
					button_ok.disabled = false;
					raise;
				}



				if (parseFloat(old_value) != parseFloat(document.getElementById('html_sales_pos_edit_line_value').value)) {

					if (tp.toUpperCase() == 'UNIT_PRICE') {

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].sales_condition_id = silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id;

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price_source = '';

					}else if (tp.toUpperCase() == 'DISCOUNT') {

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].sales_condition_id = silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id;

						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].base_discount_source = '';
						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].extra_discount_source = '';

					}
				}

				let items_mode = [];

				if ((tp.toUpperCase() != 'BASE_DISCOUNT_HEADER') && (tp.toUpperCase() != 'EXTRA_DISCOUNT_HEADER')) {

					items_mode[items_mode.length] = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id;

					let method = path_sales_pos + '.ioi_sales_pos_get_item_mode';

					frappe.call({  	method: method,
									args: {	"item_mode": items_mode
									},
									async: false,
									callback:function(r)	{

										items_mode = [];

										if (r.message.length > 0) {

											for (var i = 0; i < r.message.length; i++) {
												items_mode[items_mode.length] = [r.message[i].name, r.message[i].mode];
											}
										}
									}
					});
				}


				if (tp.toUpperCase() == 'QTY') {


					let method = path_sales_pos + '.ioi_sales_pos_get_item_info';

					let item_mode = 0;
					let scan_mode = 0;

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id.trim() != '')) {

						frappe.call({  	method: method,
										args: {"item_id": silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id},
										async: false,
										callback:function(r)	{

											item_mode  = r.message.item_mode;
											scan_mode  = r.message.scan_mode;

										}
						});
					}

					let do_update = false;

					if (item_mode == 4) {

						if ((parseFloat(document.getElementById('html_sales_pos_edit_line_value').value) < -1) || (parseFloat(document.getElementById('html_sales_pos_edit_line_value').value) > 1)) {

							document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("SN item quantity has to be between -1 and 1");
							button_ok.disabled = false;
							raise;

						}
					}

					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].delivered_qty = parseFloat(document.getElementById('html_sales_pos_edit_line_value').value);

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].sales_condition_id != silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id)
					{
						silicon_ioi.doctype.ioiSalesPOS.populate_line(idx, 'QTY');
						silicon_ioi.doctype.ioiSalesPOS.populate_line_after(idx);
					}

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id.trim() != '')) {
						silicon_ioi.doctype.ioiSalesPOS.check_stock();
					}

					let s = document.getElementById('html_sales_pos_edit_line_value').value;

					if ((s.indexOf('.') != -1) || (s.indexOf(',') != -1)) {

						document.getElementById('ioi_sales_pos_detail_table_detail_delivered_qty_'+idx).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].delivered_qty.toFixed(2)) + '&nbsp;';
					}else{
						document.getElementById('ioi_sales_pos_detail_table_detail_delivered_qty_'+idx).innerHTML = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].delivered_qty + '&nbsp;';
					}


				}else if (tp.toUpperCase() == 'UNIT_PRICE') {

					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price = parseFloat(document.getElementById('html_sales_pos_edit_line_value').value) / (100 + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate) * 100;
					document.getElementById('ioi_sales_pos_detail_table_detail_unit_price_'+idx).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price.toFixed(2)) + '&nbsp;';

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price == 0)) {


						if (!document.getElementById('html_sales_pos_edit_line_free_sales_allowed').checked) {
							document.getElementById('ioi_sales_pos_detail_table_detail').rows[parseInt(idx)].cells[0].style.backgroundColor = 'red';
						}else{

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].overstock_qty != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].overstock_qty) != 0)) {

								let item_found = false;

								if (items_mode.length > 0) {

									if (items_mode[0][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id) {
										if (items_mode[0][1] != 2) {
											item_found = true;
										}
									}
								}

								if (item_found) {
									document.getElementById('ioi_sales_pos_detail_table_detail').rows[parseInt(idx)].cells[0].style.backgroundColor = '#FC793D';
								}
							}else{
								document.getElementById('ioi_sales_pos_detail_table_detail').rows[parseInt(idx)].cells[0].style.backgroundColor = '';
							}

						}
					}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].overstock_qty != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].overstock_qty) != 0)) {

						let item_found = false;

						if (items_mode.length > 0) {

							if (items_mode[0][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id) {
								if (items_mode[0][1] != 2) {
									item_found = true;
								}
							}
						}

						if (item_found) {
							document.getElementById('ioi_sales_pos_detail_table_detail').rows[parseInt(idx)].cells[0].style.backgroundColor = '#FC793D';
						}
					}else{
						document.getElementById('ioi_sales_pos_detail_table_detail').rows[parseInt(idx)].cells[0].style.backgroundColor = '';
					}

					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_pos_free_sales_allowed = 0;

					if (document.getElementById('html_sales_pos_edit_line_free_sales_allowed').checked) {
						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_pos_free_sales_allowed = 1;
					}

					if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price) != 0) {
						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_pos_free_sales_allowed = 0;
					}



				}else if (tp.toUpperCase() == 'DISCOUNT') {

					let min_value = 0;

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_base_discount) {
						min_value = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_base_discount);
					}

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_extra_discount) {

						if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_extra_discount) < parseFloat(min_value)) {
							min_value = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_extra_discount);
						}
					}

					if (silicon_ioi.doctype.ioiSalesPOS.no_min_value == 0) {

						if (parseFloat(document.getElementById('html_sales_pos_edit_line_value').value) < parseFloat(min_value)) {
							document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("Min value allowed") + ': ' + parseFloat(min_value).toFixed(2);
							button_ok.disabled = false;
							raise;
						}
					}

					if (parseFloat(document.getElementById('html_sales_pos_edit_line_value').value) > parseFloat(silicon_ioi.doctype.ioiSalesPOS.value_max_value)) {
						document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("Max value allowed") + ': ' + parseFloat(silicon_ioi.doctype.ioiSalesPOS.value_max_value).toFixed(2);
						button_ok.disabled = false;
						raise;
					}

					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].base_discount = 0;
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].extra_discount = parseFloat(document.getElementById('html_sales_pos_edit_line_value').value);
					document.getElementById('ioi_sales_pos_detail_table_detail_discount_'+idx).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].extra_discount.toFixed(2)) + '&nbsp;%&nbsp;';


				}else if (tp.toUpperCase() == 'BASE_DISCOUNT_HEADER') {

					let min_value = 0;

					if (silicon_ioi.doctype.ioiSalesPOS.doc.def_base_discount) {
						min_value = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.def_base_discount);
					}

					if (silicon_ioi.doctype.ioiSalesPOS.no_min_value == 0) {

						if (parseFloat(document.getElementById('html_sales_pos_edit_line_value').value) < parseFloat(min_value)) {
							document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("Min value allowed") + ': ' + parseFloat(min_value).toFixed(2);
							button_ok.disabled = false;
							raise;
						}
					}

					if (parseFloat(document.getElementById('html_sales_pos_edit_line_value').value) > parseFloat(silicon_ioi.doctype.ioiSalesPOS.value_max_value)) {
						document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("Max value allowed") + ': ' + parseFloat(silicon_ioi.doctype.ioiSalesPOS.value_max_value).toFixed(2);
						button_ok.disabled = false;
						raise;
					}

					silicon_ioi.doctype.ioiSalesPOS.doc.base_discount = parseFloat(document.getElementById('html_sales_pos_edit_line_value').value);
					document.getElementById('ioi_sales_pos_base_discount').value = silicon_ioi.doctype.ioiSalesPOS.doc.base_discount.toFixed(2);

				}else if (tp.toUpperCase() == 'EXTRA_DISCOUNT_HEADER') {

					let min_value = 0;

					if (silicon_ioi.doctype.ioiSalesPOS.doc.def_extra_discount) {
						min_value = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.def_extra_discount);
					}

					if (silicon_ioi.doctype.ioiSalesPOS.no_min_value == 0) {

						if (parseFloat(document.getElementById('html_sales_pos_edit_line_value').value) < parseFloat(min_value)) {
							document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("Min value allowed") + ': ' + parseFloat(min_value).toFixed(2);
							button_ok.disabled = false;
							raise;
						}
					}

					if (parseFloat(document.getElementById('html_sales_pos_edit_line_value').value) > parseFloat(silicon_ioi.doctype.ioiSalesPOS.value_max_value)) {
						document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("Max value allowed") + ': ' + parseFloat(silicon_ioi.doctype.ioiSalesPOS.value_max_value).toFixed(2);
						button_ok.disabled = false;
						raise;
					}

					silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount = parseFloat(document.getElementById('html_sales_pos_edit_line_value').value);
					document.getElementById('ioi_sales_pos_extra_discount').value = silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount.toFixed(2);
				}



				silicon_ioi.doctype.ioiSalesPOS.compute_document();

				if ((tp.toUpperCase() != 'BASE_DISCOUNT_HEADER') && (tp.toUpperCase() != 'EXTRA_DISCOUNT_HEADER')) {



					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id != '')) {
						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id != '')) {
							document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + idx.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id + ')';
						}else{
							document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + idx.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description;
						}
					}else{

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty != 0)) {
							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id != '')) {
								document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + idx.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id + ')';
							}else{
								document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + idx.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description;
							}
						}else{
							document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + idx.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description;
						}
					}

					let extra_discount = 0

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].extra_discount) {
						extra_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].extra_discount;
					}

					document.getElementById('ioi_sales_pos_detail_table_detail_discount_'+idx).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(extra_discount.toFixed(2)) + '&nbsp;%&nbsp;';


					let unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate / 100.00));

					document.getElementById('ioi_sales_pos_detail_table_detail_unit_price_' + idx.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(unit_price.toFixed(2)) + '&nbsp;';

					let total_line = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate / 100.00));

					document.getElementById('ioi_sales_pos_detail_table_detail_total_' + idx.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(total_line.toFixed(2)) + '&nbsp';

				}else{

					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

						for (var k = 0; k < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; k++ ) {

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].item_id != '')) {
								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].unit_id != '')) {
									document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + k.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].unit_id + ')';
								}else{
									document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + k.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].item_description;
								}
							}else{

								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].delivered_qty) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].delivered_qty != 0)) {
									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].unit_id != '')) {
										document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + k.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].unit_id + ')';
									}else{
										document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + k.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].item_description;
									}
								}else{
									document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + k.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].item_description;
								}
							}

							let extra_discount = 0

							if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(k)].extra_discount) {
								extra_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(k)].extra_discount;
							}

							document.getElementById('ioi_sales_pos_detail_table_detail_discount_'+k).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(extra_discount.toFixed(2)) + '&nbsp;%&nbsp;';


							let unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(k)].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(k)].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(k)].vat_rate / 100.00));

							document.getElementById('ioi_sales_pos_detail_table_detail_unit_price_' + k.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(unit_price.toFixed(2)) + '&nbsp;';

							let total_line = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[k].vat_rate / 100.00));

							document.getElementById('ioi_sales_pos_detail_table_detail_total_' + k.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(total_line.toFixed(2)) + '&nbsp';

						}
					}

				}


				if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
					silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
					silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
				}

				if ((tp.toUpperCase() == 'BASE_DISCOUNT_HEADER') || (tp.toUpperCase() == 'EXTRA_DISCOUNT_HEADER')) {
					silicon_ioi.doctype.ioiSalesPOS.display_on_screen(tp.toUpperCase());
				}else{
					silicon_ioi.doctype.ioiSalesPOS.display_on_screen('MODIFY_LINE', idx);
				}

				if (tp.toUpperCase() == 'QTY') {

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id.trim() != '')) {

						let items_mode = [];


						items_mode[items_mode.length] = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id;

						let method = path_sales_pos + '.ioi_sales_pos_get_item_mode';

						frappe.call({  	method: method,
										args: {	"item_mode": items_mode
										},
										async: false,
										callback:function(r)	{

											items_mode = [];

											if (r.message.length > 0) {

												for (var i = 0; i < r.message.length; i++) {
													items_mode[items_mode.length] = [r.message[i].name, r.message[i].mode];
												}
											}
										}
						});



						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].overstock_qty != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].overstock_qty) != 0)) {

							let item_found = false;

							if (items_mode.length > 0) {

								if (items_mode[0][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id) {
									if (items_mode[0][1] != 2) {
										item_found = true;
									}
								}
							}

							if (item_found) {
								document.getElementById('ioi_sales_pos_detail_table_detail').rows[parseInt(idx)].cells[0].style.backgroundColor = '#FC793D';
							}
						}else{
							document.getElementById('ioi_sales_pos_detail_table_detail').rows[parseInt(idx)].cells[0].style.backgroundColor = '';
						}

					}

				}


				u.hide();

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(400).then(() => {

					document.getElementById('ioi_sales_last_scan').innerText = '';

					if (tp.toUpperCase() == 'QTY') {

						if (parseFloat(old_value) != parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].delivered_qty)) {

							silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Line') + ' ' + (parseInt(idx)+1).toString() + ' : ' + __('Modify quantity from') + ' ' + old_value.toString() + ' ' + __('to') + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].delivered_qty.toString());
						}

					}else if (tp.toUpperCase() == 'UNIT_PRICE') {

						let n = (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate / 100.00)));

						if (parseFloat(old_value) != parseFloat(n)) {

							silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Line') + ' ' + (parseInt(idx)+1).toString() + ' : ' + __('Modify unit price from') + ' ' + old_value.toString() + ' ' + __('to') + ' ' + n.toFixed(2).toString());
						}

					}else if (tp.toUpperCase() == 'DISCOUNT') {

						if (parseFloat(old_value) != parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].extra_discount)) {
							silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Line') + ' ' + (parseInt(idx)+1).toString() + ' : ' + __('Modify discount from') + ' ' + old_value.toString() + ' ' + __('to') + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].extra_discount.toFixed(2).toString());
						}

					}else if (tp.toUpperCase() == 'BASE_DISCOUNT_HEADER') {

						if (parseFloat(old_value) != parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.base_discount)) {
							silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Modify base discount from') + ' ' + old_value.toString() + ' ' + __('to') + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.base_discount.toString());
						}

					}else if (tp.toUpperCase() == 'EXTRA_DISCOUNT_HEADER') {

						if (parseFloat(old_value) != parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount)) {
							silicon_ioi.doctype.ioiSalesPOS.add_log(0, __('Modify extra discount from') + ' ' + old_value.toString() + ' ' + __('to') + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount.toString());
						}
					}
				});


			},
			secondary_action: function(){

				u.hide();
			}

		});

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_value');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_batch_button');

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_free_sales_allowed_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_free_sales_allowed');


		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_0');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_1');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_2');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_3');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_4');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_5');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_6');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_7');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_8');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_9');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_dot');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_adddec');

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_add');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_dec');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_multi');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_div');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_equal');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_clear');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_back');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_openpar');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_closepos');

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_edit_line_calc_error');


		let html = '';

		let item_mode = 0;
		let item_id = '';
		let batch_sn_id = '';

		if (tp.toUpperCase() == 'QTY') {

			if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {
				batch_sn_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].batch_sn_id;
			}

		}

		let div_height = 75;

		if (tp.toUpperCase() == 'QTY') {

			item_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].item_id;

			if ((item_id != null) && (item_id != '')) {

				let method = path_sales_pos + '.ioi_sales_pos_get_item_info';

				frappe.call({  	method: method,
								args: {"item_id" : item_id},
								async: false,
								callback:function(r)	{

									item_mode  = r.message.item_mode;

								}
				});

				if (item_mode == 3) {
					div_height = 125;
				}
			}
		}else if (tp.toUpperCase() == 'UNIT_PRICE') {
			div_height = 105;
		}


		html += '<div style="overflow: auto; overflow-x: auto; height:' + div_height.toString() + 'px; width: 100%;">';

		let label = '';

		if (tp.toUpperCase() == 'QTY') {

			label = __('Quantity');

		}else if (tp.toUpperCase() == 'UNIT_PRICE') {

			label = __('Unit price');

		}else if (tp.toUpperCase() == 'DISCOUNT') {

			label = __('Discount');

		}else if (tp.toUpperCase() == 'BASE_DISCOUNT_HEADER') {

			label = __('Base discount');

		}else if (tp.toUpperCase() == 'EXTRA_DISCOUNT_HEADER') {

			label = __('Extra discount');
		}


		// Value
		html += '	<div style="position: relative; top: 0px; left: 2px; width:428px;">';
		html += '		<label id="html_sales_pos_edit_line_label" style="position: absolute; top: 0px; left: 0px;">' + label + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 428px; height: 25px;"> ';
		html += '			<input id="html_sales_pos_edit_line_value" type="numeric" step="any" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';


		if (tp.toUpperCase() == 'QTY') {

			if (item_mode == 3) {

				// Button Get batch qty
				html += '	<div style="position: relative; top: 50px; left: 2px; width:200px;">';
				html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 200px; height: 25px;"> ';
				html += '			<input id="html_sales_pos_edit_line_batch_button" type="button" class="input-with-feedback form-control bold" value="' + __("Get batch qty") + '">';
				html += '		</div>';
				html += '	</div>';

			}

		}else if (tp.toUpperCase() == 'UNIT_PRICE') {

			// Free sales allowed
			html += '	<div style="position: relative; top: 50px; left: 2px; width:428px;">';

			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 428px; height: 25px;"> ';
			html += '			<input id="html_sales_pos_edit_line_free_sales_allowed" type="checkbox" class="input-with-feedback form-control bold" ';

			if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_pos_free_sales_allowed == 1)	{
				html += ' checked '
			}

			html += ' >';
			html += '		</div>';
			html += '		<label id="html_sales_pos_edit_line_free_sales_allowed_label" style="position: absolute; top: 23px; left: 30px;">' + __("Free sales allowed") + '</label>';
			html += '	</div>';


		}


		html += '</div>';



		html += '<div style="overflow: auto; overflow-x: auto; height:8px; width: 100%;">';
		html += '</div>';


		html += '<div style="overflow: auto; overflow-x: auto; height:202px; width: 100%;">';

		html += '<table id="html_sales_pos_edit_line_calc" border=1 style="border: 1px solid #E8EAEB" width=100%>';

		html += '<tr style="height:40px">';

		html += '<td id="html_sales_pos_edit_line_calc_clear" width=50px align="center" style="vertical-align: middle;"><b>C</b></td>';
		html += '<td id="html_sales_pos_edit_line_calc_back" width=50px align="center" style="vertical-align: middle;"><b><-</b></td>';
		html += '<td id="html_sales_pos_edit_line_calc_openpar" width=50px align="center" style="vertical-align: middle;"><b>(</b></td>';
		html += '<td id="html_sales_pos_edit_line_calc_closepar" width=50px align="center" style="vertical-align: middle;"><b>)</b></td>';
		html += '</tr>';


		html += '<tr style="height:40px">';
		html += '<td id="html_sales_pos_edit_line_calc_7" width=50px align="center" style="vertical-align: middle;"><b>7</b></td>';
		html += '<td id="html_sales_pos_edit_line_calc_8" width=50px align="center" style="vertical-align: middle;"><b>8</b></td>';
		html += '<td id="html_sales_pos_edit_line_calc_9" width=50px align="center" style="vertical-align: middle;"><b>9</b></td>';
		html += '<td id="html_sales_pos_edit_line_calc_add" width=50px align="center" style="vertical-align: middle;"><b>+</b></td>';
		html += '</tr>';

		html += '<tr style="height:40px">';
		html += '<td id="html_sales_pos_edit_line_calc_4" width=50px align="center" style="vertical-align: middle;"><b>4</b></td>';
		html += '<td id="html_sales_pos_edit_line_calc_5" width=50px align="center" style="vertical-align: middle;"><b>5</b></td>';
		html += '<td id="html_sales_pos_edit_line_calc_6" width=50px align="center" style="vertical-align: middle;"><b>6</b></td>';
		html += '<td id="html_sales_pos_edit_line_calc_dec" width=50px align="center" style="vertical-align: middle;"><b>-</b></td>';
		html += '</tr>';

		html += '<tr style="height:40px">';
		html += '<td id="html_sales_pos_edit_line_calc_1" width=50px align="center" style="vertical-align: middle;"><b>1</b></td>';
		html += '<td id="html_sales_pos_edit_line_calc_2" width=50px align="center" style="vertical-align: middle;"><b>2</b></td>';
		html += '<td id="html_sales_pos_edit_line_calc_3" width=50px align="center" style="vertical-align: middle;"><b>3</b></td>';
		html += '<td id="html_sales_pos_edit_line_calc_multi" width=50px align="center" style="vertical-align: middle;"><b>*</b></td>';
		html += '</tr>';

		html += '<tr style="height:40px">';
		html += '<td id="html_sales_pos_edit_line_calc_0" width=50px align="center" style="vertical-align: middle;"><b>0</b></td>';
		html += '<td width=50px align="center" style="vertical-align: middle;">';
		html += '	<table border=0 style="border: 0px solid #E8EAEB" width=100%>';
		html += '	<tr style="height:38px">';
		html += '		<td id="html_sales_pos_edit_line_calc_dot" width=50% align="center" style="vertical-align: middle;"><b>.</b></td>';
		html += '		<td id="html_sales_pos_edit_line_calc_adddec" width=50% align="center" style="vertical-align: middle;"><b>+/-</b></td>';
		html += '	</tr>';
		html += '	</table>';


		html += '</td>';

		html += '<td id="html_sales_pos_edit_line_calc_equal" width=50px align="center" style="vertical-align: middle;"><b>=</b></td>';
		html += '<td id="html_sales_pos_edit_line_calc_div" width=50px align="center" style="vertical-align: middle;"><b>/</b></td>';
		html += '</tr>';


		html += '</table>';

		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:8px; width: 100%;">';
		html += '</div>';

		html += '<div style="overflow: auto; overflow-x: auto; height:30px; width: 100%;">';

		html += '	<div style="position: relative; top: 0px; left: 2px; width:428px;">';
		html += '		<b><font color="red"><label id="html_sales_pos_edit_line_calc_error" style="position: absolute; top: 0px; left: 0px;"></label></font></b>';
		html += '	</div>';


		html += '</div>';


		u.fields_dict.html_sales_pos_edit_line.$wrapper.html(html);
		u.$wrapper.find('.modal-dialog').css("max-width", "475px").css("width", "475px");
		u.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
		u.show();

		silicon_ioi.doctype.ioiSalesPOS.sleep_static(1000).then(() => {


			let fct_value_keydown = function(event) {

				if (event.keyCode == 13) {

					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].click();
					return false;
				}

			};

			document.getElementById('html_sales_pos_edit_line_value').onkeydown = fct_value_keydown;


			if (document.getElementById('html_sales_pos_edit_line_batch_button')) {

				let fct_batch_qty_click = function () {

					if ((batch_sn_id == null) || (batch_sn_id == '')) {

						document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("Batch SN is mandatory");
						return false;
					}

					if ((item_id == null) || (item_id == '')) {

						document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("Item is mandatory");
						return false;
					}

					let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
					let m = pathbatchlocation + '.ioi_batch_sn_get_data';


					frappe.call({
						method: m,
						args: {"item_id": item_id, "batch_sn_id": batch_sn_id, "is_batch": 'Y'},
						async: false,
						callback: function (r) {

							document.getElementById('html_sales_pos_edit_line_value').value = r.message[0].qty;
							document.getElementById('html_sales_pos_edit_line_value').focus();

						}
					});


				}

				document.getElementById('html_sales_pos_edit_line_batch_button').onclick = fct_batch_qty_click;
			}


			let fct_mousemove = function() {

				this.style.cursor = 'pointer';
				this.style.backgroundColor = '#E8EAEB';
				this.style.color = '#000000';
			};


			let fct_mouseleave = function() {

				this.style.cursor = 'none';
				this.style.backgroundColor = '';
				this.style.color = '';

			};

			let fct_click = function() {

				document.getElementById('html_sales_pos_edit_line_value').focus();

				document.getElementById('html_sales_pos_edit_line_calc_error').innerText = '';

				if (this.id == 'html_sales_pos_edit_line_calc_clear') {

					document.getElementById('html_sales_pos_edit_line_value').value = '0';

				}else if (this.id == 'html_sales_pos_edit_line_calc_back') {

					let s = document.getElementById('html_sales_pos_edit_line_value').value;

					if (s != '') {
						s = s.substring(0, s.length-1);
						document.getElementById('html_sales_pos_edit_line_value').value = s;
					}

				}else if (this.id == 'html_sales_pos_edit_line_calc_equal') {

					if (document.getElementById('html_sales_pos_edit_line_value').value.trim() != '') {

						try {

							let res = window.eval(document.getElementById('html_sales_pos_edit_line_value').value);

							if (res.toString().toUpperCase() == 'INFINITY') {
								document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("Invalid expression");
							}else {
								document.getElementById('html_sales_pos_edit_line_value').value = res;
							}

						} catch (error) {
							document.getElementById('html_sales_pos_edit_line_calc_error').innerText = __("Invalid expression");
						}
					}
				}else{

					let s = this.id;

					while (s.indexOf('_') != -1) {
						s = s.substring(s.indexOf('_')+1, s.length);
					}

					s = s.trim()

					if (s == 'add') {

						document.getElementById('html_sales_pos_edit_line_value').value += '+';

					}else if (s == 'dec') {

						document.getElementById('html_sales_pos_edit_line_value').value += '-';

					}else if (s == 'multi') {
						document.getElementById('html_sales_pos_edit_line_value').value += '*';

					}else if (s == 'div') {

						document.getElementById('html_sales_pos_edit_line_value').value += '/';

					}else if (s == 'openpar') {

						document.getElementById('html_sales_pos_edit_line_value').value += '(';

					}else if (s == 'closepar') {

						document.getElementById('html_sales_pos_edit_line_value').value += ')';

					}else if (s == 'dot') {

						document.getElementById('html_sales_pos_edit_line_value').value += '.';
					}else if (s == 'adddec') {

						if ((document.getElementById('html_sales_pos_edit_line_value').value != '') && (document.getElementById('html_sales_pos_edit_line_value').value != '0')) {

							let s = document.getElementById('html_sales_pos_edit_line_value').value;

							s = s.substring(0, 1);

							if (s == '+') {

								document.getElementById('html_sales_pos_edit_line_value').value = '-' + document.getElementById('html_sales_pos_edit_line_value').value.substring(1, document.getElementById('html_sales_pos_edit_line_value').value.length);

							}else if (s == '-') {

								document.getElementById('html_sales_pos_edit_line_value').value = document.getElementById('html_sales_pos_edit_line_value').value.substring(1, document.getElementById('html_sales_pos_edit_line_value').value.length);

							}else {
								document.getElementById('html_sales_pos_edit_line_value').value = '-' + document.getElementById('html_sales_pos_edit_line_value').value;
							}

						}

					}else{

						if (document.getElementById('html_sales_pos_edit_line_value').value == '0') {

							document.getElementById('html_sales_pos_edit_line_value').value = s;

						}else{
							document.getElementById('html_sales_pos_edit_line_value').value += s;
						}
					}
				}

			};

			document.getElementById('html_sales_pos_edit_line_calc_0').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_0').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_0').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_1').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_1').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_1').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_2').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_2').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_2').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_3').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_3').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_3').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_4').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_4').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_4').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_5').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_5').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_5').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_6').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_6').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_6').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_7').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_7').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_7').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_8').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_8').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_8').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_9').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_9').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_9').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_dot').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_dot').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_dot').onmouseleave = fct_mouseleave;


			document.getElementById('html_sales_pos_edit_line_calc_adddec').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_adddec').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_adddec').onmouseleave = fct_mouseleave;


			document.getElementById('html_sales_pos_edit_line_calc_add').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_add').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_add').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_dec').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_dec').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_dec').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_multi').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_multi').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_multi').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_div').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_div').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_div').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_equal').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_equal').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_equal').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_clear').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_clear').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_clear').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_back').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_back').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_back').onmouseleave = fct_mouseleave;


			document.getElementById('html_sales_pos_edit_line_calc_openpar').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_openpar').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_openpar').onmouseleave = fct_mouseleave;

			document.getElementById('html_sales_pos_edit_line_calc_closepar').onclick = fct_click;
			document.getElementById('html_sales_pos_edit_line_calc_closepar').onmousemove = fct_mousemove;
			document.getElementById('html_sales_pos_edit_line_calc_closepar').onmouseleave = fct_mouseleave;




			document.getElementById('html_sales_pos_edit_line_value').value = old_value;
			document.getElementById('html_sales_pos_edit_line_value').focus();
			document.getElementById('html_sales_pos_edit_line_value').select();

		});

	}


	// *************************************************************************************************************************************************
	// Quick Item Search
	// *************************************************************************************************************************************************

	static refresh_quick_items(value = null)
	{
		let search_term = '';

		if ((value != null) && (value.trim() != '')) {
			search_term = value;
		}

		if (document.getElementById('ioi_sales_pos_quick_item_search_nb_header')) {

			for (var i = 0; i < document.getElementById('ioi_sales_pos_quick_item_search_nb_header').value; i++) {

				this.remove_element('ioi_sales_pos_quick_item_search_header_name_' + i.toString());
				this.remove_element('ioi_sales_pos_quick_item_search_header_description_' + i.toString());
				this.remove_element('ioi_sales_pos_quick_item_search_header_display_mode_' + i.toString());
				this.remove_element('ioi_sales_pos_quick_item_search_header_' + i.toString());
				this.remove_element('ioi_sales_pos_quick_item_search_header_image_' + i.toString());
			}

			this.remove_element('ioi_sales_pos_quick_item_search_nb_header');
		}

		if (document.getElementById('ioi_sales_pos_quick_item_search_nb_detail')) {

			for (var i = 0; i < document.getElementById('ioi_sales_pos_quick_item_search_nb_detail').value; i++) {

				this.remove_element('ioi_sales_pos_quick_item_search_detail_separator_' + i.toString());
				this.remove_element('ioi_sales_pos_quick_item_search_detail_parent_' + i.toString());
				this.remove_element('ioi_sales_pos_quick_item_search_detail_name_' + i.toString());
				this.remove_element('ioi_sales_pos_quick_item_search_detail_item_id_' + i.toString());
				this.remove_element('ioi_sales_pos_quick_item_search_detail_' + i.toString());
				this.remove_element('ioi_sales_pos_quick_item_search_detail_image_div_' + i.toString());
				this.remove_element('ioi_sales_pos_quick_item_search_detail_image_' + i.toString());
				this.remove_element('ioi_sales_pos_quick_item_search_detail_label_div_' + i.toString());
				this.remove_element('ioi_sales_pos_quick_item_search_detail_label_' + i.toString());
			}

			silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_nb_detail');
		}


		let canvas_height = 80;

		silicon_ioi.doctype.ioiSalesPOS.quick_item_build_header_canvas(canvas_height);

		let selected_site = '';

		let method = path_user + '.ioi_user_get_site'

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{ 	selected_site = r.message
												}
		});


		let nb_header = 0;

		method = path_sales_pos + '.ioi_sales_pos_get_quick_items_header'

		frappe.call({  	method: method,
						args: {"site_id": selected_site, "search": search_term},
						async: false,
						callback:function(r)	{

							if (r.message.length > 0) {

								let top = 0;
								let left = 0;

								canvas_height -= 8;

								for (var i = 0; i < r.message.length; i++) {

									silicon_ioi.doctype.ioiSalesPOS.quick_item_build_header(r.message[i], i, top, left, canvas_height);

									top -= canvas_height;
									left += canvas_height;

									nb_header++;
								}

							}else{
								document.getElementById('ioi_sales_pos_quick_item_search_header_selected_name').value = '';
							}
						}
		});

		let html = '';

		html += '<input id="ioi_sales_pos_quick_item_search_nb_header" type="hidden" value="' + nb_header + '">';


		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_header_separator_last');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_header_separator_line');

		if (nb_header == 0) {

			html += '<div id="ioi_sales_pos_quick_item_search_header_separator_last" style="overflow: auto; overflow-x: auto; height:6px; width:100%;">';
			html += '</div>';


			html += '<div id="ioi_sales_pos_quick_item_search_header_separator_line" style="overflow: auto; overflow-x: auto; height:1px; width:100%; background-color: #E8EAEB;">';
			html += '</div>';
		}

		document.getElementById('ioi_sales_pos_quick_item_search_header_result').insertAdjacentHTML('beforeend', html)

		silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then( () => {


			let fct_click = function() {

				for (var i = 0; i < document.getElementById('ioi_sales_pos_quick_item_search_nb_header').value; i++) {

					if (document.getElementById('ioi_sales_pos_quick_item_search_header_' + i.toString())) {
						document.getElementById('ioi_sales_pos_quick_item_search_header_' + i.toString()).style.backgroundColor = '';
					}
				}

				this.style.backgroundColor = '#D7D9DB';

				let s = this.id;

				while (s.indexOf('_') != -1) {
					s = s.substring(s.indexOf('_')+1, s.length);
				}

				s = s.trim();

				document.getElementById('ioi_sales_pos_quick_item_search_header_canvas_info_label').innerText = document.getElementById('ioi_sales_pos_quick_item_search_header_description_' + s).value;

				document.getElementById('ioi_sales_pos_quick_item_search_header_selected_name').value = document.getElementById('ioi_sales_pos_quick_item_search_header_name_' + s).value;

				silicon_ioi.doctype.ioiSalesPOS.quick_item_build_detail_refresh(this.id);

			};

			let found_clicked = false;

			for (var i = 0; i < document.getElementById('ioi_sales_pos_quick_item_search_nb_header').value; i++) {

				if (document.getElementById('ioi_sales_pos_quick_item_search_header_' + i.toString())) {
					document.getElementById('ioi_sales_pos_quick_item_search_header_' + i.toString()).onclick = fct_click;

					if ((!document.getElementById('ioi_sales_pos_quick_item_search_header_selected_name')) || ((document.getElementById('ioi_sales_pos_quick_item_search_header_selected_name')) && (document.getElementById('ioi_sales_pos_quick_item_search_header_selected_name').value.trim() == ''))) {

						if (i == 0) {
							document.getElementById('ioi_sales_pos_quick_item_search_header_' + i.toString()).click();
							found_clicked = true;
						}
					}else{
						if (document.getElementById('ioi_sales_pos_quick_item_search_header_name_' + i.toString())) {

							if (document.getElementById('ioi_sales_pos_quick_item_search_header_name_' + i.toString()).value == document.getElementById('ioi_sales_pos_quick_item_search_header_selected_name').value) {
								document.getElementById('ioi_sales_pos_quick_item_search_header_' + i.toString()).click();
								found_clicked = true;
							}
						}
					}
				}
			}

			if (!found_clicked) {

				if (document.getElementById('ioi_sales_pos_quick_item_search_header_0')) {
					document.getElementById('ioi_sales_pos_quick_item_search_header_0').click();
				}
			}
		});
	}


	static quick_item_build_header_canvas(canvas_height)
	{
		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_header_canvas');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_header_separator');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_header_separator_bis');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_header_canvas_info');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_header_canvas_info_label');

		let html = '';

		html += '<div id="ioi_sales_pos_quick_item_search_header_canvas" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:' + canvas_height.toString() + 'px; width:100%; border: 0px solid #E8EAEB;">';
		html += '</div>';

		html += '<div id="ioi_sales_pos_quick_item_search_header_separator" style="overflow: auto; overflow-x: auto; height:1px; width:100%; background-color: #E8EAEB;">';
		html += '</div>';

		html += '<div id="ioi_sales_pos_quick_item_search_header_separator_bis" style="overflow: auto; overflow-x: auto; height:6px; width:100%;">';
		html += '</div>';

		html += '<div id="ioi_sales_pos_quick_item_search_header_canvas_info" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:20px; width:100%; border: 0px solid #E8EAEB;">';

		html += '	<div id="ioi_sales_pos_quick_item_search_header_canvas_info" style="position:relative: height:20px; width:100%;" align="center">';
		html += '		<b><label id="ioi_sales_pos_quick_item_search_header_canvas_info_label"></label></b>';
		html += '	</div>';
		html += '</div>';


		document.getElementById('ioi_sales_pos_quick_item_search_header_result').insertAdjacentHTML('beforeend', html);
	}


	static quick_item_build_header(data, i, top, left, height)
	{
		let html = '';

		html += '<input id="ioi_sales_pos_quick_item_search_header_name_' + i.toString() + '" type="hidden" value="' + data.name + '">';
		html += '<input id="ioi_sales_pos_quick_item_search_header_description_' + i.toString() + '" type="hidden" value="' + data.description + '">';
		html += '<input id="ioi_sales_pos_quick_item_search_header_display_mode_' + i.toString() + '" type="hidden" value="' + data.display_mode + '">';

		html += '<div id="ioi_sales_pos_quick_item_search_header_' + i.toString() + '" style="position: relative; top: ' + top.toString() + 'px; left: ' + left.toString() + 'px; height:' + height.toString() + 'px; width:' + height.toString() + 'px; ';
		html += 'border: 0px solid #E8EAEB;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';">';

		let img_top = 4;
		let img_left = 4;
		let img_height = height - img_top - img_left;

		html += '	<img id="ioi_sales_pos_quick_item_search_header_image_' + i.toString() + '" src="' + data.image + '" width="' + img_height.toString() + 'px" height="' + img_height.toString() + 'px" style="position:absolute; top:' + img_top.toString() + 'px; left:' + img_left.toString() + 'px; width: ' + img_height.toString() + 'px; height: ' + img_height.toString() + 'px; object-fit: fill;"></img>';

		html += '</div>';

		document.getElementById('ioi_sales_pos_quick_item_search_header_canvas').insertAdjacentHTML('beforeend', html);

	}

	static quick_item_build_detail_refresh(id)
	{

		if (document.getElementById('ioi_sales_pos_quick_item_search_nb_detail')) {

			for (var i = 0; i < document.getElementById('ioi_sales_pos_quick_item_search_nb_detail').value; i++) {

				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_detail_separator_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_detail_parent_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_detail_name_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_detail_item_id_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_detail_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_detail_image_div_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_detail_image_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_detail_label_div_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_detail_label_' + i.toString());
			}

			silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_quick_item_search_nb_detail');
		}

		let s = id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length);
		}

		s = s.trim();

		let search_term = '';

		if (document.getElementById('ioi_sales_pos_quick_item_search').value.trim() != '') {
			search_term = document.getElementById('ioi_sales_pos_quick_item_search').value;
		}

		let nb_detail = 0;


		let method = path_sales_pos + '.ioi_sales_pos_get_quick_items_detail'

		frappe.call({  	method: method,
						args: {"name": document.getElementById('ioi_sales_pos_quick_item_search_header_name_' + s).value, "search": search_term},
						async: false,
						callback:function(r)	{

							if (r.message.length > 0) {

								for (var i = 0; i < r.message.length; i++) {

									if (parseInt(document.getElementById('ioi_sales_pos_quick_item_search_header_display_mode_' + s).value) == 0) {
										silicon_ioi.doctype.ioiSalesPOS.quick_item_build_detail_list_mode(r.message[i], i);
									}else {
										silicon_ioi.doctype.ioiSalesPOS.quick_item_build_detail_thumbnail_mode(r.message[i], i);

									}

									nb_detail++;
								}

							}
						}
		});

		let html = '<input id="ioi_sales_pos_quick_item_search_nb_detail" type="hidden" value="' + nb_detail + '">';


		document.getElementById('ioi_sales_pos_quick_item_search_result').insertAdjacentHTML('beforeend', html);

	}


	static quick_item_build_detail_list_mode(data, i)
	{
		let html = '';

		html += '<div id="ioi_sales_pos_quick_item_search_detail_separator_' + i.toString() + '" style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		html += '<input id="ioi_sales_pos_quick_item_search_detail_parent_' + i.toString() + '" type="hidden" value="' + data.parent + '">';
		html += '<input id="ioi_sales_pos_quick_item_search_detail_name_' + i.toString() + '" type="hidden" value="' + data.name + '">';
		html += '<input id="ioi_sales_pos_quick_item_search_detail_item_id_' + i.toString() + '" type="hidden" value="' + data.item_id + '">';

		html += '<div id="ioi_sales_pos_quick_item_search_detail_' + i.toString() + '" style="overflow-x: auto; overflow-y:hidden; height:70px; width:100%; border: 1px solid #E8EAEB;" ';
		html += 'onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';" onclick="">';


		let leftl = 4;
		let topl = 23;


		if ((data.image != null) && (data.image.trim() != '')) {

			html += '	<div id="ioi_sales_pos_quick_item_search_detail_image_div_' + i.toString() + '" style="position:relative;" onmousemove="this.style.cursor = \'pointer\';">';
			html += '		<img id="ioi_sales_pos_quick_item_search_detail_image_' + i.toString() + '" src="' + data.image + '" width="62px" height="62px" style="position:absolute;top:4px; left:4px;" onmousemove="this.style.cursor = \'pointer\';"></img>';
			html += '	</div>';
			leftl = 75;
		}

		html += '	<div id="ioi_sales_pos_quick_item_search_detail_label_div_' + i.toString() + '" style="position:relative;" onmousemove="this.style.cursor = \'pointer\';">';
		html += '		<label id="ioi_sales_pos_quick_item_search_detail_label_' + i.toString() + '" style="position: absolute; top: ' + topl.toString() + 'px; left: ' + leftl.toString() + 'px; width: 250px; z-index:5;" onmousemove="this.style.cursor = \'pointer\';"><b>' + data.description + '</b></label>';
		html += '	</div>';

		html += '</div>';

		document.getElementById('ioi_sales_pos_quick_item_search_result').insertAdjacentHTML('beforeend', html);


		let fct_click = function() {

			for (var i = 0; i < document.getElementById('ioi_sales_pos_quick_item_search_nb_detail').value; i++) {

				if (document.getElementById('ioi_sales_pos_quick_item_search_detail_' + i.toString())) {
					document.getElementById('ioi_sales_pos_quick_item_search_detail_' + i.toString()).style.backgroundColor = '';
				}
				if (document.getElementById('ioi_sales_pos_quick_item_search_detail_label_' + i.toString())) {
					document.getElementById('ioi_sales_pos_quick_item_search_detail_label_' + i.toString()).style.color = '';
				}

			}

			let s = this.id;

			while (s.indexOf('_') != -1) {
				s = s.substring(s.indexOf('_')+1, s.length);
			}

			s = s.trim();

			document.getElementById('ioi_sales_pos_quick_item_search_detail_label_' + s).style.color = '#000000';

			this.style.backgroundColor = '#D7D9DB';

			silicon_ioi.doctype.ioiSalesPOS.add_item(this);
		}

		document.getElementById('ioi_sales_pos_quick_item_search_detail_' + i.toString()).onclick = fct_click;

	}

	static quick_item_build_detail_thumbnail_mode(data, i)
	{
		let html = '';

		html += '<input id="ioi_sales_pos_quick_item_search_detail_parent_' + i.toString() + '" type="hidden" value="' + data.parent + '">';
		html += '<input id="ioi_sales_pos_quick_item_search_detail_name_' + i.toString() + '" type="hidden" value="' + data.name + '">';
		html += '<input id="ioi_sales_pos_quick_item_search_detail_item_id_' + i.toString() + '" type="hidden" value="' + data.item_id + '">';

		html += '<div id="ioi_sales_pos_quick_item_search_detail_' + i.toString() + '" style="float:left;overflow-x: hidden; overflow-y:hidden; height:158px; width:156px; border: 1px solid #E8EAEB;" ';
		html += 'onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';" onclick="">';


		let leftl = 4;
		let topl = 23;


		if ((data.image != null) && (data.image.trim() != '')) {

			html += '	<div id="ioi_sales_pos_quick_item_search_detail_image_div_' + i.toString() + '" style="position:relative;width:156px;" onmousemove="this.style.cursor = \'pointer\';">';
			html += '		<img id="ioi_sales_pos_quick_item_search_detail_image_' + i.toString() + '" src="' + data.image + '" width="100px" height="100px" style="position:absolute;top:8px; width:106px; height:106px; left:24px; object-fit: fill;" onmousemove="this.style.cursor = \'pointer\';"></img>';
			html += '	</div>';
		}

		html += '	<div id="ioi_sales_pos_quick_item_search_detail_label_div_' + i.toString() + '" style="position:relative;top:126px;width: 156px;" onmousemove="this.style.cursor = \'pointer\';" align="center">';

		let desc = '';

		if ((data.description != null) && (data.description != '')) {
			desc = data.description.substring(0,20);
		}

		html += '		<label id="ioi_sales_pos_quick_item_search_detail_label_' + i.toString() + '" style="width: 156px;" onmousemove="this.style.cursor = \'pointer\';"><b>' + desc + '</b></label>';
		html += '	</div>';

		html += '</div>';


		document.getElementById('ioi_sales_pos_quick_item_search_result').insertAdjacentHTML('beforeend', html);


		let fct_click = function() {

			for (var i = 0; i < document.getElementById('ioi_sales_pos_quick_item_search_nb_detail').value; i++) {

				if (document.getElementById('ioi_sales_pos_quick_item_search_detail_' + i.toString())) {
					document.getElementById('ioi_sales_pos_quick_item_search_detail_' + i.toString()).style.backgroundColor = '';
				}

				if (document.getElementById('ioi_sales_pos_quick_item_search_detail_label_' + i.toString())) {
					document.getElementById('ioi_sales_pos_quick_item_search_detail_label_' + i.toString()).style.color = '';
				}

			}

			let s = this.id;

			while (s.indexOf('_') != -1) {
				s = s.substring(s.indexOf('_')+1, s.length);
			}

			s = s.trim();

			document.getElementById('ioi_sales_pos_quick_item_search_detail_label_' + s).style.color = '#000000';


			this.style.backgroundColor = '#D7D9DB';

			silicon_ioi.doctype.ioiSalesPOS.add_item(this);
		}

		document.getElementById('ioi_sales_pos_quick_item_search_detail_' + i.toString()).onclick = fct_click;

	}

	static add_item(obj)
	{
		let s = obj.id;

		while (s.indexOf('_') != -1) {
			s = s.substring(s.indexOf('_')+1, s.length)
		}

		s = s.trim();

		let item_id = document.getElementById('ioi_sales_pos_quick_item_search_detail_item_id_' + s).value;
		let qty = 1;
		silicon_ioi.doctype.ioiSalesPOS.new_line(item_id, qty);
	}


	static compute_document()
	{
		if ((!silicon_ioi.doctype.ioiSalesPOS.doc) || (silicon_ioi.doctype.ioiSalesPOS.doc == '')) {
			return false;
		}

		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding) || ((silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding) && (silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding.toString().trim() == ''))) {

			silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding = 0;

		}else{
			if (silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding < 0) {

				silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding = 0;
			}

			if (silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding > 9) {

				silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding = 9;
			}
		}

		// Compute all document (lines + header)

		let vat_matrix_key = [];
		let vat_matrix_type = [];
		let vat_matrix_rate = [];
		let vat_matrix_wo_vat = [];
		let vat_matrix_base = [];
		let vat_matrix_vat = [];
		let vat_matrix_inc_vat = [];
		let cpt = 0;
		let total_wo_vat_neg_part = 0;
		let tot_internal_value_line_option = 0;
		let tot_sub_total = 0;
		let total_weight = 0;
		let total_volume = 0;

		let fin_disc = 0;

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount) && (silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount.toString().trim() == ''))
		{	fin_disc = 0;
		}else
		{	fin_disc = silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount;
		}


		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail)
		{
			let vtype = '';
			let vrate = '';
			let vother = '';
			let n = 0;
			let str_vrate = '';
			let str_vother = '';
			let str_zero = '';
			let idx = 0;


			// Generate VAT Matrix
			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++)
			{
				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.trim() == '')))
				{	vtype = '';
				}else
				{	vtype = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase().trim();
				}

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate == 0)))
				{	vrate = '0';
				}else
				{	vrate = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate.toString().trim();
				}

				if ((vtype != '') || (vrate != ''))
				{
					str_vrate = vrate;

					cpt = 1;
					n = 15 - str_vrate.length;

					str_zero = '';

					while (cpt < n)
					{
						str_zero += '0';
						cpt++;
					}

					str_vrate = str_zero + str_vrate;



					if (vat_matrix_key.indexOf(vtype + "|" + str_vrate) == -1) // + "|" + str_vother) == -1)
					{
						vat_matrix_key.push(vtype + "|" + str_vrate); // + "|" + str_vother);
						vat_matrix_rate.push(parseFloat(0));
						vat_matrix_type.push('');
						vat_matrix_wo_vat.push(parseFloat(0));
						vat_matrix_base.push(parseFloat(0));
						vat_matrix_vat.push(parseFloat(0));
						vat_matrix_inc_vat.push(parseFloat(0));
					}
				}
			}



			vat_matrix_key.sort();

			for(var k = 0; k < vat_matrix_key.length; k++)
			{
				let value = vat_matrix_key[k];

				let f = value.substring(0, value.indexOf('|'));

				let last = value.substring(value.indexOf('|')+1, value.length);

				let s = last;

				if (s.trim() == '')
				{	s = '0';
				}

				s = parseFloat(s).toString().trim();


				cpt = 1;
				n = 15 - s.length;


				str_zero = '';

				while (cpt < n)
				{
					str_zero += '0';
					cpt++;
				}

				s = str_zero + s;


				value = f + '|' + s; // + '|' + last;

				vat_matrix_key[k] = value;
			}


			// Item Budget Extra Discount Re-init to zero
			if (silicon_ioi.doctype.ioiSalesPOS.use_triggered_budget_discount == 1) {

				for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

					if (silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id != silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_condition_id) {

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id.trim() != '')) {

							let init_extra_discount = false;

							if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].order_master_link) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].order_master_link) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].order_master_link.trim() == ''))) {
								init_extra_discount = true;
							}


							if (init_extra_discount) {

								let has_detail_discount = 0

								let method = path_item_budget + '.ioi_item_budget_has_detail_discounts';

								frappe.call({  	method: method,
												args: {"item_budget_id": silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id, "currency_id": silicon_ioi.doctype.ioiSalesPOS.doc.currency_id},
												async: false,
												callback:function(r)	{
													has_detail_discount = r.message;
												}
								});

								if (has_detail_discount == 1) {
									silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount = 0;
								}
							}
						}
					}
				}
			}


			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++)
			{
				let qty = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty;

				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].weight)
				{
					total_weight += silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].weight;
				}

				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].volume)
				{
					total_volume += silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].volume;
				}

				// Compute value line doc currency
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency = silicon_ioi.doctype.ioiSalesPOS.line_compute_value(
					silicon_ioi.doctype.ioiSalesPOS.doc.document_calc_mode, qty, silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].free_part_qty,
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].fixed_price, silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price,
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_discount, silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount,
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount, silicon_ioi.doctype.ioiSalesPOS.doc.base_discount,
					silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount, silicon_ioi.doctype.ioiSalesPOS.doc.credit_mode,
					silicon_ioi.doctype.ioiSalesPOS.journal_unit_price_decimal_number,
					silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding,
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].is_option);

				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].is_option == 1)
				{
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].internal_value_line_option = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
					tot_internal_value_line_option += parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].internal_value_line_option);
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency = 0;
				}

				// Compute value line sys currency
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_sys_currency = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate_inv)

				// Compute total negative part
				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency < 0)
				{	total_wo_vat_neg_part += parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
				}


				// Compute internal line doc no esc
				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].no_financial_discount == 1)
				{
					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency == 0)
					{
						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].internal_line_doc_no_esc = 0;
					}else
					{	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].internal_line_doc_no_esc = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
					}
				}else
				{	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].internal_line_doc_no_esc = 0;
				}

				// Sub total
				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sub_total_break == 0)
				{
					tot_sub_total += parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sub_total = 0;
				}else
				{
					tot_sub_total += parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);

					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sub_total = parseFloat(tot_sub_total);
				}

				// Populate VAT matrix
				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.trim() == '')))
				{	vtype = '';
				}else
				{	vtype = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase().trim();
				}

				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate == 0)))
				{	vrate = '0';
				}else
				{	vrate = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate.toString().trim();
				}

				if ((vtype != '') || (vrate != ''))
				{

					str_vrate = vrate;

					cpt = 1;
					n = 15 - str_vrate.length;

					str_zero = '';

					while (cpt < n)
					{
						str_zero += '0';
						cpt++;
					}

					str_vrate = str_zero + str_vrate;

					idx = vat_matrix_key.indexOf(vtype + "|" + str_vrate); // + "|" + vother);


					vat_matrix_wo_vat[idx] = parseFloat(vat_matrix_wo_vat[idx]) + parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);

					if ((!silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode) || ((silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode) && ((silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode == 0) || (silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode == 1))))
					{	vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]) + parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
					}else
					{
						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].no_financial_discount) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].no_financial_discount == 1))
						{	vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]) + parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
						}else
						{	vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]) + (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency) - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency) * fin_disc / 100 ))
						}

					}

					vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]);

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) && ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate.toString().trim() != '')))
					{	vat_matrix_rate[idx] = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate);
					}

					vat_matrix_type[idx] = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type;


					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type)
					{
						let rate = 0;

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate != 0))
						{	rate = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate;
						}

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
						{	vat_matrix_inc_vat[idx] = parseFloat(vat_matrix_inc_vat[idx]) + parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000) )));
						}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
						{	vat_matrix_inc_vat[idx] = parseFloat(vat_matrix_inc_vat[idx]) + parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
						}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
						{	vat_matrix_inc_vat[idx] = parseFloat(vat_matrix_inc_vat[idx]) + parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency)
						}

						if ((!silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode) || ((silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode) && ((silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode == 0) || (silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode == 1))))
						{
							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
							{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000)));
							}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
							{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
							}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
							{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
							}
						}else{
							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].no_financial_discount) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].no_financial_discount == 1))
							{
								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
								{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000)));
								}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
								{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
								}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
								{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
								}
							}else{
								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
								{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency - (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * fin_disc / 100.00)) * ((rate / 100.000000));
								}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
								{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
								}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
								{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
								}
							}

						}
					}

				}
			}

			if (silicon_ioi.doctype.ioiSalesPOS.use_triggered_budget_discount == 1) {

				let item_budget = []

				for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

					if (silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id != silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_condition_id) {

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id.trim() != '')) {

							let init_extra_discount = false;

							if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].order_master_link) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].order_master_link) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].order_master_link.trim() == ''))) {
								init_extra_discount = true;
							}


							if (init_extra_discount) {

								let has_detail_discount = 0

								let method = path_item_budget + '.ioi_item_budget_has_detail_discounts';

								frappe.call({  	method: method,
												args: {"item_budget_id": silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id, "currency_id": silicon_ioi.doctype.ioiSalesPOS.doc.currency_id},
												async: false,
												callback:function(r)	{
													has_detail_discount = r.message;
												}
								});

								if (has_detail_discount == 1) {

									let item_budget_found = false;

									for (var j = 0; j < item_budget.length; j++) {

										if ((item_budget[j][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id) && (item_budget[j][1] == silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) ) {

											item_budget[j][2] += parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
											item_budget_found = true;
											break;
										}
									}

									if (!item_budget_found) {
										item_budget[item_budget.length] = [silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id, silicon_ioi.doctype.ioiSalesPOS.doc.currency_id, parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency)];
									}
								}
							}
						}
					}
				}

				if (item_budget.length > 0) {

					let data = []

					let method = path_item_budget + '.ioi_item_budget_get_discounts_to_apply';

					frappe.call({  	method: method,
									args: {"item_budget": item_budget},
									async: false,
									callback:function(r)	{
										data = r.message;
									}
					});

					if (data.length > 0) {

						for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++)
						{
							if (silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id != silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_condition_id) {

								let update_extra_discount = false;

								if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].order_master_link) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].order_master_link) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].order_master_link.trim() == ''))) {
									update_extra_discount = true;
								}


								if (update_extra_discount) {

									for (var j = 0; j < data.length; j++) {

										if (data[j].item_budget_id == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id) {
											silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount = data[j].extra_discount;
											break;
										}

									}
								}
							}
						}


						// Recompute line with Budget Extra discount

						vat_matrix_key = [];
						vat_matrix_type = [];
						vat_matrix_rate = [];
						vat_matrix_wo_vat = [];
						vat_matrix_base = [];
						vat_matrix_vat = [];
						vat_matrix_inc_vat = [];


						// Generate VAT Matrix
						for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++)
						{
							if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.trim() == '')))
							{	vtype = '';
							}else
							{	vtype = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase().trim();
							}

							if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate == 0)))
							{	vrate = '0';
							}else
							{	vrate = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate.toString().trim();
							}

							if ((vtype != '') || (vrate != ''))
							{
								str_vrate = vrate;

								cpt = 1;
								n = 15 - str_vrate.length;

								str_zero = '';

								while (cpt < n)
								{
									str_zero += '0';
									cpt++;
								}

								str_vrate = str_zero + str_vrate;



								if (vat_matrix_key.indexOf(vtype + "|" + str_vrate) == -1) // + "|" + str_vother) == -1)
								{
									vat_matrix_key.push(vtype + "|" + str_vrate); // + "|" + str_vother);
									vat_matrix_rate.push(parseFloat(0));
									vat_matrix_type.push('');
									vat_matrix_wo_vat.push(parseFloat(0));
									vat_matrix_base.push(parseFloat(0));
									vat_matrix_vat.push(parseFloat(0));
									vat_matrix_inc_vat.push(parseFloat(0));
								}
							}
						}



						vat_matrix_key.sort();

						for(var k = 0; k < vat_matrix_key.length; k++)
						{
							let value = vat_matrix_key[k];

							let f = value.substring(0, value.indexOf('|'));

							let last = value.substring(value.indexOf('|')+1, value.length);

							let s = last;

							if (s.trim() == '')
							{	s = '0';
							}

							s = parseFloat(s).toString().trim();


							cpt = 1;
							n = 15 - s.length;


							str_zero = '';

							while (cpt < n)
							{
								str_zero += '0';
								cpt++;
							}

							s = str_zero + s;


							value = f + '|' + s; // + '|' + last;

							vat_matrix_key[k] = value;
						}


						for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++)
						{
							let qty = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty;

							if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].weight)
							{
								total_weight += silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].weight;
							}

							if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].volume)
							{
								total_volume += silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].volume;
							}

							// Compute value line doc currency
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency = silicon_ioi.doctype.ioiSalesPOS.line_compute_value(
								silicon_ioi.doctype.ioiSalesPOS.doc.document_calc_mode, qty, silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].free_part_qty,
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].fixed_price, silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price,
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_discount, silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount,
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount, silicon_ioi.doctype.ioiSalesPOS.doc.base_discount,
								silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount, silicon_ioi.doctype.ioiSalesPOS.doc.credit_mode,
								silicon_ioi.doctype.ioiSalesPOS.journal_unit_price_decimal_number,
								silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding,
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].is_option);

							if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].is_option == 1)
							{
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].internal_value_line_option = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
								tot_internal_value_line_option += parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].internal_value_line_option);
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency = 0;
							}

							// Compute value line sys currency
							silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_sys_currency = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate_inv)

							// Compute total negative part
							if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency < 0)
							{	total_wo_vat_neg_part += parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
							}


							// Compute internal line doc no esc
							if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].no_financial_discount == 1)
							{
								if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency == 0)
								{
									silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].internal_line_doc_no_esc = 0;
								}else
								{	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].internal_line_doc_no_esc = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
								}
							}else
							{	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].internal_line_doc_no_esc = 0;
							}

							// Sub total
							if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sub_total_break == 0)
							{
								tot_sub_total += parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sub_total = 0;
							}else
							{
								tot_sub_total += parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);

								silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sub_total = parseFloat(tot_sub_total);
							}

							// Populate VAT matrix
							if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.trim() == '')))
							{	vtype = '';
							}else
							{	vtype = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase().trim();
							}

							if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate == 0)))
							{	vrate = '0';
							}else
							{	vrate = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate.toString().trim();
							}

							if ((vtype != '') || (vrate != ''))
							{

								str_vrate = vrate;

								cpt = 1;
								n = 15 - str_vrate.length;

								str_zero = '';

								while (cpt < n)
								{
									str_zero += '0';
									cpt++;
								}

								str_vrate = str_zero + str_vrate;

								idx = vat_matrix_key.indexOf(vtype + "|" + str_vrate); // + "|" + vother);


								vat_matrix_wo_vat[idx] = parseFloat(vat_matrix_wo_vat[idx]) + parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);

								if ((!silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode) || ((silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode) && ((silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode == 0) || (silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode == 1))))
								{	vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]) + parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
								}else
								{
									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].no_financial_discount) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].no_financial_discount == 1))
									{	vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]) + parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
									}else
									{	vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]) + (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency) - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency) * fin_disc / 100 ))
									}

								}

								vat_matrix_base[idx] = parseFloat(vat_matrix_base[idx]);

								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) && ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate.toString().trim() != '')))
								{	vat_matrix_rate[idx] = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate);
								}

								vat_matrix_type[idx] = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type;


								if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type)
								{
									let rate = 0;

									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate != 0))
									{	rate = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate;
									}

									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
									{	vat_matrix_inc_vat[idx] = parseFloat(vat_matrix_inc_vat[idx]) + parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000) )));
									}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
									{	vat_matrix_inc_vat[idx] = parseFloat(vat_matrix_inc_vat[idx]) + parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency);
									}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
									{	vat_matrix_inc_vat[idx] = parseFloat(vat_matrix_inc_vat[idx]) + parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency)
									}

									if ((!silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode) || ((silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode) && ((silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode == 0) || (silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode == 1))))
									{
										if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
										{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000)));
										}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
										{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
										}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
										{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
										}
									}else{
										if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].no_financial_discount) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].no_financial_discount == 1))
										{
											if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
											{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * ((rate / 100.000000)));
											}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
											{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
											}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
											{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
											}
										}else{
											if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'XXX'))
											{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency - (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * fin_disc / 100.00)) * ((rate / 100.000000));
											}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'INT'))
											{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
											}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'S') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NCO'))
											{	vat_matrix_vat[idx] = parseFloat(vat_matrix_vat[idx]) + 0;
											}
										}

									}
								}

							}
						}
					}

				}
			}
		}

		// Update wo_vat, base_vat, vat, inc_vat in the header
		silicon_ioi.doctype.ioiSalesPOS.doc.total_htva = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_base = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_vat = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_wo_vat_neg_part = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_in_system_currency = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_htva_option_part = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_fin_discount_base = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_fin_discount_tva = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_fin_discount_tvac = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_fin_discount = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_fast_fin_discount = 0;

		silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat1 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.base_vat1 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.vat_type1 = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.rate_vat1 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.vat1 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat1 = 0;

		silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat2 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.base_vat2 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.vat_type2 = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.rate_vat2 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.vat2 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat2 = 0;

		silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat3 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.base_vat3 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.vat_type3 = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.rate_vat3 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.vat3 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat3 = 0;

		silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat4 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.base_vat4 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.vat_type4 = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.rate_vat4 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.vat4 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat4 = 0;

		silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat5 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.base_vat5 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.vat_type5 = '';
		silicon_ioi.doctype.ioiSalesPOS.doc.rate_vat5 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.vat5 = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat5 = 0;

		for (var i = 0; i < vat_matrix_key.length; i++)
		{
			if (i == 0)
			{	silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat1 = parseFloat(vat_matrix_wo_vat[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.base_vat1 = parseFloat(vat_matrix_base[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.vat_type1 = vat_matrix_type[i];
				silicon_ioi.doctype.ioiSalesPOS.doc.rate_vat1 = parseFloat(vat_matrix_rate[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.vat1 = parseFloat(vat_matrix_vat[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat1 = parseFloat(vat_matrix_inc_vat[i]);
			}else if (i == 1)
			{	silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat2 = parseFloat(vat_matrix_wo_vat[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.base_vat2 = parseFloat(vat_matrix_base[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.vat_type2 = vat_matrix_type[i];
				silicon_ioi.doctype.ioiSalesPOS.doc.rate_vat2 = parseFloat(vat_matrix_rate[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.vat2 = parseFloat(vat_matrix_vat[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat2 = parseFloat(vat_matrix_inc_vat[i]);
			}else if (i == 2)
			{	silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat3 = parseFloat(vat_matrix_wo_vat[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.base_vat3 = parseFloat(vat_matrix_base[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.vat_type3 = vat_matrix_type[i];
				silicon_ioi.doctype.ioiSalesPOS.doc.rate_vat3 = parseFloat(vat_matrix_rate[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.vat3 = parseFloat(vat_matrix_vat[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat3 = parseFloat(vat_matrix_inc_vat[i]);
			}else if (i == 3)
			{	silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat4 = parseFloat(vat_matrix_wo_vat[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.base_vat4 = parseFloat(vat_matrix_base[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.vat_type4 = vat_matrix_type[i];
				silicon_ioi.doctype.ioiSalesPOS.doc.rate_vat4 = parseFloat(vat_matrix_rate[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.vat4 = parseFloat(vat_matrix_vat[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat4 = parseFloat(vat_matrix_inc_vat[i]);
			}else if (i == 4)
			{	silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat5 = parseFloat(vat_matrix_wo_vat[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.base_vat5 = parseFloat(vat_matrix_base[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.vat_type5 = vat_matrix_type[i];
				silicon_ioi.doctype.ioiSalesPOS.doc.rate_vat5 = parseFloat(vat_matrix_rate[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.vat5 = parseFloat(vat_matrix_vat[i]);
				silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat5 = parseFloat(vat_matrix_inc_vat[i]);
			}else
			{	break;
			}
		}

		// update amounts in the header
		let exp = Math.pow(10, silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding);

		silicon_ioi.doctype.ioiSalesPOS.doc.total_htva = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat1 + silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat2 + silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat3 + silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat4 + silicon_ioi.doctype.ioiSalesPOS.doc.wo_vat5);
		silicon_ioi.doctype.ioiSalesPOS.doc.total_htva = Math.round(silicon_ioi.doctype.ioiSalesPOS.doc.total_htva * exp) / exp;

		silicon_ioi.doctype.ioiSalesPOS.doc.total_base = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.base_vat1 + silicon_ioi.doctype.ioiSalesPOS.doc.base_vat2 + silicon_ioi.doctype.ioiSalesPOS.doc.base_vat3 + silicon_ioi.doctype.ioiSalesPOS.doc.base_vat4 + silicon_ioi.doctype.ioiSalesPOS.doc.base_vat5);
		silicon_ioi.doctype.ioiSalesPOS.doc.total_base = Math.round(silicon_ioi.doctype.ioiSalesPOS.doc.total_base * exp) / exp;


		if ((!silicon_ioi.doctype.ioiSalesPOS.doc.vat_type) || ((silicon_ioi.doctype.ioiSalesPOS.doc.vat_type) && (silicon_ioi.doctype.ioiSalesPOS.doc.vat_type.trim() == '')))
		{
			silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac = 0;
		}else
		{
			if (silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode != 2)
			{
				silicon_ioi.doctype.ioiSalesPOS.doc.total_vat = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.vat1 + silicon_ioi.doctype.ioiSalesPOS.doc.vat2 + silicon_ioi.doctype.ioiSalesPOS.doc.vat3 + silicon_ioi.doctype.ioiSalesPOS.doc.vat4 + silicon_ioi.doctype.ioiSalesPOS.doc.vat5);
				silicon_ioi.doctype.ioiSalesPOS.doc.total_vat = Math.round(silicon_ioi.doctype.ioiSalesPOS.doc.total_vat * exp) / exp;

				silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac = silicon_ioi.doctype.ioiSalesPOS.doc.total_htva + silicon_ioi.doctype.ioiSalesPOS.doc.total_vat;
				silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac = Math.round(silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac * exp) / exp;

			}else
			{	silicon_ioi.doctype.ioiSalesPOS.doc.total_vat = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.vat1 + silicon_ioi.doctype.ioiSalesPOS.doc.vat2 + silicon_ioi.doctype.ioiSalesPOS.doc.vat3 + silicon_ioi.doctype.ioiSalesPOS.doc.vat4 + silicon_ioi.doctype.ioiSalesPOS.doc.vat5);
				silicon_ioi.doctype.ioiSalesPOS.doc.total_vat = Math.round(silicon_ioi.doctype.ioiSalesPOS.doc.total_vat * exp) / exp;

				silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac = silicon_ioi.doctype.ioiSalesPOS.doc.total_htva + silicon_ioi.doctype.ioiSalesPOS.doc.total_vat;
				silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac = Math.round(silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac * exp) / exp;
			}
		}

		silicon_ioi.doctype.ioiSalesPOS.doc.total_in_system_currency = (silicon_ioi.doctype.ioiSalesPOS.doc.total_htva * silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate_inv) * silicon_ioi.doctype.ioiSalesPOS.doc.credit_mode;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_in_system_currency = Math.round(silicon_ioi.doctype.ioiSalesPOS.doc.total_in_system_currency * exp) / exp;

		silicon_ioi.doctype.ioiSalesPOS.doc.total_htva_option_part = Math.round(tot_internal_value_line_option * exp) / exp;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_wo_vat_neg_part = Math.round(total_wo_vat_neg_part * exp) / exp;

		// Total financial discount
		silicon_ioi.doctype.ioiSalesPOS.doc.total_fin_discount = 0;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_fast_fin_discount = 0;

		if (silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode == 1)
		{

			if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail)
			{
				let total_fin_discount = 0;

				for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++)
				{
					if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].no_financial_discount == 0)
					{

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'XXX')) {
							total_fin_discount += silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * ((parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) / 100.000000) ));
						}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'INT')) {
							total_fin_discount += 0;
						}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NCO') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'S')) {
							total_fin_discount += 0;
						}
					}
				}

				silicon_ioi.doctype.ioiSalesPOS.doc.total_fin_discount = silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac - (parseFloat(total_fin_discount) / 100.00 * parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount));
				silicon_ioi.doctype.ioiSalesPOS.doc.total_fast_fin_discount = silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac - (parseFloat(total_fin_discount) / 100.00 * parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.fast_financial_discount));
			}
		}else if (silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount_mode == 2)
		{
			let total_fin_discount = 0;

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++)
			{
				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].no_financial_discount == 0)
				{

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'N') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NAT') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'X') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'XXX')) {
						total_fin_discount += silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * ((parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate) / 100.000000) ));
					}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'E') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'INT')) {
						total_fin_discount += 0;
					}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'I') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'EEC') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'C') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'NCO') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_type.toUpperCase() == 'S')) {
						total_fin_discount += 0;
					}
				}
			}

			let tot_tvac = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat1 + silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat2 + silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat3 + silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat4 + silicon_ioi.doctype.ioiSalesPOS.doc.inc_vat5);

			silicon_ioi.doctype.ioiSalesPOS.doc.total_fin_discount = tot_tvac - (parseFloat(total_fin_discount) / 100.00 * parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.financial_discount));
			silicon_ioi.doctype.ioiSalesPOS.doc.total_fast_fin_discount = tot_tvac - (parseFloat(total_fin_discount) / 100.00 * parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.fast_financial_discount));

		}


		// Total weight and volume
		silicon_ioi.doctype.ioiSalesPOS.doc.total_weight = total_weight;
		silicon_ioi.doctype.ioiSalesPOS.doc.total_volume = total_volume;



		document.getElementById('ioi_sales_pos_total_tvac').innerText = '';



		exp = Math.pow(10, 2);


		let total_tvac = 0;

		if (silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac) {
			total_tvac = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac);
		}

		total_tvac =  Math.round(total_tvac * exp) / exp;

		document.getElementById('ioi_sales_pos_total_tvac').innerText = silicon_ioi.doctype.ioiSalesPOS.format_number(total_tvac.toFixed(2)) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id;


		let already_paid = 0;

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.name) && (silicon_ioi.doctype.ioiSalesPOS.doc.name.trim() != '')) {

			frappe.call({  	method: path_sales_pos + '.ioi_sales_pos_get_already_paid',
							args: 	{	"name": silicon_ioi.doctype.ioiSalesPOS.doc.name
							},
							async: false,
							callback: function(r)	{

								already_paid = r.message;

							}
			});
		}


		already_paid = Math.round(already_paid * exp) / exp;


		let still_to_pay = parseFloat(total_tvac) - parseFloat(already_paid);

		still_to_pay = Math.round(still_to_pay * exp) / exp;

		document.getElementById('ioi_sales_pos_pay_already_paid').innerText = silicon_ioi.doctype.ioiSalesPOS.format_number(already_paid.toFixed(2)) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id;

		document.getElementById('ioi_sales_pos_pay_still_to_pay').innerText = silicon_ioi.doctype.ioiSalesPOS.format_number(still_to_pay.toFixed(2)) + ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id;

		let color = '#60A367';



		if (Math.round(still_to_pay * exp) / exp > 0) {
			color = '#ED514C';
		}

		document.getElementById('ioi_sales_pos_pay_still_to_pay').style.color = color;




		silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 0;

		if (parseFloat(already_paid) != 0) {

			if (parseFloat(already_paid) < parseFloat(total_tvac)) {

				silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 1;

			}else{
				silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id = 2;
			}
		}



		let description = '';
		let bgcolor = '';

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.doctype) && (silicon_ioi.doctype.ioiSalesPOS.doc.doctype.trim() != '')) {

			if (silicon_ioi.doctype.ioiCashDesk.verifify_pending_payment(silicon_ioi.doctype.ioiSalesPOS.doc.doctype, silicon_ioi.doctype.ioiSalesPOS.doc.name, false)){
				if (silicon_ioi.doctype.ioiCashDesk.verifify_pending_payment(silicon_ioi.doctype.ioiSalesPOS.doc.doctype, silicon_ioi.doctype.ioiSalesPOS.doc.name, true)){
					description = __("Pending transaction (Waiting)");
				}else{
					description = __("Pending transaction (To finalize)");
				}
				bgcolor = '#FF8469';
				ioiSalesPOS.payment_area_information_visibility(false);

			}
			else{
				ioiSalesPOS.payment_area_information_visibility(true);
				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id) && ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == '') || (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == 0)))) {
					description = __("Unpaid");
					bgcolor = '#D9D3D2';
				}else if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == 1) {
					description = __("Partial");
					bgcolor = '#FFC869';
				}else{
					description = __("Total")
					bgcolor = '#7ECC6E';
				}
			}

			document.getElementById('ioi_sales_pos_pay_status_panel').style.backgroundColor = bgcolor;
			document.getElementById('ioi_sales_pos_pay_status').innerText = description;
		}else{
			ioiSalesPOS.payment_area_information_visibility(true);
			if ((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id) && ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == '') || (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == 0)))) {
				description = __("Unpaid");
				bgcolor = '#D9D3D2';
			}else if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == 1) {
				description = __("Partial");
				bgcolor = '#FFC869';
			}else{
				description = __("Total")
				bgcolor = '#7ECC6E';
			}
			document.getElementById('ioi_sales_pos_pay_status_panel').style.backgroundColor = bgcolor;
			document.getElementById('ioi_sales_pos_pay_status').innerText = description;
		}

	}

	static line_compute_value(	document_calc_mode, line_qty, line_free_qty, line_fixed_price, line_unit_price, line_discount_in_value, line_base_discount,
								line_extra_discount, base_discount, extra_discount, credit_mode, unit_price_digit_rounding, currency_digit_rounding, is_option)
	{
		let value_line = 0;
		let calc_mode = 0;
		let mode_credit = 1;

		if ((!credit_mode) || (credit_mode.toString() == ''))
		{	mode_credit = 1;
		}else
		{	mode_credit = credit_mode;
		}

		document.getElementById('ioi_sales_last_scan').innerText = '';


		if ((mode_credit != -1 ) && (mode_credit != 1))
		{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Credit mode has to be -1 or 1'));
			raise;
		}

		if ((!document_calc_mode) || ((document_calc_mode) && (document_calc_mode.toString() == '')))
		{	calc_mode = 0;
		}else
		{	calc_mode = document_calc_mode;
		}

		if ((calc_mode != 0) && (calc_mode != 1) && (calc_mode != 2) && (calc_mode != 3))
		{
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document calc mode has to be 0, 1, 2 or 3'));
			raise;
		}

		let unit_price_rounding = 2;

		if ((unit_price_digit_rounding) || (unit_price_digit_rounding >= 0))
		{	unit_price_rounding = unit_price_digit_rounding;
		}

		let currency_rounding = 2;

		if ((currency_digit_rounding) || (currency_digit_rounding >= 0))
		{	currency_rounding = currency_digit_rounding;
		}

		let option = is_option;

		if ((line_qty == 0) && (line_free_qty == 0) && (line_fixed_price == 0))
		{
			value_line = 0;
		}else
		{
			let qty = 0;
			let free_qty = 0;
			let fixed_price = 0;
			let unit_price = 0;
			let line_rval = 0;
			let line_rb = 0;
			let line_re = 0;
			let master_rb = 0;
			let master_re = 0;
			let exp = 0;

			if (line_qty)
			{	qty = line_qty;
			}

			if (line_free_qty)
			{	free_qty = line_free_qty;
			}

			if (line_fixed_price)
			{	fixed_price = line_fixed_price;
			}

			if (line_unit_price)
			{	unit_price = line_unit_price;
			}

			if (line_discount_in_value)
			{	line_rval = line_discount_in_value;
			}

			if (line_base_discount)
			{	line_rb = line_base_discount;
			}

			if (line_extra_discount)
			{	line_re = line_extra_discount;
			}

			if (base_discount)
			{	master_rb = base_discount;
			}

			if (extra_discount)
			{	master_re = extra_discount;
			}


			if ((calc_mode == 0) || (calc_mode == 1))
			{	value_line = parseFloat(( fixed_price + ( ( qty - free_qty  ) * ( unit_price - line_rval ) * ( 1 - ( line_rb / 100.000000)) * ( 1 - ( line_re / 100.000000)) * ( 1 - ( master_rb / 100.000000)) * ( 1 - ( master_re / 100.000000)) ) ) * mode_credit);
			}else if ((calc_mode == 2) || (calc_mode == 3))
			{
				exp = Math.pow(10, unit_price_rounding);
				let price_rounded = unit_price - line_rval;
				price_rounded = Math.round(price_rounded * exp) / exp;
				value_line = parseFloat(( fixed_price + ( ( qty - free_qty  ) * ( parseFloat(price_rounded) ) * ( 1 - ( line_rb / 100.000000)) * ( 1 - ( line_re / 100.000000)) * ( 1 - ( master_rb / 100.000000)) * ( 1 - ( master_re / 100.000000)) ) ) * mode_credit);
			}

			if ((calc_mode == 1) || (calc_mode == 3))
			{
				exp = Math.pow(10, currency_rounding);
				value_line = Math.round(value_line * exp) / exp;
			}

		}

		return value_line;
	}

	static populate_line(idx, source_type = 'ITEM_ID', unit_id = '')
	{
		let can_populate_line = false;

		let item_id = ''
		let barcode_ref = ''

		if ((source_type.toUpperCase() == 'ITEM_ID') || (source_type.toUpperCase() == 'QTY'))
		{
			if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id.trim() != ''))
			{	can_populate_line = true;
				item_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id;

			}else
			{
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_source = '';
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_tvac_source = '';
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].fixed_price_source = '';
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount_source = '';
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount_source = '';
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].discount_value_source = '';
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].min_order_qty_source = '';
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].multiple_order_qty_source = '';
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_num_source = '';
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_den_source = '';

				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_id = '';
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_coef = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_ref_date = '';
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_ref_value = 0
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_cur_value = 0;
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].decimals_allowed = 1;



			}

			if (source_type.toUpperCase() == 'QTY') {

				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].withme == 1) {
					can_populate_line = false;
				}

			}
		}else
		{
			if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].barcode_reference) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].barcode_reference.trim() != ''))
			{	can_populate_line = true;
				barcode_ref = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].barcode_reference;
			}

		}

		if (can_populate_line)
		{
			document.getElementById('ioi_sales_last_scan').innerText = '';

			if ((!silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_id.trim() == '')))
			{
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Customer has to be filled'));
				silicon_ioi.doctype.ioiSalesPOS.doc.item_id = '';
				can_populate_line = false;
			}

			if ((can_populate_line) && ((!silicon_ioi.doctype.ioiSalesPOS.doc.sales_catalog_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.sales_catalog_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.sales_catalog_id.trim() == ''))))
			{
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Sales catalog has to be filled'));
				silicon_ioi.doctype.ioiSalesPOS.doc.item_id = '';
				can_populate_line = false;
			}

			if ((can_populate_line) && ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_condition_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_condition_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_condition_id.trim() == ''))))
			{
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Sales condition (item line) has to be filled'));
				silicon_ioi.doctype.ioiSalesPOS.doc.item_id = '';
				can_populate_line = false;
			}

			if ((can_populate_line) && ((!silicon_ioi.doctype.ioiSalesPOS.doc.document_date) || ((silicon_ioi.doctype.ioiSalesPOS.doc.document_date) && (silicon_ioi.doctype.ioiSalesPOS.doc.document_date.trim() == ''))))
			{
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Document date has to be filled'));
				silicon_ioi.doctype.ioiSalesPOS.doc.item_id = '';
				can_populate_line = false;
			}


			if ((can_populate_line) && ((!silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.currency_id.trim() == ''))))
			{
				silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Currency has to be filled'));
				silicon_ioi.doctype.ioiSalesPOS.doc.item_id = '';
				can_populate_line = false;
			}

            let dc_type = '';
            let qty = 0;

			dc_type = 'DELIVERY';

			if (!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty)
			{
				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty = 0;
				qty = 0;
			}else
			{
				qty = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty;
			}

			if (can_populate_line)
			{
				let me = this;

				frappe.call({  	method: path_sales_document + '.ioi_sales_document_populate_line',
								args: 	{	"doctype": 'ioi Sales Delivery',
											"document_type": dc_type,
											"source_type": source_type,
											"item_id": item_id,
											"barcode_ref": barcode_ref,
											"customer_id": silicon_ioi.doctype.ioiSalesPOS.doc.order_customer_id,
											"sales_catalog_id": silicon_ioi.doctype.ioiSalesPOS.doc.sales_catalog_id,
											"sales_condition_id": silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_condition_id,
											"currency_id": silicon_ioi.doctype.ioiSalesPOS.doc.currency_id,
											"currency_rate_inv": silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate_inv,
											"currency_rate": silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate,
											"system_currency_id": silicon_ioi.doctype.ioiSalesPOS.system_currency,
											"document_date": silicon_ioi.doctype.ioiSalesPOS.doc.document_date,
											"line_qty": qty,
											"unit_id": unit_id,
											"index_pivot_date": silicon_ioi.doctype.ioiSalesPOS.doc.index_pivot_date,
											"document_language": silicon_ioi.doctype.ioiSalesPOS.doc.language,
											"journal_id" : silicon_ioi.doctype.ioiSalesPOS.doc.journal_id,
											"customer_family_1_id": silicon_ioi.doctype.ioiSalesPOS.doc.customer_family_1_id,
											"is_sales_pos": 1

								},


								async: false,
								callback: function(r)	{

															if (source_type.toUpperCase() != 'QTY')
															{
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id = '';
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description = '';
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_mode = '';

																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].account_id = silicon_ioi.doctype.ioiSalesPOS.doc.accounting_default_account_id;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].analytic1_id = '';
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].analytic2_id = '';
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].analytic3_id = '';
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].analytic4_id = '';

																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id = '';

																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].stock_unit_id = '';
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_sales_unit = 1;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_stock_unit = 1;
															}

															if (source_type.toUpperCase() != 'QTY') {

																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price = 0;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_tvac = 0;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].fixed_price = 0;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount = 0;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount = 0;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].discount_in_value = 0;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].discount_only = 0;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].no_financial_discount = 0;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_included_in_price = 0;
															}

															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_id = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_coef = 0;
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_ref_date = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_ref_value = 0
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_cur_value = 0;
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_active = 0;
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].ftg_priority = 0;

															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].alert_margin_rate = silicon_ioi.doctype.ioiSalesPOS.journal_default_alert_margin_rate
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].alert_margin_mode = silicon_ioi.doctype.ioiSalesPOS.journal_margin_mode
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].cost_margin_rate = 0
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_margin_rate = 0
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].margin_alert = 0
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_budget_id = ''
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].cost_value = 0


															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].supplier_fnd = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].purch_unit_price_fnd = 0;



															if (source_type.toUpperCase() != 'QTY')
															{
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate = silicon_ioi.doctype.ioiSalesPOS.doc.spec_vat_rate;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].withme_id = '';
															}

															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_num = 0;
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_den = 0;
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_qty = 0;

															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].external_ref = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_service = '';

															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_source = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_tvac_source = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].fixed_price_source = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount_source = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount_source = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].discount_value_source = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].min_order_qty_source = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].multiple_order_qty_source = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_num_source = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_den_source = '';

															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].manual_price = 0;

															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].decimals_allowed = 1;


															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_unit_price = 0;
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_fixed_price = 0;
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_base_discount = 0;
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_extra_discount = 0;
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_value_discount = 0;

															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_value_line_doc_currency = 0;



															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].ext_unit_price = 0;
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].ext_unit_id = '';
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].ext_unit_coef = 0;
															silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].ext_qty = 0;



															if (r.message.error == 1)
															{
																silicon_ioi.doctype.ioiSalesPOS.add_log(2, r.message.error_message);

															}else
															{
																if (source_type.toUpperCase() != 'QTY')
																{
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id = r.message.item_id;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description = r.message.item_description;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_mode = r.message.mode;

																	if ((r.message.manufacturer_ref != null) && (r.message.manufacturer_ref != '')) {
																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].manufacturer_ref = r.message.manufacturer_ref;
																	}
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id = r.message.unit_id;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].invoicing_mode = r.message.invoicing_mode;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].stock_unit_id = r.message.stock_unit_id;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_sales_unit = r.message.coef_sales_unit;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_stock_unit = r.message.coef_stock_unit;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_service = r.message.vat_service;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].weight = r.message.weight;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].tare = r.message.tare;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].volume = r.message.volume;


																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].line_memo = '';
																}


																if (source_type.toUpperCase() != 'QTY')
																{
																	if (silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_fill_memo == 1)
																	{
																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].line_memo = r.message.item_memo;
																	}

																	if (silicon_ioi.doctype.ioiSalesPOS.journal_deliveries_fill_thumbnail == 1)
																	{
																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].thumbnail = r.message.thumbnail;
																	}
																}

																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty = r.message.line_qty;


																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].decimals_allowed = r.message.decimals_allowed;

																if ((r.message.min_order_qty) && (r.message.min_order_qty != 0))
																{
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].min_order_qty = r.message.min_order_qty;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].min_order_qty_source = r.message.min_order_qty_source;
																}

																if ((r.message.multiple_order_qty) && (r.message.multiple_order_qty != 0))
																{
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].multiple_order_qty = r.message.multiple_order_qty;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].multiple_order_qty_source = r.message.multiple_order_qty_source;
																}

																if ((r.message.free_part_num) && (r.message.free_part_num != 0))
																{
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_num = r.message.free_part_num;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_num_source = r.message.free_part_num_source;
																}

																if ((r.message.free_part_den) && (r.message.free_part_den != 0))
																{
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_den = r.message.free_part_den;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_den_source = r.message.free_part_den_source;
																}

																if ((r.message.free_part_qty) && (r.message.free_part_qty != 0))
																{
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_qty = r.message.free_part_qty;
																}

																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].ftg_priority = r.message.ftg_priority;

																// Index
																let get_index = true;

																if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].order_master_link) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].order_master_link.trim() != ''))
																{	get_index = false;
																}

																if (get_index)
																{
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_id = r.message.index_id;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_coef = r.message.index_coef;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_ref_date = r.message.index_ref_date;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_ref_value = r.message.index_ref_value;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_cur_value = r.message.index_cur_value;

																	if ((r.message.index_id) && (r.message.index_id.trim() != ''))
																	{
																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].index_active = 1;
																	}
																}

																if (source_type.toUpperCase() != 'QTY')
																{

																	if (r.message.has_price_defined == 0) {
																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate.vat_rate = silicon_ioi.doctype.ioiSalesPOS.doc.spec_vat_rate;

																	}else{
																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate = r.message.specific_vat_rate;
																	}

																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].line_memo = r.message.item_memo;

																	if ((r.message.account_id) && (r.message.account_id.trim() != '')) {
																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].account_id = r.message.account_id;
																	}
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].analytic1_id = r.message.analytic1_id;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].analytic2_id = r.message.analytic2_id;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].analytic3_id = r.message.analytic3_id;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].analytic4_id = r.message.analytic4_id;
																}


																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_cost_std_stamp = r.message.item_cost_std_stamp;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_cost = r.message.item_cost_calc_stamp;



																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_budget_id = r.message.budget_id;

																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].alert_margin_rate = silicon_ioi.doctype.ioiSalesPOS.journal_default_alert_margin_rate
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].alert_margin_mode = silicon_ioi.doctype.ioiSalesPOS.journal_margin_mode

																if ((r.message.alert_margin_rate) && (r.message.alert_margin_rate != 0)) {

																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].alert_margin_rate = r.message.alert_margin_rate;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].alert_margin_mode = r.message.alert_margin_mode;
																}


																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].cost_margin_rate = 0;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_margin_rate = 0;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].margin_alert = 0;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].cost_value = 0;



																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].supplier_fnd = r.message.supplier_fnd;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].purch_unit_price_fnd = r.message.purch_unit_price_fnd;



																if (source_type.toUpperCase() != 'QTY')
																{
																	if ((r.message.warehouse_id) && (r.message.warehouse_id.trim() != ''))
																	{
																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].warehouse_id = r.message.warehouse_id;
																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].warehouse_location_id = r.message.warehouse_location_id;
																	}
																}


																if (r.message.discount_only == 0)
																{
																	if ((r.message.unit_price) && (r.message.unit_price != 0)) {
																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price = r.message.unit_price;
																	}
																	if ((r.message.unit_price_tvac) && (r.message.unit_price_tvac != 0)) {
																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_tvac = r.message.unit_price_tvac;
																	}
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].fixed_price = r.message.fixed_price;

																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_source = r.message.unit_price_source;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_tvac_source = r.message.unit_price_tvac_source;
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].fixed_price_source = r.message.fixed_price_source;
																}


																if (r.message.no_financial_discount == 1)
																{
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].no_financial_discount = 1;
																}

																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount = r.message.base_discount;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount = r.message.extra_discount;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_discount = r.message.discount_in_value;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount_source = r.message.base_discount_source;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount_source = r.message.extra_discount_source;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].discount_value_source = r.message.discount_value_source;

																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_included_in_price = r.message.vat_included_in_price;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].external_ref = r.message.external_reference;

																if (source_type.toUpperCase() != 'QTY')
																{
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].withme_id = r.message.withme_id;
																}


																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_fixed_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].fixed_price;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_base_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_extra_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_value_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_discount;

																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_value_line_doc_currency = 0;



																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].ext_unit_price = r.message.ext_unit_price;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].ext_unit_id = r.message.ext_unit_id;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].ext_unit_coef = r.message.ext_unit_coef;
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].ext_qty = r.message.ext_qty;

																let qt = 0;

																if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty.toString() != '')) {
																	qt = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty;
																}

																if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].reserved_qty) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].reserved_qty.toString() != '')) {
																	qt = qt + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].reserved_qty;
																}

																if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].allocated_qty) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].allocated_qty.toString() != '')) {
																	qt = qt + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].allocated_qty;
																}



																// Compute value line doc currency
																silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_value_line_doc_currency = silicon_ioi.doctype.ioiSalesPOS.line_compute_value(
																	silicon_ioi.doctype.ioiSalesPOS.doc.document_calc_mode, qt, silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_qty,
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].fixed_price, silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price,
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_discount, silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount,
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount, silicon_ioi.doctype.ioiSalesPOS.doc.base_discount,
																	silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount, silicon_ioi.doctype.ioiSalesPOS.doc.credit_mode,
																	silicon_ioi.doctype.ioiSalesPOS.journal_unit_price_decimal_number,
																	silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding,
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].is_option);





																if (source_type.toUpperCase() != 'QTY')
																{
																	if ((r.message.price_description) && (r.message.price_description.trim() != ''))
																	{
																		if (r.message.price_description[0] == '&')
																		{
																			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description + r.message.price_description.substr(1, r.message.price_description.length);
																		}
																	}
																}

																silicon_ioi.doctype.ioiSalesPOS.convert_in_stock_unit(idx, 'delivered_qty');
																silicon_ioi.doctype.ioiSalesPOS.convert_in_stock_unit(idx, 'invoiced_qty');
																silicon_ioi.doctype.ioiSalesPOS.convert_in_stock_unit(idx, 'ordered_qty');
																silicon_ioi.doctype.ioiSalesPOS.convert_in_stock_unit(idx, 'free_part_qty');
																silicon_ioi.doctype.ioiSalesPOS.convert_in_stock_unit(idx, 'reserved_qty');

																if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_condition_id == silicon_ioi.doctype.ioiSalesPOS.customer_manual_sales_condition_id) {
																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_condition_id = silicon_ioi.doctype.ioiSalesPOS.doc.sales_condition_id;
																}

																if (source_type.toUpperCase() == 'ITEM_ID') {

																	let fct_callback = function(warehouse_id = null, warehouse_location_id = null, batch_sn_id = null, code_ref = null, voucher_value_tvac = null, voucher_value = null, voucher_vat_rate = null, qty = null, voucher = null) {

																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].warehouse_id = warehouse_id;
																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].warehouse_location_id = warehouse_location_id;


																		if ((voucher == null) || (voucher == 0)) {
																			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_id = batch_sn_id;
																			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_code_ref = code_ref;
																		}else{
																			if (qty != null) {

																				if (qty <= 0) {
																					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_id = batch_sn_id;
																					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_code_ref = code_ref;
																				}else{
																					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_id = '';
																					silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_code_ref = '';
																				}

																			}else{
																				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_id = batch_sn_id;
																				silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].batch_sn_code_ref = code_ref;
																			}

																		}



																		if (qty != null) {
																			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty = parseFloat(qty)
																		}

																		if (batch_sn_id != null) {

																			if (batch_sn_id != '') {

																				if (voucher_value_tvac != null) {
																					if (parseFloat(voucher_value_tvac) != 0) {
																						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price_tvac = voucher_value_tvac;
																					}
																				}

																				if (voucher_value != null) {
																					if (parseFloat(voucher_value) != 0) {
																						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price = voucher_value;
																					}
																				}

																				if (voucher_vat_rate != null) {
																					if (parseFloat(voucher_vat_rate) != 0) {
																						silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate = voucher_vat_rate;
																					}
																				}
																			}
																		}

																		// Compute value line doc currency
																		silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].def_value_line_doc_currency = silicon_ioi.doctype.ioiSalesPOS.line_compute_value(
																			silicon_ioi.doctype.ioiSalesPOS.doc.document_calc_mode, qt, silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_qty,
																			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].fixed_price, silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price,
																			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].value_discount, silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount,
																			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount, silicon_ioi.doctype.ioiSalesPOS.doc.base_discount,
																			silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount, silicon_ioi.doctype.ioiSalesPOS.doc.credit_mode,
																			silicon_ioi.doctype.ioiSalesPOS.journal_unit_price_decimal_number,
																			silicon_ioi.doctype.ioiSalesPOS.doc.currency_digit_rounding,
																			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].is_option);

																		silicon_ioi.doctype.ioiSalesPOS.current_line_id = idx;

																		silicon_ioi.doctype.ioiSalesPOS.compute_document();

																		silicon_ioi.doctype.ioiSalesPOS.sleep_static(500).then(() => {
																			document.getElementById('ioi_sales_pos_save_button').click();
																		});



																	}

																	silicon_ioi.ioiCommon.ioi_sales_document_get_whs_loc_with_stock('ioi Sales Delivery', silicon_ioi.doctype.ioiSalesPOS.doc.currency_id, silicon_ioi.doctype.ioiSalesPOS.doc.currency_rate_inv,
																																	silicon_ioi.doctype.ioiSalesPOS.doc.journal_id, silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id,
																																	silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty, fct_callback);

																}


															}

														}
				});
			}
		}else
		{

			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].decimals_allowed = 1;


			silicon_ioi.doctype.ioiSalesPOS.compute_document();

			if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
				silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
				silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
			}

		}
	}

	static populate_line_after(idx, action)
	{
		if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_id != '')) {
			if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id != '')) {
				document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + idx.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id + ')';
			}else{
				document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + idx.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description;
			}
		}else{

			if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty != 0)) {
				if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id != '')) {
					document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + idx.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_id + ')';
				}else{
					document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + idx.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description;
				}
			}else{
				document.getElementById('ioi_sales_pos_detail_table_detail_item_description_' + idx.toString()).innerHTML = '&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_description;
			}
		}

		let s = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty.toString();

		if ((s.indexOf('.') != -1) || (s.indexOf(',') != -1)) {

			document.getElementById('ioi_sales_pos_detail_table_detail_delivered_qty_'+idx.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].delivered_qty.toFixed(2)) + '&nbsp;';
		}else{
			document.getElementById('ioi_sales_pos_detail_table_detail_delivered_qty_'+idx.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].delivered_qty + '&nbsp;';
		}


		let unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].vat_rate / 100.00));

		document.getElementById('ioi_sales_pos_detail_table_detail_unit_price_' + idx.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(unit_price.toFixed(2)) + '&nbsp';

		if (parseFloat(unit_price) == 0) {

			if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].sales_pos_free_sales_allowed != 1) {
				if (document.getElementById('ioi_sales_pos_detail_table_detail')) {
					if (document.getElementById('ioi_sales_pos_detail_table_detail').rows.length > 0) {
						document.getElementById('ioi_sales_pos_detail_table_detail').rows[idx].cells[0].style.backgroundColor = 'red';
					}
				}
			}
		}


		let discount = 0;

		let base_discount = 0;

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount) {
			base_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].base_discount;
		}

		let extra_discount = 0;

		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount) {
			extra_discount = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].extra_discount;
		}


		if ((parseFloat(base_discount) != 0) && (parseFloat(extra_discount) != 0)) {

			discount = ((1 - (parseFloat(base_discount) / 100.00)) * (1 - (parseFloat(extra_discount) / 100.00))) * 100

		}else if (parseFloat(base_discount) != 0) {

			discount = parseFloat(base_discount);

		}else if (parseFloat(extra_discount) != 0) {

			discount = parseFloat(extra_discount);
		}


		document.getElementById('ioi_sales_pos_detail_table_detail_discount_' + idx.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(discount.toFixed(2)) + '&nbsp%&nbsp;';


		silicon_ioi.doctype.ioiSalesPOS.compute_document();

		if (!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].extra_discount) {
			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].extra_discount = 0;
		}

		document.getElementById('ioi_sales_pos_detail_table_detail_discount_'+idx.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].extra_discount.toFixed(2)) + '&nbsp;%&nbsp;';

		let total_line = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[parseInt(idx)].vat_rate / 100.00));

		document.getElementById('ioi_sales_pos_detail_table_detail_total_' + idx.toString()).innerHTML = silicon_ioi.doctype.ioiSalesPOS.format_number(total_line.toFixed(2)) + '&nbsp';


		if (silicon_ioi.doctype.ioiSalesPOS.state == '') {
			silicon_ioi.doctype.ioiSalesPOS.change_state('EDIT');
			silicon_ioi.doctype.ioiSalesPOS.state = 'EDIT';
		}

		silicon_ioi.doctype.ioiSalesPOS.current_line_id = idx;

		silicon_ioi.doctype.ioiSalesPOS.display_on_screen(action, idx);
	}

	static convert_in_stock_unit(idx, fieldname)
	{
		if (fieldname.toUpperCase() == 'DELIVERED_QTY') {

			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].stock_delivered_qty = (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].delivered_qty * 1.0) * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_sales_unit * 1.0) / (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_stock_unit * 1.0);

		}else if (fieldname.toUpperCase() == 'INVOICED_QTY') {

			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].stock_invoiced_qty = (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].invoiced_qty * 1.0) * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_sales_unit * 1.0) / (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_stock_unit * 1.0);

		}else if (fieldname.toUpperCase() == 'ORDERED_QTY') {

			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].stock_ordered_qty = (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].ordered_qty * 1.0) * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_sales_unit * 1.0) / (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_stock_unit * 1.0);

		}else if (fieldname.toUpperCase() == 'FREE_PART_QTY') {

			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].stock_free_part_qty = (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].free_part_qty * 1.0) * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_sales_unit * 1.0) / (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_stock_unit * 1.0);

		}else if (fieldname.toUpperCase() == 'RESERVED_QTY') {

			silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].stock_reserved_qty = (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].reserved_qty * 1.0) * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_sales_unit * 1.0) / (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].coef_stock_unit * 1.0);
		}
	}




	static change_state(action)
	{
		if ((action.toUpperCase() == 'INSERT') || (action.toUpperCase() == 'EDIT')) {

			document.getElementById('ioi_sales_pos_new_button').disabled = true;
			document.getElementById('ioi_sales_pos_edit_button').disabled = true;
			document.getElementById('ioi_sales_pos_delete_button').disabled = true;

			document.getElementById('ioi_sales_pos_save_button').disabled = false;

		}else{

			document.getElementById('ioi_sales_pos_new_button').disabled = false;
			document.getElementById('ioi_sales_pos_edit_button').disabled = false;
			document.getElementById('ioi_sales_pos_delete_button').disabled = false;

			document.getElementById('ioi_sales_pos_save_button').disabled = true;

		}
	}

	static payment_area_information_visibility(show){
		if (show){
			$("#ioi_sales_pos_pay_status_panel").css("width","100px");
			$("#ioi_sales_pos_pay_status").css("width","100px");

			$("#ioi_sales_pos_pay_already_paid_label").show();
			$("#ioi_sales_pos_pay_already_paid").show();
			$("#ioi_sales_pos_pay_still_to_pay_label").show();
			$("#ioi_sales_pos_pay_still_to_pay").show();

		}else{
			$("#ioi_sales_pos_pay_already_paid_label").hide();
			$("#ioi_sales_pos_pay_already_paid").hide();
			$("#ioi_sales_pos_pay_still_to_pay_label").hide();
			$("#ioi_sales_pos_pay_still_to_pay").hide();

			$("#ioi_sales_pos_pay_status_panel").css("width","280px");
			$("#ioi_sales_pos_pay_status").css("width","280px");
		}

	}

	static refresh_screen()
	{
		silicon_ioi.doctype.ioiSalesPOS.current_log = [];

		if ((silicon_ioi.doctype.ioiSalesPOS.doc) && (silicon_ioi.doctype.ioiSalesPOS.doc != '')) {

			if ((silicon_ioi.doctype.ioiSalesPOS.doc.sales_pos_memo_logs != null) && (silicon_ioi.doctype.ioiSalesPOS.doc.sales_pos_memo_logs != '')) {

				let s = silicon_ioi.doctype.ioiSalesPOS.doc.sales_pos_memo_logs;

				while (s.indexOf('\n') != -1) {

					let line = s.substring(0, s.indexOf('\n'));

					let error = line.substring(0, line.indexOf('|'));
					line = line.substring(line.indexOf('|')+1, line.length);

					let msg = line.substring(0, line.indexOf('|'));
					line = line.substring(line.indexOf('|')+1, line.length);

					let dt = line;

					silicon_ioi.doctype.ioiSalesPOS.current_log[silicon_ioi.doctype.ioiSalesPOS.current_log.length] = [error, msg, dt]

					s = s.substring(s.indexOf('\n')+1, s.length)
				}
			}
		}


		silicon_ioi.doctype.ioiSalesPOS.load_sales_journal_data();

		let s = __('Document');

		document.getElementById('ioi_sales_pos_document_id').innerText = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc.name) {
			document.getElementById('ioi_sales_pos_document_id').innerText = silicon_ioi.doctype.ioiSalesPOS.doc.name;
		}

		document.getElementById('ioi_sales_pos_document_date').innerText = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc.document_date) {
			document.getElementById('ioi_sales_pos_document_date').innerText = silicon_ioi.doctype.ioiSalesPOS.doc.document_date;
		}

		document.getElementById('ioi_sales_pos_document_status_panel').style.backgroundColor = '';

		document.getElementById('ioi_sales_pos_document_status').innerText = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) {

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.ioistatus.length; i++) {

				if (silicon_ioi.doctype.ioiSalesPOS.ioistatus[i][0] == silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus) {

					document.getElementById('ioi_sales_pos_document_status_panel').style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.ioistatus[i][2];
					document.getElementById('ioi_sales_pos_document_status').style.color = silicon_ioi.doctype.ioiSalesPOS.ioistatus[i][3];
					document.getElementById('ioi_sales_pos_document_status').innerText = silicon_ioi.doctype.ioiSalesPOS.ioistatus[i][1];
				}
			}

		}


		document.getElementById('ioi_sales_pos_customer_id').value = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id) {
			document.getElementById('ioi_sales_pos_customer_id').value = silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_id;
		}


		document.getElementById('ioi_sales_pos_customer_name').value = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_name) {
			document.getElementById('ioi_sales_pos_customer_name').value = silicon_ioi.doctype.ioiSalesPOS.doc.delivery_customer_name;
		}

		document.getElementById('ioi_sales_pos_base_discount').value = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc.base_discount) {
			document.getElementById('ioi_sales_pos_base_discount').value = silicon_ioi.doctype.ioiSalesPOS.doc.base_discount;
		}

		document.getElementById('ioi_sales_pos_extra_discount').value = '';

		if (silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount) {
			document.getElementById('ioi_sales_pos_extra_discount').value = silicon_ioi.doctype.ioiSalesPOS.doc.extra_discount;
		}


		document.getElementById('ioi_sales_pos_total_tvac').innerText = '';

		let exp = Math.pow(10, 2);

		let total_tvac = 0;

		if (silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac) {
			total_tvac = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.total_tvac);
		}

		document.getElementById('ioi_sales_pos_total_tvac').innerText = total_tvac.toFixed(2);

		if (silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) {
			document.getElementById('ioi_sales_pos_total_tvac').innerText += ' ' +silicon_ioi.doctype.ioiSalesPOS.doc.currency_id
		}

		total_tvac = Math.round(total_tvac * exp) / exp;

/*
		let already_paid = 0;


		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

				already_paid += parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_paid);
			}
		}

		already_paid = Math.round(already_paid * exp) / exp;
*/

		let already_paid = 0;

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.name) && (silicon_ioi.doctype.ioiSalesPOS.doc.name.trim() != '')) {

			frappe.call({  	method: path_sales_pos + '.ioi_sales_pos_get_already_paid',
							args: 	{	"name": silicon_ioi.doctype.ioiSalesPOS.doc.name
							},
							async: false,
							callback: function(r)	{

								already_paid = r.message;

							}
			});
		}


		already_paid = Math.round(already_paid * exp) / exp;


		let still_to_pay = parseFloat(total_tvac) - parseFloat(already_paid);


		still_to_pay = Math.round(still_to_pay * exp) / exp;

		document.getElementById('ioi_sales_pos_pay_already_paid').innerText = already_paid.toFixed(2);

		if (silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) {

			document.getElementById('ioi_sales_pos_pay_already_paid').innerText += ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id;
		}

		document.getElementById('ioi_sales_pos_pay_still_to_pay').innerText = still_to_pay.toFixed(2);

		if (silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) {

			document.getElementById('ioi_sales_pos_pay_still_to_pay').innerText += ' ' + silicon_ioi.doctype.ioiSalesPOS.doc.currency_id;
		}

		let color = '#60A367';



		if (Math.round(still_to_pay * exp) / exp > 0) {
			color = '#ED514C';
		}

		document.getElementById('ioi_sales_pos_pay_still_to_pay').style.color = color;



		let description = '';
		let bgcolor = '';

		if ((silicon_ioi.doctype.ioiSalesPOS.doc.doctype) && (silicon_ioi.doctype.ioiSalesPOS.doc.doctype.trim() != '')) {

			if (silicon_ioi.doctype.ioiCashDesk.verifify_pending_payment(silicon_ioi.doctype.ioiSalesPOS.doc.doctype, silicon_ioi.doctype.ioiSalesPOS.doc.name, false)){
				if (silicon_ioi.doctype.ioiCashDesk.verifify_pending_payment(silicon_ioi.doctype.ioiSalesPOS.doc.doctype, silicon_ioi.doctype.ioiSalesPOS.doc.name, true)){
					description = __("Pending transaction (Waiting)");
				}else{
					description = __("Pending transaction (To finalize)");
				}
				bgcolor = '#FF8469';
				ioiSalesPOS.payment_area_information_visibility(false);
			}else{
				ioiSalesPOS.payment_area_information_visibility(true);
				if ((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id) && ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == '') || (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == 0)))) {
					description = __("Unpaid");
					bgcolor = '#D9D3D2';
				}else if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == 1) {
					description = __("Partial");
					bgcolor = '#FFC869';
				}else{
					description = __("Total")
					bgcolor = '#7ECC6E';
				}
			}
		}else{
			ioiSalesPOS.payment_area_information_visibility(true);
			if ((!silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id) || ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id) && ((silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == '') || (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == 0)))) {
				description = __("Unpaid");
				bgcolor = '#D9D3D2';
			}else if (silicon_ioi.doctype.ioiSalesPOS.doc.cashdesk_payment_status_id == 1) {
				description = __("Partial");
				bgcolor = '#FFC869';
			}else{
				description = __("Total")
				bgcolor = '#7ECC6E';
			}
		}


		document.getElementById('ioi_sales_pos_pay_status_panel').style.backgroundColor = bgcolor;
		document.getElementById('ioi_sales_pos_pay_status').innerText = description;


		document.getElementById('ioi_sales_pos_customer_id').disabled = false;
		document.getElementById('ioi_sales_pos_base_discount').disabled = true;
		document.getElementById('ioi_sales_pos_extra_discount').disabled = true;

		if (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus != 0) {
			document.getElementById('ioi_sales_pos_customer_id').disabled = true;
		}


		if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

			for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString());

				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_item_description_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_delivered_qty_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_unit_price_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_discount_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_total_' + i.toString());

				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_item_edit_button_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_qt_edit_button_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_price_edit_button_' + i.toString());
				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_discount_edit_button_' + i.toString());

				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_row_' + i.toString());


			}

			silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail');
		}

		if (silicon_ioi.doctype.ioiSalesPOS.doc.ioistatus == 0) {

			if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

				if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length > 0) {

					document.getElementById('ioi_sales_pos_customer_id').disabled = true;

				}

			}
		}


		if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

			if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length > 0) {

				let user_id = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
				let badge_id = silicon_ioi.doctype.ioiSalesPOS.batchuser;


				let data_fields = [];

				let method = path_user_grid_profile + '.ioi_sales_pos_user_grid_profile_get_grid_fields';

				frappe.call({
					method: method,
					args: {
						"user_id": user_id,
						"badge_id": badge_id
					},
					async: false,
					callback:function(r)	{
						data_fields = r.message
					}
				});

				let html = '';

				silicon_ioi.doctype.ioiSalesPOS.profile_grid = 'DEF';

				if (data_fields.length != 0) {

					let grid_width = 30;

					for(var i=0; i < data_fields.length; i++) {

						silicon_ioi.doctype.ioiSalesPOS.profile_grid = data_fields[i].profile;
						document.getElementById('html_sales_pos_profile').value = silicon_ioi.doctype.ioiSalesPOS.profile_grid;

						grid_width += parseInt(data_fields[i].width);

						if ((data_fields[i].fieldname == 'delivered_qty') || (data_fields[i].fieldname == 'unit_price') || (data_fields[i].fieldname == 'discount') || (data_fields[i].fieldname == 'total')) {
							grid_width += 30;
						}

					}

					silicon_ioi.doctype.ioiSalesPOS.sales_pos_grid_width = parseInt(grid_width);




					html += '<table id="ioi_sales_pos_detail_table_detail" width=' + silicon_ioi.doctype.ioiSalesPOS.sales_pos_grid_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB">';

					for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

						html += '<tr id="ioi_sales_pos_detail_table_detail_row_' + i.toString() + '" style="height:30px">';


						html += '<td width=30px style="vertical-align: middle;" align="center">';

						html += '<input type="checkbox" id="ioi_sales_pos_detail_table_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 0px; left: 0px;" ';
						html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
						html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
						html += '>';

						html += '</td>';

						for(var j=0; j < data_fields.length; j++) {

							if (data_fields[j].fieldname == 'item_description') {

								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id != '')) {

									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
										html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')' + '</td>';
									}else{
										html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
									}
								}else{

									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty != 0)) {

										if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
											html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')' + '</td>';
										}else{
											html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
										}
									}else{
										html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
									}

								}
							}else if (data_fields[j].fieldname == 'delivered_qty') {

								let s = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toString();

								if ((s.indexOf('.') != -1) || (s.indexOf(',') != -1)) {
									html += '<td id="ioi_sales_pos_detail_table_detail_delivered_qty_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toFixed(2)) + '&nbsp;</td>';
								}else{
									html += '<td id="ioi_sales_pos_detail_table_detail_delivered_qty_' + i.toString() + '"  width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty + '&nbsp;</td>';
								}

								html += '<td width=30px style="vertical-align: middle;" align="center">';
								html += '	<img id="ioi_sales_pos_qt_edit_button_' + i.toString() + '" title="' + __("Edit qty") + '" src="/assets/silicon_ioi/images/buttons/edit_qty.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
								html += '</td>';

							}else if (data_fields[j].fieldname == 'unit_price') {

								let unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00));

								html += '<td id="ioi_sales_pos_detail_table_detail_unit_price_' + i.toString() + '" width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(unit_price.toFixed(2)) + '&nbsp;</td>';

								html += '<td width=30px style="vertical-align: middle;">';
								html += '	<img id="ioi_sales_pos_price_edit_button_' + i.toString() + '" title="' + __("Edit price") + '" src="/assets/silicon_ioi/images/buttons/edit_price.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
								html += '</td>';

							}else if (data_fields[j].fieldname == 'discount') {

								let discount = 0;

								if ((parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) != 0) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) != 0)) {

									discount = ((1 - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) / 100.00)) * (1 - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) / 100.00))) * 100

								}else if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) != 0) {

									discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount);

								}else if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) != 0) {

									discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount);
								}

								html += '<td id="ioi_sales_pos_detail_table_detail_discount_' + i.toString() + '" width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(discount.toFixed(2)) + '&nbsp;%&nbsp;</td>';

								html += '<td width=30px style="vertical-align: middle;">';
								html += '	<img id="ioi_sales_pos_discount_edit_button_' + i.toString() + '" title="' + __("Edit discount") + '" src="/assets/silicon_ioi/images/buttons/edit_discount.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"> </img>';
								html += '</td>';

							}else if (data_fields[j].fieldname == 'total') {

								let total_line = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00))

								html += '<td id="ioi_sales_pos_detail_table_detail_total_' + i.toString() + '" width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(total_line.toFixed(2)) + '&nbsp;</td>';

								html += '<td width=30px style="vertical-align: middle;" align="center">';
								html += '	<img id="ioi_sales_pos_item_edit_button_' + i.toString() + '" title="' + __("Item & Code ref") + '" src="/assets/silicon_ioi/images/buttons/edit_qty.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
								html += '</td>';
							}else{

								if ((data_fields[j].fieldtype.toUpperCase() == 'DATA') || (data_fields[j].fieldtype.toUpperCase() == 'ATTACH') || (data_fields[j].fieldtype.toUpperCase() == 'ATTACH IMAGE') ||
									(data_fields[j].fieldtype.toUpperCase() == 'AUTOCOMPLETE') || (data_fields[j].fieldtype.toUpperCase() == 'LINK') || (data_fields[j].fieldtype.toUpperCase() == 'DYNAMIC LINK') ||
									(data_fields[j].fieldtype.toUpperCase() == 'READ ONLY')) {

									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
									}else{
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
									}
								}else if ((data_fields[j].fieldtype.toUpperCase() == 'FLOAT') || (data_fields[j].fieldtype.toUpperCase() == 'PERCENT') || (data_fields[j].fieldtype.toUpperCase() == 'CURRENCY')) {
									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
									}else{

										if (data_fields[j].fieldtype.toUpperCase() == 'PERCENT') {
											html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname].toFixed(2)) + '&nbsp;%&nbsp;</td>';
										}else{
											html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname].toFixed(2)) + '&nbsp;</td>';
										}
									}
								}else if (data_fields[j].fieldtype.toUpperCase() == 'INT') {
									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
									}else{
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '&nbsp;</td>';
									}
								}else if (data_fields[j].fieldtype.toUpperCase() == 'DURATION') {
									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
									}else{
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '&nbsp;</td>';
									}
								}else if (data_fields[j].fieldtype.toUpperCase() == 'DATE') {
									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
									}else{
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
									}
								}else if (data_fields[j].fieldtype.toUpperCase() == 'DATETIME') {
									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
									}else{
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname].substring(0, 19) + '</td>';
									}
								}else if (data_fields[j].fieldtype.toUpperCase() == 'TIME') {
									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
									}else{
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname].substring(0, 8) + '</td>';
									}
								}else if (data_fields[j].fieldtype.toUpperCase() == 'SELECT') {
									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
									}else{

										if (data_fields[j].options != '') {
											html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
										}else{

											if (data_fields[j].fieldname == 'alert_margin_mode') {

												let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

												if ((v == 0) || (v == '0')) {
													v = __("Cost margin");
												}else{
													v = __("Sales margin");
												}

												html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

											}else if (data_fields[j].fieldname == 'invoicing_mode') {

												let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

												if ((v == 0) || (v == '0')) {
													v = __("Default");
												}else if ((v == 1) || (v == '1')) {
													v = __("Up to ordered");
												}else if ((v == 2) || (v == '2')) {
													v = __("Fix ordered");
												}else if ((v == 3) || (v == '3')) {
													v = __("Open order");
												}

												html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

											}else if (data_fields[j].fieldname == 'is_withme') {

												let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

												if ((v == 0) || (v == '0')) {
													v = __("Detail");
												}else{
													v = __("Document");
												}

												html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

											}else if (data_fields[j].fieldname == 'margin_alert') {

												let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

												if ((v == 0) || (v == '0')) {
													v = __("Ok");
												}else if ((v == -1) || (v == '-1')) {
													v = __("Margin alert");
												}else{
													v = __("Margin error");
												}

												html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

											}else if (data_fields[j].fieldname == 'stored_qty_mode') {

												let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

												if ((v == 0) || (v == '0')) {
													v = __("Good");
												}else if ((v == 1) || (v == '1')) {
													v = __("To check");
												}else if ((v == 2) || (v == '2')) {
													v = __("Bad");
												}

												html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

											}else if (data_fields[j].fieldname == 'vat_service') {

												let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

												if (v == '')  {
													v = __("Empty");
												}else if (v == 'G') {
													v = __("Goods");
												}else if (v == 'S') {
													v = __("Service");
												}

												html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

											}else if (data_fields[j].fieldname == 'vat_type') {

												let v = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname];

												if (v == 'NAT')  {
													v = __("National");
												}else if (v == 'NCO') {
													v = __("National cocontractant");
												}else if (v == 'EEC') {
													v = __("Europe");
												}else if (v == 'INT') {
													v = __("International");
												}else if (v == 'XXX') {
													v = __("Others");
												}

												html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + v + '</td>';

											}else{
												html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
											}



										}
									}
								}else if (data_fields[j].fieldtype.toUpperCase() == 'CHECK') {

									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
									}else{
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;" align="center">';

										html += '<input type="checkbox" style="postion:absolute; top: 0px; left: 0px;" disabled ';
										if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 1) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == true) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'true')) {
											html += ' checked '
										}
										html += '>';

										html += '</td>';
									}
								}else if (data_fields[j].fieldtype.toUpperCase() == 'COLOR') {
									if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == 'null') || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] == '')) {
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;</td>';
									}else{
										html += '<td width=' + data_fields[j].width.toString() + 'px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i][data_fields[j].fieldname] + '</td>';
									}
								}

							}

						}

						html += '</tr>';

					}

					html += '</table>';

				}else{

					html += '<table id="ioi_sales_pos_detail_table_detail" width=' + silicon_ioi.doctype.ioiSalesPOS.sales_pos_grid_width.toString() + 'px border=1 style="border: 1px solid #E8EAEB">';

					for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

						html += '<tr id="ioi_sales_pos_detail_table_detail_row_' + i.toString() + '" style="height:30px">';


						html += '<td width=30px style="vertical-align: middle;" align="center">';

						html += '<input type="checkbox" id="ioi_sales_pos_detail_table_detail_checked_id_' + i.toString() + '" style="postion:absolute; top: 0px; left: 0px;" ';
						html += '       onkeydown="   if (event.keyCode == 13) { return false; } " ';
						html += '       onkeyup="   if (event.keyCode == 13) { return false; } " ';
						html += '>';

						html += '</td>';

						if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id != '')) {

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
								html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')' + '</td>';
							}else{
								html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
							}
						}else{

							if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty != 0)) {

								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id != '')) {
									html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '&nbsp;(' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_id + ')' + '</td>';
								}else{
									html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
								}
							}else{
								html += '<td id="ioi_sales_pos_detail_table_detail_item_description_' + i.toString() + '"  width=267px style="vertical-align: middle;">&nbsp;' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_description + '</td>';
							}

						}

						let s = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toString();

						if ((s.indexOf('.') != -1) || (s.indexOf(',') != -1)) {
							html += '<td id="ioi_sales_pos_detail_table_detail_delivered_qty_' + i.toString() + '"  width=60px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty.toFixed(2)) + '&nbsp;</td>';
						}else{
							html += '<td id="ioi_sales_pos_detail_table_detail_delivered_qty_' + i.toString() + '"  width=60px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].delivered_qty + '&nbsp;</td>';
						}

						html += '<td width=30px style="vertical-align: middle;" align="center">';
						html += '	<img id="ioi_sales_pos_qt_edit_button_' + i.toString() + '" title="' + __("Edit qty") + '" src="/assets/silicon_ioi/images/buttons/edit_qty.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
						html += '</td>';


						let unit_price = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00));

						html += '<td id="ioi_sales_pos_detail_table_detail_unit_price_' + i.toString() + '" width=80px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(unit_price.toFixed(2)) + '&nbsp;</td>';

						html += '<td width=30px style="vertical-align: middle;">';
						html += '	<img id="ioi_sales_pos_price_edit_button_' + i.toString() + '" title="' + __("Edit price") + '" src="/assets/silicon_ioi/images/buttons/edit_price.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
						html += '</td>';

						let discount = 0;

						if ((parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) != 0) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) != 0)) {

							discount = ((1 - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) / 100.00)) * (1 - (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) / 100.00))) * 100

						}else if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount) != 0) {

							discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].base_discount);

						}else if (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount) != 0) {

							discount = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount);
						}

						html += '<td id="ioi_sales_pos_detail_table_detail_discount_' + i.toString() + '" width=70px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(discount.toFixed(2)) + '&nbsp;%&nbsp;</td>';

						html += '<td width=30px style="vertical-align: middle;">';
						html += '	<img id="ioi_sales_pos_discount_edit_button_' + i.toString() + '" title="' + __("Edit discount") + '" src="/assets/silicon_ioi/images/buttons/edit_discount.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"> </img>';
						html += '</td>';


						let total_line = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency + (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency * (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].vat_rate / 100.00))

						html += '<td id="ioi_sales_pos_detail_table_detail_total_' + i.toString() + '" width=90px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiSalesPOS.format_number(total_line.toFixed(2)) + '&nbsp;</td>';

						html += '<td width=30px style="vertical-align: middle;" align="center">';
						html += '	<img id="ioi_sales_pos_item_edit_button_' + i.toString() + '" title="' + __("Item & Code ref") + '" src="/assets/silicon_ioi/images/buttons/edit_qty.svg" width="24px" height="24px" style="position:relative;top:0px; left:0px;" onmousemove="this.style.cursor = \'pointer\';" onmouseleave="this.style.cursor = \'none\';"></img>';
						html += '</td>';

						html += '</tr>';





					}


					html += '</table>';
				}



				document.getElementById('ioi_sales_pos_detail_area').innerHTML = html;


				silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {


					let fct_scroll = function(event) {
						document.getElementById('ioi_sales_pos_detail_area_header').scrollLeft = this.scrollLeft;
					}


					document.getElementById('ioi_sales_pos_detail_area').onscroll = fct_scroll;

					if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

						// Get Item_mode

						let item_mode = [];

						if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail) {

							for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

								if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id != null) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id != '')) {

									let item_found = false;

									for (var j = 0; j < item_mode.length; j++) {

										if (item_mode[j][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) {
											item_found = true;
											break;
										}
									}

									if (!item_found) {
										item_mode[item_mode.length] = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id;
									}

								}

							}


							let method = path_sales_pos + '.ioi_sales_pos_get_item_mode';

							frappe.call({  	method: method,
											args: {	"item_mode": item_mode
											},
											async: false,
											callback:function(r)	{

												item_mode = [];

												if (r.message.length > 0) {

													for (var i = 0; i < r.message.length; i++) {
														item_mode[item_mode.length] = [r.message[i].name, r.message[i].mode];
													}
												}
											}
							});


						}




						let fct_click = function() {

							for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

								if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

									if (silicon_ioi.doctype.ioiSalesPOS.is_dark_mode == 0) {

										if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price) || ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price == 0))) {
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#FFFFFF';


											if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_pos_free_sales_allowed != 1) {
												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = 'red';
											}else{
												if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty) != 0)) {
													document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#FFFFFF';

													if (item_mode.length > 0) {

														let item_found = false;
														for (var j = 0; j < item_mode.length; j++) {

															if (item_mode[j][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) {
																if (item_mode[j][1] != 2) {
																	item_found = true;
																	break;
																}
															}

														}
														if (item_found) {
															document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = '#FC793D';
														}
													}
												}else{
													document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#FFFFFF';
												}
											}

										}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty) != 0)) {
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#FFFFFF';

											if (item_mode.length > 0) {

												let item_found = false;
												for (var j = 0; j < item_mode.length; j++) {

													if (item_mode[j][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) {
														if (item_mode[j][1] != 2) {
															item_found = true;
															break;
														}
													}

												}
												if (item_found) {
													document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = '#FC793D';
												}
											}


										}else{
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#FFFFFF';
										}
									}else{

										if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price == 0)) {
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#1C2126';
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#FFFFFF';

											if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_pos_free_sales_allowed != 1) {
												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = 'red';
											}else{
												if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty) != 0)) {
													document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#1C2126';
													document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#FFFFFF';

													if (item_mode.length > 0) {

														let item_found = false;
														for (var j = 0; j < item_mode.length; j++) {

															if (item_mode[j][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) {
																if (item_mode[j][1] != 2) {
																	item_found = true;
																	break;
																}
															}

														}
														if (item_found) {
															document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = '#FC793D';
														}
													}


												}else{
													document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#1C2126';
													document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#FFFFFF';
												}
											}

										}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty) != 0)) {
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#1C2126';
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#FFFFFF';

											if (item_mode.length > 0) {

												let item_found = false;
												for (var j = 0; j < item_mode.length; j++) {

													if (item_mode[j][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) {
														if (item_mode[j][1] != 2) {
															item_found = true;
															break;
														}
													}

												}
												if (item_found) {
													document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = '#FC793D';
												}
											}

										}else{
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = '#1C2126';
											document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#FFFFFF';
										}

									}

									document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = false;
								}
							}

							this.checked = true;

							for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

								if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

									if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {

										if (silicon_ioi.doctype.ioiSalesPOS.is_dark_mode == 0) {

											if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price == 0)) {
												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;

												if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_pos_free_sales_allowed != 1) {
													document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = 'red';
												}else{
													if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty) != 0)) {
														document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;

														if (item_mode.length > 0) {

															let item_found = false;
															for (var j = 0; j < item_mode.length; j++) {

																if (item_mode[j][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) {
																	if (item_mode[j][1] != 2) {
																		item_found = true;
																		break;
																	}
																}

															}
															if (item_found) {
																document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = '#FC793D';
															}
														}

													}else{
														document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;
													}

												}

											}else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty) != 0)) {
												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;

													if (item_mode.length > 0) {

														let item_found = false;
														for (var j = 0; j < item_mode.length; j++) {

															if (item_mode[j][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) {
																if (item_mode[j][1] != 2) {
																	item_found = true;
																	break;
																}
															}

														}
														if (item_found) {
															document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = '#FC793D';
														}
													}

											}else{
												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;

											}

										}else{


											if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].unit_price == 0)) {
												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;
												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#000000';
												if (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_pos_free_sales_allowed != 1) {
													document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = 'red';
												}else{
													if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty) != 0)) {

														document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;
														document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#000000';
														if (item_mode.length > 0) {

															let item_found = false;
															for (var j = 0; j < item_mode.length; j++) {

																if (item_mode[j][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) {
																	if (item_mode[j][1] != 2) {
																		item_found = true;
																		break;
																	}
																}

															}
															if (item_found) {
																document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = '#FC793D';
															}
														}

													}else{

														document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;
														document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#000000';
													}
												}


											} else if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty != null) && (parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].overstock_qty) != 0)) {

												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;
												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#000000';

												if (item_mode.length > 0) {

													let item_found = false;

													for (var j = 0; j < item_mode.length; j++) {

														if (item_mode[j][0] == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_id) {
															if (item_mode[j][1] != 2) {
																item_found = true;
																break;
															}
														}

													}
													if (item_found) {
														document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].cells[0].style.backgroundColor = '#FC793D';
													}
												}

											}else{

												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.backgroundColor = silicon_ioi.doctype.ioiSalesPOS.selected_line_color;
												document.getElementById('ioi_sales_pos_detail_table_detail').rows[i].style.color = '#000000';
											}
										}

									}
								}
							}

							let s = this.id;

							while (s.indexOf('_') != -1) {
								s = s.substring(s.indexOf('_')+1, s.length)
							}

							if (s.trim() != '') {

								if (parseInt(s) == document.getElementById('ioi_sales_pos_detail_table_detail').rows.length-1) {
									document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + s).focus();
								}
							}

						}

						let fct_edit_item = function() {

							silicon_ioi.doctype.ioiSalesPOS.edit_item_line(this);
						}


						let fct_edit_qty = function() {

							silicon_ioi.doctype.ioiSalesPOS.edit_qty_line(this);
						}


						let fct_edit_price = function() {

							silicon_ioi.doctype.ioiSalesPOS.edit_price_line(this);
						}


						let fct_edit_discount = function() {

							silicon_ioi.doctype.ioiSalesPOS.edit_discount_line(this);
						}

						let fct_row_click = function() {

							let s = this.id;

							while (s.indexOf('_') != -1) {
								s = s.substring(s.indexOf('_')+1, s.length);
							}

							s = s.trim(s);

							document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + s).click();

						}

						let fct_check_keydown = function(event)
						{
							if (event.keyCode == 40) {

								let s = this.id;

								while (s.indexOf('_') != -1) {
									s = s.substring(s.indexOf('_')+1, s.length);
								}

								s = s.trim(s);

								if (parseInt(s) == document.getElementById('ioi_sales_pos_detail_table_detail').rows.length-1) {
									document.getElementById('ioi_sales_pos_detail_new_button').click();
								}
							}
						}



						for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

							if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {
								document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).onclick = fct_click;
								document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).onkeydown = fct_check_keydown;

								if (silicon_ioi.doctype.ioiSalesPOS.current_line_id == -1) {

									if (i == 0) {
										document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = true;
									}
								}else{
									if (i == silicon_ioi.doctype.ioiSalesPOS.current_line_id ) {
										document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = true;
									}
								}

							}

							if (document.getElementById('ioi_sales_pos_item_edit_button_' + i.toString())) {
								document.getElementById('ioi_sales_pos_item_edit_button_' + i.toString()).onclick = fct_edit_item;
							}


							if (document.getElementById('ioi_sales_pos_qt_edit_button_' + i.toString())) {
								document.getElementById('ioi_sales_pos_qt_edit_button_' + i.toString()).onclick = fct_edit_qty;
							}

							if (document.getElementById('ioi_sales_pos_price_edit_button_' + i.toString())) {
								document.getElementById('ioi_sales_pos_price_edit_button_' + i.toString()).onclick = fct_edit_price;
							}


							if (document.getElementById('ioi_sales_pos_detail_table_detail_discount_' + i.toString())) {

								const cell = document.getElementById('ioi_sales_pos_detail_table_detail_discount_' + i.toString());

								if (cell.onmouseenter == null) {
									cell.onmouseenter = (e) => {
										let timeout = setTimeout(() => {
											let i = this.id;

											while (i.indexOf('_') != -1) {
												i = i.substring(i.indexOf('_')+1, i.length);
											}

											silicon_ioi.doctype.ioiSalesPOS.display_next_item_budget_discounts(parseInt(i), e);
										}, 600);

										cell.onmouseleave = () => {
											clearTimeout(timeout)};
									};
								}
							}


							if (document.getElementById('ioi_sales_pos_discount_edit_button_' + i.toString())) {
								document.getElementById('ioi_sales_pos_discount_edit_button_' + i.toString()).onclick = fct_edit_discount;
							}


							document.getElementById('ioi_sales_pos_detail_table_detail_row_' + i.toString()).onclick = fct_row_click;


						}

						if (silicon_ioi.doctype.ioiSalesPOS.current_line_id == -1) {
							document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_0').click();
						}else{
							document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + silicon_ioi.doctype.ioiSalesPOS.current_line_id.toString()).click();
						}
					}

				});
			}

		}

		silicon_ioi.doctype.ioiSalesPOS.compute_document();

		if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

			for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

				if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {
					document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = false;
				}
			}


			for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

				if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

					if (silicon_ioi.doctype.ioiSalesPOS.current_line_id == i) {
						document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = true;
						break;
					}
				}
			}
		}

	}

	static display_next_item_budget_discounts(idx, e)
	{

		let current_item_budget_id = silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[idx].item_budget_id;

		if ((current_item_budget_id) && (current_item_budget_id.trim() != '')) {
			silicon_ioi.doctype.ioiSalesPOS.do_display_next_item_budget_discounts(current_item_budget_id, e);
		}else{

			let div = $("#grid_info_popup");

			if (div) {
				div.remove();
			}
		}
	}


	static do_display_next_item_budget_discounts(current_item_budget_id, e)
	{
		if ((!current_item_budget_id) || ((current_item_budget_id) && (current_item_budget_id.trim() == ''))) {
			return false;
		}


		if (silicon_ioi.doctype.ioiSalesPOS.use_triggered_budget_discount == 1) {

			let item_budget = []

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.doc.line_detail.length; i++) {

				if (silicon_ioi.doctype.ioiSalesPOS.manual_sales_condition_id != silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].sales_condition_id) {

					if ((silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id) && (silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id.trim() != '')) {

						let init_extra_discount = false;

						if ((!silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].order_master_link) || ((silicon_ioi.doctype.ioiSalesPOS.line_detail[i].order_master_link) && (csilicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].order_master_link.trim() == ''))) {
							init_extra_discount = true;
						}


						if (init_extra_discount) {

							let has_detail_discount = 0

							let method = 'silicon_ioi.ioi_configuration.doctype.ioi_item_budget.ioi_item_budget.ioi_item_budget_has_detail_discounts';

							frappe.call({  	method: method,
											args: {"item_budget_id": silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id, "currency_id": silicon_ioi.doctype.ioiSalesPOS.doc.currency_id},
											async: false,
											callback:function(r)	{
												has_detail_discount = r.message;
											}
							});

							if (has_detail_discount == 1) {

								let item_budget_found = false;

								for (var j = 0; j < item_budget.length; j++) {

									if ((item_budget[j].name == silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id) && (item_budget[j].currency_id == silicon_ioi.doctype.ioiSalesPOS.doc.currency_id) ) {

										let value = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency) * (100.00 / (100 - parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount)));

										item_budget[j].value = parseFloat(item_budget[j].value) + parseFloat(value);
										item_budget_found = true;
										break;
									}
								}

								if (!item_budget_found) {
									let value = parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].value_line_doc_currency) * (100.00 / (100 - parseFloat(silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].extra_discount)));
									item_budget[item_budget.length] = {"name": silicon_ioi.doctype.ioiSalesPOS.doc.line_detail[i].item_budget_id, "currency_id": silicon_ioi.doctype.ioiSalesPOS.doc.currency_id, "value": parseFloat(value)};
								}
							}
						}
					}
				}
			}



			if (item_budget.length > 0) {

				let method = 'silicon_ioi.ioi_configuration.doctype.ioi_item_budget.ioi_item_budget.ioi_item_budget_get_discounts_tooltip';

				let data = [];

				frappe.call({  	method: method,
								args: {"current_item_budget_id": current_item_budget_id, "item_budgets": item_budget},
								async: false,
								callback:function(r)	{
									data = r.message;
								}
				});


				if (data.length == 0) {
					return false;
				}

				if (document.getElementById('item_budget_discounts_table')) {

					for (var i = 0; i < document.getElementById('item_budget_discounts_table').rows.length; i++) {

						if (document.getElementById('item_budget_discounts_item_budget_id_' + i.toString())) {
							document.getElementById('item_budget_discounts_item_budget_id_' + i.toString()).remove();
						}

						if (document.getElementById('item_budget_discounts_currency_id_' + i.toString())) {
							document.getElementById('item_budget_discounts_currency_id_' + i.toString()).remove();
						}

						if (document.getElementById('item_budget_discounts_ordered_value_' + i.toString())) {
							document.getElementById('item_budget_discounts_ordered_value_' + i.toString()).remove();
						}

						if (document.getElementById('item_budget_discounts_next_discount_at_' + i.toString())) {
							document.getElementById('item_budget_discounts_next_discount_at_' + i.toString()).remove();
						}


						if (document.getElementById('item_budget_next_discounts_button_' + i.toString())) {
							document.getElementById('item_budget_next_discounts_button_' + i.toString()).remove();
						}


					}
					document.getElementById('item_budget_discounts_table').remove()

				}


				let html = '';

				html += '<table border="0" width=1020px>';
				html += '<tr height=30px>';
				html += '<td width=200px><p style="font-size: 12px;height:30px"><u>' + __("Discount category") + '</u></p></td>';
				html += '<td width=70px align="center"><p style="font-size: 12px;height:30px"><u>' + __("Currency") + '</u></p></td>';
				html += '<td width=80px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Ordered value") + '</u></p></td>';
				html += '<td width=80px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Discount at") + '</u></p></td>';
				html += '<td width=75px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Granted discount") + '</u></p></td>';
				html += '<td width=70px align="right"><p style="font-size: 12px;height:30px"><u>' + __("You win") + '</u></p></td>';
				html += '<td width=80px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Net value") + '</u></p></td>';
				html += '<td width=80px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Next discount at") + '</u></p></td>';
				html += '<td width=80px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Discount gap") + '</u></p></td>';
				html += '<td width=75px align="right"><p style="font-size: 12px;height:30px"><u>' + __("Next discount") + '</u></p></td>';
				html += '<td width=80px align="right"><p style="font-size: 12px;height:30px"><u>' + __("You can min win") + '</u></p></td>';
				html += '<td width=50px align="center"><p style="font-size: 12px;height:30px"><u>' + __("Next") + '</u></p></td>';
				html += '</tr>';
				html += "</table>"

				html += '<table id="item_budget_discounts_table" border="0" width=1020px>';
				for (var i = 0; i < data.length; i++) {

					html += '<input type="hidden" id="item_budget_discounts_item_budget_id_' + i.toString() + '" value="' + data[i].item_budget_id + '">';
					html += '<input type="hidden" id="item_budget_discounts_currency_id_' + i.toString() + '" value="' + data[i].currency_id  + '">';
					html += '<input type="hidden" id="item_budget_discounts_ordered_value_' + i.toString() + '" value="' + data[i].ordered_value  + '">';
					html += '<input type="hidden" id="item_budget_discounts_next_discount_at_' + i.toString() + '" value="' + data[i].next_discount_at  + '">';

					html += '<tr height=10px>';
					html += '<td width=200px><p style="font-size: 12px;height:10px;"><label style="height:15px">' + data[i].item_budget_id + '</label></p></td>';
					html += '<td width=70px align="center"><p style="font-size: 12px;height:10px;"><label style="height:15px">' + data[i].currency_id + '</label></p></td>';
					html += '<td width=80px align="right"><p style="font-size: 12px;height:10px;"><label style="height:15px">' + data[i].ordered_value.toFixed(2) + '</label></p></td>';


					html += '<td width=80px align="right"><p style="font-size: 12px;height:10px"><label style="height:15px">' + data[i].discount_at.toFixed(2) + '</label></p></td>';

					if (data[i].is_discount_granted == 1) {
						html += '<td width=75px align="right"><p style="font-size: 12px;height:10px"><label style="background:#00ff00;color:#000000;height:15px">' + data[i].granted_discount + ' %</label></p></td>';
					}else{
						html += '<td width=75px align="right"><p style="font-size: 12px;height:10px"><label style="background:#00ffff;color:#000000;height:15px">' + data[i].granted_discount + ' %</label></p></td>';
					}

					if (data[i].is_discount_granted == 1) {
						html += '<td width=70px align="right"><p style="font-size: 12px;height:10px"><label style="background:#00ff00;color:#000000;height:15px">' + data[i].gain.toFixed(2) + '</label></p></td>';
					}else{
						html += '<td width=70px align="right"><p style="font-size: 12px;height:10px"><label style="background:#00ffff;color:#000000;height:15px">' + data[i].gain.toFixed(2) + '</label></p></td>';
					}

					if (data[i].net_value != 0) {
						if (data[i].is_discount_granted == 1) {
							html += '<td width=80px align="right"><p style="font-size: 12px;height:10px"><label style="background:#00ff00;color:#000000;height:15px">' + data[i].net_value.toFixed(2) + '</label></p></td>';
						}else{
							html += '<td width=80px align="right"><p style="font-size: 12px;height:10px"><label style="background:#00ffff;color:#000000;height:15px">' + data[i].net_value.toFixed(2) + '</label></p></td>';
						}
					}else{
						html += '<td width=80px align="right">&nbsp;</td>';
					}

					if (data[i].next_discount_at != 0) {
						html += '<td width=80px align="right"><p style="font-size: 12px;height:10px"><label style="background:#ffff00;color:#000000;height:15px">' + data[i].next_discount_at.toFixed(2) + '</label></p></td>';
					}else{
						html += '<td width=80px align="right">&nbsp;</td>';
					}

					if (data[i].discount_gap != 0) {
						html += '<td width=80px align="right"><p style="font-size: 12px;height:10px"><label style="background:#ffff00;color:#000000;height:15px">' + data[i].discount_gap.toFixed(2) + '</label></p></td>';
					}else{
						html += '<td width=80px align="right">&nbsp;</td>';
					}

					if (data[i].next_discount != 0) {
						html += '<td width=75px align="right"><p style="font-size: 12px;height:10px"><label style="background:#ffff00;color:#000000;height:15px">' + data[i].next_discount + ' %</label></p></td>';
					}else{
						html += '<td width=75px align="right">&nbsp;</td>';
					}
					if (data[i].next_min_gain) {
						html += '<td width=80px align="right"><p style="font-size: 12px;height:10px"><label style="background:#ffff00;color:#000000;height:15px">' + data[i].next_min_gain.toFixed(2) + '</label></p></td>';
					}else{
						html += '<td width=80px align="right">&nbsp;</td>';
					}

					if (data[i].has_next_again == 1) {
						html += '<td width=50px align="center"><button id="item_budget_next_discounts_button_' + i.toString() + '" style="width:30px; height:20px; border: none;">...</button></td>';
					}else{
						html += '<td width=50px align="center">&nbsp;</td>';
					}



					html += '</tr>';

				}

				html += "</table>"

				silicon_ioi.ioiCommon.show_grid_info_popup(html, 120000, e);

				silicon_ioi.doctype.ioiSalesPOS.sleep_static(250).then(() => {

					if (document.getElementById('item_budget_discounts_table')) {

						for (var i = 0; i < document.getElementById('item_budget_discounts_table').rows.length; i++) {

							if (document.getElementById('item_budget_next_discounts_button_' + i.toString())) {

								let fct_click = function() {

									let id = this.id;

									while (id.indexOf('_') != -1) {
										id = id.substring(id.indexOf('_')+1, id.length);
									}

									silicon_ioi.doctype.ioiSalesPOS.show_next_item_budget_discount(id);
								};

								document.getElementById('item_budget_next_discounts_button_' + i.toString()).onclick = fct_click;
							}
						}


					}

				});

			}
		}

	}

	static show_next_item_budget_discount(id)
	{

		let item_budget_id = document.getElementById('item_budget_discounts_item_budget_id_' + id.toString()).value;
		let currency_id = document.getElementById('item_budget_discounts_currency_id_' + id.toString()).value;
		let ordered_value = parseFloat(document.getElementById('item_budget_discounts_ordered_value_' + id.toString()).value);
		let next_discount_at = parseFloat(document.getElementById('item_budget_discounts_next_discount_at_' + id.toString()).value);

		let method = 'silicon_ioi.ioi_configuration.doctype.ioi_item_budget.ioi_item_budget.ioi_item_budget_get_next_discounts';

		let data = [];

		frappe.call({  	method: method,
						args: {"item_budget_id": item_budget_id, "currency_id": currency_id, "ordered_value": ordered_value, "next_discount_at": next_discount_at},
						async: false,
						callback:function(r)	{
							data = r.message;
						}
		});


		if (data.length == 0) {
			return false;
		}

		let title = __("Item budget") + ' ' + item_budget_id + ' : ' + __("next discounts")

		var ItemBudgetNextDiscounts = new frappe.ui.Dialog({
			title: title,
			static: true,
			fields: [	{'fieldname': 'html_item_budget_next_discounts_form', 'fieldtype': 'HTML'}
					],
			primary_action_label: __("Close"),
			primary_action: function(){
				ItemBudgetNextDiscounts.hide();
			}
		});

		let html = ""

		html += '<div style="overflow: auto; overflow-x: auto; height:180px; width: 100%;">';

		html += '<table border="0" width=480px>';
		html += '<tr height=30px>';
		html += '<td width=120px align="right"><u>' + __("Next discount at") + '</u></td>';
		html += '<td width=120px align="right"><u>' + __("Discount gap") + '</u></td>';
		html += '<td width=120px align="right"><u>' + __("Next discount") + '</u></td>';
		html += '<td width=120px align="right"><u>' + __("You can min win") + '</u></td>';
		html += '</tr>';
		html += "</table>"

		html += '<table border="0" width=480px>';


		for (var i = 0; i < data.length; i++) {
			html += '<tr height=30px>';

			if (data[i].trigger_value != 0) {
				html += '<td width=120px align="right">' + data[i].trigger_value.toFixed(2) + ' ' + data[i].currency_id + '</td>';
			}else{
				html += '<td width=120px align="right">&nbsp;</td>';
			}

			if (data[i].discount_gap != 0) {
				html += '<td width=120px align="right">' + data[i].discount_gap.toFixed(2) + ' ' + data[i].currency_id + '</td>';
			}else{
				html += '<td width=120px align="right">&nbsp;</td>';
			}

			if (data[i].next_discount != 0) {
				html += '<td width=120px align="right">' + data[i].extra_discount + ' %</td>';
			}else{
				html += '<td width=120px align="right">&nbsp;</td>';
			}
			if (data[i].next_min_gain) {
				html += '<td width=120px align="right">' + data[i].next_min_gain.toFixed(2) + ' ' + data[i].currency_id + '</td>';
			}else{
				html += '<td width=120px align="right">&nbsp;</td>';
			}

			html += '</tr>';
		}
		html += '</table>';

		html += '</div>';

		ItemBudgetNextDiscounts.fields_dict.html_item_budget_next_discounts_form.$wrapper.html(html);
		ItemBudgetNextDiscounts.$wrapper.find('.modal-dialog').css("max-width", "540px").css("width", "540px");
		ItemBudgetNextDiscounts.show();

	}

	static clear_screen()
	{


		document.getElementById('ioi_sales_pos_document_id').innerText = '';
		document.getElementById('ioi_sales_pos_document_date').innerText = '';
		document.getElementById('ioi_sales_pos_document_status').innerText = '';
		document.getElementById('ioi_sales_pos_document_status_panel').style.backgroundColor = '';


		document.getElementById('ioi_sales_pos_customer_id').value = '';
		document.getElementById('ioi_sales_pos_customer_name').value = '';

		document.getElementById('ioi_sales_pos_base_discount').value = '';
		document.getElementById('ioi_sales_pos_extra_discount').value = '';

		document.getElementById('ioi_sales_pos_total_tvac').innerText = '';

		document.getElementById('ioi_sales_pos_pay_already_paid').innerText = '';
		document.getElementById('ioi_sales_pos_pay_still_to_pay').innerText = '';

		document.getElementById('ioi_sales_pos_pay_status_panel').style.backgroundColor = '';
		document.getElementById('ioi_sales_pos_pay_status').innerText = '';

		if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

			for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

				silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString());

			}

			silicon_ioi.doctype.ioiSalesPOS.remove_element('ioi_sales_pos_detail_table_detail')
		}



	}

	static add_log(error = 0, msg = '')
	{
		document.getElementById('ioi_sales_last_scan').innerText = msg;

		if (error == 0) {

			// No error
			document.getElementById('ioi_sales_last_scan').style.color = 'green';

		}else if (error == 1) {

			// Warning
			document.getElementById('ioi_sales_last_scan').style.color = '#FC793D';

		}else if (error == 2) {

			// Error
			document.getElementById('ioi_sales_last_scan').style.color = '#FC5C47';
		}

		let dt = Date.now();

		silicon_ioi.doctype.ioiSalesPOS.current_log[silicon_ioi.doctype.ioiSalesPOS.current_log.length] = [error, msg, dt];

		if ((silicon_ioi.doctype.ioiSalesPOS.doc) && (silicon_ioi.doctype.ioiSalesPOS.doc != '')) {

			if ((silicon_ioi.doctype.ioiSalesPOS.doc.sales_pos_memo_logs == null) || (silicon_ioi.doctype.ioiSalesPOS.doc.sales_pos_memo_logs == '')) {
				silicon_ioi.doctype.ioiSalesPOS.doc.sales_pos_memo_logs = error + '|' + msg + '|' + dt;
			}else{
				silicon_ioi.doctype.ioiSalesPOS.doc.sales_pos_memo_logs += '\n' + error + '|' + msg + '|' + dt;
			}


			let method = path_sales_pos + '.ioi_sales_pos_save_log';

			frappe.call({  	method: method,
							args: {	"name": silicon_ioi.doctype.ioiSalesPOS.doc.name,
									"log": silicon_ioi.doctype.ioiSalesPOS.doc.sales_pos_memo_logs
							},
							async: false,
							callback:function(r)	{
							}
			});
		}

	}

	static format_number(value)
	{
		let s = value.toString();

		if ((s.indexOf('.') != -1) || (s.indexOf(',') != -1)) {

			if (s.indexOf('.') != -1) {

				s = s.replaceAll('.', silicon_ioi.doctype.ioiSalesPOS.decimal_separator);

			}else {

				s = s.replaceAll(',', silicon_ioi.doctype.ioiSalesPOS.decimal_separator);

			}
		}

		let sign = '';

		if (s[0] == '-') {
			sign = '-';
			s = s.substring(1, s.length);
		}



		let int_value = '0';
		let float_value = '00';


		if (s.indexOf(silicon_ioi.doctype.ioiSalesPOS.decimal_separator) != -1) {

			int_value = s.substring(0, s.indexOf(silicon_ioi.doctype.ioiSalesPOS.decimal_separator))
			float_value = s.substring(s.indexOf(silicon_ioi.doctype.ioiSalesPOS.decimal_separator)+1, s.length)
		}else{
			int_value = s;
		}


		let total = 0;

		if (int_value.length > 12) {
			total = 15;
		}else if (int_value.length > 9) {
			total = 12;
		}else if (int_value.length > 6) {
			total = 9;
		}else if (int_value.length > 3) {
			total = 6;
		}

		let int_output = '';

		for (var i = int_value.length; i < total; i++) {
			int_output += '0';
		}

		int_output += int_value;

		int_value = int_output.trim();

		int_output = '';

		for (var i = 0; i < int_value.length; i++) {

			if (i > 0) {

				if (i % 3 == 0) {
					int_output += silicon_ioi.doctype.ioiSalesPOS.thousand_separator;
				}
			}

			int_output += int_value[i];
		}

		int_output = int_output.trim();

		int_value = '';

		if (int_output != 0) {

			for (var i = 0; i < int_output.length; i++) {

				if (int_output[i] != '0') {
					int_value = int_output.substring(i, int_output.length)
					break;
				}
			}
		}else{
			int_value = '0';
		}

		let return_value = sign + int_value + silicon_ioi.doctype.ioiSalesPOS.decimal_separator + float_value;

		return return_value;
	}

	static remove_element(id)
	{
		if (document.getElementById(id)) {
			document.getElementById(id).remove();
		}
	}

	static configure_grid()
	{

		document.getElementById('ioi_sales_last_scan').innerText = '';

		if ((silicon_ioi.doctype.ioiSalesPOS.state == 'EDIT') || (silicon_ioi.doctype.ioiSalesPOS.state == 'INSERT')) {
			silicon_ioi.doctype.ioiSalesPOS.add_log(2, __('Save your changes before please'));
			return false;
		}


		var configureGridForm = new frappe.ui.Dialog({
			'title': __("Grid configurator"),
			'static': true,
			'fields': [
				{'fieldname': 'html_sales_pos_grid_configurator', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];

				button_ok.disabled = true;

				if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length == 0) {
					button_ok.disabled = false;
					frappe.msgprint({ title: __("Message"), message: __("No selected fields"), indicator: "red" });
					raise;
				}

				let fieldnames = [];
				let fieldwidths = [];

				for (var i = 0; i < document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length; i++) {

					fieldnames[fieldnames.length] = document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[i].value;

					let found = false;

					let width = 100;


					for(var j = 0; j < silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length; j++) {

						if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[j][0] == document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[i].value) {

							found = true;

							if ((silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[j][1]) && (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[j][1] != '') && (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[j][1] != 0)) {
								width = parseInt(silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[j][1]);
								fieldwidths[fieldwidths.length] = parseInt(width);
								break;
							}
						}
					}

					if (!found) {
						fieldwidths[fieldwidths.length] = parseInt(width);
					}
				}


				let user_id = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
				let badge_id = silicon_ioi.doctype.ioiSalesPOS.batchuser;

				let profile = '';

				if ((!silicon_ioi.doctype.ioiSalesPOS.profile_grid) || ((silicon_ioi.doctype.ioiSalesPOS.profile_grid) && (silicon_ioi.doctype.ioiSalesPOS.profile_grid.trim() == ''))) {
					profile = 'DEF'
				}else{
					profile = silicon_ioi.doctype.ioiSalesPOS.profile_grid;
				}

				let method = path_user_grid_profile + '.ioi_sales_pos_user_grid_profile_save_grid_profile';

				let ret = {};

				frappe.call({
					method: method,
					args: {
						"user_id": user_id,
						"badge_id": badge_id,
						"profile": profile,
						"fieldnames": fieldnames,
						"fieldwidths": fieldwidths
					},
					async: false,
					callback:function(r)	{
						ret = r.message
					}
				});

				if (ret.error != 0) {
					button_ok.disabled = false;
					frappe.msgprint({ title: __("Message"), message: ret.error_msg, indicator: "red" });
					raise;
				}else{
					configureGridForm.hide();

					silicon_ioi.doctype.ioiSalesPOS.rebuild_grid_header();

					silicon_ioi.doctype.ioiSalesPOS.current_line_id = -1;

					if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

						for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

							if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

								if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked) {
									silicon_ioi.doctype.ioiSalesPOS.current_line_id = i;
									break;
								}
							}
						}
					}


					silicon_ioi.doctype.ioiSalesPOS.refresh_screen();

					if (document.getElementById('ioi_sales_pos_detail_table_detail')) {

						for (var i = 0; i < document.getElementById('ioi_sales_pos_detail_table_detail').rows.length; i++) {

							if (silicon_ioi.doctype.ioiSalesPOS.current_line_id == i) {

								if (document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString())) {

									document.getElementById('ioi_sales_pos_detail_table_detail_checked_id_' + i.toString()).checked = true;
								}
							}
						}
					}



					let fct_up_down_over = function() {
						this.style.cursor = 'pointer';
					}

					let fct_up_down_leave = function() {
						this.style.cursor = 'none';
					}

					document.getElementById('sales_pos_grid_configurator').onmouseover = fct_up_down_over;
					document.getElementById('sales_pos_grid_configurator').onmouseleave = fct_up_down_leave;

					let fct_grid_configurator_click = function() {
						silicon_ioi.doctype.ioiSalesPOS.configure_grid();
					}

					document.getElementById('sales_pos_grid_configurator').onclick = fct_grid_configurator_click;

				}

			},
			secondary_action: function(){

				configureGridForm.hide();
			}

		});

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_available_fields_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_search');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_available_fields');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_profile_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_profile');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_selected_fields_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_selected_fields');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_add');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_remove');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_add_all');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_remove_all');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_up');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_down');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_width_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_configurator_width');

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:480px; width: 100%;">';

		// Available fields
		html += '	<div style="position: relative; top: 0px; left: 2px; width:440px;">';
		html += '		<label id="html_sales_pos_grid_configurator_available_fields_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Available fields") + '</label>';

		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 440px; height: 25px;"> ';
		html += '			<input id="html_sales_pos_grid_configurator_search" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';

		html += '		<div class="control-input" style="position: absolute; top: 60px; left: 0px; width: 440px; height: 25px;"> ';
		html += '			<select id="html_sales_pos_grid_configurator_available_fields" size = "23" class="input-with-feedback form-control bold">';
		html += '			</select>';
		html += '		</div>';
		html += '	</div>';


		// Add
		html += '	<div style="position: relative; top: 25px; left: 450px; width:40px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="html_sales_pos_grid_configurator_add" title="' + __("Add") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&rarr;' + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Remove
		html += '	<div style="position: relative; top: 65px; left: 450px; width:40px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="html_sales_pos_grid_configurator_remove" title="' + __("Remove") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&larr;' + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Add All
		html += '	<div style="position: relative; top: 105px; left: 450px; width:40px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="html_sales_pos_grid_configurator_add_all" title="' + __("Add all fields") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&rarrb;' + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Remove All
		html += '	<div style="position: relative; top: 145px; left: 450px; width:40px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="html_sales_pos_grid_configurator_remove_all" title="' + __("Remove all fields") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&larrb;' + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Selected fields
		html += '	<div style="position: relative; top: 0px; left: 502px; width:440px;">';
		html += '		<label id="html_sales_pos_grid_configurator_selected_fields_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Grid columns (column 'Description' must be the first column)") + '</label>';

		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 440px; height: 25px;"> ';
		html += '			<select id="html_sales_pos_grid_configurator_selected_fields" size = "19" class="input-with-feedback form-control bold">';
		html += '			</select>';
		html += '		</div>';
		html += '	</div>';


		// Width
		html += '	<div style="position: relative; top: 360px; left: 502px; width:440px;">';
		html += '		<label id="html_sales_pos_grid_configurator_width_label" style="position: absolute; top: 4px; left: 0px;z-index:5;">' + __("Column width") + '</label>';

		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 0px; width: 440px; height: 25px;"> ';
		html += '			<input id="html_sales_pos_grid_configurator_width" type="number" step="any" class="input-with-feedback form-control bold" style="text-align: right;" value="">';
		html += '		</div>';

		html += '	</div>';


		// Save as Profile
		html += '	<div style="position: relative; top: 420px; left: 502px; width:440px;">';
		html += '		<label id="html_sales_pos_grid_configurator_profile_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Save as profile") + '</label>';

		html += '		<div class="control-input" style="position: absolute; top: 22px; left: 0px; width: 440px; height: 25px;"> ';
		html += '			<select id="html_sales_pos_grid_configurator_profile" class="input-with-feedback form-control bold">';
		html += '				<option value="DEF" selected>' + __("Default") + '</option>';
		html += '				<option value="A">A</option>';
		html += '				<option value="B">B</option>';
		html += '				<option value="C">C</option>';
		html += '			</select>';
		html += '		</div>';
		html += '	</div>';

		// Up
		html += '	<div style="position: relative; top: 25px; left: 952px; width:40px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="html_sales_pos_grid_configurator_up" title="' + __("Up") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&uarr;' + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Down
		html += '	<div style="position: relative; top: 65px; left: 952px; width:40px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="html_sales_pos_grid_configurator_down" title="' + __("Down") + '" class="btn btn-default ellipsis" style="height: 30px; width: 40px;" onclick="">&darr;' + '</button>';
		html += '		</div>';
		html += '	</div>';


		html += '</div>';


		let action_select_grid_profile = function () {
			silicon_ioi.doctype.ioiSalesPOS.select_grid_profile();
		}

		configureGridForm.add_custom_action(__("Select other profile"), action_select_grid_profile);


		let action_clear_grid_profile = function () {
			silicon_ioi.doctype.ioiSalesPOS.clear_grid_profile();
		}

		configureGridForm.add_custom_action(__("Remove this profile"), action_clear_grid_profile);


		configureGridForm.fields_dict.html_sales_pos_grid_configurator.$wrapper.html(html);
		configureGridForm.$wrapper.find('.modal-dialog').css("max-width", "1045px").css("width", "1045px");
		configureGridForm.$wrapper.find('.modal-dialog').css("max-height", "600px").css("height", "600px");
		configureGridForm.show();

		silicon_ioi.doctype.ioiSalesPOS.sleep_static(200).then(() => {

			document.getElementById('html_sales_pos_grid_configurator_profile').value = silicon_ioi.doctype.ioiSalesPOS.profile_grid;

			let fct_change_profile = function () {
				silicon_ioi.doctype.ioiSalesPOS.profile_grid = document.getElementById('html_sales_pos_grid_configurator_profile').value;


				document.getElementById('html_sales_pos_profile').value = silicon_ioi.doctype.ioiSalesPOS.profile_grid;


				if (silicon_ioi.doctype.ioiSalesPOS.profile_grid == 'DEF') {

					document.getElementById('html_sales_pos_profile_panel_default').style.backgroundColor = '#D9D3D2';
					document.getElementById('html_sales_pos_profile_panel_a').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_b').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_c').style.backgroundColor = '';

					document.getElementById('html_sales_pos_profile_title_default').style.color = '#000000';
					document.getElementById('html_sales_pos_profile_title_a').style.color = '';
					document.getElementById('html_sales_pos_profile_title_b').style.color = '';
					document.getElementById('html_sales_pos_profile_title_c').style.color = '';

				}else if (silicon_ioi.doctype.ioiSalesPOS.profile_grid == 'A') {

					document.getElementById('html_sales_pos_profile_panel_default').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_a').style.backgroundColor = '#D9D3D2';
					document.getElementById('html_sales_pos_profile_panel_b').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_c').style.backgroundColor = '';

					document.getElementById('html_sales_pos_profile_title_default').style.color = '';
					document.getElementById('html_sales_pos_profile_title_a').style.color = '#000000';
					document.getElementById('html_sales_pos_profile_title_b').style.color = '';
					document.getElementById('html_sales_pos_profile_title_c').style.color = '';

				}else if (silicon_ioi.doctype.ioiSalesPOS.profile_grid == 'B') {

					document.getElementById('html_sales_pos_profile_panel_default').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_a').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_b').style.backgroundColor = '#D9D3D2';
					document.getElementById('html_sales_pos_profile_panel_c').style.backgroundColor = '';

					document.getElementById('html_sales_pos_profile_title_default').style.color = '';
					document.getElementById('html_sales_pos_profile_title_a').style.color = '';
					document.getElementById('html_sales_pos_profile_title_b').style.color = '#000000';
					document.getElementById('html_sales_pos_profile_title_c').style.color = '';

				}else if (silicon_ioi.doctype.ioiSalesPOS.profile_grid == 'C') {

					document.getElementById('html_sales_pos_profile_panel_default').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_a').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_b').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_c').style.backgroundColor = '#D9D3D2';


					document.getElementById('html_sales_pos_profile_title_default').style.color = '';
					document.getElementById('html_sales_pos_profile_title_a').style.color = '';
					document.getElementById('html_sales_pos_profile_title_b').style.color = '';
					document.getElementById('html_sales_pos_profile_title_c').style.color = '#000000';
				}

			}

			document.getElementById('html_sales_pos_grid_configurator_profile').onchange = fct_change_profile;

			let fct_keydown = function(event) {

				if (event.keyCode == 13) {
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_refresh_available_fields();
					return false;
				}
			}

			document.getElementById('html_sales_pos_grid_configurator_search').onkeydown = fct_keydown;

			let fct_add = function() {
				silicon_ioi.doctype.ioiSalesPOS.configure_grid_add();
			}

			document.getElementById('html_sales_pos_grid_configurator_add').onclick = fct_add;

			let fct_remove = function() {
				silicon_ioi.doctype.ioiSalesPOS.configure_grid_remove();
			}

			document.getElementById('html_sales_pos_grid_configurator_remove').onclick = fct_remove;

			let fct_add_all = function() {
				silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_all();
			}

			document.getElementById('html_sales_pos_grid_configurator_add_all').onclick = fct_add_all;

			let fct_remove_all = function() {
				silicon_ioi.doctype.ioiSalesPOS.configure_grid_remove_all();
			}

			document.getElementById('html_sales_pos_grid_configurator_remove_all').onclick = fct_remove_all;



			let fct_up = function() {
				silicon_ioi.doctype.ioiSalesPOS.configure_grid_up();
			}

			document.getElementById('html_sales_pos_grid_configurator_up').onclick = fct_up;


			let fct_down = function() {
				silicon_ioi.doctype.ioiSalesPOS.configure_grid_down();
			}

			document.getElementById('html_sales_pos_grid_configurator_down').onclick = fct_down;

			let fct_width_change = function()
			{
				if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length > 0) {

					for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length; i++) {

						if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] == document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex].value) {

							if ((document.getElementById('html_sales_pos_grid_configurator_width').value) && (document.getElementById('html_sales_pos_grid_configurator_width').value != '')) {

								if (parseInt(document.getElementById('html_sales_pos_grid_configurator_width').value) < 20) {

									silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1] = 20;
									document.getElementById('html_sales_pos_grid_configurator_width').value = 20;

								}else{
									silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1] = parseInt(document.getElementById('html_sales_pos_grid_configurator_width').value);
								}
							}else{
								silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1] = 100;
								document.getElementById('html_sales_pos_grid_configurator_width').value = 100;
							}
							break;
						}

					}
				}
			}

			document.getElementById('html_sales_pos_grid_configurator_width').onchange = fct_width_change;



			silicon_ioi.doctype.ioiSalesPOS.configure_grid_refresh_available_fields();

			if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length == 0) {


				let user_id = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
				let badge_id = silicon_ioi.doctype.ioiSalesPOS.batchuser;


				let data_fields = [];

				let method = path_user_grid_profile + '.ioi_sales_pos_user_grid_profile_get_grid_fields';

				frappe.call({
					method: method,
					args: {
						"user_id": user_id,
						"badge_id": badge_id
					},
					async: false,
					callback:function(r)	{
						data_fields = r.message
					}
				});

				silicon_ioi.doctype.ioiSalesPOS.profile_grid = 'DEF';

				if (data_fields.length == 0) {

					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('item_description', __("Description"), 267);
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('delivered_qty', __("Qty"), 60);
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('unit_price', __("Unit price"), 80);
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('discount', __("Discount"), 70);
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('total', __("Total"), 90);

					document.getElementById('html_sales_pos_grid_configurator_width').value = 267;

				}else{

					let width = 0;

					for (var i = 0; i < data_fields.length; i++) {

						silicon_ioi.doctype.ioiSalesPOS.profile_grid = data_fields[i].profile;

						silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column(data_fields[i].fieldname, data_fields[i].label + ' ( ' + data_fields[i].fieldname + ' ) ', data_fields[i].width);

						if (i == 0) {
							width = data_fields[i].width
						}
					}

					document.getElementById('html_sales_pos_grid_configurator_width').value = width;
				}

			}

			if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length > 0) {
				document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex = 0;
			}


			let fct_change_selected_fields = function() {


				if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields) {

					for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length; i++) {

						if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] == document.getElementById('html_sales_pos_grid_configurator_selected_fields').value) {
							document.getElementById('html_sales_pos_grid_configurator_width').value = silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1];
						}

					}
				}

				if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length > 0) {

					for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length; i++) {

						if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] == document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex].value) {

							if ((document.getElementById('html_sales_pos_grid_configurator_width').value) && (document.getElementById('html_sales_pos_grid_configurator_width').value != '')) {

								if (parseInt(document.getElementById('html_sales_pos_grid_configurator_width').value) < 20) {

									silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1] = 20;
									document.getElementById('html_sales_pos_grid_configurator_width').value = 20;

								}else{
									silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1] = parseInt(document.getElementById('html_sales_pos_grid_configurator_width').value);
								}
							}else{
								silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1] = 100;
								document.getElementById('html_sales_pos_grid_configurator_width').value = 100;
							}
							break;
						}

					}
				}
			}

			document.getElementById('html_sales_pos_grid_configurator_selected_fields').onchange = fct_change_selected_fields;
			fct_change_selected_fields();


		});

	}

	static select_grid_profile()
	{
		var selectGridProfileForm = new frappe.ui.Dialog({
			'title': __("Select other profile"),
			'static': true,
			'fields': [
				{'fieldname': 'html_sales_pos_grid_select_profile', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Ok'),
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				let button_ok = document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1];

				button_ok.disabled = true;

				let old_profile = silicon_ioi.doctype.ioiSalesPOS.profile_grid

				silicon_ioi.doctype.ioiSalesPOS.profile_grid = document.getElementById('html_sales_pos_grid_select_profile_profile').value;

				document.getElementById('html_sales_pos_grid_configurator_profile').value = silicon_ioi.doctype.ioiSalesPOS.profile_grid;


				let user_id = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
				let badge_id = silicon_ioi.doctype.ioiSalesPOS.batchuser;

				let new_profile = '';

				if ((!silicon_ioi.doctype.ioiSalesPOS.profile_grid) || ((silicon_ioi.doctype.ioiSalesPOS.profile_grid) && (silicon_ioi.doctype.ioiSalesPOS.profile_grid.trim() == ''))) {
					new_profile = 'DEF'
				}else{
					new_profile = silicon_ioi.doctype.ioiSalesPOS.profile_grid;
				}



				let method = path_user_grid_profile + '.ioi_sales_pos_user_grid_profile_change_profile';

				let ret = {};

				frappe.call({
					method: method,
					args: {
						"user_id": user_id,
						"badge_id": badge_id,
						"old_profile": old_profile,
						"new_profile": new_profile
					},
					async: false,
					callback:function(r)	{
						ret = r.message
					}
				});

				selectGridProfileForm.hide();

				silicon_ioi.doctype.ioiSalesPOS.profile_grid = document.getElementById('html_sales_pos_grid_configurator_profile').value;


				document.getElementById('html_sales_pos_profile').value = silicon_ioi.doctype.ioiSalesPOS.profile_grid;


				if (silicon_ioi.doctype.ioiSalesPOS.profile_grid == 'DEF') {

					document.getElementById('html_sales_pos_profile_panel_default').style.backgroundColor = '#D9D3D2';
					document.getElementById('html_sales_pos_profile_panel_a').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_b').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_c').style.backgroundColor = '';

					document.getElementById('html_sales_pos_profile_title_default').style.color = '#000000';
					document.getElementById('html_sales_pos_profile_title_a').style.color = '';
					document.getElementById('html_sales_pos_profile_title_b').style.color = '';
					document.getElementById('html_sales_pos_profile_title_c').style.color = '';

				}else if (silicon_ioi.doctype.ioiSalesPOS.profile_grid == 'A') {

					document.getElementById('html_sales_pos_profile_panel_default').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_a').style.backgroundColor = '#D9D3D2';
					document.getElementById('html_sales_pos_profile_panel_b').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_c').style.backgroundColor = '';

					document.getElementById('html_sales_pos_profile_title_default').style.color = '';
					document.getElementById('html_sales_pos_profile_title_a').style.color = '#000000';
					document.getElementById('html_sales_pos_profile_title_b').style.color = '';
					document.getElementById('html_sales_pos_profile_title_c').style.color = '';

				}else if (silicon_ioi.doctype.ioiSalesPOS.profile_grid == 'B') {

					document.getElementById('html_sales_pos_profile_panel_default').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_a').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_b').style.backgroundColor = '#D9D3D2';
					document.getElementById('html_sales_pos_profile_panel_c').style.backgroundColor = '';

					document.getElementById('html_sales_pos_profile_title_default').style.color = '';
					document.getElementById('html_sales_pos_profile_title_a').style.color = '';
					document.getElementById('html_sales_pos_profile_title_b').style.color = '#000000';
					document.getElementById('html_sales_pos_profile_title_c').style.color = '';

				}else if (silicon_ioi.doctype.ioiSalesPOS.profile_grid == 'C') {

					document.getElementById('html_sales_pos_profile_panel_default').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_a').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_b').style.backgroundColor = '';
					document.getElementById('html_sales_pos_profile_panel_c').style.backgroundColor = '#D9D3D2';


					document.getElementById('html_sales_pos_profile_title_default').style.color = '';
					document.getElementById('html_sales_pos_profile_title_a').style.color = '';
					document.getElementById('html_sales_pos_profile_title_b').style.color = '';
					document.getElementById('html_sales_pos_profile_title_c').style.color = '#000000';
				}



				if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length > 0) {

					for (var i = document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length-1; i >= 0; i--) {
						document.getElementById('html_sales_pos_grid_configurator_selected_fields').remove(i);
					}

				}

				let data_fields = [];

				method = path_user_grid_profile + '.ioi_sales_pos_user_grid_profile_get_grid_fields';

				frappe.call({
					method: method,
					args: {
						"user_id": user_id,
						"badge_id": badge_id
					},
					async: false,
					callback:function(r)	{
						data_fields = r.message
					}
				});


				if (data_fields.length == 0) {

					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('item_description', __("Description"), 267);
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('delivered_qty', __("Qty"), 60);
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('unit_price', __("Unit price"), 80);
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('discount', __("Discount"), 70);
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('total', __("Total"), 90);

					document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex = 0;
					document.getElementById('html_sales_pos_grid_configurator_width').value = 267;

				}else{

					let width = 0;

					for (var i = 0; i < data_fields.length; i++) {

						silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column(data_fields[i].fieldname, data_fields[i].label + ' ( ' + data_fields[i].fieldname + ' ) ', data_fields[i].width);

						if (i == 0) {
							width = data_fields[i].width
						}
					}

					document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex = 0;
					document.getElementById('html_sales_pos_grid_configurator_width').value = width;
				}

				for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length; i++) {

					if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] == document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex].value) {

						if ((document.getElementById('html_sales_pos_grid_configurator_width').value) && (document.getElementById('html_sales_pos_grid_configurator_width').value != '')) {

							if (parseInt(document.getElementById('html_sales_pos_grid_configurator_width').value) < 20) {

								silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1] = 20;
								document.getElementById('html_sales_pos_grid_configurator_width').value = 20;

							}else{
								silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1] = parseInt(document.getElementById('html_sales_pos_grid_configurator_width').value);
							}
						}else{
							silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1] = 100;
							document.getElementById('html_sales_pos_grid_configurator_width').value = 100;
						}
						break;
					}

				}

			},
			secondary_action: function(){
				selectGridProfileForm.hide();
			}

		});

		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_select_profile_profile_label');
		silicon_ioi.doctype.ioiSalesPOS.remove_element('html_sales_pos_grid_select_profile_profile');

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:80px; width: 100%;">';

		// Profile
		html += '	<div style="position: relative; top: 0px; left: 2px; width:300px;">';
		html += '		<label id="html_sales_pos_grid_select_profile_profile_label" style="position: absolute; top: 0px; left: 0px;z-index:5;">' + __("Profile") + '</label>';

		html += '		<div class="control-input" style="position: absolute; top: 22px; left: 0px; width: 300px; height: 25px;"> ';
		html += '			<select id="html_sales_pos_grid_select_profile_profile" class="input-with-feedback form-control bold">';

		if (silicon_ioi.doctype.ioiSalesPOS.profile_grid != 'DEF') {
			html += '				<option value="DEF" selected>' + __("Default") + '</option>';
		}
		if (silicon_ioi.doctype.ioiSalesPOS.profile_grid != 'A') {
			html += '				<option value="A">A</option>';
		}
		if (silicon_ioi.doctype.ioiSalesPOS.profile_grid != 'B') {
			html += '				<option value="B">B</option>';
		}
		if (silicon_ioi.doctype.ioiSalesPOS.profile_grid != 'C') {
			html += '				<option value="C">C</option>';
		}
		html += '			</select>';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';


		selectGridProfileForm.fields_dict.html_sales_pos_grid_select_profile.$wrapper.html(html);
		selectGridProfileForm.$wrapper.find('.modal-dialog').css("max-width", "350px").css("width", "350px");
		selectGridProfileForm.$wrapper.find('.modal-dialog').css("max-height", "600px").css("height", "600px");
		selectGridProfileForm.show();
	}

	static clear_grid_profile()
	{

		let msg = 'Remove this profile ?';

		frappe.confirm(	msg,
						() => 	{
									silicon_ioi.doctype.ioiSalesPOS.do_clear_grid_profile();
								},
						() => 	{
								}
		);

	}

	static do_clear_grid_profile()
	{
		let profile = silicon_ioi.doctype.ioiSalesPOS.profile_grid;

		let user_id = silicon_ioi.doctype.ioiSalesPOS.batchuser_userid;
		let badge_id = silicon_ioi.doctype.ioiSalesPOS.batchuser;

		let method = path_user_grid_profile + '.ioi_sales_pos_user_grid_profile_clear_profile';

		frappe.call({
			method: method,
			args: {
				"user_id": user_id,
				"badge_id": badge_id,
				"profile": profile,
			},
			async: false,
			callback:function(r)	{

				silicon_ioi.doctype.ioiSalesPOS.profile_grid = 'DEF';
				document.getElementById('html_sales_pos_grid_configurator_profile').value = silicon_ioi.doctype.ioiSalesPOS.profile_grid;

				if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length > 0) {

					for (var i = document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length-1; i >= 0; i--) {
						document.getElementById('html_sales_pos_grid_configurator_selected_fields').remove(i);
					}

				}

				let data_fields = [];

				method = path_user_grid_profile + '.ioi_sales_pos_user_grid_profile_get_grid_fields';

				frappe.call({
					method: method,
					args: {
						"user_id": user_id,
						"badge_id": badge_id
					},
					async: false,
					callback:function(r)	{
						data_fields = r.message;
					}
				});


				if (data_fields.length == 0) {

					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('item_description', __("Description"), 267);
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('delivered_qty', __("Qty"), 60);
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('unit_price', __("Unit price"), 80);
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('discount', __("Discount"), 70);
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column('total', __("Total"), 90);

					document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex = 0;
					document.getElementById('html_sales_pos_grid_configurator_width').value = 267;

				}else{

					let width = 0;

					for (var i = 0; i < data_fields.length; i++) {

						silicon_ioi.doctype.ioiSalesPOS.configure_grid_add_column(data_fields[i].fieldname, data_fields[i].label + ' ( ' + data_fields[i].fieldname + ' ) ', data_fields[i].width);

						if (i == 0) {
							width = data_fields[i].width;
						}
					}

					document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex = 0;
					document.getElementById('html_sales_pos_grid_configurator_width').value = width;
				}


				for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length; i++) {

					if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] == document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex].value) {

						if ((document.getElementById('html_sales_pos_grid_configurator_width').value) && (document.getElementById('html_sales_pos_grid_configurator_width').value != '')) {

							if (parseInt(document.getElementById('html_sales_pos_grid_configurator_width').value) < 20) {

								silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1] = 20;
								document.getElementById('html_sales_pos_grid_configurator_width').value = 20;

							}else{
								silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1] = parseInt(document.getElementById('html_sales_pos_grid_configurator_width').value);
							}
						}else{
							silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1] = 100;
							document.getElementById('html_sales_pos_grid_configurator_width').value = 100;
						}
						break;
					}

				}
			}


		});
	}

	static configure_grid_add_column(fieldname, description, width)
	{

		let option = document.createElement("option");
		option.value = fieldname;
		option.text = description;

		let fct_dbl_click = function() {
			silicon_ioi.doctype.ioiSalesPOS.configure_grid_remove();

			if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields) {

				for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length; i++) {

					if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] == this.value) {
						document.getElementById('html_sales_pos_grid_configurator_width').value = silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1];
					}

				}
			}

			if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length == 0) {
				document.getElementById('html_sales_pos_grid_configurator_width').value = '';
			}



		}

		option.ondblclick = fct_dbl_click;


		let fct_click = function() {

			if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields) {

				for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length; i++) {

					if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] == this.value) {
						document.getElementById('html_sales_pos_grid_configurator_width').value = silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1];
					}

				}
			}

			if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length == 0) {
				document.getElementById('html_sales_pos_grid_configurator_width').value = '';
			}
		}

		option.onclick = fct_click;

		document.getElementById('html_sales_pos_grid_configurator_selected_fields').add(option);


		let idx = document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length-1;

		if (!silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields) {
			silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields = [];
		}

		silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length] = [fieldname, width];

		document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex = idx;
		document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex].click();
	}

	static configure_grid_refresh_available_fields()
	{
		let method = path_user_grid_profile + '.ioi_sales_pos_user_grid_profile_get_available_fields';

		silicon_ioi.doctype.ioiSalesPOS.configure_available_fields = [];

		frappe.call({
			method: method,
			args: {
				"search_term": document.getElementById('html_sales_pos_grid_configurator_search').value

			},
			async: false,
			callback:function(r)	{
				silicon_ioi.doctype.ioiSalesPOS.configure_available_fields = r.message;
			}
		});

		if (document.getElementById('html_sales_pos_grid_configurator_available_fields').options.length > 0) {

			for (var i = document.getElementById('html_sales_pos_grid_configurator_available_fields').options.length-1; i >= 0; i--) {
				document.getElementById('html_sales_pos_grid_configurator_available_fields').options.remove(i);
			}

		}

		if (silicon_ioi.doctype.ioiSalesPOS.configure_available_fields.length > 0) {

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_available_fields.length; i++) {

				let option = document.createElement("option");
				option.value = silicon_ioi.doctype.ioiSalesPOS.configure_available_fields[i].fieldname
				option.text = silicon_ioi.doctype.ioiSalesPOS.configure_available_fields[i].formatted_fieldname;

				let fct_dbl_click = function() {
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_add();
				}

				option.ondblclick = fct_dbl_click;


				document.getElementById('html_sales_pos_grid_configurator_available_fields').add(option);
			}

			document.getElementById('html_sales_pos_grid_configurator_available_fields').selectedIndex = 0;
		}
	}

	static configure_grid_add()
	{
		if (document.getElementById('html_sales_pos_grid_configurator_available_fields').options.length == 0)	{
			return false;
		}


		let current_value = document.getElementById('html_sales_pos_grid_configurator_available_fields').options[document.getElementById('html_sales_pos_grid_configurator_available_fields').selectedIndex].value
		let current_text = document.getElementById('html_sales_pos_grid_configurator_available_fields').options[document.getElementById('html_sales_pos_grid_configurator_available_fields').selectedIndex].innerText



		let found = false;

		for (var i = 0; i < document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length; i++) {
			if ((current_value) == document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[i].value) {
				found = true;
				break;
			}
		}

		if (!found) {

			if (!silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields) {
				silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields = [];
			}

			let option = document.createElement("option");
			option.value = current_value;
			option.text = current_text;

			let fct_dbl_click = function() {
				silicon_ioi.doctype.ioiSalesPOS.configure_grid_remove();

				if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields) {

					for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length; i++) {

						if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] == this.value) {
							document.getElementById('html_sales_pos_grid_configurator_width').value = silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1];
						}

					}
				}

				if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length == 0) {
					document.getElementById('html_sales_pos_grid_configurator_width').value = '';
				}



			}

			option.ondblclick = fct_dbl_click;


			let fct_click = function() {

				if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields) {

					for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length; i++) {

						if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] == this.value) {
							document.getElementById('html_sales_pos_grid_configurator_width').value = silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1];
						}

					}
				}

				if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length == 0) {
					document.getElementById('html_sales_pos_grid_configurator_width').value = '';
				}
			}

			option.onclick = fct_click;

			document.getElementById('html_sales_pos_grid_configurator_selected_fields').add(option);


			let idx = document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length-1;

			if (!silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields) {
				silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields = [];
			}

			for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_available_fields.length; i++) {

				if (silicon_ioi.doctype.ioiSalesPOS.configure_available_fields[i].fieldname == current_value) {
					silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length] = [current_value, 100];
					break;
				}

			}

			document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex = idx;
			document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex].click();

		}

	}

	static configure_grid_remove()
	{

		if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length == 0) {
			return false;
		}


		let current_value = document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex].value

		if ((current_value == 'item_description') || (current_value == 'delivered_qty') || (current_value == 'unit_price') || (current_value == 'discount') || (current_value == 'total')) {
			return false;
		}

		let idx = -1;

		for (var i = 0; i < document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length; i++) {

			if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[i].value == current_value) {
				document.getElementById('html_sales_pos_grid_configurator_selected_fields').remove(i);

				idx = i;
				break;
			}
		}

		if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length != 0) {

			if (idx != -1) {

				if (idx <= document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length-1) {
					document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex = idx;
				}else{
					document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex = document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length-1;
				}


			}
		}

		if (!silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields) {
			silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields = [];
		}

		for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length; i++) {

			if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] == current_value) {
				silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.slice(i, 1);
				break;
			}
		}

		if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length == 0) {
			document.getElementById('html_sales_pos_grid_configurator_width').value = '';
		}


	}

	static configure_grid_add_all()
	{
		if (document.getElementById('html_sales_pos_grid_configurator_available_fields').options.length == 0)	{
			return false;
		}


		for (var k = 0; k < document.getElementById('html_sales_pos_grid_configurator_available_fields').options.length; k++) {


			let current_value = document.getElementById('html_sales_pos_grid_configurator_available_fields').options[k].value;
			let current_text = document.getElementById('html_sales_pos_grid_configurator_available_fields').options[k].innerText

			let found = false;

			for (var i = 0; i < document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length; i++) {
				if (current_value == document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[i].value) {
					found = true;
					break;
				}
			}

			if (!found) {

				let option = document.createElement("option");
				option.value = current_value;
				option.text = current_text;

				let fct_dbl_click = function() {
					silicon_ioi.doctype.ioiSalesPOS.configure_grid_remove();

					if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields) {

						for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length; i++) {

							if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] == this.value) {
								document.getElementById('html_sales_pos_grid_configurator_width').value = silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1];
							}

						}
					}


				}

				option.ondblclick = fct_dbl_click;


				let fct_click = function() {

					if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields) {

						for (var i = 0; i < silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length; i++) {

							if (silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] == this.value) {
								document.getElementById('html_sales_pos_grid_configurator_width').value = silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][1];
							}

						}
					}
				}

				option.onclick = fct_click;

				document.getElementById('html_sales_pos_grid_configurator_selected_fields').add(option);

				if (!silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields) {
					silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields = [];
				}

				silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length] = [current_value, 100];

			}
		}

		let idx = document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length-1;
		document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex = idx;
		document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex].click();


	}


	static configure_grid_remove_all()
	{
		if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length == 0) {
			return false;
		}

		for (var k = document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length-1; k >=0 ; k--) {

			if ((document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[k].value != 'item_description') &&
				(document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[k].value != 'delivered_qty') &&
				(document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[k].value != 'unit_price') &&
				(document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[k].value != 'discount') &&
				(document.getElementById('html_sales_pos_grid_configurator_selected_fields').options[k].value != 'total')) {
				document.getElementById('html_sales_pos_grid_configurator_selected_fields').remove(k);
			}
		}

		if (!silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields) {
			silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields = [];
		}


		for (var i = silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.length-1; i >= 0; i--) {

			if ((silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] != 'item_description') &&
				(silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] != 'delivered_qty') &&
				(silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] != 'unit_price') &&
				(silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] != 'discount') &&
				(silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields[i][0] != 'total')) {

				silicon_ioi.doctype.ioiSalesPOS.configure_selected_fields.slice(i, 1);

			}
		}
	}


	static configure_grid_up()
	{

		if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length > 0) {

			if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex != 0) {

				let current_idx = document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex;

				if (current_idx != -1) {

					let selected_fields_current_value = document.getElementById('html_sales_pos_grid_configurator_selected_fields')[current_idx].value;
					let selected_fields_current_label = document.getElementById('html_sales_pos_grid_configurator_selected_fields')[current_idx].innerText;


					let prior_idx = current_idx-1;

					if (document.getElementById('html_sales_pos_grid_configurator_selected_fields')[prior_idx]) {

						let selected_fields_prior_value = document.getElementById('html_sales_pos_grid_configurator_selected_fields')[prior_idx].value;
						let selected_fields_prior_label = document.getElementById('html_sales_pos_grid_configurator_selected_fields')[prior_idx].innerText;


						if (selected_fields_prior_value == 'item_description') {
							return false;
						}


						document.getElementById('html_sales_pos_grid_configurator_selected_fields')[current_idx].value = selected_fields_prior_value;
						document.getElementById('html_sales_pos_grid_configurator_selected_fields')[current_idx].innerText = selected_fields_prior_label;

						document.getElementById('html_sales_pos_grid_configurator_selected_fields')[prior_idx].value = selected_fields_current_value;
						document.getElementById('html_sales_pos_grid_configurator_selected_fields')[prior_idx].innerText = selected_fields_current_label;

						document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex = prior_idx;

					}
				}
			}
		}

	}

	static configure_grid_down()
	{
		if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length > 0) {

			if (document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex != document.getElementById('html_sales_pos_grid_configurator_selected_fields').options.length-1) {

				let current_idx = document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex;

				if (current_idx != -1) {

					let selected_fields_current_value = document.getElementById('html_sales_pos_grid_configurator_selected_fields')[current_idx].value;
					let selected_fields_current_label = document.getElementById('html_sales_pos_grid_configurator_selected_fields')[current_idx].innerText;

					if (selected_fields_current_value == 'item_description') {
						return false;
					}

					let next_idx = current_idx + 1;

					if (document.getElementById('html_sales_pos_grid_configurator_selected_fields')[next_idx]) {

						let selected_fields_next_value = document.getElementById('html_sales_pos_grid_configurator_selected_fields')[next_idx].value;
						let selected_fields_next_label = document.getElementById('html_sales_pos_grid_configurator_selected_fields')[next_idx].innerText;

						document.getElementById('html_sales_pos_grid_configurator_selected_fields')[current_idx].value = selected_fields_next_value
						document.getElementById('html_sales_pos_grid_configurator_selected_fields')[current_idx].innerText = selected_fields_next_label;

						document.getElementById('html_sales_pos_grid_configurator_selected_fields')[next_idx].value = selected_fields_current_value
						document.getElementById('html_sales_pos_grid_configurator_selected_fields')[next_idx].innerText = selected_fields_current_label;

						document.getElementById('html_sales_pos_grid_configurator_selected_fields').selectedIndex = next_idx;

					}
				}
			}
		}
	}


	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	static set_scan_focus()
	{

		if (document.getElementById('ioi_sales_pos_scan_active')) {

			if (document.getElementById('ioi_sales_pos_scan_active').checked) {

				if (document.getElementsByClassName('modal-backdrop fade show')) {
					if (document.getElementsByClassName('modal-backdrop fade show').length == 0) {

						if (!document.getElementById('ioi_sales_pos_screen_locked')) {
							document.getElementById('ioi_sales_pos_scan').focus();
						}
					}
				}else{

					if (!document.getElementById('ioi_sales_pos_screen_locked')) {
						document.getElementById('ioi_sales_pos_scan').focus();
					}
				}

			}
		}

		window.setTimeout(silicon_ioi.doctype.ioiSalesPOS.set_scan_focus, 100);
	}


}

silicon_ioi.doctype.ioiSalesPOS = ioiSalesPOS;
