frappe.provide('silicon_ioi');
const path_report = 'silicon_ioi.ioi_system.doctype.ioi_report.ioi_report';

export class ioiReportMethods {
	static #validate_question_values(question, values) {
		let response = {}
		response["success"] = true;
		response["errors"] = [];

		if ((question["min"] != question["max"])) {
			if (!(values["value_1"] >= question["min"] && values["value_1"] <= question["max"])) {
				response["success"] = false;
				response["errors"].push({ "value_idx": 1, "id": "MIN_MAX_ERR", 'text': __('Min max limit exceeded') });
			}

			if (values["value_2"]) {
				if (!(values["value_2"] >= question["min"] && values["value_2"] <= question["max"])) {
					response["success"] = false;
					response["errors"].push({ "value_idx": 2, "id": "MIN_MAX_ERR", 'text': __('Min max limit exceeded') });
				}
			}
		}

		if (question["interval"] && question["compare_values"]) {
			if (values["value_2"] < values["value_1"]) {
				response["success"] = false;
				response["errors"].push({ "value_idx": 2, "id": "MAX_SMALLER_MIN", 'text': __('The maximum value is small than the minimum value') });
			}
		}

		return response;
	}

	static #get_report_translated_text(reportName, srcText, languageDest) {
		let response = srcText;
		frappe.call({
			method: "silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.get_report_translated_text",
			args: {
				reportName: reportName,
				srcText: srcText,
				languageDest: languageDest
			},
			async: false,
			callback(r) {
				response = r.message || srcText;
			},
		});
		return response;
	}
	static async showPromptListSel(query) {
		return new Promise((resolve) => {
			if (query.indexOf("_{list.sel}") > -1) {
				frappe.prompt([
					{
						label: __('Document Type'),
						fieldname: 'document_type',
						fieldtype: 'Link',
						options: 'DocType',
						reqd: 1
					},
					{
						label: __('Document Name'),
						fieldname: 'document',
						fieldtype: 'Dynamic Link',
						options: 'document_type',
						reqd: 1
					},
				], (values) => {
					let result = [];
					result.push(values.document);

					resolve(result);
				})
			} else {
				resolve([]);
			}

		}
		);
	}
	static async showReportQuestions(report) {

		let questions = [];
		frappe.call({
			method: "silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.get_report_questions",
			args: {
				report_name: report["name"]
			},
			async: false,
			callback(r) {
				questions = r.message;
			},
		});
		let answers = [];
		let isAborted = false;
		for (var i = 0; i < questions.length && !isAborted; i++) {
			await silicon_ioi.ioiReportMethods.show_question(report["name"], questions[i]["name"], report["test_mode"] == true).then((result) => {
				answers.push(result);
				if (result["action"] == "ABORT") {
					isAborted = true;
				}
			});
		}
		return answers;
	}

	static show_question(report_name, question_name, test_mode = false) {
		function set_border_error(dlg, value_idx, is_error) {
			if (dlg && [1, 2].indexOf(value_idx) > -1) {
				let ctrl = value_idx == 1 ? dlg.fields_dict.value_1 : dlg.fields_dict.value_2;

				if (ctrl) {
					//if (ctrl.constructor.name == 'ControlDatetime') {
					if (is_error) {
						ctrl.$wrapper.addClass('has-error');
					} else {
						ctrl.$wrapper.removeClass('has-error');
					}
				}
			}
		}
		return new Promise((resolve) => {

			let method = path_report + '.get_question'
			frappe.call({
				method: method,
				args: {
					"report_name": report_name,
					"question_name": question_name
				},
				async: false,
				callback: function (r) {
					let question = r.message;
					let fields = [];

					if (question && question["label_value_1"]) {
						question["label_value_1"] = ioiReportMethods.#get_report_translated_text(report_name, question["label_value_1"], frappe.boot.lang)
					}

					if (question && question["label_value_2"]) {
						question["label_value_2"] = ioiReportMethods.#get_report_translated_text(report_name, question["label_value_2"], frappe.boot.lang)
					}

					switch (question["type_of_value"]) {
						case 'INT':
							fields.push({
								'fieldname': 'value_1',
								'fieldtype': 'Int',
								label: question["label_value_1"] || "",
								'reqd': question["optional"] != 1,
								'default': question["default_value_1"] || ''
							});
							if (question["interval"] == 1) {
								fields.push({ 'fieldname': 'col_1', 'fieldtype': 'Column Break' });
								fields.push({
									'fieldname': 'value_2',
									'fieldtype': 'Int',
									label: question["label_value_2"] || "",
									'reqd': question["optional"] != 1,
									'default': question["default_value_2"] || ''
								});
							}
							break;
						case 'DEC':
							fields.push({
								'fieldname': 'value_1',
								'fieldtype': 'Float',
								label: question["label_value_1"] || "",
								'reqd': question["optional"] != 1,
								'default': question["default_value_1"] || ''
							});
							if (question["interval"] == 1) {
								fields.push({ 'fieldname': 'col_1', 'fieldtype': 'Column Break' });
								fields.push({
									'fieldname': 'value_2',
									'fieldtype': 'Float',
									label: question["label_value_2"] || "",
									'reqd': question["optional"] != 1,
									'default': question["default_value_2"] || ''
								});
							}
							break;
						case 'TXT':
							fields.push({
								'fieldname': 'value_1',
								'fieldtype': 'Data',
								label: question["label_value_1"] || "",
								'reqd': question["optional"] != 1,
								'default': question["default_value_1"] || ''
							});
							if (question["interval"] == 1) {
								fields.push({ 'fieldname': 'col_1', 'fieldtype': 'Column Break' });
								fields.push({
									'fieldname': 'value_2',
									'fieldtype': 'Data',
									label: question["label_value_2"] || "",
									'reqd': question["optional"] != 1,
									'default': question["default_value_2"] || ''
								});
							}
							break;
						case 'TUC':
							fields.push({
								'fieldname': 'value_1',
								'fieldtype': 'Data',
								label: question["label_value_1"] || "",
								'reqd': question["optional"] != 1,
								'default': question["default_value_1"] || ''
							});

							if (question["interval"] == 1) {
								fields.push({ 'fieldname': 'col_1', 'fieldtype': 'Column Break' });
								fields.push({
									'fieldname': 'value_2',
									'fieldtype': 'Data',
									label: question["label_value_2"] || "",
									'reqd': question["optional"] != 1,
									'default': question["default_value_2"] || ''
								});
							}
							break;
						case 'DAT':
							fields.push({
								'fieldname': 'value_1',
								'fieldtype': 'Date',
								label: question["label_value_1"] || "",
								'reqd': question["optional"] != 1,
								'default': question["default_value_1"] || ''
							});

							if (question["interval"] == 1) {
								fields.push({ 'fieldname': 'col_1', 'fieldtype': 'Column Break' });
								fields.push({
									'fieldname': 'value_2',
									'fieldtype': 'Date',
									label: question["label_value_2"] || "",
									'reqd': question["optional"] != 1,
									'default': question["default_value_2"] || ''
								});
							}
							break;
						case 'TIM':
							fields.push({
								'fieldname': 'value_1',
								'fieldtype': 'Time',
								label: question["label_value_1"] || "",
								'reqd': question["optional"] != 1,
								'default': question["default_value_1"] || ''
							});

							if (question["interval"] == 1) {
								fields.push({ 'fieldname': 'col_1', 'fieldtype': 'Column Break' });
								fields.push({
									'fieldname': 'value_2',
									'fieldtype': 'Time',
									label: question["label_value_2"] || "",
									'reqd': question["optional"] != 1,
									'default': question["default_value_2"] || ''
								});
							}
							break;
						case 'DTI':
							fields.push({
								'fieldname': 'value_1',
								'fieldtype': 'Datetime',
								label: question["label_value_1"] || "",
								'reqd': question["optional"] != 1,
								'default': question["default_value_1"] || ''
							});
							if (question["interval"] == 1) {
								fields.push({ 'fieldname': 'col_1', 'fieldtype': 'Column Break' });
								fields.push({
									'fieldname': 'value_2',
									'fieldtype': 'Datetime',
									label: question["label_value_2"] || "",
									'reqd': question["optional"] != 1,
									'default': question["default_value_2"] || ''
								});
							}
							break;
						case 'LNK':
							fields.push({
								'fieldname': 'value_1',
								'fieldtype': 'Link',
								'max_items': 1000,
								label: question["label_value_1"] || "",
								'reqd': question["optional"] != 1,
								'options': question['lnk_doctype'],
								get_query: path_report + ".get_query_for_link_field",
								'default': question["default_value_1"] || ''
							});

							if (question["interval"] == 1) {
								fields.push({ 'fieldname': 'col_1', 'fieldtype': 'Column Break' });
								fields.push({
									'fieldname': 'value_2',
									'fieldtype': 'Link',
									'max_items': 1000,
									label: question["label_value_2"] || "",
									'reqd': question["optional"] != 1,
									get_query: path_report + ".get_query_for_link_field",
									'options': question['lnk_doctype'],
									'default': question["default_value_2"] || ''
								});

							}
							break;
						case 'SEL':
							fields.push({
								'fieldname': 'value_1',
								'fieldtype': 'Select',
								label: question["label_value_1"] || "",
								'reqd': question["optional"] != 1,
								'options': question['static_values'],
								'default': question["default_value_1"] || ''
							});

							if (question["interval"] == 1) {
								fields.push({ 'fieldname': 'col_1', 'fieldtype': 'Column Break' });
								fields.push({
									'fieldname': 'value_2',
									'fieldtype': 'Select',
									label: question["label_value_2"] || "",
									'reqd': question["optional"] != 1,
									'options': question['static_values'],
									'default': question["default_value_2"] || ''
								});
							}
							break;
						case 'QRY':
							fields.push({
								'fieldname': 'value_1',
								'fieldtype': 'Select',
								label: question["label_value_1"] || "",
								'reqd': question["optional"] != 1,
								options: question["query_result"],
								only_select: 1,
								'default': question["default_value_1"] || ''
							});
							if (question["interval"] == 1) {
								fields.push({ 'fieldname': 'col_1', 'fieldtype': 'Column Break' });
								fields.push({
									'fieldname': 'value_2',
									'fieldtype': 'Select',
									label: question["label_value_2"] || "",
									'reqd': question["optional"] != 1,
									options: question["query_result"],
									'default': question["default_value_2"] || ''
								});
							}
							break;
					}

					let dlg = new frappe.ui.Dialog({
						'title': ioiReportMethods.#get_report_translated_text(report_name, question["title"], frappe.boot.lang),
						'fields': fields,
					});

					switch (question["type_of_value"]) {
						case 'TUC':
							dlg.fields_dict.value_1.$wrapper[0].fieldobj.$input.css('text-transform', 'uppercase');
							if (question["interval"] == 1) {
								dlg.fields_dict.value_2.$wrapper[0].fieldobj.$input.css('text-transform', 'uppercase');
							}
							break;
					}

					dlg.set_primary_action(__("OK"), (values) => {

						if (!values["value_1"] && values["value_1"] != 0 ) {
							values = { "value_1": "" }
						}
						if (question["interval"] == 1 && !values["value_2"] && values["value_2"] != 0) {
							values["value_2"] = "";
						}
						switch (question["type_of_value"]) {
							case 'TUC':

								values["value_1"] = values["value_1"].toUpperCase();

								if (values["value_2"]) {
									values["value_2"] = values["value_1"].toUpperCase();
								}
								break;
						}

						dlg.disable_primary_action();
						let validate_result = ioiReportMethods.#validate_question_values(question, values);

						if (validate_result["success"]) {
							dlg.hide();

							values['report_name'] = report_name;
							values['question_name'] = question_name;
							values['idx'] = question["idx"];
							values["action"] = "CONTINUE";

							if (question["keep_last"] == '1' && !test_mode) {
								method = path_report + '.set_question_default_values'
								frappe.call({
									method: method,
									args: {
										"report_name": report_name,
										"question_name": question_name,
										"value_1": values["value_1"] || "",
										"value_2": values["value_2"] || ""
									},
									async: true
								});
							}

							resolve(values);
						} else {
							dlg.enable_primary_action();
							let msg = ""
							set_border_error(dlg, 1, false);
							set_border_error(dlg, 2, false);

							validate_result.errors.forEach((err) => {
								//border: '1px solid var(--error-border)'
								set_border_error(dlg, err["value_idx"], true);
								msg = msg + err["text"] + "<br>";
							});
							frappe.msgprint({
								title: __('Error(s) detected'),
								indicator: 'red',
								message: msg
							});
						}
					});

					if (question["optional"]) {
						dlg.add_custom_action(__("Ignore"), () => {
							dlg.hide();
							let values = {};
							values['report_name'] = report_name;
							values['question_name'] = question_name;
							values['idx'] = question["idx"];
							values["action"] = "IGNORE";
							resolve(values);
						});
					}

					dlg.set_secondary_action_label(__("Abort"));
					dlg.set_secondary_action(() => {
						let values = {};
						values['report_name'] = report_name;
						values['question_name'] = question_name;
						values['idx'] = question["idx"];
						values["action"] = "ABORT";
						dlg.hide();
						resolve(values);
					});
					dlg.show();
				}
			});
		});
	}

	static async dialog_render_template(email_template_name, use_html, subject, message) {
		let dlg;
		const render_template = async () => {
			if(!dlg){
				return
			}



			if ((await frappe.db.exists("DocType", cstr(dlg.fields_dict.master_dt.value))) &&
				(await frappe.db.exists(dlg.fields_dict.master_dt.value, dlg.fields_dict.master_name.value))
				){
					function escapeString(txt){

						return cstr(txt).replaceAll(/&/g, "&amp;")
						.replaceAll(/</g, "&lt;")
						.replaceAll(/>/g, "&gt;")
						.replaceAll(/"/g, "&quot;")
						.replaceAll(/'/g, "&#039;")
						.replaceAll(/%/g, "&#37;")
						.replaceAll(/\[/g, "&#91;")
						.replaceAll(/\]/g, "&#93;")
						.replaceAll(/{/g, "&#123;")
						.replaceAll(/}/g, "&#125;")
						.replaceAll(/\n/g, "&#10;")
						.replaceAll(/\t/g, "&#9;");
					}

					let doc = await frappe.db.get_doc(dlg.fields_dict.master_dt.value,dlg.fields_dict.master_name.value)
					frappe.call({
						method:"silicon_ioi.utils.lib.system.ioi_render_template",
						args:{
							template:subject,
							context: {master : doc}
						},
						callback:(r) => {
							$("#subject-preview-error").remove()
							if (r.message.success){
								dlg.fields_dict.subject_preview.set_value(r.message.rendered_template)
							}else{
								dlg.fields_dict.subject_preview.set_value(subject)

								let el = dlg.$wrapper.find("[data-fieldname=subject_preview] .clearfix");
								if (el.length > 0){
									let error_msg = escapeString(r.message.error)
									el[0].insertAdjacentHTML('beforeEnd',`<span id="subject-preview-error" class="indicator-pill no-indicator-dot whitespace-nowrap red" title="${error_msg}" style="cursor: pointer"><span>error</span></span>`);
								}

							}
						}
					});

					frappe.call({
						method:"silicon_ioi.utils.lib.system.ioi_render_template",
						args:{
							template:message,
							context: {master : doc}
						},
						callback:(r) => {
							$("#template-preview-error").remove()
							if (r.message.success){
								dlg.fields_dict.template_preview.set_value(r.message.rendered_template)
							}else{
								dlg.fields_dict.template_preview.set_value(message);

								let el = dlg.$wrapper.find("[data-fieldname=template_preview] .clearfix");
								if (el.length > 0){
									let error_msg = escapeString(r.message.error)
									el[0].insertAdjacentHTML('beforeEnd',`<span id="template-preview-error" class="indicator-pill no-indicator-dot whitespace-nowrap red" title="${error_msg}" style="cursor: pointer"><span>error</span></span>`);
								}
							}
						}
					})
			}
		}

		if (!(await frappe.db.exists("Email Template",cstr(email_template_name)))){
			frappe.throw(__("Email Template not found"));

		}

		let c_name_dt = frappe.scrub(`${frappe.session.user}_eml_tmpl_${email_template_name}_dt`);
		let c_name_doc_name = frappe.scrub(`${frappe.session.user}_eml_tmpl_${email_template_name}_doc_name`);

		dlg = new frappe.ui.Dialog({
			title: __("Electronic payment"),
			size: "extra-large",
			fields: [
				{
					label: __('Master Doctype'),
					fieldname: 'master_dt',
					fieldtype: 'Link',
					options: 'DocType',
					default: frappe.get_cookie(c_name_dt) != "undefined"? frappe.get_cookie(c_name_dt) : "" || "",
					onchange: () => {
						render_template()
					}
				},
				{
					fieldname: 'col1',
					fieldtype: 'Column Break'
				},
				{
					label: __('Master Name'),
					fieldname: 'master_name',
					fieldtype: 'Dynamic Link',
					options: 'master_dt',
					default: frappe.get_cookie(c_name_doc_name) != "undefined"? frappe.get_cookie(c_name_doc_name) : "" || "",
					onchange: () => {
						render_template()
					}
				},
				{
					fieldname: 'sec1',
					fieldtype: 'Section Break'
				},
				{
					label: __('Subject'),
					fieldname: 'subject_preview',
					fieldtype: 'Data',
				},
				{
					label: __('Preview'),
					fieldname: 'template_preview',
					fieldtype: 'HTML Editor',
				},
			],
			primary_action_label: __("Close"),
			primary_action: (values) => {
				if (cstr(values.master_dt)){
					document.cookie = `${c_name_dt}=${values.master_dt}`;
				}
				if (cstr(values.master_name)){
					document.cookie = `${c_name_doc_name}=${values.master_name}`;
				}
				dlg.hide();
			},
			on_page_show: async() => {
				render_template()
			}
		});
		dlg.show();
	}

	static show_reporting_help(opts = [], title = 'Reporting help') {
		let fields = [];
		let variable_list = [];
		let html_template = `
		<table border=0 style="border: 0px;  width=1300px; font-size: smaller;">
			<tr style="height:30px;">
				<td width=250px style="vertical-align: middle;">&nbsp;<b>%name%</b></td>
				<td width=%comment_width% style="vertical-align: middle;">&nbsp;%comment%</td>
				<td width=400px style="display: %example_display%; vertical-align: middle;">&nbsp; ${__('Example')}: <b>%example%</b></td>
			</tr>
		</table>
		`
		frappe.call({
			method: "silicon_ioi.utils.lib.system.get_variables_help",
			args: {
				opts: opts
			},
			async: false,
			callback(r) {
				variable_list = r.message;
			},
		});

		for (var i = 0; i < variable_list.length; i++) {
			if (variable_list[i]["name"] == "section") {
				fields.push({
					label: variable_list[i]["comment"],
					fieldname: 'sb_' + i,
					fieldtype: 'Section Break',
					collapsible: 1
				});
			} else {
				fields.push({
					fieldname: 'sb_html_' + i,
					fieldtype: 'HTML',
					options: html_template
							.replace('%name%', variable_list[i]["name"])
							.replace('%comment%', variable_list[i]["comment"])
							.replace('%example%', variable_list[i]["example"])
							.replace('%example_display%', variable_list[i]["example"] ? 'block' : 'none')
							.replace('%comment_width%', variable_list[i]["example"] ? '700px' : '950px')
				});


		// 		fields.push({
		// 			fieldname: 'sb_column_b1_' + i,
		// 			fieldtype: 'Column Break'
		// 		});

		// 		fields.push({
		// 			label: variable_list[i]["comment"],
		// 			fieldname: 'sb_heading_comment_' + i,
		// 			fieldtype: 'Heading'
		// 		});
		// 		fields.push({
		// 			fieldname: 'sb_column_b2_' + i,
		// 			fieldtype: 'Column Break'
		// 		});
		// 		if (variable_list[i]["example"]) {
		// 			fields.push({
		// 				label: variable_list[i]["example"],
		// 				fieldname: 'sb_heading_example_' + i,
		// 				fieldtype: 'Heading'
		// 			});
		// 		}
			}
		}

		let d = new frappe.ui.Dialog({
			'title': __(title),
			'fields': fields,
			primary_action_label: __('Ok'),
			primary_action: function () {
				d.hide();
			},
			'wide': true
		});

		if (window.innerWidth <= 1000) {
			d.$wrapper.find('.modal-dialog').css("max-width", '100%').css("width", '100%');
		} else {
			d.$wrapper.find('.modal-dialog').css("max-width", '1350px').css("width", '1350px');
		}

		d.show();
	}

	static show_reporting_help_old(opts = [], title = 'Reporting help') {
		var d = new frappe.ui.Dialog({
			'title': __(title),
			'fields': [
				{ 'fieldname': 'html_reporting_help', 'fieldtype': 'HTML' }

			],
			primary_action_label: __('Ok'),
			primary_action: function () {
				d.hide();
			}
		});
		let variable_list = [];

		frappe.call({
			method: "silicon_ioi.utils.lib.system.get_variables_help",
			args: {
				opts: opts
			},
			async: false,
			callback(r) {
				variable_list = r.message;
			},
		});
		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 1310px;">';

		html += '<table border=0 style="border: 0px solid #E8EAEB" width=1300px>';

		for (var i = 0; i < variable_list.length; i++) {
			if (variable_list[i]["name"] == "section") {

				html += '<tr style="height:30px;">';
				html += '<td colspan="3" style="vertical-align: middle;">&nbsp;<div class="section-head" style="background-color:#D0E7FB; border-radius: 6px; padding: 4px;"><b>' + variable_list[i]["comment"] + '</b></div></td>';
				html += '</tr>';

			} else {

				html += '<tr style="height:30px">';
				html += '<td width=300px style="vertical-align: middle;">&nbsp;<b>' + variable_list[i]["name"] + '</b></td>';
				html += '<td width=700px style="vertical-align: middle;">&nbsp;' + variable_list[i]["comment"] + '</td>';
				if (variable_list[i]["example"]) {
					html += '<td width=250px style="vertical-align: middle;">&nbsp;' + __('Example') + ':	<b>' + variable_list[i]["example"] + '</b></td>';
				}
				html += '</tr>';

			}
		}

		html += '</table>';
		html += '</div>';

		d.fields_dict.html_reporting_help.$wrapper.html(html);

		if (window.innerWidth <= 1000) {
			d.$wrapper.find('.modal-dialog').css("max-width", '100%').css("width", '100%');
		} else {
			d.$wrapper.find('.modal-dialog').css("max-width", '1350px').css("width", '1350px');
		}
		d.show();



	}

	static ioi_report_import() {
		var d = new frappe.ui.Dialog({
			'title': __("Import reports"),
			'fields': [
				{
					label: __('Select file'),
					fieldname: 'select_file',
					fieldtype: 'Attach',
					reqd: true
				},
				{
					label: __('Replace existing report name'),
					fieldname: 'replace_report_check',
					fieldtype: 'Check'
				},
				{
					label: __('Replace existing translation'),
					fieldname: 'replace_translation_check',
					fieldtype: 'Check'
				},
				{
					label: __('Replace existing email template'),
					fieldname: 'replace_email_template_check',
					fieldtype: 'Check'
				},
				{
					label: __('Replace existing report style'),
					fieldname: 'replace_report_style_check',
					fieldtype: 'Check'
				},
				{
					label: __('Verify doctype version'),
					fieldname: 'verify_dt_version_check',
					fieldtype: 'Check',
					default: 1
				}
			],
			primary_action_label: __('Import'),
			primary_action: function () {
				if (d.fields_dict.select_file.value) {
					frappe.call({
						method: "silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.importReports",
						args: {
							path: d.fields_dict.select_file.value,
							replace_reports_if_exists: d.fields_dict.replace_report_check.value == 1,
							replace_translations_if_exists: d.fields_dict.replace_translation_check.value == 1,
							replace_email_template_if_exists: d.fields_dict.replace_email_template_check.value == 1,
							replace_report_style_if_exists: d.fields_dict.replace_report_style_check.value == 1,
							verify_dt_version_check: d.fields_dict.verify_dt_version_check.value == 1,
						},
						async: false,
						callback(r) {

							if (r.message.result.code == 200) {
								d.hide();
								frappe.msgprint(__("Report import completed"))
							} else {
								if (r.message.result.code == 500) {
									frappe.msgprint({
										title: __("Report import failed"),
										message: __("Cannot be open the file because the file format or extension is not valid"),
										indicator: "red"
									});
									console.log(r.message.result);
								} else {
									if (r.message.result.code == 501) {
										frappe.msgprint({
											title: __("Report import failed"),
											message: __("File appears to be corrupted"),
											indicator: "red"
										});
										console.log(r.message.result);
									} else {
										if (r.message.result.code == 502) {
											frappe.msgprint({
												title: __("Report import failed"),
												message: __("No report to import into the file"),
												indicator: "red"
											});
											console.log(r.message.result);
										} else {
											if (r.message.result.code == 503) {
												frappe.msgprint({
													title: __("Report import failed"),
													message: __("DocTypes version verification failed"),
													indicator: "red"
												});
												console.log(r.message.result);
											}
										}
									}
								}
							}
						},
					});
				}



			}
		});

		d.show();

	}
	static ioi_report_export() {
		let report_names = cur_list.get_checked_items().map((n) => n.name);

		var d = new frappe.ui.Dialog({
			'title': __("Export reports"),
			'fields': [
				{ 'label': __('Export unlinked translations'), 'fieldname': 'export_unlinked_translations', 'fieldtype': 'Check' },
				{ 'label': __('Export all report styles'), 'fieldname': 'export_report_styles', 'fieldtype': 'Check' }
			],
			primary_action_label: __('Ok'),
			primary_action: function () {
				let params = new URLSearchParams({
					names: JSON.stringify(report_names),
					export_unlinked_translations: d.fields_dict.export_unlinked_translations.value == 1,
					export_report_styles: d.fields_dict.export_report_styles.value == 1
				});
				let url = `/api/method/silicon_ioi.ioi_system.doctype.ioi_report.ioi_report.export_reports?${params}`;

				let w = window.open(url);
				if (!w) {
					frappe.msgprint(__('Please enable pop-ups'));
					return;
				} else {
					d.hide();
				}

			}
		});

		d.show();


	}
}

silicon_ioi.ioiReportMethods = ioiReportMethods;
