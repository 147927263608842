current_hash = window.location.hash;
call_validation();
user_validation();

//to allow locationchange event
(() => {
    let oldPushState = history.pushState;
    history.pushState = function pushState() {
        let ret = oldPushState.apply(this, arguments);
        window.dispatchEvent(new Event('pushstate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
    };

    let oldReplaceState = history.replaceState;
    history.replaceState = function replaceState() {
        let ret = oldReplaceState.apply(this, arguments);
        window.dispatchEvent(new Event('replacestate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
    };

    window.addEventListener('popstate', () => {
        window.dispatchEvent(new Event('locationchange'));
    });
})();


// Capture the locationchange event
window.addEventListener('locationchange', function(){
    call_validation();
});

var saved_doctype="";

function call_validation(){
	let doctype = window.location.href.split('/app/').pop().split('/')[0].replaceAll("-"," ");
	//to prevent multiple fire
	if (doctype!=saved_doctype){
		saved_doctype=doctype;
		if (doctype){
			//form
			frappe.call({
				method: "silicon_ioi.ioi_configuration.doctype.ioi_call_validation.ioi_call_validation.server_call",
				args:{
					doctype:doctype
				},
				async:true,
				callback: function (r) {
					if(r["message"]){
						if (r["message"].length<2){
							frappe.show_alert(__("An error occurred while contacting the distant server."),60);
						}else{
							if (r["message"][0]=="Soon Expired"){
								if (r["message"][1]){
									frappe.show_alert(r["message"][1],60);
								}else{
									frappe.show_alert(r["message"][1],60);
								}
							}
							if (r["message"][0]=="Expired"){
								let doctype_name=doctype.replaceAll("-"," ").replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase()).replace("Ioi","ioi")
								if (r["message"][1]){
									frappe.show_alert(r["message"][1],60);
								}else{
									frappe.show_alert(r["message"][1],60);
								}
								frappe.ui.form.on(doctype_name, {
									refresh: function (frm) {
										frm.disable_save();
									}
								})
							}
							if (r["message"][0]=="Locked"){
								if (r["message"][1]){
									frappe.dom.freeze(r["message"][1]);
									frappe.msgprint(r["message"][1]);
								}else{
									frappe.dom.freeze(r["message"][1]);
									frappe.msgprint(r["message"][1]);
								}
							}
						}
						
					}
				},
			});
				
		}
	}
}

function user_validation(){
	frappe.call({
		method: "silicon_ioi.ioi_configuration.doctype.ioi_call_validation.ioi_call_validation.user_validation",
		async:true,
		callback: function (r) {
			if(r["message"]){
				if (r["message"].length==2){
					if (r["message"][0]=="Locked"){
						frappe.dom.freeze(r["message"][1]);
						frappe.msgprint(r["message"][1]);
					}
					if (r["message"][0]=="Exceeded"){
						frappe.show_alert(r["message"][1],60);
					}
				}
			}
		}
	})
}

/*
function render_nav_if_license_message(message=null){
	$("#wrong-license-badge").remove();
	if(message!=null){
		$(".main-section .sticky-top .navbar.navbar-expand").css({"background-color":"#ffcfcf"});
		$(".main-section .sticky-top .navbar.navbar-expand .input-group.search-bar.text-muted")[0].insertAdjacentHTML('beforeBegin', `
			<span 	id="wrong-license-badge"
					class="badge align-middle"
					style="padding: 8px; background-color: #ffffff99"
					data-toggle="tooltip"
					title='${__("Some features are disabled (Ponto, Email...)")}'>${__("Running on wrong url")}</span>
		`);
	}
}
*/
